import { useEffect } from "react";
import { message } from "antd";
import UiActions from "app/store/actions/ui.actions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Notification } from "app/types";
import { ArgsProps } from "antd/lib/message";

const NotificationsHandler = () => {
  const dispatch = useAppDispatch();
  // @ts-ignore handels userUI store typing
  const notifications: Notification[] = useAppSelector((state) => state.userUi.notifications);

  useEffect(() => {
    if (notifications.length) {
      dispatch(UiActions.setNotifications([]));
      notifications.forEach((notificationObject) => {
        const msgDetails: ArgsProps = {
          content: notificationObject.message,
          duration: notificationObject.duration,
          key: notificationObject.key,
          onClose: notificationObject.onClose,
          style: notificationObject.style,
          className: notificationObject.className
        };

        if (notificationObject.type !== null) {
          msgDetails.type = notificationObject.type || "success";
        }

        message.open(msgDetails);
      });
    }
  }, [notifications]);

  return null;
};

export default NotificationsHandler;
