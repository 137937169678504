import React, { useState } from "react";
import styled from "styled-components";
import Hue from "react-color/lib/components/hue/Hue";
import { ColorResult, CustomPicker } from "react-color";
import { InjectedColorProps } from "react-color/lib/components/common/ColorWrap";

const StyledHue = styled(Hue)`
  .hue-horizontal {
    border-radius: 10px !important;
  }
`;

const CustomHue = (props: InjectedColorProps) => {
  const [color, setColor] = useState<ColorResult>();
  return (
    <StyledHue
      height="10px"
      width="100%"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={setColor}
      color={color?.rgb || props.rgb}
    />
  );
};

export default CustomPicker(CustomHue);
