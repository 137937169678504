import * as React from "react";
import type { EditorConfig, NodeKey, SerializedLexicalNode, Spread } from "lexical";
import { DecoratorNode, LexicalNode } from "lexical";
import PronunciationComponent from "app/components/common/LexicalEditor/nodes/PronunciationComponent";
import { SynthesisMarkupLanguageType } from "app/types";

export type SerializedPronunciationNode = Spread<
  {
    pronounced: string;
    text: string;
    type: "pronunciation";
    key: string | number;
  },
  SerializedLexicalNode
>;

export class PronunciationNode extends DecoratorNode<JSX.Element> {
  __className: string;
  __pronounced: string;
  __text: string;
  __isFirstCreation: boolean;

  static getType(): string {
    return "pronunciation";
  }

  static clone(node: PronunciationNode): PronunciationNode {
    return new PronunciationNode(node.__pronounced, node.__text, false, node.__key);
  }

  constructor(pronounced: string, text: string, isFirstCreation?: boolean, key?: NodeKey) {
    super(key);
    this.__className = "pronunciation-node";
    this.__pronounced = pronounced;
    this.__text = text as string;
    this.__isFirstCreation = isFirstCreation || false;
  }

  setText(text: string) {
    const writable = this.getWritable();
    writable.__pronounced = text || "";
  }

  decorate(): JSX.Element {
    return (
      <PronunciationComponent
        pronounced={this.__pronounced}
        text={this.__text}
        isFirstCreation={this.__isFirstCreation}
        nodeKey={this.__key}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement("span");
    dom.className = this.__className;
    return dom;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): boolean {
    return false;
  }

  static importJSON(serializedNode: SerializedPronunciationNode): PronunciationNode {
    const node = $createPronunciationNode(serializedNode.pronounced, serializedNode.text);
    return node;
  }

  exportJSON(): SerializedPronunciationNode {
    return {
      type: SynthesisMarkupLanguageType.pronunciation,
      pronounced: this.__pronounced,
      text: this.__text,
      version: 1,
      key: this.__key
    };
  }
}

export function $isPronunciationNode(
  node: LexicalNode | null | undefined
): node is PronunciationNode {
  return node instanceof PronunciationNode;
}

export function $createPronunciationNode(
  pronounced: string,
  text: string,
  isFirstCreation?: boolean
): PronunciationNode {
  return new PronunciationNode(pronounced, text, isFirstCreation);
}
