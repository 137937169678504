/* eslint-disable  camelcase */

interface Header {
  name: string;
  type: string;
}

export interface Datastore {
  id: string;
  headers: Header[];
}

export interface ImportDataStoreRequest {
  url?: string;
  file_name?: string;
  handle?: string;
  type: DataType;
  text?: string;
}

export enum DataType {
  text = "text",
  file = "file",
  url = "url"
}

export enum DataSourceType {
  docx = "docx",
  doc = "doc",
  pdf = "pdf",
  script = "script"
}

export interface DataStoreContent {
  created_at: string;
  data: Record<string, string>;
  data_store_id: string;
  id: string;
}
export interface DataStoreResult {
  id: string;
  workspace_id: string;
  user_id: string;
  type: DataType;
  source_type: DataSourceType;
  data: string;
  paragraphs?: string[];
  content?: DataStoreContent[];
}
export interface UpdateDataStore {
  data?: string;
}

export interface UpdateDataStoreRequest {
  dataStoreId: string;
  updateRequest: UpdateDataStore;
}

export enum PresentationTextType {
  Notes = "notes",
  Slides = "slides"
}

export enum DraftGeneratorType {
  Summarize = "summarize",
  Podcast = "podcast"
}

export enum TalkPace {
  Relaxed = "relaxed",
  Fast = "fast"
}

export enum RecipeSetup {
  Interview = "Interview",
  Podcast = "Podcast"
}

export interface draftGeneratorRequest {
  title: string;
  order_id: string;
  data_store_id?: string;
  video_length?: VideoLength;
  type?: DraftGeneratorType;
  pace?: TalkPace;
  host?: string;
  recipe_id?: string;
  podcast_url?: string;
  prompt?: string;
}

export enum VideoLength {
  sec15 = "15s",
  sec30 = "30s",
  sec45 = "45s",
  sec60 = "60s",
  sec75 = "75s"
}
