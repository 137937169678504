import { defineMessages } from "react-intl";

export const accountMessages = defineMessages({
  newVideo: {
    id: `page-header.account.new-video`,
    defaultMessage: "New video"
  },
  fromTemplate: {
    id: `page-header.account.from-template`,
    defaultMessage: "From template"
  },
  createNewVideo: {
    id: `page-header.account.create-new-video`,
    defaultMessage: "Create new video"
  },
  videoWizard: {
    id: `page-header.account.video-wizard`,
    defaultMessage: "Video Wizard"
  },
  importPresentation: {
    id: `page-header.account.from-presentation`,
    defaultMessage: "From presentation"
  },
  startFromDocument: {
    id: `page-header.account.from-document`,
    defaultMessage: "From document"
  },
  startFromScript: {
    id: `page-header.account.from-script`,
    defaultMessage: "Script"
  },
  profileSettings: {
    id: `page-header.account.profile-settings`,
    defaultMessage: "Profile settings"
  },
  workspaceSettings: {
    id: `page-header.account.workspace-settings`,
    defaultMessage: "Workspace settings"
  },
  getHelp: {
    id: `page-header.account.get-help`,
    defaultMessage: "Get help"
  },
  helpCenter: {
    id: `page-header.account.help-center`,
    defaultMessage: "Help Center"
  },
  signout: {
    id: `page-header.account.sign-out`,
    defaultMessage: "Sign out"
  }
});

export const businessMessages = defineMessages({
  lightMode: {
    id: `page-header.business.light-mode`,
    defaultMessage: "Light mode"
  },
  darkMode: {
    id: `page-header.business.dark-mode`,
    defaultMessage: "Dark mode"
  },
  useCases: {
    id: `page-header.business.use-cases.title`,
    defaultMessage: "Use Cases"
  },
  eLearning: {
    id: `page-header.business.use-cases.e-learning`,
    defaultMessage: "E-Learning"
  },
  languageLearning: {
    id: `page-header.business.use-cases.language-learning`,
    defaultMessage: "Language Learning"
  },
  humanResources: {
    id: `page-header.business.use-cases.human-resources`,
    defaultMessage: "Human Resources"
  },
  realEstate: {
    id: `page-header.business.use-cases.real-estate`,
    defaultMessage: "Real Estate"
  },
  financialReports: {
    id: `page-header.business.use-cases.financial-reports`,
    defaultMessage: "Financial Reports"
  },
  eCommerce: {
    id: `page-header.business.use-cases.e-commerce`,
    defaultMessage: "E-Commerce"
  },
  resources: {
    id: `page-header.business.resources`,
    defaultMessage: "Resources"
  },
  becomeCharacter: {
    id: `page-header.business.resources.become-a-character`,
    defaultMessage: "Become a Character"
  },
  knowledgeBase: {
    id: `page-header.business.resources.knowledge-base`,
    defaultMessage: "Knowledge Base"
  },
  home: {
    id: `page-header.business.home`,
    defaultMessage: "Home"
  },
  templates: {
    id: `page-header.business.templates`,
    defaultMessage: "Templates"
  },
  workflows: {
    id: `page-header.business.workflows`,
    defaultMessage: "Workflows"
  },
  avatars: {
    id: `page-header.business.avatars`,
    defaultMessage: "Avatars"
  },
  briefs: {
    id: `page-header.business.briefs`,
    defaultMessage: "Briefs"
  },
  profile: {
    id: `page-header.business.profile`,
    defaultMessage: "Account Settings"
  },
  projects: {
    id: `page-header.business.projects`,
    defaultMessage: "Projects"
  },
  projectsPlaceholder: {
    id: `page-header.business.projects-search-placeholder`,
    defaultMessage: "Search..."
  },
  integrations: {
    id: `page-header.business.resources.integrations`,
    defaultMessage: "Integrations"
  },
  plansAndPricing: {
    id: `page-header.business.plans-and-pricing`,
    defaultMessage: "Plans and Pricing"
  },
  searchPlaceholder: {
    id: `page-header.business.search-placeholder`,
    defaultMessage: "Search"
  },
  sendBriefButton: {
    id: `page-header.business.send-brief-button`,
    defaultMessage: "Send brief"
  },
  homeTab: {
    id: `page-header.business.home-tab`,
    defaultMessage: "Home"
  },
  workspaceTab: {
    id: `page-header.business.workspace-tab`,
    defaultMessage: "Workspace"
  }
});
