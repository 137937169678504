/* eslint-disable react/jsx-props-no-spreading */
import { ReactComponent as UpgradeIcon } from "app/assets/sider/upgrade-crown.svg";
import { inviteTeamMessages } from "app/components/teams/messages";
import styled from "styled-components";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { useIntl } from "react-intl";
import { maxWorkspaceMembers, PlanEnum, Location } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useNavigate } from "react-router-dom";
import { useAuth } from "app/auth/useAuth";
import InviteMemberNextUIModal from "app/components/teams/InviteMemberNextUIModal/InviteMemberNextUIModal";

const StyledUpgradeIcon = styled(UpgradeIcon)`
  width: 22px;
  height: 22px;
  margin-bottom: 3px;
  margin-right: 5px;
  path {
    fill: ${({ theme }) => theme.orange4};
  }
`;

const StyledButton = styled(Button)`
  &&,
  &&:focus {
    align-items: center;
    display: inline-flex;
    border: 1px solid var(--gray-10, #000);
    color: var(--gray-10, #000);
    i {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      margin-right: 10px;
      color: var(--gray-10, #000);
    }
  }
  &&:hover {
    border: 1px solid var(--gray-08, #595959);
    color: var(--gray-08, #595959);
    i {
      color: var(--gray-08, #595959);
    }
  }
  &&:active {
    border: 1px solid var(--gray-07, #8c8c8c);
    color: var(--gray-07, #8c8c8c);
    i {
      color: var(--gray-07, #8c8c8c);
    }
  }
`;

interface InviteMembersButtonProps {
  isNewTeam?: boolean;
  iconOnly?: boolean;
  icon?: ReactNode;
  className?: string;
  onClick?: () => void;
  onClose?: () => void;
  source: string;
  openModal?: boolean;
}

const InviteMembersButton = ({
  className = "",
  iconOnly = false,
  icon,
  onClick,
  onClose,
  source,
  openModal = false,
  ...props
}: InviteMembersButtonProps) => {
  const [inviteTeamMembersModalVisible, setInviteTeamMembersModalVisible] = useState(false);
  const { currentPlan } = useCurrentPlan();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const showPaymentModal = useUpgradeModal();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));

  const totalAllowedMembers: number = useMemo(() => {
    return currentWorkspace?.max_seats || maxWorkspaceMembers[currentPlan];
  }, [currentWorkspace?.max_seats, currentPlan]);

  const showCrownInviteMembers = (currentWorkspace?.members?.length || 0) >= totalAllowedMembers;

  useEffect(() => {
    if (openModal) {
      if (!isAuthenticated) {
        navigate(Location.Home);
        return;
      }
      setInviteTeamMembersModalVisible(true);
    }
  }, [openModal]);

  const onClickButton = () => {
    if (!isAuthenticated) {
      navigate(Location.Home);
      return;
    }
    if (showCrownInviteMembers) {
      const upgradeText = formatMessage(inviteTeamMessages.upgradeTitle);
      const upgradeSubText = formatMessage(inviteTeamMessages.upgradeSubTitle, {
        number: totalAllowedMembers
      });
      dispatch(analyticsEvents.productUpgradeCta({ cta: source }));
      if (currentPlan === PlanEnum.business) {
        navigate(Location.Pricing);
        return;
      }
      const targetPlan =
        currentPlan === PlanEnum.enterprise || currentPlan === PlanEnum.advanced
          ? PlanEnum.enterprise
          : PlanEnum.business;
      showPaymentModal({
        source,
        upgradeText,
        upgradeSubText,
        targetPlan,
        requestedPlans: [targetPlan]
      });
    } else {
      dispatch(analyticsEvents.inviteMemberModalOpen({ source }));
      setInviteTeamMembersModalVisible(true);
      if (onClick) {
        onClick();
      }
    }
  };

  const onCloseModal = () => {
    setInviteTeamMembersModalVisible(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <StyledButton
        data-auto-id={`invite-members-button-${source}`}
        className={className}
        onClick={onClickButton}
        icon={
          icon ||
          (showCrownInviteMembers ? <StyledUpgradeIcon /> : <i className="fa-solid fa-plus" />)
        }
        shape={iconOnly ? "circle" : "default"}
        {...props}
      >
        {!iconOnly && formatMessage(inviteTeamMessages.inviteMembers)}
      </StyledButton>
      <InviteMemberNextUIModal onClose={onCloseModal} visible={inviteTeamMembersModalVisible} />
    </>
  );
};

export default InviteMembersButton;
