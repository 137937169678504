export const WHATS_NEW_URL =
  "https://help.hourone.ai/hc/en-us/articles/12801648740753-New-Features-Updates-Timeline";
export const knowledgeBaseUrl = "https://hourone-ai.zendesk.com/hc/en-us";
export const knowledgeBaseUrlModeration = `${knowledgeBaseUrl}/articles/10520382164369-Why-am-I-blocked-`;
export const knowledgeBaseUrlModerationEthics = `${knowledgeBaseUrl}/articles/11892528573841-Security-Moderation-Ethics`;
export const knowledgeBaseUrlIllegalCharacters = `${knowledgeBaseUrl}/articles/10167913482385-Illegal-characters`;
export const hubSpotEnterpriseUrl =
  "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";

export const videoAskSeanCloneVoice = "https://www.videoask.com/fy16uswm3";
export const mobileDeepLink = "com.studio.verification://redirect?fromEmail=true";

export const serviceAgreementUrl = "https://hourone.ai/services-agreement/";
export const privatePolicyUrl = "https://hourone.ai/privacy-policy/";
export const apiDocsUrl = "https://hourone.gitbook.io/api-docs/";

export const bookDemoUrl = (source: string) =>
  `https://hourone.ai/demo/?product_upgrade_intent=${source.toLowerCase()}`;
