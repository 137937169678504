import React, { MouseEventHandler, useMemo } from "react";
import styled from "styled-components";
import { Button, Empty, List } from "antd";
import { Recipe, RecipeList, RecipeType } from "app/types";
import TemplatePreviewThumbnail from "app/components/editor/templateSelect/TemplatePreviewThumbnail";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import { templatesMessages } from "app/components/editor/templateSelect/messages";
import { useIntl } from "react-intl";
import { CharacterType } from "app/types/character";

// region [[ Styles ]]

const TemplatesListView = styled.div`
  overflow-y: scroll;
  flex: 1 1 auto;
  padding: 0 40px 40px 40px;
`;

const TemplatesListStyled = styled(List)`
  .ant-list-item {
    padding: 0px;
  }
` as typeof List;

const CategoryTitle = styled.div`
  font-size: 24px;
`;

const TemplateDescription = styled.div`
  font-size: 14px;
`;

// endregion [[ Styles ]]

// region [[ Props ]]

export interface TemplatesListProps {
  sections: RecipeList[];
  selectRecipe: (recipe: Recipe) => void;
  searchFilter: string;
  resetSearch?: CallableFunction;
  loading: boolean;
  selectedSectionType?: RecipeType;
  showMobileTemplates?: boolean;
}

// endregion [[ Props ]]

// region [[ Functions ]]
// endregion [[ Functions ]]

const TemplatesList = ({
  sections,
  searchFilter,
  selectRecipe,
  resetSearch,
  loading,
  selectedSectionType,
  showMobileTemplates
}: TemplatesListProps) => {
  const { formatMessage } = useIntl();
  const filteredSection = useMemo(
    () =>
      sections.filter((section) =>
        Object.values(section).filter((val: string) => val === searchFilter)
      ),
    [sections]
  );

  const filterSectionsByTitle = useMemo(
    () =>
      filteredSection.map((recipeSection: RecipeList) => {
        return {
          ...recipeSection,
          items: recipeSection.items.filter(
            // Returns relevant recpies by title
            (recipe: Recipe) => {
              return (
                ((showMobileTemplates && recipe.character_types?.includes(CharacterType.mobile)) ||
                  !showMobileTemplates) &&
                recipe.title.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1
              );
            }
          )
        };
      }),
    [filteredSection, searchFilter, showMobileTemplates]
  );

  const filterEmptySections = useMemo(
    // Remove sections without matching recpies
    () => filterSectionsByTitle.filter((recipeSection: RecipeList) => recipeSection.items.length),
    [filteredSection, searchFilter, filterSectionsByTitle]
  );

  return (
    <TemplatesListView>
      <ConditionalRender condition={!loading && !filterEmptySections.length}>
        <Empty description={formatMessage(templatesMessages.noTemplatesFoundTitle)}>
          <Button onClick={resetSearch as MouseEventHandler}>
            {formatMessage(templatesMessages.noTemplatesFoundAction)}
          </Button>
        </Empty>
      </ConditionalRender>
      <ConditionalRender condition={loading || filterEmptySections.length > 0}>
        <TemplatesListStyled
          loading={loading}
          dataSource={filterEmptySections}
          renderItem={({ name, items, id }) => (
            <TemplatesListStyled
              key={id}
              header={<CategoryTitle>{name}</CategoryTitle>}
              size="small"
              dataSource={items}
              grid={{ column: 2, gutter: 30 }}
              renderItem={(recipe: Recipe) => (
                <List.Item
                  key={recipe.id}
                  onClick={() => {
                    selectRecipe(recipe);
                  }}
                >
                  <TemplatePreviewThumbnail
                    badgeText={recipe.pro ? String(recipe.plans[0] ?? "").toUpperCase() : undefined}
                    badgeIcon={<CrownIcon />}
                    alt=""
                    video={recipe.video}
                    image={recipe.image}
                    tags={recipe.tags}
                    borderRadius="9px"
                    height="100%"
                    showRecipeDropdown={selectedSectionType === RecipeType.personal}
                    recipeId={recipe.id}
                    categoryName={name}
                  />

                  <TemplateDescription>{recipe.title}</TemplateDescription>
                </List.Item>
              )}
            />
          )}
        />
      </ConditionalRender>
    </TemplatesListView>
  );
};

export default TemplatesList;
