import * as React from "react";
import type { EditorConfig, NodeKey, SerializedLexicalNode, Spread } from "lexical";
import { DecoratorNode, LexicalNode } from "lexical";
import VariableComponent from "app/components/common/LexicalEditor/nodes/VariableComponent";
import { SynthesisMarkupLanguageType } from "app/types";

export type SerializedVariableNode = Spread<
  {
    type: "variable";
    variable_id: string;
    key: string;
  },
  SerializedLexicalNode
>;

export class VariableNode extends DecoratorNode<JSX.Element> {
  __className: string;

  __variable_id: string;

  static getType(): string {
    return "variable";
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__variable_id, node.__key);
  }

  constructor(variable_id: string, key?: NodeKey) {
    super(key);
    // todo here is the class name
    this.__className = "variable-node";
    this.__variable_id = variable_id;
  }

  decorate(): JSX.Element {
    return <VariableComponent id={this.__variable_id} nodeKey={this.__key} />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement("span");
    dom.className = this.__className;
    return dom;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): boolean {
    return false;
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode(serializedNode.variable_id);
    return node;
  }

  exportJSON(): SerializedVariableNode {
    return {
      type: SynthesisMarkupLanguageType.variable,
      variable_id: this.__variable_id,
      version: 1,
      key: this.__key
    };
  }
}

export function $isVariableNode(node: LexicalNode | null | undefined): node is VariableNode {
  return node instanceof VariableNode;
}

export function $createVariableNode(id: string): VariableNode {
  return new VariableNode(id);
}
