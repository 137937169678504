import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useModal, { ModalName } from "app/hooks/useModal";
import { SlackChannel } from "app/types/slack";
import { userActions } from "app/store/slices/user.slice";
import Search from "antd/lib/input/Search";
import { ReactComponent as SlackSvg } from "app/assets/realsme/slack-large.svg";
import useNotifications from "app/hooks/useNotifications";
import { fetchingStatus } from "app/utils/helpers";
import useErrors from "app/hooks/useErrors";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { StyledModal } from "app/components/common/StyledModal";

const StyledSearch = styled(Search)`
  &&& .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border: unset;
  }
  width: 100%;
`;
const Modal = styled(StyledModal)`
  .ant-modal-content {
    border-radius: 2px 2px 0px 0px;
  }
`;

const ChannelWrapper = styled(H1_FlexRow)<{ $selected?: boolean }>`
  background: ${(props) => (props.$selected ? "var(--gray-03)" : undefined)};
`;

const HashTagIcon = styled(H1_FlexRow)`
  background: #dcddff;
  border-radius: 2px;
  font-size: 25px;
  font-weight: 500;
  color: white;
`;

interface ChannelCardProps {
  name: string;
  onClick: () => void;
}
const ChannelCard = ({ name, onClick }: ChannelCardProps) => {
  return (
    <ChannelWrapper
      width="312px"
      height="62px"
      onClick={onClick}
      gap="10px"
      padding="5px"
      align="center"
    >
      <HashTagIcon width="42px" height="42px" align="center" justify="center">
        #
      </HashTagIcon>
      <H1_TextSmall>{name}</H1_TextSmall>
    </ChannelWrapper>
  );
};

const ContentWrapper = styled(H1_FlexColumn)`
  padding: 12px 18px;
  border-top: 1px black solid;
`;
const ShareChannelModal = () => {
  const [search, setSearch] = useState<string>("");
  const intl = useIntl();
  const { closeModal, editorModalOpen } = useModal();
  const dispatch = useAppDispatch();
  const { notifyMessages } = useNotifications();

  const { notifyError } = useErrors();

  const slackChannels = useAppSelector((state) => state.user.slackChannels);
  const slackShareStatus = useAppSelector((state) => state.user.slackShareStatus);

  const visible = editorModalOpen?.open === ModalName.slackShareChannel;
  const videoId = editorModalOpen?.context?.videoId;

  const onClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (visible) {
      dispatch(userActions.getSlackChannelsRequest());
    }
  }, [visible]);

  useEffect(() => {
    if (slackShareStatus === fetchingStatus.succeeded) {
      dispatch(userActions.setSlackShareStatusToIdle());
      notifyMessages([
        {
          message: "Link sent via Slack"
        }
      ]);
    } else if (slackShareStatus === fetchingStatus.failed) {
      dispatch(userActions.setSlackShareStatusToIdle());
      notifyError({ general: buildGeneralError("Sending failed", intl) });
    }
  }, [slackShareStatus]);

  const result = useMemo(() => {
    if (search) {
      return slackChannels.filter((channel: SlackChannel) => {
        return channel.name.toLowerCase().includes(search.toLowerCase());
      });
    }
    return slackChannels;
  }, [search, slackChannels]);

  const onShare = (channelId: string) => {
    dispatch(userActions.slackShareRequest({ videoId, channelId }));
    closeModal();
  };

  const onSearchChange = (event: any) => {
    const { value } = event.target;
    setSearch(value);
  };
  return (
    <Modal
      width="348px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "448px" }}
      onCancel={onClose}
      maskClosable
    >
      <H1_FlexRow padding="12px 0px 12px 28px" gap="14px">
        <SlackSvg />
        <H1_TextSmall>Choose a channel</H1_TextSmall>
      </H1_FlexRow>
      <ContentWrapper>
        <H1_FlexRow>
          <StyledSearch placeholder="Search" onChange={onSearchChange} />
        </H1_FlexRow>
        <H1_FlexColumn overflow="scroll" width="fit-content" padding="10px" gap="5px">
          {result.map((channel: SlackChannel) => {
            return (
              <ChannelCard
                name={channel.name}
                key={channel.id}
                onClick={() => {
                  onShare(channel.id);
                }}
              />
            );
          })}
        </H1_FlexColumn>
      </ContentWrapper>
    </Modal>
  );
};

export default ShareChannelModal;
