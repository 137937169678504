import React, { ReactNode, useState } from "react";
import styled from "styled-components";

const RippleContainer = styled.div<{ $borderRadius?: string }>`
  overflow: hidden;
  display: inline-block;
  position: relative;
  border-radius: ${({ $borderRadius }) => $borderRadius};
`;

const Ripple = styled.span`
  z-index: 1000;
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  transform: scale(0);
  animation: ripple 1s linear;
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

interface RippleProp {
  id: number;
  x: number;
  y: number;
  size: number;
}

const CustomRipple = ({
  borderRadius,
  children
}: {
  borderRadius?: string;
  children: ReactNode;
}) => {
  const [ripples, setRipples] = useState<RippleProp[]>([]);

  const addRipple = (event: React.MouseEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    const newRipple: RippleProp = {
      id: new Date().getTime(),
      x,
      y,
      size
    };

    setRipples((prev) => [...prev, newRipple]);
  };

  return (
    <RippleContainer onClick={addRipple} $borderRadius={borderRadius}>
      {ripples.map((ripple: RippleProp) => (
        <Ripple
          key={ripple.id}
          style={{
            width: ripple.size,
            height: ripple.size,
            top: ripple.y,
            left: ripple.x
          }}
        />
      ))}
      {children}
    </RippleContainer>
  );
};

export default CustomRipple;
