import { defineMessages } from "react-intl";

export const videoProtectionMessages = defineMessages({
  anyoneWithTheLink: {
    id: `publish-video-modal.video-protection.anyone-with-the-link`,
    defaultMessage: `Anyone with the link`
  },
  passwordProtected: {
    id: `publish-video-modal.video-protection.password-protected`,
    defaultMessage: `Password protected link`
  },
  passwordProtectedExplained: {
    id: `publish-video-modal.video-protection.password-protected.explained`,
    defaultMessage: `Set your password to protect your video`
  },
  publiclyAvailable: {
    id: `publish-video-modal.video-protection.publicly-available`,
    defaultMessage: `This design will be publicly available, anybody with the link can access it.`
  }
});
