import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Slider } from "antd";
import * as VoiceSelector from "app/store/selectorsV2/voices.selectors";
import { isUserHasMoreAtLeastTwoClonedVoices } from "app/store/selectorsV2/voices.selectors";
import {
  ChangeAttribute,
  defaultVoiceFilter,
  FeatureFlag,
  PatchOperation,
  Voice,
  VoiceSpeechAudioAction
} from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import styled, { useTheme } from "styled-components";
import { messages } from "app/components/editor/sideDrawers/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import LinkButton from "app/components/common/Layout/LinkButton";
import SelectedVoiceElement from "app/components/editor/sideDrawers/VoiceDrawer/SelectedVoiceElement";
import { fetchingStatus, isMediaPlaying } from "app/utils/helpers";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { PlanFeature } from "app/config/planFeature";
import { useShowCrown } from "app/hooks/useUpgradeModal";
import usePermissions from "app/hooks/usePermissions";
import { ListenStatus, voicesActions } from "app/store/slices/voices.slice";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import TopSceneAttributes from "app/components/editor/sideDrawers/TopSceneAttributes";
import VoiceDrawerFilters from "app/components/editor/sideDrawers/VoiceDrawer/VoiceDrawerFilters";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { Divider } from "antd/es";
import GoBackButton from "app/components/common/GoBackButton";
import { useCloneVoiceModal } from "app/hooks/useCloneVoiceModal";
import { debounce } from "lodash";
import { scenesActions } from "app/store/slices/scenes.slice";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { charactersActions } from "app/store/slices/characters.slice";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";

const SlidersButton = styled(Button)<{ $active: boolean }>`
  position: absolute;
  right: 0;
  height: 32px;
  width: 32px;
  z-index: 2;
  i {
    color: ${({ theme, $active }) =>
      $active ? (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2) : theme.gray11};
  }
`;

const StyledSlider = styled(Slider)`
  width: 200px;
  margin: 0;
  .ant-slider-rail {
    background: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? "rgba(60, 60, 67, 0.18)" : "rgba(60, 60, 67, 1)"};
  }
  && .ant-slider-handle:focus {
    box-shadow: none;
  }
  && .ant-slider-track {
    background-color: ${({ theme }) => theme.blue4};
  }
  &&& .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
  &&& .ant-slider-handle:focus,
  &&& .ant-slider-handle {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray8};
    filter: drop-shadow(0px 3.648px 7.904px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 0.304px 2.432px rgba(0, 0, 0, 0.12));
    border-color: rgba(60, 60, 67, 0.18);
    border-width: 1px;
    width: 17px;
    height: 17px;
    margin-top: -7px;
    &&&&:hover {
      border-color: rgba(60, 60, 67, 0.18);
    }
  }
  .ant-tooltip {
    .ant-tooltip-inner {
      padding: 4px 8px;
      background-color: ${({ theme }) => theme.gray1};
      color: ${({ theme }) => theme.gray7};
    }
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: ${({ theme }) => theme.gray1};
    }
  }
`;

const VoiceSettingsFlexColumn = styled(H1_FlexColumn)<{ $hidden: boolean }>`
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  max-height: ${({ $hidden }) => ($hidden ? "0" : "122px")};
  padding: ${({ $hidden }) => ($hidden ? "0" : "11px")};
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme, $hidden }) => ($hidden ? "none" : `1px solid ${theme.gray3}`)};
  border-radius: 4px;

  transition-duration: 0.3s, 0.3s, 0s;
  transition-delay: 0s, 0s, ${({ $hidden }) => ($hidden ? "0.1s" : "0")};
  transition-property: opacity, max-height, padding;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275),
    cubic-bezier(0.175, 0.885, 0.32, 1.275), cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 4px 0;
  }
`;

const MagnifyingGlassIcon = styled.i`
  position: absolute;
  z-index: 2;
  font-size: 12px;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => theme.gray7};
`;

const StyledInput = styled(H1_Input)`
  box-sizing: border-box;
  width: 100%;
  input {
    background-color: ${({ theme }) => theme.gray3};
    border-radius: 4px;
    border: 1px solid transparent;
    padding-right: 58px;
    padding-left: 30px;
    min-width: 100%; /** Bug that should be fixed in the design system */
    color: ${({ theme }) => theme.gray11};
  }
  i {
    right: 36px;
  }
`;

const NotFoundText = styled(H1_TextSmall)`
  display: inline-block;
  margin-top: 20px;
`;

const SearchLinkButton = styled(LinkButton)`
  text-decoration: underline;
  justify-content: flex-start;
  display: inline-flex;
`;

const HiddenAudioPlayer = styled(H1_FlexRow)`
  display: none;
`;

const CloneVoiceButton = styled(Button)<{ $orange: boolean }>`
  height: 38px;
  &,
  &:active,
  &:focus {
    background: ${(props) => (props.$orange ? props.theme.orange1 : props.theme.blue4)};
    color: ${(props) => (props.$orange ? props.theme.orange4 : "white")};
    border: unset;
  }
  &:hover {
    color: white;
    background: ${(props) => (props.$orange ? props.theme.orange2 : props.theme.blue2)};
    opacity: 0.8;
  }
  i {
    margin-right: 8px;
  }
`;

interface EditingProjectVoiceDrawerProps {
  right: string;
  width: string;
}

const MAX_SCENE_RATIO = 1.1;
const MIN_SCENE_RATIO = 0.9;

const EditingProjectVoiceDrawer = ({ right, width }: EditingProjectVoiceDrawerProps) => {
  const [isInitialSpeechDataLoaded, setIsInitialSpeechDataLoaded] = useState<boolean>(false);
  const [voiceSettingsPanel, setVoiceSettingsPanel] = useState<boolean>(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const [speed, setSpeed] = useState(1);
  const [pitch, setPitch] = useState(1);
  const [query, setQuery] = useState<string>("");
  const showCrown = useShowCrown({
    requestedFeature: PlanFeature.VoiceClone
  });
  const [playingVoiceId, setPlayingVoiceId] = useState<string | undefined>(undefined);
  const [loadingVoiceId, setLoadingVoiceId] = useState<string | undefined>(undefined);
  const [previewItem, setPreviewItem] = useState<Voice | undefined>(undefined);
  const flags = useFlags();
  const { editorDrawerOpen, originContext, openDrawer } = useDrawer();
  const audioRef = useRef<HTMLAudioElement>(null);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const openVoiceModal = useCloneVoiceModal();
  const assetKey = originContext?.assetKey || "voice";
  const characterAssetKey = originContext?.characterAssetKey || "character";
  const isSwitchingAvatar = originContext?.isSwitchingAvatar || false;

  const allCharacterVoices: Voice[] = useAppSelector((state) =>
    VoiceSelector.getFilteredVoicesBySearch(state, query, characterAssetKey)
  );
  const { sceneId, scene } = useSelectedScene();

  const { isWriteWorkspaceAllow } = usePermissions();

  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const filters = useAppSelector((state) => state.voices.filters);
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);

  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    VoiceSelector.getSelectedVoiceForDrawer(state, assetKey)
  );
  const draftGlobalCharacterStatus = useAppSelector(
    (state) => state.drafts.draftGlobalCharacterStatus
  );
  const isTemplateCinematic = useAppSelector(templatesSelectors.isTemplateCinematic);

  const isDraftGlobalCharacterLoading = draftGlobalCharacterStatus === fetchingStatus.loading;

  const isDrawerActive = editorDrawerOpen === Drawer.Voices;
  const showNoResultsFound = query && allCharacterVoices.length === 0;
  const previewItemId = previewItem?.id;
  const userHasMoreAtLeastTwoClonedVoices = useAppSelector(isUserHasMoreAtLeastTwoClonedVoices);
  const showCloneVoice = flags[FeatureFlag.cloneYourVoice] && !userHasMoreAtLeastTwoClonedVoices;
  const isPlayingAudio = footerAudioPlayer.status === ListenStatus.playing;
  const isApplyAllScenes = currentDraft?.global_character;

  const voiceAssets = scene?.layout.assets.voice || [];
  const currentVoiceIndex = voiceAssets.findIndex((voice) => voice.key === assetKey) + 1;
  const isLightMode = theme.mode === ThemeMode.Light;
  const draftId = currentDraft?.id as string;

  const isFilterActive = useMemo(
    () =>
      defaultVoiceFilter.useCase !== filters.useCase ||
      defaultVoiceFilter.tone !== filters.tone ||
      defaultVoiceFilter.age !== filters.age ||
      defaultVoiceFilter.pause !== filters.pause,
    [filters]
  );

  useEffect(() => {
    let sceneSpeed;
    let scenePitch;
    if (currentDraft?.global_character) {
      if (currentDraft?.attributes?.voice?.voice?.voice_adjustments) {
        sceneSpeed = currentDraft?.attributes?.voice?.voice?.voice_adjustments.find(
          (element) => element.action === VoiceSpeechAudioAction.tempo
        );
        scenePitch = currentDraft?.attributes?.voice?.voice?.voice_adjustments.find(
          (element) => element.action === VoiceSpeechAudioAction.pitch_shift
        );
      }
    } else if (scene?.attributes?.voice?.voice?.voice_adjustments) {
      sceneSpeed = scene?.attributes?.voice?.voice?.voice_adjustments.find(
        (element) => element.action === VoiceSpeechAudioAction.tempo
      );
      scenePitch = scene?.attributes?.voice?.voice?.voice_adjustments.find(
        (element) => element.action === VoiceSpeechAudioAction.pitch_shift
      );
    }

    setSpeed(sceneSpeed?.ratio || 1);
    setPitch(scenePitch?.ratio || 1);
  }, [sceneId]);
  useEffect(() => {
    if (isDrawerActive && isPlayingAudio) {
      setPlayingVoiceId(undefined);
      setLoadingVoiceId(undefined);
      if (audioRef.current && isMediaPlaying(audioRef.current)) {
        audioRef.current.pause();
      }
    }
  }, [isPlayingAudio, isDrawerActive]);
  useEffect(() => {
    if (!isDrawerActive) {
      setPreviewItem(undefined);
      setPlayingVoiceId(undefined);
      setLoadingVoiceId(undefined);
      setQuery("");
      setIsInitialSpeechDataLoaded(false);
    }
  }, [isDrawerActive]);

  const updateSceneSettings = useCallback(
    debounce((newSpeed, newPitch) => {
      dispatch(voicesActions.cleanFooterAudio());
      dispatch(voicesActions.cleanVoicePreviewV2());
      dispatch(
        scenesActions.patchVoiceSpeechRequest({
          sceneId,
          assetKey,
          voiceAdjustments: [
            { action: VoiceSpeechAudioAction.tempo, ratio: newSpeed },
            { action: VoiceSpeechAudioAction.pitch_shift, ratio: newPitch }
          ]
        })
      );
    }, 750),
    [pitch, speed, assetKey]
  );

  useEffect(() => {
    if (!isInitialSpeechDataLoaded) {
      setIsInitialSpeechDataLoaded(true);
      return;
    }
    updateSceneSettings(speed, pitch);
    return () => {
      updateSceneSettings.cancel();
    };
  }, [speed, pitch]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    setQuery(search);
    dispatch(
      googleEvents.searchVoice({
        query: search
      })
    );
  };

  const onChangePreview = (currentItem: Voice) => {
    if (currentItem.id !== selectedVoice?.id) {
      setPreviewItem(currentItem);
    }
  };

  const onVoiceChange = (voiceId: string) => {
    if (isDraftGlobalCharacterLoading) {
      return;
    }
    // Stop the Scene Listen feature if its played
    dispatch(voicesActions.cleanFooterAudio());

    if (isTemplateCinematic) {
      const obj: ChangeAttribute = {
        voice: {
          [assetKey]: {
            voice_id: voiceId
          }
        }
      };
      dispatch(charactersActions.updateAttributesOnAllScenesRequest({ attributes: obj }));

      return;
    }
    const op = [
      { op: "replace", path: `attributes.voice.${assetKey}.voice_id`, value: voiceId }
    ] as PatchOperation[];

    if (isApplyAllScenes) {
      dispatch(
        draftsActionsV2.patchDraftRequest({
          draftId,
          operations: op,
          skipRePreview: true
        })
      );
    } else if (!isApplyAllScenes) {
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId: sceneId as string,
          operations: op,
          skipRePreview: true
        })
      );
    }
  };

  const onClickVoicePlay = (e: React.MouseEvent, item: Voice) => {
    e.stopPropagation();
    if (item.id === previewItemId) {
      const audio = audioRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        audio.pause();
      } else {
        setPlayingVoiceId(previewItemId);
        audio.play();
      }
    } else {
      setPreviewItem(item);
      setLoadingVoiceId(item.id);
    }
  };

  const onClickVoiceSettingsIcon = (e: React.MouseEvent) => {
    e.stopPropagation();
    setVoiceSettingsPanel(!voiceSettingsPanel);
  };

  const onClickClearSearch = () => {
    setQuery("");
  };

  const onPlayPreview = () => {
    setPlayingVoiceId(previewItemId);
    setLoadingVoiceId(undefined);
    audioRef.current?.play();
  };

  const onPausePreview = () => {
    setPlayingVoiceId(undefined);
    setLoadingVoiceId(undefined);
  };

  const handleCloneVoiceClick = () => {
    const source = "upgrade_clone_voice";
    dispatch(googleEvents.cloneVoice());
    if (!isWriteWorkspaceAllow) {
      return;
    }
    openVoiceModal(source);
  };

  const onClickBackToScene = () => {
    if (isSwitchingAvatar) {
      openDrawer(Drawer.AvatarsAndVoices);
    } else {
      openDrawer(Drawer.Scene);
    }
  };

  const onOpenSliders = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const onCloseSliders = () => {
    // If the user clicks on the Open Filter button, it will close it and then enter here
    setTimeout(() => {
      setIsFiltersVisible(false);
    });
  };

  const onReduceSpeedSlider = () => {
    setSpeed(Math.max(MIN_SCENE_RATIO, speed - 0.1));
    dispatch(voicesActions.cleanFooterAudio());
    dispatch(voicesActions.cleanVoicePreviewV2());
  };

  const onIncreaseSpeedSlider = () => {
    setSpeed(Math.min(MAX_SCENE_RATIO, speed + 0.1));
    dispatch(voicesActions.cleanFooterAudio());
    dispatch(voicesActions.cleanVoicePreviewV2());
  };

  const onReducePitchSlider = () => {
    setPitch(Math.max(MIN_SCENE_RATIO, pitch - 0.1));
    dispatch(voicesActions.cleanFooterAudio());
    dispatch(voicesActions.cleanVoicePreviewV2());
  };

  const onIncreasePitchSlider = () => {
    setPitch(Math.min(MAX_SCENE_RATIO, pitch + 0.1));
    dispatch(voicesActions.cleanFooterAudio());
    dispatch(voicesActions.cleanVoicePreviewV2());
  };

  return (
    <FlexDrawer active={isDrawerActive} right={right} width={width}>
      <H1_FlexColumn flex="0 0 auto" padding="0 22px 0 0" gap="10px" position="relative">
        <H1_FlexRow align="center" flex="0 0 30px" justify="space-between">
          <GoBackButton
            onClick={onClickBackToScene}
            fontSize="14px"
            label={
              isSwitchingAvatar
                ? intl.formatMessage(messages.backToAvatars)
                : intl.formatMessage(messages.backToScene)
            }
            color={isLightMode ? theme.gray6 : theme.gray8}
          />
          <ConditionalRender condition={voiceAssets.length > 1}>
            <H1_FlexRow gap="8px" align="center">
              <H1_Icon icon="fas fa-user" color={isLightMode ? theme.gray6 : theme.gray8} />
              <H1_TextSmall color={isLightMode ? theme.gray6 : theme.gray8}>
                {intl.formatMessage(messages.avatarNumber, {
                  number: currentVoiceIndex > 0 ? currentVoiceIndex : 1
                })}
              </H1_TextSmall>
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexRow>
        <H1_FlexRow position="relative">
          <StyledInput
            isFixedWidth
            placeholder={intl.formatMessage(messages.voiceDrawerSearchPlaceholder)}
            initialValue={query}
            allowClear
            onChange={onSearch}
            onClear={() => setQuery("")}
          />
          <MagnifyingGlassIcon className="far fa-magnifying-glass" />
          <SlidersButton
            onClick={onOpenSliders}
            variant="light"
            isIconOnly
            startContent={<i className={"fal fa-sliders"} />}
            $active={isFilterActive}
          />
          <VoiceDrawerFilters isFiltersVisible={isFiltersVisible} onClose={onCloseSliders} />
        </H1_FlexRow>
      </H1_FlexColumn>
      <H1_FlexColumn flex={showNoResultsFound ? "0 1 auto" : "1"}>
        <H1_FlexColumn>
          <ConditionalRender condition={!!selectedVoice}>
            <H1_FlexRow padding="7px 0px 10px 0" width="100%">
              <SelectedVoiceElement
                isSticky
                item={selectedVoice as Voice}
                isSelected={!!selectedVoice?.id}
                isPreviewItem={previewItemId === selectedVoice?.id}
                isPlaying={playingVoiceId === selectedVoice?.id}
                isLoadingVoice={loadingVoiceId === selectedVoice?.id}
                onClickVoicePlay={onClickVoicePlay}
                onClickVoiceSettingsIcon={onClickVoiceSettingsIcon}
                assetKey={assetKey}
              />
            </H1_FlexRow>
          </ConditionalRender>
          <TopSceneAttributes
            isTransparent
            element={intl.formatMessage(messages.voiceElement)}
            shouldAddRightPadding
            padding="15px 22px 15px 19px"
          />
          <VoiceSettingsFlexColumn
            $hidden={!voiceSettingsPanel}
            height="122px"
            flex="0 0 auto"
            gap="5px"
            margin="0 22px 0 0"
          >
            <H1_TextXs color={theme.gray7}>{intl.formatMessage(messages.voiceSettings)}</H1_TextXs>
            <StyledDivider />
            <H1_FlexRow height="27px" align="center" justify="space-between">
              <H1_FlexRow flex="0 0 50px" align="center">
                <H1_TextXs>{intl.formatMessage(messages.voiceSettingsSpeed)}</H1_TextXs>
              </H1_FlexRow>
              <H1_FlexRow gap="10px" align="center">
                <Button
                  size="sm"
                  variant="light"
                  isIconOnly
                  startContent={<i className="far fa-minus" />}
                  onClick={onReduceSpeedSlider}
                  isDisabled={speed === MIN_SCENE_RATIO}
                />
                <StyledSlider
                  min={MIN_SCENE_RATIO}
                  max={MAX_SCENE_RATIO}
                  onChange={setSpeed}
                  value={speed}
                  step={0.1}
                  tooltip={{
                    getPopupContainer: () =>
                      document.querySelector(`.ant-slider-handle`) as HTMLElement
                  }}
                />
                <Button
                  size="sm"
                  variant="light"
                  isIconOnly
                  startContent={<i className="far fa-plus" />}
                  onClick={onIncreaseSpeedSlider}
                  isDisabled={speed === MAX_SCENE_RATIO}
                />
              </H1_FlexRow>
            </H1_FlexRow>
            <H1_FlexRow height="27px" align="center" justify="space-between">
              <H1_FlexRow flex="0 0 50px" align="center">
                <H1_TextXs>{intl.formatMessage(messages.voiceSettingsPitch)}</H1_TextXs>
              </H1_FlexRow>
              <H1_FlexRow gap="10px" align="center">
                <Button
                  size="sm"
                  variant="light"
                  isIconOnly
                  startContent={<i className="far fa-minus" />}
                  onClick={onReducePitchSlider}
                  isDisabled={pitch === MIN_SCENE_RATIO}
                />
                <StyledSlider
                  min={MIN_SCENE_RATIO}
                  max={MAX_SCENE_RATIO}
                  onChange={setPitch}
                  value={pitch}
                  step={0.1}
                  tooltip={{
                    getPopupContainer: () =>
                      document.querySelector(`.ant-slider-handle`) as HTMLElement
                  }}
                />
                <Button
                  size="sm"
                  variant="light"
                  isIconOnly
                  startContent={<i className="far fa-plus" />}
                  onClick={onIncreasePitchSlider}
                  isDisabled={pitch === MAX_SCENE_RATIO}
                />
              </H1_FlexRow>
            </H1_FlexRow>
          </VoiceSettingsFlexColumn>
          <ConditionalRender condition={showCloneVoice}>
            <H1_FlexRow padding={voiceSettingsPanel ? "17px 22px 17px 0px" : "0 22px 17px 0px"}>
              <H1_FlexColumn flex="1" gap="17px">
                <CloneVoiceButton
                  data-auto-id="clone-voice-button"
                  icon={
                    showCrown ? (
                      <i className="fa-solid fa-crown" />
                    ) : (
                      <i className="fa-solid fa-microphone" />
                    )
                  }
                  onClick={handleCloneVoiceClick}
                  $orange={!!showCrown}
                >
                  {intl.formatMessage(messages.voiceDrawerCloneButtonLabel)}
                </CloneVoiceButton>
              </H1_FlexColumn>
            </H1_FlexRow>
          </ConditionalRender>
          <H1_FlexColumn
            className="all-voices-list"
            gap="10px"
            padding="0 22px 0 0"
            overflow="hidden auto"
          >
            {allCharacterVoices.map((item: Voice) => (
              <SelectedVoiceElement
                key={item.id}
                item={item}
                isSelected={selectedVoice?.id === item.id}
                isPreviewItem={previewItemId === item.id}
                isPlaying={playingVoiceId === item.id}
                isLoadingVoice={loadingVoiceId === item.id}
                onClickVoicePlay={onClickVoicePlay}
                onChangePreview={onChangePreview}
                assetKey={assetKey}
                onVoiceChange={onVoiceChange}
              />
            ))}
          </H1_FlexColumn>
        </H1_FlexColumn>
      </H1_FlexColumn>
      <ConditionalRender condition={!!showNoResultsFound}>
        <NotFoundText whiteSpace="break-spaces">
          {
            // @ts-ignore handels formmating issue with html tags params
            intl.formatMessage(messages.voiceDrawerNoResultsFound, {
              query,
              link: (str) => (
                <SearchLinkButton $color={theme.blue4} onClick={onClickClearSearch}>
                  {str}
                </SearchLinkButton>
              )
            }) as ReactNode
          }
        </NotFoundText>
      </ConditionalRender>
      <HiddenAudioPlayer>
        <audio
          ref={audioRef}
          src={previewItem?.sample_url as string}
          onCanPlay={onPlayPreview}
          onPause={onPausePreview}
          onEnded={() => setPlayingVoiceId(undefined)}
        >
          <track kind="captions" />
        </audio>
      </HiddenAudioPlayer>
    </FlexDrawer>
  );
};

export default EditingProjectVoiceDrawer;
