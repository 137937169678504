import { AutoComplete, Image, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Location, SearchSingleResult, SearchType } from "app/types";
import { getWorkspaceSearchByType } from "app/store/selectorsV2/drafts.selectors";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { workspaceSearchMessages } from "app/components/common/Layout/messages";
import { useIntl } from "react-intl";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useNavigate } from "react-router-dom";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useDebounce } from "@react-hook/debounce";
import { BaseSelectRef } from "rc-select";
import { AnimatePresence, motion } from "framer-motion";
import { ThemeMode } from "app/utils/theme";

const { Option } = AutoComplete;

const NameText = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 400;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  line-height: 20px;
  color: ${({ theme }) => theme.gray7};
`;

const StyledImage = styled(Image)`
  border-radius: 3px;
`;
const HeaderOption = styled(Option)`
  cursor: default;
  border-bottom: 1px solid ${({ theme }) => theme.gray4};
`;
const FolderFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.blue1};
  border-radius: 3px;
`;
const LoaderWrapper = styled(H1_FlexRow)`
  position: absolute;
  z-index: 202;
  top: 14px;
  right: 10px;
`;
const MagnifyGlassIcon = styled(H1_Icon)`
  position: absolute;
  z-index: 202;
  top: 13px;
  left: 10px;
  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6)};
`;
const DarkBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 201;
`;
const StyledAutocomplete = styled(AutoComplete)`
  z-index: 202;
  width: 355px;
  height: 40px;

  &&& .ant-select-selector {
    padding-left: 32px;
    border-radius: 64px;
  }
  && .ant-select-clear {
    background-color: transparent;
    svg {
      fill: ${({ theme }) => theme.gray11};
    }
  }
  .ant-select-selection-placeholder {
    font-family: Inter, apple-system, BlinkMacSystemFont, Poppins, Ariel;
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6)};
    line-height: 40px;
  }
  &&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.blue1};
    box-shadow: none;
  }
  && .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
    padding-left: 22px;
  }
  && .ant-select-selector {
    border-radius: 3px;
    border: none;
    height: 40px;
    width: 100%;
    background: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
    align-items: center;
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6)};
    box-shadow: none;
    &:hover {
      background: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray3 : theme.gray2)};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.blue1};
    }
    .ant-select-selection-item {
      height: 100%;
      align-items: center;
      display: flex;
    }
  }
  .ant-select-dropdown {
    background-color: ${({ theme }) => theme.gray1};
    color: ${({ theme }) => theme.gray11};
    border-radius: 4px;
    max-width: 331px;
    padding: 10px;
    .rc-virtual-list-holder-inner {
      gap: 12px;
    }
    .ant-select-item-option {
      height: 48px;
      align-items: center;
    }
    .ant-select-item-option-active {
      background-color: ${({ theme }) => theme.gray2};
      color: ${({ theme }) => theme.gray11};
    }
  }
  && .ant-select-item-option-disabled {
    border-bottom: 1px solid ${({ theme }) => theme.gray4};
    cursor: default;
    height: 26px;
  }
`;

interface WorkspaceSearchProps {
  onElementClicked?: () => void;
  containerClassName?: string;
  isDarkBackground?: boolean;
}
const WorkspaceSearch = ({
  containerClassName = "",
  onElementClicked,
  isDarkBackground = true
}: WorkspaceSearchProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [searchDebounce, setSearchDebounce] = useDebounce("", 500);
  const autocompleteRef = useRef<BaseSelectRef>(null);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const foldersOptions = useAppSelector((state) =>
    getWorkspaceSearchByType(state, SearchType.folder)
  );
  const draftsOptions = useAppSelector((state) =>
    getWorkspaceSearchByType(state, SearchType.draft)
  );
  const workspaceSearchStatus = useAppSelector((state) => state.drafts.workspaceSearchStatus);
  const isWorkspaceSearchLoading = workspaceSearchStatus === fetchingStatus.loading;

  useEffect(() => {
    if (containerClassName) {
      autocompleteRef.current?.focus();
    }
  }, [containerClassName]);

  useEffect(() => {
    if (searchDebounce) {
      dispatch(draftsActions.workspaceSearchRequest({ searchValue: searchDebounce }));
    }
  }, [searchDebounce]);

  const onFolderSelected = (id: string) => {
    navigate(`${Location.Projects}/${id}`);
  };
  const onDraftSelected = (id: string) => {
    navigate(`${Location.Editor}/${id}`);
  };
  const onSelectElement = (selection: any) => {
    const { type, value } = selection;
    if (type === SearchType.folder) {
      navigate(`${Location.Projects}/${value}`);
    } else if (type === SearchType.draft) {
      navigate(`${Location.Editor}/${value}`);
    }
    if (onElementClicked) {
      onElementClicked();
    }
  };

  const onBlurAutocomplete = () => {
    setIsFocused(false);
    setSearch("");
    setSearchDebounce("");
    dispatch(draftsActions.clearWorkspaceSearchResults());
  };

  const onSearch = (text: string) => {
    setSearch(text);
    setSearchDebounce(text);
  };

  return (
    <H1_FlexRow position="relative">
      <AnimatePresence>
        <ConditionalRender condition={!!isDarkBackground && isFocused}>
          <DarkBackground
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5
            }}
          />
        </ConditionalRender>
      </AnimatePresence>
      <StyledAutocomplete
        ref={autocompleteRef}
        value={search}
        className={`search-autocomplete-container-${containerClassName}`}
        getPopupContainer={() =>
          document.querySelector(
            `.search-autocomplete-container-${containerClassName}`
          ) as HTMLElement
        }
        dropdownMatchSelectWidth={417}
        placeholder={
          isFocused
            ? formatMessage(workspaceSearchMessages.searchFocused)
            : formatMessage(workspaceSearchMessages.search)
        }
        onSelect={onSelectElement}
        onSearch={onSearch}
        onFocus={() => setIsFocused(true)}
        onBlur={onBlurAutocomplete}
        allowClear={!isWorkspaceSearchLoading}
        notFoundContent={
          !search || isWorkspaceSearchLoading ? null : (
            <H1_TextXs color={theme.gray7}>
              {/* @ts-ignore - styling issues */}
              {formatMessage(workspaceSearchMessages.notFound, {
                text: search,
                b: (str) => <b>{str}</b>
              })}
            </H1_TextXs>
          )
        }
      >
        {draftsOptions.length > 0 && (
          <HeaderOption key="drafts-header" disabled>
            <H1_TextXs color={theme.gray7}>
              {formatMessage(workspaceSearchMessages.videos)}
            </H1_TextXs>
          </HeaderOption>
        )}
        {draftsOptions.map((option: SearchSingleResult) => (
          <Option key={option.id} value={option.id}>
            <H1_FlexRow
              justify="space-between"
              align="center"
              onClick={() => onDraftSelected(option.id)}
            >
              <H1_FlexRow align="center" gap="10px">
                <H1_FlexRow width="58px" height="33px" align="center" justify="center">
                  <StyledImage
                    fallback={fallbackPlaceholder}
                    preview={false}
                    src={option.image}
                    height={33}
                    alt={option.text}
                  />
                </H1_FlexRow>
                <NameText ellipsis={{ tooltip: option.text }}>{option.text}</NameText>
              </H1_FlexRow>
            </H1_FlexRow>
          </Option>
        ))}
        {foldersOptions.length > 0 && (
          <HeaderOption key="folders-header" disabled>
            <H1_TextXs color={theme.gray7}>
              {formatMessage(workspaceSearchMessages.folders)}
            </H1_TextXs>
          </HeaderOption>
        )}
        {foldersOptions.map((option: SearchSingleResult) => (
          <Option key={option.id} value={option.id}>
            <H1_FlexRow align="center" gap="10px" onClick={() => onFolderSelected(option.id)}>
              <FolderFlexRow width="58px" height="33px" align="center" justify="center">
                <H1_Icon icon="far fa-folder" color={theme.gray1} />
              </FolderFlexRow>
              <NameText ellipsis={{ tooltip: option.text }}>{option.text}</NameText>
            </H1_FlexRow>
          </Option>
        ))}
      </StyledAutocomplete>
      <MagnifyGlassIcon icon="far fa-magnifying-glass" />
      <ConditionalRender condition={isWorkspaceSearchLoading}>
        <LoaderWrapper>
          <i className="fas fa-spinner-third fa-spin" />
        </LoaderWrapper>
      </ConditionalRender>
    </H1_FlexRow>
  );
};

export default WorkspaceSearch;
