import styled, { css } from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)<{
  $padding?: string;
  $overflow?: string;
  $gap?: string;
  $minHeight?: string;
  $flex?: string;
  $borderRadius?: string;
  $glassEffect?: boolean;
  $alignItems?: string;
  $justifyContent?: string;
}>`
  .ant-modal-content {
    background-color: ${({ theme }) => theme.content};
  }
  .ant-modal-body {
    background-color: ${({ theme }) => theme.content};
    display: flex;
    flex-direction: column;
    padding: ${({ $padding }) => $padding};
    overflow: ${({ $overflow }) => $overflow};
    min-height: ${({ $minHeight }) => $minHeight};
    flex: ${({ $flex }) => $flex};
    position: relative;
    gap: ${({ $gap }) => $gap};
    align-items: ${({ $alignItems }) => $alignItems};
    justify-content: ${({ $justifyContent }) => $justifyContent};
    border-radius: ${({ $borderRadius }) => $borderRadius || "12px"};
  }
  ${({ $glassEffect, $borderRadius, $minHeight }) =>
    $glassEffect &&
    css`
      flex-shrink: 0;
      background: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
      backdrop-filter: blur(10px); /* Blur effect */
      border-radius: ${$borderRadius || "12px"};
      border: 1px solid rgba(255, 255, 255, 0.3); /* Light white border */
      padding: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
      height: ${$minHeight ? `calc(${$minHeight} + 40px)` : "auto"};
    `};
`;
