import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { ListenStatus, voicesActions } from "app/store/slices/voices.slice";
import { v4 as uuidv4 } from "uuid";
import { Draft, TranscriptAudioPlayType } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useTranscriptPlay from "app/hooks/useTranscriptPlay";
import { fetchingStatus } from "app/utils/helpers";

interface GlobalPronunciationSingleElementProps {
  word: string;
  pronounce: string;
  onUpdateWord: (value: string) => void;
  onUpdatePronounced: (value: string) => void;
  onDelete: () => void;
}
const GlobalPronunciationSingleElement = ({
  word,
  pronounce,
  onUpdateWord,
  onUpdatePronounced,
  onDelete
}: GlobalPronunciationSingleElementProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentWord, setCurrentWord] = useState(word);
  const [currentPronounced, setCurrentPronounced] = useState(pronounce);
  const dispatch = useAppDispatch();
  const { onTogglePlayPause, isCurrentDraftLoaded } = useTranscriptPlay({
    autoPlay: true,
    type: TranscriptAudioPlayType.draft
  });
  const currentDraft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);
  const footerLoading = useAppSelector((state) => state.voices.footerLoading);
  const createVoiceStatus = useAppSelector((state) => state.voices.createVoiceStatus);

  const isPlayingAudio = footerAudioPlayer.status === ListenStatus.playing && isCurrentDraftLoaded;
  const audioLoading =
    footerLoading ||
    createVoiceStatus === fetchingStatus.loading ||
    footerAudioPlayer.status === ListenStatus.loading;

  useEffect(() => {
    if (isPlaying && footerAudioPlayer.status === ListenStatus.idle) {
      setIsPlaying(false);
    }
  }, [footerAudioPlayer.status]);

  useEffect(() => {
    if (word !== currentWord) {
      setCurrentWord(word);
    }
  }, [word]);

  useEffect(() => {
    if (pronounce !== currentPronounced) {
      setCurrentPronounced(pronounce);
    }
  }, [pronounce]);
  const onPreviewPhraseClicked = () => {
    if (isPlayingAudio) {
      onTogglePlayPause();
      return;
    }
    setIsPlaying(true);
    dispatch(
      analyticsEvents.previewPhrase({
        text: currentWord,
        pronounced: currentPronounced,
        nodeKey: ""
      })
    );
    dispatch(
      voicesActions.createAudioPreviewRequest({
        text: currentPronounced,
        orderId: uuidv4(),
        draftId: currentDraft?.id as string,
        type: TranscriptAudioPlayType.text,
        voiceId: currentDraft?.attributes?.voice?.voice?.voice_id as string,
        autoPlay: true
      })
    );
  };

  const onChangeWord = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setCurrentWord(value);
    if (word !== value) {
      onUpdateWord(value);
    }
  };

  const onChangePronounced = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setCurrentPronounced(value);
    if (pronounce !== value) {
      onUpdatePronounced(value);
    }
  };

  const onClickDelete = () => {
    onDelete();
  };

  return (
    <H1_FlexRow align="center" gap="16px">
      <H1_FlexRow width="191px">
        <Input size="sm" value={currentWord} onChange={onChangeWord} />
      </H1_FlexRow>
      <i className="far fa-arrow-right" />
      <H1_FlexRow width="191px">
        <Input size="sm" value={currentPronounced} onChange={onChangePronounced} variant="flat" />
      </H1_FlexRow>
      <H1_FlexRow gap="8px">
        <Button
          isDisabled={!currentPronounced}
          isIconOnly
          variant="flat"
          color="primary"
          onClick={onPreviewPhraseClicked}
          startContent={
            <i
              className={
                isPlaying && audioLoading
                  ? "far fa-spinner-third fa-spin"
                  : isPlaying && isPlayingAudio
                  ? "far fa-pause"
                  : "far fa-volume"
              }
            />
          }
        />
        <Button
          isIconOnly
          variant="flat"
          color="primary"
          onClick={onClickDelete}
          startContent={<i className="far fa-trash" />}
        />
      </H1_FlexRow>
    </H1_FlexRow>
  );
};

export default GlobalPronunciationSingleElement;
