import React, { Key, useEffect, useMemo, useRef, useState } from "react";
import {
  H1_TextHeadline,
  H1_TextMiddle,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from "@nextui-org/react";
import FileUploader from "app/components/common/FileUploader";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { DataStoreContent, DataType, MimeType } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { dataStoreActions } from "app/store/slices/dataStore.slice";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { draftsActions } from "app/store/slices/drafts.slice";
import useWorkflow from "app/pages/EditDraftPage/workflow/useWorkflow";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import WorkflowTableStatus from "app/pages/EditDraftPage/workflow/WorkflowTableStatus";
import WorkflowTableModified from "app/pages/EditDraftPage/workflow/WorkflowTableModified";
import WorkflowTableActionIcon from "app/pages/EditDraftPage/workflow/WorkflowTableActionIcon";
import { videosActions } from "app/store/slices/videos.slice";
import { Typography } from "antd";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { getIsVideosByDataStoreContentIds } from "app/store/selectorsV2/videos.selectors";

const FILE_TYPES = MimeType.csv;

const MaxFlexColumn = styled(H1_FlexColumn)`
  max-width: 90vw;
`;
const StyledTable = styled(Table)`
  > div {
    background-color: ${({ theme }) => theme.content};
  }
  margin-bottom: 20px;
  thead tr:first-of-type {
    height: 65px;
  }
  thead th {
    background-color: ${({ theme }) => theme.content};
  }
  thead th:first-of-type {
    width: 65px;
  }
  tbody tr {
    height: 65px;
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.gray2};
    }
  }
  td {
    max-width: 250px;
  }
`;
const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.gray1};
  padding: 49px 68px 52px;
  max-width: 710px;
`;
const BorderFlexColumn = styled(H1_FlexColumn)`
  border: 1px dashed ${({ theme }) => theme.gray5};
  &:hover {
    border-color: ${({ theme }) => theme.gray6};
  }
`;

const RoundButton = styled(Button)`
  background-color: ${({ theme }) => theme.gray1};
  border-radius: 50%;
  max-width: 41px;
  min-width: 41px;
  width: 41px;
  height: 41px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  i {
    font-size: 14px;
  }
`;

const NameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.gray11};
  font-size: 0.875rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-weight: 400;
  && {
    max-width: ${({ $maxWidth }: { $maxWidth: string }) => $maxWidth};
  }
`;

const SPECIAL_HEADER_VIDEO_ACTION = "#VIDEO_ACTION#";
const SPECIAL_HEADER_INDEX = "#";
const SPECIAL_HEADER_STATUS = "#Status#";
const SPECIAL_HEADER_MODIFIED = "#Modified#";

interface WorkflowTableProps {
  shareLinkMode?: boolean;
  draftId?: string;
  workflowId?: string;
}
const WorkflowTable = ({ shareLinkMode = false, draftId, workflowId }: WorkflowTableProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Set<string>>(new Set());
  const [createdVideoId, setCreatedVideoId] = useState<string>("");
  const [rowHover, onRowHover] = useState<string>("");
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { reset, onSelectFile, importLoading, fileStoreResult } = useFileUploadFilestack({
    autoUpload: true
  });
  const { createWorkflowVideo } = useWorkflow();
  const videoCreateStatus = useAppSelector((state) => state.videos.videoCreateStatus);
  const bulkSubmissionsStatus = useAppSelector((state) => state.drafts.bulkSubmissionsStatus);
  const csvExportStatus = useAppSelector((state) => state.drafts.csvExportStatus);
  const workflowLiveMode =
    useAppSelector((state) => state.drafts.workflowLiveMode) || shareLinkMode;
  const workflowSubmissionStatus = useAppSelector((state) => state.drafts.workflowSubmissionStatus);
  const importDataStatus = useAppSelector((state) => state.dataStore.importDataStatus);
  const getImportDataStatus = useAppSelector((state) => state.dataStore.getImportDataStatus);
  const importDataResult = useAppSelector((state) => state.dataStore.importDataResult);
  const isVideoExistsInContent = useAppSelector(getIsVideosByDataStoreContentIds);

  const content = importDataResult?.content || [];
  const isTableExists = !!content && content.length > 0;
  const bulkSubmissionsLoading = bulkSubmissionsStatus === fetchingStatus.loading;
  const videoCreateLoading = videoCreateStatus === fetchingStatus.loading;
  const isLoading =
    getImportDataStatus === fetchingStatus.loading || importDataStatus === fetchingStatus.loading;
  const isRowIconLoading = videoCreateLoading || bulkSubmissionsLoading;
  const isCsvExportStatusLoading = csvExportStatus === fetchingStatus.loading;

  const tableColumns = useMemo(() => {
    if (!content || content.length === 0) {
      return [];
    }
    const columnNames = Object.keys(content[0].data);
    return [
      {
        key: SPECIAL_HEADER_VIDEO_ACTION,
        title: " ",
        width: 65,
        isCustomHeader: true
      },
      {
        key: SPECIAL_HEADER_INDEX,
        title: "#",
        width: 65,
        isCustomHeader: true
      },
      {
        key: SPECIAL_HEADER_STATUS,
        title: "Status",
        width: 175,
        isCustomHeader: true
      },
      ...columnNames.map((col) => ({
        key: col,
        title: col,
        width: undefined,
        isCustomHeader: false
      })),
      {
        key: SPECIAL_HEADER_MODIFIED,
        title: "Modified",
        width: 220,
        isCustomHeader: true
      }
    ];
  }, [content]);

  useEffect(() => {
    if (videoCreateStatus === fetchingStatus.succeeded) {
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    } else if (videoCreateStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    }
  }, [videoCreateStatus]);

  useEffect(() => {
    if (workflowSubmissionStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setWorkflowSubmissionStatusToIdle());
    }
    if (workflowSubmissionStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setWorkflowSubmissionStatusToIdle());
    }
  }, [workflowSubmissionStatus]);

  useEffect(() => {
    if (importDataStatus === fetchingStatus.succeeded) {
      dispatch(dataStoreActions.updateImportDataToIdle());
      if (importDataResult) {
        if (!importDataResult.content) {
          dispatch(
            dataStoreActions.getImportDataRequest({
              dataStoreId: importDataResult.id,
              includeContent: true
            })
          );
        }
      }
    }
    if (importDataStatus === fetchingStatus.failed) {
      dispatch(dataStoreActions.updateImportDataToIdle());
    }
  }, [importDataStatus]);

  useEffect(() => {
    if (fileStoreResult) {
      (async () => {
        dispatch(
          dataStoreActions.importDataAndConnectRequest({
            type: DataType.file,
            file_name: fileStoreResult.name?.split(".").slice(0, -1).join(".") || "Untitled CSV",
            url: fileStoreResult.url,
            handle: fileStoreResult.handle,
            draftId
          })
        );
        reset();
      })();
    }
  }, [fileStoreResult]);

  const onClickUploadCSV = (e: React.MouseEvent) => {
    if (fileUploaderRef.current) {
      (fileUploaderRef.current as any).onClick(e);
    }
  };

  const onClickExportCsv = () => {
    const allContentIds = content?.map(({ id }) => id);
    const dataStoreId = content && content[0].data_store_id;
    if (allContentIds && dataStoreId) {
      dispatch(
        analyticsEvents.workflowCsvExport({
          dataStoreContentIds: allContentIds as string[],
          dataStoreId,
          workflowId
        })
      );
      dispatch(
        draftsActions.csvExportRequest({
          dataStoreContentIds: allContentIds as string[],
          dataStoreId,
          workflowId: workflowId as string
        })
      );
    }
  };

  const onSelectionChange = (rowKeys: Set<Key>) => {
    const selectedKeys = new Set(Array.from(rowKeys).map((key) => key as string));
    setSelectedRowKeys(selectedKeys);
  };

  const onClickRunAll = () => {
    const allContentIds = content?.map(({ id }) => id);
    const dataStoreId = content && content[0].data_store_id;
    if (allContentIds && dataStoreId) {
      dispatch(
        analyticsEvents.workflowCsvRunAll({
          dataStoreContentIds: allContentIds as string[],
          dataStoreId,
          workflowId
        })
      );
      dispatch(
        draftsActions.bulkSubmissionsRequest({
          dataStoreContentIds: allContentIds as string[],
          dataStoreId,
          workflowId: workflowId as string
        })
      );
    }
  };

  const onClickRunSelected = () => {
    const dataStoreId = content && content[0].data_store_id;
    const currentIds = Array.from(selectedRowKeys);
    if (currentIds.length > 0 && dataStoreId) {
      dispatch(
        analyticsEvents.workflowCsvRunSelected({
          dataStoreContentIds: currentIds as string[],
          dataStoreId,
          workflowId
        })
      );
      dispatch(
        draftsActions.bulkSubmissionsRequest({
          dataStoreContentIds: currentIds as string[],
          dataStoreId,
          workflowId: workflowId as string
        })
      );
    }
  };

  const onClickCreateVideo = (currentContent: DataStoreContent) => {
    dispatch(
      analyticsEvents.workflowCreateVideo({
        source: "table",
        isPublishMode: workflowLiveMode,
        dataStoreId: currentContent.data_store_id,
        dataStoreContentId: currentContent.id,
        workflowId
      })
    );
    setCreatedVideoId(currentContent.id);
    if (workflowLiveMode) {
      const dataStoreId = currentContent.data_store_id;
      dispatch(
        draftsActions.bulkSubmissionsRequest({
          dataStoreContentIds: [currentContent.id],
          dataStoreId: dataStoreId,
          workflowId: workflowId as string
        })
      );
    } else {
      createWorkflowVideo(currentContent.id, true);
    }
  };

  return (
    <H1_FlexRow
      height="100%"
      width="100%"
      justify="center"
      align={isTableExists ? "flex-start" : "center"}
    >
      {isTableExists && !isLoading && (
        <MaxFlexColumn gap="20px" width="100%">
          <H1_FlexRow justify="flex-end" gap="10px">
            <FileUploader
              onFilesSelected={(files) => onSelectFile(files[0])}
              disabled={importLoading}
              fileTypes={FILE_TYPES}
              multiple={false}
              ref={fileUploaderRef}
            >
              <Button
                startContent={<i className="fas fa-up-from-line" />}
                isLoading={importLoading}
                onClick={onClickUploadCSV}
              >
                Import CSV
              </Button>
            </FileUploader>
            <ConditionalRender condition={workflowLiveMode}>
              <ConditionalRender condition={isVideoExistsInContent}>
                <Button
                  color="primary"
                  startContent={
                    isCsvExportStatusLoading ? (
                      <i className="far fa-spinner-third fa-spin" />
                    ) : (
                      <i className="fas fa-down-from-line" />
                    )
                  }
                  onClick={onClickExportCsv}
                >
                  Export CSV
                </Button>
              </ConditionalRender>
              <ConditionalRender condition={selectedRowKeys.size > 0}>
                <Button
                  isDisabled={bulkSubmissionsLoading}
                  color="primary"
                  startContent={
                    bulkSubmissionsLoading ? (
                      <i className="far fa-spinner-third fa-spin" />
                    ) : (
                      <i className="fas fa-play" />
                    )
                  }
                  onClick={onClickRunSelected}
                >
                  Run Selected
                </Button>
              </ConditionalRender>
              <Button
                isDisabled={bulkSubmissionsLoading}
                color="primary"
                startContent={
                  bulkSubmissionsLoading ? (
                    <i className="far fa-spinner-third fa-spin" />
                  ) : (
                    <i className="fas fa-play" />
                  )
                }
                onClick={onClickRunAll}
              >
                Run All
              </Button>
            </ConditionalRender>
          </H1_FlexRow>
          <StyledTable
            color="primary"
            selectionMode={workflowLiveMode ? "multiple" : "none"}
            isHeaderSticky
            aria-label="csv table"
            onSelectionChange={onSelectionChange}
          >
            <TableHeader>
              {tableColumns.map((tableColumn, index) => (
                <TableColumn
                  className={tableColumn.isCustomHeader ? "bg-white" : ""}
                  key={tableColumn.key + index}
                  width={tableColumn.width}
                >
                  {tableColumn.title}
                </TableColumn>
              ))}
            </TableHeader>
            <TableBody>
              {content?.map((currentContent: DataStoreContent, index) => (
                <TableRow
                  onMouseEnter={() => onRowHover(currentContent.id)}
                  onMouseLeave={() => onRowHover("")}
                  key={currentContent.id}
                >
                  {tableColumns.map((tableColumn) =>
                    tableColumn.key === SPECIAL_HEADER_VIDEO_ACTION ? (
                      <TableCell key={currentContent.id + tableColumn.key + index}>
                        <RoundButton
                          onClick={() => onClickCreateVideo(currentContent)}
                          startContent={
                            <WorkflowTableActionIcon
                              dataStoreContentId={currentContent.id}
                              isLoading={isRowIconLoading && createdVideoId === currentContent.id}
                            />
                          }
                          isIconOnly
                        />
                      </TableCell>
                    ) : tableColumn.key === SPECIAL_HEADER_INDEX ? (
                      <TableCell key={currentContent.id + tableColumn.key + index}>
                        <H1_TextSmall fontFamily="Inter">{index + 1}</H1_TextSmall>
                      </TableCell>
                    ) : tableColumn.key === SPECIAL_HEADER_STATUS ? (
                      <TableCell key={currentContent.id + tableColumn.key + index}>
                        <WorkflowTableStatus dataStoreContentId={currentContent.id} />
                      </TableCell>
                    ) : tableColumn.key === SPECIAL_HEADER_MODIFIED ? (
                      <TableCell key={currentContent.id + tableColumn.key + index}>
                        <WorkflowTableModified
                          dataStoreContentId={currentContent.id}
                          rowHover={rowHover}
                          createdAt={currentContent.created_at}
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={currentContent.id + tableColumn.key + index}>
                        <NameText
                          ellipsis={{ tooltip: currentContent.data[tableColumn.key] }}
                          $maxWidth="220px"
                        >
                          {currentContent.data[tableColumn.key]}
                        </NameText>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </MaxFlexColumn>
      )}
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={(!content || content.length === 0) && !isLoading}>
        <StyledCard>
          <H1_FlexColumn gap="12px" width="369px" padding="0 0 22px 0">
            <H1_TextHeadline>Import data file</H1_TextHeadline>
            <H1_TextMiddle whiteSpace="normal" color={theme.gray7} fontWeight={600}>
              Select the data files you to use to personalize videos in bulk
            </H1_TextMiddle>
          </H1_FlexColumn>
          <FileUploader
            onFilesSelected={(files) => onSelectFile(files[0])}
            disabled={importLoading}
            fileTypes={FILE_TYPES}
            multiple={false}
            ref={fileUploaderRef}
          >
            <BorderFlexColumn
              gap="12px"
              align="center"
              width="574px"
              height="315px"
              padding="24px 0 14px"
            >
              <H1_FlexColumn padding="50px 0 0 0" align="center" width="243px" gap="5px">
                <H1_Icon
                  icon="fas fa-file-arrow-up"
                  color={theme.gray7}
                  size="57px"
                  isCursorPointer
                />
                <H1_TextXs
                  margin="0 0 30px 0"
                  whiteSpace="normal"
                  lineHeight="16px"
                  textAlign="center"
                  color={theme.gray7}
                >
                  Upload a CSV file to begin import process
                </H1_TextXs>
                <Button
                  fullWidth
                  color="primary"
                  isLoading={importLoading}
                  onClick={onClickUploadCSV}
                  startContent={<i className="fas fa-cloud-arrow-up" />}
                >
                  Choose Files
                </Button>
              </H1_FlexColumn>
            </BorderFlexColumn>
          </FileUploader>
        </StyledCard>
      </ConditionalRender>
    </H1_FlexRow>
  );
};

export default WorkflowTable;
