import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { FontsSet, LayoutAsset, Palette, PaletteColor, TemplateLayout } from "app/types";
import "app/components/editor/bottomDrawers/GroupDrawers.scss";
import styled, { useTheme } from "styled-components";
import PaletteButton from "app/components/editor/sideDrawers/StyleDrawer/PaletteButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { templatesActions } from "app/store/slices/templates.slice";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import ConditionalRender from "app/components/common/ConditionalRender";
import { styleDrawerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SelectColorType } from "app/store/thunks/analyticsEvents.thunk";
import { extractColorsBySize, fetchingStatus } from "app/utils/helpers";
import { isEqual } from "lodash";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { getFontById } from "app/store/selectorsV2/fonts.selectors";
import { Tooltip } from "antd/es";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import CustomColorPickerPopover from "app/components/editor/sideDrawers/StyleDrawer/CustomColorPickerPopover";
import layoutSelectors from "app/store/selectorsV2/layout.selectors";
import { LayoutCategory } from "app/types/layout";
import { Button } from "@nextui-org/react";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { ThemeMode } from "app/utils/theme";

const SeeAllButton = styled(Button)`
  height: 22px;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
  }
`;
const Divider = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray4};
`;
const ShadowFlexColumn = styled(H1_FlexColumn)`
  border-radius: 5px;
  border-bottom: 1px solid
    ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
`;

const InvisibleFlexRow = styled(H1_FlexRow)`
  background-color: transparent;
`;

const EditButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.gray1};
    color: ${({ theme }) => theme.gray11};
  }
  width: 49px;
  min-width: 49px;
`;

const BrandKitFlexRow = styled(H1_FlexRow)`
  top: 17px;
  left: 17px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray1};
`;

const BorderFlexColumn = styled(H1_FlexColumn)<{ $selected?: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray5)};
  &:hover {
    border-color: ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray5)};
    background-color: ${({ theme }) => theme.gray2};
  }
`;

const FontImage = styled.img`
  user-select: none;
  -webkit-user-drag: none;
`;
const StyleDrawerV2 = ({ isDrawerActive = false }: { isDrawerActive: boolean }) => {
  const [isVerticalScroller, setIsVerticalScroller] = useState<boolean>(false);
  const [isCustomColorsPalettePopoverOpen, setIsCustomColorsPalettePopoverOpen] =
    useState<boolean>(false);
  const [isSeeAllPalettesOpen, setIsSeeAllPalettesOpen] = useState<boolean>(false);
  const [isSeeAllFontsOpen, setIsSeeAllFontsOpen] = useState<boolean>(false);
  const elementsRef = useRef<HTMLDivElement>(null);
  const fontsRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const fonts: FontsSet[] = useAppSelector((state) => state.fonts.fonts);

  const currentPalettes = useAppSelector(templatesSelectors.getSystemPalettes);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const appliedPalette = useAppSelector(templatesSelectors.getAppliedPalette);

  const currentBrandkit = useAppSelector((state) => state.brandKit.currentBrandkit);
  const createPaletteStatus = useAppSelector((state) => state.templates.createPaletteStatus);
  const currentFontSet = useAppSelector((state) => getFontById(state, currentDraft.font_set_id));
  const allLayouts: TemplateLayout[] = useAppSelector(layoutSelectors.getCurrentTemplateLayouts);

  const currentFontSetId: string | undefined = currentDraft.font_set_id;
  const draftId = currentDraft.id as string;

  const isPresetAvailable = useMemo(() => {
    let result = false;

    if (allLayouts) {
      for (const layout of allLayouts) {
        const categories = Object.keys(layout.assets) as LayoutCategory[];
        for (const category of categories) {
          const asset = layout.assets[category as LayoutCategory] as unknown as LayoutAsset;
          const hasTextAssetWithPreset =
            asset &&
            asset.type.toLowerCase() === "text" &&
            asset.preset &&
            Object.keys(asset.preset).length > 0;
          if (hasTextAssetWithPreset) {
            result = true;
            break;
          }
        }

        if (result) {
          break;
        }
      }
    }

    return result;
  }, [allLayouts]);

  const noFonts = fonts.length === 0 || !isPresetAvailable;

  const displayedPalettes = useMemo(() => {
    if (isSeeAllPalettesOpen || noFonts) {
      return currentPalettes;
    }
    if (isSeeAllFontsOpen) {
      return [];
    }

    return currentPalettes.slice(0, currentBrandkit.palette ? 5 : 6);
  }, [currentPalettes, isSeeAllPalettesOpen, isSeeAllFontsOpen, currentBrandkit.palette, noFonts]);

  const displayedFonts = useMemo(() => {
    if (isSeeAllPalettesOpen) {
      return [];
    }
    if (isSeeAllFontsOpen) {
      return fonts;
    }

    if (!isPresetAvailable) {
      return [];
    }
    return fonts.slice(0, 6);
  }, [fonts, isSeeAllPalettesOpen, isSeeAllFontsOpen, isPresetAvailable]);

  const isCurrentBrandkitPaletteSelected = useMemo(() => {
    if (!currentBrandkit.palette) {
      return false;
    }
    return isEqual(
      extractColorsBySize(currentBrandkit.palette, appliedPalette)?.map(
        (palette: PaletteColor) => palette.color
      ),
      appliedPalette?.map((palette: PaletteColor) => palette.color)
    );
  }, [currentBrandkit.palette, appliedPalette]);

  useEffect(() => {
    let isVerticalScroller = false;
    if (elementsRef.current) {
      isVerticalScroller = elementsRef.current.scrollHeight > elementsRef.current.clientHeight;
      setIsVerticalScroller(isVerticalScroller);
    }
    if (fontsRef.current) {
      isVerticalScroller =
        isVerticalScroller || fontsRef.current.scrollHeight > fontsRef.current.clientHeight;
      setIsVerticalScroller(isVerticalScroller);
    }
  }, [isSeeAllFontsOpen, isSeeAllPalettesOpen]);

  useEffect(() => {
    if (isDrawerActive) {
      dispatch(
        templatesActions.getPalettesRequest({
          draftId,
          templateId: currentDraft.template_id as string
        })
      );
    }
  }, [isDrawerActive, draftId]);

  useEffect(() => {
    if (!isDrawerActive) {
      return;
    }
    if (createPaletteStatus === fetchingStatus.succeeded) {
      dispatch(templatesActions.setCreatePaletteToIdle());
    } else if (createPaletteStatus === fetchingStatus.failed) {
      dispatch(templatesActions.setCreatePaletteToIdle());
    }
  }, [createPaletteStatus, isDrawerActive]);

  const onSelectPaletteByPaletteId = (palette: Palette) => {
    dispatch(
      analyticsEvents.selectColor({
        palette: palette.name,
        paletteId: palette.id as string,
        type: SelectColorType.change_by_palette_id
      })
    );
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId,
        operations: [
          { op: "replace", path: "palette_id", value: palette.id },
          { op: "replace", path: "palette" }
        ]
      })
    );
  };

  const onSelectFont = (fontsSet: FontsSet) => {
    dispatch(
      analyticsEvents.selectFont({
        font: fontsSet.name,
        fontId: fontsSet.id
      })
    );
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId,
        operations: [{ op: "replace", path: "font_set_id", value: fontsSet.id }]
      })
    );
  };
  const onSelectPaletteByPalette = (
    colors: PaletteColor[],
    type: SelectColorType,
    paletteType: string
  ) => {
    dispatch(analyticsEvents.selectColor({ palette: paletteType, type }));
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId,
        operations: [
          { op: "replace", path: "palette", value: colors },
          { op: "replace", path: "palette_id" }
        ]
      })
    );
  };

  const upsertCustom = (colors: PaletteColor[]) => {
    dispatch(
      templatesActions.upsertPaletteRequest({
        draftId,
        name: "custom",
        colors,
        skipFramePreview: false
      })
    );
    dispatch(
      analyticsEvents.selectColor({ palette: "custom", type: SelectColorType.edit_selected_color })
    );
  };

  const onClickSeeAllPalettes = () => {
    setIsSeeAllPalettesOpen(!isSeeAllPalettesOpen);
  };

  const onClickSeeAllFonts = () => {
    setIsSeeAllFontsOpen(!isSeeAllFontsOpen);
  };

  const onCloseColorsPickerPopover = (colors?: PaletteColor[]) => {
    setIsCustomColorsPalettePopoverOpen(false);
    if (colors) {
      upsertCustom(colors);
    }
  };

  const handleVisibleChange = (visible: boolean) => {
    setIsCustomColorsPalettePopoverOpen(visible);
  };

  return (
    <H1_FlexColumn flex="1" gap="20px" overflow="hidden">
      <H1_FlexColumn gap="18px" flex="0 0 auto">
        <H1_FlexRow justify="space-between">
          <H1_TextSmall fontWeight={700}>
            {intl.formatMessage(styleDrawerMessages.styleDrawerTitle)}
          </H1_TextSmall>
        </H1_FlexRow>
        <ConditionalRender condition={!appliedPalette}>
          <H1_FlexRow
            flex="0 0 92px"
            padding="10px"
            width="100%"
            align="center"
            justify="center"
            height="92px"
          >
            <CircleLoader size="120px" />
          </H1_FlexRow>
          <Divider margin="8px 30px 6px 0" height="1px" width="calc(100% - 30px)" />
        </ConditionalRender>
        <ConditionalRender condition={!!appliedPalette}>
          <ShadowFlexColumn margin="0 30px 0 0" gap="8px">
            <H1_FlexRow position="relative" gap="12px">
              <BorderFlexColumn
                width="153px"
                height="92px"
                padding="10px"
                align="center"
                justify="center"
                position="relative"
              >
                <PaletteButton
                  paletteColors={appliedPalette as PaletteColor[]}
                  selected={false}
                  disabled
                  paletteId={currentDraft.palette_id}
                />
                <CustomColorPickerPopover
                  draftId={draftId}
                  header={intl.formatMessage(styleDrawerMessages.paletteColorPopoverHeader)}
                  onFinish={onCloseColorsPickerPopover}
                  initialColors={appliedPalette as PaletteColor[]}
                  visible={isCustomColorsPalettePopoverOpen}
                  onVisibleChange={handleVisibleChange}
                >
                  <InvisibleFlexRow
                    position="absolute"
                    width="153px"
                    height="92px"
                    padding="10px"
                    align="center"
                    justify="center"
                  >
                    <EditButton size="sm" onClick={() => setIsCustomColorsPalettePopoverOpen(true)}>
                      {intl.formatMessage(styleDrawerMessages.editButton)}
                    </EditButton>
                  </InvisibleFlexRow>
                </CustomColorPickerPopover>
              </BorderFlexColumn>
              <ConditionalRender condition={!!currentFontSetId}>
                <H1_FlexColumn
                  padding="10px 10px 10px 0"
                  width="143px"
                  height="92px"
                  align="center"
                  justify="center"
                >
                  <img alt="" src={currentFontSet?.image_url} width="133px" height="72px" />
                </H1_FlexColumn>
              </ConditionalRender>
            </H1_FlexRow>
          </ShadowFlexColumn>
          <Divider margin="8px 30px 6px 0" height="1px" width="calc(100% - 30px)" />
        </ConditionalRender>
      </H1_FlexColumn>
      <H1_FlexColumn overflow="auto" flex="1 1 auto" ref={elementsRef}>
        <ConditionalRender condition={!isSeeAllFontsOpen}>
          <H1_FlexColumn
            flex={isSeeAllFontsOpen || noFonts ? "1 0 auto" : "0 0 auto"}
            gap="8px"
            overflow="hidden"
            padding={isVerticalScroller ? "2px 20px 18px 0" : "2px 20px 18px 0"}
          >
            <H1_FlexRow align="center" justify="space-between">
              <H1_TextSmall color={theme.gray8}>
                {intl.formatMessage(styleDrawerMessages.colorsTitle)}
              </H1_TextSmall>
              <ConditionalRender condition={!noFonts}>
                <SeeAllButton size="sm" variant="light" onClick={onClickSeeAllPalettes}>
                  <H1_TextSmall>
                    {intl.formatMessage(
                      isSeeAllPalettesOpen
                        ? styleDrawerMessages.seeLess
                        : styleDrawerMessages.seeAll
                    )}
                  </H1_TextSmall>
                </SeeAllButton>
              </ConditionalRender>
            </H1_FlexRow>
            <H1_FlexRow alignSelf="flex-start" wrap="wrap" gap="14px 16px">
              <ConditionalRender condition={!!currentBrandkit?.palette}>
                <BorderFlexColumn
                  width="153px"
                  height="92px"
                  padding="10px"
                  align="center"
                  justify="center"
                  position="relative"
                  $selected={isCurrentBrandkitPaletteSelected}
                >
                  <PaletteButton
                    onSelectPalette={() =>
                      onSelectPaletteByPalette(
                        currentBrandkit.palette as PaletteColor[],
                        SelectColorType.use_brand,
                        "brand"
                      )
                    }
                    paletteColors={currentBrandkit?.palette as PaletteColor[]}
                    selected={false}
                  />
                  <Tooltip title={intl.formatMessage(styleDrawerMessages.yourBrandColors)}>
                    <BrandKitFlexRow
                      align="center"
                      justify="center"
                      padding="7px"
                      position="absolute"
                    >
                      <H1_Icon color={theme.blue2} icon="fas fa-swatchbook" size="13px" />
                    </BrandKitFlexRow>
                  </Tooltip>
                </BorderFlexColumn>
              </ConditionalRender>
              {displayedPalettes.map((palette) => {
                return (
                  <BorderFlexColumn
                    width="153px"
                    height="92px"
                    padding="10px"
                    align="center"
                    justify="center"
                    key={palette.id}
                    $selected={palette.id === currentDraft.palette_id}
                  >
                    <PaletteButton
                      onSelectPalette={() => onSelectPaletteByPaletteId(palette)}
                      paletteColors={palette.colors}
                      selected={false}
                      paletteId={palette.id}
                    />
                  </BorderFlexColumn>
                );
              })}
            </H1_FlexRow>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!isSeeAllPalettesOpen && !noFonts}>
          <H1_FlexColumn
            flex="1 0 auto"
            gap="8px"
            overflow={isSeeAllPalettesOpen || isSeeAllFontsOpen ? "auto" : "hidden"}
            padding={isVerticalScroller ? "0 20px 2px 0" : "0 20px 2px 0"}
            ref={fontsRef}
          >
            <H1_FlexRow align="center" justify="space-between">
              <H1_TextSmall color={theme.gray8}>
                {intl.formatMessage(styleDrawerMessages.fontsTitle)}
              </H1_TextSmall>
              <SeeAllButton size="sm" variant="light" onClick={onClickSeeAllFonts}>
                <H1_TextSmall>
                  {intl.formatMessage(
                    isSeeAllFontsOpen ? styleDrawerMessages.seeLess : styleDrawerMessages.seeAll
                  )}
                </H1_TextSmall>
              </SeeAllButton>
            </H1_FlexRow>
            <H1_FlexRow alignSelf="flex-start" wrap="wrap" gap="14px 16px">
              {displayedFonts.map((font: FontsSet) => {
                return (
                  <BorderFlexColumn
                    width="153px"
                    height="92px"
                    padding="10px"
                    align="center"
                    justify="center"
                    key={font.id}
                    onClick={() => onSelectFont(font)}
                    $selected={font.id === currentFontSetId}
                  >
                    <FontImage alt="" src={font.image_url} width="133px" height="72px" />
                  </BorderFlexColumn>
                );
              })}
            </H1_FlexRow>
          </H1_FlexColumn>
        </ConditionalRender>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default StyleDrawerV2;
