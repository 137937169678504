import React, { MouseEvent, useEffect, useState } from "react";
import { Alert, Spin } from "antd";
import { MediaLibraryTabs, MediaType, SelectedImageContext, StockMedia } from "app/types/media";
import styled, { css } from "styled-components";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import MediaGenericItem from "app/pages/mediaLibrary/MediaGenericItem";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import useDisplayUrls, { MediaUrl } from "app/hooks/useDisplayUrls";
import { Chip } from "@nextui-org/chip";
import {
  getMediaStockContentByTags,
  getMediaStockTags
} from "app/store/selectorsV2/media.selectors";
import startCase from "lodash-es/startCase";
import { AnimatePresence, motion } from "framer-motion";

const StyledAnimatePresence = styled(AnimatePresence)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 35px;
`;

const IconFlexRow = styled(H1_FlexRow)`
  border-radius: 2px;
  background-color: white;
  position: absolute;
  left: 15px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  ${({ top }: { top: string }) => css`
    top: ${top};
  `};
`;

const ImageContainer = styled(H1_FlexColumn)`
  max-width: 168px;
  position: relative;
  &:hover ${IconFlexRow} {
    opacity: 1;
  }

  img {
    filter: saturate(65%);
    object-fit: contain;
  }
`;

const MediaItem = ({
  onClick,
  onZoomItem,
  selected,
  item
}: {
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  item: StockMedia;
  selected: boolean;
  onZoomItem: (e: MouseEvent<HTMLDivElement>) => void;
}) => {
  const { displayUrls, loading } = useDisplayUrls([item.url]);
  const displayUrl: MediaUrl | undefined = displayUrls[item.url];
  const onImageClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!loading) {
      onClick(e);
    }
  };
  return (
    <H1_FlexColumn onClick={onImageClick}>
      <ImageContainer overflow="hidden" gap="7px" padding="4px" flex="0 0 auto">
        <MediaGenericItem
          url={displayUrl?.thumbnail as string}
          selected={selected}
          id={item.id}
          isVideo={item.media_type === MediaType.video}
        />
        <IconFlexRow onClick={onZoomItem} top="25px" padding="2px" align="center" justify="center">
          <ArrowsAltOutlined />
        </IconFlexRow>
      </ImageContainer>
    </H1_FlexColumn>
  );
};

const HouroneTab = ({
  onSelectImage,
  currentValue,
  onZoomItem
}: {
  onSelectImage: (value: string, context?: SelectedImageContext) => void;
  currentValue: string;
  onZoomItem: (event: React.MouseEvent, url: string, mediaType?: MediaType) => void;
}) => {
  const [selected, setSelected] = useState<StockMedia>();
  const [tags, setTags] = useState<string[]>([]);
  const [error, setError] = useState<string>();

  const dispatch = useAppDispatch();
  const mediaStock = useAppSelector((state) => getMediaStockContentByTags(state, tags));
  const filteredMediaStock = useAppSelector((state) => getMediaStockContentByTags(state, tags));
  const mediaStockStatus = useAppSelector((state) => state.media.mediaStockStatus);
  const allTags = useAppSelector(getMediaStockTags);
  const loading = mediaStockStatus === fetchingStatus.loading;

  useEffect(() => {
    if (!mediaStock.length) {
      dispatch(mediaActions.getHouroneMediaRequest());
    }
  }, []);

  useEffect(() => {
    if (mediaStockStatus === fetchingStatus.failed) {
      setError("Error fetching media stock");
    }
    if (mediaStockStatus === fetchingStatus.succeeded) {
      setError("");
    }
  }, [mediaStockStatus]);

  useEffect(() => {
    if (mediaStock.length) {
      const imageItem = mediaStock.find((img) => img.url === currentValue);
      if (imageItem) {
        setSelected(imageItem);
      } else {
        setSelected(undefined);
      }
    }
  }, [mediaStock, currentValue]);

  const onImageSelection = (e: MouseEvent<HTMLDivElement>, item: StockMedia) => {
    // @ts-ignore handels formmating issue with html tags params
    if (e?.target?.tagName === "A") return;
    setSelected(item);
    if (item.url) {
      onSelectImage(item.url, {
        // thumbnailType: MediaType.image,
        // thumbnail: item.thumbnailUrl,
        url: item.url,
        // title: item.title,
        handle: item.handle,
        tab: MediaLibraryTabs.Hourone,
        mediaType: item.media_type
      });
    }
  };

  const onSelectTag = (tag: string) => {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  };

  return (
    <H1_FlexColumn overflow="auto" height="100%" padding="10px 0 0 0">
      {loading && <Spin />}
      <H1_FlexRow margin={"0px 0px 5px 12px"} gap={"5px"}>
        {allTags.map((tag) => (
          <Chip
            className={"cursor-pointer"}
            size="sm"
            variant={tags.length && tags.includes(tag) ? "solid" : "bordered"}
            color={tags.length && tags.includes(tag) ? "primary" : "default"}
            key={tag}
            onClick={() => onSelectTag(tag)}
          >
            {startCase(tag)}
          </Chip>
        ))}
      </H1_FlexRow>

      {error ? (
        <Alert message={error} showIcon />
      ) : (
        <StyledAnimatePresence>
          <H1_FlexRow wrap="wrap" gap="18px" overflow="hidden auto">
            {filteredMediaStock.map((item) => (
              <motion.div
                layout
                key={item.id}
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                  default: {
                    duration: 0.5
                  },
                  scale: {
                    damping: 2
                  }
                }}
              >
                <H1_FlexColumn width="168px">
                  <MediaItem
                    onClick={(e) => onImageSelection(e, item)}
                    item={item}
                    selected={item.id === selected?.id}
                    onZoomItem={(e) => onZoomItem(e, item.url, item.media_type)}
                  />
                </H1_FlexColumn>
              </motion.div>
            ))}
          </H1_FlexRow>
        </StyledAnimatePresence>
      )}
    </H1_FlexColumn>
  );
};

export default HouroneTab;
