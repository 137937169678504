import React, { useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  PlanFeature,
  PlanValues,
  useAiToolsFeatures,
  useCollaborationFeatures,
  useMediaFeatures,
  usePlanCustomizationFeatures,
  usePublishingFeatures,
  useSupportFeatures,
  useVideoCreationFeatures
} from "app/pages/pricing/PricingPlans";
import FeatureCell from "app/pages/pricing/FeatureCell";
import FeatureContent from "app/pages/pricing/FeatureContent";
import {
  FeatureFlag,
  FetchStatus,
  PlanAndPriceModel,
  PlanEnum,
  SubscriptionPlanEnum
} from "app/types";
import { paymentModalMessages, pricingPageMessages } from "app/pages/pricing/messages";
import { useIntl } from "react-intl";
import { paymentsActions } from "app/store/slices/payments.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fetchingStatus } from "app/utils/helpers";
import { currentWorkspaceSubscriptionPlan } from "app/store/selectorsV2/workspaces.selectors";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextLargeHeadline,
  H1_TextMiddle,
  H1_TextSubBanner
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import PricingContent from "app/pages/pricing/PricingContent";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";

const COLUMN_WIDTH = "200px";
// region [[ Styles ]]

const AlignFlexColumn = styled(H1_FlexColumn)`
  ${({ alignSelf }: { alignSelf: string }) => css`
    align-self: ${alignSelf};
  `};
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
    align-items: center;
  }
`;

const BoxFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;

const ColumnsFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;

const StyledTable = styled(Table)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  margin-top: 0.3125rem;
  .ant-table {
    background-color: ${(props) => props.theme.gray1};
    background-color: transparent;
  }
  && .ant-table-thead > tr > th {
    border-bottom: 1px solid ${(props) => props.theme.gray5};
    padding-left: 5px;
    position: sticky;
    top: 0;
    background-color: ${(props) => props.theme.gray1};
    z-index: 11;
  }
  .ant-table-cell {
    color: ${({ theme }) => theme.gray11};
  }
  .ant-table table {
    border-spacing: 0 0.625rem;
  }
  .ant-checkbox {
    border-spacing: 0;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0.5625rem 0.5rem;
    &.ant-table-cell-row-hover {
      background-color: ${(props) => props.theme.gray2};
    }
  }
  & .ant-table-column-sorters {
    flex-direction: row-reverse;
    gap: 0.3125rem;
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
        transition: all 0.5s ease-in-out;
        background-color: ${(props) => props.theme.gray1};
        height: 5.3125rem;
      }
      &:hover {
        td {
          background-color: ${(props) =>
            props.theme.gray3} !important; /* &&& doesn't work with &:hover */
          ${NameText} {
            text-decoration: underline;
            font-weight: 400;
          }
        }
      }
      padding: 0.625rem 1rem 0.625rem;
      &:not(:last-child) td {
        border-bottom: 1px solid ${(props) => props.theme.gray4};
      }
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

const StickyHeader = styled(H1_FlexRow)`
  margin-top: 73px;
  position: sticky;
  top: 0px;
  z-index: 12;
  background-color: ${({ theme }) => theme.gray1};
  font-size: 28px;
  font-weight: 800;
  line-height: 42px;
`;

const PricingContainer = styled(H1_FlexColumn)`
  margin-bottom: 20px;
  tr:nth-child(even) {
    background-color: ${(props) => props.theme.gray2};
  }

  & .ant-table-tbody > tr:hover td {
    background-color: initial;
  }
  min-height: auto;
  &:last-child {
    padding-bottom: 90px;
  }

  td svg {
    margin: auto;
  }
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: calc(100% + 200px);
    margin-left: 200px;
  }
`;

const VisibilityText = styled(H1_TextMiddle)`
  ${({ paddingLeft, justify }: { paddingLeft?: string; justify: string }) => css`
    padding-left: ${paddingLeft};
    justify-content: ${justify};
  `};
`;

const Pricing = () => {
  const [pricingFetchError, setPricingFetchError] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const flags = useFlags();
  const { paymentPeriod } = useAppSelector((state) => state.payments.pricingModalProperties);
  const videoCreationFeatures = useVideoCreationFeatures({ paymentPeriod });
  const planCustomizationFeatures = usePlanCustomizationFeatures();
  const aiToolsFeatures = useAiToolsFeatures({ paymentPeriod });
  const mediaFeatures = useMediaFeatures();
  const publishingFeatures = usePublishingFeatures();
  const collaborationFeatures = useCollaborationFeatures();
  const supportFeatures = useSupportFeatures();
  const subscriptionPlan: SubscriptionPlanEnum = useAppSelector((state) =>
    currentWorkspaceSubscriptionPlan(state)
  );
  const pricingStatus: FetchStatus = useAppSelector((state) => state.payments.pricingStatus);
  const pricingAndPlans: Partial<PlanAndPriceModel>[] = useAppSelector(
    (state) => state.payments.pricingAndPlans
  );

  useEffect(() => {
    if (pricingStatus === fetchingStatus.failed) {
      setPricingFetchError(true);
    }
  }, [pricingStatus]);

  useEffect(() => {
    if (pricingAndPlans && flags[FeatureFlag.responsiveBannerCode]) {
      const getCurrentPlans = pricingAndPlans.filter(
        (pricingPlan) => pricingPlan.subscription_plan === subscriptionPlan
      );
      // Should add slider in the pricing page and then address this
      if (getCurrentPlans.length > 0) {
        dispatch(
          paymentsActions.verifyCouponRequest({
            coupon: flags[FeatureFlag.responsiveBannerCode],
            planId: getCurrentPlans[0]?.id as string
          })
        );
      }
    }
  }, [pricingAndPlans]);

  const columns = (showTitles: boolean, tableTitle: string): ColumnsType<PlanFeature> => [
    {
      dataIndex: "feature",
      key: "feature",
      width: "150px",
      title: () => (
        <VisibilityText
          justify="flex-start"
          fontSize="17px"
          lineHeight="22px"
          color={theme.gray10}
          fontWeight={700}
          paddingLeft="12px"
          whiteSpace="break-spaces"
        >
          {tableTitle}
        </VisibilityText>
      ),
      render: (feature: string, record) => (
        <FeatureContent featureName={feature} info={record.info as string} />
      )
    },
    {
      width: COLUMN_WIDTH,
      dataIndex: "plans",
      key: PlanEnum.onBoarding,
      align: "center",
      title: () =>
        showTitles ? (
          <VisibilityText
            justify="center"
            fontSize="28px"
            lineHeight="42px"
            color={theme.gray10}
            fontWeight={800}
          >
            {formatMessage(pricingPageMessages.onBoardingTableTitle)}
          </VisibilityText>
        ) : null,
      render: (plans: PlanValues) => {
        return <FeatureCell plans={plans} plan={PlanEnum.onBoarding} />;
      }
    },
    {
      width: COLUMN_WIDTH,
      dataIndex: "plans",
      key: PlanEnum.basic,
      align: "center",
      title: () =>
        showTitles ? (
          <VisibilityText
            justify="center"
            fontSize="28px"
            lineHeight="42px"
            color={theme.gray10}
            fontWeight={800}
          >
            {formatMessage(pricingPageMessages.personalTitle)}
          </VisibilityText>
        ) : null,
      render: (plans: PlanValues) => {
        return <FeatureCell plans={plans} plan={PlanEnum.basic} />;
      }
    },
    {
      width: COLUMN_WIDTH,
      dataIndex: "plans",
      key: PlanEnum.advanced,
      title: () =>
        showTitles ? (
          <VisibilityText
            justify="center"
            fontSize="28px"
            lineHeight="42px"
            color={theme.gray10}
            fontWeight={800}
          >
            {formatMessage(pricingPageMessages.businessTitle)}
          </VisibilityText>
        ) : null,
      render: (plans: PlanValues) => {
        return <FeatureCell plans={plans} plan={PlanEnum.advanced} />;
      }
    },
    {
      width: COLUMN_WIDTH,
      dataIndex: "plans",
      key: PlanEnum.enterprise,
      title: () =>
        showTitles ? (
          <VisibilityText
            justify="center"
            fontSize="28px"
            lineHeight="42px"
            color={theme.gray10}
            fontWeight={800}
          >
            {formatMessage(pricingPageMessages.enterpriseTitle)}
          </VisibilityText>
        ) : null,
      render: (plans: PlanValues) => {
        return <FeatureCell plans={plans} plan={PlanEnum.enterprise} />;
      }
    }
  ];

  if (pricingFetchError) {
    return (
      <H1_FlexColumn flex="1">
        <H1_FlexColumn flex="1" gap="10px" justify="center" align="center">
          <H1_TextSubBanner fontSize="46px" color={theme.blue4}>
            {formatMessage(paymentModalMessages.paymentErrorHeader)}
          </H1_TextSubBanner>
          <H1_TextLargeHeadline fontSize="36px" color={theme.blue4}>
            {formatMessage(paymentModalMessages.paymentErrorDescription)}
          </H1_TextLargeHeadline>
        </H1_FlexColumn>
      </H1_FlexColumn>
    );
  }

  return (
    <ColumnsFlexColumn overflow="auto">
      <AlignFlexColumn alignSelf="center" align="center" width="1182px">
        <BoxFlexColumn padding="65px 0" align="center" flex="1 1 100%">
          <AlignFlexColumn alignSelf="start" gap="6px" flex="0 0 auto">
            <H1_TextLargeHeadline fontSize="40px" lineHeight="60px" color={theme.gray10}>
              {formatMessage(pricingPageMessages.title)}
            </H1_TextLargeHeadline>
            <H1_TextMiddle fontSize="18px" lineHeight="25px" color={theme.gray10}>
              {formatMessage(pricingPageMessages.subtitle)}
            </H1_TextMiddle>
          </AlignFlexColumn>
          <PricingContent />

          <H1_FlexColumn position="relative" flex="1 0 auto">
            <StickyHeader height="69px">
              <H1_FlexRow flex="0 0 236px" height="69px" />
              <H1_FlexRow flex="0 0 200px" height="69px" justify="center" align="center">
                <H1_TextHeadline fontSize="28px">
                  {formatMessage(pricingPageMessages.onBoardingTableTitle)}
                </H1_TextHeadline>
              </H1_FlexRow>
              <H1_FlexRow flex="0 0 200px" height="69px" justify="center" align="center">
                <H1_TextHeadline fontSize="28px">
                  {formatMessage(pricingPageMessages.personalTitle)}
                </H1_TextHeadline>
              </H1_FlexRow>
              <H1_FlexRow flex="0 0 200px" height="69px" justify="center" align="center">
                <H1_TextHeadline fontSize="28px">
                  {formatMessage(pricingPageMessages.businessTitle)}
                </H1_TextHeadline>
              </H1_FlexRow>
              <H1_FlexRow flex="0 0 200px" height="69px" justify="center" align="center">
                <H1_TextHeadline fontSize="28px">
                  {formatMessage(pricingPageMessages.enterpriseTitle)}
                </H1_TextHeadline>
              </H1_FlexRow>
            </StickyHeader>
            <PricingContainer>
              <StyledTable
                size="middle"
                // @ts-ignore - worked until now
                columns={columns(false, formatMessage(pricingPageMessages.aiFeatures))}
                rowKey="feature"
                dataSource={aiToolsFeatures}
                pagination={false}
              />
            </PricingContainer>
            <PricingContainer>
              <StyledTable
                size="middle"
                // @ts-ignore - worked until now
                columns={columns(false, formatMessage(pricingPageMessages.videoCreationFeatures))}
                rowKey="feature"
                dataSource={videoCreationFeatures}
                pagination={false}
              />
            </PricingContainer>
            <PricingContainer>
              <StyledTable
                size="middle"
                // @ts-ignore - worked until now
                columns={columns(false, formatMessage(pricingPageMessages.customization))}
                rowKey="feature"
                dataSource={planCustomizationFeatures}
                pagination={false}
              />
            </PricingContainer>
            <PricingContainer>
              <StyledTable
                size="middle"
                // @ts-ignore - worked until now
                columns={columns(false, formatMessage(pricingPageMessages.mediaFeatures))}
                rowKey="feature"
                dataSource={mediaFeatures}
                pagination={false}
              />
            </PricingContainer>
            <PricingContainer>
              <StyledTable
                size="middle"
                // @ts-ignore - worked until now
                columns={columns(false, formatMessage(pricingPageMessages.publishingFeatures))}
                rowKey="feature"
                dataSource={publishingFeatures}
                pagination={false}
              />
            </PricingContainer>
            <PricingContainer>
              <StyledTable
                size="middle"
                // @ts-ignore - worked until now
                columns={columns(false, formatMessage(pricingPageMessages.collaborationFeature))}
                rowKey="feature"
                dataSource={collaborationFeatures}
                pagination={false}
              />
            </PricingContainer>
            <PricingContainer>
              <StyledTable
                size="middle"
                // @ts-ignore - worked until now
                columns={columns(false, formatMessage(pricingPageMessages.supportFeature))}
                rowKey="feature"
                dataSource={supportFeatures}
                pagination={false}
              />
            </PricingContainer>
          </H1_FlexColumn>
        </BoxFlexColumn>
      </AlignFlexColumn>
    </ColumnsFlexColumn>
  );
};
export default Pricing;
