/* eslint-disable camelcase */
import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useAppDispatch } from "app/hooks";
import { Channel } from "pusher-js";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { extractUserIdForPusher } from "app/utils/helpers";

const PusherFramePreviewHandler = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleMessage = (message: {
      pair_info: { scene_id: string; order_id: string };
      progress: number;
      status: string;
    }) => {
      if (message.status === "processing") {
        dispatch(
          scenesActions.scenePreviewLocalUpdate({
            sceneId: message.pair_info.scene_id,
            orderId: message.pair_info.order_id,
            progress: message.progress,
            loading: true
          })
        );
      } else if (message.status === "ready") {
        setTimeout(() => {
          // to let cdn be updated
          dispatch(
            scenesActions.scenePreviewLocalUpdate({
              sceneId: message.pair_info.scene_id,
              orderId: message.pair_info.order_id,
              progress: message.progress,
              loading: false
            })
          );
        }, 1000);
      } else if (message.status === "failed") {
        dispatch(
          scenesActions.scenePreviewLocalUpdate({
            sceneId: message.pair_info.scene_id,
            orderId: message.pair_info.order_id,
            loading: false
          })
        );
        dispatch(
          analyticsEvents.somethingWentWrongEvent({
            message: "Frame preview failed",
            extra: { sceneId: message?.pair_info?.scene_id }
          })
        );
      }
    };
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}_frame_preview`;
      channel = pusher.subscribe(channelName);
      channel.bind("pipeline_progress", handleMessage);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [user?.sub]);

  return null;
};

export default PusherFramePreviewHandler;
