import { defineMessages } from "react-intl";

export const workspaceSearchMessages = defineMessages({
  notFound: {
    id: `workspace-search.not-found`,
    defaultMessage: `Nothing has been found as <b>{text}</b>`
  },
  search: {
    id: `workspace-search.search`,
    defaultMessage: `Search videos and folders`
  },
  searchFocused: {
    id: `workspace-search.search-focused`,
    defaultMessage: `What are you looking for?`
  },
  videos: {
    id: `workspace-search.videos`,
    defaultMessage: `Videos`
  },
  folders: {
    id: `workspace-search.folders`,
    defaultMessage: `Folders`
  }
});
