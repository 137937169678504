import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { validateCreateVideo } from "app/components/editor/validations";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { Template } from "app/types/template";
import useErrors from "app/hooks/useErrors";
import styled from "styled-components";
import UiActions from "app/store/actions/ui.actions";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import usePermissions from "app/hooks/usePermissions";
import { draftsActions } from "app/store/slices/drafts.slice";
import { Button } from "@nextui-org/react";

export const messages = defineMessages({
  createLabel: {
    id: "editor.bake-video.button.create.label",
    defaultMessage: "Create Video"
  },
  videoCreationFailed: {
    id: "notification.validation.video-creation-failed",
    defaultMessage: "Video creation failed"
  },
  processingTooltip: {
    id: "editor.bake-video.tooltip.processing",
    defaultMessage: "Processing {progress}%"
  }
});

const StyledButton = styled(Button)`
  padding: 1px 13px;
  height: 40px;
  border-radius: 7px;
  background-color: #2c285c; /* Or approved */
  &:hover {
    background-color: #2c285c;
  }
  &:active {
    background-color: #2c285c;
  }
  svg {
    margin-right: 5px;
  }
`;

const PublishButton = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const showPaymentModal = useUpgradeModal();

  const { notifyError } = useErrors();
  const { isWriteWorkspaceAllow } = usePermissions();

  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const publishWorkflowStatus = useAppSelector((state) => state.drafts.publishWorkflowStatus);
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const template: Template | undefined = useAppSelector((state) => state.templates.currentTemplate);
  const limits = useAppSelector(getLimits);
  const deleteSceneStatus = useAppSelector((state) => state.scenes.deleteSceneStatus);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const createSceneStatus = useAppSelector((state) => state.scenes.createSceneStatus);

  const deleteSceneLoading = deleteSceneStatus === fetchingStatus.loading;
  const publishLoading = publishWorkflowStatus === fetchingStatus.loading;
  const scenesStatusLoading = scenesStatus === fetchingStatus.loading;
  const createSceneStatusLoading = createSceneStatus === fetchingStatus.loading;
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);
  const workflowId = useAppSelector((state) => state.drafts.currentDraft.workflow_id);

  const disabled =
    !template ||
    !draft ||
    !isWriteWorkspaceAllow ||
    deleteSceneLoading ||
    scenesStatusLoading ||
    createSceneStatusLoading ||
    workflowLiveMode;

  useEffect(() => {
    if (publishWorkflowStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setPublishWorkflowStatusToIdle());
      dispatch(draftsActions.getLiveWorkflowRequest({ workflowId: workflowId as string }));
    } else if (publishWorkflowStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setPublishWorkflowStatusToIdle());
    }
  }, [publishWorkflowStatus]);
  // todo deicde what to do with locked template probably the same
  const onCreateWorkflow = () => {
    // running validation
    const { errors, sceneIdsErrors } = validateCreateVideo(
      draft,
      scenes,
      template as Template,
      intl,
      limits
    );
    if (Object.keys(sceneIdsErrors).length) {
      dispatch(UiActions.setValidationScroll(true));
      dispatch(UiActions.setSceneErrors(sceneIdsErrors));
    }
    if (Object.keys(errors).length) {
      notifyError(errors);
      // dispatch(googleEvents.createVideo({ draftId: draft.id as string, success: false }));
    } else if (template?.pro) {
      const upgradeRequired = showPaymentModal({
        source: "upgrade_publish_workflow_button",
        requestedPlans: template?.plans ?? []
      });

      if (!upgradeRequired) {
        dispatch(
          draftsActions.publishWorkflowRequest({
            workflowId: draft.workflow_id as string
          })
        );
      }
    } else {
      dispatch(
        draftsActions.publishWorkflowRequest({
          workflowId: draft.workflow_id as string
        })
      );
    }
  };

  // todo decide if it close for some plans?
  return (
    <>
      <StyledButton
        dats-auto-id="editor-publish-workflow-button"
        isLloading={publishLoading}
        onClick={onCreateWorkflow}
        startContent={template?.pro ? <CrownIcon /> : <i className="fas fa-rocket" />}
        isDisabled={disabled}
        color="primary"
      >
        Publish workflow
      </StyledButton>
    </>
  );
};

export default PublishButton;
