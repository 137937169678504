/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/avatars.thunk";
import { fetchingStatus } from "app/utils/helpers";
import { FetchStatus } from "app/types";
import { avatarsAdapter } from "app/store/adapters/adapters";
import { ConsentSession } from "app/types/virtualTwin";

interface AvatarState {
  createAvatarStatus: FetchStatus;
  avatarStatus: FetchStatus;
  getConsentSessionStatus: FetchStatus;
  consentSession?: ConsentSession;
}
export const avatarsSlice = createSlice({
  name: "Avatars",
  initialState: avatarsAdapter.getInitialState<AvatarState>({
    createAvatarStatus: fetchingStatus.idle,
    avatarStatus: fetchingStatus.idle,
    getConsentSessionStatus: fetchingStatus.idle,
    consentSession: undefined
  }),
  reducers: {
    setCreateToIdle(state) {
      state.createAvatarStatus = fetchingStatus.idle;
    },
    setFetchToIdle(state) {
      state.avatarStatus = fetchingStatus.idle;
    },
    setFetchConsentToIdle(state) {
      state.getConsentSessionStatus = fetchingStatus.idle;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.createAvatarRequest.pending, (state) => {
      state.createAvatarStatus = fetchingStatus.loading;
    });

    builder.addCase(asyncThunks.createAvatarRequest.fulfilled, (state) => {
      state.createAvatarStatus = fetchingStatus.succeeded;
    });

    builder.addCase(asyncThunks.createAvatarRequest.rejected, (state) => {
      state.createAvatarStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.getAvatarConsentSessionRequest.pending, (state) => {
      state.getConsentSessionStatus = fetchingStatus.loading;
    });

    builder.addCase(asyncThunks.getAvatarConsentSessionRequest.fulfilled, (state, action) => {
      state.getConsentSessionStatus = fetchingStatus.succeeded;
      state.consentSession = action.payload;
    });

    builder.addCase(asyncThunks.getAvatarConsentSessionRequest.rejected, (state) => {
      state.getConsentSessionStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.getAvatarsRequest.pending, (state) => {
      state.avatarStatus = fetchingStatus.loading;
    });

    builder.addCase(asyncThunks.getAvatarsRequest.fulfilled, (state, action) => {
      state.avatarStatus = fetchingStatus.succeeded;
      avatarsAdapter.setAll(state, action.payload);
    });

    builder.addCase(asyncThunks.getAvatarsRequest.rejected, (state) => {
      state.avatarStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.updateAvatarProgress.pending, (state, action) => {
      const { status, sessionId, progress } = action.meta.arg;
      if (status === "ready" || status === "failed") {
        avatarsAdapter.removeOne(state, sessionId);
      } else {
        avatarsAdapter.updateOne(state, { id: sessionId, changes: { status, progress } });
      }
    });
  }
});

export default avatarsSlice.reducer;

export const avatarsActions = {
  createAvatarRequest: asyncThunks.createAvatarRequest,
  getAvatarsRequest: asyncThunks.getAvatarsRequest,
  updateAvatarProgress: asyncThunks.updateAvatarProgress,
  getAvatarConsentSessionRequest: asyncThunks.getAvatarConsentSessionRequest,
  ...avatarsSlice.actions
};
