import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import messages from "app/pages/EditDraftPage/messages";

import { useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import { useEffect } from "react";
import { joinExperiment } from "app/store/thunks/analyticsEvents.thunk";
import { useAuth } from "app/auth/useAuth";
import { Tooltip } from "antd";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray4)};
  padding: 1px 13px;
  border-radius: 7px;
  height: 40px;
  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  span {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  }
  border: none;
  span {
    font-weight: 500;
  }
  &:hover {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    span {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    i {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    background-color: ${({ theme }) => theme.gray5};
  }
  &:active {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    span {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    i {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    background-color: ${({ theme }) => theme.gray6};
  }
  svg {
    margin-right: 5px;
  }
`;

const PreviewButton = () => {
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const voicePreviewStatus = useAppSelector((state) => state.voices.voicePreviewStatus);
  const loading = voicePreviewStatus === fetchingStatus.loading;
  const { user } = auth;

  useEffect(() => {
    dispatch(
      joinExperiment({
        id: user?.sub as string,
        email: user?.email as string,
        experimentName: "exp_quickVideoPreview"
      })
    );
  }, []);

  const onPreview = () => {
    openModal(ModalName.scenesPlayer);
  };

  return (
    <Tooltip title={formatMessage(messages.previewButton)}>
      <>
        <StyledButton
          data-auto-id="preview-all-button"
          isLoading={loading}
          onClick={onPreview}
          startContent={!loading && <i className="fas fa-eye" />}
          isIconOnly
        />
      </>
    </Tooltip>
  );
};

export default PreviewButton;
