import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteBackgroundMusic,
  getBackgroundMusic,
  updateBackgroundMusic,
  uploadBackgroundMusic
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { BackgroundMusic } from "app/types/music";
import { gradientGenerate } from "app/utils/gradientGenrator";

const prefix = "[Background Music]";

const getBackgroundMusicListRequest = createAsyncThunk<BackgroundMusic[]>(
  `${prefix} getBackgroundMusicListRequest`,
  async () => {
    const result = await getBackgroundMusic();

    return result.map((music: BackgroundMusic) => ({
      ...music,
      backgroundGradient: gradientGenerate()
    }));
  },
  thunkOptions
);

const uploadBackgroundMusicListRequest = createAsyncThunk<
  BackgroundMusic,
  Partial<BackgroundMusic>
>(
  `${prefix} uploadBackgroundMusicListRequest`,
  async (music) => {
    const result = await uploadBackgroundMusic(music);

    return { ...result, backgroundGradient: gradientGenerate() };
  },
  thunkOptions
);

const deleteBackgroundMusicListRequest = createAsyncThunk<void, { id: string }>(
  `${prefix} deleteBackgroundMusicListRequest`,
  async ({ id }) => {
    await deleteBackgroundMusic({ id });
  },
  thunkOptions
);

const updateBackgroundMusicListRequest = createAsyncThunk<
  BackgroundMusic,
  Partial<BackgroundMusic>
>(
  `${prefix} updateBackgroundMusicListRequest`,
  async (music) => {
    const result = await updateBackgroundMusic(music);

    return result;
  },
  thunkOptions
);

export default {
  getBackgroundMusicListRequest,
  uploadBackgroundMusicListRequest,
  updateBackgroundMusicListRequest,
  deleteBackgroundMusicListRequest
};
