import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import Config from "app/config/Config";

// Reducers
import reducers from "app/store/reducers/index";
import reducersV2 from "app/store/reducersV2/index";
import middlewareV2 from "app/store/middlewaresV2/index";

export const history = createBrowserHistory();

// Here goes the storage we need to add when growing
const rootReducer = combineReducers({
  ...reducers,
  ...reducersV2
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Override sensitive information - passwords etc. by overiding state and actions.
});

const devEnvs = ["development", "test", "local"];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewareV2),
  devTools: devEnvs.includes(Config.env),
  enhancers: [sentryReduxEnhancer]
});

const redux = { store };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type GetState = () => RootState;
export default redux;
