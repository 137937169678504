/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/layouts.thunk";
import { fetchingStatus } from "app/utils/helpers";
import { FetchStatus, Layout } from "app/types";

interface LayoutState {
  layouts: Layout[];
  status: FetchStatus;
}

const INITIAL_STATE: LayoutState = {
  layouts: [],
  status: fetchingStatus.idle
};

export const layoutsSlice = createSlice({
  name: "Layouts",
  initialState: INITIAL_STATE,
  reducers: {
    setAllLayouts: (state, action) => {
      state.layouts = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getLayoutsRequest.fulfilled, (state, action) => {
      state.layouts = action.payload;
      state.status = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.getLayoutsRequest.pending, (state) => {
      state.status = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getLayoutsRequest.rejected, (state) => {
      state.status = fetchingStatus.failed;
    });
  }
});

export default layoutsSlice.reducer;

export const layoutActions = {
  getLayoutsRequest: asyncThunks.getLayoutsRequest,
  ...layoutsSlice.actions
};
