import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import playlistsSelectors from "app/store/selectorsV2/playlists.selectors";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { css, useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import { Draft, Location, Playlist, Video, VideoStatusEnum, VideoType } from "app/types";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { AnimatePresence, motion } from "framer-motion";
import { playlistsActions } from "app/store/slices/playlists.slice";
import GrowingInput from "app/components/common/GrowingInput";
import SortableContainer from "app/pages/editor/SortableContainer";
import PlaylistVideoElement from "app/pages/PlaylistsPage/PlaylistVideoElement";
import VideoSortableItem from "app/pages/PlaylistsPage/VideoSortiableItem";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button } from "@nextui-org/react";
import {
  draftsGlobalSelectors,
  scenesGlobalSelectors,
  videosGlobalSelectors
} from "app/store/adapters/adapters";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import { scenesActions } from "app/store/slices/scenes.slice";
import { durationToMMSS, fetchingStatus } from "app/utils/helpers";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { playListMessages } from "app/pages/PlaylistsPage/messages";
import { useIntl } from "react-intl";
import { getVideosByType } from "app/store/selectorsV2/videos.selectors";
import ShareVideoMenu from "app/components/common/ShareVideoMenu";
import { Progress } from "antd";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import useModal, { ModalName } from "app/hooks/useModal";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { draftsActions } from "app/store/slices/drafts.slice";

const VideoPreview = styled(H1_FlexRow)`
  flex-shrink: 0;
  border-radius: 10px;
  background-color: transparent;
  aspect-ratio: 16 / 9;
  & > * {
    flex: 1;
  }
`;

const MinFlexRow = styled(H1_FlexRow)`
  min-height: 0;
`;
const LeftFlexColumn = styled(H1_FlexColumn)`
  min-width: 466px;
`;
const BackgroundFlexRow = styled(H1_FlexRow)<{ $isSelected: boolean }>`
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.gray3 : theme.gray1)};
  &:hover {
    background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.gray3 : theme.gray2)};
  }
`;
const MergeAllButton = styled(Button)`
  width: fit-content;
  color: ${({ theme }) => theme.gray1};
  background-color: #a1a1aa;
`;
const BorderFlexRow = styled(H1_FlexRow)<{ $background: string }>`
  border-radius: 10px;
  background-color: ${({ $background }) => $background};
`;
const DurationRow = styled(H1_FlexRow)`
  border-radius: 25px;
  top: 7px;
  right: 6px;
  background: rgba(0, 0, 0, 0.3);
`;
const StyledGrowingInput = styled(GrowingInput)`
  width: fit-content;
  transition: background 0.3s ease-in-out;
  &&,
  &&:focus {
    outline: none;
    border-radius: 2px;
    padding: 2px;
  }
  input {
    transition: background 0.3s ease-in-out;
    background-color: ${({ theme }) => theme.gray1};
    color: ${({ theme }) => theme.gray11};
  }
  &:hover {
    input {
      background-color: ${({ theme }) => theme.gray3};
    }
    background-color: ${({ theme }) => theme.gray3};
  }
`;
const DraggableFlexRow = styled(H1_FlexRow)`
  background-color: transparent;
  width: fit-content;
`;

const VideoSeparator = styled(H1_FlexColumn)<{ $overVideo: boolean; $isBottom?: boolean }>`
  position: absolute;
  width: 100%;
  left: -20px;
  ${({ $isBottom, $overVideo, theme }) =>
    $isBottom
      ? css`
          bottom: -10px;
          border-bottom: ${$overVideo && `2px solid ${theme.blue4}`};
          padding-bottom: ${$overVideo && "22px"};
        `
      : css`
          top: -10px;
          border-top: ${$overVideo && `2px solid ${theme.blue4}`};
          padding-top: ${$overVideo && "22px"};
        `};
`;

const VideoContainer = styled(H1_FlexColumn)<{
  $dragging?: boolean;
}>`
  opacity: ${({ $dragging }) => ($dragging ? 0.3 : 1)};
  min-width: 0;
`;

const SinglePlaylistPage = () => {
  const [selectedDraftId, setSelectedDraftId] = useState<string>();
  const [hoverId, setHoverId] = useState<string | undefined>(undefined);
  const [titleValue, setTitleValue] = useState<string>("");
  const [overId, setOverId] = useState<string | undefined>(undefined);
  const [draggingDraft, setDraggingDraft] = useState<
    { draft: Draft; id: string; index: number } | undefined
  >(undefined);
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const theme = useTheme();
  const navigate = useNavigate();
  const { playlistId } = useParams<{ playlistId: string }>();
  const dispatch = useAppDispatch();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor, {
      // Example for MouseSensor
      activationConstraint: {
        delay: 250, // Delay in milliseconds before activation.
        tolerance: 5 // Movement tolerance in pixels.
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const playlistVideosStatus = useAppSelector((state) => state.playlists.playlistVideosStatus);
  const updatePlaylistStatus = useAppSelector((state) => state.playlists.updatePlaylistStatus);
  const playlistMergedVideoId = useAppSelector((state) => state.playlists.playlistMergedVideoId);
  const createPlaylistMergeStatus = useAppSelector(
    (state) => state.playlists.createPlaylistMergeStatus
  );

  const currentPlaylist: Playlist | undefined = useAppSelector((state) =>
    playlistsSelectors.getPlaylistById(state, playlistId)
  );
  const selectedDraft: Draft | undefined = useAppSelector((state) =>
    draftsGlobalSelectors.selectById(state, selectedDraftId || "")
  );

  const mergedVideos = useAppSelector((state) => getVideosByType(state, VideoType.playlist));
  const currentCreatedMergedVideo: Video | undefined = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, playlistMergedVideoId || "")
  );

  const isScenesLoading = scenesStatus === fetchingStatus.loading;
  const isLoading = playlistVideosStatus === fetchingStatus.loading;
  const isUpdatePlaylistLoading = updatePlaylistStatus === fetchingStatus.loading;
  const isCreatePlaylistMergedLoading = createPlaylistMergeStatus === fetchingStatus.loading;
  const isVideoProcessing =
    currentCreatedMergedVideo?.status === VideoStatusEnum.Processing ||
    currentCreatedMergedVideo?.status === VideoStatusEnum.NotStarted;

  useEffect(() => {
    return () => {
      dispatch(draftsActions.resetDraftStore());
    };
  }, []);
  useEffect(() => {
    if (playlistId) {
      dispatch(playlistsActions.getPlaylistByIdRequest(playlistId));
    }
  }, [playlistId]);

  useEffect(() => {
    if (currentPlaylist) {
      setTitleValue(currentPlaylist.title);
      setSelectedDraftId(currentPlaylist.draft_ids[0]);
    }
  }, [currentPlaylist?.id]);

  useEffect(() => {
    if (selectedDraft?.id) {
      dispatch(scenesActions.getDraftsScenesRequest(selectedDraft?.id));
    }
  }, [selectedDraft?.id]);

  const onUpdateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitleValue(value);
  };

  const onBlurTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch(
      playlistsActions.updatePlaylistRequest({
        id: playlistId as string,
        title: value,
        draftsIds: currentPlaylist?.draft_ids as string[]
      })
    );
  };

  const onClickPlaylists = () => {
    navigate(Location.Playlists);
  };

  const onClickMergeAll = () => {
    dispatch(
      analyticsEvents.mergePlaylistVideo({
        playlistId,
        title: titleValue
      })
    );
    dispatch(
      playlistsActions.createPlaylistMergeRequest({
        id: playlistId as string
      })
    );
  };

  const handleDragStart = (event: any) => {
    if (isUpdatePlaylistLoading || !currentPlaylist) {
      return;
    }
    const targetDraft = currentPlaylist.draft_ids.find(
      (currentDraftId) => currentDraftId === event.active.id
    ) as Draft;
    const targetDraftIndex = currentPlaylist.draft_ids.findIndex(
      (currentDraftId) => currentDraftId === event.active.id
    ) as number;
    setDraggingDraft({ draft: targetDraft, id: event.active.id, index: targetDraftIndex });
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id || !currentPlaylist) {
      // Do nothing
    } else {
      const sourceIndex = currentPlaylist.draft_ids.findIndex(
        (currentDraftId) => currentDraftId === active.id
      );
      const targetIndex = currentPlaylist.draft_ids.findIndex(
        (currentDraftId) => currentDraftId === over.id
      );
      let newDraftIds: string[];
      const originalDraftIds = currentPlaylist.draft_ids as string[];
      const movedDraftId = originalDraftIds[sourceIndex];

      newDraftIds = [
        ...originalDraftIds.slice(0, sourceIndex),
        ...originalDraftIds.slice(sourceIndex + 1)
      ];

      newDraftIds = [
        ...newDraftIds.slice(0, targetIndex),
        movedDraftId,
        ...newDraftIds.slice(targetIndex)
      ];

      dispatch(
        playlistsActions.updatePlaylistRequest({
          id: playlistId as string,
          title: titleValue,
          draftsIds: newDraftIds
        })
      );
    }
    setOverId(undefined);
    setDraggingDraft(undefined);
  };

  const handleDragOver = ({ active, over }: any) => {
    if (over && active.id !== over.id) {
      setOverId(over.id);
    } else {
      setOverId(undefined);
    }
  };

  const onRemoveVideoFromPlaylist = (draftId: string) => {
    if (currentPlaylist?.draft_ids) {
      dispatch(
        playlistsActions.updatePlaylistRequest({
          id: playlistId as string,
          title: titleValue,
          draftsIds: currentPlaylist.draft_ids.filter((id) => id !== draftId)
        })
      );
    }
  };

  const onClickPlayVideo = (reqId: string) => {
    openModal(ModalName.videoReady, { reqId });
  };

  if (isLoading || !currentPlaylist) {
    return <CircleLoader />;
  }

  return (
    <H1_FlexColumn height="100%" gap="20px" padding="0 30px 30px">
      <H1_FlexRow height="24px" gap="8px" align="center" flex="0 0 auto">
        <H1_FlexRow flex="0 0 auto" onClick={onClickPlaylists}>
          <H1_TextSmall fontSize="16px" color={theme.gray7}>
            {formatMessage(playListMessages.headline)}
          </H1_TextSmall>
        </H1_FlexRow>
        <H1_TextSmall fontSize="16px" color={theme.gray7}>
          /
        </H1_TextSmall>
        <StyledGrowingInput
          disabled={isUpdatePlaylistLoading}
          onChange={onUpdateTitle}
          onBlur={onBlurTitle}
          value={titleValue}
        />
        <ConditionalRender condition={isUpdatePlaylistLoading}>
          <H1_FlexRow width="24px">
            <CircleLoader size="24px" />
          </H1_FlexRow>
        </ConditionalRender>
      </H1_FlexRow>
      <MergeAllButton
        onClick={onClickMergeAll}
        startContent={
          <i
            className={
              isCreatePlaylistMergedLoading ? "far fa-spinner-third fa-spin" : "far fa-merge"
            }
          />
        }
      >
        {formatMessage(playListMessages.mergeAll)}
      </MergeAllButton>
      <MinFlexRow height="100%" gap="26px">
        <LeftFlexColumn gap="20px" flex="1 1 auto" overflow="auto">
          {mergedVideos.slice(0, 1).map((mergedVideo: Video) => (
            <H1_FlexRow
              height="126px"
              key={mergedVideo.id}
              align="center"
              gap="27px"
              justify="space-between"
            >
              <H1_FlexRow align="center" gap="17px">
                <BorderFlexRow
                  overflow="hidden"
                  position="relative"
                  height="126px"
                  width="calc(126px * 16 / 9)"
                  justify="center"
                  align="center"
                  $background={
                    isVideoProcessing || mergedVideo.status === VideoStatusEnum.Processing
                      ? theme.blue2
                      : theme.gray1
                  }
                >
                  <ConditionalRender
                    condition={
                      isVideoProcessing || mergedVideo.status === VideoStatusEnum.Processing
                    }
                  >
                    <H1_FlexRow padding="15px">
                      <Progress
                        width={80}
                        type="circle"
                        trailColor={theme.blue1}
                        strokeColor={theme.blue4}
                        percent={currentCreatedMergedVideo?.progress || mergedVideo.progress || 0}
                        showInfo
                      />
                    </H1_FlexRow>
                  </ConditionalRender>
                  <ConditionalRender
                    condition={
                      !isVideoProcessing &&
                      (mergedVideo.status === VideoStatusEnum.Ready ||
                        mergedVideo.status === VideoStatusEnum.Published)
                    }
                  >
                    <MuxPlayer reqId={mergedVideo.req_id} />
                    <ConditionalRender condition={!!mergedVideo.duration}>
                      <DurationRow position="absolute" height="18px" padding="0 4px">
                        <H1_TextXs color="white">
                          {durationToMMSS(mergedVideo.duration as number)}
                        </H1_TextXs>
                      </DurationRow>
                    </ConditionalRender>
                  </ConditionalRender>
                </BorderFlexRow>
                <H1_TextSmall ellipsis>{mergedVideo.title}</H1_TextSmall>
              </H1_FlexRow>
              <ConditionalRender
                condition={
                  !isVideoProcessing &&
                  (mergedVideo.status === VideoStatusEnum.Ready ||
                    mergedVideo.status === VideoStatusEnum.Published)
                }
              >
                <H1_FlexRow gap="20px" align="center">
                  <Button
                    radius="full"
                    variant="flat"
                    isIconOnly
                    startContent={<H1_Icon icon="fas fa-play" isCursorPointer />}
                    onClick={() => onClickPlayVideo(mergedVideo?.req_id as string)}
                  />
                  <ShareVideoMenu
                    isNextUI
                    withText={false}
                    withDownload
                    withCopyLink
                    video={mergedVideo}
                    source="playlist_merged_video"
                    icon={<i className="far fa-ellipsis-vertical" />}
                  />
                </H1_FlexRow>
              </ConditionalRender>
            </H1_FlexRow>
          ))}
          <DndContext
            autoScroll={false}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={currentPlaylist?.draft_ids as string[]}
              strategy={verticalListSortingStrategy}
            >
              <AnimatePresence>
                {(currentPlaylist?.draft_ids as string[]).map((currentDraftId: string, index) => (
                  <motion.div
                    key={currentDraftId}
                    layout="position"
                    initial={{ scale: 0.5 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    transition={{
                      default: {
                        duration: 0.5
                      }
                    }}
                  >
                    <BackgroundFlexRow
                      padding="10px"
                      width="100%"
                      height="82px"
                      position="relative"
                      gap="15px"
                      $isSelected={selectedDraftId === currentDraftId}
                      onClick={() => setSelectedDraftId(currentDraftId)}
                      onMouseEnter={() => setHoverId(currentDraftId)}
                      onMouseLeave={() => setHoverId(undefined)}
                      overflow="auto"
                    >
                      <VideoSortableItem
                        disabled={isUpdatePlaylistLoading}
                        isVisible={hoverId === currentDraftId}
                        idx={index + 1}
                        id={currentDraftId as string}
                      />
                      <VideoContainer
                        width="100%"
                        position="relative"
                        $dragging={currentDraftId === draggingDraft?.id}
                      >
                        <ConditionalRender condition={draggingDraft && index < draggingDraft.index}>
                          <VideoSeparator $overVideo={overId === currentDraftId} />
                        </ConditionalRender>
                        <PlaylistVideoElement
                          draftId={currentDraftId as string}
                          onRemoveVideoFromPlaylist={onRemoveVideoFromPlaylist}
                        />
                        <ConditionalRender condition={draggingDraft && index > draggingDraft.index}>
                          <VideoSeparator $isBottom $overVideo={overId === currentDraftId} />
                        </ConditionalRender>
                      </VideoContainer>
                    </BackgroundFlexRow>
                  </motion.div>
                ))}
              </AnimatePresence>
            </SortableContext>
            <DragOverlay>
              {draggingDraft && (
                <SortableContainer key={draggingDraft?.id} elementId={draggingDraft?.id as string}>
                  <DraggableFlexRow
                    height="100%"
                    padding="0 0 0 0"
                    position="relative"
                    gap="15px"
                    justify="center"
                    width="871px"
                  >
                    <VideoSortableItem
                      isDragging
                      isVisible
                      idx={draggingDraft?.index + 1}
                      id={draggingDraft?.id}
                    />
                    <PlaylistVideoElement draftId={draggingDraft?.draft?.id as string} />
                  </DraggableFlexRow>
                </SortableContainer>
              )}
            </DragOverlay>
          </DndContext>
        </LeftFlexColumn>
        <H1_FlexColumn height="100%" flex="0 0 590px">
          <ConditionalRender condition={!!selectedDraft}>
            <VideoPreview overflow="hidden" width="100%" justify="center" align="center">
              <MuxPlayer reqId={selectedDraft?.last_video?.req_id as string} />
            </VideoPreview>
          </ConditionalRender>
          <ConditionalRender condition={isScenesLoading}>
            <CircleLoader />
          </ConditionalRender>
          <ConditionalRender condition={scenes.length > 0 && !isScenesLoading}>
            <H1_FlexColumn gap="20px" margin="20px 0 0 0" overflow="auto">
              {scenes.map((scene, index) => (
                <H1_FlexColumn gap="10px" key={scene.id} flex="0 0 auto">
                  <H1_TextSmall>
                    {index + 1}. {scene.name}
                  </H1_TextSmall>
                  <H1_TextXs whiteSpace="normal">
                    {scene.attributes.text?.transcript?.text}
                  </H1_TextXs>
                </H1_FlexColumn>
              ))}
            </H1_FlexColumn>
          </ConditionalRender>
        </H1_FlexColumn>
      </MinFlexRow>
    </H1_FlexColumn>
  );
};

export default SinglePlaylistPage;
