import { useAppDispatch } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import { Video } from "app/types";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { paymentModalMessages } from "app/pages/pricing/messages";
import { useIntl } from "react-intl";

const useEmbed = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const showPaymentModal = useUpgradeModal();
  const upgradeText = formatMessage(paymentModalMessages.upgradeEmbedTitle);

  const embedCode = (source: string, video: Video, backModal?: ModalName) => {
    dispatch(googleEvents.embedIntent({ source }));
    const reqId = video.req_id;
    if (
      showPaymentModal({
        source,
        reqId,
        upgradeText,
        backModal,
        requestedFeature: PlanFeature.EmbedVideo
      })
    ) {
      return;
    }
    openModal(
      ModalName.embeddedVideo,
      { reqId: video.req_id, videoId: video.id, source },
      backModal
    );
  };

  return embedCode;
};

export default useEmbed;
