import React from "react";
import {
  IMessage,
  IUserPreferenceSettings,
  NotificationBell,
  NovuProvider,
  PopoverNotificationCenter
} from "@novu/notification-center";
import { useAuth } from "app/auth/useAuth";
import { useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "app/types";
import Config from "app/config/Config";
import { useNavigate } from "react-router-dom";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";

const StyleFlexRow = styled(H1_FlexRow)`
  .nc-bell-button > svg {
    color: ${({ theme }) => theme.gray8};
  }
`;
const NovuNotificationCenter = () => {
  const { user } = useAuth();
  const flags = useFlags();
  const navigate = useNavigate();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));

  if (!user || !user.sub || !currentWorkspace?.id) {
    return <></>;
  }

  if (!flags[FeatureFlag.notificationInbox]) {
    return <></>;
  }
  const preferenceFilter = (userPreferences: IUserPreferenceSettings) => {
    return !userPreferences?.template?.tags?.includes("don-not-show-tag");
  };
  const handleOnNotificationClick = (message: IMessage) => {
    if (message?.cta?.data?.url) {
      navigate(message.cta.data.url);
    }
  };
  return (
    <StyleFlexRow justify="center" align="center" width="41px" height="41px">
      <NovuProvider
        subscriberId={`${currentWorkspace?.id}_${user?.sub}`}
        applicationIdentifier={Config.novuPublicKeyIdentifier}
        socketUrl={Config.novuSocketUrl}
        backendUrl={Config.novuBackendUrl}
      >
        <PopoverNotificationCenter
          showUserPreferences={true}
          colorScheme={"light"}
          position="top-start"
          offset={5}
          preferenceFilter={preferenceFilter}
          onNotificationClick={handleOnNotificationClick}
        >
          {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
        </PopoverNotificationCenter>
      </NovuProvider>
    </StyleFlexRow>
  );
};

export default NovuNotificationCenter;
