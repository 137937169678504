import React from "react";
import styled, { useTheme } from "styled-components";
import { Progress } from "antd";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextSmall,
  H1_TextSubBanner,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";

const PlayerContainer = styled(H1_FlexColumn)<{ $showBackground: boolean }>`
  border-radius: 3px;
  padding: ${({ $showBackground }) => ($showBackground ? "20px" : "unset")};
  background: ${({ $showBackground }) => ($showBackground ? "#27272A" : "unset")};
  color: white;
  > div:not(.ant-progress) {
    height: 100%;
    > div {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
  &&& mux-player {
    height: 100%;
  }
`;

interface VideoPlayerProps {
  progress?: number;
  loading?: boolean;
  playbackId?: string;
}
const VideoPlayer = ({ playbackId, loading, progress }: VideoPlayerProps) => {
  const theme = useTheme();
  return (
    <PlayerContainer
      height="100%"
      width="100%"
      justify={"center"}
      align={"center"}
      $showBackground={!playbackId}
    >
      <ConditionalRender condition={loading}>
        <H1_FlexColumn flex={"1"} justify={"center"} align={"center"}>
          <H1_TextSubBanner fontWeight={700} margin="0 0 13px 0" color={theme.gray5}>
            {progress}%
          </H1_TextSubBanner>
          <H1_TextSmall color={theme.gray5}>Rendering your video</H1_TextSmall>
          <H1_TextXs color={theme.gray5}>Hang in there, this may take a few minutes</H1_TextXs>
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={!!playbackId}>
        <MuxPlayer shape={"landscape"} playbackId={playbackId} />
      </ConditionalRender>
      <ConditionalRender condition={loading}>
        <Progress percent={progress} showInfo={false} />
      </ConditionalRender>
    </PlayerContainer>
  );
};

export default VideoPlayer;
