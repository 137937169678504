import styled, { useTheme } from "styled-components";
import { Button, Image, Progress, Table, TablePaginationConfig, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AlignType } from "rc-table/lib/interface";
import {
  DEFAULT_TABLE_PAGE_SIZE,
  Draft,
  DraftType,
  ReviewStatus,
  VideoStatusEnum
} from "app/types";
import React, { useContext, useMemo } from "react";
import messages from "app/components/projects-managment/DraftsView/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import {
  dateSorter,
  durationToMMSS,
  getFormattedDate,
  getTimeAgo,
  hasSomeParentTheClass
} from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import DropdownProjectMetaEdit from "app/components/common/DropdownProjectMetaEdit";
import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import { videosEntities } from "app/store/selectorsV2/videos.selectors";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import EmptyState from "app/components/common/EmptyState";
import ReviewStatusButton from "app/components/common/ReviewStatus/ReviewStatusButton";
import AvatarInitials from "app/components/common/AvatarInitials";
import { draftsActions } from "app/store/slices/drafts.slice";
import { HeaderContext } from "app/hoc/HeaderContext";
import { ThemeMode } from "app/utils/theme";

const StyledTable = styled(Table)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  margin-top: 0.3125rem;
  .ant-table {
    background-color: ${(props) =>
      props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
  }
  && .ant-table-thead > tr > th {
    border-bottom: 1px solid
      ${(props) => (props.theme.mode === ThemeMode.Light ? props.theme.gray5 : "transparent")};
    padding-left: 5px;
    position: sticky;
    top: 0;
    background-color: ${(props) =>
      props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
    z-index: 11;
  }
  .ant-table table {
    border-spacing: 0 0.625rem;
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: ${(props) => props.theme.gray3};
    }
  }
  .ant-checkbox {
    border-spacing: 0;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0.5625rem 0.5rem;
    &.ant-table-cell-row-hover {
      background-color: ${(props) => props.theme.gray2};
    }
  }
  & .ant-table-column-sorters {
    flex-direction: row-reverse;
    gap: 0.3125rem;
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
        transition: all 0.5s ease-in-out;
        background-color: ${(props) =>
          props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
        height: 5.3125rem;
      }
      &:hover {
        td {
          background-color: ${(props) =>
            props.theme.gray3} !important; /* &&& doesn't work with &:hover */
          ${NameText} {
            text-decoration: underline;
            font-weight: 400;
          }
        }
      }
      padding: 0.625rem 1rem 0.625rem;
      &:not(:last-child) td {
        border-bottom: 1px solid
          ${(props) =>
            props.theme.mode === ThemeMode.Light ? props.theme.gray4 : props.theme.gray2};
      }
      &:last-child td {
        border-bottom: 1px solid
          ${(props) =>
            props.theme.mode === ThemeMode.Light ? props.theme.gray4 : props.theme.gray12};
      }
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

const StyledProgress = styled(Progress)`
  width: 6.25rem;
  .ant-progress-inner {
    border: 1px solid ${({ theme }) => theme.blue2};
  }
`;

const PlayButton = styled(Button)`
  &&,
  &&:focus {
    flex: 0 0 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 2.5rem;
    border: 1px solid ${({ theme }) => theme.blue1};
    background-color: ${({ theme }) => theme.blue1};
    i {
      color: #27272a;
    }
  }
  &&:hover {
    background-color: ${({ theme }) => theme.blue2};
    border: 1px solid ${({ theme }) => theme.blue2};
  }
  &&:active {
    background-color: ${({ theme }) => theme.blue3};
    border: 1px solid ${({ theme }) => theme.blue3};
  }
`;

const StyledIcon = styled.i`
  font-size: 0.625rem;
`;

const StyledNameText = styled(NameText)`
  font-weight: 400;
`;

const ImageContainer = styled.div`
  height: 4.0625rem;
  width: 7.25rem;
  img {
    border: 1px solid ${(props) => props.theme.gray3};
    object-fit: contain;
    border-radius: 8px;
    overflow: hidden;
  }
`;

const DraftsTable = ({
  type,
  withMultipleSelectCheckbox = false,
  pagination = false,
  hideMoveFolder,
  hideEmptyState = false,
  hidePlayColumn = false,
  hideOptions = false,
  clickable = true,
  onCreateNewVideo
}: {
  pagination?: false | TablePaginationConfig;
  type?: DraftType;
  withMultipleSelectCheckbox?: boolean;
  hideMoveFolder?: boolean;
  hideEmptyState?: boolean;
  hidePlayColumn?: boolean;
  clickable?: boolean;
  hideOptions?: boolean;
  onCreateNewVideo?: () => void;
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const { searchValue } = useContext(HeaderContext);
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const { filteredDrafts: drafts } = useAppSelector((state) =>
    draftsSelectors.getDraftsByPageAndQuery(state, undefined, searchValue, undefined, type)
  );
  const selectedDraftIds = useAppSelector((state) => state.drafts.selectedDraftIds);
  const videosMap = useAppSelector((state) => videosEntities(state));
  // Todo - Remove once server implementation is made
  const totalDrafts = drafts.length;
  const withPagination = totalDrafts > DEFAULT_TABLE_PAGE_SIZE;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    dispatch(draftsActions.setSelectedDraftIds(newSelectedRowKeys));
  };

  const rowSelection = {
    selectedRowKeys: selectedDraftIds,
    onChange: onSelectChange
  };

  const playVideo = (e: React.MouseEvent, draft: Draft) => {
    e.stopPropagation();
    dispatch(analyticsEvents.videoPlay({ source: "recentVideos", videoId: draft.last_video?.id }));
    openModal(ModalName.videoReady, { reqId: draft.last_video?.req_id });
  };

  const onSelectDraft = (draftId: string) => {
    if (clickable) {
      dispatch(analyticsEvents.editDraft({ source: "recentVideos", draftId }));
      dispatch(analyticsEvents.navigateToEditor({ source: "draft_table_item" }));
      navigate(`/editor/${draftId}`);
    }
  };

  const columns: ColumnsType<Draft> = useMemo(
    () => [
      {
        title: formatMessage(messages.tableColTitleTitle),
        dataIndex: "title",
        key: "title",
        render: (_: unknown, row: Draft) => {
          return (
            <H1_FlexRow gap="1rem" align="center">
              <ImageContainer>
                <Image
                  width="7rem"
                  height="3.9375rem"
                  src={row.image || fallbackPlaceholder}
                  preview={false}
                  fallback={fallbackPlaceholder}
                />
              </ImageContainer>
              <StyledNameText $maxWidth="22.5rem" ellipsis={{ tooltip: row.title }}>
                {row.title}
              </StyledNameText>
            </H1_FlexRow>
          );
        },
        sorter: (a: Draft, b: Draft) => (a.title as string).localeCompare(b.title as string)
      },
      {
        title: formatMessage(messages.tableColOwnerTitle),
        dataIndex: "owner_picture",
        key: "owner_picture",
        width: "6.25rem",
        render: (editedAt: string, row: Draft) => {
          return (
            <H1_FlexColumn width="2.5rem" height="2.5rem">
              <Tooltip title={row.owner_email}>
                <>
                  <AvatarInitials src={row.owner_picture} text={row.owner_email || ""} />
                </>
              </Tooltip>
            </H1_FlexColumn>
          );
        },
        sorter: (a: Draft, b: Draft) =>
          (a.owner_picture as string)?.localeCompare(b.owner_picture as string)
      },
      {
        title: formatMessage(messages.tableColUpdatedTitle),
        dataIndex: "updated_at",
        key: "updated_at",
        width: "9.375rem",
        render: (editedAt: string, row: Draft) => {
          const video = videosMap[row.last_video?.id as string];
          const progress = video?.progress;
          const isProgressDisplayed = !!(progress && progress > 0 && progress < 100);
          return (
            <H1_FlexColumn>
              <H1_TextSmall>{editedAt && getTimeAgo(editedAt)}</H1_TextSmall>
              <ConditionalRender condition={isProgressDisplayed}>
                <Tooltip title={`${progress}%`}>
                  <StyledProgress
                    strokeColor={theme.blue2}
                    strokeWidth={8}
                    trailColor={theme.gray1}
                    strokeLinecap="square"
                    percent={progress}
                    showInfo={false}
                    width={75}
                  />
                </Tooltip>
              </ConditionalRender>
            </H1_FlexColumn>
          );
        },
        sorter: (a: Draft, b: Draft) => dateSorter(a.updated_at as string, b.updated_at as string)
      },
      {
        title: formatMessage(messages.tableColDurationTitle),
        dataIndex: "lastVideo",
        hidden: drafts.length === 0,
        width: "6.25rem",
        key: "lastVideo",
        render: (_: unknown, row: Draft) => {
          const duration = row.last_video?.duration;
          if (!duration) {
            return <></>;
          }
          const time = durationToMMSS(duration);
          return <H1_TextSmall>{time}</H1_TextSmall>;
        }
      },
      {
        title: formatMessage(messages.tableColCreatedTitle),
        dataIndex: "created_at",
        key: "created_at",
        width: "7rem",
        render: (createdDate: string) => {
          return <H1_TextSmall>{getFormattedDate(createdDate)}</H1_TextSmall>;
        }
      },
      {
        title: formatMessage(messages.tableColReviewStatusTitle),
        dataIndex: "reviewStatus",
        hidden: drafts.length === 0,
        width: "6rem",
        key: "reviewStatus",
        filters: [
          { text: formatMessage(messages.tableColReviewStatusDraft), value: ReviewStatus.Draft },
          {
            text: formatMessage(messages.tableColReviewStatusInReview),
            value: ReviewStatus.InReview
          },
          {
            text: formatMessage(messages.tableColReviewStatusPending),
            value: ReviewStatus.Pending
          },
          {
            text: formatMessage(messages.tableColReviewStatusApproved),
            value: ReviewStatus.Approved
          },
          {
            text: formatMessage(messages.tableColReviewStatusRejected),
            value: ReviewStatus.Rejected
          }
        ],
        onFilter: (value: number | string | boolean, record: Draft) =>
          record.review_status === value,
        render: (_: unknown, row: Draft) => {
          return <ReviewStatusButton draftId={row.id as string} />;
        }
      },
      {
        dataIndex: "",
        align: "center" as AlignType,
        key: "playVideo",
        width: "4.6875rem",
        hidden: drafts.length === 0 || hidePlayColumn,
        filters: [
          {
            text: formatMessage(messages.readyVideos),
            value: formatMessage(messages.readyVideos)
          }
        ],
        onFilter: (_: unknown, row: Draft) => {
          return (
            row.last_video?.status === VideoStatusEnum.Published ||
            row.last_video?.status === VideoStatusEnum.Ready
          );
        },
        render: (_: unknown, row: Draft) => {
          return (
            <ConditionalRender
              condition={[VideoStatusEnum.Published, VideoStatusEnum.Ready].includes(
                row.last_video?.status as VideoStatusEnum
              )}
            >
              <H1_FlexRow justify="center" align="center">
                <PlayButton
                  size="large"
                  shape="round"
                  onClick={(e) => playVideo(e, row)}
                  icon={<StyledIcon className="fas fa-play" />}
                />
              </H1_FlexRow>
            </ConditionalRender>
          );
        }
      },
      {
        hidden: hideOptions,
        dataIndex: "",
        key: "x",
        className: "actions",
        width: "4.375rem",
        render: (_: unknown, row: Draft) => {
          const isVideoReady =
            row.last_video?.status === VideoStatusEnum.Published ||
            row.last_video?.status === VideoStatusEnum.Ready;
          return (
            <DropdownProjectMetaEdit
              draft={row}
              onRenameDraft={() => {
                openModal(ModalName.renameDraft, { draftId: row.id });
              }}
              showCopyLink={isVideoReady}
              showDownload={isVideoReady}
              showEmbed={isVideoReady}
              showDownloadSubtitles={isVideoReady}
              onOpenDraft={() => onSelectDraft(row?.id as string)}
              withMoveToFolder={!hideMoveFolder}
            />
          );
        }
      }
    ],
    [drafts, videosMap, theme.mode]
  ).filter((item) => !item.hidden);
  return (
    <>
      <ConditionalRender condition={drafts.length === 0 && !hideEmptyState}>
        <EmptyState
          text={formatMessage(messages.emptyFolderText)}
          description={formatMessage(messages.emptyFolderDescription)}
          withButton
          buttonText={formatMessage(messages.emptyFolderButtonText)}
          icon="fal fa-folder"
          onClickButton={onCreateNewVideo}
        />
      </ConditionalRender>
      <ConditionalRender condition={drafts.length > 0}>
        <StyledTable
          rowSelection={
            withMultipleSelectCheckbox
              ? {
                  type: "checkbox",
                  ...rowSelection
                }
              : undefined
          }
          size="middle"
          columns={columns}
          rowKey="id"
          pagination={withPagination ? pagination : false}
          dataSource={drafts}
          onRow={(record: Draft): React.HTMLAttributes<HTMLElement> => ({
            onClick: (event: React.MouseEvent<HTMLElement>) => {
              if (
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "ant-table-selection-column") &&
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "draft-actions-menu") &&
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "ant-dropdown-trigger") &&
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "ant-dropdown")
              ) {
                onSelectDraft(record.id as string);
              }
            }
          })}
        />
      </ConditionalRender>
    </>
  );
};

export default DraftsTable;
