import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuth } from "app/auth/useAuth";
import { isMobile } from "react-device-detect";
import "app/components/authentication/LoginFooter.scss";
import styled, { css } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { SMALL_SCREEN_PX } from "app/config/Constants";

const messages = defineMessages({
  loginButton: {
    id: "login-footer.button.login.title",
    defaultMessage: "Login"
  },
  joinUsButton: {
    id: "login-footer.button.register.title",
    defaultMessage: "Join Now"
  },
  explainText: {
    id: "login-footer.text",
    defaultMessage: "Hour One is AI driven video creation platform, we give your content character."
  }
});

const CloseButton = styled(Button)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const LoginFooterContainer = styled(H1_FlexRow)<{ $hide: boolean; $isMobile: boolean }>`
  ${(props) =>
    props.$isMobile &&
    css`
      padding-right: 50px;
      .login-footer-content {
        flex-direction: column;
      }
    `};
  ${(props) =>
    props.$hide &&
    css`
      display: none;
      width: 0 !important;
      height: 0 !important;
      min-width: 0 !important;
      min-height: 0 !important;
    `};
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  height: 90px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    height: auto;
  }
  border: 1px solid ${(props) => props.theme.pink4};
  border-bottom: none;
  text-align: center;
  background-color: ${(props) => props.theme.gray1};
  padding: 0 calc((100% - var(--inner-content-max-width)) / 2);
`;

const LoginFooter = ({ referral = "" }: { referral: string }) => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    setDisplay(!isAuthenticated);
  }, []);

  return (
    <LoginFooterContainer $hide={!display} $isMobile={isMobile} justify="center" align="center">
      <H1_FlexColumn className="login-footer-content" padding="10px" gap="10px">
        <H1_FlexRow align="center">
          <CloseButton
            type="text"
            icon={<i className="fas fa-times-circle" />}
            onClick={() => setDisplay(!display)}
          />
          {intl.formatMessage(messages.explainText) !== " " && (
            <H1_TextSmall lineHeight="20px" whiteSpace="break-spaces" fontSize="13px">
              {intl.formatMessage(messages.explainText)}
            </H1_TextSmall>
          )}
        </H1_FlexRow>
        <H1_FlexRow className="actions" gap="10px" justify="center">
          <Button type="primary" onClick={() => navigate(`/?init=signUp&ref=${referral}`)}>
            {intl.formatMessage(messages.joinUsButton)}
          </Button>
          <Button onClick={() => navigate("/")}>{intl.formatMessage(messages.loginButton)}</Button>
        </H1_FlexRow>
      </H1_FlexColumn>
    </LoginFooterContainer>
  );
};

export default LoginFooter;
