import { useAppDispatch, useAppSelector } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { SocialMedia, VideoStatusEnum } from "app/types";
import { useEffect, useState } from "react";
import { fetchingStatus } from "app/utils/helpers";
import { playgroundActions } from "app/store/slices/playground.slice";
import useShare from "app/hooks/useShare";

interface VideoData {
  video: { req_id: string; id: string; status: string };
  socialMedia: SocialMedia;
  source: string;
}
const usePlaygroundShareSocialMedia = () => {
  const [selectedVideoData, setSelectedVideoData] = useState<VideoData | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { shareSocialMedia } = useShare();
  const playgroundShareVideoStatus = useAppSelector((state) => state.playground.shareVideoStatus);

  useEffect(() => {
    if (selectedVideoData && playgroundShareVideoStatus) {
      if (playgroundShareVideoStatus === fetchingStatus.succeeded) {
        shareSocialMedia(
          selectedVideoData.socialMedia as SocialMedia,
          selectedVideoData.video,
          selectedVideoData.source as string,
          true
        );
        dispatch(playgroundActions.updateShareStatusToIdle());
        setSelectedVideoData(undefined);
      } else if (playgroundShareVideoStatus === fetchingStatus.failed) {
        dispatch(playgroundActions.updateShareStatusToIdle());
        setSelectedVideoData(undefined);
      }
    }
  }, [playgroundShareVideoStatus]);

  const shareLink = (
    source: string,
    video: { req_id: string; id: string; status: string },
    socialMedia: SocialMedia
  ) => {
    dispatch(googleEvents.shareSocialMediaIntent({ source }));
    if (video.status !== VideoStatusEnum.Published) {
      setSelectedVideoData({ video, socialMedia, source });
      dispatch(
        playgroundActions.playgroundShareVideoRequest({
          videoId: video.id
        })
      );
    } else {
      shareSocialMedia(socialMedia, video, source, false);
      setSelectedVideoData(undefined);
    }
  };

  return { shareLink, shareSocialMedia };
};

export default usePlaygroundShareSocialMedia;
