import { ReactNode } from "react";
import AuthWorkosProvider from "app/auth/AuthWorkosProvider";

interface AuthSwitcherProps {
  children: ReactNode;
}
const AuthSwitcher = ({ children }: AuthSwitcherProps) => {
  return <AuthWorkosProvider>{children}</AuthWorkosProvider>;
  //
  // return (
  //   <AuthProvider
  //     domain={Config.auth0.domain}
  //     clientId={Config.auth0.clientId}
  //     audience={Config.auth0.apiUrl}
  //     logoutUri={window.location.origin}
  //     redirectUri={window.location.origin}
  //     scope={Config.auth0.scope}
  //   >
  //     {children}
  //   </AuthProvider>
  // );
};

export default AuthSwitcher;
