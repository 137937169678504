import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import { addCharacterMessages } from "app/components/digitalAvatar/messages";

import { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { useAppDispatch } from "app/hooks";
import { bookDemoUrl } from "app/utils/urls";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";

import { useNavigate } from "react-router-dom";
import Config from "app/config/Config";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag, Location } from "app/types";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import { useCloneVoiceModal } from "app/hooks/useCloneVoiceModal";
import { useTheme } from "styled-components";

const useVirtualCharacterOptions = () => {
  const intl = useIntl();
  const { closeModal } = useModal();
  const navigate = useNavigate();
  const flags = useFlags();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const featureDisabledForPlan = useShowCrown({ requestedFeature: PlanFeature.createAvatar });
  const openVoiceModal = useCloneVoiceModal();

  const onWebcamClick = () => {
    dispatch(analyticsEvents.selectedAvatarCreationType({ type: "webcam" }));
    if (!featureDisabledForPlan) {
      navigate(Location.CreateTwin);
      closeModal();
    } else {
      const source = `create_avatar_modal_plan`;
      dispatch(analyticsEvents.pricingMeetingRequest({ source }));
      window.open(bookDemoUrl(source), "_blank");
    }
  };

  const onStudioClick = () => {
    const source = `create_avatar_modal_studio`;
    dispatch(analyticsEvents.selectedAvatarCreationType({ type: "studio" }));
    dispatch(analyticsEvents.pricingMeetingRequest({ source }));
    window.open(bookDemoUrl(source), "_blank");
  };

  const onCloneVoiceClick = () => {
    openVoiceModal("create_avatar", ModalName.createVirtualTwin);
    dispatch(analyticsEvents.selectedAvatarCreationType({ type: "voice" }));
  };

  const virtualCharacterOptions = [
    {
      title: intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionStudioTitle),
      tag: intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionStudioTag),
      tagColor: theme.green2,
      iconName: "fa-solid fa-camera-movie",
      onClick: onStudioClick,
      features: [
        intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionStudioFeature1),
        intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionStudioFeature2),
        intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionStudioFeature3)
      ]
    }
  ];

  if (flags[FeatureFlag.avatarFromWebcam] || Config.env === "local") {
    virtualCharacterOptions.push({
      title: intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionWebcamTitle),
      // @ts-ignore: can eccept elm
      tag: featureDisabledForPlan ? (
        <>
          <CrownIcon /> +
          {intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionWebcamTag)}
        </>
      ) : (
        intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionWebcamTag)
      ),
      tagColor: theme.blue1,
      iconName: "fa-solid fa-camera-web",
      onClick: onWebcamClick,
      features: [
        intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionWebcamFeature1),
        intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionWebcamFeature2),
        intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionWebcamFeature3)
      ]
    });
  }

  virtualCharacterOptions.push({
    title: intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionVoiceCloneTitle),
    tag: intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionVoiceCloneTag),
    tagColor: theme.gray6,
    iconName: "fa-solid fa-microphone",
    onClick: onCloneVoiceClick,
    features: [
      intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionVoiceCloneFeature1),
      intl.formatMessage(addCharacterMessages.virtualTwinCreationModalOptionVoiceCloneFeature2)
    ]
  });

  return { virtualCharacterOptions };
};

export default useVirtualCharacterOptions;
