import { useIntl } from "react-intl";
import { fetchingStatus } from "app/utils/helpers";
import messages from "app/components/projects-managment/DraftsView/messages";
import "app/components/projects-managment/projectsManagment.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";
import { FetchStatus, Location } from "app/types";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Tooltip } from "antd";
import DraftsGrid from "app/components/common/DraftsAndFolders/DraftsGrid";
import DraftsTable from "app/components/common/DraftsAndFolders/DraftsTable";
import { userActions } from "app/store/slices/user.slice";
import useSmallScreen from "app/hooks/useSmallScreen";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { useNavigate } from "react-router-dom";
import DraftsViewMode from "app/components/DraftsViewMode";
import { DraftsTableSkeleton } from "app/pages/HomePage/HomePageSkeletonLoading";
import { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { ThemeMode } from "app/utils/theme";

const DraftsView = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isSmallScreen } = useSmallScreen();
  const preferences = useAppSelector((state) => state.user.preferences);
  const draftsStatus: FetchStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const isLoading = draftsStatus === fetchingStatus.loading;
  const isGrid = preferences.draft_view_grid || isSmallScreen;

  const onClickTable = () => {
    dispatch(userActions.updateUserPreferencesRequest({ draft_view_grid: true }));
  };

  const onClickGrid = () => {
    dispatch(userActions.updateUserPreferencesRequest({ draft_view_grid: false }));
  };

  const sendAnalyticsEmptyFolder = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "empty-folder",
        type: NewVideoType.video
      })
    );
  };
  const onCreateNewVideo = () => {
    sendAnalyticsEmptyFolder();
    navigate(Location.Templates);
  };
  if (isLoading) {
    return <DraftsTableSkeleton />;
  }
  return (
    <H1_FlexColumn padding="0 3.125rem 1.875rem" flex="1 0 auto" width="100%">
      <H1_FlexRow justify="space-between" align="center" flex="0 0 2.375rem">
        <H1_TextMiddle
          fontWeight={500}
          color={theme.mode === ThemeMode.Light ? theme.gray8 : theme.gray5}
        >
          {formatMessage(messages.tableTitle)}
        </H1_TextMiddle>
        <H1_FlexRow gap="0.3125rem" align={"center"}>
          <DraftsViewMode />
          <ConditionalRender condition={isGrid && !isSmallScreen}>
            <Tooltip title={formatMessage(messages.table)}>
              <>
                <Button
                  variant="light"
                  onClick={onClickGrid}
                  startContent={<H1_Icon color={theme.gray11} icon="fa-solid fa-list" />}
                  isIconOnly
                />
              </>
            </Tooltip>
          </ConditionalRender>
          <ConditionalRender condition={!isGrid}>
            <Tooltip title={formatMessage(messages.grid)}>
              <>
                <Button
                  variant="light"
                  onClick={onClickTable}
                  startContent={<H1_Icon color={theme.gray11} icon="fa-solid fa-grid" />}
                  isIconOnly
                />
              </>
            </Tooltip>
          </ConditionalRender>
        </H1_FlexRow>
      </H1_FlexRow>
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!isLoading}>
        <ConditionalRender condition={!isGrid}>
          <DraftsTable onCreateNewVideo={onCreateNewVideo} hideMoveFolder={true} hideEmptyState />
        </ConditionalRender>
        <ConditionalRender condition={isGrid}>
          <H1_FlexRow padding="20px 0 0 0" width="100%" height="100%">
            <DraftsGrid
              source="recentVideos"
              hideMoveFolder={true}
              limit={10}
              onCreateNewVideo={onCreateNewVideo}
            />
          </H1_FlexRow>
        </ConditionalRender>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default DraftsView;
