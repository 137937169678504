import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { digitalTwinCreationMessages } from "app/pages/createDigitalTwinPage/messages";
import { Tag } from "antd";
import { useMemo } from "react";
import { useEffect } from "react";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { VirtualTwinSteps } from "app/types/virtualTwin";
import { Button } from "@nextui-org/react";
interface InstructionsStepProps {
  onFinish: () => void;
}

const StyledTag = styled(Tag)<{ $color: string }>`
  border-radius: 10px;
  color: ${({ $color }) => $color};
  width: max-content;
  padding: 0 15px 0 7px;
  margin-bottom: 20px;
`;

const PaddingButton = styled(Button)`
  padding: 20px 40px;
  margin: 120px auto 20px;
`;

const ColoredPoint = styled.div<{ $color: string }>`
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  width: 5px;
  height: 5px;
  background-color: ${({ $color }) => $color};
`;

const InstructionsStep = ({ onFinish }: InstructionsStepProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      googleEvents.avatarWebcamSessionProgress({
        stage: VirtualTwinSteps.instructions
      })
    );
  }, []);

  const details = useMemo(
    () => [
      {
        tagColor: "#027A48",
        tagBg: "#ECFDF3",
        headline: intl.formatMessage(
          digitalTwinCreationMessages.instructionsStepRecommendedHeadline
        ),
        features: [
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepRecommendedFeature1),
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepRecommendedFeature2),
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepRecommendedFeature3),
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepRecommendedFeature4)
        ]
      },
      {
        tagColor: theme.pink3,
        tagBg: theme.pink1,
        headline: intl.formatMessage(digitalTwinCreationMessages.instructionsStepAvoidHeadline),
        features: [
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepAvoidFeature1),
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepAvoidFeature2),
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepAvoidFeature3),
          intl.formatMessage(digitalTwinCreationMessages.instructionsStepAvoidFeature4)
        ]
      }
    ],
    [intl]
  );

  return (
    <H1_FlexColumn align="center" gap="75px" justify="space-between" height="100%" padding="0 80px">
      <H1_TextMidHeadline>
        {intl.formatMessage(digitalTwinCreationMessages.avatarCreationStepInstructionsHeadline)}
      </H1_TextMidHeadline>
      <H1_FlexRow justify="space-between" width="100%" gap="20px">
        {details.map((detail, idx) => (
          <div key={detail.headline + idx + "container"}>
            <H1_FlexColumn gap="15px">
              <StyledTag color={detail.tagBg} $color={detail.tagColor}>
                <ColoredPoint $color={detail.tagColor} />
                {detail.headline}
              </StyledTag>
              {detail.features.map((feature, fIdx) => (
                <H1_FlexRow key={feature + fIdx + idx} padding="0 0 0 8px" align="center">
                  <ColoredPoint $color={detail.tagColor} />
                  {feature}
                </H1_FlexRow>
              ))}
            </H1_FlexColumn>
          </div>
        ))}
      </H1_FlexRow>
      <PaddingButton color="primary" onClick={onFinish}>
        {intl.formatMessage(digitalTwinCreationMessages.startButtonLabel)}
      </PaddingButton>
    </H1_FlexColumn>
  );
};
export default InstructionsStep;
