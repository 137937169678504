import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const VideoPlayerContainer = styled(H1_FlexColumn)`
  max-width: 100%;
`;

const StyledVideo = styled.video<{ $aspectRatio: number; width: string; $maxHeight?: string }>`
  align-self: center;
  border-radius: 8px;
  max-height: ${({ $maxHeight }) => $maxHeight};
  max-width: 497px;
  align-self: center;
  width: ${(props) => (props.$aspectRatio > 1 ? props.width : "100%")};
  height: ${(props) =>
    props.width === "100%" ? (props.$aspectRatio > 1 ? "100%" : "auto") : "fit-content"};
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.$aspectRatio === 0 ? 0 : 1)};
  position: ${(props) => (props.$aspectRatio === 0 ? "absolute" : "static")};
`;

const LoaderFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) =>
    theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray12};
  z-index: 5;
  top: 0;
  left: 0;
`;

const ControlButton = styled(Button)`
  i {
    font-size: 22px;
  }
`;

const ProgressBarContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray4};
`;
const ProgressBarTrack = styled(H1_FlexRow)<{ $progress: number }>`
  background-color: ${({ theme }) => theme.gray7};
  width: ${({ $progress }) => $progress}%;
`;

const FontAwesomeIcon = styled.i`
  font-size: 28px;
  color: ${({ theme }) => theme.gray7};
`;

interface VideoPlayerWithControlsProps {
  width?: string;
  height?: string;
  maxHeight?: string;
  src: string;
  currentTime: number;
  duration: number;
  reset: () => void;
  isPlaying: boolean;
  isMuted: boolean;
  handleMuteUnmute: () => void;
  handlePlayPause: () => void;
  videoLoaded: boolean;
  aspectRatio: number;
  image?: string;
  videoNode: HTMLVideoElement;
}

const VideoPlayerWithControls = React.forwardRef<HTMLVideoElement, VideoPlayerWithControlsProps>(
  (
    {
      width = "100%",
      height = "auto",
      maxHeight,
      src,
      currentTime,
      duration,
      reset,
      isPlaying,
      isMuted,
      handleMuteUnmute,
      handlePlayPause,
      videoLoaded,
      aspectRatio,
      image,
      videoNode
    }: VideoPlayerWithControlsProps,
    videoRef
  ) => {
    const theme = useTheme();
    useEffect(() => reset(), [src]);

    const handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (videoNode) {
        const progressBar = event.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const totalWidth = progressBar.offsetWidth;
        const clickPosition = event.clientX - rect.left;
        const seekTime = (clickPosition / totalWidth) * videoNode.duration;
        videoNode.currentTime = seekTime;
      }
    };

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    return (
      <VideoPlayerContainer position="relative" gap="20px" height="100%" justify="space-between">
        <StyledVideo
          src={src}
          autoPlay={isPlaying}
          ref={videoRef}
          width={width}
          muted={isMuted}
          height={!videoLoaded ? 0 : height}
          $aspectRatio={aspectRatio}
          $maxHeight={maxHeight}
          poster={image}
        />
        <ConditionalRender condition={!aspectRatio}>
          <LoaderFlexRow position="absolute" width="100%" height="100%">
            <CircleLoader />
          </LoaderFlexRow>
        </ConditionalRender>

        <H1_FlexRow gap="10px" align="center">
          <ControlButton
            size="sm"
            variant="light"
            isIconOnly
            startContent={
              <FontAwesomeIcon className={isPlaying ? "fas fa-pause" : "fas fa-play"} />
            }
            onClick={handlePlayPause}
          />
          <H1_FlexRow>
            <H1_TextXs color={theme.gray7}>
              {formatTime(currentTime)} / {formatTime(duration)}
            </H1_TextXs>
          </H1_FlexRow>
          <ProgressBarContainer flex="1" height="5px" onClick={handleProgressClick}>
            <ProgressBarTrack height="100%" $progress={(currentTime / duration) * 100} />
          </ProgressBarContainer>
          <ControlButton
            size="sm"
            variant="light"
            isIconOnly
            startContent={
              <FontAwesomeIcon className={isMuted ? "fas fa-volume-slash" : "fas fa-volume"} />
            }
            onClick={handleMuteUnmute}
          />
        </H1_FlexRow>
      </VideoPlayerContainer>
    );
  }
);

VideoPlayerWithControls.displayName = "VideoPlayerWithControls";

export default VideoPlayerWithControls;
