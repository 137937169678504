import React, { useEffect, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { videosActions } from "app/store/slices/videos.slice";
import { Audience, FetchStatus, Location, Video } from "app/types";
import videosSelectors from "app/store/selectorsV2/videos.selectors";
import styled from "styled-components";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { SwiperSlide } from "swiper/react";
import ChannelsPageSingleVideo from "app/pages/Channels/ChannelsPageSingleVideo";
import { fetchingStatus } from "app/utils/helpers";
import { useIntl } from "react-intl";
import { channelsMessages } from "app/pages/Channels/messages";
import { SingleVideoContextWrapper } from "app/components/common/Reactions/ChannelsPageSingleVideoContext";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import EmptyState from "app/components/common/EmptyState";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";

const PageContainer = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray3};
`;

const StyledNavigationSwiper = styled(NavigationSwiper)`
  height: 100%;
  > .swiper-wrapper {
    height: 100%;
  }
  > .swiper-button-prev {
    left: calc(100% - 100px);
    top: calc(100% - 120px);
    transform: rotate(90deg);
    transform-origin: left center;
    &:active {
      transform: rotate(90deg) scale(0.95);
    }
  }
  > .swiper-button-next {
    left: calc(100% - 100px);
    top: calc(100% - 60px);
    transform: rotate(90deg);
    transform-origin: left center;
    &:active {
      transform: rotate(90deg) scale(0.95);
    }
  }
`;

const ChannelsPage = () => {
  const [currentVideoId, setCurrentVideoId] = useState<string>();
  const { channelName } = useParams<{ channelName: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const isRouteCommunity = location.pathname === Location.Community;
  const readyToPlayVideos: Video[] = useAppSelector(videosSelectors.getReadyToPlayVideos);
  const videosStatus: FetchStatus = useAppSelector((state) => state.videos.videosStatus);
  const isLoading = videosStatus === fetchingStatus.loading;
  const isViewAllCurrentUserVideos = channelName === undefined;

  useEffect(() => {
    dispatch(videosActions.cleanVideos());
  }, []);

  useEffect(() => {
    if (!currentVideoId && readyToPlayVideos?.length > 0) {
      setCurrentVideoId(readyToPlayVideos[0].id);
    }
  }, [currentVideoId, readyToPlayVideos]);

  useEffect(() => {
    if (!isRouteCommunity) {
      dispatch(videosActions.cleanVideos());
      dispatch(
        videosActions.searchVideosApiRequest({
          limit: 20,
          query: isViewAllCurrentUserVideos ? "" : channelName,
          audience: isViewAllCurrentUserVideos ? Audience.private : Audience.public,
          expand: true
        })
      );
    }
  }, [channelName, location]);

  useEffect(() => {
    if (isRouteCommunity) {
      dispatch(videosActions.cleanVideos());
      dispatch(videosActions.getFeaturedVideosApiRequest());
    }
  }, [location]);

  const onVideoChange = (swiper: any) => {
    const currentIndex = swiper.activeIndex;
    setCurrentVideoId(readyToPlayVideos[currentIndex].id);
  };

  const onClickCreateVideo = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "empty-channel",
        type: NewVideoType.video
      })
    );
    navigate(Location.Templates);
  };

  return (
    <PageContainer flex="1" overflow="hidden">
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!isLoading && readyToPlayVideos?.length === 0}>
        <EmptyState
          text={formatMessage(channelsMessages.noVideosTitle)}
          description={formatMessage(channelsMessages.noVideosDescription)}
          icon="fa-solid fa-video"
          buttonText={formatMessage(channelsMessages.noVideosButtonText)}
          withButton
          onClickButton={onClickCreateVideo}
        />
      </ConditionalRender>
      <ConditionalRender condition={!isLoading && readyToPlayVideos?.length > 0}>
        <H1_FlexColumn height="100%" width="100%" justify="center" position="relative">
          <StyledNavigationSwiper
            onSlideChange={onVideoChange}
            direction="vertical"
            centeredSlides
            allowTouchMove={false}
            navigation
            slidesPerView={1.4}
            spaceBetween={100}
            speed={700}
            mousewheel
          >
            {readyToPlayVideos.map((video: Video) => (
              <SwiperSlide key={video.id}>
                <SingleVideoContextWrapper videoId={video.id}>
                  <ChannelsPageSingleVideo isCurrentVideo={currentVideoId === video.id} />
                </SingleVideoContextWrapper>
              </SwiperSlide>
            ))}
          </StyledNavigationSwiper>
        </H1_FlexColumn>
      </ConditionalRender>
    </PageContainer>
  );
};

export default ChannelsPage;
