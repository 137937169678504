import { createAsyncThunk } from "@reduxjs/toolkit";
import { IntlShape } from "react-intl";
import {
  cloneVoice,
  getVoicesList,
  previewAudio,
  setFavoritesBulk
} from "app/services/serviceV2Apis";
import { FavoriteType, Scene, Template, ThunkApi, TranscriptAudioPlayType, Voice } from "app/types";
import { thunkOptions, VALIDATION_ERROR } from "app/store/thunks/thunkCommon";
import { validateAudioPreview } from "app/components/editor/validations";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import UiActions from "app/store/actions/ui.actions";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { voicesActions } from "app/store/slices/voices.slice";

const prefix = "[Voices]";

const createAudioPreviewRequest = createAsyncThunk<
  any,
  {
    type: TranscriptAudioPlayType;
    text?: string;
    sceneId?: string;
    orderId: string;
    draftId: string;
    voiceId?: string;
    autoPlay: boolean;
  },
  ThunkApi
>(
  `${prefix} createAudioPreviewRequest`,
  async ({ type, text, sceneId, draftId, voiceId, orderId }, thunkApi) => {
    const state = thunkApi.getState();
    // @ts-ignore handels userUI store typing
    const intl = state.userUi.intl as IntlShape;
    const isScenePreview = type === "scene";
    const scenes: Scene[] = sceneId
      ? [scenesGlobalSelectors.selectById(state, sceneId) as Scene]
      : scenesGlobalSelectors.selectAll(state);
    const draft = state.drafts.currentDraft;
    const limits = getLimits(thunkApi.getState());
    const template = thunkApi.getState().templates.currentTemplate as Template;

    const { errors, sceneIdsErrors } = validateAudioPreview(draft, scenes, intl, limits, template);
    if (Object.keys(sceneIdsErrors).length) {
      thunkApi.dispatch(UiActions.setSceneErrors(sceneIdsErrors));
    }
    if (Object.keys(errors).length) {
      throw thunkApi.rejectWithValue({ key: VALIDATION_ERROR, errors });
    }
    // scene id should be the versionedId
    let res;
    if (isScenePreview && sceneId) {
      const scene = scenesGlobalSelectors.selectById(state, sceneId);
      res = await previewAudio(orderId, type, voiceId, draftId, scene?.id);
    } else {
      res = await previewAudio(orderId, type, voiceId, draftId, undefined, text);
    }
    return res.data;
  },
  thunkOptions
);
const createFullPreviewV2Request = createAsyncThunk<
  any,
  {
    orderId: string;
    draftId: string;
  },
  ThunkApi
>(
  `${prefix} createFullPreviewV2Request`,
  async ({ draftId, orderId }, thunkApi) => {
    const state = thunkApi.getState();
    // @ts-ignore handels userUI store typing
    const intl = state.userUi.intl as IntlShape;
    const scenes: Scene[] = scenesGlobalSelectors.selectAll(state);
    const draft = state.drafts.currentDraft;
    const limits = getLimits(thunkApi.getState());
    const template = thunkApi.getState().templates.currentTemplate as Template;

    const { errors, sceneIdsErrors } = validateAudioPreview(draft, scenes, intl, limits, template);
    if (Object.keys(sceneIdsErrors).length) {
      thunkApi.dispatch(UiActions.setSceneErrors(sceneIdsErrors));
    }
    if (Object.keys(errors).length) {
      throw thunkApi.rejectWithValue({ key: VALIDATION_ERROR, errors });
    }
    // scene id should be the versionedId
    const res = await previewAudio(orderId, "draft", undefined, draftId, undefined);
    return res.data;
  },
  thunkOptions
);

const getVoicesListRequest = createAsyncThunk(
  `${prefix} getVoicesListRequest`,
  async () => {
    const res = await getVoicesList();
    const result = await res.data;
    return result;
  },
  thunkOptions
);

const cloneVoiceRequest = createAsyncThunk(
  `${prefix} cloneVoiceRequest`,
  async ({
    displayName,
    url,
    orderId,
    description
  }: {
    displayName: string;
    url: string;
    orderId: string;
    description?: string;
  }) => {
    const result = await cloneVoice(displayName, url, orderId, description);
    return result;
  },
  thunkOptions
);

const setFavoritesBulkRequest = createAsyncThunk<void, Voice, ThunkApi>(
  `${prefix} setFavoritesVoiceBulkRequest`,
  async (voice: Voice, thunkApi) => {
    const favoritesVoices: Voice[] = [voice];
    const favoritesAssets = favoritesVoices.map((curr) => ({
      asset_id: curr.id,
      asset_type: FavoriteType.voice,
      active: !voice.favorite
    }));
    thunkApi.dispatch(voicesActions.setFavoritesBulk(favoritesAssets));
    await setFavoritesBulk(favoritesAssets);
  },
  thunkOptions
);
export default {
  createAudioPreviewRequest,
  getVoicesListRequest,
  cloneVoiceRequest,
  createFullPreviewV2Request,
  setFavoritesBulkRequest
};
