import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ArrowsAltOutlined } from "@ant-design/icons";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { mediaItemActionsLibraryMessages } from "app/pages/mediaLibrary/messages";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";

export const IconFlexRow = styled(H1_FlexRow)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.gray1};
  height: 26px;
  aspect-ratio: 1;
  z-index: 2;
  opacity: 0;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
  transition: 0.3s transform cubic-bezier(0.645, 0.045, 0.355, 1),
    0.3s opacity cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    transform: scale(1.1);
  }
  margin-left: 10px;
`;

const getItem = (label: React.ReactNode, key?: React.Key | null, show = false): ItemType => {
  if (!show) {
    return null;
  }
  return {
    key,
    label,
    className: "media-item-action"
  } as unknown as ItemType;
};

export const IconInPopover = styled.div`
  display: inline-flex;
  width: 100%;
`;

const IconContainer = styled(H1_FlexRow)`
  top: 9px;
  position: absolute;
  right: 5px;
  &&& .ant-popover-inner-content {
    padding: 0;
  }
`;

const MediaItemActions = ({
  onZoomItem,
  onDeleteItem,
  onEditItem,
  onDownloadItem,
  onMoveItem
}: {
  onDeleteItem?: (event: React.MouseEvent) => void;
  onZoomItem?: (event: React.MouseEvent) => void;
  onEditItem?: (event: React.MouseEvent) => void;
  onDownloadItem?: (event: React.MouseEvent) => void;
  onMoveItem?: (event: React.MouseEvent) => void;
}) => {
  const { formatMessage } = useIntl();

  const onZoomWrap = (e: React.MouseEvent) => {
    if (onZoomItem) {
      onZoomItem(e);
    }
  };

  const onEditWrap = (e: React.MouseEvent) => {
    e.stopPropagation();
    setTimeout(() => {
      if (onEditItem) {
        onEditItem(e);
      }
    }, 500); // Not sure why this number works
  };

  const onDownloadWrap = (e: React.MouseEvent) => {
    if (onDownloadItem) {
      onDownloadItem(e);
    }
  };

  const onMoveWrap = (e: React.MouseEvent) => {
    if (onMoveItem) {
      onMoveItem(e);
    }
  };

  const onDeleteWrap = (e: React.MouseEvent) => {
    if (onDeleteItem) {
      onDeleteItem(e);
    }
  };

  const items: ItemType[] = useMemo(
    () => [
      getItem(
        <IconInPopover onClick={onZoomWrap}>
          <H1_TextXs>{formatMessage(mediaItemActionsLibraryMessages.zoom)}</H1_TextXs>
        </IconInPopover>,
        "zoom",
        !!onZoomItem
      ),
      getItem(
        <IconInPopover onClick={onDownloadWrap}>
          <H1_TextXs>{formatMessage(mediaItemActionsLibraryMessages.download)}</H1_TextXs>
        </IconInPopover>,
        "download",
        !!onDownloadItem
      ),
      getItem(
        <IconInPopover onClick={onEditWrap}>
          <H1_TextXs>{formatMessage(mediaItemActionsLibraryMessages.editName)}</H1_TextXs>
        </IconInPopover>,
        "edit",
        !!onEditItem
      ),
      getItem(
        <IconInPopover onClick={onMoveWrap}>
          <H1_TextXs>{formatMessage(mediaItemActionsLibraryMessages.move)}</H1_TextXs>
        </IconInPopover>,
        "move",
        !!onMoveItem
      ),
      getItem(
        <IconInPopover onClick={onDeleteWrap}>
          <H1_TextXs>{formatMessage(mediaItemActionsLibraryMessages.delete)}</H1_TextXs>
        </IconInPopover>,
        "delete",
        !!onDeleteItem
      )
    ],
    []
  );

  return (
    <IconContainer gap="20">
      <ConditionalRender condition={!!onZoomItem}>
        <IconFlexRow onClick={onZoomItem} padding="2px" align="center" justify="center">
          <ArrowsAltOutlined />
        </IconFlexRow>
      </ConditionalRender>

      <NextDropdown
        placement="bottom-end"
        className="media-item-action-popover"
        withWhiteBackground
        items={items}
      >
        <IconFlexRow padding="2px" align="center" justify="center">
          <i className="fa-regular fa-ellipsis"></i>
        </IconFlexRow>
      </NextDropdown>
    </IconContainer>
  );
};

export default MediaItemActions;
