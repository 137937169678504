import React, { ReactNode, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Button, Divider, Row } from "antd";
import realsModalBackground from "app/assets/images/reals-modal-background.png";
import templatesIcon from "app/assets/templates/templates-icon.png";
import presentationIcon from "app/assets/templates/presentation-icon.png";
import { useIntl } from "react-intl";
import { templatesFowChoosingMessages } from "app/components/onBoarding/messages";
import useModal, { ModalName } from "app/hooks/useModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { UseCase } from "app/types";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextMiddle,
  H1_TextMidHeadline,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const StyledRow = styled(Row)`
  flex: 1;
  width: 100%;
  background-image: url("${realsModalBackground}");
  background-repeat: no-repeat;
  background-position: center;
`;

const OrStyle = styled("span")`
  margin: 22px;
`;

const OptionButton = styled(Button)`
  width: 191px;
`;

const CustomDivider = styled(Divider)`
  height: 150px;
`;
export interface OptionBoxProps {
  onClick: () => void;
  image: string;
  buttonText: string;
  title: string | React.ReactNode;
  description: string;
  loading?: boolean;
}

const OptionBox = ({ onClick, image, buttonText, title, description, loading }: OptionBoxProps) => {
  const theme = useTheme();
  return (
    <H1_FlexColumn justify="space-between" height="300px" width="365px" align="center">
      <H1_FlexColumn height="151px" justify="center">
        <img src={image} alt={description} />
      </H1_FlexColumn>
      <H1_TextMiddle fontWeight={600} textAlign="center">
        {title}
      </H1_TextMiddle>

      <H1_TextXs color={theme.gray5} fontSize="11px" textAlign="center">
        {description}
      </H1_TextXs>
      <OptionButton loading={loading} className="secondary-btn" size="large" onClick={onClick}>
        {buttonText}
      </OptionButton>
    </H1_FlexColumn>
  );
};

const TemplateFlowChoosingModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { openModal, editorModalOpen } = useModal();
  const preferences = useAppSelector((state) => state.user.preferences);
  // this one is guard if user trying to change url path.
  const userData = useAppSelector((state) => state.user.userData);
  const theme = useTheme();
  const selfOpen =
    preferences.show_recommended &&
    userData?.hubspot?.initial &&
    userData.use_case_selection === UseCase.E_LEARNING;

  const visible = editorModalOpen?.open === ModalName.templateFlowChoosingModal;
  useEffect(() => {
    if (selfOpen) {
      openModal(ModalName.templateFlowChoosingModal);
    }
  }, [selfOpen]);

  const onPresentationFlow = () => {
    dispatch(
      analyticsEvents.templateSelectedModal({
        templateName: "presentation",
        source: "presentation-modal"
      })
    );
    openModal(ModalName.presentationUploader, {
      source: ModalName.templateFlowChoosingModal
    });
  };

  const onTemplateFlow = () => {
    dispatch(analyticsEvents.templatesBrowse({ source: "eLearningModal" }));
    openModal(ModalName.templateSelect, { source: ModalName.templateFlowChoosingModal });
  };

  return (
    <StyledModal
      width="867px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "671px" }}
      maskClosable={false}
      closable={false}
      destroyOnClose
      $padding="44px 47px 45px"
      $alignItems="center"
    >
      <H1_FlexColumn padding="42px" align="center" width="100%" height="100%">
        <H1_TextMidHeadline textAlign="center" color={theme.blue4} fontWeight={600}>
          {formatMessage(templatesFowChoosingMessages.header)}
        </H1_TextMidHeadline>
        <StyledRow wrap={false} justify="space-around" align="middle">
          <OptionBox
            onClick={onTemplateFlow}
            image={templatesIcon}
            buttonText={formatMessage(templatesFowChoosingMessages.templateOptionButton)}
            title={
              formatMessage(templatesFowChoosingMessages.templateOptionTitle, {
                br: <br />
              }) as ReactNode
            }
            description={formatMessage(templatesFowChoosingMessages.templateOptionDescription)}
          />
          <H1_FlexColumn align="center">
            <CustomDivider type="vertical" />
            <OrStyle>{formatMessage(templatesFowChoosingMessages.or)}</OrStyle>
            <CustomDivider type="vertical" />
          </H1_FlexColumn>
          <OptionBox
            onClick={onPresentationFlow}
            image={presentationIcon}
            buttonText={formatMessage(templatesFowChoosingMessages.presentationOptionButton)}
            title={
              formatMessage(templatesFowChoosingMessages.presentationOptionTitle, {
                br: <br />
              }) as ReactNode
            }
            description={formatMessage(templatesFowChoosingMessages.presentationOptionDescription)}
          />
        </StyledRow>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default TemplateFlowChoosingModal;
