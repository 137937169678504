import styled from "styled-components";
import { Slider } from "antd";
import { ReactNode } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const TrimFlexRow = styled(H1_FlexRow)``;
const StyledTracker = styled(Slider)`
  .ant-slider {
    position: absolute;
    margin: 0;
  }
  padding: 0;
  margin: 0;
  top: 4px;
  left: 20px;
  height: 66px;
  width: calc(100% - 36px);
  z-index: 2;
  position: absolute;
  .ant-slider-handle {
    &:focus {
      box-shadow: none;
    }
    display: block;
    border: unset;
    height: 62px;
    margin-top: -2px;
    width: 2px;
    background-color: ${(props) => props.theme.blue4};
    border-radius: 2px;
  }

  .ant-slider-track,
  .ant-slider-rail,
  .ant-slider-step {
    display: none;
  }
  .ant-tooltip {
    .ant-tooltip-inner {
      padding: 4px 8px;
      background-color: ${({ theme }) => theme.blue4};
      color: white;
      border-radius: 26px;
    }
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: ${({ theme }) => theme.blue4};
    }
  }
`;
const StyledSlider = styled(Slider)`
  flex: 1;
  width: 100%;
  .ant-slider {
    position: absolute;
    margin: 0;
  }
  margin: 0;
  padding: 0;
  height: 66px;
  && .ant-slider-dot-active {
    border-color: inherited;
    border-radius: 0;
    width: 2px;
    height: 43px;
    top: 0px;
    background-color: ${(props) => props.theme.blue4};
  }
  .ant-slider-track {
    background-color: white !important;
    height: 66px;
    border-top: 4px solid ${({ theme }) => theme.gray4};
    border-bottom: 4px solid ${({ theme }) => theme.gray4};
  }

  .ant-slider-rail {
    background-color: ${({ theme }) => theme.gray4} !important;
    height: 66px;
    border-radius: 4px;
  }
  .ant-slider-handle[class^="ant-slider-handle"] {
    background-color: ${({ theme }) => theme.blue2};
    height: 66px;
    width: 36px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: unset;
    &:focus {
      box-shadow: none;
    }

    &::after {
      font-weight: 900;
      font-size: 20px;
      font-family: "Font Awesome 5 Pro";
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-slider-handle.ant-slider-handle-2 {
    z-index: 10;
    border-radius: 0px 4px 4px 0px;
    &::after {
      content: "\\f105";
    }
  }
  .ant-slider-handle.ant-slider-handle-1 {
    z-index: 10;
    border-radius: 4px 0px 0px 4px;
    &::after {
      content: "\\f104";
    }
  }
  .ant-slider-handle::after {
    top: -5px;
  }

  .ant-tooltip {
    .ant-tooltip-inner {
      padding: 4px 8px;
      background-color: ${({ theme }) => theme.blue4};
      color: white;
      border-radius: 26px;
    }
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: ${({ theme }) => theme.blue4};
    }
  }
`;
interface TrimInputProps {
  trackValue: number;
  formatter: (value?: number) => ReactNode;
  onTrackChange: (value: number) => void;
  min: number;
  max: number;
  onChange: ([min, max]: number[]) => void;
  value: [number, number];
}

const TrimInput = ({
  trackValue,
  formatter,
  onTrackChange,
  min,
  max,
  onChange,
  value
}: TrimInputProps) => {
  return (
    <TrimFlexRow flex="1" height="66px" position="relative">
      <StyledTracker
        className="tooltip-video-action-slider-marker"
        value={trackValue}
        tooltip={{
          getPopupContainer: () =>
            document.querySelector(`.tooltip-video-action-slider-marker`) as HTMLElement,
          formatter
        }}
        onChange={(newValue) => {
          onTrackChange(newValue);
        }}
        min={min}
        max={max}
        step={0.01}
      />
      <StyledSlider
        onChange={(newValue) => {
          onChange(newValue);
        }}
        tooltip={{
          getPopupContainer: () => document.querySelector(`.ant-slider-handle`) as HTMLElement,
          formatter
        }}
        min={min}
        max={max}
        value={value}
        range
        step={0.01}
      />
    </TrimFlexRow>
  );
};

export default TrimInput;
