import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { templatesPageMessages } from "app/pages/Templates/messages";
import { useAppDispatch } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { Orientation, PlanEnum } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import FullTemplatesPageGridCollection from "app/pages/Templates/FullTemplatesPageGridCollection";
import { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";

const DEFAULT_CATEGORY = "General";
const FullTemplatesPageEmptyStates = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const showPaymentModal = useUpgradeModal();

  const onClickRequestTemplate = () => {
    dispatch(analyticsEvents.requestTemplate());
    const upgradeText = formatMessage(templatesPageMessages.noTemplateUpgradeText);
    const upgradeSubText = formatMessage(templatesPageMessages.noTemplateUpgradeSubText);
    dispatch(analyticsEvents.requestTemplate());
    showPaymentModal({
      upgradeText,
      upgradeSubText,
      source: "upgrade_request_template",
      requestedPlans: [PlanEnum.enterprise]
    });

    return;
  };

  return (
    <H1_FlexColumn gap="10px" flex="1 1 100%" width="100%">
      <FullTemplatesPageGridCollection
        orientation={Orientation.AnySize}
        sectionName={DEFAULT_CATEGORY}
        showCustomTemplate
        showCategoryName={false}
      />
      <H1_FlexRow
        gap="10px"
        flex="1"
        justify="center"
        align="center"
        width="100%"
        height="100%"
        padding="0 0 20px 0"
      >
        <H1_TextXs textAlign="center" color={theme.gray6}>
          {formatMessage(templatesPageMessages.noTemplates)}
        </H1_TextXs>
        <br />
        <Button onClick={onClickRequestTemplate}>
          {formatMessage(templatesPageMessages.requestTemplate)}
        </Button>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default FullTemplatesPageEmptyStates;
