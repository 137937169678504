import styled from "styled-components";
import React, { forwardRef, MutableRefObject, useRef, useState } from "react";
import { isMediaPlaying } from "app/utils/helpers";

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
`;

interface VideoWithHoverProps {
  video: string;
  image?: string;
  className?: string;
  autoPlay?: boolean;
  disableIcon?: boolean;
  shouldRestartOnLeave?: boolean;
  onTimeUpdate?: (event: React.SyntheticEvent<HTMLVideoElement>) => void;
  onSeek?: (event: React.SyntheticEvent<HTMLVideoElement>) => void;
}

const VideoWithHover = forwardRef<HTMLVideoElement, VideoWithHoverProps>(
  (
    {
      className,
      video,
      image,
      autoPlay = false,
      disableIcon = false,
      shouldRestartOnLeave = false,
      onTimeUpdate,
      onSeek
    }: VideoWithHoverProps,
    ref
  ) => {
    const videoRef = (ref as MutableRefObject<HTMLVideoElement>) || useRef<HTMLVideoElement>(null);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const onMouseEnter = () => {
      if (videoLoaded) {
        videoRef?.current?.play();
      }
    };

    const onMouseLeave = () => {
      if (videoLoaded && isMediaPlaying(videoRef.current as HTMLVideoElement)) {
        videoRef?.current?.pause();
        if (shouldRestartOnLeave && videoRef?.current) {
          videoRef?.current?.load();
        }
      }
    };

    return (
      <>
        {!disableIcon && <i className="fas fa-video video-poster-icon" />}

        <StyledVideo
          ref={videoRef}
          className={className}
          onCanPlay={() => setVideoLoaded(true)}
          src={video}
          poster={image}
          muted
          loop
          autoPlay={autoPlay}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onTimeUpdate={onTimeUpdate}
          onSeeked={onSeek}
        />
      </>
    );
  }
);

VideoWithHover.displayName = "VideoWithHover";

export default VideoWithHover;
