import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ProtectionType, Video } from "app/types";
import ShareOptionsDropDown from "app/components/VideoShareProtection/ShareOptionsDropDown";
import { useEffect } from "react";
import useVideoShareProtection from "./useVideoShareProtection";
import ConditionalRender from "app/components/common/ConditionalRender";
import { DisplayStateEnum } from "./ProtectionContext";
import { useAppSelector } from "app/hooks";
import { videosGlobalSelectors } from "app/store/adapters/adapters";

const BlueOrbFlexRow = styled(H1_FlexRow)`
  background-color: ${(props) => props.theme.gray5};
  border-radius: 50%;
`;

const StyledFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    height: auto;
  }
`;

const StyledTextSmall = styled(H1_TextSmall)`
  flex: 2;
`;

const WhiteGlobalOutlined = styled.div`
  color: ${(props) => props.theme.gray1};
  height: min-content;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const ShareOptions = ({ videoId }: { videoId: string }) => {
  const theme = useTheme();
  const video: Video | undefined = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, videoId as string)
  );

  if (!video) return <></>;

  const { fetchVideoProtection, currentProtectionOption, setVideoProtectionDisplay } =
    useVideoShareProtection();
  const displayProtection = currentProtectionOption(video.protected);

  useEffect(() => {
    fetchVideoProtection();
  }, []);

  const onChangeProtection = (key: ProtectionType) => {
    switch (key) {
      case ProtectionType.Password:
        setVideoProtectionDisplay(DisplayStateEnum.SHOW_PASSWORD_DIALOG);
        break;
      default:
        setVideoProtectionDisplay(DisplayStateEnum.PUBLIC);
        break;
    }
  };

  return (
    <H1_FlexColumn gap="25px">
      <StyledFlexRow height="63px" gap="25px" align="center">
        <BlueOrbFlexRow justify="center" align="center" width="40px" height="40px">
          <ConditionalRender condition={!!displayProtection}>
            <WhiteGlobalOutlined>{displayProtection?.icon}</WhiteGlobalOutlined>
          </ConditionalRender>
        </BlueOrbFlexRow>
        <H1_FlexColumn gap="5px">
          <ShareOptionsDropDown
            currentProtection={displayProtection?.key}
            onChangeProtection={onChangeProtection}
          />
          <ConditionalRender condition={!!video.protected}>
            <H1_FlexRow>
              <StyledTextSmall
                fontSize="13px"
                color={theme.gray8}
                fontWeight={500}
                whiteSpace="normal"
              >
                {displayProtection?.subtitle}
              </StyledTextSmall>
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexColumn>
      </StyledFlexRow>
    </H1_FlexColumn>
  );
};

export default ShareOptions;
