import React, { SyntheticEvent, useEffect, useRef } from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  object-fit: contain;
  height: 135px;
  width: 100%;
  border-radius: 10px;
`;

interface LazyImageProps {
  isVisible: boolean;
  onChangeVisible: (visible: boolean) => void;
  src: string;
  onErrorImage?: ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => void;
  dataImageHandle: string;
}

const LazyImage: React.FC<LazyImageProps> = ({
  src,
  dataImageHandle,
  onErrorImage,
  isVisible,
  onChangeVisible
}) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          // Check if the element is intersecting
          if (entry.isIntersecting) {
            onChangeVisible(true); // Set the element as visible
            // Stop observing the current element
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null, // Adjust if your images are in a scrollable container
        rootMargin: "100px"
      }
    );

    const currentImageRef = imageRef.current;
    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);

  return (
    <StyledImage
      data-image-handle={dataImageHandle}
      onError={onErrorImage}
      ref={imageRef as React.RefObject<HTMLImageElement>}
      src={isVisible ? src : undefined}
      alt=""
      loading="lazy"
    />
  );
};

export default LazyImage;
