import {
  FeatureFlag,
  FetchStatus,
  Font,
  FontsSet,
  LayoutAsset,
  LayoutAssetTypeOptions,
  LayoutColor,
  LayoutOverflow,
  LayoutTextType,
  PaletteColor,
  PaletteColorKey,
  PatchOperation,
  TextAlignment,
  TitleProperty
} from "app/types";
import { useIntl } from "react-intl";
import React, { useMemo, useState } from "react";
import {
  fetchingStatus,
  getAttributeValueBySceneOrLayout,
  isArabicText,
  removePaletteColorKeyPrefix
} from "app/utils/helpers";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Collapse, Tooltip } from "antd";
import DevModePopover from "app/components/DevModePopover";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { css, useTheme } from "styled-components";
import TextInput from "app/components/editor/scene/TextInput";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import WandButton from "app/components/common/WandButton";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useAppDispatch, useAppSelector } from "app/hooks";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import { useFlags } from "launchdarkly-react-client-sdk";
import { customColorPickerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import { scenesActions } from "app/store/slices/scenes.slice";
import { getFontById } from "app/store/selectorsV2/fonts.selectors";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { ThemeMode } from "app/utils/theme";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { PopoverContent, PopoverTrigger, Popover } from "@nextui-org/react";

const DEFAULT_TEXT_ASSET_LENGTH_RESTRICTION = 1024;

const MinFlexColumn = styled(H1_FlexColumn)`
  min-height: 48px;
`;

const BorderDotIcon = styled(H1_Icon)`
  border: 2px solid ${({ theme }) => theme.gray5};
  border-radius: 50%;
`;

const CustomCollapse = styled(Collapse)`
  &.ant-collapse {
    width: 231px;
    background-color: ${({ theme }) => theme.gray2};
    border: none;
    .ant-collapse-content {
      border: none;
    }
    .ant-collapse-content-box {
      padding-left: 0;
      padding-top: 0;
      background-color: ${({ theme }) => theme.gray2};
    }
    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        justify-content: space-between;
        position: relative;
        padding: 0 0 12px 0;
        .ant-collapse-expand-icon {
          right: 0;
          position: absolute;
        }
      }
    }

    .ant-collapse-header-text {
      color: ${({ theme }) => theme.gray7};
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      font-weight: 600;
      font-size: 12px;
    }
  }
  &.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    padding: 0;
  }
`;

const IconBackgroundFlexRow = styled(H1_FlexRow)<{ $selected: boolean }>`
  background: ${({ theme, $selected }) => ($selected ? theme.gray3 : "transparent")};
`;

const StyledGradientIcon = styled(H1_Icon)`
  background: linear-gradient(to bottom, black, white);
  color: transparent;
  background-clip: text;
  border: 2px solid transparent;
  padding: 2px;

  &:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 255, 0.15);
  }
`;

const TypeIcon = styled(H1_Icon)<{ $selected: boolean }>`
  background-color: ${({ $selected, theme }) => ($selected ? theme.gray3 : "transparent")};
  border: 2px solid transparent;
  padding: 2px;
  &:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 255, 0.15);
  }
`;

const ColorCircle = styled(H1_FlexRow)<{ $color: string; $selected: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: white;
    box-shadow: 0 0 12px 0 rgba(0, 0, 255, 0.15);
  }
  border: 2px ${(props) => props.theme.gray3} solid;
  position: relative;
  ${({ $selected }) =>
    $selected &&
    css`
      filter: drop-shadow(0px 0px 4px #aaf);
    `};
`;

const IconsRowFlexRow = styled(H1_FlexRow)<{ $visibility: boolean }>`
  transition: all 0.3s ease-in-out;
  opacity: ${({ $visibility }) => ($visibility ? 1 : 0)};
  pointer-events: ${({ $visibility }) => ($visibility ? "auto" : "none")};
`;

const IconContainer = styled(H1_FlexRow)<{ $selected?: boolean }>`
  cursor: pointer;
  i {
    cursor: pointer;
    color: ${({ theme }) => theme.gray11};
  }
  width: 23px;
  height: 23px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
  &:active {
    background-color: ${({ theme }) => theme.gray4};
  }
  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) => theme.gray4};
    `};
`;

const BackgroundFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray6};
`;

const ErrorText = styled(H1_TextXs)`
  flex: 0;
  width: 100%;
  position: absolute;
  bottom: -17px;
  font-size: x-small;
  padding-left: 7px;
  color: ${({ theme }) => theme.pink4};
`;

const StyledWandButton = styled(WandButton)<{ loading: boolean }>`
  line-height: 0;
  i {
    font-size: 13px;
  }
  line-height: 17px;
  position: absolute;
  right: 12px;
  top: 13px;
  padding: 0;
  && {
    color: ${({ theme }) => theme.gray7};
    &:hover {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2)};
    }
  }
`;
const StyledMixedTagsWrapper = styled(TextInput)<{
  $error: boolean;
  $isFocused: boolean;
  $maxHeight: string;
}>`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-size: 12px;
  line-height: 16px;
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray2};
  color: ${(props) => props.theme.gray11};
  border-color: ${(props) => (props.$error ? props.theme.pink4 : props.theme.gray5)};
  max-height: ${({ $isFocused, $maxHeight }) => ($isFocused ? "none" : `${$maxHeight} !important`)};
  &&& {
    padding: 8px 34px 8px 12px;
    border-radius: 12px;
    &:hover {
      border-color: ${({ theme }) => theme.gray3};
      background-color: ${({ theme }) => theme.gray2};
    }
    &:focus {
      border-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2)};
    }
  }
`;

const TextAsset = ({
  asset,
  onTextChange,
  draftId,
  onClickGenerateTitle,
  sceneAssetsGenerationStatus
}: {
  asset: LayoutAsset;
  onTextChange: (key: string, value: string, title: string) => void;
  draftId: string;
  onClickGenerateTitle: (key: string, textTitle: string) => void;
  sceneAssetsGenerationStatus: Record<string, FetchStatus>;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [onHover, setOnHover] = useState<boolean>(false);
  const intl = useIntl();
  const theme = useTheme();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { scene, sceneId } = useSelectedScene();

  const appliedPalette = useAppSelector(templatesSelectors.getAppliedPalette);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const currentFontSet: FontsSet | undefined = useAppSelector((state) =>
    getFontById(state, currentDraft.font_set_id)
  );
  const isLayoutHasPreset = !!asset.preset;
  const layoutPresetTextType = asset.preset?.text_type;
  const sceneTranscript = scene?.attributes?.text?.transcript?.text;

  const presetOverride =
    scene?.attributes?.text && scene?.attributes?.text[asset.key as string]?.preset_override;
  const currentOverflowScaleToFit =
    getAttributeValueBySceneOrLayout(scene, asset, "text", "overflow") ===
    LayoutOverflow.scaleToFit;
  const currentTextVisible =
    presetOverride?.has_text === undefined || presetOverride?.has_text === true;

  const maxHeightByType = useMemo(() => {
    switch (layoutPresetTextType) {
      case LayoutTextType.heading:
      case LayoutTextType.body:
        return "66px";
      default:
        return "46px";
    }
  }, [layoutPresetTextType]);
  const textColor = useMemo(() => {
    const color = appliedPalette?.find(
      (paletteColor) =>
        removePaletteColorKeyPrefix(paletteColor.key as string) ===
        getAttributeValueBySceneOrLayout(scene, asset, "text", "text_color")
    );
    return color?.color || "#000";
  }, [presetOverride?.text_color, appliedPalette]);

  const isGradientTypeSelected = (action: TitleProperty) => {
    const textColorType = getAttributeValueBySceneOrLayout(scene, asset, "text", "text_color_type");
    const boxColorType = getAttributeValueBySceneOrLayout(scene, asset, "text", "box_color_type");
    return action === TitleProperty.textColor
      ? textColorType === LayoutAssetTypeOptions.gradient
      : boxColorType === LayoutAssetTypeOptions.gradient;
  };
  const isGradientTypeAvailable = (action: TitleProperty) => {
    const textColorType = getAttributeValueBySceneOrLayout(scene, asset, "text", "text_color_type");
    const boxColorType = getAttributeValueBySceneOrLayout(scene, asset, "text", "box_color_type");
    return action === TitleProperty.textColor ? !!textColorType : !!boxColorType;
  };
  const beforeTextChanged = (value: string) => {
    if (isArabicText(value.trim())) {
      setErrorMessage(intl.formatMessage(sceneDrawerMessages.titleArabicUnsupported));
      return;
    }
    setErrorMessage(undefined);
    onTextChange(asset.key, value, asset.title);
  };

  const onMouseEnter = () => {
    setOnHover(true);
  };

  const onMouseLeave = () => {
    setOnHover(false);
  };

  const addPresetProperty = (operations: PatchOperation[], property: string, value: any) => {
    operations.push({
      op: "replace",
      path: `attributes.text.${asset.key}.preset_override.${property}`,
      value
    });
  };

  const onClickTextEye = () => {
    const operations: PatchOperation[] = [];
    addPresetProperty(operations, "has_text", !currentTextVisible);
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const handleFontWeight = (weight: string) => {
    switch (weight) {
      case "Light":
        return 300;
      case "Regular":
        return 400;
      case "Medium":
        return 500;
      case "Bold":
        return 600;
      case "Black":
        return 700;
      default:
        return 400;
    }
    return 400;
  };

  const onChooseBrandColor = (color: string | undefined, action: TitleProperty, index = 0) => {
    let actionPropertyName: string;
    let gradientValue: LayoutColor[] = [];
    const operations: PatchOperation[] = [];
    let newGradientArr: LayoutColor[] = [];

    switch (action) {
      case TitleProperty.textBackgroundColor:
        addPresetProperty(operations, "box", !!color);
        actionPropertyName = isGradientTypeSelected(action) ? "box_color_gradient" : "box_color";
        gradientValue = getAttributeValueBySceneOrLayout(
          scene,
          asset,
          "text",
          "box_color_gradient"
        ) as LayoutColor[];
        break;
      case TitleProperty.textColor:
        actionPropertyName = isGradientTypeSelected(action) ? "text_color_gradient" : "text_color";
        gradientValue = getAttributeValueBySceneOrLayout(
          scene,
          asset,
          "text",
          "text_color_gradient"
        ) as LayoutColor[];
        break;
      default:
        actionPropertyName = "";
    }

    if (color) {
      let value: any = color.split("_")[1];
      if (isGradientTypeSelected(action) && gradientValue[index]) {
        newGradientArr = [...gradientValue];
        newGradientArr[index] = value as LayoutColor;
        value = newGradientArr;
      }
      addPresetProperty(operations, actionPropertyName, value);
    }

    if (operations.length > 0) {
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId,
          operations
        })
      );
    }
  };

  const onChooseType = (type: LayoutAssetTypeOptions | undefined, action: TitleProperty) => {
    let actionPropertyName: string;
    const operations: PatchOperation[] = [];
    switch (action) {
      case TitleProperty.textBackgroundColor:
        actionPropertyName = "box_color_type";
        addPresetProperty(operations, "box", !!type);
        break;
      case TitleProperty.textColor:
        actionPropertyName = "text_color_type";
        break;
      default:
        actionPropertyName = "";
    }
    if (actionPropertyName) {
      addPresetProperty(operations, actionPropertyName, type);
    }

    if (operations.length > 0) {
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId,
          operations
        })
      );
    }
  };

  const onUpdateTextOverflow = () => {
    const operations: PatchOperation[] = [];
    addPresetProperty(
      operations,
      "overflow",
      currentOverflowScaleToFit ? LayoutOverflow.truncate : LayoutOverflow.scaleToFit
    );
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const onChooseAlignment = (alignment: TextAlignment) => {
    let actionPropertyName: string;
    const operations: PatchOperation[] = [];
    switch (alignment) {
      case TextAlignment.left:
      case TextAlignment.right:
      case TextAlignment.center:
        actionPropertyName = "alignment_horizontal";
        break;
      case TextAlignment.top:
      case TextAlignment.bottom:
      case TextAlignment.middle:
        actionPropertyName = "alignment_vertical";
        break;
      default:
        actionPropertyName = "";
    }
    if (actionPropertyName) {
      addPresetProperty(operations, actionPropertyName, alignment);
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId,
          operations
        })
      );
    }
  };

  const onClickFont = (e: any, { level }: Font) => {
    console.log(e);
    e.stopPropagation();
    const operations: PatchOperation[] = [];
    addPresetProperty(operations, "font_level", level);
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
  };

  const textFamilyMenu = {
    items: [
      {
        className: "text-header",
        key: "header",
        startContent: <i className="fal fa-text" />,
        label: <H1_TextXs color={theme.gray7}>All Fonts</H1_TextXs>,
        showDivider: true
      },
      ...(currentFontSet?.fonts.map((fontSet: Font) => ({
        className:
          fontSet.level === getAttributeValueBySceneOrLayout(scene, asset, "text", "font_level")
            ? "text-selected"
            : "",
        key: fontSet.family,
        label: (
          <H1_TextXs
            color={theme.gray7}
            fontWeight={handleFontWeight(fontSet.family.split(" ").slice(-1)[0])}
          >
            {fontSet.family.split(" ").slice(0, -1).join(" ")}
          </H1_TextXs>
        ),
        onClick: (domEvent: any) => onClickFont(domEvent, fontSet)
      })) || [])
    ]
  };

  const colorsPopoverContent = (
    action: TitleProperty,
    onChooseBrandColor: (key: string | undefined, action: TitleProperty, index?: number) => void,
    canRemoveColor = false
  ) => {
    const headerText = () => {
      switch (action) {
        case TitleProperty.textColor:
          return intl.formatMessage(sceneDrawerMessages.textColorTooltip);
        case TitleProperty.textBackgroundColor:
          return intl.formatMessage(sceneDrawerMessages.textBackgroundColorTooltip);
        default:
          return "";
      }
    };

    const colorTooltip = (color: PaletteColor) => {
      switch (color.key) {
        case PaletteColorKey.color_A1:
          return intl.formatMessage(customColorPickerMessages.a1Color);
        case PaletteColorKey.color_A2:
          return intl.formatMessage(customColorPickerMessages.a2Color);
        case PaletteColorKey.color_A3:
          return intl.formatMessage(customColorPickerMessages.a3Color);
        case PaletteColorKey.color_W:
          return intl.formatMessage(customColorPickerMessages.wColor);
        case PaletteColorKey.color_B:
          return intl.formatMessage(customColorPickerMessages.bColor);
        default:
          return "";
      }
    };

    const selectedColor = (selectedColor: string | undefined, action: TitleProperty, index = 0) => {
      let textColor: string;
      let textColorGradient: LayoutColor[];
      let currentColor: string;
      switch (action) {
        case TitleProperty.textColor:
          textColor = getAttributeValueBySceneOrLayout(
            scene,
            asset,
            "text",
            "text_color"
          ) as string;
          textColorGradient = getAttributeValueBySceneOrLayout(
            scene,
            asset,
            "text",
            "text_color_gradient"
          ) as LayoutColor[];
          currentColor = isGradientTypeSelected(action) ? textColorGradient[index] : textColor;
          return selectedColor?.includes(currentColor || "") || false;
          break;
        case TitleProperty.textBackgroundColor:
          textColor = (getAttributeValueBySceneOrLayout(scene, asset, "text", "box_color") ||
            "") as string;
          textColorGradient = getAttributeValueBySceneOrLayout(
            scene,
            asset,
            "text",
            "box_color_gradient"
          ) as LayoutColor[];
          currentColor = isGradientTypeSelected(action) ? textColorGradient[index] : textColor;
          return (
            (selectedColor?.includes(currentColor || "") && presetOverride?.box) ||
            (selectedColor === undefined && !presetOverride?.box) ||
            false
          );
          break;
      }

      return false;
    };

    const isColorSelected =
      action === TitleProperty.textBackgroundColor
        ? (getAttributeValueBySceneOrLayout(scene, asset, "text", "box") as boolean)
        : true;
    return (
      <H1_FlexColumn padding="8px 4px" gap="10px">
        <H1_FlexRow gap="7px">
          <ConditionalRender condition={canRemoveColor}>
            <Tooltip title={intl.formatMessage(sceneDrawerMessages.noColorTooltip)}>
              <H1_FlexRow onClick={() => onChooseType(undefined, action)}>
                <TypeIcon
                  isCursorPointer
                  $selected={!isColorSelected}
                  color={theme.gray7}
                  size="11px"
                  icon="fa-light fa-ban"
                ></TypeIcon>
              </H1_FlexRow>
            </Tooltip>
          </ConditionalRender>
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.solidColorTooltip)}>
            <H1_FlexRow onClick={() => onChooseType(LayoutAssetTypeOptions.solid, action)}>
              <TypeIcon
                isCursorPointer
                $selected={isColorSelected && !isGradientTypeSelected(action)}
                color={theme.gray7}
                size="11px"
                icon="fa-light fa-palette"
              />
            </H1_FlexRow>
          </Tooltip>
          <ConditionalRender condition={isGradientTypeAvailable(TitleProperty.textBackgroundColor)}>
            <Tooltip title={intl.formatMessage(sceneDrawerMessages.gradientColorTooltip)}>
              <IconBackgroundFlexRow
                $selected={isColorSelected && isGradientTypeSelected(action)}
                onClick={() => onChooseType(LayoutAssetTypeOptions.gradient, action)}
              >
                <StyledGradientIcon isCursorPointer icon="fas fa-square" size="11px" />
              </IconBackgroundFlexRow>
            </Tooltip>
          </ConditionalRender>
        </H1_FlexRow>
        <CustomCollapse accordion defaultActiveKey={["1"]}>
          <Collapse.Panel header={headerText()} key="1" showArrow={false} collapsible="disabled">
            <ConditionalRender condition={isColorSelected}>
              <H1_FlexRow padding="10px 0 0 0" gap="10px" align="center">
                {appliedPalette?.map((paletteColor: PaletteColor) => (
                  <Tooltip title={colorTooltip(paletteColor)} key={paletteColor.key}>
                    <ColorCircle
                      onClick={() => onChooseBrandColor(paletteColor.key, action)}
                      $color={paletteColor.color}
                      $selected={selectedColor(paletteColor.key, action)}
                    />
                  </Tooltip>
                ))}
              </H1_FlexRow>
            </ConditionalRender>
            <ConditionalRender condition={isGradientTypeSelected(action) && isColorSelected}>
              <H1_FlexRow padding="10px 0 0 0" gap="10px" align="center">
                {appliedPalette?.map((paletteColor: PaletteColor) => (
                  <Tooltip title={colorTooltip(paletteColor)} key={paletteColor.key}>
                    <ColorCircle
                      onClick={() => onChooseBrandColor(paletteColor.key, action, 1)}
                      $color={paletteColor.color}
                      $selected={selectedColor(paletteColor.key, action, 1)}
                    />
                  </Tooltip>
                ))}
              </H1_FlexRow>
            </ConditionalRender>
          </Collapse.Panel>
        </CustomCollapse>
      </H1_FlexColumn>
    );
  };

  const alignmentIcon = useMemo(() => {
    const alignmentIcon = getAttributeValueBySceneOrLayout(
      scene,
      asset,
      "text",
      "alignment_horizontal"
    );
    switch (alignmentIcon) {
      case TextAlignment.left:
        return "fa-align-left";
      case TextAlignment.center:
        return "fa-align-center";
      case TextAlignment.right:
        return "fa-align-right";
      default:
        return "fa-align-left";
    }
  }, [presetOverride?.alignment_horizontal, asset]);

  const alignmentPopoverContent = (onChooseBrandAlignment: (key: TextAlignment) => void) => {
    const selectedAlignmentHorizontal = getAttributeValueBySceneOrLayout(
      scene,
      asset,
      "text",
      "alignment_horizontal"
    );
    const selectedAlignmentVertical = getAttributeValueBySceneOrLayout(
      scene,
      asset,
      "text",
      "alignment_vertical"
    );
    return (
      <H1_FlexColumn padding="8px 4px" gap="10px">
        <H1_FlexRow gap="10px" align="center">
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.textAlignLeftTooltip)}>
            <IconContainer
              $selected={selectedAlignmentHorizontal === TextAlignment.left}
              onClick={() => onChooseBrandAlignment(TextAlignment.left)}
            >
              <H1_Icon icon="fal fa-align-left" size="11px" />
            </IconContainer>
          </Tooltip>
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.textAlignCenterTooltip)}>
            <IconContainer
              $selected={selectedAlignmentHorizontal === TextAlignment.center}
              onClick={() => onChooseBrandAlignment(TextAlignment.center)}
            >
              <H1_Icon icon="fal fa-align-center" size="11px" />
            </IconContainer>
          </Tooltip>
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.textAlignRightTooltip)}>
            <IconContainer
              $selected={selectedAlignmentHorizontal === TextAlignment.right}
              onClick={() => onChooseBrandAlignment(TextAlignment.right)}
            >
              <H1_Icon icon="fal fa-align-right" size="11px" />
            </IconContainer>
          </Tooltip>
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.textAlignTopTooltip)}>
            <IconContainer
              $selected={selectedAlignmentVertical === TextAlignment.top}
              onClick={() => onChooseBrandAlignment(TextAlignment.top)}
            >
              <H1_Icon icon="fal fa-objects-align-top" size="11px" />
            </IconContainer>
          </Tooltip>
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.textAlignMiddleTooltip)}>
            <IconContainer
              $selected={selectedAlignmentVertical === TextAlignment.middle}
              onClick={() => onChooseBrandAlignment(TextAlignment.middle)}
            >
              <H1_Icon icon="fal fa-objects-align-center-vertical" size="11px" />
            </IconContainer>
          </Tooltip>
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.textAlignBottomTooltip)}>
            <IconContainer
              $selected={selectedAlignmentVertical === TextAlignment.bottom}
              onClick={() => onChooseBrandAlignment(TextAlignment.bottom)}
            >
              <H1_Icon icon="fal fa-objects-align-bottom" size="11px" />
            </IconContainer>
          </Tooltip>
        </H1_FlexRow>
      </H1_FlexColumn>
    );
  };

  const onFocusTextAsset = () => {
    setIsFocused(true);
  };
  const onBlurTextAsset = () => {
    setIsFocused(false);
  };

  return (
    <MinFlexColumn gap="7px" width="100%" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <H1_FlexRow justify="space-between" align="center">
        <H1_TextXs color={theme.gray7} fontWeight={600}>
          {asset.title}
        </H1_TextXs>
        <H1_FlexRow gap="7px" align="center">
          <IconsRowFlexRow
            $visibility={onHover && isLayoutHasPreset && flags[FeatureFlag.scenePreset]}
            gap="7px"
          >
            <Popover placement="left">
              <Tooltip title={intl.formatMessage(sceneDrawerMessages.textColorTooltip)}>
                <PopoverTrigger>
                  <IconContainer>
                    <BorderDotIcon color={textColor} icon="fas fa-circle" size="11px" />
                  </IconContainer>
                </PopoverTrigger>
              </Tooltip>
              <PopoverContent>
                {colorsPopoverContent(TitleProperty.textColor, onChooseBrandColor)}
              </PopoverContent>
            </Popover>
            <Popover placement="left">
              <Tooltip title={intl.formatMessage(sceneDrawerMessages.textBackgroundColorTooltip)}>
                <PopoverTrigger>
                  <IconContainer>
                    <BackgroundFlexRow width="15px" height="17px" justify="center" align="center">
                      <H1_Icon icon="fas fa-text" color={theme.gray1} size="11px" />
                    </BackgroundFlexRow>
                  </IconContainer>
                </PopoverTrigger>
              </Tooltip>
              <PopoverContent>
                {colorsPopoverContent(TitleProperty.textBackgroundColor, onChooseBrandColor, true)}
              </PopoverContent>
            </Popover>
            <Popover placement="left">
              <Tooltip title={intl.formatMessage(sceneDrawerMessages.textAlignmentTooltip)}>
                <PopoverTrigger>
                  <IconContainer>
                    <H1_Icon color={theme.gray7} icon={`fas ${alignmentIcon}`} size="11px" />
                  </IconContainer>
                </PopoverTrigger>
              </Tooltip>
              <PopoverContent>{alignmentPopoverContent(onChooseAlignment)}</PopoverContent>
            </Popover>
            <NextDropdown items={textFamilyMenu.items} withWhiteBackground>
              <IconContainer>
                <Tooltip title={intl.formatMessage(sceneDrawerMessages.textFamilyTooltip)}>
                  <H1_Icon color={theme.gray7} icon="fas fa-text" size="11px" />
                </Tooltip>
              </IconContainer>
            </NextDropdown>
            <Tooltip
              title={
                currentOverflowScaleToFit
                  ? intl.formatMessage(sceneDrawerMessages.textOverflowScaleToFitTooltip)
                  : intl.formatMessage(sceneDrawerMessages.textOverflowTruncateTooltip)
              }
            >
              <IconContainer $selected={currentOverflowScaleToFit} onClick={onUpdateTextOverflow}>
                <H1_Icon color={theme.gray7} icon="fas fa-text-width" size="11px" />
              </IconContainer>
            </Tooltip>
          </IconsRowFlexRow>
          <ConditionalRender
            condition={isLayoutHasPreset && flags[FeatureFlag.scenePreset] && false}
          >
            <Tooltip title={intl.formatMessage(sceneDrawerMessages.textHiddenTooltip)}>
              <IconContainer onClick={onClickTextEye}>
                <H1_Icon
                  color={theme.gray7}
                  icon={currentTextVisible ? "fas fa-eye" : "fas fa-eye-slash"}
                  size="11px"
                />
              </IconContainer>
            </Tooltip>
          </ConditionalRender>
        </H1_FlexRow>
      </H1_FlexRow>
      <H1_FlexRow position="relative">
        <StyledMixedTagsWrapper
          $isFocused={isFocused}
          $maxHeight={maxHeightByType}
          onFocus={onFocusTextAsset}
          onBlur={onBlurTextAsset}
          $error={!!errorMessage}
          maxLength={DEFAULT_TEXT_ASSET_LENGTH_RESTRICTION}
          useWordCounter
          value={scene?.attributes.text?.[asset.key]?.text as string}
          updateTextFunc={beforeTextChanged}
          isTranscript={false}
          autoSize={{
            minRows: 2,
            maxRows: (scene?.attributes.text?.[asset.key]?.text?.length || 0) > 0 ? 10 : 1
          }}
        />
        <ConditionalRender condition={!!errorMessage}>
          <ErrorText>{errorMessage}</ErrorText>
        </ConditionalRender>

        <ConditionalRender condition={!!sceneTranscript}>
          <Tooltip title={intl.formatMessage(sceneDrawerMessages.titleGenerationToolTip)}>
            <StyledWandButton
              loading={
                sceneAssetsGenerationStatus[`${sceneId}-attributes.text.${asset.key}.text`] ===
                fetchingStatus.loading
              }
              size="18px"
              lowContrast
              onClick={() => onClickGenerateTitle(asset.key, asset.title)}
            />
          </Tooltip>
        </ConditionalRender>
        <DevModePopover
          value={{
            id: asset.key,
            text: scene?.attributes.text?.[asset.key]?.text || "{{TEXT}}"
          }}
          path={"scenes[#].texts_elements[#]"}
          placement={"left"}
        />
      </H1_FlexRow>
    </MinFlexColumn>
  );
};

export default TextAsset;
