import React from "react";
import styled from "styled-components";
import { Button } from "@nextui-org/react";

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.blue4};
  span {
    color: ${({ theme }) => theme.blue4};
  }
  fill: ${({ theme }) => theme.blue4};
  background-color: transparent;
  &:hover {
    color: ${({ theme }) => theme.blue3};
    span {
      color: ${({ theme }) => theme.blue3};
    }
    fill: ${({ theme }) => theme.blue3};
    background-color: transparent;
  }
  &:active {
    color: ${({ theme }) => theme.blue2};
    span {
      color: ${({ theme }) => theme.blue2};
    }
    fill: ${({ theme }) => theme.blue2};
    background-color: transparent;
  }
`;

interface BackButtonProps {
  onBack?: () => void;
}

const BackButton = ({ onBack }: BackButtonProps) => {
  return (
    <StyledButton
      variant="light"
      isIconOnly
      onClick={onBack}
      startContent={<i className="far fa-chevron-left" />}
    />
  );
};

export default BackButton;
