import React from "react";
import { HeaderTab } from "app/types";

export interface HeaderContextType {
  activeHeaderTab: HeaderTab;
  setActiveHeaderTab: (value: HeaderTab) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  isOpenBrandHub: boolean;
  setIsOpenBrandHub: (value: boolean) => void;
}
export const HeaderContext = React.createContext<HeaderContextType>({
  isOpenBrandHub: false,
  searchValue: "",
  activeHeaderTab: HeaderTab.home,
  setIsOpenBrandHub: () => null,
  setSearchValue: () => null,
  setActiveHeaderTab: () => null
});
