import { Tabs } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import EditForm from "app/pages/EditDraftPage/workflow/EditForm";
import React, { useEffect, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import ScenesList from "app/components/editor/scene/ScenesList";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import IntegrateWithApi from "app/pages/EditDraftPage/workflow/IntegrateWithApi";
import Submissions from "app/pages/EditDraftPage/workflow/Submissions";
import WorkflowTable from "app/pages/EditDraftPage/workflow/WorkflowTable";
import { useParams } from "react-router-dom";
import { dataStoreActions } from "app/store/slices/dataStore.slice";
import { FeatureFlag } from "app/types";
import { useFlags } from "launchdarkly-react-client-sdk";

const TabTitle = ({ title, icon, dataAutoId }: any) => {
  return (
    <H1_FlexRow align={"center"} gap={"10px"} justify={"center"} data-auto-id={dataAutoId}>
      <i className={icon}></i>
      {title}
    </H1_FlexRow>
  );
};
const StyledTabs = styled(Tabs)`
  color: #5a5aff;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  .ant-tabs-nav-list {
    justify-content: center;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab:hover {
    color: #5a5aff;
  }
  .ant-tabs-tab {
    width: 120px;
  }
  .ant-tabs-nav {
    margin-bottom: 80px;
  }
  .ant-tabs-tab-btn {
    width: 100%;
  }
`;
type TabTypes = "storyboard" | "form" | "test" | "submissions" | "table";
const WorkFlowContent = () => {
  const [currentTab, setCurrentTab] = useState<TabTypes>("form");

  const { draftId } = useParams<{ draftId: string }>();
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const workflowId = currentDraft?.workflow_id || "";
  // in live mode - view form + create video
  //   test- add integration tab should be code
  const onChange = (tab: string) => {
    if (tab !== "storyboard") {
      dispatch(uiActionsV2.setShowEditorDrawer(false));
    } else if (!workflowLiveMode) {
      dispatch(uiActionsV2.setShowEditorDrawer(true));
    }
    setCurrentTab(tab as TabTypes);
  };
  useEffect(() => {
    dispatch(uiActionsV2.setIsWorkflowMode(true));
    dispatch(uiActionsV2.setShowEditorDrawer(false));
    return () => {
      dispatch(uiActionsV2.setIsWorkflowMode(false));
      dispatch(uiActionsV2.setShowEditorDrawer(true));
      dispatch(dataStoreActions.cleanDataStore());
    };
  }, []);

  useEffect(() => {
    if (workflowLiveMode) {
      dispatch(uiActionsV2.setShowEditorDrawer(false));
      setCurrentTab("form");
    } else {
      dispatch(uiActionsV2.setShowEditorDrawer(false));
      setCurrentTab("form");
    }
  }, [workflowLiveMode]);

  return (
    <>
      <StyledTabs
        key={workflowLiveMode ? "1" : "2"}
        destroyInactiveTabPane
        activeKey={currentTab}
        onChange={onChange}
      >
        <Tabs.TabPane
          tab={
            <TabTitle
              title={"Storyboard"}
              icon={"fa-regular fa-file-lines"}
              dataAutoId="workflow-tab-storyboard"
            />
          }
          key="storyboard"
        ></Tabs.TabPane>
        {flags[FeatureFlag.csvTable] && (
          <Tabs.TabPane
            tab={
              <TabTitle
                title={"Table"}
                icon={"fa-regular fa-table"}
                dataAutoId="workflow-tab-table"
              />
            }
            key="table"
          ></Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <TabTitle
              dataAutoId="workflow-tab-form"
              title={workflowLiveMode ? "View form" : "Form"}
              icon={workflowLiveMode ? "fa-regular fa-eye" : "fa-regular fa-pen"}
            />
          }
          key="form"
        ></Tabs.TabPane>
        {workflowLiveMode && (
          <>
            <Tabs.TabPane
              tab={
                <TabTitle dataAutoId="workflow-tab-test" title={"API"} icon="fa-regular fa-code" />
              }
              key="test"
            ></Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <TabTitle
                  dataAutoId="workflow-tab-submissions"
                  title={"Submissions"}
                  icon={"fa-regular fa-eye"}
                />
              }
              key="submissions"
            ></Tabs.TabPane>
          </>
        )}
      </StyledTabs>

      <ConditionalRender condition={currentTab === "storyboard"}>
        <ScenesList />
      </ConditionalRender>
      <ConditionalRender condition={currentTab === "table"}>
        <WorkflowTable draftId={draftId} workflowId={workflowId} />
      </ConditionalRender>
      <ConditionalRender condition={currentTab === "form" && !workflowLiveMode}>
        <EditForm />
      </ConditionalRender>
      <ConditionalRender condition={currentTab === "form" && workflowLiveMode}>
        <EditForm liveMode />
      </ConditionalRender>
      <ConditionalRender condition={currentTab === "test"}>
        <IntegrateWithApi />
      </ConditionalRender>
      <ConditionalRender condition={currentTab === "submissions"}>
        <Submissions />
      </ConditionalRender>
    </>
  );
};

export default WorkFlowContent;
