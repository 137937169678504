import { useIntl } from "react-intl";
import styled from "styled-components";
import { homePageMessages } from "app/pages/HomePage/messages";
import { H1_TextLargeHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { joinExperiment } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import React, { useEffect, useState } from "react";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";
import { useNavigate } from "react-router-dom";
import { FetchStatus, Location } from "app/types";
import { useAuth } from "app/auth/useAuth";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { draftsActions } from "app/store/slices/drafts.slice";
import { Button } from "@nextui-org/react";

const GradientSectionFlexRow = styled(H1_FlexRow)`
  background: linear-gradient(92deg, rgba(183, 111, 101, 1) 0.22%, rgba(221, 91, 78, 1) 160.21%);
  border-radius: 10px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      font-size: 16px;
    }
  }
`;

const ImageContainer = styled(H1_FlexRow)`
  right: -10px;
  bottom: -10px;
  @media (max-width: 1400px) {
    display: none;
  }
`;

const PositionButton = styled(Button)`
  background-color: white;
  margin-top: 13px;
  padding: 2px 30px;
  width: min-content;
  border-radius: 60px;
  & {
    height: 47px;
  }
`;

const HomePageTopSectionFTU = () => {
  const [exploreButtonLoading, setExploreButtonLoading] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const navigate = useNavigate();
  const drafts = useAppSelector(draftsGlobalSelectors.selectAll);
  const createFirstDraftStatus: FetchStatus = useAppSelector(
    (state) => state.drafts.createFirstDraftStatus
  );
  const createDraftLoading = createFirstDraftStatus === fetchingStatus.loading;

  useEffect(() => {
    dispatch(
      joinExperiment({
        id: user?.sub as string,
        email: user?.email as string,
        experimentName: "exp_onHomepageFtuBannerFlow"
      })
    );
  }, []);

  useEffect(() => {
    if (
      exploreButtonLoading &&
      createFirstDraftStatus !== fetchingStatus.loading &&
      drafts.length
    ) {
      dispatch(draftsActions.setCreateDraftStatusToIdle());
      setExploreButtonLoading(false);
      const firstDraft = drafts[0];
      if (firstDraft) {
        dispatch(analyticsEvents.navigateToEditor({ source: "homepage_ftu" }));
        navigate(`${Location.Editor}/${firstDraft.id}`);
      } else {
        navigate(Location.Templates);
      }
    }
  }, [exploreButtonLoading, createFirstDraftStatus, drafts]);

  const onCallToActionClick = () => {
    const SOURCE = "FTU-banner";
    dispatch(
      analyticsEvents.homepageCTA({
        source: SOURCE,
        actionDescription: "startHere"
      })
    );

    if (createDraftLoading) {
      setExploreButtonLoading(true);
      return;
    }

    const firstDraft = drafts[0];
    if (firstDraft) {
      dispatch(analyticsEvents.navigateToEditor({ source: "homepage_ftu" }));
      navigate(`${Location.Editor}/${firstDraft.id}`);
    } else {
      navigate(Location.Templates);
    }
  };

  return (
    <H1_FlexColumn
      position="relative"
      flex="1 1 auto"
      height="250px"
      data-auto-id="homepage-ftu-banner"
    >
      <GradientSectionFlexRow
        position="relative"
        height="100%"
        overflow="hidden"
        padding="30px 30px 40px"
      >
        <H1_FlexColumn justify="space-between">
          <H1_FlexColumn gap="5px">
            <H1_TextLargeHeadline whiteSpace="break-spaces" fontWeight={500} color="white">
              {formatMessage(homePageMessages.largeHeadingFtu)}
            </H1_TextLargeHeadline>
            <H1_TextMiddle fontWeight={500} whiteSpace="break-spaces" color="white">
              {formatMessage(homePageMessages.smallParagraphFtu)}
            </H1_TextMiddle>
          </H1_FlexColumn>
          <PositionButton onClick={onCallToActionClick} isLoading={exploreButtonLoading}>
            {formatMessage(homePageMessages.bannerNewUserCTA)}
          </PositionButton>
        </H1_FlexColumn>
      </GradientSectionFlexRow>
      <ImageContainer position="absolute">
        <img
          width="163px"
          height="260px"
          src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/createFirstVideoIcon.webp"
          alt="workflow"
        />
      </ImageContainer>
    </H1_FlexColumn>
  );
};

export default HomePageTopSectionFTU;
