import { transactionId } from "app/services/sentry";
import redux from "app/store/store";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import axios, { AxiosRequestConfig } from "axios";

let securityAccessToken: string | null = null;
let authClient: { renewToken: () => Promise<string> };

const TIMEOUT = 60000;

const security = {
  getAccessTokenSilently: () => securityAccessToken,
  setAccessTokenSilently: (newAccessToken: string | null) => {
    securityAccessToken = newAccessToken;
  },
  setAuthLockClient: (client: { renewToken: () => Promise<string> }) => {
    authClient = client;
  }
};

interface Options extends RequestInit {
  headers?: { [key: string]: string };
  rejectOnFailure?: boolean;
}

const renewToken = async (): Promise<{
  error?: unknown;
  isLoginRequired?: boolean;
  token?: string;
}> => {
  try {
    const token = await authClient.renewToken();
    return { token: token };
  } catch (err) {
    if (err instanceof Error && err.message === "login_required") {
      return { isLoginRequired: true };
    } else {
      console.warn("security failed to renew token", err);
      return { error: err };
    }
  }
};

function addQueryParamToUrl(url: string, name: string, as_client: string) {
  const splittedUrl = url.split("?");
  if (splittedUrl.length > 1) {
    return `${url}&${name}=${as_client}`;
  } else {
    return `${url}?${name}=${as_client}`;
  }
}

const executeRequest = async (url: string, options: Options = {}, accessToken?: string) => {
  const currentWorkspace = getCurrentWorkspace(redux.store.getState());
  const as_client = redux.store.getState().user?.initialQueryParams?.as_client as string;

  const clientVersion = import.meta.env.REACT_APP_VERSION;
  const headers = {
    Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    "content-type": "application/json;charset=UTF-8",
    Accept: "application/json",
    "Content-Encoding": "gzip,deflate,br",
    "x-transaction-id": transactionId,
    "x-client-version": clientVersion,
    "workspace-id": currentWorkspace?.id,
    "team-id": currentWorkspace?.current_team?.id,
    ...(options.headers || {})
  };

  const requestOptions: AxiosRequestConfig = {
    url: as_client ? addQueryParamToUrl(url, "as_client", as_client) : url,
    method: options.method,
    timeout: TIMEOUT,
    responseType: "json",
    headers,
    data: options.body,
    validateStatus: () => true
  };
  const res = await axios(requestOptions);
  if (options.rejectOnFailure && !(res.status >= 200 && res.status < 400)) {
    let body;
    try {
      if (res.statusText !== "No Content") {
        body = await res.data;
      }
    } catch (e) {
      console.error("failed to parsed error response", e);
    }

    const optionsSentize = { ...options };

    delete optionsSentize.headers?.Authorization;

    // eslint-disable-next-line no-throw-literal
    throw {
      message: body?.detail,
      stack: {
        request: [url, optionsSentize],
        response: res
      },
      status: res.status,
      originalError: body
    };
  }
  return res;
};

const fetchSecured = async (url: string, options: Options = {}, accessToken?: string) => {
  if (!accessToken) {
    // eslint-disable-next-line no-param-reassign
    accessToken = security.getAccessTokenSilently() as string;
  }

  try {
    const res = await executeRequest(url, options, accessToken);
    return res;
  } catch (err) {
    // @ts-ignore: status does not exists on error {}
    if (err?.status === 401 && authClient) {
      const result = await renewToken();
      if (result.token) {
        // setToken
        securityAccessToken = result.token;
        const res = await executeRequest(url, options, securityAccessToken);
        return res;
      }
      console.warn(`failed to renew token on 401 api`, { extra: { result } });
    }
    throw err;
  }
};

export { security, fetchSecured };
