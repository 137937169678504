import { FetchStatus, DataStoreResult } from "app/types";
import { createSlice } from "@reduxjs/toolkit";
import { dataStoreAdapter } from "app/store/adapters/adapters";
import { fetchingStatus } from "app/utils/helpers";
import asyncThunks from "app/store/thunks/dataStore.thunk";

interface DataStoreState {
  importDataStatus: FetchStatus;
  updateImportDataStatus: FetchStatus;
  getImportDataStatus: FetchStatus;
  importDataResult?: DataStoreResult;
  tempImportDataResult?: DataStoreResult;
  importDataErrorsResult: Record<string, boolean>;
}

const INITIAL_STATE: DataStoreState = {
  importDataResult: undefined,
  importDataErrorsResult: {},
  importDataStatus: fetchingStatus.idle as FetchStatus,
  getImportDataStatus: fetchingStatus.idle as FetchStatus,
  updateImportDataStatus: fetchingStatus.idle as FetchStatus
};

export const dataStoresSlice = createSlice({
  name: "DataStore",
  initialState: dataStoreAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    updateImportDataToIdle: (state) => {
      state.importDataStatus = fetchingStatus.idle;
    },
    updateImportDataErrorsResult: (state, action) => {
      state.importDataErrorsResult = { ...state.importDataErrorsResult, ...action.payload };
    },
    updateGetImportDataToIdle: (state) => {
      state.getImportDataStatus = fetchingStatus.idle;
    },
    cleanDataStore(state) {
      state.importDataResult = undefined;
    },
    setUpdateImportDataToIdle(state) {
      state.updateImportDataStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    saveCurrentCsvTable(state) {
      state.tempImportDataResult = state.importDataResult;
      return state;
    },
    restoreCurrentCsvTable(state) {
      if (state.tempImportDataResult) {
        state.importDataResult = state.tempImportDataResult;
      }
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.importDataRequest.pending, (state) => {
      state.importDataStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.importDataRequest.rejected, (state) => {
      state.importDataStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.importDataRequest.fulfilled, (state, action) => {
      state.importDataStatus = fetchingStatus.succeeded as FetchStatus;
      dataStoreAdapter.addOne(state, action.payload);
      state.importDataResult = action.payload;
    });
    builder.addCase(asyncThunks.importDataAndConnectRequest.pending, (state) => {
      state.importDataStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.importDataAndConnectRequest.rejected, (state) => {
      state.importDataStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.importDataAndConnectRequest.fulfilled, (state, action) => {
      state.importDataStatus = fetchingStatus.succeeded as FetchStatus;
      dataStoreAdapter.addOne(state, action.payload);
      state.importDataResult = action.payload;
    });
    builder.addCase(asyncThunks.updateImportDataRequest.pending, (state) => {
      state.updateImportDataStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.updateImportDataRequest.rejected, (state) => {
      state.updateImportDataStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.updateImportDataRequest.fulfilled, (state) => {
      state.updateImportDataStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getImportDataRequest.pending, (state) => {
      state.getImportDataStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getImportDataRequest.rejected, (state) => {
      state.getImportDataStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getImportDataRequest.fulfilled, (state, action) => {
      state.getImportDataStatus = fetchingStatus.succeeded as FetchStatus;
      state.importDataResult = action.payload;
    });
  }
});

export default dataStoresSlice.reducer;

export const dataStoreActions = {
  importDataRequest: asyncThunks.importDataRequest,
  importDataAndConnectRequest: asyncThunks.importDataAndConnectRequest,
  updateImportDataRequest: asyncThunks.updateImportDataRequest,
  getImportDataRequest: asyncThunks.getImportDataRequest,
  ...dataStoresSlice.actions
};
