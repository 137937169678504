import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import PaymentModalLeftSide from "app/pages/pricing/PaymentModal/PaymentModalLeftSide";
import PaymentModalHeadlinePoppinsText from "app/pages/pricing/PaymentModal/PaymentModalHeadlinePoppinsText";
import { enterpriseUpgradeModalMessages } from "app/pages/pricing/messages";
import { useIntl } from "react-intl";
import { loadScript, unLoadScript } from "app/utils/helpers";
import { meetingsDetails } from "app/types";
import { hubSpotEnterpriseUrl } from "app/utils/urls";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const dataSrc = `${meetingsDetails}?embed=true`;

const MeetingContainer = styled.div`
  iframe {
    height: 600px !important;
  }
`;

const EnterpriseModal = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { returnToModal, editorModalOpen } = useModal();
  const visible = editorModalOpen?.open === ModalName.enterpriseUpgradeModal;
  const upgradeText = editorModalOpen?.context?.upgradeText;
  const upgradeSubText = editorModalOpen?.context?.upgradeSubText;

  useEffect(() => {
    const scriptId = "hubspot-script-meeting";
    if (visible) {
      loadScript(hubSpotEnterpriseUrl, scriptId, () => null);
    }

    if (!visible) {
      unLoadScript(scriptId);
    }
  }, [visible]);

  const onClose = () => {
    returnToModal();
  };

  return (
    <StyledModal
      width="927px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="0"
      $flex="1"
      $minHeight="792px"
    >
      <H1_FlexRow flex="1 1 auto">
        <PaymentModalLeftSide />
        <H1_FlexColumn width="423px" flex="1">
          <H1_FlexColumn flex="1" padding="30px 20px" width="100%" gap="16px">
            <PaymentModalHeadlinePoppinsText
              title={
                upgradeText || formatMessage(enterpriseUpgradeModalMessages.upgradeToRealsTitle)
              }
            />
            <H1_FlexColumn flex="1">
              <H1_TextSmall whiteSpace="break-spaces" lineHeight="18px" color={theme.gray11}>
                {upgradeSubText ||
                  formatMessage(enterpriseUpgradeModalMessages.upgradeToRealsSubTitle, {
                    br: <br />
                  })}
              </H1_TextSmall>

              <MeetingContainer className="meetings-iframe-container" data-src={dataSrc} />
            </H1_FlexColumn>
          </H1_FlexColumn>
        </H1_FlexColumn>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default EnterpriseModal;
