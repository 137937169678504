import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Config from "app/config/Config";

const StripWraper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const stripePromise = Config.stripe ? loadStripe(Config.stripe) : undefined;
  return stripePromise ? <Elements stripe={stripePromise}>{children}</Elements> : <>{children}</>;
};

export default StripWraper;
