import styled from "styled-components";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/upgrade-crown.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const StyledUpgradeIcon = styled(UpgradeIcon)`
  width: 22px;
  height: 22px;
  margin-bottom: 3px;
`;

export interface TopVideoActionsIconMenuItemProps {
  keyItem: string;
  onClick: () => void;
  text: string;
  showUpgradeIcon?: boolean;
  icon: React.ReactNode;
}

// TODO: covert to antd menu new stracture
const TopVideoActionsIconMenuItem = ({
  keyItem,
  text,
  onClick,
  showUpgradeIcon,
  icon
}: TopVideoActionsIconMenuItemProps) => {
  return (
    <H1_FlexRow
      key={keyItem}
      onClick={onClick}
      justify="space-between"
      data-auto-id={`top-video-actions-${keyItem}`}
    >
      <H1_FlexRow gap="14px" align="center">
        <H1_FlexRow flex="0 0 auto" width="20px" align="center" justify="center">
          {icon}
        </H1_FlexRow>
        <H1_TextSmall>{text}</H1_TextSmall>
      </H1_FlexRow>
      <ConditionalRender condition={showUpgradeIcon}>
        <StyledUpgradeIcon />
      </ConditionalRender>
    </H1_FlexRow>
  );
};

export default TopVideoActionsIconMenuItem;
