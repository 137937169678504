import { useAuth } from "app/auth/useAuth";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { useEffect } from "react";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import styled from "@emotion/styled";
import config from "app/config/Config";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const Circle = styled(H1_FlexRow)`
  position: relative;
  width: 223px;
  height: 223px;
  flex-shrink: 0;
  border-radius: 184px;
  background-color: #dcddff;
`;
const Actions = styled.div`
  display: flex;
  width: 420px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
`;

const LogoutButton = styled(Button)`
  display: flex;
  height: 40px;
  min-width: 80px;
  padding: 10px 66.803px 10px 67.197px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #3f3f46;
`;

const ContactSupportButton = styled(Button)`
  display: flex;
  height: 40px;
  min-width: 80px;
  padding: 10px 66.803px 10px 67.197px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
`;

const ErrorPage = () => {
  const { logout } = useAuth();
  const dispatch = useAppDispatch();

  const navigateToHomepage = () => {
    window.location.href = config.clientUrl;
  };
  useEffect(() => {
    dispatch(googleEvents.errorPageShowed());
  }, []);

  const onOpenZendesk = () => {
    if (typeof window.zE === "function") {
      window.zE("webWidget", "show");
    }
    window.zE.activate({ hideOnClose: true });
  };

  return (
    <H1_FlexColumn height={"100vh"}>
      <H1_FlexRow flex={"0 0 50px"} padding={"10px 0px 10px 10px"}>
        <H1_FlexRow onClick={navigateToHomepage}>
          <img
            src={
              "https://reals-public-perma-frontend-content.s3.us-east-2.amazonaws.com/reals-static-files/houroneIcon.svg"
            }
            alt="error"
          />
        </H1_FlexRow>
      </H1_FlexRow>
      <H1_FlexColumn flex={"1 1 calc(100% - 50px)"} justify="center" align={"center"}>
        <H1_FlexRow margin={"0 0 13px 0"}>
          <Circle>
            <img
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }}
              src={
                "https://reals-public-perma-frontend-content.s3.us-east-2.amazonaws.com/reals-static-files/houroneLogo.svg"
              }
              alt="error"
            />
          </Circle>
        </H1_FlexRow>
        <H1_FlexRow margin={"0 0 8px 0"}>
          <H1_TextMiddle>Sorry, Something went wrong</H1_TextMiddle>
        </H1_FlexRow>
        <H1_FlexRow margin={"0 0 18px 0"}>
          <H1_TextSmall>If this problem persists, please contact our support</H1_TextSmall>
        </H1_FlexRow>
        <H1_FlexRow>
          <Actions>
            <LogoutButton onClick={() => logout()}>
              <H1_TextSmall color={"#FFF"}>Logout and try again</H1_TextSmall>
            </LogoutButton>
            <ContactSupportButton variant="light" onClick={onOpenZendesk}>
              <H1_TextSmall>Contact Support</H1_TextSmall>
            </ContactSupportButton>
          </Actions>
        </H1_FlexRow>
        <H1_FlexRow></H1_FlexRow>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default ErrorPage;
