import { MenuProps } from "antd";
import React, { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { videoReadyModalMessages } from "app/pages/editor/messages";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { PlanFeature } from "app/config/planFeature";
import {
  PlanEnum,
  SupportedSubtitlesFileExtension,
  SupportedTranslationSubtitlesLanguagesCode,
  SupportedTranslationSubtitlesLanguagesFlagsCode,
  Video
} from "app/types";
import { videosActions } from "app/store/slices/videos.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { paymentModalMessages } from "app/pages/pricing/messages";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { fetchingStatus } from "app/utils/helpers";
import { useIntl } from "react-intl";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import useModal, { ModalName } from "app/hooks/useModal";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { capitalize } from "lodash";

const StyledMoreDropdown = styled(H1_Dropdown)`
  color: ${(props) => props.theme.gray1};
  background-color: ${({ theme }) => theme.gray7};
  box-shadow: none;
  min-width: 43px;
  width: 43px;
  min-height: 43px;
  &:hover {
    background-color: ${({ theme }) => theme.gray6}cc;
    color: ${(props) => props.theme.gray1};
  }
  .ant-dropdown-menu {
    padding: 8px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.gray2};
    color: ${(props) => props.theme.gray11};
  }
  &.overlay-more-dropdown {
    .ant-dropdown {
      width: 250px;
    }
    max-height: 370px;
    height: 30px;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu {
      .ant-dropdown-menu-submenu-expand-icon {
        svg {
          color: ${(props) => props.theme.gray11};
          fill: ${(props) => props.theme.gray11};
        }
        top: calc(50% - 15px);
      }
      .ant-dropdown-menu-title-content {
        color: ${(props) => props.theme.gray11};
      }
      color: ${(props) => props.theme.gray11};
      transition: none;
      padding: 11px 12px;
      border-radius: 10px;
      &:hover {
        background-color: ${({ theme }) => theme.blue4};
        color: white;
        span {
          color: white;
        }
        svg {
          fill: white;
        }
      }
      &&&&.ant-dropdown-menu-item-active {
        background-color: ${({ theme }) => theme.blue4};
        color: ${({ theme }) => theme.gray1};
        span {
          color: ${({ theme }) => theme.gray1};
        }
      }
      .ant-dropdown-menu-item,
      .ant-dropdown-menu-submenu-title {
        transition: none;
        padding: 0;
        &:hover {
          background-color: ${({ theme }) => theme.blue4};
          color: ${({ theme }) => theme.gray1};
          i {
            color: ${({ theme }) => theme.gray1};
          }
        }
      }
      .ant-dropdown-menu-submenu .ant-dropdown-menu-item {
        &:hover {
          background-color: ${({ theme }) => theme.blue4};
          color: ${({ theme }) => theme.gray1};

          span,
          i {
            color: ${({ theme }) => theme.gray1};
          }
        }
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 11px 12px;
    }
  }
`;
interface DownloadScormProps {
  source: string;
  video: Video;
  draftId: string;
  backModal?: ModalName;
}
interface MoreMenuButtonProps {
  selectedVideo?: Video;
}
const MoreMenuButton = ({ selectedVideo }: MoreMenuButtonProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { openModal } = useModal();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const showPaymentModal = useUpgradeModal();
  const { formatMessage } = useIntl();

  const showCrownScorm = useShowCrown({
    requestedFeature: PlanFeature.ScormExport
  });

  const translateVideoSubtitlesStatus = useAppSelector(
    (state) => state.videos.translateVideoSubtitlesStatus
  );
  const translateVideoLoading = translateVideoSubtitlesStatus === fetchingStatus.loading;

  const handleTranslateSubtitles = (
    languageCode: SupportedTranslationSubtitlesLanguagesCode,
    fileExtension: SupportedSubtitlesFileExtension
  ) => {
    dispatch(googleEvents.exportSubtitles({ source: "playModal" }));

    if (
      !selectedVideo?.paid &&
      showPaymentModal({
        source: "playModal-download-subtitles",
        reqId: selectedVideo?.req_id,
        upgradeText: formatMessage(paymentModalMessages.upgradeDownloadSubtitlesTitle),
        backModal: ModalName.videoReady,
        requestedFeature: PlanFeature.DownloadVideoSubtitles,
        targetPlan: PlanEnum.business
      })
    ) {
      return;
    }

    dispatch(
      videosActions.translateVideoSubtitles({
        videoId: selectedVideo?.id as string,
        translateSubtitlesRequest: {
          language_code: languageCode,
          file_extension: fileExtension
        }
      })
    );
  };

  const getFlagIconUrl = (locale: string) => `https://flagcdn.com/${locale}.svg`;

  const generateSubtitlesMenuItems = (
    fileExtension: SupportedSubtitlesFileExtension
  ): MenuProps["items"] => {
    return Object.entries(SupportedTranslationSubtitlesLanguagesCode).map(([language, locale]) => {
      const labelKey = `download${capitalize(
        language
      )}SubtitlesFileButton` as keyof typeof videoReadyModalMessages;
      const message = videoReadyModalMessages[labelKey];
      if (!message) {
        return null;
      }

      return {
        key: `${language}-${fileExtension}`,
        icon: (
          <>
            <img
              src={getFlagIconUrl(
                SupportedTranslationSubtitlesLanguagesFlagsCode[
                  language as keyof typeof SupportedTranslationSubtitlesLanguagesFlagsCode
                ]
              )}
              alt={`${language} flag`}
              style={{ width: 20, height: 14, marginRight: 8 }}
            />
          </>
        ),
        onClick: () =>
          handleTranslateSubtitles(
            locale as SupportedTranslationSubtitlesLanguagesCode,
            fileExtension
          ),
        label: formatMessage(message)
      };
    });
  };

  const handleScormExport = ({ source, video }: DownloadScormProps) => {
    dispatch(googleEvents.downloadIntentScorm({ source }));

    if (
      !video?.paid &&
      showPaymentModal({
        source: "playModal-export-scorm",
        reqId: video.req_id,
        upgradeText: formatMessage(paymentModalMessages.upgradeExportScormTitle),
        backModal: ModalName.videoReady,
        requestedFeature: PlanFeature.ScormExport,
        targetPlan: PlanEnum.business
      })
    ) {
      return;
    }

    const orderId = uuidv4();
    dispatch(
      videosActions.downloadScormVideoRequest({
        videoId: video.id,
        orderId
      })
    );

    setOpenMenu(false);
    openModal(ModalName.scormLoading, { orderId });
  };

  const itemsMore: MenuProps["items"] = useMemo(
    () => [
      {
        key: "subtitles-vtt",
        label: formatMessage(videoReadyModalMessages.downloadSubtitlesFileAsVTTButton),
        children: generateSubtitlesMenuItems(SupportedSubtitlesFileExtension.vtt)
      },
      {
        key: "subtitles-srt",
        label: formatMessage(videoReadyModalMessages.downloadSubtitlesFileAsSRTButton),
        children: generateSubtitlesMenuItems(SupportedSubtitlesFileExtension.srt)
      },
      {
        key: "export-scorm",
        label: (
          <H1_FlexRow gap="10px" align="center">
            {formatMessage(videoReadyModalMessages.exportScormButton)}
            {showCrownScorm && <H1_Icon color={theme.orange3} icon="fas fa-crown" />}
          </H1_FlexRow>
        ),
        onClick: () =>
          handleScormExport({
            source: "exportScorm",
            video: selectedVideo as Video,
            draftId: selectedVideo?.draft_id as string
          })
      }
    ],
    [selectedVideo, translateVideoLoading]
  );

  return (
    <StyledMoreDropdown
      overlayClassName="overlay-more-dropdown"
      onlyIcon={true}
      icon={
        translateVideoLoading ? (
          <i className="fas fa-spinner fa-spin" />
        ) : (
          <i className="fa-light fa-ellipsis" />
        )
      }
      destroyPopupOnHide
      menu={{ items: itemsMore }}
      trigger={["click", "hover"]}
      className="overlay-more-dropdown"
      getPopupContainer={() => document.querySelector(`.overlay-more-dropdown`) as HTMLElement}
      open={openMenu}
      onOpenChange={setOpenMenu}
    />
  );
};

export default MoreMenuButton;
