import { createSelector } from "reselect";
import { foldersGlobalSelectors } from "app/store/adapters/adapters";
import { dateSorter } from "app/utils/helpers";

const DEFAULT_FOLDER_NAMES = ["default", "My First Project"];

export const getFoldersSortByUpdate = createSelector(
  [foldersGlobalSelectors.selectAll],
  (folders) => {
    const result = [...folders];
    result.sort((a, b) => {
      if (DEFAULT_FOLDER_NAMES.includes(a.name as string)) {
        return -1;
      }
      if (DEFAULT_FOLDER_NAMES.includes(b.name as string)) {
        return 1;
      }
      return dateSorter(a.updated_at as string, b.updated_at as string);
    });
    return result;
  }
);

export const getFoldersByPageAndQuery = createSelector(
  [
    getFoldersSortByUpdate,
    // Do not use typed arguments because objecting them will cause re-rendering every time redux state chagnes, for object is only a reference https://redux.js.org/usage/deriving-data-selectors#using-selectors-with-react-redux
    (state, currentPage?: number, query?: string, totalNumberOfFoldersPerPage?: number) => ({
      currentPage,
      query,
      totalNumberOfFoldersPerPage
    })
  ],
  (folders, { currentPage = 1, query = "", totalNumberOfFoldersPerPage = 10 }) => {
    const currentItemsNumber = (currentPage - 1) * totalNumberOfFoldersPerPage;
    const filterFolders = folders.filter((folder) =>
      folder.name?.toLowerCase().includes(query.trim().toLowerCase())
    );
    return {
      filterFolders,
      slicedFolders: filterFolders.slice(
        currentItemsNumber,
        currentItemsNumber + totalNumberOfFoldersPerPage
      ),
      totalFolders: filterFolders.length
    };
  }
);

export const getFoldersSortAlphabetically = createSelector(
  [foldersGlobalSelectors.selectAll],
  (folders) => {
    const result = [...folders];
    result.sort((a, b) => {
      if (DEFAULT_FOLDER_NAMES.includes(a.name as string)) {
        return -1;
      }
      if (DEFAULT_FOLDER_NAMES.includes(b.name as string)) {
        return 1;
      }
      return a.name?.localeCompare(b.name as string) || 0;
    });
    return result;
  }
);
