import React from "react";
import {
  SegmentContextContext,
  SegmentTrackProps
} from "app/components/_Infrastructure/core/segment-context";

// Create an analytics hook that we can use with other components.
export const useAnalytics: () => React.ContextType<typeof SegmentContextContext> = () => {
  const result = React.useContext(SegmentContextContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};

export type UseSegmentProps = {
  /**
   * Dont actualy send events to segment
   */
  dryRun?: boolean;
};

/* eslint-disable consistent-return */
export const useSegment = ({ dryRun = false }: UseSegmentProps) => {
  const { analytics, prependSend } = useAnalytics();
  if (!analytics) {
    console.error("No analytics object passed to useSegment hook -> Events aren't beeing sent");
    return;
  }

  const page = (): void => {
    if (!dryRun) analytics?.page();
    if (prependSend) prependSend("page");
  };

  const track = ({ name, properties }: SegmentTrackProps): void => {
    if (!dryRun) analytics.track(name, properties);
    if (prependSend) prependSend("track", { name, properties });
  };

  const identify = (
    userId: string,
    permissions: string,
    userEmail: string,
    data: any = {}
  ): void => {
    if (!dryRun) {
      analytics?.identify(userId, {
        permissions,
        email: userEmail,
        ...data
      });
    }
    if (prependSend)
      prependSend("identify", {
        userId,
        permissions,
        email: userEmail,
        ...data
      });
  };

  const reset = (): void => {
    if (!dryRun) analytics?.reset();
    if (prependSend) prependSend("reset");
  };

  return {
    page,
    track,
    identify,
    reset
  };
};
