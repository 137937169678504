import { Folder } from "app/types";
import styled from "styled-components";
import React, { useMemo } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import FolderItemDropdown from "app/components/common/DraftsAndFolders/FolderItemDropdown";
import { useNavigate } from "react-router-dom";
import GridItem from "app/components/common/GridItem";
import { getTimeAgo } from "app/utils/helpers";

const FolderIcon = styled.i`
  font-size: 1.625rem;
  color: ${(props) => props.theme.gray1};
`;

const FolderIconContainerFlexRow = styled(H1_FlexRow)`
  background-color: ${(props) => props.theme.blue1};
  border-radius: 4px 4px 0 0;
`;

const StyledGridItem = styled(GridItem)`
  border-radius: 5px;
  &:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    ${FolderIconContainerFlexRow} {
      filter: brightness(70%);
      transition: filter 0.3s ease-in-out;
    }
  }
  &:active {
    box-shadow: none;
  }
`;

interface FolderGridItemProps {
  folder: Folder;
}

const FolderGridItem = ({ folder }: FolderGridItemProps) => {
  const navigate = useNavigate();
  const updatedAt = useMemo(() => getTimeAgo(folder.updated_at as string), [folder]);

  const onSelectFolder = () => {
    navigate(`/folders/${folder.id}`);
  };

  return (
    <StyledGridItem
      title={folder.name as string}
      subTitle={updatedAt}
      dropdown={<FolderItemDropdown folderItem={folder} />}
      onClick={onSelectFolder}
    >
      <FolderIconContainerFlexRow
        height="calc(12.3125rem * 9 / 16)"
        align="center"
        justify="center"
      >
        <FolderIcon className="fa-solid fa-folder" />
      </FolderIconContainerFlexRow>
    </StyledGridItem>
  );
};

export default FolderGridItem;
