import { AuthContextState } from "app/auth/authContext";
import {
  AuthAction,
  ErrorAction,
  InitializeAction,
  LoginAction
} from "app/auth/actions/AuthActions";

export const AuthMiddleWare: Record<
  string,
  (state: AuthContextState, action: AuthAction) => AuthContextState
> = {
  INITIALIZE: (state: AuthContextState, action: AuthAction): AuthContextState => {
    const { isAuthenticated, user, accessToken, expiresIn } = (action as InitializeAction).payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      accessToken,
      error: null,
      expiresIn
    };
  },
  LOGIN: (state: AuthContextState, action: AuthAction): AuthContextState => {
    const { user, accessToken, expiresIn } = (action as LoginAction).payload;
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
      accessToken,
      error: null,
      expiresIn
    };
  },
  ERROR: (state: AuthContextState, action: AuthAction): AuthContextState => {
    const { error } = (action as ErrorAction).payload;
    return {
      ...state,
      error
    };
  },
  LOGOUT: (state: AuthContextState): AuthContextState => {
    return {
      ...state,
      isAuthenticated: false,
      isInitialized: true,
      user: null,
      accessToken: null,
      error: null
    };
  }
};
