import { Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Draft, EditorView, InternalScene } from "app/types";
import ScenePreview from "app/components/editor/scene/ScenePreview";
import TranscriptPreview from "app/components/editor/scene/TranscriptPreview";
import styled, { css, useTheme } from "styled-components";
import SceneMixedTagged, {
  StyledLexicalEditor
} from "app/components/editor/scene/SceneMixedTagged";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneVoiceStatus } from "app/store/thunks/analyticsEvents.thunk";
import UiActions from "app/store/actions/ui.actions";
import { voicesActions } from "app/store/slices/voices.slice";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import { getCurrentWorkspace, getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { useUpdateMyPresence } from "app/services/liveBlocksClient";
import Selections from "app/components/editor/scene/Selections";
import ConditionalRender from "app/components/common/ConditionalRender";
import useModal, { ModalName } from "app/hooks/useModal";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import usePermissions from "app/hooks/usePermissions";
import { getTextOutOfSML } from "app/utils/helpers";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import SceneOutsideToolbar from "app/components/editor/scene/SceneOutsideToolbar";
import { getSceneEST } from "app/store/selectorsV2/scenes.selectors";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import AugmentedSelectionContainer from "app/components/editor/scene/AugmentedSelection/AugmentedSelectionContainer";

const PERCENT_TO_SHOW_SCENE_LENGTH = 80;
interface SceneProps {
  scene: InternalScene;
  idx: number;
  isDragging?: boolean;
}

const handleWidthByAspectRatio = (aspectRatio: number) => {
  switch (aspectRatio) {
    case 16 / 9:
      return "174px";
    case 9 / 16:
      return "98px";
    case 1:
      return "98px";
    default:
      return aspectRatio > 1 ? "174" : aspectRatio < 1 ? "98px" : "98px";
  }
};

const handleHeightByAspectRatio = (aspectRatio: number) => {
  switch (aspectRatio) {
    case 16 / 9:
      return "98px";
    case 9 / 16:
      return "174px";
    case 1:
      return "98px";
    default:
      return aspectRatio > 1 ? "174" : aspectRatio < 1 ? "98px" : "98px";
  }
};

const SceneListenButtonContainer = styled(H1_FlexRow)`
  position: relative;
  z-index: 2;
  align-self: center;
  right: -44px;
`;

export const SceneContentContainer = styled(H1_FlexColumn)<{ error?: boolean }>`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  max-width: 480px;
  width: 480px;
  min-height: 66px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  ${({ error, theme }) => css`
    border: ${error && `1px solid ${theme.pink4}`};
  `}
`;

export const TextContainer = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  transition: 0.3s all ease-in-out;
  min-height: 66px;
  color: #0f0c1d;
`;

const SceneEstimation = styled(H1_FlexRow)<{ $selected: boolean }>`
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: ${({ $selected }) => ($selected ? 1 : 0)};
  z-index: ${({ $selected }) => ($selected ? 2 : -1)};
`;

const ScenePreviewContainer = styled.div<{ error?: boolean; $aspectRatio: number }>`
  border: 1px solid ${({ theme }) => theme.gray3};
  height: ${({ $aspectRatio }) => handleHeightByAspectRatio($aspectRatio)};
  width: 174px;
  min-width: 174px;
  align-self: flex-start;
  border-radius: 12px;
  overflow: hidden;
  // (# TODO: make it work with other false / css value)
  aspect-ratio: ${(props: { $aspectRatio: number; error?: boolean }) => props.$aspectRatio || "0"};
  opacity: ${(props: { $aspectRatio: number; error?: boolean }) => (props.$aspectRatio ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  ${({ error, theme }) => css`
    border: ${error && `1px solid ${theme.pink4}`};
  `}
  display: flex;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    align-self: center;
    margin-bottom: 20px;
  }
`;

const StyledFlex = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
  }
`;

export const CorneredIconButton = styled(Button)<{ $iconSize: string }>`
  opacity: 0;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in, background 0.3s ease-in;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  height: ${({ $iconSize }) => $iconSize};
  width: ${({ $iconSize }) => $iconSize};
  min-width: ${({ $iconSize }) => $iconSize};
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.gray1}d9 0%,
    ${({ theme }) => theme.gray1}8c 100%
  );
  &:hover {
    i {
      color: ${({ theme }) => theme.gray11};
    }
    transform: scale(1.1);
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.gray1}d9 0%,
      ${({ theme }) => theme.gray1}d9 100%
    );
  }
  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }
`;

const StyledListItem = styled.div<{ selected?: boolean; $error?: boolean; $isDragging?: boolean }>`
  position: relative;
  left: -14px;
  flex-direction: row-reverse;
  max-width: 906px;
  transition: 0.3s all ease-in-out;
  box-shadow: ${({ $isDragging }) => ($isDragging ? "rgba(0, 0, 0, 0.2) 0 7px 14px 0" : "none")};
  border-radius: 10px;
  cursor: pointer;
  min-width: 350px;
  width: 765px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  border: 1px solid transparent;
  && {
    padding: 18px 23px; /* override antd */
  }
  &&:hover {
    border: 1px solid
      ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray3 : "transparent")};
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray4};
    ${StyledLexicalEditor}, ${SceneContentContainer}, ${TextContainer} {
      background-color: ${({ theme }) =>
        theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray4};
    }
  }

  &&& {
    ${({ selected, theme }) =>
      selected &&
      css`
        border: 1px solid ${theme.mode === ThemeMode.Light ? theme.blue2 : "transparent"};
        background-color: ${({ theme }) =>
          theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray3};
        margin-top: 20px;
        ${StyledLexicalEditor}, ${SceneContentContainer}, ${TextContainer} {
          background-color: ${({ theme }) =>
            theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray3};
        }
      `}

    ${({ $error, theme }) =>
      $error &&
      css`
        border: 1px solid ${theme.pink4};
      `}

    &:hover {
      ${CorneredIconButton} {
        opacity: 1;
      }
    }
    &:active {
      border: 1px solid ${({ theme }) => theme.blue2};
    }
  }
`;

const Scene = ({ scene, idx, isDragging }: SceneProps) => {
  const [currentInnerText, setCurrentInnerText] = useState<string>("");
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { isWriteWorkspaceAllow } = usePermissions();

  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);

  const viewOnly = workflowLiveMode || !isWriteWorkspaceAllow;

  // @ts-ignore handels userUI store typing
  const sceneIdsErrors = useAppSelector((state) => state.userUi.sceneIdsError);
  // @ts-ignore handels userUI store typing
  const validationScroll = useAppSelector((state) => state.userUi.validationScroll);
  const aspectRatio = useAppSelector(templatesSelectors.getAspectRatio);
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const updateMyPresence = useUpdateMyPresence();
  const limits = useAppSelector(getLimits);
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const draftId: string = draft?.id as string;
  const maxTranscriptLength = limits?.chars_per_scene as number;
  const selectedSceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const transcriptUrl = scene.attributes?.media?.transcript?.url;
  const sceneEST = useAppSelector((state) =>
    getSceneEST(state, {
      sceneId: scene.id as string
    })
  );

  const sceneId = scene.id as string;
  const currentSceneErrors = sceneIdsErrors[sceneId];
  const isErrorInTranscript = currentSceneErrors && currentSceneErrors.transcript;
  const isErrorInMedia = currentSceneErrors && currentSceneErrors.media;
  const validationError = isErrorInTranscript || isErrorInMedia;
  const isWorkspaceWithMultipleMembers = (currentWorkspace?.members.length || 0) > 1;
  const isCurrentSceneSelected = selectedSceneId === sceneId;
  const showSceneLength =
    isCurrentSceneSelected &&
    currentInnerText?.length / maxTranscriptLength >= PERCENT_TO_SHOW_SCENE_LENGTH / 100;

  const currentSceneText = useMemo(() => {
    if (scene?.attributes?.text?.transcript?.synthesis_markup_language) {
      return getTextOutOfSML(scene?.attributes?.text?.transcript?.synthesis_markup_language.nodes);
    }
    return scene?.attributes?.text?.transcript?.text as string;
  }, [
    scene?.attributes?.text?.transcript?.text,
    scene?.attributes?.text?.transcript?.synthesis_markup_language
  ]);

  useEffect(() => {
    setCurrentInnerText(currentSceneText);
    dispatch(voicesActions.cleanFooterAudio());
    dispatch(voicesActions.cleanVoicePreviewV2());
  }, [currentSceneText]);

  useEffect(() => {
    if (validationScroll && validationError) {
      const errorElement = document.querySelector(`[data-scene-index="scene-${idx}"]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
      }
      dispatch(UiActions.setValidationScroll(false));
    }
  }, [validationScroll, validationError]);

  const onSelectScene = () => {
    dispatch(
      analyticsEvents.selectScene({
        selectedScene: { name: scene.name, index: idx + 1, id: sceneId },
        view: EditorView.Multiple
      })
    );
    dispatch(scenesActions.setSelectedSceneId(sceneId));
    openDrawer(Drawer.Scene);
  };

  const onBlur = () => {
    if (isWorkspaceWithMultipleMembers) {
      if (updateMyPresence) {
        updateMyPresence({ sceneId: undefined, email: undefined });
      }
    }
  };

  const onUseVoice = () => {
    dispatch(
      analyticsEvents.useVoice({
        selectedScene: {
          name: scene.name,
          id: sceneId,
          index: idx
        },
        status: SceneVoiceStatus.click
      })
    );
    openModal(ModalName.transcriptAudioUploadModal, { sceneId, draftId, name: scene.name, idx });
  };

  return (
    <StyledListItem
      $error={isErrorInTranscript}
      selected={selectedSceneId === sceneId}
      key={`wrapper-${sceneId}`}
      onClick={onSelectScene}
      data-scene-index={`scene-${idx}`}
      data-auto-id={`editor-scenes-list-scene-${idx}`}
      onBlur={onBlur}
      $isDragging={isDragging}
    >
      <AugmentedSelectionContainer sceneId={sceneId} />
      <StyledFlex key={`transcript-${idx}`}>
        <SceneEstimation $selected={selectedSceneId === sceneId}>
          <Tooltip title={formatMessage(sceneMessages.timeEst)} align={{ offset: [0, 10] }}>
            <>
              <H1_TextSmall color={theme.blue2}>{sceneEST}</H1_TextSmall>
            </>
          </Tooltip>
        </SceneEstimation>
        <SceneOutsideToolbar sceneId={sceneId} idx={idx} />
        <ScenePreviewContainer
          $aspectRatio={aspectRatio}
          error={isErrorInMedia}
          data-auto-id={`editor-scenes-list-preview-scene-${idx}`}
        >
          <ConditionalRender condition={isWorkspaceWithMultipleMembers}>
            <Selections sceneId={sceneId} />
          </ConditionalRender>
          <ScenePreview
            disabledPreview
            height={handleHeightByAspectRatio(aspectRatio)}
            width={handleWidthByAspectRatio(aspectRatio)}
            sceneId={sceneId}
            url={scene.last_preview_url}
          />
        </ScenePreviewContainer>
        <H1_FlexRow padding="0 0 0 29px" gap="41px" width="100%">
          <H1_FlexColumn gap="5px" position="relative">
            <SceneContentContainer
              padding="0 14px 0 0"
              flex="1 1 auto"
              data-auto-id={`editor-scenes-list-text-editor-scene-${idx}`}
            >
              <TextContainer flex="1 0 auto">
                <SceneMixedTagged
                  scene={scene}
                  idx={idx}
                  setInnerText={setCurrentInnerText}
                  currentInnerText={currentInnerText}
                  onUseVoice={onUseVoice}
                  viewOnly={viewOnly}
                  hideToolbar={!!transcriptUrl || viewOnly}
                />
              </TextContainer>
            </SceneContentContainer>
            <ConditionalRender condition={showSceneLength}>
              <Tooltip
                title={formatMessage(sceneMessages.charactersLeft)}
                mouseEnterDelay={0.5}
                align={{ offset: [0, 10] }}
              >
                <H1_FlexRow width="fit-content">
                  <H1_TextSmall
                    margin="2px 0 0 0"
                    color={
                      currentInnerText?.length > maxTranscriptLength ? theme.pink4 : theme.gray7
                    }
                  >
                    {currentInnerText?.length}/{maxTranscriptLength}
                  </H1_TextSmall>
                </H1_FlexRow>
              </Tooltip>
            </ConditionalRender>
          </H1_FlexColumn>
        </H1_FlexRow>
        <SceneListenButtonContainer flex="0 0 41px">
          <TranscriptPreview transcript={currentSceneText} sceneId={scene.id} idx={idx} />
        </SceneListenButtonContainer>
      </StyledFlex>
    </StyledListItem>
  );
};

export default Scene;
