/* eslint-disable react/jsx-pascal-case,react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";

import { useIntl } from "react-intl";

import "app/pages/HomePage/HomePage.scss";
import { homePageMessages } from "app/pages/HomePage/messages";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { SwiperSlide } from "swiper/react";
import { ThemeMode } from "app/utils/theme";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import useModal, { ModalName } from "app/hooks/useModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "app/types";
import PodcastModal from "app/pages/HomePage/PodcastModal/PodcastModal";
import { Button } from "@nextui-org/react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useUpgradeToEnterprise } from "app/hooks/useUpgradeModal";

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  padding-bottom: 5px;
  .swiper-slide {
    width: calc(160px * 16 / 9);
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 74px;
    justify-content: center;
  }
  && .swiper-button-prev {
    left: 30px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }

  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }
`;

const GradientCoverFlexRow = styled(H1_FlexRow)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(24, 24, 27, 0.9) 90%);
`;

const ButtonsFlexRow = styled(H1_FlexRow)`
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;

const PositionButton = styled(Button)`
  min-width: 20px;
  background-color: rgba(202, 202, 202, 0.6);
  color: white;
  span {
    color: white;
  }
  border: none;
  &:hover,
  &:active {
    border: none;
    color: white;
    span {
      color: white;
    }
    background-color: rgba(202, 202, 202, 1);
    &&& i {
      color: white;
      fill: white;
    }
  }
`;

const ContactSalesGradientCoverFlexRow = styled(H1_FlexRow)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(24, 24, 27, 0.9) 90%);
  &:hover {
    ${ButtonsFlexRow} {
      z-index: 2;
      opacity: 1;
    }
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.4);
    img {
      filter: brightness(70%);
    }
  }
`;
const CardFlexRow = styled(H1_FlexRow)`
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(24, 24, 27, 0.9) 100%),
    lightgray 50% / cover no-repeat;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(24, 24, 27, 0.9) 100%);
  img {
    transition: filter 0.3s ease-in-out;
  }
  &:hover {
    img {
      filter: brightness(120%);
    }
  }
`;

const AbsoluteText = styled(H1_TextSmall)`
  position: absolute;
  bottom: 9px;
  left: 12px;
`;
const MediaEntertainmentStrip = () => {
  const [isPodcastModalOpen, setIsPodcastModalOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { openModal } = useModal();
  const flags = useFlags();
  const upgradeToEnterprise = useUpgradeToEnterprise();

  const onClickInterview = () => {
    openModal(ModalName.templatePreview, {
      recipeId: flags[FeatureFlag.interviewRecipeId],
      recipeCategoryName: "Custom"
    });
  };

  const onClickPodcast = () => {
    if (flags[FeatureFlag.podcast]) {
      openModal(ModalName.templatePreview, {
        recipeId: flags[FeatureFlag.podcastRecipeId],
        recipeCategoryName: "Custom"
      });
    }
  };

  const onClickContactSales = () => {
    upgradeToEnterprise({ source: "homepage_m_a_e" });
  };

  const mediaEntertainmentCards: any[] = useMemo(() => {
    return [
      {
        text: "Interview",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/interview.webp"
            alt=""
          />
        ),
        onClick: onClickInterview
      },
      {
        text: "Podcast",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/podcast.webp"
            alt=""
          />
        ),
        onClick: onClickPodcast
      },
      {
        text: "Stage",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/stage.webp"
            alt=""
          />
        )
      },
      {
        text: "Fireside",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/fireside.webp"
            alt=""
          />
        )
      },
      {
        text: "Testimonial",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/testimonial.webp"
            alt=""
          />
        )
      },
      {
        text: "Commercial",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/commercial.webp"
            alt=""
          />
        )
      },
      {
        text: "Stand up",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/stand_up.webp"
            alt="stand_up"
          />
        )
      },
      {
        text: "Debate",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/debate.webp"
            alt=""
          />
        )
      },
      {
        text: "Late night show",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/late_night_show.webp"
            alt=""
          />
        )
      },
      {
        text: "Talk show",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/talk_show.webp"
            alt=""
          />
        )
      },
      {
        text: "Panel",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/sport_panel.webp"
            alt=""
          />
        )
      },
      {
        text: "Sitcom",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/sitcom.webp"
            alt=""
          />
        )
      },
      {
        text: "Shark Tank",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/shark_tank.webp"
            alt=""
          />
        )
      }
    ];
  }, []);

  return (
    <H1_FlexColumn padding="2rem 0.875rem 0 0" width="100%" flex="1 0 auto">
      <PodcastModal visible={isPodcastModalOpen} onClose={() => setIsPodcastModalOpen(false)} />
      <H1_FlexRow
        align="center"
        justify="space-between"
        padding="0 0 1.3125rem 0"
        margin="0 0 0 3.125rem"
      >
        <H1_TextMiddle
          color={theme.mode === ThemeMode.Light ? theme.gray8 : theme.gray5}
          fontWeight={500}
        >
          {formatMessage(homePageMessages.socialMediaCollection)}
        </H1_TextMiddle>
      </H1_FlexRow>
      <H1_FlexRow
        height="100%"
        width="100%"
        position="relative"
        padding="0 2.25rem 1.875rem 3.125rem"
        overflow="hidden"
        gap="30px"
      >
        <StyledSwiper
          navigation
          speed={700}
          spaceBetween={28}
          slidesPerView="auto"
          slidesPerGroup={4}
          lazy
        >
          {mediaEntertainmentCards.map((mediaEntertainmentCard, index: number) => (
            <SwiperSlide key={index}>
              <CardFlexRow
                overflow="hidden"
                position="relative"
                width="265px"
                flex="1 0 265px"
                height="147px"
                onClick={mediaEntertainmentCard.onClick}
              >
                {mediaEntertainmentCard.image}
                <GradientCoverFlexRow />
                <AbsoluteText color="#D4D4D8">{mediaEntertainmentCard.text}</AbsoluteText>
                <ConditionalRender condition={!mediaEntertainmentCard.onClick}>
                  <ContactSalesGradientCoverFlexRow position="relative">
                    <ButtonsFlexRow
                      position="relative"
                      height="100%"
                      width="100%"
                      align="center"
                      justify="center"
                      gap="0.625rem"
                    >
                      <PositionButton onClick={onClickContactSales}>Contact sales</PositionButton>
                    </ButtonsFlexRow>
                  </ContactSalesGradientCoverFlexRow>
                </ConditionalRender>
              </CardFlexRow>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default MediaEntertainmentStrip;
