import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { videoWizardV2Messages } from "app/components/common/VideoWizardModalV2/messages";
import { VideoWizardV2Properties, VideoWizardWorkflowVariables } from "app/types";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { capitalize } from "lodash-es";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { fetchingStatus } from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import { getVariablesOptions } from "app/store/selectorsV2/drafts.selectors";
import { Button, Input, Textarea } from "@nextui-org/react";

const BackButton = styled(Button)`
  position: absolute;
  top: 10px;
  left: 10px;
`;
const BorderedButton = styled(Button)`
  margin-top: 20px;
  align-self: flex-end;
  i {
    font-size: 12px;
  }
`;

const ContainerFlexRow = styled(H1_FlexRow)<{ $opacity: boolean }>`
  opacity: ${({ $opacity }) => ($opacity ? 0.5 : 1)};
  pointer-events: ${({ $opacity }) => ($opacity ? "none" : "auto")};
`;

const StyledForm = styled(H1_FlexColumn)`
  flex: 1;
  overflow: hidden;
  padding: 49px 48px 43px 43px;
`;

const BorderFlexColumn = styled(H1_FlexColumn)`
  border-top: 1px solid ${({ theme }) => theme.gray4};
`;

interface VideoWizardV2Stage2Props {
  variablesValues: Record<string, string>;
  onChangeProperties: (key: keyof VideoWizardV2Properties, value: any) => void;
  onClickNext: () => void;
  onClickBack: () => void;
  subCategoryTitle: string;
  category: string;
  subCategory: string;
}
const VideoWizardV2Stage2 = ({
  onClickNext,
  onClickBack,
  onChangeProperties,
  variablesValues,
  subCategoryTitle,
  category,
  subCategory
}: VideoWizardV2Stage2Props) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const createWorkflowCategoriesStatus = useAppSelector(
    (state) => state.drafts.createWorkflowCategoriesStatus
  );
  const topicToDraftStatus = useAppSelector((state) => state.drafts.topicToDraftStatus);
  const variables: VideoWizardWorkflowVariables[] = useAppSelector((state) =>
    getVariablesOptions(state, category, subCategory)
  );
  const isCreateDraftLoading = createWorkflowCategoriesStatus === fetchingStatus.loading;
  const isTopicToDraftLoading = topicToDraftStatus === fetchingStatus.loading;
  const isTopicToDraftSucceeded = topicToDraftStatus === fetchingStatus.succeeded;
  const shouldShowOpacity = isTopicToDraftLoading || isTopicToDraftSucceeded;

  const onChangeVariable = (e: React.ChangeEvent<HTMLInputElement>, variableName: string) => {
    const { value } = e.target;
    onChangeProperties("variablesValues", { ...variablesValues, [variableName]: value });
  };

  const onChangeTextVariable = (value: string, variableName: string) => {
    onChangeProperties("variablesValues", { ...variablesValues, [variableName]: value });
  };

  const onClickCtaButton = () => {
    onClickNext();
  };

  const onClickBackButton = () => {
    onClickBack();
  };

  return (
    <ContainerFlexRow flex="0 0 auto" height="631px" $opacity={shouldShowOpacity}>
      <StyledForm>
        <BackButton
          size="sm"
          onClick={onClickBackButton}
          variant="light"
          isIconOnly
          startContent={<H1_Icon icon="fas fa-arrow-left" isCursorPointer />}
        />
        {/* Title */}
        <H1_TextSubtitle>{subCategoryTitle}</H1_TextSubtitle>
        <BorderFlexColumn
          padding="17px 0 0 0"
          margin="15px 0 0 0"
          align="center"
          gap="18px"
          overflow="hidden auto"
          flex="1 1 auto"
        >
          {variables.map((variable) => (
            <H1_FlexColumn flex="0 0 auto" gap="2px" width="100%" key={variable.name}>
              <H1_TextSmall color={theme.gray9} fontWeight={600} lineHeight="32px">
                {capitalize(variable.name.split("_").join(" "))}
              </H1_TextSmall>
              <ConditionalRender condition={variable.type === "string"}>
                <Input
                  size="sm"
                  placeholder={variable.placeholder}
                  value={variablesValues[variable.name] || variablesValues[variable.name]}
                  onChange={(e) => onChangeVariable(e, variable.name)}
                />
              </ConditionalRender>
              <ConditionalRender condition={variable.type === "text"}>
                <Textarea
                  placeholder={variable.placeholder}
                  defaultValue={variablesValues[variable.name]}
                  onChange={(e) => onChangeTextVariable(e.target.value, variable.name)}
                />
              </ConditionalRender>
            </H1_FlexColumn>
          ))}
        </BorderFlexColumn>
        <BorderedButton
          color="primary"
          isLoading={isCreateDraftLoading}
          onClick={onClickCtaButton}
          stratContent={<H1_Icon icon="fas fa-wand-magic-sparkles" />}
        >
          {formatMessage(videoWizardV2Messages.ctaButton)}
        </BorderedButton>
      </StyledForm>
      <H1_FlexRow flex="0 0 361px">
        <img
          height="100%"
          width="100%"
          src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/document.webp"
          alt="workflow2"
        />
      </H1_FlexRow>
    </ContainerFlexRow>
  );
};

export default VideoWizardV2Stage2;
