import { defineMessages } from "react-intl";

export const styleDrawerMessages = defineMessages({
  styleDrawerTitle: {
    id: `editor.drawer.style-drawer.style-drawer-title`,
    defaultMessage: "Style"
  },
  backButton: {
    id: `editor.drawer.style-drawer.style-drawer-back-button`,
    defaultMessage: "Back"
  },
  brandkitPaletteTitle: {
    id: `editor.drawer.style-drawer.brandkit-title`,
    defaultMessage: "Your brandkit palette"
  },
  customTitle: {
    id: `editor.drawer.style-drawer.custom-title`,
    defaultMessage: "Your custom palette"
  },
  colorsTitle: {
    id: `editor.drawer.style-drawer.colors-title`,
    defaultMessage: "Colors Palettes"
  },
  fontsTitle: {
    id: `editor.drawer.style-drawer.fonts-title`,
    defaultMessage: "Fonts"
  },
  yourBrandColors: {
    id: `editor.drawer.style-drawer.your-brand-colors`,
    defaultMessage: "Your brand colors"
  },
  seeAll: {
    id: `editor.drawer.style-drawer.see-all`,
    defaultMessage: "See all"
  },
  seeLess: {
    id: `editor.drawer.style-drawer.see-less`,
    defaultMessage: "See less"
  },
  appliedTitle: {
    id: `editor.drawer.style-drawer.applied-title`,
    defaultMessage: "Applied"
  },
  editButton: {
    id: `editor.drawer.style-drawer.edit-custom-button`,
    defaultMessage: "Edit"
  },
  paletteColorPopoverHeader: {
    id: `editor.drawer.style-drawer.palette-color-popover-header`,
    defaultMessage: "Style colors"
  },
  addBrandColorsButton: {
    id: `editor.drawer.style-drawer.add-brand-color-btn`,
    defaultMessage: "+ Add Palette"
  },
  useBrandkit: {
    id: `editor.drawer.style-drawer.use-brandkit-button`,
    defaultMessage: "Use Brand kit"
  }
});
export const customColorPickerMessages = defineMessages({
  title: {
    id: `editor.drawer.style-drawer.custom-color-picker.title`,
    defaultMessage: "My brand colors"
  },
  cancelButton: {
    id: `editor.drawer.style-drawer.custom-color-picker.cancel-button`,
    defaultMessage: "Cancel"
  },
  doneButton: {
    id: `editor.drawer.style-drawer.custom-color-picker.apply-button`,
    defaultMessage: "Apply"
  },
  wColor: {
    id: `editor.drawer.style-drawer.custom-color-picker.w-color`,
    defaultMessage: "Light"
  },
  bColor: {
    id: `editor.drawer.style-drawer.custom-color-picker.wb-color`,
    defaultMessage: "Dark"
  },
  a1Color: {
    id: `editor.drawer.style-drawer.custom-color-picker.a1-color`,
    defaultMessage: "Primary"
  },
  a2Color: {
    id: `editor.drawer.style-drawer.custom-color-picker.a2-color`,
    defaultMessage: "Secondary"
  },
  a3Color: {
    id: `editor.drawer.style-drawer.custom-color-picker.a3-color`,
    defaultMessage: "Tertiary"
  }
});
