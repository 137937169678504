import { useSortable } from "@dnd-kit/sortable";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const ContainerFlexRow = styled(H1_FlexRow)`
  z-index: 2;
  transition: 0.3s ease-in-out all;
`;
const GrabFlexRow = styled(H1_FlexRow)<{ $isHover?: boolean; $disabled: boolean }>`
  opacity: ${({ $isHover }) => (!$isHover ? 0 : 1)};
  z-index: ${({ $isHover }) => (!$isHover ? -1 : 2)};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  transition: 0.3s ease-in-out all;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "grab")};
  & i {
    cursor: grab;
  }
  &:hover {
    i {
      color ${({ theme }) => theme.gray11};
    }
  }
`;

const WidthTextSmall = styled(H1_TextSmall)<{ $isDragging?: boolean }>`
  width: 17px;
  background-color: transparent;
  border-radius: 20px;
  align-self: center;
  justify-content: center;
  padding: 5px 6px 5px 3px;
  opacity: ${({ $isDragging }) => ($isDragging ? 0 : 1)};
`;

interface VideoSortableItemProps {
  id: string;
  idx: number;
  isVisible?: boolean;
  isDragging?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
}
const VideoSortableItem = ({
  id,
  idx,
  isVisible,
  isDragging,
  disabled = false
}: VideoSortableItemProps) => {
  const { attributes, listeners, setNodeRef } = useSortable({ id: id });
  const theme = useTheme();

  return (
    <ContainerFlexRow
      flex="0 0 auto"
      height="62px"
      align="center"
      position="relative"
      gap="14px"
      ref={setNodeRef}
    >
      <GrabFlexRow
        justify="center"
        width="18px"
        align="center"
        {...attributes}
        {...listeners}
        $isHover={isVisible}
        $disabled={disabled}
      >
        <H1_Icon size="16px" color={theme.gray7} icon="fas fa-grip-dots-vertical" isCursorPointer />
      </GrabFlexRow>
      <WidthTextSmall fontWeight={800} $isDragging={isDragging} color={theme.gray7}>
        {idx}
      </WidthTextSmall>
    </ContainerFlexRow>
  );
};

export default VideoSortableItem;
