import "app/pages/player/player.scss";
import { useAuth } from "app/auth/useAuth";
import LoginFooter from "app/components/authentication/LoginFooter";
import styled from "styled-components";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import { useNavigate, useParams } from "react-router-dom";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import { Location } from "app/types";

const StyledHourOneLogo = styled(HourOneLogo)`
  g path:last-child {
    fill: ${(props) => props.theme.blue4};
  }
`;

const RatioContainer = styled.div`
  --aspect-ratio: 9/16;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  max-width: 950px;
  display: block;
  margin: auto;
  & > iframe,
  & > video,
  & > img,
  & > div,
  & > .ant-image {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    html {
      overflow: hidden;
    }
  }
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(var(--aspect-ratio) * 100%);
  }
`;

const PlayerContainer = styled(H1_FlexColumn)`
  padding: 20px;
`;

const PlayerHeader = styled(H1_FlexColumn)`
  height: 100px;
  svg {
    height: 45px;
    width: auto;
  }
`;

const Player = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user, isInitialized } = useAuth();
  const showFooter = !user && isInitialized;
  if (!params.reqId) return null;

  const onClickHeader = () => {
    navigate(Location.Home);
  };

  return (
    <PlayerContainer justify="center" gap="10px">
      <PlayerHeader onClick={onClickHeader} justify="center" align="flex-start">
        <StyledHourOneLogo />
      </PlayerHeader>
      <div>
        <RatioContainer>
          <MuxPlayer reqId={params.reqId as string} />
        </RatioContainer>
      </div>
      {showFooter && <LoginFooter referral={params.reqId as string} />}
    </PlayerContainer>
  );
};

export default Player;
