import { useAppDispatch, useAppSelector } from "app/hooks";
import { webhooksActions } from "app/store/slices/webhooks.slice";
import { Location, WebhookStatus } from "app/types";
import { useNavigate } from "react-router-dom";
import { webhooksGlobalSelectors } from "app/store/adapters/adapters";
import { fetchingStatus } from "app/utils/helpers";
import { useEffect } from "react";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";

interface WebhookOptionsDropdownProps {
  webhookId: string;
}
const WebhookOptionsDropdown = ({ webhookId }: WebhookOptionsDropdownProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const webhook = useAppSelector((state) => webhooksGlobalSelectors.selectById(state, webhookId));
  const statusChangeStatus = useAppSelector((state) => state.webhooks.statusChangeStatus);
  const changeSecretStatus = useAppSelector((state) => state.webhooks.changeSecretStatus);
  const statusSecretLoading = changeSecretStatus === fetchingStatus.loading;
  const statusChangeLoading = statusChangeStatus === fetchingStatus.loading;

  useEffect(() => {
    if (
      changeSecretStatus === fetchingStatus.succeeded ||
      changeSecretStatus === fetchingStatus.failed
    ) {
      dispatch(webhooksActions.setChangeSecretStatusToIdle());
    }
  }, [changeSecretStatus]);

  const onDelete = () => {
    dispatch(webhooksActions.deleteWebhookRequest(webhookId));
    navigate(`${Location.Webhooks}`);
  };

  const onTogglePause = () => {
    dispatch(webhooksActions.togglePauseWebhookRequest(webhookId));
  };

  const onReactive = () => {
    dispatch(webhooksActions.reactiveWebhookRequest(webhookId));
  };

  const onCreateSecret = () => {
    dispatch(webhooksActions.createWebhookSecretRequest(webhookId));
  };

  const menu = {
    items: [
      webhook?.status === WebhookStatus.inactive
        ? {
            key: "reactive",
            label: "Reactive",
            onClick: onReactive,
            disabled: statusSecretLoading
          }
        : null,
      webhook?.status === WebhookStatus.paused
        ? {
            key: "enable",
            label: "Enable",
            onClick: onTogglePause,
            disabled: statusChangeLoading
          }
        : null,
      webhook?.status === WebhookStatus.active
        ? {
            key: "pause",
            label: "Pause",
            onClick: onTogglePause,
            disabled: statusChangeLoading
          }
        : null,
      {
        key: "secret",
        label: "Recreate Secret",
        onClick: onCreateSecret
      },
      {
        key: "delete",
        label: "Delete Webhook",
        onClick: onDelete
      }
    ]
  };

  return (
    <H1_Dropdown
      onlyIcon
      icon={<i className="fa-solid fa-ellipsis-vertical" />}
      isTransparent
      menu={menu}
      placement="bottomLeft"
      arrow={false}
    >
      {/*<StyledIcon className="far fa-ellipsis-h" />*/}
    </H1_Dropdown>
  );
};

export default WebhookOptionsDropdown;
