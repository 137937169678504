import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import {
  FlatRecipe,
  Orientation,
  Recipe,
  RecipeList,
  RecipeType,
  UseCaseSegmentType,
  WorkspaceType
} from "app/types";
import { uniqBy, values } from "lodash-es";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";

export const ALL_CATEGORIES_ID = "all-category-name";
const recipesData = (state: RootState) => state.recipes.recipes;
const workflowRecipes = (state: RootState) => state.recipes.workflowRecipes;
const customRecipesData = (state: RootState) => state.recipes.customRecipes;
const personalRecipesData = (state: RootState) => state.recipes.personalRecipes;
const status = (state: RootState) => state.recipes.status;
const currentWorkspace = (state: RootState) => getCurrentWorkspace(state);
const featureFlags = (state: RootState) => state.user.featureFlags;

const userSegment = (state: RootState) => state.user.preferences.usage_segment;

const getRecipeSections = createSelector([recipesData], (recipes) => {
  return recipes.map(({ name }) => name);
});

const getCustomSections = createSelector([customRecipesData], (recipes) => {
  return recipes.map(({ name }) => name);
});

const getAllRecipes = createSelector(
  [recipesData, customRecipesData, personalRecipesData],
  (recipes, customRecipes, personalRecipes) => {
    const unified = [...recipes, ...customRecipes, ...personalRecipes].reduce<
      Record<string, RecipeList>
    >((acc: { [key: string]: RecipeList }, cur) => {
      if (acc[cur.name]) {
        acc[cur.name].items = [...acc[cur.name].items, ...cur.items];
        acc[cur.name].tags = [...acc[cur.name].tags, ...cur.tags];
      } else {
        acc[cur.name] = { ...cur, items: [...cur.items], tags: [...cur.tags] };
      }
      return acc;
    }, {});

    return values<RecipeList>(unified);
  }
);

const getRecipesCategoriesNames = createSelector(
  [recipesData, customRecipesData, personalRecipesData],
  (recipes, customRecipes, personalRecipes) => {
    let unified = [...customRecipes, ...recipes, ...personalRecipes];
    const customCategoryName = "custom";
    const customCategory = unified.find(
      (category) => category.name?.toLowerCase() === customCategoryName
    );
    if (customCategory) {
      unified = [
        customCategory,
        ...unified.filter((category) => category.name?.toLowerCase() !== customCategoryName)
      ];
    }
    const namesCategories: Partial<RecipeList>[] = unified.map(({ name, id }: RecipeList) => ({
      name,
      id
    }));
    return namesCategories;
  }
);

const getAllRecipesCategoriesNames = createSelector(
  [
    getRecipesCategoriesNames,
    userSegment,
    (
      state,
      options?: {
        visibleChipsCount?: number;
        selectedSectionsNames?: string[];
      }
    ) => options
  ],
  (recipes, segment, options = {}) => {
    const { selectedSectionsNames, visibleChipsCount } = options;
    if (visibleChipsCount && selectedSectionsNames) {
      const originalRecipes: Partial<RecipeList>[] = JSON.parse(JSON.stringify(recipes));
      if (segment) {
        const segmentRecipe: Partial<RecipeList> = {
          id: segment,
          name: segment
        };
        // checking if there is already category equals to segment
        const segmentExists = originalRecipes.find(
          (item) => item.name?.toLowerCase() === segment.toLowerCase()
        );
        if (!segmentExists) {
          originalRecipes.unshift(segmentRecipe);
        }
      }
      const actualVisibleChipsCount = visibleChipsCount - 2; // Actual number according to width, minus the "All" section, minus the three dots, minuts the 0 index
      const selectSectionsIndexes: number[] = [];
      selectedSectionsNames.forEach((section) => {
        const displayedIndex = originalRecipes.findIndex((displayed) => displayed.name === section);
        selectSectionsIndexes.push(displayedIndex);
      });
      selectSectionsIndexes.forEach((sectionIndex) => {
        if (sectionIndex > actualVisibleChipsCount) {
          for (let i = actualVisibleChipsCount; i > 0; i--) {
            if (!selectedSectionsNames.includes(originalRecipes[i]?.name as string)) {
              // Swap elements
              const temp = originalRecipes[i];
              originalRecipes[i] = originalRecipes[sectionIndex];
              originalRecipes[sectionIndex] = temp;
              // quit the loop
              i = -1;
            }
          }
        }
      });
      recipes = originalRecipes;
    }
    const sortedRecipesBySegment = segment
      ? recipes.sort((a) => {
          if (a.name?.toLowerCase() === segment.toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        })
      : recipes;
    return [{ name: "All", id: ALL_CATEGORIES_ID }, ...sortedRecipesBySegment];
  }
);

const getFlattenRecipes = createSelector([getAllRecipes], (allRecipes) => {
  const recipes: FlatRecipe[] = allRecipes.flatMap((recipeList) =>
    recipeList.items.map((rec) => ({
      ...rec,
      categoryName: recipeList.name,
      recipeListId: recipeList.id,
      recipeType: recipeList.recipeType,
      usage_segments: recipeList.usage_segments
    }))
  );

  return uniqBy(recipes, (recipe: Recipe) => recipe.id);
});

const getFlattenRecipesCustomsFirst = createSelector(
  [recipesData, customRecipesData, personalRecipesData],
  (recipes, customRecipes, personalRecipes) => {
    const CUSTOM = "Custom";
    const unified = [
      ...customRecipes,
      ...recipes.filter((rec) => rec.name === CUSTOM),
      ...recipes.filter((rec) => rec.name !== CUSTOM),
      ...personalRecipes
    ].reduce<Record<string, RecipeList>>((acc: { [key: string]: RecipeList }, cur) => {
      if (acc[cur.name]) {
        acc[cur.name].items = [...acc[cur.name].items, ...cur.items];
        acc[cur.name].tags = [...acc[cur.name].tags, ...cur.tags];
      } else {
        acc[cur.name] = { ...cur, items: [...cur.items], tags: [...cur.tags] };
      }
      return acc;
    }, {});

    const allRecipes = values<RecipeList>(unified);
    const flatRecipes: FlatRecipe[] = allRecipes.flatMap((recipeList) =>
      recipeList.items.map((rec) => ({
        ...rec,
        categoryName: recipeList.name,
        recipeListId: recipeList.id,
        recipeType: recipeList.recipeType,
        usage_segments: recipeList.usage_segments
      }))
    );

    return uniqBy(flatRecipes, (recipe: Recipe) => recipe.id);
  }
);

const getSummaryRecipes = createSelector(
  [
    getFlattenRecipes,
    (state, selectedRecipeId) => ({
      selectedRecipeId
    })
  ],
  (recipes, { selectedRecipeId }) => {
    return recipes
      .filter(
        (recipe) =>
          recipe.recipeType === RecipeType.personal || recipe.categoryName.toLowerCase() === "sales"
      )
      .sort((a, b) => {
        if (a.id === selectedRecipeId) {
          return -1;
        }
        if (a.recipeType === RecipeType.personal && b.recipeType === RecipeType.personal) {
          return 0;
        } else if (a.recipeType === RecipeType.personal && b.recipeType !== RecipeType.personal) {
          return -1;
        } else {
          return 1;
        }
      });
  }
);

const getSortedFlattenRecipesBySegmentName = createSelector(
  [getFlattenRecipes, userSegment],
  (recipes, segmentName) => {
    const filteredRecipes = [...recipes].sort((a, b) => {
      if (
        a.usage_segments?.includes(segmentName as UseCaseSegmentType) &&
        b.usage_segments?.includes(segmentName as UseCaseSegmentType)
      ) {
        return 0;
      } else if (a.usage_segments?.includes(segmentName as UseCaseSegmentType)) {
        return -1;
      } else {
        return 0;
      }
    });
    return uniqBy(filteredRecipes, (recipe: Recipe) => recipe.id);
  }
);

const getFilteredFlattenRecipesByOrientationAndId = createSelector(
  [
    getFlattenRecipes,
    // Do not use typed arguments because objecting them will cause re-rendering every time redux state chagnes, for object is only a reference https://redux.js.org/usage/deriving-data-selectors#using-selectors-with-react-redux
    (state, orientation, filteredRecipesIds) => ({
      orientation,
      filteredRecipesIds
    })
  ],
  (allRecipes, { orientation, filteredRecipesIds }) => {
    let filteredRecipes = allRecipes;
    if (orientation && orientation !== Orientation.AnySize) {
      switch (orientation) {
        case Orientation.Landscape:
          filteredRecipes = allRecipes.filter(
            (recipe: Recipe) =>
              !recipe.aspect_ratio ||
              parseInt(recipe.aspect_ratio.width) / parseInt(recipe.aspect_ratio.height) > 1
          );
          break;
        case Orientation.Portrait:
          filteredRecipes = allRecipes.filter(
            (recipe: Recipe) =>
              recipe.aspect_ratio &&
              parseInt(recipe.aspect_ratio.width) / parseInt(recipe.aspect_ratio.height) < 1
          );
          break;
        case Orientation.Square:
          filteredRecipes = allRecipes.filter(
            (recipe: Recipe) =>
              recipe.aspect_ratio &&
              parseInt(recipe.aspect_ratio.width) / parseInt(recipe.aspect_ratio.height) === 1
          );
          break;
      }
    }

    if (filteredRecipesIds && filteredRecipesIds.length > 0) {
      filteredRecipes = filteredRecipes.filter((recipe) => !filteredRecipesIds.includes(recipe.id));
    }

    return uniqBy(filteredRecipes, (recipe: Recipe) => recipe.id);
  }
);

const getFilteredFlattenRecipesByDefaultProperty = createSelector(
  [getFlattenRecipes],
  (allRecipes) => {
    const filteredRecipes = allRecipes;
    filteredRecipes.sort((a) => {
      if (a.default) {
        return -1;
      } else {
        return 1;
      }
    });

    return uniqBy(filteredRecipes, (recipe: Recipe) => recipe.id);
  }
);

const getFilteredFlattenRecipesBySectionName = createSelector(
  [
    getFlattenRecipes,
    // Do not use typed arguments because objecting them will cause re-rendering every time redux state chagnes, for object is only a reference https://redux.js.org/usage/deriving-data-selectors#using-selectors-with-react-redux
    (state, filteredFlattenRecipesByOrientationAndId, sectionsName) => ({
      filteredFlattenRecipesByOrientationAndId,
      sectionsName
    })
  ],
  (allRecipes, { filteredFlattenRecipesByOrientationAndId, sectionsName }) => {
    let filteredRecipes = filteredFlattenRecipesByOrientationAndId || [];
    if (sectionsName && sectionsName.length > 0) {
      filteredRecipes = filteredRecipes.filter((recipe: FlatRecipe) => {
        if (sectionsName === recipe.categoryName) {
          return true;
        }
        return recipe.usage_segments?.some((segment) => {
          if (sectionsName.includes(segment as string)) {
            return true;
          }
        });
      });
    }

    return uniqBy(filteredRecipes, (recipe: Recipe) => recipe.id);
  }
);

const getFilteredFlattenRecipes = createSelector(
  [
    getFlattenRecipes,
    (state, orientation, sectionsNames, query) => ({ orientation, sectionsNames, query })
  ],
  (allRecipes, { orientation, sectionsNames, query }) => {
    let filteredRecipes = allRecipes || [];
    if (orientation && orientation !== Orientation.AnySize) {
      switch (orientation) {
        case Orientation.Landscape:
          filteredRecipes = allRecipes.filter(
            (recipe: Recipe) =>
              !recipe.aspect_ratio ||
              parseInt(recipe.aspect_ratio.width) / parseInt(recipe.aspect_ratio.height) > 1
          );
          break;
        case Orientation.Portrait:
          filteredRecipes = allRecipes.filter(
            (recipe: Recipe) =>
              recipe.aspect_ratio &&
              parseInt(recipe.aspect_ratio.width) / parseInt(recipe.aspect_ratio.height) < 1
          );
          break;
        case Orientation.Square:
          filteredRecipes = allRecipes.filter(
            (recipe: Recipe) =>
              recipe.aspect_ratio &&
              parseInt(recipe.aspect_ratio.width) / parseInt(recipe.aspect_ratio.height) === 1
          );
          break;
      }
    }
    if (sectionsNames && sectionsNames.length > 0) {
      filteredRecipes = filteredRecipes.filter((recipe: FlatRecipe) => {
        for (const sectionName of sectionsNames) {
          if (sectionName === recipe.categoryName) {
            return true;
          }
        }
        return recipe.usage_segments?.some((segment) => {
          if (sectionsNames.includes(segment as string)) {
            return true;
          }
        });
      });
    }
    if (query) {
      filteredRecipes = filteredRecipes.filter((recipe: FlatRecipe) => {
        if (recipe.title?.toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
        if (recipe.categoryName.toLowerCase() === query.toLowerCase()) {
          return true;
        }
        const { tags, hidden_tags } = recipe;
        if (tags.join().toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
        if (recipe.description?.toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
        if (hidden_tags?.join().toLowerCase().includes(query.toLowerCase())) {
          return true;
        }

        return false;
      });
    }
    return uniqBy(filteredRecipes, (recipe: Recipe) => recipe.id);
  }
);

const getSlicedFlattenRecipes = createSelector(
  [
    getFlattenRecipesCustomsFirst,
    getSortedFlattenRecipesBySegmentName,
    currentWorkspace,
    featureFlags,
    // Do not use typed arguments because objecting them will cause re-rendering every time redux state chagnes, for object is only a reference https://redux.js.org/usage/deriving-data-selectors#using-selectors-with-react-redux
    (state, numOfRecipes, page) => ({
      numOfRecipes,
      page
    })
  ],
  (customFirstRecipes, recipes, currentWorkspace, flags, { numOfRecipes, page }) => {
    if (currentWorkspace?.metadata?.type === WorkspaceType.Iconic && flags.iconic) {
      return customFirstRecipes.slice(0, page * numOfRecipes);
    }
    return recipes.slice(0, page * numOfRecipes);
  }
);

const getAllRecipesItemsByRecipeListId = createSelector(
  [
    recipesData,
    customRecipesData,
    personalRecipesData,
    getFlattenRecipes,
    (state, recipeListId?: string) => recipeListId
  ],
  (recipes, customRecipes, personalRecipes, flattenRecipes, recipeListId) => {
    if (!recipeListId || recipeListId === ALL_CATEGORIES_ID) {
      return flattenRecipes;
    }
    const unified = [...recipes, ...customRecipes, ...personalRecipes];
    return unified.find((recipeList) => recipeList.id === recipeListId)?.items || [];
  }
);

export const getRecipeByRecipeId = createSelector(
  [
    recipesData,
    customRecipesData,
    personalRecipesData,
    workflowRecipes,
    (state, recipeId?: string) => recipeId
  ],
  (realsRecipes, customRecipes, personalRecipes, workflowRecipes, recipeId) => {
    if (!recipeId) {
      return undefined;
    }
    const allRecipes = [...realsRecipes, ...customRecipes, ...personalRecipes];
    return (
      allRecipes
        .flatMap((recipeList: RecipeList) => recipeList.items)
        .find((recipe: Recipe) => recipe.id === (recipeId as string)) ||
      workflowRecipes.find((recipe: Recipe) => recipe.id === (recipeId as string))
    );
  }
);

export const selectedSectionData = createSelector(
  [
    recipesData,
    customRecipesData,
    personalRecipesData,
    // Do not use typed arguments because objecting them will cause re-rendering every time redux state chagnes, for object is only a reference https://redux.js.org/usage/deriving-data-selectors#using-selectors-with-react-redux
    (state, selectedSectionName, selectedSectionType) => ({
      selectedSectionName,
      selectedSectionType
    })
  ],
  (recipes, customRecipes, personalRecipes, { selectedSectionName, selectedSectionType }) => {
    let allRecipes;
    switch (selectedSectionType) {
      case RecipeType.custom:
        allRecipes = customRecipes;
        break;
      case RecipeType.personal:
        allRecipes = personalRecipes;
        break;
      default:
        allRecipes = [...recipes, ...customRecipes, ...personalRecipes];
    }

    let sectionRecipes;
    if (selectedSectionName.length > 0) {
      sectionRecipes = allRecipes.filter((recipe) => selectedSectionName.includes(recipe.name));
      return Array.isArray(sectionRecipes) ? sectionRecipes : [sectionRecipes];
    }
    return allRecipes;
  }
);

const recipesSelectors = {
  getRecipeSections,
  getCustomSections,
  getAllRecipes,
  getAllRecipesCategoriesNames,
  getRecipesCategoriesNames,
  getAllRecipesItemsByRecipeListId,
  getFlattenRecipes,
  getFilteredFlattenRecipes,
  getFilteredFlattenRecipesBySectionName,
  getFilteredFlattenRecipesByOrientationAndId,
  getFilteredFlattenRecipesByDefaultProperty,
  getFlattenRecipesCustomsFirst,
  getSlicedFlattenRecipes,
  getRecipeByRecipeId,
  getSortedFlattenRecipesBySegmentName,
  status,
  recipesData,
  customRecipesData,
  personalRecipesData,
  selectedSectionData,
  getSummaryRecipes
};

export default recipesSelectors;
