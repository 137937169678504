import React, { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

export const marqueeAnimation = (width: number, containerWidth: string) => keyframes`
  0% {
    transform: translateX(0);
  }
  50% { transform: translateX(calc(-${width}px + ${containerWidth} - 20px)); }
  100% {
    transform: translateX(0);
  }
`;

const TextBoxContainer = styled(H1_FlexRow)`
  overflow: hidden;
  padding: 0px;
`;

const TextBoxContent = styled.div<{
  $enableAnimation: boolean;
  $width: number;
  $containerWidth: string;
  $pauseAnimation: boolean;
}>`
  padding: 0;
  display: inline-block;
  width: fit-content;
  white-space: nowrap;
  animation: ${({ $width, $containerWidth, $pauseAnimation }) =>
    $pauseAnimation
      ? "none"
      : css`
    ${marqueeAnimation($width, $containerWidth)} 10s linear infinite}
  `};
  animation-play-state: ${({ $enableAnimation }) => ($enableAnimation ? "running" : "paused")};
`;

interface MarqueeTextBoxProps {
  text: string;
  width: string;
  color: string;
  pauseAnimation: boolean;
}

const MarqueeTextBox = ({ color, text, width, pauseAnimation }: MarqueeTextBoxProps) => {
  const containerRef = React.useRef(null);
  const contentRef = React.useRef(null);
  const [enableAnimation, setEnableAnimation] = React.useState(false);

  useEffect(() => {
    const PADDING = 10;
    if (containerRef.current && contentRef.current) {
      const containerWidth = (containerRef.current as HTMLDivElement).offsetWidth;
      const contentWidth = (contentRef.current as HTMLDivElement).offsetWidth;
      setEnableAnimation(
        (contentRef.current as HTMLDivElement).offsetWidth >
          (containerRef.current as HTMLDivElement).offsetWidth - PADDING
      );
      if (contentWidth > containerWidth - PADDING) {
        const animationDuration =
          contentWidth - containerWidth < PADDING
            ? (contentWidth / containerWidth) * 2
            : contentWidth - containerWidth < PADDING * 8
            ? (contentWidth / containerWidth) * 3
            : (contentWidth / containerWidth) * 5;
        (contentRef.current as HTMLDivElement).style.animationDuration = `${animationDuration}s`;
      }
    }
  }, [text]);

  return (
    <TextBoxContainer ref={containerRef} width={width}>
      <TextBoxContent
        $containerWidth={width}
        $width={contentRef.current ? (contentRef.current as HTMLDivElement).offsetWidth : 0}
        $enableAnimation={enableAnimation && !pauseAnimation}
        $pauseAnimation={pauseAnimation}
        ref={contentRef}
      >
        <H1_TextSmall color={color}>{text}</H1_TextSmall>
      </TextBoxContent>
    </TextBoxContainer>
  );
};

export default MarqueeTextBox;
