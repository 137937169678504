import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteRecipe,
  getRecipes,
  getRecipesExamples,
  getRecommendedRecipes
} from "app/services/serviceV2Apis";
import { ThunkApi, thunkOptions } from "app/store/thunks/thunkCommon";
import { FlatRecipe, Recipe, RecipeList, RecipeType } from "app/types/recipe";
import { v4 as uuidv4 } from "uuid";
import { recipesActions } from "app/store/slices/recipes.slice";
import { uniqBy } from "lodash-es";

const prefix = "[Recipes]";
const getRecipesRequest = createAsyncThunk<
  {
    customRecipes: RecipeList[];
    recipes: RecipeList[];
    personalRecipes: RecipeList[];
    useCache: boolean;
  },
  { examples?: boolean } | void,
  ThunkApi
>(
  `${prefix} getRecipesRequest`,
  async (options, thunkApi) => {
    if (thunkApi.getState().recipes.useCache) {
      return {
        customRecipes: [],
        recipes: [],
        personalRecipes: [],
        useCache: true
      };
    }
    let result = [];
    if (options?.examples) {
      result = await getRecipesExamples();
    } else {
      result = await getRecipes();
    }

    const resultWithIds = {
      customRecipes: result.custom_recipes.map((recipe: RecipeList) => ({
        ...recipe,
        id: uuidv4().toString(),
        recipeType: RecipeType.custom
      })),
      recipes: result.recipes.map((recipe: RecipeList) => ({
        ...recipe,
        id: uuidv4().toString()
      })),
      personalRecipes: result.personal_recipes.map((recipe: RecipeList) => ({
        ...recipe,
        id: uuidv4().toString(),
        recipeType: RecipeType.personal
      }))
    };

    return { ...resultWithIds, useCache: false };
  },
  thunkOptions
);
const getRecipesWorkflowsRequest = createAsyncThunk<FlatRecipe[], void, ThunkApi>(
  `${prefix} getRecipesWorkflowsRequest`,
  async () => {
    const result = await getRecipes("workflow");
    const sanitizedResult = Object.values(result).flatMap((recipeList: any) => {
      return (
        recipeList?.flatMap((category: any) => {
          const recipeListId = uuidv4().toString();
          return category.items.map((recipe: Recipe) => ({
            ...recipe,
            categoryName: category.name,
            recipeListId: recipeListId,
            // recipeType?: RecipeType;
            usage_segments: recipeList.usage_segments
          }));
        }) || []
      );
    });

    return uniqBy(sanitizedResult, "id") as FlatRecipe[];
  },
  thunkOptions
);
const getRecommendedRecipesRequest = createAsyncThunk<Recipe[], void>(
  `${prefix} getRecommendedRecipesRequest`,
  async () => {
    const result = await getRecommendedRecipes();
    return result;
  },
  thunkOptions
);

const deleteRecipeRequest = createAsyncThunk<void, { recipeId: string; categoryName: string }>(
  `${prefix} deleteRecipeRequest`,
  async ({ recipeId }) => {
    await deleteRecipe(recipeId);
  },
  thunkOptions
);

const updateSelectedSectionNames = createAsyncThunk<void, { name: string }, ThunkApi>(
  `${prefix} updateSelectedSectionNames`,
  async ({ name }, thunkAPI) => {
    const state = thunkAPI.getState();
    const { selectedSectionsNames } = state.recipes;
    if (selectedSectionsNames.includes(name)) {
      const filteredNames = selectedSectionsNames.filter(
        (currentSection) => currentSection !== name
      );
      thunkAPI.dispatch(recipesActions.setSelectedSectionNames(filteredNames));
    } else {
      thunkAPI.dispatch(recipesActions.setSelectedSectionNames([...selectedSectionsNames, name]));
    }
  },
  thunkOptions
);

export default {
  getRecipesRequest,
  getRecommendedRecipesRequest,
  deleteRecipeRequest,
  updateSelectedSectionNames,
  getRecipesWorkflowsRequest
};
