import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Playlist } from "app/types";
import playlistsSelectors from "app/store/selectorsV2/playlists.selectors";
import { playlistsActions } from "app/store/slices/playlists.slice";
import { Button, Input } from "@nextui-org/react";
import { useIntl } from "react-intl";
import { playListMessages } from "app/pages/PlaylistsPage/messages";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-content {
    border-radius: 10px;
    width: 350px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
    border-radius: 10px;
    gap: 5px;
  }
`;
interface RenamePlaylistModalProps {
  id?: string;
  visible: boolean;
  onCloseModal: () => void;
}

const RenamePlaylistModal = ({ id, visible, onCloseModal }: RenamePlaylistModalProps) => {
  const [currentTitle, setCurrentTitle] = useState("");
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const currentPlaylist: Playlist | undefined = useAppSelector((state) =>
    playlistsSelectors.getPlaylistById(state, id)
  );

  useEffect(() => {
    if (currentPlaylist) {
      setCurrentTitle(currentPlaylist.title);
    }
  }, [currentPlaylist]);

  const onClose = () => {
    if (currentTitle !== currentPlaylist?.title && id) {
      dispatch(
        playlistsActions.updatePlaylistRequest({
          id,
          title: currentTitle as string,
          draftsIds: currentPlaylist?.draft_ids as string[]
        })
      );
    }
    onCloseModal();
  };

  const onUpdateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCurrentTitle(value);
  };

  return (
    <Modal
      open={visible}
      footer={null}
      closeIcon={<CloseModalIcon size="40px" />}
      centered
      onCancel={onClose}
      destroyOnClose
    >
      <Input
        label={formatMessage(playListMessages.renameTitle)}
        labelPlacement="outside"
        placeholder={formatMessage(playListMessages.myPlaylist)}
        onChange={onUpdateTitle}
        value={currentTitle}
      />
      <H1_FlexRow justify="flex-end" padding="30px 0 0 0" gap="10px">
        <Button variant="light" onClick={onCloseModal}>
          {formatMessage(playListMessages.cancel)}
        </Button>
        <Button color="primary" onClick={onClose}>
          {formatMessage(playListMessages.rename)}
        </Button>
      </H1_FlexRow>
    </Modal>
  );
};

export default RenamePlaylistModal;
