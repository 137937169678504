import React, { useEffect, useState } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { paymentsActions } from "app/store/slices/payments.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { SetupIntentResult } from "@stripe/stripe-js";
import { fetchingStatus } from "app/utils/helpers";
import CreditCardDetailsContent from "app/pages/pricing/PaymentModal/CreditCardDetailsContent";
import { userActions } from "app/store/slices/user.slice";
import { defineMessages, useIntl } from "react-intl";
import { StyledModal } from "app/components/common/StyledModal";

const messages = defineMessages({
  byVideoTitle: {
    id: `addonepayment-modal.buy-video-button.title`,
    defaultMessage: `Buy video`
  }
});

const AddOnPaymentModal = () => {
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { editorModalOpen, returnToModal } = useModal();
  const addOnPaymentResult = useAppSelector((state) => state.payments.addOnPaymentResult);
  const executeSessionStatus = useAppSelector((state) => state.payments.executeSessionStatus);
  const addOnPaymentStatus = useAppSelector((state) => state.payments.addOnPaymentStatus);
  const executeLoading =
    executeSessionStatus === fetchingStatus.loading ||
    addOnPaymentStatus === fetchingStatus.loading;

  const feature = editorModalOpen?.context?.feature;
  const args = editorModalOpen?.context?.args;

  const visible = editorModalOpen?.open === ModalName.addOnPaymentModal;

  useEffect(() => {
    if (!visible) {
      dispatch(paymentsActions.cleanPayment());
      return;
    }
    if (
      executeSessionStatus === fetchingStatus.succeeded ||
      executeSessionStatus === fetchingStatus.failed
    ) {
      // todo do we want to bring here the features?
      dispatch(paymentsActions.setExecuteSessionStatusToIdle());
      dispatch(userActions.getUserDataRequest());
      returnToModal();
    }
  }, [visible, executeSessionStatus]);

  useEffect(() => {
    if (addOnPaymentStatus === fetchingStatus.succeeded) {
      dispatch(paymentsActions.setAddOnPaymentToIdle());
    } else if (addOnPaymentStatus === fetchingStatus.failed) {
      setPaymentErrorMessage("Something went wrong, please try again");
      dispatch(paymentsActions.setAddOnPaymentToIdle());
    }
  }, []);

  useEffect(() => {
    dispatch(paymentsActions.getFeaturesPricingRequest());
  }, []);

  const onClose = () => {
    returnToModal();
  };

  const onPaymentDone = () => {
    dispatch(
      paymentsActions.executeSessionRequest({
        invoiceId: addOnPaymentResult?.invoice_id
      })
    );
  };
  const onBack = () => {
    returnToModal();
  };

  const onSetupFinish = (setupIntent: SetupIntentResult) => {
    dispatch(
      paymentsActions.addOnPaymentRequest({
        ...args,
        featureId: feature?.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        paymentMethod: setupIntent!.setupIntent!.payment_method as string
      })
    );
  };

  return (
    <StyledModal
      width="927px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="0"
      $flex="1"
      $minHeight="610px"
    >
      <CreditCardDetailsContent
        price={`$${feature?.price}`}
        confirmText={intl.formatMessage(messages.byVideoTitle)}
        onBack={onBack}
        onFinish={onPaymentDone}
        onSetupFinish={onSetupFinish}
        secret={addOnPaymentResult?.secret}
        loading={executeLoading}
        error={paymentErrorMessage}
      />
    </StyledModal>
  );
};

export default AddOnPaymentModal;
