/* eslint-disable react/jsx-pascal-case,no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Select, SelectProps } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useModal, { ModalName } from "app/hooks/useModal";
import { fetchingStatus } from "app/utils/helpers";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useNavigate } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { aiGenerateMessages } from "app/components/editor/scene/AugmentedSelection/messages";
import { Location, PlanEnum, SocialMedia } from "app/types";
import {
  getNumberOfScenesOptions,
  videoWizardPlaceholdersList
} from "app/components/editor/scene/AugmentedSelection/VideoWizardConsts";
import WandLoader from "app/components/common/Layout/WandLoader";
import ConditionalRender from "app/components/common/ConditionalRender";
import AIPromptArea from "app/components/editor/scene/AugmentedSelection/AIPromptArea";
import { v4 as uuidv4 } from "uuid";

import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import { VIDEO_WIZARD_TIMEOUT_SEC } from "app/config/Constants";
import usePermissions from "app/hooks/usePermissions";
import IncrementalLoader from "app/components/common/Loaders/IncrementalLoader";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_Select } from "app/components/_Infrastructure/design-system/select";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const messages = defineMessages({
  title: {
    id: "video-wizard-modal.title",
    defaultMessage: "Create AI video"
  },
  subtitle: {
    id: "video-wizard-modal.subtitle",
    defaultMessage: "Write my script in just seconds by entering video description"
  },
  header: {
    id: "video-wizard-modal.header",
    defaultMessage: "Video Wizard"
  },
  createButton: {
    id: "video-wizard-modal.create-button",
    defaultMessage: "Create"
  }
});

const StyledIncrementalLoader = styled(IncrementalLoader)`
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 15px;
`;

const OverlayFlexColumn = styled(H1_FlexColumn)`
  background-color: rgba(255, 255, 255, 0.5);
`;
const VideoWizardModal = () => {
  const [numberOfScenes, setNumberOfScenes] = useState<number>(3);
  const [promptValue, setPromptValue] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { returnToModal, editorModalOpen } = useModal();
  const { notifyError } = useErrors();
  const { isWriteWorkspaceAllow } = usePermissions();

  const appData = useAppSelector((state) => state.user.appData);
  const topicToDraftStatus = useAppSelector((state) => state.drafts.topicToDraftStatus);
  const topicToDraftResultId = useAppSelector((state) => state.drafts.topicToDraftResultId);
  const currentPlan: PlanEnum = appData.plan as PlanEnum;
  const visible = editorModalOpen?.open === ModalName.videoWizard;
  const targetPlatform: SocialMedia | undefined = editorModalOpen?.context?.targetPlatform;
  const isTopicToDraftLoading = topicToDraftStatus === fetchingStatus.loading;
  const isTopicToDraftSucceeded = topicToDraftStatus === fetchingStatus.succeeded;
  const shouldShowIncrementalLoader = isTopicToDraftLoading || isTopicToDraftSucceeded;

  const numberOfScenesSelection = useMemo(() => {
    return getNumberOfScenesOptions(currentPlan, appData);
  }, [currentPlan]);

  const selectOptions = useMemo(() => {
    const options: SelectProps["options"] = [];
    numberOfScenesSelection.forEach((sceneNumber) => {
      const option = {
        value: sceneNumber,
        label:
          sceneNumber === 0
            ? formatMessage(aiGenerateMessages.autoScenes)
            : sceneNumber === 1
            ? formatMessage(aiGenerateMessages.singleScene)
            : formatMessage(aiGenerateMessages.numberOfScenes, {
                number: sceneNumber
              }),
        icon: <i className="fa-solid fa-clock" />,
        key: sceneNumber
      };
      options.push(option);
    });

    return options;
  }, [numberOfScenesSelection]);

  const currentPlaceholder = useMemo(() => {
    const index =
      Math.round(Math.random() * videoWizardPlaceholdersList.length) %
      videoWizardPlaceholdersList.length;
    return videoWizardPlaceholdersList[index];
  }, []);

  useEffect(() => {
    if (!visible) {
      setPromptValue("");
    }
  }, [visible]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    let timeoutTopicSucceededId: ReturnType<typeof setTimeout>;
    if (topicToDraftStatus === fetchingStatus.idle || !visible) {
      return;
    }
    if (topicToDraftStatus === fetchingStatus.loading) {
      timeoutId = setTimeout(() => {
        notifyError({
          general: buildGeneralMessage(
            formatMessage(aiGenerateMessages.videoWizardTimeoutErrorHead),
            formatMessage(aiGenerateMessages.videoWizardTimeoutError)
          )
        });
        dispatch(draftsActions.updateTopicToDraftStatusToIdle());
      }, VIDEO_WIZARD_TIMEOUT_SEC * 1000);
    }
    if (topicToDraftStatus === fetchingStatus.succeeded && topicToDraftResultId) {
      // Wait a second to show the loader in 100%
      timeoutTopicSucceededId = setTimeout(() => {
        onClose();
        dispatch(analyticsEvents.navigateToEditor({ source: "video_wizard_modal" }));
        navigate(`${Location.Editor}/${topicToDraftResultId}`);
        dispatch(draftsActions.updateTopicToDraftStatusToIdle());
        clearTimeout(timeoutTopicSucceededId);
      }, 1000);
    } else if (topicToDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateTopicToDraftStatusToIdle());
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (timeoutTopicSucceededId) {
        clearTimeout(timeoutId);
      }
    };
  }, [topicToDraftStatus, visible, topicToDraftResultId]);

  const onClose = () => {
    returnToModal();
  };

  const onClickCreate = () => {
    dispatch(
      draftsActions.topicToDraftRequest({
        orderId: uuidv4(),
        title: "Video Wizard draft",
        promptValue: promptValue || currentPlaceholder,
        numberOfScenes: numberOfScenes,
        type: "create_draft_from_topic",
        targetPlatform: targetPlatform
      })
    );
  };

  const onChangePromptText = (value: string) => {
    setPromptValue(value);
  };

  return (
    <StyledModal
      width="715px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "338px" }}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="33px 37px 52px 48px"
      $overflow="hidden"
    >
      <H1_FlexColumn gap="6px" justify="space-between" flex="1">
        <H1_FlexColumn gap="6px" position="relative" flex="1">
          <H1_FlexRow gap="4px" align="center" height="32px">
            <i className="fa-sharp fa-solid fa-wand-magic-sparkles" />
            <H1_TextXs fontFamily="Poppins">{formatMessage(messages.header)}</H1_TextXs>
          </H1_FlexRow>
          <AIPromptArea
            disabled={isTopicToDraftLoading}
            placeholder={currentPlaceholder}
            value={promptValue}
            onChange={onChangePromptText}
          />
          <ConditionalRender condition={isTopicToDraftLoading}>
            <OverlayFlexColumn
              position="absolute"
              height="100%"
              width="100%"
              justify="center"
              align="center"
            >
              <H1_FlexColumn justify="center" align="center">
                <WandLoader size="100px" />
              </H1_FlexColumn>
            </OverlayFlexColumn>
          </ConditionalRender>
        </H1_FlexColumn>
        <H1_FlexRow align="center" justify="space-between">
          <H1_Select
            defaultValue={0}
            onChange={setNumberOfScenes}
            value={numberOfScenes}
            options={selectOptions}
            width="155px"
            disabled={isTopicToDraftLoading}
          >
            {numberOfScenesSelection.map((sceneNumber) => (
              <Select.Option
                key={sceneNumber}
                value={sceneNumber}
                icon={<i className="fa-solid fa-clock" />}
              >
                {sceneNumber === 0
                  ? formatMessage(aiGenerateMessages.autoScenes)
                  : sceneNumber === 1
                  ? formatMessage(aiGenerateMessages.singleScene)
                  : formatMessage(aiGenerateMessages.numberOfScenes, {
                      number: sceneNumber
                    })}
              </Select.Option>
            ))}
          </H1_Select>
          <Button
            color="primary"
            onClick={onClickCreate}
            startContent={<i className="fa-sharp fa-solid fa-sparkles" />}
            isLoading={isTopicToDraftLoading || false}
            isDisabled={!isWriteWorkspaceAllow}
          >
            {formatMessage(messages.createButton)}
          </Button>
        </H1_FlexRow>
      </H1_FlexColumn>
      <ConditionalRender condition={shouldShowIncrementalLoader}>
        <StyledIncrementalLoader isFinished={isTopicToDraftSucceeded} />
      </ConditionalRender>
    </StyledModal>
  );
};

export default VideoWizardModal;
