/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/userData.thunk";
import { fetchingStatus } from "app/utils/helpers";
import {
  ApiKey,
  AppMetadata,
  Feature,
  FetchStatus,
  InitialQueryParams,
  MFAMetadata,
  MFATypes,
  UserMetadata
} from "app/types";
import { FeatureFlags } from "app/types/featureFlags";
import { Preferences } from "app/types/preferences";
import { SlackChannel, SlackInstall } from "app/types/slack";
import type { Auth0Result } from "auth0-js";
import { CreditsReportMsg } from "app/types/pusherMessages";
import { MarketPlaceFlow } from "app/types/payments";

const initialState: {
  userDataStatus: FetchStatus;
  userRegistrationStatus: FetchStatus;
  updateUserPreferencesStatus: FetchStatus;
  keysStatus: FetchStatus;
  apiKeys: ApiKey[];
  createKeyStatus: FetchStatus;
  slackBindStatus: FetchStatus;
  slackShareStatus: FetchStatus;
  favoriteStatus: FetchStatus;
  userConnectStatus: FetchStatus;
  marketPlaceFlow?: MarketPlaceFlow;
  onBoardingStatus: FetchStatus;
  mfaStatus: FetchStatus;
  mfaInfo?: MFAMetadata;
  appData: AppMetadata;
  userData: UserMetadata;
  permissions: string[];
  preferences: Preferences;
  currentEmail?: string;
  featureFlags: FeatureFlags;
  sourceUrl?: string;
  initialQueryParams: InitialQueryParams;
  slackInstall?: SlackInstall;
  slackChannels: SlackChannel[];
  newAuthResult?: Auth0Result;
  features: Feature[];
  creditsWarning?: CreditsReportMsg;
  registered: boolean;
  refreshToken: boolean;
  authFactors: MFAMetadata[];
  pendingAuthenticationToken?: string;
  mfaChallengeId?: string;
  email: string;
  logoutRedirect?: string;
  msMarketPlaceToken?: string;
} = {
  userDataStatus: fetchingStatus.idle as FetchStatus,
  userRegistrationStatus: fetchingStatus.idle as FetchStatus,
  keysStatus: fetchingStatus.idle as FetchStatus,
  createKeyStatus: fetchingStatus.idle as FetchStatus,
  slackBindStatus: fetchingStatus.idle as FetchStatus,
  slackShareStatus: fetchingStatus.idle as FetchStatus,
  favoriteStatus: fetchingStatus.idle as FetchStatus,
  userConnectStatus: fetchingStatus.idle as FetchStatus,
  updateUserPreferencesStatus: fetchingStatus.idle as FetchStatus,
  onBoardingStatus: fetchingStatus.idle as FetchStatus,
  mfaStatus: fetchingStatus.idle as FetchStatus,
  apiKeys: [],
  permissions: [],
  appData: {},
  userData: {},
  preferences: {},
  featureFlags: {},
  initialQueryParams: {},
  slackChannels: [],
  features: [],
  creditsWarning: undefined,
  registered: false,
  refreshToken: false,
  authFactors: [],
  pendingAuthenticationToken: undefined,
  mfaChallengeId: undefined,
  email: "",
  logoutRedirect: undefined,
  msMarketPlaceToken: undefined,
  marketPlaceFlow: undefined
};

export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setMFAStatusToIdle(state) {
      state.mfaStatus = fetchingStatus.idle;
      return state;
    },
    setMsMarketplaceToken(state, action) {
      state.msMarketPlaceToken = action.payload;
      return state;
    },
    cleanUser(state) {
      state = initialState;
      return state;
    },
    setLogoutRedirect(state, action) {
      state.logoutRedirect = action.payload;
      return state;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPendingAuthenticationToken(state, action) {
      state.pendingAuthenticationToken = action.payload;
      return state;
    },
    setMfaChallengeId(state, action) {
      state.mfaChallengeId = action.payload;
      return state;
    },
    setInitialQueryParams(state, action) {
      state.initialQueryParams = action.payload;
      return state;
    },
    setUserRegister(state) {
      state.registered = true;
      return state;
    },

    setFeatureFlags(state, action) {
      state.featureFlags = JSON.parse(JSON.stringify(action.payload));
      return state;
    },
    setOnBoardingStatusToIdle(state) {
      state.onBoardingStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setUserDataStatusToIdle(state) {
      state.userDataStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setUpdateUserPreferencesStatusToIdle(state) {
      state.updateUserPreferencesStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setSlackShareStatusToIdle(state) {
      state.slackShareStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setUserRegistrationStatusToIdle(state) {
      state.userRegistrationStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setUserPermissions(state, action) {
      state.permissions = action.payload;
      return state;
    },
    setUseCaseSelection(state, action) {
      state.userData.use_case_selection = action.payload;
      return state;
    },
    setInitial(state) {
      if (state.userData.hubspot) {
        state.userData.hubspot.initial = true;
      } else {
        state.userData.hubspot = { initial: true };
      }
      return state;
    },
    setSourceUrl(state, action) {
      state.sourceUrl = action.payload;
      return state;
    },
    setNewAuthResult(state, action) {
      state.newAuthResult = action.payload;
      return state;
    },
    cleanAuthResult(state) {
      delete state.newAuthResult;
      return state;
    },
    setCreditsWarningMsg(state, action) {
      state.creditsWarning = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getUserDataRequest.fulfilled, (state, action) => {
      state.userData = action.payload?.user_metadata || {};
      state.appData = action.payload?.app_metadata || {};
      state.preferences = action.payload?.preferences ?? {};
      state.features = action.payload?.features ?? [];
      state.userDataStatus = fetchingStatus.succeeded as FetchStatus;
      state.currentEmail = action.payload?.email || "";
      state.registered = action.payload?.registered;
      state.refreshToken = action.payload?.refreshToken;
      state.authFactors = action.payload?.auth_factors;
    });
    builder.addCase(asyncThunks.getUserDataRequest.pending, (state) => {
      state.userDataStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getUserDataRequest.rejected, (state) => {
      state.userDataStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getKeysRequest.fulfilled, (state, action) => {
      state.keysStatus = fetchingStatus.succeeded as FetchStatus;
      state.apiKeys = action.payload;
    });
    builder.addCase(asyncThunks.getKeysRequest.pending, (state) => {
      state.keysStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getKeysRequest.rejected, (state) => {
      state.keysStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createKeysRequest.fulfilled, (state, action) => {
      state.createKeyStatus = fetchingStatus.succeeded as FetchStatus;
      state.apiKeys = [action.payload];
    });
    builder.addCase(asyncThunks.createKeysRequest.pending, (state) => {
      state.createKeyStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createKeysRequest.rejected, (state) => {
      state.createKeyStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.updateUserPreferencesRequest.pending, (state, action) => {
      const newPreferences = action.meta.arg;
      state.preferences = { ...state.preferences, ...newPreferences };
    });
    builder.addCase(asyncThunks.updateUserPreferencesRequest.fulfilled, (state, action) => {
      state.updateUserPreferencesStatus = fetchingStatus.succeeded;
      const newPreferences = action.meta.arg;
      state.preferences = { ...state.preferences, ...newPreferences };
    });
    builder.addCase(asyncThunks.updateUserPreferencesRequest.rejected, (state) => {
      state.updateUserPreferencesStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.deleteKeyRequest.pending, (state) => {
      state.apiKeys = [];
    });

    builder.addCase(asyncThunks.slackBindRequest.fulfilled, (state) => {
      state.slackBindStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.slackInstallUrlRequest.fulfilled, (state, action) => {
      state.slackInstall = action.payload;
    });
    builder.addCase(asyncThunks.getSlackChannelsRequest.fulfilled, (state, action) => {
      state.slackChannels = action.payload.data;
    });
    builder.addCase(asyncThunks.slackShareRequest.pending, (state) => {
      state.slackShareStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.slackShareRequest.rejected, (state) => {
      state.slackShareStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.slackShareRequest.fulfilled, (state) => {
      state.slackShareStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.setFavoriteRequest.pending, (state) => {
      state.favoriteStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.setFavoriteRequest.rejected, (state) => {
      state.favoriteStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.setFavoriteRequest.fulfilled, (state) => {
      state.favoriteStatus = fetchingStatus.succeeded;
    });

    builder.addCase(asyncThunks.userConnectRequest.pending, (state, action) => {
      state.userConnectStatus = fetchingStatus.loading;
      if (action.meta.arg.gcpToken) {
        state.marketPlaceFlow = MarketPlaceFlow.google;
      } else {
        state.marketPlaceFlow = MarketPlaceFlow.microsoft;
      }
    });
    builder.addCase(asyncThunks.userConnectRequest.rejected, (state) => {
      state.userConnectStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.userConnectRequest.fulfilled, (state) => {
      state.userConnectStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.onBoardingRequest.fulfilled, (state) => {
      state.onBoardingStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.onBoardingRequest.pending, (state) => {
      state.onBoardingStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.onBoardingRequest.rejected, (state) => {
      state.onBoardingStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.mFAEnrollRequest.fulfilled, (state, action) => {
      state.mfaStatus = fetchingStatus.succeeded;
      state.mfaInfo = {
        id: action.payload.id,
        type: action.payload.type
      };
      if (action.payload.type === MFATypes.totp) {
        state.mfaInfo.qrCode = action.payload.totp.qr_code;
        state.mfaInfo.secret = action.payload.totp.secret;
        state.authFactors = [
          ...state.authFactors,
          { id: action.payload.id, type: action.payload.type }
        ];
      }
    });
    builder.addCase(asyncThunks.mFAEnrollRequest.pending, (state) => {
      state.mfaStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.mFAEnrollRequest.rejected, (state) => {
      state.mfaStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.deleteMFARequest.fulfilled, (state, action) => {
      state.mfaStatus = fetchingStatus.succeeded;
      if (action.payload) {
        state.mfaInfo = undefined;
        state.authFactors = state.authFactors.filter((auth) => auth.id !== action.payload);
      }
    });
    builder.addCase(asyncThunks.deleteMFARequest.pending, (state) => {
      state.mfaStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.deleteMFARequest.rejected, (state) => {
      state.mfaStatus = fetchingStatus.failed;
    });
  }
});

export default userSlice.reducer;

export const userActions = {
  ...asyncThunks,
  ...userSlice.actions
};
