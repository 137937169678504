import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { manageTeamMessages } from "app/pages/Teams/messages";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import ConditionalRender from "app/components/common/ConditionalRender";
import { containsUrl, fetchingStatus } from "app/utils/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import GrowingInput from "app/components/common/GrowingInput";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const StyledInput = styled(GrowingInput)`
  &&,
  &&:focus {
    input {
      background-color: ${({ theme }) => theme.gray1};
      color: ${({ theme }) => theme.gray11};
    }
    border: 1px solid ${({ theme }) => theme.gray5};
    outline: none;
    align-self: center;
    border-radius: 2px;
    padding: 2px 10px;
  }
`;

const MembersPageTeamName = () => {
  const [isEditName, setIsEditName] = useState<boolean>(false);
  const [workspaceNameValue, setWorkspaceNameValue] = useState<string>("");
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const updateWorkspaceNameStatus = useAppSelector(
    (state) => state.workspaces.updateWorkspaceNameStatus
  );
  const isLoadingNameUpdate = updateWorkspaceNameStatus === fetchingStatus.loading;
  const isOwner = currentWorkspace?.is_owner;

  useEffect(() => {
    if (updateWorkspaceNameStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.updateWorkspaceNameStatusToIdle());
    }
    if (updateWorkspaceNameStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.updateWorkspaceNameStatusToIdle());
    }
  }, [updateWorkspaceNameStatus]);

  useEffect(() => {
    if (currentWorkspace) {
      setWorkspaceNameValue(currentWorkspace.name);
    }
  }, [currentWorkspace]);

  const onChangeWorkspaceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!!value && !containsUrl(value)) {
      setWorkspaceNameValue(value);
    }
  };

  const onApproveWorkspaceName = () => {
    if (workspaceNameValue !== currentWorkspace?.name) {
      dispatch(
        workspacesActions.updateWorkspacePropertiesRequest({
          workspaceId: currentWorkspace?.id as string,
          workspace: { name: workspaceNameValue }
        })
      );
    }
    setIsEditName(false);
  };

  return (
    <H1_FlexRow padding="5px 0 20px 46px" gap="15px" align="center">
      <ConditionalRender condition={isLoadingNameUpdate}>
        <Spin indicator={<LoadingOutlined spin />} />
      </ConditionalRender>
      <ConditionalRender condition={!isEditName && !isLoadingNameUpdate}>
        <H1_TextMiddle>{currentWorkspace?.name}</H1_TextMiddle>
        <ConditionalRender condition={isOwner}>
          <Button
            size="sm"
            color="primary"
            onClick={() => setIsEditName(true)}
            startContent={<i className="fa-solid fa-pencil" />}
          >
            {formatMessage(manageTeamMessages.edit)}
          </Button>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender condition={isEditName && !isLoadingNameUpdate}>
        <StyledInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={isEditName}
          value={workspaceNameValue}
          onChange={onChangeWorkspaceName}
        />
        <Button
          size="sm"
          color="primary"
          onClick={onApproveWorkspaceName}
          isIconOnly
          variant="bordered"
          startContent={<i className="fa-solid fa-check" />}
        />
      </ConditionalRender>
    </H1_FlexRow>
  );
};

export default MembersPageTeamName;
