import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import EmptyState from "app/components/common/EmptyState";
import { charactersActions } from "app/store/slices/characters.slice";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import { charactersGlobalSelectors, scenesGlobalSelectors } from "app/store/adapters/adapters";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import CharacterDrawerElement from "app/components/editor/sideDrawers/CharacterDrawer/CharacterDrawerElement";
import styled from "styled-components";

const ContainerFlexRow = styled(H1_FlexRow)`
  min-height: 0;
`;
interface CharacterDrawerProps {
  query: string;
  characterAssetKey: string;
  voiceAssetKey: string;
  onClearSearch: () => void;
  isSwitchingAvatar: boolean;
}

const CharacterDrawerList = ({
  query,
  onClearSearch,
  characterAssetKey,
  voiceAssetKey,
  isSwitchingAvatar
}: CharacterDrawerProps) => {
  const [gridHeight, setGridHeight] = useState(window.innerHeight);
  const [originalGridHeight, setOriginalGridHeight] = useState(window.innerHeight);
  const ref = useRef<HTMLDivElement>(null);
  const [isCompletedLoading, setIsCompletedLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const isGestureSelected = !!useAppSelector((state) => state.characters.gestureSourceId);
  const characterFetchingStatus = useAppSelector((state) => state.characters.charactersStatus);
  const templateFetchingStatus = useAppSelector((state) => state.drafts.deleteDraftStatus);
  const currentTemplate = useAppSelector(CharacterSelectors.template);
  const filteredCharacters = useAppSelector((state) =>
    CharacterSelectors.getCharactersList(state, characterAssetKey, query, true)
  );
  const allCharacters = useAppSelector(charactersGlobalSelectors.selectAll);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const changeCinematicCharactersStatus = useAppSelector(
    (state) => state.characters.changeCinematicCharactersStatus
  );

  useEffect(() => {
    setGridHeight(ref.current?.offsetHeight || 0);
    setOriginalGridHeight(ref.current?.offsetHeight || 0);

    const handleResize = () => {
      setGridHeight(ref.current?.offsetHeight || 0);
      setOriginalGridHeight(ref.current?.offsetHeight || 0);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loading =
    !currentTemplate ||
    changeCinematicCharactersStatus === fetchingStatus.loading ||
    characterFetchingStatus === fetchingStatus.loading ||
    templateFetchingStatus === fetchingStatus.loading;

  useEffect(() => {
    if (!loading && isCompletedLoading) {
      setGridHeight(originalGridHeight);
    }
    dispatch(charactersActions.setFilters({ gestures: [] }));
  }, [isGestureSelected]);

  useEffect(() => {
    if (allCharacters.length > 0 && scenes.length > 0 && currentDraft) {
      setIsCompletedLoading(true);
    }
  }, [allCharacters.length, currentDraft, scenes]);

  const onResetAll = () => {
    dispatch(charactersActions.resetFilters());
    onClearSearch();
  };

  if (loading) {
    return <CircleLoader />;
  }
  return (
    <ContainerFlexRow ref={ref} wrap="wrap" gap="14px" justify="flex-start">
      <ConditionalRender condition={filteredCharacters.length === 0}>
        <EmptyState
          text={formatMessage(characterDrawerMessages.emptyText)}
          textSize="16px"
          description={formatMessage(characterDrawerMessages.emptyDescription)}
          icon={<H1_Icon icon="fal fa-user" size="20px" />}
          withButton
          buttonText={formatMessage(characterDrawerMessages.resetAll)}
          onClickButton={onResetAll}
        />
      </ConditionalRender>
      <ConditionalRender condition={filteredCharacters.length > 0}>
        <CharacterDrawerElement
          voiceAssetKey={voiceAssetKey}
          characterAssetKey={characterAssetKey}
          height={gridHeight}
          query={query}
          isSwitchingAvatar={isSwitchingAvatar}
        />
      </ConditionalRender>
    </ContainerFlexRow>
  );
};

export default CharacterDrawerList;
