import { useIntl } from "react-intl";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { Button } from "antd";
import joinSrcImage from "app/assets/teams/join-team.png";
import foldersSrcImage from "app/assets/teams/folders.png";
import { joinTeamMessages } from "app/pages/Teams/messages";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Invite } from "app/types";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { AnimatePresence, motion } from "framer-motion";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextMidHeadline,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    padding: 0;
    flex: 1;
    min-height: 739px;
    max-height: 80vh;
  }
`;

const TopFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray11};
`;

const JoinImage = styled.img`
  position: absolute;
  left: 45px;
  bottom: 38px;
`;

const FoldersImage = styled.img`
  position: absolute;
  right: 48px;
  top: 20px;
`;

const TitleText = styled(H1_TextMidHeadline)`
  display: inline-block;
  position: absolute;
  right: 44px;
  bottom: 39px;
`;

const BottomFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray1};
`;

const LinkButton = styled(Button)`
  &&&,
  &&&:focus {
    border: none;
    color: ${({ theme }) => theme.blue4};
    padding-left: 0;
  }
  i {
    margin-right: 5px;
  }
  &&&:hover {
    color: ${({ theme }) => theme.blue2};
    span {
      text-decoration: underline;
    }
  }
  &&&:active {
    color: ${({ theme }) => theme.blue1};
  }
`;

const ActionButton = styled(Button)`
  &&,
  &&:focus {
    color: ${({ theme }) => theme.gray1};
    background-color: ${({ theme }) => theme.blue4};
    width: 100%;
  }
  &&&:hover {
    background-color: ${({ theme }) => theme.blue2};
  }
  &&&:active {
    background-color: ${({ theme }) => theme.blue1};
  }
  &&&:disabled {
    background-color: ${({ theme }) => theme.gray6};
  }
`;

interface JoinTeamModalProps {
  visible: boolean;
  onClose: () => void;
}
const JoinTeamModal = ({ visible, onClose }: JoinTeamModalProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const invitations = useAppSelector((state) => state.workspaces.invitations);

  const submitInvite = (inviteId: string, isApproved: boolean, workspaceId?: string) => {
    dispatch(analyticsEvents.submitWorkspaceInvite({ inviteId, isApproved, workspaceId }));
    dispatch(workspacesActions.submitInviteRequest({ inviteId, isApproved, workspaceId }));
  };

  if (!invitations) {
    return null;
  }

  return (
    <Modal
      width="490px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon size="40px" />}
    >
      <TopFlexColumn position="relative" flex="0 0 273px">
        <JoinImage src={joinSrcImage} height={56} width={182} alt="join-team" />
        <FoldersImage src={foldersSrcImage} height={84} width={74} alt="folders" />
        <TitleText
          whiteSpace="break-spaces"
          color="var(--gray-01, #fff)"
          fontSize="26px"
          lineHeight="28px"
          fontWeight={500}
          fontFamily="DrukWide"
        >
          {/* @ts-ignore handels formmating issue with html tags params */}
          {formatMessage(joinTeamMessages.title, {
            span: (...text) => (
              <H1_TextMidHeadline
                color={theme.orange4}
                fontSize="26px"
                lineHeight="28px"
                fontWeight={500}
                fontFamily="DrukWide"
              >
                {text}
              </H1_TextMidHeadline>
            )
          })}
        </TitleText>
      </TopFlexColumn>
      <BottomFlexColumn justify="space-between" flex="1" padding="32px 46px 46px">
        <H1_FlexColumn>
          <H1_FlexColumn gap="19px" align="center" flex="0 0 auto" padding="0 0 10px 0">
            <H1_TextHeadline whiteSpace="break-spaces">
              {invitations.length > 1
                ? formatMessage(joinTeamMessages.welcomePlurals)
                : formatMessage(joinTeamMessages.welcome)}
            </H1_TextHeadline>
            <H1_TextSmall whiteSpace="break-spaces">
              {invitations.length > 1
                ? formatMessage(joinTeamMessages.descriptionPlurals)
                : formatMessage(joinTeamMessages.description)}
            </H1_TextSmall>
          </H1_FlexColumn>
          <H1_FlexColumn overflow="auto" position="relative">
            <AnimatePresence>
              {invitations.map((invite: Invite) => (
                <motion.div
                  key={invite.id}
                  layout="position"
                  initial={{ scale: 0.5 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{
                    default: {
                      duration: 0.5
                    }
                  }}
                >
                  <H1_FlexRow justify="space-between" flex="1" align="center">
                    <H1_TextSmall fontWeight={500} fontFamily="DrukWide">
                      {invite.workspace_name}
                    </H1_TextSmall>
                    <H1_FlexRow gap="10px">
                      <LinkButton
                        onClick={() => submitInvite(invite.id, true, invite.workspace_id)}
                      >
                        {formatMessage(joinTeamMessages.join)}
                      </LinkButton>
                      <LinkButton onClick={() => submitInvite(invite.id, false)}>
                        {formatMessage(joinTeamMessages.reject)}
                      </LinkButton>
                    </H1_FlexRow>
                  </H1_FlexRow>
                </motion.div>
              ))}
            </AnimatePresence>
          </H1_FlexColumn>
        </H1_FlexColumn>
        <H1_FlexRow
          align="center"
          justify="center"
          height="40px"
          padding="10px 0 0 0"
          flex="0 0 50px"
        >
          <ActionButton size="large" onClick={onClose}>
            {formatMessage(joinTeamMessages.letsGo)}
          </ActionButton>
        </H1_FlexRow>
      </BottomFlexColumn>
    </Modal>
  );
};

export default JoinTeamModal;
