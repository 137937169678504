export enum EditorView {
  Single = "Single",
  Multiple = "Multiple"
}

export enum TranscriptAudioPlayType {
  draft = "draft",
  scene = "scene",
  text = "text"
}
