import { createContext, Dispatch, SetStateAction } from "react";
import { Gender } from "app/types";

interface SettingContext {
  gender: Gender;
  setGender: Dispatch<SetStateAction<Gender>>;
  addPong: boolean;
  setAddPong: Dispatch<SetStateAction<boolean>>;
  addTransparent: boolean;
  setAddTransparent: Dispatch<SetStateAction<boolean>>;
  name: string;
  setName?: Dispatch<SetStateAction<string>>;
}

const CharacterSettingsContext = createContext<SettingContext | undefined>(undefined);

export default CharacterSettingsContext;
