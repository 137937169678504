import { defineMessages } from "react-intl";

export const pauseMessages = defineMessages({
  notSupported: {
    id: "pause-node.not-supported",
    defaultMessage: "Current voice does not support pauses"
  },
  notSupported3Secs: {
    id: "pause-node.not-supported-3-secs",
    defaultMessage: "Current voice provider supports pauses between 1 to 3 seconds"
  },
  pause: {
    id: "pause-node.pause",
    defaultMessage: "Add pause"
  }
});

export const pronunciationMessages = defineMessages({
  pronunciation: {
    id: "pronunciation-node.pronunciation",
    defaultMessage: "Add pronunciation"
  }
});

export const toolbarMessages = defineMessages({
  undoButton: {
    id: "toolbar.undo-button",
    defaultMessage: "Undo"
  },
  redoButton: {
    id: "toolbar.redo-button",
    defaultMessage: "Redo"
  }
});
