import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const buildGeneralMessage = (message = "", description = "", type = "alert") => {
  return {
    type,
    message,
    description: (
      <H1_TextXs color="#8C8C8C" whiteSpace="normal">
        {description}
      </H1_TextXs>
    )
  };
};

export default buildGeneralMessage;
