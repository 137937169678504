import React, { MouseEvent, useEffect, useState } from "react";
import { Alert, Button, Input } from "antd";
import { useIntl } from "react-intl";
import { uniqBy } from "lodash-es";
import messages from "app/components/editor/sideDrawers/EditingProjectMediaLibrary/messages";
import Config from "app/config/Config";
import { addUnsplashUtms } from "app/utils/helpers";
import { MediaLibraryTabs, MediaType, SelectedImageContext } from "app/types/media";
import styled, { css } from "styled-components";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import MediaGenericItem from "app/pages/mediaLibrary/MediaGenericItem";

const { Search } = Input;

interface UnsplashItem {
  id: string;
  created_at: string;
  updated_at: string;
  width: number;
  height: number;
  color: string;
  blur_hash: string;
  likes: number;
  liked_by_user: boolean;
  description: string;
  image?: string;
  title?: string;
  user: {
    id: string;
    username: string;
    name: string;
    portfolio_url: string;
    bio: string;
    location: string;
    total_likes: number;
    total_photos: number;
    total_collections: number;
    instagram_username: string;
    twitter_username: string;
    profile_image: {
      small: string;
      medium: string;
      large: string;
    };
    links: {
      self: string;
      html: string;
      photos: string;
      likes: string;
      portfolio: string;
    };
    current_user_collections: {
      id: number;
      title: string;
      published_at: string;
    }[];
  };
  urls: {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
  };
  links: {
    self: string;
    html: string;
    download: string;
    download_location: string;
  };
}

const WrapFlexRow = styled(H1_FlexRow)`
  flex-wrap: wrap;
  gap: 18px;
  overflow-y: auto;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 20px;
`;

const IconFlexRow = styled(H1_FlexRow)`
  border-radius: 2px;
  background-color: white;
  position: absolute;
  left: 15px;
  display: none;
  &:hover {
    transform: scale(1.1);
  }
  ${({ top }: { top: string }) => css`
    top: ${top};
  `};
`;

const ImageContainer = styled(H1_FlexColumn)`
  max-width: 168px;
  position: relative;
  &:hover ${IconFlexRow} {
    display: flex;
  }

  img {
    filter: saturate(65%);
    object-fit: contain;
  }
`;

const LinkText = styled.div`
  width: fit-content;
  align-self: stretch;
  margin: 3px 0 0;
  font-weight: 300;
  font-size: 9px;
  line-height: 1.2;
  padding: 0 8px;
  color: ${({ theme }) => theme.gray11};
`;

const LoadButton = styled(Button)`
  margin-top: 20px;
`;

interface UnsplashState {
  searchQuery: string;
  usplashOffset: number;
  usplashImages: UnsplashItem[];
}

const UnsplashTab = ({
  onSelectImage,
  currentValue,
  onZoomItem
}: {
  onSelectImage: (value: string, context?: SelectedImageContext) => void;
  currentValue: string;
  onZoomItem: (event: React.MouseEvent, url: string, mediaType?: MediaType) => void;
}) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<UnsplashItem>();
  const [loading, setLoading] = useState(false);
  const [unsplashState, setUnsplashState] = useState<UnsplashState>({
    searchQuery: "",
    usplashOffset: 1,
    usplashImages: []
  });
  const [error, setError] = useState<string>();
  const fetchUnsplash = async (
    searchQuery: string,
    usplashOffset: number,
    usplashImages: UnsplashItem[],
    url = "/photos"
  ) => {
    const clientId = Config.unsplashClient;
    setLoading(true);
    try {
      let baseUrl = `https://api.unsplash.com`;
      let combUrl = `${baseUrl}${url}/?client_id=${clientId}&per_page=12&page=${usplashOffset}`;
      if (searchQuery) {
        baseUrl = `https://api.unsplash.com/search`;
        combUrl = `${baseUrl}${url}/?client_id=${clientId}&per_page=12&page=${usplashOffset}&query=${searchQuery}`;
      }
      const res = await fetch(combUrl);
      let resBody = await res.json();
      const { results }: { results: UnsplashItem[] } = resBody;
      if (results) {
        resBody = results;
      }
      const usplashImagesForState = [
        ...usplashImages,
        ...resBody.map((imageData: UnsplashItem) => ({
          ...imageData,
          blurHash: imageData.blur_hash,
          image: addUnsplashUtms(imageData.urls.thumb),
          available: true,
          title:
            // @ts-ignore handels formmating issue with html tags params
            intl.formatMessage(messages.unsplashTagline, {
              name: imageData.user.name,
              imageLink: (str) => (
                <a
                  href={`${imageData.user.links.html}/?utm_source=Reals&utm_medium=referral`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {str}
                </a>
              ),
              unsplashLink: (str) => (
                <a
                  href="https://unsplash.com/?utm_source=Reals&utm_medium=referral"
                  target="_blank"
                  rel="noreferrer"
                >
                  {str}
                </a>
              )
            })
        }))
      ];
      const combined = uniqBy(usplashImagesForState, "id");

      setUnsplashState({ ...unsplashState, searchQuery, usplashOffset, usplashImages: combined });
    } catch (err) {
      setError(intl.formatMessage(messages.unsplashErr));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (unsplashState?.usplashImages?.length) {
      const imageItem = unsplashState?.usplashImages.find(
        (img: UnsplashItem) => img.urls?.regular === currentValue
      );
      if (imageItem) {
        setSelected(imageItem);
      } else {
        setSelected(undefined);
      }
    }
  }, [unsplashState?.usplashImages, currentValue]);

  useEffect(() => {
    fetchUnsplash(unsplashState.searchQuery, unsplashState.usplashOffset, []);
  }, []);

  const { usplashImages } = unsplashState;

  const onSearch = () => {
    setUnsplashState({ ...unsplashState, usplashImages: [], usplashOffset: 1 });
    fetchUnsplash(unsplashState.searchQuery, 1, []);
  };

  const onLoadMore = () => {
    const usplashOffset = unsplashState.usplashOffset + 1;
    setUnsplashState({ ...unsplashState, usplashOffset });
    fetchUnsplash(unsplashState.searchQuery, usplashOffset, unsplashState.usplashImages);
  };

  const onImageSelection = (e: MouseEvent<HTMLDivElement>, item: UnsplashItem) => {
    // @ts-ignore handels formmating issue with html tags params
    if (e?.target?.tagName === "A") return;
    setSelected(item);
    const imageUrl = usplashImages.find((img) => img.id === item.id)?.urls?.regular;
    if (imageUrl) {
      onSelectImage(imageUrl, {
        thumbnailType: MediaType.image,
        thumbnail: item.urls?.thumb,
        url: imageUrl,
        title: item.title,
        handle: item.id,
        tab: MediaLibraryTabs.Unsplash,
        mediaType: MediaType.image
      });
    }
  };

  return (
    <H1_FlexColumn overflow="auto" height="100%" padding="10px 0 0 0">
      <StyledSearch
        placeholder="Search unsplash"
        allowClear
        onChange={(e) =>
          setUnsplashState({ ...unsplashState, searchQuery: e.target.value.toString() })
        }
        onSearch={onSearch}
      />
      {error ? (
        <Alert message={error} showIcon />
      ) : (
        <WrapFlexRow>
          {usplashImages.map((item) => (
            <H1_FlexColumn key={item.id} width="168px">
              <H1_FlexColumn onClick={(e) => onImageSelection(e, item)}>
                <ImageContainer overflow="hidden" gap="7px" padding="4px" flex="0 0 auto">
                  <MediaGenericItem
                    url={item.image as string}
                    selected={item.id === selected?.id}
                    id={item.id}
                  />
                  <IconFlexRow
                    onClick={(e) => onZoomItem(e, item.urls?.full)}
                    top="25px"
                    padding="2px"
                    align="center"
                    justify="center"
                  >
                    <ArrowsAltOutlined />
                  </IconFlexRow>
                </ImageContainer>
              </H1_FlexColumn>
              <LinkText>{item.title}</LinkText>
            </H1_FlexColumn>
          ))}
        </WrapFlexRow>
      )}
      <LoadButton type="primary" loading={loading} onClick={onLoadMore}>
        {intl.formatMessage(messages.unsplashLoad)}
      </LoadButton>
    </H1_FlexColumn>
  );
};

export default UnsplashTab;
