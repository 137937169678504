import { useIntl } from "react-intl";
import { digitalTwinCreationMessages } from "app/pages/createDigitalTwinPage/messages";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_Select } from "app/components/_Infrastructure/design-system/select";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import useCharacterSettingsContext from "app/pages/createDigitalTwinPage/useCharacterSettingsContext";

const IconWrapper = styled(H1_FlexColumn)`
  color: ${(props) => props.theme.gray9};
`;

const StyledH1Input = styled(H1_Input)`
  input {
    background-color: ${(props) => props.theme.gray3};
    border-radius: 3px;
    border: none;
    &:focus,
    &:hover,
    &:active {
      border: none;
    }
  }
`;

const StyledH1Select = styled(H1_Select)`
  background-color: ${(props) => props.theme.gray3};
  border-radius: 3px;
  border: none;
  width: 100%;
  .ant-select-selection-item {
    &:focus,
    &:hover,
    &:active {
      border: none;
      * {
        color: initial;
      }
    }
  }
  &:focus,
  &:hover,
  &:active {
    border: none;
    * {
      color: initial;
    }
  }
`;

interface SelcetOption {
  key: string;
  value: string;
  label: string;
}
const genders: SelcetOption[] = [
  {
    key: "male",
    value: "male",
    label: "Male"
  },
  {
    key: "female",
    value: "female",
    label: "Female"
  },
  {
    key: "all",
    value: "not_given",
    label: "All"
  }
];

const CharacterSettings = () => {
  const intl = useIntl();
  const { gender, setGender, setName } = useCharacterSettingsContext();

  return (
    <>
      <H1_FlexColumn gap="10px">
        <IconWrapper>{intl.formatMessage(digitalTwinCreationMessages.avatarNameLabel)}</IconWrapper>
        <StyledH1Input
          placeholder={intl.formatMessage(digitalTwinCreationMessages.avatarNamePlaceholder)}
          onBlur={(e) => setName && setName(e.currentTarget.value)}
          minWidth="100%"
        />
      </H1_FlexColumn>
      <H1_FlexColumn gap="10px">
        <IconWrapper>
          {intl.formatMessage(digitalTwinCreationMessages.avatarGenderLabel)}
        </IconWrapper>
        <StyledH1Select
          width="300px"
          dropdownMatchSelectWidth={300}
          dropdownAlign={{
            offset: [-17, 5]
          }}
          placeholder={intl.formatMessage(digitalTwinCreationMessages.selectCamPlaceholder)}
          value={gender}
          options={genders}
          onChange={setGender}
        />
      </H1_FlexColumn>
    </>
  );
};

export default CharacterSettings;
