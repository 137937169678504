import { defineMessages } from "react-intl";

export default defineMessages({
  drawerTitle: {
    id: "editor.drawer.media.title",
    defaultMessage: "<b>Select a </b><b>Visual Asset</b>"
  },
  unsplashTagline: {
    id: "editor.drawer.media.unsplash.image.tagline",
    defaultMessage:
      "Photo by <imageLink>{name}</imageLink> on <unsplashLink>Unsplash</unsplashLink>"
  },
  unsplashLoad: {
    id: "editor.drawer.media.unsplash.button.load-more",
    defaultMessage: "Load more"
  },
  defaultMediaPreview: {
    id: "editor.drawer.media.preview.no-media",
    defaultMessage: "<b>Select a</b> media"
  },
  unsplashErr: {
    id: "editor.drawer.media.error.unsplash",
    defaultMessage: "Got Unsplash Error, Try again later"
  },
  carouselTag: {
    id: "editor.drawer.media.carousel.tag",
    defaultMessage: "Row {row}"
  },
  selectedLabel: {
    id: "editor.drawer.media.selected.label",
    defaultMessage: "Selected"
  },
  selectLabel: {
    id: "editor.drawer.media.select.label",
    defaultMessage: "Select"
  },
  tabMediaTitle: {
    id: "editor.drawer.media.tabs.media.title",
    defaultMessage: "Media library"
  },
  tabDataTitle: {
    id: "editor.drawer.media.tabs.data.title",
    defaultMessage: "Data library"
  },
  tabUnsplashTitle: {
    id: "editor.drawer.media.tabs.stock.title",
    defaultMessage: "Stock images"
  }
});
