import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled from "styled-components";
import { Widget } from "@typeform/embed-react";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { paymentsActions } from "app/store/slices/payments.slice";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import Config from "app/config/Config";
import { StyledModal } from "app/components/common/StyledModal";

const StyledWidget = styled(Widget)`
  width: 100%;
  height: 100%;
`;
interface CancelModalProps {
  open: boolean;
  onClose: () => void;
}
const CancelModal = ({ open, onClose }: CancelModalProps) => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const subscriptions = useAppSelector((state) => state.payments.subscriptions);
  const workspace = useAppSelector((state) => getCurrentWorkspace(state));

  const hiddenFields = {
    plan_renewal_date: subscriptions[0].current_period_end || "",
    first_name: user?.email as string,
    email: user?.email as string,
    user_id: user?.sub as string,
    subscription_plan: workspace?.metadata?.subscription_plan || "",
    refundable: String(subscriptions[0].refundable || false)
  };

  const onSubmit = () => {
    dispatch(paymentsActions.cancelSubscriptionRequest());
    onClose();
  };

  return (
    <StyledModal
      width="867px"
      open={open}
      footer={null}
      centered
      bodyStyle={{ height: "671px" }}
      onCancel={onClose}
      closeIcon={<CloseModalIcon />}
      $justifyContent="space-between"
      $padding="44px 42px 45px 55px"
      $alignItems="center"
    >
      <StyledWidget hidden={hiddenFields} id={Config.cancelFormId} onSubmit={onSubmit} />
    </StyledModal>
  );
};

export default CancelModal;
