import React from "react";
import { useIntl } from "react-intl";
import usePaymentFlow from "app/pages/pricing/usePaymentFlow";
import useModal, { ModalName } from "app/hooks/useModal";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextMiddle,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { paymentModalV2InitialMessages } from "app/pages/pricing/PaymentModalV2/messages";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { FeatureFlag, Location, PaymentPeriod, PlanToUpgrade } from "app/types";
import { Switch } from "antd";
import PaymentModalV2SinglePlan from "app/pages/pricing/PaymentModalV2/PaymentModalV2SinglePlan";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { paymentsActions } from "app/store/slices/payments.slice";
import { useNavigate } from "react-router-dom";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { getBusinessYearlyPlan } from "app/store/selectorsV2/payments.selectors";
import { Button } from "@nextui-org/react";
import { useFlags } from "launchdarkly-react-client-sdk";

const HeadlineText = styled(H1_TextHeadline)`
  align-self: center;
`;

const ToggleFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: auto;
  }
`;

const CircleFlexRow = styled(H1_FlexRow)`
  border-radius: 50%;
  background-color: ${(props) => props.theme.orange4};
`;

const UpgradeIcon = styled.i`
  color: ${(props) => props.theme.gray1};
`;

const CompareIcon = styled.i`
  color: ${(props) => props.theme.blue4};
  cursor: pointer;
`;

const FloatingText = styled(H1_FlexRow)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const BlueLinkButton = styled(Button)`
  &&& {
    background-color: transparent;
  }
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const BlueSwitch = styled(Switch)`
  &.ant-switch {
    background-color: ${({ theme }) => theme.blue4};
  }
`;

const FullFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;
const StyledPlansContainer = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    width: 100%;
    overflow: auto;
  }
`;

export interface PricingModalPhaseInitialTypeProps {
  onFinish: (plan: PlanToUpgrade) => void;
}

const PaymentModalV2PhaseInitial = ({ onFinish }: PricingModalPhaseInitialTypeProps) => {
  const { closeModal, editorModalOpen } = useModal();
  const flags = useFlags();
  const { upgradeText, targetPlan } = usePaymentFlow({
    paymentPeriod: editorModalOpen?.context?.paymentPeriod,
    targetPlan: editorModalOpen?.context?.targetPlan,
    active: editorModalOpen?.open === ModalName.paymentModalV2,
    source: editorModalOpen?.context?.source,
    upgradeText: editorModalOpen?.context?.upgradeText
  });
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const { paymentPeriod } = useAppSelector((state) => state.payments.pricingModalProperties);
  const planBusinessYearly = useAppSelector(getBusinessYearlyPlan);
  const isPaymentMonthly = paymentPeriod === PaymentPeriod.Monthly;
  const discount = planBusinessYearly?.discount_title as string;

  const onChangePaymentType = (checked: boolean) => {
    const newPaymentPeriod = checked ? PaymentPeriod.Monthly : PaymentPeriod.Yearly;
    dispatch(analyticsEvents.togglePaymentPeriod({ paymentPeriod: newPaymentPeriod }));
    dispatch(
      paymentsActions.updatePricingModalProperties({
        paymentPeriod: newPaymentPeriod
      })
    );
  };

  const onClickComparePlans = () => {
    dispatch(analyticsEvents.comparePlans());
    closeModal();
    navigate(Location.Pricing);
  };

  return (
    <H1_FlexColumn height="100%" flex="1" padding="50px 60px 40px 60px" position="relative">
      <FullFlexColumn gap="15px" align="center">
        <ConditionalRender condition={!!upgradeText}>
          <FloatingText gap="10px" align="center">
            <CircleFlexRow height="31px" width="31px" justify="center" align="center">
              <UpgradeIcon className="fa fa-crown" />
            </CircleFlexRow>
            <H1_TextMiddle color={theme.gray8}>{upgradeText}</H1_TextMiddle>
          </FloatingText>
        </ConditionalRender>
        <HeadlineText>{formatMessage(paymentModalV2InitialMessages.title)}</HeadlineText>
        <ConditionalRender condition={flags[FeatureFlag.withPaymentYearly]}>
          <ToggleFlexRow justify="center" width="100%" gap="15px">
            <H1_FlexRow gap="5px">
              <H1_TextXs color={!isPaymentMonthly ? theme.gray11 : theme.gray7}>
                {formatMessage(paymentModalV2InitialMessages.yearly)}
              </H1_TextXs>
              <H1_TextXs color={theme.blue4}>
                {formatMessage(paymentModalV2InitialMessages.yearlyDiscount, {
                  yearlyDiscount: discount
                })}
              </H1_TextXs>
            </H1_FlexRow>
            <BlueSwitch checked={isPaymentMonthly} onChange={onChangePaymentType} />
            <H1_TextXs color={isPaymentMonthly ? theme.gray11 : theme.gray7}>
              {formatMessage(paymentModalV2InitialMessages.monthly)}
            </H1_TextXs>
          </ToggleFlexRow>
        </ConditionalRender>
        <StyledPlansContainer gap="25px" padding="26px 0 5px 0" align="flex-end">
          <PaymentModalV2SinglePlan
            cardPlan={PlanToUpgrade.personal}
            onSelectedPlan={onFinish}
            targetPlan={targetPlan}
          />
          <PaymentModalV2SinglePlan
            cardPlan={PlanToUpgrade.business}
            onSelectedPlan={onFinish}
            targetPlan={targetPlan}
            isRecommended
          />
          <PaymentModalV2SinglePlan
            cardPlan={PlanToUpgrade.enterprise}
            onSelectedPlan={onFinish}
            targetPlan={targetPlan}
          />
        </StyledPlansContainer>
        <H1_FlexRow gap="5px" align="center" onClick={onClickComparePlans}>
          <BlueLinkButton variant="light" color="primary" onClick={onClickComparePlans}>
            {formatMessage(paymentModalV2InitialMessages.comparePlans)}
          </BlueLinkButton>
          <CompareIcon className="fa-regular fa-arrow-up-right-from-square" />
        </H1_FlexRow>
      </FullFlexColumn>
    </H1_FlexColumn>
  );
};

export default PaymentModalV2PhaseInitial;
