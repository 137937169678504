import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import JSONPretty from "react-json-pretty";
import styled, { useTheme } from "styled-components";

const MaxHeightFlexRow = styled(H1_FlexRow)`
  max-height: 300px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
  border-radius: 4px;
`;
const StyledJSONPretty = styled(JSONPretty)<{ $height: string }>`
  && .__json-pretty__ {
    ${({ $height }) => ($height ? `height: ${$height}` : "")}
    overflow: scroll;
  }
  && {
    width: 100%;
  }
`;

interface JsonViewerProps {
  value: any;
  height?: string;
}

const JsonViewer = ({ value, height }: JsonViewerProps) => {
  const theme = useTheme();
  const commonStyle = "background:" + theme.gray3 + ";color:" + theme.gray11;
  return (
    <MaxHeightFlexRow width={"100%"} height={height || "auto"} position="relative">
      <StyledJSONPretty
        $height={height || "300px"}
        id="json-pretty"
        data={value || {}}
        mainStyle={commonStyle + ";padding:5px;margin-bottom:0"}
        keyStyle={commonStyle}
        valueStyle={commonStyle}
        booleanStyle={commonStyle}
        stringStyle={commonStyle}
      />
    </MaxHeightFlexRow>
  );
};

export default JsonViewer;
