/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/playlists.thunk";
import { fetchingStatus } from "app/utils/helpers";
import { FetchStatus } from "app/types";
import { playlistsAdapter } from "app/store/adapters/adapters";

interface PlaylistState {
  status: FetchStatus;
  playlistVideosStatus: FetchStatus;
  createPlaylistStatus: FetchStatus;
  updatePlaylistStatus: FetchStatus;
  createPlaylistMergeStatus: FetchStatus;
  playlistMergedVideoId?: string;
}

const INITIAL_STATE: PlaylistState = {
  status: fetchingStatus.idle,
  playlistVideosStatus: fetchingStatus.idle,
  createPlaylistStatus: fetchingStatus.idle,
  updatePlaylistStatus: fetchingStatus.idle,
  createPlaylistMergeStatus: fetchingStatus.idle
};

export const playlistsSlice = createSlice({
  name: "Playlists",
  initialState: playlistsAdapter.getInitialState<PlaylistState>(INITIAL_STATE),
  reducers: {
    setAllPlaylists: (state, action) => {
      playlistsAdapter.setAll(state, action.payload);
    },
    updateCreatePlaylistStatusToIdle: (state) => {
      state.createPlaylistStatus = fetchingStatus.idle;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getPlaylistsRequest.fulfilled, (state, action) => {
      playlistsAdapter.setAll(state, action.payload);
      state.status = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.getPlaylistsRequest.pending, (state) => {
      state.status = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getPlaylistsRequest.rejected, (state) => {
      state.status = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.getPlaylistByIdRequest.fulfilled, (state, action) => {
      state.playlistVideosStatus = fetchingStatus.succeeded;
      playlistsAdapter.addOne(state, action.payload);
    });
    builder.addCase(asyncThunks.getPlaylistByIdRequest.pending, (state) => {
      state.playlistVideosStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getPlaylistByIdRequest.rejected, (state) => {
      state.playlistVideosStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.createPlaylistRequest.fulfilled, (state, action) => {
      state.ids.unshift(action.payload.id);
      state.entities[action.payload.id] = action.payload;
      state.createPlaylistStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.createPlaylistRequest.pending, (state) => {
      state.createPlaylistStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.createPlaylistRequest.rejected, (state) => {
      state.createPlaylistStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.deletePlaylistRequest.pending, (state, action) => {
      const { id } = action.meta.arg;
      playlistsAdapter.removeOne(state, id);
    });
    builder.addCase(asyncThunks.updatePlaylistRequest.fulfilled, (state) => {
      state.updatePlaylistStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.updatePlaylistRequest.pending, (state, action) => {
      state.updatePlaylistStatus = fetchingStatus.loading;
      const { id, title, draftsIds } = action.meta.arg;
      playlistsAdapter.updateOne(state, {
        id,
        changes: { title, draft_ids: draftsIds }
      });
    });
    builder.addCase(asyncThunks.updatePlaylistRequest.rejected, (state) => {
      state.updatePlaylistStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.createPlaylistMergeRequest.fulfilled, (state, action) => {
      state.playlistMergedVideoId = action.payload?.video?.id;
      state.createPlaylistMergeStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.createPlaylistMergeRequest.pending, (state) => {
      state.createPlaylistMergeStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.createPlaylistMergeRequest.rejected, (state) => {
      state.createPlaylistMergeStatus = fetchingStatus.failed;
    });
  }
});

export default playlistsSlice.reducer;

export const playlistsActions = {
  getPlaylistsRequest: asyncThunks.getPlaylistsRequest,
  getPlaylistByIdRequest: asyncThunks.getPlaylistByIdRequest,
  createPlaylistRequest: asyncThunks.createPlaylistRequest,
  updatePlaylistRequest: asyncThunks.updatePlaylistRequest,
  deletePlaylistRequest: asyncThunks.deletePlaylistRequest,
  createPlaylistMergeRequest: asyncThunks.createPlaylistMergeRequest,
  ...playlistsSlice.actions
};
