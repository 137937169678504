import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSubtitle, H1_TextXs } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { homePageWorkspaceMessages } from "app/pages/HomePage/messages";
import { FetchStatus, Video } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import videosSelectors from "app/store/selectorsV2/videos.selectors";
import React, { useEffect, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useModal, { ModalName } from "app/hooks/useModal";
import { videosActions } from "app/store/slices/videos.slice";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { fetchingStatus, getTimeAgo } from "app/utils/helpers";
import { Avatar, Button } from "@nextui-org/react";
import { Typography } from "antd";
import ShareVideoMenu from "app/components/common/ShareVideoMenu";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

const ButtonsFlexRow = styled(H1_FlexRow)`
  transition: all 0.3s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
`;
const PositionButton = styled(Button)`
  background-color: rgba(202, 202, 202, 0.6);
  color: ${({ theme }) => theme.gray1};
  span {
    color: ${({ theme }) => theme.gray1};
  }
  border: none;
  border-radius: 50%;
  &&& {
    transform: translate(-50%, -50%);
  }
  &:hover,
  &:active {
    border: none;
    color: ${({ theme }) => theme.gray1};
    span {
      color: ${({ theme }) => theme.gray1};
    }
    background-color: rgba(202, 202, 202, 1);
    &&& i {
      color: ${({ theme }) => theme.gray1};
      fill: ${({ theme }) => theme.gray1};
    }
  }
`;

const StyledIcon = styled.i`
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  margin: auto;
`;

const NameText = styled(Typography.Text)`
  font-size: 14px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  color: ${({ theme }) => theme.gray11};
`;

const NameAvatar = styled(Avatar)`
  min-width: 29px;
  min-height: 29px;
  flex-shrink: 0;
`;

const StyledImage = styled.img`
  border-radius: 10px;
  height: auto;
  width: 100%;
`;

const StyledVideoContainer = styled(H1_FlexRow)<{ $aspectRatio: number }>`
  border-radius: 10px;
  width: ${({ $aspectRatio }) => `calc(133px * ${$aspectRatio})`};
  height: 133px;
  max-height: 133px;
  background-color: transparent;
  display: flex;
  img {
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    ${ButtonsFlexRow} {
      z-index: 2;
    }
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.4);
    img {
      filter: brightness(80%);
    }
  }
  & > * {
    flex: 1;
  }
`;

const LIMIT = 20;

const PublishedVideosStrip = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [hoverId, setHoverId] = useState<string | undefined>(undefined);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const isLastVideoReached: boolean = useAppSelector((state) => state.videos.isLastVideoReached);
  const readyToPlayVideos: Video[] = useAppSelector(videosSelectors.getReadyToPlayVideos);
  const getVideoFeedStatus: FetchStatus = useAppSelector(
    (state) => state.videos.getVideoFeedStatus
  );
  const isVideoFeedStatusLoading = getVideoFeedStatus === fetchingStatus.loading;

  useEffect(() => {
    dispatch(videosActions.cleanVideos());
  }, []);

  useEffect(() => {
    if (!isLoaded && getVideoFeedStatus === fetchingStatus.succeeded) {
      setIsLoaded(true);
    }
  }, [getVideoFeedStatus]);

  useEffect(() => {
    dispatch(
      videosActions.getVideosFeedRequest({
        limit: LIMIT,
        skip: currentPage * LIMIT
      })
    );
  }, [currentPage]);

  const onClickVideo = (video: Video) => {
    openModal(ModalName.videoReady, { reqId: video.req_id });
  };

  const onMouseEnter = (videoId: string) => {
    setHoverId(videoId);
  };

  const onMouseLeave = () => {
    setHoverId(undefined);
  };

  const onClickLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <H1_FlexColumn padding="28px 0 0" gap="21px" flex="1">
      <ConditionalRender condition={!isLoaded}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={readyToPlayVideos.length > 0}>
        <H1_FlexRow align="center" justify="space-between">
          <H1_TextSubtitle color={theme.gray8} fontWeight={500}>
            {formatMessage(homePageWorkspaceMessages.publishedVideos)}
          </H1_TextSubtitle>
        </H1_FlexRow>
        <H1_FlexRow
          height="100%"
          width="100%"
          position="relative"
          padding="0 30px 30px 00px"
          overflow="auto"
          wrap="wrap"
          gap="30px"
        >
          {readyToPlayVideos.map((video: Video) => (
            <H1_FlexColumn
              key={video.id}
              gap="8px"
              align="center"
              onClick={() => onClickVideo(video)}
              width="250px"
            >
              <StyledVideoContainer
                overflow="hidden"
                position="relative"
                $aspectRatio={video.aspect_ratio.width / video.aspect_ratio.height}
              >
                <StyledImage
                  src={hoverId === video.id ? video.animated_thumbnail : video.thumbnail_url}
                  onMouseEnter={() => onMouseEnter(video.id)}
                  onMouseLeave={onMouseLeave}
                />
                <ButtonsFlexRow position="absolute" gap="10px">
                  <PositionButton
                    isIconOnly
                    onClick={() => onClickVideo(video)}
                    startContent={<StyledIcon className="fa-solid fa-play" />}
                  />
                </ButtonsFlexRow>
              </StyledVideoContainer>
              <H1_FlexRow align="center" justify="space-between" width="100%">
                <H1_FlexRow gap="10px" overflow="hidden">
                  {video.created_by_picture ? (
                    <NameAvatar size={29} src={video.created_by_picture} />
                  ) : (
                    <NameAvatar size={29}>
                      {video.created_by_name ? video.created_by_name[0] : ""}
                    </NameAvatar>
                  )}
                  <H1_FlexColumn overflow="hidden">
                    <NameText ellipsis={{ tooltip: video.title }}>{video.title}</NameText>
                    <H1_TextXs color={theme.gray7}>{getTimeAgo(video.created_at)}</H1_TextXs>
                  </H1_FlexColumn>
                </H1_FlexRow>
                <ShareVideoMenu
                  isNextUI
                  withText={false}
                  withDownload
                  withCopyLink
                  video={video}
                  source="homepage_workspace_video"
                  icon={
                    <H1_Icon isCursorPointer color={theme.gray11} icon="far fa-ellipsis-vertical" />
                  }
                />
              </H1_FlexRow>
            </H1_FlexColumn>
          ))}
        </H1_FlexRow>

        <H1_FlexRow width="100%" margin="0 0 30px 0" justify="center">
          <Button
            color="primary"
            onClick={onClickLoadMore}
            isLoading={isVideoFeedStatusLoading}
            isDisabled={isLastVideoReached}
          >
            {formatMessage(homePageWorkspaceMessages.loadMore)}
          </Button>
        </H1_FlexRow>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default PublishedVideosStrip;
