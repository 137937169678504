import { DisplayStateEnum } from "app/components/VideoShareProtection/ProtectionContext";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import useVideoShareProtection from "app/components/VideoShareProtection/useVideoShareProtection";
import { Input, InputRef } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useIntl } from "react-intl";
import { publishVideoModalMessages } from "app/pages/editor/PublishVideoModal/messages";
import { useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { ProtectionType } from "app/types";
import { MouseEventHandler, useRef, useState } from "react";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button, Switch } from "@nextui-org/react";

const MarginIIcon = styled.i`
  margin-right: 10px;
`;

const ErrorSmallText = styled(H1_TextXs)`
  margin-left: 10px;
  color: ${({ theme }) => theme.gray7};
`;

const PasswordInput = styled(Input.Password)`
  width: 100%;
  margin-right: 10px;
  min-height: 40px;
`;

const PublishVideoProtectionView = ({
  showVideoUrl,
  isPublishLoading,
  onCopy,
  onInput,
  url,
  onChangeAvailability,
  linkAvailability
}: {
  url: string;
  showVideoUrl: boolean;
  isPublishLoading: boolean;
  onCopy: () => void;
  onInput: () => void;
  onChangeAvailability: (isSelected: boolean) => void;
  linkAvailability: boolean;
}) => {
  const [passwordValidationError, setPasswordValidationError] = useState<string>();
  const passRef = useRef<InputRef>(null);
  const { formatMessage } = useIntl();
  const setVideoProtectionStatus = useAppSelector((state) => state.videos.setVideoProtectionStatus);
  const fetchVideoProtectionStatus = useAppSelector(
    (state) => state.videos.fetchVideoProtectionStatus
  );

  const showPaymentModal = useUpgradeModal();
  const showCrown = useShowCrown({
    requestedFeature: PlanFeature.VideoPasswordProtection
  });

  const { displayState, setVideoProtection, setVideoProtectionDisplay } = useVideoShareProtection();

  const isAnyLoading =
    isPublishLoading ||
    setVideoProtectionStatus === fetchingStatus.loading ||
    fetchVideoProtectionStatus === fetchingStatus.loading;

  const passwordValidation = (password: string) => {
    if (password) {
      const valid = password.length > 2 && password.length < 9;
      if (!valid) {
        setPasswordValidationError(
          formatMessage(publishVideoModalMessages.passwordGeneralRestriction)
        );
        return false;
      }
    } else {
      setPasswordValidationError(formatMessage(publishVideoModalMessages.emptyPasswordGeneral));
      return false;
    }
    setPasswordValidationError(undefined);
    return true;
  };

  const onSetPassword: MouseEventHandler<HTMLElement> = () => {
    if (passRef?.current) {
      const password = passRef.current?.input?.value;
      if (
        password &&
        !showPaymentModal({
          source: "video_password_protection_dialog",
          requestedFeature: PlanFeature.VideoPasswordProtection
        })
      ) {
        if (passwordValidation(password)) {
          setVideoProtection(ProtectionType.Password, { password });
        }
      }
    }
  };

  const onOveridePassword = () => {
    setVideoProtectionDisplay(DisplayStateEnum.SHOW_PASSWORD_DIALOG);
  };

  const onPasswordBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    passwordValidation(event.target?.value);
  };

  const displayStateShowPassword = displayState === DisplayStateEnum.SHOW_PASSWORD_DIALOG;
  const displayStateShowPasswordProtected = displayState === DisplayStateEnum.PROTECTED_BY_PASSWORD;

  return (
    <>
      <ConditionalRender condition={!displayStateShowPassword}>
        <Switch size="sm" isSelected={linkAvailability} onValueChange={onChangeAvailability}>
          {formatMessage(publishVideoModalMessages.videoAvailability)}
        </Switch>
        <H1_FlexRow height="40px" gap="21px" flex="1">
          <Input disabled value={showVideoUrl ? url : ""} onClick={onInput} />
          <Button
            color="primary"
            isLoading={isAnyLoading}
            startContent={<i className="far fa-link" />}
            onClick={onCopy}
            isDisabled={displayState == DisplayStateEnum.SHOW_PASSWORD_DIALOG}
          >
            {formatMessage(publishVideoModalMessages.copyLinkButton)}
          </Button>
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={displayStateShowPasswordProtected}>
        <Button
          color="primary"
          isLoading={isAnyLoading}
          startContent={<MarginIIcon className="fa-regular fa-lock" />}
          onClick={onOveridePassword}
        >
          {formatMessage(publishVideoModalMessages.overrideProtectionButton)}
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={displayStateShowPassword}>
        <H1_FlexRow height="40px" gap="21px" width="100%">
          <H1_FlexColumn flex="1">
            <PasswordInput
              placeholder="password"
              ref={passRef}
              maxLength={8}
              minLength={3}
              status={passwordValidationError?.length ? "error" : undefined}
              onBlur={onPasswordBlur}
            />
            <ConditionalRender condition={!!passwordValidationError}>
              <ErrorSmallText>{passwordValidationError}</ErrorSmallText>
            </ConditionalRender>
            <ConditionalRender condition={!passwordValidationError}>
              <ErrorSmallText>
                {formatMessage(publishVideoModalMessages.passwordGeneralRestriction)}
              </ErrorSmallText>
            </ConditionalRender>
          </H1_FlexColumn>
          <Button
            color="primary"
            isLoading={isAnyLoading}
            startContent={showCrown ? <CrownIcon /> : <i className="far fa-lock" />}
            onClick={onSetPassword}
            isDisabled={!!passwordValidationError}
          >
            {formatMessage(publishVideoModalMessages.protectButton)}
          </Button>
        </H1_FlexRow>
      </ConditionalRender>
    </>
  );
};

export default PublishVideoProtectionView;
