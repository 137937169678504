import { defineMessages } from "react-intl";

export const TrashPageMessages = defineMessages({
  recoverDraft: {
    id: `trash.recover-draft.title`,
    defaultMessage: "Recover videos"
  },
  cleanDraft: {
    id: `trash.clean-draft.title`,
    defaultMessage: "Delete videos forever"
  },
  emptyFolderText: {
    id: `trash.empty-state.text`,
    defaultMessage: "Trash is empty"
  },
  emptyFolderDescription: {
    id: `trash.empty-state.description`,
    defaultMessage: "No videos in this folder"
  }
});
