import React, { useState, MouseEvent } from "react";
import QueueAnim from "rc-queue-anim";

import { Row, Col, Typography, Descriptions, Modal, Badge, Dropdown, message, Menu } from "antd";
import {
  MoreOutlined,
  DownloadOutlined,
  ShareAltOutlined,
  CodeOutlined
  // PlusCircleOutlined
} from "@ant-design/icons";
import { Gutter } from "antd/lib/grid/row";

import { has, capitalize } from "lodash-es";
import VideoWithPoster from "app/components/common/VideoWithPoster";
import ImageWithLoading from "app/components/common/ImageWithLoading";
import "app/components/common/discovery/Discovery.scss";

const { Title } = Typography;

// It once was a lodash type, We had to extract it for us to use lodash-es.
type Many<T> = T | ReadonlyArray<T>;
interface DiscoveryGridProps<T> {
  data: T[];
  unavailableBadgeText?: string;
  unavailableKey?: Many<string | number | symbol>;
  idProp?: string;
  details?: null | string[];
  chosen?: null | string;
  detailsBox?:
    | null
    | ((
        item: T,
        details: string[],
        selected: null | T,
        onSelect: (selectedItem: T) => void,
        id: string
      ) => void);
  showMenu?: boolean;
  badgeDescriptionKey?: null | string;
  onSelect?: (e: Event | MouseEvent | MouseEvent<HTMLElement, MouseEvent>, id: string) => void;
  onDetailsBoxOpen?: null | ((item: T) => void);
  onSelectUnavailable?: null | (() => void);
  noAnim?: boolean;
  wrapperRowGutter?: Gutter | [Gutter, Gutter];
}
const DiscoveryGrid = <T extends Record<string, any>>({
  data,
  unavailableBadgeText = "Comming Soon",
  unavailableKey = "available",
  idProp = "id",
  details = null,
  chosen = null,
  detailsBox = null,
  showMenu = false,
  badgeDescriptionKey = null,
  onSelect = () => null,
  onDetailsBoxOpen = () => null,
  onSelectUnavailable = null,
  noAnim = false,
  wrapperRowGutter = [20, 20]
}: DiscoveryGridProps<T>) => {
  const [selected, setSelected] = useState<T | null>(null);
  const selectPreview = (video: string): typeof ImageWithLoading | typeof VideoWithPoster => {
    if (!video) return ImageWithLoading;
    return video.endsWith(".gif") ? ImageWithLoading : VideoWithPoster;
  };
  const renderMenu = () => {
    const items = [
      {
        key: "Download",
        icon: <DownloadOutlined />,
        label: "Download"
      },
      {
        key: "Share",
        icon: <ShareAltOutlined />,
        label: "Share"
      },
      {
        key: "Embed",
        icon: <CodeOutlined />,
        label: "Embed"
      }
    ];
    return <Menu items={items} />;
  };

  const toggleSelected = (item: T): void => setSelected(selected === item ? null : item);

  const renderDetailsBox = (item: T) => {
    const disabled = has(item, unavailableKey) && !item[unavailableKey as string];

    const PreviewTag = selectPreview(item.video ? (item.video as string) : (item.image as string));
    return (
      <Modal
        key={item[idProp as string] as string | number | null | undefined}
        open={selected === item}
        onOk={(e) => onSelect(e, item[idProp] as string)}
        onCancel={() => toggleSelected(item)}
        okText={disabled ? unavailableBadgeText : "Choose me"}
        okButtonProps={{ disabled }}
        centered
        width={850}
      >
        <Row gutter={[20, 20]}>
          <Col className="gutter-row" span={24}>
            <Title level={3}>{item.title}</Title>
          </Col>
          <Col className="gutter-row" span={17}>
            <PreviewTag
              video={item.video}
              alt={`box-${item[idProp as string]}`}
              style={{ backgroundColor: item.color ? item.color : "unset" }}
              blurHash={item.blurHash}
            />
          </Col>
          <Col className="gutter-row" span={7} style={{ paddingLeft: "10px" }}>
            <Descriptions layout="horizontal" size="small">
              {details &&
                details.map((itemKey) => (
                  <Descriptions.Item
                    key={`desc-${itemKey}`}
                    label={capitalize(itemKey.replace("_", " "))}
                    span={3}
                  >
                    <Title level={5}>{capitalize(item[itemKey])}</Title>
                  </Descriptions.Item>
                ))}
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    );
  };
  const handleBoxClick = (e: MouseEvent, item: T) => {
    // Handle detailed item
    if (details && details.length) {
      if (typeof onDetailsBoxOpen === "function") onDetailsBoxOpen(item);
      if (item !== selected) return toggleSelected(item);
    } else {
      // Handle unavilable items
      const isNotAvailable = has(item, "available") && !item.available;
      const isPro = has(item, "pro") && item.pro;
      if (isPro || isNotAvailable) {
        return onSelectUnavailable
          ? onSelectUnavailable()
          : message.error("This item is unavailable");
      }
      return onSelect(e, item[idProp as string]);
    }

    return false;
  };

  const renderBox = (item: T) => {
    const PreviewTag = selectPreview(item.video);
    return (
      <Col
        flex={1}
        className={`box ${selected === item ? "selected" : ""} ratio-169`}
        key={`box-${item[idProp as string]}`}
        onClick={(e) => handleBoxClick(e, item)}
      >
        <PreviewTag
          className={`box ${selected === item ? "selected" : ""} ${
            item[idProp as string] === chosen ? "chosen" : ""
          }`}
          style={{ backgroundColor: item.color ? item.color : null }}
          image={item.image}
          video={item.video}
          alt={`preview-box-${item[idProp as string]}`}
          disableIcon
          blurHash={item.blurHash}
        />
        {showMenu && (
          <Dropdown overlay={renderMenu}>
            <MoreOutlined style={{ position: "absolute", top: "171px", right: "12px" }} />
          </Dropdown>
        )}
        {badgeDescriptionKey && (
          <span className="badge-description">{item[badgeDescriptionKey]}</span>
        )}
        {(item.title || item.owner || item.date) && (
          <div className="section-header">
            {item.title ? <span className="video-title">{item.title}</span> : null}
            {item.owner && (
              <span className="video-subtitle">
                {item.owner || " "} | {item.views || " "}
              </span>
            )}
            {item.date && <span className="video-subtitle">{item.date || " "}</span>}
          </div>
        )}
        {details && detailsBox
          ? detailsBox(item, details, selected, toggleSelected, idProp)
          : renderDetailsBox(item)}
      </Col>
    );
  };

  const renderItemWrap = (item: T) => {
    const unavailable =
      unavailableKey && has(item, unavailableKey)
        ? !item[unavailableKey as string]
        : item.available || true;
    const isPro = has(item, "pro") && item.pro;
    return unavailable || isPro ? (
      <Badge.Ribbon text={unavailableBadgeText} key={`box-${item[idProp || "id"]}-badge`}>
        {renderBox(item)}
      </Badge.Ribbon>
    ) : (
      <div className="no-ribbon-wrapper" key={`box-${item[idProp || "id"]}-no-badge`}>
        {renderBox(item)}
      </div>
    );
  };

  const renderContent = () => data.map((item) => renderItemWrap(item));
  return (
    <div className="grid-container">
      {noAnim ? (
        <Row gutter={wrapperRowGutter}>{renderContent()}</Row>
      ) : (
        <QueueAnim
          type={["right", "left"]}
          // @ts-ignore handels IProps.component?: string | React.ClassType<any, React.Component<{}, {}, any>, React.ComponentClass<any, any>> | React.ForwardRefExoticComponent<IProps & {
          component={Row}
          componentProps={{ gutter: wrapperRowGutter }}
        >
          {renderContent()}
        </QueueAnim>
      )}
    </div>
  );
};

DiscoveryGrid.defaultProps = {
  idProp: "id",
  unavailableKey: "available",
  unavailableBadgeText: "Upgrade ⭐",
  showMenu: false,
  noAnim: false,
  wrapperRowGutter: [20, 20],
  details: null,
  chosen: null,
  detailsBox: null,
  badgeDescriptionKey: null,
  onDetailsBoxOpen: () => null,
  onSelectUnavailable: null,
  // startFromScratchItem: null,
  onSelect: () => null
};

export default DiscoveryGrid;
