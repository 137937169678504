import React from "react";
import { defineMessages, useIntl } from "react-intl";

import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import AiGenerate from "app/components/editor/scene/AugmentedSelection/AiGenerate";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

const messages = defineMessages({
  title: {
    id: "video-wizard.title",
    defaultMessage: "Create AI Video"
  },
  subTitle: {
    id: "video-wizard.subtitle",
    defaultMessage: "Write my script in just seconds by entering video description"
  }
});

const VideoWizardSymbol = styled(H1_FlexRow)`
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.blue2};
  border-radius: 10px;
`;

export interface VideoWizardDrawerProps {
  right: string;
  width: string;
}

const VideoWizardDrawer = ({ right, width }: VideoWizardDrawerProps) => {
  const intl = useIntl();
  const { editorDrawerOpen } = useDrawer();
  const theme = useTheme();

  const isDrawerActive = editorDrawerOpen === Drawer.VideoWizard;

  return (
    <FlexDrawer right={right} active={isDrawerActive} width={width}>
      <VideoWizardSymbol
        width="40px"
        height="40px"
        justify="center"
        align="center"
        margin="0 0 18px 0"
      >
        <H1_Icon color={theme.gray1} icon="fas fa-sparkles" />
      </VideoWizardSymbol>
      <H1_TextSubtitle margin="0 0 13px 0" color={theme.gray10}>
        {intl.formatMessage(messages.title)}
      </H1_TextSubtitle>
      <H1_TextSmall margin="0 30px 28px 0" whiteSpace="normal" color={theme.gray8}>
        {intl.formatMessage(messages.subTitle)}
      </H1_TextSmall>
      <AiGenerate />
    </FlexDrawer>
  );
};

export default VideoWizardDrawer;
