import React from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Orientation, PlanEnum, Recipe, segmentTitle } from "app/types";
import SingleTemplateItem from "app/pages/Templates/SingleTemplateItem";
import { useAppDispatch, useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import { templatesPageMessages } from "app/pages/Templates/messages";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import ConditionalRender from "app/components/common/ConditionalRender";

const BorderFlexColumn = styled(H1_FlexColumn)`
  border: 3px dashed ${({ theme }) => theme.gray5};
  border-radius: 4px;
  &:hover {
    border-color: ${({ theme }) => theme.blue4};
    i,
    span {
      color: ${({ theme }) => theme.blue4};
    }
  }
`;

const PlusIcon = styled.i`
  color: ${({ theme }) => theme.gray7};
  position: absolute;
  top: -2px;
  left: 7px;
  font-size: 20px;
`;

const TemplateIcon = styled.i`
  color: ${({ theme }) => theme.gray7};
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 20px;
`;

const CustomTemplateText = styled(H1_TextXs)`
  width: 120px;
`;

interface FullTemplatesPageGridCollectionProps {
  orientation: Orientation;
  sectionName: string;
  showCustomTemplate?: boolean;
  showCategoryName?: boolean;
}

const FullTemplatesPageGridCollection = ({
  orientation,
  sectionName,
  showCustomTemplate = false,
  showCategoryName = true
}: FullTemplatesPageGridCollectionProps) => {
  const dispatch = useAppDispatch();
  const showPaymentModal = useUpgradeModal();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const filteredTemplates = useAppSelector((state) =>
    recipesSelectors.getFilteredFlattenRecipesByOrientationAndId(state, orientation, undefined)
  );
  const templates = useAppSelector((state) =>
    recipesSelectors.getFilteredFlattenRecipesBySectionName(state, filteredTemplates, sectionName)
  );

  const onClickCustomTemplate = () => {
    const upgradeText = formatMessage(templatesPageMessages.createCustomTemplate);
    const upgradeSubText = formatMessage(templatesPageMessages.createCustomTemplateSubtitle);
    dispatch(analyticsEvents.requestTemplate());
    showPaymentModal({
      upgradeText,
      upgradeSubText,
      source: "upgrade_custom_template",
      requestedPlans: [PlanEnum.enterprise]
    });

    return null;
  };

  if (templates.length === 0) {
    return <></>;
  }

  return (
    <H1_FlexRow
      key={sectionName}
      width="100%"
      position="relative"
      padding="0 30px 42px 50px"
      flex="0 0 auto"
    >
      <H1_FlexColumn gap="10px" flex="1 1 100%" width="100%" padding="17px 0 0 0">
        <ConditionalRender condition={showCategoryName}>
          <H1_FlexRow align="flex-end">
            <H1_TextMiddle color={theme.gray8}>{segmentTitle(sectionName)}</H1_TextMiddle>
          </H1_FlexRow>
        </ConditionalRender>
        <H1_FlexRow position="relative" flex="0 0 auto" gap="28px" wrap="wrap">
          {showCustomTemplate && (
            <BorderFlexColumn
              onClick={onClickCustomTemplate}
              height="202px"
              width="calc(160px * 16 / 9)"
              flex="0 0 auto"
              align="center"
              justify="center"
            >
              <H1_FlexRow position="relative" height="34px" width="58px">
                <PlusIcon className="far fa-plus" />
                <TemplateIcon className="far fa-table-layout" />
              </H1_FlexRow>
              <CustomTemplateText color={theme.gray7} whiteSpace="normal" textAlign="center">
                {formatMessage(templatesPageMessages.createCustomTemplate, { br: <br /> })}
              </CustomTemplateText>
            </BorderFlexColumn>
          )}
          {templates.map((recipe: Recipe) => (
            <SingleTemplateItem recipe={recipe} key={recipe.id} />
          ))}
        </H1_FlexRow>
      </H1_FlexColumn>
    </H1_FlexRow>
  );
};

export default FullTemplatesPageGridCollection;
