import React, { ReactNode, useEffect } from "react";
import { MimeType } from "app/types";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import FileUploader from "app/components/common/FileUploader";
import styled from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ReactComponent as HouroneLogo } from "./hourone-logo.svg";

const FILE_TYPES = [MimeType.allowedImages];

interface ButtonProps {
  background: any;
  icon: ReactNode;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const ButtonContainer = styled.button<{ disabled?: boolean }>`
  position: relative;
  width: 117px;
  height: 117px;
  border: none;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.7)};
  }
`;

const BackgroundImage = styled.img`
  //position: absolute;
  width: 117px;
  height: 117px;
  border-radius: 50%;
  object-fit: cover;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
`;

const IconButton = ({
  background,
  icon,
  onClick,
  loading = false,
  disabled = false
}: ButtonProps) => {
  const [showIcon, setShowIcon] = React.useState(false);
  const handleClick = () => {
    if (!loading && !disabled && onClick) {
      onClick();
    }
  };
  const onEnter = () => {
    setShowIcon(true);
  };
  const onMouseLeave = () => {
    setShowIcon(false);
  };

  return (
    <>
      <ButtonContainer
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={onEnter}
        onMouseLeave={onMouseLeave}
      >
        <ConditionalRender condition={!!background}>
          <BackgroundImage src={background} alt="Background Image" />
        </ConditionalRender>
        <ConditionalRender condition={!background}>
          <HouroneLogo />
        </ConditionalRender>
        <ConditionalRender condition={showIcon}>
          <IconWrapper>{icon}</IconWrapper>
        </ConditionalRender>
      </ButtonContainer>
    </>
  );
};

interface UploadLogoButtonProps {
  background: any;
  disabled: boolean;
}
const UploadLogoButton = ({ background, disabled }: UploadLogoButtonProps) => {
  const dispatch = useAppDispatch();
  const { reset, onSelectFile, importLoading, fileStoreResult } = useFileUploadFilestack({
    autoUpload: true
  });
  const workflowId = useAppSelector((state) => state.drafts.currentDraft.workflow_id);

  const onEditLogo = (url: string) => {
    dispatch(
      draftsActions.updateWorkflowFormRequest({
        workflowId: workflowId as string,
        form: { image: url }
      })
    );
  };
  useEffect(() => {
    if (fileStoreResult) {
      onEditLogo(fileStoreResult.url);
    }
  }, [fileStoreResult]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <FileUploader
      fileTypes={FILE_TYPES.join()}
      multiple
      onFilesSelected={(files) => onSelectFile(files[0])}
    >
      <IconButton
        background={background}
        loading={importLoading}
        icon={<i className="fa-solid fa-file-arrow-up"></i>}
        disabled={disabled}
      ></IconButton>
    </FileUploader>
  );
};

export default UploadLogoButton;
