import "app/components/editor/bottomDrawers/GroupDrawers.scss";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { DrawerProps } from "app/components/editor/sideDrawers/CommonDrawerTypes";
import StyleDrawerInner from "app/components/editor/sideDrawers/StyleDrawer/StyleDrawerInner";
import { useFlags } from "launchdarkly-react-client-sdk";
import ConditionalRender from "app/components/common/ConditionalRender";
import { FeatureFlag } from "app/types";
import StyleDrawerV2 from "app/components/editor/sideDrawers/StyleDrawer/StyleDrawerInnerV2";

const StyleDrawer = ({ right, width }: DrawerProps) => {
  const { editorDrawerOpen } = useDrawer();
  const flags = useFlags();
  const isDrawerActive = editorDrawerOpen === Drawer.Colors;

  return (
    <FlexDrawer padding="20px 0 0 30px" right={right} active={isDrawerActive} width={width}>
      <ConditionalRender condition={!flags[FeatureFlag.fonts]}>
        <StyleDrawerInner isDrawerActive={isDrawerActive} drawerName="styleDrawer" />
      </ConditionalRender>
      <ConditionalRender condition={flags[FeatureFlag.fonts]}>
        <StyleDrawerV2 isDrawerActive={isDrawerActive} />
      </ConditionalRender>
    </FlexDrawer>
  );
};

export default StyleDrawer;
