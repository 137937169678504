import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store/store";
import { imageFitDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";

import { useIntl } from "react-intl";
import { scenesActions } from "app/store/slices/scenes.slice";
import styled, { useTheme } from "styled-components";
import { FilterType } from "app/types/media";
import blackAndWhiteImage from "app/assets/images/filter-b-w.png";
import sepiaImage from "app/assets/images/sepia-image-fit.png";
import noFilterImage from "app/assets/images/no-filter.png";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const IMAGE_WIDTH = "100px";

const BorderedImageFlexRow = styled(H1_FlexRow)<{ isHighlighted: boolean }>`
  border: 1px solid transparent;
  border-color: ${({ isHighlighted, theme }) => (isHighlighted ? theme.blue2 : "transparent")};
  transition: transform 0.3s ease-in-out;
  i {
    color: ${({ isHighlighted, theme }) => (isHighlighted ? theme.blue4 : theme.blue2)};
  }
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.9);
  }
  border-width: 2px;
  box-sizing: content-box;
  img {
    width: ${IMAGE_WIDTH};
  }
`;

const PaddingText = styled(H1_TextXs)`
  padding-left: 3px;
`;

const ImageFitFilterType = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const imageFit = useAppSelector((state: RootState) => state.scenes.imageFit);
  const { filterType } = imageFit;
  const isFilterMonochrome = filterType === FilterType.monochrome;
  const isFilterSepia = filterType === FilterType.sepia;
  const isNoFilter = filterType === undefined;

  const onSelectFilterType = (type: FilterType | undefined) => {
    dispatch(scenesActions.updateImageFit({ filterType: type }));
    analyticsEvents.selectImageFitFilterType({ filterType: type });
  };

  return (
    <H1_FlexColumn gap="8px" padding="10px 0 20px 0">
      <PaddingText color={theme.gray7}>
        {intl.formatMessage(imageFitDrawerMessages.filters)}
      </PaddingText>
      <H1_FlexRow gap="9px" justify="center">
        <H1_FlexColumn gap="5px" width={IMAGE_WIDTH} align="center">
          <BorderedImageFlexRow
            width={IMAGE_WIDTH}
            flex={`0 0 ${IMAGE_WIDTH}`}
            justify="center"
            align="center"
            onClick={() => onSelectFilterType(undefined)}
            isHighlighted={isNoFilter}
          >
            <img src={noFilterImage} alt="No Filter" />
          </BorderedImageFlexRow>
          <H1_TextXs textAlign="center" color={isNoFilter ? theme.blue4 : theme.gray7}>
            {intl.formatMessage(imageFitDrawerMessages.none)}
          </H1_TextXs>
        </H1_FlexColumn>
        <H1_FlexColumn gap="5px" width={IMAGE_WIDTH} align="center">
          <BorderedImageFlexRow
            width={IMAGE_WIDTH}
            flex={`0 0 ${IMAGE_WIDTH}`}
            justify="center"
            align="center"
            onClick={() => onSelectFilterType(FilterType.monochrome)}
            isHighlighted={isFilterMonochrome}
          >
            <img src={blackAndWhiteImage} alt="B&W" />
          </BorderedImageFlexRow>
          <H1_TextXs textAlign="center" color={isFilterMonochrome ? theme.blue4 : theme.gray7}>
            {intl.formatMessage(imageFitDrawerMessages.filtersBW)}
          </H1_TextXs>
        </H1_FlexColumn>
        <H1_FlexColumn gap="5px" width={IMAGE_WIDTH} align="center">
          <BorderedImageFlexRow
            width={IMAGE_WIDTH}
            flex={`0 0 ${IMAGE_WIDTH}`}
            justify="center"
            align="center"
            onClick={() => onSelectFilterType(FilterType.sepia)}
            isHighlighted={isFilterSepia}
          >
            <img src={sepiaImage} alt="Sepia" />
          </BorderedImageFlexRow>
          <H1_TextXs textAlign="center" color={isFilterSepia ? theme.blue4 : theme.gray7}>
            {intl.formatMessage(imageFitDrawerMessages.filtersSepia)}
          </H1_TextXs>
        </H1_FlexColumn>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default ImageFitFilterType;
