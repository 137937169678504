import styled, { css, useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Draft, FeatureFlag } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { Switch } from "antd";
import { ThemeMode } from "app/utils/theme";
import { Button, Chip, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { charactersActions } from "app/store/slices/characters.slice";
import { CharacterFilters } from "app/types/character";
import { RootState } from "app/store/store";
import { capitalize } from "lodash-es";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";

const BackgroundFlexRow = styled(H1_FlexRow)<{ $isTransparent: boolean }>`
  background-color: ${({ theme, $isTransparent }) =>
    $isTransparent ? "transparent" : theme.gray4};
  border-radius: 4px;
`;

const BlueSwitch = styled(Switch)`
  &.ant-switch {
    background-color: ${({ theme, checked }) =>
      checked ? theme.blue4 : theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray4};
  }
`;

const DarkButton = styled(Button)`
  background-color: ${({ theme }) => theme.gray3};
`;

const PopoverContentContainer = styled(H1_FlexColumn)`
  border-radius: 8px;
  max-height: 371px;
`;

const GrayButton = styled(Button)`
  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6)};
`;

const StyledChip = styled(Chip)<{ $selected: boolean }>`
  background-color: ${({ theme }) => theme.gray3};
  color: ${({ theme }) => theme.gray7};
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(10px);
    background-color: ${({ theme }) => theme.gray5};
    color: ${({ theme }) => theme.gray11};
  }
  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) => theme.gray11};
      color: ${({ theme }) => theme.gray2};
      &:hover {
        background-color: ${({ theme }) => theme.gray11}cc;
        color: ${({ theme }) => theme.gray2};
      }
    `}
`;

const TopSceneAttributes = ({
  isTransparent = false,
  element,
  shouldAddRightPadding = false,
  padding,
  withFilters = false,
  query,
  characterAssetKey
}: {
  isTransparent?: boolean;
  element: string;
  shouldAddRightPadding?: boolean;
  padding: string;
  withFilters?: boolean;
  query?: string;
  characterAssetKey?: string;
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { scene } = useSelectedScene();
  const draftGlobalCharacterStatus = useAppSelector(
    (state) => state.drafts.draftGlobalCharacterStatus
  );
  const filters: Partial<CharacterFilters> = useAppSelector(
    (state: RootState) => state.characters.filters
  );
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft) as Draft;
  const isTemplateCinematic = useAppSelector(templatesSelectors.isTemplateCinematic);
  const isApplyAllScenes = currentDraft?.global_character;
  const layoutCharacter = scene?.layout?.assets?.character;

  const allGesturesUniqueOptions = useAppSelector((state) =>
    CharacterSelectors.getUniqueGesturesValues(state, characterAssetKey || "", query || "", false)
  );
  const isDisplayed =
    (!!withFilters && allGesturesUniqueOptions.length > 0) || !isTemplateCinematic;
  const selectedGestures: string[] = filters.gestures || [];

  useEffect(() => {
    if (draftGlobalCharacterStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateDraftGlobalCharacterStatusToIdle());
    }
    if (draftGlobalCharacterStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateDraftGlobalCharacterStatusToIdle());
    }
  }, [draftGlobalCharacterStatus]);

  const onChangeApplySettings = (checked: boolean) => {
    dispatch(
      analyticsEvents.changeMultipleCharacterSettings({
        clickAllScenes: !checked,
        draftId: currentDraft.id as string
      })
    );
    dispatch(
      draftsActions.updateDraftGlobalCharacterRequest({
        globalCharacter: !checked
      })
    );
  };

  const onClickClear = () => {
    dispatch(
      charactersActions.setFilters({
        ...filters,
        gestures: []
      })
    );
  };

  const onClickChip = (gesture: string) => {
    const newGesturesFilters = filters.gestures?.includes(gesture)
      ? filters.gestures?.filter((removed) => removed !== gesture)
      : [...(filters.gestures || []), gesture];
    dispatch(
      charactersActions.setFilters({
        ...filters,
        gestures: newGesturesFilters
      })
    );
  };

  return (
    <ConditionalRender condition={flags[FeatureFlag.multiplePresenters] && !!layoutCharacter}>
      <BackgroundFlexRow
        height={isDisplayed ? "50px" : "0"}
        width={shouldAddRightPadding ? "calc(100% - 22px)" : "100%"}
        padding={isDisplayed ? padding : "0"}
        justify="space-between"
        align="center"
        flex={isDisplayed && shouldAddRightPadding ? "0 0 50px" : "0 1 auto"}
        margin={isDisplayed && shouldAddRightPadding ? "0 0 15px 0" : "0"}
        $isTransparent={isTransparent}
      >
        <ConditionalRender condition={!isTemplateCinematic}>
          <H1_TextXs>
            {intl.formatMessage(characterDrawerMessages.applyOneScene, { element })}
          </H1_TextXs>
          <BlueSwitch size="small" checked={!isApplyAllScenes} onChange={onChangeApplySettings} />
        </ConditionalRender>
        <ConditionalRender condition={!!withFilters && allGesturesUniqueOptions.length > 0}>
          <Popover placement="top-end">
            <PopoverTrigger>
              <DarkButton
                variant="flat"
                startContent={
                  <H1_Icon
                    color={
                      !!withFilters && selectedGestures.length > 0
                        ? theme.mode === ThemeMode.Light
                          ? theme.blue4
                          : theme.blue3
                        : theme.gray11
                    }
                    icon="far fa-sliders"
                  />
                }
              >
                <H1_TextSmall>{intl.formatMessage(characterDrawerMessages.filters)}</H1_TextSmall>
              </DarkButton>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverContentContainer gap="10px" padding="17px 19px" width="371px" overflow="auto">
                <H1_FlexRow justify="space-between" align="center">
                  <H1_TextSmall color={theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6}>
                    {intl.formatMessage(characterDrawerMessages.filters)}
                  </H1_TextSmall>
                  <GrayButton
                    isDisabled={selectedGestures.length === 0}
                    variant="light"
                    onClick={onClickClear}
                  >
                    {intl.formatMessage(characterDrawerMessages.clear)}
                  </GrayButton>
                </H1_FlexRow>
                <H1_FlexRow gap="7px" wrap="wrap">
                  {allGesturesUniqueOptions.map((gesture: string, index) => (
                    <StyledChip
                      $selected={selectedGestures.includes(gesture)}
                      onClick={() => onClickChip(gesture)}
                      key={index}
                    >
                      {capitalize(gesture)}
                    </StyledChip>
                  ))}
                </H1_FlexRow>
              </PopoverContentContainer>
            </PopoverContent>
          </Popover>
        </ConditionalRender>
      </BackgroundFlexRow>
    </ConditionalRender>
  );
};

export default TopSceneAttributes;
