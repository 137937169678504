import { useAppDispatch, useAppSelector } from "app/hooks";
import { VideoRatingReaction, ReactionMapping, Reactor, Video } from "app/types";
import styled from "styled-components";
import { List, Popover, Tooltip } from "antd";
import { videosActions } from "app/store/slices/videos.slice";
import videoSelectors from "app/store/selectorsV2/videos.selectors";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { emojisMappedData } from "app/components/common/Reactions/reactionsConsts";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useSingleVideoContext } from "app/components/common/Reactions/ChannelsPageSingleVideoContext";
import { useIntl } from "react-intl";
import { reactionsMessages as messages } from "app/components/common/Reactions/messages";
import { useAuth } from "app/auth/useAuth";
import { useParams } from "react-router-dom";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { v4 as uuid4 } from "uuid";

const Capitlized = styled(H1_TextSmall)`
  text-transform: capitalize;
`;

const EmojiContainer = styled(H1_FlexRow)<{ $isActive: boolean }>`
  transform-origin: 40% 40%;
  background-color: ${(props) =>
    props.$isActive ? "var(--blue-04, #5a5aff)" : "var(--gray-02, #fafafa)"};
  * {
    color: ${(props) => (props.$isActive ? "var(--gray-01, #fff)" : "inherit")};
  }
  padding: 2px 8px;
  cursor: pointer;
  i {
    font-size: 15px;
    line-height: 20px;
  }
  &:hover {
    i:not(.no-amin),
    span {
      color: var(--blue-04, #5a5aff);
    }
    background-color: ${(props) =>
      props.$isActive ? "var(--blue-04, #5a5aff)" : "var(--gray-01, #fff)"};
    span:not(.no-amin) {
      color: ${(props) => (props.$isActive ? "var(--gray-01, #fff)" : "inherit")};
      transform: ${(props) => (props.$isActive ? "scale(0.7)" : "scale(1.2) translateY(4px)")};
    }
    span.emoji:not(.no-amin) {
      transform: ${(props) => (props.$isActive ? "scale(0.7)" : "scale(1.2) translateY(-2px)")};
    }
  }
`;

const LinedEmojiContainer = styled(H1_FlexRow)<{ $isActive: boolean }>`
  cursor: pointer;
  transform-origin: 40% 40%;
  text-shadow: ${(props) => (props.$isActive ? "var(--blue-04, #5a5aff) 1px 1px 8px" : "none")};
  i {
    font-size: 15px;
    line-height: 20px;
  }
  &:hover {
    span.emoji {
      transform: ${(props) => (props.$isActive ? "scale(0.7)" : "scale(1.2) translateY(-2px)")};
    }
  }
`;

const ReactedNum = styled(H1_TextSmall)`
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

const EmojiIcon = styled(H1_TextSmall)`
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

const UnpaddedList = styled(List)`
  padding: 0;
  li {
    padding: 0;
    color: var(--gray-08, "#595959");
  }
`;

const ReactedEmojiContainer = ({ emojiData }: { emojiData: ReactionMapping }) => {
  const dispatch = useAppDispatch();
  const { video } = useSingleVideoContext();
  const { user } = useAuth();
  const { channelName } = useParams<{ channelName: string }>();
  const intl = useIntl();
  const getVideoReactionsCountInner = (reaction: string) =>
    useAppSelector((state) =>
      videoSelectors.getVideoReactionsCount(state, video.id as string, reaction)
    ) as number;
  const emojiReactionsCount = getVideoReactionsCountInner(emojiData.emojiSign);

  const onClickEmoji = (reaction: VideoRatingReaction, reacted = false) => {
    dispatch(
      videosActions.upsertVideoRatingRequest({
        videoId: video.id,
        reaction,
        user: {
          nickname: user?.nickname,
          // eslint-disable-next-line camelcase
          is_current_user: true,
          // eslint-disable-next-line camelcase
          distinct_id: uuid4().toString(),
          picture: user?.picture as string
        },
        activate: !reacted,
        channelName: channelName as string
      })
    );
    dispatch(
      analyticsEvents.reactAVideo({
        reaction,
        videoId: video.id,
        activate: !reacted
      })
    );
  };

  const reaction = video.reactions?.find((aReaction) => aReaction.reaction === emojiData.emojiSign);
  if (!reaction || reaction?.count === 0) {
    return null;
  }

  return (
    <>
      <ConditionalRender condition={(reaction?.reacted_users?.length || 0) > 0}>
        <Popover
          destroyTooltipOnHide
          overlayClassName="video-reactors-popover"
          showArrow={false}
          getPopupContainer={() =>
            document.querySelector(`.video-container-${video.id}`) as HTMLElement
          }
          content={null}
          title={
            <UnpaddedList
              size="small"
              dataSource={reaction.reacted_users as Reactor[]}
              renderItem={(item) => (
                <List.Item key={(item as Reactor).distinct_id}>
                  <Capitlized fontSize="13px" lineHeight="18px">
                    {(item as Reactor).nickname}
                  </Capitlized>
                </List.Item>
              )}
            />
          }
          trigger="hover"
          placement="bottom"
        >
          <EmojiContainer
            padding="6px 0 9px;"
            $isActive={Boolean(reaction?.active)}
            justify="center"
            align="center"
            gap="5px"
            onClick={() => onClickEmoji(emojiData.emojiSign, reaction?.active)}
          >
            <EmojiIcon fontSize="13px" className="emoji">
              {emojiData.emojiSign}
            </EmojiIcon>
            <ReactedNum fontSize="13px" textAlign="center" lineHeight="0">
              {emojiReactionsCount}
            </ReactedNum>
          </EmojiContainer>
        </Popover>
      </ConditionalRender>
      <ConditionalRender condition={!reaction || (reaction?.reacted_users?.length || 0) === 0}>
        <Tooltip title={intl.formatMessage(emojiData.emojiTooltip)} placement="bottom">
          <EmojiContainer
            padding="6px 0 9px;"
            $isActive={Boolean(reaction?.active)}
            justify="center"
            align="center"
            gap="5px"
          >
            <EmojiIcon
              fontSize="13px"
              className="emoji"
              onClick={() => onClickEmoji(emojiData.emojiSign, reaction?.active)}
            >
              {emojiData.emojiSign}
            </EmojiIcon>
            <ReactedNum fontSize="13px" textAlign="center" lineHeight="0">
              {emojiReactionsCount}
            </ReactedNum>
          </EmojiContainer>
        </Tooltip>
      </ConditionalRender>
    </>
  );
};

const ReactionLineContainer = ({ video }: { video: Video }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { user } = useAuth();
  const { channelName } = useParams<{ channelName: string }>();

  const onClickEmoji = (reaction: VideoRatingReaction, reacted = false) => {
    dispatch(
      videosActions.upsertVideoRatingRequest({
        videoId: video.id,
        reaction,
        user: {
          nickname: user?.nickname,
          // eslint-disable-next-line camelcase
          distinct_id: uuid4().toString(),
          // eslint-disable-next-line camelcase
          is_current_user: true,
          picture: user?.picture as string
        },
        activate: !reacted,
        channelName: channelName as string
      })
    );
    dispatch(
      analyticsEvents.reactAVideo({
        reaction,
        videoId: video.id,
        activate: !reacted
      })
    );
  };

  return (
    <H1_FlexRow justify="space-between" align="flex-start">
      {emojisMappedData.map((emojiData) => (
        <Tooltip
          placement="top"
          title={intl.formatMessage(emojiData.emojiTooltip)}
          key={emojiData.emojiText}
        >
          <LinedEmojiContainer
            $isActive={Boolean(
              video.reactions?.find((reaction) => reaction.reaction === emojiData.emojiSign)?.active
            )}
          >
            <EmojiIcon
              fontSize="20px"
              className="emoji"
              onClick={() =>
                onClickEmoji(
                  emojiData.emojiSign,
                  video.reactions?.find((reaction) => reaction.reaction === emojiData.emojiSign)
                    ?.active
                )
              }
            >
              {emojiData.emojiSign}
            </EmojiIcon>
          </LinedEmojiContainer>
        </Tooltip>
      ))}
    </H1_FlexRow>
  );
};

const AddReactionIcon = styled("i")`
  &:hover {
    color: var(--blue-04, #5a5aff);
  }
`;

const VideoReactions = () => {
  const { video } = useSingleVideoContext();
  const intl = useIntl();
  const getAllVideoReactionsCount = () =>
    useAppSelector((state) =>
      videoSelectors.getAllReactionsCount(state, video.id as string)
    ) as number;
  const allReactionsCount = getAllVideoReactionsCount();
  return (
    <H1_FlexRow
      gap="10px"
      justify="flex-start"
      align="flex-start"
      key={`video_${video.id}_reactions`}
    >
      {emojisMappedData.map((emojiData) => (
        <ReactedEmojiContainer
          emojiData={emojiData}
          key={`video_${video.id}_reaction_${emojiData.emojiSign}}`}
        />
      ))}
      <Popover
        destroyTooltipOnHide
        placement="topRight"
        title={<ReactionLineContainer video={video} />}
        overlayClassName="emojis-popover"
        showArrow={false}
        getPopupContainer={() =>
          document.querySelector(`.video-tagline-${video.id}`) as HTMLElement
        }
        align={{
          offset: [0, 7]
        }}
      >
        <EmojiContainer
          $isActive={false}
          padding="6px 0 9px;"
          justify="center"
          align="center"
          gap="5px"
        >
          <ConditionalRender condition={allReactionsCount > 0}>
            <H1_TextSmall fontSize="13px" textAlign="center" lineHeight="0" className="no-amin">
              {intl.formatMessage(messages.allReactionsLabel, { count: allReactionsCount })}
            </H1_TextSmall>
          </ConditionalRender>
          <AddReactionIcon className="fa-regular fa-face-smile-plus" />
        </EmojiContainer>
      </Popover>
    </H1_FlexRow>
  );
};

export default VideoReactions;
