import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "app/pages/login/Login.scss";
import { ReactComponent as HourOneNewLogo } from "app/assets/images/h1loginlogo.svg";
import { useIntl } from "react-intl";
import { loginMessages } from "app/pages/login/messages";
import { isMobile } from "react-device-detect";
import { userActions } from "app/store/slices/user.slice";
import { useAppDispatch } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useSearchParams } from "react-router-dom";
import ConditionalRender from "app/components/common/ConditionalRender";
import CustomLogin from "app/pages/login/CustomLogin";

const LoginView = styled.div`
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px;
  min-height: 50%;
  flex: 1;
`;

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: fit-content;
`;

const SubTitle = styled.div`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
`;

const CardWrapper = styled.div`
  position: relative;
  height: inherit;
  min-height: inherit;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
`;

const Card = styled.div`
  /* overflow: hidden; */
  background-color: #fff;
  display: flex;
  width: 760px;
  height: auto;
  flex-direction: row;
  @include cubic;
`;

const MobileCard = styled(Card)`
  height: 100vh;
`;

// todo also on mobile

/*
 * validations for input
 *unified sso with login
 * check if need api for detect provider
 * check if permissions needed in system
 * if workos token - > user it for logged in.
 * fallback to auth0
 * email verification - what provider to take or use both.
 * need to test verification flow
 * workos auth provider need to handle email verified.
 *  need reset password
 * */
const Login = () => {
  const [mfaFactorScreen, setMfaFactorScreen] = useState<boolean>(false);
  const [initSignup, setInitSignup] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [params] = useSearchParams();

  useEffect(() => {
    const init = params.get("init");
    if (init === "signUp") {
      setInitSignup(true);
    } else {
      setInitSignup(false);
    }
  }, []);

  useEffect(() => {
    dispatch(userActions.setSourceUrl(window.location.toString()));
  }, []);

  // todo apply the change on mobile
  if (isMobile) {
    return (
      <LoginView className="full-height">
        <CardWrapper>
          <MobileCard>
            <FormContainer>
              <ConditionalRender condition={!mfaFactorScreen}>
                <Header>
                  <H1_FlexRow padding="30px 15px 26px 0" align="center">
                    <HourOneNewLogo height={59} width="auto" />
                  </H1_FlexRow>
                  <ConditionalRender condition={!initSignup}>
                    <SubTitle>{formatMessage(loginMessages.welcomeToRealsDesktop)}</SubTitle>
                  </ConditionalRender>
                  <ConditionalRender condition={initSignup}>
                    <SubTitle>{formatMessage(loginMessages.createYourAccount)}</SubTitle>
                  </ConditionalRender>
                </Header>
              </ConditionalRender>
              <CustomLogin
                activeSignUp={initSignup}
                mfaFactorScreen={mfaFactorScreen}
                onSetMfaFactorScreen={setMfaFactorScreen}
              />
            </FormContainer>
          </MobileCard>
        </CardWrapper>
      </LoginView>
    );
  }

  return (
    <LoginView className="red-top-bar full-height">
      <CardWrapper>
        <Card>
          <FormContainer>
            <ConditionalRender condition={!mfaFactorScreen}>
              <H1_FlexColumn align="center">
                <H1_FlexRow width="203px" gap="15px" padding="0 15px 26px 0" align="center">
                  <HourOneNewLogo height={59} width="auto" />
                </H1_FlexRow>
                <ConditionalRender condition={!initSignup}>
                  <SubTitle>{formatMessage(loginMessages.welcomeToRealsDesktop)}</SubTitle>
                </ConditionalRender>
                <ConditionalRender condition={initSignup}>
                  <SubTitle>{formatMessage(loginMessages.createYourAccount)}</SubTitle>
                </ConditionalRender>
              </H1_FlexColumn>
            </ConditionalRender>
            <CustomLogin
              activeSignUp={initSignup}
              mfaFactorScreen={mfaFactorScreen}
              onSetMfaFactorScreen={setMfaFactorScreen}
            />
          </FormContainer>
        </Card>
      </CardWrapper>
    </LoginView>
  );
};

export default Login;
