import React, { useEffect, useState } from "react";

import "app/pages/HomePage/HomePage.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { MemberStatus } from "app/types";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import JoinTeamModal from "app/pages/Teams/JoinTeamModal";
import WelcomeTeamModal from "app/pages/Teams/WelcomeTeamModal";

const HomePageInvitations = () => {
  const [joinTeamModalVisible, setJoinTeamModalVisible] = useState<boolean>(false);
  const [welcomeTeamModalVisible, setWelcomeTeamModalVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const getInvitesStatus = useAppSelector((state) => state.workspaces.getInvitesStatus);
  const getUserInvitesStatus = useAppSelector((state) => state.workspaces.getUserInvitesStatus);
  const invitations = useAppSelector((state) => state.workspaces.invitations);

  useEffect(() => {
    dispatch(workspacesActions.getUserInvitesRequest());
  }, []);

  useEffect(() => {
    if (invitations.length) {
      const pendingInvitations = invitations.filter(
        (invitation) => invitation.status === MemberStatus.pending
      );
      if (pendingInvitations.length > 0) {
        setJoinTeamModalVisible(true);
      } else {
        const acceptedInvitations = invitations.filter(
          (invitation) => invitation.status === MemberStatus.accepted
        );
        if (acceptedInvitations.length > 0) {
          setWelcomeTeamModalVisible(true);
        }
      }
    }
  }, [invitations]);

  useEffect(() => {
    if (getInvitesStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.updateGetInvitesStatusToIdle());
    }
    if (getInvitesStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.updateGetInvitesStatusToIdle());
    }
  }, [getInvitesStatus]);

  useEffect(() => {
    if (getUserInvitesStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.updateGetUserInvitesStatusToIdle());
    }
    if (getUserInvitesStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.updateGetUserInvitesStatusToIdle());
    }
  }, [getUserInvitesStatus]);

  const onCloseJoinTeamModal = () => {
    setJoinTeamModalVisible(false);
    dispatch(workspacesActions.ackAcceptedInvitationsRequest());
    dispatch(workspacesActions.removeAllInvitations());
  };

  const onCloseWelcomeTeamModal = () => {
    setWelcomeTeamModalVisible(false);
    dispatch(workspacesActions.removeAllInvitations());
  };

  return (
    <>
      <JoinTeamModal visible={joinTeamModalVisible} onClose={onCloseJoinTeamModal} />
      <WelcomeTeamModal visible={welcomeTeamModalVisible} onClose={onCloseWelcomeTeamModal} />
    </>
  );
};

export default HomePageInvitations;
