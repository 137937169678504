import styled, { useTheme } from "styled-components";
import { useAppDispatch } from "app/hooks";
import { useIntl } from "react-intl";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import {
  H1_TextMiddle,
  H1_TextMidHeadline,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useState } from "react";
import { homePageMessages } from "app/pages/HomePage/messages";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import { PlanEnum } from "app/types";
import { isValidZoomMeetingUrl } from "app/utils/helpers";
import { draftsActions } from "app/store/slices/drafts.slice";
import { v4 as uuidv4 } from "uuid";
import { Button, Input } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const StyledCrownIcon = styled(CrownIcon)`
  margin-top: 2px;
  margin-right: 5px;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

const ZoomModal = ({
  visible,
  closeModal
}: {
  visible: boolean;
  closeModal: (e?: React.MouseEvent) => void;
}) => {
  const [url, setUrl] = useState<string>("");
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const showPaymentModal = useUpgradeModal();
  const { formatMessage } = useIntl();
  const showCrownModal = useShowCrown({
    requestedFeature: PlanFeature.ZoomBotMeeting
  });

  const isValidUrl = !url || isValidZoomMeetingUrl(url);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const onClickCreate = () => {
    if (
      !showPaymentModal({
        source: "upgrade_zoom_bot_meeting",
        upgradeText: formatMessage(homePageMessages.zoomModalUpgrade),
        requestedPlans: [PlanEnum.enterprise]
      })
    ) {
      dispatch(draftsActions.summaryRequest({ type: "zoom_meeting", url, orderId: uuidv4() }));
      dispatch(analyticsEvents.zoomModalClick());
      closeModal();
    }
  };

  return (
    <StyledModal
      width="755px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "255px" }}
      onCancel={closeModal}
      destroyOnClose
      maskClosable
      closeIcon={<CloseModalIcon />}
      $padding="40px 40px 25px"
      $justifyContent="space-between"
    >
      <H1_FlexColumn gap="15px">
        <H1_TextMidHeadline whiteSpace="normal" fontWeight={500}>
          {formatMessage(homePageMessages.zoomModalTitle)}
        </H1_TextMidHeadline>
        <H1_TextMiddle whiteSpace="normal" fontWeight={500} color={theme.gray7}>
          {formatMessage(homePageMessages.zoomModalSubtitle)}
        </H1_TextMiddle>
        <H1_FlexColumn gap="8px">
          <Input
            size="sm"
            labelPlacement="outside"
            placeholder={formatMessage(homePageMessages.zoomModalPlaceholder)}
            defaultValue={url}
            onChange={onInputChange}
            isClearable
          />
          <ConditionalRender condition={!isValidUrl}>
            <H1_TextXs fontSize="10px" color="red" lineHeight="12px">
              {formatMessage(homePageMessages.zoomModalNotValidUrl)}
            </H1_TextXs>
          </ConditionalRender>
        </H1_FlexColumn>
      </H1_FlexColumn>
      <H1_FlexRow align="center" justify="flex-end" position="relative">
        <StyledButton color="primary" onClick={onClickCreate} isDisabled={!url || !isValidUrl}>
          <ConditionalRender condition={showCrownModal}>
            <StyledCrownIcon />
          </ConditionalRender>
          {formatMessage(homePageMessages.zoomModalCta)}
        </StyledButton>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default ZoomModal;
