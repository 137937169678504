import { defineMessages } from "react-intl";

// export const onBoardingV2Messages = defineMessages({});

export const userTypeMessages = defineMessages({
  headLineTitle: {
    id: `on-boarding-v2.user-type.headLine-title`,
    defaultMessage: "What are you using Hour One for?"
  },
  workHeadLineTitle: {
    id: `on-boarding-v2.user-type.work-headLine-title`,
    defaultMessage: "Work"
  },
  workSubTitle: {
    id: `on-boarding-v2.user-type.work-sub-title`,
    defaultMessage: "Creating videos as part of your job"
  },
  personalHeadLineTitle: {
    id: `on-boarding-v2.user-type.personal-headLine-title`,
    defaultMessage: "Personal"
  },
  personalSubLineTitle: {
    id: `on-boarding-v2.user-type.personal-sub-title`,
    defaultMessage: "Create content as a hobby"
  },
  studentHeadLineTitle: {
    id: `on-boarding-v2.user-type.student-headLine-title`,
    defaultMessage: "Student"
  },
  studentSubLineTitle: {
    id: `on-boarding-v2.user-type.student-sub-title`,
    defaultMessage: "Creating video as a student or instructor"
  }
});

export const teamTypeMessages = defineMessages({
  headLineTitle: {
    id: `on-boarding-v2.team-type.headLine-title`,
    defaultMessage: "What kind of team are you on?"
  },
  marketingTitle: {
    id: `on-boarding-v2.team-type.marketing-title`,
    defaultMessage: "Marketing"
  },
  productManagementTitle: {
    id: `on-boarding-v2.team-type.product-management-title`,
    defaultMessage: "Media & Entertainment"
  },
  salesTitle: {
    id: `on-boarding-v2.team-type.sales-title`,
    defaultMessage: "Sales"
  },
  customerSupportTitle: {
    id: `on-boarding-v2.team-type.customer-support-title`,
    defaultMessage: "Customer Support"
  },
  humanResourcesTitle: {
    id: `on-boarding-v2.team-type.human-resources-title`,
    defaultMessage: "Human Resources"
  },
  userResearchTitle: {
    id: `on-boarding-v2.team-type.user-research-title`,
    defaultMessage: "User Research"
  },
  learningDevelopmentTitle: {
    id: `on-boarding-v2.team-type.learning-development-title`,
    defaultMessage: "Learning & Development"
  },
  otherTitle: {
    id: `on-boarding-v2.team-type.other-title`,
    defaultMessage: "Other"
  }
});
export const contentTypeMessages = defineMessages({
  headLineTitle: {
    id: `on-boarding-v2.content-type.headLine-title`,
    defaultMessage: "What kind of content are you planning to make?\n"
  },
  continueButton: {
    id: `on-boarding-v2.content-type.continue-button`,
    defaultMessage: "Continue"
  }
});

export const personalContentTypeMessages = defineMessages({
  socialMediaContentTitle: {
    id: `on-boarding-v2.personal-content.social-media-content-title`,
    defaultMessage: "Social media content"
  },
  videoPresentationsTitle: {
    id: `on-boarding-v2.personal-content.video-presentations-title`,
    defaultMessage: "Video presentations"
  },
  videoGreetingTitle: {
    id: `on-boarding-v2.personal-content.video-greeting-title`,
    defaultMessage: "Video greeting"
  },
  videoAdTitle: {
    id: `on-boarding-v2.personal-content.video-ad-title`,
    defaultMessage: "Video ad"
  },
  brandVideoTitle: {
    id: `on-boarding-v2.personal-content.Brand-video-title`,
    defaultMessage: "Brand video"
  },
  otherTitle: {
    id: `on-boarding-v2.personal-content.other`,
    defaultMessage: "Other"
  }
});

export const marketingContentMessages = defineMessages({
  videoAdsTitle: {
    id: `on-boarding-v2.marketing-content.video-ads-title`,
    defaultMessage: "Video ads"
  },
  socialMediaClipsTitle: {
    id: `on-boarding-v2.marketing-content.social-media-clips-title`,
    defaultMessage: "Social media clips"
  },
  productHowTosTitle: {
    id: `on-boarding-v2.marketing-content.Product-how-tos-title`,
    defaultMessage: "Product how-tos"
  },
  creatingWebinarsTitle: {
    id: `on-boarding-v2.marketing-content.creating-webinars-title`,
    defaultMessage: "Creating webinars"
  },
  videoPresentationsTitle: {
    id: `on-boarding-v2.marketing-content.video-presentations-title`,
    defaultMessage: "Video presentations"
  },
  teamCommunicationTitle: {
    id: `on-boarding-v2.marketing-content.team-communication-title`,
    defaultMessage: "Team communication"
  },
  brandedContentTitle: {
    id: `on-boarding-v2.marketing-content.branded-content-title`,
    defaultMessage: "Branded content"
  },
  otherTitle: {
    id: `on-boarding-v2.marketing-content.other-title`,
    defaultMessage: "Other"
  }
});

export const productManagementContentMessages = defineMessages({
  videoPresentationsTitle: {
    id: `on-boarding-v2.product-management-content.video-presentations-title`,
    defaultMessage: "Video presentations"
  },
  teamCommunicationTitle: {
    id: `on-boarding-v2.product-management-content.team-communication-title`,
    defaultMessage: "Team communication"
  },
  featureReleasesTitle: {
    id: `on-boarding-v2.product-management-content.feature-releases-title`,
    defaultMessage: "Feature releases"
  }
});

export const salesContentMessages = defineMessages({
  productTutorialsTitle: {
    id: `on-boarding-v2.sales-content.product-tutorials-title`,
    defaultMessage: "Product tutorials"
  },
  videoPresentationsTitle: {
    id: `on-boarding-v2.sales-content.video-presentations-title`,
    defaultMessage: "Video presentations"
  },
  supportTrainingTitle: {
    id: `on-boarding-v2.sales-content.support-training-title`,
    defaultMessage: "Support & training"
  },
  teamCommunicationTitle: {
    id: `on-boarding-v2.sales-content.team-communication-title`,
    defaultMessage: "Team communication"
  },
  prospectingPitchesTitle: {
    id: `on-boarding-v2.sales-content.prospecting-pitches-title`,
    defaultMessage: "Prospecting & pitches"
  },
  otherTitle: {
    id: `on-boarding-v2.sales-content.other-title`,
    defaultMessage: "Other"
  }
});
export const customerSupportContentMessages = defineMessages({
  helpDeskContentTitle: {
    id: `on-boarding-v2.customer-support-content.help-desk-title`,
    defaultMessage: "Help desk content"
  },
  supportTrainingTitle: {
    id: `on-boarding-v2.customer-support-content.support-training-title`,
    defaultMessage: "Support & training"
  },
  teamCommunicationTitle: {
    id: `on-boarding-v2.customer-support-content.team-communication-title`,
    defaultMessage: "Team communication"
  }
});

export const humanResourcesContentMessages = defineMessages({
  trainingComplianceContentTitle: {
    id: `on-boarding-v2.human-resources-content.training-compliance-title`,
    defaultMessage: "Training & compliance"
  },
  videoPresentationsTitle: {
    id: `on-boarding-v2.human-resources-content.video-presentations-title`,
    defaultMessage: "Video presentations"
  },
  recruitingTitle: {
    id: `on-boarding-v2.human-resources-content.recruiting-title`,
    defaultMessage: "Recruiting"
  },
  employeeDevelopmentTitle: {
    id: `on-boarding-v2.human-resources-content.employee-development-title`,
    defaultMessage: "Employee development"
  },
  otherTitle: {
    id: `on-boarding-v2.human-resources-content.other-title`,
    defaultMessage: "Other"
  }
});

export const learningDevelopmentContentMessages = defineMessages({
  productTutorialsContentTitle: {
    id: `on-boarding-v2.learning-development-content.product-tutorials-title`,
    defaultMessage: "Product tutorials"
  },
  creatingWebinarsTitle: {
    id: `on-boarding-v2.learning-development-content.creating-webinars-title`,
    defaultMessage: "Creating webinars"
  },
  trainingComplianceContentTitle: {
    id: `on-boarding-v2.learning-development-content.training-compliance-title`,
    defaultMessage: "Training & compliance"
  },
  videoPresentationsTitle: {
    id: `on-boarding-v2.learning-development-content.video-presentations-title`,
    defaultMessage: "Video presentations"
  },
  otherTitle: {
    id: `on-boarding-v2.learning-development-content.other-title`,
    defaultMessage: "Other"
  }
});

export const userResearchContentMessages = defineMessages({
  teamCommunicationTitle: {
    id: `on-boarding-v2.user-research-content.team-communication-title`,
    defaultMessage: "Team communication"
  },
  userResearchTitle: {
    id: `on-boarding-v2.user-research-content.user-research-title`,
    defaultMessage: "User research"
  },
  otherTitle: {
    id: `on-boarding-v2.user-research-content.other-title`,
    defaultMessage: "Other"
  }
});

export const setWorkspaceMessages = defineMessages({
  headLineTitle: {
    id: `on-boarding-v2.set-workspace.head-line-title`,
    defaultMessage: "Set up your workspace"
  },
  subTitle: {
    id: `on-boarding-v2.set-workspace.sub-title`,
    defaultMessage:
      "Join your team’s Workspace and gain instant access to team videos. Enter website for full brand customization"
  },
  workspaceName: {
    id: `on-boarding-v2.set-workspace.workspace-name`,
    defaultMessage: "Workspace name"
  },
  workspaceInitialValueName: {
    id: `on-boarding-v2.set-workspace.workspace-initial-value-name`,
    defaultMessage: "My Workspace"
  },
  companyWebsite: {
    id: `on-boarding-v2.set-workspace.company-website`,
    defaultMessage: "Company website"
  },
  companyHourOneWebsite: {
    id: `on-boarding-v2.set-workspace.company-hour-one-website`,
    defaultMessage: "www.your-site.com"
  },
  phoneNumber: {
    id: `on-boarding-v2.set-workspace.phone-number`,
    defaultMessage: "Phone number"
  },
  phoneNumberErrorMessage: {
    id: `on-boarding-v2.set-workspace.phone-number-errors-message`,
    defaultMessage: "Phone number is not valid"
  },
  yourPhone: {
    id: `on-boarding-v2.set-workspace.your-phone`,
    defaultMessage: "Your phone"
  },
  lowerTitle: {
    id: `on-boarding-v2.set-workspace.lower-title`,
    defaultMessage: "Join another workspace instead"
  },
  continueButton: {
    id: `on-boarding-v2.set-workspace.continue-button`,
    defaultMessage: "Continue"
  }
});

export const inviteTeamMembersMessages = defineMessages({
  headLineTitle: {
    id: `on-boarding-v2.invite-team-members.head-line-title`,
    defaultMessage: "Invite your team members"
  },
  subTitle: {
    id: `on-boarding-v2.invite-team-members.sub-title`,
    defaultMessage: "Invite your teammates to join your workspace and work in collaboration"
  },
  skipButton: {
    id: `on-boarding-v2.invite-team-members.skip`,
    defaultMessage: "Skip"
  },
  emailAddresses: {
    id: `on-boarding-v2.invite-team-members.email-addresses`,
    defaultMessage: "Email Addresses"
  },
  email: {
    id: `on-boarding-v2.invite-team-members.email`,
    defaultMessage: "Email"
  },
  addAnother: {
    id: `on-boarding-v2.invite-team-members.add-another`,
    defaultMessage: "Add Another"
  },
  inviteAndContinue: {
    id: `on-boarding-v2.invite-team-members.invite-and-continue`,
    defaultMessage: "Invite and continue"
  },
  notNowButton: {
    id: `on-boarding-v2.invite-team-members.not-now`,
    defaultMessage: "Not now"
  }
});
export const howDidYouHearAboutUsMessages = defineMessages({
  headLineTitle: {
    id: `on-boarding-v2.how-did-you-hear.head-line-title`,
    defaultMessage: "How did you discover Hour One?"
  },
  google: {
    id: `on-boarding-v2.how-did-you-hear.google`,
    defaultMessage: "Google"
  },
  instagram: {
    id: `on-boarding-v2.how-did-you-hear.instagram`,
    defaultMessage: "Instagram"
  },
  twitter: {
    id: `on-boarding-v2.how-did-you-hear.twitter`,
    defaultMessage: "Twitter"
  },
  linkedin: {
    id: `on-boarding-v2.how-did-you-hear.linkedin`,
    defaultMessage: "Linkedin"
  },
  facebook: {
    id: `on-boarding-v2.how-did-you-hear.facebook`,
    defaultMessage: "Facebook"
  },
  article: {
    id: `on-boarding-v2.how-did-you-hear.article`,
    defaultMessage: "Article"
  },
  youtube: {
    id: `on-boarding-v2.how-did-you-hear.youtube`,
    defaultMessage: "Youtube"
  },
  tikTok: {
    id: `on-boarding-v2.how-did-you-hear.tikTok`,
    defaultMessage: "TikTok"
  },
  other: {
    id: `on-boarding-v2.how-did-you-hear.other`,
    defaultMessage: "Other"
  },
  wordOfMouth: {
    id: `on-boarding-v2.how-did-you-hear.word-to-mouth`,
    defaultMessage: "Word of mouth"
  }
});

export const pricingMessages = defineMessages({
  headLineTitle: {
    id: `on-boarding-v2.pricing.head-line-title`,
    defaultMessage: "Choose your plan"
  },
  doneButton: {
    id: `on-boarding-v2.pricing.done-button`,
    defaultMessage: "Done"
  }
});
