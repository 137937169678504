import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import PreviewPanel from "app/components/editor/sideDrawers/PreviewPanel";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ExportContent from "app/components/editor/sideDrawers/ExportVideoDrawer/ExportContent";
import { videosActions } from "app/store/slices/videos.slice";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { fetchingStatus } from "app/utils/helpers";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import TitleFlexRow from "app/components/editor/sideDrawers/CommonComponents/TitleFlexRow";
import { exportVideoDrawerMessages } from "app/components/editor/sideDrawers/messages";
import { useIntl } from "react-intl";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import CircleLoader from "app/components/common/Loaders/CircleLoader";

const PanelDivider = styled(Divider)`
  border-color: ${(props) => props.theme.gray3};
`;
const BackButton = styled(Button)`
  color: ${(props) => props.theme.pink4};
  border: unset;
  padding-right: 0;
`;
const PaddingFlexColumn = styled(H1_FlexColumn)`
  padding-right: 20px;
`;
const PaddingTitleFlexRow = styled(TitleFlexRow)`
  padding-right: 20px;
`;

export interface ExportVideoProps {
  right: string;
  width: string;
  top?: string;
  borderLeft?: string;
  position?: string;
}

const ExportVideoDrawer = ({ right, width, top, borderLeft, position }: ExportVideoProps) => {
  const dispatch = useAppDispatch();
  const { editorDrawerOpen, originContext, openDrawer } = useDrawer();
  const { formatMessage } = useIntl();
  const { video, backDrawer, draftId } = originContext;

  const status = useAppSelector((state) => state.videos.videosStatus);

  const loading = status === fetchingStatus.loading;

  const isDrawerVisible = editorDrawerOpen === Drawer.Export;

  useEffect(() => {
    if (isDrawerVisible && draftId) {
      dispatch(
        videosActions.getVideosRequest({
          draftId,
          pagination: {
            limit: 30,
            page: 1
          }
        })
      );
    }
  }, [isDrawerVisible, draftId]);

  const onBack = () => {
    openDrawer(backDrawer, originContext);
  };
  return (
    <FlexDrawer
      right={right}
      active={isDrawerVisible}
      width={width}
      top={top}
      borderLeft={borderLeft}
      position={position}
    >
      <PaddingTitleFlexRow height="52px" justify="space-between">
        {formatMessage(exportVideoDrawerMessages.export)}
        {backDrawer && (
          <BackButton onClick={onBack}>
            <LeftOutlined /> {formatMessage(exportVideoDrawerMessages.exportBackButton)}
          </BackButton>
        )}
      </PaddingTitleFlexRow>
      <PaddingFlexColumn overflow="auto">
        <PreviewPanel selectedVideo={video} />
        <PanelDivider />
        {loading ? <CircleLoader /> : <ExportContent video={video} />}
      </PaddingFlexColumn>
    </FlexDrawer>
  );
};
export default ExportVideoDrawer;
