import React, { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";

import { foldersActions } from "app/store/slices/folders.slice";
import { fetchingStatus } from "app/utils/helpers";
import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import { deleteFolderModalMessages } from "app/pages/FoldersPage/messages";
import { foldersGlobalSelectors } from "app/store/adapters/adapters";
import { Folder } from "app/types";
import styled from "styled-components";
import { StyledModal } from "app/components/common/StyledModal";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";

const BoldMessage = styled("span")`
  font-weight: 300;
`;

const DeleteFolderModal = () => {
  const dispatch = useAppDispatch();
  const { editorModalOpen, closeModal } = useModal();
  const { formatMessage } = useIntl();

  const deletedStatus = useAppSelector((state) => state.folders.deleteFolderStatus);
  const { folderId } = editorModalOpen?.context || {};
  const folderData = useAppSelector((state) =>
    foldersGlobalSelectors.selectById(state, folderId as string)
  );

  useEffect(() => {
    if (deletedStatus === fetchingStatus.succeeded) {
      dispatch(foldersActions.setDeleteFolderStatusToIdle());
      closeModal();
    } else if (deletedStatus === fetchingStatus.failed) {
      dispatch(foldersActions.setDeleteFolderStatusToIdle());
    }
  }, [deletedStatus]);

  const handleDeleteRequest = () => {
    return dispatch(foldersActions.deleteFolderRequest(folderData as Folder));
  };

  const visible = editorModalOpen?.open === ModalName.deleteFolder;
  const isLoading = deletedStatus === fetchingStatus.loading;

  return (
    <StyledModal
      open={visible}
      destroyOnClose
      onCancel={closeModal}
      centered
      cancelText={
        <H1_TextSmall>{formatMessage(deleteFolderModalMessages.cancelButton)}</H1_TextSmall>
      }
      okText={
        isLoading ? (
          <i className="fas fa-spinner fa-spin" />
        ) : (
          formatMessage(deleteFolderModalMessages.deleteButton)
        )
      }
      width={400}
      onOk={handleDeleteRequest}
      bodyStyle={{ padding: "20px" }}
    >
      <H1_TextMiddle>{formatMessage(deleteFolderModalMessages.title)}</H1_TextMiddle>
      <H1_TextSmall>
        {
          // @ts-ignore handels intl formatting with elements issue
          formatMessage(deleteFolderModalMessages.content, {
            folderName: folderData?.name,
            b: (str) => <BoldMessage> {str}</BoldMessage>
          }) as ReactNode
        }
      </H1_TextSmall>
    </StyledModal>
  );
};

export default DeleteFolderModal;
