import { Button } from "antd";
import styled from "styled-components";
import { ButtonProps } from "antd/lib/button/button";

const StyledRoundButton = styled(Button)`
  && {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none !important;
    background-color: var(--gray-01, #fff);
    box-shadow: 0px 0px 7.5px -6px rgba(0, 0, 0, 0.25);
    transition: all ease-in-out 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    i,
    path {
      color: var(--gray-07, "#8c8c8c");
      fill: var(--gray-07, "#8c8c8c");
    }
    svg {
      overflow: visible;
    }

    &:hover,
    &:focus,
    &:focus-within {
      background-color: var(--gray-02, #fafafa);
      border: none;
      i,
      path {
        color: var(--blue-04, "#5a5aff");
        fill: var(--blue-04, "#5a5aff");
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  @media screen and (max-height: 810px) {
    &&& {
      width: 32px;
      height: 32px;
      i {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-height: 630px) {
    &&& {
      width: 28px;
      height: 28px;

      i {
        font-size: 12px;
      }
    }
  }
`;

const RoundButton = (props: ButtonProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledRoundButton shape="round" {...props} />;
};

export default RoundButton;
