import React, { useEffect, useMemo, useState } from "react";
import { List } from "antd";
import styled, { css } from "styled-components";
import { Folder } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ReactComponent as FolderIcon } from "app/assets/folders/folderIcon.svg";
import { fetchingStatus } from "app/utils/helpers";
import { foldersActions } from "app/store/slices/folders.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import * as foldersSelectors from "app/store/selectorsV2/folders.selectors";
import { moveFolderModalMessages } from "app/pages/FoldersPage/messages";
import { useIntl } from "react-intl";
import { trackEvent } from "app/store/thunks/analyticsEvents.thunk";
import { StyledModal } from "app/components/common/StyledModal";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button } from "@nextui-org/react";

// region [[ Styles ]]

const ListWrapper = styled.div`
  & .ant-list-item:hover {
    background-color: ${({ theme }) => theme.gray3};
  }

  & .ant-list-item {
    cursor: pointer;
  }
`;

const CreateFolderButton = styled(Button)`
  background-color: transparent;
  text-decoration: underline;
  & span {
    text-decoration: underline;
  }
`;

const FolderItem = styled(List.Item)<{ $selected: boolean }>`
  ${(props) => css`
    background-color: ${props.$selected ? props.theme.gray2 : "unset"};
  `};
`;

const FolderIconContainer = styled.div<{ $selected: boolean }>`
  ${({ $selected, theme }) => css`
    & svg path[fill="#FF005C"] {
      fill: ${$selected ? theme.blue4 : theme.gray11};
    }
  `};
`;

// endregion [[ Styles ]]

const MoveToFolderModal = () => {
  const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { editorModalOpen, closeModal, openModal } = useModal();
  const { formatMessage } = useIntl();

  const allFolders = useAppSelector(foldersSelectors.getFoldersSortByUpdate);
  const currentFolder = useAppSelector((state) => state.folders.currentFolder);

  const foldersStatus = useAppSelector((state) => state.folders.foldersStatus);
  const isLoading = foldersStatus === fetchingStatus.loading;

  const { draftIds } = editorModalOpen?.context || {};

  const visible = editorModalOpen?.open === ModalName.moveToFolder;

  const foldersWithoutCurrentFolder = useMemo(() => {
    return allFolders.filter((folder) => folder.id !== currentFolder?.id);
  }, [allFolders, currentFolder]);

  useEffect(() => {
    if (visible) {
      dispatch(foldersActions.getRootFoldersRequest());
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setSelectedFolder(undefined);
    }
  }, [visible]);

  const onSelectFolder = (folder: Folder) => {
    if (folder.id === selectedFolder?.id) {
      setSelectedFolder(undefined);
    } else {
      setSelectedFolder(folder);
    }
  };

  const onCreateFolder = () => {
    openModal(ModalName.createFolder, { draftIds, showMoveToFolder: true });
  };

  const onMove = () => {
    dispatch(
      foldersActions.moveToFolderBulkRequest({
        draftIds,
        parentId: selectedFolder?.id as string
      })
    );
    dispatch(trackEvent({ eventName: "app:folders_fileMove", properties: { type: "draft" } }));

    closeModal();
  };

  return (
    <>
      <StyledModal
        open={visible}
        destroyOnClose
        footer={false}
        centered
        width={600}
        bodyStyle={{ padding: "20px", width: "585px" }}
      >
        <H1_TextMiddle>{formatMessage(moveFolderModalMessages.title)}</H1_TextMiddle>
        <H1_TextSmall>{formatMessage(moveFolderModalMessages.subtitle)}</H1_TextSmall>
        <ListWrapper>
          <List
            size="large"
            bordered
            loading={isLoading}
            style={{ height: "400px", overflow: "auto" }}
            dataSource={foldersWithoutCurrentFolder}
            renderItem={(folder: Folder) => (
              <FolderItem
                key={folder.id}
                onClick={() => onSelectFolder(folder)}
                $selected={folder.id === selectedFolder?.id}
              >
                <List.Item.Meta
                  avatar={
                    <FolderIconContainer $selected={folder.id === selectedFolder?.id}>
                      <FolderIcon width={40} />
                    </FolderIconContainer>
                  }
                  title={<H1_TextSmall>{folder.name}</H1_TextSmall>}
                  description={
                    <H1_TextXs>
                      {formatMessage(moveFolderModalMessages.numOfItems, {
                        number: folder.total_items
                      })}
                    </H1_TextXs>
                  }
                />
              </FolderItem>
            )}
          />
        </ListWrapper>

        <H1_FlexRow width="100%" justify="space-between" padding="30px 0 0 0" gap="10px">
          <CreateFolderButton onClick={onCreateFolder} type="link">
            {formatMessage(moveFolderModalMessages.createNewProject)}
          </CreateFolderButton>
          <H1_FlexRow gap="10px">
            <Button variant="light" onClick={closeModal}>
              {formatMessage(moveFolderModalMessages.cancelButton)}
            </Button>
            <Button color="primary" onClick={onMove} isDisabled={!selectedFolder}>
              {formatMessage(moveFolderModalMessages.moveButton)}
            </Button>
          </H1_FlexRow>
        </H1_FlexRow>
      </StyledModal>
    </>
  );
};

export default MoveToFolderModal;
