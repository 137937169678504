import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { AutoComplete, Button } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Channel } from "app/types";
import { leftNavChannelsMessages } from "app/components/LeftNav/messages";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextHeadline, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 54px 46px 40px;
    flex: 1;
    border-radius: 12px;
    min-height: 460px;
    height: 460px;
    gap: 23px;
    align-items: center;
  }
`;

const ActionButton = styled(Button)`
  &&,
  &&:focus {
    color: ${({ theme }) => theme.gray1};
    background-color: ${({ theme }) => theme.blue4};
    width: 100%;
  }
  &&&:hover {
    background-color: ${({ theme }) => theme.blue2};
  }
  &&&:active {
    background-color: ${({ theme }) => theme.blue1};
  }
`;

const SecondaryButton = styled(Button)`
  &&,
  &&:focus {
    color: ${({ theme }) => theme.gray6};
    background-color: ${({ theme }) => theme.gray3};
    width: 100%;
    border: 1px solid ${({ theme }) => theme.gray5};
  }
  &&&:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
`;

interface AddChannelModalProps {
  visible: boolean;
  onFinish: (name: string) => void;
  onClose: () => void;
}
const AddChannelModal = ({ visible, onFinish, onClose }: AddChannelModalProps) => {
  const [channelName, setChannelName] = useState<string>("");
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { isWriteWorkspaceAllow } = usePermissions();
  const theme = useTheme();

  const channelsSearch = useAppSelector((state) => state.workspaces.channelsSearch);

  const channelsNames = useMemo(
    () =>
      channelsSearch.map((channel: Channel) => ({
        label: channel.name.replace(" ", "_"),
        value: channel.name
      })),
    [channelsSearch]
  );

  useEffect(() => {
    if (!visible) {
      setChannelName("");
    }
  }, [visible]);

  const onChangeChannelName = (value: string) => {
    setChannelName(value);
    dispatch(workspacesActions.getChannelsSearchRequest(value));
  };

  return (
    <Modal
      width="490px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexColumn justify="space-between" flex="1">
        <H1_FlexColumn gap="19px" align="center">
          <H1_TextHeadline color={theme.gray10}>
            {formatMessage(leftNavChannelsMessages.titleAddChannel)}
          </H1_TextHeadline>
          <H1_TextSmall whiteSpace="break-spaces">
            {formatMessage(leftNavChannelsMessages.descriptionAddChannel)}
          </H1_TextSmall>
        </H1_FlexColumn>
        <H1_FlexColumn gap="8px" padding="30px 0 0 0">
          <H1_TextSmall color={theme.gray9}>
            {formatMessage(leftNavChannelsMessages.channelName)}
          </H1_TextSmall>
          <AutoComplete
            options={channelsNames}
            value={channelName}
            onChange={onChangeChannelName}
            placeholder={formatMessage(leftNavChannelsMessages.channelNamePlaceholder)}
            filterOption={(inputValue, option) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              (option!.value as string).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
          />
        </H1_FlexColumn>
        <H1_FlexRow gap="15px" align="center" justify="center" height="40px">
          <SecondaryButton size="large" onClick={onClose}>
            {formatMessage(leftNavChannelsMessages.cancelButton)}
          </SecondaryButton>
          <ActionButton
            size="large"
            onClick={() => onFinish(channelName)}
            disabled={!channelName || !isWriteWorkspaceAllow}
          >
            {formatMessage(leftNavChannelsMessages.addButton)}
          </ActionButton>
        </H1_FlexRow>
      </H1_FlexColumn>
    </Modal>
  );
};

export default AddChannelModal;
