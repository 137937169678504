import { defineMessages } from "react-intl";

export const addCharacterMessages = defineMessages({
  addCharacterText: {
    id: `add-character.icon.text`,
    defaultMessage: "Free Instant Avatar"
  },
  virtualTwinCreationModalTitle: {
    id: "add-character.modal.title",
    defaultMessage: "Create Your Avatar"
  },
  virtualTwinCreationModalFooter: {
    id: "add-character.modal.footer",
    defaultMessage: "Frequently asked questions"
  },
  virtualTwinCreationModalOptionAppTitle: {
    id: "add-character.modal.options.app.title",
    defaultMessage: "Record using App"
  },
  virtualTwinCreationModalOptionAppTag: {
    id: "add-character.modal.options.app.tag",
    defaultMessage: "High Quality"
  },
  virtualTwinCreationModalOptionAppFeature1: {
    id: "add-character.modal.options.app.feature1",
    defaultMessage: "Record from phone"
  },
  virtualTwinCreationModalOptionAppFeature2: {
    id: "add-character.modal.options.app.feature2",
    defaultMessage: "1080p resolution"
  },
  virtualTwinCreationModalOptionAppFeature3: {
    id: "add-character.modal.options.app.feature3",
    defaultMessage: "Waist up framing"
  },
  virtualTwinCreationModalOptionWebcamTitle: {
    id: "add-character.modal.options.web-camera.title",
    defaultMessage: "Record using Webcam"
  },
  virtualTwinCreationModalOptionWebcamTag: {
    id: "add-character.modal.options.web-camera.tag",
    defaultMessage: "Lite Quality"
  },
  virtualTwinCreationModalOptionWebcamFeature1: {
    id: "add-character.modal.options.web-camera.feature1",
    defaultMessage: "Record from laptop"
  },
  virtualTwinCreationModalOptionWebcamFeature2: {
    id: "add-character.modal.options.web-camera.feature2",
    defaultMessage: "720p resolution"
  },
  virtualTwinCreationModalOptionWebcamFeature3: {
    id: "add-character.modal.options.web-camera.feature3",
    defaultMessage: "Chest up framing"
  },
  virtualTwinCreationModalOptionStudioTitle: {
    id: "add-character.modal.options.studio.title",
    defaultMessage: "Record using Studio"
  },
  virtualTwinCreationModalOptionStudioTag: {
    id: "add-character.modal.options.studio.tag",
    defaultMessage: "Highest quality"
  },
  virtualTwinCreationModalOptionStudioFeature1: {
    id: "add-character.modal.options.studio.feature1",
    defaultMessage: "Professional Studio Session"
  },
  virtualTwinCreationModalOptionStudioFeature2: {
    id: "add-character.modal.options.studio.feature2",
    defaultMessage: "4k resolution"
  },
  virtualTwinCreationModalOptionStudioFeature3: {
    id: "add-character.modal.options.studio.feature3",
    defaultMessage: "Multiple outfits"
  },
  virtualTwinCreationErrorTitle: {
    id: "add-character.error.title",
    defaultMessage: "Avatar creation failed"
  },
  virtualTwinCreationModalOptionVoiceCloneTitle: {
    id: "add-character.modal.options.voice-clone.title",
    defaultMessage: "Clone your voice"
  },
  virtualTwinCreationModalOptionVoiceCloneTag: {
    id: "add-character.modal.options.voice-clone.tag",
    defaultMessage: "Voice"
  },
  virtualTwinCreationModalOptionVoiceCloneFeature1: {
    id: "add-character.modal.options.voice-clone.feature1",
    defaultMessage: "Record your own voice from laptop"
  },
  virtualTwinCreationModalOptionVoiceCloneFeature2: {
    id: "add-character.modal.options.voice-clone.feature2",
    defaultMessage: "High quality"
  }
});
