import React, { useEffect } from "react";
import { useAppSelector } from "app/hooks";
import { PlanEnum } from "app/types";
import { useIntl } from "react-intl";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import buildLlmWarningMessage from "app/hoc/ErrorNotifier/buildLlmWarningMessage";
import { llmPusherMessages } from "app/hoc/Pusher/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useErrors from "app/hooks/useErrors";
import { currentWorkspacePlan } from "app/store/selectorsV2/workspaces.selectors";
import useUpgradeModal, { getTargetPlan } from "app/hooks/useUpgradeModal";
import { Progress } from "antd";
import { CreditsType, CreditsReportMsg } from "app/types/pusherMessages";
import { Button } from "@nextui-org/react";

const LLMProgress = styled(Progress)`
  margin-bottom: 3px;
`;
const UpgradeButton = styled(Button)`
  && {
    background-color: #ffe7ca;
    color: #ff9000;
  }
`;

const WhiteFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray1};
`;

const CreditsWarningNotifier = () => {
  const { formatMessage } = useIntl();
  const { notifyError } = useErrors();
  const theme = useTheme();
  const showPaymentModal = useUpgradeModal();
  // @ts-ignore handle userUI store typing
  const creditsWarnings: CreditsReportMsg = useAppSelector((state) => state.user.creditsWarning);
  const currentPlan = useAppSelector((state) => currentWorkspacePlan(state));
  const onUpgradeLlm = () => {
    showPaymentModal({
      source: "upgrade_llm",
      requestedPlans: [getTargetPlan(currentPlan) as PlanEnum],
      upgradeText: formatMessage(llmPusherMessages.upgradeLlmText)
    });
  };
  useEffect(() => {
    if (creditsWarnings) {
      if (creditsWarnings.credits_type === CreditsType.LLM) {
        notifyError({
          general: buildLlmWarningMessage(
            creditsWarnings?.spent_percentage === 100
              ? formatMessage(llmPusherMessages.headLineTitleLlm)
              : formatMessage(llmPusherMessages.headLineTitleLlmWarning),
            <WhiteFlexColumn>
              <H1_FlexRow>
                <H1_TextXs color={theme.gray7} whiteSpace="normal">
                  {creditsWarnings?.spent_percentage === 100
                    ? formatMessage(llmPusherMessages.subTitleLlm)
                    : formatMessage(llmPusherMessages.subTitleLlmWarning, {
                        creditsPercent: creditsWarnings?.spent_percentage
                      })}
                </H1_TextXs>
              </H1_FlexRow>
              <LLMProgress
                strokeColor={theme.orange4}
                trailColor={theme.gray4}
                showInfo={false}
                percent={creditsWarnings?.spent_percentage}
                status={"normal"}
              />
            </WhiteFlexColumn>,
            "alert",
            <UpgradeButton fullWidth color="primary" onClick={onUpgradeLlm}>
              {formatMessage(llmPusherMessages.upgradeButton)}
            </UpgradeButton>
          )
        });
      }
    }
  }, [creditsWarnings]);

  return null;
};

export default CreditsWarningNotifier;
