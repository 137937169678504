import { defineMessages } from "react-intl";

export const editorHeaderMessages = defineMessages({
  translationHeadline: {
    id: `editor-header.translation.popover.header`,
    defaultMessage: "Translate Video"
  },
  translationCurrentLang: {
    id: `editor-header.translation.popover.currentLanguage`,
    defaultMessage: "Current Language"
  },
  translationDestination: {
    id: `editor-header.translation.popover.translationDestination`,
    defaultMessage: "Translate to"
  },
  translationExplain: {
    id: `editor-header.translation.popover.explain`,
    defaultMessage: "A copy of this video will be created in the selected language."
  },
  callToAction: {
    id: `editor-header.translation.popover.button.cta`,
    defaultMessage: "Translate"
  },
  translationInProgress: {
    id: `editor-header.translation.inProgress.notification`,
    defaultMessage: "Video translation in progress"
  },
  translationPaymentHeadline: {
    id: `editor-header.translation.payment-modal.headline`,
    defaultMessage: "To translate video please upgrade"
  },
  translationProcess: {
    id: "editor-header.translation.loading",
    defaultMessage:
      "Draft is being translated, You'll be redirected to the translated draft when finished"
  }
});
