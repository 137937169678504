import styled from "styled-components";

import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const PlayButton = styled(Button)`
  background: unset;
  font-size: 40px;
  &&,
  &&:hover {
    color: ${({ theme }) => theme.blue4};
    span {
      color: ${({ theme }) => theme.blue4};
    }
    background: unset;
  }
  i {
    color: ${({ theme }) => theme.blue4}!important;
    font-size: 32px;
  }
  i:hover {
    opacity: 0.8;
  }
`;
const FileSize = styled(H1_FlexRow)`
  background: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray7 : props.theme.gray1};
  border-radius: 8px;
  color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray2 : props.theme.gray10};
  font-size: 8px;
  font-family: apple-system, BlinkMacSystemFont, Poppins, Ariel;
  padding: 4px 8px;
`;
const MusicIcon = styled("i")`
  color: ${(props) => props.theme.blue1};
  font-size: 30px;
`;

const TrashButton = styled(Button)`
  i {
    color: ${(props) => props.theme.gray8};
  }
`;

const BackgroundFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  border-radius: 12px;
`;

interface FilePlayerProps {
  fileSize?: number;
  fileName?: string;
  playing?: boolean;
  onPlay: () => void;
  onStop: () => void;
  onDelete?: () => void;
}

const FilePlayer = ({ fileSize, fileName, playing, onPlay, onStop, onDelete }: FilePlayerProps) => {
  const sizeAsString = Number(fileSize ? fileSize / (1000 * 1000) : 0).toFixed(2);
  return (
    <BackgroundFlexRow
      padding="10px"
      height="100%"
      align="center"
      width="100%"
      justify="space-between"
    >
      <H1_FlexRow justify="center" align="center" gap="8px">
        <MusicIcon className="fa-solid fa-file-music" />
        <H1_TextXs ellipsis={!!fileName}>{fileName}</H1_TextXs>
        <FileSize justify="center" align="center">
          {sizeAsString}MB
        </FileSize>
      </H1_FlexRow>
      <H1_FlexRow>
        <PlayButton
          color="primary"
          isIconOnly
          startContent={<i className={`fa-solid fa-circle-${playing ? "stop" : "play"}`} />}
          onClick={() => (playing ? onStop() : onPlay())}
        />
        {onDelete && (
          <TrashButton
            variant="light"
            onClick={onDelete}
            isIconOnly
            startContent={<i className="fa-solid fa-trash" />}
          />
        )}
      </H1_FlexRow>
    </BackgroundFlexRow>
  );
};

export default FilePlayer;
