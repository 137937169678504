import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useAppSelector } from "app/hooks";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { TrayItem } from "app/store/slices/uiV2.slice";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { motion } from "framer-motion";
import { Button } from "@nextui-org/react";

const TrayContainer = styled(H1_FlexColumn)`
  position: fixed;
  bottom: 20px;
  right: 90px;
  flex-direction: column-reverse;
  z-index: 100;
`;

const SingleTryItems = styled(H1_FlexRow)`
  position: relative;
  flex: 0 0 auto; // Adjust this according to your needs. This prevents items from growing or shrinking.
  background: ${({ theme }) => theme.gray1};
  box-shadow: ${({ theme }) => `0px 0px 17px 9px ${theme.gray11}33`};

  border-radius: 6px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  transition: transform 0.2s ease-in-out; // Use transform for visual effects
  min-width: 150px; // Ensures that the content dictates the size, but doesn't shrink past content.
  min-height: 50px;
  & button {
    display: none;
    position: absolute;
  }

  &:hover:has(button) {
    padding-left: 50px;
    .doc-loader {
      display: none;
    }
    button {
      display: flex;
      left: 10px;
    }
  }
`;

const Tray = () => {
  const items = useAppSelector((state) => state.uiV2.tray);
  const theme = useTheme();
  const itemKeys = useMemo(() => {
    return Object.keys(items);
  }, [items]);

  return (
    <TrayContainer gap="10px">
      {itemKeys.map((trayKey: string) => (
        <motion.div
          layout
          key={trayKey}
          initial={{ height: 0 }}
          animate={{ height: 50 }}
          exit={{ height: 0 }}
          transition={{
            default: {
              duration: 0.5
            },
            height: {
              damping: 2
            }
          }}
        >
          <SingleTryItems
            padding="0px 20px 0px 10px"
            align="center"
            justify="flex-end"
            key={trayKey}
            onClick={() =>
              (items[trayKey] as TrayItem)?.onExpand
                ? (items[trayKey]?.onExpand as () => void)()
                : null
            }
          >
            <ConditionalRender condition={!!items[trayKey]?.onExpand && items[trayKey]?.showButton}>
              <Button
                isIconOnly
                startContent={<i className="fa-solid fa-up-right-and-down-left-from-center" />}
                variant="light"
                size="sm"
                onClick={() => (items[trayKey]?.onExpand as () => void)()}
              />
            </ConditionalRender>
            <ConditionalRender condition={(items[trayKey] as TrayItem)?.showLoader}>
              <CircleLoader className="doc-loader" size="50px" />
            </ConditionalRender>
            <H1_TextSmall color={theme.gray8}>
              {(items[trayKey] as TrayItem)?.headline}
            </H1_TextSmall>
          </SingleTryItems>
        </motion.div>
      ))}
    </TrayContainer>
  );
};

export default Tray;
