import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAvatar,
  getAvatarConsentSession,
  getAvatars,
  getCharactersList
} from "app/services/serviceV2Apis";
import { ThunkApi } from "app/types";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { Avatar, AvatarRequest, ConsentSession } from "app/types/virtualTwin";
import { charactersActions } from "app/store/slices/characters.slice";
import UiActions from "app/store/actions/ui.actions";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import { IntlShape } from "react-intl";
import { addSourceId } from "app/store/thunks/characters.thunk";
import { addCharacterMessages } from "app/components/digitalAvatar/messages";

const prefix = "[Avatars]";

const createAvatarRequest = createAsyncThunk<Avatar, AvatarRequest, ThunkApi>(
  `${prefix} createAvatarRequest`,
  async (body) => {
    const result = await createAvatar(body);
    return result;
  },
  thunkOptions
);

const getAvatarsRequest = createAsyncThunk<Avatar[], string[], ThunkApi>(
  `${prefix} getAvatarsRequest`,
  async (statuses?: string[]) => {
    const result = await getAvatars(statuses);
    return result;
  },
  thunkOptions
);

const updateAvatarProgress = createAsyncThunk<
  void,
  {
    sessionId: string;
    characterId?: string;
    progress: number;
    status: string;
    failureReason?: string;
    intl: IntlShape;
  },
  ThunkApi
>(
  `${prefix} updateAvatarProgress`,
  async ({ characterId, status, failureReason, intl }, thunkApi) => {
    if (status === "failed") {
      thunkApi.dispatch(
        UiActions.setErrors({
          avatarCreationSession: buildGeneralError(
            failureReason,
            intl,
            intl.formatMessage(addCharacterMessages.virtualTwinCreationErrorTitle)
          )
        })
      );
    } else if (characterId) {
      const characters = await getCharactersList({ character_id: characterId });
      if (characters.length !== 1) {
        console.error("got avatar completed, but missing character", characterId);
      } else {
        characters.map(addSourceId);
        thunkApi.dispatch(charactersActions.upsertCharacter(characters[0]));
      }
    }
  },
  thunkOptions
);

const getAvatarConsentSessionRequest = createAsyncThunk<ConsentSession, void, ThunkApi>(
  `${prefix} getAvatarConsentRequest`,
  async () => {
    const result = await getAvatarConsentSession();
    return result;
  },
  thunkOptions
);

export default {
  getAvatarConsentSessionRequest,
  createAvatarRequest,
  updateAvatarProgress,
  getAvatarsRequest
};
