import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getVariableById } from "app/store/selectorsV2/drafts.selectors";
import { $getNodeByKey } from "lexical";
import { $isVariableNode } from "app/components/common/LexicalEditor/nodes/VariableNode";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { draftsActions } from "app/store/slices/drafts.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";

const InlineFlexRow = styled(H1_FlexRow)<{
  $open: boolean;
  $disabled: boolean;
}>`
  display: inline-flex;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
  background-color: ${({ theme, $open, $disabled }) =>
    $disabled ? theme.gray5 : $open ? theme.blue2 : theme.blue1};
  color: ${({ theme, $disabled }) => ($disabled ? theme.gray3 : theme.blue4)};
  span {
    color: ${({ theme, $disabled }) => ($disabled ? theme.gray3 : theme.blue4)};
  }
  && i {
    color: ${({ theme, $disabled }) => ($disabled ? theme.gray3 : theme.blue4)};
  }
  &:hover {
    opacity: 0.8;
  }
`;

const InputContainer = styled(H1_Input)`
  position: relative;
  z-index: 4;
  input {
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
    background-color: ${({ theme }) => theme.blue1};
    color: ${({ theme }) => theme.blue4};
  }
`;

interface VariableComponentProps {
  id: string;
  nodeKey: string;
}

const VariableComponent = ({ id, nodeKey }: VariableComponentProps) => {
  const [open, setOpen] = useState(false);
  const [currentText, setCurrentText] = useState<string>("");
  const [currentDefaultValue, setCurrentDefaultValue] = useState<string>("");
  const [currentDisplayTitle, setCurrentDisplayTitle] = useState<string>("");
  const [editor] = useLexicalComposerContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const selectedVariable = useAppSelector((state) => getVariableById(state, id));
  const draft = useAppSelector(({ drafts }) => drafts.currentDraft);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    if (selectedVariable) {
      setCurrentText(selectedVariable.name);
      setCurrentDefaultValue(selectedVariable.default_value);
      setCurrentDisplayTitle(selectedVariable.display_title);
    }
  }, [selectedVariable]);

  const onUpdateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentText(e.target.value);
  };

  const onUpdateDefaultValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDefaultValue(e.target.value);
  };
  const onUpdateDisplayTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDisplayTitle(e.target.value);
  };

  const removeVariable = () => {
    // dispatch(analyticsEvents.editorPauseRemoved({ time, nodeKey }));
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isVariableNode(node)) {
        node.remove();
        // Force calling the onBlurText in order to update the server
        editor.focus();
        editor.blur();
      }
    });
  };

  const onBlur = () => {
    // dispatch(analyticsEvents.editorPauseClosed({ time, nodeKey }));
    dispatch(
      draftsActions.updateVariableRequest({
        draftId: draft?.id as string,
        variableId: selectedVariable?.id as string,
        variable: {
          name: (currentText || "").replace(/ /g, "_"),
          display_title: currentDisplayTitle,
          default_value: currentDefaultValue
        }
      })
    );
    setOpen(false);
  };

  // todo disable if there is no variable

  const isWarning = false;
  // todo need a warning if the variable does not exist

  const onOpenChange = (currentVisibility: boolean) => {
    setOpen(currentVisibility);
    if (!currentVisibility) {
      onBlur();
    }
  };

  const onClickText = () => {
    setOpen(true);
  };

  const content = (
    <H1_FlexColumn gap="8px" padding="10px">
      <H1_FlexRow justify="space-between" gap="8px" align="center">
        <H1_TextXs>Name:</H1_TextXs>
        <InputContainer
          initialValue={currentText}
          onChange={onUpdateText}
          minWidth="130px"
          isFixedWidth
          ref={inputRef}
          // @ts-ignore - Should be added to design system in the future
          maxLength={20}
        />
      </H1_FlexRow>
      <H1_FlexRow justify="space-between" gap="8px" align="center">
        <H1_TextXs>Default value:</H1_TextXs>
        <InputContainer
          initialValue={currentDefaultValue}
          onChange={onUpdateDefaultValue}
          minWidth="130px"
          isFixedWidth
        />
      </H1_FlexRow>
      <H1_FlexRow justify="space-between" gap="8px" align="center">
        <H1_TextXs>Display name:</H1_TextXs>
        <InputContainer
          initialValue={currentDisplayTitle}
          onChange={onUpdateDisplayTitle}
          minWidth="130px"
          isFixedWidth
        />
      </H1_FlexRow>
      <H1_FlexRow gap="8px" align="center" justify="flex-end">
        <Button
          isIconOnly
          variant="light"
          size="sm"
          onClick={onBlur}
          startContent={<i className="far fa-circle-check" />}
        />
        <Button
          size="sm"
          isIconOnly
          startContent={<i className="far fa-trash" />}
          onClick={removeVariable}
        />
      </H1_FlexRow>
    </H1_FlexColumn>
  );

  return (
    <Popover onOpenChange={onOpenChange} isOpen={open}>
      <Tooltip
        title={selectedVariable ? "Variable" : "Variable is missing in variables list"}
        placement={isWarning ? "bottom" : "top"}
      >
        <>
          <PopoverTrigger>
            <InlineFlexRow
              margin="0 5px"
              padding="0 5px"
              justify="center"
              align="center"
              height="22px"
              position="relative"
              $open={false}
              $disabled={false}
              onClick={onClickText}
            >
              <H1_TextXs lineHeight="19px">{selectedVariable?.name || ""}</H1_TextXs>
            </InlineFlexRow>
          </PopoverTrigger>
        </>
      </Tooltip>
      <PopoverContent>{content}</PopoverContent>
    </Popover>
  );
};

export default VariableComponent;
