import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFolder,
  deleteFolder,
  editFolder,
  getFilesFolders,
  getFolder,
  getFolderContent,
  moveToFolderMultiple
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { draftsActions } from "app/store/slices/drafts.slice";
import { CreateFolder, Draft, Folder, FoldersContext, ThunkApi } from "app/types";
import { videosActions } from "app/store/slices/videos.slice";

const prefix = "[Folders]";

const getRootFoldersRequest = createAsyncThunk(
  `${prefix} getRootFoldersRequest`,
  async () => {
    const res = await getFilesFolders();
    const result = res.data;
    return result;
  },
  thunkOptions
);
const getFolderContentRequest = createAsyncThunk<
  Folder,
  { folderId: string; fileType?: string },
  ThunkApi
>(
  `${prefix} getFolderContentRequest`,
  async ({ folderId, fileType }, thunkApi) => {
    const res = await getFolderContent(folderId, fileType);
    const result = res.data;
    const drafts = result.content
      .filter((folder: Folder) => !!folder.data)
      .map((folder: Folder) => {
        return {
          ...folder.data,
          path_id: folder.id
        } as Draft;
      })
      .filter((draft: Draft | undefined) => !!draft); // this one for case there is a file which has no data
    // ThunkApi.
    thunkApi.dispatch(draftsActions.setAllDrafts(drafts));
    const videos = drafts
      .filter((draft: Draft) => !!draft.last_video)
      .map((draft: Draft) => draft.last_video);
    thunkApi.dispatch(videosActions.setVideos(videos));
    return result.metadata;
  },
  thunkOptions
);

const createFolderRequest = createAsyncThunk<Folder, CreateFolder>(
  `${prefix} createFolderRequest`,
  async (data) => {
    const res = await createFolder(data);
    const result = res.data;
    return result;
  },
  thunkOptions
);

const editFolderNameRequest = createAsyncThunk<void, { folderId: string; name: string }>(
  `${prefix} editFolderNameRequest`,
  async ({ folderId, name }) => {
    await editFolder(folderId, name);
  },
  thunkOptions
);

const moveToFolderBulkRequest = createAsyncThunk<
  void,
  { draftIds: string[]; parentId: string },
  ThunkApi
>(
  `${prefix} moveToFolderBulkRequest`,
  async ({ draftIds, parentId }, thunkApi) => {
    const draftsEntities = thunkApi.getState().drafts.entities;
    const request = draftIds.map((draftId) => {
      return {
        id: draftsEntities[draftId]?.path_id as string,
        parent_id: parentId
      };
    });
    thunkApi.dispatch(draftsActions.removeDraftsFromList(draftIds));
    thunkApi.dispatch(draftsActions.cleanSelectedDraftIds());
    await moveToFolderMultiple(request);
  },
  thunkOptions
);

const deleteFolderRequest = createAsyncThunk<void, Folder>(
  `${prefix} deleteFolderRequest`,
  async (folderData) => {
    await deleteFolder(folderData.id as string);
  },
  thunkOptions
);
const getFolderByIdRequest = createAsyncThunk<
  Folder,
  { folderId: string; context?: FoldersContext }
>(
  `${prefix} getFolderByIdRequest`,
  async ({ folderId, context }) => {
    const result = await getFolder(folderId, context);
    return result;
  },
  thunkOptions
);

export default {
  getRootFoldersRequest,
  getFolderContentRequest,
  createFolderRequest,
  editFolderNameRequest,
  moveToFolderBulkRequest,
  deleteFolderRequest,
  getFolderByIdRequest
};
