import { defineMessages } from "react-intl";
const messages = defineMessages({
  generic: {
    id: "notification.validation.generic.message",
    defaultMessage: "Sorry, Something Went Wrong"
  },
  characterDesc: {
    id: "notification.validation.character.desc",
    defaultMessage: "Please select a different presenter."
  },
  voiceDesc: {
    id: "notification.validation.voice.desc",
    defaultMessage: "Please select a voice to continue creating a preview"
  },
  transcriptMsg: {
    id: "notification.validation.transcript.message",
    defaultMessage: "Missing transcript"
  },
  cannotCreateVideo: {
    id: "notification.validation.cannot-create-video",
    defaultMessage: "We couldn't create your video"
  },
  cannotAudioPreview: {
    id: "notification.validation.cannot-create-audio",
    defaultMessage: "We couldn't create your audio"
  },
  textsMsg: {
    id: "notification.validation.texts.message",
    defaultMessage: "Missing texts in scene"
  },
  textsDesc: {
    id: "notification.validation.texts.desc",
    defaultMessage: "Please type in your texts assets"
  },
  transcriptDesc: {
    id: "notification.validation.transcript.desc",
    defaultMessage: "Please type in your narrative in:"
  },
  transcriptDescPlurals: {
    id: "notification.validation.transcript.desc-plurals",
    defaultMessage: "Please type in your narrative in:"
  },
  transcriptBadCharsDesc: {
    id: "notification.validation.transcript.bad-chars-desc",
    defaultMessage: "Please remove any invalid characters from the script in:"
  },
  transcriptSceneNumber: {
    id: "notification.validation.transcript.scene-number",
    defaultMessage: "Scene {number}"
  },
  transcriptDescBadCharsHeader: {
    id: "notification.validation.transcript.bad-chars-header",
    defaultMessage: "Oops, Invalid Characters!"
  },
  transcriptDescBadCharsPlurals: {
    id: "notification.validation.transcript.bad-chars-desc-plurals",
    defaultMessage: "Please remove any invalid characters from the script in:"
  },
  mediaMsg: {
    id: "notification.validation.media.message",
    defaultMessage: "Missing media selection"
  },
  mediaDesc: {
    id: "notification.validation.media.desc",
    defaultMessage: "Please choose image/video in scene {number}"
  },
  symbolDesc: {
    id: "notification.validation.symbol.desc",
    defaultMessage: "Invalid symbols at scene number {number}"
  },
  moderationMediaMsg: {
    id: "notification.validation.moderation-media.message",
    defaultMessage: "Oops! Media Moderation Issue"
  },
  moderationMediaDesc: {
    id: "notification.validation.moderation-media.desc",
    defaultMessage:
      "Please upload another image/video file ensuring it adheres to our <link>guidelines</link>"
  },
  moderationTxtMsg: {
    id: "notification.validation.moderation-text.message",
    defaultMessage: "Oops! Let's Keep it Friendly"
  },
  moderationTxtDesc: {
    id: "notification.validation.moderation-text.desc",
    defaultMessage:
      "Make sure your script is respectful and free of <link>offensive content.</link>"
  }
});

export default messages;
