import styled, { useTheme } from "styled-components";
import { useAppDispatch } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import srcImage from "app/assets/homepage/mobile-modal-image.png";
import appStoreImage from "app/assets/homepage/appstore.png";
import { useIntl } from "react-intl";
import QRCode from "react-qr-code";
import Config from "app/config/Config";
import { useAuth } from "app/auth/useAuth";
import { mobileBannerMessages } from "app/components/editor/sideDrawers/EditingCharacterDiscover/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextLargeHeadline, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const serverBaseUrl = Config.serverUrl;

const PosterImage = styled.img`
  position: relative;
  z-index: 2;
  margin-left: 80px;
  margin-top: 77px;
  height: 305px;
`;

const AppStoreImage = styled.img`
  width: 130px;
  height: 40px;
  cursor: pointer;
`;

/* Should have display block */
const RelativeImage = styled.div`
  position: relative;
  width: 463px;
  height: 100%;
`;

const MobileCharacterQrModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { returnToModal, editorModalOpen } = useModal();
  const { user } = useAuth();
  const theme = useTheme();

  const visible = editorModalOpen?.open === ModalName.mobileQrModal;
  const qrValue = `${serverBaseUrl}/v2/services/app-download?ref=${user?.sub}`;

  const onCloseModal = () => {
    dispatch(googleEvents.mobileModalClose());
    returnToModal();
  };
  const onClickAppStore = () => {
    window.open(Config.mobileAppStoreUrl, "_blank");
    dispatch(googleEvents.mobileModalOnClicked({ item: "appStoreButton" }));
    returnToModal();
  };

  return (
    <>
      <StyledModal
        width="833px"
        open={visible}
        footer={null}
        centered
        bodyStyle={{ height: "470px" }}
        onCancel={onCloseModal}
        destroyOnClose
        closeIcon={<CloseModalIcon />}
      >
        <H1_FlexRow>
          <RelativeImage>
            <PosterImage src={srcImage} />
          </RelativeImage>
          <H1_FlexColumn gap="24px" padding="83px 62px 0 0">
            <QRCode value={qrValue} size={100} />
            <H1_FlexColumn gap="4px">
              <H1_FlexRow width="308px" padding="12px 0 0 0">
                <H1_TextLargeHeadline
                  color={theme.gray10}
                  whiteSpace="break-spaces"
                  fontWeight={700}
                >
                  {formatMessage(mobileBannerMessages.title)}
                </H1_TextLargeHeadline>
              </H1_FlexRow>
              <H1_FlexRow width="308px">
                <H1_TextSmall whiteSpace="break-spaces" inline color={theme.gray10}>
                  {formatMessage(mobileBannerMessages.subtitleText)}
                </H1_TextSmall>
              </H1_FlexRow>
            </H1_FlexColumn>
            <AppStoreImage src={appStoreImage} onClick={onClickAppStore} />
          </H1_FlexColumn>
        </H1_FlexRow>
      </StyledModal>
    </>
  );
};

export default MobileCharacterQrModal;
