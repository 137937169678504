import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Channel } from "pusher-js";
import { extractUserIdForPusher } from "app/utils/helpers";
import { PlanEnum } from "app/types";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { paymentsActions } from "app/store/slices/payments.slice";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import useNotifications from "app/hooks/useNotifications";
import useCurrentPlan from "app/hooks/useCurrentPlan";

const PusherPlanChanged = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const { notifyMessages } = useNotifications();
  const { getCurrentPlanText } = useCurrentPlan();

  useEffect(() => {
    let channel: Channel;
    if (!currentWorkspace?.id) {
      return;
    }
    const handleMessage = (message: { workspace_id: string; plan: PlanEnum; credits: number }) => {
      const { workspace_id, plan, credits } = message;

      if (currentWorkspace?.id === workspace_id) {
        dispatch(paymentsActions.getCreditsRequest());
        dispatch(workspacesActions.getWorkspacesRequest());
        notifyMessages([
          {
            message: `Exciting news! Your plan is now ${getCurrentPlanText(
              plan
            )}, with an extra ${parseInt(String(credits / 60))} minutes added. Enjoy!`
          }
        ]);
      }
    };
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("plan_changed", handleMessage);
    }

    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [currentWorkspace?.id]);

  return null;
};

export default PusherPlanChanged;
