import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import { draftsGlobalSelectors, scenesGlobalSelectors } from "app/store/adapters/adapters";
import { dateSorter } from "app/utils/helpers";
import { Draft, VideoStatusEnum, VideoWizardWorkflowSubCategory } from "app/types";

const currentTemplate = (state: RootState) => state.templates.currentTemplate;
// @ts-ignore handels userUI store typing
const drawerOrigin = (state: RootState) => state.userUi.drawerOrigin;
export const currentDraft = (state: RootState) => state.drafts.currentDraft;
export const draftHistory = (state: RootState) => state.drafts.draftHistory;
export const createDraftStatus = (state: RootState) => state.drafts.createDraftStatus;
export const workspaceSearchResults = (state: RootState) => state.drafts.workspaceSearchResults;
export const workflowCategories = (state: RootState) => state.drafts.workflowCategories;
export const workflowSubmissions = (state: RootState) => state.drafts.workflowSubmissions;
const selectedDraftIds = (state: RootState) => state.drafts.selectedDraftIds;
const draftEntities = (state: RootState) => state.drafts.entities;
// todo need to convert character to be adatper

export const getSceneForDrawer = createSelector(
  [drawerOrigin, scenesGlobalSelectors.selectEntities],
  (sceneDetails, scenes) => {
    if (sceneDetails) {
      return scenes[sceneDetails.id];
    }
    return null;
  }
);

export const getDraftWithTemplate = createSelector(
  [currentDraft, currentTemplate],
  (draft, template) => {
    return {
      ...draft,
      template
    };
  }
);

export const getLatestDraftHistory = createSelector([draftHistory], (drafts) => {
  return drafts[0];
});

export const getDraftsSortByUpdate = createSelector([draftsGlobalSelectors.selectAll], (drafts) => {
  const result = [...drafts];
  return result.sort((a, b) => dateSorter(a.updated_at as string, b.updated_at as string));
});

export const getVideosFromDraftHistory = createSelector([draftHistory], (drafts) => {
  return drafts.map((history) => history.video);
});

export const getDraftHistoryByVideoId = createSelector(
  [draftHistory, (state, videoId?: string) => videoId],
  (drafts, videoId) => {
    return drafts.find((history) => history.video?.id === videoId);
  }
);

export const getDraftsBySource = createSelector(
  [draftsGlobalSelectors.selectAll, (state, source: string) => source],
  (drafts, source) => {
    return drafts.filter((draft: Draft) => draft.source === source);
  }
);

export const getDraftsBySourceAndFilter = createSelector(
  [
    draftsGlobalSelectors.selectAll,
    (state, { source, property, value }) => ({ source, property, value })
  ],
  (drafts, { source, property, value }) => {
    return drafts.filter((draft: Draft) => {
      if (draft.source !== source && source) {
        return false;
      }
      const currentProperty = draft[property as keyof Draft] as any;
      if (currentProperty === undefined || currentProperty.length === 0 || value === undefined) {
        return !value;
      }
      if (Array.isArray(currentProperty)) {
        let isFound = false;
        currentProperty.forEach((prop) => {
          isFound = isFound || prop.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        return isFound;
      }
      if (currentProperty?.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        return true;
      }

      return false;
    });
  }
);

export const getDraftsByPageAndQuery = createSelector(
  [
    getDraftsSortByUpdate,
    // Do not use typed arguments because objecting them will cause re-rendering every time redux state chagnes, for object is only a reference https://redux.js.org/usage/deriving-data-selectors#using-selectors-with-react-redux
    (state, currentPage, query, totalNumberOfDraftsPerPage, type) => ({
      currentPage,
      query,
      totalNumberOfDraftsPerPage,
      type
    })
  ],
  (drafts, { currentPage, query, totalNumberOfDraftsPerPage, type }) => {
    const currentItemsNumber = (currentPage - 1) * totalNumberOfDraftsPerPage;
    const filteredDrafts = drafts.filter((draft) => {
      let filtered = draft.title?.toLowerCase().includes(query?.trim().toLowerCase());
      if (type && filtered) {
        filtered = draft.type === type;
      }
      return filtered;
    });
    return {
      filteredDrafts,
      slicedDrafts: filteredDrafts.slice(
        currentItemsNumber,
        currentItemsNumber + totalNumberOfDraftsPerPage
      ),
      totalDrafts: filteredDrafts.length
    };
  }
);

export const getDraftsByPage = createSelector(
  [draftsGlobalSelectors.selectAll, (state, currentPage) => currentPage],
  (drafts, currentPage) => {
    const currentItemsNumber = (currentPage - 1) * 10;
    const sorter = drafts.sort((a: Draft, b: Draft) =>
      dateSorter(a.updated_at as string, b.updated_at as string)
    );
    return sorter.slice(currentItemsNumber, currentItemsNumber + 10);
  }
);

export const getWorkspaceSearchByType = createSelector(
  [workspaceSearchResults, (state, searchType) => searchType],
  (searchResults, searchType) => {
    if (searchResults.results && searchResults.results.length > 0) {
      return searchResults.results.filter(
        (searchResult) => searchResult.resource_type === searchType
      );
    }

    return [];
  }
);

export const getStylesOptions = createSelector(
  [workflowCategories, (state, ratio) => ratio],
  (workflowCategories, ratio) => {
    if (!workflowCategories) {
      return [];
    }
    const workflow = workflowCategories.styles.find(
      (currentStyle: { ratio: string }) => currentStyle.ratio.toLowerCase() === ratio.toLowerCase()
    );
    if (!workflow) {
      return [];
    }

    return workflow.recipes_styles;
  }
);
export const getDurationOptions = createSelector([workflowCategories], (workflowCategories) => {
  if (!workflowCategories) {
    return [];
  }
  return workflowCategories.lengths.map((length: string) => ({
    value: length,
    label: length
  }));
});

export const getCategoriesOptions = createSelector([workflowCategories], (workflowCategories) => {
  if (!workflowCategories) {
    return [];
  }
  return workflowCategories.categories.map(
    (category: { id: string; title: string; sub_categories?: any }) => ({
      value: category.id,
      label: category.title,
      subCategories: category.sub_categories
        ? category.sub_categories.map(
            (subCategory: { id: string; title: string; variables: [] }) => ({
              value: subCategory.id,
              label: subCategory.title,
              variables: subCategory.variables
            })
          )
        : []
    })
  );
});

export const getSubCategoryOptions = createSelector(
  [getCategoriesOptions, (state, category) => category],
  (categories, category) => {
    if (!categories || !category) {
      return [];
    }
    const selectedCategory = categories.find((c) => c.value === category);
    return selectedCategory?.subCategories || [];
  }
);

export const getVariablesOptions = createSelector(
  [getSubCategoryOptions, (state, category, subCategory) => ({ category, subCategory })],
  (subCategories, { subCategory }) => {
    if (!subCategories || !subCategory) {
      return [];
    }
    const selectedSubcategory = subCategories.find(
      (c: VideoWizardWorkflowSubCategory) => c.value === subCategory
    );
    return selectedSubcategory?.variables || [];
  }
);

export const getVariableById = createSelector(
  [currentDraft, (state, id: string) => id],
  (draft, id) => {
    return draft.variables?.find((variable) => variable.id === id);
  }
);

export const getWorkflowSubmissionById = createSelector(
  [workflowSubmissions, (state, id: string) => id],
  (workflowSubmissions, id) => {
    return workflowSubmissions?.find((submission) => submission.id === id);
  }
);

export const getDraftIdsWithReadyVideos = createSelector(
  [draftEntities, selectedDraftIds],
  (draftEntities, selectedDraftIds) => {
    return selectedDraftIds.reduce((arr: string[], curr: string) => {
      const currentDraft = draftEntities[curr] as Draft;
      const video = currentDraft?.last_video;
      if (
        video &&
        (video.status === VideoStatusEnum.Ready || video.status === VideoStatusEnum.Published)
      ) {
        return [...arr, curr];
      }
      return arr;
    }, []);
  }
);
