import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";

import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import { DevModeSize, FeatureFlag, Recipe, RecipeList } from "app/types";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import SingleTemplate from "app/components/editor/sideDrawers/TemplatesDrawer/SingleTemplate";
import { recipesActions } from "app/store/slices/recipes.slice";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import TemplateDrawerElement from "app/components/editor/sideDrawers/TemplatesDrawer/TemplateDrawerElement";
import { Chip } from "@nextui-org/react";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { ThemeMode } from "app/utils/theme";

const BackgroundChip = styled(Chip)<{ $isSelected: boolean }>`
  transition: background-color 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, $isSelected }) =>
      !$isSelected && (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray4)};
  }
  background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.blue4 : theme.gray6)};
  span {
    color: white;
  }
`;
const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: calc(100% - 24px);
  margin: 0 24px 0 0;
  .swiper-slide {
    display: flex;
    width: auto;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    box-shadow: none;
    background-color: transparent;
    border: none;
    top: calc(50% + 2px);
    bottom: 20px;
    background-size: 24px;
    transition: 0.3s all ease-in-out;
    z-index: 100;
    &:hover {
      background-size: 30px;
    }
    &:active {
      background-size: 20px;
    }
  }
  .swiper-button-prev {
    left: -10px;
    opacity: 0.8;
  }
  .swiper-button-next {
    right: 15px;
    opacity: 0.8;
  }
`;

export interface TemplatesDrawerProps {
  right: string;
  width: string;
}

const TemplatesDrawer = ({ right, width }: TemplatesDrawerProps) => {
  const [gridHeight, setGridHeight] = useState(window.innerHeight);
  const [isSwiperReady, setIsSwiperReady] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<Partial<RecipeList> | undefined>(
    undefined
  );
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const { editorDrawerOpen } = useDrawer();
  const showPaymentModal = useUpgradeModal();

  const flags = useFlags();
  const currentWorkspaceId = useAppSelector((state) => getCurrentWorkspace(state)?.id);

  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const categoriesNames = useAppSelector((state) =>
    recipesSelectors.getAllRecipesCategoriesNames(state)
  );

  const flattenRecipes = useAppSelector(recipesSelectors.getFlattenRecipes);
  const recipesStatus = useAppSelector((state) => state.recipes.status);
  const draftRecipeStatus = useAppSelector((state) => state.drafts.draftRecipeStatus);
  const isLoading =
    recipesStatus === fetchingStatus.loading || draftRecipeStatus === fetchingStatus.loading;
  const isDrawerActive = editorDrawerOpen === Drawer.Templates;

  useEffect(() => {
    const handleResize = () => {
      setGridHeight(ref.current?.offsetHeight || 0);
    };
    if (isDrawerActive) {
      dispatch(recipesActions.getRecipesRequest());
      setTimeout(() => {
        setIsSwiperReady(true);
      }, 1000);
      setGridHeight(ref.current?.offsetHeight || 0);

      window.addEventListener("resize", handleResize);
    } else {
      setIsSwiperReady(false);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDrawerActive, currentWorkspaceId]);

  useEffect(() => {
    if (recipesStatus === fetchingStatus.succeeded) {
      setGridHeight(ref.current?.offsetHeight || 0);
    }
  }, [recipesStatus]);

  const onSelectSection = (section: Partial<RecipeList> | undefined) => {
    if (section) {
      dispatch(analyticsEvents.templateCategorySelect({ categoryName: section.name as string }));
    }
    if (section?.id === selectedSection?.id) {
      setSelectedSection(undefined);
    } else {
      setSelectedSection(section);
    }
  };

  const changeRecipe = (recipeId: string, recipeName: string) => {
    dispatch(
      analyticsEvents.changeRecipe({
        recipeName,
        recipeId
      })
    );
    dispatch(
      draftsActions.changeCurrentDraftRecipeRequest({
        draftId: draft.id as string,
        recipeId
      })
    );
  };

  const onSelectedRecipeId = (recipeId: string) => {
    const recipe: Recipe = flattenRecipes.find(
      (flattenRecipe) => flattenRecipe.id === recipeId
    ) as Recipe;

    const recipeTitle = recipe.title;

    if (recipe.pro && flags[FeatureFlag.blockProTemplateOnCreateDraft]) {
      const isUpgradeRequired = showPaymentModal({
        source: "upgrade_change_template_drawer",
        requestedPlans: recipe.plans
      });

      if (!isUpgradeRequired) {
        changeRecipe(recipeId, recipeTitle);
      }
    } else {
      changeRecipe(recipeId, recipeTitle);
    }
  };

  return (
    <FlexDrawer right={right} active={isDrawerActive} width={width} className="templates">
      <H1_FlexColumn flex="1 1 auto">
        <ConditionalRender condition={isLoading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={!isLoading}>
          {/* No swiper - skeleton loader */}
          <ConditionalRender condition={!isSwiperReady}>
            <H1_FlexColumn padding="10px 20px 0 0" flex="0 0 50px" margin="0 0 10px 0">
              <StyledSkeletonButton $width="100%" $height="40px" active block size="large" />
            </H1_FlexColumn>
          </ConditionalRender>
          <ConditionalRender condition={isSwiperReady}>
            <H1_FlexRow
              position="relative"
              padding="0 20px"
              width="100%"
              flex="0 0 50px"
              gap="10px"
              margin="0 0 10px 0"
            >
              <StyledSwiper
                allowTouchMove={false}
                navigation
                speed={700}
                slidesPerView="auto"
                spaceBetween={10}
                slidesPerGroup={2}
              >
                {categoriesNames.map((recipeList: Partial<RecipeList>) => (
                  <SwiperSlide key={recipeList.id}>
                    <BackgroundChip
                      $isSelected={recipeList.id === selectedSection?.id}
                      size="sm"
                      variant="flat"
                      onClick={() => onSelectSection(recipeList)}
                    >
                      <H1_TextSmall>{recipeList.name}</H1_TextSmall>
                    </BackgroundChip>
                    {/*<Box*/}
                    {/*  onClick={() => onSelectSection(recipeList)}*/}
                    {/*  $isColored={recipeList.id === selectedSection?.id}*/}
                    {/*  width="fit-content"*/}
                    {/*  padding="0 5px"*/}
                    {/*  height="30px"*/}
                    {/*  justify="center"*/}
                    {/*  align="center"*/}
                    {/*>*/}
                    {/*  <H1_TextXs lineHeight="12px">{recipeList.name}</H1_TextXs>*/}
                    {/*</Box>*/}
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </H1_FlexRow>
          </ConditionalRender>
          <H1_FlexRow
            padding="0 22px 20px 0"
            width="356px"
            height="calc(356px * 9 / 16)"
            flex="0 0 auto"
          >
            <ConditionalRender condition={!!draft.recipe_id && flattenRecipes?.length > 0}>
              <SingleTemplate
                recipeId={draft.recipe_id as string}
                isSelected={false}
                width="100%"
                isNameDisplayed={false}
                size={DevModeSize.large}
                isTemplateSupported
              />
            </ConditionalRender>
          </H1_FlexRow>
          <H1_FlexRow flex="1" wrap="wrap" gap="14px" overflow="auto" ref={ref}>
            <TemplateDrawerElement
              height={gridHeight}
              onSelectedRecipeId={onSelectedRecipeId}
              selectedSectionId={selectedSection?.id}
              currentDraftRecipeId={draft?.recipe_id}
            />
          </H1_FlexRow>
        </ConditionalRender>
      </H1_FlexColumn>
    </FlexDrawer>
  );
};

export default TemplatesDrawer;
