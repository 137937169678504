import { Swiper } from "swiper/react";
import styled from "styled-components";
import { ThemeMode } from "app/utils/theme";

export const NavigationSwiper = styled(Swiper)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-wrapper {
    height: auto;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    border: 1px solid ${(props) => props.theme.gray5};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${(props) => props.theme.gray8};
    background-color: ${(props) => props.theme.gray2};
    transition-property: border-color, color;
    transition-duration: 0.2s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12);
    svg {
      fill: ${(props) => props.theme.gray8};
      width: 16px;
      height: 16px;
    }
    &:hover {
      border-color: ${(props) => props.theme.gray5};
    }
    &:active {
      transform: scale(0.95);
    }
    &::after {
      content: "";
    }
  }

  && .swiper-button-prev {
    background-image: ${(props) =>
      props.theme.mode === ThemeMode.Light
        ? `url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-left.svg")`
        : `url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/angle-left_white.svg")`};
    background-color: ${(props) =>
      props.theme.mode === ThemeMode.Light ? props.theme.gray1 : "transparent"};
  }

  && .swiper-button-next {
    background-image: ${(props) =>
      props.theme.mode === ThemeMode.Light
        ? `url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-right.svg")`
        : `url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/angle-right_white.svg")`};
    background-color: ${(props) =>
      props.theme.mode === ThemeMode.Light ? props.theme.gray1 : "transparent"};
  }
`;
