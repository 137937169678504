export interface BriefRecipientResponse {
  email: string;
}

export enum BriefUserRelation {
  creator = "creator",
  recipient = "recipient"
}
export interface Brief {
  id?: string;
  created_at?: string;
  email?: string;
  content?: string;
  recipients?: BriefRecipientResponse[];
  recipe_id?: string;
  file_url?: string;
  archived?: boolean;
  user_relation?: BriefUserRelation;
}

export interface BriefRequest {
  recipients: string[];
  content: string;
  recipe_id?: string;
  file_url?: string;
}
