import React, { Suspense, useMemo, useRef, useState } from "react";

import { useIntl } from "react-intl";
import "app/pages/HomePage/HomePage.scss";
import styled, { useTheme } from "styled-components";
import { homePageMessages } from "app/pages/HomePage/messages";
import { useAppDispatch, useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import { FlatRecipe, Location } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { SwiperSlide } from "swiper/react";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useNavigate } from "react-router-dom";
import TemplateCollectionSkelaton from "./TemplatesCollectionSkelaton";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";

const SingleTemplateInCollectionSwiper = React.lazy(
  () => import("app/pages/HomePage/TemplateCollection/SingleTemplateInCollectionSwiper")
);

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  padding-bottom: 5px;
  .swiper-slide {
    width: calc(160px * 16 / 9);
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 94px;
    justify-content: center;
  }
  && .swiper-button-prev {
    left: 30px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }

  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }
`;

const MAX_NUM_OF_TEMPLATES = 16;
const MAX_NUM_OF_SLIDES = 6;
const TEMPLATE_WIDTH = (160 * 16) / 9;
const SPACE_BETWEEN_TEMPLATES = 28;

const TemplatesCollection = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ref = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const flattenRecipes = useAppSelector((state) =>
    recipesSelectors.getSlicedFlattenRecipes(state, MAX_NUM_OF_TEMPLATES, currentPage)
  ) as FlatRecipe[];

  const numOfSlides = useMemo(() => {
    if (ref?.current && flattenRecipes.length > 0) {
      // @ts-ignore handels intl formatting with elements issue
      const width = ref?.current?.offsetWidth;
      return Math.floor((width - 100) / (TEMPLATE_WIDTH + SPACE_BETWEEN_TEMPLATES)) || 1;
    }
    return MAX_NUM_OF_SLIDES;
  }, [ref?.current, flattenRecipes.length]);

  const onViewAllTemplates = () => {
    dispatch(analyticsEvents.templatesBrowse({ source: "templateCollection" }));
    navigate(Location.Templates);
  };

  const onReachEnd = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <H1_FlexColumn padding="34px 20px 0 0" width="100%" flex="1 0 auto" ref={ref}>
      <Suspense fallback={<TemplateCollectionSkelaton />}>
        <ConditionalRender condition={!flattenRecipes.length}>
          <TemplateCollectionSkelaton />
        </ConditionalRender>
        <ConditionalRender condition={flattenRecipes.length > 0}>
          <H1_FlexRow
            align="center"
            justify="space-between"
            padding="0 28px 21px 0"
            margin="0 0 0 50px"
          >
            <H1_TextMiddle
              color={theme.mode === ThemeMode.Light ? theme.gray8 : theme.gray5}
              fontWeight={500}
            >
              {formatMessage(homePageMessages.templatesCollection)}
            </H1_TextMiddle>
            <Button size="sm" variant="light" color="primary" onClick={onViewAllTemplates}>
              {formatMessage(homePageMessages.viewAll)}
            </Button>
          </H1_FlexRow>
          <H1_FlexRow
            height="100%"
            width="100%"
            position="relative"
            padding="0 30px 30px 50px"
            overflow="hidden"
          >
            <StyledSwiper
              navigation
              speed={700}
              spaceBetween={28}
              slidesPerView="auto"
              slidesPerGroup={numOfSlides}
              onReachEnd={onReachEnd}
              lazy
            >
              {flattenRecipes.map((recipe: FlatRecipe) => (
                <SwiperSlide key={`${recipe.categoryName}_${recipe.id}`}>
                  <SingleTemplateInCollectionSwiper recipe={recipe} />
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </H1_FlexRow>
        </ConditionalRender>
      </Suspense>
    </H1_FlexColumn>
  );
};

export default TemplatesCollection;
