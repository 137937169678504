import * as filestackClient from "app/services/filestackClient";
import { convertPdfToImages, MAX_FILE_SIZE } from "app/services/filestackClient";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { draftsActions } from "app/store/slices/drafts.slice";
import { Draft, FilestackPolicy, FoldersContext, PresentationTextType } from "app/types";
import { addPolicyToUrlsIfFileStackMedia, fetchingStatus } from "app/utils/helpers";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import * as Sentry from "@sentry/react";
import useErrors from "app/hooks/useErrors";
import { useIntl } from "react-intl";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";

interface UsePresentationToDraftProps {
  autoImport?: boolean;
  autoCreate?: boolean;
  draftCreate: boolean;
}
const UsePresentationToDraft = (options: UsePresentationToDraftProps = { draftCreate: true }) => {
  const [textType, setTextType] = useState<PresentationTextType>(PresentationTextType.Slides);
  const [progress, setProgress] = useState(0);
  const [presentationFileStackUrl, setPresentationFileStackUrl] = useState("");
  const [file, setFile] = useState<File>();
  const [parsedImages, setParsedImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { notifyError } = useErrors();

  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const createDraftLoading = createDraftStatus === fetchingStatus.loading;

  useEffect(() => {
    if (file && options.autoImport) {
      executeImport();
    }
  }, [file, options.autoImport]);

  useEffect(() => {
    if (parsedImages.length && options.autoCreate) {
      createPresentation(textType);
    }
  }, [parsedImages, options.autoCreate]);

  const onSelectFile = (selectedFile: File) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      notifyError({
        general: buildGeneralError(
          `File size can't be larger than ${MAX_FILE_SIZE / 1024 / 1024}MB`,
          intl
        )
      });
      setFile(undefined);
      return false;
    }
    setFile(selectedFile);
    return true;
  };

  const createPresentation = async (presentationType?: PresentationTextType) => {
    let slidesNumber = 0;
    setLoading(true);
    try {
      const client = filestackClient?.getClient("upload");

      slidesNumber = parsedImages.length;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const splittedName = file!.name.split(".");
      const ext = splittedName[splittedName.length - 1] || "";
      const fileName = splittedName.slice(0, -1).join(".");
      const mediaRequest = await Promise.all(
        parsedImages.map(async (image, index) => {
          const storeResult = (await client?.storeURL(image, {
            filename: `${fileName} ${index + 1}.${ext}`
          })) as {
            handle: string;
            filename: string;
            size: number;
            type: string;
          };

          await filestackClient.createImageThumbnail(filestackPolicy, storeResult.handle as string);
          const url = `https://cdn.filestackcontent.com/${storeResult.handle}`;

          return { url, handle: storeResult.handle };
        })
      );
      dispatch(
        mediaActions.createMediaBulkRequest({ bulk: mediaRequest, context: FoldersContext.media })
      );

      if (options.draftCreate) {
        const presentationUrls = mediaRequest.map((media) => media.url);
        const draft: Draft = {
          type: "presentation",
          title: `Presentation-${file?.name}`,
          description: "",
          presentation_assets: presentationUrls,
          presentation_type: presentationType,
          presentation_url: presentationFileStackUrl
        };
        dispatch(draftsActions.createDraftRequest({ draft }));
        dispatch(analyticsEvents.mediaUploaderPresentation({ status: "success", slidesNumber }));
      }
    } catch (err) {
      console.error("error", err);
      Sentry.captureException(err, {
        extra: { description: "user failed upload images " }
      });
      dispatch(analyticsEvents.mediaUploaderPresentation({ status: "fail", slidesNumber }));
      dispatch(
        analyticsEvents.somethingWentWrongEvent({
          message: "Failed process presentation"
        })
      );
      notifyError({ general: buildGeneralError("Failed process presentation", intl) });
    }
    setLoading(false);
  };
  const executeImport = async () => {
    const slidesNumber = 0;
    try {
      setLoading(true);
      setError(false);
      if (file && file.size > MAX_FILE_SIZE) {
        notifyError({
          general: buildGeneralError(
            `File size can't be larger than ${MAX_FILE_SIZE / 1024 / 1024}MB`,
            intl
          )
        });
        setFile(undefined);
        return;
      }
      const { payload: uploadPolicy } = await dispatch(
        mediaActions.getMediaPolicyRequest("upload")
      );
      filestackClient.init(uploadPolicy as FilestackPolicy, "upload");
      const client = filestackClient?.getClient("upload");
      const result = await client?.upload(file as File, {
        onProgress: (evt) => {
          if (evt?.totalPercent) {
            // progress until -1 , 1 is for the work latter
            setProgress(evt.totalPercent - 1);
          }
        },
        progressInterval: 500
      });

      const convertJobResult = await convertPdfToImages(filestackPolicy, result.handle);
      setPresentationFileStackUrl(result.url);

      if (convertJobResult.results.doc_to_images.error) {
        throw new Error(
          `Failed convert presentation to images: ${convertJobResult.results.doc_to_images.error}`
        );
      }

      const images = convertJobResult.results.doc_to_images.assets.map(
        (result: { url: string }) => result.url
      );

      const imagesWithSignature = addPolicyToUrlsIfFileStackMedia(images, filestackPolicy);
      setParsedImages(imagesWithSignature);
      if (!options.autoCreate) {
        setLoading(false);
      }
    } catch (err: any) {
      console.error("error", err);
      Sentry.captureException(err, {
        extra: { description: "user failed import presentation" }
      });
      dispatch(analyticsEvents.mediaUploaderPresentation({ status: "fail", slidesNumber }));
      dispatch(
        analyticsEvents.somethingWentWrongEvent({
          message: "Failed process presentation"
        })
      );
      notifyError({ general: buildGeneralError("Failed process presentation", intl) });
      setLoading(false);
      setError(true);
    }
  };

  const reset = () => {
    setFile(undefined);
    setParsedImages([]);
    setProgress(0);
    setLoading(false);
    setTextType(PresentationTextType.Slides);
    setError(false);
  };

  return {
    file,
    reset,
    onSelectFile,
    executeImport,
    importLoading: loading || createDraftLoading,
    createPresentation,
    parsedImages,
    progress,
    setTextType,
    textType,
    error
  };
};

export default UsePresentationToDraft;
