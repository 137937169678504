import lodash from "lodash-es";
import { AppDispatch, RootState } from "app/store/store";

export const thunkOptions = {
  serializeError: (error: any) => {
    if (lodash.get(error, "message")) {
      if (error?.config && error?.config.headers) {
        delete error.config.headers.Authorization;
      }
      return {
        message: error.message,
        stack: error.stack,
        status: error.status,
        originalError: error
      };
    }
    return error;
  }
};
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export default {
  thunkOptions
};

export type ThunkApi = {
  dispatch: AppDispatch;
  state: RootState;
  serializedErrorType: { status?: number };
};
