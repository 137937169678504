import { useAppDispatch } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { SocialMedia } from "app/types";
import * as Sentry from "@sentry/react";
import Config from "app/config/Config";

const useShare = () => {
  const dispatch = useAppDispatch();

  const shareSocialMedia = (
    socialMedia: SocialMedia,
    video: { req_id: string; id: string; status: string },
    source: string,
    isFirstPublished: boolean
  ) => {
    const currentReqId: string | undefined = video.req_id;
    const url = `${Config.ogPlayerUrl}/${currentReqId}`;
    const socialMediaTitle = `Video%20made%20by%20HourOne.ai`;

    dispatch(
      googleEvents.shareVideoViaSocial({
        source,
        videoId: video?.id as string,
        req_id: video?.req_id as string,
        socialMedia,
        isFirstPublished
      })
    );
    let socialMediaUrl = "";
    switch (socialMedia) {
      case SocialMedia.twitter:
        socialMediaUrl = `https://twitter.com/intent/tweet?url=${url}&hashtags=hourone`;
        break;
      case SocialMedia.facebook:
        socialMediaUrl = `https://www.facebook.com/sharer.php?u=${url}&quote=${socialMediaTitle}`;
        break;
      case SocialMedia.linkedin:
        socialMediaUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${socialMediaTitle}`;
        break;
      case SocialMedia.whatsapp:
        socialMediaUrl = `https://wa.me/?text=${socialMediaTitle}%20${url}`;
        break;
      case SocialMedia.telegram:
        socialMediaUrl = `https://telegram.me/share/url?url=${url}&title=${socialMediaTitle}`;
        break;
      default:
        Sentry.captureException(`Invalid social media - ${socialMedia}`);
        return;
    }

    window.open(socialMediaUrl, "_blank");
  };

  return { shareSocialMedia };
};

export default useShare;
