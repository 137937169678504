import { defineMessages } from "react-intl";

export const editorLexicalNotificationsMessages = defineMessages({
  textTooLong: {
    id: `editor.scene.lexical.notifications.textTooLong`,
    defaultMessage: "Script too long. Shorten to {limit} characters"
  },
  splitOffer: {
    id: `editor.scene.lexical.notifications.splitOffer`,
    defaultMessage: "or split to scenes"
  },
  splitOfferAccept: {
    id: `editor.scene.lexical.notifications.splitOffer.button.accept`,
    defaultMessage: "Split"
  },
  splitOfferReject: {
    id: `editor.scene.lexical.notifications.splitOffer.button.reject`,
    defaultMessage: "No"
  },
  illegalCharacter: {
    id: `editor.scene.lexical.notifications.illegalCharacter`,
    defaultMessage: "Please remove <link>invalid</link> characters from your script {chars}"
  },
  illegalCharacterRemove: {
    id: `editor.scene.lexical.notifications.illegalCharacter.button.label`,
    defaultMessage: "Remove"
  }
});
