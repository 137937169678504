import React, { useState } from "react";
import * as workosApiCalls from "./workosApiCalls";
import { trackEvent } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { useCookies } from "react-cookie";
import { inhouseStoredCookies } from "app/services/cookie-manager";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "app/types";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { Button } from "@nextui-org/react";

const messages = defineMessages({
  buttonTitle: {
    id: `sso-button.text`,
    defaultMessage: "Log in with SSO"
  },
  error: {
    id: `sso-button.error`,
    defaultMessage: "Something went wrong, reach out to support if the issue persists"
  }
});

const StyledError = styled.div`
  color: red;
`;
const SsoButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const [cookies] = useCookies(inhouseStoredCookies);
  const { formatMessage } = useIntl();

  const ssoEnabled = flags[FeatureFlag.sso];

  const onLogin = async () => {
    try {
      setError(false);
      setLoading(true);
      const result = await workosApiCalls.createSso();
      workosApiCalls.saveSsoCodeVerifierStorage(result.code_verifier);
      dispatch(
        trackEvent({
          eventName: "app:user_login",
          properties: { provider: "sso", hubspotToken: cookies.hubspotutk }
        })
      );
      window.location.replace(result.url);
    } catch (err: any) {
      workosApiCalls.cleanSessionStorage();
      dispatch(
        trackEvent({
          eventName: "app:user_loginFailed",
          properties: { provider: "sso", error: err.message }
        })
      );
      console.error(err);
      setError(err);
      setLoading(false);
    }
  };

  if (!ssoEnabled) {
    return null;
  }
  return (
    <H1_FlexColumn align={"center"}>
      <Button fullWidth color="primary" isLoading={loading} variant="light" onClick={onLogin}>
        {formatMessage(messages.buttonTitle)}
      </Button>
      <ConditionalRender condition={error}>
        <StyledError>{formatMessage(messages.error)}</StyledError>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default SsoButton;
