import { Skeleton } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import SkeletonButton from "antd/es/skeleton/Button";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

import styled from "styled-components";

const StyledSkeletonButton = styled(SkeletonButton)`
  &&&.ant-skeleton.ant-skeleton-block {
    width: ${({ $width }: { $width?: string }) => $width || "100%"};
  }
  &&&.ant-skeleton-element .ant-skeleton-button {
    height: ${({ $height }: { $height?: string; $borderRadius?: string; $width?: string }) =>
      $height};
    width: ${({ $width }: { $width?: string }) => $width};
    border-radius: ${({ $borderRadius }: { $borderRadius?: string }) => $borderRadius};
  }
`;

const TableSkeletonRow = () => {
  return (
    <H1_FlexRow height="5.3125rem" flex="0 0 5.3125rem" gap="1.25rem" padding="1.25rem 0 0 0">
      <StyledSkeletonButton
        $width="7rem"
        $height="3.75rem"
        active
        block
        size="large"
        $borderRadius="5px"
      />
      <H1_FlexRow padding="1.25rem 0 0 0" width="calc(60% - 7rem)">
        <Skeleton loading active paragraph={{ rows: 0 }} />
      </H1_FlexRow>
      <H1_FlexRow padding="1.25rem 0 0 0" width="20%">
        <Skeleton loading active paragraph={{ rows: 0 }} />
      </H1_FlexRow>
      <H1_FlexRow padding="1.25rem 0 0 0" width="20%">
        <Skeleton loading active paragraph={{ rows: 0 }} />
      </H1_FlexRow>
    </H1_FlexRow>
  );
};

const PageTableSkeleton = () => {
  return (
    <H1_FlexColumn padding="0 1.875rem 0 0">
      <H1_FlexRow padding="2.5rem 0 0 0">
        <Skeleton loading active paragraph={{ rows: 0, width: 100 }} title={{ width: "100%" }} />
      </H1_FlexRow>
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
    </H1_FlexColumn>
  );
};

export default PageTableSkeleton;
