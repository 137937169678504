import { GridChildComponentProps, VariableSizeGrid } from "react-window";
import React, { useCallback, useMemo, useState } from "react";
import { Character } from "app/types/character";
import { FeatureFlag } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { mobileBannerMessages } from "app/components/editor/sideDrawers/EditingCharacterDiscover/messages";
import { AnimatePresence, motion } from "framer-motion";
import CharacterDrawerSingle from "app/components/editor/sideDrawers/CharacterDrawer/CharacterDrawerSingle";
import { useFlags } from "launchdarkly-react-client-sdk";
import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Card, CardBody, CardFooter } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const YourCharacterIcon = styled.i`
  font-size: 28px;
  color: ${({ theme }) => theme.gray7};
`;

const WidthTextSmall = styled(H1_TextSmall)`
  max-width: 100%;
  align-self: center;
`;

const BackgroundCardFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray2 : theme.gray3)};
  border-radius: 0 0 16px 16px;
`;
const StyledCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.gray3};
  width: 141px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  &&&:hover {
    --tw-shadow: var(--nextui-box-shadow-small);
    --tw-shadow-colored: var(--nextui-box-shadow-small);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
  &&&:active {
    --tw-shadow: var(--nextui-box-shadow-none);
    --tw-shadow-colored: var(--nextui-box-shadow-none);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
`;

const BoxCharacterFlexColumn = styled(H1_FlexColumn)<{ $selected?: boolean; $greyedOut?: boolean }>`
  border-radius: 10px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  opacity: ${(props) => props.$greyedOut && "50%"};
  &:active {
    transform: scale(0.97);
  }
  z-index: 500;
  /* In order to show the menu above other characters */
  &:has(.ant-select-open) {
    z-index: 501;
  }
`;

interface CharacterDrawerElementProps {
  characterAssetKey: string;
  voiceAssetKey: string;
  height: number;
  query: string;
  isSwitchingAvatar: boolean;
}
const CharacterDrawerElement = ({
  voiceAssetKey,
  characterAssetKey,
  height,
  query,
  isSwitchingAvatar
}: CharacterDrawerElementProps) => {
  const [animatedItems, setAnimatedItems] = useState<boolean>(true);
  const flags = useFlags();

  const { openModal } = useModal();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const currentTemplate = useAppSelector(CharacterSelectors.template);
  const filters = useAppSelector((state) => state.characters.filters);
  const filteredCharacters = useAppSelector((state) =>
    CharacterSelectors.getCharactersList(state, characterAssetKey, query, true)
  );
  const isGestureSelected = !!useAppSelector((state) => state.characters.gestureSourceId);
  const rowCount = useMemo(
    () =>
      Math.ceil(
        (filteredCharacters.length + (flags[FeatureFlag.mobileQr] && !isGestureSelected ? 1 : 0)) /
          2
      ),
    [filteredCharacters.length, isGestureSelected]
  );

  const onDisabledAnimation = () => {
    setAnimatedItems(false);
    setTimeout(() => {
      setAnimatedItems(true);
    }, 500);
  };

  const openMobileModal = () => {
    openModal(ModalName.createVirtualTwin);
  };
  const renderRow = useCallback(
    ({ rowIndex, columnIndex, style }: Partial<GridChildComponentProps>) => {
      if (rowIndex === undefined || columnIndex === undefined) {
        return null;
      }
      const characterIndex = rowIndex * 2 + columnIndex;

      if (characterIndex === 0 && flags[FeatureFlag.mobileQr] && !isGestureSelected) {
        return (
          <div
            style={{
              ...style,
              display: "flex",
              alignItems: "center"
            }}
          >
            <BoxCharacterFlexColumn
              flex="1"
              justify="space-between"
              align="center"
              height="186px"
              padding="10px 7px"
              gap="10px"
              width="141px"
            >
              <StyledCard shadow="none" isPressable onClick={openMobileModal}>
                <CardBody className="overflow-visible p-0 static">
                  <BackgroundCardFlexRow height="121px" justify="center" align="center">
                    <YourCharacterIcon className="fas fa-user-plus" />
                  </BackgroundCardFlexRow>
                </CardBody>
                <CardFooter className="px-2 py-0 h-11">
                  <H1_FlexRow height="44px" align="center">
                    <WidthTextSmall textAlign="left" color={theme.gray7}>
                      {formatMessage(mobileBannerMessages.yourCharacter)}
                    </WidthTextSmall>
                  </H1_FlexRow>
                </CardFooter>
              </StyledCard>
            </BoxCharacterFlexColumn>
          </div>
        );
      }

      // Adjust index to account for the mobile QR feature. Otherwise, first character will always be hidden
      const adjustedIndex =
        flags[FeatureFlag.mobileQr] && !isGestureSelected ? characterIndex - 1 : characterIndex;
      const character: Character = filteredCharacters[adjustedIndex];
      if (!character) {
        return null;
      }
      const isTemplateSupported =
        currentTemplate?.character_types && character.type
          ? currentTemplate?.character_types?.includes(character.type)
          : true;
      return (
        <AnimatePresence key={character.id}>
          <motion.div
            style={{
              ...style,
              display: "flex",
              alignItems: "center",
              marginLeft: characterIndex % 2 === 1 ? "14px" : ""
            }}
            layout={animatedItems}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
              default: {
                duration: animatedItems ? 0.5 : 0
              }
            }}
          >
            <CharacterDrawerSingle
              voiceAssetKey={voiceAssetKey}
              characterAssetKey={characterAssetKey}
              characterId={character.id as string}
              isTemplateSupported={isTemplateSupported}
              onDisableAnimation={onDisabledAnimation}
              isSwitchingAvatar={isSwitchingAvatar}
            />
          </motion.div>
        </AnimatePresence>
      );
    },
    [filteredCharacters.length, query, filters, isGestureSelected]
  );

  return (
    <VariableSizeGrid
      style={{ overflow: "hidden auto" }}
      height={height}
      width={334}
      rowCount={rowCount}
      columnCount={2}
      rowHeight={() => 186}
      columnWidth={() => 155}
    >
      {renderRow}
    </VariableSizeGrid>
  );
};

export default React.memo(CharacterDrawerElement);
