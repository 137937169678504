import styled, { css } from "styled-components";

const Title = styled.span`
  font-family: lobster;
  color: ${(props) => props.theme.pink4};
  ${({ $color, $fontSize }: { $color: string | undefined; $fontSize: string | undefined }) => css`
    color: ${$color as string};
    font-size: ${$fontSize || "inherit"};
  `};
`;

export interface LabelProps {
  color?: string;
  fontSize?: string;
  className?: string;
  children?: React.ReactNode;
}

const LobsterText = ({ color, fontSize, className, children }: LabelProps) => {
  return (
    <Title $color={color} $fontSize={fontSize} className={className}>
      {children}
    </Title>
  );
};
export default LobsterText;
