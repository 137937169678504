import { Progress } from "antd";

import styled, { useTheme } from "styled-components";
import { useAppSelector } from "app/hooks";
import * as videosSelector from "app/store/selectorsV2/videos.selectors";
import { useIntl } from "react-intl";
import { VideoStatusEnum } from "app/types";
import messages from "app/pages/EditDraftPage/messages";
import useModal, { ModalName } from "app/hooks/useModal";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const StyledProgress = styled(Progress)`
  .ant-progress-outer {
    padding-right: 0;
  }
`;

const FastIcon = styled.i`
  animation-duration: 1s;
  color: ${({ theme }) => theme.gray10};
`;

const StyledFlexColumn = styled(H1_FlexColumn)`
  padding: 5px 11px 5px 17px;
  height: 50px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.gray2};
  }
  &:active {
    background-color: ${(props) => props.theme.gray3};
  }
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100px;
    span:first-of-type {
      display: none;
    }
  }
`;

const videoProgressTranslations = {
  not_started: messages.renderingVideoCreating,
  processing: messages.renderingVideoCreating,
  ready: messages.renderingVideoReady,
  published: messages.renderingVideoReady,
  failed: messages.renderingVideoFail,
  cancelled: messages.renderingVideoCanceled
};

const VideoProgress = () => {
  const { formatMessage } = useIntl();
  const { openModal, editorModalOpen } = useModal();
  const theme = useTheme();
  const lastVideo = useAppSelector(videosSelector.getLastVersionVideo);
  const lastVideoProgress = lastVideo?.progress || 0;
  const lastVideoStatus = lastVideo?.status;
  const lastVideoNotStarted = lastVideoStatus === VideoStatusEnum.NotStarted;

  const onVideoProgressClick = () => {
    if (lastVideoProgress && lastVideoStatus === VideoStatusEnum.Ready) {
      openModal(ModalName.videoReady, { reqId: lastVideo.id });
    } else if (lastVideoProgress && lastVideoStatus === VideoStatusEnum.Failed) {
      openModal(ModalName.videoFailed, { videoId: lastVideo.id });
    } else if (lastVideoProgress && !editorModalOpen?.open) {
      openModal(ModalName.createVideoModalV2, { videoId: lastVideo.id });
    }
  };

  if (lastVideoProgress === 100 || !(lastVideoProgress || lastVideoNotStarted)) {
    return null;
  }
  return (
    <>
      <StyledFlexColumn
        flex="1 1 50px"
        width="215px"
        onClick={onVideoProgressClick}
        justify="space-between"
        data-auto-id="video-progress"
      >
        <H1_FlexRow justify="space-between">
          <div>
            <FastIcon className="fa-regular fa-spinner-third fa-spin" />
          </div>
          <H1_TextXs lineHeight="20px" fontSize="10px" color={theme.gray10}>
            {formatMessage(videoProgressTranslations[lastVideoStatus as VideoStatusEnum])}
          </H1_TextXs>
          <H1_TextXs lineHeight="20px" fontSize="10px" color={theme.gray10}>
            {lastVideoProgress}%
          </H1_TextXs>
        </H1_FlexRow>
        <H1_FlexRow>
          <StyledProgress
            trailColor={theme.gray5}
            strokeColor={theme.blue4}
            percent={lastVideoProgress}
            format={() => null}
            strokeWidth={10}
          />
        </H1_FlexRow>
      </StyledFlexColumn>
    </>
  );
};

export default VideoProgress;
