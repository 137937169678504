import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Divider, Modal } from "antd";
import PreviewPanel from "app/components/editor/sideDrawers/PreviewPanel";
import { videosActions } from "app/store/slices/videos.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { DraftRevertType, Video } from "app/types";
import RenderingContent from "app/components/editor/sideDrawers/RenderPanel/RenderingContent";
import videosSelectors from "app/store/selectorsV2/videos.selectors";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import TitleFlexRow from "app/components/editor/sideDrawers/CommonComponents/TitleFlexRow";
import { messages } from "app/components/editor/sideDrawers/messages";
import { useIntl } from "react-intl";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { draftsActions } from "app/store/slices/drafts.slice";
// region [[ Styles ]]

/* todo add format js
 * todo consider to split export and render status to two drawers / or two drawer full body
 *
 * */

const PanelDivider = styled(Divider)`
  border-color: #ececec;
`;

const PaddingFlexColumn = styled(H1_FlexColumn)`
  padding-right: 20px;
`;

interface RenderPanelProps {
  width: string;
  right: string;
}

const RenderPanelDrawer = ({ right, width }: RenderPanelProps) => {
  const [selectedVideoId, setSelectedVideoId] = useState<string | undefined>();
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const { editorDrawerOpen, openDrawer } = useDrawer();
  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const videos = useAppSelector(videosGlobalSelectors.selectAll);
  const selectedVideo = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, selectedVideoId as string)
  );
  const readyToPlayVideos = useAppSelector(videosSelectors.getReadyToPlayVideos);

  const isDrawerVisible = editorDrawerOpen === Drawer.Versions;

  useEffect(() => {
    if (draft && isDrawerVisible) {
      dispatch(
        videosActions.getVideosRequest({
          draftId: draft.id as string,
          pagination: { limit: 30, page: 1 }
        })
      );
    }
    if (!isDrawerVisible) {
      setSelectedVideoId(undefined);
    }
  }, [draft, isDrawerVisible]);

  useEffect(() => {
    if (!selectedVideoId && videos.length) {
      setSelectedVideoId(videos[0].id);
    }
  }, [videos]);

  const onExport = (video: Video) => {
    openDrawer(Drawer.Export, {
      video,
      draftId: draft.id,
      backDrawer: Drawer.Versions
    });
  };
  const onRevert = (video: Video) => {
    return Modal.confirm({
      title: formatMessage(messages.restoreModalTitle),
      content: formatMessage(messages.restoreModalDescription),
      onOk: () => {
        dispatch(
          draftsActions.revertDraftRequest({
            draftId: draft.id as string,
            body: {
              draft_history_id: video.versioned_draft_id,
              type: DraftRevertType.draft_history
            }
          })
        );
      }
    });
  };

  return (
    <FlexDrawer right={right} active={isDrawerVisible} width={width}>
      <TitleFlexRow height="52px">{formatMessage(messages.renderingProgress)}</TitleFlexRow>
      <PaddingFlexColumn>
        <PreviewPanel
          selectedVideo={selectedVideo}
          onChange={(video) => setSelectedVideoId(video.id)}
          videos={readyToPlayVideos}
        />
        <PanelDivider />

        <RenderingContent
          selectedVideo={selectedVideo}
          videos={videos}
          onVideoSelect={setSelectedVideoId}
          onExport={onExport}
          onRevert={onRevert}
        />
      </PaddingFlexColumn>
    </FlexDrawer>
  );
};
export default RenderPanelDrawer;
