import { createSlice } from "@reduxjs/toolkit";
import { Channel, FetchStatus, Invite, Member, Workspace } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import asyncThunks from "app/store/thunks/workspaces.thunk";
import { workspaceAdapter } from "app/store/adapters/adapters";
import { uniqBy } from "lodash-es";

interface workspacesState {
  workspacesStatus: FetchStatus;
  createWorkspacesStatus: FetchStatus;
  inviteMembersStatus: FetchStatus;
  updateWorkspaceNameStatus: FetchStatus;
  removeMembersStatus: FetchStatus;
  getInvitesStatus: FetchStatus;
  getUserInvitesStatus: FetchStatus;
  submitInvitesStatus: FetchStatus;
  getWorkspaceByIdStatus: FetchStatus;
  ackInvitesStatus: FetchStatus;
  invitations: Invite[];
  reviewInvites?: Member[];
  createChannelStatus: FetchStatus;
  removeChannelStatus: FetchStatus;
  getChannelsStatus: FetchStatus;
  getChannelsSearchStatus: FetchStatus;
  changeWorkspaceRoleStatus: FetchStatus;
  changeWorkspaceRoleUserId?: string;
  channels: Channel[];
  channelsSearch: Channel[];
}

const INITIAL_STATE: workspacesState = {
  workspacesStatus: fetchingStatus.idle as FetchStatus,
  createWorkspacesStatus: fetchingStatus.idle as FetchStatus,
  inviteMembersStatus: fetchingStatus.idle as FetchStatus,
  updateWorkspaceNameStatus: fetchingStatus.idle as FetchStatus,
  removeMembersStatus: fetchingStatus.idle as FetchStatus,
  getInvitesStatus: fetchingStatus.idle as FetchStatus,
  getUserInvitesStatus: fetchingStatus.idle as FetchStatus,
  changeWorkspaceRoleStatus: fetchingStatus.idle as FetchStatus,
  submitInvitesStatus: fetchingStatus.idle as FetchStatus,
  ackInvitesStatus: fetchingStatus.idle as FetchStatus,
  getWorkspaceByIdStatus: fetchingStatus.idle as FetchStatus,
  invitations: [],
  createChannelStatus: fetchingStatus.idle as FetchStatus,
  removeChannelStatus: fetchingStatus.idle as FetchStatus,
  getChannelsStatus: fetchingStatus.idle as FetchStatus,
  getChannelsSearchStatus: fetchingStatus.idle as FetchStatus,
  channels: [],
  channelsSearch: []
};

export const workspacesSlice = createSlice({
  name: "Workspaces",
  initialState: workspaceAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    cleanWorkspaces: (state) => {
      workspaceAdapter.removeAll(state);
      state.workspacesStatus = fetchingStatus.idle;
      return state;
    },
    updateCreateWorkspaceStatusToIdle: (state) => {
      state.createWorkspacesStatus = fetchingStatus.idle;
    },
    upsertWorkspace: (state, action) => {
      state.createWorkspacesStatus = fetchingStatus.idle;
      workspaceAdapter.upsertOne(state, action.payload);
    },
    updateWorkspaceNameStatusToIdle: (state) => {
      state.updateWorkspaceNameStatus = fetchingStatus.idle;
    },
    cleanChangeWorkspaceRole: (state) => {
      state.changeWorkspaceRoleStatus = fetchingStatus.idle;
      state.changeWorkspaceRoleUserId = undefined;
    },
    updateGetInvitesStatusToIdle: (state) => {
      state.getInvitesStatus = fetchingStatus.idle;
    },
    updateRemoveMembersStatusToIdle: (state) => {
      state.removeMembersStatus = fetchingStatus.idle;
    },
    updateGetWorkspaceByIdStatusToIdle: (state) => {
      state.getWorkspaceByIdStatus = fetchingStatus.idle;
    },
    updateGetUserInvitesStatusToIdle: (state) => {
      state.getUserInvitesStatus = fetchingStatus.idle;
    },
    removeAllInvitations: (state) => {
      state.invitations = [];
    },
    updateGetChannelsStatusToIdle: (state) => {
      state.getChannelsStatus = fetchingStatus.idle;
    },
    removeWorkspaceFromList: (state, action) => {
      workspaceAdapter.removeOne(state, action.payload);
    },
    setChannels: (state, action) => {
      state.channels = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getWorkspacesRequest.pending, (state) => {
      state.workspacesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getWorkspacesRequest.rejected, (state) => {
      state.workspacesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getWorkspacesRequest.fulfilled, (state, action) => {
      state.workspacesStatus = fetchingStatus.succeeded as FetchStatus;
      workspaceAdapter.setAll(state, action.payload);
    });

    builder.addCase(asyncThunks.createWorkspaceRequest.pending, (state) => {
      state.createWorkspacesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createWorkspaceRequest.rejected, (state) => {
      state.createWorkspacesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createWorkspaceRequest.fulfilled, (state, action) => {
      state.createWorkspacesStatus = fetchingStatus.succeeded as FetchStatus;
      workspaceAdapter.addOne(state, action.payload);
    });

    builder.addCase(asyncThunks.inviteMembersToWorkspaceRequest.pending, (state) => {
      state.inviteMembersStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.inviteMembersToWorkspaceRequest.rejected, (state) => {
      state.inviteMembersStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.inviteMembersToWorkspaceRequest.fulfilled, (state, action) => {
      state.inviteMembersStatus = fetchingStatus.succeeded as FetchStatus;
      const { workspaceId } = action.meta.arg;
      if (state.entities[workspaceId] && action.payload.length > 0) {
        const { members } = state.entities[workspaceId] as Workspace;
        workspaceAdapter.updateOne(state, {
          id: workspaceId,
          changes: { members: [...members, ...action.payload] }
        });
      }
    });

    builder.addCase(asyncThunks.removeMembersFromWorkspaceRequest.pending, (state, action) => {
      state.removeMembersStatus = fetchingStatus.loading as FetchStatus;
      const { workspaceId, members } = action.meta.arg;
      members.forEach(({ email }: Pick<Member, "email">) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.entities[workspaceId]!.members = state.entities[workspaceId]!.members.filter(
          (currentMember) => currentMember.email !== email
        );
      });
    });
    builder.addCase(asyncThunks.removeMembersFromWorkspaceRequest.rejected, (state) => {
      state.removeMembersStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.removeMembersFromWorkspaceRequest.fulfilled, (state) => {
      state.removeMembersStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.updateWorkspacePropertiesRequest.pending, (state, action) => {
      state.updateWorkspaceNameStatus = fetchingStatus.loading as FetchStatus;
      const { workspaceId, workspace } = action.meta.arg;
      if (workspaceId && workspace) {
        workspaceAdapter.updateOne(state, { id: workspaceId, changes: workspace });
      }
    });
    builder.addCase(asyncThunks.updateWorkspacePropertiesRequest.rejected, (state) => {
      state.updateWorkspaceNameStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.updateWorkspacePropertiesRequest.fulfilled, (state) => {
      state.updateWorkspaceNameStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.getUserInvitesRequest.pending, (state) => {
      state.getUserInvitesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getUserInvitesRequest.rejected, (state) => {
      state.getUserInvitesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getUserInvitesRequest.fulfilled, (state, action) => {
      state.getUserInvitesStatus = fetchingStatus.succeeded as FetchStatus;
      state.invitations = action.payload || [];
    });

    builder.addCase(asyncThunks.submitInviteRequest.pending, (state, action) => {
      state.submitInvitesStatus = fetchingStatus.loading as FetchStatus;
      const { inviteId } = action.meta.arg;
      state.invitations = state.invitations.filter((invite) => invite.id !== inviteId);
    });
    builder.addCase(asyncThunks.submitInviteRequest.rejected, (state) => {
      state.submitInvitesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.submitInviteRequest.fulfilled, (state, action) => {
      state.submitInvitesStatus = fetchingStatus.succeeded as FetchStatus;
      const { workspaceId } = action.meta.arg;
      if (workspaceId && action.payload) {
        workspaceAdapter.addOne(state, action.payload);
      }
    });

    builder.addCase(asyncThunks.reviewInviteRequest.pending, (state, action) => {
      state.submitInvitesStatus = fetchingStatus.loading as FetchStatus;
      const { inviteId } = action.meta.arg;
      state.invitations = state.invitations.filter((invite) => invite.id !== inviteId);
    });
    builder.addCase(asyncThunks.reviewInviteRequest.rejected, (state) => {
      state.submitInvitesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.reviewInviteRequest.fulfilled, (state) => {
      state.submitInvitesStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.ackAcceptedInvitationsRequest.pending, (state) => {
      state.ackInvitesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.ackAcceptedInvitationsRequest.rejected, (state) => {
      state.ackInvitesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.ackAcceptedInvitationsRequest.fulfilled, (state) => {
      state.ackInvitesStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.getWorkspaceByIdRequest.pending, (state) => {
      state.getWorkspaceByIdStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getWorkspaceByIdRequest.rejected, (state) => {
      state.getWorkspaceByIdStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getWorkspaceByIdRequest.fulfilled, (state, action) => {
      state.getWorkspaceByIdStatus = fetchingStatus.succeeded as FetchStatus;
      const { members } = action.payload;
      const { workspaceId } = action.meta.arg;
      if (workspaceId && members?.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.entities[workspaceId]!.members = members;
      }
    });

    builder.addCase(asyncThunks.createChannelRequest.pending, (state) => {
      state.createChannelStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createChannelRequest.rejected, (state) => {
      state.createChannelStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createChannelRequest.fulfilled, (state, action) => {
      state.createChannelStatus = fetchingStatus.succeeded as FetchStatus;
      state.channels = [...action.payload.channels, ...state.channels];
      state.channels = uniqBy(state.channels, "name");
    });

    builder.addCase(asyncThunks.removeChannelRequest.pending, (state, action) => {
      state.removeChannelStatus = fetchingStatus.loading as FetchStatus;
      const { name } = action.meta.arg;
      state.channels = state.channels.filter((channel) => channel.name !== name);
    });
    builder.addCase(asyncThunks.removeChannelRequest.rejected, (state) => {
      state.removeChannelStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.removeChannelRequest.fulfilled, (state) => {
      state.removeChannelStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.getChannelsRequest.pending, (state) => {
      state.getChannelsStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getChannelsRequest.rejected, (state) => {
      state.getChannelsStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getChannelsRequest.fulfilled, (state, action) => {
      state.getChannelsStatus = fetchingStatus.succeeded as FetchStatus;
      state.channels = action.payload.channels;
    });
    builder.addCase(asyncThunks.getChannelsSearchRequest.pending, (state) => {
      state.getChannelsSearchStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getChannelsSearchRequest.rejected, (state) => {
      state.getChannelsSearchStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getChannelsSearchRequest.fulfilled, (state, action) => {
      state.getChannelsSearchStatus = fetchingStatus.succeeded as FetchStatus;
      state.channelsSearch = action.payload.channels;
    });
    builder.addCase(asyncThunks.changeWorkspaceRoleRequest.pending, (state, action) => {
      state.changeWorkspaceRoleStatus = fetchingStatus.loading as FetchStatus;
      state.changeWorkspaceRoleUserId = action.meta.arg.userId;
    });
    builder.addCase(asyncThunks.changeWorkspaceRoleRequest.rejected, (state) => {
      state.changeWorkspaceRoleStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.changeWorkspaceRoleRequest.fulfilled, (state, action) => {
      state.changeWorkspaceRoleStatus = fetchingStatus.succeeded as FetchStatus;
      const { userId, workspaceId, role } = action.meta.arg;

      const members = state.entities[workspaceId]?.members;
      if (members) {
        const memberIndex = members.findIndex((member) => member.user_id === userId);
        members[memberIndex].role = role;
        workspaceAdapter.updateOne(state, { id: workspaceId, changes: { members: [...members] } });
      }
    });
  }
});

export default workspacesSlice.reducer;

export const workspacesActions = {
  getWorkspacesRequest: asyncThunks.getWorkspacesRequest,
  createWorkspaceRequest: asyncThunks.createWorkspaceRequest,
  inviteMembersToWorkspaceRequest: asyncThunks.inviteMembersToWorkspaceRequest,
  removeMembersFromWorkspaceRequest: asyncThunks.removeMembersFromWorkspaceRequest,
  updateWorkspacePropertiesRequest: asyncThunks.updateWorkspacePropertiesRequest,
  getUserInvitesRequest: asyncThunks.getUserInvitesRequest,
  submitInviteRequest: asyncThunks.submitInviteRequest,
  reviewInviteRequest: asyncThunks.reviewInviteRequest,
  ackAcceptedInvitationsRequest: asyncThunks.ackAcceptedInvitationsRequest,
  getWorkspaceByIdRequest: asyncThunks.getWorkspaceByIdRequest,
  getChannelsRequest: asyncThunks.getChannelsRequest,
  createChannelRequest: asyncThunks.createChannelRequest,
  removeChannelRequest: asyncThunks.removeChannelRequest,
  getChannelsSearchRequest: asyncThunks.getChannelsSearchRequest,
  changeWorkspaceRoleRequest: asyncThunks.changeWorkspaceRoleRequest,
  ...workspacesSlice.actions
};
