import { AppMetadata, PlanEnum } from "app/types";

const MAX_NUMBER_OF_SCENES = 5;

export const videoWizardPlaceholdersList = [
  "A Warm Welcome to Jane, Our New Marketing Specialist",
  "Introducing John Smith, Our Talented HR Manager",
  "Employee Spotlight, Celebrating Lisa Brown’s Achievements",
  "Introducing our latest app, PhotoMorph, revolutionizing image transformations",
  "Boosting Brand Awareness with Our Latest Social Media Campaign about dog shampoo",
  "Promote sustainable products in a casual tone to eco-conscious customers",
  "Market online fitness program with upbeat, motivational tone to health-conscious individuals"
];

export const getNumberOfScenesOptions = (currentPlan: PlanEnum, appData: AppMetadata) => {
  let scenesNum: number;
  switch (currentPlan) {
    case PlanEnum.onBoarding:
      scenesNum = appData.limits?.scenes || Math.min(MAX_NUMBER_OF_SCENES, 6);
      break;
    case PlanEnum.personal:
      scenesNum = appData.limits?.scenes || Math.min(MAX_NUMBER_OF_SCENES, 6);
      break;
    case PlanEnum.business:
      scenesNum = MAX_NUMBER_OF_SCENES;
      break;
    default:
      scenesNum = MAX_NUMBER_OF_SCENES;
  }
  const numbersArray = Array.from({ length: scenesNum + 1 }, (value, index) => index);
  return numbersArray;
};
