import { Button, Card, CardBody, CardFooter, CardHeader, Image } from "@nextui-org/react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { integrationsMessages } from "app/pages/IntegrationsPage/messages";
import { useIntl } from "react-intl";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { ThemeMode } from "app/utils/theme";

const ImageContainer = styled(H1_FlexRow)`
  border: 1px solid ${({ theme }) => theme.gray7}33;
  border-radius: 50%;
`;
const StyledCard = styled(Card)`
  width: 335px;
  height: 173px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray3)};
`;
const StyledButton = styled(Button)<{ isDisabled?: boolean }>`
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.gray4 : theme.gray1)};
  border: 1px solid ${({ theme }) => theme.gray7}33;
  color: ${({ theme }) => theme.gray7};
`;
const StyledCardHeader = styled(CardHeader)`
  width: 335px;
  height: 40px;
  padding-bottom: 0;
  display: flex;
  gap: 12px;
`;
const StyledCardBody = styled(CardBody)`
  padding-top: 8px;
  height: 40px;
  overflow: hidden;
`;
interface IntegrationsCardProps {
  image: string;
  imageSize?: string;
  headline: string;
  content: string;
  buttonText?: string;
  disabled?: boolean;
  onClick: () => void;
}

const IntegrationsCard = ({
  image,
  imageSize = "24px",
  headline,
  content,
  buttonText,
  disabled = false,
  onClick
}: IntegrationsCardProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  return (
    <StyledCard>
      <StyledCardHeader>
        <ImageContainer
          width="36px"
          height="36px"
          justify="center"
          align="center"
          flex="0 0 36px"
          padding="6px"
        >
          <Image height={imageSize} radius="sm" src={image} width={imageSize} />
        </ImageContainer>
        <H1_FlexColumn>
          <H1_TextSubtitle fontSize="18px" fontWeight={500} color={theme.gray7}>
            {headline}
          </H1_TextSubtitle>
        </H1_FlexColumn>
      </StyledCardHeader>
      <StyledCardBody>
        <H1_TextSmall whiteSpace="normal" color={`${theme.gray7}99`}>
          {content}
        </H1_TextSmall>
      </StyledCardBody>
      <CardFooter>
        <StyledButton isDisabled={disabled} radius="lg" fullWidth onClick={onClick}>
          {buttonText || formatMessage(integrationsMessages.cardButtonText)}
        </StyledButton>
      </CardFooter>
    </StyledCard>
  );
};

export default IntegrationsCard;
