import React, { forwardRef, MutableRefObject, ReactNode, useImperativeHandle, useRef } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import useErrors from "app/hooks/useErrors";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import { useIntl } from "react-intl";
import { fileUploaderMessages } from "app/components/common/fileUploaderMessages";

type FileUploaderProps = {
  multiple: boolean;
  onFilesSelected: (files: File[]) => void;
  fileTypes?: string;
  children: ReactNode;
  disabled?: boolean;
  maxSizeInMB?: number;
  height?: string;
};

const HiddenFileInput = styled("input")`
  display: none;
`;
const FileUploader = forwardRef(
  (
    {
      multiple,
      onFilesSelected,
      fileTypes,
      children,
      disabled,
      maxSizeInMB,
      height
    }: FileUploaderProps,
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const { notifyError } = useErrors();
    const { formatMessage } = useIntl();

    useImperativeHandle(ref, () => ({
      onClick
    }));

    const handleFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);
      let isSizeOk = true;
      if (maxSizeInMB) {
        isSizeOk = Array.from(files).every((file) => file.size < maxSizeInMB * 1000 * 1000);
      }
      if (!isSizeOk) {
        notifyError({
          general: buildGeneralMessage(
            formatMessage(fileUploaderMessages.tooLargeTitle),
            formatMessage(fileUploaderMessages.tooLargeDescription)
          )
        });
        return;
      }
      if (files && fileTypes) {
        const filteredFiles = files.filter((file) => {
          const fileType = file.type;
          return fileTypes.includes(fileType);
        });

        onFilesSelected(filteredFiles);
      } else if (files.length) {
        onFilesSelected(files);
      }
    };

    const onClick = (e: React.MouseEvent) => {
      if (disabled) {
        return;
      }
      if (innerRef && (innerRef as MutableRefObject<HTMLInputElement>).current) {
        (innerRef as MutableRefObject<HTMLInputElement>).current.click();
      }
      e.stopPropagation();
      e.preventDefault();
    };

    return (
      <>
        <HiddenFileInput
          id="fileInput"
          type="file"
          ref={innerRef}
          multiple={multiple}
          accept={fileTypes}
          onChange={handleFilesSelected}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <H1_FlexColumn height={height} onClick={onClick}>
          {children}
        </H1_FlexColumn>
      </>
    );
  }
);

FileUploader.displayName = "FileUploader";

export default FileUploader;
