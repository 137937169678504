import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { StyledModal } from "app/components/common/StyledModal";

const ZendeskFeedbackModal = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
  return (
    <StyledModal
      className="zendesk-feedback-modal"
      width="320px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "160px" }}
      onCancel={onClose}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="31px 38px 45px"
      $alignItems="center"
    />
  );
};

export default ZendeskFeedbackModal;
