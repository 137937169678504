import DeleteFolderModal from "app/pages/FoldersPage/DeleteFolderModal";
import React, { useEffect } from "react";
import styled from "styled-components";
import { TablePaginationConfig } from "antd";
import DraftsTable from "app/components/common/DraftsAndFolders/DraftsTable";
import { RecoverBulkDraftRequest } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { trashActions } from "app/store/slices/trash.slice";
import { TrashType } from "app/types/trash";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button } from "@nextui-org/react";
import { useIntl } from "react-intl";
import { TrashPageMessages } from "app/pages/Trash/messages";
import { draftsActions } from "app/store/slices/drafts.slice";
import { DraftsTableSkeleton } from "app/pages/HomePage/HomePageSkeletonLoading";
import EmptyState from "app/components/common/EmptyState";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray1};
`;

const TrashPage = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const trashStatus = useAppSelector((state) => state.trash.trashStatus);
  const selectedDraftIds = useAppSelector((state) => state.drafts.selectedDraftIds);
  const allDrafts = useAppSelector(draftsGlobalSelectors.selectAll);
  const isLoading = trashStatus === fetchingStatus.loading;

  useEffect(() => {
    if (trashStatus === fetchingStatus.succeeded || trashStatus === fetchingStatus.failed) {
      dispatch(trashActions.setTrashItemByTypeStatusToIdle());
    }
  }, [trashStatus]);

  useEffect(() => {
    dispatch(trashActions.getTrashItemByTypeRequest(TrashType.draft));
  }, []);

  const onClickRecoverDraft = () => {
    const recoverDrafts = selectedDraftIds.map((id): RecoverBulkDraftRequest => ({ id }));
    dispatch(draftsActions.recoverDraftRequest(recoverDrafts));
  };
  const onClickDeleteForever = () => {
    dispatch(draftsActions.cleanDraftRequest(selectedDraftIds));
  };

  if (isLoading) {
    return <DraftsTableSkeleton />;
  }

  return (
    <BackgroundFlexColumn padding="1rem 0 1.875rem 1.875rem" flex="1">
      <H1_FlexColumn gap="2.125rem" flex="1">
        <H1_FlexColumn gap="0.5rem" flex="1">
          <ConditionalRender condition={allDrafts.length > 0}>
            <H1_FlexRow gap="10px" flex="0 0 auto" justify="flex-end" padding="0 30px 0 0 ">
              <Button
                color="primary"
                onClick={onClickRecoverDraft}
                isDisabled={selectedDraftIds.length === 0}
              >
                {formatMessage(TrashPageMessages.recoverDraft)}
              </Button>
              <Button
                color="danger"
                onClick={onClickDeleteForever}
                isDisabled={selectedDraftIds.length === 0}
              >
                {formatMessage(TrashPageMessages.cleanDraft)}
              </Button>
            </H1_FlexRow>
          </ConditionalRender>
          <H1_FlexColumn overflow="auto" flex="1 1 auto" padding="0 1.875rem 0 0">
            <DraftsTable
              clickable={false}
              hideOptions
              hideEmptyState
              withMultipleSelectCheckbox={true}
              pagination={{ position: ["bottomCenter"], size: "default" } as TablePaginationConfig}
            />
            <ConditionalRender condition={allDrafts.length === 0}>
              <EmptyState
                text={formatMessage(TrashPageMessages.emptyFolderText)}
                description={formatMessage(TrashPageMessages.emptyFolderDescription)}
                icon="fal fa-trash"
              />
            </ConditionalRender>
          </H1_FlexColumn>
        </H1_FlexColumn>
      </H1_FlexColumn>
      <DeleteFolderModal />
    </BackgroundFlexColumn>
  );
};

export default TrashPage;
