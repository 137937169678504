import { Navigate, Route } from "react-router-dom";
import Login from "app/pages/login/Login";
import SignupSuccess from "app/pages/login/SignupSuccess";
import Player from "app/pages/player/Player";
import playerMessages from "app/pages/player/messages";
import TitleComponent from "app/hoc/WithHeaderTitle";
import { SentryRoutes } from "app/services/sentry";
import FullTemplatesPage from "app/pages/Templates/FullTemplatesPage";
import React from "react";
import WorkflowSubmissionPage from "app/pages/WorkflowSubmissionPage/WorkflowSubmissionPage";
import MsLandingPage from "app/pages/MsLandingPage/MsLandingPage";

// eslint-disable-next-line react/display-name
const NotAuthenticatedRouter = React.memo(() => {
  return (
    <SentryRoutes>
      <Route path="/">
        <Route path="" element={<Login />} />
        <Route path="signupSuccess" element={<SignupSuccess />} />
        <Route path="templates" element={<FullTemplatesPage />} />
        <Route path="ms-marketplace" element={<MsLandingPage />} />
        <Route
          path="player/:reqId"
          element={
            <>
              <Player />
              <TitleComponent intlDef={playerMessages.playerHeadline} />
            </>
          }
        />
        <Route
          path="workflows/:workflowId"
          element={
            <>
              <WorkflowSubmissionPage />
              {/*<TitleComponent intlDef={playerMessages.playerHeadline} />*/}
            </>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </SentryRoutes>
  );
});

export default NotAuthenticatedRouter;
