import React, { useEffect } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useIntl } from "react-intl";
import { useAppDispatch } from "app/hooks";
import {
  H1_TextLargeHeadline,
  H1_TextMiddle,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import { mobilePromptFinishMessages } from "app/pages/HomePage/messages";
import { Location } from "app/types";
import { useNavigate } from "react-router-dom";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useUpgradeToEnterprise } from "app/hooks/useUpgradeModal";
import { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";

const MobileFinishPage = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const upgradeToEnterprise = useUpgradeToEnterprise();

  useEffect(() => {
    dispatch(
      analyticsEvents.mobileFinishPage({
        action: "initial"
      })
    );
  }, []);

  const onClickBookDemo = () => {
    dispatch(
      analyticsEvents.mobileFinishPage({
        action: "bookDemo"
      })
    );
    if (window) {
      const source = "upgrade_mobile_on_boarding";
      upgradeToEnterprise({ source });
    }
  };

  const onClickPricing = () => {
    dispatch(
      analyticsEvents.mobileFinishPage({
        action: "pricing"
      })
    );
    navigate(Location.Pricing);
  };

  return (
    <H1_FlexColumn gap="10px" justify="center" align="center">
      <H1_TextMiddle color={theme.orange4}>
        {formatMessage(mobilePromptFinishMessages.finishTitle)}
      </H1_TextMiddle>
      <H1_TextLargeHeadline
        fontWeight={500}
        whiteSpace="normal"
        textAlign="center"
        color={theme.gray11}
      >
        {formatMessage(mobilePromptFinishMessages.finishTitleBig)}
      </H1_TextLargeHeadline>
      <H1_FlexRow padding="10px 0 34px">
        <H1_TextSmall whiteSpace="normal" textAlign="center">
          {formatMessage(mobilePromptFinishMessages.finishSubTitle)}
        </H1_TextSmall>
      </H1_FlexRow>
      <iframe
        title="video"
        src="https://player.vimeo.com/video/810557834?h=000f1c83e2&color=12324b&title=0&byline=0&portrait=0"
        width="calc(180px * 16 / 9)"
        height="180"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
      <H1_FlexRow padding="36px 0 11px">
        <H1_TextMiddle color={theme.gray11} whiteSpace="normal" fontWeight={500}>
          {formatMessage(mobilePromptFinishMessages.finishLowerTitle)}
        </H1_TextMiddle>
      </H1_FlexRow>
      <H1_FlexRow gap="17px">
        <Button onClick={onClickBookDemo} variant="bordered" color="primary">
          {formatMessage(mobilePromptFinishMessages.finishBookDemoButton)}
        </Button>
        <Button color="primary" onClick={onClickPricing}>
          {formatMessage(mobilePromptFinishMessages.finishPricingButton)}
        </Button>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default MobileFinishPage;
