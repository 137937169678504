import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { onImageError } from "app/utils/helpers";
import { Tooltip } from "antd";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { MediaFileType, Video, VideoResolution, VideoStatusEnum } from "app/types";
import React from "react";
import styled, { useTheme } from "styled-components";
import ShareVideoMenu from "app/components/common/ShareVideoMenu";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppSelector } from "app/hooks";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import { ModalName } from "app/hooks/useModal";
import useCopyLink from "app/hooks/useCopyLink";
import useDownload from "app/hooks/useDownload";

const ButtonsFlexColumn = styled(H1_FlexColumn)`
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 15px;
`;

const StyledShareVideoMenu = styled(ShareVideoMenu)`
  && {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
  }
  padding: 0;
  width: 40px;
  height: 40px;
  justify-content: center;

  i {
    color: ${({ theme }) => theme.gray11};
  }

  &:hover {
    i {
      color: ${({ theme }) => theme.gray11};
    }
  }
`;
const IconContainer = styled(H1_FlexRow)`
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.gray4};
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
  }
  &:active {
    box-shadow: none;
    background-color: ${({ theme }) => theme.gray2};
  }
`;

const Image = styled.img`
  border-radius: 5px;
  width: 100%;
  flex: 1;
`;

const VideoPreview = styled("div")`
  width: 100%;
  background-color: transparent;
  aspect-ratio: 16 / 9;
  display: flex;
  & > * {
    flex: 1;
  }
`;
const SOURCE = "workflow_player";
interface WorkflowPlayerProps {
  videoId?: string;
}
const WorkflowPlayer = ({ videoId }: WorkflowPlayerProps) => {
  const { copyLink } = useCopyLink();
  const theme = useTheme();
  const { downloadVideo } = useDownload({ resolution: VideoResolution["1080p"] });

  const video = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, videoId as string)
  );
  const readyToPlay =
    video?.status === VideoStatusEnum.Ready || video?.status === VideoStatusEnum.Published;

  const onClickCopyLink = () => {
    copyLink(SOURCE, video as Video, video?.draft_id);
  };

  const onDownload = () => {
    downloadVideo({
      source: SOURCE,
      video: video as Video,
      fileType: MediaFileType.mp4,
      backModal: ModalName.downloadVideo,
      draftId: video?.draft_id as string
    });
  };
  return (
    <H1_FlexColumn align={"center"} flex={"1"} position="relative">
      <ConditionalRender condition={readyToPlay}>
        <VideoPreview>
          <MuxPlayer reqId={video?.req_id as string} />
        </VideoPreview>
      </ConditionalRender>
      <ConditionalRender condition={!readyToPlay}>
        <Image
          src={video?.thumbnail_url || fallbackPlaceholder}
          alt="video thumbnail"
          onError={onImageError}
        />
      </ConditionalRender>
      <ConditionalRender condition={readyToPlay}>
        <ButtonsFlexColumn gap="10px">
          <Tooltip title="Download" placement="left">
            <IconContainer onClick={onDownload}>
              <H1_Icon color={theme.gray11} isCursorPointer icon="fas fa-arrow-down" size="14px" />
            </IconContainer>
          </Tooltip>
          <Tooltip title="Copy link" placement="left">
            <IconContainer onClick={onClickCopyLink}>
              <H1_Icon color={theme.gray11} isCursorPointer icon="fas fa-link" size="14px" />
            </IconContainer>
          </Tooltip>
          <Tooltip title="Share" placement="left">
            <StyledShareVideoMenu
              isNextUI
              icon={<H1_Icon isCursorPointer icon="fas fa-paper-plane-top" size="14px" />}
              withText={false}
              video={video as Video}
              source={SOURCE}
            />
          </Tooltip>
        </ButtonsFlexColumn>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default WorkflowPlayer;
