import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import { ThemeMode } from "app/utils/theme";

const grow = keyframes`
  0% { max-height: var(--lineHeight); }
  100% { max-height: calc(var(--lineHeight) * var(--lines)); }
`;

const carriageReturn = keyframes`
  0% { top: 0; }
  100% { top: calc(var(--lineHeight) * var(--lines)); }
`;

const type = keyframes`
  0% { width: 100%; }
  100% { width: 0%; }
`;

const caret = keyframes`
  0% { color: var(--bgColor); }
  100% { color: black; }
`;

interface AnimatedTextProps {
  $animatedTextPaddingRight: number;
  $fontFamily: string;
  $fontSize: string;
  $lineHeight: string;
  $backgroundColor: string;
  $visible: boolean;
}

export const AnimatedText = styled.p`
  margin-bottom: 0;
  font-size: ${(props: AnimatedTextProps) => props.$fontSize};
  font-family: ${(props: AnimatedTextProps) => props.$fontFamily};
  position: absolute;
  padding-right: ${(props: AnimatedTextProps) => `${props.$animatedTextPaddingRight}px`};
  opacity: ${(props: { $visible: boolean }) => (props.$visible ? 1 : 0)};
  z-index: ${(props: { $visible: boolean }) => (props.$visible ? 2 : -1)};
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-out;
  --bgColor: ${(props: AnimatedTextProps) => props.$backgroundColor};
  --lines: 500;
  --lineHeight: ${(props: AnimatedTextProps) => props.$lineHeight};
  --timePerLine: 1.2s;
  --widthCh: 100%;
  --width: calc(var(--widthCh) * 1ch);
  /* do not touch the time property!!! */
  --time: calc(var(--lines) * var(--timePerLine));
  animation: ${grow} var(--time) steps(var(--lines));
  animation-fill-mode: none;
  background: var(--bgColor);
  color: ${({ theme }) => theme.gray11};
  line-height: var(--lineHeight);
  max-height: 100%;
  overflow: hidden;
  width: var(--width);
  font-weight: 400;
  animation-play-state: ${({ $visible }) => ($visible ? "running" : "paused")};

  &::before {
    content: "";
    animation: ${type} var(--timePerLine) linear infinite,
      ${carriageReturn} var(--time) steps(var(--lines)) var(--lines),
      ${caret} 0.5s steps(2) infinite;
    animation-play-state: ${({ $visible }) => ($visible ? "running" : "paused")};
    background: var(--bgColor);
    border-left: 10px solid;
    bottom: 0;
    height: 100%;
    position: absolute;
    right: 0;
    width: 100%;
  }
  &.stop-caret-animation {
    &::before {
      border-left: 0 solid;
    }
  }
  &.stop-animation {
    animation: none;
    &::before {
      animation: none;
    }
  }
`;

interface TypeTextAnimationParagraphProps {
  animatedTextPaddingRight?: number;
  active: boolean;
  isLoading: boolean;
  text: string;
  onFinishAnimation: () => void;
  fontFamily?: string;
  fontSize?: string;
  lineHeight?: string;
  backgroundColor?: string;
}

const TypeTextAnimationParagraph = ({
  animatedTextPaddingRight = 0,
  active,
  isLoading,
  text,
  onFinishAnimation,
  fontFamily = "Inter",
  fontSize = "0.9375rem",
  lineHeight = "1.375rem"
}: TypeTextAnimationParagraphProps) => {
  const [isLastLine, setIsLastLine] = useState<boolean>(false);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const theme = useTheme();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (active) {
      // It takes some time until the component size is being reflected, and the 0.3s transition finishes
      timeoutId = setTimeout(() => {
        const height: number = paragraphRef.current?.parentElement?.clientHeight || 0;
        const lineHeightNumber: number = parseInt(lineHeight, 10);

        setTimeout(() => {
          setIsLastLine(true);
        }, (height / lineHeightNumber - 1) * 1200); // lineHeightNumber is a single line height, each line animation time is 1.2 second
        setTimeout(() => {
          clearTimeout(timeoutId);
          onFinishAnimation();
          setIsLastLine(false);
        }, (height / lineHeightNumber) * 1200); // lineHeightNumber is a single line height, each line animation time is 1.2 second
      }, 300);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [active]);

  return (
    <AnimatedText
      $animatedTextPaddingRight={animatedTextPaddingRight}
      $fontFamily={fontFamily}
      $fontSize={fontSize}
      $lineHeight={lineHeight}
      $backgroundColor={theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2}
      ref={paragraphRef}
      $visible={active}
      className={`${isLoading ? "stop-animation" : ""} ${isLastLine ? "stop-caret-animation" : ""}`}
    >
      {text}
    </AnimatedText>
  );
};

export default TypeTextAnimationParagraph;
