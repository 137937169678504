import styled from "styled-components";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button/button";

const PurpleButtonStyled = styled(Button)<{ $width?: string }>`
  background: ${(props) => props.theme.pink4};
  border: unset;
  &:hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.pink4};
    border: unset;
  }
  svg {
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$width};
`;
export interface CustomButton {
  width?: string;
}
const PurpleButton = ({ width, ...rest }: ButtonProps & CustomButton) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PurpleButtonStyled type="primary" $width={width} {...rest} />;
};
export default PurpleButton;
