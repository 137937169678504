import { Helmet as ReactHelmet, HelmetProps as ReactHelmetProps } from "react-helmet";
import { useIntl } from "react-intl";
import Config from "app/config/Config";
import { defineMessages } from "react-intl/node_modules/@formatjs/intl";

const messsages = defineMessages({
  description: {
    id: "header.metatag.description",
    defaultMessage: "Create your own videos with real syntactic characters"
  },
  siteNmae: {
    id: "header.metatag.siteName",
    defaultMessage: "HourOne AI"
  }
});

interface TitleComponentProps {
  title?: string;
  intlDef?: {
    id: string;
    defaultMessage: string;
    values?: Record<string, any> | void;
  };
}

interface HelmetProps extends ReactHelmetProps {
  children: React.ReactNode[];
}

const Helmet = (props: HelmetProps) => <ReactHelmet {...props} />;
const TitleComponent = ({ title, intlDef }: TitleComponentProps) => {
  const intl = useIntl();
  const siteName = intl.formatMessage(messsages.siteNmae);
  const titleStr = intlDef ? intl.formatMessage(intlDef) : title;
  const description = intl.formatMessage(messsages.description);
  return (
    <Helmet defaultTitle={siteName} titleTemplate={`${siteName} - %s`}>
      <title>{titleStr}</title>
      <base target="_blank" href={siteName} />
      <meta name="description" content={description} />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={siteName} />
      <meta itemProp="description" content={description} />

      <meta property="og:title" content={titleStr} />
      <meta property="og:site_name" content={titleStr} />
      <meta property="og:url" content={Config.clientUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:determiner" content="the" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="video.other" />

      <meta name="twitter:card" content="player" />
      <meta name="twitter:site" content="@HourOneAI" />
      <meta name="twitter:title" content={titleStr} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

TitleComponent.defaultProps = {
  title: "HourOne AI",
  intlDef: null
};

export default TitleComponent;
