import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { Channel } from "pusher-js";
import { CreditsType, CreditsReportMsg } from "app/types/pusherMessages";

import { userActions } from "app/store/slices/user.slice";
import { useAppDispatch } from "app/hooks";
const PusherCreditsHandler = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  useEffect(() => {
    let channel: Channel;
    const handleLLMReport = (message: CreditsReportMsg) => {
      const { credits_type } = message;

      if (credits_type === CreditsType.LLM) {
        dispatch(userActions.setCreditsWarningMsg(message));
      }
    };
    if (user?.sub) {
      const userId = user.sub.split("|")[1];
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("credits_spent_event", handleLLMReport);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherCreditsHandler;
