import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";

import ConditionalRender from "app/components/common/ConditionalRender";
import { FeatureFlag, MediaCategory, Scene, Template } from "app/types";
import { validateAudioPreview } from "app/components/editor/validations";
import useErrors from "app/hooks/useErrors";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import styled from "styled-components";
import { Button } from "@nextui-org/react";

const StyledButton = styled(Button)`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  min-width: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.gray1};
    filter: brightness(90%);
  }
  &:active {
    background-color: ${({ theme }) => theme.gray1};
    box-shadow: none;
  }
`;
interface ScreenCaptureButtonProps {
  className?: string;
  sceneId: string;
  assetKey: string;
  attributeType: MediaCategory;
  disabled?: boolean;
  hideText?: boolean;
  variant: string;
  icon: React.ReactNode;
}

const messages = defineMessages({
  useScreenRecording: {
    id: "screen-capture.use-screen-recording",
    defaultMessage: "Screen capture"
  },
  useScreenRecordingDisabledDescription: {
    id: "screen-capture.use-screen-disabled-description",
    defaultMessage: "Screen capture is disabled for chosen layout, try another layout"
  },
  title: {
    id: "screen-capture.title",
    defaultMessage: "Screen Capture"
  }
});

const ScreenCaptureButton = ({
  className,
  attributeType,
  assetKey,
  sceneId,
  disabled,
  hideText = false,
  variant,
  icon
}: ScreenCaptureButtonProps) => {
  const intl = useIntl();
  const { openModal } = useModal();
  const { notifyError } = useErrors();
  const limits = useAppSelector(getLimits);

  const dispatch = useAppDispatch();

  const featureFlags = useAppSelector((state) => state.user.featureFlags);
  const scene = useAppSelector((state) =>
    scenesGlobalSelectors.selectById(state, sceneId)
  ) as Scene;
  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const currentTemplate = useAppSelector((state) => state.templates.currentTemplate);

  const isLayoutSupportMedia = useMemo(() => {
    return !!Object.keys((scene?.layout?.assets?.media || scene?.layout?.assets?.visual) ?? [])
      .length;
  }, [scene]);

  const onShareScreen = () => {
    const { errors } = validateAudioPreview(
      draft,
      [scene],
      intl,
      limits,
      currentTemplate as Template
    );
    if (Object.keys(errors).length) {
      notifyError(errors);
    } else {
      dispatch(
        analyticsEvents.screenRecord({
          status: "open"
        })
      );
      openModal(ModalName.screenRecording, { sceneId, assetKey, attributeType });
    }
  };

  return (
    <ConditionalRender condition={featureFlags[FeatureFlag.screenCaptureFeature]}>
      <ConditionalRender condition={hideText}>
        <StyledButton
          color="primary"
          variant={variant}
          className={className}
          isDisabled={!isLayoutSupportMedia || disabled}
          onClick={onShareScreen}
          startContent={icon}
          isIconOnly
        >
          {!hideText && intl.formatMessage(messages.title)}
        </StyledButton>
      </ConditionalRender>
      <ConditionalRender condition={!hideText}>
        <Button
          isDisabled={!isLayoutSupportMedia || disabled}
          onClick={onShareScreen}
          startContent={icon}
        >
          {!hideText && intl.formatMessage(messages.title)}
        </Button>
      </ConditionalRender>
    </ConditionalRender>
  );
};

export default ScreenCaptureButton;
