/* eslint-disable camelcase */

import { PlanFeature } from "app/config/planFeature";

export declare class User {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
}

export const PRICING_PAGE_SOURCE = "pricingPage";
export const DEEP_LINK_SOURCE = "deeplink";
interface HubspotDescription {
  form_id: string;
  portal_id: string;
}

export enum MFATypes {
  totp = "totp"
}
export interface MFAMetadata {
  id: string;
  type: MFATypes;
  secret?: string;
  qrCode?: string;
}
export interface UserMetadata {
  linkedin?: string;
  website?: string;
  termsAgreed?: boolean;
  pending?: boolean;
  is_enterprise?: boolean;
  journey?: string | null;
  hubspot?: Record<string, boolean>;
  use_case_selection?: string;
}

export type SubscriptionPlan =
  | "basic_monthly"
  | "standard_monthly"
  | "pro_monthly"
  | "enterprise_monthly"
  | "basic_yearly"
  | "standard_yearly"
  | "pro_yearly"
  | "enterprise_yearly"
  | "on-boarding"
  | "converted";

export enum SubscriptionPlanEnum {
  basic_monthly = "basic_monthly",
  personal_monthly = "personal_monthly",
  business_monthly = "business_monthly",
  standard_monthly = "standard_monthly",
  pro_monthly = "pro_monthly",
  enterprise_monthly = "enterprise_monthly",
  basic_yearly = "basic_yearly",
  personal_yearly = "personal_yearly",
  business_yearly = "business_yearly",
  standard_yearly = "standard_yearly",
  pro_yearly = "pro_yearly",
  enterprise_yearly = "enterprise_yearly",
  on_boarding = "on-boarding"
}

export interface PlanAndPriceModel {
  id: string;
  subscription_plan: SubscriptionPlanEnum;
  price: number;
  price_per_month: number;
  discount_title: string;
  publish_credits: number;
}

export interface SubscriptionResults {
  subscriptionId?: string;
  secret?: string;
  isCoupon?: boolean;
  paymentStatus?: string;
}

export enum PaymentModalSource {
  editorHeader = "editorHeader",
  logo = "logo",
  colorDrawer = "colorDrawer",
  download = "download",
  publish = "publish",
  videoReady = "videoReady"
}

export enum PaymentModalAction {
  close = "close",
  next = "next",
  back = "back",
  play = "play"
}

export enum PaymentModalPhase {
  CHOOSE_PLAN = "CHOOSE_PLAN",
  INITIAL = "INITIAL",
  PAYMENT_TYPE = "PAYMENT_TYPE",
  CREDIT_CARD_DETAILS = "CREDIT_CARD_DETAILS",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS"
}

export enum PaymentPeriod {
  Yearly = "Yearly",
  Monthly = "Monthly"
}

export enum PlanEnum {
  legacy = "legacy",
  basic = "basic",
  enterprise = "enterprise",
  pro = "pro",
  advanced = "advanced",

  // these are updated:
  onBoarding = "on-boarding",
  personal = "personal",
  business = "business"
}

export enum PlanToUpgrade {
  "on-boarding" = "on-boarding",
  personal = "personal",
  business = "business",
  enterprise = "enterprise"
}
export interface MetadataLimits {
  scenes: number;
  chars_per_scene: number;
}
export interface AppMetadata {
  plan?: PlanEnum;
  subscription_plan?: SubscriptionPlan;
  verified?: boolean;
  limits?: MetadataLimits;
  hubspot?: Record<string, HubspotDescription>;
}
// is it used?
export interface UserData {
  created_at: string;
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  updated_at: string;
  user_metadata: UserMetadata;
  app_metadata: AppMetadata;
  last_ip: string;
  last_login: string;
  logins_count: number;
}

export interface ApiKey {
  id?: string;
  key?: string;
}

export interface InitialQueryParams {
  slack_install?: string;
  plan?: string;
  type?: PaymentPeriod;
  as_client?: string;
  marketplace_ref?: string;
  msMarketplace_ref?: string;
}

export interface Feature {
  name: PlanFeature;
}

export interface FeaturePrice {
  name: PlanFeature;
  price: number;
}

export interface PricingModalProperties {
  numberOfMinutesPosition?: number;
  selectedPlan: PlanToUpgrade;
  phase: PaymentModalPhase;
  paymentPeriod: PaymentPeriod;
  originalPlan?: PlanEnum;
  coupon?: string;
}

export interface CreditsData {
  account: string;
  authorized: number;
  available: number;
  spent: number;
  credits_type: string;
  spent_from_reset: number;
}

export enum FavoriteType {
  character = "character",
  voice = "voice"
}

export interface Favorite {
  asset_id: string;
  asset_type: string;
  active: boolean;
}
