import { MutableRefObject, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ListenStatus, voicesActions } from "app/store/slices/voices.slice";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";

import "app/components/editor/Footer.scss";
import useErrors from "app/hooks/useErrors";
import styled, { css } from "styled-components";
import { extractUserIdForPusher, isMediaPlaying } from "app/utils/helpers";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";

const FooterWrapper = styled.div`
  ${({ isHidden }: { isHidden: boolean }) => css`
    display: ${isHidden ? "none" : "inherit"};
    height: ${isHidden ? 0 : "inherit"};
    max-height: ${isHidden ? 0 : "inherit"};
  `};
`;

interface UpdateMessage {
  // eslint-disable-next-line camelcase
  channel_id: string;
  progress: number;
  // eslint-disable-next-line camelcase
  pair_info: { url?: string; scene_id?: string; order_id?: string };
}

export interface EditingProjectFooterProps {
  isHidden?: boolean;
}

const EditingProjectFooter = ({ isHidden }: EditingProjectFooterProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { notifyError } = useErrors();
  const { user } = useAuth();

  const audioRef = useRef<HTMLAudioElement | null>();
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);

  const currentOrderId = footerAudioPlayer.orderId;
  useEffect(() => {
    const cancelPlayFooterAudioLoading = () => {
      dispatch(voicesActions.turnOffFooterLoading());
    };

    const handleFullPreview = (msg: UpdateMessage) => {
      const currentSceneId = msg.pair_info.scene_id;
      const orderId = msg.pair_info.order_id;
      if (currentOrderId !== orderId) {
        console.warn("got incorrect order id", orderId);
        return;
      }
      if (currentSceneId) {
        dispatch(
          voicesActions.updateFooterAudioPlayer({
            sceneId: currentSceneId,
            orderId
          })
        );
      } else {
        dispatch(
          voicesActions.updateFooterAudioPlayer({
            sceneId: undefined,
            orderId
          })
        );
      }
      if (msg.progress === 100) {
        dispatch(
          voicesActions.updateFooterAudioPlayer({
            url: msg.pair_info.url as string,
            orderId
          })
        );
        cancelPlayFooterAudioLoading();
      } else if (msg.progress === -1) {
        cancelPlayFooterAudioLoading();
        dispatch(
          analyticsEvents.somethingWentWrongEvent({
            message: "Creating audio preview failed"
          })
        );
        notifyError({ general: buildGeneralError("Creating audio preview failed", intl) });
      }
    };

    const userId = extractUserIdForPusher(user?.sub);

    const channelName = `${userId}`;
    const channel = pusher.subscribe(channelName);

    const stopAudioUpdates = () => {
      channel.unbind("full_audio_preview_v2", handleFullPreview);
      cancelPlayFooterAudioLoading();
    };

    const listenForAudioUpdates = () => {
      channel.bind("full_audio_preview_v2", handleFullPreview);
    };

    listenForAudioUpdates();
    return () => {
      stopAudioUpdates();
    };
  }, [currentOrderId]);

  useEffect(() => {
    return () => {
      dispatch(voicesActions.cleanFooterAudio());
      dispatch(voicesActions.cleanVoicePreviewV2());
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (footerAudioPlayer.status === ListenStatus.rewind) {
        if (isMediaPlaying(audioRef.current)) {
          (audioRef.current as HTMLAudioElement).pause();
        }
        audioRef.current.currentTime = 0;
      } else if (footerAudioPlayer.status === ListenStatus.play && footerAudioPlayer.url) {
        audioRef.current.play();
      } else if (
        (footerAudioPlayer.status === ListenStatus.pause ||
          footerAudioPlayer.status === ListenStatus.idle) &&
        isMediaPlaying(audioRef.current)
      ) {
        (audioRef.current as HTMLAudioElement).pause();
      }
    }
  }, [audioRef.current, footerAudioPlayer.status]);

  useEffect(() => {
    if (
      audioRef.current &&
      footerAudioPlayer.status === ListenStatus.canPlay &&
      footerAudioPlayer.autoPlay
    ) {
      audioRef.current.play();
    }
  }, [footerAudioPlayer, audioRef.current]);

  const onPlay = () => {
    dispatch(
      voicesActions.updateFooterAudioPlayer({
        ...footerAudioPlayer,
        status: ListenStatus.playing
      })
    );
  };

  const onCanPlay = () => {
    if (footerAudioPlayer.status !== ListenStatus.playing) {
      // can play can called multiple times on playing - we want only the first time
      dispatch(
        voicesActions.updateFooterAudioPlayer({
          ...footerAudioPlayer,
          status: ListenStatus.canPlay
        })
      );
    }
  };
  const onEnded = () => {
    dispatch(
      voicesActions.updateFooterAudioPlayer({
        status: ListenStatus.idle
      })
    );
  };
  return (
    <FooterWrapper isHidden={isHidden || false}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        src={footerAudioPlayer.url}
        autoPlay={false}
        ref={audioRef as MutableRefObject<HTMLAudioElement>}
        onCanPlay={onCanPlay}
        onPlay={onPlay}
        onEnded={onEnded}
        preload={"auto"}
      />
    </FooterWrapper>
  );
};
export default EditingProjectFooter;
