/* eslint @typescript-eslint/naming-convention: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/jsx-pascal-case: 0 */

import { Text, TextProps } from "./Text";

export enum H1_TextNames {
  xs = "xs",
  small = "small",
  middle = "middle",
  subtitle = "subtitle",
  midHeadline = "midHeadline",
  headline = "headline",
  largeHeadline = "largeHeadline",
  subBanner = "subBanner",
  banner = "banner"
}
export const H1_TextSizes: Record<H1_TextNames, { fontSize: string; lineHeight: string }> = {
  [H1_TextNames.xs]: { fontSize: "0.75rem", lineHeight: "1.25rem" },
  [H1_TextNames.small]: { fontSize: "0.875rem", lineHeight: "1.375rem" },
  [H1_TextNames.middle]: { fontSize: "1rem", lineHeight: "1.5rem" },
  [H1_TextNames.subtitle]: { fontSize: "1.25rem", lineHeight: "1.75rem" },
  [H1_TextNames.midHeadline]: { fontSize: "1.5rem", lineHeight: "2rem" },
  [H1_TextNames.headline]: { fontSize: "1.875rem", lineHeight: "2.375rem" },
  [H1_TextNames.largeHeadline]: { fontSize: "2.375rem", lineHeight: "2.875rem" },
  [H1_TextNames.subBanner]: { fontSize: "2.875rem", lineHeight: "3.375rem" },
  [H1_TextNames.banner]: { fontSize: "3.5rem", lineHeight: "4rem" }
};

// H1_TextXs - font size: 12px, line height: 20px
export const H1_TextXs = (
  props: React.PropsWithChildren<React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.xs].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.xs].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextSmall - font size: 14px, line height: 22px
export const H1_TextSmall = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.small].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.small].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextMiddle - font size: 16px, line height: 24px
export const H1_TextMiddle = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.middle].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.middle].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextSubtitle - font size: 20px, line height: 28px
export const H1_TextSubtitle = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.subtitle].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.subtitle].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextMidHeadline - font size: 24px, line height: 43px
export const H1_TextMidHeadline = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.midHeadline].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.midHeadline].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextHeadline - font size: 30px, line height: 38px
export const H1_TextHeadline = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.headline].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.headline].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextLargeHeadline - font size: 38px, line height: 36px
export const H1_TextLargeHeadline = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.largeHeadline].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.largeHeadline].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextSubBanner - font size: 46px, line height: 54px
export const H1_TextSubBanner = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.subBanner].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.subBanner].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};

// H1_TextBanner - font size: 56px, line height: 64px
export const H1_TextBanner = (
  props: React.PropsWithChildren<Partial<TextProps<HTMLSpanElement>>>
) => {
  const { children } = props;
  return (
    <Text
      fontSize={H1_TextSizes[H1_TextNames.banner].fontSize}
      lineHeight={H1_TextSizes[H1_TextNames.banner].lineHeight}
      {...props}
    >
      {children}
    </Text>
  );
};
