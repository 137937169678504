import { defineMessages } from "react-intl";

// AudioPreviewContent
export const audioPreviewContentMessages = defineMessages({
  createVideoDescription: {
    id: `editor.audio-preview-content.description`,
    defaultMessage: "We recommend to listen to audio before"
  },
  AudioPreviewButton: {
    id: `editor.audio-preview-content.audio-preview-button`,
    defaultMessage: "Audio Preview"
  },
  skipToGenerateVideoButton: {
    id: `editor.audio-preview-content.skip-to-generate-video-button`,
    defaultMessage: "Skip and generate the video"
  }
});

// CreateVideoContent
export const createVideoContentMessages = defineMessages({
  keepEditingButton: {
    id: `editor.create-video-content.keep-editing-button`,
    defaultMessage: "Keep Editing"
  },
  creatingYourVideo: {
    id: `editor.create-video-content.creating-your-video`,
    defaultMessage: `Creating Your <span>Video</span>`
  },
  onlyTakesAMinute: {
    id: `editor.create-video-content.only-takes-a-minute`,
    defaultMessage: `Your video is being generated and will be ready soon.`
  },
  uploadingVideo: {
    id: `editor.create-video-content.uploading-video`,
    defaultMessage: `Rendering video...`
  },
  cancelButton: {
    id: `editor.create-video-content.cancel-button`,
    defaultMessage: `Cancel`
  }
});

// VideoReadyModal
export const videoReadyModalMessages = defineMessages({
  title: {
    id: `editor.video-ready-modal.title`,
    defaultMessage: `Your video is <span>Ready</span>`
  },
  versionNumber: {
    id: `editor.video-ready-modal.version-number`,
    defaultMessage: `Version {number}`
  },
  keepEditingButton: {
    id: `editor.video-ready-modal.keep-editing-button`,
    defaultMessage: "Keep Editing"
  },
  downloadFileButton: {
    id: `editor.video-ready-modal.download-file-button`,
    defaultMessage: "Download"
  },
  copyLinkTooltip: {
    id: `editor.video-ready-modal.copy-link-tooltip`,
    defaultMessage: "Copy link"
  },
  shareTooltip: {
    id: `editor.video-ready-modal.share-tooltip`,
    defaultMessage: "Share"
  },
  exportScormButton: {
    id: `editor.video-ready-modal.download-scorm-button`,
    defaultMessage: "Export SCORM"
  },
  downloadSubtitlesFileAsVTTButton: {
    id: `editor.video-ready-modal.download-subtitles-file-as-vtt-button`,
    defaultMessage: "Export Subtitles (.VTT)"
  },
  downloadSubtitlesFileAsSRTButton: {
    id: `editor.video-ready-modal.download-subtitles-file-as-srt-button`,
    defaultMessage: "Export Subtitles (.SRT)"
  },
  downloadEnglishSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-english-file-button`,
    defaultMessage: "English"
  },
  downloadSpanishSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-spanish-file-button`,
    defaultMessage: "Spanish"
  },
  downloadGermanSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-german-file-button`,
    defaultMessage: "German"
  },
  downloadPortugueseSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-portuguese-file-button`,
    defaultMessage: "Portuguese"
  },
  downloadItalianSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-italian-file-button`,
    defaultMessage: "Italian"
  },
  downloadFrenchSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-french-file-button`,
    defaultMessage: "French"
  },
  downloadDutchSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-Dutch-file-button`,
    defaultMessage: "Dutch"
  },
  downloadJapaneseSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-japanese-file-button`,
    defaultMessage: "Japanese"
  },
  downloadHebrewSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-hebrew-file-button`,
    defaultMessage: "Hebrew"
  },
  downloadChineseSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-chinese-file-button`,
    defaultMessage: "Chinese"
  },
  downloadHindiSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-hindi-file-button`,
    defaultMessage: "Hindi"
  },
  downloadRussianSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-russian-file-button`,
    defaultMessage: "Russian"
  },
  downloadKoreanSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-korean-file-button`,
    defaultMessage: "Korean"
  },
  downloadArabicSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-arabic-file-button`,
    defaultMessage: "Arabic"
  },
  downloadIndonesianSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-indonesian-file-button`,
    defaultMessage: "Indonesian"
  },
  downloadTagalogSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-tagalog-file-button`,
    defaultMessage: "Tagalog"
  },
  downloadSwahiliSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-swahili-file-button`,
    defaultMessage: "Swahili"
  },
  downloadRomanianSubtitlesFileButton: {
    id: `editor.video-ready-modal.download-subtitle-romanian-file-button`,
    defaultMessage: "Romanian"
  },
  buyVideoButton: {
    id: `editor.video-ready-modal.buy-video-button`,
    defaultMessage: "Buy video {amount}"
  },
  buyVideoDescriptionButton: {
    id: `editor.video-ready-modal.buy-video-description-button`,
    defaultMessage: "One time purchase"
  },
  shareButton: {
    id: `editor.video-ready-modal.share-button`,
    defaultMessage: "Share"
  },
  copyLinkButton: {
    id: `editor.video-ready-modal.copy-link-button`,
    defaultMessage: "Copy Link"
  },
  copiedLinkButton: {
    id: `editor.video-ready-modal.copied-link-button`,
    defaultMessage: "Copied"
  },
  embedLink: {
    id: `editor.video-ready-modal.embed-link`,
    defaultMessage: "Embed"
  },
  copyToEmail: {
    id: `editor.video-ready-modal.copy-to-email`,
    defaultMessage: "Embed in email"
  },
  shareToTwitter: {
    id: `editor.video-ready-modal.share-to-twitter`,
    defaultMessage: "Share to Twitter"
  },
  shareToFacebook: {
    id: `editor.video-ready-modal.share-to-facebook`,
    defaultMessage: "Share to Facebook"
  },
  shareToWhatsapp: {
    id: `editor.video-ready-modal.share-to-whatsapp`,
    defaultMessage: "Share to WhatsApp"
  },
  shareToLinkedIn: {
    id: `editor.video-ready-modal.share-to-linked-in`,
    defaultMessage: "Share to LinkedIn"
  }
});

// VideoFailedModal
export const videoFailedModalMessages = defineMessages({
  title: {
    id: `editor.video-failed-modal.title`,
    defaultMessage: `Something went <span>wrong</span>`
  },
  failedRendering: {
    id: `editor.video-failed-modal.failed-rendering`,
    defaultMessage: `Your video failed rendering. Our support team is working on it right now.{br}Please try again later.`
  },
  continueButton: {
    id: `editor.video-failed-modal.continue-button`,
    defaultMessage: "Continue"
  },
  contactSupportLink: {
    id: `editor.video-failed-modal.contact-support-link`,
    defaultMessage: "Contact Support"
  }
});

// RightMenu
export const rightMenuMessages = defineMessages({
  layout: {
    id: `editor.right-menu.layout`,
    defaultMessage: `Layout`
  },
  music: {
    id: `editor.right-menu.background-music`,
    defaultMessage: `Music`
  },
  colors: {
    id: `editor.right-menu.colors`,
    defaultMessage: `Style`
  },
  presenter: {
    id: `editor.right-menu.presenter`,
    defaultMessage: `Avatars`
  },
  avatarAndVoices: {
    id: `editor.right-menu.avatar-and-voices`,
    defaultMessage: `Avatars`
  },
  voices: {
    id: `editor.right-menu.voices`,
    defaultMessage: `Voices`
  },
  images: {
    id: `editor.right-menu.images`,
    defaultMessage: `Media`
  },
  texts: {
    id: `editor.right-menu.texts`,
    defaultMessage: `Titles`
  },
  brand: {
    id: `editor.right-menu.brand`,
    defaultMessage: `Brand`
  },
  video: {
    id: `editor.right-menu.video`,
    defaultMessage: `Video`
  },
  templates: {
    id: `editor.right-menu.templates`,
    defaultMessage: `Template`
  },
  comments: {
    id: `editor.right-menu.comments`,
    defaultMessage: `Comments`
  },
  upgradeComments: {
    id: `editor.right-menu.upgrade-comments`,
    defaultMessage: `Upgrade to use comments`
  },
  new: {
    id: `editor.right-menu.new`,
    defaultMessage: `New`
  },
  intro: {
    id: `editor.right-menu.intro`,
    defaultMessage: `Intro`
  },
  versions: {
    id: `editor.right-menu.versions`,
    defaultMessage: `Versions`
  },
  videoWizard: {
    id: `editor.right-menu.video-wizard`,
    defaultMessage: "Video Wizard"
  },
  scene: {
    id: `editor.right-menu.scene`,
    defaultMessage: `Scene`
  }
});

// LeftMenu
export const leftMenuMessages = defineMessages({
  emptyScenesErrorTitle: {
    id: `editor.left-menu.empty-scenes-error-title`,
    defaultMessage: `Too Many Scenes`
  },
  emptyScenesError: {
    id: `editor.left-menu.empty-scenes-error`,
    defaultMessage: `You've reached the maximum number of scenes`
  },
  duplicateScene: {
    id: `editor.left-menu.duplicate-scene`,
    defaultMessage: `Duplicate Scene`
  },
  deleteScene: {
    id: `editor.left-menu.delete-scene`,
    defaultMessage: `Delete Scene`
  }
});

// Video Modals
export const videoModalsMessages = defineMessages({
  backButton: {
    id: `editor.video-modals.back-button`,
    defaultMessage: `Back`
  },
  downloadThisVideo: {
    id: `editor.video-modals.download-this-video`,
    defaultMessage: `Download This Video`
  },
  fileType: {
    id: `editor.video-modals.file-type`,
    defaultMessage: `File Type`
  },
  fileTypeMp4: {
    id: `editor.video-modals.file-type-mp4`,
    defaultMessage: `MP4 Video`
  },
  fileTypeWebm: {
    id: `editor.video-modals.file-type-webm`,
    defaultMessage: `WebM`
  },
  fileTypeMov: {
    id: `editor.video-modals.file-type-mov`,
    defaultMessage: `MOV`
  },
  highRes: {
    id: `editor.video-modals.high-res`,
    defaultMessage: `Full HD (1080p)`
  },
  mediumRes: {
    id: `editor.video-modals.medium-res`,
    defaultMessage: `HD (720p)`
  },
  downloadButton: {
    id: `editor.video-modals.download-button`,
    defaultMessage: `Download`
  }
});
