import { createAsyncThunk } from "@reduxjs/toolkit";
import { IntlShape } from "react-intl";
import {
  cancelVideoRendering,
  createVideo,
  deleteVideo,
  downloadScormVideo,
  downloadTranscodeVideo,
  downloadVideo,
  fetchVideoProtection,
  getFeaturedVideosRequest,
  getVideoReaction,
  getVideos,
  getVideosByReqId,
  getVideosFeed,
  getVideoSubtitle,
  publishVideo,
  removeVideoProtection,
  searchVideosApi,
  setVideoPasswordProtection,
  translateVideoSubtitles,
  updateVideo,
  updateVideoFeedbackReaction,
  updateVideoRatingReaction
} from "app/services/serviceV2Apis";
import { validateCreateVideo } from "app/components/editor/validations";
import {
  Audience,
  Draft,
  MediaFileType,
  PatchOperation,
  ProtectionType,
  Reactor,
  Scene,
  Template,
  ThunkApi,
  TranslateSubtitlesRequest,
  TranslateSubtitlesResponse,
  Video,
  VideoFeedbackReaction,
  VideoRatingReaction,
  VideoResolution,
  VideoStatusEnum,
  VideoSubtitleResult,
  VideoVariable
} from "app/types";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";

import { thunkOptions, VALIDATION_ERROR } from "app/store/thunks/thunkCommon";
import {
  draftsGlobalSelectors,
  scenesGlobalSelectors,
  videosGlobalSelectors
} from "app/store/adapters/adapters";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { videosActions } from "app/store/slices/videos.slice";
import uiActions from "app/store/actions/ui.actions";
import { pusherMessages } from "app/hoc/Pusher/messages";
import { ModalName } from "app/hooks/useModal";
import { SourceName } from "app/types/realsMe";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { SMALL_SCREEN } from "app/config/Constants";
import { paymentsActions } from "app/store/slices/payments.slice";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";

const prefix = "[Videos]";

const createVideoRequest = createAsyncThunk<
  Video,
  {
    datastore_content_id?: string;
    draftId: string;
    skip402ErrorHandling: boolean;
    skipValidations?: boolean;
    variables?: VideoVariable[];
  },
  ThunkApi
>(
  `${prefix} createVideoRequest`,
  async ({ datastore_content_id, draftId, skipValidations = false, variables }, thunkApi) => {
    const scenes = scenesGlobalSelectors.selectAll(thunkApi.getState());
    const draft = thunkApi.getState().drafts.currentDraft;
    // @ts-ignore handels userUI store typing
    const intl = thunkApi.getState().userUi.intl as IntlShape;
    const limits = getLimits(thunkApi.getState());
    if (!skipValidations) {
      const template = thunkApi.getState().templates.currentTemplate as Template;
      const { errors } = validateCreateVideo(draft, scenes, template, intl, limits);
      if (Object.keys(errors).length) {
        thunkApi.dispatch(
          googleEvents.createVideo({
            draftId,
            success: false
          })
        );
        throw thunkApi.rejectWithValue({ key: VALIDATION_ERROR, errors });
      }
    }
    const result = await createVideo(draftId, undefined, variables, datastore_content_id);
    thunkApi.dispatch(
      googleEvents.createVideo({
        draftId,
        req_id: result.req_id,
        success: true
      })
    );
    return result;
  },
  thunkOptions
);

const createVideoRealsMeRequest = createAsyncThunk<
  Video,
  { draftId: string; destinations?: string[]; skip402ErrorHandling: boolean },
  ThunkApi
>(
  `${prefix} createVideoRealsMeRequest`,
  async ({ draftId, destinations }, thunkApi) => {
    const draft = draftsGlobalSelectors.selectById(thunkApi.getState(), draftId) as Draft;
    const currentDraftSceneId = draft?.element_graph?.head as string;
    const currentDraftScene = scenesGlobalSelectors.selectById(
      thunkApi.getState(),
      currentDraftSceneId
    );
    const template = thunkApi.getState().templates.currentTemplate as Template;
    // @ts-ignore handels userUI store typing
    const intl = thunkApi.getState().userUi.intl as IntlShape;
    const limits = getLimits(thunkApi.getState());

    const { errors } = validateCreateVideo(
      draft,
      [currentDraftScene as Scene],
      template,
      intl,
      limits
    );
    if (Object.keys(errors).length) {
      thunkApi.dispatch(
        googleEvents.createVideo({
          draftId,
          success: false
        })
      );
      throw thunkApi.rejectWithValue({ key: VALIDATION_ERROR, errors });
    }
    const result = await createVideo(draftId, destinations);
    thunkApi.dispatch(
      googleEvents.createVideo({
        draftId,
        req_id: result.req_id,
        success: true
      })
    );
    return result;
  },
  thunkOptions
);

const getVideosRequest = createAsyncThunk<
  Video[],
  { draftId: string; pagination?: { limit?: number; page: number } },
  ThunkApi
>(
  `${prefix} getVideosRequest`,
  async ({ draftId, pagination }) => {
    const result = await getVideos(draftId, pagination);
    return result;
  },
  thunkOptions
);

const getVideosByReqIdRequest = createAsyncThunk<Video[], string, ThunkApi>(
  `${prefix} getVideosByReqIdRequest`,
  async (reqId) => {
    const result = await getVideosByReqId(reqId);
    return result;
  },
  thunkOptions
);

const downloadVideoRequest = createAsyncThunk<
  any,
  {
    videoId: string;
    backModal?: string;
  },
  ThunkApi
>(
  `${prefix} downloadVideoRequest`,
  async ({ videoId }) => {
    const result = await downloadVideo(videoId);
    return result;
  },
  thunkOptions
);

const downloadTranscodeVideoRequest = createAsyncThunk<
  any,
  {
    fileType: MediaFileType;
    resolution: VideoResolution;
    draftId: string;
    videoId: string;
    backModal?: string;
  },
  ThunkApi
>(
  `${prefix} downloadTranscodeVideoRequest`,
  async ({ videoId, fileType, resolution }, thunkApi) => {
    const orderId = thunkApi.getState().videos.downloadVideoFormat[videoId].orderId as string;

    const result = await downloadTranscodeVideo(videoId, fileType, resolution, orderId);
    if (result.status === PusherMessagesStatusEnum.ready) {
      // @ts-ignore handels userUI store typing
      const intl = thunkApi.getState().userUi.intl as IntlShape;
      thunkApi.dispatch(
        uiActions.setNotifications([
          {
            message: intl.formatMessage(pusherMessages.downloadSucceeded)
          }
        ])
      );
    }
    thunkApi.dispatch(paymentsActions.getCreditsRequest());

    return result;
  },
  thunkOptions
);

const downloadScormVideoRequest = createAsyncThunk<
  any,
  {
    videoId: string;
    orderId: string;
  },
  ThunkApi
>(
  `${prefix} downloadScormVideoRequest`,
  async ({ videoId, orderId }, thunkApi) => {
    const result = await downloadScormVideo(videoId, orderId);
    if (result.status === PusherMessagesStatusEnum.ready) {
      // @ts-ignore handels userUI store typing
      const intl = thunkApi.getState().userUi.intl as IntlShape;
      thunkApi.dispatch(uiActionsV2.removeFromTray(orderId));
      thunkApi.dispatch(
        uiActions.setNotifications([
          {
            message: intl.formatMessage(pusherMessages.downloadSucceeded)
          }
        ])
      );
    }
    thunkApi.dispatch(paymentsActions.getCreditsRequest());

    return result;
  },
  thunkOptions
);

const updateLocalScormDownloadFormat = createAsyncThunk<
  void,
  { videoId: string; orderId: string; link?: string; status: PusherMessagesStatusEnum },
  ThunkApi
>(
  `${prefix} updateLocalScormDownloadFormat`,
  async ({ videoId, orderId, link, status }, thunkApi) => {
    const {
      videos: { scormDownloadVideoFormat }
    } = thunkApi.getState();
    if (scormDownloadVideoFormat && scormDownloadVideoFormat[orderId]) {
      thunkApi.dispatch(
        videosActions.setScormDownloadVideoFormat({
          videoId,
          orderId,
          link,
          status
        })
      );
    } else {
      console.warn("order id or resource is not found for download");
    }
  }
);

const updateLocalDownloadFormat = createAsyncThunk<
  void,
  { videoId: string; orderId: string; link?: string; status: PusherMessagesStatusEnum },
  ThunkApi
>(`${prefix} updateLocalDownloadFormat`, async ({ videoId, orderId, link, status }, thunkApi) => {
  const {
    videos: { downloadVideoFormat }
  } = thunkApi.getState();
  const resource = downloadVideoFormat[videoId];

  if (resource && resource.orderId === orderId) {
    thunkApi.dispatch(
      videosActions.setDownloadVideoFormat({
        videoId,
        orderId,
        link,
        status
      })
    );
  } else {
    console.warn("order id or resource is not found for download");
  }
});

const publishVideoRequest = createAsyncThunk<
  void,
  {
    videoId: string;
    reqId?: string;
    backModal?: string;
  },
  ThunkApi
>(
  `${prefix} publishVideoRequest`,
  async ({ videoId }, thunkApi) => {
    await publishVideo(videoId);
    thunkApi.dispatch(paymentsActions.getCreditsRequest());
  },
  thunkOptions
);

const updateVideoProgress = createAsyncThunk<
  void,
  {
    status: VideoStatusEnum;
    progress: number;
    req_id: string;
    duration?: number;
  },
  ThunkApi
>(
  `${prefix} updateVideoProgress`,
  async (message, thunkapi) => {
    const state = thunkapi.getState();
    const { isWorkflowMode } = state.uiV2;
    const { currentDraft } = state.drafts;
    const videos = videosGlobalSelectors.selectAll(state);
    const video = videos.find((cur: Video) => cur.req_id === message.req_id);
    if (video && video.status !== VideoStatusEnum.Canceled) {
      thunkapi.dispatch(
        videosActions.updateVideoStatusAndProgress({
          duration: message.duration,
          videoId: video.id,
          progress: message.progress,
          status: message.status
        })
      );
      const videoDraft = draftsGlobalSelectors.selectById(state, video.draft_id);
      if (
        videoDraft?.source &&
        videoDraft?.source !== SourceName.bio &&
        message.status === VideoStatusEnum.Ready
      ) {
        thunkapi.dispatch(uiActions.setRealsMeVideoReadyNotification(true));
        thunkapi.dispatch(
          uiActions.setNotificationsBadgeCounter(
            // @ts-ignore handels userUI store typing
            thunkapi.getState().userUi.notificationsBadgeCounter + 1
          )
        );
      }

      if (currentDraft?.type === "workflow" || isWorkflowMode) {
        return;
      }

      if (
        currentDraft?.id === video.draft_id &&
        [VideoStatusEnum.Ready, VideoStatusEnum.Failed].includes(message.status)
      ) {
        const screenWidth = document.body.clientWidth;
        if (screenWidth < SMALL_SCREEN) {
          return;
        }
        const modalName =
          message.status === VideoStatusEnum.Ready ? ModalName.videoReady : ModalName.videoFailed;
        thunkapi.dispatch(uiActions.setEditorModalOpen(modalName, { reqId: video.req_id }));
      }
    }
  },
  thunkOptions
);

const updateVideoThumbnail = createAsyncThunk<
  void,
  {
    req_id: string;
    url: string;
  },
  ThunkApi
>(
  `${prefix} updateVideoThumbnail`,
  async (message, thunkapi) => {
    const state = thunkapi.getState();
    const videos = videosGlobalSelectors.selectAll(state);
    const video = videos.find((cur: Video) => cur.req_id === message.req_id);
    if (video) {
      setTimeout(() => {
        thunkapi.dispatch(
          videosActions.updateVideoThumbnailUrl({
            videoId: video.id as string,
            url: message.url
          })
        );
      }, 2000);
    }
  },
  thunkOptions
);

export const searchVideosApiRequest = createAsyncThunk<
  { data: Video[] },
  {
    source?: SourceName;
    query?: string;
    limit?: number;
    audience?: Audience;
    expand?: boolean;
  }
>(
  `${prefix} searchVideosApiRequest`,
  async ({ source, query, limit, audience, expand }) => {
    const result = await searchVideosApi(query, limit, source, audience, expand);
    return result;
  },
  thunkOptions
);

export const deleteVideoRequest = createAsyncThunk<void, { videoId: string }>(
  `${prefix} deleteVideoRequest`,
  async ({ videoId }) => {
    await deleteVideo(videoId);
  },
  thunkOptions
);

const updateVideoRequest = createAsyncThunk<
  void,
  {
    videoId: string;
    operations: PatchOperation[];
  },
  ThunkApi
>(
  `${prefix} updateVideoRequest`,
  async ({ videoId, operations }) => {
    await updateVideo(videoId, operations);
  },
  thunkOptions
);

const upsertVideoRatingRequest = createAsyncThunk<
  void,
  {
    videoId: string;
    reaction: VideoRatingReaction;
    user: Reactor;
    activate?: boolean;
    channelName: string;
  }
>(
  `${prefix} upsertVideoRatingRequest`,
  async ({ videoId, reaction, activate = true, channelName }) => {
    await updateVideoRatingReaction(videoId, reaction, activate, channelName);
  },
  thunkOptions
);

const upsertVideoFeedbackRequest = createAsyncThunk<
  VideoFeedbackReaction,
  {
    videoId: string;
    reaction: VideoFeedbackReaction;
  }
>(
  `${prefix} upsertVideoFeedbackRequest`,
  async ({ videoId, reaction }) => {
    await updateVideoFeedbackReaction(videoId, reaction);
    return reaction;
  },
  thunkOptions
);

const getVideoFeedbackRequest = createAsyncThunk<
  VideoFeedbackReaction | null,
  {
    videoId: string;
  }
>(
  `${prefix} getVideoFeedbackRequest`,
  async ({ videoId }) => {
    const res = await getVideoReaction(videoId, "video_feedback", true);
    if (res.length) {
      return res[0]["reaction"];
    }
  },
  thunkOptions
);

const getFeaturedVideosApiRequest = createAsyncThunk<{ data: Video[] }>(
  `${prefix} getFeaturedVideosRequest`,
  async () => {
    return await getFeaturedVideosRequest();
  },
  thunkOptions
);

const getVideoSubtitleApiRequest = createAsyncThunk<VideoSubtitleResult, string, ThunkApi>(
  `${prefix} getVideoSubtitleApiRequest`,
  async (videoId) => {
    const result = await getVideoSubtitle(videoId);
    return result;
  },
  thunkOptions
);

const translateVideoSubtitlesApiRequest = createAsyncThunk<
  TranslateSubtitlesResponse,
  {
    videoId: string;
    translateSubtitlesRequest: TranslateSubtitlesRequest;
  },
  ThunkApi
>(
  `${prefix} translateVideoSubtitlesApiRequest`,
  async ({ videoId, translateSubtitlesRequest }) => {
    const result = await translateVideoSubtitles(videoId, translateSubtitlesRequest);
    return result;
  },
  thunkOptions
);

const cancelVideoRequest = createAsyncThunk<void, { videoId: string }, ThunkApi>(
  `${prefix} cancelVideoRequest`,
  async ({ videoId }) => {
    await cancelVideoRendering({ videoId });
  },
  thunkOptions
);

const fetchVideoProtectionRequest = createAsyncThunk<
  { protection: ProtectionType },
  { videoId: string },
  ThunkApi
>(
  `${prefix} fetchVideoProtectionRequest`,
  async ({ videoId }) => {
    const res = await fetchVideoProtection({ videoId });
    return { protection: res ? ProtectionType.Password : ProtectionType.None };
  },
  thunkOptions
);

const setVideoPasswordProtectionRequest = createAsyncThunk<
  void,
  { videoId: string; password: string },
  ThunkApi
>(
  `${prefix} setVideoPasswordProtectionRequest`,
  async ({ videoId, password }) => {
    await setVideoPasswordProtection({ videoId, password });
  },
  thunkOptions
);

const removeVideoProtectionRequest = createAsyncThunk<void, { videoId: string }, ThunkApi>(
  `${prefix} removeVideoProtectionRequest`,
  async ({ videoId }) => {
    await removeVideoProtection({ videoId });
  },
  thunkOptions
);
const getVideosFeedRequest = createAsyncThunk<
  { data: Video[] },
  { skip: number; limit: number },
  ThunkApi
>(
  `${prefix} getVideosFeedRequest`,
  async ({ skip, limit }) => {
    const result = await getVideosFeed(skip, limit);
    return result;
  },
  thunkOptions
);

export default {
  createVideoRequest,
  createVideoRealsMeRequest,
  getVideosRequest,
  getVideosByReqIdRequest,
  searchVideosApiRequest,
  downloadVideoRequest,
  downloadTranscodeVideoRequest,
  downloadScormVideoRequest,
  updateLocalDownloadFormat,
  updateLocalScormDownloadFormat,
  publishVideoRequest,
  updateVideoProgress,
  updateVideoThumbnail,
  deleteVideoRequest,
  updateVideoRequest,
  upsertVideoRatingRequest,
  upsertVideoFeedbackRequest,
  getVideoFeedbackRequest,
  getFeaturedVideosApiRequest,
  getVideoSubtitleApiRequest,
  translateVideoSubtitlesApiRequest,
  cancelVideoRequest,
  fetchVideoProtectionRequest,
  setVideoPasswordProtectionRequest,
  removeVideoProtectionRequest,
  getVideosFeedRequest
};
