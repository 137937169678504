import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { daysUntilDate, fetchingStatus, formatDate } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { capitalize, startCase } from "lodash";
import {
  SubscriptionCancellationResult,
  SubscriptionStatus,
  SubscriptionStatusValue
} from "app/types/payments";
import { paymentsActions } from "app/store/slices/payments.slice";
import { FeatureFlag, PlanEnum } from "app/types";
import useNotifications from "app/hooks/useNotifications";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import {
  minutesModalMessages,
  profileMessages,
  subscriptionMessages
} from "app/pages/profile/messages";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import MinutesCard from "app/pages/profile/MinutesCard";
import { Divider } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import CancelModal from "app/pages/profile/CancelModal";
import { useUpgradeByPlan } from "app/hooks/useUpgradeModal";
import OppositeUpgradeButton from "app/components/common/UpgradeButton/OppositeUpgradeButton";
import useCredits from "app/hooks/useCredits";
import MinutesModal from "app/pages/profile/MinutesModal";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const FREE_MINUTES = 3;
const WhiteFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
  border-radius: 4px;
`;

const PlanCardFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
`;

const StyledStatus = styled(H1_FlexRow)<{
  $color?: string;
}>`
  border-radius: 7px;
  background-color: ${({ $color }) => $color};
  color: ${({ theme }) => theme.gray1};
  min-width: 100px;
`;

const CircleIconContainer = styled(H1_FlexRow)<{ $background: string }>`
  background: ${({ $background }) => $background};
  border-radius: 50%;
`;

const CrownIcon = styled.i`
  color: ${({ theme }) => theme.gray1};
  font-size: 20px;
`;

const SubscriptionCard = () => {
  const [isOpenMinutesModal, setIsOpenMinutesModal] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { currentPlan, currentPlanText, currentPlanPeriodTimeText } = useCurrentPlan();
  const { notifyMessages } = useNotifications();
  const flags = useFlags();
  const upgradeModal = useUpgradeByPlan();
  const { isUnlimited } = useCredits();

  const subscriptions = useAppSelector((state) => state.payments.subscriptions);
  const cancelStatus = useAppSelector((state) => state.payments.cancelStatus);
  const cancelResult = useAppSelector((state) => state.payments.cancelResult);
  const getSubscriptionStatus = useAppSelector((state) => state.payments.getSubscriptionStatus);
  const updateCreditCardStatus = useAppSelector((state) => state.payments.updateCreditCardStatus);
  const updateCreditCardUrl = useAppSelector((state) => state.payments.updateCreditCardUrl);

  const isOnboarding = currentPlan === PlanEnum.onBoarding;
  const isEnterprise = currentPlan === PlanEnum.enterprise;
  const cancelLoading =
    cancelStatus === fetchingStatus.loading || getSubscriptionStatus === fetchingStatus.loading;
  const currentSubscription: SubscriptionStatus | undefined =
    subscriptions[0]?.status === SubscriptionStatusValue.canceled && isOnboarding
      ? undefined
      : subscriptions[0];
  const isStatusActive = currentSubscription?.status === SubscriptionStatusValue.active;
  const isStatusPaused = currentSubscription?.status === SubscriptionStatusValue.paused;
  const isStatusPastDue = currentSubscription?.status === SubscriptionStatusValue.past_due;
  const isUpdateCreditCardStatusLoading = updateCreditCardStatus === fetchingStatus.loading;
  const isUpdateCreditCardAvailable = isStatusActive || isStatusPastDue || isStatusPaused;

  const roundMinutes = useMemo(
    () => Math.floor((currentSubscription?.credit_charge_per_period || 0) / 60 || FREE_MINUTES),
    [currentSubscription?.credit_charge_per_period]
  );
  const statusColor = useMemo(() => {
    switch (currentSubscription?.status) {
      case SubscriptionStatusValue.active:
        return theme.blue4;
      case SubscriptionStatusValue.cancel_at_period_end:
      case SubscriptionStatusValue.canceled:
        return theme.pink4;
      case SubscriptionStatusValue.paused:
        return theme.gray7;
      default:
        return theme.gray1;
    }
  }, [currentSubscription?.status]);
  const description = useMemo(() => {
    if (currentSubscription && !cancelLoading) {
      if (currentSubscription.status === SubscriptionStatusValue.canceled) {
        return intl.formatMessage(subscriptionMessages.cancelAt, {
          time: formatDate(currentSubscription.canceled_at)
        });
      } else if (currentSubscription.status === SubscriptionStatusValue.cancel_at_period_end) {
        return intl.formatMessage(subscriptionMessages.subscriptionEnd, {
          time: formatDate(currentSubscription.current_period_end)
        });
      } else if (currentSubscription.status === SubscriptionStatusValue.paused) {
        return intl.formatMessage(subscriptionMessages.subscriptionResume, {
          time: daysUntilDate(currentSubscription.resumes_at),
          plan: capitalize(
            currentSubscription.subscription_plan_on_resume
              ? currentSubscription.subscription_plan_on_resume.split("_")[0]
              : "plan"
          )
        });
      }
    } else {
      return intl.formatMessage(subscriptionMessages.defaultMinutes);
    }
    return "";
  }, [currentSubscription, cancelLoading, cancelStatus]);

  useEffect(() => {
    if (updateCreditCardStatus === fetchingStatus.succeeded && updateCreditCardUrl.url) {
      window.open(updateCreditCardUrl.url, "_blank");
      dispatch(paymentsActions.updateCreditCardStatusToIdle());
    }
    if (updateCreditCardStatus === fetchingStatus.failed) {
      dispatch(paymentsActions.updateCreditCardStatusToIdle());
    }
  }, [updateCreditCardUrl.url, updateCreditCardStatus]);
  useEffect(() => {
    dispatch(paymentsActions.getSubscriptionStatusRequest());
    return () => {
      dispatch(paymentsActions.cleanSubscriptions());
    };
  }, []);

  useEffect(() => {
    if (cancelStatus === fetchingStatus.succeeded && cancelResult) {
      let message = "";
      switch (cancelResult) {
        case SubscriptionCancellationResult.subscription_canceled:
          message = intl.formatMessage(subscriptionMessages.cancelNotification);
          break;
        case SubscriptionCancellationResult.subscription_paused:
          message = intl.formatMessage(subscriptionMessages.pausedNotification);
          break;
        case SubscriptionCancellationResult.subscription_refunded:
          message = intl.formatMessage(subscriptionMessages.refundedNotification);
          break;

        case SubscriptionCancellationResult.subscription_cancellation_scheduled:
          message = intl.formatMessage(subscriptionMessages.cancelScheduledNotification);
          break;
        case SubscriptionCancellationResult.discount_requested:
          message = intl.formatMessage(subscriptionMessages.discountNotification);
          break;
        default:
          message = "";
      }
      if (message) {
        notifyMessages([
          {
            message
          }
        ]);
      }
      dispatch(paymentsActions.getSubscriptionStatusRequest());
      dispatch(paymentsActions.setSubscriptionCancelToIdle());
    } else if (cancelStatus === fetchingStatus.failed) {
      dispatch(paymentsActions.setSubscriptionCancelToIdle());
    }
  }, [cancelStatus]);

  const onCancelSubscription = () => {
    setShowQuestions(true);
  };
  const onChangeCreditCard = () => {
    if (subscriptions[0]) {
      dispatch(paymentsActions.updateCreditCardRequest(subscriptions[0].subscription_id as string));
    }
  };
  const onCloseCancelModal = () => {
    setShowQuestions(false);
  };

  const onOpenMinutesModal = () => {
    setIsOpenMinutesModal(true);
  };
  const onCloseMinutesModal = () => {
    setIsOpenMinutesModal(false);
  };

  const onClickUpgrade = () => {
    upgradeModal({ source: `upgrade_profile_page` });
  };

  return (
    <WhiteFlexColumn flex="0 0 auto" width="100%" padding="20px 30px 30px">
      <MinutesModal onClose={onCloseMinutesModal} visible={isOpenMinutesModal} />
      <ConditionalRender condition={!!currentSubscription}>
        <CancelModal open={showQuestions} onClose={onCloseCancelModal} />
      </ConditionalRender>
      <H1_TextMiddle margin="0 0 3px 0" color={theme.gray8}>
        {intl.formatMessage(subscriptionMessages.title)}
      </H1_TextMiddle>
      <Divider />
      <ConditionalRender condition={cancelLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!cancelLoading}>
        <H1_FlexColumn gap="6px" padding="3px 0 0 0">
          <H1_TextSmall color={theme.gray9} fontWeight={500}>
            {intl.formatMessage(subscriptionMessages.pricingPlans)}
          </H1_TextSmall>
          <PlanCardFlexRow
            margin="13px 0 0 0"
            padding="20px"
            align="center"
            justify="space-between"
          >
            <H1_FlexRow gap="19px" align="center">
              <ConditionalRender condition={isStatusActive}>
                <CircleIconContainer
                  width="40px"
                  height="38px"
                  position="relative"
                  align="center"
                  justify="center"
                  $background={theme.orange3}
                >
                  <CrownIcon className="fa fa-crown" />
                </CircleIconContainer>
              </ConditionalRender>
              <H1_FlexColumn>
                <H1_TextMiddle>
                  {intl.formatMessage(profileMessages.currentPlan, {
                    plan: currentPlanText,
                    period: currentPlanPeriodTimeText
                  })}
                </H1_TextMiddle>
                <ConditionalRender condition={isStatusActive}>
                  <ConditionalRender condition={isUnlimited}>
                    <H1_TextXs color={theme.gray8}>
                      {intl.formatMessage(profileMessages.unlimitedMinutes)}
                    </H1_TextXs>
                  </ConditionalRender>
                  <ConditionalRender condition={!isUnlimited && !isEnterprise}>
                    <H1_TextXs color={theme.gray8}>
                      {intl.formatMessage(profileMessages.currentPlanMinutes, {
                        number: roundMinutes
                      })}
                    </H1_TextXs>
                  </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender condition={!isStatusActive}>
                  <ConditionalRender condition={isUnlimited}>
                    <H1_TextXs color={theme.gray8}>
                      {intl.formatMessage(profileMessages.unlimitedMinutes)}
                    </H1_TextXs>
                  </ConditionalRender>
                  <ConditionalRender condition={!isUnlimited}>
                    <H1_TextXs>{description}</H1_TextXs>
                  </ConditionalRender>
                </ConditionalRender>
              </H1_FlexColumn>
            </H1_FlexRow>
            <ConditionalRender condition={isOnboarding && !currentSubscription?.status}>
              <OppositeUpgradeButton
                onClick={onClickUpgrade}
                source="upgrade-via-profile-page"
                text={intl.formatMessage(profileMessages.upgradeTextBtn)}
              />
            </ConditionalRender>
            <ConditionalRender condition={!!currentSubscription?.status}>
              <StyledStatus
                $color={statusColor}
                padding="5px"
                align="center"
                justify="center"
                height="38px"
                width="128px"
              >
                {startCase(currentSubscription?.status)}
              </StyledStatus>
            </ConditionalRender>
          </PlanCardFlexRow>
          <Divider />
        </H1_FlexColumn>
      </ConditionalRender>
      <MinutesCard />

      <ConditionalRender
        condition={!isUpdateCreditCardAvailable || !flags[FeatureFlag.subscriptionCard]}
      >
        <ConditionalRender condition={flags[FeatureFlag.minutesUsage]}>
          <H1_FlexRow padding="20px 20px 0 0" justify="flex-end" gap="20px">
            <Button
              variant="light"
              color="primary"
              onClick={onOpenMinutesModal}
              isLoading={isUpdateCreditCardStatusLoading}
            >
              {intl.formatMessage(minutesModalMessages.minutesUsageAnalytics)}
            </Button>
          </H1_FlexRow>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender
        condition={isUpdateCreditCardAvailable && flags[FeatureFlag.subscriptionCard]}
      >
        <H1_FlexRow padding="20px 20px 0 0" justify="flex-end" gap="20px">
          <ConditionalRender condition={flags[FeatureFlag.minutesUsage]}>
            <Button
              variant={theme.mode === ThemeMode.Light ? "light" : "solid"}
              color={theme.mode === ThemeMode.Light ? "primary" : "default"}
              onClick={onOpenMinutesModal}
              isLoading={isUpdateCreditCardStatusLoading}
            >
              {intl.formatMessage(minutesModalMessages.minutesUsageAnalytics)}
            </Button>
          </ConditionalRender>
          <Button
            variant={theme.mode === ThemeMode.Light ? "light" : "solid"}
            color={theme.mode === ThemeMode.Light ? "primary" : "default"}
            onClick={onChangeCreditCard}
            isLoading={isUpdateCreditCardStatusLoading}
          >
            {intl.formatMessage(subscriptionMessages.changeCreditCardButton)}
          </Button>
          <Button variant="light" onClick={onCancelSubscription} isLoading={cancelLoading}>
            {intl.formatMessage(subscriptionMessages.cancelButton)}
          </Button>
        </H1_FlexRow>
      </ConditionalRender>
    </WhiteFlexColumn>
  );
};

export default SubscriptionCard;
