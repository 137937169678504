import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch } from "app/hooks";
import { Channel } from "pusher-js";
import { CloneVoiceEvent } from "app/types";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import { voicesActions } from "app/store/slices/voices.slice";
import uiActions from "app/store/actions/ui.actions";
import { extractUserIdForPusher } from "app/utils/helpers";

const PusherCloneVoiceHandler = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const { notifyError } = useErrors();

  useEffect(() => {
    let channel: Channel;
    const handleProgressUpdate = (message: CloneVoiceEvent) => {
      const { clone_status } = message;

      if (clone_status === "done") {
        dispatch(voicesActions.replaceCloneVoice(message));
        dispatch(
          uiActions.setNotifications([
            {
              message: "Your new voice is ready!"
            }
          ])
        );
      } else {
        dispatch(voicesActions.removeVoiceByOrderId(message.order_id));
        notifyError({
          general: buildGeneralMessage(
            `Something went wrong`,
            "something went wrong processing your voice, please contact support"
          )
        });
      }
    };
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("clone_voice", handleProgressUpdate);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherCloneVoiceHandler;
