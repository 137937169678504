import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SubmissionForm from "app/pages/WorkflowSubmissionPage/SubmissionForm";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useParams } from "react-router-dom";
import { Alert, Tabs } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import VideoRenderPreview from "app/pages/WorkflowSubmissionPage/VideoRenderPreview";
import { videosActions } from "app/store/slices/videos.slice";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { FeatureFlag, SynthesisMarkupLanguage, VideoStatusEnum } from "app/types";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import WorkflowTable from "app/pages/EditDraftPage/workflow/WorkflowTable";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { useFlags } from "launchdarkly-react-client-sdk";

const BackgroundFlexColumn = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray4};
  min-height: 0;
`;

const StyledTabs = styled(Tabs)`
  color: #5a5aff;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  .ant-tabs-nav-list {
    justify-content: center;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab:hover {
    color: #5a5aff;
  }
  .ant-tabs-tab {
    //width: 150px;
    width: 120px;
  }
  .ant-tabs-nav {
    margin-bottom: 80px;
  }
  .ant-tabs-tab-btn {
    width: 100%;
  }
`;

const TabTitle = ({ title, icon }: any) => {
  return (
    <H1_FlexRow align="center" gap="10px" justify="center">
      <i className={icon} />
      {title}
    </H1_FlexRow>
  );
};

type TabTypes = "form" | "table";

const WorkflowSubmissionPage = () => {
  const [currentTab, setCurrentTab] = useState<TabTypes>("form");
  const [variableValues, setVariableValues] = useState<Record<string, string>>({});

  const dispatch = useAppDispatch();

  const { isAuthenticated } = useAuth();

  const [error, setError] = useState(false);
  const { workflowId } = useParams<{ workflowId: string }>();
  const flags = useFlags();

  const getWorkflowStatus = useAppSelector((state) => state.drafts.getWorkflowStatus);
  const workflowResult = useAppSelector((state) => state.drafts.workflowResult);
  const workflowPlayingVideoId = useAppSelector((state) => state.drafts.workflowPlayingVideoId);
  const totalSml: SynthesisMarkupLanguage[] = useAppSelector(
    scenesSelectors.getScenesTotalSmlWorkflow
  );
  const isLoading = getWorkflowStatus === fetchingStatus.loading;

  useEffect(() => {
    dispatch(videosActions.cleanVideos());
    dispatch(draftsActions.getWorkflowRequest({ workflowId: workflowId as string }));
    dispatch(uiActionsV2.setIsWorkflowMode(true));
    return () => {
      dispatch(uiActionsV2.setIsWorkflowMode(false));
    };
  }, []);

  useEffect(() => {
    if (workflowResult) {
      const values = workflowResult.variables.reduce((acc: Record<string, string>, variable) => {
        acc[variable.id] = variable.default_value || "";
        return acc;
      }, {});
      setVariableValues(values);
    }
  }, [workflowResult]);

  useEffect(() => {
    if (getWorkflowStatus === fetchingStatus.failed) {
      setError(true);
    }
  }, [getWorkflowStatus]);

  useEffect(() => {
    // for anonymous user
    if (isAuthenticated || !workflowResult?.id) {
      return;
    }

    const handleProgress = (parsed: {
      status: VideoStatusEnum;
      progress: number;
      // eslint-disable-next-line camelcase
      req_id: string;
    }) => {
      dispatch(videosActions.updateVideoProgress(parsed));
    };
    const channelName = `${workflowId}`;

    const channel = pusher.subscribe(channelName);
    channel.bind("pipeline_progress", handleProgress);
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [isAuthenticated, workflowResult?.id]);

  const onChangeVariableValue = (variableId: string, value: string) => {
    setVariableValues({ ...variableValues, [variableId]: value });
  };

  const onChange = (tab: string) => {
    setCurrentTab(tab as TabTypes);
  };

  return (
    <BackgroundFlexColumn
      flex="1 0 auto"
      justify={"center"}
      padding={"20px 0px 10px 0px"}
      gap={"20px"}
    >
      <ConditionalRender condition={error}>
        <Alert message={"Workflow not available"} banner type="error" />
      </ConditionalRender>
      <ConditionalRender condition={!!workflowResult}>
        <StyledTabs destroyInactiveTabPane activeKey={currentTab} onChange={onChange}>
          {flags[FeatureFlag.csvTable] && (
            <Tabs.TabPane
              tab={<TabTitle title={"Table"} icon={"fa-regular fa-table"} />}
              key="table"
            >
              <H1_FlexRow margin="30px 40px 0">
                <WorkflowTable shareLinkMode workflowId={workflowId} />
              </H1_FlexRow>
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab={<TabTitle title="View form" icon="fa-regular fa-eye" />} key="form">
            <H1_FlexRow gap="20px" justify="center">
              <SubmissionForm
                variableValues={variableValues}
                onChangeVariableValue={onChangeVariableValue}
                totalSml={totalSml}
              />
              <VideoRenderPreview
                variableValues={variableValues}
                videoId={workflowPlayingVideoId as string}
                totalSml={totalSml}
              />
            </H1_FlexRow>
          </Tabs.TabPane>
        </StyledTabs>
      </ConditionalRender>
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
    </BackgroundFlexColumn>
  );
};

export default WorkflowSubmissionPage;
