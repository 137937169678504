import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { $getNodeByKey } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { H1_TextMiddle, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Tooltip } from "antd";
import { $isPauseNode } from "app/components/common/LexicalEditor/nodes/PauseNode";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Voice, VoiceProvider } from "app/types";
import { useIntl } from "react-intl";
import { pauseMessages } from "app/components/common/LexicalEditor/nodes/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import ConditionalRender from "app/components/common/ConditionalRender";
import { scenesActions } from "app/store/slices/scenes.slice";
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";

const MAX_TIME = 10;
const MAX_TIME_ELEVEN_LABS = 3;
const MIN_TIME_ELEVEN_LABS = 1;
const MIN_TIME = 0.5;

const ClockIcon = styled.i`
  color: ${({ theme }) => theme.blue4};
  font-size: 12px;
`;
const InlineFlexRow = styled(H1_FlexRow)<{
  $open: boolean;
  $disabled: boolean;
  $warningBackground: boolean;
}>`
  display: inline-flex;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
  background-color: ${({ theme, $open, $disabled, $warningBackground }) =>
    $disabled
      ? theme.gray5
      : $warningBackground
      ? theme.orange1
      : $open
      ? theme.blue2
      : theme.blue1};
  color: ${({ theme, $disabled }) => ($disabled ? theme.gray3 : theme.blue4)};
  && i,
  span {
    color: ${({ theme, $disabled }) => ($disabled ? theme.gray3 : theme.blue4)};
  }
  &:hover {
    opacity: 0.8;
  }
  &::after {
    height: 14px;
    font-size: 14px;
    content: "s";
    margin-bottom: 9px;
  }
`;

const Icon = styled.i`
  font-size: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.gray11};
  &:hover {
    color: ${({ theme }) => theme.blue4};
  }
`;

const TextContainer = styled(H1_TextMiddle)`
  padding: 6px 10px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.gray11};
  background-color: ${({ theme }) => theme.gray3};
  &::after {
    content: "s";
  }
`;

const TrashButton = styled(Button)`
  margin-left: 17px;
`;

interface PauseComponentProps {
  time: string;
  sceneId: string;
  nodeKey: string;
}

const PauseComponent = ({ time, sceneId, nodeKey }: PauseComponentProps) => {
  const [open, setOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>(Number(time));
  const [editor] = useLexicalComposerContext();
  const { formatMessage } = useIntl();
  const voice: Voice | undefined = useAppSelector((state) =>
    scenesSelectors.getSceneVoiceBySceneId(state, sceneId)
  );
  const dispatch = useAppDispatch();
  const isVoiceElevenLabs = voice?.provider === VoiceProvider.ElevenLabs;
  const isMaxTimeReactedElevenLabs = isVoiceElevenLabs && currentTime > MAX_TIME_ELEVEN_LABS;
  const isMinTimeReactedElevenLabs = isVoiceElevenLabs && currentTime < MIN_TIME_ELEVEN_LABS;
  const isWarning = isMaxTimeReactedElevenLabs || isMinTimeReactedElevenLabs;

  const currentTimeStr = useMemo(() => currentTime.toFixed(1), [currentTime]);

  useEffect(() => {
    dispatch(scenesActions.setScenesChanged(open));
    if (!open) {
      onBlur();
    }
  }, [open]);

  const removePause = () => {
    dispatch(analyticsEvents.editorPauseRemoved({ time, nodeKey }));
    editor.update(
      () => {
        const node = $getNodeByKey(nodeKey);
        if ($isPauseNode(node)) {
          node.remove();
          // Force calling the onBlurText in order to update the server
          editor.focus();
          editor.blur();
        }
      },
      {
        onUpdate: () => {
          // Force calling the onBlurText in order to update the server
          editor.focus();
          editor.blur();
        }
      }
    );
  };

  const onClickIncreasePauseTime = () => {
    const maxTime = isVoiceElevenLabs ? MAX_TIME_ELEVEN_LABS : MAX_TIME;
    if (currentTime < maxTime) {
      setCurrentTime(currentTime + 0.5);
    }
  };

  const onClickDecreasePauseTime = () => {
    const minTime = isVoiceElevenLabs ? MIN_TIME_ELEVEN_LABS : MIN_TIME;
    setCurrentTime(currentTime <= minTime ? currentTime : currentTime - 0.5);
  };

  const onClickPauseInstance = () => {
    setOpen(true);
  };

  const onBlur = () => {
    setOpen(false);
    dispatch(analyticsEvents.editorPauseClosed({ time, nodeKey }));
    editor.update(
      () => {
        const node = $getNodeByKey(nodeKey);
        if ($isPauseNode(node)) {
          node.setTime(currentTime.toString());
        }
      },
      {
        onUpdate: () => {
          // Force calling the onBlurText in order to update the server
          editor.focus();
          editor.blur();
        }
      }
    );
  };

  const content = (
    <H1_FlexRow gap="5px" align="center">
      <Icon onClick={onClickDecreasePauseTime} className="far fa-minus" />
      <TextContainer>{currentTimeStr}</TextContainer>
      <Icon onClick={onClickIncreasePauseTime} className="far fa-plus" />
      <TrashButton
        size="sm"
        variant="light"
        isIconOnly
        startContent={<i className="far fa-trash" />}
        onClick={removePause}
      />
    </H1_FlexRow>
  );

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Tooltip
      title={
        !voice?.pause_supported
          ? formatMessage(pauseMessages.notSupported)
          : isWarning
          ? formatMessage(pauseMessages.notSupported3Secs)
          : undefined
      }
      placement={isWarning ? "bottom" : "top"}
    >
      <Popover isOpen={open && voice?.pause_supported} onOpenChange={handleOpenChange}>
        <PopoverTrigger>
          <InlineFlexRow
            margin="0 5px"
            padding="0 5px"
            justify="center"
            align="center"
            height="22px"
            position="relative"
            $open={open}
            $disabled={!voice?.pause_supported}
            onClick={onClickPauseInstance}
            $warningBackground={isWarning}
            gap="5px"
          >
            <ConditionalRender condition={isWarning}>
              <ClockIcon className="far fa-warning" />
            </ConditionalRender>
            <ClockIcon className="far fa-clock" />
            <H1_TextXs lineHeight="19px">{currentTimeStr}</H1_TextXs>
          </InlineFlexRow>
        </PopoverTrigger>
        <PopoverContent>{content}</PopoverContent>
      </Popover>
    </Tooltip>
  );
};

export default PauseComponent;
