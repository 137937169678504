import { defineMessages } from "react-intl";

export const briefsModalMessages = defineMessages({
  briefHeadLine: {
    id: "briefs.modal.brief-head-line",
    defaultMessage: "Create brief"
  },
  sendBriefButton: {
    id: "briefs.modal.send-brief-button",
    defaultMessage: "Send brief"
  },
  addPeopleInput: {
    id: "briefs.modal.add-people-input",
    defaultMessage: "Add people"
  },
  briefContentInput: {
    id: "briefs.modal.brief-content-input",
    defaultMessage: "Write your video brief "
  },
  briefCreatedMsg: {
    id: "briefs.modal.brief-created-msg",
    defaultMessage: "Brief created successfully <a>view</a>"
  },
  briefFailedMsg: {
    id: "briefs.modal.brief-failed-msg",
    defaultMessage: "Failed to send your brief, please try again"
  }
});

export const briefsPageMessages = defineMessages({
  incomingBriefsTab: {
    id: "briefs.page.incoming-briefs-tab",
    defaultMessage: "Incoming briefs"
  },
  sentBriefsTab: {
    id: "briefs.page.sent-briefs-tab",
    defaultMessage: "Sent briefs"
  },
  archivedBriefsTab: {
    id: "briefs.page.archived-briefs-tab",
    defaultMessage: "Archived briefs"
  },
  createVideoButton: {
    id: "briefs.page.create-video-button",
    defaultMessage: "Create Video"
  }
});
