import { useEffect } from "react";
import { useIntl } from "react-intl";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { videoLoaderModalMessages } from "app/components/common/player/messages";

import CircleLoader from "app/components/common/Loaders/CircleLoader";
import styled, { useTheme } from "styled-components";
import { getTrayElmByKey } from "app/store/selectorsV2/uiV2.selectors";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";
import { StyledModal } from "app/components/common/StyledModal";

const StyledCircleLoader = styled(CircleLoader)`
  height: 50px;
  width: 80px;
`;

const ScormLoadingCreationModal = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { closeModal, openModal, closeModalIfOpen, editorModalOpen } = useModal();

  const currentOrderId = editorModalOpen?.context?.orderId;

  const scormDownloadVideoFormat = useAppSelector((state) => state.videos.scormDownloadVideoFormat);
  const scormOrderId = useAppSelector(
    (state) => state.videos.scormDownloadVideoFormat[currentOrderId]?.orderId
  );

  const minimized = useAppSelector((state) => getTrayElmByKey(state, scormOrderId));

  useEffect(() => {
    if (!currentOrderId) {
      return;
    }
    Object.entries(scormDownloadVideoFormat).forEach(([key, value]) => {
      const videoScormDownloadStatus = value.status;
      if (videoScormDownloadStatus === PusherMessagesStatusEnum.ready) {
        dispatch(uiActionsV2.removeFromTray(key));
        if (currentOrderId === key) {
          closeModalIfOpen(ModalName.scormLoading);
        }
      }
      if (videoScormDownloadStatus === PusherMessagesStatusEnum.failed) {
        dispatch(uiActionsV2.removeFromTray(key));
        if (currentOrderId === key) {
          closeModalIfOpen(ModalName.scormLoading);
        }
      }
    });
  }, [scormDownloadVideoFormat]);

  const handleMinimized = (scormOrderId: string) => {
    if (scormOrderId) {
      dispatch(
        uiActionsV2.addToTray({
          [scormOrderId]: {
            showLoader: true,
            showButton: true,
            headline: intl.formatMessage(videoLoaderModalMessages.HeadlineTitle),
            onExpand: () => {
              dispatch(uiActionsV2.removeFromTray(scormOrderId));
              openModal(ModalName.scormLoading, { orderId: scormOrderId });
            }
          }
        })
      );
      closeModal();
    }
  };

  return (
    <StyledModal
      width="520px"
      open={!minimized && !!scormOrderId}
      footer={null}
      centered
      key="scorm-creation-loader"
      bodyStyle={{ height: "207px" }}
      onCancel={() => handleMinimized(scormOrderId)}
      destroyOnClose
      $padding="40px 48px 55px"
      $alignItems="center"
      $justifyContent="space-between"
    >
      <StyledCircleLoader />
      <H1_TextSubtitle lineHeight="35px" margin="12px 0 0" color={theme.gray9} fontWeight={700}>
        {intl.formatMessage(videoLoaderModalMessages.HeadlineTitle)}
      </H1_TextSubtitle>
      <H1_TextSmall color={theme.gray8}>
        {intl.formatMessage(videoLoaderModalMessages.SubTitle)}
      </H1_TextSmall>
    </StyledModal>
  );
};
export default ScormLoadingCreationModal;
