import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import styled, { css } from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { mediaLibraryMessages } from "app/pages/mediaLibrary/StableDiffusion/messages";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { FeatureFlag, FetchStatus } from "app/types";
import { v4 as uuidv4 } from "uuid";

import {
  Media,
  MediaLibraryTabs,
  MediaType,
  SelectedImageContext,
  ServerFolderMedia
} from "app/types/media";
import { getGeneratedSortedMediaByType } from "app/store/selectorsV2/media.selectors";
import { Collapse, Typography } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import ConditionalRender from "app/components/common/ConditionalRender";
import MediaLibraryTabSDGeneratePrompt from "app/pages/mediaLibrary/StableDiffusion/Prompt";
import GeneratedImageDisplay from "app/pages/mediaLibrary/StableDiffusion/GeneratedImageDisplay";
import useErrors from "app/hooks/useErrors";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import MediaGenericItem from "app/pages/mediaLibrary/MediaGenericItem";

const { Panel } = Collapse;

const StyledAnimatePresence = styled(AnimatePresence)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 35px;
`;

const BoxFlexColumn = styled(H1_FlexColumn)`
  max-width: 154px;
  & div {
    transform: none !important;
  }
`;

const ShrinkFlexRow = styled(H1_FlexRow)<{ $shrinked: boolean }>`
  overflow: hidden;
  flex-direction: column;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${(props) =>
    props.$shrinked &&
    css`
      & > div > :first-child {
        transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        flex-direction: row;
        flex-wrap: nowrap;
        input,
        .ant-input-affix-wrapper {
          min-width: 100px;
        }
        * {
          overflow-wrap: anywhere;
          white-space: normal;
          font-size: 10px;
          line-height: 12px;
        }
      }
      button {
        opacity: 0;
        display: none;
      }
      & > div > :nth-child(2) {
        max-width: auto;
      }
    `}
`;

const NameText = styled(Typography.Text)`
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.gray8};
`;

const StatusCircle = styled("span")<{ $on: boolean }>`
  color: ${(props) => props.theme.gray5};
  &::before {
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 60%;
    background-color: ${(props) => (props.$on ? "green" : "red")};
  }
`;

const StyledCollapse = styled(Collapse)`
  width: 100%;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.gray1};

  .ant-collapse-content {
    overflow: auto;
    border-radius: 10px;
    max-height: 300px;
    > .ant-collapse-content-box {
      display: flex;
      border-radius: 10px;
    }
  }
`;
export interface MediaLibraryTabSDGenerateProps {
  onImageSelect: (value: string, context?: SelectedImageContext) => void;
  selectedUrl?: string;
}

const MediaLibraryTabSDGenerate = ({
  onImageSelect,
  selectedUrl
}: MediaLibraryTabSDGenerateProps) => {
  const [openPanel, setOpenPanel] = useState<string>();
  const [topShrink, setTopShrink] = useState<boolean>(false);
  const [orderIdPending, setOrderIdPending] = useState<string>();
  const [showGenerated, setShowGenerated] = useState<boolean>(true);
  const [timedOut, setTimeouted] = useState<NodeJS.Timeout>();
  const [validators, setValidators] = useState<Record<string, boolean>>({
    length: false,
    negation: true,
    noun: false,
    adjective: false,
    dependentObj: false
  });
  const fileList: ServerFolderMedia[] = useAppSelector((state) =>
    getGeneratedSortedMediaByType(state, {})
  );
  const generatedRequestLastResults: undefined | Media = useAppSelector(
    (state) => state.media.generatedRequestLastResults
  );
  const mediaGenerateStatus: FetchStatus = useAppSelector(
    (state) => state.media.generatedRequestStatus
  );

  const intl = useIntl();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { notifyError } = useErrors();

  useEffect(() => {
    if (mediaGenerateStatus === "failed") {
      failureHandel();
    }
  }, [mediaGenerateStatus]);

  useEffect(() => {
    if (generatedRequestLastResults && generatedRequestLastResults.orderId === orderIdPending) {
      setShowGenerated(generatedRequestLastResults.orderId === orderIdPending);
      setTopShrink(true);
      setOrderIdPending(undefined);
      if (timedOut) {
        clearTimeout(timedOut);
      }
      onImageSelect(
        generatedRequestLastResults?.url as string,
        {
          handle: generatedRequestLastResults?.handle,
          thumbnailType: generatedRequestLastResults?.media_type,
          thumbnail: generatedRequestLastResults?.url,
          title: generatedRequestLastResults?.metadata?.text,
          tab: MediaLibraryTabs.StableDiffusion
        } as SelectedImageContext
      );
    } else {
      dispatch(mediaActions.resetGeneratedMediaLastResponse());
    }
    dispatch(mediaActions.updateGeneratedRequestStatusToIdle());
  }, [generatedRequestLastResults]);

  useEffect(() => {
    return () => {
      setOrderIdPending(undefined);
      setShowGenerated(false);
      setTopShrink(false);
      if (timedOut) {
        clearTimeout(timedOut);
      }
    };
  }, []);

  const sdValidation = flags[FeatureFlag.sdPromptValidation];

  const failureHandel = () => {
    if (orderIdPending) {
      setOrderIdPending(undefined);
      notifyError({
        generatedMedia: buildGeneralError(
          intl.formatMessage(mediaLibraryMessages.sdResponseTOMessage),
          intl
        )
      });
      if (timedOut) {
        clearTimeout(timedOut);
      }
      setTimeouted(undefined);
      dispatch(mediaActions.updateGeneratedRequestStatusToIdle());
    }
  };

  useEffect(() => {
    if (orderIdPending) {
      // Handle super long response from pusher -> 0.5 min (30000 ms)
      const timeOut: ReturnType<typeof setTimeout> = setTimeout(failureHandel, 30000);
      setTimeouted(timeOut);
    } else if (timedOut) {
      clearTimeout(timedOut);
    }

    return () => {
      if (timedOut) {
        clearTimeout(timedOut);
        setTimeouted(undefined);
      }
    };
  }, [orderIdPending]);

  const sendPrompt = async (value: string) => {
    const orderId = uuidv4().toString();
    setOrderIdPending(orderId);
    dispatch(mediaActions.generateSDMediaRequest({ txt: value, orderId }));
  };

  const validatorsEntries = useMemo(() => Object.entries(validators), [validators]);

  return (
    <H1_FlexColumn
      flex="1 1 auto"
      overflow="hidden"
      gap="33px"
      height="100%"
      justify="space-between"
      align="center"
    >
      <ShrinkFlexRow
        overflow="auto"
        gap="16px"
        wrap="wrap"
        align="center"
        justify="start"
        width="100%"
        height="max-content"
        $shrinked={topShrink}
      >
        <MediaLibraryTabSDGeneratePrompt
          sendPrompt={sendPrompt}
          shrinked={topShrink}
          validators={validators}
          setValidators={(newValidators: Record<string, boolean>) => setValidators(newValidators)}
          helperText={!sdValidation && intl.formatMessage(mediaLibraryMessages.helperGenerateSD)}
          onFocus={() => {
            setOpenPanel(undefined);
            setTopShrink(false);
            setShowGenerated(false);
          }}
        />
        <ConditionalRender condition={sdValidation}>
          {validatorsEntries.map((validator) => {
            return (
              <StatusCircle $on={validator[1]} key={validator[0]}>
                {validator[0]}
              </StatusCircle>
            );
          })}
        </ConditionalRender>
      </ShrinkFlexRow>
      <ConditionalRender condition={generatedRequestLastResults && !openPanel && showGenerated}>
        <GeneratedImageDisplay
          url={generatedRequestLastResults?.url as string}
          selected={selectedUrl === generatedRequestLastResults?.url}
          handle={generatedRequestLastResults?.handle as string}
          text={generatedRequestLastResults?.metadata?.text}
          onClickImage={(url: string) =>
            onImageSelect(url, {
              handle: generatedRequestLastResults?.handle as string,
              url: url,
              title: generatedRequestLastResults?.metadata?.text || "",
              tab: MediaLibraryTabs.StableDiffusion
            })
          }
        />
      </ConditionalRender>
      <ConditionalRender condition={fileList.length > 0}>
        <StyledCollapse
          accordion
          activeKey={openPanel}
          onChange={(key: string | string[]) => {
            setOpenPanel(key as string);
            if (key) setTopShrink(true);
            else {
              setShowGenerated(true);
              setTopShrink(!!generatedRequestLastResults);
            }
          }}
        >
          <Panel
            header={intl.formatMessage(mediaLibraryMessages.sdLastCreatedHeader)}
            key="lastCreated"
          >
            <StyledAnimatePresence>
              {fileList.map((file: ServerFolderMedia) => (
                <motion.div
                  layout
                  key={file.id}
                  initial={{ scale: 0.5 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{
                    default: {
                      duration: 0.5
                    },
                    scale: {
                      damping: 2
                    }
                  }}
                >
                  <BoxFlexColumn
                    position="relative"
                    overflow="hidden"
                    gap="7px"
                    flex="0 0 auto"
                    onClick={() =>
                      onImageSelect(file.data?.url as string, {
                        handle: file.data?.handle as string,
                        url: file.data?.url as string,
                        title: file.data?.metadata?.text || "",
                        tab: MediaLibraryTabs.StableDiffusion,
                        mediaType: MediaType.image
                      })
                    }
                  >
                    <MediaGenericItem
                      url={file?.data?.url as string}
                      selected={selectedUrl === file.data?.url}
                      id={file.data?.handle as string}
                    />
                    <NameText ellipsis={{ tooltip: file.data?.metadata?.text }}>
                      {file.data?.metadata?.text}
                    </NameText>
                  </BoxFlexColumn>
                </motion.div>
              ))}
            </StyledAnimatePresence>
          </Panel>
        </StyledCollapse>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default MediaLibraryTabSDGenerate;
