/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/templates.thunk";
import { Palette, PaletteTypes } from "app/types/template";
import { FetchStatus, Template } from "app/types";
import { fetchingStatus } from "app/utils/helpers";

const INITIAL_STATE: {
  currentTemplate?: Template;
  currentPalettes: Palette[];
  createPaletteStatus: FetchStatus;
  palettesStatus: FetchStatus;
  createPaletteResult?: Palette;
} = {
  currentTemplate: undefined,
  currentPalettes: [],
  createPaletteStatus: fetchingStatus.idle as FetchStatus,
  palettesStatus: fetchingStatus.idle as FetchStatus,
  createPaletteResult: undefined
};

export const templatesSlice = createSlice({
  name: "Templates",
  initialState: INITIAL_STATE,
  reducers: {
    setCreatePaletteToIdle(state) {
      state.createPaletteStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    replaceCurrentTemplate(state, action) {
      state.currentTemplate = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getTemplateByIdRequest.pending, (state) => {
      state.currentTemplate = undefined;
    });
    builder.addCase(asyncThunks.getTemplateByIdRequest.fulfilled, (state, action) => {
      state.currentTemplate = action.payload;
    });

    builder.addCase(asyncThunks.getPalettesRequest.pending, (state) => {
      state.palettesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getPalettesRequest.fulfilled, (state, action) => {
      state.currentPalettes = action.payload;
      state.palettesStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getPalettesRequest.rejected, (state) => {
      state.palettesStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.upsertPaletteRequest.pending, (state, action) => {
      state.createPaletteStatus = fetchingStatus.loading as FetchStatus;
      if (action.meta.arg.id) {
        const index = state.currentPalettes.findIndex(
          (palette) => palette.id === action.meta.arg.id
        );
        if (index < 0) {
          state.currentPalettes.push({ ...action.meta.arg, type: PaletteTypes.Custom } as Palette);
        } else {
          state.currentPalettes[index].colors = action.meta.arg.colors;
        }
      }
    });

    builder.addCase(asyncThunks.upsertPaletteRequest.rejected, (state) => {
      state.createPaletteStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.upsertPaletteRequest.fulfilled, (state, action) => {
      if (!action.meta.arg.id) {
        // if id  exist its upsert, otherwise create
        state.currentPalettes.unshift(action.payload);
      }
      state.createPaletteStatus = fetchingStatus.succeeded as FetchStatus;
      state.createPaletteResult = action.payload;
    });
  }
});

export default templatesSlice.reducer;

export const templatesActions = {
  getTemplatesRequest: asyncThunks.getTemplatesRequest,
  getTemplateByIdRequest: asyncThunks.getTemplateByIdRequest,
  postNeedTemplatesRequest: asyncThunks.postNeedTemplatesRequest,
  upsertPaletteRequest: asyncThunks.upsertPaletteRequest,
  getPalettesRequest: asyncThunks.getPalettesRequest,
  ...templatesSlice.actions
};
