import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Avatar, Button } from "@nextui-org/react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { getTimeAgo } from "app/utils/helpers";
import { ThemeMode } from "app/utils/theme";

const UrlButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.gray6};
  &:hover {
    text-decoration: underline;
  }
`;

interface CGNCommentProps {
  img: string;
  name: string;
  time: string;
  content: string;
}
const CGNComment = ({ img, name, time, content }: CGNCommentProps) => {
  const theme = useTheme();
  return (
    <H1_FlexRow gap="16px">
      <Avatar size="md" src={img} />
      <H1_FlexColumn gap="5px">
        <H1_FlexRow gap="5px">
          <H1_TextSmall color={theme.gray11}>{name}</H1_TextSmall>
          <H1_TextSmall color={theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5}>
            {getTimeAgo(time)}
          </H1_TextSmall>
        </H1_FlexRow>
        <H1_TextSmall
          whiteSpace="normal"
          color={theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5}
        >
          {content}
        </H1_TextSmall>
        <UrlButton>Replay to thread</UrlButton>
      </H1_FlexColumn>
    </H1_FlexRow>
  );
};

export default CGNComment;
