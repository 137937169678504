import { useEffect, useState } from "react";
import { Progress } from "antd";
import { useTheme } from "styled-components";

interface IncrementalLoaderProps {
  isFinished?: boolean;
  className?: string;
  strokeWidth?: number;
  strokeLinecap?: "butt" | "square" | "round";
}
const IncrementalLoader = ({
  isFinished,
  className,
  strokeWidth = 6,
  strokeLinecap = "butt"
}: IncrementalLoaderProps) => {
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (isFinished) {
      setProgress(100);
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [isFinished]);

  useEffect(() => {
    const startTime = Date.now();
    const endTime = startTime + 40000; // 40 seconds in milliseconds

    const updateProgress = () => {
      const currentTime = Date.now();
      // The use in dates to calculate the remaining time
      const remainingTime = Math.max(0, endTime - currentTime);
      // Calculate the new progress percentage based on the remaining time
      const newPercent = 95 - (remainingTime / 40000) * 95;
      // If the new percentage is greater than or equal to 95 and there's an interval running, clear it
      if (newPercent >= 95 && intervalId) {
        clearInterval(intervalId);
      }

      setProgress(newPercent);
    };

    setIntervalId(setInterval(updateProgress, 1000)); // Update every second

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <Progress
      type="line"
      trailColor={theme.blue1}
      strokeWidth={strokeWidth}
      strokeColor={theme.blue4}
      strokeLinecap={strokeLinecap}
      percent={progress}
      showInfo={false}
      className={className}
    />
  );
};

export default IncrementalLoader;
