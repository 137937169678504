import { DownOutlined } from "@ant-design/icons";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { MenuProps, Dropdown } from "antd";

import { ProtectionType } from "app/types";
import useVideoShareProtection from "app/components/VideoShareProtection/useVideoShareProtection";
import { useMemo } from "react";
import styled from "styled-components";

const CursoredTextMiddle = styled(H1_TextMiddle)`
  cursor: pointer;
`;

const CursoredDownOutlined = styled(DownOutlined)`
  cursor: pointer;
`;

const ShareOptionsDropDown = ({
  currentProtection,
  onChangeProtection
}: {
  currentProtection?: ProtectionType;
  onChangeProtection: (key: ProtectionType) => void;
}) => {
  const { protectionOptions } = useVideoShareProtection();

  const changeProtection: MenuProps["onSelect"] = ({ key }) => {
    if (onChangeProtection) {
      onChangeProtection(key as ProtectionType);
    }
  };

  const protectionHeadline = useMemo(() => {
    return protectionOptions.find((item) => item.key === (currentProtection || ProtectionType.None))
      ?.headline;
  }, [protectionOptions, currentProtection]);

  const items: MenuProps["items"] = protectionOptions.map((protectionItem) => ({
    label: (
      <H1_FlexRow gap="10px" justify="start" align="center">
        {protectionItem.icon}
        {protectionItem.headline}
      </H1_FlexRow>
    ),
    key: protectionItem.key
  }));

  return (
    <Dropdown
      className="dropdown-button-protection-options"
      getPopupContainer={() =>
        document.querySelector(`.dropdown-button-protection-options`) as HTMLElement
      }
      destroyPopupOnHide={true}
      menu={{
        items,
        inlineIndent: 0,
        selectable: true,
        defaultSelectedKeys: [currentProtection || ProtectionType.None],
        selectedKeys: currentProtection && [currentProtection],
        onSelect: changeProtection
      }}
    >
      <H1_FlexRow align="center" gap="10px" width="200px">
        <CursoredTextMiddle fontWeight={600}>{protectionHeadline}</CursoredTextMiddle>
        <CursoredDownOutlined />
      </H1_FlexRow>
    </Dropdown>
  );
};

export default ShareOptionsDropDown;
