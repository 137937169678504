import {
  $createRangeSelection,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_EDITOR
} from "lexical";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

import {
  $createVariableNode,
  VariableNode
} from "app/components/common/LexicalEditor/nodes/VariableNode";
import { VariableCommandPayload } from "app/components/common/LexicalEditor/plugins/types";
import constants from "app/components/common/LexicalEditor/plugins/constants";

export default function VariablePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VariableNode])) {
      throw new Error("VariablePlugins: VariableNode not registered on editor");
    }

    return editor.registerCommand<VariableCommandPayload>(
      constants.INSERT_VARIABLE_COMMAND,
      (payload) => {
        const { id } = payload;
        let selection = $getSelection();
        if (!selection) {
          const rangeSelection = $createRangeSelection();
          $setSelection(rangeSelection);
          selection = $getSelection();
        }

        if ($isRangeSelection(selection)) {
          const variableNode = $createVariableNode(id);
          selection.insertNodes([variableNode]);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
