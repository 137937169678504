/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode } from "react";
import { Dropdown, DropdownProps, MenuProps } from "antd";
import styled, { css } from "styled-components";

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const AlignText = styled.span`
  font-size: 0.875rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  line-height: 1.375rem;
`;

const StyledDropdown = styled(Dropdown)<{
  $noBorder?: boolean;
  title?: string;
  $isTransparent?: boolean;
  $width?: string;
}>`
  cursor: pointer;
  padding: ${(props) => (props.title ? "0.313rem 1rem" : "0.313rem")};
  border-width: 1px;
  border-style: solid;
  background-color: ${(props) => (props.$isTransparent ? "transparent" : props.theme.gray1)};
  border-color: ${(props) =>
    props.$isTransparent || props.$noBorder ? "transparent" : props.theme.gray5};
  color: ${(props) => props.theme.gray9};
  fill: ${(props) => props.theme.gray9};
  width: ${(props) => props.$width};
  &:hover {
    color: ${(props) => (props.$isTransparent ? props.theme.blue4 : props.theme.pink3)};
    fill: ${(props) => (props.$isTransparent ? props.theme.blue4 : props.theme.pink3)};
    ${({ $noBorder, $isTransparent }) => {
      if ($noBorder) {
        return css`
          border-color: "transparent";
        `;
      }
      if ($isTransparent) {
        return css`
          border-color: ${(props) => props.theme.blue4};
        `;
      }
      return css`
        border-color: ${(props) => props.theme.pink3};
      `;
    }}
  }
  &:active {
    color: ${(props) => (props.$isTransparent ? props.theme.blue2 : props.theme.pink2)};
    fill: ${(props) => (props.$isTransparent ? props.theme.blue2 : props.theme.pink2)};
    ${({ $noBorder, $isTransparent }) => {
      if ($noBorder) {
        return css`
          border-color: "transparent";
        `;
      }
      if ($isTransparent) {
        return css`
          border-color: ${(props) => props.theme.blue2};
        `;
      }
      return css`
        border-color: ${(props) => props.theme.pink2};
      `;
    }}
  }
  &[disabled] {
    cursor: not-allowed;
    color: ${(props) => props.theme.gray6};
    fill: ${(props) => props.theme.gray6};
    border-color: ${(props) =>
      props.$isTransparent || props.$noBorder ? "transparent" : props.theme.gray5};
    background-color: ${(props) => (props.$isTransparent ? "transparent" : props.theme.gray3)};
  }
`;

export interface H1_DropdownProps extends DropdownProps {
  className?: string;
  width?: string;
  title?: string;
  isTransparent?: boolean;
  onlyIcon?: boolean;
  withChevron?: boolean;
  withCaret?: boolean;
  disabled?: boolean;
  menu: MenuProps;
  icon?: ReactNode;
}

export const H1_Dropdown = ({
  icon,
  withChevron,
  withCaret,
  className,
  width,
  isTransparent,
  onlyIcon = false,
  menu,
  disabled,
  title,
  ...props
}: H1_DropdownProps) => {
  return (
    <StyledDropdown
      disabled={disabled}
      $width={width}
      $isTransparent={isTransparent}
      $noBorder={onlyIcon}
      className={className}
      menu={menu}
      {...props}
    >
      <FlexRow>
        <FlexRow>
          {!!icon && icon}
          {!onlyIcon && title && <AlignText>{title}</AlignText>}
        </FlexRow>
        {!onlyIcon && withChevron && !withCaret && <i className="fa-regular fa-chevron-down" />}
        {!onlyIcon && withCaret && !withChevron && <i className="fa-solid fa-caret-down" />}
      </FlexRow>
    </StyledDropdown>
  );
};

H1_Dropdown.defaultProps = {
  isTransparent: false,
  withChevron: true,
  withCaret: false,
  onlyIcon: false,
  className: "",
  icon: undefined
};
