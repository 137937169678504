import React, { SyntheticEvent, useEffect, useRef } from "react";
import styled from "styled-components";

const StyledVideo = styled.video`
  object-fit: contain;
  height: 135px;
  width: 100%;
  border-radius: 10px;
`;

interface LazyVideoProps {
  isVisible: boolean;
  onChangeVisible: (visible: boolean) => void;
  src: string;
  onErrorVideo?: ({ currentTarget }: SyntheticEvent<HTMLVideoElement, Event>) => void;
  dataImageHandle: string;
}

const LazyVideo: React.FC<LazyVideoProps> = ({
  isVisible,
  onChangeVisible,
  src,
  dataImageHandle,
  onErrorVideo
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          // Check if the element is intersecting
          if (entry.isIntersecting) {
            onChangeVisible(true); // Set the element as visible
            // Stop observing the current element
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null, // Adjust if your images are in a scrollable container
        rootMargin: "100px"
      }
    );

    const currentVideoRef = videoRef.current;
    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }

    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
    };
  }, []);

  return (
    <StyledVideo
      data-image-handle={dataImageHandle}
      onError={onErrorVideo}
      ref={videoRef as React.RefObject<HTMLVideoElement>}
      src={isVisible ? src : undefined}
    />
  );
};

export default LazyVideo;
