import { defineMessages } from "react-intl";

export const screenRecordingMessages = defineMessages({
  modalTitle: {
    id: `screen-recording.screen-recording-modal.modal-title`,
    defaultMessage: "Your screen recording is ready"
  },
  uploadButton: {
    id: `screen-recording.screen-recording-modal.upload-button`,
    defaultMessage: "Use Recording"
  },
  deleteButton: {
    id: `screen-recording.screen-recording-modal.delete-button`,
    defaultMessage: "Delete"
  },
  trimButton: {
    id: `screen-recording.screen-recording-modal.trim-button`,
    defaultMessage: "Trim video"
  },
  trimFinishButton: {
    id: `screen-recording.screen-recording-modal.trim-finish-button`,
    defaultMessage: "Trim"
  },
  cancelTrimButton: {
    id: `screen-recording.screen-recording-modal.cancel-trim-button`,
    defaultMessage: "Cancel"
  },
  recordAgain: {
    id: `screen-recording.screen-recording-modal.record-again-button`,
    defaultMessage: "Record again"
  },
  cancelButton: {
    id: `screen-recording.screen-recording-modal.cancel-trim`,
    defaultMessage: "Cancel"
  },
  downloadButton: {
    id: `screen-recording.screen-recording-modal.download-button`,
    defaultMessage: "Download"
  },

  failedUploadError: {
    id: `screen-recording.screen-recording-modal.notification-error`,
    defaultMessage: "Failed upload recorded video"
  },
  trimFreezeOption: {
    id: `screen-recording.screen-recording-modal.trim-freeze-option`,
    defaultMessage: "Freeze"
  },
  trimLoopOption: {
    id: `screen-recording.screen-recording-modal.trim-loop-option`,
    defaultMessage: "Loop"
  },
  trimAdpatSpeedOption: {
    id: `screen-recording.screen-recording-modal.trim-adapt-speed-option`,
    defaultMessage: "Adapt speed"
  }
});

export const screenRecordingUploaderMessages = defineMessages({
  uploadButton: {
    id: `screen-recording.screen-recording-uploader.upload-button`,
    defaultMessage: "record"
  },
  uploadText: {
    id: `screen-recording.screen-recording-uploader.upload-text`,
    defaultMessage: "Screen Capture"
  }
});

export const screenRecordingSessionMessages = defineMessages({
  waitingTitle: {
    id: `screen-recording.session.waiting-title`,
    defaultMessage: "Your recording will start in..."
  },
  waitingSubTitle: {
    id: `screen-recording.session.waiting-subtitle`,
    defaultMessage: "Please wait a few sec..."
  },
  cancelButton: {
    id: `screen-recording.session.cancel-button`,
    defaultMessage: "Cancel"
  }
});

export const screenRecordingInitialSessionMessages = defineMessages({
  title: {
    id: `screen-recording.initial-session.title`,
    defaultMessage: "Record Screen"
  },
  subtitle: {
    id: `screen-recording.initial-session.subtitle`,
    defaultMessage:
      "Record your screen to show{br}product demos, training sessions,{br}tutorials, lectures and use{br}within video."
  },
  audioError: {
    id: `screen-recording.initial-session.audio-error`,
    defaultMessage: "Unable to load audio.{br} please try again or select a different voice."
  },
  startRecordingButton: {
    id: `screen-recording.initial-session.start-recording-button`,
    defaultMessage: "Start recording"
  },
  preparingAudio: {
    id: `screen-recording.initial-session.preparing-audio`,
    defaultMessage: "Preparing audio"
  }
});

export const screenRecordingUploadModalMessages = defineMessages({
  title: {
    id: `screen-recording.upload-modal.title`,
    defaultMessage: "Preparing scene with recording"
  }
});
