import { createContext, useContext, MutableRefObject } from "react";

export interface RecorderContextWrapperOptions {
  maxRecordingDurationSec?: number;
  minRecordingDurationSec?: number;
  counter?: boolean;
  timerDown?: boolean;
  recordOptions?: MediaRecorderOptions;
}

export interface RecorderContextMediaMenu {
  key: string;
  value: string;
  label: string;
}

interface RecorderContextProps {
  isRecording: boolean;
  startCapture: (constraints?: MediaStreamConstraints) => Promise<void>;
  handleRecordingToggle: () => Promise<void>;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  videoFile: File | null;
  error: Error | null | unknown;
  reset: (dontClearError?: boolean) => void;
  ready: boolean;
  microphoneDevices: MediaDeviceInfo[];
  cameraDevices: MediaDeviceInfo[];
  counter?: string;
  timeInSec: number;
  getMediaDevices: () => Promise<void>;
  stopCapture: () => void;
  resetOptions: (options: RecorderContextWrapperOptions) => void;
  microphoneMenu: RecorderContextMediaMenu[];
  cameraMenu: RecorderContextMediaMenu[];
}

const RecorderContext = createContext<RecorderContextProps | undefined>(undefined);

const useRecorderContext = () => {
  const recorderContext = useContext(RecorderContext);
  if (!recorderContext) {
    throw new Error("Out of context");
  }

  return recorderContext;
};

export default { RecorderContext, useRecorderContext };
