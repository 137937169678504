import React, { ReactNode, useState } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { Location, TargetAudience, TemplateSelection } from "app/types";
import { useNavigate } from "react-router-dom";
import ZoomModal from "app/pages/HomePage/ZoomModal";
import { recipesActions } from "app/store/slices/recipes.slice";

const BackgroundImageFlexColumn = styled(H1_FlexColumn)`
  border-radius: 4px 4px 0 0;
  max-height: 160px;
  img {
    object-fit: contain;
    position: absolute;
    bottom: 0;
    height: auto;
    border-radius: 4px 4px 0 0;
    aspect-ratio: 255 / 160;
  }
`;

const WhiteFlexRow = styled(H1_FlexRow)`
  background: white;
  border-radius: 0 0 4px 4px;
`;

export interface SocialMediaCardProps {
  type: TargetAudience;
  image: ReactNode;
  text: string;
}

const SocialMediaCard = ({ type, image, text }: SocialMediaCardProps) => {
  const [isZoomModalOpen, setIsZoomModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onCardClick = () => {
    const SOURCE = `SocialMediaCard-${type}`;
    dispatch(
      analyticsEvents.newVideo({
        source: SOURCE,
        type: NewVideoType.videoWizard
      })
    );
    switch (type) {
      case TargetAudience.training:
        dispatch(recipesActions.setTemplateSelection(TemplateSelection.LnD));
        navigate(Location.Templates);
        break;
      case TargetAudience.creators:
        dispatch(recipesActions.setTemplateSelection(TemplateSelection.Product));
        navigate(Location.Templates);
        break;
      case TargetAudience.sales:
        dispatch(recipesActions.setTemplateSelection(TemplateSelection.Sales));
        navigate(Location.Templates);
        break;
      case TargetAudience.developers:
        navigate(Location.Playground);
        break;
    }
  };

  const onCloseZoom = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setIsZoomModalOpen(false);
  };

  return (
    <H1_FlexColumn width="255px" position="relative" onClick={onCardClick}>
      <ZoomModal visible={isZoomModalOpen} closeModal={onCloseZoom} />
      <H1_FlexColumn position="relative" height="10rem">
        <BackgroundImageFlexColumn
          position="absolute"
          height="10rem"
          width="100%"
          justify="center"
          align="center"
          overflow="hidden"
        >
          {image}
        </BackgroundImageFlexColumn>
      </H1_FlexColumn>
      <WhiteFlexRow height="2.8125rem" align="center" padding="0 1.25rem">
        <H1_TextSmall>{text}</H1_TextSmall>
      </WhiteFlexRow>
    </H1_FlexColumn>
  );
};

export default SocialMediaCard;
