import { defineMessages } from "react-intl";

export const channelsMessages = defineMessages({
  channelsHeadline: {
    id: "channels.headline",
    defaultMessage: "Channels"
  },
  noVideosTitle: {
    id: `channels.no-videos-title`,
    defaultMessage: "Add Videos to channel"
  },
  noVideosDescription: {
    id: `channels.no-videos-description`,
    defaultMessage: "There are no videos in this channel. Create first video in channel."
  },
  noVideosButtonText: {
    id: `channels.no-videos-button-text`,
    defaultMessage: "New Video"
  },
  videoAnalyticsPopoverTitle: {
    id: "channels.side-menu.popover.analytics.title",
    defaultMessage: "Video Analytics"
  },
  videoAnalyticsPopoverViewsTitle: {
    id: "channels.side-menu.popover.analytics.section.views.title",
    defaultMessage: "Views"
  },
  videoAnalyticsPopoverViewsTooltip: {
    id: "channels.side-menu.popover.analytics.section.views.explanetion",
    defaultMessage: "Total views for the video"
  },
  videoAnalyticsPopoverViewersTitle: {
    id: "channels.side-menu.popover.analytics.section.viewers.title",
    defaultMessage: "Viewers"
  },
  videoAnalyticsPopoverViewersTooltip: {
    id: "channels.side-menu.popover.analytics.section.viewers.explanetion",
    defaultMessage: "Total count of distinct viewers for the video"
  },
  videoAnalyticsPopoverRemixedTitle: {
    id: "channels.side-menu.popover.analytics.section.remixed.title",
    defaultMessage: "Video created from"
  },
  videoAnalyticsPopoverRemixedTooltip: {
    id: "channels.side-menu.popover.analytics.section.remixed.explanetion",
    defaultMessage: "Count of replicated videos from this video"
  },
  videoAnalyticsPopoverViewersUpdatedAt: {
    id: "channels.side-menu.popover.analytics.updated_at",
    defaultMessage: "Last updated at {updated}"
  },
  copyLinkButtonTooltip: {
    id: "channels.copy-link-tooltip-button",
    defaultMessage: "Copy link"
  },
  forkButtonTooltip: {
    id: "channels.fork-tooltip-button",
    defaultMessage: "Duplicate video"
  }
});
