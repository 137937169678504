import React, { useCallback, useRef } from "react";

interface UseRefCallbackParams<T> {
  ref?: React.MutableRefObject<T>;
  onMount?: (node: T) => void;
  onUnmount?: (node: T) => void;
  debug?: boolean;
}

// Generic syntax inspired by: https://stackoverflow.com/questions/32308370/what-is-the-syntax-for-typescript-arrow-functions-with-generics
export const useRefCallback = <T,>({
  ref,
  onMount,
  onUnmount,
  debug = false
}: UseRefCallbackParams<T>) => {
  // https://medium.com/welldone-software/usecallback-might-be-what-you-meant-by-useref-useeffect-773bc0278ae
  const nodeRef = ref ?? useRef<T | null>(null);

  const setRef = useCallback(
    (node: T) => {
      if (nodeRef.current) {
        // Make sure to cleanup any events/references added to the last instance
        if (debug) console.log("will trigger on unmount");
        if (onUnmount) onUnmount(nodeRef.current);
      }

      nodeRef.current = node;

      if (nodeRef.current) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        if (debug) console.log("will trigger on mount");
        if (onMount) onMount(nodeRef.current);
      }
    },
    [onUnmount, onMount]
  );

  return setRef;
};
