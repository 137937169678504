import { defineMessages } from "react-intl";

export const WebhooksPageMessages = defineMessages({
  title: {
    id: "webhooks-page.title",
    defaultMessage: "Webhooks"
  },
  createWebhookLabel: {
    id: "webhooks-page.create-webhook.label",
    defaultMessage: "Create Webhook"
  },
  status: {
    id: "webhooks-page.form.status",
    defaultMessage: "Status"
  },
  secret: {
    id: "webhooks-page.form.secret",
    defaultMessage: "Secret"
  },
  revealSecret: {
    id: "webhooks-page.form.reveal-secret",
    defaultMessage: "Reveal secret"
  },
  save: {
    id: "webhooks-page.form.save",
    defaultMessage: "Save"
  }
});
export const WebhooksTableMessages = defineMessages({
  tableNameColumn: {
    id: "webhooks-page.table.name",
    defaultMessage: "Name"
  },
  tableDescriptionColumn: {
    id: "webhooks-page.table.description",
    defaultMessage: "Description"
  },
  tableUpadtedColumn: {
    id: "webhooks-page.table.updated",
    defaultMessage: "Updated"
  },
  tableStatusColumn: {
    id: "webhooks-page.table.status",
    defaultMessage: "Status"
  },
  tableUrlColumn: {
    id: "webhooks-page.table.url",
    defaultMessage: "Url"
  },
  emptyWebhookButtonPage: {
    id: "webhooks-page.table.empty-webhook-button",
    defaultMessage: "Create webhook"
  },
  emptyWebhookText: {
    id: "webhooks-page.table.empty-webhook-text",
    defaultMessage: "No webhooks"
  },
  emptyWebhookDescription: {
    id: "webhooks-page.table.empty-webhook-description",
    defaultMessage: "There are no webhooks in the table"
  }
});
export const CreateWebhookMessages = defineMessages({
  title: {
    id: "webhooks-page.modal.title",
    defaultMessage: "Create Webhook"
  },
  subtitle: {
    id: "webhooks-page.modal.subtitle",
    defaultMessage: "Here you define a webhook for getting updates about video ready or failed"
  },
  saveBtn: {
    id: "webhooks-page.modal.save-button",
    defaultMessage: "Save"
  }
});

export const WebhookFormMessages = defineMessages({
  title: {
    id: "webhooks-page.form.title",
    defaultMessage: "Endpoint name"
  },
  description: {
    id: "webhooks-page.form.description",
    defaultMessage: "Description"
  },
  endpointUrl: {
    id: "webhooks-page.form.endpoint-url",
    defaultMessage: "Endpoint URL"
  },
  events: {
    id: "webhooks-page.form.events",
    defaultMessage: "Events"
  },
  eventPlaceholder: {
    id: "webhooks-page.form.event-placeholder",
    defaultMessage: "Select a single event"
  },
  videoReady: {
    id: "webhooks-page.form.video-ready",
    defaultMessage: "Video ready"
  },
  videoFailed: {
    id: "webhooks-page.form.video-failed",
    defaultMessage: "Video failed"
  }
});
