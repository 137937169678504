import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import AvatarsAndVoicesDrawerSingleElement from "app/components/editor/sideDrawers/CharacterDrawer/AvatarsAndVoicesDrawerSingleElement";
import GoBackButton from "app/components/common/GoBackButton";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { ThemeMode } from "app/utils/theme";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button } from "@nextui-org/react";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { ChangeAttribute, CharacterRecord, Voice } from "app/types";
import { charactersActions } from "app/store/slices/characters.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus, isMediaPlaying } from "app/utils/helpers";
import * as Sentry from "@sentry/react";
import { ListenStatus } from "app/store/slices/voices.slice";

const ReplaceButtonContainer = styled(H1_FlexRow)`
  position: absolute;
  top: calc(70px - 16px);
  right: -23px;
  z-index: 3;
  button {
    background-color: ${({ theme }) => theme.gray5};
    color: ${({ theme }) => theme.gray8};
  }
`;

const HiddenAudioPlayer = styled(H1_FlexRow)`
  display: none;
`;

export interface AvatarsAndVoicesDrawerProps {
  right: string;
  width: string;
}

const AvatarsAndVoicesDrawer = ({ right, width }: AvatarsAndVoicesDrawerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [playingVoiceId, setPlayingVoiceId] = useState<string | undefined>(undefined);
  const [loadingVoiceId, setLoadingVoiceId] = useState<string | undefined>(undefined);
  const [previewItem, setPreviewItem] = useState<Voice | undefined>(undefined);
  const { editorDrawerOpen, openDrawer } = useDrawer();
  const { scene } = useSelectedScene();
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const audioRef = useRef<HTMLAudioElement>(null);

  const previewItemId = previewItem?.id;

  const changeCinematicCharactersStatus = useAppSelector(
    (state) => state.characters.changeCinematicCharactersStatus
  );
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);

  const isCinematicCharactersLoading = changeCinematicCharactersStatus === fetchingStatus.loading;
  const charactersAssets = scene?.layout.assets.character || [];

  const isDrawerActive = editorDrawerOpen === Drawer.AvatarsAndVoices;
  const isLightMode = theme.mode === ThemeMode.Light;
  const isAllLoading = isCinematicCharactersLoading || isLoading;
  const isPlayingAudio = footerAudioPlayer.status === ListenStatus.playing;

  useEffect(() => {
    if (isDrawerActive) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300); // Drawer animation time
    } else {
      setIsLoading(true);
      setPreviewItem(undefined);
      setPlayingVoiceId(undefined);
      setLoadingVoiceId(undefined);
    }
  }, [isDrawerActive]);

  useEffect(() => {
    if (isDrawerActive && isPlayingAudio) {
      setPlayingVoiceId(undefined);
      setLoadingVoiceId(undefined);
      if (audioRef.current && isMediaPlaying(audioRef.current)) {
        audioRef.current.pause();
      }
    }
  }, [isPlayingAudio, isDrawerActive]);

  const onClickBackToScene = () => {
    openDrawer(Drawer.Scene);
  };

  const onClickReplacePosition = (index: number) => {
    const firstCharacter = charactersAssets[index];
    const secondCharacter = charactersAssets[index + 1];
    const firstVoiceAssetKey = firstCharacter.restrictions?.voice_key || "voice";
    const secondVoiceAssetKey = secondCharacter.restrictions?.voice_key || "voice";
    const firstCharacterRecord = scene?.attributes?.character?.[
      firstCharacter.key
    ] as CharacterRecord;
    const secondCharacterRecord = scene?.attributes?.character?.[
      secondCharacter.key
    ] as CharacterRecord;
    const firstVoiceRecord = scene?.attributes?.voice?.[firstVoiceAssetKey];
    const secondVoiceRecord = scene?.attributes?.voice?.[secondVoiceAssetKey];
    if (!firstCharacterRecord || !secondCharacterRecord) {
      console.error("One or both characters do not exist");
      Sentry.captureException(
        `Replace cinematic avatars failed. One or both characters do not exist ${firstCharacter.key} ${secondCharacter.key}`
      );
      return;
    }
    const obj: ChangeAttribute = {
      character: {
        [firstCharacter.key]: {
          source_character_id: secondCharacterRecord.character_id,
          preset_override: secondCharacterRecord.preset_override
        },
        [secondCharacter.key]: {
          source_character_id: firstCharacterRecord.character_id,
          preset_override: firstCharacterRecord.preset_override
        }
      },
      voice: {
        [firstVoiceAssetKey]: {
          voice_id: secondVoiceRecord?.voice_id
        },
        [secondVoiceAssetKey]: {
          voice_id: firstVoiceRecord?.voice_id
        }
      }
    };
    dispatch(charactersActions.updateAttributesOnAllScenesRequest({ attributes: obj }));
  };

  const onClickVoicePlay = (e: React.MouseEvent, item: Voice) => {
    e.stopPropagation();
    if (item.id === previewItemId) {
      const audio = audioRef.current as HTMLAudioElement;
      if (isMediaPlaying(audio)) {
        audio.pause();
      } else {
        setPlayingVoiceId(previewItemId);
        audio.play();
      }
    } else {
      setPreviewItem(item);
      setLoadingVoiceId(item.id);
    }
  };

  const onPlayPreview = () => {
    setPlayingVoiceId(previewItemId);
    setLoadingVoiceId(undefined);
    audioRef.current?.play();
  };

  const onPausePreview = () => {
    setPlayingVoiceId(undefined);
    setLoadingVoiceId(undefined);
  };

  return (
    <H1_FlexColumn>
      <FlexDrawer right={right} active={isDrawerActive} width={width}>
        <H1_FlexRow flex="0 0 30px" margin="0 0 10px 0" align="center" justify="space-between">
          <GoBackButton
            onClick={onClickBackToScene}
            fontSize="14px"
            label={intl.formatMessage(characterDrawerMessages.backToScene)}
            color={isLightMode ? theme.gray6 : theme.gray8}
          />
        </H1_FlexRow>
        <ConditionalRender condition={isAllLoading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={!isAllLoading}>
          <H1_FlexRow wrap="wrap" gap="14px">
            {charactersAssets.map((characterAsset, index) => (
              <H1_FlexRow position="relative" key={characterAsset.key}>
                <AvatarsAndVoicesDrawerSingleElement
                  characterAsset={characterAsset}
                  onClickVoicePlay={onClickVoicePlay}
                  previewItemId={previewItemId}
                  playingVoiceId={playingVoiceId}
                  loadingVoiceId={loadingVoiceId}
                />
                <ConditionalRender condition={index < charactersAssets.length - 1}>
                  <ReplaceButtonContainer>
                    <Button
                      size="sm"
                      isIconOnly
                      startContent={
                        <H1_Icon isCursorPointer size="20px" icon="fas fa-arrows-rotate" />
                      }
                      onClick={() => onClickReplacePosition(index)}
                    />
                  </ReplaceButtonContainer>
                </ConditionalRender>
              </H1_FlexRow>
            ))}
          </H1_FlexRow>
        </ConditionalRender>
      </FlexDrawer>
      <HiddenAudioPlayer>
        <audio
          ref={audioRef}
          src={previewItem?.sample_url as string}
          onCanPlay={onPlayPreview}
          onPause={onPausePreview}
          onEnded={() => setPlayingVoiceId(undefined)}
        >
          <track kind="captions" />
        </audio>
      </HiddenAudioPlayer>
    </H1_FlexColumn>
  );
};

export default AvatarsAndVoicesDrawer;
