import styled, { useTheme } from "styled-components";
import { message } from "antd";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React from "react";
import { H1_TextHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useCopy from "app/hooks/useCopy";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { validateCreateVideo } from "app/components/editor/validations";
import { Template } from "app/types";
import UiActions from "app/store/actions/ui.actions";
import { draftsActions } from "app/store/slices/drafts.slice";
import useErrors from "app/hooks/useErrors";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { useIntl } from "react-intl";
import { fetchingStatus } from "app/utils/helpers";
import usePermissions from "app/hooks/usePermissions";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const PurpleFlexRow = styled(H1_FlexRow)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.blue1};
`;

const GrayFlexRow = styled(H1_FlexRow)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.gray4};
`;

const UnderlineButton = styled(Button)`
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
  color: ${({ theme }) => theme.gray8};
  span {
    color: ${({ theme }) => theme.gray8};
  }
`;

interface ShareWorkflowModalProps {
  visible: boolean;
  onClose: () => void;
}

const ShareWorkflowModal = ({ visible, onClose }: ShareWorkflowModalProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { notifyError } = useErrors();
  const { triggerCopy } = useCopy();
  const showPaymentModal = useUpgradeModal();
  const { isWriteWorkspaceAllow } = usePermissions();

  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const limits = useAppSelector(getLimits);
  const workflowId = useAppSelector((state) => state.drafts.currentDraft.workflow_id);
  const template: Template | undefined = useAppSelector((state) => state.templates.currentTemplate);

  const workflow = useAppSelector((state) => state.drafts.currentDraft.workflow);
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const createSceneStatus = useAppSelector((state) => state.scenes.createSceneStatus);
  const deleteSceneStatus = useAppSelector((state) => state.scenes.deleteSceneStatus);
  const scenesStatusLoading = scenesStatus === fetchingStatus.loading;
  const deleteSceneLoading = deleteSceneStatus === fetchingStatus.loading;
  const createSceneStatusLoading = createSceneStatus === fetchingStatus.loading;
  const disabled =
    !template ||
    !draft ||
    !isWriteWorkspaceAllow ||
    deleteSceneLoading ||
    scenesStatusLoading ||
    createSceneStatusLoading ||
    workflowLiveMode;

  const link = `${window.location.origin}/workflows/${workflowId}`;

  const onClickPublish = () => {
    const { errors, sceneIdsErrors } = validateCreateVideo(
      draft,
      scenes,
      template as Template,
      intl,
      limits
    );
    if (Object.keys(sceneIdsErrors).length) {
      dispatch(UiActions.setValidationScroll(true));
      dispatch(UiActions.setSceneErrors(sceneIdsErrors));
    }
    if (Object.keys(errors).length) {
      notifyError(errors);
      // dispatch(googleEvents.createVideo({ draftId: draft.id as string, success: false }));
    } else if (template?.pro) {
      const upgradeRequired = showPaymentModal({
        source: "upgrade_publish_workflow_share_modal",
        requestedPlans: template?.plans ?? []
      });

      if (!upgradeRequired) {
        dispatch(
          draftsActions.publishWorkflowRequest({
            workflowId: draft.workflow_id as string
          })
        );
      }
    } else {
      dispatch(
        draftsActions.publishWorkflowRequest({
          workflowId: draft.workflow_id as string
        })
      );
    }
  };

  const onClickCopyLink = () => {
    triggerCopy({ copyContent: link, shouldNotify: false });
    message.success("Copied link to clipboard");
  };
  return (
    <StyledModal
      width="858px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      closeIcon={<CloseModalIcon />}
      destroyOnClose
      $gap="13px"
      $padding="40px"
    >
      <H1_TextHeadline>Share video form</H1_TextHeadline>
      <ConditionalRender condition={!disabled && !workflow?.published}>
        <PurpleFlexRow align="center" gap="10px" height="40px" padding="8px">
          <H1_Icon icon="far fa-triangle-exclamation" color={theme.gray8} />
          <H1_TextMiddle color={theme.gray8}>This video form is not published yet.</H1_TextMiddle>
          <ConditionalRender condition={!disabled}>
            <UnderlineButton type="link" onClick={onClickPublish}>
              Click to publish latest version
            </UnderlineButton>
          </ConditionalRender>
        </PurpleFlexRow>
      </ConditionalRender>
      <GrayFlexRow align="center" gap="10px" padding="20px" height="64px" justify="space-between">
        <H1_TextMiddle color={theme.gray8}>{link}</H1_TextMiddle>
        <Button
          color="primary"
          startContent={<i className="far fa-share" />}
          onClick={onClickCopyLink}
        >
          Copy Link
        </Button>
      </GrayFlexRow>
    </StyledModal>
  );
};

export default ShareWorkflowModal;
