import ConditionalRender from "app/components/common/ConditionalRender";
import React from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Video, VideoStatusEnum } from "app/types";
import { Button } from "@nextui-org/react";
import { getTimeAgo } from "app/utils/helpers";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import useDownload from "app/hooks/useDownload";
import useCopyLink from "app/hooks/useCopyLink";
import { getVideoByDataStoreContentId } from "app/store/selectorsV2/videos.selectors";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

interface WorkflowTableStatusInterface {
  dataStoreContentId: string;
  rowHover: string;
  createdAt: string;
}

const SOURCE = "workflow_csv";

const WorkflowTableModified = ({
  dataStoreContentId,
  rowHover,
  createdAt
}: WorkflowTableStatusInterface) => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const { openDownloadModal } = useDownload();
  const { copyLink } = useCopyLink();
  const currentVideo: Video | undefined = useAppSelector((state) =>
    getVideoByDataStoreContentId(state, dataStoreContentId)
  );

  const isVideoCompleted =
    currentVideo?.status === VideoStatusEnum.Published ||
    currentVideo?.status === VideoStatusEnum.Ready;
  const isHoverDataDisplayed = rowHover === dataStoreContentId && isVideoCompleted;
  const onClickWatchVideo = () => {
    dispatch(analyticsEvents.videoPlay({ source: SOURCE, videoId: currentVideo?.id }));
    openModal(ModalName.videoReady, { reqId: currentVideo?.req_id });
  };

  const onCopy = () => {
    copyLink(SOURCE, currentVideo as Video, currentVideo?.draft_id as string);
  };

  const onDownload = () => {
    openDownloadModal({
      source: SOURCE,
      video: currentVideo,
      backModal: ModalName.videoReady
    });
  };

  return (
    <>
      <ConditionalRender condition={isHoverDataDisplayed}>
        <H1_FlexRow width="220px" align="center" gap="10px">
          <Button
            startContent={<i className="fas fa-play" />}
            color="primary"
            onClick={onClickWatchVideo}
          >
            Watch
          </Button>
          <Button isIconOnly color="primary" onClick={onDownload}>
            <i className="fas fa-circle-down" />
          </Button>
          <Button isIconOnly color="primary" onClick={onCopy}>
            <i className="fas fa-link" />
          </Button>
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={!isHoverDataDisplayed}>
        <H1_FlexRow width="220px" align="center" flex="0 0 220px">
          <H1_TextSmall>{getTimeAgo(createdAt)}</H1_TextSmall>
        </H1_FlexRow>
      </ConditionalRender>
    </>
  );
};

export default WorkflowTableModified;
