import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { urlModalUploaderMessages, urlTypeErrors } from "app/components/editor/documents/messages";
import { DataType, ImportDataStoreRequest } from "app/types";
import React, { useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import { isValidUrl } from "@reduxjs/toolkit/src/query/utils/isValidUrl";
import { useTheme } from "styled-components";
import { Button, Input } from "@nextui-org/react";

const NextButton = styled(Button)`
  min-width: 130px;
  height: 38px;
`;
const UploadDragWrapper = styled.div<{ $background: string }>`
  display: flex;
  width: 100%;
  .ant-upload.ant-upload-drag {
    background: ${(props) => props.$background};
    border-radius: 9px;
  }
  & > span {
    height: 276px;
    width: 100%;
  }
  border-radius: 10px;
`;

const UrlInput = styled(Input)<{ $isError: boolean }>`
  width: 100%;
  flex-shrink: 0;
  margin-top: 12px;
  border-color: ${(props: { $isError: boolean }) => (props.$isError ? "red" : "transparent")};
`;

export interface UploadContentProps {
  uploadSucceeded: (importRequest: ImportDataStoreRequest) => void;
}

const UploadUrlContent = ({ uploadSucceeded }: UploadContentProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const [validUrl, setValidUrl] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const onInputChange = async (e: React.BaseSyntheticEvent<HTMLInputElement>) => {
    const { value } = e.target;
    try {
      if (isValidUrl(value)) {
        setValidUrl(value);
        setIsError(false);
      } else {
        setValidUrl(undefined);
        setIsError(true);
      }
    } catch (e) {
      setValidUrl(undefined);
      setIsError(true);
    }
  };
  const onClickNext = () => {
    const urlRequest: ImportDataStoreRequest = {
      url: validUrl,
      type: DataType.url
    };
    uploadSucceeded(urlRequest);
  };
  return (
    <UploadDragWrapper $background={theme.gray2}>
      <H1_FlexColumn height="100%" justify="space-between" width="100%">
        <H1_FlexColumn align="left">
          <H1_TextSmall
            color={theme.gray6}
            fontSize="16px"
            font-weight="500"
            line-height="32px"
            font-style="normal"
          >
            {intl.formatMessage(urlModalUploaderMessages.mainDescription)}
          </H1_TextSmall>
          <H1_TextXs
            color={theme.gray7}
            fontSize="14px"
            font-weight="400"
            line-height="20px"
            font-style="normal"
          >
            {intl.formatMessage(urlModalUploaderMessages.description)}
          </H1_TextXs>
          <UrlInput
            size="sm"
            className="urlInput"
            key="urlInput"
            placeholder={intl.formatMessage(urlModalUploaderMessages.urlInput)}
            // @ts-ignore: synth event
            onBlur={onInputChange}
            $isError={isError}
          />
          <ConditionalRender condition={isError}>
            <H1_TextXs whiteSpace="normal" fontSize="11px" lineHeight="16px" color="red">
              {intl.formatMessage(urlTypeErrors.urlTypeErrorHeadline)}
            </H1_TextXs>
          </ConditionalRender>
        </H1_FlexColumn>

        <H1_FlexRow width="100%" justify="flex-end">
          <NextButton color="primary" isDisabled={isError || !validUrl} onClick={onClickNext}>
            {intl.formatMessage(urlModalUploaderMessages.nextButton)}
          </NextButton>
        </H1_FlexRow>
      </H1_FlexColumn>
    </UploadDragWrapper>
  );
};

export default UploadUrlContent;
