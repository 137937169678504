import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";
import { ReviewStatus } from "app/types";
import { Button, Divider, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { useIntl } from "react-intl";
import { reviewStatusMessages } from "app/components/common/ReviewStatus/messages";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useState } from "react";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { ThemeMode } from "app/utils/theme";

const StyledButton = styled(Button)<{ $color: string; $textColor: string }>`
  background-color: ${({ $color }) => $color};
  color: ${({ $textColor }) => $textColor};
  height: 24px;
  justify-content: center;
  padding-left: 8px;
  width: 85px;
  text-align: center;
`;
interface ReviewStatusButtonProps {
  draftId: string;
}

const ReviewStatusButton = ({ draftId }: ReviewStatusButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const draft = useAppSelector((state) => draftsGlobalSelectors.selectById(state, draftId));
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const draftReviewStatus = draft?.review_status || currentDraft?.review_status;
  const isLightMode = theme.mode === ThemeMode.Light;

  const color = (theStatus?: ReviewStatus) => {
    const status = theStatus || draftReviewStatus;
    switch (status) {
      case ReviewStatus.Draft:
        return isLightMode ? "#a1a1aa" : "#52525B";
      case ReviewStatus.InReview:
        return isLightMode ? "#F7B750" : "#936316";
      case ReviewStatus.Pending:
        return isLightMode ? "#9353D3" : "#481878";
      case ReviewStatus.Approved:
        return isLightMode ? "#17C964" : "#0E793C";
      case ReviewStatus.Rejected:
        return isLightMode ? "#F31260" : "#920B3A";
      default:
        return isLightMode ? "#a1a1aa" : "#52525B";
    }
  };

  const text = (theStatus?: ReviewStatus) => {
    const status = theStatus || draftReviewStatus;
    switch (status) {
      case ReviewStatus.Draft:
        return formatMessage(reviewStatusMessages.draft);
      case ReviewStatus.InReview:
        return formatMessage(reviewStatusMessages.inReview);
      case ReviewStatus.Pending:
        return formatMessage(reviewStatusMessages.pending);
      case ReviewStatus.Approved:
        return formatMessage(reviewStatusMessages.approved);
      case ReviewStatus.Rejected:
        return formatMessage(reviewStatusMessages.rejected);
      default:
        return formatMessage(reviewStatusMessages.draft);
    }
  };

  const onClickReviewStatus = (newStatus: ReviewStatus) => {
    dispatch(
      draftsActions.patchDraftRequest({
        draftId,
        operations: [{ op: "replace", path: "review_status", value: newStatus }]
      })
    );
    setIsOpen(false);
  };

  return (
    <Popover
      placement="bottom-start"
      showArrow={false}
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      classNames={{ content: "p-0" }}
    >
      <PopoverTrigger>
        <StyledButton $color={color(draftReviewStatus)} $textColor="#fafafa">
          {text(draftReviewStatus)}
        </StyledButton>
      </PopoverTrigger>
      <PopoverContent>
        <H1_FlexColumn gap="10px" padding="10px 0">
          <H1_TextMiddle color={theme.gray7} margin="0 20px">
            {formatMessage(reviewStatusMessages.updateStatus)}
          </H1_TextMiddle>
          <Divider />
          <H1_FlexColumn align="center" gap="10px" padding="10px 20px">
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Draft)}
              $color={color(ReviewStatus.Draft)}
              $textColor="#fafafa"
            >
              {text(ReviewStatus.Draft)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Pending)}
              $color={color(ReviewStatus.Pending)}
              $textColor="#fafafa"
            >
              {text(ReviewStatus.Pending)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.InReview)}
              $color={color(ReviewStatus.InReview)}
              $textColor="#fafafa"
            >
              {text(ReviewStatus.InReview)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Approved)}
              $color={color(ReviewStatus.Approved)}
              $textColor="#fafafa"
            >
              {text(ReviewStatus.Approved)}
            </StyledButton>
            <StyledButton
              onClick={() => onClickReviewStatus(ReviewStatus.Rejected)}
              $color={color(ReviewStatus.Rejected)}
              $textColor="#fafafa"
            >
              {text(ReviewStatus.Rejected)}
            </StyledButton>
          </H1_FlexColumn>
        </H1_FlexColumn>
      </PopoverContent>
    </Popover>
  );
};

export default ReviewStatusButton;
