import React, { useEffect, useMemo, useState } from "react";
import "app/components/projects-managment/projectsManagment.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Divider } from "antd";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import HourOneBreadcrumb, { BreadcrumbElement } from "app/components/common/HourOneBreadcrumb";
import { WebhooksPageMessages } from "app/pages/webhooks/messages";
import { webhooksActions } from "app/store/slices/webhooks.slice";
import { webhooksGlobalSelectors } from "app/store/adapters/adapters";
import WebhookForm from "app/pages/webhooks/WebhookForm";
import { Webhook, WebhookStatus } from "app/types";
import { useParams } from "react-router-dom";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import SecretButton from "app/pages/webhooks/SecretButton";
import WebhookOptionsDropdown from "app/pages/webhooks/WebhookOptionsDropdown";
import { capitalize } from "lodash";
import { validateWebHook } from "app/components/editor/validations";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray1};
`;

const StyledHourOneBreadcrumb = styled(HourOneBreadcrumb)`
  && {
    padding-left: 0;
    font-size: 1rem;
  }
  &&&.ant-breadcrumb {
    a,
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme.gray7};
    }
    > :last-child a {
      color: ${(props) => props.theme.gray7};
    }
    .ant-breadcrumb-link {
      color: ${(props) => props.theme.gray9};
    }
    &&& .ant-breadcrumb-link:hover {
      a {
        color: ${(props) => props.theme.gray8};
      }
    }
  }
`;

const WebhookEditPage = () => {
  const [editedWebhook, setEditedWebhook] = useState<Webhook>();
  const [touch, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<keyof Webhook, string>>(
    {} as Record<keyof Webhook, string>
  );
  const { webhookId = "" } = useParams<{ webhookId: string }>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const webHooksStatus = useAppSelector((state) => state.webhooks.webhookStatus);
  const updateWebhookStatus = useAppSelector((state) => state.webhooks.updateWebhookStatus);
  const isLoading = webHooksStatus === fetchingStatus.loading;
  const updateLoading = updateWebhookStatus === fetchingStatus.loading;

  const webhook = useAppSelector((state) => webhooksGlobalSelectors.selectById(state, webhookId));
  useEffect(() => {
    if (updateWebhookStatus === fetchingStatus.succeeded) {
      dispatch(webhooksActions.setUpdateStatusToIdle());
      setTouched(false);
    } else if (webHooksStatus === fetchingStatus.failed) {
      dispatch(webhooksActions.setUpdateStatusToIdle());
    }
  }, [updateWebhookStatus]);
  useEffect(() => {
    if (webhook) {
      setEditedWebhook(webhook);
    }
  }, [webhook]);

  const statusColor = useMemo(() => {
    if (webhook?.status) {
      switch (webhook?.status) {
        case WebhookStatus.active:
          return theme.green4;
        case WebhookStatus.inactive:
          return theme.gray7;
        case WebhookStatus.paused:
          return theme.pink4;
      }
    }
    return "black";
  }, [webhook?.status]);
  useEffect(() => {
    if (webhookId) {
      dispatch(webhooksActions.getWebhookRequest(webhookId));
    }
  }, [webhookId]);

  const breadcrumbs: BreadcrumbElement[] = useMemo(() => {
    const res: BreadcrumbElement[] = [
      {
        id: "webhooks",
        name: formatMessage(WebhooksPageMessages.title),
        url: "/webhooks"
      },
      {
        id: "name",
        name: webhook?.name || ""
      }
    ];
    return res;
  }, [webhook?.name]);

  const onchange = (prop: keyof Webhook, data: any) => {
    setTouched(true);
    if (editedWebhook) {
      setEditedWebhook({ ...editedWebhook, [prop]: data });
    }
  };
  const onSave = () => {
    const errors = validateWebHook(editedWebhook as Webhook);
    if (editedWebhook && Object.keys(errors).length === 0) {
      dispatch(
        webhooksActions.updateWebhookRequest({
          webhookId: editedWebhook?.id as string,
          request: editedWebhook
        })
      );
    }
    setErrors(errors);
  };

  return (
    <BackgroundFlexColumn padding="1rem 0 1.875rem 1.875rem" flex="1">
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!isLoading && !!editedWebhook}>
        <H1_FlexColumn gap="2.125rem" flex="1">
          <H1_FlexRow
            height="1.375rem"
            flex="0 0 2.375rem"
            align="center"
            justify="space-between"
            gap="0.3125rem"
            padding="0 1.875rem 0 0"
          >
            <StyledHourOneBreadcrumb breadcrumbs={breadcrumbs} separator="/" />
          </H1_FlexRow>
          <H1_FlexColumn
            align="center"
            alignSelf="center"
            width="500px"
            overflow="auto"
            flex="1 1 auto"
            padding="0 1.875rem 0 0"
            gap={"10px"}
          >
            <H1_FlexRow padding="0 0 20px 0" align="center" width="100%" justify="space-between">
              <H1_FlexRow align="center" gap="10px">
                <H1_FlexColumn gap="5px" height={"50px"} justify={"space-between"}>
                  <H1_TextSubtitle fontWeight={500}>
                    {formatMessage(WebhooksPageMessages.status)}
                  </H1_TextSubtitle>
                  <H1_TextSmall color={statusColor}>{capitalize(webhook?.status)}</H1_TextSmall>
                </H1_FlexColumn>
                <Divider type="vertical" />
                <H1_FlexColumn gap="5px" height={"50px"} justify={"space-between"}>
                  <H1_TextSubtitle fontWeight={500}>
                    {formatMessage(WebhooksPageMessages.secret)}
                  </H1_TextSubtitle>
                  <SecretButton value={webhook?.signing_secret as string} />
                </H1_FlexColumn>
              </H1_FlexRow>
              <WebhookOptionsDropdown webhookId={webhookId} />
            </H1_FlexRow>
            <WebhookForm
              errors={errors}
              name={editedWebhook?.name}
              url={editedWebhook?.url}
              events={editedWebhook?.events}
              description={editedWebhook?.description}
              onChange={onchange}
              disabled={updateLoading}
            />
            <H1_FlexRow alignSelf="flex-end" padding="20px 0 0 0">
              <Button color="primary" isLoading={updateLoading} disabled={!touch} onClick={onSave}>
                {formatMessage(WebhooksPageMessages.save)}
              </Button>
            </H1_FlexRow>
          </H1_FlexColumn>
        </H1_FlexColumn>
      </ConditionalRender>
    </BackgroundFlexColumn>
  );
};
export default WebhookEditPage;
