/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import PreviewTag from "app/components/common/PreviewTag";
import { isMediaPlaying, onImageError } from "app/utils/helpers";
import { Button, Dropdown, Image } from "antd";
import { templatesMessages } from "app/components/editor/templateSelect/messages";
import { useIntl } from "react-intl";
import { recipesActions } from "app/store/slices/recipes.slice";
import { useAppDispatch } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
// region [[ Styles ]]

const TemplatePreviewThumbnailView = styled.div`
  cursor: pointer;
  margin: 0 auto;
`;

const TagsFlexRow = styled(H1_FlexRow)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const VideoContainer = styled.div.attrs(
  ({ width, height, borderRadius, aspectRatio, isBorderWidth }: VideoContainerProps) => ({
    width,
    height,
    borderRadius,
    aspectRatio,
    isBorderWidth
  })
)`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ width, height }) => (width === "100%" ? "auto" : height)};
  min-height: calc(160px * 9 / 16);
  video,
  img {
    border-style: solid;
    border-width: ${(props: any) => (props.isBorderWidth ? "1px" : 0)};
    border-color: ${(props: any) => (props.aspectRatio > 1 ? props.theme.gray3 : "transparent")};
    border-radius: ${({ borderRadius }) => borderRadius};
    min-height: calc(160px * 9 / 16);
  }
`;

const StyledDropdown = styled(Dropdown)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  transition: opacity 0.2s ease-in-out;
  opacity: ${(props: { $isDisplayed: boolean }) => (props.$isDisplayed ? 1 : 0)};
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
  margin-left: 10px;
  background-color: ${(props) => props.theme.gray1};
  color: ${(props) => props.theme.gray8};
  border: none;
  &:hover {
    color: ${(props) => props.theme.gray8};
    background-color: ${(props) => props.theme.gray2};
    box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
    border: none;
  }
  &:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:focus {
    background-color: transparent;
    color: ${(props) => props.theme.gray8};
  }
`;

const StyledImage = styled(Image)`
  padding-bottom: 3px;
`;

const StyledIcon = styled.i`
  font-size: 16px;
  margin: auto;
`;

const StyledVideo = styled.video`
  width: ${(props: { $aspectRatio: number; width: string; videoLoaded: boolean }) =>
    props.$aspectRatio > 1 ? props.width : "100%"};
  height: ${(props: { $aspectRatio: number; width: string }) =>
    props.width === "100%" ? (props.$aspectRatio > 1 ? "100%" : "calc(80vh - 274px)") : "100%"};
  transition: all 0.3s ease-in-out;
  opacity: ${(props: { $aspectRatio: number }) => (props.$aspectRatio === 0 ? 0 : 1)};
  position: ${(props: { $aspectRatio: number }) =>
    props.$aspectRatio === 0 ? "absolute" : "static"};
`;

// endregion [[ Styles ]]

// region [[ Props ]]

export interface TemplatePreviewThumbnailProps {
  image?: string;
  alt: string;
  video: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  disableIcon?: boolean;
  autoPlay?: boolean;
  showControls?: boolean;
  badgeText?: string | React.ReactNode;
  tags?: string[];
  badgeIcon?: React.ReactNode;
  showRecipeDropdown?: boolean;
  recipeId?: string;
  categoryName?: string;
  className?: string;
}

export interface VideoContainerProps {
  width: string;
  height: string;
  borderRadius?: string;
  aspectRatio?: number;
  isBorderWidth?: boolean;
}

const TemplatePreviewThumbnail = ({
  image,
  video,
  width = "260px",
  height = "calc(260px * 9 / 16)",
  borderRadius,
  autoPlay = false,
  showControls = false,
  badgeText,
  tags,
  badgeIcon,
  showRecipeDropdown = false,
  recipeId,
  categoryName,
  className
}: TemplatePreviewThumbnailProps) => {
  const [onHover, setOnHover] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const videoRef = useRef<HTMLVideoElement>();

  const onVideoLoaded = () => {
    const aspectRatio = videoRef.current?.videoWidth || 1 / (videoRef.current?.videoHeight || 1);
    setAspectRatio(aspectRatio);
    setVideoLoaded(true);
  };

  const onMouseEnter = () => {
    if (image && videoLoaded) {
      videoRef.current?.play();
    }
  };

  const onMouseLeave = () => {
    if (image && videoLoaded && !autoPlay && isMediaPlaying(videoRef.current)) {
      videoRef.current?.pause();
      return videoRef.current?.load();
    }
    return false;
  };

  const onDeleteRecipe = ({ domEvent }: any) => {
    domEvent.stopPropagation();
    dispatch(
      analyticsEvents.deleteRecipe({
        recipeId: recipeId as string,
        categoryName: categoryName as string
      })
    );
    dispatch(
      recipesActions.deleteRecipeRequest({
        recipeId: recipeId as string,
        categoryName: categoryName as string
      })
    );
  };

  const menu = {
    items: [
      {
        key: "open-draft",
        icon: <i className="fa-regular fa-trash" />,
        onClick: onDeleteRecipe,
        label: formatMessage(templatesMessages.deleteTemplate)
      }
    ],
    // antd weird dropdown menu props
    onClick: ({ domEvent }: any) => {
      domEvent.stopPropagation();
    }
  };

  return (
    <TemplatePreviewThumbnailView className={className}>
      <VideoContainer
        isBorderWidth={videoLoaded}
        width={width}
        height={height}
        borderRadius={borderRadius || "0px"}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <ConditionalRender condition={showRecipeDropdown}>
          <H1_FlexRow onClick={(e) => e.stopPropagation()}>
            <StyledDropdown
              $isDisplayed={onHover}
              placement="bottomLeft"
              menu={menu}
              key="actions-menu"
            >
              <StyledButton size="small" icon={<StyledIcon className="far fa-ellipsis-h" />} />
            </StyledDropdown>
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={!videoLoaded && !!image && !video}>
          <StyledImage
            preview={false}
            src={image}
            alt=""
            width={width}
            height={height}
            onError={onImageError}
          />
        </ConditionalRender>
        <ConditionalRender condition={!videoLoaded && !image}>
          <CircleLoader />
        </ConditionalRender>
        <StyledVideo
          className="template-video"
          width={width}
          height={!videoLoaded ? 0 : height}
          videoLoaded={videoLoaded}
          $aspectRatio={aspectRatio}
          ref={videoRef as React.RefObject<HTMLVideoElement>}
          poster={image}
          muted
          autoPlay={autoPlay}
          controls={showControls}
          loop
          src={video}
          onCanPlay={() => onVideoLoaded()}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
        <TagsFlexRow gap="5px">
          <ConditionalRender condition={!!badgeText}>
            <PreviewTag icon={badgeIcon} background={theme.orange3}>
              {badgeText}
            </PreviewTag>
          </ConditionalRender>
          <ConditionalRender condition={!!tags?.length}>
            <PreviewTag background={theme.blue4}>{tags?.[0]}</PreviewTag>
          </ConditionalRender>
        </TagsFlexRow>
      </VideoContainer>
    </TemplatePreviewThumbnailView>
  );
};

export default TemplatePreviewThumbnail;
