import React, { ReactNode } from "react";
import { MimeType } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { defaultFileTypes } from "app/types/media";
import FileDropzone from "app/components/FileDropzone";
import styled from "styled-components";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
`;
export interface UploadFileContentProps {
  onSelectFile: (file: File[]) => void;
  fileTypes: MimeType[];
  disabled?: boolean;
  children: ReactNode;
  openFileDialogOnClick?: boolean;
  width?: string;
  height?: string;
  maxSizeInMB?: number;
}

const UploadFileContent = ({
  onSelectFile,
  fileTypes,
  disabled,
  width,
  children,
  openFileDialogOnClick,
  maxSizeInMB,
  height
}: UploadFileContentProps) => {
  return (
    <BackgroundFlexRow width={width} height={"100%"}>
      <FileDropzone
        openFileDialogOnClick={openFileDialogOnClick}
        onSelectedFiles={onSelectFile}
        disabled={disabled}
        fileTypes={fileTypes && fileTypes.length > 0 ? fileTypes.join() : defaultFileTypes}
        maxSizeInMB={maxSizeInMB}
        height={height}
      >
        {children}
      </FileDropzone>
    </BackgroundFlexRow>
  );
};

export default UploadFileContent;
