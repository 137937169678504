import React, { useState } from "react";
import { DragEndEvent, DragOverlay, DragStartEvent, useDndMonitor } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
  arrayMove
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Tag } from "antd";
import styled from "styled-components";
import { TagAreaType, TypeTag } from "app/types/media";

export const Icon = styled("i")`
  margin-right: 5px;
  font-size: 12px;
  line-height: 24px;
`;

interface SortableTagType {
  tag: TypeTag;
  removeTag: (tag: TypeTag) => void;
  // eslint-disable-next-line react/require-default-props
  key?: string;
  // eslint-disable-next-line react/require-default-props
  overlay?: boolean;
  // eslint-disable-next-line react/require-default-props
  editable?: boolean;
}

const TagTxt = styled("span")`
  display: inline-block;
  cursor: pointer;
`;

const SortableTag = ({
  tag,
  removeTag,
  key = undefined,
  overlay = false,
  editable = true
}: SortableTagType) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: tag.id
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    alignSelf: "center",
    opacity: overlay ? 0.8 : 1
  };

  return (
    <Tag
      ref={setNodeRef}
      key={`${tag.label}-${key ?? "!"}`}
      color={tag.color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      style={style}
      icon={<Icon className={tag.icon} />}
      closable={editable}
      onClose={() => {
        removeTag(tag);
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TagTxt {...listeners} ref={setActivatorNodeRef}>
        {tag?.label?.length > 20 ? `${tag?.label.slice(0, 20)}...` : tag?.label}
      </TagTxt>
    </Tag>
  );
};

const MediaLibraryTabSDGenerateDraggableTags = ({ tags, setTags, editable }: TagAreaType) => {
  const [draggingTag, setDraggingTag] = useState<TypeTag>();

  const handleDragStart = (event: DragStartEvent) => {
    setDraggingTag(tags.find((tag: TypeTag) => tag.id === event.active.id) as TypeTag);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setTags((tagsState: TypeTag[]) => {
        const oldIndex = tagsState.findIndex((t) => t.id === active.id);
        const newIndex = tagsState.findIndex((t) => t.id === over?.id);

        return arrayMove(tagsState, oldIndex, newIndex);
      });
    }
    setDraggingTag(undefined);
  };

  useDndMonitor({
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd
  });

  const removeTag = (tag: TypeTag) => {
    setTags((tagsState: TypeTag[]) => {
      const removed = tagsState.filter((t) => t.label !== tag.label);
      return removed;
    });
  };

  return (
    <ConditionalRender condition={tags.length > 0}>
      <SortableContext items={tags} strategy={horizontalListSortingStrategy}>
        {tags.map((tag: TypeTag) => (
          <SortableTag key={tag.label} tag={tag} removeTag={removeTag} editable={editable} />
        ))}
      </SortableContext>

      <DragOverlay>
        <ConditionalRender condition={!!draggingTag}>
          <SortableTag
            tag={draggingTag as TypeTag}
            removeTag={removeTag}
            overlay
            editable={!!editable}
          />
        </ConditionalRender>
      </DragOverlay>
    </ConditionalRender>
  );
};

export default MediaLibraryTabSDGenerateDraggableTags;
