import { defineMessages } from "react-intl";

export default defineMessages({
  tableColTitleTitle: {
    id: `projects.table.column.title`,
    defaultMessage: "Video Name"
  },
  tableColTypeTitle: {
    id: `projects.table.column.type`,
    defaultMessage: "Type"
  },
  tableColTypeDataLabel: {
    id: `projects.table.column.type.content.data`,
    defaultMessage: "Data"
  },
  tableColTypeDataLessLabel: {
    id: `projects.table.column.type.content.dataless`,
    defaultMessage: "Dataless"
  },
  projectsHeadline: {
    id: `projects.headline`,
    defaultMessage: "My Projects"
  },
  tableColAllVideosTitle: {
    id: `projects.table.column.videos-count`,
    defaultMessage: "Videos"
  },
  tableColOnAirVideosTitle: {
    id: `projects.table.column.videos-on-air`,
    defaultMessage: "On air"
  },
  tableColProcessingVideosTitle: {
    id: `projects.table.column.videos-processing`,
    defaultMessage: "Processing"
  },
  tableColCreatedTitle: {
    id: `projects.table.column.created`,
    defaultMessage: "Created at"
  },
  playButton: {
    id: `projects.table.column.play-button`,
    defaultMessage: "Play"
  },
  readyVideos: {
    id: `projects.table.column.ready-videos`,
    defaultMessage: "Ready Videos"
  },
  tableColOwnerTitle: {
    id: `projects.table.column.owner-image`,
    defaultMessage: "Owner"
  },
  tableColUpdatedTitle: {
    id: `projects.table.column.updated`,
    defaultMessage: "Edited"
  },
  tableColDurationTitle: {
    id: `projects.table.column.duration`,
    defaultMessage: "Duration"
  },
  tableColReviewStatusTitle: {
    id: `projects.table.column.review-status`,
    defaultMessage: "Status"
  },
  tableColReviewStatusDraft: {
    id: `projects.table.column.review-status-draft`,
    defaultMessage: "Draft"
  },
  tableColReviewStatusInReview: {
    id: `projects.table.column.review-status-in-review`,
    defaultMessage: "In Review"
  },
  tableColReviewStatusPending: {
    id: `projects.table.column.review-status-peding`,
    defaultMessage: "Pending"
  },
  tableColReviewStatusApproved: {
    id: `projects.table.column.review-status-approved`,
    defaultMessage: "Approved"
  },
  tableColReviewStatusRejected: {
    id: `projects.table.column.review-status-rejected`,
    defaultMessage: "Rejected"
  },
  tableTitle: {
    id: `projects.title`,
    defaultMessage: "Recent Videos"
  },
  table: {
    id: `projects.table`,
    defaultMessage: "Table"
  },
  grid: {
    id: `projects.grid`,
    defaultMessage: "Grid"
  },
  emptyProjectsListTitle: {
    id: `projects.empty`,
    defaultMessage: "No Projects Yet!"
  },
  emptyProjectsListActionLabel: {
    id: `projects.empty.action`,
    defaultMessage: "No Projects Yet!"
  },
  emptyFolderText: {
    id: `empty-state.no-folder-text`,
    defaultMessage: "Empty Folder"
  },
  emptyFolderDescription: {
    id: `empty-state.no-folder-description`,
    defaultMessage: "You have no videos in this folder."
  },
  emptyFolderButtonText: {
    id: `empty-state.no-folder-button-text`,
    defaultMessage: "New Video"
  },
  emptyFoldersText: {
    id: `empty-state.no-folders-text`,
    defaultMessage: "No Folders"
  },
  emptyFoldersDescription: {
    id: `empty-state.no-folders-description`,
    defaultMessage: "You have no folders at all. Create new folder"
  },
  emptyFoldersButtonText: {
    id: `empty-state.no-folders-button-text`,
    defaultMessage: "New Folder"
  }
});
