import React, { ChangeEvent, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { briefsActions } from "app/store/slices/briefs.slice";
import { briefsModalMessages } from "app/pages/Briefs/messages";
import styled, { useTheme } from "styled-components";
import { fetchingStatus, isValidEmail } from "app/utils/helpers";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { message } from "antd";
import { BriefRequest } from "app/types/brief";
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Spacer,
  Textarea
} from "@nextui-org/react";
import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { Chip } from "@nextui-org/chip";
import { useAuth } from "app/auth/useAuth";
import { KeyboardCode } from "@dnd-kit/core";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { useNavigate } from "react-router-dom";
import { Location, Member } from "app/types";
import UserDetails from "app/pages/Briefs/UserDetails";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  border-radius: 14px;
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;
  }
  .ant-modal-close {
    z-index: 11; /* default of NextUI is also 10 so must override or it unclickable */
  }
`;

// const StyledTextArea = styled(H1_Textarea)`
//   && {
//     display: block;
//     height: 100%;
//     min-height: 175px;
//     line-height: 22px;
//     margin: 0;
//     padding: 20px;
//     width: 100%;
//     overflow: auto;
//     border-radius: 7px;
//     border: 3px solid  ${({ theme }) => theme.gray3}
//     background: ${({ theme }) => theme.gray1};
//     color: ${({ theme }) => theme.gray8};
//     box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) inset;
//   }
// `;

// const StyledTextArea = styled(Textarea)`
//   && {
//     display: block;
//     height: 100%;
//     min-height: 175px;
//     line-height: 22px;
//     margin: 0;
//     padding: 20px;
//     width: 100%;
//     overflow: auto;
//   }
// `;
const StyledInlineButton = styled(Button)<{ $marginLeft?: string; $underline?: boolean }>`
  text-decoration: ${(props) => (props.$underline ? "underline" : "none")};
  display: inline-block;
  margin-left: ${(props) => props.$marginLeft || "initial"};
  font-weight: 300;
`;

const StyledBriefButton = styled(Button)<{ $disabled: boolean }>`
  margin: 0 12px 20px 12px;
  background-color: ${({ theme, $disabled }) => ($disabled ? theme.gray5 : theme.blue4)};
`;
interface BriefsModalProps {
  onFinish: (obj?: any) => void;
  visible: boolean;
}
const BriefsModal = ({ visible, onFinish }: BriefsModalProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const createBriefsStatus = useAppSelector((state) => state.briefs.createBriefsStatus);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [briefData, setBriefData] = useState<string>("");
  const [briefMembers, setBriefMembers] = useState<string[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [emailValue, setEmailValue] = useState<string>("");
  const briefMembersEmpty = briefMembers.length === 0;
  const briefDataEmpty = briefData === "";
  const workspaceMembers = useMemo(
    () =>
      currentWorkspace?.members
        .filter((member: Member) => member.email !== user?.email)
        .map((member: Member) => ({
          picture: member.picture,
          value: member.email,
          label: member.nickname
        })) || [],
    []
  );
  const [currentWorkspaceMembers, setCurrentWorkspaceMembers] = useState(workspaceMembers);
  useEffect(() => {
    if (createBriefsStatus == fetchingStatus.succeeded) {
      message.open({
        content: intl.formatMessage(briefsModalMessages.briefCreatedMsg, {
          a: (chunks: ReactNode) => (
            <StyledInlineButton
              variant="light"
              $underline
              onClick={() => {
                navigate(Location.Briefs + "?tab=sentBriefs");
              }}
            >
              {chunks}
            </StyledInlineButton>
          )
        })
      });
    }
    if (createBriefsStatus == fetchingStatus.failed) {
      message.error({
        content: intl.formatMessage(briefsModalMessages.briefFailedMsg)
      });
    }
    dispatch(briefsActions.setCreateBriefsToIdle());
    onFinish();
  }, [createBriefsStatus]);

  const getUserIdFromEmail = (email: string) => {
    for (const user of currentWorkspace?.members as Member[]) {
      if (user.email === email) {
        return user.user_id;
      }
    }
    return null; // Return null if email not found
  };

  const onCloseModal = () => {
    setBriefData("");
    setUserIds([]);
    setEmailValue("");
    setBriefMembers([]);
    setCurrentWorkspaceMembers(workspaceMembers);
    onFinish();
  };
  const onClickCreateBrief = () => {
    const briefRequest: BriefRequest = {
      content: briefData,
      recipients: userIds
    };
    dispatch(briefsActions.createBriefsRequest({ briefs: briefRequest }));
    onCloseModal();
  };
  const onBlurEmail = (e?: any, value?: string) => {
    const currentValue = value || emailValue;
    const emailValid = isValidEmail(currentValue) && currentValue !== user?.email;
    if (emailValid) {
      removeFromMemberList(currentValue);
      const userId = getUserIdFromEmail(currentValue);
      if (userId) {
        setBriefMembers([...briefMembers, currentValue]);
        setUserIds([...userIds, userId]);
        setEmailValue("");
      }
    }
  };

  const onKeyDown = (event: any) => {
    if (
      event.key === KeyboardCode.Enter ||
      event.key === KeyboardCode.Space ||
      event.key === KeyboardCode.Esc
    ) {
      onBlurEmail();
    }
  };

  const removeFromMemberList = (email: string) => {
    setCurrentWorkspaceMembers(currentWorkspaceMembers.filter((member) => member.value !== email));
    setEmailValue("");
  };
  const addToMemberList = (email: string) => {
    const memberToAdd = workspaceMembers.filter((member) => member.value === email);
    setCurrentWorkspaceMembers([...currentWorkspaceMembers, ...memberToAdd]);
    setBriefMembers(briefMembers.filter((member: string) => member != email));
    setEmailValue("");
  };

  return (
    <Modal
      width="640px"
      open={visible}
      footer={null}
      centered
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon onClick={onCloseModal} />}
    >
      <Card>
        <CardHeader className="justify-left px-6 pb-0 pt-6">
          <H1_TextSubtitle color={theme.gray7}>
            {intl.formatMessage(briefsModalMessages.briefHeadLine)}
          </H1_TextSubtitle>
        </CardHeader>
        <CardBody className="px-6 pb-1">
          <Autocomplete
            variant="bordered"
            defaultItems={currentWorkspaceMembers}
            labelPlacement="outside"
            selectorIcon={<></>}
            inputValue={emailValue}
            onInputChange={setEmailValue}
            onSelectionChange={(value) => onBlurEmail(undefined, value as string)}
            placeholder={
              briefMembersEmpty ? intl.formatMessage(briefsModalMessages.addPeopleInput) : ""
            }
            startContent={
              <H1_FlexRow gap="8px" flex="0 0 auto">
                {briefMembers.map((member, index) => (
                  <Chip key={index} onClose={() => addToMemberList(member)}>
                    {member}
                  </Chip>
                ))}
              </H1_FlexRow>
            }
            onKeyDown={onKeyDown}
          >
            {(user) => (
              <AutocompleteItem
                key={user.value as string}
                textValue={user.label}
                onClick={() => removeFromMemberList(user.value as string)}
              >
                <UserDetails userEmail={user.value as string} />
              </AutocompleteItem>
            )}
          </Autocomplete>

          <Spacer y={4} />
          <Textarea
            minRows={5}
            variant="bordered"
            ref={textareaRef}
            value={briefData}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setBriefData(event.target.value as string)
            }
            placeholder={intl.formatMessage(briefsModalMessages.briefContentInput)}
          />
        </CardBody>
        <CardFooter>
          <StyledBriefButton
            fullWidth
            color="primary"
            className="text-gray-1"
            size="md"
            onClick={onClickCreateBrief}
            disabled={briefMembersEmpty || briefDataEmpty}
            $disabled={briefMembersEmpty || briefDataEmpty}
          >
            {intl.formatMessage(briefsModalMessages.sendBriefButton)}
          </StyledBriefButton>
        </CardFooter>
      </Card>
    </Modal>
  );
};

export default BriefsModal;
