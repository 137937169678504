import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import {
  PresentationLoaderModalMessages,
  videoLoaderModalMessages
} from "app/components/editor/documents/messages";

import CircleLoader from "app/components/common/Loaders/CircleLoader";
import styled, { useTheme } from "styled-components";
import { uniqueId } from "lodash";
import { getTrayElmByKey } from "app/store/selectorsV2/uiV2.selectors";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { StyledModal } from "app/components/common/StyledModal";

const StyledCircleLoader = styled(CircleLoader)`
  height: 50px;
  width: 80px;
`;
const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 40px 48px 55px;
    align-items: center;
    justify-content: space-between;
  }
`;

const PresentationLoadingCreationModal = ({ trayKey }: { trayKey?: string }) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loaderKey, setLoaderKey] = useState<string>();
  const minimized = useAppSelector((state) => getTrayElmByKey(state, loaderKey));

  useEffect(() => {
    setLoaderKey(trayKey || uniqueId("doc-loader"));
  }, []);

  const handleMinimized = () => {
    if (loaderKey) {
      dispatch(
        uiActionsV2.addToTray({
          [loaderKey]: {
            showLoader: true,
            showButton: true,
            headline: intl.formatMessage(videoLoaderModalMessages.HeadlineTitle),
            onExpand: () => dispatch(uiActionsV2.removeFromTray(loaderKey))
          }
        })
      );
    }
  };

  return (
    <Modal
      width="520px"
      open={!minimized}
      footer={null}
      centered
      key="document-creation-loader"
      bodyStyle={{ height: "207px" }}
      onCancel={handleMinimized}
      destroyOnClose
    >
      <StyledCircleLoader />
      <H1_TextSubtitle lineHeight="35px" margin="12px 0 0" color={theme.gray9} fontWeight={700}>
        {intl.formatMessage(PresentationLoaderModalMessages.HeadlineTitle)}
      </H1_TextSubtitle>
      <H1_TextSmall color={theme.gray8}>
        {intl.formatMessage(PresentationLoaderModalMessages.SubTitle)}
      </H1_TextSmall>
    </Modal>
  );
};
export default PresentationLoadingCreationModal;
