import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Divider, Modal } from "antd";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import layoutSelectors from "app/store/selectorsV2/layout.selectors";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import layoutsThunk from "app/store/thunks/layouts.thunk";
import { Layout as LayoutType, TemplateLayout } from "app/types/layout";
import { MediaType } from "app/types/media";
import DiscoveryGrid from "app/components/common/discovery/DiscoveryGrid";
import { useAppSelector } from "app/hooks";
import Preview from "app/components/editor/sideDrawers/Preview";

import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import { scenesActions } from "app/store/slices/scenes.slice";
import LayoutDrawerPreview from "app/components/editor/sideDrawers/LayoutDrawerPreview";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import TitleFlexRow from "app/components/editor/sideDrawers/CommonComponents/TitleFlexRow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";

const translatePrefix = "editor.drawer.layout";
const messages = {
  drawerTitle: {
    id: `${translatePrefix}.title`,
    defaultMessage: "<b>Create a new</b> Scene"
  },
  drawerEditTitle: {
    id: `${translatePrefix}.title`,
    defaultMessage: "<b>Edit</b> Layout"
  },
  previewTitle: {
    id: `${translatePrefix}.preview.text`,
    defaultMessage: "Layout"
  },
  chooseLayout: {
    id: `${translatePrefix}.choose-layout.text`,
    defaultMessage: "Choose Layout"
  }
};

export interface LayoutDrawerProps {
  right: string;
  width: string;
}

const LayoutDrawer = ({ right, width }: LayoutDrawerProps) => {
  const [layoutPopUp, setLayoutPopUp] = useState<LayoutType | undefined>();
  const [selectedLayoutId, setSelectedLayoutId] = useState<string | undefined>();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { editorDrawerOpen } = useDrawer();

  const currentTemplate = useAppSelector((state) => state.templates.currentTemplate);
  const allLayouts: TemplateLayout[] = useAppSelector(layoutSelectors.getCurrentTemplateLayouts);
  const sceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const currentSceneLayout = useAppSelector((state) =>
    scenesSelectors.getSceneLayout(state, sceneId as string)
  );

  const isDrawerActive = editorDrawerOpen === Drawer.Layout;

  const selectedLayout: TemplateLayout | undefined = useMemo(
    () => allLayouts?.find((layout) => layout.id === selectedLayoutId),
    [selectedLayoutId, allLayouts]
  );

  useEffect(() => {
    setSelectedLayoutId(currentSceneLayout?.id);
  }, [currentSceneLayout]);

  useEffect(() => {
    if (currentTemplate?.id && sceneId) {
      dispatch(layoutsThunk.getLayoutsRequest(currentTemplate.id));
    }
    return () => {
      setLayoutPopUp(undefined);
    };
  }, [currentTemplate, sceneId]);

  const onSelect = (id: string) => {
    if (selectedLayoutId === id) {
      return;
    }
    setLayoutPopUp(allLayouts.find((layout) => layout.id === id));
  };

  const onEditLayout = (layoutId: string) => {
    dispatch(scenesActions.patchSceneLayout({ layoutId, sceneId: sceneId as string }));
  };

  const onSelectLayoutModal = () => {
    if (layoutPopUp && layoutPopUp.id) {
      setSelectedLayoutId(layoutPopUp.id);
      onEditLayout(layoutPopUp.id);
    }
    setLayoutPopUp(undefined);
  };
  return (
    <FlexDrawer right={right} active={isDrawerActive} width={width}>
      <Modal
        key={layoutPopUp?.id}
        open={!!layoutPopUp}
        onCancel={() => setLayoutPopUp(undefined)}
        onOk={onSelectLayoutModal}
        okText={intl.formatMessage(messages.chooseLayout)}
        closable
        centered
        width={700}
      >
        <Preview
          previewUrls={
            layoutPopUp?.thumbnail_image
              ? [{ url: layoutPopUp?.thumbnail_image, assetType: MediaType.image }]
              : []
          }
          previewMessage={sceneId ? messages.drawerEditTitle : messages.drawerTitle}
        />

        <Divider />
      </Modal>
      <H1_FlexColumn className="side-drawer layout-drawer" width="auto !important">
        <TitleFlexRow height="52px">
          {
            // @ts-ignore handels formmating issue with html tags params
            intl.formatMessage(sceneId ? messages.drawerEditTitle : messages.drawerTitle, {
              b: (str) => (
                <H1_TextMiddle fontSize="18px" fontWeight={300}>
                  {str}
                </H1_TextMiddle>
              )
            }) as ReactNode
          }
        </TitleFlexRow>
        <H1_FlexColumn flex="1 1 auto">
          {selectedLayout?.thumbnail_image && (
            <LayoutDrawerPreview selectedLayout={selectedLayout} />
          )}
          <DiscoveryGrid<LayoutType>
            data={allLayouts}
            chosen={selectedLayoutId}
            idProp="id"
            details={[]}
            detailsBox={() => null}
            onSelect={(_e, id) => onSelect(id)}
          />
        </H1_FlexColumn>
      </H1_FlexColumn>
    </FlexDrawer>
  );
};

export default LayoutDrawer;
