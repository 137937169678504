import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FeatureFlag, PatchOperation, Video } from "app/types";
import { useIntl } from "react-intl";
import { videoReadyModalMessages } from "app/pages/editor/messages";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { fetchingStatus, getTimeAgo } from "app/utils/helpers";
import useModal, { ModalName } from "app/hooks/useModal";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/circle-upgrade-icon.svg";
import usePermissions from "app/hooks/usePermissions";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as paymentsSelectors from "app/store/selectorsV2/payments.selectors";
import useCopyLink from "app/hooks/useCopyLink";
import useDownload from "app/hooks/useDownload";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import videosSelectors from "app/store/selectorsV2/videos.selectors";
import BuyVideoButton from "app/pages/editor/VideoReadyModal/BuyVideoButton";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PlanFeature } from "app/config/planFeature";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import useSmallScreen from "app/hooks/useSmallScreen";
import ShareVideoMenu from "app/components/common/ShareVideoMenu";
import FeedbackBar from "app/components/common/player/FeedbackBar";
import type MuxPlayerElement from "@mux/mux-player";
import MoreMenuButton from "app/pages/editor/VideoReadyModal/MoreMenuButton";
import { Avatar, Button } from "@nextui-org/react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import VideoTags from "app/components/common/VideoTags";
import { useAuth } from "app/auth/useAuth";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { videosActions } from "app/store/slices/videos.slice";
import useEmbed from "app/hooks/useEmbed";
import NextUITooltip from "app/components/common/NextUI/Tooltip/NextUITooltip";

const MinHeightFlexRow = styled(H1_FlexRow)`
  min-height: 0; /* Fix bug in Safari */
`;

const LoadingFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.gray1};
  z-index: 20;
  pointer-events: none;
  border-radius: 10px;
`;

const RoundNextUIButton = styled(Button)`
  width: 43px;
  min-width: 43px;
  height: 43px;
  background-color: ${({ theme }) => theme.gray7};
  color: ${({ theme }) => theme.gray1};
  &:hover {
    background-color: ${({ theme }) => theme.gray6};
    opacity: 0.8;
  }
`;

const StyledShareVideoMenu = styled(ShareVideoMenu)`
  height: 43px;
  min-width: 43px;
  & {
    background-color: ${({ theme }) => theme.gray7};
    color: ${({ theme }) => theme.gray1};
    border-radius: 50%;
    width: 43px;
    height: 43px;
    padding: 0.313rem;
    &:hover {
      background-color: ${({ theme }) => theme.gray6};
      opacity: 0.8;
    }
  }
`;

const MoreMenuFlexColumn = styled(H1_FlexColumn)`
  top: 13px;
  right: 13px;
  z-index: 2;
  gap: 10px;
  height: auto;
  width: 43px;
`;

const VideoPreview = styled(H1_FlexRow)`
  min-height: 0; /* Fix bug in Safari */
  transition: 0.3s ease-in-out all;
  background-color: transparent;
  aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
  width: ${(props: { $aspectRatio: number }) => (props.$aspectRatio > 1 ? "100%" : "auto")};
  height: ${(props: { $aspectRatio: number }) => (props.$aspectRatio > 1 ? "auto" : "100%")};
  > div > div {
    height: 100%;
  }
  &&& mux-player {
    aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
    max-height: 548px;
    min-height: 480px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  & > * {
    flex: 1;
    height: 100%;
  }
`;

const NameAvatar = styled(Avatar)`
  min-width: 29px;
  min-height: 29px;
  flex-shrink: 0;
`;

const MaxWidthFlexRow = styled(H1_FlexRow)<{ $maxWidth: string }>`
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

const BottomFlexRow = styled(H1_FlexRow)`
  margin-top: auto;
`;

const CtaButton = styled(Button)`
  position: relative;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    padding: 1px 10px;
    span {
      display: none;
    }
  }
`;

const CorneredUpgradeIcon = styled(UpgradeIcon)`
  position: absolute;
  right: -13px;
  top: -8px;
`;
``;

const TitleInput = styled(H1_Input)`
  font-size: 14px;
  padding: 0;
  border: none;
  background: transparent;
  color: black;
  font-size: 14px;
  justify-content: center;

  input {
    font-weight: 500;
    padding: 0;
    border: none;
    line-height: 22px;
    max-width: 100%;
    color: ${({ theme }) => theme.gray11};
    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-skip: none;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
      border: none;
    }
  }
`;

const VideoReadyModalContent = ({ width }: { width: string }) => {
  const [currentVideoTitle, setCurrentVideoTitle] = useState<string>("");
  const [currentAspectRatio, setCurrentAspectRatio] = useState<number>(0);
  const [isForceLoading, setIsForceLoading] = useState(true);
  const [halfPlayed, setHalfPlayed] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const playerRef = useRef<MuxPlayerElement>();
  const { editorModalOpen, openModal } = useModal();
  const { openDownloadModal } = useDownload();
  const { formatMessage } = useIntl();
  const { copyLink } = useCopyLink();
  const flags = useFlags();
  const theme = useTheme();
  const { isDownloadAllow, buyVideo } = usePermissions();
  const auth = useAuth();
  const { isSmallScreen } = useSmallScreen();
  const embedCode = useEmbed();

  const downloadVideoStatus = useAppSelector((state) => state.videos.downloadVideoStatus);
  const { reqId } = editorModalOpen?.context || {};

  const selectedVideo: Video | undefined = useAppSelector((state) =>
    videosSelectors.getVideoByReqId(state, reqId)
  );
  const addOnDownloadFeature = useAppSelector((state) =>
    paymentsSelectors.getPricingFeatureIdByAction(state, PlanFeature.DownloadVideo)
  );

  const oneTimePayment = flags[FeatureFlag.oneTimePayment] && buyVideo;
  const isOwner = selectedVideo?.created_by === auth.user?.sub;
  const draftId: string = selectedVideo?.draft_id || "";
  const openEmbed = isDownloadAllow || selectedVideo?.paid;

  useEffect(() => {
    return () => {
      setHalfPlayed(false);
    };
  }, []);

  useEffect(() => {
    if (selectedVideo?.id) {
      setCurrentVideoTitle(selectedVideo.title as string);
    }
  }, [selectedVideo?.id]);
  useEffect(() => {
    if (!selectedVideo?.aspect_ratio) {
      return;
    }
    const ratio =
      (selectedVideo?.aspect_ratio?.width || 0) / (selectedVideo?.aspect_ratio?.height || 1);
    setCurrentAspectRatio(ratio);
  }, [selectedVideo?.aspect_ratio]);
  const openDownload = isDownloadAllow || selectedVideo?.paid;

  const onDownload = () => {
    openDownloadModal({
      source: "playModal-download",
      video: selectedVideo,
      backModal: ModalName.videoReady
    });
  };

  const onEmbed = () => {
    if (selectedVideo) {
      embedCode(
        `playModal_embed`,
        { id: selectedVideo.id, req_id: selectedVideo.req_id } as Video,
        ModalName.videoReady
      );
    }
  };

  const onBuyVideo = () => {
    dispatch(googleEvents.productUpgradeCta({ cta: "one_time_payment" }));
    dispatch(googleEvents.userBuyAddonIntent({ videoId: selectedVideo?.id as string }));
    openModal(
      ModalName.addOnPaymentModal,
      { feature: addOnDownloadFeature, args: { videoId: selectedVideo?.id }, reqId },
      ModalName.videoReady
    );
  };

  const onCopy = () => {
    copyLink(
      "play_modal_copy_link",
      selectedVideo as Video,
      draftId as string,
      ModalName.videoReady
    );
  };

  const onCanPlay = () => {
    setIsForceLoading(false);
  };

  const onVideoHalfPlayed = () => {
    if (!halfPlayed) {
      setHalfPlayed(true);
    }
  };

  const onOpenTextualFeedback = () => {
    if (playerRef?.current) {
      playerRef.current.pause();
    }
  };

  const onChangeName = (value: string) => {
    setCurrentVideoTitle(value);
  };

  const onBlurName = () => {
    if (selectedVideo) {
      const { id } = selectedVideo;
      const operations = [{ op: "replace", path: "title", value: currentVideoTitle }];
      dispatch(
        videosActions.updateVideoRequest({
          videoId: id,
          operations: operations as PatchOperation[]
        })
      );
    }
  };

  return (
    <>
      <ConditionalRender condition={isForceLoading}>
        <LoadingFlexRow flex="1" height="100%" width="100%" justify="center" align="center">
          <CircleLoader />
        </LoadingFlexRow>
      </ConditionalRender>
      <MinHeightFlexRow
        flex="1 1 auto"
        justify="center"
        width={currentAspectRatio > 1 ? "100%" : "auto"}
        height={currentAspectRatio > 1 ? "auto" : "calc(100% - 104px)"}
      >
        <VideoPreview
          position="relative"
          $aspectRatio={currentAspectRatio}
          align="center"
          justify="center"
          className="video-preview-container"
        >
          <ConditionalRender condition={!!selectedVideo}>
            <MoreMenuFlexColumn position="absolute">
              <NextUITooltip
                content={formatMessage(videoReadyModalMessages.shareTooltip)}
                placement="left"
              >
                <H1_FlexRow height="43px" flex="0 0 43px">
                  <StyledShareVideoMenu
                    withText={false}
                    withEmbed={false}
                    video={selectedVideo as Video}
                    source="play_modal"
                    isNextUI
                    icon={<i className="fa fa-share" />}
                    className="rounded-full"
                  />
                </H1_FlexRow>
              </NextUITooltip>
              <NextUITooltip
                content={formatMessage(videoReadyModalMessages.copyLinkTooltip)}
                placement="left"
              >
                <RoundNextUIButton
                  className="rounded-full"
                  onClick={onCopy}
                  startContent={<i className="fas fa-link" />}
                  isIconOnly
                />
              </NextUITooltip>
              <MoreMenuButton selectedVideo={selectedVideo} />
            </MoreMenuFlexColumn>
          </ConditionalRender>
          <MuxPlayer
            shape={currentAspectRatio > 1 ? "landscape" : "portrait"}
            templateFlavor={selectedVideo?.pipeline_request?.flavor_id}
            reqId={selectedVideo?.req_id as string}
            onVideoHalfPlayed={onVideoHalfPlayed}
            onLoadStart={(evt) => {
              playerRef.current = evt.target as MuxPlayerElement;
            }}
            onCanPlay={onCanPlay}
          />
        </VideoPreview>
      </MinHeightFlexRow>
      <ConditionalRender condition={!!reqId && halfPlayed}>
        <FeedbackBar reqId={reqId} onOpenTextualFeedback={onOpenTextualFeedback} />
      </ConditionalRender>
      <BottomFlexRow justify="space-between" width="100%" flex="0 0 40px" padding="10px 0 0 0">
        <H1_FlexRow flex="1" gap="13px" overflow="hidden">
          {selectedVideo?.created_by_picture ? (
            <NextUITooltip content={selectedVideo?.created_by_name}>
              <NameAvatar size={29} src={selectedVideo?.created_by_picture} />
            </NextUITooltip>
          ) : (
            <NameAvatar size={29}>
              {selectedVideo?.created_by_name ? selectedVideo?.created_by_name[0] : ""}
            </NameAvatar>
          )}
          <H1_FlexColumn flex="1" overflow="hidden">
            <H1_FlexRow align="center" height="24px">
              <TitleInput
                minWidth="50px"
                initialValue={currentVideoTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeName(e.target.value)}
                onBlur={() => onBlurName()}
              />
              <ConditionalRender condition={!isSmallScreen}>
                <MaxWidthFlexRow margin="0 10px 0 0" $maxWidth={width} alignSelf="flex-start">
                  <VideoTags isOwner={isOwner} videoId={selectedVideo?.id as string} />
                </MaxWidthFlexRow>
              </ConditionalRender>
            </H1_FlexRow>
            <H1_TextXs lineHeight="16px" color={theme.gray7}>
              {getTimeAgo(selectedVideo?.created_at)}
            </H1_TextXs>
          </H1_FlexColumn>
        </H1_FlexRow>
        <H1_FlexRow gap="20px">
          <ConditionalRender condition={!!selectedVideo}>
            <CtaButton
              color="primary"
              onClick={onEmbed}
              startContent={<i className="far fa-code-simple" />}
            >
              <ConditionalRender condition={!openEmbed}>
                <CorneredUpgradeIcon />
              </ConditionalRender>
              {formatMessage(videoReadyModalMessages.embedLink)}
            </CtaButton>
            <CtaButton
              color="primary"
              onClick={onDownload}
              isLoading={downloadVideoStatus === fetchingStatus.loading}
              startContent={<i className="far fa-arrow-down-to-line" />}
            >
              <ConditionalRender condition={!openDownload}>
                <CorneredUpgradeIcon />
              </ConditionalRender>
              {formatMessage(videoReadyModalMessages.downloadFileButton)}
            </CtaButton>
          </ConditionalRender>
        </H1_FlexRow>
        <ConditionalRender
          condition={
            oneTimePayment && !openDownload && addOnDownloadFeature?.price && !isSmallScreen
          }
        >
          <H1_FlexRow gap="20px">
            <BuyVideoButton price={addOnDownloadFeature?.price} onClick={onBuyVideo} />
          </H1_FlexRow>
        </ConditionalRender>
      </BottomFlexRow>
    </>
  );
};

export default VideoReadyModalContent;
