import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { presentersPageMessages } from "app/pages/presenters/messages";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Character } from "app/types/character";
import DevModePopover from "app/components/DevModePopover";
import ConditionalRender from "app/components/common/ConditionalRender";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { Button, Input } from "@nextui-org/react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { Tag } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import { charactersActions } from "app/store/slices/characters.slice";
import { useAuth } from "app/auth/useAuth";
import { PatchOperation } from "app/types";
import useConfirmModal from "app/hooks/useConfirmModal";
import { ThemeMode } from "app/utils/theme";

const StyledInput = styled(Input)`
  --tw-ring-offset-color: none;
`;

const StyledTag = styled(Tag)`
  color: white;
  position: absolute;
  top: 9px;
  left: 9px;
  border-radius: 50px;
`;

const CharImage = styled.img`
  max-height: 164px;
`;

const HoverFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
`;

const BoxCharacterFlexColumn = styled(H1_FlexColumn)`
  border-radius: 10px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.gray6}55;
  &:hover {
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.03);
    border: 1px solid ${({ theme }) => theme.gray6};
    ${HoverFlexRow} {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &:active {
    box-shadow: none;
    transform: scale(0.97);
  }
`;

const BackgroundContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray2};
`;

const WidthTextMiddle = styled(H1_TextMiddle)`
  max-width: 100%;
  padding: 0 10px;
`;

const PresentersPageSingleElement = ({
  characterId,
  onClickSelectCharacter
}: {
  characterId: string;
  onClickSelectCharacter: (char: string) => void;
}) => {
  const [characterName, setCharacterName] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();
  const character = useAppSelector((state) =>
    charactersGlobalSelectors.selectById(state, characterId)
  );

  useEffect(() => {
    if (characterName && ref.current) {
      ref.current.focus();
    }
  }, [characterName]);

  const onClickRemoveCharacter = (e: React.MouseEvent) => {
    if (!character?.tailor_made) {
      return;
    }
    e.stopPropagation();
    openConfirmModal({
      onCancel: closeConfirmModal,
      title: formatMessage(presentersPageMessages.removeCharTitle),
      content: formatMessage(presentersPageMessages.removeCharDesc),
      onConfirm() {
        dispatch(
          charactersActions.deleteCharacterRequest({
            character_id: character?.character_id,
            id: character?.id
          })
        );
      }
    });
  };

  const onClickEditCharacterName = (e: React.MouseEvent) => {
    if (
      !character ||
      !character.tailor_made ||
      !character.user_id ||
      character.user_id !== user?.sub
    ) {
      return;
    }
    e.stopPropagation();
    setCharacterName(character.title);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onBlurCharacterName(event);
    }
  };

  const onBlurCharacterName = (event: any) => {
    const { character_id, id } = character as Character;
    const operations: PatchOperation[] = [];
    event.stopPropagation();
    operations.push({
      op: "replace",
      path: `title`,
      value: characterName
    });
    dispatch(
      charactersActions.updateCharacterTitleRequest({
        character_id,
        id,
        operations,
        title: characterName
      })
    );
    setCharacterName("");
  };

  const items = useMemo(() => {
    const result = [
      {
        key: "rename",
        onClick: onClickEditCharacterName,
        label: formatMessage(presentersPageMessages.rename)
      },
      {
        key: "remove",
        onClick: onClickRemoveCharacter,
        label: formatMessage(presentersPageMessages.remove)
      }
    ];
    return result;
  }, []);

  return (
    <BoxCharacterFlexColumn
      align="center"
      flex="0 0 auto"
      width="232px"
      height="215px"
      position="relative"
      overflow="hidden"
      onClick={() => onClickSelectCharacter(characterId)}
    >
      <DevModePopover placement="left" value={{ character_id: characterId }} path="root" />
      <ConditionalRender condition={character?.tailor_made}>
        <StyledTag color={theme.orange3}>
          {formatMessage(presentersPageMessages.myAvatar)}
        </StyledTag>
        <ConditionalRender condition={!!character?.user_id && character?.user_id === user?.sub}>
          <HoverFlexRow>
            <NextDropdown items={items}>
              <Button
                variant="flat"
                isIconOnly
                startContent={<i className="far fa-ellipsis-vertical" />}
              />
            </NextDropdown>
          </HoverFlexRow>
        </ConditionalRender>
      </ConditionalRender>
      <BackgroundContainer width="100%" height="164px" justify="center">
        <CharImage
          height={164}
          src={character?.thumbnails?.page.image || character?.image}
          alt={character?.title}
        />
      </BackgroundContainer>
      <ConditionalRender condition={!characterName}>
        <H1_FlexRow padding={!character?.description ? "10px 0 0 0" : "0"}>
          <WidthTextMiddle textAlign="center" ellipsis>
            {character?.title}
          </WidthTextMiddle>
        </H1_FlexRow>
      </ConditionalRender>
      <H1_FlexRow
        className={characterName ? "" : "hidden"}
        gap="10px"
        align="center"
        justify="center"
        padding="10px"
        width="100%"
      >
        <StyledInput
          classNames={{ inputWrapper: "h-[24px]" }}
          value={characterName}
          onClick={(e) => e.stopPropagation()}
          onValueChange={(value) => setCharacterName(value)}
          onKeyDown={onKeyDown}
          ref={ref}
          size="sm"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
        />
        <Button
          size="sm"
          variant="light"
          color="primary"
          startContent={<i className="fas fa-check" />}
          onClick={onBlurCharacterName}
          isIconOnly
        />
      </H1_FlexRow>
      <ConditionalRender condition={!!character?.description}>
        <H1_TextSmall color={theme.gray7} textAlign="center" whiteSpace="normal">
          {character?.description}
        </H1_TextSmall>
      </ConditionalRender>
    </BoxCharacterFlexColumn>
  );
};

export default PresentersPageSingleElement;
