import { Button, Form, Row, Space } from "antd";
import React from "react";

import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";
import useNotifications from "app/hooks/useNotifications";
import { renameDraftModalMessages } from "app/pages/FoldersPage/messages";
import { StyledModal } from "app/components/common/StyledModal";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { Input } from "@nextui-org/react";

const RenameDraftModal = () => {
  const dispatch = useAppDispatch();
  const { editorModalOpen, closeModal } = useModal();
  const { notifyMessages } = useNotifications();
  const intl = useIntl();

  const { draftId } = editorModalOpen?.context || {};
  const draft = useAppSelector((state) => draftsGlobalSelectors.selectById(state, draftId));
  const { title: currentName } = draft || {};
  const visible = editorModalOpen?.open === ModalName.renameDraft;

  const onRenameDraft = (draftData: { name: string }) => {
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId: draftId as string,
        operations: [{ op: "replace", path: "title", value: draftData.name }]
      })
    );
    notifyMessages([
      {
        message: intl.formatMessage(renameDraftModalMessages.updateNameNotification, {
          previousName: currentName,
          name: draftData.name
        })
      }
    ]);
    dispatch(googleEvents.editedVideoName());
    closeModal();
  };

  const handleFocus = (event: any) => event && event.target && event.target.select();

  return (
    <StyledModal
      open={visible}
      destroyOnClose
      onCancel={closeModal}
      centered
      width={400}
      footer={false}
      bodyStyle={{ padding: "20px" }}
    >
      <H1_TextMiddle>{intl.formatMessage(renameDraftModalMessages.title)}</H1_TextMiddle>
      <Form layout="vertical" onFinish={(values) => onRenameDraft(values)} hideRequiredMark>
        <Form.Item
          label={intl.formatMessage(renameDraftModalMessages.label)}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage(renameDraftModalMessages.validationError)
            }
          ]}
        >
          <Input
            size="sm"
            labelPlacement="outside"
            placeholder={intl.formatMessage(renameDraftModalMessages.namePlaceholder)}
            defaultValue={currentName}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onFocus={handleFocus}
          />
        </Form.Item>
        <Row justify="end">
          <Space>
            <Button onClick={closeModal} ghost type="primary">
              {intl.formatMessage(renameDraftModalMessages.cancelButton)}
            </Button>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage(renameDraftModalMessages.saveButton)}
            </Button>
          </Space>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default RenameDraftModal;
