import styled, { css } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const BackgroundImageFlexRow = styled(H1_FlexRow)`
  position: relative;
  background-color: ${(props) => props.theme.gray2};
  border: 1px dashed ${(props) => props.theme.gray5};
  border-radius: 9px;
  min-height: 185px;
  cursor: pointer;
  opacity: 1;
  img {
    object-fit: contain;
    aspect-ratio: 16/9;
  }
  ${({ isPreviewVisible }: { isPreviewVisible: boolean }) =>
    isPreviewVisible &&
    css`
      border-style: solid;
      &:hover {
        img {
          transition: opacity 0.3s;
          opacity: 0.8;
        }
      }
    `};
`;

export interface BackgroundImageProps {
  isPreviewVisible: boolean;
  onImageClick: () => void;
  children?: React.ReactNode;
}

const BackgroundImage = ({ isPreviewVisible, onImageClick, children }: BackgroundImageProps) => {
  return (
    <BackgroundImageFlexRow
      justify="center"
      align="center"
      height="185px"
      flex="1 1 100%"
      overflow="hidden"
      onClick={onImageClick}
      isPreviewVisible={isPreviewVisible}
    >
      {children}
    </BackgroundImageFlexRow>
  );
};

export default BackgroundImage;
