import { mediaLibraryMessages } from "app/pages/mediaLibrary/messages";
import { mediaLibraryMessages as mediaLibraryMessagesStableDiffusion } from "app/pages/mediaLibrary/StableDiffusion/messages";
import MediaLibraryTabMyMedia from "app/pages/mediaLibrary/MediaLibraryTabMyMedia";

import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { MediaLibraryTabs, MediaType, SelectedImageContext } from "app/types/media";
import UnsplashTab from "app/components/editor/sideDrawers/EditingProjectMediaLibrary/UnsplashTab";
import MediaLibraryTabSDGenerate from "app/pages/mediaLibrary/StableDiffusion/Generate";
import { useFlags } from "launchdarkly-react-client-sdk";
import PexelsTab from "app/components/mediaLibrary/PexelsTab/PexelsTab";
import { FeatureFlag, FetchStatus, MimeType } from "app/types";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import { fetchingStatus } from "app/utils/helpers";
import HouroneTab from "app/components/editor/sideDrawers/EditingProjectMediaLibrary/HouroneTab";
import { Divider, Tab, Tabs } from "@nextui-org/react";

const MarginText = styled(H1_TextSmall)`
  margin-left: 14px;
  margin-right: 14px;
`;

export interface MediaLibraryModalTabsProps {
  activeTab: MediaLibraryTabs;
  selectedUrl?: string;
  onImageSelect: (value: string, context?: SelectedImageContext) => void;
  onZoomItem: (event: React.MouseEvent, url: string, mediaType?: MediaType) => void;
  onTabChange: (value: string) => void;
  progress?: number;
  importLoading: boolean;
  hideStockVideos?: boolean;
  mediaModalOpen: boolean;
  mimetypes?: MimeType[];
  onSelectFile: (file: File[]) => void;
  mainMode?: boolean;
}

const MediaLibraryModalTabs = ({
  activeTab,
  selectedUrl,
  onImageSelect,
  onZoomItem,
  onTabChange,
  progress,
  importLoading,
  mimetypes,
  mediaModalOpen,
  onSelectFile,
  hideStockVideos = false,
  mainMode = false
}: MediaLibraryModalTabsProps) => {
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const intl = useIntl();
  const flags = useFlags();
  const theme = useTheme();
  const allMediaFiles = useAppSelector(mediaGlobalSelectors.selectAll);
  const mediaStatus: FetchStatus = useAppSelector((state) => state.media.mediaStatus);

  useEffect(() => {
    if (mediaStatus === fetchingStatus.succeeded && !isFirstLoaded) {
      setIsFirstLoaded(true);
      onTabChange(
        allMediaFiles.length === 0 ? MediaLibraryTabs.Unsplash : MediaLibraryTabs.MyMedia
      );
    }
  }, [allMediaFiles, mediaStatus, isFirstLoaded]);

  const tabs = [
    {
      title: (
        <MarginText color={theme.gray8}>
          {intl.formatMessage(mediaLibraryMessages.myMediaTab)}
        </MarginText>
      ),
      key: MediaLibraryTabs.MyMedia,
      children: (
        <MediaLibraryTabMyMedia
          onSelectFile={onSelectFile}
          onZoomItem={onZoomItem}
          onImageSelect={onImageSelect}
          selectedUrl={selectedUrl}
          progress={progress}
          mediaModalOpen={mediaModalOpen}
          importLoading={importLoading}
          mimetypes={mimetypes}
        />
      )
    }
  ];
  if (flags[FeatureFlag.houroneStock] && !mainMode) {
    tabs.push({
      title: (
        <MarginText color={theme.gray8}>
          {intl.formatMessage(mediaLibraryMessages.houroneTab)}
        </MarginText>
      ),
      key: MediaLibraryTabs.Hourone,
      children: (
        <HouroneTab
          onSelectImage={onImageSelect}
          currentValue={selectedUrl as string}
          onZoomItem={onZoomItem}
        />
      )
    });
  }
  if (!mainMode) {
    tabs.push({
      title: (
        <MarginText color={theme.gray8}>
          {intl.formatMessage(mediaLibraryMessages.stockTab)}
        </MarginText>
      ),
      key: MediaLibraryTabs.Unsplash,
      children: (
        <UnsplashTab
          onSelectImage={onImageSelect}
          currentValue={selectedUrl as string}
          onZoomItem={onZoomItem}
        />
      )
    });
  }
  if (flags[FeatureFlag.pexels] && !hideStockVideos && !mainMode) {
    tabs.push({
      title: (
        <MarginText color={theme.gray8}>
          {intl.formatMessage(mediaLibraryMessages.pexelsTab)}
        </MarginText>
      ),
      key: MediaLibraryTabs.Pexels,
      children: (
        <PexelsTab
          onSelectImage={onImageSelect}
          currentValue={selectedUrl as string}
          onZoomItem={onZoomItem}
        />
      )
    });
  }

  if (flags[FeatureFlag.sdMediaTab] && !mainMode) {
    tabs.push({
      title: (
        <MarginText color={theme.gray8}>
          {intl.formatMessage(mediaLibraryMessagesStableDiffusion.sdTab)}
        </MarginText>
      ),
      key: MediaLibraryTabs.StableDiffusion,
      children: (
        <MediaLibraryTabSDGenerate onImageSelect={onImageSelect} selectedUrl={selectedUrl} />
      )
    });
  }

  return (
    <H1_FlexColumn flex="1 1 auto" width="100%">
      <Tabs
        variant="underlined"
        color="primary"
        className="pt-4 pl-[30px]"
        defaultSelectedKey={MediaLibraryTabs.MyMedia}
        selectedKey={activeTab}
        onSelectionChange={(key) => onTabChange(key as string)}
        items={tabs}
        classNames={{
          panel: "h-full overflow-hidden px-0 pt-0",
          tab: "px-0",
          tabList: "px-0 pb-0",
          cursor: "w-full"
        }}
      >
        {(item) => (
          <Tab key={item.key} title={item.title}>
            <Divider />
            <H1_FlexColumn flex="1 1 auto" overflow="auto" padding="8px 30px 0" height="100%">
              {item.children}
            </H1_FlexColumn>
          </Tab>
        )}
      </Tabs>
    </H1_FlexColumn>
  );
};

export default MediaLibraryModalTabs;
