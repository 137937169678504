import { DefaultOptionType } from "antd/lib/select";

export enum AugmentedSelection {
  oneSentence = "oneSentence",
  longer = "longer",
  shorten = "shorten",
  happy = "happy",
  serious = "serious",
  professional = "professional",
  young = "young",
  funny = "funny",
  runAsPrompt = "runAsPrompt",
  rephrasePerson = "rephrasePerson",
  rephraseFree = "rephraseFree",
  product = "product",
  sales = "sales",
  bio = "bio"
}

export enum AugmentedType {
  tone = "tone",
  length = "length",
  rephrase = "rephrase",
  free = "free",
  split = "split"
}

export enum AugmentedModification {
  oneSentence = "one_sentence",
  shorten = "shorten",
  expand = "expand",
  rephraseFree = "free",
  rephrasePerson = "first_person"
}

export type GeneratedTranscriptResponse = { transcript: string };

export enum GenerateTranscriptType {
  sdr = "sdr",
  bio = "bio",
  product = "product",
  free = "free"
}

export enum SupportedTranslationLanguages {
  english = "English",
  mandarin = "Mandarin",
  cantonese = "Cantonese",
  spanish = "Spanish",
  french = "French",
  german = "German",
  portuguese = "Portuguese",
  italian = "Italian",
  hindi = "Hindi",
  arabic = "Arabic",
  hebrew = "Hebrew",
  russian = "Russian",
  japanese = "Japanese",
  turkish = "Turkish",
  polish = "Polish",
  hungarian = "Hungarian",
  czech = "Czech",
  dutch = "Dutch",
  korean = "Korean",
  indonesian = "Indonesian",
  swahili = "Swahili",
  tagalog = "Tagalog",
  romanian = "Romanian"
}

export enum SupportedTranslationSubtitlesLanguagesFlagsCode {
  english = "us",
  spanish = "es",
  german = "de",
  portuguese = "pt",
  italian = "it",
  french = "fr",
  dutch = "nl",
  japanese = "jp",
  turkish = "tr",
  polish = "pl",
  hungarian = "hu",
  czech = "cz",
  hebrew = "il",
  chinese = "cn",
  hindi = "in",
  russian = "ru",
  korean = "kr",
  arabic = "sa",
  indonesian = "id",
  tagalog = "tl",
  swahili = "ke",
  romanian = "ro"
}
export enum SupportedTranslationSubtitlesLanguagesCode {
  english = "en",
  spanish = "es",
  german = "de",
  portuguese = "pt",
  italian = "it",
  french = "fr",
  dutch = "nl",
  japanese = "ja",
  turkish = "tr",
  polish = "pl",
  hungarian = "hu",
  czech = "cz",
  hebrew = "he",
  chinese = "zh",
  hindi = "hi",
  russian = "ru",
  korean = "ko",
  arabic = "ar",
  indonesian = "id",
  tagalog = "tl",
  swahili = "sw",
  romanian = "ro"
}

export enum SupportedSubtitlesFileExtension {
  vtt = "vtt",
  srt = "srt"
}

export enum PromptTone {
  happy = "happy",
  serious = "serious",
  professional = "professional",
  young = "young",
  funny = "funny"
}

export type GenerateTranscript = {
  type: GenerateTranscriptType;
  parameters?: {
    tone?: PromptTone;
    name?: string;
    characteristics?: string;
    lead_linkedin_profile?: string;
    sdr_linkedin_profile?: string;
    product_description?: string;
    prompt?: string;
  };
};

export enum AiUseCase {
  product = "product",
  sales = "sales"
}

export enum VideoWizardDuration {
  seconds15 = "15s",
  seconds30 = "30s",
  seconds45 = "45s",
  seconds60 = "60s",
  seconds75 = "1m15s"
}

export enum VideoWizardCategory {
  sales = "sales"
}

export enum VideoWizardType {
  coldOutboundMail = "coldOutboundMail"
}

export enum VideoWizardRatio {
  landscape = "landscape",
  portrait = "portrait",
  square = "square"
}

export interface VideoWizardV2Properties {
  ratio?: string;
  subCategory: string | undefined;
  subCategoryTitle: string | undefined;
  category?: string;
  duration?: string;
  variablesValues: Record<string, string>;
  recipeId?: string;
  style?: VideoWizardStyle;
}

export enum VideoWizardStage {
  stage1 = "stage1",
  stage2 = "stage2"
}

export enum VideoWizardStyle {
  corporate = "corporate",
  fun = "fun",
  basic = "basic"
}

export interface VideoWizardWorkflowVariables {
  name: string;
  type: string;
  placeholder: string;
  required: boolean;
}
export interface VideoWizardWorkflowSubCategory {
  value: string;
  label: string;
  variables: VideoWizardWorkflowVariables[];
}
export interface VideoWizardWorkflowCategory {
  value: string;
  label: string;
  subCategories?: VideoWizardWorkflowSubCategory[];
}

export interface VideoWizardWorkflowSerialized {
  durationOptions: DefaultOptionType[];
  categoriesOptions: VideoWizardWorkflowCategory[];
}
export interface VideoWizardWorkflow {
  lengths: string[];
  categories: {
    id: string;
    title: string;
    sub_categories?: {
      id: string;
      title: string;
      variables: {
        name: string;
        type: string;
        placeholder: string;
        required: boolean;
      }[];
    }[];
  }[];
  styles: {
    ratio: string;
    recipes_styles: {
      style: VideoWizardStyle;
      recipe_id: string;
    }[];
  }[];
}
