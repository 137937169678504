import { useEffect, useState } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import { importDocumentsModalMessages } from "app/components/editor/documents/messages";
import StartFromTemplate from "app/components/editor/documents/StartFromTemplate";

import {
  draftGeneratorRequest,
  DraftGeneratorType,
  ImportDataStoreRequest,
  Location,
  VideoLength
} from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";

import { fetchingStatus } from "app/utils/helpers";
import { dataStoreActions } from "app/store/slices/dataStore.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import dataStoreThunk from "app/store/thunks/dataStore.thunk";
import styled, { useTheme } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { draftsActions } from "app/store/slices/drafts.slice";
import DocumentLoadingCreationModal from "app/components/editor/documents/DocumentLoadingCreationModal";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useNavigate } from "react-router-dom";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import UploadUrlContent from "app/components/editor/documents/UploadUrlContent";
import UrlSettingsContent from "app/components/editor/documents/UrlSettingsContent";

const UploadWrapper = styled(H1_FlexColumn)`
  > div {
    height: 100%;
    > span {
      height: 100%;
    }
  }
`;
const StartFromUrlModal = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { editorModalOpen, closeModal } = useModal();

  const visible = editorModalOpen?.open === ModalName.startFromUrlModal;
  const recipeId = editorModalOpen?.context?.recipeId;
  const createDraftStatus = useAppSelector((state) => state.drafts.createDraftStatus);
  const createDraftFromImportLoading = createDraftStatus === fetchingStatus.loading;
  const importDataStatus = useAppSelector((state) => state.dataStore.importDataStatus);
  const importDataStatusLoading = importDataStatus === fetchingStatus.loading;
  const importDataResult = useAppSelector((state) => state.dataStore.importDataResult);
  const draftGeneratorOrderId = useAppSelector((state) => state.drafts.createDraftGeneratorOrderId);
  const dataStoreId = importDataResult?.id;
  const createDraftGeneratorStatus = useAppSelector(
    (state) => state.drafts.createDraftGeneratorStatus
  );
  const createDraftGeneratorStatusLoading = createDraftGeneratorStatus === fetchingStatus.loading;
  const draftGeneratorId = useAppSelector((state) => state.drafts.draftGeneratorId);

  useEffect(() => {
    if (visible && createDraftGeneratorStatus === fetchingStatus.succeeded) {
      const editorDraftLink = `${Location.Editor}/${draftGeneratorId}`;
      dispatch(analyticsEvents.StartFromUrl({ status: "success" }));
      dispatch(analyticsEvents.navigateToEditor({ source: "import_data" }));
      dispatch(uiActionsV2.removeFromTray(draftGeneratorOrderId));
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
      navigate(editorDraftLink);
      closeModal();
    }
    if (visible && createDraftGeneratorStatus === fetchingStatus.failed) {
      dispatch(analyticsEvents.StartFromUrl({ status: "fail" }));
      setTimeout(() => dispatch(uiActionsV2.removeFromTray(draftGeneratorOrderId)), 1000);
      closeModal();
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
    }
  }, [createDraftGeneratorStatus]);

  useEffect(() => {
    if (visible && importDataStatus === fetchingStatus.succeeded) {
      dispatch(dataStoreActions.updateImportDataToIdle());
      setCurrentStep(currentStep + 1);
    }
    if (importDataStatus === fetchingStatus.failed) {
      dispatch(dataStoreActions.updateImportDataToIdle());
    }
  }, [importDataStatus]);

  useEffect(() => {
    if (!visible) {
      resetModal();
    }
  }, [visible]);
  const resetModal = () => {
    setCurrentStep(0);
    dispatch(dataStoreActions.cleanDataStore());
    dispatch(dataStoreActions.updateImportDataToIdle());
  };
  const stepOneSucceeded = (importRequest: ImportDataStoreRequest) => {
    dispatch(dataStoreThunk.importDataRequest(importRequest));
  };
  const stepTwoSucceeded = (videoLength: VideoLength, textType: DraftGeneratorType) => {
    const draftGeneratorRequest: draftGeneratorRequest = {
      title: "Video from my Url",
      order_id: uuidv4().toString(),
      data_store_id: dataStoreId as string,
      video_length: videoLength,
      type: textType,
      recipe_id: recipeId
    };
    dispatch(draftsActions.createDraftGenerator(draftGeneratorRequest));
  };

  const startFromDocumentStepsTitles = [
    {
      title: intl.formatMessage(importDocumentsModalMessages.FirstStepUrl)
    },
    {
      title: intl.formatMessage(importDocumentsModalMessages.SecondStepUrl)
    }
  ];

  if (visible && createDraftGeneratorStatusLoading) {
    return <DocumentLoadingCreationModal trayKey={draftGeneratorOrderId} startFromType="URL" />;
  }
  return (
    <StartFromTemplate
      open={visible}
      current={currentStep}
      onClose={closeModal}
      displaySteps={!createDraftFromImportLoading}
      titles={
        <H1_TextMidHeadline color={theme.gray11} whiteSpace="normal">
          {intl.formatMessage(importDocumentsModalMessages.UrlHeadlineTitle)}
        </H1_TextMidHeadline>
      }
      startFromSteps={startFromDocumentStepsTitles}
    >
      <ConditionalRender condition={!createDraftFromImportLoading}>
        <ConditionalRender condition={currentStep === 0 && !importDataStatusLoading}>
          <UploadWrapper height="100%" width="100%">
            <UploadUrlContent uploadSucceeded={stepOneSucceeded} />
          </UploadWrapper>
        </ConditionalRender>
        <ConditionalRender condition={importDataStatusLoading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={currentStep === 1}>
          <UrlSettingsContent reset={resetModal} onFinish={stepTwoSucceeded} />
        </ConditionalRender>
      </ConditionalRender>
    </StartFromTemplate>
  );
};
export default StartFromUrlModal;
