import { PaletteColor } from "app/types";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

interface PaletteBackgroundProps {
  colors: PaletteColor[];
  selected?: boolean;
}

const StyledColor = styled.div<{
  selected?: boolean;
  color?: string;
  leftRound?: boolean;
  rightRound?: boolean;
}>`
  background-color: ${({ color }) => color};
  flex: 1;
`;

const PaletteBackground = ({ colors, selected }: PaletteBackgroundProps) => {
  return (
    <H1_FlexRow height="72px" width="133px">
      {colors.map((paletteColor, index) => {
        return <StyledColor key={index} selected={selected} color={paletteColor.color} />;
      })}
    </H1_FlexRow>
  );
};

export default PaletteBackground;
