import { getVideoThumbnailWithPlayCircle } from "app/utils/helpers";
import useErrors from "app/hooks/useErrors";
import useNotifications from "app/hooks/useNotifications";
import { defineMessages, useIntl } from "react-intl";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import * as Sentry from "@sentry/react";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { paymentModalMessages } from "app/pages/pricing/messages";
import { Video } from "app/types";
import Config from "app/config/Config";

const messages = defineMessages({
  prepareNotification: {
    id: "useEmailVideoEmbedSender.notifications.preparing",
    defaultMessage: "Preparing email embed"
  },
  copyNotification: {
    id: "useEmailVideoEmbedSender.notifications.copy",
    defaultMessage: "Copied email embed to clipboard"
  }
});

const useEmailVideoEmbedSender = () => {
  const intl = useIntl();
  const { notifyError } = useErrors();
  const { notifyMessages } = useNotifications();
  const showPaymentModal = useUpgradeModal();
  const upgradeText = intl.formatMessage(paymentModalMessages.upgradeEmbedTitle);

  const prepareEmailContent = async (video: Video, source: string) => {
    const reqId = video.req_id;
    if (showPaymentModal({ source, upgradeText, requestedFeature: PlanFeature.EmbedVideo })) {
      return;
    }

    if (typeof ClipboardItem === "undefined") {
      notifyMessages([{ message: "This feature is not available on your browser." }]);
      return;
    }
    try {
      notifyMessages([{ message: intl.formatMessage(messages.prepareNotification) }]);

      const thumbnail = await getVideoThumbnailWithPlayCircle(reqId);
      const myDiv = document.createElement("div");
      myDiv.style.height = "257px";

      myDiv.style.display = "relative";
      myDiv.style.width = "460px";
      myDiv.style.maxWidth = "100%";
      const link = document.createElement("a");
      const image = document.createElement("img");
      image.setAttribute("src", thumbnail);
      image.height = 257;
      image.width = 460;
      const playerUrl = `${Config.ogPlayerUrl}/${reqId}`;
      link.setAttribute("href", playerUrl);
      link.appendChild(image);
      myDiv.appendChild(link);
      const html = myDiv.outerHTML;
      const htmlBlob = new Blob([html], { type: "text/html" });

      const text = myDiv.textContent ?? "";
      const textBlob = new Blob([text], { type: "text/plain" });

      const clipboardItem = new ClipboardItem({
        [htmlBlob.type]: htmlBlob,
        [textBlob.type]: textBlob
      });

      await navigator.clipboard.write([clipboardItem]);
      notifyMessages([{ message: intl.formatMessage(messages.copyNotification) }]);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err, {
        extra: {
          description: "Embed in email failed"
        }
      });
      notifyError({ general: buildGeneralError("Embed in email failed", intl) });
    }
  };
  return {
    prepareEmailContent
  };
};

export default useEmailVideoEmbedSender;
