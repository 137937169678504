import React, { ReactNode } from "react";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import { CircularProgress } from "@nextui-org/react";

const Icon = styled.i`
  font-size: 30px;
  color: ${({ theme }) => theme.gray5};
`;
const UploadAreaFlexColumn = styled(H1_FlexColumn)<{ $disabled: boolean }>`
  border: 2px dashed ${({ theme }) => theme.gray5};
  border-radius: 4px;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  &:hover {
    border-color: ${({ theme }) => theme.gray7};
    span,
    i {
      color: ${({ theme }) => theme.gray7};
    }
  }
`;
interface UploadAreaProps {
  width?: string;
  height?: string;
  icon?: ReactNode;
  text: string;
  disabled?: boolean;
  progress?: number;
  showValueLabel?: boolean;
}
const UploadArea = ({
  width = "100%",
  height = "100",
  icon = <Icon className="far fa-cloud-arrow-up" />,
  disabled = false,
  text,
  progress,
  showValueLabel = false
}: UploadAreaProps) => {
  const theme = useTheme();
  return (
    <UploadAreaFlexColumn
      $disabled={disabled}
      justify="center"
      align="center"
      width={width}
      height={height}
    >
      {progress && progress > 0 && progress < 100 ? (
        <CircularProgress
          showValueLabel={showValueLabel}
          value={progress}
          color="primary"
          size="lg"
          aria-label="Loading..."
        />
      ) : (
        icon
      )}
      <H1_TextXs color={theme.gray5}>{text}</H1_TextXs>
    </UploadAreaFlexColumn>
  );
};

export default UploadArea;
