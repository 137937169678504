import { defineMessages, useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import styled, { useTheme } from "styled-components";
import srcImage from "app/assets/images/empty-balance.png";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import ConditionalRender from "app/components/common/ConditionalRender";
import useSmallScreen from "app/hooks/useSmallScreen";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextLargeHeadline,
  H1_TextSmall,
  H1_TextSubtitle
} from "app/components/_Infrastructure/Typography";
import { getDefaultWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { userActions } from "app/store/slices/user.slice";
import { Location } from "app/types";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const messages = defineMessages({
  title: {
    id: "workspace.modals.no-access-modal.title",
    defaultMessage: "Denied Entry to Workspace :("
  },
  subTitle: {
    id: "workspace.modals.no-access-modal.subtitle",
    defaultMessage: "You do not have access to the selected workspace"
  },
  description: {
    id: "workspace.modals.no-access-modal.description",
    defaultMessage:
      "To gain access to the workspace, you need to communicate with the workspace administrator."
  },
  fallbkacBtn: {
    id: "workspace.modals.no-access-modal.fallback-btn",
    defaultMessage: "Click to switch to default workspace"
  }
});

const StyledFlexContainer = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    width: 100%;
    gap: 0;
    align-items: flex-start;
  }
`;

const ProgressBarFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex: 0 0 45px;
    width: 100%;
  }
`;

const Rectangle = styled.svg`
  background-color: ${({ theme }) => theme.orange4};
  transform: rotate(14deg);
  width: 311px;
  height: 153px;
  left: 30px;
  top: 88px;
  position: absolute;
  z-index: 1;
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 2;
  width: 243px;
  height: 258px;
  left: 87px;
  top: 0;
  clip-path: polygon(0% 0%, 60% 0%, 100% 0%, 100% 100%, 67% 100%, 0% 85%);
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const NoWorkspaceAccessModal = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isSmallScreen } = useSmallScreen();
  const { editorModalOpen, closeModal } = useModal();
  const theme = useTheme();
  const defaultWorkspace = useAppSelector(getDefaultWorkspace);
  const navigate = useNavigate();

  const visible = editorModalOpen?.open === ModalName.noAccessToWorkspace;

  const onFallback = () => {
    if (defaultWorkspace) {
      dispatch(
        userActions.updateUserPreferencesRequest({ current_workspace_id: defaultWorkspace.id })
      );
      closeModal();
      navigate(Location.Home);
    }
  };
  return (
    <StyledModal
      width="833px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "470px" }}
      closable={false}
      destroyOnClose
      $padding="40px 62px"
      $alignItems="center"
    >
      <StyledFlexContainer width="100%" height="100%" align="center" gap="46px">
        <ProgressBarFlexColumn
          position="relative"
          flex="0 0 366px"
          padding="0 10px 0 0"
          height="100%"
        >
          <ConditionalRender condition={!isSmallScreen}>
            <Rectangle />
            <StyledImage src={srcImage} />
          </ConditionalRender>
        </ProgressBarFlexColumn>
        <H1_FlexColumn width="308px" justify="space-between" padding="15px 0 0 0" gap="15px">
          <H1_FlexColumn gap="12px">
            <H1_TextLargeHeadline whiteSpace="break-spaces" fontWeight={700} color={theme.gray10}>
              {intl.formatMessage(messages.title)}
            </H1_TextLargeHeadline>
            <H1_TextSubtitle whiteSpace="break-spaces" fontWeight={600} color={theme.gray10}>
              {intl.formatMessage(messages.subTitle)}
            </H1_TextSubtitle>
            <H1_TextSmall whiteSpace="break-spaces" color={theme.gray10}>
              {
                intl.formatMessage(messages.description, {
                  br: <br />
                }) as React.ReactNode
              }
            </H1_TextSmall>
          </H1_FlexColumn>
          <Button onClick={onFallback} color="primary">
            {intl.formatMessage(messages.fallbkacBtn)}
          </Button>
        </H1_FlexColumn>
      </StyledFlexContainer>
    </StyledModal>
  );
};

export default NoWorkspaceAccessModal;
