import styled, { useTheme } from "styled-components";
import { Popover, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import React, { useMemo, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { SwiperSlide } from "swiper/react";
import { playgroundActions } from "app/store/slices/playground.slice";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import VideoWithHover from "app/components/common/VideoWithHover";
import { playgroundVideosCollection } from "app/pages/PlayGround/urls";
import UploadArea from "app/components/common/UploadArea";
import ConditionalRender from "app/components/common/ConditionalRender";
import { PreloadedVideo } from "app/types/playground";
import { useUpgradeByPlan } from "app/hooks/useUpgradeModal";
import { MimeType } from "app/types";
import UploadFileContent from "app/components/common/UploadFileContent";
import MediaFileItem from "app/pages/PlayGround/MediaFileItem";
import { addPolicyToUrlIfFileStackMedia } from "app/utils/helpers";
import { getFilteredMediaBySpecificType } from "app/store/selectorsV2/media.selectors";
import { MediaType } from "app/types/media";
import { Button } from "@nextui-org/react";

const StyledSwiper = styled(NavigationSwiper)`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  margin-left: 0;
  margin-right: 0;

  .swiper-slide {
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    top: calc(50% - 2px);
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(64 87 109 / 7%);
    border: 1px solid #8c8c8c;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: #8c8c8c;
    background-color: #343445;
    text-align: center;
    transition-property: border-color, color, opacity;
    transition-duration: 0.3s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);

    svg {
      fill: #8c8c8c;
      width: 16px;
      height: 16px;
    }

    &.swiper-button-disabled {
      pointer-events: auto;
    }

    &:hover {
      border-color: #8c8c8c;
    }

    &:active {
      transform: scale(0.95);
    }

    &::after {
      content: "";
    }
  }

  .swiper-button-prev {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-left-gray.svg");
    left: 0;
  }

  .swiper-button-next {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-right-gray.svg");
    right: 0;
  }

  &:hover .swiper-button-prev:not(.swiper-button-disabled),
  &:hover .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const OuterImageBackgroundContainer = styled(H1_FlexRow)<{ $isBorderDisplayed: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ $isBorderDisplayed }) => ($isBorderDisplayed ? "white" : "transparent")};
  background-color: transparent;
  padding: 2px; /* Space between the border and the inner element */
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const BackgroundContainer = styled(H1_FlexColumn)`
  background-color: #32323c;
  min-width: 745px;
  min-height: 197px;
`;
const PopoverContainerFlexColumn = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: rgba(52, 52, 69, 0.5);
`;
const PresenterImageBackgroundFlexRow = styled(H1_FlexRow)`
  border-radius: 2px;
`;
const RemoveUploadButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
`;
const StyledVideoWithHover = styled(VideoWithHover)`
  position: relative;
  cursor: pointer;
  z-index: 10;
  width: calc((148px * 16) / 9);
  height: 148px;
  video {
    object-fit: contain;
  }
`;
const UploadIcon = styled.i<{ $size: string }>`
  font-size: ${({ $size }) => $size};
  color: #d9d9d9;
`;
const StyledPopover = styled(Popover)`
  padding: 0;

  &&& .ant-popover-inner-content {
    padding: 0;
  }
`;

const BackgroundIcon = styled(H1_FlexRow)`
  background-color: #aaaaff1f;
`;

const Icon = styled.i`
  color: white;
`;

interface PlaygroundSelectExistingVideoProps {
  executeImport: (files: File[]) => void;
  progress?: number;
  importLoading: boolean;
  offsetX?: number;
}
const PlaygroundSelectExistingVideo = ({
  importLoading,
  progress,
  executeImport,
  offsetX = -20
}: PlaygroundSelectExistingVideoProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const upgradeModal = useUpgradeByPlan();
  const customPresenter = useAppSelector((state) => state.playground.customPresenter);

  const files = useAppSelector((state) => getFilteredMediaBySpecificType(state, MediaType.video));

  const filestackReadPolicy = useAppSelector((state) => state.media.filestackReadPolicy);

  const onSelectVideo = (value: PreloadedVideo) => {
    dispatch(googleEvents.playgroundPresenterSelection({ selectedPresenterId: value.url }));
    dispatch(
      playgroundActions.updatePlayGroundDetails({
        customPresenter: {
          id: value.url,
          name: "User video",
          image: value.thumbnail,
          url: value.url
        }
      })
    );
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onClickRemoveCustomPresenter = () => {
    dispatch(playgroundActions.removePlayGroundCustomPresenter());
  };

  const onClickUpgrade = () => {
    upgradeModal({ source: `upgrade_playground_upload_video` });
  };

  const content = useMemo(
    () => (
      <BackgroundContainer width="1200px" height="197px">
        <PopoverContainerFlexColumn
          padding="17px 25px 19px"
          width="100%"
          height="100%"
          align="center"
          justify="space-between"
        >
          <H1_FlexRow width="100%" flex="0 0 36px" align="center" justify="space-between">
            <H1_FlexRow gap="10px" align="center">
              <BackgroundIcon align="center" justify="center" width="30px" height="30px">
                <Icon className="fas fa-user" />
              </BackgroundIcon>
              <H1_FlexColumn>
                <H1_FlexRow gap="15px" align="center">
                  <H1_TextSmall color="white">Select Video</H1_TextSmall>
                  <Tooltip
                    color={theme.blue4}
                    placement="right"
                    title="Transform your videos effortlessly to speaking videos with our AI. Make sure to upload video with a person looking at the camera the entire video clip."
                  >
                    <Icon className="fa-regular fa-info-circle" />
                  </Tooltip>
                </H1_FlexRow>
                <H1_TextXs margin="-5px 0 0 0" color="#8c8c8c">
                  Select video from videos collection
                </H1_TextXs>
              </H1_FlexColumn>
            </H1_FlexRow>
          </H1_FlexRow>
          <H1_FlexColumn padding="20px 0 0 0" gap="5px" width="100%">
            <H1_FlexRow gap="18px" width="100%">
              <StyledSwiper
                allowTouchMove={false}
                navigation
                speed={700}
                slidesPerView={6}
                slidesPerGroup={6}
              >
                <SwiperSlide key="upgrade">
                  <H1_FlexRow position="relative" onClick={onClickUpgrade}>
                    <UploadFileContent
                      fileTypes={[MimeType.mp4]}
                      onSelectFile={(files) => {
                        executeImport(files);
                        setOpen(false);
                      }}
                    >
                      <UploadArea
                        width="calc((81px * 16) / 9)"
                        height="81px"
                        text="Upload video"
                        icon={<UploadIcon $size="20px" className="far fa-cloud-arrow-up" />}
                      />
                    </UploadFileContent>
                  </H1_FlexRow>
                </SwiperSlide>

                {files.map((file) => (
                  <SwiperSlide key={file.id}>
                    <MediaFileItem fileId={file.id} onSelectMedia={onSelectVideo} />
                  </SwiperSlide>
                ))}
                {playgroundVideosCollection.map((video: PreloadedVideo) => (
                  <SwiperSlide key={video.url}>
                    <H1_FlexColumn gap="5px">
                      <OuterImageBackgroundContainer
                        flex="0 0 87px"
                        height="87px"
                        $isBorderDisplayed={video.url === customPresenter?.url}
                        position="relative"
                      >
                        <H1_FlexRow
                          position="relative"
                          width="calc((81px * 16) / 9)"
                          height="81px"
                          onClick={() => onSelectVideo(video)}
                          justify="center"
                        >
                          <VideoWithHover
                            video={video.preview}
                            image={video.thumbnail}
                            autoPlay={false}
                            disableIcon
                            shouldRestartOnLeave={false}
                          />
                        </H1_FlexRow>
                      </OuterImageBackgroundContainer>
                    </H1_FlexColumn>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </H1_FlexRow>
          </H1_FlexColumn>
        </PopoverContainerFlexColumn>
      </BackgroundContainer>
    ),
    [files]
  );

  return (
    <StyledPopover
      placement="topRight"
      className="select-video"
      getPopupContainer={() => document.querySelector(`.select-video`) as HTMLElement}
      content={content}
      overlayInnerStyle={{
        padding: 0
      }}
      trigger="click"
      showArrow={false}
      align={{ targetOffset: [offsetX, 0] }}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <ConditionalRender condition={!customPresenter || importLoading}>
        <UploadArea
          width="270px"
          height="148px"
          text="Upload video"
          progress={progress}
          showValueLabel
          icon={
            importLoading ? (
              <UploadIcon $size={"30px"} className="fas fa-spinner-third fa-spin" />
            ) : (
              <UploadIcon $size={"30px"} className="far fa-video-plus" />
            )
          }
        />
      </ConditionalRender>
      <ConditionalRender condition={!!customPresenter && !importLoading}>
        <PresenterImageBackgroundFlexRow
          align="center"
          width="calc((148px * 16) / 9)"
          height="148px"
          justify="center"
          position="relative"
        >
          <StyledVideoWithHover
            video={addPolicyToUrlIfFileStackMedia(
              customPresenter?.url as string,
              filestackReadPolicy
            )}
            image={customPresenter?.image as string}
            autoPlay
            disableIcon
            shouldRestartOnLeave
          />
          <RemoveUploadButton
            onClick={onClickRemoveCustomPresenter}
            isIconOnly
            startContent={<i className="fas fa-trash" />}
          />
        </PresenterImageBackgroundFlexRow>
      </ConditionalRender>
    </StyledPopover>
  );
};

export default PlaygroundSelectExistingVideo;
