import { knowledgeBaseUrl } from "app/utils/urls";

declare global {
  interface Window {
    // zE: any;
    // zESettings: any;
    HubSpotConversations: any;
  }
}

// if (!window?.zE) {
//   window.zE = {};
//   window.zESettings = {};
// }

if (!window.HubSpotConversations) {
  window.HubSpotConversations = {};
}

export const onOpenZendesk = () => {
  window.HubSpotConversations.widget.open();
};

export const onOpenKnowledgeBase = () => {
  window.open(knowledgeBaseUrl, "_blank");
};
