import { useAppDispatch } from "app/hooks";

import uiActions from "app/store/actions/ui.actions";
import { ValidationErrors } from "app/types";

const useErrors = () => {
  const dispatch = useAppDispatch();

  const notifyError = (errors: ValidationErrors) => {
    dispatch(uiActions.setErrors(errors));
  };

  return { notifyError };
};

export default useErrors;
