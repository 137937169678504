import { Spin } from "antd";
import { ReactComponent as RecordIcon } from "app/assets/editor/record-icon.svg";

import { LoadingOutlined } from "@ant-design/icons";
import ConditionalRender from "app/components/common/ConditionalRender";
import { screenRecordingInitialSessionMessages } from "app/components/screenRecording/messages";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { ReactNode } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextLargeHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;
export interface InitialSessionProps {
  onStartRecording: () => void;
  onClose: () => void;
  loading?: boolean;
  audioError?: boolean;
}

const StyledButton = styled(Button)`
  svg {
    fill: white;
  }
`;

const InitialSession = ({
  loading,
  onStartRecording,
  onClose,
  audioError
}: InitialSessionProps) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <StyledModal
      visible
      destroyOnClose
      centered
      width="391px"
      footer={null}
      bodyStyle={{ height: "363px" }}
      closeIcon={<CloseModalIcon />}
      onCancel={onClose}
      $padding="40px 0px"
      $alignItems="center"
      $justifyContent="space-between"
      className="no-blurry-background"
    >
      <H1_FlexColumn height="364px" align="center" justify="space-between">
        <H1_FlexColumn align="center" gap="10px">
          <H1_TextLargeHeadline color={theme.gray9} fontWeight={400}>
            {intl.formatMessage(screenRecordingInitialSessionMessages.title)}
          </H1_TextLargeHeadline>
          <H1_TextSmall textAlign="center" ellipsis color={theme.gray9}>
            {
              intl.formatMessage(screenRecordingInitialSessionMessages.subtitle, {
                br: <br />
              }) as ReactNode
            }
          </H1_TextSmall>
        </H1_FlexColumn>
        <H1_FlexColumn align="center" gap="26px">
          <ConditionalRender condition={loading}>
            <H1_FlexRow gap="10px" align="center">
              <Spin spinning={loading} indicator={antIcon} />
              <H1_TextXs fontSize="11px" color={theme.gray9}>
                {intl.formatMessage(screenRecordingInitialSessionMessages.preparingAudio)}
              </H1_TextXs>
            </H1_FlexRow>
          </ConditionalRender>
          <H1_FlexColumn align={"center"}>
            <ConditionalRender condition={audioError}>
              <H1_TextSmall textAlign="center" ellipsis color={"red"}>
                {
                  intl.formatMessage(screenRecordingInitialSessionMessages.audioError, {
                    br: <br />
                  }) as ReactNode
                }
              </H1_TextSmall>
            </ConditionalRender>
            <StyledButton
              color="danger"
              onClick={onStartRecording}
              startContent={<RecordIcon />}
              isDisabled={loading || audioError}
            >
              {intl.formatMessage(screenRecordingInitialSessionMessages.startRecordingButton)}
            </StyledButton>
          </H1_FlexColumn>
        </H1_FlexColumn>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default InitialSession;
