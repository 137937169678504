import React, { ReactNode, useMemo } from "react";
import {
  paymentModalMessages,
  pricingPageMessages,
  pricingPlansFeatures
} from "app/pages/pricing/messages";
import { useIntl } from "react-intl";
import styled, { css, useTheme } from "styled-components";
import { ReactComponent as VideosIcon } from "app/assets/pricing/videos.svg";
import { ReactComponent as PreviewIcon } from "app/assets/pricing/preview.svg";
import { ReactComponent as LogoIcon } from "app/assets/pricing/logo.svg";
import { ReactComponent as VoicesIcon } from "app/assets/pricing/voices.svg";
import { ReactComponent as ScenesIcon } from "app/assets/pricing/scenes.svg";
import { ReactComponent as PresentersIcon } from "app/assets/pricing/presenters.svg";
import { ReactComponent as TemplatesIcon } from "app/assets/pricing/templates.svg";
import { ReactComponent as ShareIcon } from "app/assets/pricing/share.svg";

import {
  FeatureFlag,
  maxWorkspaceMembers,
  PaymentPeriod,
  PlanEnum,
  PlanToUpgrade,
  SubscriptionPlanEnum
} from "app/types";
import { useAppSelector } from "app/hooks";
import { discountPrice } from "app/utils/helpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import {
  getAllBusinessMonthlyPlansMinutes,
  getBusinessMonthlyPlan,
  getBusinessYearlyPlan,
  getCurrentBusinessMinutes,
  getPersonalMonthlyPlan,
  getPersonalYearlyPlan
} from "app/store/selectorsV2/payments.selectors";
import { SliderMarks } from "antd/es/slider";

const AlignSelfText = styled(H1_TextMidHeadline)`
  align-self: flex-end;
  ${({ opacity }: { opacity?: boolean }) =>
    opacity &&
    css`
      opacity: 0.17;
    `}
`;

export interface PlanData {
  name: string;
  displayName?: string;
  description: string | ReactNode;
  price?: number | string;
  priceDescription?: string | ReactNode;
  headerFeatures: (string | ReactNode)[];
  minutes: number;
  promotion?: boolean;
  popular?: boolean;
}

export type PlanValues = Record<string, string | number | ReactNode>;
export interface PlanFeature {
  feature: string;
  plans: PlanValues;
  info?: string | ReactNode;
}

export const usePlanCustomizationFeatures = (): PlanFeature[] => {
  const { formatMessage } = useIntl();
  const features: PlanFeature[] = useMemo(() => {
    return [
      {
        feature: formatMessage(pricingPlansFeatures.webAvatar),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.voiceCloning),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.voiceUpload),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.musicUpload),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.noWaterMark),
        plans: {
          "on-boarding": undefined,
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.brandKit),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.brandIntroOutro),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: "v",
          enterprise: "v"
        }
      }
    ];
  }, [formatMessage]);

  return features;
};

export const usePricingPlans = ({ currentPlanType }: { currentPlanType: PaymentPeriod }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const pricingAndPlans = useAppSelector((state) => state.payments.pricingAndPlans);
  const { numberOfMinutesPosition } = useAppSelector(
    (state) => state.payments.pricingModalProperties
  );
  const planBusinessMonthly = useAppSelector(getBusinessMonthlyPlan);
  const planPersonalMonthly = useAppSelector(getPersonalMonthlyPlan);
  const allBusinessMonthlyPlansMinutes: SliderMarks = useAppSelector(
    getAllBusinessMonthlyPlansMinutes
  );
  const isPaymentMonthly = currentPlanType === PaymentPeriod.Monthly;

  const plans = useMemo(() => {
    return {
      "on-boarding": {
        name: formatMessage(pricingPageMessages.onBoardingTitle),
        displayName: formatMessage(pricingPageMessages.onBoardingTitle),
        subtitle: formatMessage(pricingPageMessages.onBoardingSubtitle),
        price: 0,
        priceMonthly: () => formatMessage(pricingPageMessages.freeMinutes),
        priceYearly: () => formatMessage(pricingPageMessages.freeMinutes),
        finalPrice: () => formatMessage(pricingPageMessages.onBoardingPrice),
        priceDescription: formatMessage(pricingPageMessages.onBoardingPriceDescription),
        priceYearlyDescription: formatMessage(pricingPageMessages.onBoardingPriceDescription),
        optionsTitle: formatMessage(pricingPageMessages.onBoardingOptionsTitle),
        headerFeatures: [
          { text: formatMessage(pricingPageMessages.onBoardingShareVideosViaLink) },
          { text: formatMessage(pricingPageMessages.onBoardingAiAvatars) },
          { text: formatMessage(pricingPageMessages.onBoardingOneUser) }
        ]
      },
      personal: {
        name: formatMessage(pricingPageMessages.personalTitle),
        displayName: formatMessage(pricingPageMessages.personalTitle),
        subtitle: formatMessage(pricingPageMessages.personalSubtitle),
        price: planPersonalMonthly?.price,
        priceMonthly: () => formatMessage(pricingPageMessages.generalMonthlyPrice),
        priceYearly: () => formatMessage(pricingPageMessages.generalYearlyPrice),
        finalPrice: (priceNumber: number) =>
          // @ts-ignore handels intl formatting with elements issue
          formatMessage(
            isPaymentMonthly
              ? pricingPageMessages.generalPrice
              : pricingPageMessages.generalPriceAnnually,
            {
              span: (...text) => (
                <AlignSelfText
                  lineHeight="25px"
                  fontSize="15px"
                  fontWeight={500}
                  color={theme.gray8}
                >
                  {text}
                </AlignSelfText>
              ),
              price: priceNumber
            }
          ),
        priceDescription: formatMessage(pricingPageMessages.personalPriceDescription),
        priceYearlyDescription: formatMessage(pricingPageMessages.personalPriceYearlyDescription),
        optionsTitle: formatMessage(pricingPageMessages.personalOptionsTitle),
        headerFeatures: [
          { text: formatMessage(pricingPageMessages.personalVideoDownload) },
          { text: formatMessage(pricingPageMessages.personalNoWatermark) },
          { text: formatMessage(pricingPageMessages.personalAll2dTemplates) },
          { text: formatMessage(pricingPageMessages.personalScriptVideoWizard) },
          { text: formatMessage(pricingPageMessages.personalLiteSeats) }
        ]
      },
      business: {
        name: formatMessage(pricingPageMessages.businessTitle),
        displayName: formatMessage(pricingPageMessages.businessTitle),
        subtitle: formatMessage(pricingPageMessages.businessSubtitle),
        price: planBusinessMonthly?.price,
        priceMonthly: (numberOfMinutesPosition?: number) =>
          formatMessage(pricingPageMessages.businessGeneralMonthlyPrice, {
            number: numberOfMinutesPosition
          }),
        priceYearly: (numberOfMinutesPosition?: number) =>
          formatMessage(pricingPageMessages.businessGeneralYearlyPrice, {
            number: numberOfMinutesPosition
          }),
        finalPrice: (priceNumber: number) =>
          // @ts-ignore handels intl formatting with elements issue
          formatMessage(
            isPaymentMonthly
              ? pricingPageMessages.generalPrice
              : pricingPageMessages.generalPriceAnnually,
            {
              span: (...text) => (
                <AlignSelfText
                  lineHeight="25px"
                  fontSize="15px"
                  fontWeight={500}
                  color={theme.gray8}
                >
                  {text}
                </AlignSelfText>
              ),
              price: priceNumber
            }
          ),
        priceDescription: formatMessage(pricingPageMessages.businessPriceDescription),
        priceYearlyDescription: formatMessage(pricingPageMessages.businessPriceYearlyDescription),
        optionsTitle: formatMessage(pricingPageMessages.businessOptionsTitle),
        headerFeatures: [
          {
            text: formatMessage(pricingPageMessages.businessAccessTo3dTemplates)
          },
          { text: formatMessage(pricingPageMessages.businessBrandKit) },
          { text: formatMessage(pricingPageMessages.businessSupport) },
          { text: formatMessage(pricingPageMessages.businessTranslationWizard) },
          { text: formatMessage(pricingPageMessages.businessImprovedRendering) },
          {
            text: formatMessage(pricingPageMessages.business5TeamSeats),
            tooltip: formatMessage(pricingPageMessages.teamSeatsTooltip)
          },
          { text: formatMessage(pricingPageMessages.businessWorkspaceCollaboration) },
          { text: formatMessage(pricingPageMessages.businessWebAvatar) }
        ]
      },
      enterprise: {
        name: formatMessage(pricingPageMessages.enterpriseTitle),
        displayName: formatMessage(pricingPageMessages.enterpriseTitle),
        subtitle: formatMessage(pricingPageMessages.enterpriseSubtitle),
        price: 0,
        priceMonthly: () => formatMessage(pricingPageMessages.enterpriseMinutes),
        priceYearly: () => formatMessage(pricingPageMessages.enterpriseMinutes),
        finalPrice: () => formatMessage(pricingPageMessages.enterprisePrice),
        priceDescription: formatMessage(pricingPageMessages.enterprisePriceDescription),
        priceYearlyDescription: formatMessage(pricingPageMessages.enterprisePriceDescription),
        optionsTitle: formatMessage(pricingPageMessages.enterpriseOptionsTitle),
        headerFeatures: [
          { text: formatMessage(pricingPageMessages.enterpriseUnlimitedAmount) },
          { text: formatMessage(pricingPageMessages.enterpriseTotalCustomization) },
          { text: formatMessage(pricingPageMessages.enterprisePriorityQueue) },
          { text: formatMessage(pricingPageMessages.enterpriseUserAccessManagement) },
          { text: formatMessage(pricingPageMessages.enterpriseDedicatedCustomerSuccess) },
          { text: formatMessage(pricingPageMessages.enterpriseApiAccess) },
          { text: formatMessage(pricingPageMessages.enterpriseSSO) }
        ]
      }
    };
  }, [
    formatMessage,
    pricingAndPlans,
    currentPlanType,
    numberOfMinutesPosition,
    allBusinessMonthlyPlansMinutes
  ]);

  return plans;
};

export const useVideoCreationFeatures = ({
  paymentPeriod
}: {
  paymentPeriod: PaymentPeriod;
}): PlanFeature[] => {
  const { formatMessage } = useIntl();
  const currentMinutes = useAppSelector(getCurrentBusinessMinutes) as number;

  const features: PlanFeature[] = useMemo(() => {
    return [
      {
        feature: formatMessage(pricingPlansFeatures.minutesOfVideoPublished),
        plans: {
          "on-boarding": formatMessage(pricingPlansFeatures.freeMinutesPerMonth),
          basic:
            paymentPeriod === PaymentPeriod.Yearly
              ? formatMessage(pricingPlansFeatures.liteMinutesPerYear)
              : formatMessage(pricingPlansFeatures.liteMinutesPerMonth),
          advanced:
            paymentPeriod === PaymentPeriod.Yearly
              ? formatMessage(pricingPlansFeatures.businessMinutesPerYear, {
                  number: currentMinutes * 12
                })
              : formatMessage(pricingPlansFeatures.businessMinutesPerMonth, {
                  number: currentMinutes
                }),
          enterprise: "infinite"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.scenesPerVideo),
        plans: {
          "on-boarding": 6,
          basic: 10,
          advanced: 15,
          enterprise: formatMessage(pricingPlansFeatures.tailored)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.videoTemplates),
        plans: {
          "on-boarding": formatMessage(pricingPlansFeatures.allPreviewOnly),
          basic: formatMessage(pricingPlansFeatures.only2D),
          advanced: formatMessage(pricingPlansFeatures.only2d3d),
          enterprise: formatMessage(pricingPlansFeatures.only2d3d)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.characterLibrary),
        plans: {
          "on-boarding": formatMessage(pricingPlansFeatures.all),
          basic: formatMessage(pricingPlansFeatures.all),
          advanced: formatMessage(pricingPlansFeatures.all),
          enterprise: formatMessage(pricingPlansFeatures.all)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.languageLibrary),
        plans: {
          "on-boarding": formatMessage(pricingPlansFeatures.limited),
          basic: formatMessage(pricingPlansFeatures.all),
          advanced: formatMessage(pricingPlansFeatures.all),
          enterprise: formatMessage(pricingPlansFeatures.all)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.workflowCredits),
        plans: {
          "on-boarding": 3,
          basic: "",
          advanced: 10,
          enterprise: 500
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.transparentBackground),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.quickPreview),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.apiAccess),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      }
    ];
  }, [formatMessage, paymentPeriod, currentMinutes]);

  return features;
};

export const useAiToolsFeatures = ({
  paymentPeriod
}: {
  paymentPeriod: PaymentPeriod;
}): PlanFeature[] => {
  const { formatMessage } = useIntl();

  const features: PlanFeature[] = useMemo(() => {
    return [
      {
        feature: formatMessage(pricingPlansFeatures.aiCredits),
        plans: {
          "on-boarding": 10,
          basic: paymentPeriod === PaymentPeriod.Yearly ? 240 : 20,
          advanced: paymentPeriod === PaymentPeriod.Yearly ? 1200 : 100,
          enterprise: "infinite"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.aiVideoWizard),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        },
        info: formatMessage(pricingPlansFeatures.aiVideoWizardInfo)
      },
      {
        feature: formatMessage(pricingPlansFeatures.aiScriptWizard),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        },
        info: formatMessage(pricingPlansFeatures.aiScriptWizardInfo)
      },
      {
        feature: formatMessage(pricingPlansFeatures.meetingBotWizard),
        plans: {
          "on-boarding": "",
          basic: "",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.translationWizard),
        plans: {
          "on-boarding": "",
          basic: "",
          advanced: "v",
          enterprise: "v"
        }
      }
    ];
  }, [formatMessage]);

  return features;
};

export const useMediaFeatures = (): PlanFeature[] => {
  const { formatMessage } = useIntl();

  const features: PlanFeature[] = useMemo(() => {
    return [
      {
        feature: formatMessage(pricingPlansFeatures.uploadYourOwn),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.accessToImages),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      // {
      //   feature: formatMessage(pricingPlansFeatures.importPPT),
      //   plans: {
      //     "on-boarding": "v",
      //     basic: "v",
      //     advanced: "v",
      //     enterprise: "v"
      //   }
      // },
      {
        feature: formatMessage(pricingPlansFeatures.documentToVideo),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.promptToImage),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.voiceToVideo),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.screenRecording),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: "v",
          enterprise: "v"
        }
      }
    ];
  }, [formatMessage]);

  return features;
};

export const usePublishingFeatures = (): PlanFeature[] => {
  const { formatMessage } = useIntl();

  const features: PlanFeature[] = useMemo(() => {
    return [
      {
        feature: formatMessage(pricingPlansFeatures.videoQuality),
        plans: {
          "on-boarding": "720p",
          basic: "1080p",
          advanced: "1080p",
          enterprise: "4K / 8K"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.shareViaLink),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.download),
        plans: {
          "on-boarding": undefined,
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.embed),
        plans: {
          "on-boarding": undefined,
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.exportSubtitles),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.internalChannel),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      }
    ];
  }, [formatMessage]);

  return features;
};

export const useCollaborationFeatures = (): PlanFeature[] => {
  const { formatMessage } = useIntl();

  const features: PlanFeature[] = useMemo(() => {
    return [
      {
        feature: formatMessage(pricingPlansFeatures.studioEditorSeats),
        plans: {
          "on-boarding": 1,
          basic: 1,
          advanced: 1,
          enterprise: formatMessage(pricingPlansFeatures.custom)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.studioViewerSeats),
        plans: {
          "on-boarding": 1,
          basic: 3,
          advanced: 5,
          enterprise: formatMessage(pricingPlansFeatures.custom)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.teamsSeats),
        plans: {
          "on-boarding": 1,
          basic: 1,
          advanced: 1,
          enterprise: formatMessage(pricingPlansFeatures.custom)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.teams),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: formatMessage(pricingPlansFeatures.custom)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.projects),
        plans: {
          "on-boarding": 1,
          basic: 3,
          advanced: 5,
          enterprise: formatMessage(pricingPlansFeatures.unlimited)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.accessManagement),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      }
    ];
  }, [formatMessage]);

  return features;
};

export const useSupportFeatures = (): PlanFeature[] => {
  const { formatMessage } = useIntl();

  const features: PlanFeature[] = useMemo(() => {
    return [
      {
        feature: formatMessage(pricingPlansFeatures.customerSupport),
        plans: {
          "on-boarding": formatMessage(pricingPlansFeatures.helpCenter),
          basic: formatMessage(pricingPlansFeatures.helpCenter),
          advanced: formatMessage(pricingPlansFeatures.ticketingSupport),
          enterprise: formatMessage(pricingPlansFeatures.prioritySupport)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.knowledgeBase),
        plans: {
          "on-boarding": "v",
          basic: "v",
          advanced: "v",
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.techSupport247),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.successManager),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.mfa),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.samlSso),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: "v"
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.videoExpertAssistance),
        plans: {
          "on-boarding": undefined,
          basic: undefined,
          advanced: undefined,
          enterprise: formatMessage(pricingPlansFeatures.addon)
        }
      },
      {
        feature: formatMessage(pricingPlansFeatures.moderation),
        plans: {
          "on-boarding": formatMessage(pricingPlansFeatures.yes),
          basic: formatMessage(pricingPlansFeatures.yes),
          advanced: formatMessage(pricingPlansFeatures.yes),
          enterprise: formatMessage(pricingPlansFeatures.no)
        }
      }
    ];
  }, [formatMessage]);

  return features;
};

export const usePaymentModal = ({ plan }: { plan: PlanToUpgrade }) => {
  const { formatMessage } = useIntl();
  const flags = useFlags();
  const pricingAndPlans = useAppSelector((state) => state.payments.pricingAndPlans);
  const verifyCouponResults = useAppSelector((state) => state.payments.verifyCouponResults);
  const planBusinessMonthly = useAppSelector(getBusinessMonthlyPlan);
  const planBusinessYearly = useAppSelector(getBusinessYearlyPlan);
  const planPersonalMonthly = useAppSelector(getPersonalMonthlyPlan);
  const planPersonalYearly = useAppSelector(getPersonalYearlyPlan);
  const percentOff = verifyCouponResults?.percentOff;
  const appliedCouponPlanId = verifyCouponResults?.planId;

  // Todo - get it from the server in the pricing API
  const maxSeats = maxWorkspaceMembers[PlanEnum.business];

  const plans: Record<string, any> = useMemo(() => {
    return {
      personal: {
        title: formatMessage(paymentModalMessages.upgradeToRealsTitle),
        upgradeToRealsButton: formatMessage(paymentModalMessages.upgradeToRealsButton),
        phaseInitial: {
          subtitle: formatMessage(paymentModalMessages.upgradeToRealsSubTitle, { br: <br /> }),
          features: [
            formatMessage(paymentModalMessages.minutesVideo),
            formatMessage(paymentModalMessages.unlockAllVoices),
            formatMessage(paymentModalMessages.upToScenes)
          ]
        },
        phasePaymentType: {
          features: [
            formatMessage(paymentModalMessages.engaging),
            formatMessage(paymentModalMessages.createVideos)
          ],
          paymentYearly: formatMessage(paymentModalMessages.paymentYearly, {
            price: planPersonalMonthly?.price
          }),
          price: planPersonalMonthly?.price || 0,
          priceMonthly: planPersonalMonthly?.price_per_month || 0,
          priceBilledYearly: planPersonalYearly?.price,
          priceMonthlyBilledYearly: planPersonalYearly?.price_per_month,
          discount: planPersonalMonthly?.discount_title,
          discountYearly: planPersonalYearly?.discount_title
        },
        phaseCreditCardDetails: {
          subscriptionPlanMonthly: SubscriptionPlanEnum.personal_monthly,
          subscriptionPlanYearly: SubscriptionPlanEnum.personal_yearly,
          submitButton: formatMessage(paymentModalMessages.submitButton)
        },
        phaseSuccess: {
          videoUrl: "https://df6g5g0b3bt51.cloudfront.net/videos/personal_member.mp4",
          welcome: formatMessage(paymentModalMessages.welcomeToReals),
          welcomeSubtitle: formatMessage(paymentModalMessages.theProfessionalSuite, {
            br: <br />
          }),
          features: [
            {
              key: "video",
              icon: <VideosIcon />,
              header: formatMessage(paymentModalMessages.videos),
              description: formatMessage(paymentModalMessages.videosDescription)
            },
            {
              key: "preview",
              icon: <PreviewIcon />,
              header: formatMessage(paymentModalMessages.preview),
              description: formatMessage(paymentModalMessages.previewDescription)
            },
            {
              key: "voices",
              icon: <VoicesIcon />,
              header: formatMessage(paymentModalMessages.voices),
              description: formatMessage(paymentModalMessages.voicesDescription)
            },
            {
              key: "scenes",
              icon: <ScenesIcon />,
              header: formatMessage(paymentModalMessages.scenes),
              description: formatMessage(paymentModalMessages.scenesDescription)
            },
            {
              key: "presenters",
              icon: <PresentersIcon />,
              header: formatMessage(paymentModalMessages.presenters),
              description: formatMessage(paymentModalMessages.presentersDescription)
            },
            {
              key: "share",
              icon: <ShareIcon />,
              header: formatMessage(paymentModalMessages.share),
              description: formatMessage(paymentModalMessages.shareDescription)
            }
          ]
        }
      },
      business: {
        title: formatMessage(paymentModalMessages.upgradeToRealsTitlePersonal),
        upgradeToRealsButton: formatMessage(paymentModalMessages.upgradeToRealsButtonPersonal),
        phaseInitial: {
          subtitle: formatMessage(paymentModalMessages.upgradeToRealsSubTitlePersonal, {
            br: <br />
          }),
          features: [
            formatMessage(paymentModalMessages.minutesVideoPersonal),
            formatMessage(paymentModalMessages.createTeam2SeatsPersonal, { number: maxSeats }),
            formatMessage(paymentModalMessages.customPresenterPersonal),
            formatMessage(paymentModalMessages.accessTo3dTemplatesPersonal)
          ]
        },
        phasePaymentType: {
          features: [
            formatMessage(paymentModalMessages.engaging),
            formatMessage(paymentModalMessages.addYourLogos)
          ],
          paymentYearly: formatMessage(paymentModalMessages.paymentYearlyPersonal, {
            price: planBusinessMonthly?.price
          }),
          price: planBusinessMonthly?.price,
          priceMonthly: planBusinessMonthly?.price_per_month || 0,
          priceBilledYearly: planBusinessYearly?.price,
          priceMonthlyBilledYearly: planBusinessYearly?.price_per_month,
          discount: planBusinessMonthly?.discount_title,
          discountYearly: planBusinessYearly?.discount_title
        },
        phaseCreditCardDetails: {
          subscriptionPlanYearly: SubscriptionPlanEnum.business_yearly,
          subscriptionPlanMonthly: SubscriptionPlanEnum.business_monthly,
          submitButton: formatMessage(paymentModalMessages.submitButtonPersonal)
        },
        phaseSuccess: {
          videoUrl: "https://df6g5g0b3bt51.cloudfront.net/videos/business_member.mp4",
          welcome: formatMessage(paymentModalMessages.welcomeToRealsPersonal),
          welcomeSubtitle: formatMessage(paymentModalMessages.theProfessionalSuitePersonal, {
            br: <br />
          }),
          features: [
            {
              key: "video",
              icon: <VideosIcon />,
              header: formatMessage(paymentModalMessages.videos),
              description: formatMessage(paymentModalMessages.videosDescriptionPersonal)
            },
            {
              key: "team",
              icon: <PreviewIcon />,
              header: formatMessage(paymentModalMessages.teams),
              description: formatMessage(paymentModalMessages.teamsDescription)
            },
            {
              key: "scenes",
              icon: <ScenesIcon />,
              header: formatMessage(paymentModalMessages.scenes),
              description: formatMessage(paymentModalMessages.scenesDescriptionPersonal)
            },
            {
              key: "presenters",
              icon: <PresentersIcon />,
              header: formatMessage(paymentModalMessages.presenters),
              description: formatMessage(paymentModalMessages.presentersDescription)
            },
            {
              key: "logo",
              icon: <LogoIcon />,
              header: formatMessage(paymentModalMessages.logo),
              description: formatMessage(paymentModalMessages.logoDescription)
            },
            {
              key: "templates",
              icon: <TemplatesIcon />,
              header: formatMessage(paymentModalMessages.templates),
              description: formatMessage(paymentModalMessages.templatesDescription)
            }
          ]
        }
      }
    };
  }, [formatMessage]);

  const monthlyPrice: number = useMemo(() => {
    const initialMonthlyPrice = plans[plan].phasePaymentType.priceMonthly;
    let currentPlanMonthlyPlanId;
    if (plan === PlanToUpgrade.personal) {
      currentPlanMonthlyPlanId = planPersonalMonthly?.id;
    }
    if (plan === PlanToUpgrade.business) {
      currentPlanMonthlyPlanId = planBusinessMonthly?.id;
    }
    const calculatedPrice =
      currentPlanMonthlyPlanId === appliedCouponPlanId || flags[FeatureFlag.responsiveBannerCode]
        ? discountPrice(initialMonthlyPrice, percentOff ?? 0)
        : initialMonthlyPrice;

    return calculatedPrice;
  }, [percentOff, appliedCouponPlanId, pricingAndPlans, planPersonalMonthly, planBusinessMonthly]);

  const yearlyPricePerMonth: number = useMemo(() => {
    const initialYearlyPrice = plans[plan].phasePaymentType.priceMonthlyBilledYearly;
    let currentPlanYearlyPlanId;
    if (plan === PlanToUpgrade.personal) {
      currentPlanYearlyPlanId = planPersonalYearly?.id;
    }
    if (plan === PlanToUpgrade.business) {
      currentPlanYearlyPlanId = planBusinessYearly?.id;
    }

    const price =
      currentPlanYearlyPlanId === appliedCouponPlanId || flags[FeatureFlag.responsiveBannerCode]
        ? discountPrice(initialYearlyPrice, percentOff ?? 0)
        : initialYearlyPrice;

    return price;
  }, [percentOff, appliedCouponPlanId, pricingAndPlans]);

  const totalYearlyPrice: number = useMemo(() => {
    const initialYearlyPrice = plans[plan].phasePaymentType.priceBilledYearly;
    let currentPlanYearlyPlanId;
    if (plan === PlanToUpgrade.personal) {
      currentPlanYearlyPlanId = planPersonalYearly?.id;
    }
    if (plan === PlanToUpgrade.business) {
      currentPlanYearlyPlanId = planBusinessYearly?.id;
    }
    const price =
      currentPlanYearlyPlanId === appliedCouponPlanId || flags[FeatureFlag.responsiveBannerCode]
        ? discountPrice(initialYearlyPrice, percentOff ?? 0)
        : initialYearlyPrice;

    return price;
  }, [percentOff, appliedCouponPlanId, pricingAndPlans]);

  return { plans, monthlyPrice, totalYearlyPrice, yearlyPricePerMonth };
};
