import { useAppSelector } from "app/hooks";
import { RootState } from "app/store/store";
import { imageFitDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";

import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import { FitOptions } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const BUTTON_WIDTH = "100px";

const ImageFitIcon = styled.i`
  font-size: 37px;
`;

const BorderedFlexRow = styled(H1_FlexRow)<{ isHighlighted: boolean }>`
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.gray5};
  border-color: ${({ isHighlighted, theme }) => (isHighlighted ? theme.blue4 : theme.gray5)};
  transition: transform 0.3s ease-in-out;
  i {
    color: ${({ isHighlighted, theme }) => (isHighlighted ? theme.blue4 : theme.blue1)};
  }
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const PaddingText = styled(H1_TextXs)`
  padding-left: 3px;
`;
interface ImageFitTypeProps {
  onSelectFitType: (type: FitOptions) => void;
}
const ImageFitType = ({ onSelectFitType }: ImageFitTypeProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const imageFit = useAppSelector((state: RootState) => state.scenes.imageFit);
  const { fitType } = imageFit;
  const isCrop = fitType === FitOptions.crop;
  const isScale = fitType === FitOptions.scale;
  const isSmartCrop = fitType === FitOptions.smart_crop;

  return (
    <H1_FlexColumn gap="8px" padding="206px 0 20px 0">
      <PaddingText color={theme.gray7}>
        {intl.formatMessage(imageFitDrawerMessages.imageFit)}
      </PaddingText>
      <H1_FlexRow gap="12px" justify="center">
        <H1_FlexColumn gap="5px" width={BUTTON_WIDTH} align="center">
          <BorderedFlexRow
            justify="center"
            align="center"
            width={BUTTON_WIDTH}
            flex={`0 0 ${BUTTON_WIDTH}`}
            onClick={() => onSelectFitType(FitOptions.smart_crop)}
            isHighlighted={isSmartCrop}
          >
            <ImageFitIcon className="fa-regular fa-frame" />
          </BorderedFlexRow>
          <H1_TextXs textAlign="center" color={isSmartCrop ? theme.blue4 : theme.gray7}>
            {intl.formatMessage(imageFitDrawerMessages.smartCropIcon)}
          </H1_TextXs>
        </H1_FlexColumn>
        <H1_FlexColumn gap="5px" width={BUTTON_WIDTH} align="center">
          <BorderedFlexRow
            justify="center"
            align="center"
            width={BUTTON_WIDTH}
            flex={`0 0 ${BUTTON_WIDTH}`}
            onClick={() => onSelectFitType(FitOptions.crop)}
            isHighlighted={isCrop}
          >
            <ImageFitIcon className="fa-regular fa-crop" />
          </BorderedFlexRow>
          <H1_TextXs textAlign="center" color={isCrop ? theme.blue4 : theme.gray7}>
            {intl.formatMessage(imageFitDrawerMessages.cropIcon)}
          </H1_TextXs>
        </H1_FlexColumn>
        <H1_FlexColumn gap="5px" width={BUTTON_WIDTH} align="center">
          <BorderedFlexRow
            width={BUTTON_WIDTH}
            flex={`0 0 ${BUTTON_WIDTH}`}
            justify="center"
            align="center"
            onClick={() => onSelectFitType(FitOptions.scale)}
            isHighlighted={isScale}
          >
            <ImageFitIcon className="fa-regular fa-arrows-maximize" />
          </BorderedFlexRow>
          <H1_TextXs textAlign="center" color={isScale ? theme.blue4 : theme.gray7}>
            {intl.formatMessage(imageFitDrawerMessages.scaleIcon)}
          </H1_TextXs>
        </H1_FlexColumn>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default ImageFitType;
