import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { mediaLibraryFoldersModalMessages } from "app/pages/mediaLibrary/messages";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getMediaListFolders } from "app/store/selectorsV2/media.selectors";
import { Button, Listbox, ListboxItem, ListboxSection, Selection } from "@nextui-org/react";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { Folder } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { fetchingStatus } from "app/utils/helpers";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import {
  H1_TextMidHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const StyledListbox = styled(Listbox)`
  max-height: 400px;
  overflow: auto;
  margin-top: 20px;
`;

const Modal = styled(StyledModal)`
  .ant-modal-body {
    background-color: ${({ theme }) => theme.gray2};
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
    border-radius: 12px;
  }
`;

interface MediaLibraryMoveToFolderModalProps {
  visible: boolean;
  selectedFiles: Set<string>;
  rootFolderId?: string;
  onCloseModal: (isMoved?: boolean) => void;
}

const MediaLibraryMoveToFolderModal = ({
  visible,
  selectedFiles,
  rootFolderId,
  onCloseModal
}: MediaLibraryMoveToFolderModalProps) => {
  const [selectedFolderId, setSelectedFolderId] = useState<Selection>(new Set());
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const folders: Folder[] = useAppSelector((state) => getMediaListFolders(state, selectedFiles));
  const moveMediaStatus = useAppSelector((state) => state.media.moveMediaStatus);
  const foldersStack = useAppSelector((state) => state.media.foldersStack);
  const isLoading = moveMediaStatus === fetchingStatus.loading;
  const lastFolder = foldersStack?.at(-1);

  const currentSelectedFolder = useMemo(() => {
    const allFolders = Array.from(selectedFolderId);
    return allFolders.length > 0 ? (allFolders[0] as string) : "";
  }, [selectedFolderId]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (moveMediaStatus === fetchingStatus.succeeded) {
      dispatch(mediaActions.updateFolderTargetIdLoadingStatusToIdle());
      onCloseModal(true);
    }

    if (moveMediaStatus === fetchingStatus.failed) {
      dispatch(mediaActions.updateFolderTargetIdLoadingStatusToIdle());
    }
  }, [moveMediaStatus]);

  const onClickMove = () => {
    dispatch(
      mediaActions.moveToFolderMultipleRequest({
        selectedFolderId: currentSelectedFolder,
        selectedFiles: Array.from(selectedFiles)
      })
    );
  };

  return (
    <Modal
      width="300px"
      open={visible}
      footer={null}
      centered
      onCancel={() => onCloseModal()}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexColumn flex="1 1 auto">
        <H1_FlexRow
          flex="0 0 auto"
          width="100%"
          position="relative"
          justify="space-between"
          padding="0 30px"
        >
          <H1_FlexRow flex="0 0 auto">
            <H1_TextMidHeadline color={theme.gray9}>
              {formatMessage(mediaLibraryFoldersModalMessages.headerTitle)}
            </H1_TextMidHeadline>
          </H1_FlexRow>
        </H1_FlexRow>
        <StyledListbox
          classNames={{
            base: "max-w-xs",
            list: "max-h-[300px] overflow-scroll"
          }}
          selectionMode="single"
          onSelectionChange={setSelectedFolderId}
          variant="flat"
        >
          <ListboxSection title="" showDivider>
            <ListboxItem
              key={rootFolderId || "root"}
              textValue={formatMessage(mediaLibraryFoldersModalMessages.root)}
              isDisabled={!lastFolder}
            >
              <H1_FlexRow gap="8px" align="center">
                <H1_Icon icon="fas fa-folder" color={theme.gray11} size="24px" />
                <H1_FlexColumn>
                  <H1_TextSmall color={theme.gray11}>
                    {formatMessage(mediaLibraryFoldersModalMessages.root)}
                  </H1_TextSmall>
                </H1_FlexColumn>
              </H1_FlexRow>
            </ListboxItem>
          </ListboxSection>
          <ListboxSection title="" showDivider={foldersStack?.length > 1}>
            {foldersStack?.slice(0, foldersStack.length - 1).map((item: any) => (
              <ListboxItem
                key={item.id}
                textValue={item.name}
                isDisabled={lastFolder?.id === item.id}
              >
                <H1_FlexRow gap="8px" align="center">
                  <H1_Icon icon="fas fa-folder" color={theme.gray11} size="24px" />
                  <H1_FlexColumn>
                    <H1_TextSmall color={theme.gray11}>{item.name}</H1_TextSmall>
                    <ConditionalRender condition={item.previousContent?.length === 1}>
                      <H1_TextXs color={theme.gray7}>
                        {formatMessage(mediaLibraryFoldersModalMessages.numOfItems, {
                          number: item.previousContent?.length
                        })}
                      </H1_TextXs>
                    </ConditionalRender>
                    <ConditionalRender condition={item.previousContent?.length !== 1}>
                      <H1_TextXs color={theme.gray7}>
                        {formatMessage(mediaLibraryFoldersModalMessages.numOfItemsPlurals, {
                          number: item.previousContent?.length
                        })}
                      </H1_TextXs>
                    </ConditionalRender>
                  </H1_FlexColumn>
                </H1_FlexRow>
              </ListboxItem>
            ))}
          </ListboxSection>
          <ListboxSection title="">
            {folders.map((item: any) => (
              <ListboxItem
                key={item.id}
                textValue={item.name}
                isDisabled={lastFolder?.id === item.id}
              >
                <H1_FlexRow gap="8px" align="center">
                  <H1_Icon icon="fas fa-folder" color={theme.gray11} size="24px" />
                  <H1_FlexColumn>
                    <H1_TextSmall color={theme.gray11}>{item.name}</H1_TextSmall>
                    <ConditionalRender condition={item.total_items === 1}>
                      <H1_TextXs color={theme.gray7}>
                        {formatMessage(mediaLibraryFoldersModalMessages.numOfItems, {
                          number: item.total_items
                        })}
                      </H1_TextXs>
                    </ConditionalRender>
                    <ConditionalRender condition={!!item.total_items && item.total_items !== 1}>
                      <H1_TextXs color={theme.gray7}>
                        {formatMessage(mediaLibraryFoldersModalMessages.numOfItemsPlurals, {
                          number: item.total_items
                        })}
                      </H1_TextXs>
                    </ConditionalRender>
                  </H1_FlexColumn>
                </H1_FlexRow>
              </ListboxItem>
            ))}
          </ListboxSection>
        </StyledListbox>
      </H1_FlexColumn>
      <H1_FlexRow align="flex-end" flex="0 0 50px" justify="flex-end" width="100%" gap="10px">
        <Button size="sm" variant="light" onClick={() => onCloseModal()}>
          {formatMessage(mediaLibraryFoldersModalMessages.cancelBtn)}
        </Button>
        <Button
          color="primary"
          size="sm"
          onClick={onClickMove}
          isLoading={isLoading}
          isDisabled={!currentSelectedFolder}
        >
          {formatMessage(mediaLibraryFoldersModalMessages.moveBtn)}
        </Button>
      </H1_FlexRow>
    </Modal>
  );
};

export default MediaLibraryMoveToFolderModal;
