/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/fonts.thunk";
import { fetchingStatus } from "app/utils/helpers";
import { FetchStatus, FontsSet } from "app/types";

const INITIAL_STATE: {
  fonts: FontsSet[];
  getFontsStatus: FetchStatus;
} = {
  fonts: [],
  getFontsStatus: fetchingStatus.idle
};

export const fontsSlice = createSlice({
  name: "Fonts",
  initialState: INITIAL_STATE,
  reducers: {
    updateGetStatusToIdle: (state) => {
      state.getFontsStatus = fetchingStatus.idle;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getFontsRequest.fulfilled, (state, action) => {
      state.getFontsStatus = fetchingStatus.succeeded;
      state.fonts = action.payload;
    });
    builder.addCase(asyncThunks.getFontsRequest.pending, (state) => {
      state.getFontsStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getFontsRequest.rejected, (state) => {
      state.getFontsStatus = fetchingStatus.failed;
    });
  }
});

export default fontsSlice.reducer;

export const fontsActions = {
  getFontsRequest: asyncThunks.getFontsRequest,
  ...fontsSlice.actions
};
