import { useAppDispatch } from "app/hooks";

import uiActions from "app/store/actions/ui.actions";
import { Notification } from "app/types";

const useNotifications = () => {
  const dispatch = useAppDispatch();

  const notifyMessages = (notifications: Notification[]) => {
    dispatch(uiActions.setNotifications(notifications));
  };

  return { notifyMessages };
};

export default useNotifications;
