import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import { DataStoreContent, Video, VideoStatusEnum, VideoType } from "app/types";

const videos = (state: RootState) => videosGlobalSelectors.selectAll(state);
const importDataResult = (state: RootState) => state.dataStore.importDataResult;
export const videosEntities = (state: RootState) => state.videos.entities;

export const readyToPlayStatuses = ["ready", "published"];
const processingStatuses = ["processing"];

export const getLastVersionVideo = createSelector([videos], (allVideos) => {
  // todo temp - until impl api
  return allVideos[0];
});

export const getReadyToPlayVideos = createSelector([videos], (allVideos) => {
  return allVideos.filter((video) => readyToPlayStatuses.includes(video.status as string));
});

export const getProcessingVideos = createSelector([videos], (allVideos) => {
  return allVideos.filter((video) => processingStatuses.includes(video.status as string));
});

export const getVideosByDraftId = createSelector(
  [videos, (state, draftId: string) => draftId],
  (allVideos, draftId) => {
    if (!draftId) {
      return undefined;
    }
    return allVideos.find((video) => video.draft_id === (draftId as string));
  }
);

export const getVideoByReqId = createSelector(
  [videos, (state, reqId?: string) => reqId],
  (allVideos, reqId) => {
    if (!reqId) {
      return undefined;
    }
    return allVideos.find((video) => video.req_id === (reqId as string));
  }
);

export const getVideoReactionsCount = createSelector(
  [(state, videoId, reaction) => [state, videoId, reaction]],
  ([state, videoId, reaction]): number => {
    const video = videosGlobalSelectors.selectById(state, videoId as string);
    const filteredReaction = video?.reactions?.find((single) => single.reaction === reaction);
    if (filteredReaction) {
      return filteredReaction.count;
    }
    return 0;
  }
);

export const getAllReactionsCount = createSelector(
  [(state, videoId) => [state, videoId]],
  ([state, videoId]): number => {
    const video = videosGlobalSelectors.selectById(state, videoId as string);
    const reactions = video?.reactions;
    if (reactions) {
      return Object.values(reactions).reduce((a, b) => a + b.count, 0) as number;
    }

    return 0;
  }
);

export const getVideoByDataStoreContentId = createSelector(
  [videos, (state, dataStoreContentId: string) => dataStoreContentId],
  (videos, dataStoreContentId): Video | undefined => {
    return videos
      .filter(
        (video) => !!video.datastore_content_id && video.datastore_content_id === dataStoreContentId
      )
      .reduce((latest: Video | undefined, current) => {
        if (!latest) {
          return current;
        }
        const latestDate = new Date(latest.created_at || 0);
        const currentDate = new Date(current.created_at || 0);
        return currentDate > latestDate ? current : latest;
      }, undefined);
  }
);

export const getIsVideosByDataStoreContentIds = createSelector(
  [videos, importDataResult],
  (videos, importDataResult): boolean => {
    const isVideoFound = (importDataResult?.content || [])
      .map(({ id }: DataStoreContent) => id)
      .some((dataStoreContentId: string) => {
        const currentVideo = videos.find(
          (video) =>
            !!video.datastore_content_id &&
            video.datastore_content_id === dataStoreContentId &&
            (video.status === VideoStatusEnum.Ready || video.status === VideoStatusEnum.Published)
        );
        return !!currentVideo;
      });

    return isVideoFound;
  }
);

export const getVideosByType = createSelector(
  [videos, (state, type: VideoType) => type],
  (videos, type) => {
    return videos.filter((video) => video.type === type);
  }
);

export default {
  getLastVersionVideo,
  getReadyToPlayVideos,
  getProcessingVideos,
  getVideoByReqId,
  getAllReactionsCount,
  getVideoReactionsCount
};
