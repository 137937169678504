import { DEFAULT_GALLERY_PAGE_SIZE, Draft, DraftType } from "app/types";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import DraftGridItem from "app/components/common/DraftsAndFolders/DraftGridItem";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Pagination } from "antd";
import { useAppSelector } from "app/hooks";
import { getDraftsByPageAndQuery } from "app/store/selectorsV2/drafts.selectors";
import { RootState } from "app/store/store";
import { calculateTotalNumberOfGridElements, convertRemToPixels } from "app/utils/helpers";
import { HeaderContext } from "app/hoc/HeaderContext";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import messages from "app/components/projects-managment/DraftsView/messages";
import EmptyState from "app/components/common/EmptyState";

const StyledFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    justify-content: center;
  }
`;

const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

const DraftsGrid = ({
  source,
  limit,
  type,
  hideMoveFolder,
  hideEmptyState,
  onCreateNewVideo
}: {
  source: string;
  limit?: number;
  type?: DraftType;
  hideMoveFolder?: boolean;
  hideEmptyState?: boolean;
  onCreateNewVideo: () => void;
}) => {
  const [totalNumberOfDraftsPerPage, setTotalNumberOfDraftsPerPage] =
    useState(DEFAULT_GALLERY_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const ref = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const { searchValue } = useContext(HeaderContext);

  const { slicedDrafts: currentDisplayedDrafts, totalDrafts } = useAppSelector(
    (state: RootState) => {
      const res = getDraftsByPageAndQuery(
        state,
        currentPage,
        searchValue,
        totalNumberOfDraftsPerPage,
        type
      );
      return res;
    }
  );
  const withPagination = totalDrafts > totalNumberOfDraftsPerPage;

  useLayoutEffect(() => {
    if (limit) {
      setTotalNumberOfDraftsPerPage(limit);
      return;
    }
    if (ref.current) {
      const paginationHeight = convertRemToPixels(2);
      const paginationGapHeight = convertRemToPixels(1.5);
      const width = ref.current.clientWidth;
      const height = ref.current.clientHeight - (paginationHeight + paginationGapHeight);
      const widthGap = 2;
      const heightGap = 2.125;
      setTotalNumberOfDraftsPerPage(
        calculateTotalNumberOfGridElements(width, height, widthGap, heightGap)
      );
    }
  }, [limit]);

  const onChangePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <H1_FlexColumn flex="1 1 auto" width="100%" gap="1.5rem" ref={ref} justify="space-between">
      <ConditionalRender condition={totalDrafts > 0}>
        <StyledFlexRow wrap="wrap" width="100%" align="center" gap="2rem 2.125rem">
          {currentDisplayedDrafts.map((draft: Draft) => (
            <DraftGridItem
              hideMoveFolder={hideMoveFolder}
              source={source}
              key={draft.id}
              draftId={draft.id as string}
            />
          ))}
        </StyledFlexRow>
        <ConditionalRender condition={withPagination}>
          <H1_FlexRow justify="center" width="100%" align="center">
            <StyledPagination
              defaultCurrent={1}
              pageSize={totalNumberOfDraftsPerPage}
              current={currentPage}
              total={totalDrafts}
              onChange={onChangePagination}
              showSizeChanger={false}
            />
          </H1_FlexRow>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender condition={totalDrafts === 0 && !hideEmptyState}>
        <EmptyState
          text={formatMessage(messages.emptyFolderText)}
          description={formatMessage(messages.emptyFolderDescription)}
          withButton
          buttonText={formatMessage(messages.emptyFolderButtonText)}
          icon="fal fa-folder"
          onClickButton={onCreateNewVideo}
        />
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default DraftsGrid;
