import { defineMessages } from "react-intl";

export const podcastMessages = defineMessages({
  title: {
    id: `podcast.title`,
    defaultMessage: "Podcast maker"
  },
  promptToVideoTile: {
    id: `podcast.prompt-title`,
    defaultMessage: "Prompt To Video"
  },
  subtitle: {
    id: `podcast.subtitle`,
    defaultMessage: "Create podcast video from podcast audio"
  },
  uploadAudio: {
    id: `podcast.upload-audio`,
    defaultMessage: "Upload audio"
  },
  promptToVideo: {
    id: `podcast.prompt-to-video`,
    defaultMessage: "Prompt to video"
  },
  usePodcastScript: {
    id: `podcast.use-podcast-script`,
    defaultMessage: "Use podcast script"
  },
  generateBtn: {
    id: `podcast.generate-button`,
    defaultMessage: "Generate podcast"
  },
  cancelBtn: {
    id: `podcast.cancel-button`,
    defaultMessage: "Cancel"
  },
  backBtn: {
    id: `podcast.back-button`,
    defaultMessage: "Back"
  },
  podcastMessages: {
    id: `podcast.prompt.placeholder`,
    defaultMessage: "Describe what is your podcast about..."
  }
});
