import React, { useEffect, useState } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { voicesActions } from "app/store/slices/voices.slice";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneVoiceStatus } from "app/store/thunks/analyticsEvents.thunk";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import TranscriptAudioModalContent, {
  VIEW_MODE
} from "app/components/editor/scene/transcriptAudioUploader/TranscriptAudioModalContent";
import { StyledModal } from "app/components/common/StyledModal";

const TranscriptAudioUploadModal = () => {
  const [viewMode, setViewMode] = useState<VIEW_MODE>(VIEW_MODE.main);
  const dispatch = useAppDispatch();
  const { closeModal, editorModalOpen } = useModal();
  const open = editorModalOpen?.open === ModalName.transcriptAudioUploadModal;
  const draftId = editorModalOpen?.context?.draftId;
  const sceneId = editorModalOpen?.context?.sceneId;
  const sceneIndex = editorModalOpen?.context?.idx;
  const scene = useAppSelector((state) => scenesGlobalSelectors.selectById(state, sceneId));
  const sceneName = scene?.name as string;
  const { file, reset, onSelectFile, importLoading, executeImport, fileStoreResult } =
    useFileUploadFilestack();
  const MAX_TIME_IN_SEC = 600; // flags[FeatureFlag.transcriptAudioUploadNoLimit] ? 600 : 60;

  useEffect(() => {
    if (!open) {
      reset();
      setViewMode(VIEW_MODE.main);
    }
  }, [open]);

  useEffect(() => {
    if (fileStoreResult) {
      dispatch(voicesActions.cleanFooterAudio());
      dispatch(
        scenesActions.patchSceneRequest({
          draftId,
          sceneId,
          operations: [
            { op: "replace", path: `attributes.media.transcript.url`, value: fileStoreResult.url }
          ],
          skipRePreview: true
        })
      );
      closeModal();
    }
  }, [fileStoreResult]);

  const onModalCancel = () => {
    closeModal();
  };

  const onStopRecordEvent = () => {
    dispatch(
      analyticsEvents.useVoice({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: sceneIndex
        },
        status: SceneVoiceStatus.stopRecord
      })
    );
  };
  const onStartRecordEvent = () => {
    dispatch(
      analyticsEvents.useVoice({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: sceneIndex
        },
        status: SceneVoiceStatus.startRecord
      })
    );
  };

  const onChooseFile = (selectedFile: File) => {
    onSelectFile(selectedFile);
    setViewMode(VIEW_MODE.finish);
  };

  const cleanFile = () => {
    reset();
  };
  return (
    <StyledModal
      width="530px"
      open={open}
      footer={null}
      centered
      bodyStyle={{ height: "600px" }}
      onCancel={onModalCancel}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      closable={!importLoading}
      $padding="50px"
      $alignItems="flex-start"
      $justifyContent="space-between"
    >
      <TranscriptAudioModalContent
        file={file}
        maxTimeInSec={MAX_TIME_IN_SEC}
        viewMode={viewMode}
        importLoading={importLoading}
        onChooseFile={onChooseFile}
        cleanFile={cleanFile}
        onSelectFile={onSelectFile}
        executeImport={executeImport}
        onRecordSelect={() => setViewMode(VIEW_MODE.voiceRecording)}
        onCancel={onModalCancel}
        onStartRecordEvent={onStartRecordEvent}
        onStopRecordEvent={onStopRecordEvent}
      />
    </StyledModal>
  );
};

export default TranscriptAudioUploadModal;
