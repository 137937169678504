import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import pusher from "app/services/pusher";
import { Channel } from "pusher-js";
import { useAuth } from "app/auth/useAuth";
import { draftsActions } from "app/store/slices/drafts.slice";
import { extractUserIdForPusher, fetchingStatus } from "app/utils/helpers";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import * as Sentry from "@sentry/react";
import { defineMessages, useIntl } from "react-intl";
import { DraftTranslationMsg, PusherMessagesStatusEnum } from "app/types/pusherMessages";
import useNotifications from "app/hooks/useNotifications";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { Location } from "app/types";
import styled from "styled-components";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useTimeoutOnLoading from "app/hooks/useTimeoutOnLoading";
import { Button } from "@nextui-org/react";

const draftTranslationEventName = "augment_draft.translate";
const messagesKey = "draftTranslation";

const messages = defineMessages({
  translationErrorContent: {
    id: "global-pusher.augment_scene.translate.error.content",
    defaultMessage: "We're sorry, there was an issue while attempting to translate your draft"
  },
  translationErrorTitle: {
    id: "global-pusher.augment_scene.translate.error.title",
    defaultMessage: "Oops! Translation error"
  },
  translationProcess: {
    id: "global-pusher.augment_scene.translate.loading",
    defaultMessage:
      "Draft is being translated, You'll be redirected to the translated draft when finished"
  },
  translationSucceed: {
    id: "global-pusher.augment_scene.translate.succeeded",
    defaultMessage: "Translation complete <a>Back to original draft</a>"
  }
});

const StyledInlineButton = styled(Button)<{ $marginLeft?: string; $underline?: boolean }>`
  text-decoration: ${(props) => (props.$underline ? "underline" : "none")};
  display: inline-block;
  margin-left: ${(props) => props.$marginLeft || "initial"};
  font-weight: 300;
  min-width: 10px;
`;

export const PusherSceneAugmentationHandler = () => {
  const dispatch = useAppDispatch();
  const { notifyError } = useErrors();
  const { formatMessage } = useIntl();
  const { user } = useAuth();
  const { notifyMessages } = useNotifications();
  const navigate = useNavigate();

  const draftTranslationOrderId = useAppSelector((state) => state.drafts.draftTranslationOrderId);
  const draftTranslationStatus = useAppSelector((state) => state.drafts.draftTranslationStatus);
  const draftTranslationStatusCode = useAppSelector(
    (state) => state.drafts.draftTranslationStatusCode
  );
  const originDraftTranslationOrderId = useAppSelector(
    (state) => state.drafts.originDraftTranslationOrderId
  );
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const draftStatus = useAppSelector((state) => state.drafts.draftsStatus);

  const handleTrnalationStatuses = () => {
    if (draftTranslationStatus === fetchingStatus.loading) {
      notifyMessages([
        {
          message: formatMessage(messages.translationProcess),
          key: messagesKey,
          type: "loading"
        }
      ]);
    } else if (draftTranslationStatus === fetchingStatus.failed) {
      message.destroy(messagesKey);
      if (draftTranslationStatusCode != "402") {
        notifyError({
          general: buildGeneralMessage(
            formatMessage(messages.translationErrorTitle),
            formatMessage(messages.translationErrorContent)
          )
        });
      }
      dispatch(draftsActions.cleanDraftTranslationId());
      dispatch(draftsActions.updateDraftTranslationStatusTo(fetchingStatus.idle));
    }
  };

  const handleTrnalationStatusesOnTimeout = () => {
    dispatch(draftsActions.updateDraftTranslationStatusTo(fetchingStatus.failed));
  };

  useTimeoutOnLoading({
    loading: draftTranslationStatus === fetchingStatus.loading,
    timeoutSec: 300,
    onTimeout: handleTrnalationStatusesOnTimeout
  });

  useEffect(() => {
    // Open back to origin draft message when done with loading proccess, and moved to the new translated draft
    if (
      !!draftTranslationOrderId &&
      draftTranslationStatus === fetchingStatus.succeeded &&
      currentDraft?.id !== draftTranslationOrderId &&
      draftStatus === fetchingStatus.idle
    ) {
      const message: ReactNode = (
        <>
          {formatMessage(messages.translationSucceed, {
            a: (chunks: ReactNode) => (
              <StyledInlineButton
                variant="light"
                size="sm"
                $underline
                onClick={() => {
                  dispatch(analyticsEvents.navigateToEditor({ source: "draft_translation" }));
                  navigate(`${Location.Editor}/${originDraftTranslationOrderId}`);
                  clearTranslatedDraftId();
                  dispatch(draftsActions.updateDraftTranslationStatusTo(fetchingStatus.idle));
                }}
              >
                {chunks}
              </StyledInlineButton>
            )
          })}
          <StyledInlineButton
            size="sm"
            variant="light"
            $marginLeft="20px"
            onClick={clearTranslatedDraftId}
          >
            X
          </StyledInlineButton>
        </>
      );

      notifyMessages([
        {
          message,
          duration: 30, // Half a minute
          type: null,
          key: messagesKey,
          onClose: clearTranslatedDraftId,
          className: "back-to-translation-origin"
        }
      ]);

      return () => {
        // @tidharpeer1 wondering about that
        notifyMessages([]);
      };
    }
  }, [draftTranslationStatus, currentDraft, draftStatus]);

  useEffect(() => {
    handleTrnalationStatuses();
  }, [draftTranslationStatus]);

  const clearTranslatedDraftId = () => {
    message.destroy(messagesKey);
    dispatch(draftsActions.cleanDraftTranslationId());
  };

  const onDraftTranslationMessage = (translateionMsg: DraftTranslationMsg) => {
    const { status, draft_id: draftId, order_id: orderId } = translateionMsg;
    if (orderId !== draftTranslationOrderId) return;
    if (draftTranslationStatus === fetchingStatus.loading) {
      if (status === PusherMessagesStatusEnum.ready) {
        dispatch(
          draftsActions.handleDraftTranslationResults({
            status: fetchingStatus.succeeded,
            draftId,
            orderId
          })
        );
        const editorDraftLink = `${Location.Editor}/${draftId}`;
        dispatch(
          analyticsEvents.track({
            properties: {
              draftId: draftId,
              result: "success"
            },
            eventId: "translation",
            featureId: "translate_feature"
          })
        );
        dispatch(analyticsEvents.navigateToEditor({ source: "pusher_translation" }));
        navigate(editorDraftLink);
      } else {
        dispatch(
          analyticsEvents.track({
            properties: {
              draftId: draftId,
              result: "failed"
            },
            eventId: "translation",
            featureId: "translate_feature"
          })
        );
        dispatch(
          draftsActions.handleDraftTranslationResults({
            status: fetchingStatus.failed,
            draftId,
            orderId
          })
        );
        Sentry.captureMessage("Client got failed draft translation pusher message", {
          extra: {
            draftId,
            status
          }
        });
      }
    } else {
      Sentry.captureMessage("Recived a translation message after timeout", {
        extra: {
          draftId,
          status
        }
      });
    }
  };

  useEffect(() => {
    let subs: Channel;
    if (user?.sub && !!draftTranslationOrderId) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      subs = pusher.subscribe(channelName);
      subs.bind(draftTranslationEventName, onDraftTranslationMessage);
    }
    return () => {
      if (subs) {
        subs.unbind(draftTranslationEventName, onDraftTranslationMessage);
        subs.unsubscribe();
      }
    };
  }, [draftTranslationOrderId]);

  return null;
};

export default PusherSceneAugmentationHandler;
