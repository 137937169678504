import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { DraftVariable, VariableType } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button, Divider, Input, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import { KeyboardCode } from "@dnd-kit/core";

const MaxFlexColumn = styled(H1_FlexColumn)`
  max-height: 300px;
`;
const HoverFlexRow = styled(H1_FlexRow)`
  width: 250px;
  padding: 5px 10px;
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
    border-radius: 12px;
  }
`;
const HoverButton = styled(Button)<{ disabled?: boolean }>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
  }
  &:disabled {
    opacity: 0.3;
  }
`;

const VariableItem = ({ variable }: { variable: DraftVariable }) => {
  // todo should support edit mode , which allow to done , should has a state
  const [editMode, setEditMode] = React.useState(false);
  const dispatch = useAppDispatch();
  const draftId = useAppSelector((state) => state.drafts.currentDraft.id);
  const [variableName, setVariableName] = React.useState(variable.name);

  useEffect(() => {
    setVariableName(variable.name);
  }, []);

  const onSave = () => {
    if (!variableName) {
      // todo also call to validation for checking that it not already exists
      // ux how to present it?
      return;
    }
    setEditMode(false);
    dispatch(
      draftsActions.updateVariableRequest({
        draftId: draftId as string,
        variableId: variable.id as string,
        variable: { name: variableName }
      })
    );
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === KeyboardCode.Enter) {
      onSave();
    }
  };
  const onClick = (event: any) => {
    event.stopPropagation();
  };

  const onVariableEdit = (evt: any) => {
    evt.stopPropagation();
    setEditMode(true);
  };
  if (editMode) {
    return (
      <H1_FlexRow
        width="100%"
        justify="space-between"
        onClick={onClick}
        align={"center"}
        gap={"5px"}
      >
        <Input
          size="sm"
          labelPlacement="outside"
          defaultValue={variable.name}
          onChange={(evt: any) => setVariableName(evt.target.value)}
          onKeyDown={onKeyDown}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <Button color="primary" variant="light" size="sm" onClick={onSave}>
          Done
        </Button>
      </H1_FlexRow>
    );
  }

  return (
    <H1_FlexRow width="100%" align={"center"} justify={"space-between"}>
      <H1_TextSmall>{variable.name}</H1_TextSmall>
      <Button
        isIconOnly
        size="sm"
        variant="light"
        startContent={<i className="fa-regular fa-pen"></i>}
        onClick={onVariableEdit}
      />
    </H1_FlexRow>
  );
};

export interface VariablesMenuPros {
  onClickInsertVariable: (variable: DraftVariable) => void;
}
// const mockVariables: DraftVariable[] = [{ id: "1", display_title: "title", name: "var-name" }];
const VariablesMenu = ({ onClickInsertVariable }: VariablesMenuPros) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const draft = useAppSelector((state) => state.drafts.currentDraft);

  const variables = useAppSelector((state) => state.drafts.currentDraft.variables);
  const addVariableStatus = useAppSelector((state) => state.drafts.addVariableStatus);

  const loading = addVariableStatus === fetchingStatus.loading;
  const onAddVariable = () => {
    if (loading) {
      return;
    }
    dispatch(
      draftsActions.addVariableRequest({
        draftId: draft.id as string,
        variable: {
          display_title: "var" + (variables?.length || 0),
          name: "var" + (variables?.length || 0),
          type: VariableType.text
        }
      })
    );
  };

  const onClick = (e: any) => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleOpenChange = (open: boolean) => {
    setVisible(open);
  };

  return (
    <Popover isOpen={visible} onOpenChange={handleOpenChange} placement="bottom-start">
      <PopoverTrigger>
        <HoverButton
          color="primary"
          isDisabled={false}
          onClick={onClick}
          variant="light"
          isIconOnly
          startContent={<i className="fa-solid fa-cube" />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <MaxFlexColumn overflow="auto">
          <HoverFlexRow align="center" justify="space-between" onClick={onAddVariable}>
            <H1_TextSmall>Add new</H1_TextSmall>
            <Button
              color="primary"
              isIconOnly
              size="sm"
              startContent={
                <i
                  className={
                    loading ? "fa-sharp fa-light fa-loader add-icon" : "fa-regular fa-plus add-icon"
                  }
                ></i>
              }
              onClick={onAddVariable}
            ></Button>
          </HoverFlexRow>
          <ConditionalRender condition={variables && variables.length > 0}>
            <Divider />
          </ConditionalRender>
          {variables?.map((variable) => (
            <HoverFlexRow
              width="100%"
              justify="space-between"
              align="center"
              key={variable.id}
              onClick={() => onClickInsertVariable(variable)}
            >
              <VariableItem variable={variable} />
            </HoverFlexRow>
          ))}
        </MaxFlexColumn>
      </PopoverContent>
    </Popover>
  );
};

export default VariablesMenu;
