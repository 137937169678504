import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Button, Divider } from "antd";
import { useDispatch } from "react-redux";
import { Draft, FeatureFlag, Folder, Recipe, RecipeType } from "app/types";
import TemplatePreviewThumbnail from "app/components/editor/templateSelect/TemplatePreviewThumbnail";
import { draftsActions } from "app/store/slices/drafts.slice";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { fetchingStatus } from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import { useIntl } from "react-intl";
import { templatesMessages } from "app/components/editor/templateSelect/messages";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import { useFlags } from "launchdarkly-react-client-sdk";
import useUpgradeModal from "app/hooks/useUpgradeModal";

import { ModalName } from "app/hooks/useModal";
import RealsTextIcon from "app/components/common/RealsTextIcon";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextMiddle,
  H1_TextMidHeadline,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { H1_Select } from "app/components/_Infrastructure/design-system/select";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAuth } from "app/auth/useAuth";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import usePermissions from "app/hooks/usePermissions";

// region [[ Styles ]]

const StyledTemplatePreviewThumbnail = styled(TemplatePreviewThumbnail)`
  && video {
    max-height: 480px;
    border: none;
  }
`;

const TemplatePreviewContentView = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;

  & .ant-layout-header {
    line-height: unset;
  }
`;

const LeftSide = styled(H1_FlexColumn)`
  background: ${(props) => props.theme.gray11};
  max-width: 50%;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const RightSide = styled(H1_FlexColumn)`
  background: ${(props) => props.theme.gray1};
`;

const DividerStyled = styled(Divider)`
  border-color: #e5e5e5;
`;

const StyledButton = styled(Button)`
  &&,
  &&:focus,
  &&:focus-within {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    padding-left: 8px;
    padding-right: 8px;
    appearance: button;
    border-radius: 2px;
    user-select: none;
    border-width: 1px;
    border-color: ${({ theme }) => theme.blue4};
    background-color: ${({ theme }) => theme.blue4};
    height: 38px;
    color: ${({ theme }) => theme.gray1};
    fill: ${({ theme }) => theme.gray1};

    i {
      font-size: 16px;
    }

    svg,
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.blue3};
    border-color: ${({ theme }) => theme.blue3};
    color: ${({ theme }) => theme.gray1};
    fill: ${({ theme }) => theme.gray1};
    i,
    svg {
      color: ${({ theme }) => theme.gray1};
      fill: ${({ theme }) => theme.gray1};
    }
  }
  &:active {
    background-color: ${({ theme }) => theme.blue2};
    border-color: ${({ theme }) => theme.blue2};
    color: ${({ theme }) => theme.gray1};
    fill: ${({ theme }) => theme.gray1};
    i,
    svg {
      color: ${({ theme }) => theme.gray1};
      fill: ${({ theme }) => theme.gray1};
    }
  }
`;

export interface TemplatePreviewContentProps {
  recipe: Recipe;
  folderId?: string;
  folders: Folder[];
  recipeType?: RecipeType;
  userNotAuthActionHandler: () => void;
}

const TemplatePreviewContent = ({
  recipe,
  folders,
  folderId,
  recipeType,
  userNotAuthActionHandler
}: TemplatePreviewContentProps) => {
  const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>();
  const showPaymentModal = useUpgradeModal();
  const { isAuthenticated } = useAuth();

  const flags = useFlags();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [draftName, setDraftName] = useState("");
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const isLoading = createDraftStatus === fetchingStatus.loading;
  const { formatMessage } = useIntl();
  const { isWriteWorkspaceAllow } = usePermissions();

  useEffect(() => {
    if (folderId && folders) {
      const folder = folders.find((current) => current.id === folderId);
      setSelectedFolder(folder);
    }
  }, [folderId, folderId]);

  const getDraftName = () => {
    return draftName === "" ? `Untitled Video - ${recipe.title}` : draftName;
  };

  const onUseTemplate = () => {
    if (!isAuthenticated) {
      userNotAuthActionHandler();
      return;
    }

    handleProjectCreation({
      recipe_id: recipe.id,
      title: getDraftName(),
      description: "",
      folder_parent_id: selectedFolder?.id
    });
  };

  const handleProjectCreation = (draft: Draft) => {
    const upgradeText = formatMessage(templatesMessages.upgradeText);
    const upgradeSubText = formatMessage(templatesMessages.upgradeSubtitleText);
    const newDraft = {
      ...draft,
      type: recipeType === RecipeType.personal ? "personal_recipe" : undefined
    } as Draft;
    if (recipe.pro && flags[FeatureFlag.blockProTemplateOnCreateDraft]) {
      const upgradeRequired = showPaymentModal({
        upgradeText,
        upgradeSubText,
        source: "upgrade_template_select",
        backModal: ModalName.templateSelect,
        requestedPlans: recipe.plans,
        context: {
          recipeId: recipe.id,
          recipeType
        }
      });

      if (!upgradeRequired) {
        dispatch(draftsActions.createDraftRequest({ draft: newDraft }));
      }
    } else {
      dispatch(draftsActions.createDraftRequest({ draft: newDraft }));
    }
  };

  const onChangeName = (event: any) => {
    setDraftName(event.target.value);
  };

  const onSelectFolder = (selectedFolderId: string) => {
    setSelectedFolder(folders.find((folder) => folder.id === selectedFolderId));
  };

  return (
    <TemplatePreviewContentView>
      <LeftSide padding="2.5rem" flex="1" height="100%">
        <H1_FlexColumn height="12.0625rem" flex="0 0 auto">
          <RealsTextIcon size={41} color={theme.gray1} />
          <H1_TextMiddle color={theme.gray1} margin="1.25rem 0 3.75rem" whiteSpace="break-spaces">
            {formatMessage(templatesMessages.realsExplanation)}
          </H1_TextMiddle>
        </H1_FlexColumn>

        <StyledTemplatePreviewThumbnail
          showControls
          alt={recipe.title}
          video={recipe.video_full || recipe.video}
          image={recipe.image}
          width="100%"
          autoPlay
        />
      </LeftSide>
      <RightSide flex="1" height="100%" padding="2.5rem">
        <H1_FlexColumn padding="1.25rem 0 0 0" width="100%" gap="1.25rem" flex="1">
          <H1_TextMidHeadline>{recipe.title}</H1_TextMidHeadline>
          <H1_TextSmall>{recipe.description}</H1_TextSmall>
          <DividerStyled />
          <H1_FlexColumn gap="0.3125rem">
            <H1_TextSmall>
              {formatMessage(templatesMessages.chooseDraftNameFormHeader)}
            </H1_TextSmall>
            <H1_Input
              disabled={isLoading}
              placeholder={formatMessage(templatesMessages.untitledPlaceholder)}
              initialValue={draftName}
              onChange={onChangeName}
            />
          </H1_FlexColumn>
          <DividerStyled />
          <H1_FlexColumn gap="0.3125rem" width="70%">
            <H1_TextSmall>{formatMessage(templatesMessages.chooseFolderFormHeader)}</H1_TextSmall>
            <H1_Select
              options={folders.map((folder) => ({ value: folder.id, label: folder.name }))}
              placeholder={formatMessage(templatesMessages.selectFolderPlaceholder)}
              onChange={onSelectFolder}
              defaultValue={selectedFolder?.name || "Default"}
              value={selectedFolder?.name}
              loading={isLoading}
            />
          </H1_FlexColumn>
        </H1_FlexColumn>

        <H1_FlexRow justify="flex-end">
          <StyledButton
            icon={recipe.pro ? <CrownIcon /> : undefined}
            loading={isLoading}
            onClick={onUseTemplate}
            disabled={!isWriteWorkspaceAllow}
          >
            {formatMessage(templatesMessages.useThisTemplateButton)}
          </StyledButton>
        </H1_FlexRow>
      </RightSide>
    </TemplatePreviewContentView>
  );
};

export default TemplatePreviewContent;
