import { useEffect } from "react";

interface useTimeoutOnLoadingProps {
  timeoutSec: number;
  loading: boolean;
  onTimeout: () => void;
}
const useTimeoutOnLoading = ({ loading, timeoutSec, onTimeout }: useTimeoutOnLoadingProps) => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (loading) {
      timeoutId = setTimeout(() => {
        onTimeout();
      }, timeoutSec * 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [loading]);
};
export default useTimeoutOnLoading;
