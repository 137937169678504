import styled from "styled-components";
import { addPolicyToUrlIfFileStackMedia, onImageError, onVideoError } from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";
import { CaretRightOutlined } from "@ant-design/icons";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { ReactNode, useMemo } from "react";
import MediaItemActions from "app/pages/mediaLibrary/MediaItemActions";
import ItemLayout from "./ItemLayout";

const PlayIconContainerFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 60px;
  left: 60px;
  background-color: ${(props) => props.theme.gray1};
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
`;
const PlayIcon = styled(CaretRightOutlined)`
  svg {
    width: 21px;
    height: 21px;
  }
  color: ${(props) => props.theme.gray11};
`;

const StyledImage = styled.img`
  object-fit: contain;
  height: 135px;
  width: 100%;
  border-radius: 10px;
`;

const StyledVideo = styled.video`
  object-fit: contain;
  height: 135px;
  width: 100%;
  border-radius: 10px;
`;

export interface MediaGenericItemProps {
  selected: boolean;
  url: string;
  isVideo?: boolean;
  isThumbnailVideo?: boolean;
  id: string;
  footer?: ReactNode;
  onZoomItem?: (event: React.MouseEvent) => void;
}

const MediaGenericItem = ({
  selected,
  url,
  isVideo,
  isThumbnailVideo,
  id,
  onZoomItem
}: MediaGenericItemProps) => {
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const formatedUrl = useMemo(
    () => addPolicyToUrlIfFileStackMedia(url, filestackPolicy),
    [url, filestackPolicy]
  );

  return (
    <ItemLayout selected={selected}>
      <ConditionalRender condition={!isThumbnailVideo}>
        <StyledImage data-image-handle={id} src={formatedUrl} onError={onImageError} />
      </ConditionalRender>

      <ConditionalRender condition={!!isThumbnailVideo}>
        <StyledVideo data-image-handle={id} src={formatedUrl} onError={onVideoError} />
      </ConditionalRender>
      <ConditionalRender condition={!!isVideo}>
        <PlayIconContainerFlexRow
          width="40px"
          height="40px"
          padding="0 0 0 4px"
          justify="center"
          align="center"
        >
          <PlayIcon />
        </PlayIconContainerFlexRow>
      </ConditionalRender>
      <MediaItemActions onZoomItem={onZoomItem} />
    </ItemLayout>
  );
};

export default MediaGenericItem;
