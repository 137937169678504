import { defineMessages } from "react-intl";

export const presentersPageMessages = defineMessages({
  headerTitle: {
    id: "presenters-page.header-title",
    defaultMessage: "Find the perfect avatar"
  },
  headerSubTitle: {
    id: "presenters-page.header-subtitle",
    defaultMessage: "for your videos"
  },
  searchPlaceholder: {
    id: "presenters-page.search-placeholder",
    defaultMessage: "Search avatar"
  },
  newReleases: {
    id: "presenters-page.new-releases",
    defaultMessage: "New Releases"
  },
  removeCharTitle: {
    id: "presenters-page.remove-char-title",
    defaultMessage: "Remove Character"
  },
  removeCharDesc: {
    id: "presenters-page.remove-char-description",
    defaultMessage: "Are you sure you want to remove this character?"
  },
  rename: {
    id: "presenters-page.rename",
    defaultMessage: "Rename"
  },
  remove: {
    id: "presenters-page.remove",
    defaultMessage: "Remove"
  },
  headerCharacterText1: {
    id: "presenters-page.header-character-text1",
    defaultMessage: "News"
  },
  headerCharacterText2: {
    id: "presenters-page.header-character-text2",
    defaultMessage: "Learning and Development"
  },
  headerCharacterText3: {
    id: "presenters-page.header-character-text3",
    defaultMessage: "Marketing"
  },
  filterBy: {
    id: "presenters-page.filter-by",
    defaultMessage: "Filter By"
  },
  all: {
    id: "presenters-page.all",
    defaultMessage: "All"
  },
  resetAll: {
    id: "presenters-page.reset-all",
    defaultMessage: "Reset All"
  },
  gender: {
    id: "presenters-page.gender",
    defaultMessage: "Gender"
  },
  female: {
    id: "presenters-page.female",
    defaultMessage: "Female"
  },
  male: {
    id: "presenters-page.male",
    defaultMessage: "Male"
  },
  clothing: {
    id: "presenters-page.clothing",
    defaultMessage: "Clothing"
  },
  hair: {
    id: "presenters-page.hair",
    defaultMessage: "Hair"
  },
  age: {
    id: "presenters-page.age",
    defaultMessage: "Age"
  },
  studio: {
    id: "presenters-page.studio",
    defaultMessage: "Studio"
  },
  mobile: {
    id: "presenters-page.mobile",
    defaultMessage: "Mobile"
  },
  desk: {
    id: "presenters-page.desk",
    defaultMessage: "Desk"
  },
  createVideoWithCharacter: {
    id: "presenters-page.create-video-with-character",
    defaultMessage: "Create video with avatar"
  },
  emptyText: {
    id: "presenters-page.empty-text",
    defaultMessage: "Oops, looks like we couldn't find any avatars"
  },
  emptyDescription: {
    id: "presenters-page.empty-description",
    defaultMessage: "Try changing your filters and try again?"
  },
  myAvatar: {
    id: "presenters-page.my-avatar",
    defaultMessage: "My Avatar"
  },
  progress: {
    id: "presenters-page.progress.label",
    defaultMessage: "Avatar is in progress"
  }
});
