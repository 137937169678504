import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

import { Skeleton } from "antd";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";

const GridSkeletonRow = () => {
  return (
    <H1_FlexColumn gap="5px" flex="1 0 240px" padding="45px 0 0 0">
      <H1_FlexColumn flex="1 0 30px">
        <Skeleton loading active paragraph={{ rows: 0, width: 100 }} title={{ width: "100px" }} />
      </H1_FlexColumn>
      <H1_FlexRow gap="28px" padding="0 0 30px 0" flex="0 0 160px">
        <StyledSkeletonButton active block $height="160px" $width="calc(160px * 16 / 9)" />
        <StyledSkeletonButton active block $height="160px" $width="calc(160px * 16 / 9)" />
        <StyledSkeletonButton active block $height="160px" $width="calc(160px * 16 / 9)" />
        <StyledSkeletonButton active block $height="160px" $width="calc(160px * 16 / 9)" />
        <StyledSkeletonButton active block $height="160px" $width="calc(160px * 16 / 9)" />
        <StyledSkeletonButton active block $height="160px" $width="calc(160px * 16 / 9)" />
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

const FullTemplatePageSkeleton = () => {
  return (
    <H1_FlexColumn padding="0 50px">
      <H1_FlexColumn padding="0 0 27px 0" flex="1 0 54px">
        <StyledSkeletonButton $width="100%" $height="50px" active block size="large" />
      </H1_FlexColumn>
      <GridSkeletonRow />
      <GridSkeletonRow />
      <GridSkeletonRow />
      <GridSkeletonRow />
    </H1_FlexColumn>
  );
};

export default FullTemplatePageSkeleton;
