import React, { MouseEvent, ReactNode, useState } from "react";
import { Alert, Button, Input, Select } from "antd";
import { useIntl } from "react-intl";
import messages from "app/components/mediaLibrary/PexelsTab/messages";
import { MediaType, SelectedImageContext } from "app/types/media";
import styled, { css } from "styled-components";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { MediaLibraryTabs } from "app/types/media";
import ConditionalRender from "app/components/common/ConditionalRender";
import usePexels from "app/components/mediaLibrary/PexelsTab/usePexels";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import MediaGenericItem from "app/pages/mediaLibrary/MediaGenericItem";

const { Option } = Select;

const { Search } = Input;
const WrapFlexRow = styled(H1_FlexRow)`
  flex-wrap: wrap;
  gap: 18px;
  overflow-y: auto;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 20px;
`;

const IconFlexRow = styled(H1_FlexRow)`
  border-radius: 2px;
  background-color: white;
  position: absolute;
  left: 15px;
  display: none;
  &:hover {
    transform: scale(1.1);
  }
  ${({ top }: { top: string }) => css`
    top: ${top};
  `};
`;

const ImageContainer = styled(H1_FlexColumn)`
  max-width: 168px;
  &:hover ${IconFlexRow} {
    display: flex;
  }

  img {
    filter: saturate(65%);
    object-fit: contain;
  }
`;

const LinkText = styled.div`
  width: fit-content;
  align-self: stretch;
  margin: 3px 0 0;
  font-weight: 300;
  font-size: 9px;
  line-height: 1.2;
  padding: 0 8px;
  color: ${({ theme }) => theme.gray11};
`;

const LoadButton = styled(Button)`
  margin-top: 20px;
`;
const SelectContainer = styled.div`
  width: 100px;
`;

export interface PexelsTabProps {
  onSelectImage: (value: string, context?: SelectedImageContext) => void;
  currentValue: string;
  onZoomItem: (event: React.MouseEvent, url: string, mediaType?: MediaType) => void;
}

const PexelsTab = ({ onSelectImage, onZoomItem }: PexelsTabProps) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<number>();
  const {
    loading,
    error,
    onSearch,
    onLoadMore,
    images,
    videos,
    onSearchChange,
    onChangeMediaType,
    mediaType
  } = usePexels();

  const onMediaSelect = (
    e: MouseEvent<HTMLDivElement>,
    url: string,
    thumbnailType: MediaType,
    thumbnail: string,
    title: string | ReactNode,
    id: number,
    selectedMediaType: MediaType
  ) => {
    // @ts-ignore handels Property 'tagName' does not exist on type 'EventTarget'
    if (e?.target?.tagName === "A") return;
    setSelected(id);
    onSelectImage(url, {
      thumbnailType,
      thumbnail,
      url: url,
      title,
      handle: String(id),
      tab: MediaLibraryTabs.Pexels,
      mediaType: selectedMediaType
    });
  };

  const creditLink = (ownerName: string, ownerUrl: string, type: MediaType) => {
    // @ts-ignore handels formmating issue with html tags params
    return intl.formatMessage(
      type === MediaType.image ? messages.pexelsImageTag : messages.pexelsVideoTag,
      {
        name: ownerName,
        imageLink: (str) => (
          <a href={`${ownerUrl}`} target="_blank" rel="noreferrer">
            {str}
          </a>
        ),
        pexelsLink: (str) => (
          <a href="https://www.pexels.com" target="_blank" rel="noreferrer">
            {str}
          </a>
        )
      }
    ) as ReactNode;
  };

  return (
    <H1_FlexColumn overflow="auto" height="100%" padding="10px 0 0 0">
      <H1_FlexRow gap="5px" justify="space-between">
        <ConditionalRender condition={false}>
          <SelectContainer>
            <Select style={{ width: 100 }} onChange={onChangeMediaType} value={mediaType}>
              <Option value={MediaType.image}>{intl.formatMessage(messages.filterImages)}</Option>
              <Option value={MediaType.video}>{intl.formatMessage(messages.filterVideos)}</Option>
            </Select>
          </SelectContainer>
        </ConditionalRender>
        <StyledSearch
          placeholder={intl.formatMessage(messages.searchPlaceholder)}
          allowClear
          onChange={(e) => onSearchChange(e.target.value)}
          onSearch={onSearch}
        />
      </H1_FlexRow>

      <ConditionalRender condition={error}>
        <Alert message={intl.formatMessage(messages.error)} showIcon />
      </ConditionalRender>
      <WrapFlexRow>
        <ConditionalRender condition={mediaType === MediaType.image}>
          {images.map((item) => (
            <H1_FlexColumn
              key={item.id}
              width="168px"
              onClick={(e) =>
                onMediaSelect(
                  e,
                  item.src.medium,
                  MediaType.image,
                  item.src.tiny,
                  creditLink(item.photographer, item.photographer_url, MediaType.image),
                  item.id,
                  MediaType.image
                )
              }
            >
              <ImageContainer
                position="relative"
                overflow="hidden"
                gap="7px"
                padding="4px"
                flex="0 0 auto"
              >
                <MediaGenericItem
                  url={item.src.tiny}
                  selected={item.id === selected}
                  id={String(item.id)}
                />
                <IconFlexRow
                  onClick={(e) => onZoomItem(e, item.src.original, MediaType.image)}
                  top="25px"
                  padding="2px"
                  align="center"
                  justify="center"
                >
                  <ArrowsAltOutlined />
                </IconFlexRow>
              </ImageContainer>
              <LinkText>
                {creditLink(item.photographer, item.photographer_url, MediaType.image)}
              </LinkText>
            </H1_FlexColumn>
          ))}
        </ConditionalRender>
        <ConditionalRender condition={mediaType === MediaType.video}>
          {videos.map((item) => (
            <H1_FlexColumn
              key={item.id}
              width="168px"
              onClick={(e) =>
                onMediaSelect(
                  e,
                  item.video_files[0].link,
                  MediaType.image,
                  item.image,
                  creditLink(item.user.name, item.user.url, MediaType.video),
                  item.id,
                  MediaType.video
                )
              }
            >
              <ImageContainer
                position="relative"
                overflow="hidden"
                gap="7px"
                padding="4px"
                flex="0 0 auto"
              >
                <MediaGenericItem
                  url={item.image}
                  selected={item.id === selected}
                  id={String(item.id)}
                  isVideo
                />
                <IconFlexRow
                  onClick={(e) => onZoomItem(e, item.video_files[0].link, MediaType.video)}
                  top="25px"
                  padding="2px"
                  align="center"
                  justify="center"
                >
                  <ArrowsAltOutlined />
                </IconFlexRow>
              </ImageContainer>
              <LinkText>{creditLink(item.user.name, item.user.url, MediaType.video)}</LinkText>
            </H1_FlexColumn>
          ))}
        </ConditionalRender>
      </WrapFlexRow>
      <LoadButton type="primary" loading={loading} onClick={onLoadMore}>
        {intl.formatMessage(messages.loadMore)}
      </LoadButton>
    </H1_FlexColumn>
  );
};

export default PexelsTab;
