import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { userActions } from "app/store/slices/user.slice";

const useQueryParamReducer = () => {
  const [isQueryParamReduced, setIsQueryParamReduced] = useState<boolean>();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryParams: any = {};

    searchParams.forEach((value: string, key: string) => {
      queryParams[key] = value;
    });

    dispatch(userActions.setInitialQueryParams(queryParams));
    setIsQueryParamReduced(true);
  }, []);

  return {
    isQueryParamReduced
  };
};

export default useQueryParamReducer;
