import React, { ChangeEventHandler, useState } from "react";
import { useIntl } from "react-intl";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { Button, Input } from "antd";
import srcImage from "app/assets/teams/create-new-team.png";
import { createNewTeamMessages } from "app/components/teams/messages";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextHeadline, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { containsUrl } from "app/utils/helpers";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 54px 46px 40px;
    flex: 1;
    border-radius: 12px;
    min-height: 477px;
    height: 477px;
    gap: 23px;
    align-items: center;
  }
`;

const ActionButton = styled(Button)`
  &&,
  &&:focus {
    color: ${({ theme }) => theme.gray1};
    background-color: ${({ theme }) => theme.blue4};
    width: 100%;
  }
  &&&:hover {
    background-color: ${({ theme }) => theme.blue2};
  }
  &&&:active {
    background-color: ${({ theme }) => theme.blue1};
  }
`;

const SecondaryButton = styled(Button)`
  &&,
  &&:focus {
    color: ${({ theme }) => theme.gray6};
    background-color: ${({ theme }) => theme.gray3};
    width: 100%;
    border: 1px solid ${({ theme }) => theme.gray5};
  }
  &&&:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
`;

interface CreateWorkspaceModalProps {
  visible: boolean;
  onFinish: (name: string) => void;
  onClose: () => void;
}
const CreateWorkspaceModal = ({ visible, onFinish, onClose }: CreateWorkspaceModalProps) => {
  const [teamName, setTeamName] = useState<string>("");
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const validateAndSetName: ChangeEventHandler<HTMLInputElement> = (event) => {
    const teamName = event.target.value;
    if (!!teamName && !containsUrl(teamName)) {
      setTeamName(teamName);
    }
  };

  return (
    <Modal
      width="461px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <img src={srcImage} height={56} width={182} alt="create-new-team" />
      <H1_FlexColumn justify="space-between" flex="1">
        <H1_FlexColumn gap="19px" align="center">
          <H1_TextHeadline color={theme.gray10}>
            {formatMessage(createNewTeamMessages.title)}
          </H1_TextHeadline>
          <H1_TextSmall whiteSpace="break-spaces">
            {formatMessage(createNewTeamMessages.description)}
          </H1_TextSmall>
        </H1_FlexColumn>
        <H1_FlexColumn gap="8px" padding="30px 0 0 0">
          <H1_TextSmall color={theme.gray9}>
            {formatMessage(createNewTeamMessages.title)}
          </H1_TextSmall>
          <Input
            value={teamName}
            onChange={validateAndSetName}
            placeholder={formatMessage(createNewTeamMessages.teamNamePlaceholder)}
          />
        </H1_FlexColumn>
        <H1_FlexRow gap="15px" align="center" justify="center" height="40px">
          <SecondaryButton size="large" onClick={onClose}>
            {formatMessage(createNewTeamMessages.cancelButton)}
          </SecondaryButton>
          <ActionButton size="large" onClick={() => onFinish(teamName)} disabled={!teamName}>
            {formatMessage(createNewTeamMessages.createButton)}
          </ActionButton>
        </H1_FlexRow>
      </H1_FlexColumn>
    </Modal>
  );
};

export default CreateWorkspaceModal;
