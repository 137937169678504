/* eslint-disable no-nested-ternary */
import React from "react";
import { PaletteColor } from "app/types";
import styled from "styled-components";
import PaletteBackground from "./PaletteBackground";
import DevModePopover from "app/components/DevModePopover";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button } from "@nextui-org/react";

const StyledButton = styled(Button)<{ disabled?: boolean; $selected?: boolean }>`
  min-width: fit-content;
  height: fit-content;
  padding: 0;
  border-radius: 5px;
  border: 1px solid ${({ theme, disabled }) => (disabled ? theme.gray5 : theme.gray3)};
  &:hover {
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.gray5 : theme.gray3)};
  }
  &:active {
    ${({ disabled }) => !disabled && "transform: scale(0.95);"}
  }
`;

const ContainerFlexRow = styled(H1_FlexRow)`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.gray3};
`;
export interface PaletteButtonProps {
  paletteColors: PaletteColor[];
  selected: boolean;
  onSelectPalette?: () => void;
  disabled?: boolean;
  paletteId?: string;
}

const PaletteButton = ({
  paletteColors,
  disabled,
  selected,
  onSelectPalette,
  paletteId
}: PaletteButtonProps) => {
  const onSelected = () => {
    if (onSelectPalette) {
      onSelectPalette();
    }
  };

  return (
    <ContainerFlexRow
      overflow="hidden"
      position="relative"
      gap="5px"
      align="center"
      height="72px"
      width="133px"
    >
      <H1_FlexRow flex="1">
        <ConditionalRender condition={!!paletteId}>
          <DevModePopover
            placement="topLeft"
            value={{ palette_id: paletteId as string }}
            path="root"
          />
        </ConditionalRender>
        <StyledButton
          color="primary"
          isDisabled={disabled}
          $selected={selected}
          onClick={onSelected}
        >
          <PaletteBackground selected={selected} colors={paletteColors} />
        </StyledButton>
      </H1_FlexRow>
    </ContainerFlexRow>
  );
};

export default PaletteButton;
