import { videoReadyModalMessages } from "app/pages/editor/messages";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const Button = styled("button")`
  background: ${({ theme }) => theme.orange4};
  border: unset;
  padding: 0px;
  border-radius: 2px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
  }
  cursor: pointer;
  height: 36px;
`;

const IconWrapper = styled(H1_FlexColumn)`
  background: ${({ theme }) => theme.orange2};
  height: 100%;
  width: 35px;
  i {
    color: ${({ theme }) => theme.gray1};
  }
  border-radius: 2px 0px 0px 2px;
`;
interface BuyVideoButtonProps {
  price?: number;
  onClick: () => void;
}
const BuyVideoButton = ({ price, onClick }: BuyVideoButtonProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Button onClick={onClick}>
      <H1_FlexRow justify="center" align="center" height="100%">
        <IconWrapper justify="center">
          <i className="fa-solid fa-play" />
        </IconWrapper>
        <H1_FlexColumn padding="0px 12px" gap="2px" justify="center">
          <H1_TextXs lineHeight="12px" color={theme.gray1}>
            {formatMessage(videoReadyModalMessages.buyVideoButton, {
              amount: `$${price}`
            })}
          </H1_TextXs>
          <H1_TextXs lineHeight="8px" fontSize="8px" color={theme.gray1}>
            {formatMessage(videoReadyModalMessages.buyVideoDescriptionButton)}
          </H1_TextXs>
        </H1_FlexColumn>
      </H1_FlexRow>
    </Button>
  );
};

export default BuyVideoButton;
