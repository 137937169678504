import { Draft, Location } from "app/types";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { durationToMMSS } from "app/utils/helpers";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Button } from "@nextui-org/react";
import useModal, { ModalName } from "app/hooks/useModal";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { useIntl } from "react-intl";
import { playListMessages } from "app/pages/PlaylistsPage/messages";
import { useNavigate } from "react-router-dom";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useAppSelector } from "app/hooks";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";

const PlayButton = styled(Button)`
  opacity: 0;
`;
const MoreItemsButton = styled(Button)`
  opacity: 0;
  pointer-events: none;
  i {
    color: ${({ theme }) => theme.gray11};
  }
`;
const VideoElement = styled(H1_FlexRow)`
  border-radius: 10px;
  min-width: 0;
  &:hover {
    ${PlayButton} {
      opacity: 1;
    }
    ${MoreItemsButton} {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;
const VideoFlexRow = styled(H1_FlexRow)`
  border-radius: 10px;
  * {
    pointer-events: none;
  }
`;

const DurationRow = styled(H1_FlexRow)`
  border-radius: 25px;
  bottom: 7px;
  right: 6px;
  background: rgba(0, 0, 0, 0.3);
`;

interface PlaylistVideoElementProps {
  draftId: string;
  onRemoveVideoFromPlaylist?: (draftId: string) => void;
}

const PlaylistVideoElement = ({
  draftId,
  onRemoveVideoFromPlaylist
}: PlaylistVideoElementProps) => {
  const { openModal } = useModal();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const draft: Draft = useAppSelector((state) =>
    draftsGlobalSelectors.selectById(state, draftId)
  ) as Draft;
  const onClickPlayVideo = (reqId: string) => {
    openModal(ModalName.videoReady, { reqId });
  };

  const onRemoveVideo = () => {
    if (onRemoveVideoFromPlaylist) {
      onRemoveVideoFromPlaylist(draft.id as string);
    }
  };

  const onEditVideo = () => {
    navigate(`${Location.Editor}/${draft.id}`);
  };

  const items = useMemo(() => {
    const result = [
      {
        key: "edit",
        onClick: onEditVideo,
        label: <H1_TextSmall>{formatMessage(playListMessages.editVideo)}</H1_TextSmall>
      },
      {
        key: "remove",
        onClick: onRemoveVideo,
        label: <H1_TextSmall>{formatMessage(playListMessages.removeVideo)}</H1_TextSmall>
      }
    ];
    return result;
  }, []);

  if (!draft) {
    return null;
  }

  return (
    <VideoElement
      flex="1 1 auto"
      justify="space-between"
      align="center"
      height="62px"
      key={draft.id}
    >
      <H1_FlexRow align="center" gap="13px">
        <VideoFlexRow
          overflow="hidden"
          height="62px"
          width="112px"
          position="relative"
          justify="center"
          flex="0 0 112px;"
        >
          <img src={draft.image} alt="" />
          <ConditionalRender condition={!!draft.last_video?.duration}>
            <DurationRow position="absolute" height="18px" padding="0 4px">
              <H1_TextXs color="white">
                {durationToMMSS(draft.last_video?.duration as number)}
              </H1_TextXs>
            </DurationRow>
          </ConditionalRender>
        </VideoFlexRow>
        <H1_TextSmall margin="0 0 0 4px" ellipsis>
          {draft.last_video?.title}
        </H1_TextSmall>
      </H1_FlexRow>
      <H1_FlexRow gap="20px" align="center">
        <PlayButton
          radius="full"
          variant="flat"
          isIconOnly
          startContent={<H1_Icon icon="fas fa-play" isCursorPointer />}
          onClick={() => onClickPlayVideo(draft.last_video?.req_id as string)}
        />
        <NextDropdown items={items}>
          <MoreItemsButton
            size="sm"
            variant="light"
            startContent={<i className="far fa-ellipsis-vertical" />}
            isIconOnly
          />
        </NextDropdown>
      </H1_FlexRow>
    </VideoElement>
  );
};

export default PlaylistVideoElement;
