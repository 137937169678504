import React, { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import { pricingPageMessages } from "app/pages/pricing/messages";
import { FeatureFlag, PaymentPeriod, PlanToUpgrade, PricingPlanLine } from "app/types";
import styled, { useTheme } from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/upgrade-crown.svg";
import IconButton from "app/components/common/Icons/IconButton";
import { usePricingPlans } from "app/pages/pricing/PricingPlans";
import { Slider } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextLargeHeadline,
  H1_TextMiddle,
  H1_TextMidHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import {
  getAllBusinessMonthlyPlansMinutes,
  getCurrentBusinessMinutes,
  getSpecificSubscriptionPlan
} from "app/store/selectorsV2/payments.selectors";
import { SliderMarks } from "antd/es/slider";
import { paymentModalV2InitialMessages } from "app/pages/pricing/PaymentModalV2/messages";
import { paymentsActions } from "app/store/slices/payments.slice";
import { Button } from "@nextui-org/react";
import NextUITooltip from "app/components/common/NextUI/Tooltip/NextUITooltip";

const ContainerFlexColumn = styled(H1_FlexColumn)`
  border: 1px solid ${(props) => props.theme.gray5};
  border-radius: 25px;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  .ant-slider-rail {
    background-color: ${({ theme }) => theme.gray4};
  }
  .ant-slider-track {
    background-color: ${({ theme }) => theme.blue4};
  }
  :not(.ant-slider-disabled) {
    .ant-slider-handle {
      background-color: ${({ theme }) => theme.blue4};
      border-color: ${({ theme }) => theme.blue4};
    }
  }
  .ant-slider-step {
    .ant-slider-dot {
      display: none;
    }
  }
  .ant-slider-mark {
    .ant-slider-mark-text {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      white-space: pre;
    }
  }
  &:hover {
    .ant-slider-rail {
      background-color: ${({ theme }) => theme.gray4};
    }
    .ant-slider-track {
      background-color: ${({ theme }) => theme.blue4};
    }
    :not(.ant-slider-disabled) {
      .ant-slider-handle:not(.ant-tooltip-open) {
        background-color: ${({ theme }) => theme.blue4};
        border-color: ${({ theme }) => theme.blue4};
      }
    }
  }
`;

const BorderedIconButton = styled(IconButton)`
  border-radius: 24px;
`;

const MostPopularFlexColumn = styled(H1_FlexColumn)`
  top: -13px;
  left: calc(50% - 80px);
  text-align: center;
  background-color: ${(props) => props.theme.blue4};
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;
`;

const StyledIcon = styled.i`
  font-size: 20px;
  color: ${(props) => props.theme.gray6};
`;

const InfoButton = styled(Button)`
  height: 20px;
  background-color: transparent !important;
  padding-top: 0;
  &&& {
    border: none;
  }
  & i {
    color: ${(props) => props.theme.gray7};
  }
`;

const LineThourghText = styled(H1_TextMidHeadline)`
  text-decoration: line-through;
`;

export interface PricingPagePlanProps {
  plan: PlanToUpgrade;
  showMostPopular?: boolean;
  showUpgradeIcon?: boolean;
  showContactSalesPlan?: boolean;
  showPriceDescription?: boolean;
  disabled?: boolean;
  isSelected?: boolean;
  isPriceMonthly?: boolean;
  onSelectedPlan: (plan: string) => void;
  paymentType: PaymentPeriod;
  onBoardingPricingMode?: boolean;
}

const PricingPagePlan = ({
  plan,
  showMostPopular,
  showUpgradeIcon,
  showContactSalesPlan,
  showPriceDescription,
  disabled,
  isSelected,
  isPriceMonthly,
  onSelectedPlan,
  paymentType,
  onBoardingPricingMode = false
}: PricingPagePlanProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const plans = usePricingPlans({ currentPlanType: paymentType });
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const buttonDisabled = isSelected || disabled;
  const pricingAndPlans = useAppSelector((state) => state.payments.pricingAndPlans);
  const verifyCouponResults = useAppSelector((state) => state.payments.verifyCouponResults);
  const verifyCouponStatus = useAppSelector((state) => state.payments.verifyCouponStatus);
  const verifyCouponStatusLoading = verifyCouponStatus === fetchingStatus.loading;
  const { numberOfMinutesPosition } = useAppSelector(
    (state) => state.payments.pricingModalProperties
  );
  const allBusinessMonthlyPlansMinutes: SliderMarks = useAppSelector(
    getAllBusinessMonthlyPlansMinutes
  );

  const currentMinutes = useAppSelector(getCurrentBusinessMinutes) as number;

  const targetSelectedPlan = useAppSelector((state) => getSpecificSubscriptionPlan(state, plan));
  const showDiscountPrice =
    flags[FeatureFlag.responsiveBannerCode] &&
    verifyCouponStatus !== fetchingStatus.loading &&
    verifyCouponResults?.percentOff &&
    (plan === PlanToUpgrade.personal || plan === PlanToUpgrade.business);
  const descriptionPrice = isPriceMonthly
    ? plans[plan].priceDescription
    : plans[plan].priceYearlyDescription;
  const isShowAnnualPrice = plan === PlanToUpgrade.personal || plan === PlanToUpgrade.business;

  const marks: SliderMarks = useMemo(() => {
    if (!allBusinessMonthlyPlansMinutes) {
      return {};
    }
    const plansAsArray = Object.keys(allBusinessMonthlyPlansMinutes);
    if (plansAsArray.length > 0) {
      const returnObject: Record<number, string> = {};
      plansAsArray.forEach((obj: string, index: number) => {
        returnObject[index + 1] = formatMessage(paymentModalV2InitialMessages.sliderMinutes, {
          number: Math.round(allBusinessMonthlyPlansMinutes[obj] as number)
        });
      });
      return returnObject;
    }
    return {};
  }, [allBusinessMonthlyPlansMinutes]);

  const currentPrice = useMemo(() => {
    let newPrice = targetSelectedPlan?.price_per_month;
    if (showDiscountPrice && verifyCouponResults?.percentOff) {
      newPrice = parseFloat(
        (((newPrice || 0) * (100 - verifyCouponResults?.percentOff)) / 100).toFixed(2)
      );
    }

    return newPrice;
  }, [showDiscountPrice, verifyCouponResults?.percentOff, pricingAndPlans, targetSelectedPlan]);

  const onChangeSlider = (value: number) => {
    dispatch(
      paymentsActions.updatePricingModalProperties({
        numberOfMinutesPosition: value
      })
    );
  };

  return (
    <ContainerFlexColumn
      justify="space-between"
      position="relative"
      width="273px"
      flex="1"
      padding="27px 18px"
    >
      <H1_FlexColumn gap="29px">
        <ConditionalRender condition={showMostPopular}>
          <MostPopularFlexColumn position="absolute" height="27px" justify="center" align="center">
            <H1_TextXs fontSize="14px" lineHeight="15px" color={theme.gray3}>
              {formatMessage(pricingPageMessages.mostPopular)}
            </H1_TextXs>
          </MostPopularFlexColumn>
        </ConditionalRender>
        {/* Top */}
        <H1_FlexColumn gap="6px" flex={plan === PlanToUpgrade.business ? "0 0 90px" : "0 0 90px"}>
          <H1_FlexRow gap="10px" align="center">
            <ConditionalRender condition={showUpgradeIcon}>
              <UpgradeIcon width="24px" height="24px" />
            </ConditionalRender>
            <H1_TextHeadline
              fontWeight={800}
              fontSize="28px"
              lineHeight="42px"
              color={theme.gray10}
            >
              {plans[plan].displayName}
            </H1_TextHeadline>
          </H1_FlexRow>
          <H1_TextSmall whiteSpace="break-spaces" fontWeight={500} color={theme.gray10}>
            {plans[plan].subtitle}
          </H1_TextSmall>
          {/* Slider */}
          <ConditionalRender
            condition={plan === PlanToUpgrade.business && !verifyCouponStatusLoading}
          >
            <H1_FlexRow width="100%" padding="6px 10px 0">
              <StyledSlider
                min={1}
                max={3}
                dots
                marks={marks}
                defaultValue={numberOfMinutesPosition}
                value={numberOfMinutesPosition}
                onChange={onChangeSlider}
                step={null}
                tooltip={{ formatter: null }}
                disabled={buttonDisabled}
              />
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexColumn>

        {/* Price */}
        <ConditionalRender condition={verifyCouponStatusLoading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={!verifyCouponStatusLoading}>
          <H1_FlexColumn flex="0 0 54px">
            <ConditionalRender condition={showDiscountPrice}>
              <H1_FlexRow gap="5px" align="baseline" height="36px">
                <LineThourghText
                  fontWeight={500}
                  fontSize="24px"
                  lineHeight="36px"
                  color={theme.gray10}
                >
                  ${plans[plan].price}
                </LineThourghText>
                <H1_TextHeadline
                  fontWeight={500}
                  fontSize="32px"
                  lineHeight="36px"
                  color={theme.blue4}
                >
                  ${currentPrice}
                </H1_TextHeadline>
                <ConditionalRender condition={isShowAnnualPrice}>
                  <H1_TextMiddle
                    lineHeight="25px"
                    fontSize="15px"
                    fontWeight={500}
                    color={theme.gray8}
                  >
                    {formatMessage(pricingPageMessages.perMonth)}
                  </H1_TextMiddle>
                </ConditionalRender>
              </H1_FlexRow>
            </ConditionalRender>
            <ConditionalRender condition={!showDiscountPrice}>
              <H1_TextLargeHeadline
                fontWeight={500}
                fontSize="36px"
                lineHeight="36px"
                color={theme.gray10}
              >
                {plans[plan].finalPrice(currentPrice as number) as ReactNode}
              </H1_TextLargeHeadline>
            </ConditionalRender>
            <H1_FlexRow gap="10px">
              <ConditionalRender condition={plan === PlanToUpgrade.business}>🎉</ConditionalRender>
              <H1_TextXs
                fontSize="12px"
                lineHeight="18px"
                color={
                  !isPriceMonthly && plan === PlanToUpgrade.business ? theme.blue4 : theme.gray10
                }
              >
                {isPriceMonthly
                  ? plans[plan].priceMonthly(currentMinutes)
                  : plans[plan].priceYearly(currentMinutes * 12)}
              </H1_TextXs>
            </H1_FlexRow>
          </H1_FlexColumn>
        </ConditionalRender>
        <BorderedIconButton
          backgroundColor={isSelected || buttonDisabled ? theme.gray5 : theme.blue4}
          height="45px"
          onClick={() => onSelectedPlan(plan)}
          disabled={buttonDisabled}
        >
          <H1_TextMiddle color={theme.gray1} fontSize="15px" lineHeight="22px" fontWeight={500}>
            <ConditionalRender condition={showContactSalesPlan && !isSelected}>
              {formatMessage(pricingPageMessages.contactSalesButton)}
            </ConditionalRender>
            <ConditionalRender condition={!showContactSalesPlan && !isSelected}>
              {formatMessage(
                onBoardingPricingMode
                  ? pricingPageMessages.getStartedButton
                  : pricingPageMessages.selectPlanButton
              )}
            </ConditionalRender>
            <ConditionalRender condition={isSelected}>
              {formatMessage(pricingPageMessages.yourCurrentPlanButton)}
            </ConditionalRender>
          </H1_TextMiddle>
        </BorderedIconButton>
        <H1_FlexRow height="23px">
          <ConditionalRender condition={showPriceDescription}>
            <H1_TextMiddle
              whiteSpace="break-spaces"
              fontSize="15px"
              lineHeight="22px"
              color={theme.gray10}
            >
              {descriptionPrice}
            </H1_TextMiddle>
          </ConditionalRender>
        </H1_FlexRow>
        <H1_FlexColumn gap="16px">
          <H1_TextMiddle fontWeight={500} fontSize="15px" lineHeight="22px" color={theme.gray8}>
            {plans[plan].optionsTitle}
          </H1_TextMiddle>
          <H1_FlexColumn gap="10px">
            {plans[plan].headerFeatures.map(({ text, tooltip }: PricingPlanLine, index: number) => (
              <H1_FlexRow align="center" key={`OptionNumber${index}`} gap="10px">
                <StyledIcon className="fas fa-check-circle" />
                <H1_TextXs
                  whiteSpace="break-spaces"
                  fontSize="11px"
                  lineHeight="16px"
                  color={theme.gray8}
                  fontWeight={500}
                >
                  {text}
                </H1_TextXs>
                <ConditionalRender condition={!!tooltip}>
                  <NextUITooltip content={tooltip}>
                    <InfoButton
                      isIconOnly
                      variant="light"
                      startContent={<i className="far fa-circle-info" />}
                    />
                  </NextUITooltip>
                </ConditionalRender>
              </H1_FlexRow>
            ))}
          </H1_FlexColumn>
        </H1_FlexColumn>
      </H1_FlexColumn>
    </ContainerFlexColumn>
  );
};

export default PricingPagePlan;
