import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { Channel } from "pusher-js";
import { useAppDispatch } from "app/hooks";
import * as Sentry from "@sentry/react";
import { CGNType } from "app/types";
import { cgnActions } from "app/store/slices/cgn.slice";

const PusherCGN = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleCGN = (message: {
      name: "entertainment" | "news";
      type: CGNType;
      playback_id: string;
    }) => {
      const { type, playback_id, name } = message;
      if (!playback_id) {
        console.error("CGN pusher does not contain playback_id");
        Sentry.captureException("CGN pusher does not contain playback_id");
        return;
      }
      dispatch(cgnActions.setCGNProperties({ name, playback_id, type }));
    };
    if (user?.sub) {
      const channelName = `hourone_streaming`;
      channel = pusher.subscribe(channelName);
      channel.bind("hourone.streaming.playback", handleCGN);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherCGN;
