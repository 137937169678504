import MediaItemActions from "app/pages/mediaLibrary/MediaItemActions";
import React, { useEffect, useState } from "react";
import ItemLayout from "app/pages/mediaLibrary/ItemLayout";
import MediaLibraryEditName from "app/pages/mediaLibrary/MediaLibraryEditName";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import { ServerFolderMedia } from "app/types/media";
import styled from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";

const FolderIcon = styled.i`
  color: ${({ theme }) => theme.blue1};
  font-size: 50px;
`;
export interface MediaLibraryFolderItemProps {
  fileId: string;
  loading: boolean;
  onDeleteItem?: (event: React.MouseEvent) => void;
  onMoveItem?: (event: React.MouseEvent) => void;
  onEditIntent: (file: ServerFolderMedia) => void;
  isSelected: boolean;
}
const MediaLibraryFolderItem = ({
  onDeleteItem,
  onMoveItem,
  fileId,
  onEditIntent,
  loading,
  isSelected
}: MediaLibraryFolderItemProps) => {
  const [editable, setEditable] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const createdFolder = useAppSelector((state) => state.media.createdFolder);
  const file = useAppSelector((state) =>
    mediaGlobalSelectors.selectById(state, fileId)
  ) as ServerFolderMedia;

  useEffect(() => {
    if (createdFolder?.id === fileId) {
      setEditable(true);
      onEditIntent(file);
    }
  }, [createdFolder]);

  const onEdit = () => {
    setEditable(true);
    onEditIntent(file);
  };

  const onCloseEdit = () => {
    setEditable(false);
    dispatch(mediaActions.resetCreatedFolder());
  };

  return (
    <ItemLayout
      folderLayout
      fileId={file.id}
      selected={isSelected}
      footer={
        <MediaLibraryEditName
          name={file.name as string}
          editable={editable}
          onEdit={onEdit}
          onCloseEdit={onCloseEdit}
          fileId={file.id}
        />
      }
    >
      <ConditionalRender condition={loading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!loading}>
        <FolderIcon className="far fa-folder" />
      </ConditionalRender>

      <MediaItemActions onDeleteItem={onDeleteItem} onEditItem={onEdit} onMoveItem={onMoveItem} />
    </ItemLayout>
  );
};

export default MediaLibraryFolderItem;
