import { defineMessages } from "react-intl";

export const videoWizardV2Messages = defineMessages({
  title: {
    id: `video-wizard-v2.title`,
    defaultMessage: `What videos are you trying to make?`
  },
  iAmMaking: {
    id: `video-wizard-v2.i-am-making`,
    defaultMessage: `I am making a video for`
  },
  videoFor: {
    id: `video-wizard-v2.video-for`,
    defaultMessage: `Video for`
  },
  chooseFormat: {
    id: `video-wizard-v2.choose-format`,
    defaultMessage: `CHOOSE A FORMAT`
  },
  chooseStyle: {
    id: `video-wizard-v2.choose-a-style`,
    defaultMessage: `CHOOSE A STYLE`
  },
  choosePace: {
    id: `video-wizard-v2.choose-pace`,
    defaultMessage: `ESTIMATED DURATION`
  },
  ctaButtonNext: {
    id: `video-wizard-v2.cta-button-next`,
    defaultMessage: `Next`
  },
  ctaButton: {
    id: `video-wizard-v2.cta-button`,
    defaultMessage: `Create my video`
  },
  landscape: {
    id: `video-wizard-v2.landscape`,
    defaultMessage: `16:9`
  },
  portrait: {
    id: `video-wizard-v2.portrait`,
    defaultMessage: `9:16`
  },
  square: {
    id: `video-wizard-v2.square`,
    defaultMessage: `1:1`
  },
  corporate: {
    id: `video-wizard-v2.corporate`,
    defaultMessage: `Corporate`
  },
  basic: {
    id: `video-wizard-v2.basic`,
    defaultMessage: `Basic`
  },
  fun: {
    id: `video-wizard-v2.fun`,
    defaultMessage: `Fun`
  },
  mainPoints: {
    id: `video-wizard-v2.main-points`,
    defaultMessage: `Main points you want to cover`
  },
  mainPointsPlaceholder: {
    id: `video-wizard-v2.main-points-placeholder`,
    defaultMessage: `Information about the recipient: [bullet points of info on the recipient]
Goal of email: [what do you want them to do]
Make a personal connection
Focus on a relevant pain point
Expand on how you can help them
Provide a plan for next steps`
  },
  videoWizardTimeoutErrorHead: {
    id: "ai-generate.video-wizard.timeout-error-head",
    defaultMessage: "Oops, Script Generation Taking Longer Than Expected!"
  },
  videoWizardTimeoutError: {
    id: "ai-generate.video-wizard.timeout-error",
    defaultMessage: "Don't worry, our video wizard is working hard. Please give it another shot!"
  }
});
