import { Button, ButtonProps } from "antd";
import styled from "styled-components";
import WandLoader from "app/components/common/Layout/WandLoader";
import React from "react";

export const ButtonLoader = styled(Button)<{ $size?: string; $color?: string }>`
  && {
    max-width: ${(props) => props.$size};
    max-height: ${(props) => props.$size};
    color: ${(props) => (props.$color ? props.$color : props.theme.blue4)};
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      color: ${(props) => (props.$color ? props.$color : props.theme.blue4)};
      svg {
        fill: ${(props) => (props.$color ? props.$color : props.theme.blue4)};
      }
    }
    &&& svg {
      transform: scale(2.5) !important;
    }
  }
`;

export const LowContrastButton = styled(ButtonLoader)`
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  color: var(--gray-07, #8c8c8c);
`;

export const RegularButton = styled(ButtonLoader)`
  &&& {
    border: none;
    border-radius: 2px;
    margin: 0;
    max-width: ${(props) => props.$size};
    max-height: ${(props) => props.$size};
    width: ${(props) => props.$size};
    height: ${(props) => props.$size};
    background-color: ${({ theme }) => theme.gray1};
    color: ${({ theme }) => theme.gray8};
    i {
      font-size: 14px;
    }
    &:hover {
      background-color: ${(props) => props.theme.gray3};
    }
  }
`;

export interface WandButtonProps extends Omit<ButtonProps, "size"> {
  lowContrast?: boolean;
  loading?: boolean;
  size?: string;
  asRegularButton?: boolean;
}

const IconLoader = ({ size, loading }: { size?: string; loading: boolean }) => {
  if (loading) {
    return <WandLoader size={size} />;
  }
  return <i className="fa-solid fa-wand-magic-sparkles" />;
};

const WandButton = React.forwardRef<HTMLElement, WandButtonProps>((props, ref) => {
  const { loading, lowContrast, asRegularButton, size, color } = props;
  const propsClear = { ...props };
  delete propsClear.loading;
  delete propsClear.lowContrast;
  delete propsClear.size;

  delete propsClear.color;
  delete propsClear.asRegularButton;
  if (lowContrast) {
    return (
      <LowContrastButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...propsClear}
        $size={size}
        $color={color}
        size={undefined}
        ref={ref}
        type="ghost"
        icon={<IconLoader loading={!!loading} size={size || "100%"} />}
      />
    );
  }

  if (asRegularButton) {
    return (
      <RegularButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...propsClear}
        $size={size}
        size={undefined}
        icon={<IconLoader loading={!!loading} size="100%" />}
      />
    );
  }

  return (
    <ButtonLoader
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...propsClear}
      size={undefined}
      $color={color}
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon={<IconLoader loading={!!loading} {...propsClear} size="100%" />}
    />
  );
});

WandButton.displayName = "WandButton";

export default WandButton;
