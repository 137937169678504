import React, { useState } from "react";
import { useIntl } from "react-intl";

import { FeatureFlag, Palette, PaletteColor, PlanEnum } from "app/types";
import "app/components/editor/bottomDrawers/GroupDrawers.scss";
import styled from "styled-components";
import PaletteButton from "app/components/editor/sideDrawers/StyleDrawer/PaletteButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import { fetchingStatus } from "app/utils/helpers";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import ConditionalRender from "app/components/common/ConditionalRender";
import CustomColorPickerModal from "app/components/editor/sideDrawers/StyleDrawer/CustomColorPickerModal";
import usePermissions from "app/hooks/usePermissions";
import { styleDrawerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { paymentModalMessages } from "app/pages/pricing/messages";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { useFlags } from "launchdarkly-react-client-sdk";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const GreyButton = styled(Button)`
  color: ${({ theme }) => theme.gray11};
  span {
    color: ${({ theme }) => theme.gray11};
  }
  background-color: ${({ theme }) => theme.gray4};
  &:hover {
    color: ${({ theme }) => theme.gray11};
    span {
      color: ${({ theme }) => theme.gray11};
    }
    background-color: ${({ theme }) => theme.gray5};
  }
  &:active {
    color: ${({ theme }) => theme.gray11};
    span {
      color: ${({ theme }) => theme.gray11};
    }
    background-color: ${({ theme }) => theme.gray6};
  }
`;
const StyledEditButton = styled(Button)`
  font-family: Inter, Poppins;
  font-size: 13px;
  padding: 0px;
`;

const OwnPallete = ({
  drawerName,
  onSelectPalette,
  upsertCustom
}: {
  drawerName: string;
  onSelectPalette: (pallete: Palette) => void;
  upsertCustom: (colors: PaletteColor[]) => void;
  selectable?: boolean;
}) => {
  const [custom, setCustom] = useState<Palette>();
  const intl = useIntl();
  const flags = useFlags();

  const dispatch = useAppDispatch();
  const { isAllowedToAddPalette } = usePermissions();
  const showPaymentModal = useUpgradeModal();
  const currentPalettes = useAppSelector(templatesSelectors.getSystemPalettes);
  const customPaletteOption: Palette | undefined = useAppSelector(
    templatesSelectors.getCustomPalette
  );
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const isCustomPaletteExist = useAppSelector(templatesSelectors.isCustomPaletteExist);
  const createPaletteStatus = useAppSelector((state) => state.templates.createPaletteStatus);

  const isCreateLoading = createPaletteStatus === fetchingStatus.loading;

  const allowCustomColorForAnyPlan = flags[FeatureFlag.allowCustomColorForAnyPlan];

  const onCreateCustomColors = () => {
    const upgradeText = intl.formatMessage(paymentModalMessages.upgradeColorsTitle);
    if (
      !allowCustomColorForAnyPlan &&
      !isAllowedToAddPalette &&
      showPaymentModal({
        source: drawerName,
        upgradeText,
        requestedFeature: PlanFeature.CustomColorPalette,
        targetPlan: PlanEnum.business
      })
    ) {
      return;
    }

    const samplePalette = currentPalettes.find((palette) => !palette.custom);
    if (samplePalette) {
      setCustom({ ...samplePalette, name: "Custom", id: undefined });
    }
  };

  const onEditCustom = () => {
    dispatch(
      analyticsEvents.editColorIntent({
        palette: customPaletteOption?.name as string,
        paletteId: customPaletteOption?.id as string
      })
    );
    setCustom(customPaletteOption);
  };

  const onFinishUpdateCustomPalette = async (colors: PaletteColor[]) => {
    setCustom(undefined);
    upsertCustom(colors);
  };

  const onCloseCustomPickerModal = () => {
    setCustom(undefined);
  };

  return (
    <>
      <ConditionalRender condition={!!custom}>
        <CustomColorPickerModal
          onFinish={onFinishUpdateCustomPalette}
          colors={(custom as Palette)?.colors}
          onClose={onCloseCustomPickerModal}
        />
      </ConditionalRender>

      <ConditionalRender condition={!!customPaletteOption}>
        <H1_FlexColumn width="100%">
          <H1_FlexRow align="center" justify="space-between">
            <H1_TextSmall>{intl.formatMessage(styleDrawerMessages.customTitle)}</H1_TextSmall>
            <StyledEditButton onClick={onEditCustom} type="link">
              {intl.formatMessage(styleDrawerMessages.editButton)}
            </StyledEditButton>
          </H1_FlexRow>
          <PaletteButton
            onSelectPalette={() => onSelectPalette(customPaletteOption as Palette)}
            paletteColors={customPaletteOption?.colors as PaletteColor[]}
            selected={currentDraft.palette_id === customPaletteOption?.id}
          />
        </H1_FlexColumn>
      </ConditionalRender>

      <ConditionalRender condition={!isCustomPaletteExist}>
        <GreyButton
          isLoading={isCreateLoading}
          onClick={onCreateCustomColors}
          startContent={
            !allowCustomColorForAnyPlan && !isAllowedToAddPalette ? <CrownIcon /> : undefined
          }
          fullWidth
        >
          {intl.formatMessage(styleDrawerMessages.addBrandColorsButton)}
        </GreyButton>
      </ConditionalRender>
    </>
  );
};

export default OwnPallete;
