import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextMiddle,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCinematicCharactersCenterShotType } from "app/store/selectorsV2/character.selectors";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import React, { useEffect, useMemo, useState } from "react";
import { charactersActions } from "app/store/slices/characters.slice";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import PodcastCharacterElement from "app/pages/HomePage/PodcastModal/PodcastCharacterElement";
import { Button } from "@nextui-org/react";
import { RecipeSetup, TalkPace } from "app/types";
import { ThemeMode } from "app/utils/theme";
import { SwiperSlide } from "swiper/react";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";

const GradientFlexColumn = styled(H1_FlexColumn)`
  background: linear-gradient(180deg, #1c1d21 2.5%, #222222 100%);
  border-radius: 12px;
`;
const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  padding-bottom: 5px;
  .swiper-slide {
    width: 164px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 74px;
    justify-content: center;
  }
  && .swiper-button-prev {
    left: 30px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }

  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }
`;

const GradientCoverFlexRow = styled(H1_FlexRow)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(24, 24, 27, 0.9) 90%);
`;

const CardFlexRow = styled(H1_FlexRow)<{ $border: boolean }>`
  border-radius: 10px;
  border: 2px solid
    ${({ theme, $border }) =>
      $border ? (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue3) : theme.gray4};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(24, 24, 27, 0.9) 100%);
  img {
    transition: filter 0.3s ease-in-out;
    object-fit: cover;
  }
  &:hover {
    img {
      filter: brightness(120%);
    }
  }
`;

const AbsoluteText = styled(H1_TextSmall)`
  position: absolute;
  bottom: 9px;
  left: 12px;
`;

const StyledButton = styled(Button)<{ $border: boolean }>`
  border: 2px solid
    ${({ $border, theme }) =>
      $border ? (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue3) : "transparent"};
`;
interface PodcastModalSetupProps {
  onClose: () => void;
  onFinish: (properties?: {
    participantHostId: string;
    participantId: string;
    setup: RecipeSetup;
    pace: TalkPace;
  }) => void;
}

const PodcastModalSetup = ({ onClose, onFinish }: PodcastModalSetupProps) => {
  const [participantHostId, setParticipantHostId] = useState<string>();
  const [participantId, setParticipantId] = useState<string>();
  const [pace, setPace] = useState<TalkPace>(TalkPace.Relaxed);
  const [setup, setSetup] = useState<RecipeSetup>(RecipeSetup.Interview);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const hasCharacters = useAppSelector(charactersGlobalSelectors.selectAll).length > 0;
  const characters = useAppSelector(getCinematicCharactersCenterShotType);
  const charactersStatus = useAppSelector((state) => state.characters.charactersStatus);
  const isLoadingCharacters = charactersStatus === fetchingStatus.loading;

  useEffect(() => {
    if (!hasCharacters) {
      dispatch(charactersActions.getCharactersListRequest());
    }
  }, [hasCharacters]);

  useEffect(() => {
    if (characters.length > 1 && !participantId && !participantHostId) {
      setParticipantHostId(characters[0].character_id);
      setParticipantId(characters[1].character_id);
    }
  }, [characters.length]);

  const onSelectAvatar = () => {
    setParticipantHostId(participantId);
    setParticipantId(participantHostId);
  };

  const onClickInterview = () => {
    setSetup(RecipeSetup.Interview);
  };

  const onClickPodcast = () => {
    setSetup(RecipeSetup.Podcast);
  };

  const onClickGenerateButton = () => {
    onFinish({
      participantHostId: participantHostId as string,
      participantId: participantId as string,
      setup,
      pace
    });
  };

  const setupCards: any[] = useMemo(() => {
    return [
      {
        isSelected: setup === RecipeSetup.Interview,
        text: "Interview",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/interview.webp"
            alt=""
          />
        ),
        onClick: onClickInterview
      },
      {
        isSelected: setup === RecipeSetup.Podcast,
        text: "Podcast",
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/podcast.webp"
            alt=""
          />
        ),
        onClick: onClickPodcast
      }
    ];
  }, [setup]);

  return (
    <H1_FlexColumn gap="20px">
      <H1_TextHeadline color={theme.gray7}>Edit style</H1_TextHeadline>
      <H1_TextMiddle color={theme.gray5}>
        Upload audio with male and female voice to create video podcast
      </H1_TextMiddle>
      <H1_FlexRow gap="15px">
        <GradientFlexColumn padding="10px" gap="7px" width="362px" height="332px" overflow="auto">
          <H1_TextSmall color={theme.gray5}>Select Avatars</H1_TextSmall>
          <ConditionalRender condition={isLoadingCharacters}>
            <CircleLoader />
          </ConditionalRender>
          <ConditionalRender condition={characters.length > 0}>
            <H1_FlexRow wrap="wrap" gap="14px">
              {characters.map((character) => (
                <PodcastCharacterElement
                  isParticipantHost={character.character_id === participantHostId}
                  isParticipant={character.character_id === participantId}
                  key={character.character_id}
                  id={character.id}
                  onSelectAvatar={onSelectAvatar}
                />
              ))}
            </H1_FlexRow>
          </ConditionalRender>
        </GradientFlexColumn>
        <GradientFlexColumn padding="10px" gap="7px" width="506px" height="332px" overflow="hidden">
          <H1_TextSmall color={theme.gray5}>Select Pace</H1_TextSmall>
          <H1_FlexRow gap="20px">
            <H1_FlexRow flex="1">
              <StyledButton
                $border={pace === TalkPace.Relaxed}
                fullWidth
                size="lg"
                onClick={() => setPace(TalkPace.Relaxed)}
              >
                Relaxed talk
              </StyledButton>
            </H1_FlexRow>
            <H1_FlexRow flex="1">
              <StyledButton
                $border={pace === TalkPace.Fast}
                fullWidth
                size="lg"
                onClick={() => setPace(TalkPace.Fast)}
              >
                Fast talk
              </StyledButton>
            </H1_FlexRow>
          </H1_FlexRow>
          <H1_TextSmall margin="20px 0 0 0" color={theme.gray5}>
            Select Setup
          </H1_TextSmall>
          <H1_FlexRow gap="10px" position="relative">
            <StyledSwiper
              speed={700}
              spaceBetween={28}
              slidesPerView="auto"
              slidesPerGroup={2}
              allowTouchMove={false}
            >
              {setupCards.map((setupCard, index: number) => (
                <SwiperSlide key={index}>
                  <CardFlexRow
                    overflow="hidden"
                    position="relative"
                    width="164px"
                    flex="0 0 164px"
                    height="161px"
                    onClick={setupCard.onClick}
                    $border={setupCard.isSelected}
                  >
                    {setupCard.image}
                    <GradientCoverFlexRow />
                    <AbsoluteText color="#D4D4D8">{setupCard.text}</AbsoluteText>
                  </CardFlexRow>
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </H1_FlexRow>
        </GradientFlexColumn>
      </H1_FlexRow>
      <H1_FlexRow justify="flex-end" gap="15px" padding="20px 20px 0 0">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={onClickGenerateButton}
          isDisabled={!participantId || !participantHostId}
        >
          Generate Podcast
        </Button>
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default PodcastModalSetup;
