import React, { useEffect, useMemo, useState } from "react";
import "app/components/projects-managment/projectsManagment.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Divider } from "antd";
import { useIntl } from "react-intl";
import styled from "styled-components";
import HourOneBreadcrumb, { BreadcrumbElement } from "app/components/common/HourOneBreadcrumb";
import usePermissions from "app/hooks/usePermissions";
import { WebhooksPageMessages } from "app/pages/webhooks/messages";
import WebhooksTable from "app/pages/webhooks/WebhooksTable";
import CreateWebhookModal from "app/pages/webhooks/CreateWebhookModal";
import { webhooksActions } from "app/store/slices/webhooks.slice";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { Button } from "@nextui-org/react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray1};
`;

const StyledHourOneBreadcrumb = styled(HourOneBreadcrumb)`
  && {
    padding-left: 0;
    font-size: 1rem;
  }
  &&&.ant-breadcrumb {
    a,
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme.gray7};
    }
    > :last-child a {
      color: ${(props) => props.theme.gray7};
    }
    .ant-breadcrumb-link {
      color: ${(props) => props.theme.gray9};
    }
    &&& .ant-breadcrumb-link:hover {
      a {
        color: ${(props) => props.theme.gray8};
      }
    }
  }
`;

const WebhookPage = () => {
  const [showCreateWebhookModal, setShowCreateWebhookModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const webhookStatus = useAppSelector((state) => state.webhooks.webhookStatus);
  const isLoading = webhookStatus === fetchingStatus.loading;
  const { isWriteWorkspaceAllow } = usePermissions();

  useEffect(() => {
    dispatch(webhooksActions.getWebhooksRequest());
  }, []);

  const breadcrumbs: BreadcrumbElement[] = useMemo(() => {
    const res: BreadcrumbElement[] = [
      {
        id: "webhooks",
        name: formatMessage(WebhooksPageMessages.title)
      }
    ];
    return res;
  }, []);

  const onCreateWebhook = () => {
    setShowCreateWebhookModal(true);
  };
  const onCloseCreateWebhook = () => {
    setShowCreateWebhookModal(false);
  };
  return (
    <BackgroundFlexColumn padding="1rem 0 1.875rem 1.875rem" flex="1">
      <ConditionalRender condition={showCreateWebhookModal}>
        <CreateWebhookModal visible={true} onClose={onCloseCreateWebhook} />
      </ConditionalRender>
      <H1_FlexColumn gap="2.125rem" flex="1">
        <H1_FlexRow
          height="1.375rem"
          flex="0 0 2.375rem"
          align="center"
          justify="space-between"
          gap="0.3125rem"
          padding="0 1.875rem 0 0"
        >
          <StyledHourOneBreadcrumb breadcrumbs={breadcrumbs} separator="/" />
          <H1_FlexRow align="center">
            <Divider type="vertical" />
            <Button
              disabled={!isWriteWorkspaceAllow}
              onClick={onCreateWebhook}
              color="primary"
              startContent={<i className="fa-regular fa-plus" />}
            >
              {formatMessage(WebhooksPageMessages.createWebhookLabel)}
            </Button>
          </H1_FlexRow>
        </H1_FlexRow>
        <H1_FlexColumn overflow="auto" flex="1 1 auto" padding="0 1.875rem 0 0">
          <ConditionalRender condition={isLoading}>
            <CircleLoader />
          </ConditionalRender>
          <ConditionalRender condition={!isLoading}>
            <WebhooksTable onCreateWebhook={onCreateWebhook} />
          </ConditionalRender>
        </H1_FlexColumn>
      </H1_FlexColumn>
    </BackgroundFlexColumn>
  );
};
export default WebhookPage;
