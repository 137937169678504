/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { paymentsActions } from "app/store/slices/payments.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FeatureFlag, PaymentFormFields, PaymentPeriod, PlanToUpgrade } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { usePaymentModal } from "app/pages/pricing/PricingPlans";
import { useForm } from "react-hook-form";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe
} from "@stripe/react-stripe-js";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled, { css, useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextMiddle,
  H1_TextMidHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { paymentModalV2CreditCardMessages } from "app/pages/pricing/PaymentModalV2/messages";
import PaymentModalV2CouponCode from "app/pages/pricing/PaymentModalV2/PaymentModalV2CouponCode";
import { format } from "date-fns/esm";
import { ReactComponent as PoweredByStripeLogo } from "app/assets/pricing/powered-by-stripe-logo.wine.svg";
import { privatePolicyUrl, serviceAgreementUrl } from "app/utils/urls";
import useCreditCard from "app/pages/pricing/useCreditCard";
import PaymentModalV2G2Recommendation from "app/pages/pricing/PaymentModalV2/PaymentModalV2G2Recommendation";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import useSmallScreen from "app/hooks/useSmallScreen";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button } from "@nextui-org/react";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { Tooltip } from "antd";

const StyledCircleLoader = styled(CircleLoader)`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 6;
`;
const BlueIcon = styled.i`
  color: ${(props) => props.theme.blue4};
`;

const TinyTextSmall = styled(H1_TextSmall)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    font-size: 11px;
  }
`;

const PageFlexRow = styled(H1_FlexRow)`
  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
    display: flex;
    .ant-spin-container {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
  @media (max-width: ${SMALL_SCREEN_PX}) {
    padding: 30px;
    width: 100%;
  }
`;

const WidthFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 74px 59px 42px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    padding: 30px;
    width: 100%;
  }
`;

const TextLink = styled(H1_TextXs)`
  cursor: pointer;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    font-size: 10px;
  }
`;

const WidthTextXs = styled(H1_TextXs)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
    font-size: 10px;
  }
`;

const StyledArrowLeftOutlined = styled.i`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 43px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.gray7};
  &:hover {
    color: ${({ theme }) => theme.gray6};
  }
`;

const ErrorFlexRow = styled(H1_FlexRow)`
  background-color: #f8d3d4;
  min-height: 32px;
`;

const ErrorText = styled(H1_TextXs)`
  min-height: 32px;
  align-items: center;
`;

const BorderedFlexRow = styled(H1_FlexRow)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.gray5};
  border-radius: 2px;
  box-sizing: border-box;
  height: 31px;
  min-height: 31px;
  position: relative;
  padding-left: 10px;
  padding-right: 5px;
  ${({ error }: { error?: boolean }) =>
    error &&
    css`
      border-color: ${(props) => props.theme.pink4};
    `};
`;

const NoBorderInput = styled.input`
  border: none;
  &::placeholder,
  ::placeholder {
    color: ${(props) => props.theme.gray7};
    font-weight: 400;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  }
  outline: none;
  width: 100%;
  color: ${(props) => props.theme.gray11};
`;

const StyledCardNumberElement = styled(CardNumberElement)`
  width: 100%;
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  width: calc(50% - 8px);
  position: absolute;
  left: calc(2em + 8px);
`;

const StyledCardCvcElement = styled(CardCvcElement)`
  width: calc(50% - 8px);
`;

const HolidayButton = styled.div`
  position: absolute;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
  width: 181px;
  height: 46px;
`;
const Holiday20Button = styled(HolidayButton)`
  bottom: 142px;
  right: 245px;
`;

const Holiday30Button = styled(HolidayButton)`
  bottom: 142px;
  right: 28px;
`;

export interface PricingModalPhaseCreditCardDetailsProps {
  onFinish: () => void;
  onBack?: () => void;
  visible: boolean;
}

const PaymentModalV2PhaseCreditCardDetails = ({
  onFinish,
  onBack,
  visible
}: PricingModalPhaseCreditCardDetailsProps) => {
  const dispatch = useAppDispatch();
  const { isSmallScreen } = useSmallScreen();
  const stripe = useStripe();
  const flags = useFlags();
  const { formatMessage } = useIntl();
  const { selectedPlan, paymentPeriod } = useAppSelector(
    (state) => state.payments.pricingModalProperties
  );
  const { totalYearlyPrice, monthlyPrice } = usePaymentModal({
    plan: selectedPlan as PlanToUpgrade
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: "onBlur"
  });
  const theme = useTheme();

  const {
    confirmCardPayment,
    setCardHolderName,
    setPostal,
    isStripeLoading,
    paymentErrorMessage,
    clearCreditCardStates,
    isFinishing
  } = useCreditCard({ visible });

  const createPaymentStatus = useAppSelector((state) => state.payments.createPaymentStatus);
  const executeSessionStatus = useAppSelector((state) => state.payments.executeSessionStatus);
  const createPaymentLoadingStatus = createPaymentStatus === fetchingStatus.loading;
  const setupStatus = useAppSelector((state) => state.payments.setupStatus);
  const setupStatusLoading = setupStatus === fetchingStatus.loading;
  /* executeSessionStatus succeeded is also part of the loading due to the race condition there that require the use of setTimeout */
  /* If not using it, the modal will suddenly not be disabled, and then will change to the Success Modal. */
  const afterPaymentLoading =
    executeSessionStatus === fetchingStatus.loading ||
    executeSessionStatus === fetchingStatus.succeeded;

  const allLoading = createPaymentLoadingStatus || isStripeLoading || afterPaymentLoading;
  const isYearlyPaymentType = paymentPeriod === PaymentPeriod.Yearly;
  const isPersonalPlan = selectedPlan === PlanToUpgrade.personal;
  const price = isYearlyPaymentType
    ? formatMessage(paymentModalV2CreditCardMessages.pricePerYear, {
        price: totalYearlyPrice
      })
    : formatMessage(paymentModalV2CreditCardMessages.pricePerMonth, {
        price: monthlyPrice
      });
  const currentTime = format(new Date(), "PP");

  useEffect(() => {
    dispatch(paymentsActions.setupRequest());
    return () => {
      clearCreditCardStates();
    };
  }, []);

  useEffect(() => {
    if (isFinishing) {
      onFinish();
    }
  }, [isFinishing]);

  const handlePostalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setPostal(inputValue);
    if (inputValue && errors.postal) {
      clearErrors(PaymentFormFields.postal);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setCardHolderName(inputValue);
    if (inputValue && errors.cardHolderName) {
      clearErrors(PaymentFormFields.cardHolderName);
    }
  };

  const onSubmit = async () => {
    await confirmCardPayment();
  };

  const onClickTerms = () => {
    const theWindow: WindowProxy | null = window.open(serviceAgreementUrl, "_blank");

    theWindow?.focus();
  };

  const onClickPrivacy = () => {
    const theWindow: WindowProxy | null = window.open(privatePolicyUrl, "_blank");

    theWindow?.focus();
  };

  const onClickHoliday20Button = () => {
    dispatch(paymentsActions.updatePricingModalProperties({ coupon: "SAVE20DEAL" }));
  };
  const onClickHoliday30Button = () => {
    dispatch(paymentsActions.updatePricingModalProperties({ coupon: "SAVE30DEAL" }));
  };

  const popoverContent = useMemo(() => {
    return (
      <H1_FlexColumn>
        <H1_TextSmall color="white" whiteSpace="break-spaces" fontWeight={500}>
          {formatMessage(paymentModalV2CreditCardMessages.yearly)}
        </H1_TextSmall>
        <H1_TextSmall color="white" whiteSpace="break-spaces">
          {formatMessage(paymentModalV2CreditCardMessages.yearlyPopover)}
        </H1_TextSmall>
        <br />
        <H1_TextSmall color="white" fontWeight={500} whiteSpace="break-spaces">
          {formatMessage(paymentModalV2CreditCardMessages.monthly)}
        </H1_TextSmall>
        <H1_TextSmall color="white" whiteSpace="break-spaces">
          {formatMessage(paymentModalV2CreditCardMessages.monthlyPopover)}
        </H1_TextSmall>
      </H1_FlexColumn>
    );
  }, [theme.mode]);

  return (
    <PageFlexRow width="100%" height="100%" flex="1 1 auto" position="relative">
      <ConditionalRender condition={allLoading}>
        <StyledCircleLoader />
      </ConditionalRender>
      <WidthFlexColumn width="569px" flex="1 0 auto" justify="space-between">
        <ConditionalRender condition={!!onBack}>
          <StyledArrowLeftOutlined className="fa-regular fa-chevron-left" onClick={onBack} />
        </ConditionalRender>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <H1_FlexColumn width="100%" gap="12px" flex="1 1 auto">
            {/* Payment Details Header */}
            <H1_FlexRow padding="0 0 30px 0" height="30px" align="center">
              <H1_TextMidHeadline color={theme.gray10}>
                {isPersonalPlan
                  ? formatMessage(paymentModalV2CreditCardMessages.subscribeToLite)
                  : formatMessage(paymentModalV2CreditCardMessages.subscribeToBusiness)}
              </H1_TextMidHeadline>
            </H1_FlexRow>
            <H1_FlexRow align="center" justify="space-between">
              <H1_FlexRow gap="10px" align="center">
                <BlueIcon className="fa-regular fa-check" />
                <TinyTextSmall color={theme.gray8}>
                  {formatMessage(paymentModalV2CreditCardMessages.cancellationNote)}
                </TinyTextSmall>
              </H1_FlexRow>
              <Tooltip title={popoverContent} placement="right" showArrow>
                <H1_Icon color={theme.gray8} icon="fa-regular fa-info-circle" />
              </Tooltip>
            </H1_FlexRow>
            {/* Error Message */}
            <ConditionalRender condition={!!paymentErrorMessage}>
              <ErrorFlexRow padding="0 0 0 12px" align="center">
                <ErrorText color={theme.gray11} whiteSpace="break-spaces">
                  {paymentErrorMessage}
                </ErrorText>
              </ErrorFlexRow>
            </ConditionalRender>
            {/* Cardholder Name */}
            <BorderedFlexRow align="center" error={!!errors.cardHolderName}>
              <NoBorderInput
                {...register(PaymentFormFields.cardHolderName, { required: true })}
                placeholder={formatMessage(
                  paymentModalV2CreditCardMessages.cardholderNamePlaceholder
                )}
                onChange={handleNameChange}
              />
            </BorderedFlexRow>
            {/* Card Number */}
            <BorderedFlexRow align="center" gap="10px">
              <StyledCardNumberElement
                options={{
                  showIcon: true,
                  style: { base: { color: theme.gray11 } }
                }}
              />
            </BorderedFlexRow>
            {/* Card Expiry && Card CVC */}
            <H1_FlexRow gap="16px">
              <BorderedFlexRow align="center" flex="1" gap="10px">
                <i className="fal fa-calendar" />
                <StyledCardExpiryElement
                  options={{
                    style: { base: { color: theme.gray11 } }
                  }}
                />
              </BorderedFlexRow>
              <BorderedFlexRow align="center" flex="1" gap="10px">
                <i className="fal fa-calendar" />
                <StyledCardCvcElement
                  options={{
                    style: { base: { color: theme.gray11 } }
                  }}
                />
              </BorderedFlexRow>
            </H1_FlexRow>
            {/* Postal Code */}
            <BorderedFlexRow align="center" error={!!errors.postal}>
              <NoBorderInput
                {...register(PaymentFormFields.postal, { required: false })}
                placeholder={formatMessage(paymentModalV2CreditCardMessages.postalCodePlaceholder)}
                onChange={handlePostalChange}
              />
            </BorderedFlexRow>

            {/* Coupon code */}
            <PaymentModalV2CouponCode />
          </H1_FlexColumn>
          <H1_FlexColumn gap="18px" flex="0 0 auto">
            <H1_FlexRow justify="space-between">
              <H1_FlexColumn>
                <H1_TextSmall color={theme.gray10}>
                  {formatMessage(paymentModalV2CreditCardMessages.currentTime, {
                    time: currentTime
                  })}
                </H1_TextSmall>
                <H1_TextSmall color={theme.gray7}>
                  {isYearlyPaymentType
                    ? formatMessage(paymentModalV2CreditCardMessages.billedAnnually)
                    : formatMessage(paymentModalV2CreditCardMessages.billedMonthly)}
                </H1_TextSmall>
              </H1_FlexColumn>
              <H1_TextMiddle color={theme.blue4}>{price}</H1_TextMiddle>
            </H1_FlexRow>
            <Button
              color="primary"
              fullWidth
              onClick={onSubmit}
              isLoading={allLoading}
              isDisabled={!stripe || allLoading || setupStatusLoading}
            >
              {formatMessage(paymentModalV2CreditCardMessages.upgradeNow)}
            </Button>
            <H1_FlexColumn>
              <WidthTextXs color={theme.gray10}>
                {/* @ts-ignore  handels intl formatting with elements issue */}
                {formatMessage(paymentModalV2CreditCardMessages.agreeToTerms, {
                  span: (str) => (
                    <TextLink color={theme.gray10} underline onClick={onClickTerms}>
                      {str}
                    </TextLink>
                  )
                })}
              </WidthTextXs>
              <TextLink
                color={theme.gray10}
                underline
                onClick={onClickPrivacy}
                whiteSpace="break-changes"
              >
                {formatMessage(paymentModalV2CreditCardMessages.privacyPolicy)}
              </TextLink>
              <H1_FlexRow padding="40px 0 0 0" justify="flex-end">
                <PoweredByStripeLogo />
              </H1_FlexRow>
            </H1_FlexColumn>
          </H1_FlexColumn>
        </StyledForm>
      </WidthFlexColumn>
      <ConditionalRender condition={!isSmallScreen}>
        <ConditionalRender condition={flags[FeatureFlag.responsiveBannerCode]}>
          <H1_FlexRow position="relative">
            <img
              src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/kashier-save-deal.webp"
              alt="january"
            />
            <Holiday20Button onClick={onClickHoliday20Button} />
            <Holiday30Button onClick={onClickHoliday30Button} />
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={!flags[FeatureFlag.responsiveBannerCode]}>
          <PaymentModalV2G2Recommendation />
        </ConditionalRender>
      </ConditionalRender>
    </PageFlexRow>
  );
};

export default PaymentModalV2PhaseCreditCardDetails;
