import { useContext } from "react";
import { VideoProtectionContext } from "app/components/VideoShareProtection/ProtectionContext";

const useVideoShareProtection = () => {
  const context = useContext(VideoProtectionContext);
  if (!context) {
    throw new Error("useVideoProtection must be used within an VideoProtectionProvider");
  }
  return context;
};

export default useVideoShareProtection;
