import React, { useMemo } from "react";
import { useAppSelector } from "app/hooks";
import { Video, VideoStatusEnum } from "app/types";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { getVideoByDataStoreContentId } from "app/store/selectorsV2/videos.selectors";
import { useTheme } from "styled-components";

interface WorkflowTableActionIconInterface {
  dataStoreContentId: string;
  isLoading: boolean;
}

const WorkflowTableActionIcon = ({
  dataStoreContentId,
  isLoading
}: WorkflowTableActionIconInterface) => {
  const theme = useTheme();
  const currentVideo: Video | undefined = useAppSelector((state) =>
    getVideoByDataStoreContentId(state, dataStoreContentId)
  );

  const icon = useMemo(() => {
    if (!currentVideo) {
      return "far fa-rocket";
    }
    if (isLoading) {
      return "far fa-spinner-third fa-spin";
    }
    switch (currentVideo.status) {
      case VideoStatusEnum.NotStarted:
        return "far fa-spinner-third fa-spin";
      case VideoStatusEnum.Published:
      case VideoStatusEnum.Ready:
        return "far fa-rotate-right";
      case VideoStatusEnum.Failed:
        return "far fa-rocket";
      case VideoStatusEnum.Processing:
        return "far fa-spinner-third fa-spin";
      default:
        return "far fa-rocket";
    }
  }, [currentVideo?.status]);

  return <H1_Icon icon={icon} color={theme.blue2} isCursorPointer />;
};

export default WorkflowTableActionIcon;
