import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { getFonts } from "app/services/serviceV2Apis";

const prefix = "[Font]";

const getFontsRequest = createAsyncThunk<any, string | undefined>(
  `${prefix} getFontsRequest`,
  async (templateId) => {
    const result = await getFonts(templateId);
    return result;
  },
  thunkOptions
);

export default {
  getFontsRequest
};
