import * as PropTypes from "prop-types";
import CircleLoader from "app/components/common/Loaders/CircleLoader";

const Loader = ({ style }) => (
  <CircleLoader style={{ display: "block", margin: "auto", ...style }} width={80} height={80} />
);

Loader.prototypes = {
  style: PropTypes.object
};

Loader.defaultProps = {
  style: null
};

export default Loader;
