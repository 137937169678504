import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApi } from "app/types";
import { createBriefs, deleteBriefs, getBriefs } from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { Brief, BriefRequest } from "app/types/brief";

const prefix = "[Briefs]";

const createBriefsRequest = createAsyncThunk<any, { briefs: BriefRequest }, ThunkApi>(
  `${prefix} createBriefsRequest`,
  async ({ briefs }) => {
    const result = await createBriefs(briefs);
    return result;
  },
  thunkOptions
);

const getBriefsRequest = createAsyncThunk<Brief[], void, ThunkApi>(
  `${prefix} getBriefsRequest`,
  async () => {
    return await getBriefs();
  },
  thunkOptions
);

const deleteBriefRequest = createAsyncThunk<any, { briefId: string }, ThunkApi>(
  `${prefix} deleteBriefRequest`,
  async ({ briefId }) => {
    const result = await deleteBriefs(briefId);
    return result;
  },
  thunkOptions
);

export default {
  createBriefsRequest,
  getBriefsRequest,
  deleteBriefRequest
};
