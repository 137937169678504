const createHex = () => {
  const hexValues = "0123456789abcdef";
  return [...Array(6)].reduce((previousValue) => {
    const nexVal = previousValue + hexValues.charAt(Math.floor(Math.random() * hexValues.length));
    return nexVal;
  }, "");
};

export const gradientGenerate = () => {
  const deg = Math.floor(Math.random() * 360);

  const gradient = `linear-gradient(${deg}deg, #${createHex()}, #${createHex()})`;

  return gradient;
};
