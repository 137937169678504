import { defineMessages } from "react-intl";

export const sceneMessages = defineMessages({
  scriptWizard: {
    id: "scene.actions.script-wizard",
    defaultMessage: "Script Wizard"
  },
  useVoice: {
    id: "scene.actions.use-voice",
    defaultMessage: "Add audio script"
  },
  listenButton: {
    id: "scene.actions.listen-button",
    defaultMessage: "Listen"
  },
  previewTooltip: {
    id: "scene.preview.preview-tooltip",
    defaultMessage: "Preview"
  },
  layoutTooltip: {
    id: "scene.preview.layout-tooltip",
    defaultMessage: "Layout"
  },
  contentTooltip: {
    id: "scene.preview.content-tooltip",
    defaultMessage: "Content"
  },
  deleteSceneTooltip: {
    id: "scene.preview.delete-scene-tooltip",
    defaultMessage: "Delete"
  },
  duplicateSceneTooltip: {
    id: "scene.preview.dupliacte-scene-tooltip",
    defaultMessage: "Duplicate"
  },
  changeLayoutTooltip: {
    id: "scene.preview.change-layout-tooltip",
    defaultMessage: "Change layout"
  },
  charactersLeft: {
    id: "scene.actions.characters-left",
    defaultMessage: "Characters Left"
  },
  variablesTooltip: {
    id: "scene.actions.variables",
    defaultMessage: "Variables"
  },
  timeEst: {
    id: "scene.actions.time-est",
    defaultMessage: "Time Estimation"
  },
  globalPronunciationTooltip: {
    id: "scene.global-pronunciation.tooltip",
    defaultMessage: "Add global pronunciation"
  },
  globalPronunciationWritten: {
    id: "scene.global-pronunciation.written",
    defaultMessage: "Written"
  },
  globalPronunciationPronounced: {
    id: "scene.global-pronunciation.pronounced",
    defaultMessage: "Pronounced"
  },
  globalPronunciationEmail: {
    id: "scene.global-pronunciation.email",
    defaultMessage: "Email"
  },
  addGlobalPronunciation: {
    id: "scene.global-pronunciation.add",
    defaultMessage: "Add Pronunciation"
  }
});
