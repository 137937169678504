/* eslint react/prop-types: 0 */
import React from "react";

import MuxPlayerElement from "@mux/mux-player";
// import MuxPlayer from "@mux/mux-player-react/dist/lazy.mjs";
import MuxPlayer from "@mux/mux-player-react/lazy";
import { H1MuxPlayerInnerProps } from "./types";

export const LazyH1MuxPlayer = React.forwardRef<MuxPlayerElement, H1MuxPlayerInnerProps>(
  (
    {
      playbackId,
      metadata,
      muxEnvKey,
      placeholder,
      muxDebug = false,
      autoPlay = false,
      loop = false,
      muted = false,
      defaultShowCaptions = false,
      thumbnailTime = 0,
      ...rest
    },
    ref
  ) => {
    const modifiedRest = { ...rest };
    delete modifiedRest.children;
    return (
      <>
        <MuxPlayer
          {...modifiedRest}
          ref={ref}
          loading="viewport"
          playbackId={playbackId}
          envKey={muxEnvKey}
          muted={muted}
          debug={muxDebug}
          autoPlay={autoPlay}
          defaultHiddenCaptions={!defaultShowCaptions}
          metadata={metadata}
          loop={loop}
          placeholder={placeholder}
          thumbnailTime={thumbnailTime}
          streamType="on-demand"
          primaryColor="white"
          secondaryColor="rgb(0 0 0 / 40%)"
        />
      </>
    );
  }
);

LazyH1MuxPlayer.displayName = "LazyH1MuxPlayer";
