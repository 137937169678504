import styled, { useTheme } from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const GoBackIconButton = styled(Button)<{ $color: string }>`
  align-self: flex-start;
  margin-right: 10px;
  display: inline-flex;
  i {
    color: ${({ $color }) => $color};
  }
`;

const messages = defineMessages({
  backButton: {
    id: `general.back-button`,
    defaultMessage: `Back`
  }
});

export interface GoBackButtonProps {
  onClick: () => void;
  fontSize: string;
  className?: string;
  label?: string;
  color?: string;
  fontFamily?: string;
  backgroundColor?: string;
}

const GoBackButton = ({ onClick, fontSize, className, label, color }: GoBackButtonProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  return (
    <GoBackIconButton
      variant="light"
      onClick={onClick}
      startContent={<i className="far fa-arrow-left" />}
      $color={color ?? theme.gray11}
      className={className}
    >
      <H1_TextSmall fontSize={fontSize} color={color ?? theme.gray11}>
        {label ?? formatMessage(messages.backButton)}
      </H1_TextSmall>
    </GoBackIconButton>
  );
};

export default GoBackButton;
