import { useAppDispatch } from "app/hooks";
import { ConfirmModalState, uiActionsV2 } from "app/store/slices/uiV2.slice";

const useConfirmModal = () => {
  const dispatch = useAppDispatch();

  const openConfirmModal = (props: Omit<ConfirmModalState, "visible">) => {
    dispatch(uiActionsV2.setConfirmModal({ visible: true, ...props }));
  };

  const closeConfirmModal = () => {
    dispatch(uiActionsV2.setConfirmModal({ visible: false }));
  };

  return { openConfirmModal, closeConfirmModal };
};

export default useConfirmModal;
