"use client";

import React from "react";
import { cn } from "app/components/LeftNav/cn";

import CellWrapper from "./CellWrapper";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import AvatarInitials from "app/components/common/AvatarInitials";

export type UserCellProps = React.HTMLAttributes<HTMLDivElement> & {
  avatar: string;
  name: string;
};

const UserCell = React.forwardRef<HTMLDivElement, UserCellProps>(
  ({ avatar, name, className, ...props }, ref) => (
    <CellWrapper ref={ref} className={cn("bg-transparent px-3 py-1", className)} {...props}>
      <H1_FlexRow align="center" gap="8px">
        <AvatarInitials size="sm" src={avatar} text={name || ""} />
        <H1_TextSmall>{name}</H1_TextSmall>
      </H1_FlexRow>
    </CellWrapper>
  )
);

UserCell.displayName = "UserCell";

export default UserCell;
