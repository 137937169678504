import { MessageDescriptor, useIntl } from "react-intl";

import { pricingPageMessages, pricingPlansMessages } from "app/pages/pricing/messages";

const mapping: Record<string, MessageDescriptor> = {
  basic: pricingPlansMessages.planBasicDisplayName,
  personal: pricingPageMessages.personalTitle,
  enterprise: pricingPageMessages.enterpriseTitle,
  business: pricingPageMessages.businessTitle
};

const usePlansConverter = () => {
  const { formatMessage } = useIntl();

  return (plan: string) => {
    if (mapping[plan]) {
      return formatMessage(mapping[plan]);
    }
    return plan;
  };
};

export default usePlansConverter;
