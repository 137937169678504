import { defineMessages } from "react-intl";

export const transcriptAudioUploaderMessages = defineMessages({
  modalHeader: {
    id: `transcript-audio-upload.modal.modal-header`,
    defaultMessage: "Add audio script"
  },
  modalDescription: {
    id: `transcript-audio-upload.modal.modal-description`,
    defaultMessage:
      "Upload a recording of your script in voice.{br}You can use only .wav files up to {MAX_TIME_IN_SEC} seconds."
  },
  modalButtonImport: {
    id: `transcript-audio-upload.modal.import-button`,
    defaultMessage: "Use audio"
  },
  modalButtonCancel: {
    id: `transcript-audio-upload.modal.cancel-button`,
    defaultMessage: "Cancel"
  },
  lengthValidationError: {
    id: `transcript-audio-upload.modal.length-validation-error`,
    defaultMessage: "Voice recording is restricted to {maxTimeInSec} seconds, please change record."
  },
  uploadFileButtonTitle: {
    id: `transcript-audio-upload.modal.upload-file.title`,
    defaultMessage: "Upload audio file{br}or drag and drop"
  },
  uploadFileButtonDescription: {
    id: `transcript-audio-upload.modal.upload-file.description`,
    defaultMessage: ".wav/.mp4 up to 50MB each"
  },
  recordButtonDescription: {
    id: `transcript-audio-upload.modal.record.description`,
    defaultMessage: "Record audio up to {maxTime}s"
  },
  recordButtonTitle: {
    id: `transcript-audio-upload.modal.record.title`,
    defaultMessage: "Record Audio"
  }
});
