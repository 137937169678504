import React, { ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { noop } from "lodash-es";

import { message, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { Draft, FeatureFlag, SynthesisMarkupLanguageType } from "app/types";
import { draftsActions } from "app/store/slices/drafts.slice";
import { executeDownload, fetchingStatus } from "app/utils/helpers";
import usePermissions from "app/hooks/usePermissions";
import styled, { useTheme } from "styled-components";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAuth } from "app/auth/useAuth";
import { draftPageMessages } from "app/pages/EditDraftPage/messages";
import { scenesActions } from "app/store/slices/scenes.slice";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import useCopy from "app/hooks/useCopy";
import usePrintScenes from "app/hooks/usePrintScenes";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { Button } from "@nextui-org/react";
import useConfirmModal from "app/hooks/useConfirmModal";
import { ThemeMode } from "app/utils/theme";

const StyledIcon = styled.i`
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  margin: auto;
  color: ${({ theme }) => theme.gray7};
  &:hover {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.gray9)};
  }
`;

const LoadingSpin = styled(Spin)`
  color: ${(props) => props.theme.pink4};
  float: left;
`;

const messages = defineMessages({
  approveDeleteProjectTitle: {
    id: `draft-dropdown-actions.project-delete-approval.title`,
    defaultMessage: "Delete video"
  },
  approveDeleteProjectContent: {
    id: `draft-dropdown-actions.project-delete-approval.message`,
    defaultMessage: "Are you sure you want to delete this video?"
  },
  editProjectMetaTitle: {
    id: `draft-dropdown-actions.dropdown.popup.title`,
    defaultMessage: "Edit video meta"
  },
  editProjectMetaFormNameLabel: {
    id: `draft-dropdown-actions.dropdown.popup.form.title.label`,
    defaultMessage: "Folder title"
  },
  editProjectMetaFormDescLabel: {
    id: `draft-dropdown-actions.dropdown.popup.form.description.label`,
    defaultMessage: "Description"
  },
  editProjectMetaDropdownDeleteButton: {
    id: `draft-dropdown-actions.dropdown.delete.button`,
    defaultMessage: "Delete"
  },
  editProjectMetaDropdownDuplicteButton: {
    id: `draft-dropdown-actions.dropdown.duplicate.button`,
    defaultMessage: "Duplicate"
  },
  editProjectMetaDropdownMoveToFolderButton: {
    id: `draft-dropdown-actions.dropdown.move-to-folder.button`,
    defaultMessage: "Move to folder"
  },
  editProjectMetaDropdownSaveAsTemplateButton: {
    id: `draft-dropdown-actions.dropdown.save-draft-as-template.button`,
    defaultMessage: "Save as template"
  },
  editProjectMetaDropdownRenameButton: {
    id: `draft-dropdown-actions.dropdown.rename.button`,
    defaultMessage: "Rename"
  },
  copyCurlExample: {
    id: `draft-dropdown-actions.dropdown.copy-curl.button`,
    defaultMessage: "Copy api curl"
  },
  editProjectMetaDropdownCopyLinkButton: {
    id: `draft-dropdown-actions.dropdown.copy-link.button`,
    defaultMessage: "Copy link"
  },
  editProjectMetaDropdownOpenDraftButton: {
    id: `draft-dropdown-actions.dropdown.open-draft.button`,
    defaultMessage: "Edit"
  },
  editProjectMetaDropdownDownloadButton: {
    id: `draft-dropdown-actions.dropdown.download.button`,
    defaultMessage: "Download"
  },
  editProjectMetaDropdownEmbedButton: {
    id: `draft-dropdown-actions.dropdown.embed.button`,
    defaultMessage: "Embed"
  },
  editProjectNewTemplateCreated: {
    id: `draft-dropdown-actions.dropdown.new-template.created`,
    defaultMessage: "New template created!"
  },
  editProjectMetaDropdownDuplicateNotification: {
    id: `draft-dropdown-actions.dropdown.notifications.duplicate-v2`,
    defaultMessage: "Video duplicate in progress"
  },
  editProjectMetaDropdownEditButton: {
    id: `draft-dropdown-actions.dropdown.meta-edit.button`,
    defaultMessage: "Rename"
  },
  editProjectMetaDropdownEditCsv: {
    id: "draft-dropdown-actions.dropdown.edit-db.button",
    defaultMessage: "Edit database"
  },
  editProjectMetaDropdownDangerZoneLabel: {
    id: "draft-dropdown-actions.dropdown.danger.label",
    defaultMessage: "Danger Zone"
  },
  editProjectMetaDropdownDownloadSubtitlesLabel: {
    id: "draft-dropdown-actions.dropdown.download-subtitles.label",
    defaultMessage: "Export Subtitles"
  }
});

interface DropdownProjectMetaEditProps {
  className?: string;
  draft: Draft;
  onRenameDraft?: () => void;
  showCopyLink?: boolean;
  showDownload?: boolean;
  showEmbed?: boolean;
  onOpenDraft?: () => void;
  showDownloadSubtitles?: boolean;
  showScriptActions?: boolean;
  downloadSubtitles?: () => void;
  withMoveToFolder?: boolean;
  duplicateNotification?: string | ReactNode;
}

const DraftDropdownActions = ({
  className = "draft-dropdown-actions-",
  draft,
  onRenameDraft,
  duplicateNotification
}: DropdownProjectMetaEditProps) => {
  const intl = useIntl();
  const flags = useFlags();
  const { user } = useAuth();
  const { getFullScriptOfAllScenes } = usePrintScenes();
  const theme = useTheme();
  const { triggerCopy } = useCopy();
  const dispatch = useAppDispatch();
  const {
    isAllowedToDeleteDraft,
    isAllowedToDuplicateDraft,
    isAllowCreateApiCurl,
    isWriteWorkspaceAllow
  } = usePermissions();
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();
  const deleteStatus = useAppSelector((state) => state.drafts.deleteDraftStatus);
  const deletedDraftId = useAppSelector((state) => state.drafts.deletedDraftId);
  const duplicationStatus = useAppSelector((state) => state.drafts.duplicationStatus);
  const duplicatedDraftId = useAppSelector((state) => state.drafts.duplicatedDraftId);
  const scenesIds = useAppSelector(scenesGlobalSelectors.selectIds);
  const footerLoading = useAppSelector((state) => state.voices.footerLoading);
  const createVoiceStatus = useAppSelector((state) => state.voices.createVoiceStatus);
  const audioLoading = footerLoading || createVoiceStatus === fetchingStatus.loading;
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);

  const draftId: string = draft?.id as string;
  const loading =
    (duplicationStatus === fetchingStatus.loading && duplicatedDraftId === draftId) ||
    (deleteStatus === fetchingStatus.loading && deletedDraftId === draftId);

  const openDeleteModal = () => {
    return openConfirmModal({
      onCancel: closeConfirmModal,
      title: intl.formatMessage(messages.approveDeleteProjectTitle),
      content: intl.formatMessage(messages.approveDeleteProjectContent),
      onConfirm: () => {
        dispatch(draftsActions.deleteDraftRequest(draftId));
      }
    });
  };

  const onDuplicate = () => {
    message.info(
      duplicateNotification ||
        intl.formatMessage(messages.editProjectMetaDropdownDuplicateNotification)
    );
    dispatch(draftsActions.duplicateDraftsRequest({ draftId }));
  };

  const onDownloadAudio = () => {
    if (audioLoading) {
      return;
    }

    if (footerAudioPlayer.url) {
      executeDownload(footerAudioPlayer.url);
    }
  };

  const onClickCopyScriptToClipboard = () => {
    const output = getFullScriptOfAllScenes();

    triggerCopy({ copyContent: output, shouldNotify: false });
    message.success("Copied to clipboard");
    dispatch(analyticsEvents.copyScript());
  };
  const onClickClearScenes = () => {
    openConfirmModal({
      onCancel: closeConfirmModal,
      title: intl.formatMessage(draftPageMessages.clearDraftTitle),
      content: intl.formatMessage(draftPageMessages.clearDraftDescription),
      onConfirm() {
        dispatch(analyticsEvents.clearScenes());
        dispatch(draftsActions.clearScenesRequest(draftId as string));
        dispatch(
          scenesActions.updateOutsideChangeSynthesisMarkupLanguage({
            [scenesIds[0] as string]: [
              { text: "", version: 1, type: SynthesisMarkupLanguageType.text }
            ]
          })
        );
      }
    });
  };

  const onSaveAsRecipe = () => {
    dispatch(
      analyticsEvents.saveAsRecipe({
        draftId,
        title: draft.title as string,
        description: draft.description
      })
    );
    dispatch(
      draftsActions.saveAsRecipeRequest({
        owner: user?.sub as string,
        draftId,
        title: draft.title as string,
        description: draft.description
      })
    );
  };

  const onCopyApiExample = () => {
    if (isAllowCreateApiCurl) {
      dispatch(draftsActions.createApiVideoExampleRequest({ draftId, type: "create" }));
    } else {
      dispatch(draftsActions.createApiVideoExampleRequest({ draftId, type: "override" }));
    }
  };
  const menu = {
    items: [
      {
        key: "rename-project",
        startContent: <i className="far fa-pen" />,
        onClick: onRenameDraft,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownRenameButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow,
        showDivider: true
      },
      isAllowCreateApiCurl && {
        key: "copy-api-example",
        startContent: <i className="far fa-server" />,
        onClick: onCopyApiExample,
        label: <H1_TextSmall>{intl.formatMessage(messages.copyCurlExample)}</H1_TextSmall>,
        showDivider: true
      },
      {
        className: "script-actions-menu clickable",
        key: "clear-all-scenes",
        startContent: <i className="fa-regular fa-eraser" />,
        onClick: onClickClearScenes,
        label: <H1_TextSmall>{intl.formatMessage(draftPageMessages.clearDraftScenes)}</H1_TextSmall>
      },
      {
        className: "script-actions-menu clickable",
        key: "print-script",
        startContent: <i className="fa-regular fa-copy" />,
        onClick: onClickCopyScriptToClipboard,
        label: (
          <H1_TextSmall>{intl.formatMessage(draftPageMessages.copyScriptToClipboard)}</H1_TextSmall>
        )
      },
      footerAudioPlayer.url &&
        flags[FeatureFlag.downloadAudioTranscript] && {
          className: "script-actions-menu clickable",
          key: "download-transcript",
          startContent: <i className="fa-regular fa-volume" />,
          onClick: onDownloadAudio,
          label: <H1_TextSmall>{intl.formatMessage(draftPageMessages.downloadAudio)}</H1_TextSmall>
        },
      flags[FeatureFlag.personalTemplates] && {
        key: "save-as-recipe",
        startContent: <i className="fa-regular fa-table-layout" />,
        onClick: onSaveAsRecipe,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownSaveAsTemplateButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow,
        showDivider: isAllowedToDuplicateDraft
      },
      isAllowedToDuplicateDraft && {
        key: "duplicate-project",
        startContent: <i className="far fa-copy" />,
        onClick: onDuplicate,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownDuplicteButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow
      },
      isAllowedToDeleteDraft && {
        className: "danger-zone",
        danger: true,
        key: "delete-project",
        startContent: <H1_Icon className="text-pink-4" icon="far fa-trash" />,
        onClick: openDeleteModal,
        label: (
          <H1_TextSmall color={theme.pink4}>
            {intl.formatMessage(messages.editProjectMetaDropdownDeleteButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow
      }
    ]
  };

  if (loading) {
    return (
      <Row className="align-i-left draft-actions-menu" key="drafts-actions">
        <LoadingSpin indicator={<LoadingOutlined spin />} />
      </Row>
    );
  }

  return (
    <NextDropdown
      withWhiteBackground
      items={menu.items}
      key="actions-menu"
      className={className + " overlay-dropdown-menu"}
    >
      <Button
        isIconOnly
        startContent={<StyledIcon className="far fa-ellipsis-h" />}
        variant="light"
        onClick={(e) => e.stopPropagation()}
      />
    </NextDropdown>
  );
};

export default DraftDropdownActions;

DraftDropdownActions.defaultProps = {
  onDeleteDraft: noop,
  onRenameDraft: noop,
  dropdownExtensions: null,
  showCopyLink: false,
  showDownload: false,
  showDownloadSubtitles: false,
  showEmbed: false,
  onOpenDraft: undefined
};
