import { ReactElement, useMemo } from "react";
import { Breadcrumb, Spin } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { ThemeMode } from "app/utils/theme";

const StyledLoadingOutlined = styled(LoadingOutlined)`
  margin-right: 10px;
`;
export const StyledBreadcrumb = styled(Breadcrumb)`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  font-size: 14px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-weight: 400 !important;
  padding-left: 25px;
  &.ant-breadcrumb {
    a,
    .ant-breadcrumb-separator {
      color: ${(props) =>
        props.theme.mode === ThemeMode.Light ? props.theme.gray7 : props.theme.gray5};
    }
    > :last-child a {
      color: ${(props) =>
        props.theme.mode === ThemeMode.Light ? props.theme.gray7 : props.theme.gray5};
    }
    .ant-breadcrumb-link {
      color: ${(props) => props.theme.gray11};
    }
    &&& .ant-breadcrumb-link:hover {
      a {
        color: ${(props) => (ThemeMode.Light ? props.theme.gray8 : props.theme.gray7)};
      }
    }
  }
`;

export type BreadcrumbElement = {
  id: string;
  name: string;
  isLoading?: boolean;
  url?: string;
};

export interface BreadcrumbElementProps {
  breadcrumbs: BreadcrumbElement[];
  className?: string;
  editableItems?: Record<string, boolean>;
  editableActive?: ReactElement;
  separator?: string;
  onRenameBreadcrumb?: (id: string) => void;
}

const BREADCRUMB_SEPARATOR = ">";

const HourOneBreadcrumb = ({
  breadcrumbs,
  className,
  editableActive,
  separator = BREADCRUMB_SEPARATOR,
  editableItems = {},
  onRenameBreadcrumb
}: BreadcrumbElementProps) => {
  const extraBreadcrumbItems = useMemo(() => {
    const onClickBreadcrumb = (id: string, isLoading?: boolean) => {
      if (!isLoading && Object.keys(editableItems).includes(id) && onRenameBreadcrumb) {
        return onRenameBreadcrumb(id);
      }
    };
    const breadcrumbsContent = ({ name, url, isLoading, id }: BreadcrumbElement) => {
      if (isLoading) {
        return <Spin indicator={<StyledLoadingOutlined spin />} />;
      }
      if (!isLoading && Object.keys(editableItems).includes(id) && editableItems[id]) {
        return editableActive;
      }
      if (url) {
        return <Link to={url as string}>{name}</Link>;
      }

      return name;
    };
    return breadcrumbs.map(({ name, url, isLoading, id }: BreadcrumbElement) => (
      <Breadcrumb.Item key={id} onClick={() => onClickBreadcrumb(id, isLoading)}>
        {breadcrumbsContent({ name, url, isLoading, id })}
      </Breadcrumb.Item>
    ));
  }, [breadcrumbs, onRenameBreadcrumb]);
  return (
    <StyledBreadcrumb separator={separator} className={className}>
      {extraBreadcrumbItems}
    </StyledBreadcrumb>
  );
};

export default HourOneBreadcrumb;
