import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { getTextOutOfSML } from "app/utils/helpers";

const usePrintScenes = () => {
  const dispatch = useAppDispatch();
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);

  const getFullScriptOfAllScenes = () => {
    let text = "";
    const sceneLength = scenes.length;
    scenes.forEach((scene, index) => {
      const sml = scene.attributes.text?.transcript?.synthesis_markup_language;
      text += `Scene ${index + 1}`;
      text += "\n";
      text += sml ? getTextOutOfSML(sml.nodes) : scene.attributes.text?.transcript?.text;
      if (index !== sceneLength - 1) {
        text += "\n\n";
      }
    });

    return text;
  };

  const printText = (text: string) => {
    dispatch(analyticsEvents.printScenes());
    const style = `
            <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 20px;
                line-height: 1.6;
                color: #333;
                white-space: break-spaces;
            }
            </style>
        `;

    const printWindow = window.open("", "_blank");
    printWindow?.document.write(`<html><head>${style}</head><body>${text}</body></html>`);
    printWindow?.document.close();
    printWindow?.focus();
    printWindow?.print();
    printWindow?.close();
  };

  return { printText, getFullScriptOfAllScenes };
};

export default usePrintScenes;
