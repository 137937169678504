import { defineMessages } from "react-intl";

export const videoProgressMessages = defineMessages({
  export: {
    id: "video-progress.export",
    defaultMessage: "Export"
  },
  restore: {
    id: "video-progress.restore",
    defaultMessage: "Restore"
  },
  canceled: {
    id: "video-progress.canceled",
    defaultMessage: "Canceled"
  }
});
export const embedMessages = defineMessages({
  embedHeadline: {
    id: `embed.embed-headline`,
    defaultMessage: `Embed`
  },
  pictureInPicture: {
    id: `embed.picture-in-picture`,
    defaultMessage: `Picture in picture`
  },
  autoplay: {
    id: `embed.autoplay`,
    defaultMessage: `Autoplay`
  },
  circleVideo: {
    id: `embed.circle-video`,
    defaultMessage: `Circle video`
  },
  copyEmbeddedCode: {
    id: `embed.copy-embedded-code`,
    defaultMessage: `Copy Embedded Code`
  },
  allowFullScreen: {
    id: `embed.allow-full-screen`,
    defaultMessage: `Allow full screen`
  }
});
