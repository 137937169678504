import React from "react";
import { Button } from "@nextui-org/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesActions } from "app/store/slices/scenes.slice";
import { DefaultAddScenePosition, Scene } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import styled, { useTheme } from "styled-components";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { ThemeMode } from "app/utils/theme";
import { defineMessages, useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const Container = styled(H1_FlexRow)`
  border-radius: 10px;
  background: ${(e) => e.theme.gray3};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 247px;
  height: 33px;
  position: absolute;
  top: -16.5px;
  align-self: center;
  button {
    border: unset;
    padding: unset;
    height: unset;
    width: unset;
    min-width: unset;
    color: ${(e) => e.theme.blue2};
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue1)};
      span,
      i {
        color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue1)};
      },
    }
      background: unset;
  }
`;
const messages = defineMessages({
  buttonText: {
    id: "scene-revert-button.button-text",
    defaultMessage: "Scene removed. Tap to "
  },
  buttonTextUndo: {
    id: "scene-revert-button.button-text-undo",
    defaultMessage: "Undo"
  }
});
const SceneRevertButton = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const lastDeletedScene = useAppSelector((state) => state.scenes.lastDeletedScene);
  const lastDeletedParentId = useAppSelector((state) => state.scenes.lastDeletedParentId);
  const draftId = useAppSelector((state) => state.drafts.currentDraft?.id);

  const deleteSceneStatus = useAppSelector((state) => state.scenes.deleteSceneStatus);
  const createSceneStatus = useAppSelector((state) => state.scenes.createSceneStatus);
  const isDeleteSceneLoading = deleteSceneStatus === fetchingStatus.loading;
  const isCreateSceneLoading = createSceneStatus === fetchingStatus.loading;

  if (!lastDeletedScene) {
    return null;
  }
  const onRevert = () => {
    dispatch(
      scenesActions.addDraftSceneRequest({
        draftId: draftId as string,
        parentId: lastDeletedParentId,
        scene: lastDeletedScene as Scene,
        defaultPosition: DefaultAddScenePosition.First
      })
    );
    dispatch(scenesActions.cleanLastDeletedScene());
  };

  return (
    <Container gap={"5px"} align={"center"} justify={"center"}>
      <H1_TextSmall whiteSpace="break-spaces" color={theme.gray9}>
        {formatMessage(messages.buttonText)}
      </H1_TextSmall>{" "}
      <Button disabled={isDeleteSceneLoading || isCreateSceneLoading} onClick={onRevert}>
        {formatMessage(messages.buttonTextUndo)}
      </Button>
    </Container>
  );
};

export default SceneRevertButton;
