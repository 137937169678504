import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { H1_TextSubtitle, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Location } from "app/types";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { isIOS, isIOS13 } from "react-device-detect";
import { Button } from "@nextui-org/react";

const messages = defineMessages({
  useFromDesktop: {
    id: `mobile-general-page.use-from-dekstop`,
    defaultMessage: "Use Hour One from <a>desktop</a> to get a better experience"
  },
  or: {
    id: `mobile-general-page.or`,
    defaultMessage: "Or"
  },
  learnMore: {
    id: `mobile-general-page.learn-more`,
    defaultMessage: "Learn more about our enterprise tailored plans:"
  },
  bookAMeetings: {
    id: `mobile-general-page.book-a-meeting`,
    defaultMessage: "Book a demo"
  }
});

const StyledInlineButton = styled(Button)`
  color: ${({ theme }) => theme.blue4};
  span {
    color: ${({ theme }) => theme.blue4};
  }
  display: inline-block;
  font-weight: 300;
  height: 22px;
  span {
    font-size: 18px;
  }
`;

const BlockMiddle = styled(H1_TextSubtitle)`
  display: inline-block;
`;

const StyledHourOneLogo = styled(HourOneLogo)`
  align-self: flex-start;
  g path:last-child {
    fill: ${(props) => props.theme.blue4};
  }
`;

const MediaFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray1};
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: black;
  margin: 0 10px;
`;

const MobileVideo = styled.video`
  width: calc(100% - 40px);
  position: absolute;
  top: 140px;
  left: 20px;
`;

const FULL_URL =
  "https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Enterprise_Page_V01_short";
const MobileGeneralPage = () => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const navigate = useNavigate();

  const url = isIOS || isIOS13 ? `${FULL_URL}.mp4` : `${FULL_URL}.webm`;

  useEffect(() => {
    const videoId = new URLSearchParams(search).get("video-id");
    if (videoId) {
      navigate(`${Location.Player}/${videoId}`);
    }
  }, []);

  const onClickCta = () => {
    window.location.href = "https://hourone.ai/demo/?product_upgrade_intent=upgrade_via_mobile";
  };

  return (
    <MediaFlexColumn height="100%" padding="20px" align="center">
      <StyledHourOneLogo />
      <H1_FlexColumn width="300px" align="center" padding="300px 20px 20px" gap="30px">
        <MobileVideo playsInline muted loop autoPlay src={url} />
        <BlockMiddle fontSize="18px" lineHeight="22px" whiteSpace="break-spaces" textAlign="center">
          {formatMessage(messages.useFromDesktop, {
            a: (chunks: ReactNode) => (
              <StyledInlineButton variant="light" color="primary">
                {chunks}
              </StyledInlineButton>
            )
          })}
        </BlockMiddle>
        <H1_FlexRow width="100%" align="center" justify="center">
          <Line />
          <H1_TextXs>{formatMessage(messages.or)}</H1_TextXs>
          <Line />
        </H1_FlexRow>
        <H1_TextSubtitle
          fontSize="18px"
          lineHeight="22px"
          whiteSpace="break-spaces"
          textAlign="center"
        >
          {formatMessage(messages.learnMore)}
        </H1_TextSubtitle>
        <Button color="primary" onClick={onClickCta}>
          {formatMessage(messages.bookAMeetings)}
        </Button>
      </H1_FlexColumn>
    </MediaFlexColumn>
  );
};

export default MobileGeneralPage;
