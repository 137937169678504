import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import {
  hasSomeParentTheClass,
  SINGLE_GRID_ITEM_CLASSNAME,
  SINGLE_GRID_ITEM_LOWER_AREA_CLASSNAME
} from "app/utils/helpers";

interface GridItemProps {
  title: string;
  subTitle: string;
  dropdown: React.ReactNode;
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

const WhiteFlexRow = styled(H1_FlexRow)`
  background-color: ${(props) => props.theme.gray1};
  border-radius: 0 0 4px 4px;
`;

const ImageContainer = styled(H1_FlexColumn)`
  img {
    object-fit: contain;
    overflow: hidden;
    border-radius: 4px 4px 0 0;
  }
`;

const GridItem = ({ title, subTitle, dropdown, children, onClick, className }: GridItemProps) => {
  const theme = useTheme();
  const onClickBox: React.MouseEventHandler<HTMLDivElement> = (event: React.BaseSyntheticEvent) => {
    if (
      hasSomeParentTheClass(event.target, "draft-actions-menu") ||
      hasSomeParentTheClass(event.target, "ant-dropdown-trigger") ||
      hasSomeParentTheClass(event.target, "ant-dropdown")
    ) {
      event.stopPropagation();
    } else {
      onClick();
    }
  };
  return (
    <H1_FlexColumn
      position="relative"
      width="12.3125rem"
      onClick={onClickBox}
      className={`${SINGLE_GRID_ITEM_CLASSNAME} ${className}`}
    >
      <ImageContainer height="calc(12.3125rem * 9 / 16)">{children}</ImageContainer>
      <WhiteFlexRow
        align="flex-start"
        justify="space-between"
        padding="0.5625rem 0.5rem 0.375rem 0.875rem"
        className={SINGLE_GRID_ITEM_LOWER_AREA_CLASSNAME}
        height="3.6875rem"
      >
        <H1_FlexColumn flex="1">
          <NameText $maxWidth="8rem" ellipsis={{ tooltip: title }}>
            {title}
          </NameText>
          <H1_TextSmall color={theme.gray7}>{subTitle}</H1_TextSmall>
        </H1_FlexColumn>
        {dropdown}
      </WhiteFlexRow>
    </H1_FlexColumn>
  );
};

export default GridItem;
