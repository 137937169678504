import { createSelector } from "reselect";
import { workspacesGlobalSelectors } from "app/store/adapters/adapters";
import { RootState } from "app/store/store";
import { MetadataLimits, PlanEnum, SubscriptionPlanEnum } from "app/types";
import { DEFAULT_CHARS_PER_SCENE, DEFAULT_MAX_SCENES } from "app/config/planFeature";
import Config from "app/config/Config";

export const appData = (state: RootState) => state.user.appData;
export const featureFlags = (state: RootState) => state.user.featureFlags;
export const preferences = (state: RootState) => state.user.preferences;

export const getCurrentWorkspace = createSelector(
  [workspacesGlobalSelectors.selectAll, preferences],
  (allWorkspaces, allPreferences) => {
    if (!allWorkspaces || allWorkspaces.length === 0) {
      return undefined;
    }

    const selectedWorkspace = allWorkspaces.find(
      (workspace) => workspace.id === allPreferences?.current_workspace_id
    );
    if (selectedWorkspace) {
      return selectedWorkspace;
    }
    const defaultWorkspace = allWorkspaces.find((workspace) => workspace.default);
    if (defaultWorkspace) {
      return defaultWorkspace;
    }

    return allWorkspaces[0];
  }
);

export const getDefaultWorkspace = createSelector(
  workspacesGlobalSelectors.selectAll,
  (allWorkspaces) => {
    const defaultWorkspace = allWorkspaces.find(
      (workspace) => workspace.default && workspace.is_owner
    );
    return defaultWorkspace;
  }
);

export const currentWorkspacePlan = createSelector(
  [getCurrentWorkspace, appData],
  (workspace, currentAppData) => {
    if (!workspace) {
      return currentAppData.plan as PlanEnum;
    }

    return workspace?.plan as PlanEnum;
  }
);

export const workspaceSupportsLiveBlocks = createSelector(
  [getCurrentWorkspace, currentWorkspacePlan, featureFlags],
  (currentWorkspace, currentWorkspacePlan, currentFeatureFlags) => {
    const isLiveBlocksSupported =
      Config.env !== "local" &&
      (currentWorkspacePlan === PlanEnum.advanced ||
        currentWorkspacePlan === PlanEnum.business ||
        currentWorkspacePlan === PlanEnum.enterprise);
    return (
      isLiveBlocksSupported &&
      (currentWorkspace?.members.length || 0) > 1 &&
      currentFeatureFlags.liveBlocks
    );
  }
);

export const currentWorkspaceSubscriptionPlan = createSelector(
  [getCurrentWorkspace, appData],
  (workspace, currentAppData) => {
    if (!workspace || !workspace.metadata) {
      return currentAppData.subscription_plan as SubscriptionPlanEnum;
    }
    return workspace?.metadata?.subscription_plan as SubscriptionPlanEnum;
  }
);
export const getCurrentWorkspacePermissions = createSelector([getCurrentWorkspace], (workspace) => {
  if (!workspace || !workspace.metadata) {
    return [];
  }
  return workspace?.permissions ?? [];
});

export const getLimits = createSelector(
  [getCurrentWorkspace, appData],
  (workspace, currentAppData) => {
    let limits;
    if (!workspace || !workspace.metadata) {
      limits = currentAppData.limits;
    } else {
      limits = workspace?.metadata?.limits;
    }
    if (!limits) {
      limits = {};
    }
    // adding defaults
    if (!limits?.chars_per_scene) {
      limits = { ...limits, chars_per_scene: DEFAULT_CHARS_PER_SCENE };
    }
    if (!limits?.scenes) {
      limits = { ...limits, scenes: DEFAULT_MAX_SCENES };
    }
    return limits as MetadataLimits;
  }
);
