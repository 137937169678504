import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { ThunkApi, Webhook, WebHookRequest, WebhookStatus } from "app/types";
import {
  createWebhook,
  createWebhookSecret,
  deleteWebhook,
  getWebhook,
  getWebhooks,
  togglePauseWebhook,
  toggleStatusWebhook,
  updateWebhook
} from "app/services/serviceV2Apis";

const prefix = "[Webhooks]";

const getWebhooksRequest = createAsyncThunk<Webhook[], void>(
  `${prefix} getWebhooksRequest`,
  async () => {
    const result = await getWebhooks();
    return result;
  },
  thunkOptions
);

const getWebhookRequest = createAsyncThunk<Webhook, string>(
  `${prefix} getWebhookRequest`,
  async (webhookId) => {
    const result = await getWebhook(webhookId);
    return result;
  },
  thunkOptions
);
const updateWebhookRequest = createAsyncThunk<
  Webhook,
  { webhookId: string; request: WebHookRequest }
>(
  `${prefix} updateWebhookRequest`,
  async ({ webhookId, request }) => {
    const result = await updateWebhook(webhookId, request);
    return result;
  },
  thunkOptions
);

const createWebhookRequest = createAsyncThunk<Webhook, WebHookRequest, ThunkApi>(
  `${prefix} createWebhookRequest`,
  async (request) => {
    const result = createWebhook(request);

    return result;
  },
  thunkOptions
);

const deleteWebhookRequest = createAsyncThunk<void, string, ThunkApi>(
  `${prefix} deleteWebhookRequest`,
  async (webhookId) => {
    await deleteWebhook(webhookId);
  },
  thunkOptions
);

const togglePauseWebhookRequest = createAsyncThunk<{ status: WebhookStatus }, string, ThunkApi>(
  `${prefix} togglePauseWebhookRequest`,
  async (webhookId) => {
    const result = await togglePauseWebhook(webhookId);
    return result;
  },
  thunkOptions
);

const reactiveWebhookRequest = createAsyncThunk<{ status: WebhookStatus }, string, ThunkApi>(
  `${prefix} reactiveWebhookRequest`,
  async (webhookId) => {
    const result = await toggleStatusWebhook(webhookId);
    return result;
  },
  thunkOptions
);

const createWebhookSecretRequest = createAsyncThunk<{ signing_secret: string }, string, ThunkApi>(
  `${prefix} createWebhookSecretRequest`,
  async (webhookId) => {
    const result = await createWebhookSecret(webhookId);
    return result;
  },
  thunkOptions
);

export default {
  createWebhookRequest,
  getWebhooksRequest,
  getWebhookRequest,
  updateWebhookRequest,
  deleteWebhookRequest,
  togglePauseWebhookRequest,
  reactiveWebhookRequest,
  createWebhookSecretRequest
};
