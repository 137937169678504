import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Video } from "app/types";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { fetchingStatus } from "app/utils/helpers";
import useModal, { ModalName } from "app/hooks/useModal";
import ConditionalRender from "app/components/common/ConditionalRender";
import videosSelectors from "app/store/selectorsV2/videos.selectors";
import { useLocation } from "react-router-dom";
import { videosActions } from "app/store/slices/videos.slice";
import VideoReadyModalContent from "./VideoReadyModalContent";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    min-width: 300px;
  }
`;

const VideoReadyModal = () => {
  const dispatch = useAppDispatch();
  const { closeModal, editorModalOpen, openModal } = useModal();
  const { search } = useLocation();
  const { reqId } = editorModalOpen?.context || {};

  const selectedVideo: Video | undefined = useAppSelector((state) =>
    videosSelectors.getVideoByReqId(state, reqId)
  );

  const draftsStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const videoStatus = useAppSelector((state) => state.videos.videosStatus);
  // The second option is when video-id parameter exists and the user enters the homepage, calling getDraftsRequest
  const isLoading =
    videoStatus === fetchingStatus.loading || draftsStatus === fetchingStatus.loading;
  const visible = editorModalOpen?.open === ModalName.videoReady;
  const draftId: string = selectedVideo?.draft_id || "";
  const minWidth = useMemo(() => {
    if (
      visible &&
      selectedVideo?.aspect_ratio &&
      selectedVideo.aspect_ratio.width &&
      selectedVideo.aspect_ratio.height
    ) {
      const { height, width } = selectedVideo.aspect_ratio;
      return `${(533 * width) / height + 60}px`;
    }
    // 533 is the height, 60 is the padding on both sides
    return `0px`;
  }, [selectedVideo?.aspect_ratio]);

  useEffect(() => {
    if (reqId && visible && !selectedVideo) {
      dispatch(videosActions.getVideosByReqIdRequest(reqId));
    }
  }, [reqId, visible, selectedVideo]);

  useEffect(() => {
    const paramsVideoId = new URLSearchParams(search).get("video-id");
    if (paramsVideoId) {
      openModal(ModalName.videoReady, { reqId: paramsVideoId });
    }
  }, []);

  const onCloseModal = () => {
    dispatch(
      googleEvents.playModalClose({
        draftId: draftId as string,
        videoId: selectedVideo?.id as string
      })
    );
    closeModal();
  };

  return (
    <Modal
      $padding="1.12rem"
      $minHeight="40.1875rem"
      width="auto"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ minHeight: "40.1875rem", minWidth }}
      onCancel={onCloseModal}
      maskClosable
      destroyOnClose
      closeIcon={<CloseModalIcon isOutsideTopRight />}
    >
      <ConditionalRender condition={isLoading}>
        <H1_FlexRow flex="1" height="100%" width="100%" justify="center" align="center">
          <CircleLoader />
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={!isLoading}>
        <VideoReadyModalContent width={minWidth} />
      </ConditionalRender>
    </Modal>
  );
};

export default VideoReadyModal;
