import styled, { useTheme } from "styled-components";
import { Button } from "antd";
import { Header } from "antd/es/layout/layout";
import { NextButton } from "app/components/onBoarding/NextButton";
import errorImage from "app/assets/error/error_image.png";
import { useAuth } from "app/auth/useAuth";
import { defineMessages, useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store/store";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

// region [[ Styles ]]

const Title = styled(H1_TextHeadline)`
  margin-bottom: 20px;
`;

const ContactButton = styled(Button)`
  & a {
    text-decoration: underline;
  }
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  width: fit-content;
`;

const messages = defineMessages({
  title: {
    id: "renew-token.title",
    defaultMessage: "This session has expired."
  },
  explainText: {
    id: "renew-token.text",
    defaultMessage: "For your account security please sign in again."
  },
  logout: {
    id: "renew-token.button.logout.text_v2",
    defaultMessage: "Sign In"
  },
  support: {
    id: "renew-token.support.text",
    defaultMessage: "Contact support"
  }
});

const RenewTokenError = () => {
  const { logout } = useAuth();
  const intl = useIntl();
  const theme = useTheme();

  const tokenRefreshErr = useAppSelector(
    // @ts-ignore handels userUI store typing
    (state: RootState) => state.userUi.apiRefreshTokenError
  );
  return (
    <StyledModal
      width="813px"
      open={tokenRefreshErr}
      footer={null}
      centered
      destroyOnClose
      closable={false}
      $flex="1"
      $padding="50px"
    >
      <H1_FlexRow justify="center" gap={"5px"}>
        <H1_FlexColumn justify="space-between">
          <Header>
            <Title color={theme.pink4} fontWeight={600}>
              {intl.formatMessage(messages.title)}
            </Title>
            <H1_TextMiddle>{intl.formatMessage(messages.explainText)}</H1_TextMiddle>
          </Header>
          <H1_FlexColumn justify="flex-start">
            <NextButton onClick={() => logout()}>{intl.formatMessage(messages.logout)}</NextButton>
            <ContactButton type="link" href="mailto:support@hourone.ai">
              {intl.formatMessage(messages.support)}
            </ContactButton>
          </H1_FlexColumn>
        </H1_FlexColumn>

        <H1_FlexColumn>
          <img src={errorImage} alt="error" width={370} />
        </H1_FlexColumn>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default RenewTokenError;
