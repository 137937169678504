import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { digitalTwinCreationMessages } from "app/pages/createDigitalTwinPage/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useRecordStepContext from "app/pages/createDigitalTwinPage/useRecordStepSettingsContext";
import styled from "styled-components";
import { useEffect } from "react";
import { H1_Select } from "app/components/_Infrastructure/design-system/select";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Switch } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button } from "@nextui-org/react";

const NoMinHightContainer = styled(H1_FlexColumn)`
  min-height: auto;
`;

const IconWrapper = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray4};
  aspect-ratio: 1;
  border-radius: 50%;
  i {
    color: ${(props) => props.theme.gray7};
  }
`;

const CenteredButton = styled(Button)`
  min-width: 10px;
  align-self: center;
  min-height: 38px;
  padding: 20px 40px;
  margin: 20px auto;
`;

interface SelcetOption {
  key: string;
  value: string;
  label: string;
}

const RecordStepRecordingSettings = ({
  isReady,
  cameraMenu,
  microphoneMenu,
  handleRecordingToggle,
  recordCTAText
}: {
  isReady: boolean;
  cameraMenu?: SelcetOption[];
  microphoneMenu?: SelcetOption[];
  handleRecordingToggle: () => void;
  recordCTAText: string;
}) => {
  const intl = useIntl();
  const {
    selectedMicrophone,
    selectedCamera,
    setSelectedCamera,
    setSelectedMicrophone,
    setfaceOutline,
    faceOutline
  } = useRecordStepContext();

  const startRecording = () => {
    // Implement recording logic using selectedCamera and selectedMicrophone
    handleRecordingToggle();
  };

  useEffect(() => {
    if (!selectedCamera && cameraMenu?.length) {
      setSelectedCamera(cameraMenu[0].value);
    }
  }, [cameraMenu, selectedCamera]);

  useEffect(() => {
    if (!!microphoneMenu && !selectedMicrophone && microphoneMenu?.length) {
      setSelectedMicrophone((microphoneMenu as SelcetOption[])[0].value);
    }
  }, [microphoneMenu, selectedMicrophone]);

  return (
    <NoMinHightContainer width="min-content" gap="10px" justify="center">
      <H1_TextMiddle>
        {intl.formatMessage(digitalTwinCreationMessages.recordStepSettingsHeadline)}
      </H1_TextMiddle>
      <H1_FlexRow gap="10px">
        <IconWrapper align="center" justify="center" width="30px" height="30px">
          <i className="fa-solid fa-camera" />
        </IconWrapper>

        <H1_Select
          width="300px"
          dropdownMatchSelectWidth={300}
          dropdownAlign={{
            offset: [-17, 5]
          }}
          placeholder={intl.formatMessage(digitalTwinCreationMessages.selectCamPlaceholder)}
          value={selectedCamera}
          options={cameraMenu || []}
          onChange={setSelectedCamera}
        />
      </H1_FlexRow>
      <ConditionalRender condition={!!microphoneMenu}>
        <H1_FlexRow gap="10px">
          <IconWrapper align="center" justify="center" width="30px" height="30px">
            <i className="fa-solid fa-microphone" />
          </IconWrapper>
          <H1_Select
            width="300px"
            dropdownMatchSelectWidth={300}
            dropdownAlign={{
              offset: [-17, 5]
            }}
            placeholder={intl.formatMessage(digitalTwinCreationMessages.selectMicPlaceholder)}
            value={selectedMicrophone}
            options={microphoneMenu || []}
            onChange={setSelectedMicrophone}
          />
        </H1_FlexRow>
      </ConditionalRender>
      <H1_FlexRow gap="10px">
        <Switch
          checkedChildren={<i className="fa-solid fa-face-smile" />}
          unCheckedChildren={<i className="fa-regular fa-face-smile" />}
          size="default"
          onChange={setfaceOutline}
          checked={faceOutline}
        />
        <H1_TextSmall whiteSpace="normal">
          <ConditionalRender condition={faceOutline}>
            {intl.formatMessage(digitalTwinCreationMessages.faceOutlineOn)}
          </ConditionalRender>
          <ConditionalRender condition={!faceOutline}>
            {intl.formatMessage(digitalTwinCreationMessages.faceOutlineOff)}
          </ConditionalRender>
        </H1_TextSmall>
      </H1_FlexRow>

      <H1_FlexRow gap="10px" justify="center">
        <CenteredButton color="primary" isDisabled={!isReady} onClick={startRecording}>
          {recordCTAText}
        </CenteredButton>
      </H1_FlexRow>
    </NoMinHightContainer>
  );
};

export default RecordStepRecordingSettings;
