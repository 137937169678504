/* eslint-disable no-param-reassign */
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { Button } from "antd";
import joinSrcImage from "app/assets/teams/join-team.png";
import foldersSrcImage from "app/assets/teams/folders.png";
import { welcomeTeamMessages } from "app/pages/Teams/messages";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MemberStatus } from "app/types";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextMidHeadline,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;
    border-radius: 12px;
    height: 584px;
  }
`;

const TopFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray11};
`;

const JoinImage = styled.img`
  position: absolute;
  left: 45px;
  bottom: 38px;
`;

const FoldersImage = styled.img`
  position: absolute;
  right: 48px;
  top: 20px;
`;

const TitleText = styled(H1_TextMidHeadline)`
  display: inline-block;
  position: absolute;
  right: 44px;
  bottom: 39px;
`;

const BottomFlexColumn = styled(H1_FlexColumn)`
  background-color: var(--gray-01, #fff);
`;

const ActionButton = styled(Button)`
  &&,
  &&:focus {
    color: var(--gray-01, #fff);
    background-color: var(--blue-04, #5a5aff);
    width: 100%;
  }
  &&&:hover {
    background-color: var(--blue-02, #aaaaff);
  }
  &&&:active {
    background-color: var(--blue-01, #dcddff);
  }
  &&&:disabled {
    background-color: var(--gray-06, #bfbfbf);
  }
`;

interface WelcomeTeamModalProps {
  visible: boolean;
  onClose: () => void;
}
const WelcomeTeamModal = ({ visible, onClose }: WelcomeTeamModalProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const invitations = useAppSelector((state) => state.workspaces.invitations);
  const acceptedInvitations = useMemo(() => {
    return invitations.filter((invitation) => invitation.status === MemberStatus.accepted);
  }, [invitations]);

  const onCloseModal = () => {
    dispatch(workspacesActions.ackAcceptedInvitationsRequest());
    onClose();
  };

  if (!invitations || acceptedInvitations.length === 0) {
    return null;
  }

  return (
    <Modal
      width="490px"
      open={visible}
      footer={null}
      centered
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon size="40px" />}
    >
      <TopFlexColumn position="relative" flex="0 0 273px">
        <JoinImage src={joinSrcImage} height={56} width={182} alt="join-team" />
        <FoldersImage src={foldersSrcImage} height={84} width={74} alt="folders" />
        <TitleText
          whiteSpace="break-spaces"
          color={theme.gray1}
          fontSize="26px"
          lineHeight="28px"
          fontWeight={500}
          fontFamily="DrukWide"
        >
          {formatMessage(welcomeTeamMessages.title)}
        </TitleText>
      </TopFlexColumn>
      <BottomFlexColumn justify="space-between" flex="1" padding="32px 46px 46px">
        <H1_FlexColumn>
          <H1_FlexColumn gap="19px" align="center" flex="0 0 auto" padding="0 0 10px 0">
            <H1_TextHeadline whiteSpace="break-spaces">
              {formatMessage(welcomeTeamMessages.welcome, {
                name: acceptedInvitations[0].workspace_name
              })}
            </H1_TextHeadline>
            <H1_TextSmall whiteSpace="break-spaces">
              {formatMessage(welcomeTeamMessages.description)}
            </H1_TextSmall>
          </H1_FlexColumn>
        </H1_FlexColumn>
        <H1_FlexRow
          align="center"
          justify="center"
          height="40px"
          padding="10px 0 0 0"
          flex="0 0 50px"
          gap="20px"
        >
          <ActionButton size="large" onClick={onCloseModal}>
            {formatMessage(welcomeTeamMessages.letsGoButton)}
          </ActionButton>
        </H1_FlexRow>
      </BottomFlexColumn>
    </Modal>
  );
};

export default WelcomeTeamModal;
