import { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

import Loader from "app/components/common/Loader";

const ImageWithLoading = ({
  image,
  video,
  alt,
  width,
  height,
  containerStyle,
  className,
  style,
  blurHash
}: {
  image: string;
  video?: null | string;
  alt?: undefined | string;
  width?: undefined | number;
  height?: undefined | number | string;
  containerStyle?: null | Record<string, unknown>;
  className?: null | string;
  style?: null | Record<string, unknown>;
  blurHash?: null | string;
}) => {
  const [loaded, setLoaded] = useState(false);
  const [marginTop, setMarginTop] = useState(0);

  useEffect(() => {
    if (marginTop !== 0 && !loaded) {
      setLoaded(true);
    }
  }, [marginTop, loaded]);

  const renderLoader = () => {
    if (blurHash) {
      return (
        <Blurhash
          style={{ marginTop, ...style }}
          hash={blurHash}
          width={width}
          height={height}
          punch={1}
        />
      );
    }
    return <Loader style={{ marginTop, ...style }} />;
  };

  return (
    <div
      style={{ ...containerStyle, width, height }}
      className={`imageWithLoading-container ${className || ""}`}
    >
      {!loaded && renderLoader()}
      {(video || image) && (
        <img
          className={`${video && !image && "gif"}`}
          src={video || image}
          style={{ marginTop, ...style }}
          onLoad={(imgLoadedEvent) => {
            const img = imgLoadedEvent?.target as HTMLImageElement;
            const container = img.parentNode as HTMLDivElement;
            const imgH = img.offsetHeight;
            const containerH = container.offsetHeight;
            if (img && containerH < imgH) {
              setMarginTop(-((imgH - containerH) / 2));
            } else {
              setLoaded(true);
            }
          }}
          alt={alt && alt}
        />
      )}
    </div>
  );
};

ImageWithLoading.defaultProps = {
  video: null,
  alt: null,
  width: "100%",
  height: 105,
  containerStyle: null,
  className: null,
  style: null,
  blurHash: null
};

export default ImageWithLoading;
