import { defineMessages } from "react-intl";

export const reviewStatusMessages = defineMessages({
  updateStatus: {
    id: "review-status.update-status",
    defaultMessage: "Update status"
  },
  draft: {
    id: "review-status.draft",
    defaultMessage: "Draft"
  },
  inReview: {
    id: "review-status.in-review",
    defaultMessage: "In Review"
  },
  pending: {
    id: "review-status.pending",
    defaultMessage: "Pending"
  },
  approved: {
    id: "review-status.approved",
    defaultMessage: "Approved"
  },
  rejected: {
    id: "review-status.rejected",
    defaultMessage: "Rejected"
  }
});
