import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import type { LexicalEditor as LexicalEditorType } from "lexical";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorState,
  Klass,
  LexicalNode
} from "lexical";
import { fetchingStatus, getTextOutOfSML } from "app/utils/helpers";
import { uniq } from "lodash-es";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import styled, { useTheme } from "styled-components";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import {
  SharedHistoryContext,
  useSharedHistoryContext
} from "app/components/common/LexicalEditor/context/SharedHistoryContext";
import ToolbarPlugin from "app/components/common/LexicalEditor/plugins/ToolbarPlugin";
import UpdatePlugin from "app/components/common/LexicalEditor/plugins/UpdatePlugin";
import MentionsPlugin from "app/components/common/LexicalEditor/plugins/MentionsPlugin";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { MaxLengthPlugin } from "app/components/common/LexicalEditor/plugins/MaxLengthPlugin";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Alert } from "antd";
import LinkButton from "app/components/common/Layout/LinkButton";
import {
  illegalCharactersInText,
  removeIllegalCharactersInText,
  validateIllegalCharsTranscript
} from "app/components/editor/validations";
import { knowledgeBaseUrlIllegalCharacters } from "app/utils/urls";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useIntl } from "react-intl";
import { editorLexicalNotificationsMessages } from "./messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { useAppSelector } from "app/hooks";
import { FetchStatus, SynthesisMarkupLanguage, SynthesisMarkupLanguageType } from "app/types";
import PausePlugin from "app/components/common/LexicalEditor/plugins/PausePlugin";
import VariablePlugin from "app/components/common/LexicalEditor/plugins/VariablePlugin";
import { SerializedElementNode } from "lexical/nodes/LexicalElementNode";
import PronunciationPlugin from "app/components/common/LexicalEditor/plugins/PronunciationPlugin";
import { SerializedLexicalNode } from "lexical/LexicalNode";
import { Button } from "@nextui-org/react";

const BorderedButton = styled(Button)`
  border-radius: 25px;
`;
const StyledContentEditable = styled(ContentEditable)`
  resize: none;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 0;
  cursor: text;
  caret-color: #444;
  p {
    margin-bottom: 0;
  }
  .LexicalEditorTheme__hashtag {
    background-color: #5890ff26;
    border-bottom: 1px solid rgba(88, 144, 255, 0.3);
  }
`;

const StyledPlaceholder = styled.div`
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

const AlertBox = styled(Alert)`
  margin-top: 10px;
  width: 100%;
  border-radius: 50px;
  cursor: default;
  background-color: ${(props) => props.theme.pink1};
  .ant-alert-icon {
    color: ${(props) => props.theme.pink4};
  }
`;

interface LexicalEditorProps {
  name: string;
  sceneId: string;
  currentInnerText: string;
  hideToolbar: boolean;
  withVariables?: boolean;
  onChange: (text: string) => void;
  onBlur: ({ json }: { json: SynthesisMarkupLanguage[] }) => void;
  onPaste: (text: string) => void;
  editorNode?: JSX.Element;
  nodes?: ReadonlyArray<
    | Klass<LexicalNode>
    | {
        replace: Klass<LexicalNode>;
        with: <
          T extends {
            new (...args: any): any;
          }
        >(
          node: InstanceType<T>
        ) => LexicalNode;
      }
  >;
  mentionsList?: string[];
  withHashtag?: boolean;
  withPause?: boolean;
  withPronunciation?: boolean;
  dataAutoIdAddon?: string;
  isRichText?: boolean;
  isReadOnly?: boolean;
  currentValue: SynthesisMarkupLanguage[];
  outsideEditorUpdate?: SynthesisMarkupLanguage[];
  maxLength?: number;
  isForceMaxLength?: boolean;
  className?: string;
  ButtonPause?: boolean;
  placeholder?: string;
  useWordCounter?: boolean;
  pauseSupported: boolean;
  scenesLeft: number;
  isSceneSelected?: boolean;
  onRemoveInvalidChars: ({ json }: { json: SynthesisMarkupLanguage[] }) => void;
  onApplySplitApprove?: (pasted: string) => void;
  onValidation?: (valid: boolean) => void;
  onUseVoice: () => void;
}

const IllegalCharacter = ({
  desc = [],
  removeillegal
}: {
  desc: string[];
  removeillegal: () => void;
}) => {
  const intl = useIntl();
  const theme = useTheme();

  const onClickIllegalCharacter: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    window.open(knowledgeBaseUrlIllegalCharacters);
  };

  return (
    <H1_FlexRow wrap="wrap" gap="10px" align="center" justify="space-between">
      <H1_TextXs color="black">
        {/* @ts-ignore: intl html tags */}
        {intl.formatMessage(editorLexicalNotificationsMessages.illegalCharacter, {
          chars: <b>{desc.join(" ")}</b>,
          link: (chunks) => (
            <LinkButton
              $fontSize="12px"
              $color={theme.blue4}
              $display="inline-block"
              onClick={onClickIllegalCharacter}
            >
              {chunks}
            </LinkButton>
          )
        })}
      </H1_TextXs>
      <H1_FlexRow gap="8px" alignSelf="flex-end">
        <Button color="primary" onClick={removeillegal} size="sm">
          {intl.formatMessage(editorLexicalNotificationsMessages.illegalCharacterRemove)}
        </Button>
      </H1_FlexRow>
    </H1_FlexRow>
  );
};

const LexicalEditor = ({
  name,
  editorNode,
  nodes,
  mentionsList,
  withHashtag = false,
  maxLength = 500,
  withPause = false,
  withPronunciation = false,
  isForceMaxLength = false,
  onChange,
  onBlur,
  onPaste,
  ButtonPause,
  className,
  placeholder = "Enter text...",
  currentValue,
  outsideEditorUpdate,
  scenesLeft,
  isSceneSelected = false,
  onRemoveInvalidChars,
  onApplySplitApprove,
  dataAutoIdAddon = "",
  isRichText = false,
  isReadOnly = false,
  useWordCounter = false,
  withVariables = true,
  onValidation = () => null,
  pauseSupported,
  sceneId,
  currentInnerText,
  onUseVoice,
  hideToolbar
}: LexicalEditorProps) => {
  const [lexicalEditor, setLexicalEditor] = useState<LexicalEditorType>();
  const [isCharactersValid, setIsCharacterValid] = useState<boolean>(true);
  const [illegalCharactersDescription, setIllegalCharactersDescription] = useState<string[]>([]);
  const [offerSplit, setOfferSplit] = useState<boolean>(false);
  const editorStateRef = useRef<EditorState>(null);
  const { historyState } = useSharedHistoryContext();
  const intl = useIntl();
  const segmentToSceneStatus: FetchStatus = useAppSelector(
    (state) => state.drafts.segmentToSceneStatus
  );
  const isTextLengthValid = useMemo(() => {
    const text = getTextOutOfSML(currentValue);
    if (onValidation) {
      onValidation(!maxLength || text.length <= maxLength);
    }
    return !maxLength || text.length <= maxLength;
  }, [maxLength, currentValue]);

  useEffect(() => {
    if (isTextLengthValid) {
      setOfferSplit(false);
    }
  }, [isTextLengthValid]);

  const onError = (error: any) => {
    console.error(error);
  };

  const prePopulatedRichText = (editor: LexicalEditorType) => {
    setLexicalEditor(editor);
    const editorState = editor.getEditorState();
    // @ts-ignore cannot change editorStateRef as it unmutateble
    editorStateRef.current = editorState;
    const text = getTextOutOfSML(currentValue);
    setIsCharacterValid(validateIllegalCharsTranscript(text));

    // Need this for the editor to be considered empty on startup
    return "";
  };

  /** When starting the editor, it does not add the text to the scenes, so here we force updating the text
   *  which causes it to update the editor with the initial text. This must be done in order to successfully click the
   *  Remove Illegal Characters button */
  useEffect(() => {
    if (lexicalEditor) {
      lexicalEditor.update(() => {
        const root = $getRoot();
        root.clear(); // Clear existing content

        // Create a new paragraph node with text
        const paragraphNode = $createParagraphNode();
        const text = getTextOutOfSML(currentValue);
        const textNode = $createTextNode(text);
        paragraphNode.append(textNode);

        // Append the paragraph to the root node
        root.append(paragraphNode);
      });
    }
  }, [lexicalEditor]);

  const initialConfig = {
    namespace: name,
    onError,
    editorState: prePopulatedRichText,
    nodes,
    theme: {
      hashtag: "LexicalEditorTheme__hashtag"
    }
  };

  const handleOnChangeText = () => {
    const { json } = getJsonSml();
    const text = getTextOutOfSML(json);
    // TODO: check if can be replaced with memorized isTextLengthValid
    const isTextLengthValidVal = !maxLength || text.length <= maxLength;
    if (!isTextLengthValidVal) {
      const scenesLeftIncludeCurrent = scenesLeft + 1;
      const canTextSplitToScenes =
        !!scenesLeft && Math.ceil(text.length / scenesLeftIncludeCurrent) <= maxLength;
      setOfferSplit(canTextSplitToScenes && scenesLeftIncludeCurrent > 1);
    }
    const isValidCharacters = validateIllegalCharsTranscript(text);
    setIsCharacterValid(isValidCharacters);
    if (onValidation) {
      onValidation(isTextLengthValidVal && isValidCharacters);
    }
    if (!isValidCharacters) {
      const matches = [...illegalCharactersInText(text)];
      setIllegalCharactersDescription(uniq(matches.map((match) => match[0])));
    }

    onChange(text);
  };

  const handleMultipleParagraphs = (paragraphs: SerializedLexicalNode[]) => {
    // This is the default, and will happen in most cases - Single paragraph
    let flattenChildren = (paragraphs[0] as SerializedElementNode).children;
    // This will happen if a user used copy-paste, with new lines inside, which Lexical considers as new paragraphs
    if (paragraphs.length > 1) {
      flattenChildren =
        paragraphs?.flatMap((child) => (child as SerializedElementNode).children) || [];
      flattenChildren = flattenChildren.flatMap((originalObj, index, array) => {
        const newLineObj = {
          type: SynthesisMarkupLanguageType.text,
          text: "\n",
          version: 1
        };
        return index === array.length - 1 ? [originalObj] : [originalObj, newLineObj];
      });
    }
    return flattenChildren;
  };

  const getJsonSml = () => {
    const paragraphs = editorStateRef.current?.toJSON().root.children;
    // This should never happen, but typescript forces me to do this
    if (!paragraphs) {
      return { json: [] };
    }

    const flattenChildren = handleMultipleParagraphs(paragraphs);
    const json = flattenChildren.map((obj: SynthesisMarkupLanguage) => {
      if (obj.type === SynthesisMarkupLanguageType.linebreak) {
        return {
          type: SynthesisMarkupLanguageType.text,
          text: "\n",
          version: obj.version,
          key: obj.key
        };
      }
      return {
        type: obj.type as SynthesisMarkupLanguageType,
        pronounced: obj.pronounced,
        text: obj.text,
        time: obj.time,
        variable_id: obj.variable_id,
        version: obj.version,
        key: obj.key,
        sceneId
      };
    });

    return { json };
  };

  const removeInvalidChars = () => {
    let cleanJson: SynthesisMarkupLanguage[] = [];
    editorStateRef?.current?.read(() => {
      const { json } = getJsonSml();
      cleanJson = json.map((sml: SynthesisMarkupLanguage) => {
        if (sml.type === SynthesisMarkupLanguageType.text && sml.text) {
          sml.text = removeIllegalCharactersInText(sml.text as string);
        }
        return sml;
      });

      onRemoveInvalidChars({ json: cleanJson });
      onBlur({ json: cleanJson });
    });
  };

  const onChangeText = (editorState: EditorState) => {
    // @ts-ignore @tidharpeer1 handles: cannot change editorStateRef as it unmutable
    editorStateRef.current = editorState;
    editorState.read(() => {
      // Read the contents of the EditorState here.
      handleOnChangeText();
    });
  };

  const onBlurText = () => {
    if (editorStateRef.current) {
      editorStateRef.current.read(() => {
        const { json } = getJsonSml();

        onBlur({ json });
      });
    }
  };

  const isOverLength = !isTextLengthValid && !!maxLength;

  const acceptSplitOffer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (onApplySplitApprove) onApplySplitApprove(currentValue[0].pronounced as string);
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        {isRichText && (
          <ToolbarPlugin
            sceneId={sceneId}
            dataAutoIdAddon={dataAutoIdAddon}
            ButtonPause={ButtonPause}
            pauseSupported={pauseSupported}
            isSceneSelected={isSceneSelected}
            onUseVoice={onUseVoice}
            currentInnerText={currentInnerText}
            isReadOnly={isReadOnly}
            hideToolbar={hideToolbar}
          />
        )}
        <OnChangePlugin onChange={onChangeText} ignoreSelectionChange />
        <HistoryPlugin externalHistoryState={historyState} />
        {isForceMaxLength && !!maxLength && <MaxLengthPlugin maxLength={maxLength} />}
        {editorNode || isRichText ? (
          <>
            <RichTextPlugin
              contentEditable={
                <StyledContentEditable className={`${className} editor-content-editable`} />
              }
              placeholder={
                <StyledPlaceholder className="editor-placeholder">
                  {!currentInnerText && placeholder}
                </StyledPlaceholder>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
          </>
        ) : (
          <>
            <PlainTextPlugin
              contentEditable={<StyledContentEditable className="editor-content-editable" />}
              placeholder={
                <StyledPlaceholder className="editor-placeholder">
                  {!currentInnerText && placeholder}
                </StyledPlaceholder>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
          </>
        )}
        <UpdatePlugin
          sceneId={sceneId}
          content={currentValue}
          outsideEditorUpdate={outsideEditorUpdate}
          onPaste={onPaste}
          isReadOnly={isReadOnly}
          onBlur={onBlurText}
        />
        {mentionsList && <MentionsPlugin mentionsList={mentionsList} />}
        {withHashtag && <HashtagPlugin />}
        {withPause && <PausePlugin sceneId={sceneId} />}
        {withPronunciation && <PronunciationPlugin />}
        {withVariables && <VariablePlugin />}
        {useWordCounter && (
          <>
            <ConditionalRender condition={isOverLength && isCharactersValid}>
              <AlertBox
                message={
                  <H1_FlexRow wrap="wrap" gap="10px" align="center" justify="space-between">
                    <H1_TextXs color="black">
                      {intl.formatMessage(editorLexicalNotificationsMessages.textTooLong, {
                        limit: (maxLength as number).toString()
                      })}
                      <ConditionalRender condition={!!offerSplit && !!scenesLeft}>
                        {intl.formatMessage(editorLexicalNotificationsMessages.splitOffer)}
                      </ConditionalRender>
                    </H1_TextXs>
                    <ConditionalRender condition={!!offerSplit && !!scenesLeft}>
                      <BorderedButton
                        onClick={acceptSplitOffer}
                        isDisabled={segmentToSceneStatus === fetchingStatus.loading}
                        size="sm"
                        color="primary"
                      >
                        {intl.formatMessage(editorLexicalNotificationsMessages.splitOfferAccept)}
                      </BorderedButton>
                    </ConditionalRender>
                  </H1_FlexRow>
                }
                banner
                type="error"
              />
            </ConditionalRender>
            <ConditionalRender condition={!isCharactersValid}>
              <AlertBox
                message={
                  <IllegalCharacter
                    desc={illegalCharactersDescription}
                    removeillegal={removeInvalidChars}
                  />
                }
                banner
                type="error"
              />
            </ConditionalRender>
          </>
        )}
      </SharedHistoryContext>
    </LexicalComposer>
  );
};

export default LexicalEditor;
