import { FetchStatus } from "app/types";
import { createSlice } from "@reduxjs/toolkit";
import { fetchingStatus } from "app/utils/helpers";
import asyncThunks from "app/store/thunks/trash.thunk";
import { trashAdapter } from "app/store/adapters/adapters";

interface TrashState {
  trashStatus: FetchStatus;
}

export const trashSlice = createSlice({
  name: "Trash",
  initialState: trashAdapter.getInitialState<TrashState>({
    trashStatus: fetchingStatus.idle as FetchStatus
  }),
  reducers: {
    setTrashItemByTypeStatusToIdle(state) {
      state.trashStatus = fetchingStatus.idle as FetchStatus;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getTrashItemByTypeRequest.fulfilled, (state) => {
      state.trashStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getTrashItemByTypeRequest.pending, (state) => {
      state.trashStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getTrashItemByTypeRequest.rejected, (state) => {
      state.trashStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});
export default trashSlice.reducer;

export const trashActions = {
  getTrashItemByTypeRequest: asyncThunks.getTrashItemByTypeRequest,
  ...trashSlice.actions
};
