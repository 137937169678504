import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import React, { useEffect, useState } from "react";
import { draftsActions } from "app/store/slices/drafts.slice";
import { videosActions } from "app/store/slices/videos.slice";
import { fetchingStatus } from "app/utils/helpers";
import VariableItem from "app/components/editor/workflows/VariableItem";
import EditableText from "app/components/editor/workflows/EditableText";
import VideoRenderPreview from "app/pages/WorkflowSubmissionPage/VideoRenderPreview";
import ShareWorkflowModal from "app/pages/EditDraftPage/workflow/ShareWorkflowModal";
import { SynthesisMarkupLanguage } from "app/types";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import UploadLogoButton from "app/pages/EditDraftPage/workflow/UploadLogoButton";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import useWorkflow from "app/pages/EditDraftPage/workflow/useWorkflow";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { Button } from "@nextui-org/react";

const Container = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: ${({ theme }) => theme.gray1};

  /* Modal shadow 10% */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  max-width: 710px;
  min-height: 500px;
  flex-shrink: 0;
  min-width: 710px;
`;
const LogoWrapper = styled(H1_FlexRow)`
  top: -60px;
  left: 0px;
`;

const StyledShare = styled(Button)`
  width: 103px;
  height: 40px;
  && {
    background: #2c285c;
  }
  align-self: end;
`;

const LogoContainer = styled(H1_FlexColumn)`
  //transition: opacity 0.3s ease; /* Add a smooth transition effect */
  //
  //&:hover {
  //  opacity: 0.6;
  //}
`;

const EditForm = ({ liveMode }: { liveMode?: boolean }) => {
  const [isShareWorkflowVisible, setIsShareWorkflowVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { createWorkflowVideo, onChangeVariableValue, variables, variableValues } = useWorkflow();
  const workflowPlayingVideoId = useAppSelector((state) => state.drafts.workflowPlayingVideoId);
  const workflowId = useAppSelector((state) => state.drafts.currentDraft.workflow_id);
  const workflow = useAppSelector((state) => state.drafts.currentDraft.workflow);
  const totalSml: SynthesisMarkupLanguage[] = useAppSelector(
    scenesSelectors.getScenesTotalSmlWorkflow
  );

  const { displayUrls } = useDisplayUrls([workflow?.form.image]);
  const logoPreviewUrl =
    workflow?.form.image && displayUrls[workflow?.form.image]
      ? displayUrls[workflow?.form.image]
      : undefined;

  const videoCreateStatus = useAppSelector((state) => state.videos.videoCreateStatus);
  const createWorkflowSubmissionStatus = useAppSelector(
    (state) => state.drafts.createWorkflowSubmissionStatus
  );
  const videoIds = useAppSelector(videosGlobalSelectors.selectIds);

  const createVideoLoading =
    videoCreateStatus === fetchingStatus.loading ||
    createWorkflowSubmissionStatus === fetchingStatus.loading;

  useEffect(() => {
    if (videoCreateStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setWorkflowPlayingVideoId(videoIds[0]));
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    } else if (videoCreateStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateVideoCreateStatusToIdle());
    }
  }, [videoCreateStatus]);

  useEffect(() => {
    if (createWorkflowSubmissionStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setCreateWorkflowSubmissionStatusToIdle());
    } else if (createWorkflowSubmissionStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setCreateWorkflowSubmissionStatusToIdle());
    }
  }, [createWorkflowSubmissionStatus]);

  const onEditTitle = (value: string) => {
    dispatch(
      draftsActions.updateWorkflowFormRequest({
        workflowId: workflowId as string,
        form: { title: value }
      })
    );
  };
  const onEditDescription = (value: string) => {
    dispatch(
      draftsActions.updateWorkflowFormRequest({
        workflowId: workflowId as string,
        form: { description: value }
      })
    );
  };

  const onClickCreateVideo = () => {
    dispatch(
      analyticsEvents.workflowCreateVideo({
        source: "form",
        isPublishMode: !!liveMode,
        workflowId
      })
    );
    createWorkflowVideo();
  };

  const onOpenShareWorkflow = () => {
    dispatch(
      analyticsEvents.workflowShareWorkflow({
        action: "open",
        source: "form",
        workflowId
      })
    );
    setIsShareWorkflowVisible(true);
  };
  const onCloseShareWorkflow = () => {
    dispatch(
      analyticsEvents.workflowShareWorkflow({
        action: "close",
        source: "form",
        workflowId
      })
    );
    setIsShareWorkflowVisible(false);
  };

  return (
    <H1_FlexRow gap={"10px"} margin={"0px 0px 10px 0px"}>
      <ShareWorkflowModal visible={isShareWorkflowVisible} onClose={onCloseShareWorkflow} />
      <H1_FlexColumn gap={"10px"} padding="0 0 20px 0">
        <Container gap={"25px"} align={"center"} position={"relative"} padding={"85px 127px"}>
          <LogoWrapper justify={"center"} position={"absolute"} width={"100%"}>
            <LogoContainer position={"relative"}>
              <UploadLogoButton background={logoPreviewUrl?.url} disabled={!!liveMode} />
            </LogoContainer>
          </LogoWrapper>
          <H1_FlexColumn gap={"12px"} align={"center"}>
            <EditableText
              viewOnly={liveMode}
              value={workflow?.form?.title || ""}
              text={<H1_TextHeadline>{workflow?.form?.title || ""}</H1_TextHeadline>}
              onSave={onEditTitle}
            />
            <EditableText
              viewOnly={liveMode}
              value={workflow?.form?.description || ""}
              text={<H1_TextMiddle>{workflow?.form?.description}</H1_TextMiddle>}
              onSave={onEditDescription}
            />
          </H1_FlexColumn>
          {variables?.map((variable) => {
            return (
              <VariableItem
                hideVariableName={liveMode}
                onChangeVariableValue={(value) => onChangeVariableValue(variable.id, value)}
                variable={variable}
                key={variable.id}
                viewOnly={liveMode}
              />
            );
          })}
          <Button
            color="primary"
            isLoading={createVideoLoading}
            onClick={onClickCreateVideo}
            startContent={<i className="fa-light fa-video" />}
          >
            Create video
          </Button>
        </Container>

        <ConditionalRender condition={liveMode}>
          <StyledShare color="primary" onClick={onOpenShareWorkflow}>
            Share
          </StyledShare>
        </ConditionalRender>
      </H1_FlexColumn>
      <VideoRenderPreview
        variableValues={variableValues}
        videoId={workflowPlayingVideoId as string}
        totalSml={totalSml}
      />
    </H1_FlexRow>
  );
};

export default EditForm;
