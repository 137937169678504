import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextSmall,
  H1_TextSubtitle,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { videoWizardV2Messages } from "app/components/common/VideoWizardModalV2/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import VideoWizardRatioCard from "app/components/common/VideoWizardModalV2/VideoWizardRatioCard";
import {
  VideoWizardCategory,
  VideoWizardDuration,
  VideoWizardRatio,
  VideoWizardStyle,
  VideoWizardV2Properties,
  VideoWizardWorkflowSubCategory
} from "app/types";
import React, { Key, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";
import {
  getCategoriesOptions,
  getDurationOptions,
  getStylesOptions,
  getSubCategoryOptions
} from "app/store/selectorsV2/drafts.selectors";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button, Radio, RadioGroup, Select, SelectItem } from "@nextui-org/react";
import NextUITooltip from "app/components/common/NextUI/Tooltip/NextUITooltip";

const BorderedButton = styled(Button)`
  align-self: flex-end;
  min-width: 137px;
`;

const BorderFlexRow = styled(H1_FlexRow)`
  border-top: 1px solid ${({ theme }) => theme.gray4};
`;

const BorderFlexColumn = styled(H1_FlexColumn)`
  border-top: 1px solid ${({ theme }) => theme.gray4};
`;

const RadioFlexRow = styled(H1_FlexRow)`
  label {
    height: 22px;
    margin-right: 0;
  }
`;

const CorporateStyleBox = styled(H1_FlexRow)<{ $selected: boolean }>`
  border: 1px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : "transparent")};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.gray4} 50%,
    ${({ theme }) => theme.blue1} 50%
  );
`;

const FunStyleBox = styled(H1_FlexRow)<{ $selected: boolean }>`
  border: 1px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : "transparent")};
  background-color: ${({ theme }) => theme.gray4};
  background-image: linear-gradient(
      45deg,
      ${({ theme }) => theme.blue1} 25%,
      transparent 25%,
      transparent 75%,
      ${({ theme }) => theme.blue1} 75%,
      ${({ theme }) => theme.blue1}
    ),
    linear-gradient(
      -45deg,
      ${({ theme }) => theme.blue1} 25%,
      transparent 25%,
      transparent 75%,
      ${({ theme }) => theme.blue1} 75%,
      ${({ theme }) => theme.blue1}
    );
  background-size: 15px 15px; /* Size of the squares */
  background-repeat: repeat;
`;

const BasicStyleBox = styled(H1_FlexRow)<{ $selected: boolean }>`
  border: 1px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : "transparent")};
  background-color: ${({ theme }) => theme.gray4};
  background-image: radial-gradient(
      circle at 0 0,
      ${({ theme }) => theme.blue1} 20%,
      transparent 20%
    ),
    radial-gradient(circle at 100% 100%, ${({ theme }) => theme.blue1} 20%, transparent 20%);
`;

const CenterFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
  background-color: white;
`;

interface VideoWizardV2Stage1Props {
  onClickNext: () => void;
  onChangeProperties: (key: keyof VideoWizardV2Properties, value: any) => void;
  style?: VideoWizardStyle;
  category?: string;
  subCategory?: string;
  subCategoryTitle?: string;
  duration?: string;
  ratio?: string;
}
const VideoWizardV2Stage1 = ({
  onClickNext,
  onChangeProperties,
  style,
  ratio,
  category,
  subCategory,
  duration,
  subCategoryTitle
}: VideoWizardV2Stage1Props) => {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [shouldHideSubCategory, setShouldHideSubCategory] = useState(
    subCategoryTitle === "Anything"
  );
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const categoryOptions = useAppSelector(getCategoriesOptions);
  const durationOptions = useAppSelector(getDurationOptions);
  const stylesOptions = useAppSelector((state) => getStylesOptions(state, ratio));
  const subCategoryOptions: VideoWizardWorkflowSubCategory[] = useAppSelector((state) =>
    getSubCategoryOptions(state, category)
  );

  const ratioLandscape = ratio === VideoWizardRatio.landscape;
  const ratioPortrait = ratio === VideoWizardRatio.portrait;
  const ratioSquare = ratio === VideoWizardRatio.square;

  useEffect(() => {
    if (categoryOptions.length > 0 && !category) {
      onChangeProperties("category", categoryOptions[0].value);
    }
    if (category && initialLoaded) {
      const subCategoryValue = subCategoryOptions[0].value;
      const subCategoryLabel = subCategoryOptions[0].label;
      setShouldHideSubCategory(subCategoryLabel === "Anything");
      onChangeProperties("subCategory", subCategoryValue);
      onChangeProperties("subCategoryTitle", subCategoryLabel);
    }
    setInitialLoaded(true);
  }, [categoryOptions, category]);

  useEffect(() => {
    if (subCategoryOptions.length > 0 && !subCategory) {
      const subCategoryValue = subCategoryOptions[0].value;
      onChangeProperties("subCategory", subCategoryValue);
      onChangeProperties("subCategoryTitle", subCategoryOptions[0].label);
    }
  }, [subCategoryOptions]);

  useEffect(() => {
    if (durationOptions.length > 0 && !duration) {
      onChangeProperties("subCategoryTitle", durationOptions[0].value);
    }
  }, [durationOptions]);

  const onChangeDuration = (value: string) => {
    onChangeProperties("duration", value as VideoWizardDuration);
  };

  const onChangeCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory: VideoWizardCategory = e.target.value as VideoWizardCategory;
    onChangeProperties("category", selectedCategory);
    onChangeProperties("variablesValues", {});
  };

  const onChangeRatio = (selectedRatio: VideoWizardRatio) => {
    onChangeProperties("ratio", selectedRatio);
  };

  const onChangeSubCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSubCategory: string = e.target.value;
    onChangeProperties("subCategory", selectedSubCategory);
    const currentSubCategory = subCategoryOptions.find(
      ({ value }) => value === selectedSubCategory
    );
    if (currentSubCategory) {
      onChangeProperties("subCategoryTitle", currentSubCategory.label);
    }
  };

  const onClickCtaButton = () => {
    const recipeId = stylesOptions.find((currentStyle) => currentStyle.style === style)?.recipe_id;
    if (!recipeId) {
      return;
    }
    onChangeProperties("recipeId", recipeId);
    onClickNext();
  };

  const onClickStyle = (style: VideoWizardStyle) => {
    onChangeProperties("style", style);
  };

  return (
    <H1_FlexRow flex="0 0 auto" height="631px">
      <H1_FlexColumn
        justify="space-between"
        align="center"
        flex="0 0 518px"
        padding="49px 41px 39px"
        width="518px"
      >
        <H1_FlexColumn align="center" gap="41px">
          <H1_FlexColumn flex="0 1 auto">
            <H1_TextSubtitle lineHeight="18px">
              {formatMessage(videoWizardV2Messages.title)}
            </H1_TextSubtitle>
            <BorderFlexRow
              width="436px"
              align="center"
              justify="flex-start"
              gap="14px"
              margin="17px 0 0 0"
              padding="17px 0 0 0"
            >
              <H1_TextSmall color={theme.gray8}>
                {formatMessage(videoWizardV2Messages.videoFor)}
              </H1_TextSmall>
              <Select
                size="sm"
                items={categoryOptions}
                labelPlacement="outside"
                onChange={onChangeCategory}
                value={category}
                selectedKeys={[category as Key]}
                className="max-w-xs"
              >
                {(option: any) => (
                  <SelectItem key={option.value} textValue={option.label}>
                    <H1_TextSmall>{option.label}</H1_TextSmall>
                  </SelectItem>
                )}
              </Select>
              <ConditionalRender condition={!shouldHideSubCategory}>
                <NextUITooltip
                  delay={700}
                  content={subCategory ? subCategoryTitle : null}
                  placement="left-start"
                >
                  <Select
                    className="max-w-xs"
                    size="sm"
                    items={subCategoryOptions}
                    labelPlacement="outside"
                    onChange={onChangeSubCategory}
                    value={subCategory}
                    selectedKeys={[subCategory as Key]}
                  >
                    {(option: any) => (
                      <SelectItem key={option.value} textValue={option.label}>
                        <H1_TextSmall>{option.label}</H1_TextSmall>
                      </SelectItem>
                    )}
                  </Select>
                </NextUITooltip>
              </ConditionalRender>
            </BorderFlexRow>
          </H1_FlexColumn>
          <H1_FlexColumn width="100%">
            <H1_TextXs lineHeight="18px">
              {formatMessage(videoWizardV2Messages.choosePace)}
            </H1_TextXs>
            <BorderFlexRow align="center" gap="14px" margin="17px 0 0 0" padding="15px 0 0 0">
              <RadioGroup onValueChange={onChangeDuration} value={duration} color="primary">
                <RadioFlexRow gap="28px">
                  {durationOptions.map((option) => (
                    <Radio
                      key={option.value}
                      value={option.value}
                      classNames={{
                        label: "text-sm"
                      }}
                    >
                      {option.label}
                    </Radio>
                  ))}
                </RadioFlexRow>
              </RadioGroup>
            </BorderFlexRow>
          </H1_FlexColumn>
          <H1_FlexColumn width="100%">
            <H1_TextXs lineHeight="18px">
              {formatMessage(videoWizardV2Messages.chooseFormat)}
            </H1_TextXs>
            <BorderFlexColumn padding="15px 0 0 0" margin="17px 0 0 0">
              <H1_FlexRow gap="14px" align="center">
                <VideoWizardRatioCard
                  text={formatMessage(videoWizardV2Messages.landscape)}
                  onClick={onChangeRatio}
                  selected={ratioLandscape}
                  ratio={VideoWizardRatio.landscape}
                />
                <VideoWizardRatioCard
                  text={formatMessage(videoWizardV2Messages.portrait)}
                  onClick={onChangeRatio}
                  selected={ratioPortrait}
                  ratio={VideoWizardRatio.portrait}
                />
                <VideoWizardRatioCard
                  text={formatMessage(videoWizardV2Messages.square)}
                  onClick={onChangeRatio}
                  selected={ratioSquare}
                  ratio={VideoWizardRatio.square}
                />
              </H1_FlexRow>
            </BorderFlexColumn>
          </H1_FlexColumn>
          <H1_FlexColumn width="100%">
            <H1_TextXs lineHeight="18px">
              {formatMessage(videoWizardV2Messages.chooseStyle)}
            </H1_TextXs>
            <BorderFlexColumn padding="17px 0 0 0" margin="17px 0 0 0">
              <H1_FlexRow gap="14px" align="center">
                <CorporateStyleBox
                  justify="center"
                  align="center"
                  width="136px"
                  height="53px"
                  onClick={() => onClickStyle(VideoWizardStyle.corporate)}
                  $selected={style === VideoWizardStyle.corporate}
                >
                  <CenterFlexRow justify="center" align="center" height="27px" padding="0 10px">
                    {formatMessage(videoWizardV2Messages.corporate)}
                  </CenterFlexRow>
                </CorporateStyleBox>
                <FunStyleBox
                  justify="center"
                  align="center"
                  width="136px"
                  height="53px"
                  $selected={style === VideoWizardStyle.fun}
                  onClick={() => onClickStyle(VideoWizardStyle.fun)}
                >
                  <CenterFlexRow justify="center" align="center" height="27px" padding="0 10px">
                    {formatMessage(videoWizardV2Messages.fun)}
                  </CenterFlexRow>
                </FunStyleBox>
                <BasicStyleBox
                  $selected={style === VideoWizardStyle.basic}
                  justify="center"
                  align="center"
                  width="136px"
                  height="53px"
                  onClick={() => onClickStyle(VideoWizardStyle.basic)}
                >
                  <CenterFlexRow justify="center" align="center" height="27px" padding="0 10px">
                    {formatMessage(videoWizardV2Messages.basic)}
                  </CenterFlexRow>
                </BasicStyleBox>
              </H1_FlexRow>
            </BorderFlexColumn>
          </H1_FlexColumn>
        </H1_FlexColumn>
        <BorderedButton color="primary" onClick={onClickCtaButton}>
          {formatMessage(videoWizardV2Messages.ctaButtonNext)}
        </BorderedButton>
      </H1_FlexColumn>
      <H1_FlexRow flex="0 0 361px">
        <img
          height="100%"
          width="100%"
          src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/document.webp"
          alt="workflow1"
        />
      </H1_FlexRow>
    </H1_FlexRow>
  );
};

export default VideoWizardV2Stage1;
