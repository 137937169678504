import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Tag } from "antd";
import messages from "app/components/common/player/messages";
import { useState } from "react";
import styled from "styled-components";

const { CheckableTag } = Tag;

const StyledCheckableTag = styled(CheckableTag)`
  margin: auto;
  display: block;
  width: 100%;
  text-align: center;
  margin-right: 40px;
  /* Outside of theme context. useTheme() */
  border-color: #5a5aff;
  color: #5a5aff;
  padding: 10px;

  &&& {
    :hover,
    &.ant-tag-checkable-checked {
      font-weight: 300;
      color: white;
      border-color: white;
      background-color: #5a5aff;
    }
  }
`;

const FeedbackModal = ({
  onSelect,
  options
}: {
  onSelect: (val: string[]) => void;
  options: { value: string }[];
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    onSelect(nextSelectedTags);
  };
  return (
    <>
      <H1_FlexRow gap="8px" wrap="wrap">
        <H1_TextSubtitle>{messages.feedbackModalSubtitle.defaultMessage}</H1_TextSubtitle>
        <H1_FlexRow gap="10px" wrap="wrap">
          {options.map((tag) => (
            <StyledCheckableTag
              key={tag.value}
              checked={selectedTags.includes(tag.value)}
              onChange={(checked) => handleChange(tag.value, checked)}
            >
              {tag.value}
            </StyledCheckableTag>
          ))}
        </H1_FlexRow>
      </H1_FlexRow>
    </>
  );
};

export default FeedbackModal;
