import { defineMessages } from "react-intl";

const messages = defineMessages({
  playerHeadline: {
    id: "player.headline",
    defaultMessage: "Player"
  },
  unAuthorized: {
    id: "403.unauthorized.error",
    defaultMessage: "Sorry, you are not authorized to access this page"
  },
  playerBackButtonLabel: {
    id: "player.back.button.label",
    defaultMessage: "Back Home"
  }
});
export default messages;
