import { useEffect } from "react";

const usePreloadAudio = (urls: string[]) => {
  useEffect(() => {
    // Create a new Audio object
    urls.forEach((url) => {
      const audio = new Audio();
      audio.src = url;

      audio.preload = "auto";
      audio.load();
    });
  }, [...urls]);
  return;
};

export default usePreloadAudio;
