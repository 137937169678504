import { VideoWizardRatio } from "app/types";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useMemo } from "react";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const BorderFlexRow = styled(H1_FlexRow)<{ $selected: boolean }>`
  border-radius: 4px;
  border: 1px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray4)};
  background-color: ${({ theme, $selected }) => ($selected ? theme.gray2 : theme.gray1)};
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
`;

const LayoutDiv = styled(H1_FlexColumn)<{ $selected: boolean }>`
  border-radius: 2px;
  background-color: ${({ theme, $selected }) => ($selected ? theme.blue1 : theme.gray4)};
  border: 1px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray4)};
`;

interface VideoWizardRatioCardProps {
  text: string;
  onClick: (ratio: VideoWizardRatio) => void;
  ratio: VideoWizardRatio;
  selected: boolean;
}

const VideoWizardRatioCard = ({ selected, ratio, onClick, text }: VideoWizardRatioCardProps) => {
  const theme = useTheme();
  const imageWidth = useMemo(() => {
    switch (ratio) {
      case VideoWizardRatio.landscape:
        return "38px";
      case VideoWizardRatio.portrait:
        return "14px";
      case VideoWizardRatio.square:
        return "23px";
      default:
        return "";
    }
  }, [ratio]);

  const onClickCard = () => {
    onClick(ratio);
  };

  return (
    <BorderFlexRow
      width="136px"
      height="53px"
      justify="center"
      $selected={selected}
      onClick={onClickCard}
      align="center"
      gap="10px"
    >
      <H1_FlexColumn height="70px" align="center" justify="center">
        <LayoutDiv height="23px" width={imageWidth} $selected={selected} />
      </H1_FlexColumn>
      <H1_TextSmall color={selected ? theme.blue4 : theme.gray8} lineHeight="14px">
        {text}
      </H1_TextSmall>
    </BorderFlexRow>
  );
};

export default VideoWizardRatioCard;
