import config from "app/config/Config";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import redux from "app/store/store";
import { isMobile } from "react-device-detect";

declare global {
  interface Window {
    analytics: any;
  }
}

const _getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) return (parts.pop() as string).split(";").shift();
};

export const load = (): void => {
  if (config.env.includes("local")) return;
  if ("load" in window.analytics) {
    window.analytics.load(config.segmentWriteKey);
  }
};
export const page = (): void => {
  if (config.env.includes("local")) return;
  window.analytics.page();
};
export const track = (name: string, properties: any): void => {
  if (config.env.includes("local")) return;
  window.analytics.track(name, {
    ...properties,
    userAgent: navigator.userAgent,
    email: redux.store.getState().user.currentEmail,
    workspaceId: getCurrentWorkspace(redux.store.getState())?.id,
    teamId: getCurrentWorkspace(redux.store.getState())?.current_team?.id,
    isMobile
  });
};
export const identify = (
  userId: string,
  permissions: string,
  userEmail: string,
  workspaceId: string,
  teamId: string,
  data: any = {},
  isMobile: boolean
): void => {
  if (config.env.includes("local")) return;
  const gaSession = _getCookie("_ga_E17BVWNDZK");
  const gaClient = _getCookie("_ga");
  const gaClickId = _getCookie("_gcl_aw");
  const fbUser = _getCookie("_fbp");
  const fbClickId = _getCookie("_fbc");
  window.analytics.identify(userId, {
    permissions,
    email: userEmail,
    workspaceId,
    teamId,
    isMobile,
    ga: {
      gaSession,
      gaClickId,
      gaClient
    },
    fb: {
      fbUser,
      fbClickId
    },
    ...data
  });
};

export const enrichIdentify = (userId: string, data: object = {}): void => {
  if (config.env.includes("local")) return;
  window.analytics.identify(userId, data);
};

export const reset = (): void => {
  if (config.env.includes("local")) return;
  window.analytics.reset();
};
