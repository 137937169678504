import { H1_TextMiddle, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ReactNode } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";

interface TitledInputCrownProps {
  title: string | ReactNode;
  rightElement?: ReactNode;
  children: ReactNode;
  error?: string;
  gap?: string;
}
const TitledInput = ({ title, children, rightElement, error, gap }: TitledInputCrownProps) => {
  return (
    <H1_FlexColumn flex={"0 0 auto"} gap={gap || "8px"}>
      <H1_FlexRow gap={"9px"} justify={"space-between"}>
        <H1_TextMiddle>{title}</H1_TextMiddle>
        {rightElement}
      </H1_FlexRow>
      <H1_FlexColumn>
        {children}
        <ConditionalRender condition={!!error}>
          <H1_TextXs color={"red"}>{error}</H1_TextXs>
        </ConditionalRender>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default TitledInput;
