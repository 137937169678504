import styled, { useTheme } from "styled-components";
import RoundButton from "app/components/common/RoundButton";
import useCopyLink from "app/hooks/useCopyLink";
import ChannelsPageForkButton from "app/pages/Channels/ChannelsPageForkButton";
import { Popover, Tooltip } from "antd";
import VideoAnalyticsPopover from "app/pages/Channels/VideoAnalyticsPopover";
import { useIntl } from "react-intl";
import { channelsMessages } from "app/pages/Channels/messages";
import { useSingleVideoContext } from "app/components/common/Reactions/ChannelsPageSingleVideoContext";
import ConditionalRender from "app/components/common/ConditionalRender";
import React from "react";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const UnderButtonNumSum = styled(H1_TextXs)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: auto;
  width: 100%;
`;

const ChannelsPageSingleVideoSideButtons = ({
  showForkButton = true
}: {
  showForkButton: boolean;
}) => {
  const intl = useIntl();
  const { video } = useSingleVideoContext();
  const theme = useTheme();

  const { copyLink } = useCopyLink();
  const onClickCopyLink = () => {
    copyLink("channels_page_copy_link", video, video.draft_id);
  };

  return (
    <H1_FlexColumn width="48px" align="flex-start" justify="start" gap="10px">
      <Popover
        destroyTooltipOnHide
        title={
          <H1_FlexRow justify="space-between" align="center">
            <H1_TextXs color={theme.blue4} fontWeight={590} padding="0 16px">
              {intl.formatMessage(channelsMessages.videoAnalyticsPopoverTitle)}
            </H1_TextXs>
            <ConditionalRender condition={Boolean(video.analytics_updated_at)}>
              <Tooltip
                showArrow={false}
                placement="topLeft"
                title={
                  <H1_TextXs fontSize="10px" color={theme.gray1}>
                    {intl.formatMessage(channelsMessages.videoAnalyticsPopoverViewersUpdatedAt, {
                      updated: new Date(video.analytics_updated_at as string).toLocaleString()
                    })}
                  </H1_TextXs>
                }
              >
                <H1_FlexRow margin="0 16px 0 0">
                  <i className="fa-light fa-circle-exclamation" />
                </H1_FlexRow>
              </Tooltip>
            </ConditionalRender>
          </H1_FlexRow>
        }
        content={<VideoAnalyticsPopover />}
        placement="leftTop"
        align={{
          offset: [7, -1]
        }}
        overlayClassName="views-popover"
        showArrow={false}
        getPopupContainer={() =>
          document.querySelector(`.video-container-${video.id}`) as HTMLElement
        }
      >
        <H1_FlexColumn>
          <RoundButton icon={<i className="fa-solid fa-eye" />} />
          <UnderButtonNumSum fontSize="10px" textAlign="center">
            {video.views || 0}
          </UnderButtonNumSum>
        </H1_FlexColumn>
      </Popover>
      {/* <RoundButton icon={<i className="fa-solid fa-share-nodes" />} /> */}
      <ConditionalRender condition={showForkButton}>
        <ChannelsPageForkButton draftId={video.versioned_draft_id} />
      </ConditionalRender>
      <Tooltip title={intl.formatMessage(channelsMessages.copyLinkButtonTooltip)}>
        <RoundButton onClick={onClickCopyLink} icon={<i className="fa-solid fa-link" />} />
      </Tooltip>
    </H1_FlexColumn>
  );
};

export default ChannelsPageSingleVideoSideButtons;
