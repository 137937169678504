import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Button, Layout, List, Row, Tag } from "antd";
import { useIntl } from "react-intl";
import { FeatureFlag, RecipeList, RecipeType } from "app/types";
import { templatesMessages } from "app/components/editor/templateSelect/messages";
import TemplateRequest from "app/components/editor/templateSelect/TemplateRequest";
import { useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAuth } from "app/auth/useAuth";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const { Footer } = Layout;

// region [[ Styles ]]

const TemplateSelectSideMenuView = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const MenuOption = styled(List.Item)<{ $selected: boolean }>`
  ${({ $selected, theme }) => css`
    padding: 0px !important;
    text-decoration: ${$selected ? "underline" : "unset"};
    color: ${$selected ? theme.pink4 : "#7e7e7e"};
    cursor: pointer;
  `};
  height: 30px;
`;

const MenuList = styled(List)`
  margin-bottom: 10px;
  .ant-list-header {
    padding-bottom: 3px;
  }
` as typeof List;

const CategoryHeader = styled(List)`
  font-weight: 700;
  font-size: 1rem;
`;

const PresentationButton = styled(Button)`
  background-color: ${(props) => props.theme.pink4};
  color: white;

  margin-bottom: 10px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.pink4};
    color: white;
  }
`;

const TemplateTag = styled(Tag)`
  background-color: ${(props) => props.theme.pink4};
  color: white;
  font-size: 8px;
  height: 14px;
  border-radius: 2px;
  line-height: 13px;
  margin-left: 10px;
  border: none;
  text-align: center;
`;

const LeftSideSliderColumn = styled(H1_FlexColumn)`
  direction: rtl;
  padding-left: 40px;
  * {
    direction: ltr;
  }
`;

const AlignedFooter = styled(Footer)`
  &&& {
    padding-left: 40px;
  }
`;
// endregion [[ Styles ]]

// region [[ Props ]]

export interface TemplateSelectSideMenuProps {
  sections: RecipeList[];
  customSections: RecipeList[];
  personalSections: RecipeList[];
  selectedSection?: string;
  selectedSectionType?: RecipeType;
  onSelectSection: (section: RecipeList | undefined, type?: RecipeType) => void;
  onPresentation?: () => void;
}

const TemplateSelectSideMenu = ({
  sections,
  customSections,
  personalSections,
  selectedSection,
  selectedSectionType,
  onSelectSection,
  onPresentation
}: TemplateSelectSideMenuProps) => {
  const intl = useIntl();
  const flags = useFlags();
  const { isAuthenticated } = useAuth();

  const presentationImportFlag = useAppSelector(
    (state) => state.user.featureFlags.presentationImport
  );
  const getMenuOption = (item: RecipeList, recipeType?: RecipeType) => {
    return (
      <MenuOption
        onClick={() => {
          onSelectSection(item, recipeType);
        }}
        $selected={selectedSection === item.name && selectedSectionType === recipeType}
        data-auto-id={`template-category-${item.name}`}
      >
        <Row align="middle">
          <span>{item.name}</span>
          {!!item.tags?.length && <TemplateTag>{item.tags[0]}</TemplateTag>}
        </Row>
      </MenuOption>
    );
  };
  const allTemplateOption = () => {
    return (
      <MenuOption
        onClick={() => {
          onSelectSection(undefined);
        }}
        $selected={!selectedSection}
      >
        <Row align="middle">
          <span> {intl.formatMessage(templatesMessages.allTemplatesButton)}</span>
        </Row>
      </MenuOption>
    );
  };
  const sectionsWithAll = useMemo(() => {
    return sections.length ? [{ name: "All", items: [], tags: [] }, ...sections] : [];
  }, [sections]);

  return (
    <TemplateSelectSideMenuView>
      <H1_FlexColumn flex="1 1 auto">
        <LeftSideSliderColumn overflow="auto">
          <ConditionalRender condition={presentationImportFlag}>
            <PresentationButton shape="round" onClick={onPresentation}>
              {intl.formatMessage(templatesMessages.presentationButton)}
            </PresentationButton>
          </ConditionalRender>

          <H1_FlexColumn>
            {!!personalSections.length && flags[FeatureFlag.personalTemplates] && (
              <MenuList
                header={
                  <CategoryHeader>
                    {intl.formatMessage(templatesMessages.personalTitle)}
                  </CategoryHeader>
                }
                size="small"
                dataSource={personalSections}
                renderItem={(record) => getMenuOption(record, RecipeType.personal)}
              />
            )}

            {!!sections.length && (
              <MenuList
                header={
                  <CategoryHeader>{intl.formatMessage(templatesMessages.listTitle)}</CategoryHeader>
                }
                size="small"
                dataSource={sectionsWithAll}
                renderItem={(record, index) => {
                  if (index === 0) {
                    return allTemplateOption();
                  }
                  return getMenuOption(record);
                }}
              />
            )}

            {!!customSections.length && (
              <MenuList
                header={
                  <CategoryHeader>
                    {intl.formatMessage(templatesMessages.customTitle)}
                  </CategoryHeader>
                }
                size="small"
                dataSource={customSections}
                renderItem={(record) => getMenuOption(record, RecipeType.custom)}
              />
            )}
          </H1_FlexColumn>
        </LeftSideSliderColumn>
      </H1_FlexColumn>
      <ConditionalRender condition={isAuthenticated}>
        <AlignedFooter>
          <TemplateRequest />
        </AlignedFooter>
      </ConditionalRender>
    </TemplateSelectSideMenuView>
  );
};
export default TemplateSelectSideMenu;
