import styled from "styled-components";

const StyledArrowRotateIcon = styled.i`
  width: 22px;
  height: 22px;
  z-index: 2;
  color: ${(props) => props.theme.gray1};
  background: ${(props) => props.theme.gray11};
  padding: 17px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowRotateIcon = () => {
  return <StyledArrowRotateIcon className="far fa-sync" />;
};
export default ArrowRotateIcon;
