import { defineMessages } from "react-intl";

export const templateDrawerMessages = defineMessages({
  selectTemplateConfirmTitle: {
    id: "templateDrawer.select-template-confirm-title",
    defaultMessage: "Change Template"
  },
  selectTemplateConfirmBody: {
    id: "templateDrawer.select-template-confirm-body-1",
    defaultMessage:
      "You are about to change your template. Depending on the chosen template, you may lose your media and/or title.\nDo you wish to continue?"
  },
  selectTemplateConfirmBody1: {
    id: "templateDrawer.select-template-confirm-body1",
    defaultMessage:
      "You are about to change your template. Depending on the chosen template, you may lose your media and/or title."
  },
  selectTemplateConfirmBody2: {
    id: "templateDrawer.select-template-confirm-body2",
    defaultMessage: "Do you wish to continue?"
  },
  all: {
    id: "templateDrawer.all",
    defaultMessage: "All"
  },
  continue: {
    id: "templateDrawer.continue",
    defaultMessage: "Continue"
  },
  cancel: {
    id: "templateDrawer.cancel",
    defaultMessage: "Cancel"
  },
  unsupportedCharacter: {
    id: "templateDrawer.unsupported-template",
    defaultMessage:
      "The current template is not compatible with this template. Please switch to a different template to use it."
  }
});
