import styled, { useTheme } from "styled-components";
import { Button, Divider } from "antd";
import { ReactComponent as HourOneNewLogo } from "app/assets/images/h1loginlogo.svg";
import { fetchSecured } from "app/store/security";
import Config from "app/config/Config";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { trackEvent } from "app/store/thunks/analyticsEvents.thunk";
import { useIntl } from "react-intl";
import { loginMessages } from "app/pages/login/messages";
import { onOpenZendesk } from "app/utils/zendesk/zendesk";
import { useSearchParams } from "react-router-dom";
import { containsUrl } from "app/utils/helpers";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextMiddle,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import { useEffect, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { useAuth } from "app/auth/useAuth";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { Location } from "app/types";
import { useNavigate } from "react-router-dom";

const SignupSuccessView = styled.div`
  background-color: white;
`;

const Logo = styled.div`
  display: inline;
  max-height: 30px;
  margin-left: 5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  padding-top: 32px;
  padding-bottom: 0px;
`;

const TopPart = styled.div`
  margin-top: -100px;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  justify-content: center;
`;

const BottomPart = styled.div`
  display: flex;
  align-items: center;
`;

const SendButton = styled(Button)`
  background-color: ${(props) => props.theme.blue4};
  border: unset;
  width: auto;

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.blue4};
  }
`;

const CardWrapper = styled.div`
  position: relative;
  height: inherit;

  min-height: inherit;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Card = styled.div`
  /* overflow: hidden; */
  margin: 30px auto;
  background-color: #fff;
  display: flex;
  max-width: 1200px;

  flex-direction: column;
  height: auto;
  border-radius: 12px;
  padding: 12px;

  @include cubic;
  @media screen and (max-width: 750px) {
    min-width: 0;
    max-width: 100%;
  }
`;

const StyledSpan = styled("span")`
  color: #e38d20;
`;

const ContactSupport = styled("a")`
  color: #e38d20;
  text-decoration: underline;
`;

const SignupSuccess = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [params] = useSearchParams();
  const email = params.get("email");

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const auth = useAuth();
  const navigate = useNavigate();

  const pendingAuthenticationToken = useAppSelector(
    (state) => state.user.pendingAuthenticationToken
  );
  useEffect(() => {
    if (isEmailSent) {
      setTimeout(() => {
        setIsEmailSent(false);
      }, 2000);
    }
  }, [isEmailSent]);

  const sendVerificationEmail = async () => {
    try {
      if (!!email && !containsUrl(email)) {
        setIsLoading(true);
        dispatch(
          trackEvent({
            eventName: "app:user_sendVerification",
            properties: {}
          })
        );
        await fetchSecured(`${Config.serverUrl}/v1/users/me/mail_verification`, {
          method: "POST",
          body: JSON.stringify({ email })
        });
        setIsEmailSent(true);
        setIsLoading(false);
      }
    } catch (err) {
      Sentry.captureException(err, {
        extra: { description: "unhandled email send verification error", email: email }
      });
    }
  };

  const codeSubmit = async () => {
    if (!code || !auth.createToken) {
      return;
      //   todo close the flow.
    }
    setLoading(true);
    try {
      await auth.createToken({
        type: "verification_code",
        verification_code: code,
        email: email as string,
        pending_authentication_token: pendingAuthenticationToken
      });

      navigate(Location.OnBoardingV2);
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err?.response?.status) {
        setCodeError(
          err?.response?.data.detail ||
            "Failed to verify code, please try again by resend verification email"
        );
      } else {
        Sentry.captureException(err, {
          extra: {
            description: "unexpected error on code submit"
          }
        });
        setCodeError("Failed to verify code, please try again");
      }
    }

    setLoading(false);
  };

  // @ts-ignore handels intl formatting with elements issue
  const verificationLinkSent = formatMessage(loginMessages.verificationLinkSent, {
    span: (...text) => <StyledSpan>{text}</StyledSpan>
  }) as React.ReactNode;

  // @ts-ignore handels intl formatting with elements issue
  const verificationLinkSubtitle = formatMessage(loginMessages.verificationLinkSubtitle, {
    email: email || formatMessage(loginMessages.yourEmail),
    span: (...text) => <StyledSpan>{text}</StyledSpan>
  }) as React.ReactNode;

  if (pendingAuthenticationToken) {
    return (
      <SignupSuccessView className="red-top-bar full-height">
        <img
          style={{ display: "none" }}
          src={`https://coon1.hourone.ai/?e=${window.btoa(email || "")}`}
          alt=""
        />
        {/*todo currently it here because auth provider need it.*/}
        <span id="auth-lock-form" />

        <CardWrapper>
          <Card>
            <TopPart>
              <Logo>
                <HourOneNewLogo height={50} />
              </Logo>
            </TopPart>

            <BottomPart>
              <FormContainer>
                <H1_TextHeadline margin="0 0 10px 0">{verificationLinkSent}</H1_TextHeadline>
                <H1_TextMiddle>{verificationLinkSubtitle}</H1_TextMiddle>
                <H1_TextMiddle margin="0 0 10px 0" textAlign="center">
                  {formatMessage(loginMessages.verificationCode)}
                </H1_TextMiddle>
                <H1_FlexColumn gap={"10px"}>
                  <H1_Input
                    onChange={(evt) => setCode(evt.target.value)}
                    placeholder={"code"}
                  ></H1_Input>
                  <SendButton
                    disabled={!code}
                    loading={loading}
                    type="primary"
                    onClick={codeSubmit}
                  >
                    Submit
                  </SendButton>
                  <ConditionalRender condition={!!codeError}>
                    <H1_TextSmall color={"red"}>{codeError}</H1_TextSmall>
                  </ConditionalRender>
                </H1_FlexColumn>
                <Divider />
                <H1_FlexColumn align="center" gap="20px" justify="space-around" margin="10px 0 0 0">
                  <H1_FlexColumn align="center" justify="space-around">
                    <H1_TextSmall color={theme.gray7}>
                      {formatMessage(loginMessages.noEmail)}
                    </H1_TextSmall>
                    <H1_TextSmall color={theme.gray7}>
                      {
                        // @ts-ignore handels intl formatting with elements issues
                        formatMessage(loginMessages.checkSpam, {
                          a: (str) => <ContactSupport onClick={onOpenZendesk}>{str}</ContactSupport>
                        }) as React.ReactNode
                      }
                    </H1_TextSmall>
                  </H1_FlexColumn>
                  <ConditionalRender condition={!isEmailSent && !!email}>
                    <SendButton loading={isLoading} type="primary" onClick={sendVerificationEmail}>
                      {formatMessage(loginMessages.sendAgainButton)}
                    </SendButton>
                  </ConditionalRender>
                  <ConditionalRender condition={isEmailSent}>
                    <H1_TextSmall>{formatMessage(loginMessages.emailSent)}</H1_TextSmall>
                  </ConditionalRender>
                </H1_FlexColumn>
              </FormContainer>
            </BottomPart>
          </Card>
        </CardWrapper>
      </SignupSuccessView>
    );
  }
  return (
    <SignupSuccessView className="red-top-bar full-height">
      <img
        style={{ display: "none" }}
        src={`https://coon1.hourone.ai/?e=${window.btoa(email || "")}`}
        alt=""
      />
      <span id="auth-lock-form" />

      <CardWrapper>
        <Card>
          <TopPart>
            <Logo>
              <HourOneNewLogo height={50} />
            </Logo>
          </TopPart>

          <BottomPart>
            <FormContainer>
              <H1_TextHeadline margin="0 0 10px 0">{verificationLinkSent}</H1_TextHeadline>
              <H1_TextMiddle>{verificationLinkSubtitle}</H1_TextMiddle>
              <H1_TextMiddle margin="0 0 10px 0" textAlign="center">
                {formatMessage(loginMessages.verificationClickHere)}
              </H1_TextMiddle>
              <Divider />
              <H1_FlexColumn align="center" gap="20px" justify="space-around" margin="10px 0 0 0">
                <H1_FlexColumn align="center" justify="space-around">
                  <H1_TextSmall color={theme.gray7}>
                    {formatMessage(loginMessages.noEmail)}
                  </H1_TextSmall>
                  <H1_TextSmall color={theme.gray7}>
                    {
                      // @ts-ignore handels intl formatting with elements issues
                      formatMessage(loginMessages.checkSpam, {
                        a: (str) => <ContactSupport onClick={onOpenZendesk}>{str}</ContactSupport>
                      }) as React.ReactNode
                    }
                  </H1_TextSmall>
                </H1_FlexColumn>
                <ConditionalRender condition={!isEmailSent}>
                  <SendButton loading={isLoading} type="primary" onClick={sendVerificationEmail}>
                    {formatMessage(loginMessages.sendAgainButton)}
                  </SendButton>
                </ConditionalRender>
                <ConditionalRender condition={isEmailSent}>
                  <H1_TextSmall>{formatMessage(loginMessages.emailSent)}</H1_TextSmall>
                </ConditionalRender>
              </H1_FlexColumn>
            </FormContainer>
          </BottomPart>
        </Card>
      </CardWrapper>
    </SignupSuccessView>
  );
};

export default SignupSuccess;
