import { cloneVoiceMessages } from "app/components/editor/cloneVoice/messages";
import FilePlayer from "app/components/common/FilePlayer";

import styled, { useTheme } from "styled-components";
import { Checkbox, Input } from "antd";
import { useIntl } from "react-intl";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

interface ApproveViewProps {
  name?: string;
  onChangeName: (evt: any) => void;
  file?: File;
  playing: boolean;
  onPlay: () => void;
  onStop: () => void;
  description?: string;
  onChangeDescription: (evt: any) => void;
  approveTerms: boolean;
  onChangeTerms: (evt: any) => void;
  disabled?: boolean;
  onCancel: () => void;
  onFinish: () => void;
  onDeleteFile: () => void;
  disabledFinish: boolean;
  showValidationError: boolean;
}

const ButtonsWrapper = styled(H1_FlexRow)`
  align-self: end;
`;

const ApproveView = ({
  name,
  onChangeName,
  file,
  playing,
  onPlay,
  onStop,
  description,
  onChangeDescription,
  approveTerms,
  onChangeTerms,
  disabled,
  onCancel,
  onFinish,
  disabledFinish,
  onDeleteFile,
  showValidationError
}: ApproveViewProps) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <>
      <H1_TextHeadline textAlign="center">
        {intl.formatMessage(cloneVoiceMessages.modalHeader)}
      </H1_TextHeadline>
      <H1_FlexColumn gap="8px">
        <H1_TextSmall color={theme.gray9}>
          {intl.formatMessage(cloneVoiceMessages.nameHeader)}
        </H1_TextSmall>
        <H1_FlexColumn>
          <Input
            placeholder="My voice"
            style={{ borderRadius: "2px" }}
            value={name}
            onChange={onChangeName}
          />
        </H1_FlexColumn>
      </H1_FlexColumn>
      <H1_FlexColumn>
        <FilePlayer
          fileName={file?.name}
          fileSize={file?.size}
          playing={playing}
          onPlay={onPlay}
          onStop={onStop}
          onDelete={onDeleteFile}
        />
        <ConditionalRender condition={showValidationError}>
          <H1_TextXs color="red">
            {intl.formatMessage(cloneVoiceMessages.validationTimeError)}
          </H1_TextXs>
        </ConditionalRender>
      </H1_FlexColumn>

      <H1_FlexColumn gap="8px">
        <H1_TextSmall color={theme.gray9}>
          {intl.formatMessage(cloneVoiceMessages.descriptionHeader)}
        </H1_TextSmall>
        <Input style={{ borderRadius: "2px" }} value={description} onChange={onChangeDescription} />
      </H1_FlexColumn>
      <H1_FlexRow gap="8px">
        <Checkbox value={approveTerms} onChange={onChangeTerms} />
        <H1_TextSmall whiteSpace="normal" color={theme.gray9}>
          {intl.formatMessage(cloneVoiceMessages.terms)}
        </H1_TextSmall>
      </H1_FlexRow>
      <ButtonsWrapper align="center" gap="15px">
        <Button key="cancel" isDisabled={disabled} onClick={onCancel}>
          {intl.formatMessage(cloneVoiceMessages.modalButtonCancel)}
        </Button>
        <Button color="primary" onClick={onFinish} isDisabled={disabledFinish} isLoading={disabled}>
          {intl.formatMessage(cloneVoiceMessages.modalButtonAdd)}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default ApproveView;
