import { useAppDispatch } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import { Video } from "app/types";

const useCopyLink = () => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const copyLink = (source: string, video: Video, draftId?: string, backModal?: ModalName) => {
    dispatch(googleEvents.copyLinkIntent({ source }));
    openModal(
      ModalName.publishVideo,
      { reqId: video.req_id, videoId: video.id, source },
      backModal
    );
  };

  return { copyLink };
};

export default useCopyLink;
