import { Result, Button, Layout } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Location } from "app/types";

export const messages = defineMessages({
  resSubTitle: {
    id: "page404.subtitle",
    defaultMessage: "Sorry, the page you visited does not exist."
  },
  pageHeadline: {
    id: "page404.headline",
    defaultMessage: "404"
  },
  actionsBack: {
    id: "page404.actions.back.button.label",
    defaultMessage: "Back Home"
  }
});

const Page404 = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Layout.Content className="404" style={{ height: "100%" }}>
      <Result
        status="404"
        title={intl.formatMessage(messages.pageHeadline)}
        subTitle={intl.formatMessage(messages.resSubTitle)}
        extra={
          <Button type="primary" onClick={() => navigate(Location.Home)}>
            {intl.formatMessage(messages.actionsBack)}
          </Button>
        }
      />
    </Layout.Content>
  );
};

export default Page404;
