/* eslint-disable camelcase */
import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch } from "app/hooks";
import useErrors from "app/hooks/useErrors";
import { Channel } from "pusher-js";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";
import { videosActions } from "app/store/slices/videos.slice";
import { executeDownload, extractUserIdForPusher } from "app/utils/helpers";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { onOpenZendesk } from "app/utils/zendesk/zendesk";
import uiActions from "app/store/actions/ui.actions";
import { useIntl } from "react-intl";
import { pusherMessages } from "app/hoc/Pusher/messages";

const PusherDownloadVideoScormHandler = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { notifyError } = useErrors();
  const { formatMessage } = useIntl();

  useEffect(() => {
    let channel: Channel;
    const handleMessage = (message: {
      video_id: string;
      order_id: string;
      url?: string;
      status: PusherMessagesStatusEnum;
    }) => {
      const { video_id, order_id, url, status } = message;
      if (status === PusherMessagesStatusEnum.processing) {
        dispatch(
          videosActions.updateLocalScormDownloadFormat({
            videoId: video_id,
            orderId: order_id,
            status
          })
        );
      } else if (status === PusherMessagesStatusEnum.ready) {
        dispatch(
          videosActions.updateLocalScormDownloadFormat({
            videoId: video_id,
            orderId: order_id,
            link: url,
            status
          })
        );
        executeDownload(url as string);
        dispatch(uiActionsV2.removeFromTray(order_id));
        dispatch(
          uiActions.setNotifications([
            {
              message: formatMessage(pusherMessages.downloadSucceeded)
            }
          ])
        );
      } else if (status === PusherMessagesStatusEnum.failed) {
        dispatch(
          videosActions.updateLocalScormDownloadFormat({
            videoId: video_id,
            orderId: "", //must clear in order to prevent download scorm modal from showing
            status
          })
        );
        onOpenZendesk();
        notifyError({
          general: buildGeneralMessage(
            `Whoops... this is embarrassing`,
            "Something happened when exporting your video as SCORM. Please submit a report so Sam can investigate it ASAP!"
          )
        });
      }
    };
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("scorm_package", handleMessage);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherDownloadVideoScormHandler;
