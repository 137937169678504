import { Progress, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { screenRecordingUploadModalMessages } from "app/components/screenRecording/messages";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

const StyledProgress = styled(Progress)`
  width: 300px;
  && .ant-progress-text {
    color: #2c2645;
    font-family: Poppins;
    font-weight: 700;
    font-size: 11px;
  }
`;

export interface UploadProgressModalProps {
  progress: number;
}

const UploadProgressModal = ({ progress }: UploadProgressModalProps) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <StyledModal
      visible
      destroyOnClose
      centered
      width="383px"
      footer={null}
      bodyStyle={{ height: "106px" }}
      closable={false}
      $padding="30px"
      $alignItems="center"
      className="no-blurry-background"
    >
      <H1_FlexColumn align="center">
        <H1_FlexRow gap="10px" justify="center">
          <Spin spinning indicator={antIcon} />
          <H1_TextXs fontSize="11px" color={theme.gray9}>
            {intl.formatMessage(screenRecordingUploadModalMessages.title)}
          </H1_TextXs>
        </H1_FlexRow>
        <H1_FlexRow gap="10px" justify="center">
          <StyledProgress
            trailColor={theme.gray5}
            strokeWidth={10}
            strokeColor={theme.pink4}
            percent={progress || 0}
          />
        </H1_FlexRow>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default UploadProgressModal;
