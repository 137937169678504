import { defineMessages } from "react-intl";

export const playListMessages = defineMessages({
  headline: {
    id: `playlists.headline`,
    defaultMessage: `Playlists`
  },
  numOfVideos: {
    id: `playlists.num-of-videos`,
    defaultMessage: `{number} Videos`
  },
  editVideo: {
    id: `playlists.edit-video`,
    defaultMessage: `Edit video`
  },
  mergeAll: {
    id: `playlists.merge-all`,
    defaultMessage: `Merge All`
  },
  removeVideo: {
    id: `playlists.remove-video`,
    defaultMessage: `Remove video`
  },
  remove: {
    id: `playlists.remove`,
    defaultMessage: `Remove`
  },
  rename: {
    id: `playlists.rename`,
    defaultMessage: `Rename`
  },
  continueCreatePlaylistTitle: {
    id: `playlists.continue-create-playlist-title`,
    defaultMessage: `Create playlist`
  },
  continueCreatePlaylistContent: {
    id: `playlists.continue-create-playlist-content`,
    defaultMessage: `Only items with generated videos will be added to the playlist. Continue?`
  },
  cancel: {
    id: `playlists.cancel`,
    defaultMessage: `Cancel`
  },
  ok: {
    id: `playlists.ok`,
    defaultMessage: `Ok`
  },
  noPlaylistsText: {
    id: `playlists.no-playlists-text`,
    defaultMessage: `Oops.... you don't have any playlists`
  },
  noPlaylistsDesc: {
    id: `playlists.no-playlists-description`,
    defaultMessage: `Please create a playlist either from an entire folder or by selecting specific videos within a folder`
  },
  renameTitle: {
    id: `playlists.rename-title`,
    defaultMessage: `Rename title`
  },
  myPlaylist: {
    id: `playlists.my-playlist`,
    defaultMessage: `My playlist`
  }
});
