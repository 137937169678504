import { useAppDispatch, useAppSelector } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { ModalName } from "app/hooks/useModal";
import { FeatureFlag, SocialMedia, VideoStatusEnum } from "app/types";
import { useEffect, useState } from "react";
import { videosActions } from "app/store/slices/videos.slice";
import { fetchingStatus } from "app/utils/helpers";
import useShare from "app/hooks/useShare";
import { useFlags } from "launchdarkly-react-client-sdk";

interface VideoData {
  video: { req_id: string; id: string; status: string };
  socialMedia: SocialMedia;
  source: string;
}
const useShareSocialMedia = () => {
  const [selectedVideoData, setSelectedVideoData] = useState<VideoData | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { shareSocialMedia } = useShare();
  const flags = useFlags();
  const publishVideoStatus = useAppSelector((state) => state.videos.publishVideoStatus);

  useEffect(() => {
    if (selectedVideoData && publishVideoStatus) {
      if (publishVideoStatus === fetchingStatus.succeeded) {
        shareSocialMedia(
          selectedVideoData.socialMedia as SocialMedia,
          selectedVideoData.video,
          selectedVideoData.source as string,
          true
        );
        dispatch(videosActions.updatePublishStatusToIdle());
        setSelectedVideoData(undefined);
      } else if (publishVideoStatus === fetchingStatus.failed) {
        dispatch(videosActions.updatePublishStatusToIdle());
        setSelectedVideoData(undefined);
      }
    }
  }, [publishVideoStatus]);

  const shareLink = (
    source: string,
    video: { req_id: string; id: string; status: string },
    socialMedia: SocialMedia,
    backModal?: ModalName
  ) => {
    dispatch(googleEvents.shareSocialMediaIntent({ source }));
    if (video.status !== VideoStatusEnum.Published && !flags[FeatureFlag.freeCopyLink]) {
      setSelectedVideoData({ video, socialMedia, source });
      dispatch(
        videosActions.publishVideoRequest({
          videoId: video.id,
          reqId: video.req_id,
          backModal
        })
      );
    } else {
      shareSocialMedia(socialMedia, video, source, false);
      setSelectedVideoData(undefined);
    }
  };

  return { shareLink, shareSocialMedia };
};

export default useShareSocialMedia;
