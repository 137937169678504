import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import recordingStepSharedSyles from "app/pages/createDigitalTwinPage/recordingStepSharedSyles";
import { useIntl } from "react-intl";
import { digitalTwinCreationMessages } from "app/pages/createDigitalTwinPage/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import CharacterSettings from "app/pages/createDigitalTwinPage/CharacterSettings";
import AdvancedCharacterSettings from "app/pages/createDigitalTwinPage/AdvancedCharacterSettings";
import { Button } from "@nextui-org/react";
import useConfirmModal from "app/hooks/useConfirmModal";

const Container = styled(H1_FlexRow)`
  & > div {
    flex: 2 1 40%;
  }
`;

const RecordStepRecordingReview = ({
  videoFile,
  approveLoading,
  onApprove,
  onReset
}: {
  videoFile: File;
  approveLoading: boolean;
  onApprove:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, approved: boolean) => void)
    | undefined;
  onReset: (reset: boolean) => void;
}) => {
  const intl = useIntl();
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const consentOnApprove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    openConfirmModal({
      content: "I consent that I own this footage for the avatar",
      onCancel: () => {
        onReset(true);
        closeConfirmModal();
      },
      onConfirm: () => {
        if (onApprove) {
          onApprove(e, true);
        }
      }
    });
  };

  return (
    <>
      <H1_TextMidHeadline>
        {intl.formatMessage(digitalTwinCreationMessages.recordStepReviewTitle)}
      </H1_TextMidHeadline>
      <recordingStepSharedSyles.VideoWrapper $visible $fullScreenMode={false}>
        <recordingStepSharedSyles.RoundedVideo
          muted
          controls
          controlsList="nodownload"
          disablePictureInPicture
          src={videoFile ? URL.createObjectURL(videoFile as File) : ""}
          autoPlay
          playsInline
        />
      </recordingStepSharedSyles.VideoWrapper>
      <Container gap="15px" width="70%" padding="0 10px" wrap="wrap">
        <CharacterSettings />
        <AdvancedCharacterSettings />
      </Container>
      <H1_FlexRow gap="35px">
        <Button color="primary" isLoading={approveLoading} onClick={consentOnApprove}>
          {intl.formatMessage(digitalTwinCreationMessages.approveRecordButton)}
        </Button>
        <Button isDisabled={approveLoading} variant="light" onClick={() => onReset(true)}>
          {intl.formatMessage(digitalTwinCreationMessages.declineRecordButton)}
        </Button>
      </H1_FlexRow>
    </>
  );
};

export default RecordStepRecordingReview;
