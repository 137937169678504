import { defineMessages } from "react-intl";

export const mobileBannerMessages = defineMessages({
  title: {
    id: "mobile-banner-homepage.text",
    defaultMessage: "Create your own avatar"
  },
  subtitleText: {
    id: `mobile-banner-homepage.subtitle-text`,
    defaultMessage: `Download our new app to create your own avatar in minutes`
  },
  yourCharacter: {
    id: `mobile-banner-homepage.your-character`,
    defaultMessage: `Create your avatar`
  }
});
