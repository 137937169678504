import { Ref, useMemo } from "react";
import sharedStyled from "app/pages/createDigitalTwinPage/recordingStepSharedSyles";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { durationToMMSS } from "app/utils/helpers";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { css, useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useIntl } from "react-intl";
import useRecordStepContext from "app/pages/createDigitalTwinPage/useRecordStepSettingsContext";
import ConditionalRender from "app/components/common/ConditionalRender";
import { digitalTwinCreationMessages } from "./messages";
import { Button } from "@nextui-org/react";

const Stopper = styled.div`
  position: fixed;
  z-index: 603; // Above video
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  color: white;
  top: calc(50% - 25px);
  left: calc(50% - 100px);
  align-self: center;
  justify-self: center;
`;

const RecordActions = styled(H1_FlexColumn)`
  position: absolute;
  z-index: 602; // Above video
  top: 0;
`;

const SilluetteWrapper = styled.div<{ $faceOutline: boolean; $fullScreenMode: boolean }>`
  position: ${({ $fullScreenMode }) => ($fullScreenMode ? "absolute" : "static")};
  z-index: 602; // Above video
  height: 100%;
  aspect-ratio: 16/9;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  div {
    ${({ $faceOutline, $fullScreenMode }) =>
      $faceOutline
        ? css`
            height: auto;
            display: flex;
            background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Green Face Silhouette.png");
            position: absolute;
            background-repeat: no-repeat;
            background-size: 100%;
            inset: 0;
            z-index: ${$fullScreenMode ? 602 : 1};
          `
        : css`
            display: none;
          `};
  }
`;

const RedCircle = styled.div`
  border-radius: 50%;
  background-color: red;
  height: 7px;
  width: 7px;
  border: 0.3px solid ${({ theme }) => theme.gray1};
`;

const RecordingTag = styled(H1_FlexRow)<{ $bright?: boolean }>`
  border-radius: 20px;
  background: ${({ $bright, theme }) => ($bright ? theme.gray1 : theme.gray8)};
  color: white;
  span {
    color: white;
  }
  button {
    min-width: 10px;
    color: white;
  }
`;

const PreviewTagWrapper = styled.div`
  position: absolute;
  margin: 10px;
  right: 0;
  top: 0;
  z-index: 603; // Above video
`;

const VideoRecorderWrap = ({
  isRecording,
  isReady,
  videoRef,
  counter,
  timeInSec,
  onStopRecord,
  textOnScreen,
  showPreview = true,
  invertedColor = false
}: {
  isReady: boolean;
  isRecording: boolean;
  videoRef: Ref<HTMLVideoElement>;
  counter?: string;
  timeInSec: number;
  onStopRecord: () => void;
  textOnScreen?: React.ReactNode;
  showPreview?: boolean;
  invertedColor?: boolean;
}) => {
  const intl = useIntl();
  const { faceOutline } = useRecordStepContext();
  const theme = useTheme();

  const timeFormmated = useMemo(() => {
    return durationToMMSS(timeInSec);
  }, [timeInSec]);

  return (
    <sharedStyled.VideoWrapper
      $visible={(isReady && showPreview && !isRecording) || isRecording}
      $fullScreenMode={isRecording}
    >
      <SilluetteWrapper $faceOutline={faceOutline} $fullScreenMode={isRecording}>
        <sharedStyled.RoundedVideo
          muted
          ref={videoRef}
          autoPlay
          playsInline
          $isRecording={isRecording}
        />
        <div />
      </SilluetteWrapper>
      <ConditionalRender condition={isRecording}>
        <ConditionalRender condition={!!textOnScreen || !counter}>
          <RecordActions
            width="100%"
            align="center"
            justify="flex-start"
            padding="20px"
            height="100vh"
            gap="10px"
          >
            <H1_FlexRow gap="15px" align="center" justify="center">
              <ConditionalRender condition={!!textOnScreen}>
                <RecordingTag
                  $bright={invertedColor}
                  padding="10px 10px"
                  align="center"
                  justify="center"
                  gap="5px"
                  width="250px"
                >
                  <H1_TextMiddle
                    whiteSpace="normal"
                    textAlign="center"
                    color={invertedColor ? theme.gray10 : theme.gray1}
                  >
                    {textOnScreen}
                  </H1_TextMiddle>
                </RecordingTag>
              </ConditionalRender>
              <ConditionalRender condition={!counter}>
                <RecordingTag padding="10px" align="center" gap="5px" width="fit-content">
                  <H1_TextSmall>{timeFormmated}</H1_TextSmall>
                  <Button
                    size="sm"
                    variant="light"
                    isIconOnly
                    startContent={<i className="fa-solid fa-stop" />}
                    onClick={onStopRecord}
                  />
                </RecordingTag>
              </ConditionalRender>
            </H1_FlexRow>
          </RecordActions>
        </ConditionalRender>
        <ConditionalRender condition={!!counter}>
          <Stopper>{counter}</Stopper>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender condition={!isRecording && showPreview}>
        <PreviewTagWrapper>
          <RecordingTag padding="0px 10px" align="center" gap="5px" width="fit-content">
            <RedCircle />
            {intl.formatMessage(digitalTwinCreationMessages.recordStepPreviewButton)}
          </RecordingTag>
        </PreviewTagWrapper>
      </ConditionalRender>
    </sharedStyled.VideoWrapper>
  );
};

export default VideoRecorderWrap;
