import styled, { keyframes } from "styled-components";

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const fillLine = keyframes`
  0% {
    width: 0%;
  }
  30% {
    width: 10%;
  }
  50% {
    width: 20%;
  }
  100% {
    width: 100%;
  }
`;
const StyledLine = styled("div")<{ $height?: string; $width?: string }>`
  height: ${({ $height }) => $height || "6px"};
  width: ${({ $width }) => $width || "100%"};
  border-radius: 40px;
  background: linear-gradient(90deg, #f4f4ff 0%, #5a5aff 100%);
  animation: ${fillLine} 2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards infinite;
`;

interface LineAnimationLoaderProps {
  height?: string;
  width?: string;
  className?: string;
}

const LineAnimationLoader = ({ height, width, className }: LineAnimationLoaderProps) => {
  return (
    <Container className={className}>
      <StyledLine $height={height} $width={width} />
    </Container>
  );
};

export default LineAnimationLoader;
