import BackgroundImage from "app/components/common/BackgroundImageFlexRow";
import ConditionalRender from "app/components/common/ConditionalRender";
import ArrowRotateIcon from "app/components/common/ArrowRotateIcon";
import TrashBinIcon from "app/components/common/TrashBinIcon";
import { Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styled, { useTheme } from "styled-components";
import { ReactNode, useRef } from "react";
import { MediaUrl } from "app/hooks/useDisplayUrls";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import { MediaType } from "app/types/media";
import { isMediaPlaying } from "app/utils/helpers";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import OppositeUpgradeButton from "app/components/common/UpgradeButton/OppositeUpgradeButton";

const ArrowFlexRow = styled(H1_FlexRow)`
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

const TrashFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const StyledVideo = styled.video`
  max-width: 334px;
  height: auto;
  aspect-ratio: 16 / 9;
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
    img {
      opacity: 0.5;
    }
  }
`;
const StyledOppositeUpgradeButton = styled(OppositeUpgradeButton)`
  border-radius: 7px;
  height: 40px;
  padding: 1px 13px;
`;
const StyledImage = styled.img`
  max-width: 334px;
  height: auto;
  &:hover img {
    opacity: 0.5;
  }
`;

const UploaderInnerText = styled(H1_FlexRow)`
  border: 1px solid ${(props) => props.theme.gray5};
  background: ${(props) => props.theme.gray1};
  border-radius: 2px;
  i {
    color: ${(props) => props.theme.gray5};
  }
`;
interface MediaChooseBoxProps {
  onClick: () => void;
  showCrown?: boolean;
  upgradeButtonText: string | ReactNode;
  replaceToolTip: string | ReactNode;
  removeToolTip: string | ReactNode;
  previewUrl?: MediaUrl;
  removeMedia?: () => void;
  insertText: string | ReactNode;
  icon?: ReactNode;
  source: string;
}

const MediaChooseBox = ({
  onClick,
  showCrown,
  upgradeButtonText,
  replaceToolTip,
  removeToolTip,
  previewUrl,
  removeMedia,
  insertText,
  icon,
  source
}: MediaChooseBoxProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const theme = useTheme();

  const isTypeVideo = previewUrl?.assetType?.toLowerCase() === MediaType.video;
  const isTypeImage = previewUrl?.assetType.toLowerCase() === MediaType.image;

  const onRemoveMedia = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    if (removeMedia) {
      removeMedia();
    }
  };
  const onUpgrade = (evt: React.MouseEvent) => {
    onClick();
    evt.stopPropagation();
  };

  const onMouseEnter = async () => {
    await videoRef.current?.play();
  };

  const onMouseLeave = () => {
    if (isMediaPlaying(videoRef.current as HTMLVideoElement)) {
      videoRef.current?.pause();
    }
  };
  return (
    <BackgroundImage onImageClick={onClick} isPreviewVisible={!!previewUrl}>
      <ConditionalRender condition={!!previewUrl}>
        <Tooltip title={replaceToolTip} placement="left">
          <ArrowFlexRow height="34px" width="34px" onClick={onUpgrade}>
            <ArrowRotateIcon />
            {showCrown && <CrownIcon />}
          </ArrowFlexRow>
        </Tooltip>
        <ConditionalRender condition={!!removeMedia}>
          <Tooltip title={removeToolTip} placement="left">
            <TrashFlexRow height="34px" width="34px" onClick={onRemoveMedia}>
              <TrashBinIcon />
            </TrashFlexRow>
          </Tooltip>
        </ConditionalRender>

        <ConditionalRender condition={isTypeVideo}>
          <StyledVideo
            autoPlay
            ref={videoRef}
            muted
            src={previewUrl?.url}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </ConditionalRender>
        <ConditionalRender condition={isTypeImage}>
          <StyledImage src={previewUrl?.url} />
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender condition={!previewUrl}>
        <ConditionalRender condition={showCrown}>
          <StyledOppositeUpgradeButton
            onClick={onUpgrade}
            source={source}
            text={upgradeButtonText as string}
          />
        </ConditionalRender>
        <ConditionalRender condition={!showCrown}>
          <UploaderInnerText
            align="center"
            justify="center"
            height="43px"
            padding="0 32px"
            gap={"5px"}
          >
            {icon || <UploadOutlined />}
            <H1_TextSmall margin={"0px 0px 0px 10px"} color={theme.gray11} fontSize="14px">
              {insertText}
            </H1_TextSmall>
          </UploaderInnerText>
        </ConditionalRender>
      </ConditionalRender>
    </BackgroundImage>
  );
};

export default MediaChooseBox;
