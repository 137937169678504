import { useAppSelector } from "app/hooks";
import { EditorView } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import ScenesList from "app/components/editor/scene/ScenesList";
import styled from "styled-components";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import WorkFlowContent from "app/pages/EditDraftPage/workflow/WorkFlowContent";
import ConditionalRender from "app/components/common/ConditionalRender";

const CustomFlexRow = styled(H1_FlexRow)`
  min-height: 0;
`;
const TopFlexColumn = styled(H1_FlexColumn)`
  height: calc(100% - 30px);
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: calc(100% - 40px);
  }
`;

const StyledCircleLoader = styled(CircleLoader)`
  position: absolute;
  left: 50%;
`;

interface CenteredEditorProps {
  viewMode: EditorView;
}

const CenteredEditor = ({ viewMode }: CenteredEditorProps) => {
  const draftStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const draftType = useAppSelector((state) => state.drafts.currentDraft.type);
  const isLoading = draftStatus === fetchingStatus.loading;
  const workflowMode = draftType === "workflow";
  // todo
  return (
    <CustomFlexRow justify="center" flex="1 1 auto" height="fit-content">
      <TopFlexColumn
        align="center"
        margin="20px 22px 0 30px"
        className="editor-container"
        flex="1 1 auto"
        justify={viewMode === EditorView.Single ? "center" : "normal"}
      >
        <ConditionalRender condition={workflowMode}>
          <WorkFlowContent />
        </ConditionalRender>
        <ConditionalRender condition={!workflowMode}>
          {viewMode === EditorView.Multiple && <ScenesList />}
          {/*{viewMode === EditorView.Single && <SingleScene />}*/}
        </ConditionalRender>
        {viewMode === EditorView.Single && isLoading && <StyledCircleLoader />}
      </TopFlexColumn>
    </CustomFlexRow>
  );
};

export default CenteredEditor;
