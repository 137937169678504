import styled, { useTheme } from "styled-components";
import { Popover } from "antd";
import { useAppSelector } from "app/hooks";
import * as React from "react";
import { useMemo, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { onImageError } from "app/utils/helpers";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { SwiperSlide } from "swiper/react";
import { getFilteredCharacters } from "app/store/selectorsV2/character.selectors";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import { useDebounce } from "@react-hook/debounce";
import { ThemeMode } from "app/utils/theme";

const StyledSwiper = styled(NavigationSwiper)`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  margin-left: 0;
  margin-right: 0;
  .swiper-slide {
    display: flex;
    width: fit-content;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    top: calc(50% - 2px);
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(64 87 109 / 7%);
    border: 1px solid #8c8c8c;
    width: 26px;
    height: 26px;
    line-height: 26px;
    color: #8c8c8c;
    background-color: #343445;
    text-align: center;
    transition-property: border-color, color, opacity;
    transition-duration: 0.3s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    svg {
      fill: #8c8c8c;
      width: 16px;
      height: 16px;
    }
    &.swiper-button-disabled {
      pointer-events: auto;
    }
    &:hover {
      border-color: ${(props) => props.theme.gray7};
    }
    &:active {
      transform: scale(0.95);
    }
    &::after {
      content: "";
    }
  }
  .swiper-button-prev {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    left: 0;
  }

  .swiper-button-next {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    right: 0;
  }
  &:hover .swiper-button-prev:not(.swiper-button-disabled),
  &:hover .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const OuterImageBackgroundContainer = styled(H1_FlexRow)<{ $isBorderDisplayed: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ $isBorderDisplayed }) => ($isBorderDisplayed ? "white" : "transparent")};
  background-color: transparent;
  padding: 2px; /* Space between the border and the inner element */
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;
const ImageBackgroundContainer = styled(H1_FlexRow)`
  border-radius: 5px;
  background: #377d21;
`;

const BackgroundContainer = styled(H1_FlexColumn)`
  background-color: #32323c;
  min-width: 745px;
  min-height: 197px;
`;
const PopoverContainerFlexColumn = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: rgba(52, 52, 69, 0.5);
`;
const PresenterImageBackgroundFlexRow = styled(H1_FlexColumn)`
  border-radius: 2px;
  background: #377d21;
`;
const PresenterImage = styled.img`
  aspect-ratio: 1;
  cursor: pointer;
`;
const StyledPopover = styled(Popover)`
  padding: 0;
  &&& .ant-popover-inner-content {
    padding: 0;
  }
`;

const BlackInput = styled(H1_Input)`
  background-color: rgba(52, 52, 69, 0.3);
  input {
    border-radius: 5px;
    border: 1px solid rgba(217, 217, 217, 0.1);
    background: rgba(52, 52, 69, 0.3);
    color: white;
  }
  i {
    color: white;
  }
`;

const BackgroundIcon = styled(H1_FlexRow)`
  background-color: #aaaaff1f;
`;

const Icon = styled.i`
  color: white;
`;

interface PresenterSelectProps {
  onChange: (value: string) => void;
  value?: string;
}

const PresenterSelect = ({ value, onChange }: PresenterSelectProps) => {
  const [open, setOpen] = useState(false);
  const [presenterSearch, setPresenterSearch] = useDebounce<string>("", 750);
  const theme = useTheme();
  const allCharacters = useAppSelector(charactersGlobalSelectors.selectAll);
  const characters = useAppSelector((state) =>
    getFilteredCharacters(state, { searchQuery: presenterSearch })
  );
  // const selectedPresenterId = useAppSelector((state) => state.playground.selectedPresenterId);

  const charactersOptions = useMemo(() => {
    const result = characters.map((c) => {
      return {
        name: c.title,
        image: c.image,
        id: c.character_id,
        url: "",
        gender: c.gender
      };
    });
    return result;
  }, [characters]);

  const currentPresenter = useMemo(() => {
    if (value) {
      return allCharacters.find((c) => c.character_id === value);
    }
    return undefined;
  }, [value, allCharacters]);

  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPresenterSearch(value);
  };

  const onClearSearch = () => {
    setPresenterSearch("");
  };

  const onSelectPresenter = (value: string) => {
    onChange(value);
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = useMemo(
    () => (
      <BackgroundContainer width="1200px" height="197px">
        <PopoverContainerFlexColumn
          padding="17px 25px 19px"
          width="100%"
          height="100%"
          align="center"
          justify="space-between"
        >
          <H1_FlexRow width="100%" flex="0 0 36px" align="center" justify="space-between">
            <H1_FlexRow gap="10px" align="center">
              <BackgroundIcon align="center" justify="center" width="30px" height="30px">
                <Icon className="fas fa-user" />
              </BackgroundIcon>
              <H1_FlexColumn>
                <H1_TextSmall color="white">Select Avatar</H1_TextSmall>
                <H1_TextXs margin="-5px 0 0 0" color={theme.gray7}>
                  Select avatar from your avatar’s collection
                </H1_TextXs>
              </H1_FlexColumn>
            </H1_FlexRow>
            <BlackInput
              initialValue={presenterSearch}
              onChange={onChangeQuery}
              placeholder="Search"
              allowClear
              onClear={onClearSearch}
            />
          </H1_FlexRow>
          <H1_FlexColumn padding="20px 0 0 0" gap="5px" width="100%">
            <H1_FlexRow gap="18px" width="100%">
              <StyledSwiper
                allowTouchMove={false}
                navigation
                speed={700}
                slidesPerView={6}
                slidesPerGroup={6}
              >
                {charactersOptions.map((object, index) => (
                  <SwiperSlide key={object.id + index}>
                    <H1_FlexColumn gap="5px">
                      <OuterImageBackgroundContainer
                        flex="0 0 87px"
                        height="87px"
                        $isBorderDisplayed={object.id === value}
                        position="relative"
                      >
                        <ImageBackgroundContainer
                          position="relative"
                          width="160px"
                          height="81px"
                          onClick={() => onSelectPresenter(object.id)}
                          justify="center"
                        >
                          <img
                            onError={onImageError}
                            src={object.image}
                            alt={object.name}
                            width={(81 * 512) / 361}
                            height={81}
                          />
                        </ImageBackgroundContainer>
                      </OuterImageBackgroundContainer>
                      <H1_TextXs margin="0 0 0 2px" color={theme.gray5}>
                        {object.name}
                      </H1_TextXs>
                    </H1_FlexColumn>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </H1_FlexRow>
          </H1_FlexColumn>
        </PopoverContainerFlexColumn>
      </BackgroundContainer>
    ),
    [presenterSearch, charactersOptions]
  );

  return (
    <StyledPopover
      placement="top"
      className="select-presenter-summary"
      getPopupContainer={() => document.querySelector(`.select-presenter-summary`) as HTMLElement}
      content={content}
      overlayInnerStyle={{
        padding: 0
      }}
      trigger="click"
      showArrow={false}
      // align={{ offset: [20, -60] }}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <PresenterImageBackgroundFlexRow align="center" width="100%" justify="center">
        <PresenterImage
          width={(148 * 512) / 361}
          height={148}
          onError={onImageError}
          src={currentPresenter?.image}
          alt={currentPresenter?.title}
        />
      </PresenterImageBackgroundFlexRow>
    </StyledPopover>
  );
};

export default PresenterSelect;
