import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import {
  ChangeRoleRequest,
  Channel,
  ChannelResponse,
  Invite,
  InviteMember,
  Member,
  MemberStatus,
  ThunkApi,
  Workspace,
  WorkspaceRequest
} from "app/types";
import {
  ackInvite,
  changeUserWorkspaceRole,
  createChannel,
  createInvitesV2,
  createWorkspace,
  getChannels,
  getUserInvites,
  getWorkspaceById,
  getWorkspaces,
  removeChannel,
  removeMembersToWorkspace,
  reviewInvite,
  submitInvite,
  updateWorkspaceProperties
} from "app/services/serviceV2Apis";
import { userActions } from "app/store/slices/user.slice";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { workspacesGlobalSelectors } from "app/store/adapters/adapters";

const prefix = "[Workspaces]";

const getWorkspacesRequest = createAsyncThunk<Workspace[], void>(
  `${prefix} getWorkspacesRequest`,
  async () => {
    const result = await getWorkspaces();
    return result;
  },
  thunkOptions
);

const createWorkspaceRequest = createAsyncThunk<
  Workspace,
  {
    workspace: WorkspaceRequest;
  },
  ThunkApi
>(
  `${prefix} createWorkspaceRequest`,
  async ({ workspace }, thunkAPI) => {
    const result = await createWorkspace(workspace);
    const workspaceId: string = result.id;
    thunkAPI.dispatch(
      userActions.updateUserPreferencesRequest({ current_workspace_id: workspaceId })
    );

    return result;
  },
  thunkOptions
);

const inviteMembersToWorkspaceRequest = createAsyncThunk<
  Member[],
  {
    workspaceId: string;
    members: InviteMember[];
  }
>(
  `${prefix} inviteMembersToWorkspaceRequest`,
  async ({ members }) => {
    const result = await createInvitesV2(members);
    return result;
  },
  thunkOptions
);

const removeMembersFromWorkspaceRequest = createAsyncThunk<
  void,
  {
    workspaceId: string;
    members: Pick<Member, "email">[];
  },
  ThunkApi
>(
  `${prefix} removeMembersToWorkspaceRequest`,
  async ({ workspaceId, members }, thunkApi) => {
    const state = thunkApi.getState();
    // @ts-ignore handels userUI store typing
    const email = state.user.currentEmail as string;
    if (members.map((member) => member.email).includes(email)) {
      const currentWorkspace = getCurrentWorkspace(thunkApi.getState());
      const ownerWorkspaceId: string = workspacesGlobalSelectors
        .selectAll(state)
        .find((workspace) => workspace.is_owner)?.id as string;
      thunkApi.dispatch(
        userActions.updateUserPreferencesRequest({ current_workspace_id: ownerWorkspaceId })
      );
      thunkApi.dispatch(workspacesActions.removeWorkspaceFromList(currentWorkspace?.id));
    }
    await removeMembersToWorkspace(workspaceId, members);
  },
  thunkOptions
);

const updateWorkspacePropertiesRequest = createAsyncThunk<
  void,
  {
    workspaceId: string;
    workspace: Partial<Workspace>;
  }
>(
  `${prefix} updateWorkspaceNameRequest`,
  async ({ workspaceId, workspace }) => {
    await updateWorkspaceProperties(workspaceId, workspace);
  },
  thunkOptions
);

const getWorkspaceByIdRequest = createAsyncThunk<Workspace, { workspaceId: string }>(
  `${prefix} getWorkspaceByIdRequest`,
  async ({ workspaceId }) => {
    const res: Workspace = await getWorkspaceById(workspaceId);

    return res;
  },
  thunkOptions
);

const getUserInvitesRequest = createAsyncThunk<Invite[], void>(
  `${prefix} getUserInvitesRequest`,
  async () => {
    const res = await getUserInvites();
    return res;
  },
  thunkOptions
);

const submitInviteRequest = createAsyncThunk<
  Workspace | undefined,
  { inviteId: string; isApproved: boolean; workspaceId?: string }
>(
  `${prefix} submitInviteRequest`,
  async ({ inviteId, isApproved, workspaceId }) => {
    let res: Workspace | undefined;
    const action: string = isApproved ? "accept" : "reject";
    await submitInvite(inviteId, action);
    if (isApproved && workspaceId) {
      res = await getWorkspaceById(workspaceId);
    }

    return res;
  },
  thunkOptions
);

const reviewInviteRequest = createAsyncThunk<void, { inviteId: string; isApproved: boolean }>(
  `${prefix} reviewInviteRequest`,
  async ({ inviteId, isApproved }) => {
    const action: string = isApproved ? "approve" : "decline";
    await reviewInvite(inviteId, action);
  },
  thunkOptions
);

const ackAcceptedInvitationsRequest = createAsyncThunk<void, void, ThunkApi>(
  `${prefix} ackAcceptedInvitationsRequest`,
  async (_, thunkAPI) => {
    const { invitations } = thunkAPI.getState().workspaces;
    const filteredInvitations = invitations.filter(
      (invitation) =>
        invitation.status === MemberStatus.accepted || invitation.status === MemberStatus.rejected
    );
    for (let i = 0; i < filteredInvitations.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await ackInvite(filteredInvitations[i].id);
    }
  },
  thunkOptions
);
const getChannelsRequest = createAsyncThunk<ChannelResponse, void>(
  `${prefix} getChannelsRequestRequest`,
  async () => {
    const result = await getChannels();

    return result;
  },
  thunkOptions
);

const createChannelRequest = createAsyncThunk<
  ChannelResponse,
  {
    name: string;
  }
>(
  `${prefix} createChannelRequest`,
  async ({ name }) => {
    const result = await createChannel(name);

    return result;
  },
  thunkOptions
);

const removeChannelRequest = createAsyncThunk<
  Channel,
  {
    name: string;
  }
>(
  `${prefix} removeChannelRequest`,
  async ({ name }) => {
    const result = await removeChannel(name);

    return result;
  },
  thunkOptions
);

const getChannelsSearchRequest = createAsyncThunk<ChannelResponse, string>(
  `${prefix} getChannelsSearchRequest`,
  async (name: string) => {
    const result = await getChannels(name);

    return result;
  },
  thunkOptions
);

const changeWorkspaceRoleRequest = createAsyncThunk<void, ChangeRoleRequest>(
  `${prefix} changeWorkspaceRoleRequest`,
  async (changeRole) => {
    const result = await changeUserWorkspaceRole(changeRole);

    return result;
  },
  thunkOptions
);

export default {
  getWorkspacesRequest,
  createWorkspaceRequest,
  inviteMembersToWorkspaceRequest,
  removeMembersFromWorkspaceRequest,
  updateWorkspacePropertiesRequest,
  getUserInvitesRequest,
  submitInviteRequest,
  reviewInviteRequest,
  ackAcceptedInvitationsRequest,
  getWorkspaceByIdRequest,
  createChannelRequest,
  getChannelsRequest,
  removeChannelRequest,
  getChannelsSearchRequest,
  changeWorkspaceRoleRequest
};
