/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/media.thunk";
import { fetchingStatus } from "app/utils/helpers";
import { FetchStatus, FilestackPolicy } from "app/types";
import { mediaAdapter } from "app/store/adapters/adapters";
import {
  allMediaTypeOptions,
  StockMedia,
  Media,
  MediaSort,
  MediaType,
  MediaTypeSort,
  ServerFolderMedia,
  SortOrder
} from "app/types/media";
import { Channel } from "pusher-js";
import { DisplayUrl, MediaUrl } from "app/hooks/useDisplayUrls";

interface MediaState {
  mediaStatus: FetchStatus;
  createMediaStatus: FetchStatus;
  deleteMediaStatus: FetchStatus;
  filestackReadPolicy: FilestackPolicy;
  filestackUploadPolicy: FilestackPolicy;
  generatedRequestStatus: FetchStatus;
  generatedRequestLastResults?: Media;
  mediaFilter: MediaType[];
  mediaSort: MediaSort;
  generatedRequestChannel?: Channel;
  updateMediaStatus: FetchStatus;
  createFolderStatus: FetchStatus;
  moveMediaStatus: FetchStatus;
  createdFolder?: ServerFolderMedia;
  currentFolderId?: string;
  foldersStack: { name: string; id?: string; previousContent: ServerFolderMedia[] }[];
  folderTargetLoadingId?: string;
  rootFolderId?: string;
  cachedRootFolder?: ServerFolderMedia[];
  cachedDisplayUrls: DisplayUrl;
  mediaStockStatus: FetchStatus;
  mediaStock: StockMedia[];
}
const initialState: MediaState = {
  mediaStatus: fetchingStatus.idle as FetchStatus,
  createMediaStatus: fetchingStatus.idle as FetchStatus,
  deleteMediaStatus: fetchingStatus.idle as FetchStatus,
  updateMediaStatus: fetchingStatus.idle as FetchStatus,
  createFolderStatus: fetchingStatus.idle as FetchStatus,
  mediaStockStatus: fetchingStatus.idle as FetchStatus,
  moveMediaStatus: fetchingStatus.idle as FetchStatus,
  filestackReadPolicy: {},
  filestackUploadPolicy: {},
  generatedRequestStatus: fetchingStatus.idle as FetchStatus,
  mediaFilter: allMediaTypeOptions,
  mediaSort: { prop: MediaTypeSort.date, order: SortOrder.down },
  generatedRequestLastResults: undefined,
  currentFolderId: undefined,
  foldersStack: [],
  rootFolderId: undefined,
  cachedDisplayUrls: {},
  mediaStock: []
};
export const mediaSlice = createSlice({
  name: "Media",
  initialState: mediaAdapter.getInitialState<MediaState>(initialState),
  reducers: {
    setMediaSort(state, action: PayloadAction<{ prop: MediaTypeSort; order: SortOrder }>) {
      state.mediaSort = action.payload;
    },
    setUrlDisplayCache(state, action: PayloadAction<{ key: string; media: MediaUrl }[]>) {
      for (const item of action.payload) {
        state.cachedDisplayUrls[item.key] = item.media;
      }
      return state;
    },
    resetMediaLibrary(state) {
      state.currentFolderId = undefined;
      state.mediaFilter = [MediaType.image, MediaType.video, MediaType.folder];
      state.foldersStack = [];
      return state;
    },
    updateFolderTargetIdLoadingStatusToIdle(state) {
      state.folderTargetLoadingId = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateCreateMediaStatusToIdle(state) {
      state.createMediaStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateMediaStatusToIdle(state) {
      state.updateMediaStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateMediaFilter(state, action: PayloadAction<MediaType>) {
      const option = action.payload;
      if (option === MediaType.all) {
        state.mediaFilter = state.mediaFilter.includes(MediaType.all) ? [] : allMediaTypeOptions;
        return state;
      }

      if (state.mediaFilter.includes(option)) {
        state.mediaFilter = state.mediaFilter.filter(
          (filter) => filter !== option && filter !== MediaType.all
        );
      } else {
        state.mediaFilter = [...state.mediaFilter, option];
      }

      return state;
    },
    resetGeneratedMediaLastResponse(state) {
      state.generatedRequestLastResults = undefined;
      return state;
    },
    resetCreatedFolder(state) {
      state.createdFolder = undefined;
      return state;
    },
    updateCreateFolderStatusToIdle(state) {
      state.createFolderStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateGeneratedRequestStatusToIdle(state) {
      state.generatedRequestStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateGeneratedRequestStatusToFailure(state) {
      state.generatedRequestStatus = fetchingStatus.failed as FetchStatus;
      return state;
    },
    pushFolderStack(state, action) {
      state.foldersStack.push(action.payload);
      return state;
    },
    pushToFolderStack(state, action) {
      const { index, file } = action.payload;
      if (state.foldersStack[index]) {
        state.foldersStack[index].previousContent.push(file);
      }
      return state;
    },
    setFolderStackByIndex(state, action) {
      const content = state.foldersStack[action.payload].previousContent;
      const id = state.foldersStack[action.payload - 1]?.id;
      state.foldersStack = state.foldersStack.splice(0, action.payload);
      mediaAdapter.setAll(state, content);
      if (action.payload === 0) {
        state.currentFolderId = undefined;
      } else {
        state.currentFolderId = id;
      }
      return state;
    },
    invalidMediaRootCache(state) {
      state.cachedRootFolder = undefined;
      state.rootFolderId = undefined;
      mediaAdapter.removeAll(state);
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getMediaListRequest.fulfilled, (state, action) => {
      state.mediaStatus = fetchingStatus.succeeded as FetchStatus;
      if (action.payload.parentId) {
        state.rootFolderId = action.payload.parentId;
      }
      state.cachedRootFolder = action.payload.files;
      mediaAdapter.setAll(state, action.payload.files);
    });
    builder.addCase(asyncThunks.getMediaListRequest.pending, (state) => {
      state.mediaStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getMediaListRequest.rejected, (state) => {
      state.mediaStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getFolderMediaContentRequest.fulfilled, (state, action) => {
      state.mediaStatus = fetchingStatus.succeeded as FetchStatus;
      const { content, metadata } = action.payload;
      mediaAdapter.setAll(state, content);
      state.currentFolderId = metadata.id;
    });
    builder.addCase(asyncThunks.getFolderMediaContentRequest.pending, (state) => {
      state.mediaStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getFolderMediaContentRequest.rejected, (state) => {
      state.mediaStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.updateMediaRequest.fulfilled, (state) => {
      state.updateMediaStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.updateMediaRequest.pending, (state, action) => {
      state.updateMediaStatus = fetchingStatus.loading as FetchStatus;
      const file = state.entities[action.meta.arg.fileId];
      if (file && file.data) {
        file.data.name = action.meta.arg.mediaUpdate.name;
      }
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });

    builder.addCase(asyncThunks.updateMediaRequest.rejected, (state) => {
      state.updateMediaStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.createMediaSDGenerationRequest.pending, (state) => {
      state.generatedRequestStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createMediaSDGenerationRequest.rejected, (state) => {
      state.generatedRequestStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteMediaRequest.fulfilled, (state) => {
      state.deleteMediaStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteMediaRequest.pending, (state, action) => {
      state.deleteMediaStatus = fetchingStatus.loading as FetchStatus;
      const { fileId } = action.meta.arg;
      mediaAdapter.removeOne(state, fileId);
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });
    builder.addCase(asyncThunks.deleteMediaRequest.rejected, (state) => {
      state.deleteMediaStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.createMediaRequest.fulfilled, (state, action) => {
      state.createMediaStatus = fetchingStatus.succeeded as FetchStatus;
      mediaAdapter.addOne(state, action.payload);
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });
    builder.addCase(asyncThunks.createMediaRequest.pending, (state) => {
      state.createMediaStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createMediaRequest.rejected, (state, action) => {
      state.createMediaStatus = fetchingStatus.failed as FetchStatus;

      const resource = Object.values(state.entities).find(
        (media) => media?.data?.handle === action.meta.arg.request.handle
      );
      if (resource) {
        mediaAdapter.removeOne(state, resource.id);
      }
    });
    builder.addCase(asyncThunks.createMediaBulkRequest.fulfilled, (state, action) => {
      state.createMediaStatus = fetchingStatus.succeeded as FetchStatus;
      mediaAdapter.addMany(state, action.payload);
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });

    builder.addCase(asyncThunks.createMediaBulkRequest.pending, (state) => {
      state.createMediaStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createMediaBulkRequest.rejected, (state) => {
      state.createMediaStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getMediaPolicyRequest.fulfilled, (state, action) => {
      // state.createMediaStatus = fetchingStatus.failed as FetchStatus;
      const type = action.meta.arg;
      if (type !== "upload") {
        state.filestackReadPolicy = action.payload;
      } else {
        state.filestackUploadPolicy = action.payload;
      }
    });

    builder.addCase(asyncThunks.createFolderRequest.pending, (state) => {
      state.createFolderStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createFolderRequest.rejected, (state) => {
      state.createFolderStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.createFolderRequest.fulfilled, (state, action) => {
      state.createFolderStatus = fetchingStatus.succeeded as FetchStatus;
      state.createdFolder = action.payload;
      mediaAdapter.addOne(state, action.payload);
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });

    builder.addCase(asyncThunks.moveToFolderRequest.pending, (state, action) => {
      mediaAdapter.removeOne(state, action.meta.arg.pathId);
      state.folderTargetLoadingId = action.meta.arg.parentId;
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });
    builder.addCase(asyncThunks.moveToFolderRequest.fulfilled, (state) => {
      state.folderTargetLoadingId = undefined;
    });
    builder.addCase(asyncThunks.moveToFolderRequest.rejected, (state) => {
      state.folderTargetLoadingId = undefined;
    });

    builder.addCase(asyncThunks.updateMediaFolderRequest.pending, (state, action) => {
      mediaAdapter.updateOne(state, {
        id: action.meta.arg.folderId,
        changes: { name: action.meta.arg.name }
      });
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });
    builder.addCase(asyncThunks.deleteMediaFolderRequest.pending, (state, action) => {
      mediaAdapter.removeOne(state, action.meta.arg);
      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });

    builder.addCase(asyncThunks.getHouroneMediaRequest.pending, (state) => {
      // mediaAdapter.removeOne(state, action.meta.arg);
      state.mediaStockStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getHouroneMediaRequest.rejected, (state) => {
      // mediaAdapter.removeOne(state, action.meta.arg);
      state.mediaStockStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getHouroneMediaRequest.fulfilled, (state, action) => {
      // mediaAdapter.removeOne(state, action.meta.arg);
      state.mediaStockStatus = fetchingStatus.succeeded as FetchStatus;
      state.mediaStock = action.payload;
    });
    builder.addCase(asyncThunks.deleteMultipleMediaRequest.pending, (state, action) => {
      const { files } = action.meta.arg;
      mediaAdapter.removeMany(state, files);
      state.deleteMediaStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteMultipleMediaRequest.rejected, (state) => {
      state.deleteMediaStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteMultipleMediaRequest.fulfilled, (state) => {
      state.deleteMediaStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.moveToFolderMultipleRequest.pending, (state, action) => {
      state.moveMediaStatus = fetchingStatus.loading;
      const { selectedFolderId } = action.meta.arg;
      state.folderTargetLoadingId = selectedFolderId;

      if (state.foldersStack.length === 0 && state.cachedRootFolder) {
        state.cachedRootFolder = Object.values(state.entities) as ServerFolderMedia[];
      }
    });
    builder.addCase(asyncThunks.moveToFolderMultipleRequest.fulfilled, (state, action) => {
      state.moveMediaStatus = fetchingStatus.succeeded;
      const { selectedFiles } = action.meta.arg;
      mediaAdapter.removeMany(state, selectedFiles);
      state.folderTargetLoadingId = undefined;
    });
    builder.addCase(asyncThunks.moveToFolderMultipleRequest.rejected, (state) => {
      state.moveMediaStatus = fetchingStatus.failed;
      state.folderTargetLoadingId = undefined;
    });
  }
});

export default mediaSlice.reducer;

export const mediaActions = {
  getMediaListRequest: asyncThunks.getMediaListRequest,
  updateMediaRequest: asyncThunks.updateMediaRequest,
  createMediaRequest: asyncThunks.createMediaRequest,
  generateSDMediaRequest: asyncThunks.createMediaSDGenerationRequest,
  deleteMediaRequest: asyncThunks.deleteMediaRequest,
  getMediaPolicyRequest: asyncThunks.getMediaPolicyRequest,
  createMediaBulkRequest: asyncThunks.createMediaBulkRequest,
  createFolderRequest: asyncThunks.createFolderRequest,
  deleteMultipleMediaRequest: asyncThunks.deleteMultipleMediaRequest,
  moveToFolderMultipleRequest: asyncThunks.moveToFolderMultipleRequest,
  getFolderMediaContentRequest: asyncThunks.getFolderMediaContentRequest,
  moveToFolderRequest: asyncThunks.moveToFolderRequest,
  updateMediaFolderRequest: asyncThunks.updateMediaFolderRequest,
  deleteMediaFolderRequest: asyncThunks.deleteMediaFolderRequest,
  getHouroneMediaRequest: asyncThunks.getHouroneMediaRequest,
  ...mediaSlice.actions
};
