import styled, { useTheme } from "styled-components";
import { Input } from "antd";
import { useIntl } from "react-intl";
import { cloneVoiceMessages } from "app/components/editor/cloneVoice/messages";
import ButtonBox from "app/components/common/ButtonBox";
import UploadFileContent from "app/components/common/UploadFileContent";
import { MimeType } from "app/types";
import { ReactNode } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextHeadline, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

const FILE_TYPES = [MimeType.wav];

const InfoWrapper = styled(H1_FlexRow)`
  background: rgba(220, 221, 255, 0.2);
  border-radius: 2px;
  i {
    color: ${(props) => props.theme.blue4};
  }
`;

const ButtonsWrapper = styled(H1_FlexRow)`
  align-self: end;
`;
interface CloneVoiceMainViewProps {
  name?: string;
  onChangeName: (evt: any) => void;
  onUploadFile: (file: File) => void;
  onRecording: () => void;
  executeImport: () => void;
  onCancel: () => void;
  loading: boolean;
  maxTime: number;
}
export const CloneVoiceMainView = ({
  onChangeName,
  name,
  onUploadFile,
  maxTime,
  onRecording,
  loading,
  executeImport,
  onCancel
}: CloneVoiceMainViewProps) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <>
      <H1_TextHeadline textAlign="center">
        {intl.formatMessage(cloneVoiceMessages.modalHeader)}
      </H1_TextHeadline>
      <H1_FlexColumn gap="8px">
        <H1_TextSmall color={theme.gray9}>
          {intl.formatMessage(cloneVoiceMessages.nameHeader)}
        </H1_TextSmall>
        <H1_FlexColumn>
          <Input
            placeholder="My voice"
            style={{ borderRadius: "2px" }}
            value={name}
            onChange={onChangeName}
          />
        </H1_FlexColumn>
      </H1_FlexColumn>
      <H1_FlexRow gap="5px" justify="space-between">
        <UploadFileContent
          disabled={loading}
          fileTypes={FILE_TYPES}
          onSelectFile={(files) => onUploadFile(files[0])}
          maxSizeInMB={10}
        >
          <ButtonBox
            width="212px"
            icon={<i className="fa-solid fa-file-music" />}
            title={
              intl.formatMessage(cloneVoiceMessages.uploadFileButtonTitle, {
                br: <br />
              }) as ReactNode
            }
            description={intl.formatMessage(cloneVoiceMessages.uploadFileButtonDescription)}
          />
        </UploadFileContent>
        <ButtonBox
          width="212px"
          icon={<i className="fa-solid fa-microphone" />}
          title={intl.formatMessage(cloneVoiceMessages.recordButtonTitle)}
          description={intl.formatMessage(cloneVoiceMessages.recordButtonDescription, {
            maxTime: maxTime / 60
          })}
          onClick={onRecording}
        />
      </H1_FlexRow>
      <InfoWrapper gap="11px" padding="14px 17px">
        <i className="fa-sharp fa-solid fa-circle-info" />
        <H1_TextSmall>
          {intl.formatMessage(cloneVoiceMessages.info, { br: <br /> }) as ReactNode}
        </H1_TextSmall>
      </InfoWrapper>
      <ButtonsWrapper align="center" gap="15px">
        <Button key="cancel" isDisabled={loading} onClick={onCancel}>
          {intl.formatMessage(cloneVoiceMessages.modalButtonCancel)}
        </Button>
        <Button color="primary" onClick={executeImport} isDisabled isLoading={loading}>
          {intl.formatMessage(cloneVoiceMessages.modalButtonAdd)}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default CloneVoiceMainView;
