import * as stripeJs from "@stripe/stripe-js";
import { SetupIntentResult } from "@stripe/stripe-js";
import { paymentsActions } from "app/store/slices/payments.slice";
import { PlanEnum, SubscriptionResults } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { fetchingStatus } from "app/utils/helpers";
import * as Sentry from "@sentry/react";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { useAuth } from "app/auth/useAuth";
import { getSpecificSubscriptionPlan } from "app/store/selectorsV2/payments.selectors";
import { userActions } from "app/store/slices/user.slice";

const useCreditCard = ({ visible }: { visible: boolean }) => {
  const [isFinishing, setIsFinishing] = useState<boolean>(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();
  const [cardHolderName, setCardHolderName] = useState<string>("");
  const [postal, setPostal] = useState<string>("");
  const [isStripeLoading, setIsStripeLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const { currentPlan } = useCurrentPlan();
  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();

  const createSubscriptionResults: SubscriptionResults | undefined = useAppSelector(
    (state) => state.payments.createSubscriptionResults
  );
  const { coupon } = useAppSelector((state) => state.payments.pricingModalProperties);
  const createPaymentStatus = useAppSelector((state) => state.payments.createPaymentStatus);
  const executeSessionStatus = useAppSelector((state) => state.payments.executeSessionStatus);
  const setupResult = useAppSelector((state) => state.payments.setupResult);
  const targetSelectedPlan = useAppSelector(getSpecificSubscriptionPlan);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (paymentErrorMessage) {
      dispatch(paymentsActions.setupRequest());
    }
  }, [paymentErrorMessage]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (error) {
      setPaymentErrorMessage(error);
      setIsStripeLoading(false);
    }
  }, [error, visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (createPaymentStatus === fetchingStatus.succeeded) {
      dispatch(paymentsActions.setCreatePaymentStatusToIdle());
    }
    if (createPaymentStatus === fetchingStatus.failed) {
      Sentry.captureMessage("failed create subscription");
      setError("Something went wrong, please try again");
      dispatch(paymentsActions.setCreatePaymentStatusToIdle());
    }
  }, [createPaymentStatus, visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    (async () => {
      if (executeSessionStatus === fetchingStatus.succeeded) {
        if (currentPlan === PlanEnum.onBoarding) {
          dispatch(analyticsEvents.firstPayment());
        }
        setTimeout(async () => {
          await auth.renewToken();
          dispatch(userActions.getUserDataRequest());
          dispatch(workspacesActions.getWorkspacesRequest());
          dispatch(paymentsActions.getCreditsRequest());
          dispatch(paymentsActions.getSubscriptionStatusRequest(true));
          dispatch(paymentsActions.setExecuteSessionStatusToIdle());
        }, 500); // Try to avoid the race condition with Stripe
        setIsFinishing(true);
      }
      if (executeSessionStatus === fetchingStatus.failed) {
        dispatch(paymentsActions.setExecuteSessionStatusToIdle());
      }
    })();
  }, [executeSessionStatus, visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    (async () => {
      if (createSubscriptionResults?.secret) {
        try {
          const result = await stripe?.confirmCardPayment(
            createSubscriptionResults?.secret as string
          );
          if (result?.error) {
            Sentry.captureMessage("failed to confirm payment", {
              extra: { result: result?.error }
            });
            setPaymentErrorMessage(result.error.message);
          } else if (result?.paymentIntent?.status === "succeeded") {
            onPaymentDone();
            setIsStripeLoading(false);
          } else {
            setPaymentErrorMessage("Unable to authenticate credit card");
            Sentry.captureMessage("unable to confirm payment", { extra: { result } });
          }
        } catch (err) {
          setPaymentErrorMessage("Unable to authenticate credit card");
          Sentry.captureException(err, {
            extra: {
              description: "exception on confirm payment"
            }
          });
        } finally {
          setIsStripeLoading(false);
        }
      }
    })();
  }, [createSubscriptionResults?.secret, visible]);

  const onSetupFinish = (setupIntent: SetupIntentResult) => {
    setError("");
    dispatch(
      paymentsActions.createSubscriptionRequest({
        planId: targetSelectedPlan?.id as string,
        paymentMethod: setupIntent?.setupIntent
          ? (setupIntent?.setupIntent.payment_method as string)
          : undefined,
        coupon: coupon as string
      })
    );
  };

  const onPaymentDone = () => {
    dispatch(
      paymentsActions.executeSessionRequest({
        subscriptionId: createSubscriptionResults?.subscriptionId as string
      })
    );
  };

  const handleSetupResult = (setupIntent: SetupIntentResult) => {
    if (setupIntent.setupIntent?.status === "succeeded") {
      setPaymentErrorMessage(undefined);
      onSetupFinish(setupIntent);
    } else if (setupIntent.error) {
      Sentry.captureException(setupIntent.error);
      console.error(setupIntent.error.message);
      setPaymentErrorMessage(setupIntent.error.message);
      setIsStripeLoading(false);
    }
  };

  const confirmCardPayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    const cardNumberElement = elements?.getElement(
      CardNumberElement
    ) as stripeJs.StripeCardNumberElement;
    const cardExpiryElement = elements?.getElement(
      CardExpiryElement
    ) as stripeJs.StripeCardExpiryElement;
    const cardCvcElement = elements?.getElement(CardCvcElement) as stripeJs.StripeCardCvcElement;
    try {
      cardNumberElement.update({ disabled: true });
      cardExpiryElement.update({ disabled: true });
      cardCvcElement.update({ disabled: true });
      setIsStripeLoading(true);
      const result = await stripe?.confirmCardSetup(setupResult?.secret as string, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: cardHolderName,
            email: auth.user?.email as string,
            address: {
              postal_code: postal
            }
          }
        }
      });
      handleSetupResult(result as SetupIntentResult);
    } catch (e: any) {
      const message = e?.message?.split("\n")[0];
      Sentry.captureException(e);
      console.error(e);
      setPaymentErrorMessage(message);
      setIsStripeLoading(false);
    } finally {
      cardNumberElement.update({ disabled: false });
      cardExpiryElement.update({ disabled: false });
      cardCvcElement.update({ disabled: false });
    }
  };

  const clearCreditCardStates = () => {
    setPaymentErrorMessage("");
    setCardHolderName("");
    setPostal("");
    setIsStripeLoading(false);
    setIsFinishing(false);
    setError("");
  };

  return {
    clearCreditCardStates,
    confirmCardPayment,
    setCardHolderName,
    setPostal,
    isStripeLoading,
    paymentErrorMessage,
    isFinishing
  };
};

export default useCreditCard;
