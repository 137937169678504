import { useEffect, useState } from "react";
import { createClient, Photo, Video } from "pexels";
import Config from "app/config/Config";
import { MediaType } from "app/types/media";
import * as Sentry from "@sentry/react";

const apiKey = Config.pexels;
const client = apiKey && createClient(apiKey);

interface PexelsState {
  searchQuery: string;
  page: number;
  mediaType: MediaType;
}

const usePexels = () => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<Photo[]>([]);
  const [videos, setVideos] = useState<Video[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const [pexelsState, setPexelsState] = useState<PexelsState>({
    searchQuery: "",
    page: 1,
    mediaType: MediaType.video
  });
  const [error, setError] = useState<boolean>(false);

  const fetchData = async (page: number) => {
    setError(false);
    setLoading(true);
    const { searchQuery } = pexelsState;
    try {
      let pexelsResult;
      if (searchQuery) {
        setImages([]);
        setVideos([]);
        const method = pexelsState.mediaType === MediaType.video ? "videos" : "photos";
        pexelsResult = await client[method].search({
          query: searchQuery,
          page
        });
      } else if (pexelsState.mediaType === MediaType.image) {
        pexelsResult = await client.photos.curated({
          page
        });
      } else if (pexelsState.mediaType === MediaType.video) {
        pexelsResult = await client.videos.popular({
          page
        });
      }

      if (pexelsResult && "error" in pexelsResult && pexelsResult.error) {
        // decide here
        console.error("pexels error", pexelsResult.error);
        Sentry.captureException(pexelsResult.error, {
          extra: { description: "pexels failed", pexelsResult }
        });
        setError(true);
      } else if (pexelsResult && "photos" in pexelsResult) {
        setImages(searchQuery ? pexelsResult.photos : [...images, ...pexelsResult.photos]);
      } else if (pexelsResult && "videos" in pexelsResult) {
        setVideos(searchQuery ? pexelsResult.videos : [...videos, ...pexelsResult.videos]);
      } else {
        Sentry.captureMessage("pexels failed - no results");
        throw new Error("no results");
      }
    } catch (err) {
      Sentry.captureException(err, {
        extra: { description: "pexels error" }
      });
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pexelsState.page);
  }, [pexelsState]);

  const onSearch = () => {
    setPexelsState({ ...pexelsState, searchQuery: searchValue });
  };

  const onLoadMore = () => {
    const newPage = pexelsState.page + 1;
    setPexelsState({ ...pexelsState, page: newPage });
  };
  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const onChangeMediaType = (media: MediaType) => {
    setPexelsState({ ...pexelsState, page: 0, mediaType: media });
  };
  return {
    error,
    images,
    videos,
    searchValue,
    mediaType: pexelsState.mediaType,
    loading,
    onSearch,
    onLoadMore,
    onSearchChange,
    onChangeMediaType
  };
};

export default usePexels;
