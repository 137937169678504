import React, { useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { Button, Row, Spin } from "antd";
import realsModalBackground from "app/assets/images/reals-modal-background.png";
import { Draft, Recipe, UseCase } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ReactComponent as RealsTextLogo } from "app/assets/sider/reals-text-logo.svg";
import TemplatePreviewThumbnail from "app/components/editor/templateSelect/TemplatePreviewThumbnail";
import { recipesActions } from "app/store/slices/recipes.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import { useNavigate } from "react-router-dom";
import { fetchingStatus } from "app/utils/helpers";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { draftsActions } from "app/store/slices/drafts.slice";
import { userActions } from "app/store/slices/user.slice";
import { recommendedTemplateModalMessages } from "app/components/onBoarding/messages";
import { useIntl } from "react-intl";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextMiddle,
  H1_TextSubtitle,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const StyledRow = styled(Row)`
  flex: 1;
  width: 100%;
  background-image: url("${realsModalBackground}");
  background-repeat: no-repeat;
  background-position: center;
`;

const OptionalBoxFlexColumn = styled(H1_FlexColumn)`
  ${(props: { $selected?: boolean }) => css`
    box-shadow: 0px 0.688889px 2.75556px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    margin: 12px;
    cursor: pointer;

    border: ${props.$selected ? "1px solid var(--ho-primary-hover, #2c2645)" : ""};
  `}
`;

const MoreInfoButton = styled(Button)`
  color: ${(props) => props.theme.gray11};
  span {
    text-decoration: underline;
  }
  align-self: end;
`;

const LogoWrapper = styled.div`
  margin-bottom: 70px;
`;

const OptionalBoxDescription = styled(H1_FlexColumn)`
  padding: 5px;
`;

const UseTemplateButton = styled(Button)`
  width: 250px;
`;

export interface OptionBoxProps {
  onClick: () => void;
  recipe: Recipe;
  selected?: boolean;
}

const OptionBox = ({ onClick, recipe, selected }: OptionBoxProps) => {
  const theme = useTheme();
  return (
    <OptionalBoxFlexColumn
      onClick={onClick}
      justify="space-between"
      height="270px"
      width="286px"
      align="center"
      $selected={selected}
    >
      <TemplatePreviewThumbnail
        showControls
        alt={recipe.title}
        video={recipe.video_full || recipe.video}
        image={recipe.image}
        width="100%"
        autoPlay
      />
      <OptionalBoxDescription flex="1" justify="center">
        <H1_TextMiddle fontWeight={600}>{recipe.title}</H1_TextMiddle>
        <H1_TextXs fontWeight={400} fontSize="11px" color={theme.gray7}>
          {recipe.description}
        </H1_TextXs>
      </OptionalBoxDescription>
    </OptionalBoxFlexColumn>
  );
};

const RecommendedTemplateModal = () => {
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe>();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const { formatMessage } = useIntl();
  const { openModal, closeModal, editorModalOpen } = useModal();

  const recommendedRecipes = useAppSelector((state) => state.recipes.recommendedRecipes);
  const recipesStatus = useAppSelector((state) => state.recipes.status);
  const createdDraft = useAppSelector(draftsSelectors.currentDraft);
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const preferences = useAppSelector((state) => state.user.preferences);

  const userData = useAppSelector((state) => state.user.userData);

  const selfOpen =
    preferences.show_recommended &&
    userData?.hubspot?.initial &&
    userData?.use_case_selection !== UseCase.E_LEARNING;

  const visible = editorModalOpen?.open === ModalName.recommendedTemplatesModal;
  const isLoading = recipesStatus === fetchingStatus.loading;

  useEffect(() => {
    if (selfOpen) {
      openModal(ModalName.recommendedTemplatesModal);
    }
  }, [selfOpen]);

  useEffect(() => {
    if (visible) {
      dispatch(recipesActions.getRecommendedRecipesRequest());
    }
  }, [visible]);

  useEffect(() => {
    if (createDraftStatus === fetchingStatus.idle || !visible) {
      return;
    }
    if (createDraftStatus === fetchingStatus.succeeded) {
      closeModal();
      navigate(`/editor/${createdDraft.id}`);
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    } else if (createDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    }
  }, [createDraftStatus, visible]);

  const onMoreTemplates = () => {
    dispatch(analyticsEvents.templatesBrowse({ source: "recommended" }));
    openModal(ModalName.templateSelect);
  };
  const onCreateDraft = () => {
    const draft: Draft = {
      title: selectedRecipe?.title,
      description: "",
      recipe_id: selectedRecipe?.id
    };
    dispatch(userActions.updateUserPreferencesRequest({ show_recommended: false }));
    dispatch(draftsActions.createDraftRequest({ draft }));
  };

  return (
    <StyledModal
      width="1031px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "671px" }}
      maskClosable={false}
      closable={false}
      destroyOnClose
      $padding="44px 47px 45px"
      $alignItems="center"
    >
      <H1_FlexColumn width="100%" height="100%" align="center">
        <LogoWrapper>
          <RealsTextLogo width={100} height={42} />
        </LogoWrapper>
        <H1_TextSubtitle fontWeight={600} color={theme.blue4}>
          {formatMessage(recommendedTemplateModalMessages.header)}
        </H1_TextSubtitle>
        <span>{formatMessage(recommendedTemplateModalMessages.subTitle)}</span>
        <StyledRow wrap={false} justify="space-around" align="middle">
          {isLoading && <Spin />}
          {recommendedRecipes.map((recipe) => {
            return (
              <H1_FlexColumn key={recipe.id} align="center">
                <OptionBox
                  onClick={() => setSelectedRecipe(recipe)}
                  recipe={recipe}
                  selected={recipe.id === selectedRecipe?.id}
                />
                <ConditionalRender condition={selectedRecipe && selectedRecipe.id === recipe.id}>
                  <UseTemplateButton
                    size="large"
                    type="primary"
                    onClick={onCreateDraft}
                    loading={createDraftStatus === fetchingStatus.loading}
                  >
                    {formatMessage(recommendedTemplateModalMessages.useTemplateBtn)}
                  </UseTemplateButton>
                </ConditionalRender>
              </H1_FlexColumn>
            );
          })}
        </StyledRow>

        <MoreInfoButton onClick={onMoreTemplates} ghost type="text">
          {formatMessage(recommendedTemplateModalMessages.moreTemplatesBtn)}
        </MoreInfoButton>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default RecommendedTemplateModal;
