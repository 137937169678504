import * as React from "react";
import type { EditorConfig, NodeKey, SerializedLexicalNode, Spread } from "lexical";
import { DecoratorNode, LexicalNode } from "lexical";
import PauseComponent from "app/components/common/LexicalEditor/nodes/PauseComponent";
import { SynthesisMarkupLanguageType } from "app/types";

export type SerializedPauseNode = Spread<
  {
    time: string;
    sceneId: string;
    type: "pause";
    key: string | number;
  },
  SerializedLexicalNode
>;

export class PauseNode extends DecoratorNode<JSX.Element> {
  __className: string;

  __time: string;
  __sceneId: string;

  static getType(): string {
    return "pause";
  }

  static clone(node: PauseNode): PauseNode {
    return new PauseNode(node.__time, node.__sceneId, node.__key);
  }

  constructor(time: string, sceneId: string, key?: NodeKey) {
    super(key);
    this.__className = "pause-node";
    this.__time = time;
    this.__sceneId = sceneId;
  }

  setTime(time: string) {
    const writable = this.getWritable();
    writable.__time = time || "0";
  }

  decorate(): JSX.Element {
    return <PauseComponent time={this.__time} sceneId={this.__sceneId} nodeKey={this.__key} />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement("span");
    dom.className = this.__className;
    return dom;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): boolean {
    return false;
  }

  static importJSON(serializedNode: SerializedPauseNode): PauseNode {
    const node = $createPauseNode(serializedNode.time, serializedNode.sceneId);
    return node;
  }

  exportJSON(): SerializedPauseNode {
    return {
      type: SynthesisMarkupLanguageType.pause,
      time: this.__time,
      sceneId: this.__sceneId,
      version: 1,
      key: this.__key
    };
  }
}

export function $isPauseNode(node: LexicalNode | null | undefined): node is PauseNode {
  return node instanceof PauseNode;
}

export function $createPauseNode(time: string, sceneId: string): PauseNode {
  return new PauseNode(time, sceneId);
}
