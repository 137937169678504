import { defineMessages } from "react-intl";

const messages = defineMessages({
  unAuthorized: {
    id: "muxplayer.error.unauthorized",
    defaultMessage: "Sorry, you are not authorized to access this page"
  },
  genralError: {
    id: "muxplayer.error.genral",
    defaultMessage: "Sorry, we couldn't load the video"
  },
  feedbackBarExplained: {
    id: "muxplayer.feedback-bar.text.explained",
    defaultMessage: "Please rate the video quality?"
  },
  feedbackModalTitle: {
    id: "muxplayer.feedback-modal.headline",
    defaultMessage: "Send feedback"
  },
  feedbackModalSubtitle: {
    id: "muxplayer.feedback-modal.sub-headline",
    defaultMessage: "What can we do better next time?"
  },
  renderQualityOption: {
    id: "muxplayer.feedback-modal.options.render-quality",
    defaultMessage: "Render quality"
  },
  avatarLipsyncOption: {
    id: "muxplayer.feedback-modal.options.avatar-lipsync",
    defaultMessage: "Avatar lip-sync"
  },
  avatarMovementOption: {
    id: "muxplayer.feedback-modal.options.avatar-movment",
    defaultMessage: "Avatar movement"
  },
  avatareEpressivenessOption: {
    id: "muxplayer.feedback-modal.options.avatar-expressiveness",
    defaultMessage: "Avatar expressiveness"
  },
  voiceQualityOption: {
    id: "muxplayer.feedback-modal.options.voice-quality",
    defaultMessage: "Voice quality"
  },
  templateQualityOption: {
    id: "muxplayer.feedback-modal.options.template-quality",
    defaultMessage: "Template quality"
  },
  cameraMovmentOption: {
    id: "muxplayer.feedback-modal.options.camera-movement",
    defaultMessage: "Camera movement"
  },
  mediaElementOption: {
    id: "muxplayer.feedback-modal.options.media-element",
    defaultMessage: "Media elements quality"
  }
});

export const videoLoaderModalMessages = defineMessages({
  HeadlineTitle: {
    id: `scorm.modal-video-loader.headline-title`,
    defaultMessage: "Exporting SCORM"
  },
  SubTitle: {
    id: `scorm.modal-video-loader.sub-title`,
    defaultMessage: "Sit tight! Your SCORM file is almost ready!"
  }
});

export default messages;
