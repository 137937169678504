import { defineMessages } from "react-intl";

// Draft Page
export const draftPageMessages = defineMessages({
  storyboardViewTooltip: {
    id: `editor.draft-page.storyboard-view-tooltip`,
    defaultMessage: "Storyboard View"
  },
  largeViewTooltip: {
    id: `editor.draft-page.large-view-tooltip`,
    defaultMessage: "Large View"
  },
  clearDraftScenes: {
    id: `editor.draft-page.clear-draft-scenes`,
    defaultMessage: "Clear all scenes"
  },
  printScript: {
    id: `editor.draft-page.print-entire-script`,
    defaultMessage: "Print entire script"
  },
  downloadAudio: {
    id: "editor.draft-page.download-button",
    defaultMessage: "Download audio"
  },
  copyScriptToClipboard: {
    id: `editor.draft-page.copy-clip-to-clipboard`,
    defaultMessage: "Copy Entire Script to Clipboard"
  },
  videoDuration: {
    id: "editor.draft-page.video-duration",
    defaultMessage: "Est. Video Duration: {time}"
  },
  scriptActions: {
    id: `editor.draft-page.scriptActions`,
    defaultMessage: "Script actions"
  },
  clearDraftTitle: {
    id: `editor.draft-page.clear-draft-title`,
    defaultMessage: "Clear script"
  },
  clearDraftDescription: {
    id: `editor.draft-page.clear-draft-description`,
    defaultMessage: "Are you sure you want to delete you entire script?"
  },
  clearDraftOkButton: {
    id: `editor.draft-page.clear-draft-ok-btn`,
    defaultMessage: "Yes"
  },
  clearDraftCancelButton: {
    id: `editor.draft-page.clear-draft-cancel-btn`,
    defaultMessage: "No"
  },
  screenRecordingToolTip: {
    id: `editor.draft-page.screen-recording-tooltip`,
    defaultMessage: "Screen Recording"
  },
  shareHeadline: {
    id: `editor.draft-page.share-headline`,
    defaultMessage: "Share"
  },
  copyLink: {
    id: `editor.draft-page.copy-link`,
    defaultMessage: "Copy Link"
  },
  embed: {
    id: `editor.draft-page.embed`,
    defaultMessage: "Embed"
  },
  emailEmbed: {
    id: `editor.draft-page.email-embed`,
    defaultMessage: "Embed in email"
  },
  download: {
    id: `editor.draft-page.download`,
    defaultMessage: "Download"
  },
  watchLatestVideoHeadline: {
    id: `editor.draft-page.video-headline`,
    defaultMessage: "Video"
  },
  watchLatest: {
    id: `editor.draft-page.watch-latest`,
    defaultMessage: "Watch Latest"
  },
  showOlderVersions: {
    id: `editor.draft-page.show-older-versions`,
    defaultMessage: "Show older versions"
  }
});
export default defineMessages({
  editorHeadline: {
    id: "editor.headline",
    defaultMessage: "Editor"
  },
  limitedEditorHeadline: {
    id: "editor.headline.limited",
    defaultMessage: "Editor - limited"
  },
  projectDbError: {
    id: "editor.error.no-database",
    defaultMessage: "{err} - Please try different database"
  },
  actionsLabel: {
    id: "editor.actions.label",
    defaultMessage: "Make it Real:"
  },
  actionsAudioButton: {
    id: "editor.actions.audio.button-v2",
    defaultMessage: "Listen All"
  },
  previewButton: {
    id: "editor.actions.preview.button",
    defaultMessage: "Quick Preview"
  },
  downloadAudioButton: {
    id: "editor.actions.audio.download-button",
    defaultMessage: "Download"
  },
  videoDuration: {
    id: "editor.footer.video-duration",
    defaultMessage: "Est. Video Duration: {time}"
  },
  actionsAudioButtoPaused: {
    id: "editor.actions.audio.pause-button",
    defaultMessage: "Pause"
  },
  actionsExportButton: {
    id: "editor.actions.export.button",
    defaultMessage: "Export"
  },
  actionsVersionsButton: {
    id: "editor.actions.versions.button",
    defaultMessage: "Versions"
  },
  renderingVideoCreating: {
    id: "editor.rendering.creating",
    defaultMessage: "RENDERING VIDEO"
  },
  renderingVideoReady: {
    id: "editor.rendering.ready",
    defaultMessage: "READY"
  },
  renderingVideoFail: {
    id: "editor.rendering.fail",
    defaultMessage: "FAIL"
  },
  renderingVideoCanceled: {
    id: "editor.rendering.canceled",
    defaultMessage: "CANCELED"
  }
});

export const videoPreviewModalMessages = defineMessages({
  mainText: {
    id: "video-preview-modal.main-text",
    defaultMessage: "You are about to create a video like this"
  },
  secondaryText: {
    id: "video-preview-modal.secondary-text",
    defaultMessage:
      "Wow! your first AI generated video. You will be able to customize its layout and content"
  },
  cancelButton: {
    id: "video-preview-modal.cancel-button",
    defaultMessage: "No thanks"
  },
  okButton: {
    id: "video-preview-modal.ok-button",
    defaultMessage: "Let's Start"
  }
});
