import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { getTextsAssetFromLayoutWithoutTranscript } from "app/store/selectorsV2/scenes.selectors";
import { LayoutAsset, LayoutCard, LayoutElementType } from "app/types/layout";
import { useAppSelector } from "app/hooks";
import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import styled, { css, useTheme } from "styled-components";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useDispatch } from "react-redux";
import { Draft, FeatureFlag, FetchStatus, PatchOperation } from "app/types";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";

import SingleMediaSection from "app/components/editor/sideDrawers/SceneDrawer/SingleMediaSection";
import { fetchingStatus, isSceneAssetGenerationLoading } from "app/utils/helpers";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import useErrors from "app/hooks/useErrors";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import TextAsset from "app/components/editor/sideDrawers/SceneDrawer/TextAsset";
import { useFlags } from "launchdarkly-react-client-sdk";
import CardSection from "app/components/editor/sideDrawers/SceneDrawer/CardSection";
import layoutSelectors from "app/store/selectorsV2/layout.selectors";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import ContentSectionCharacter from "app/components/editor/sideDrawers/SceneDrawer/ContentSectionCharacter";
import { ThemeMode } from "app/utils/theme";

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.gray4};
`;

const LayoutContentFlexColumn = styled(H1_FlexColumn)`
  opacity: 1;
  transition: flex 0.3s ease-in, opacity 0.3s ease-in;
`;

const BorderBottomFlexColumn = styled(H1_FlexColumn)`
  padding-bottom: 20px;
  ${({ isTextAvailable }: { isTextAvailable: boolean }) =>
    isTextAvailable &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.gray4};
    `};
`;
const MediaText = styled(H1_TextSmall)`
  line-height: 22px;
`;

const ChildFlexColumn = styled(H1_FlexColumn)`
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-04);
    padding-bottom: 10px;
  }
`;

const ContentSection = () => {
  const intl = useIntl();
  const theme = useTheme();
  const { notifyError } = useErrors();
  const dispatch = useDispatch();
  const flags = useFlags();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();

  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const sceneAssetsGenerationStatus: Record<string, FetchStatus> = useAppSelector(
    (state) => state.scenes.genrateSceneAssetStatus
  );

  const textAssets = useAppSelector((state) =>
    getTextsAssetFromLayoutWithoutTranscript(state, sceneId, true)
  );
  const cards = useAppSelector((state) =>
    layoutSelectors.getLayoutCardsById(state, scene?.layout?.id)
  );

  const mediaAsset = scene?.layout?.assets?.media;
  const visualAsset = scene?.layout?.assets?.visual;
  const charactersAssets = scene?.layout.assets.character || [];

  const visualForeground = useMemo(
    () =>
      visualAsset?.filter(
        (visualAsset) =>
          visualAsset.element_type === LayoutElementType.foreground && !visualAsset.card_id
      ),
    [visualAsset]
  );
  const visualBackground = useMemo(
    () =>
      visualAsset?.filter(
        (visualAsset) =>
          visualAsset.element_type === LayoutElementType.background && !visualAsset.card_id
      ),
    [visualAsset]
  );

  const isMediaExists =
    (mediaAsset && mediaAsset.length > 0) || (visualAsset && visualAsset.length > 0);
  const attributeType =
    visualAsset !== undefined && Object.keys(visualAsset).length > 0 ? "visual" : "media";
  const sceneName = scene?.name as string;
  const draftId = draft?.id as string;
  const isTextAssets = textAssets.length > 0;

  useEffect(() => {
    [...textAssets, ...(mediaAsset || [])].forEach((asset) => {
      const genTitle = sceneAssetsGenerationStatus[`${sceneId}-attributes.text.${asset.key}.text`];
      const genMedia =
        sceneAssetsGenerationStatus[`${sceneId}-attributes.${attributeType}.${asset.key}`];
      if (genMedia === fetchingStatus.succeeded || genTitle === fetchingStatus.succeeded) {
        dispatch(scenesActions.updateGenerateSceneAssetsStatusToIdle);
      }

      if (genMedia === fetchingStatus.failed || genTitle === fetchingStatus.failed) {
        notifyError({
          general: buildGeneralError(undefined, intl)
        });
        dispatch(scenesActions.updateGenerateSceneAssetsStatusToIdle);
      }
    });
  }, [sceneAssetsGenerationStatus, textAssets, mediaAsset]);

  const onTextChange = (key: string, value: string, textTitle: string): void => {
    const attribute: { text: string } | undefined =
      scene?.attributes?.text && (scene?.attributes?.text[key] as { text: string });
    if (attribute && attribute.text === value) {
      return;
    }

    const operations = [
      { op: "replace", path: `attributes.text.${key}.text`, value }
    ] as PatchOperation[];
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
    dispatch(
      analyticsEvents.changeTitle({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        },
        title: {
          name: textTitle,
          value
        }
      })
    );
  };

  const onClickGenerateTitle = (key: string, textTitle: string) => {
    const operations = [
      { op: "generate", path: `attributes.text.${key}.text` }
    ] as PatchOperation[];
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations
      })
    );
    dispatch(
      analyticsEvents.generateTitle({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        },
        title: {
          name: textTitle
        }
      })
    );
  };

  return (
    <LayoutContentFlexColumn
      padding="0 30px 20px 0"
      flex="0 1 auto"
      width="auto"
      overflow="auto"
      data-auto-id="title-media-section"
    >
      <ConditionalRender
        condition={flags[FeatureFlag.multiplePresenters] && !!charactersAssets.length}
      >
        <H1_FlexColumn flex="0 0 auto" gap="15px" padding="0 0 15px">
          <H1_TextSmall color={theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6}>
            {intl.formatMessage(sceneDrawerMessages.avatarAndVoiceTitle)}
          </H1_TextSmall>
          {charactersAssets.map((characterAsset, index) => {
            return (
              <ContentSectionCharacter
                draftId={draftId}
                key={characterAsset.key}
                assetKey={characterAsset.key}
                characterAsset={characterAsset}
                isMultipleCharacters={charactersAssets.length > 1}
                characterIndex={index + 1}
              />
            );
          })}
          <Divider />
        </H1_FlexColumn>
      </ConditionalRender>

      {/* Media selection */}
      <ConditionalRender
        condition={isMediaExists && (!!mediaAsset?.length || !!visualForeground?.length)}
      >
        <BorderBottomFlexColumn
          isTextAvailable={!!isTextAssets}
          flex="0 0 auto"
          data-auto-id="media-section"
        >
          <H1_FlexRow justify="space-between" align="baseline">
            <MediaText
              fontSize="14px"
              color={theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6}
            >
              {intl.formatMessage(sceneDrawerMessages.mediaTitle)}
            </MediaText>
          </H1_FlexRow>
          {mediaAsset?.map((currentMedia: LayoutAsset) => (
            <H1_FlexColumn key={currentMedia.key} align="flex-end">
              <ChildFlexColumn padding="10px 0 0 0" width="100%">
                <SingleMediaSection
                  loading={
                    sceneAssetsGenerationStatus[
                      `${sceneId}-attributes.media.${currentMedia.key}`
                    ] === fetchingStatus.loading
                  }
                  media={currentMedia}
                  mediaUrl={
                    (scene?.attributes?.media &&
                      scene?.attributes?.media[currentMedia.key as string]?.url) ||
                    ""
                  }
                  key={currentMedia.key}
                />
              </ChildFlexColumn>
            </H1_FlexColumn>
          ))}
          {visualForeground?.map((currentMedia: LayoutAsset) => (
            <H1_FlexColumn key={currentMedia.key} align="flex-end">
              <ChildFlexColumn padding="10px 0 0 0" width="100%">
                <SingleMediaSection
                  loading={isSceneAssetGenerationLoading(
                    sceneAssetsGenerationStatus,
                    sceneId,
                    currentMedia.key
                  )}
                  media={currentMedia}
                  mediaUrl={
                    (scene?.attributes?.visual &&
                      scene?.attributes?.visual[currentMedia.key as string]?.preset_override
                        ?.media_url) ||
                    currentMedia.preset?.media_url ||
                    ""
                  }
                  key={currentMedia.key}
                />
              </ChildFlexColumn>
            </H1_FlexColumn>
          ))}
        </BorderBottomFlexColumn>
      </ConditionalRender>

      {/* Texts selection */}
      <ConditionalRender condition={isTextAssets}>
        <H1_TextSmall
          margin={visualForeground?.length || mediaAsset?.length ? "15px 0 13px 0" : "0"}
          fontSize="14px"
          color={theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6}
        >
          {intl.formatMessage(sceneDrawerMessages.titlesTitle)}
        </H1_TextSmall>
        <BorderBottomFlexColumn
          isTextAvailable={!!visualBackground?.length}
          gap="13px"
          flex="0 0 auto"
        >
          {textAssets.map((text: LayoutAsset) => (
            <TextAsset
              key={text.key}
              asset={text}
              onClickGenerateTitle={onClickGenerateTitle}
              onTextChange={onTextChange}
              draftId={draftId}
              sceneAssetsGenerationStatus={sceneAssetsGenerationStatus}
            />
          ))}
        </BorderBottomFlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={!!cards?.length}>
        {cards?.map((card: LayoutCard) => (
          <CardSection
            key={card.id}
            cardId={card.id}
            name={card.name}
            onClickGenerateTitle={onClickGenerateTitle}
            onTextChange={onTextChange}
          />
        ))}
      </ConditionalRender>
      <ConditionalRender condition={!!visualBackground?.length}>
        <H1_FlexRow flex="1 0 auto" justify="space-between" align="baseline" padding="10px 0 0 0">
          <MediaText
            fontSize="14px"
            color={theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6}
          >
            {intl.formatMessage(sceneDrawerMessages.background)}
          </MediaText>
        </H1_FlexRow>
        {visualBackground?.map((currentMedia: LayoutAsset) => (
          <H1_FlexColumn key={currentMedia.key} align="flex-end" flex="1 0 auto">
            <ChildFlexColumn padding="10px 0 0 0" width="100%" flex="1 0 auto">
              <SingleMediaSection
                loading={isSceneAssetGenerationLoading(
                  sceneAssetsGenerationStatus,
                  sceneId,
                  currentMedia.key
                )}
                media={currentMedia}
                mediaUrl={
                  (scene?.attributes?.visual &&
                    scene?.attributes?.visual[currentMedia.key as string]?.preset_override
                      ?.media_url) ||
                  currentMedia.preset?.media_url ||
                  ""
                }
                key={currentMedia.key}
              />
            </ChildFlexColumn>
          </H1_FlexColumn>
        ))}
      </ConditionalRender>
    </LayoutContentFlexColumn>
  );
};

export default ContentSection;
