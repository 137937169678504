import React, { useEffect } from "react";
import { notification } from "antd";
import UiActions from "app/store/actions/ui.actions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ValidationErrors } from "app/types";
import { defineMessages, useIntl } from "react-intl";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button } from "@nextui-org/react";

const notificationMessages = defineMessages({
  okButton: {
    id: "notification-box.ok-button",
    defaultMessage: `Ok, got it`
  }
});

const OkButton = styled(Button)`
  bottom: 20px;
  right: 20px;
  height: 28px;
  position: absolute;
`;

const IconX = styled.i`
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 900;
`;

const ErrorNotifier = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  // @ts-ignore handle userUI store typing
  const errors: ValidationErrors = useAppSelector((state) => state.userUi.errors);
  useEffect(() => {
    const alerts = Object.entries(errors).filter(
      (entry) => !!entry[1] && entry[1].type === "alert"
    );
    if (alerts.length) {
      dispatch(UiActions.cleanErrors());
      alerts.forEach(([key, error]) => {
        return notification.open({
          key,
          message: error.message,
          description: (
            <>
              {typeof error.description === "string" ? (
                <H1_TextXs>{error.description}</H1_TextXs>
              ) : (
                error.description
              )}
            </>
          ),
          btn: error.btn || (
            <H1_FlexColumn margin="38px 0 0 0">
              <OkButton size="sm" color="primary" onClick={() => notification.destroy()}>
                {formatMessage(notificationMessages.okButton)}
              </OkButton>
            </H1_FlexColumn>
          ),
          icon: error.icon,
          closeIcon: <IconX className="far fa-xmark" />,
          duration: error.duration ?? 8,
          top: 58
        });
      });
    }
  }, [errors]);

  return null;
};

export default ErrorNotifier;
