import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus, WebhookStatus } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import asyncThunks from "app/store/thunks/webhooks.thunk";
import { webhooksAdapter } from "app/store/adapters/adapters";

interface webhooksState {
  webhookStatus: FetchStatus;
  createWebhookStatus: FetchStatus;
  updateWebhookStatus: FetchStatus;
  statusChangeStatus: FetchStatus;
  changeSecretStatus: FetchStatus;
}

const INITIAL_STATE: webhooksState = {
  webhookStatus: fetchingStatus.idle as FetchStatus,
  createWebhookStatus: fetchingStatus.idle as FetchStatus,
  updateWebhookStatus: fetchingStatus.idle as FetchStatus,
  statusChangeStatus: fetchingStatus.idle as FetchStatus,
  changeSecretStatus: fetchingStatus.idle as FetchStatus
};

export const webhooksSlice = createSlice({
  name: "Webhooks",
  initialState: webhooksAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    setCreateStatusToIdle: (state) => {
      state.createWebhookStatus = fetchingStatus.idle;
      return state;
    },
    setUpdateStatusToIdle: (state) => {
      state.updateWebhookStatus = fetchingStatus.idle;
      return state;
    },
    setChangeSecretStatusToIdle: (state) => {
      state.changeSecretStatus = fetchingStatus.idle;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getWebhooksRequest.pending, (state) => {
      state.webhookStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getWebhooksRequest.rejected, (state) => {
      state.webhookStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getWebhooksRequest.fulfilled, (state, action) => {
      state.webhookStatus = fetchingStatus.succeeded as FetchStatus;
      webhooksAdapter.setAll(state, action.payload);
    });
    builder.addCase(asyncThunks.createWebhookRequest.pending, (state) => {
      state.createWebhookStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createWebhookRequest.rejected, (state) => {
      state.createWebhookStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createWebhookRequest.fulfilled, (state, action) => {
      state.createWebhookStatus = fetchingStatus.succeeded as FetchStatus;
      webhooksAdapter.addOne(state, action.payload);
    });
    builder.addCase(asyncThunks.updateWebhookRequest.pending, (state) => {
      state.updateWebhookStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.updateWebhookRequest.rejected, (state) => {
      state.updateWebhookStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.updateWebhookRequest.fulfilled, (state) => {
      state.updateWebhookStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getWebhookRequest.pending, (state) => {
      state.webhookStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getWebhookRequest.rejected, (state) => {
      state.webhookStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getWebhookRequest.fulfilled, (state, action) => {
      state.webhookStatus = fetchingStatus.succeeded as FetchStatus;
      webhooksAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(asyncThunks.deleteWebhookRequest.fulfilled, (state, action) => {
      webhooksAdapter.removeOne(state, action.meta.arg);
    });
    builder.addCase(asyncThunks.togglePauseWebhookRequest.rejected, (state) => {
      state.statusChangeStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.reactiveWebhookRequest.rejected, (state) => {
      state.statusChangeStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.togglePauseWebhookRequest.pending, (state, action) => {
      state.statusChangeStatus = fetchingStatus.loading;
      const webhookId = action.meta.arg;
      if (state.entities[webhookId]) {
        webhooksAdapter.updateOne(state, {
          id: webhookId,
          changes: {
            status:
              state.entities[webhookId]?.status === WebhookStatus.paused
                ? WebhookStatus.active
                : WebhookStatus.paused
          }
        });
      }
    });
    builder.addCase(asyncThunks.togglePauseWebhookRequest.fulfilled, (state, action) => {
      state.statusChangeStatus = fetchingStatus.succeeded;
      const webhookId = action.meta.arg;
      if (state.entities[webhookId]) {
        webhooksAdapter.updateOne(state, {
          id: webhookId,
          changes: {
            status: action.payload.status
          }
        });
      }
    });
    builder.addCase(asyncThunks.reactiveWebhookRequest.pending, (state, action) => {
      state.statusChangeStatus = fetchingStatus.loading;
      const webhookId = action.meta.arg;
      if (state.entities[webhookId]) {
        webhooksAdapter.updateOne(state, {
          id: webhookId,
          changes: {
            status:
              state.entities[webhookId]?.status === WebhookStatus.inactive
                ? WebhookStatus.active
                : WebhookStatus.inactive
          }
        });
      }
    });
    builder.addCase(asyncThunks.reactiveWebhookRequest.fulfilled, (state, action) => {
      state.statusChangeStatus = fetchingStatus.succeeded;
      const webhookId = action.meta.arg;
      if (state.entities[webhookId]) {
        webhooksAdapter.updateOne(state, {
          id: webhookId,
          changes: {
            status: action.payload.status
          }
        });
      }
    });

    builder.addCase(asyncThunks.createWebhookSecretRequest.fulfilled, (state, action) => {
      state.changeSecretStatus = fetchingStatus.succeeded;
      const webhookId = action.meta.arg;
      if (state.entities[webhookId]) {
        webhooksAdapter.updateOne(state, {
          id: webhookId,
          changes: {
            signing_secret: action.payload.signing_secret
          }
        });
      }
    });

    builder.addCase(asyncThunks.createWebhookSecretRequest.pending, (state) => {
      state.changeSecretStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.createWebhookSecretRequest.rejected, (state) => {
      state.changeSecretStatus = fetchingStatus.failed;
    });
  }
});

export default webhooksSlice.reducer;

export const webhooksActions = {
  getWebhooksRequest: asyncThunks.getWebhooksRequest,
  createWebhookRequest: asyncThunks.createWebhookRequest,
  getWebhookRequest: asyncThunks.getWebhookRequest,
  updateWebhookRequest: asyncThunks.updateWebhookRequest,
  deleteWebhookRequest: asyncThunks.deleteWebhookRequest,
  togglePauseWebhookRequest: asyncThunks.togglePauseWebhookRequest,
  reactiveWebhookRequest: asyncThunks.reactiveWebhookRequest,
  createWebhookSecretRequest: asyncThunks.createWebhookSecretRequest,
  ...webhooksSlice.actions
};
