import { SocialMedia, Video } from "app/types";
import { MenuProps } from "antd";
import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useEmailVideoEmbedSender from "app/hooks/useEmailVideoEmbedSender";
import { defineMessages, useIntl } from "react-intl";
import useShareSocialMedia from "app/hooks/useShareSocialMedia";
import useEmbed from "app/hooks/useEmbed";
import usePermissions from "app/hooks/usePermissions";
import { ModalName } from "app/hooks/useModal";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/circle-upgrade-icon.svg";
import { videoReadyModalMessages } from "app/pages/editor/messages";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { Button } from "@nextui-org/react";
import useDownload from "app/hooks/useDownload";
import useCopyLink from "app/hooks/useCopyLink";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const StyledNextDropdown = styled(NextDropdown)`
  li[data-hover="true"] {
    span {
      color: white; /* Its inside a blue4 background */
    }
    i {
      color: white; /* Its inside a blue4 background */
    }
  }
`;
const StyledDropdown = styled(H1_Dropdown)`
  color: ${(props) => props.theme.blue4};
  background-color: ${({ theme }) => theme.gray3};
  i {
    color: ${(props) => props.theme.blue4};
  }
  &:hover {
    color: ${(props) => props.theme.blue4};
    i {
      color: ${(props) => props.theme.blue4};
    }
  }
  padding: 0 1rem;
  &.overlay-dropdown {
    box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
    .ant-dropdown {
      width: 250px;
    }
    max-height: 370px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray4};
    font-size: 12px;
    i {
      font-size: 16px;
    }
    .ant-dropdown-menu-item {
      padding: 11px 12px;
      i {
        color: ${({ theme }) => theme.gray1};
      }
      &:hover {
        background-color: ${({ theme }) => theme.blue4};
        color: ${({ theme }) => theme.gray1};
        i {
          color: ${({ theme }) => theme.gray1};
        }
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 11px 12px;
    }
  }
`;

const CircleIconContainer = styled(H1_FlexRow)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-flex;
  color: white;
`;
const EmbedCircle = styled(CircleIconContainer)`
  background-color: #000;
`;

const EmailCircle = styled(CircleIconContainer)`
  background-color: #5a5aff;
`;
const TwitterCircle = styled(CircleIconContainer)`
  background-color: rgb(29, 155, 240);
`;

const FacebookCircle = styled(CircleIconContainer)`
  background-color: #365896;
`;
const WhatsappCircle = styled(CircleIconContainer)`
  background-color: #25d366;
`;
const LinkedInCircle = styled(CircleIconContainer)`
  background-color: #0a66c2;
`;

const BrandIcon = styled.i`
  color: white;
  font-size: 14px;
`;

const StyledUpgradeIcon = styled(UpgradeIcon)`
  width: 24px;
  height: 24px;
`;

const shareVideoMessages = defineMessages({
  embedLink: {
    id: `editor.video-ready-modal.embed-link`,
    defaultMessage: "Embed"
  },
  copyToEmail: {
    id: `editor.video-ready-modal.copy-to-email`,
    defaultMessage: "Embed in email"
  },
  shareToTwitter: {
    id: `editor.video-ready-modal.share-to-twitter`,
    defaultMessage: "Share to Twitter"
  },
  shareToFacebook: {
    id: `editor.video-ready-modal.share-to-facebook`,
    defaultMessage: "Share to Facebook"
  },
  shareToWhatsapp: {
    id: `editor.video-ready-modal.share-to-whatsapp`,
    defaultMessage: "Share to WhatsApp"
  },
  shareToLinkedIn: {
    id: `editor.video-ready-modal.share-to-linked-in`,
    defaultMessage: "Share to LinkedIn"
  },
  download: {
    id: `editor.video-ready-modal.download`,
    defaultMessage: "Download"
  },
  copyLink: {
    id: `editor.video-ready-modal.copy-link`,
    defaultMessage: "Copy link"
  }
});

interface ShareVideoProps {
  icon?: ReactNode;
  video: Video;
  source: string;
  className?: string;
  withText?: boolean;
  withDownload?: boolean;
  withCopyLink?: boolean;
  withEmbed?: boolean;
  withEmbedEmail?: boolean;
  withLinkedIn?: boolean;
  withWhatsApp?: boolean;
  withFacebook?: boolean;
  withTwitter?: boolean;
  isNextUI?: boolean;
}
const ShareVideoMenu = ({
  icon,
  withText = true,
  video,
  source,
  className,
  withDownload = false,
  withCopyLink = false,
  withEmbed = true,
  withEmbedEmail = true,
  withLinkedIn = true,
  withWhatsApp = true,
  withFacebook = true,
  withTwitter = true,
  isNextUI = false
}: ShareVideoProps) => {
  const { prepareEmailContent } = useEmailVideoEmbedSender();
  const { formatMessage } = useIntl();
  const { shareLink } = useShareSocialMedia();
  const embedCode = useEmbed();
  const { isPublishAllow } = usePermissions();
  const { openDownloadModal } = useDownload();
  const { copyLink } = useCopyLink();

  const openEmbed = isPublishAllow || video?.paid;

  const onDownload = () => {
    if (video) {
      openDownloadModal({ video, source: `${source}_download` });
    }
  };

  const onCopyLink = () => {
    if (video) {
      copyLink(`${source}_copy_link`, video as Video, video.draft_id);
    }
  };
  const onEmbed = () => {
    if (video) {
      embedCode(`${source}_embed`, { id: video.id, req_id: video.req_id } as Video);
    }
  };
  const onCopyToEmail = () => {
    if (video) {
      prepareEmailContent(
        { id: video.id, req_id: video.req_id } as Video,
        `${source}_embed_email`
      ).then();
    }
  };

  const onClickTwitter = () => {
    if (video) {
      shareLink(
        `${source}_twitter`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.twitter,
        ModalName.videoReady
      );
    }
  };

  const onClickFacebook = () => {
    if (video) {
      shareLink(
        `${source}_facebook`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.facebook,
        ModalName.videoReady
      );
    }
  };

  const onClickLinkedIn = () => {
    if (video) {
      shareLink(
        `${source}_linkedin`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.linkedin,
        ModalName.videoReady
      );
    }
  };

  const onClickWhatsapp = () => {
    if (video) {
      shareLink(
        `${source}_whatsapp`,
        { id: video.id, req_id: video.req_id, status: video.status as string },
        SocialMedia.whatsapp,
        ModalName.videoReady
      );
    }
  };

  const items: MenuProps["items"] = useMemo(() => {
    const downloadItem = {
      className: "share-menu clickable",
      key: "download",
      startContent: (
        <EmbedCircle>
          <BrandIcon className="fa-solid fa-arrow-down-to-line" />
        </EmbedCircle>
      ),
      icon: (
        <LinkedInCircle>
          <BrandIcon className="fa-solid fa-arrow-down-to-line" />
        </LinkedInCircle>
      ),
      onClick: onDownload,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.download)}</H1_TextSmall>
    };
    const copyLinkItem = {
      className: "share-menu clickable",
      key: "copy-link",
      startContent: (
        <EmbedCircle>
          <BrandIcon className="fa-solid fa-link" />
        </EmbedCircle>
      ),
      icon: (
        <EmbedCircle>
          <BrandIcon className="fa-solid fa-link" />
        </EmbedCircle>
      ),
      onClick: onCopyLink,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.copyLink)}</H1_TextSmall>
    };
    const embedItem = {
      className: "share-menu clickable",
      key: "embed",
      startContent: openEmbed ? (
        <EmbedCircle>
          <BrandIcon className="fas fa-code-simple" />
        </EmbedCircle>
      ) : (
        <StyledUpgradeIcon />
      ),
      icon: openEmbed ? (
        <EmbedCircle>
          <BrandIcon className="fas fa-code-simple" />
        </EmbedCircle>
      ) : (
        <StyledUpgradeIcon />
      ),
      onClick: onEmbed,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.embedLink)}</H1_TextSmall>
    };
    const embedEmailItem = {
      className: "share-menu clickable",
      key: "copyToEmail",
      startContent: openEmbed ? (
        <EmailCircle>
          <BrandIcon className="fas fa-envelope" />
        </EmailCircle>
      ) : (
        <StyledUpgradeIcon />
      ),
      icon: openEmbed ? (
        <EmailCircle>
          <BrandIcon className="fas fa-envelope" />
        </EmailCircle>
      ) : (
        <StyledUpgradeIcon />
      ),
      onClick: onCopyToEmail,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.copyToEmail)}</H1_TextSmall>
    };

    const linkedItem = {
      className: "share-menu clickable",
      key: "shareOnLinkedIn",
      startContent: (
        <LinkedInCircle>
          <BrandIcon className="fa-brands fa-linkedin-in" />
        </LinkedInCircle>
      ),
      icon: (
        <LinkedInCircle>
          <BrandIcon className="fa-brands fa-linkedin-in" />
        </LinkedInCircle>
      ),
      onClick: onClickLinkedIn,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.shareToLinkedIn)}</H1_TextSmall>
    };

    const whatsappItem = {
      className: "share-menu clickable",
      key: "shareOnWhatsapp",
      startContent: (
        <WhatsappCircle>
          <BrandIcon className="fa-brands fa-whatsapp" />
        </WhatsappCircle>
      ),
      icon: (
        <WhatsappCircle>
          <BrandIcon className="fa-brands fa-whatsapp" />
        </WhatsappCircle>
      ),
      onClick: onClickWhatsapp,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.shareToWhatsapp)}</H1_TextSmall>
    };

    const facebookItem = {
      className: "share-menu clickable",
      key: "shareOnFacebook",
      startContent: (
        <FacebookCircle>
          <BrandIcon className="fa-brands fa-facebook-f" />
        </FacebookCircle>
      ),
      icon: (
        <FacebookCircle>
          <BrandIcon className="fa-brands fa-facebook-f" />
        </FacebookCircle>
      ),
      onClick: onClickFacebook,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.shareToFacebook)}</H1_TextSmall>
    };

    const twitterItem = {
      className: "share-menu clickable",
      key: "shareOnTwitter",
      startContent: (
        <TwitterCircle>
          <BrandIcon className="fa-brands fa-twitter" />
        </TwitterCircle>
      ),
      icon: (
        <TwitterCircle>
          <BrandIcon className="fa-brands fa-twitter" />
        </TwitterCircle>
      ),
      onClick: onClickTwitter,
      label: <H1_TextSmall>{formatMessage(shareVideoMessages.shareToTwitter)}</H1_TextSmall>
    };

    const initialItems: MenuProps["items"] = [];
    if (withDownload) {
      initialItems.push(downloadItem);
    }
    if (withCopyLink) {
      initialItems.push(copyLinkItem);
    }
    if (withEmbed) {
      initialItems.push(embedItem);
    }
    if (withEmbedEmail) {
      initialItems.push(embedEmailItem);
    }
    if (withLinkedIn) {
      initialItems.push(linkedItem);
    }
    if (withTwitter) {
      initialItems.push(twitterItem);
    }
    if (withFacebook) {
      initialItems.push(facebookItem);
    }
    if (withWhatsApp) {
      initialItems.push(whatsappItem);
    }

    return initialItems;
  }, [openEmbed, video]);

  if (isNextUI) {
    return (
      <StyledNextDropdown items={items}>
        <Button
          className={className}
          size="sm"
          variant="light"
          startContent={icon || <i className="fa-solid fa-share-nodes" />}
          isIconOnly
        />
      </StyledNextDropdown>
    );
  }

  return (
    <StyledDropdown
      icon={icon || <i className="fa-solid fa-share-nodes" />}
      title={withText ? formatMessage(videoReadyModalMessages.shareButton) : ""}
      destroyPopupOnHide
      menu={{ items }}
      trigger={["click", "hover"]}
      className={className + " overlay-dropdown"}
      withChevron={false}
      getPopupContainer={() => document.querySelector(`.overlay-dropdown`) as HTMLElement}
    ></StyledDropdown>
  );
};

export default ShareVideoMenu;
