import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch } from "app/hooks";
import { Channel } from "pusher-js";
import { playgroundActions } from "app/store/slices/playground.slice";
import { extractUserIdForPusher } from "app/utils/helpers";

const PusherPlaygroundHandler = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleProgressUpdate = (message: {
      status: string;
      video_id: string;
      playback_id: string;
      req_id: string;
      progress: number;
      pair_info: { order_id: string };
    }) => {
      const {
        progress,
        status,
        playback_id: playbackId,
        video_id: videoId,
        req_id: reqId
      } = message;

      dispatch(
        playgroundActions.updateProgressAndStatus({ status, progress, playbackId, videoId, reqId })
      );
    };
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("playground_progress", handleProgressUpdate);
    }

    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherPlaygroundHandler;
