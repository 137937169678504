import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import { DrawerProps } from "app/components/editor/sideDrawers/CommonDrawerTypes";
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { Draft, FetchStatus, MimeType, PlanEnum } from "app/types";
import * as musicSelectors from "app/store/selectorsV2/music.selectors";
import {
  addPolicyToUrlIfFileStackMedia,
  fetchingStatus,
  getAudioFileDuration
} from "app/utils/helpers";
import { BackgroundMusic } from "app/types/music";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import AudioPlayer from "app/components/common/AudioPlayer";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { messages, musicDrawerMessages } from "app/components/editor/sideDrawers/messages";
import { debounce } from "lodash-es";
import SelectedMusicElement from "app/components/editor/sideDrawers/MusicDrawerV2/SelectedMusicElement";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { SwiperSlide } from "swiper/react";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { AnimatePresence, motion } from "framer-motion";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import VolumeSlider from "app/components/editor/sideDrawers/MusicDrawerV2/VolumeSlider";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { musicActions } from "app/store/slices/music.slice";
import FileUploader from "app/components/common/FileUploader";
import { PlanFeature } from "app/config/planFeature";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { Button, Input, Switch } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const UPGRADE_SOURCE = "upgrade-upload-your-music-panel";
const FILE_TYPES = `${MimeType.wav},${MimeType.mp3}`;

const FlexMotion = styled(motion.div)`
  display: flex;
`;
const StyledAnimatePresence = styled(AnimatePresence)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: auto;
`;

const HiddenPlayer = styled(H1_FlexRow)`
  display: none;
  overflow: hidden;
`;

const BorderFlexRow = styled(H1_FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.gray5};
`;

const GrayFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray4};
`;

const NoMusicIcon = styled.i`
  font-size: 24px;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: calc(100% - 24px);
  margin: 0 24px 0 0;
  padding: 0 5px;
  .swiper-slide {
    display: flex;
    width: auto;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    box-shadow: none;
    background-color: white;
    border: none;
    top: calc(50% + 2px);
    bottom: 20px;
    background-size: 24px;
    transition: 0.3s all ease-in-out;
    z-index: 100;
    &:hover {
      background-size: 30px;
    }
    &:active {
      background-size: 20px;
    }
  }
  && .swiper-button-prev {
    left: -10px;
    background-color: ${({ theme }) => theme.gray1};
    &::before {
      background: linear-gradient(to left, transparent 0%, ${({ theme }) => theme.gray1} 100%);
      content: "";
      height: 100%;
      pointer-events: none;
      position: absolute;
      width: 24px;
      left: 24px;
    }
  }
  && .swiper-button-next {
    right: 15px;
    background-color: ${({ theme }) => theme.gray1};
    &::after {
      background: linear-gradient(to right, transparent 0%, ${({ theme }) => theme.gray1} 100%);
      content: "";
      height: 100%;
      pointer-events: none;
      position: absolute;
      width: 24px;
      right: 24px;
    }
  }
  .swiper-button-prev:not(.swiper-button-disabled),
  .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const Box = styled(H1_FlexRow)<{ $isColored: boolean }>`
  border-radius: 4px;
  border: 1px solid ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray5)};
  background-color: ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray1)};
  transition: transform 0.3s ease-in-out;
  &&& span {
    color: ${(props) =>
      props.$isColored
        ? props.theme.mode === ThemeMode.Light
          ? props.theme.gray1
          : props.theme.gray11
        : props.theme.gray9};
  }
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
`;

const DEFAULT_VALUE = 80;

const MusicDrawerV2 = ({ right, width, onDraftAttributeChange }: DrawerProps) => {
  const selectedBackgroundMusic = useAppSelector(musicSelectors.getSelectedBackgroundMusic);
  const selectedBackgroundMusicVolume = useAppSelector(
    musicSelectors.getSelectedBackgroundMusicVolume
  );
  const [musicSwitch, setMusicSwitch] = useState<boolean>(!!selectedBackgroundMusic);
  const [currentVolume, setCurrentVolume] = useState<number>(
    selectedBackgroundMusicVolume || DEFAULT_VALUE
  );
  const [isSwiperReady, setIsSwiperReady] = useState<boolean>(false);
  const [selectedMood, setSelectedMood] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [playTrackId, setPlayTrackId] = useState<string | undefined>(undefined);
  const playerRef = useRef<HTMLAudioElement | undefined>();
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { editorDrawerOpen } = useDrawer();
  const showPaymentModal = useUpgradeModal();
  const uploadMusicCrown = useShowCrown({ requestedFeature: PlanFeature.uploadMusic });
  const { reset, onSelectFile, importLoading, fileStoreResult, file, isFileStackErr } =
    useFileUploadFilestack({
      autoUpload: true
    });

  const uploadStatus: FetchStatus = useAppSelector((state) => state.music.uploadStatus);
  const currentDraft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const allBackgroundMusic: BackgroundMusic[] = useAppSelector((state) =>
    musicSelectors.formattedMusicTracks(state, { query })
  );
  const defaultTrack: BackgroundMusic = useAppSelector((state) =>
    musicSelectors.formattedMusicTracks(state, { query: "" })
  )[0];
  const allMoods = useAppSelector(musicSelectors.getAllMoods) || [];
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const playTrack = useAppSelector((state) =>
    musicSelectors.getBackgroundMusicById(state, { id: playTrackId })
  );
  const backgroundMusicLoad: boolean =
    useAppSelector((state) => state.music.status) === fetchingStatus.loading;

  const isDrawerActive: boolean = editorDrawerOpen === Drawer.MusicV2;
  const isUploadMusicLoading =
    uploadStatus === fetchingStatus.loading || importLoading || (!!file && !isFileStackErr);

  const playTrackUrl: string = useMemo(() => {
    if (playTrack) {
      const trackWithSignature = addPolicyToUrlIfFileStackMedia(playTrack.url, filestackPolicy);
      return trackWithSignature;
    }
    return "";
  }, [playTrack, filestackPolicy]);

  useEffect(() => {
    if (uploadStatus === fetchingStatus.succeeded) {
      dispatch(musicActions.updateUploadStatusToIdle());
      if (fileStoreResult) {
        const track = allBackgroundMusic.find((currentTrack) =>
          currentTrack.url.includes(fileStoreResult.handle)
        );
        if (track) {
          onMusicSelected(track.id);
        }
      }
    }
    if (uploadStatus === fetchingStatus.failed) {
      dispatch(musicActions.updateUploadStatusToIdle());
    }
  }, [uploadStatus]);

  useEffect(() => {
    if (isDrawerActive) {
      setTimeout(() => {
        setIsSwiperReady(true);
      }, 1000);
    } else {
      setPlayTrackId(undefined);
      setIsSwiperReady(false);
      reset();
    }
  }, [isDrawerActive]);

  useEffect(() => {
    if (fileStoreResult) {
      (async () => {
        const duration = await getAudioFileDuration(file as File);
        const newMusicFile: Partial<BackgroundMusic> = {
          url: fileStoreResult.url,
          title: fileStoreResult.name?.split(".").slice(0, -1).join(".") || "Untitled music",
          id: fileStoreResult.handle,
          duration: Math.ceil(duration as number)
        };
        dispatch(musicActions.uploadBackgroundMusicListRequest(newMusicFile));
        reset();
      })();
    }
  }, [fileStoreResult]);

  const onChange = (checked: boolean) => {
    setMusicSwitch(checked);
    dispatch(analyticsEvents.toggleMusic({ value: checked, draft: currentDraft.id as string }));
    if (checked && !!playTrack) {
      onDraftAttributeChange(
        "attributes.music.background_music",
        { url: playTrack?.url, audio_properties: { volume: currentVolume / 100 } },
        true
      );
    } else if (checked && !selectedBackgroundMusic) {
      onDraftAttributeChange(
        "attributes.music.background_music",
        { url: defaultTrack?.url, audio_properties: { volume: currentVolume / 100 } },
        true
      );
    } else if (!checked) {
      onDraftAttributeChange("attributes.music.background_music", null, true);
      setPlayTrackId(undefined);
    }
    if (playerRef.current) {
      playerRef.current.volume = currentVolume / 100;
    }
  };

  const onSelectMood = (mood: string) => {
    dispatch(googleEvents.searchMusic({ value: mood, isMood: true }));
    const newQuery = selectedMood === mood ? "" : mood;
    setSelectedMood(newQuery);
    setQuery(newQuery);
  };

  const onSetVolume = (vol: number, finalSelect: boolean) => {
    setCurrentVolume(vol);
    if (playerRef.current) {
      playerRef.current.volume = vol / 100;
    }
    if (finalSelect && musicSwitch) {
      onDraftAttributeChange(
        "attributes.music.background_music.audio_properties.volume",
        vol / 100,
        true
      );
    }
  };

  const toggleTrackById = (itemId: string) => {
    setPlayTrackId(playTrackId === itemId ? undefined : itemId);
  };

  const onMusicSelected = (itemId: string) => {
    const track = allBackgroundMusic.find((track) => track.id === itemId) as BackgroundMusic;
    dispatch(
      analyticsEvents.selectBackgroundMusicTrack({ track, draft: currentDraft.id as string })
    );
    onDraftAttributeChange(
      "attributes.music.background_music",
      { url: track.url, audio_properties: { volume: currentVolume / 100 } },
      true
    );
    toggleTrackById(itemId);
    if (!musicSwitch) {
      setMusicSwitch(true);
      if (playerRef.current) {
        playerRef.current.volume = currentVolume / 100;
      }
    }
  };

  const togglePlayTrack = (e: SyntheticEvent, itemId: string) => {
    e.stopPropagation();
    toggleTrackById(itemId);
    if (playerRef.current) {
      playerRef.current.volume = currentVolume / 100;
    }
  };

  const debounceRequest = useCallback(
    debounce((value: string) => {
      setQuery(value);
      if (value) {
        dispatch(googleEvents.searchMusic({ value }));
      }
      setSelectedMood("");
    }, 750),
    []
  );

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceRequest(e.target.value);
  };

  const onClearSearch = () => {
    setSelectedMood("");
    setQuery("");
  };

  const onClickUploadYourMusic = (e: React.MouseEvent) => {
    const shouldUpgrade = showPaymentModal({
      source: UPGRADE_SOURCE,
      requestedPlans: [PlanEnum.enterprise],
      targetPlan: PlanEnum.enterprise,
      upgradeText: intl.formatMessage(messages.uploadYourMusicButtonUpgradeText)
    });
    if (shouldUpgrade) {
      e.stopPropagation();
    } else {
      if (fileUploaderRef.current) {
        (fileUploaderRef.current as any).onClick(e);
      }
    }
  };

  return (
    <FlexDrawer width={width} active={isDrawerActive} right={right} className="music-drawer-v2">
      <ConditionalRender condition={!!backgroundMusicLoad}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!backgroundMusicLoad}>
        <H1_FlexColumn flex="0 0 auto" width="100%">
          {/* Search */}
          <H1_FlexRow flex="1 0 auto" padding="0 22px 0 0">
            <Input
              fullWidth
              size="sm"
              placeholder={intl.formatMessage(musicDrawerMessages.searchPlaceholder)}
              value={query}
              isClearable
              onClear={onClearSearch}
              onChange={onSearch}
              labelPlacement="outside-left"
              classNames={{ mainWrapper: "w-full" }}
            />
          </H1_FlexRow>
          {/* Swiper - moods */}
          <ConditionalRender condition={isSwiperReady}>
            <H1_FlexRow position="relative" width="100%" flex="0 0 50px" gap="10px">
              <StyledSwiper
                allowTouchMove={false}
                navigation
                speed={700}
                slidesPerView="auto"
                spaceBetween={10}
                slidesPerGroup={2}
              >
                {allMoods.map((mood: string, index: number) => (
                  <SwiperSlide key={index}>
                    <Box
                      onClick={() => onSelectMood(mood)}
                      $isColored={selectedMood === mood}
                      width="fit-content"
                      padding="0 5px"
                      height="30px"
                      justify="center"
                      align="center"
                    >
                      <H1_TextSmall lineHeight="12px">{mood}</H1_TextSmall>
                    </Box>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
            </H1_FlexRow>
          </ConditionalRender>
          {/* No swiper - skeleton loader */}
          <ConditionalRender condition={!isSwiperReady}>
            <H1_FlexColumn padding="10px 20px 0 0" flex="0 0 50px">
              <StyledSkeletonButton $width="100%" $height="40px" active block size="large" />
            </H1_FlexColumn>
          </ConditionalRender>
          {/* Selected music */}
          <ConditionalRender condition={!!selectedBackgroundMusic}>
            <SelectedMusicElement
              itemId={selectedBackgroundMusic?.id as string}
              isSticky
              isPlaying={!!playTrackId && selectedBackgroundMusic?.id === playTrackId}
              isSelected={!!selectedBackgroundMusic?.id}
              onClickMusicPlay={(e, itemId) => togglePlayTrack(e, itemId)}
              query=""
            />
          </ConditionalRender>
          {/* Music not selected */}
          <ConditionalRender condition={!selectedBackgroundMusic}>
            <H1_FlexRow padding="20px" flex="0 0 auto" height="120px" gap="19px" align="center">
              <GrayFlexRow position="relative" height="80px" width="80px" flex="0 0 80px">
                <NoMusicIcon className="far fa-music-slash" />
              </GrayFlexRow>
              <H1_TextSmall>{intl.formatMessage(musicDrawerMessages.noMusicSelected)}</H1_TextSmall>
            </H1_FlexRow>
          </ConditionalRender>
          <VolumeSlider onSelect={onSetVolume} defaultValue={selectedBackgroundMusicVolume} />
          {/* Upgrade button and switch */}
          <BorderFlexRow
            flex="1"
            justify="space-between"
            gap="8px"
            padding="15px 0 30px 0"
            margin="0 24px 0 0"
            align="center"
          >
            <H1_FlexColumn align="center" position="relative">
              <FileUploader
                onFilesSelected={(files) => onSelectFile(files[0])}
                disabled={importLoading}
                fileTypes={FILE_TYPES}
                multiple={false}
                ref={fileUploaderRef}
              >
                <Button
                  color="primary"
                  onClick={onClickUploadYourMusic}
                  isLoading={isUploadMusicLoading}
                  startContent={
                    isUploadMusicLoading ? (
                      ""
                    ) : uploadMusicCrown ? (
                      <H1_Icon color={theme.gray1} icon="fas fa-crown" isCursorPointer />
                    ) : (
                      <i className="fas fa-music" />
                    )
                  }
                >
                  {intl.formatMessage(musicDrawerMessages.uploadYourMusic)}
                </Button>
              </FileUploader>
            </H1_FlexColumn>
            <H1_FlexRow gap="10px" align="center">
              <H1_TextSmall>{intl.formatMessage(musicDrawerMessages.switch)}</H1_TextSmall>
              <Switch
                size="sm"
                color="primary"
                defaultSelected={musicSwitch}
                isSelected={musicSwitch}
                onValueChange={setMusicSwitch}
                onChange={(e) => onChange(e.target.checked)}
              />
            </H1_FlexRow>
          </BorderFlexRow>
        </H1_FlexColumn>
        {/* Music boxes */}
        <StyledAnimatePresence>
          <H1_FlexColumn width="100%" overflow="hidden auto" gap="15px">
            {allBackgroundMusic.map((track) => (
              <FlexMotion
                layout
                key={track.id}
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                  default: {
                    duration: 0.5
                  },
                  scale: {
                    damping: 2
                  }
                }}
              >
                <SelectedMusicElement
                  key={track.id}
                  itemId={track.id}
                  isSticky={false}
                  isPlaying={!!playTrackId && track.id === playTrackId}
                  isSelected={track.id === selectedBackgroundMusic?.id}
                  onChooseMusic={onMusicSelected}
                  onClickMusicPlay={(e, itemId) => togglePlayTrack(e, itemId)}
                  query={query}
                />
              </FlexMotion>
            ))}
          </H1_FlexColumn>
        </StyledAnimatePresence>
        <HiddenPlayer width="100%">
          <AudioPlayer
            audioFile={playTrack ? playTrackUrl : ""}
            isFetchingAudio={false}
            ref={playerRef}
          />
        </HiddenPlayer>
      </ConditionalRender>
    </FlexDrawer>
  );
};

export default MusicDrawerV2;
