import { Sequence, useVideoConfig, Video } from "remotion";
import React, { CSSProperties, useEffect, useMemo } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import { VideoFit } from "app/types/media";
import { PaletteColor } from "app/types";
import { hexToHsl, hexToHslWithAdjustedLightness } from "app/utils/helpers";

const StyledFlexRow = styled(H1_FlexRow)<{ $isScaled: boolean; $isCropped: boolean }>`
  video {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    object-fit: ${({ $isScaled, $isCropped }) =>
      $isScaled ? "fill" : $isCropped ? "cover" : "contain"};
  }
`;

const StyledVideo = styled(Video)<{
  $objectPosition: string;
  $transform?: string;
  $isCentered?: boolean;
  $width: string;
  $height: string;
  $backgroundColorLeft?: string;
  $backgroundColorRight?: string;
}>`
  transform: ${({ $transform, $isCentered }) => !$isCentered && $transform};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  object-position: ${({ $objectPosition, $isCentered }) =>
    $isCentered ? "50% 50%" : $objectPosition};
  background: ${({ $backgroundColorLeft, $backgroundColorRight, $isCentered }) =>
    $isCentered &&
    $backgroundColorLeft &&
    $backgroundColorRight &&
    `linear-gradient(
    to bottom,
    ${$backgroundColorLeft},
    ${$backgroundColorRight}
  )`};
`;
interface VideoPreviewProps {
  cropAspectRatio: number;
  videoFit: VideoFit;
  padColor: PaletteColor;
  originalWidth: number;
  originalHeight: number;
  disablePreview?: boolean;
  isVideo?: boolean;
  thumbnailStyle: CSSProperties;
  src: string;
  scaleSize: number;
  cropX: number;
  cropY: number;
  trimStart: number;
  trimEnd: number;
  onClick?: () => void;
  onPlay: () => void;
  duration: number;
  onDurationInFrames: (frames: number) => void;
  onVideoLoaded: () => void;
}

const VideoPreview: React.FC<Record<string, unknown>> = (props) => {
  const {
    cropAspectRatio,
    videoFit,
    padColor,
    originalWidth,
    originalHeight,
    src,
    scaleSize,
    cropX,
    cropY,
    trimStart,
    trimEnd,
    onPlay,
    duration,
    onDurationInFrames,
    onVideoLoaded
  } = props as unknown as VideoPreviewProps; // Fucking typescript
  const { width, height, durationInFrames } = useVideoConfig();

  const fps = useMemo(() => durationInFrames / duration, []);
  const startFrom = useMemo(() => Math.floor(trimStart * fps), [trimStart, fps]);
  const endAt = useMemo(() => Math.floor(trimEnd * fps), [trimEnd, fps]);
  const multiplier = cropAspectRatio > 1 ? width : height;
  const divider = cropAspectRatio > 1 ? originalWidth : originalHeight;
  const objectPositionLeft = (cropX * multiplier) / divider;
  const objectPositionTop = (cropY * multiplier) / divider;
  const isVideoScaled = videoFit === VideoFit.Scale;
  const isVideoCropped = videoFit === VideoFit.Crop;
  const isVideoPadded = videoFit === VideoFit.Pad;

  const padHsl = useMemo(() => {
    if (padColor) {
      return hexToHsl(padColor.color);
    }
  }, [padColor]);

  const padHslLightness = useMemo(() => {
    if (padColor) {
      return hexToHslWithAdjustedLightness(padColor.color, 30);
    }
  }, [padColor]);

  const padHslCssString = padHsl ? `hsl(${padHsl[0]}, ${padHsl[1]}%, ${padHsl[2]}%)` : undefined;
  const padHslLightnessCssString = padHslLightness
    ? `hsl(${padHslLightness[0]}, ${padHslLightness[1]}%, ${padHslLightness[2]}%)`
    : undefined;

  useEffect(() => {
    onDurationInFrames(durationInFrames);
  }, [durationInFrames]);

  return (
    <StyledFlexRow
      overflow="hidden"
      position="relative"
      width={`${width}px`}
      height={`${height}px`}
      $isScaled={isVideoScaled}
      $isCropped={isVideoCropped}
    >
      <Sequence>
        <StyledVideo
          onCanPlay={onVideoLoaded}
          onPlay={onPlay}
          startFrom={startFrom}
          endAt={endAt || 1}
          src={src}
          $width={`${width}px`}
          $height={`${height}px`}
          $objectPosition={`left -${objectPositionLeft}px top -${objectPositionTop}px`}
          $transform={`scale(${scaleSize})`}
          $isCentered={isVideoScaled || isVideoPadded}
          $backgroundColorLeft={padHslCssString}
          $backgroundColorRight={padHslLightnessCssString}
        />
      </Sequence>
    </StyledFlexRow>
  );
};

export default VideoPreview;
