import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Checkbox } from "antd";
import TemplateSelectSideMenu from "app/components/editor/templateSelect/TemplateSelectSideMenu";
import TemplateSearchBox from "app/components/editor/templateSelect/TemplateSearchBox";
import TemplatesList from "app/components/editor/templateSelect/TemplatesList";
import { Location, Recipe, RecipeList, RecipeType } from "app/types";
import TemplatePreviewContent from "app/components/editor/templateSelect/TemplatePreviewContent";
import { useAppDispatch, useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import useModal, { ModalName } from "app/hooks/useModal";
import { recipesActions } from "app/store/slices/recipes.slice";
import { fetchingStatus } from "app/utils/helpers";
import { foldersActions } from "app/store/slices/folders.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import ConditionalRender from "app/components/common/ConditionalRender";
import { draftsActions } from "app/store/slices/drafts.slice";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import * as userSelectors from "app/store/selectorsV2/user.selectors";
import uiActions from "app/store/actions/ui.actions";
import { templatesMessages } from "app/components/editor/templateSelect/messages";
import { useIntl } from "react-intl";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { charactersActions } from "app/store/slices/characters.slice";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useAuth } from "app/auth/useAuth";
import { getFoldersSortAlphabetically } from "app/store/selectorsV2/folders.selectors";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { StyledModal } from "app/components/common/StyledModal";

const SelectTemplateModal = styled(StyledModal)`
  min-width: 400px;

  & .ant-modal-content {
    overflow: hidden;
  }

  & .ant-layout-footer {
    padding: 0px;
  }
`;

const TemplateSelectContentWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const SiderWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
`;

const MainAreaWrapper = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TemplateSelectModal = () => {
  const [showMobileTemplates, setShowMobileTemplates] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | undefined>(undefined);
  const [selectedSection, setSelectedSection] = useState<string | undefined>(undefined);
  const [selectedSectionType, setSelectedSectionType] = useState<RecipeType | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { editorModalOpen, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useAuth();

  const recipesData = useAppSelector(recipesSelectors.recipesData);
  const customRecipesData = useAppSelector(recipesSelectors.customRecipesData);
  const personalRecipesData = useAppSelector(recipesSelectors.personalRecipesData);
  const selectedSectionList = useMemo(
    () => (selectedSection ? [selectedSection] : []),
    [selectedSection]
  );
  const selectedSectionData = useAppSelector((state) =>
    recipesSelectors.selectedSectionData(state, selectedSectionList, selectedSectionType)
  );
  const folders = useAppSelector(getFoldersSortAlphabetically);
  const recipesStatus = useAppSelector((state) => state.recipes.status);
  const deleteStatus = useAppSelector((state) => state.recipes.deleteStatus);
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const createdDraft = useAppSelector(draftsSelectors.currentDraft);
  const isOnBoardingElearning = useAppSelector(userSelectors.isOnBoardingElearning);
  const isOnBoardingNotElearning = useAppSelector(userSelectors.isOnBoardingNotElearning);
  const isLoading = recipesStatus === fetchingStatus.loading;
  const visible = editorModalOpen?.open === ModalName.templateSelect;
  const folderId = editorModalOpen?.context?.folderId;
  const recipeId = editorModalOpen?.context?.recipeId;
  const recipeType = editorModalOpen?.context?.recipeType;
  const mobileCharacters = useAppSelector(CharacterSelectors.getAllMobileCharacters);
  const templateRecipe = useAppSelector((state) => {
    const res = recipesSelectors.getRecipeByRecipeId(state, recipeId);
    return res;
  });
  const currentWorkspaceId = useAppSelector((state) => getCurrentWorkspace(state)?.id);

  useEffect(() => {
    if (templateRecipe) {
      setSelectedRecipe(templateRecipe);
    }
  }, [templateRecipe]);

  useEffect(() => {
    if (recipeType) {
      setSelectedSectionType(recipeType);
    }
  }, [recipeType]);

  useEffect(() => {
    if (deleteStatus === fetchingStatus.succeeded) {
      dispatch(recipesActions.updateDeleteStatusToIdle());
      dispatch(
        uiActions.setNotifications([
          {
            message: formatMessage(templatesMessages.deleteTemplateSucceeded)
          }
        ])
      );
    }
    if (deleteStatus === fetchingStatus.failed) {
      dispatch(recipesActions.updateDeleteStatusToIdle());
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (visible && !recipeId) {
      dispatch(recipesActions.getRecipesRequest({ examples: !isAuthenticated }));
      if (isAuthenticated) {
        dispatch(foldersActions.getRootFoldersRequest());
        if (!mobileCharacters?.length) {
          dispatch(charactersActions.getMobileCharactersListRequest());
        }
      }
    } else {
      setShowMobileTemplates(false);
    }
  }, [visible, recipeId, currentWorkspaceId]);

  useEffect(() => {
    if (!visible) {
      setSelectedSection(undefined);

      setSearchQuery("");
      setSelectedRecipe(undefined);
    }
  }, [visible]);

  useEffect(() => {
    if (createDraftStatus === fetchingStatus.idle || !visible) {
      return;
    }
    if (createDraftStatus === fetchingStatus.succeeded) {
      closeModal();
      dispatch(analyticsEvents.navigateToEditor({ source: "template_select_modal" }));
      navigate(`/editor/${createdDraft.id}`);
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    } else if (createDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    }
  }, [createDraftStatus, visible]);

  const onSelectSection = (section: RecipeList | undefined, type?: RecipeType) => {
    if (section) {
      dispatch(analyticsEvents.templateCategorySelect({ categoryName: section.name }));
    }
    setSelectedSection(section?.name);
    setSelectedSectionType(type);
    setSearchQuery("");
  };

  const resetSearch = () => {
    setSelectedSection(undefined);
    setSelectedSectionType(undefined);
    setSearchQuery("");
    setShowMobileTemplates(false);
  };

  const onChangeQuery = (query: string) => {
    setSelectedSection(undefined);
    setSelectedSectionType(undefined);
    setSearchQuery(query);
  };

  const onSelectRecipe = (recipe: Recipe) => {
    dispatch(
      analyticsEvents.templateSelectedModal({
        templateName: recipe.title,
        source: editorModalOpen?.context?.source
      })
    );

    setSelectedRecipe(recipe);
  };

  const userNotAuthActionHandler = () => {
    closeModal();
    navigate(Location.Home);
  };

  const onCancel = () => {
    if (!isAuthenticated) {
      userNotAuthActionHandler();
      return;
    }
    if (location.pathname === Location.Templates) {
      closeModal();
      return;
    }
    if (isOnBoardingElearning) {
      openModal(ModalName.templateFlowChoosingModal);
    } else if (isOnBoardingNotElearning) {
      openModal(ModalName.recommendedTemplatesModal);
    } else {
      closeModal();
    }
  };

  const onPresentation = () => {
    dispatch(
      analyticsEvents.templateSelectedModal({
        templateName: "presentation",
        source: "template-select-modal"
      })
    );
    if (!isAuthenticated) {
      userNotAuthActionHandler();
      return;
    }
    openModal(ModalName.presentationUploader, { source: ModalName.templateSelect });
  };

  const onChangeMobileTemplates = (e: CheckboxChangeEvent) => {
    setShowMobileTemplates(e.target.checked);
  };

  return (
    <SelectTemplateModal
      open={visible}
      destroyOnClose
      footer={null}
      width={1120}
      centered
      bodyStyle={{ height: "80vh", width: "auto" }}
      onCancel={onCancel}
      closeIcon={<CloseModalIcon />}
      maskClosable={!isAuthenticated}
      closable={isAuthenticated}
    >
      <ConditionalRender condition={!!selectedRecipe}>
        <TemplatePreviewContent
          userNotAuthActionHandler={userNotAuthActionHandler}
          folders={folders}
          folderId={folderId}
          recipe={selectedRecipe as Recipe}
          recipeType={selectedSectionType}
        />
      </ConditionalRender>

      <ConditionalRender condition={!selectedRecipe}>
        <TemplateSelectContentWrapper>
          <SiderWrapper>
            <TemplateSelectSideMenu
              sections={recipesData}
              customSections={customRecipesData}
              personalSections={personalRecipesData}
              selectedSection={selectedSection}
              selectedSectionType={selectedSectionType}
              onSelectSection={onSelectSection}
              onPresentation={onPresentation}
            />
          </SiderWrapper>
          <MainAreaWrapper>
            <H1_FlexRow gap="10px" align="center" padding="0 50px 0 0">
              <TemplateSearchBox
                onReset={resetSearch}
                onSearch={onChangeQuery}
                value={searchQuery}
              />
              <ConditionalRender condition={mobileCharacters?.length > 0}>
                <Checkbox checked={showMobileTemplates} onChange={onChangeMobileTemplates}>
                  {formatMessage(templatesMessages.supportMyVirtualHuman)}
                </Checkbox>
              </ConditionalRender>
            </H1_FlexRow>
            <TemplatesList
              resetSearch={resetSearch}
              loading={isLoading}
              sections={selectedSectionData}
              searchFilter={searchQuery}
              selectRecipe={onSelectRecipe}
              selectedSectionType={selectedSectionType}
              showMobileTemplates={showMobileTemplates}
            />
          </MainAreaWrapper>
        </TemplateSelectContentWrapper>
      </ConditionalRender>
    </SelectTemplateModal>
  );
};

export default TemplateSelectModal;
