import styled from "styled-components";
import { Button } from "antd";

export const NextButton = styled(Button)`
  width: 260px;
  font-size: 16px;
  height: 40px;
  border: none;
  color: white;
  background: ${(props) => props.theme.pink4};
  border-radius: 6px;
  margin-bottom: 15px;

  &:hover,
  &:focus,
  &:active {
    color: white;
    background: ${(props) => props.theme.pink4};
  }
`;
