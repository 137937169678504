import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import pusher from "app/services/pusher";
import { Channel } from "pusher-js";
import { useAuth } from "app/auth/useAuth";
import { draftsActions } from "app/store/slices/drafts.slice";
import { extractUserIdForPusher, fetchingStatus } from "app/utils/helpers";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import * as Sentry from "@sentry/react";
import { useIntl, defineMessages } from "react-intl";
import { PusherMessagesStatusEnum, SplitMsg } from "app/types/pusherMessages";
const segmentToScenes = "augment_scene.split";

export const messages = defineMessages({
  segmentToSceneError: {
    id: "global-pusher.augment_scene.split.error",
    defaultMessage: "Split transcript failed"
  }
});

const PusherSceneAugmentationHandler = () => {
  const dispatch = useAppDispatch();
  const { notifyError } = useErrors();
  const { formatMessage } = useIntl();
  const { user } = useAuth();

  const segmentToSceneStatus = useAppSelector((state) => state.drafts.segmentToSceneStatus);

  useEffect(() => {
    if (segmentToSceneStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.refreshDraftAndScenes());
      dispatch(draftsActions.updateSegmentToScenesStatusToIdle());
    }
    if (segmentToSceneStatus === fetchingStatus.failed) {
      notifyError({
        general: buildGeneralMessage(
          `Something went wrong`,
          formatMessage(messages.segmentToSceneError)
        )
      });
      dispatch(draftsActions.updateSegmentToScenesStatusToIdle());
    }
  }, [segmentToSceneStatus]);

  const onSegmentToScenesResponse = (message: SplitMsg) => {
    if (!message) return;
    const { status, scene_id: sceneId, order_id: orderId } = message;
    if (status === PusherMessagesStatusEnum.ready) {
      dispatch(
        draftsActions.handleSegmentToScenesResults({
          status: fetchingStatus.succeeded,
          sceneId,
          orderId
        })
      );
    } else {
      dispatch(
        draftsActions.handleSegmentToScenesResults({
          status: fetchingStatus.failed,
          sceneId,
          orderId
        })
      );
      Sentry.captureMessage("Client got failed augmented scene pusher message", {
        extra: {
          sceneId,
          status
        }
      });

      notifyError({
        general: buildGeneralMessage(
          `Something went wrong`,
          formatMessage(messages.segmentToSceneError)
        )
      });
    }
  };

  useEffect(() => {
    let subs: Channel;
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      subs = pusher.subscribe(channelName);
      subs.bind(segmentToScenes, onSegmentToScenesResponse);
    }
    return () => {
      if (subs) {
        subs.unbind(segmentToScenes, onSegmentToScenesResponse);
        subs.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherSceneAugmentationHandler;
