import { FeatureFlag, Folder } from "app/types";
import { folderItemMessages } from "app/pages/FoldersPage/messages";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import usePermissions from "app/hooks/usePermissions";
import { useAppDispatch } from "app/hooks";
import { playlistsActions } from "app/store/slices/playlists.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useFlags } from "launchdarkly-react-client-sdk";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const StyledIcon = styled.i`
  font-size: 1.5rem;
  height: 2rem;
  line-height: 2rem;
  margin: auto;
  padding: 0 0.5rem;
  width: 100%;
  transform: translate(50%);
  color: ${({ theme }) => theme.blue4};
`;

interface FolderItemDropdownProps {
  folderItem: Folder;
  icon?: ReactNode;
  className?: string;
}

const FolderItemDropdown = ({
  className = "folder-dropdown-",
  folderItem,
  icon
}: FolderItemDropdownProps) => {
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const flags = useFlags();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isWriteWorkspaceAllow, isEnterprise } = usePermissions();
  const isPlaylistAvailable = flags[FeatureFlag.playlists] && isEnterprise;

  const onClickFolder = (e: React.MouseEvent, { id }: Folder) => {
    e.stopPropagation();
    navigate(`/folders/${id}`);
  };

  const onClickCreatePlaylist = (e: React.MouseEvent, { id }: Folder) => {
    e.stopPropagation();
    const title = folderItem.name || "New Playlist";
    dispatch(
      analyticsEvents.createNewPlaylist({
        source: "folder_dropdown",
        folderId: id,
        title
      })
    );
    dispatch(
      playlistsActions.createPlaylistRequest({
        folderId: id,
        title
      })
    );
  };

  const onRenameClick = (e: React.MouseEvent, folder: Folder) => {
    e.stopPropagation();
    openModal(ModalName.renameFolder, { folderId: folder.id });
  };

  const onDeleteFolderClicked = (e: React.MouseEvent, { id }: Folder) => {
    e.stopPropagation();
    openModal(ModalName.deleteFolder, {
      folderId: id as string
    });
  };
  const menu = {
    items: [
      {
        key: "open",
        startContent: <i className="far fa-folder text-gray-11" />,
        label: <H1_TextSmall>{formatMessage(folderItemMessages.menuItemOpenFolder)}</H1_TextSmall>,
        title: formatMessage(folderItemMessages.menuItemOpenFolder),
        onClick: (event: React.MouseEvent) => onClickFolder(event, folderItem)
      },
      isPlaylistAvailable && {
        key: "create-playlist",
        startContent: <i className="far fa-list-music text-gray-11" />,
        label: <H1_TextSmall>{formatMessage(folderItemMessages.menuItemCreateList)}</H1_TextSmall>,
        title: formatMessage(folderItemMessages.menuItemCreateList),
        onClick: (event: React.MouseEvent) => onClickCreatePlaylist(event, folderItem)
      },
      {
        key: "rename",
        startContent: <i className="far fa-pencil text-gray-11" />,
        label: (
          <H1_TextSmall>{formatMessage(folderItemMessages.menuItemRenameFolder)}</H1_TextSmall>
        ),
        title: formatMessage(folderItemMessages.menuItemRenameFolder),
        onClick: (event: React.MouseEvent) => onRenameClick(event, folderItem),
        disabled: !isWriteWorkspaceAllow
      },
      {
        key: "delete",
        startContent: <i className="far fa-trash text-pink-4" />,
        label: (
          <H1_TextSmall color={theme.pink4}>
            {formatMessage(folderItemMessages.menuItemDeleteFolder)}
          </H1_TextSmall>
        ),
        title: formatMessage(folderItemMessages.menuItemDeleteFolder),
        onClick: (event: React.MouseEvent) => onDeleteFolderClicked(event, folderItem),
        disabled: !isWriteWorkspaceAllow
      }
    ]
  };

  return (
    <NextDropdown
      withWhiteBackground
      color="primary"
      items={menu.items}
      className={className + " overlay-dropdown"}
    >
      {icon ? icon : <StyledIcon className="far fa-ellipsis-h" />}
    </NextDropdown>
  );
};

export default FolderItemDropdown;
