import { useEffect, useMemo, useState } from "react";
import {
  DEEP_LINK_SOURCE,
  FetchStatus,
  PaymentModalAction,
  PaymentModalPhase,
  PaymentModalSource,
  PaymentPeriod,
  PlanEnum,
  PlanToUpgrade,
  PRICING_PAGE_SOURCE
} from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useModal, { ModalName } from "app/hooks/useModal";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { paymentsActions } from "app/store/slices/payments.slice";
import { fetchingStatus } from "app/utils/helpers";
import { onOpenZendesk } from "app/utils/zendesk/zendesk";
import * as Sentry from "@sentry/react";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { RootState } from "app/store/store";
import { userActions } from "app/store/slices/user.slice";
import _ from "lodash";

interface usePaymentFlowProps {
  active: boolean;
  source: string;
  targetPlan: PlanEnum;
  paymentPeriod: PaymentPeriod;
  upgradeText: string;
}

const usePaymentFlow = ({
  active,
  source,
  targetPlan,
  paymentPeriod,
  upgradeText
}: usePaymentFlowProps) => {
  const [pricingFetchError, setPricingFetchError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { returnToModal, openModal } = useModal();
  const { currentPlan } = useCurrentPlan();
  const { originalPlan } = useAppSelector((state) => state.payments.pricingModalProperties);
  const initialQueryParams = useAppSelector((state) => state.user.initialQueryParams);
  const pricingAndPlans = useAppSelector((state: RootState) => state.payments.pricingAndPlans);
  const pricingStatus: FetchStatus = useAppSelector((state) => state.payments.pricingStatus);
  const shouldStartWithCreditCardPage = active && source === PRICING_PAGE_SOURCE;

  useEffect(() => {
    if (!active) {
      return;
    }
    if (pricingStatus === fetchingStatus.succeeded) {
      dispatch(paymentsActions.setPricingSessionStatusToIdle());
    }
    if (pricingStatus === fetchingStatus.failed) {
      dispatch(paymentsActions.setPricingSessionStatusToIdle());
      setPricingFetchError(true);
      onOpenZendesk();
    }
  }, [pricingStatus, active]);

  useEffect(() => {
    if (!active) {
      dispatch(
        paymentsActions.updatePricingModalProperties({
          phase: PaymentModalPhase.INITIAL,
          originalPlan: undefined
        })
      );
      dispatch(paymentsActions.cleanPayment());
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      if (shouldStartWithCreditCardPage) {
        dispatch(
          paymentsActions.updatePricingModalProperties({
            phase: PaymentModalPhase.CREDIT_CARD_DETAILS
          })
        );
      }

      dispatch(
        paymentsActions.updatePricingModalProperties({
          originalPlan: currentPlan
        })
      );

      if (paymentPeriod) {
        dispatch(
          paymentsActions.updatePricingModalProperties({
            paymentPeriod
          })
        );
      }
    }
  }, [active, paymentPeriod, shouldStartWithCreditCardPage]);

  useEffect(() => {
    if (
      pricingAndPlans.length &&
      (initialQueryParams.plan === PlanToUpgrade.personal ||
        initialQueryParams.plan === "lite" ||
        initialQueryParams.plan === PlanToUpgrade.business)
    ) {
      const queryParamsPlan: string =
        initialQueryParams.plan === "lite" ? PlanToUpgrade.personal : initialQueryParams.plan;
      openModal(ModalName.paymentModalV2, {
        source: DEEP_LINK_SOURCE,
        targetPlan: queryParamsPlan,
        paymentPeriod: _.capitalize(initialQueryParams.type)
      });
      dispatch(userActions.setInitialQueryParams({}));
    }
  }, [initialQueryParams, pricingAndPlans.length]);

  useEffect(() => {
    if (initialQueryParams.type) {
      switch (initialQueryParams.type.toLowerCase()) {
        case PaymentPeriod.Monthly.toLowerCase():
          dispatch(
            paymentsActions.updatePricingModalProperties({
              paymentPeriod: PaymentPeriod.Monthly
            })
          );
          break;
        case PaymentPeriod.Yearly.toLowerCase():
          dispatch(
            paymentsActions.updatePricingModalProperties({
              paymentPeriod: PaymentPeriod.Yearly
            })
          );
          break;
        default:
          dispatch(
            paymentsActions.updatePricingModalProperties({
              paymentPeriod: PaymentPeriod.Yearly
            })
          );
          break;
      }
    }
  }, [initialQueryParams.type]);

  const planToUpgrade = useMemo(() => {
    if (!active || !originalPlan) {
      return PlanToUpgrade.personal;
    }
    if (targetPlan === PlanEnum.personal) {
      return PlanToUpgrade.personal;
    }
    if (targetPlan === PlanEnum.business) {
      return PlanToUpgrade.business;
    }
    if (originalPlan === PlanEnum.onBoarding) {
      return PlanToUpgrade.personal;
    }
    if (originalPlan === PlanEnum.personal) {
      return PlanToUpgrade.business;
    }

    const error = `User plan is neither on boarding nor personal - its ${originalPlan}`;
    console.error(error);
    Sentry.captureException(error);

    return PlanToUpgrade["on-boarding"];
  }, [originalPlan, targetPlan, active]);

  const onClose = () => {
    dispatch(
      analyticsEvents.userUpgrade({
        source: source as PaymentModalSource,
        action: PaymentModalAction.close,
        plan: currentPlan
      })
    );
    returnToModal();
  };

  const onCouponChange = (newCoupon: string) => {
    dispatch(
      paymentsActions.updatePricingModalProperties({
        coupon: newCoupon
      })
    );
    dispatch(
      analyticsEvents.userUpdatedCoupon({
        coupon: newCoupon,
        source: source as PaymentModalSource,
        plan: currentPlan
      })
    );
  };

  return {
    pricingFetchError,
    onCouponChange,
    setPricingFetchError,
    planToUpgrade,
    onClose,
    upgradeText,
    targetPlan
  };
};

export default usePaymentFlow;
