import { mediaLibraryMessages } from "app/pages/mediaLibrary/messages";

import { useIntl } from "react-intl";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { defaultFileTypes } from "app/types/media";
import FileUploader from "app/components/common/FileUploader";
import ScreenCaptureButton from "app/components/editor/scene/ScreenCaptureButton";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import ConditionalRender from "app/components/common/ConditionalRender";
import { MediaCategory } from "app/types";
import React, { useRef } from "react";
import { Button } from "@nextui-org/react";
import { ReactComponent as RecordIcon } from "app/assets/editor/record-icon.svg";

const StyledRecordIcon = styled(RecordIcon)`
  flex: 1 0 16px;
`;

const motionAnimation = { opacity: 1, transition: { duration: 0.5 } };
const noOpacity = { opacity: 0 };

const HeaderFlexRow = styled(H1_FlexRow)`
  max-height: 66px;
`;

const RelativeButton = styled(Button)`
  position: relative;
  min-width: 100px;
`;

interface MediaLibraryModalHeaderProps {
  executeImport: (file: File[]) => void;
  importLoading: boolean;
  mimetypes?: string[];
  assetKey: string;
  attributeType: MediaCategory;
}

const MediaLibraryModalHeaderActionButtons = ({
  executeImport,
  importLoading,
  assetKey,
  attributeType
}: MediaLibraryModalHeaderProps) => {
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const { sceneId } = useSelectedScene();

  const onClickUploadFile = (e: React.MouseEvent) => {
    if (fileUploaderRef.current) {
      (fileUploaderRef.current as any).onClick(e);
    }
  };

  return (
    <HeaderFlexRow justify="flex-end" align="items-center" flex="1 0 auto">
      <H1_FlexRow gap="20px">
        <AnimatePresence>
          <ConditionalRender condition={!!sceneId}>
            <motion.div
              key={"screen_capture" + assetKey}
              initial={noOpacity}
              animate={motionAnimation}
              exit={noOpacity}
            >
              <H1_FlexRow>
                <ScreenCaptureButton
                  icon={<StyledRecordIcon />}
                  variant="solid"
                  attributeType={attributeType}
                  assetKey={assetKey}
                  sceneId={sceneId}
                  disabled={false}
                />
              </H1_FlexRow>
            </motion.div>
          </ConditionalRender>
          <motion.div
            initial={noOpacity}
            animate={motionAnimation}
            exit={noOpacity}
            key={"uploader" + assetKey}
          >
            <FileUploader
              multiple
              disabled={importLoading}
              onFilesSelected={executeImport}
              fileTypes={defaultFileTypes}
              ref={fileUploaderRef}
            >
              <RelativeButton
                isDisabled={importLoading}
                isLoading={importLoading}
                icon={<i className={"fa fa-plus"} />}
                color="primary"
                onClick={onClickUploadFile}
                startContent={<i className="far fa-cloud-arrow-up" />}
              >
                {intl.formatMessage(mediaLibraryMessages.uploadButton)}
              </RelativeButton>
            </FileUploader>
          </motion.div>
        </AnimatePresence>
      </H1_FlexRow>
    </HeaderFlexRow>
  );
};

export default MediaLibraryModalHeaderActionButtons;
