import { useOthersMapped } from "app/services/liveBlocksClient";
import { avatarColorsList } from "app/types";

import Selection from "app/components/editor/scene/Selection";
import { useTheme } from "styled-components";

const Selections = ({ sceneId }: { sceneId: string }) => {
  const users = useOthersMapped((other) => ({
    email: other.presence.email,
    sceneId: other.presence.sceneId,
    connectionId: other.connectionId
  }));
  const theme = useTheme();

  return (
    <>
      {users.map(([connectionId, presence], index) => {
        if (presence.sceneId === sceneId) {
          return (
            <Selection
              key={connectionId}
              email={presence.email as string}
              color={avatarColorsList(theme)[(index + 1) % avatarColorsList(theme).length]} // The +1 is because the first one is the current user
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default Selections;
