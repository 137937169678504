import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Select } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { DraftRevertType, FeatureFlag, FetchStatus, PlanEnum } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { useIntl } from "react-intl";
import { aiGenerateMessages } from "app/components/editor/scene/AugmentedSelection/messages";
import { draftsActions } from "app/store/slices/drafts.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getNumberOfScenesOptions,
  videoWizardPlaceholdersList
} from "app/components/editor/scene/AugmentedSelection/VideoWizardConsts";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { v4 as uuidv4 } from "uuid";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import { SMALL_SCREEN_PX, VIDEO_WIZARD_TIMEOUT_SEC } from "app/config/Constants";
import useErrors from "app/hooks/useErrors";
import IncrementalLoader from "app/components/common/Loaders/IncrementalLoader";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_Textarea } from "app/components/_Infrastructure/design-system/textarea";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledIncrementalLoader = styled(IncrementalLoader)`
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 13px;
  padding-right: 30px;
`;

const WhiteFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  border-radius: 7px;
`;

const ClampFlexColumn = styled(H1_FlexColumn)<{ $noMinHeight: boolean }>`
  margin-bottom: 20px;
  width: 100%;
  min-height: ${({ $noMinHeight }) => ($noMinHeight ? "none" : "267px")};
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;

const OutlineButton = styled(Button)`
  &&,
  &&:focus {
    transition: none;
    border: 1px solid ${({ theme }) => theme.blue4};
    background-color: transparent;
    color: ${({ theme }) => theme.blue4};
    border-radius: 4px;
    i {
      font-size: 16px;
      color: ${({ theme }) => theme.blue4};
      margin-right: 7px;
    }
  }

  &&:hover {
    border-color: ${({ theme }) => theme.blue2};
    color: ${({ theme }) => theme.blue2};
    i {
      color: ${({ theme }) => theme.blue2};
    }
  }
  &&:active {
    border-color: ${({ theme }) => theme.blue1};
    color: ${({ theme }) => theme.blue1};
    i {
      color: ${({ theme }) => theme.blue1};
    }
  }
`;

const CTAButton = styled(Button)`
  z-index: 2;
  height: 32px;
  width: 100%;
`;

const StyledTextarea = styled(H1_Textarea)`
  height: 267px;
  width: 100%;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
`;

const StyledSelect = styled(Select)`
  &&&&&,
  &&&&&:focus {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 155px;
    border: none;
    .ant-select-selector {
      box-shadow: none;
      border: none;
      border: 1px solid ${({ theme }) => theme.gray4};
      border-radius: 4px;
      outline: none;
    }
  }
  &&&&:hover {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.blue4};
    }
  }
`;

const AiGenerate = () => {
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [promptValue, setPromptValue] = useState<string>("");
  const [numberOfScenes, setNumberOfScenes] = useState<number>(0);
  const [isPromptOpen, setIsPromptOpen] = useState(true);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const { notifyError } = useErrors();

  const theme = useTheme();
  const flags = useFlags();
  const topicToDraftStatus: FetchStatus = useAppSelector(
    (state) => state.drafts.topicToDraftStatus
  );
  const revertDraftStatus: FetchStatus = useAppSelector((state) => state.drafts.revertDraftStatus);
  const refreshDraftAndScenesStatus: FetchStatus = useAppSelector(
    (state) => state.drafts.refreshDraftAndScenesStatus
  );
  const appData = useAppSelector((state) => state.user.appData);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const preferences = useAppSelector((state) => state.user.preferences);
  const isFirstVideoCreated =
    preferences.is_first_video_created === undefined || preferences.is_first_video_created;
  const currentPlan: PlanEnum = appData.plan as PlanEnum;

  const isLoading =
    topicToDraftStatus === fetchingStatus.loading || revertDraftStatus === fetchingStatus.loading;
  const shouldShowIncrementalLoader =
    topicToDraftStatus === fetchingStatus.loading ||
    topicToDraftStatus === fetchingStatus.succeeded;

  useEffect(() => {
    if (
      refreshDraftAndScenesStatus === fetchingStatus.succeeded ||
      refreshDraftAndScenesStatus === fetchingStatus.failed
    ) {
      dispatch(draftsActions.setRefreshDraftAndScenesStatusToIdle());
    }
  }, [refreshDraftAndScenesStatus]);

  useEffect(() => {
    setIsPromptOpen(flags[FeatureFlag.videoWizard]);
    if (!isFirstVideoCreated) {
      setIsPromptOpen(false);
    }
  }, [flags[FeatureFlag.videoWizard], isFirstVideoCreated]);

  useEffect(() => {
    if (currentPlan === PlanEnum.onBoarding) {
      setNumberOfScenes(2);
    }
  }, [currentPlan]);

  const currentPlaceholder = useMemo(() => {
    const randomIndex =
      Math.round(Math.random() * videoWizardPlaceholdersList.length) %
      videoWizardPlaceholdersList.length;
    return videoWizardPlaceholdersList[randomIndex];
  }, [isPromptOpen]);

  const numberOfScenesSelection = useMemo(() => {
    return getNumberOfScenesOptions(currentPlan, appData);
  }, [currentPlan]);

  const sceneSelcetShowing = useMemo(() => {
    return (
      (!isFinished && !!promptValue && !!numberOfScenesSelection.length) ||
      (isFinished && flags[FeatureFlag.videoWizardRevert])
    );
  }, [isFinished, flags, promptValue, numberOfScenesSelection]);

  useEffect(() => {
    let timeoutTopicToDraftStatusId: any = null;
    if (topicToDraftStatus === fetchingStatus.succeeded) {
      // Wait a second to show the loader in 100%
      timeoutTopicToDraftStatusId = setTimeout(() => {
        dispatch(draftsActions.updateTopicToDraftStatusToIdle());
        dispatch(draftsActions.refreshDraftAndScenes());
        setIsFinished(true);
        clearTimeout(timeoutTopicToDraftStatusId);
      }, 1000);
    }
    if (topicToDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateTopicToDraftStatusToIdle());
    }
    let timeoutId: any = null;
    if (topicToDraftStatus === fetchingStatus.loading) {
      timeoutId = setTimeout(() => {
        notifyError({
          general: buildGeneralMessage(
            formatMessage(aiGenerateMessages.videoWizardTimeoutErrorHead),
            formatMessage(aiGenerateMessages.videoWizardTimeoutError)
          )
        });
        dispatch(draftsActions.updateTopicToDraftStatusToIdle());
      }, VIDEO_WIZARD_TIMEOUT_SEC * 1000);
      // Setting placeholder as value if placeholder sent as prompt
      if (promptValue === "") {
        setPromptValue(currentPlaceholder);
      }
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (timeoutTopicToDraftStatusId) {
        clearTimeout(timeoutTopicToDraftStatusId);
      }
    };
  }, [topicToDraftStatus]);

  useEffect(() => {
    if (revertDraftStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateRevertDraftStatusToIdle());
      setIsFinished(false);
      // After the animation ends, scroll to the top of the page
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 0.3);
    }
    if (revertDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateRevertDraftStatusToIdle());
    }
  }, [revertDraftStatus]);

  const onChangePromptText = (value: string) => {
    setPromptValue(value);
    setIsFinished(false);
  };

  const onFinishWizard = () => {
    openDrawer(Drawer.Scene);
  };

  const activatePrompt = (bump: boolean) => {
    dispatch(
      draftsActions.topicToDraftRequest({
        orderId: uuidv4(),
        promptValue,
        numberOfScenes,
        bump,
        type: "populate_draft_with_topic",
        draftId: currentDraft.id
      })
    );

    dispatch(
      analyticsEvents.activateVideoDrawerPrompt({
        promptValue,
        numberOfScenes
      })
    );
  };

  const onRevertWizard = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(analyticsEvents.revertVideoWizard());
    dispatch(
      draftsActions.revertDraftRequest({
        draftId: currentDraft?.id as string,
        body: {
          type: DraftRevertType.relative_to_head,
          relative_to_head: -1
        }
      })
    );
  };

  const activateEmptyPrompt = () => {
    dispatch(
      draftsActions.topicToDraftRequest({
        orderId: uuidv4(),
        type: "populate_draft_with_topic",
        draftId: currentDraft.id,
        promptValue: currentPlaceholder,
        numberOfScenes: 0,
        bump: true
      })
    );
    dispatch(
      analyticsEvents.activateVideoDrawerPrompt({
        promptValue: currentPlaceholder,
        numberOfScenes: 0
      })
    );
  };

  return (
    <ClampFlexColumn
      $noMinHeight={isLoading}
      position="relative"
      alignSelf="center"
      flex="none"
      gap="15px"
      className="main-ai-generate-container"
    >
      {/* prompt box */}
      <ConditionalRender condition={isPromptOpen}>
        {/* White text container */}
        <WhiteFlexRow
          padding="0 30px 0 0"
          position="relative"
          data-auto-id="video-wizard-prompt"
          gap="5px"
          align={isLoading ? "center" : "flex-start"}
          justify={isLoading ? "flex-start" : "space-between"}
          width="100%"
          height={isLoading ? "auto" : "100%"}
          flex={promptValue ? "1 1 auto" : "none"}
        >
          <ConditionalRender condition={isLoading}>
            <H1_TextSmall margin="0 0 20px 0" color={theme.blue4} fontWeight={600}>
              {formatMessage(aiGenerateMessages.creatingScript)}
            </H1_TextSmall>
          </ConditionalRender>
          <ConditionalRender condition={shouldShowIncrementalLoader}>
            <StyledIncrementalLoader isFinished={topicToDraftStatus === fetchingStatus.succeeded} />
          </ConditionalRender>
          {/* Prompt */}
          <ConditionalRender condition={!isLoading}>
            <H1_FlexColumn width="100%">
              <H1_FlexRow position="relative" width="100%" margin="0 30px 0 0">
                {/* Wrapper for static and dynamic test */}
                <StyledTextarea
                  placeholder={currentPlaceholder}
                  value={promptValue}
                  onChange={onChangePromptText}
                />
                <ConditionalRender condition={sceneSelcetShowing}>
                  <StyledSelect
                    defaultValue={numberOfScenes}
                    onChange={(e) => setNumberOfScenes(e as number)}
                    value={numberOfScenes}
                    getPopupContainer={() =>
                      document.querySelector(`.main-ai-generate-container`) as HTMLElement
                    }
                  >
                    {numberOfScenesSelection.map((sceneNumber: number) => (
                      <Select.Option
                        key={`sceneNumber-${sceneNumber}`}
                        value={sceneNumber}
                        icon={<i className="fa-solid fa-clock" />}
                      >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {sceneNumber === 0
                          ? formatMessage(aiGenerateMessages.autoScenes)
                          : sceneNumber === 1
                          ? formatMessage(aiGenerateMessages.singleScene)
                          : formatMessage(aiGenerateMessages.numberOfScenes, {
                              number: sceneNumber
                            })}
                      </Select.Option>
                    ))}
                  </StyledSelect>
                </ConditionalRender>
              </H1_FlexRow>
              {/* Bottom row buttons */}
              <H1_FlexRow gap="10px" justify="center" padding="15px 0 0 0" wrap="wrap">
                <ConditionalRender condition={!isFinished}>
                  <CTAButton
                    color="primary"
                    onClick={() => (promptValue ? activatePrompt(true) : activateEmptyPrompt())}
                    icon={<i className="fa-sharp fa-solid fa-wand-magic-sparkles" />}
                  >
                    {formatMessage(aiGenerateMessages.magic)}
                  </CTAButton>
                </ConditionalRender>
              </H1_FlexRow>
              <ConditionalRender condition={isFinished && flags[FeatureFlag.videoWizardRevert]}>
                <H1_FlexColumn gap="10px" alignSelf="center" width="fit-content">
                  <H1_FlexRow gap="10px" align="center">
                    <OutlineButton
                      variant="bordered"
                      color="primary"
                      isDisabled={!promptValue}
                      onClick={onRevertWizard}
                      startContent={<i className="fa-solid fa-undo" />}
                    >
                      {formatMessage(aiGenerateMessages.undo)}
                    </OutlineButton>
                    <OutlineButton
                      variant="bordered"
                      color="primary"
                      isDisabled={!promptValue}
                      onClick={() => activatePrompt(false)}
                      startContent={<i className="fa-solid fa-rotate" />}
                    >
                      {formatMessage(aiGenerateMessages.regenerate)}
                    </OutlineButton>
                  </H1_FlexRow>
                  <CTAButton
                    color="primary"
                    onClick={onFinishWizard}
                    startContent={<i className="fa-sharp fa-solid fa-check" />}
                  >
                    {formatMessage(aiGenerateMessages.keep)}
                  </CTAButton>
                </H1_FlexColumn>
              </ConditionalRender>
            </H1_FlexColumn>
          </ConditionalRender>
        </WhiteFlexRow>
      </ConditionalRender>
    </ClampFlexColumn>
  );
};

export default AiGenerate;
