import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useAppSelector } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Progress } from "antd";
import React from "react";
import {
  H1_TextLargeHeadline,
  H1_TextMiddle,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { SynthesisMarkupLanguage } from "app/types";
import { getVariableColor } from "app/utils/helpers";
import WorkflowPlayer from "app/components/editor/workflows/WorkflowPlayer";

const Container = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: ${({ theme }) => theme.gray1};

  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
`;
const TextContainer = styled(H1_FlexRow)`
  border-radius: 10px;
  background: ${({ theme }) => theme.gray1};
  max-height: 710px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  flex-wrap: wrap;
  display: block;
`;

const VideoPreview = styled(H1_FlexRow)`
  //padding: 10px;
  min-height: 0; /* Fix bug in Safari */
  transition: 0.3s ease-in-out all;
  background-color: transparent;
  aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
  width: ${(props: { $aspectRatio: number }) => (props.$aspectRatio > 1 ? "100%" : "auto")};
  height: ${(props: { $aspectRatio: number }) => (props.$aspectRatio > 1 ? "auto" : "100%")};
  &&& mux-player {
    aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
    max-height: 452px;
    height: 100%;
  }
  & > * {
    flex: 1;
    height: 100%;
  }
`;

const InnerTextMiddle = styled(H1_TextMiddle)`
  flex: 0 0 auto;
  height: auto;
`;

const VariableInText = styled(H1_FlexColumn)<{ background: string }>`
  border-radius: 3px;
  background: ${({ background }) => background || ""};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
  min-height: 14px;
  display: inline-block;
`;

const BreakTextXs = styled(H1_TextXs)`
  min-height: 14px;
  word-break: break-word;
  display: inline;
`;
const ProgressWrapper = styled(H1_FlexRow)`
  position: absolute;
  width: 100%;
  bottom: 0px;
`;
const VideoRenderPreview = ({
  variableValues,
  videoId,
  totalSml
}: {
  variableValues: Record<string, string>;
  videoId: string;
  totalSml: SynthesisMarkupLanguage[];
}) => {
  const theme = useTheme();
  const selectedVideo = useAppSelector((state) => videosGlobalSelectors.selectById(state, videoId));
  const isVideoExist = !!selectedVideo;
  const isVideoReady = selectedVideo?.status === "ready" || selectedVideo?.status === "published";

  const aspectRatio =
    (Number(selectedVideo?.aspect_ratio?.width) || 0) /
      (Number(selectedVideo?.aspect_ratio?.height) || 1) || 16 / 9;

  const buildText = () => {
    const text = [];
    let index = 0;
    for (const item of totalSml) {
      if (item.type === "text" || item.pronounced) {
        text.push(
          <BreakTextXs margin="1px 0" whiteSpace="pre-wrap" key={index}>
            {item.text}
          </BreakTextXs>
        );
      } else if (item.type === "variable") {
        text.push(
          <VariableInText
            key={index}
            background={getVariableColor(item.variable_id as string)}
            width={"fit-content"}
            padding={"1px 6px"}
            margin={index === 0 ? "1px 2px 1px 0px" : "1px 2px"}
          >
            <H1_TextXs fontWeight={500}>{variableValues[item.variable_id as string]}</H1_TextXs>
          </VariableInText>
        );
      }
      index++;
    }
    return text;
  };
  return (
    <H1_FlexColumn gap={"10px"}>
      <Container
        // flex={"1 0 auto"}
        align={"center"}
        position={"relative"}
        padding={"10px"}
        width={"545px"}
      >
        <VideoPreview $aspectRatio={aspectRatio} align="center" justify="center">
          <ConditionalRender condition={isVideoExist && !isVideoReady}>
            <H1_FlexColumn position={"relative"} align={"center"} justify={"center"} gap={"5px"}>
              <H1_TextLargeHeadline>{selectedVideo?.progress || 0}%</H1_TextLargeHeadline>
              <H1_TextSmall color={theme.gray7}>Rendering your video...</H1_TextSmall>
              <ProgressWrapper>
                <Progress percent={selectedVideo?.progress} showInfo={false} />
              </ProgressWrapper>
            </H1_FlexColumn>
          </ConditionalRender>
          <ConditionalRender condition={!isVideoExist}>
            <InnerTextMiddle>Submit your workflow for generating a video</InnerTextMiddle>
          </ConditionalRender>
          <ConditionalRender condition={isVideoExist && isVideoReady}>
            <WorkflowPlayer videoId={videoId} />
          </ConditionalRender>
        </VideoPreview>
      </Container>
      <TextContainer align={"center"} padding={"32px 42px"} width={"545px"} overflow={"scroll"}>
        {buildText()}
      </TextContainer>
    </H1_FlexColumn>
  );
};

export default VideoRenderPreview;
