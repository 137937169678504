import React, { ReactNode } from "react";

import styled, { css } from "styled-components";
import { Tag } from "antd";

const TemplateTag = styled(Tag)<{ $backgroundColor?: string }>`
  ${({ $backgroundColor }) => css`
    background-color: ${$backgroundColor};
  `}
  color: ${(props) => props.theme.gray1};
  font-size: 8px;
  height: 14px;
  border-radius: 2px;
  line-height: 14px;
  margin: 0;
  border: none;
  min-width: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

export interface PreviewTagProps {
  background?: string;
  icon?: ReactNode;
  children?: ReactNode;
}

const PreviewTag = ({ background, icon, children }: PreviewTagProps) => {
  return (
    <TemplateTag icon={icon} $backgroundColor={background}>
      {children}
    </TemplateTag>
  );
};

export default PreviewTag;
