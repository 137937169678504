import { User } from "app/types";
import axios from "axios";
import Config from "app/config/Config";
import { isLocalStorageSupported } from "app/utils/helpers";

const serverBaseUrl = Config.serverUrl;
enum STORAGE_KEYS {
  sso_code_verifier = "sso_code_verifier",
  srt = "srt",
  sso_expires_in = "sso_expires_in",
  sso_refresh_expires_in = "sso_refresh_expires_in"
}
export interface CreateTokenArgs {
  codeVerifier?: string;
  code?: string;
  verification_code?: string;
  pending_authentication_token?: string;
  mfa_challenge_id?: string;
  password?: string;
  email?: string;
  type: "password" | "pkce" | "verification_code" | "mfa";
}
const localStorageSupported = isLocalStorageSupported();

export const getAuthUrl = async (
  provider: "google" | "linkedin" | "authkit"
): Promise<{ url: string; code_verifier: string; method: "google" | "linkedin" }> => {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      cookies: true,
      url_type: provider
    },
    withCredentials: true
  };

  const result = await axios.get(serverBaseUrl + "/v2/auth/url", axiosConfig);
  return result.data;
};
export const changePassword = async (
  newPassword: string,
  token: string
): Promise<{ provider: "auth0" | "workos" }> => {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json"
    }
    // params: {
    //   email
    // }
  };

  const result = await axios.put(
    serverBaseUrl + "/v2/auth/password",
    { token, new_password: newPassword },
    axiosConfig
  );
  return result.data;
};

export const resetPassword = async (email: string): Promise<{ provider: "auth0" | "workos" }> => {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json"
    }
    // params: {
    //   email
    // }
  };

  const result = await axios.post(
    serverBaseUrl + "/v2/auth/forgot-password",
    { email },
    axiosConfig
  );
  return result.data;
};

export const signup = async (
  email: string,
  password: string
): Promise<{ id: string; email_verified: boolean }> => {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      // expan/**/d: expand
    }
  };

  const result = await axios.post(
    serverBaseUrl + "/v2/auth/signup",
    {
      email,
      password
    },
    axiosConfig
  );
  return result.data;
};

export const refreshToken = async (
  refreshToken: string,
  expand?: boolean
): Promise<{ access_token: string; access_token_expires: number; user_info: User }> => {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      expand: expand
    }
  };

  const result = await axios.post(
    serverBaseUrl + "/v2/auth/token/refresh",
    {
      refresh_token: refreshToken
    },
    axiosConfig
  );
  return result.data;
};

export const createSso = async (): Promise<{ url: string; code_verifier: string }> => {
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const result = await axios.post(serverBaseUrl + "/v2/auth/sso", {}, axiosConfig);
  return result.data;
};
export const createToken = async ({
  codeVerifier,
  code,
  email,
  password,
  type,
  pending_authentication_token,
  mfa_challenge_id,
  verification_code
}: CreateTokenArgs): Promise<{
  access_token: string;
  refresh_token: string;
  access_token_expires: number;
  refresh_token_expires: number;
  user_info: User;
}> => {
  const body = {
    type,
    code_verifier: codeVerifier,
    code: code,
    email,
    password,
    pending_authentication_token,
    mfa_challenge_id,
    verification_code
  };

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true
  };
  const result = await axios.post(serverBaseUrl + "/v2/auth/token", body, axiosConfig);
  return result.data;
};

export const saveSsoCodeVerifierStorage = (codeVerifier: string) => {
  localStorageSupported && localStorage.setItem(STORAGE_KEYS.sso_code_verifier, codeVerifier);
};

export const getSsoCodeVerifierStorage = () => {
  if (localStorageSupported) {
    return localStorage.getItem(STORAGE_KEYS.sso_code_verifier);
  }
};

export const removeCodeVerifierStorage = () => {
  if (localStorageSupported) {
    return localStorage.removeItem(STORAGE_KEYS.sso_code_verifier);
  }
};

export const saveSsoRefreshTokenStorage = (token: string) => {
  if (localStorageSupported) {
    localStorage.setItem(STORAGE_KEYS.srt, token);
  }
};
export const getSsoRefreshTokenStorage = () => {
  if (localStorageSupported) {
    return localStorage.getItem(STORAGE_KEYS.srt);
  }
};
export const removeSsoRefreshTokenStorage = () => {
  if (localStorageSupported) {
    return localStorage.removeItem(STORAGE_KEYS.srt);
  }
};

export const saveSsoExpiresInStorage = (expiresIn: number) => {
  if (localStorageSupported) {
    localStorage.setItem(STORAGE_KEYS.sso_expires_in, String(expiresIn));
  }
};
export const getSsoExpiresInStorage = () => {
  if (localStorageSupported) {
    return Number(localStorage.getItem(STORAGE_KEYS.sso_expires_in));
  }
};
export const removeSsoExpiresInStorage = () => {
  if (localStorageSupported) {
    return localStorage.removeItem(STORAGE_KEYS.sso_expires_in);
  }
};

export const saveSsoRefreshExpiresInStorage = (expiresIn: number) => {
  if (localStorageSupported) {
    localStorage.setItem(STORAGE_KEYS.sso_refresh_expires_in, String(expiresIn));
  }
};
export const getSsoRefreshExpiresInStorage = () => {
  if (localStorageSupported) {
    return Number(localStorage.getItem(STORAGE_KEYS.sso_refresh_expires_in));
  }
};
export const removeSsoRefreshExpiresInStorage = () => {
  if (localStorageSupported) {
    return localStorage.removeItem(STORAGE_KEYS.sso_refresh_expires_in);
  }
};

export const cleanSessionStorage = () => {
  removeCodeVerifierStorage();
  removeSsoRefreshTokenStorage();
  removeSsoExpiresInStorage();
  removeSsoRefreshExpiresInStorage();
};
