import { Gender } from "app/types";

export enum VirtualTwinSteps {
  intro = "intro",
  instructions = "instructions",
  record = "record"
}

export interface Avatar {
  id: string;
  avatar_video_url: string;
  consent_video_url?: string;
  progress?: number;
  status: string;
}

export interface ConsentSession {
  text: string;
  expiration_time: string;
}

export interface AvatarRequest {
  avatar_video_url: string;
  consent_video_url?: string;
  consent_granted?: boolean;
  character_data?: {
    gender: Gender;
    title: string;
  };
  add_pong?: boolean;
  create_transparent?: boolean;
  session_flow?: string;
}
