import { useEffect, useRef, useState } from "react";
import { MediaTrimType } from "app/types/media";
import { PlayerRef } from "@remotion/player";

const DEFAULT_FPS = 30;
interface useVideoSyncEditorProps {
  video: HTMLVideoElement | null;
  remotionVideo: PlayerRef | null;
  durationInFrames?: number;
  initialTime?: number;
}
const useVideoSyncEditor = ({
  video,
  remotionVideo,
  durationInFrames,
  initialTime
}: useVideoSyncEditorProps) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [videoMax, setVideoMax] = useState<number>(0);
  const [videoRange, setVideoRange] = useState<[number, number]>([0, 0]);
  const [fillType, setFillType] = useState<MediaTrimType>(MediaTrimType.adaptSpeed);
  const [currentTime, setCurrentTime] = useState<number>(initialTime || 0);
  const requestRef = useRef<number>();

  useEffect(() => {
    const timeupdateHandler = () => {
      setCurrentTime(video?.currentTime ?? 0);
      if (video && videoRange && videoRange[0] > video?.currentTime) {
        video.currentTime = videoRange[0];
      }
      if (video && videoRange && videoRange[1] < video?.currentTime) {
        video.currentTime = videoRange[1];
      }
      if (video && videoRange && videoRange[1]) {
        const remainingTime = videoRange[1] - video.currentTime;
        if (remainingTime <= 0) {
          if (fillType === MediaTrimType.loop) {
            video.currentTime = videoRange[0] ?? 0;
            video.play();
            if (remotionVideo) {
              remotionVideo.play();
            }
          } else {
            video.pause();
            if (remotionVideo) {
              remotionVideo.pause();
            }
          }
        }
      }
    };

    const loadedmetadata = () => {
      if (video?.duration === Infinity) {
        video.currentTime = 1000;
        fixVideoDuration();
      } else {
        setVideoMax(video?.duration as number);
        setVideoRange([0, video?.duration as number]);
      }
    };

    const onEnded = () => {
      if (video) {
        video.pause();
        if (remotionVideo) {
          remotionVideo.pause();
          remotionVideo.seekTo(video.currentTime * DEFAULT_FPS);
        }
      }
      setPlaying(false);
    };

    const onPause = () => {
      if (video) {
        video.pause();
        if (remotionVideo) {
          remotionVideo.seekTo(Math.floor(video.currentTime * DEFAULT_FPS));
          remotionVideo.pause();
        }
      }
      setPlaying(false);
    };

    if (video && video.readyState >= 1 && !videoMax) {
      loadedmetadata();
    } else if (video) {
      video.addEventListener("timeupdate", timeupdateHandler);
      video.addEventListener("loadedmetadata", loadedmetadata);
      video.addEventListener("ended", onEnded);
      video.addEventListener("pause", onPause);
    }
    return () => {
      video?.removeEventListener("timeupdate", timeupdateHandler);
      video?.removeEventListener("loadedmetadata", loadedmetadata);
      video?.removeEventListener("ended", onEnded);
      video?.removeEventListener("pause", onPause);
    };
  }, [video, videoRange, fillType, durationInFrames]);

  const syncPlayback = () => {
    if (!video || !videoRange) return;

    const currentVideoTime = video.currentTime;
    setCurrentTime(currentVideoTime);

    if (currentVideoTime >= videoRange[1]) {
      video.pause();
      if (remotionVideo) {
        remotionVideo.pause();
      }
    } else {
      requestRef.current = requestAnimationFrame(syncPlayback);
    }
  };

  useEffect(() => {
    if (playing && video) {
      requestRef.current = requestAnimationFrame(syncPlayback);
    }
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [playing, video]);

  const fixVideoDuration = (retry = 0) => {
    // hack for loading duration infinity
    if (retry === 10) {
      console.warn("failed load duration");
      return;
    }
    if (video && video.duration === Infinity) {
      setTimeout(() => {
        fixVideoDuration(retry + 1);
      }, 1000);
    } else if (video) {
      setVideoMax(video?.duration as number);
      setVideoRange([0, video?.duration as number]);
      video.currentTime = 0;
    }
  };

  const onVideoRangeChange = ([min, max]: number[]) => {
    if (video) {
      video.currentTime = min;
      setVideoRange([min, max]);
      if (remotionVideo) {
        const videoTime = Math.floor(min * DEFAULT_FPS);
        remotionVideo.seekTo(videoTime);
      }
    }
  };

  const onPlay = () => {
    if (video) {
      video.play();
      if (remotionVideo) {
        if (video.currentTime >= videoRange[1]) {
          video.currentTime = videoRange[0];
          remotionVideo.seekTo(Math.floor(videoRange[0] * DEFAULT_FPS));
        }
        remotionVideo.play();
      }
      setPlaying(true);
    }
  };

  const onStop = () => {
    if (video) {
      video.pause();
      if (remotionVideo) {
        remotionVideo.pause();
      }
      video.currentTime = 0;
      setPlaying(false);
    }
  };
  const onPause = () => {
    if (video) {
      video.pause();
      if (remotionVideo) {
        remotionVideo.pause();
        remotionVideo.seekTo(Math.floor(video.currentTime * DEFAULT_FPS));
      }
    }
    setPlaying(false);
  };
  const onSelectFill = (value: MediaTrimType) => {
    setFillType(value);
  };

  const onTrackChange = (value: number) => {
    if (value > videoRange[1] || value < videoRange[0]) {
      return;
    }
    setCurrentTime(value);
    const timeInSec = value;
    if (video) {
      video.pause();
      video.currentTime = timeInSec;
    }
    const selectedFrame = Math.floor(value * DEFAULT_FPS);
    if (remotionVideo) {
      remotionVideo.pause();
      remotionVideo.seekTo(selectedFrame);
    }
  };

  return {
    playing,
    currentTime,
    videoRange,
    videoMax,
    fillType,
    onPause,
    onStop,
    onPlay,
    onSelectFill,
    onVideoRangeChange,
    onTrackChange
  };
};

export default useVideoSyncEditor;
