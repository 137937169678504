import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Col, Row, Typography } from "antd";
import useCopy from "app/hooks/useCopy";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useIntl } from "react-intl";
import { embedMessages } from "app/components/editor/sideDrawers/RenderPanel/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { Button, Checkbox } from "@nextui-org/react";

const EmbedType = styled.div`
  margin-bottom: 15px;
`;

const StyledTextarea = styled(Typography.Text)`
  color: ${({ theme }) => theme.gray10};
  background-color: ${({ theme }) => theme.gray3}1a;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.gray5}33;
  font-size: 85%;
  padding: 0.2em 0.4em 0.1em;
  height: 100%;
  margin: 0 0.2em;
  resize: none;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
`;

const H1_FlexColumnWrapper = styled(H1_FlexColumn)`
  max-width: inherit;
`;

export interface EmbedPanelProps {
  url: string;
  onEmbeddedUrlChange?: (str: string) => void;
  onAllowedIframeFeaturesChanged?: (str: string) => void;
  title?: string;
  showCopyButton?: boolean;
  videoId: string;
}

const EmbedPanel = ({
  url,
  title,
  showCopyButton,
  onEmbeddedUrlChange,
  onAllowedIframeFeaturesChanged,
  videoId
}: EmbedPanelProps) => {
  // Some clarifications: Picture-in-picture disabling is not supported in most browsers

  const { triggerCopy, CopyToolTip } = useCopy();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [embeddedUrl, setEmbeddedUrl] = useState<string>("");
  const [allowedIframeFeatures, setAllowedIframeFeatures] = useState<string>(
    "accelerometer; clipboard-write; encrypted-media; gyroscope;"
  );
  const [pip, setPip] = useState<boolean>(false);
  const [circle, setCircle] = useState<boolean>(false);
  const [autoplay, setAutoplay] = useState<boolean>(false);
  const [allowFullScreen, setAllowFullScreen] = useState<boolean>(true);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoaded(true);
    return () => {
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (loaded && embeddedUrl && onEmbeddedUrlChange) {
      onEmbeddedUrlChange(embeddedUrl);
    }
  }, [embeddedUrl]);

  useEffect(() => {
    if (loaded && onAllowedIframeFeaturesChanged) {
      onAllowedIframeFeaturesChanged(allowedIframeFeatures);
    }
  }, [allowedIframeFeatures]);

  /* round player should disable pip and gif */
  const urlWithQueries = useMemo(() => {
    const params = new URLSearchParams();
    if (circle) {
      params.append("circle", "true");
    }
    if (pip && !circle) {
      params.append("pip", "true");
    }

    if (autoplay) {
      params.append("autoplay", "true");
    }

    const paramsStr = params.toString();
    const localEmbeddedUrl = `${url}${paramsStr ? `&${paramsStr}` : ""}`;
    if (loaded) {
      setEmbeddedUrl(localEmbeddedUrl);
    }
    return localEmbeddedUrl;
  }, [url, circle, pip, autoplay]);

  useEffect(() => {
    const allowFullScreenString = " fullscreen;";
    if (allowFullScreen) {
      setAllowedIframeFeatures((featuresString) => (featuresString += allowFullScreenString));
    } else {
      setAllowedIframeFeatures((featuresString) =>
        featuresString.replace(allowFullScreenString, "")
      );
    }
  }, [allowFullScreen]);

  useEffect(() => {
    const pipString = " picture-in-picture;";
    if (pip) {
      setAllowedIframeFeatures((featuresString) => (featuresString += pipString));
    } else {
      setAllowedIframeFeatures((featuresString) => featuresString.replace(pipString, ""));
    }
  }, [pip]);

  const textAreaValue = `<iframe src="${urlWithQueries}" disablePictureInPicture="${!pip}" frameBorder="0" allow="${allowedIframeFeatures}" />`;

  const copyEmbeddedCode = () => {
    triggerCopy({
      event: undefined,
      copyContent: textAreaValue
    });
  };

  const copyEmbeddedCodeViaTextArea = (event: React.MouseEvent<Element, MouseEvent>) => {
    triggerCopy({ event });
    dispatch(analyticsEvents.embedVideo({ embeddedUrl, videoId }));
  };

  return (
    <H1_FlexColumnWrapper height="100%" width="100%">
      <ConditionalRender condition={!!title}>
        <H1_FlexRow flex="0 0 auto" margin="0 0 15px 0">
          <H1_TextMiddle>{title}</H1_TextMiddle>
        </H1_FlexRow>
      </ConditionalRender>
      <EmbedType>
        <Row>
          <Col span={12}>
            <Checkbox
              size="sm"
              isDisabled={circle}
              isSelected={pip}
              onValueChange={(checked) => setPip(checked)}
            >
              {formatMessage(embedMessages.pictureInPicture)}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              size="sm"
              isSelected={autoplay}
              onValueChange={(checked) => setAutoplay(checked)}
            >
              {formatMessage(embedMessages.autoplay)}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              size="sm"
              isSelected={allowFullScreen}
              onValueChange={(checked) => setAllowFullScreen(checked)}
            >
              {formatMessage(embedMessages.allowFullScreen)}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox size="sm" isSelected={circle} onValueChange={(checked) => setCircle(checked)}>
              {formatMessage(embedMessages.circleVideo)}
            </Checkbox>
          </Col>
        </Row>
      </EmbedType>
      <CopyToolTip>
        <H1_FlexColumn flex="1 1 auto">
          <StyledTextarea onClick={copyEmbeddedCodeViaTextArea}>{textAreaValue}</StyledTextarea>
        </H1_FlexColumn>
      </CopyToolTip>
      <ConditionalRender condition={showCopyButton}>
        <H1_FlexRow flex="0 0 auto">
          <Button color="primary" fullWidth onClick={copyEmbeddedCode}>
            {formatMessage(embedMessages.copyEmbeddedCode)}
          </Button>
        </H1_FlexRow>
      </ConditionalRender>
    </H1_FlexColumnWrapper>
  );
};

export default EmbedPanel;
