import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { useMemo, useState } from "react";
import { useAppSelector } from "app/hooks";
import { getAssetsByCardIdAndCategory } from "app/store/selectorsV2/scenes.selectors";
import { Draft, FetchStatus, LayoutAsset, LayoutElementType } from "app/types";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import SingleMediaSection from "app/components/editor/sideDrawers/SceneDrawer/SingleMediaSection";
import { isSceneAssetGenerationLoading } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import TextAsset from "app/components/editor/sideDrawers/SceneDrawer/TextAsset";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ThemeMode } from "app/utils/theme";

const StyledAccordion = styled(Accordion)`
  border-radius: 5px;
  border-color: ${({ theme }) => theme.gray3};
`;
const ChildFlexColumn = styled(H1_FlexColumn)`
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-04);
    padding-bottom: 10px;
  }
`;

interface CardSectionProps {
  cardId: string;
  name: string;
  onClickGenerateTitle: (key: string, textTitle: string) => void;
  onTextChange: (key: string, value: string, title: string) => void;
}

const CardSection = ({ cardId, name, onClickGenerateTitle, onTextChange }: CardSectionProps) => {
  const [openItem, setOpenItem] = useState<string | undefined>(undefined);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { scene, sceneId } = useSelectedScene();

  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const sceneAssetsGenerationStatus: Record<string, FetchStatus> = useAppSelector(
    (state) => state.scenes.genrateSceneAssetStatus
  );
  const visualCardAssets = useAppSelector((state) =>
    getAssetsByCardIdAndCategory(state, cardId, "visual")
  );
  const textCardAssets = useAppSelector((state) =>
    getAssetsByCardIdAndCategory(state, cardId, "text")
  );
  const hasTextAssets = textCardAssets.length > 0;
  const draftId = draft?.id as string;
  const visualForeground = useMemo(
    () =>
      visualCardAssets?.filter(
        (visualAsset) => visualAsset.element_type === LayoutElementType.foreground
      ),
    [visualCardAssets]
  );
  const visualBackground = useMemo(
    () =>
      visualCardAssets?.filter(
        (visualAsset) => visualAsset.element_type === LayoutElementType.background
      ),
    [visualCardAssets]
  );

  const firstVisualMedia = visualCardAssets[0];

  const handleToggle = (itemId: string) => {
    setOpenItem(openItem === itemId ? undefined : itemId);
  };

  return (
    <StyledAccordion
      showDivider={false}
      className="flex flex-col rounded-medium border-small mb-[20px] px-4"
      variant="bordered"
    >
      <AccordionItem
        key={cardId}
        aria-label={name}
        title={
          <H1_FlexRow gap="11px" align="center">
            {firstVisualMedia && (
              <SingleMediaSection
                width="57px"
                height="34px"
                isCardMode
                media={firstVisualMedia}
                mediaUrl={
                  (scene?.attributes?.visual &&
                    scene?.attributes?.visual[firstVisualMedia.key as string]?.preset_override
                      ?.media_url) ||
                  firstVisualMedia.preset?.media_url ||
                  ""
                }
                key={firstVisualMedia.key}
              />
            )}
            <H1_TextXs color={theme.gray7}>{name}</H1_TextXs>
          </H1_FlexRow>
        }
        classNames={{
          heading: `${
            openItem === cardId ? "border-solid border-b border-gray-3 mb-3 pb-1" : "m-0"
          }`
        }}
        onPress={() => handleToggle(cardId)}
      >
        <H1_FlexColumn flex="0 0 auto">
          {visualForeground?.map((currentMedia: LayoutAsset) => (
            <H1_FlexColumn key={currentMedia.key} align="flex-end">
              <ChildFlexColumn width="100%">
                <SingleMediaSection
                  loading={isSceneAssetGenerationLoading(
                    sceneAssetsGenerationStatus,
                    sceneId,
                    currentMedia.key
                  )}
                  media={currentMedia}
                  mediaUrl={
                    (scene?.attributes?.visual &&
                      scene?.attributes?.visual[currentMedia.key as string]?.preset_override
                        ?.media_url) ||
                    currentMedia.preset?.media_url ||
                    ""
                  }
                  key={currentMedia.key}
                />
              </ChildFlexColumn>
            </H1_FlexColumn>
          ))}
        </H1_FlexColumn>
        {/* Texts selection */}
        <ConditionalRender condition={hasTextAssets}>
          <H1_FlexColumn gap="13px" flex="0 0 auto">
            {textCardAssets.map((text: LayoutAsset) => (
              <TextAsset
                key={text.key}
                asset={text}
                onClickGenerateTitle={onClickGenerateTitle}
                onTextChange={onTextChange}
                draftId={draftId}
                sceneAssetsGenerationStatus={sceneAssetsGenerationStatus}
              />
            ))}
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!!visualBackground?.length}>
          <H1_FlexRow flex="1 0 auto" justify="space-between" align="baseline" padding="10px 0 0 0">
            <H1_TextSmall color={theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6}>
              {formatMessage(sceneDrawerMessages.background)}
            </H1_TextSmall>
          </H1_FlexRow>
          {visualBackground?.map((currentMedia: LayoutAsset) => (
            <H1_FlexColumn key={currentMedia.key} align="flex-end" flex="1 0 auto">
              <ChildFlexColumn padding="10px 0 0 0" width="100%" flex="1 0 auto">
                <SingleMediaSection
                  loading={isSceneAssetGenerationLoading(
                    sceneAssetsGenerationStatus,
                    sceneId,
                    currentMedia.key
                  )}
                  media={currentMedia}
                  mediaUrl={
                    (scene?.attributes?.visual &&
                      scene?.attributes?.visual[currentMedia.key as string]?.preset_override
                        ?.media_url) ||
                    currentMedia.preset?.media_url ||
                    ""
                  }
                  key={currentMedia.key}
                />
              </ChildFlexColumn>
            </H1_FlexColumn>
          ))}
        </ConditionalRender>
      </AccordionItem>
    </StyledAccordion>
  );
};

export default CardSection;
