import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { durationToMMSS } from "app/utils/helpers";

export type ReactMediaRecorderRenderProps = {
  error: string;
  muteAudio: () => void;
  unMuteAudio: () => void;
  startRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  startShareScreen: () => void;
  stopRecording: () => void;
  mediaBlobUrl: null | string;
  status: StatusMessages;
  isAudioMuted: boolean;
  previewStream: MediaStream | null;
  previewAudioStream: MediaStream | null;
  clearBlobUrl: () => void;
  recordTimer: string;
  counterDownTimer: string | null;
};

export type ReactMediaRecorderHookProps = {
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
  screen?: boolean;
  onStop?: (blobUrl: string, blob: Blob) => void;
  onStart?: () => void;
  blobPropertyBag?: BlobPropertyBag;
  mediaRecorderOptions?: MediaRecorderOptions | null;
  customMediaStream?: MediaStream | null;
  stopStreamsOnStop?: boolean;
  askPermissionOnMount?: boolean;
  countDownInSec?: number;
};
export type ReactMediaRecorderProps = ReactMediaRecorderHookProps & {
  render: (props: ReactMediaRecorderRenderProps) => ReactElement;
};

export enum StatusMessages {
  mediaAborted = "media_aborted",
  shareScreenActivated = "share_screen_activated",
  shareScreenDeactivatedBeforeRecord = "share_screen_deactivated_before_record",
  permissionDenied = "permission_denied",
  noSpecifiedMediaFound = "no_specified_media_found",
  mediaInUse = "media_in_use",
  invalidMediaConstraints = "invalid_media_constraints",
  noConstraints = "no_constraints",
  recorderError = "recorder_error",
  idle = "idle",
  acquiringMedia = "acquiring_media",
  delayedStart = "delayed_start",
  recording = "recording",
  stopping = "stopping",
  stopped = "stopped",
  paused = "paused"
}

export enum RecorderErrors {
  AbortError = "media_aborted",
  NotAllowedError = "permission_denied",
  NotFoundError = "no_specified_media_found",
  NotReadableError = "media_in_use",
  OverconstrainedError = "invalid_media_constraints",
  TypeError = "no_constraints",
  NONE = "",
  NO_RECORDER = "recorder_error",
  notSupportedBrowser = "your browser is not supported screen capturing"
}

export const useReactMediaRecorder = ({
  audio = true,
  video = false,
  onStop = () => null,
  onStart = () => null,
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions = null,
  customMediaStream = null,
  stopStreamsOnStop = true,
  askPermissionOnMount = false,
  countDownInSec
}: ReactMediaRecorderHookProps): ReactMediaRecorderRenderProps => {
  const [error, setError] = useState<keyof typeof RecorderErrors>("NONE");
  const [status, setStatus] = useState<StatusMessages>(StatusMessages.idle);
  const [totalTimeInSec, setTotalTimeInSec] = useState<number>(0);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);

  useEffect(() => {
    if (countDownInSec && countDownInSec - totalTimeInSec === 0) {
      stopRecording();
    }
  }, [countDownInSec, totalTimeInSec]);

  const recordTimer = useMemo(() => {
    return durationToMMSS(totalTimeInSec);
  }, [totalTimeInSec]);

  const counterDownTimer = useMemo(() => {
    if (!countDownInSec) {
      return null;
    }

    const time = countDownInSec - totalTimeInSec;
    return durationToMMSS(time);
  }, [countDownInSec, totalTimeInSec]);

  const getMediaStream = useCallback(async () => {
    setStatus(StatusMessages.acquiringMedia);
    const requiredMedia: MediaStreamConstraints = {
      audio: typeof audio === "boolean" ? !!audio : audio,
      video: typeof video === "boolean" ? !!video : video
    };
    try {
      if (customMediaStream) {
        mediaStream.current = customMediaStream;
      } else if (screen) {
        const stream = (await window.navigator.mediaDevices.getDisplayMedia({
          video: video || true
        })) as MediaStream;
        stream.getVideoTracks()[0].addEventListener("ended", () => {
          stopRecording();
        });
        if (audio) {
          const audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio
          });

          audioStream.getAudioTracks().forEach((audioTrack) => stream.addTrack(audioTrack));
        }
        mediaStream.current = stream;
      } else {
        const stream = await window.navigator.mediaDevices.getUserMedia(requiredMedia);
        mediaStream.current = stream;
      }
      setStatus(StatusMessages.idle);
    } catch (err: any) {
      setError(err.name);
      setStatus(StatusMessages.idle);
    }
  }, [audio, video, screen]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    let counter = totalTimeInSec;
    if (status === StatusMessages.recording) {
      intervalId = setInterval(() => {
        counter += 1;
        setTotalTimeInSec(counter);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [status]);

  useEffect(() => {
    try {
      if (!window.MediaRecorder) {
        throw new Error("notSupportedBrowser");
      }

      if (screen) {
        if (!window.navigator.mediaDevices.getDisplayMedia) {
          throw new Error("notSupportedBrowser");
        }
      }

      const checkConstraints = (mediaType: MediaTrackConstraints) => {
        const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
        const unSupportedConstraints = Object.keys(mediaType).filter(
          (constraint) => !(supportedMediaConstraints as { [key: string]: any })[constraint]
        );

        if (unSupportedConstraints.length > 0) {
          console.error(
            `The constraints ${unSupportedConstraints.join(
              ","
            )} doesn't support on this browser. Please check your ReactMediaRecorder component.`
          );
        }
      };

      if (typeof audio === "object") {
        checkConstraints(audio);
      }
      if (typeof video === "object") {
        checkConstraints(video);
      }

      if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
        if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
          console.error(
            `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
          );
        }
      }

      if (!mediaStream.current && askPermissionOnMount) {
        getMediaStream();
      }
    } catch (err: any) {
      setError(err?.message);
    }

    return () => {
      if (mediaStream.current) {
        const tracks = mediaStream.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [audio, screen, video, getMediaStream, mediaRecorderOptions, askPermissionOnMount]);

  // Media Recorder Handlers
  const startShareScreen = async () => {
    setError("NONE");
    setStatus(StatusMessages.idle);
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some((track) => track.readyState === "ended");
      if (isStreamEnded) {
        await getMediaStream();
      }

      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStream.current.active) {
        return;
      }
      setStatus(StatusMessages.shareScreenActivated);
    }
  };
  const startRecording = async () => {
    setError("NONE");
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStream.current.active) {
        return;
      }
      mediaRecorder.current = new MediaRecorder(
        mediaStream.current,
        mediaRecorderOptions || undefined
      );
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onstart = onRecordingStart;
      mediaRecorder.current.onerror = () => {
        setError("NO_RECORDER");
        setStatus(StatusMessages.idle);
      };
      mediaRecorder.current.start();
      setStatus(StatusMessages.recording);
    }
  };

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStart = () => {
    onStart();
  };

  const onRecordingStop = () => {
    const [chunk] = mediaChunks.current;
    const blobProperty: BlobPropertyBag = {
      type: chunk.type,
      ...(blobPropertyBag || (video ? { type: "video/mp4" } : { type: "audio/wav" }))
    };
    const blob = new Blob(mediaChunks.current, blobProperty);
    const url = URL.createObjectURL(blob);
    setStatus(StatusMessages.stopped);
    setMediaBlobUrl(url);
    onStop(url, blob);
  };

  const muteAudio = (mute: boolean) => {
    setIsAudioMuted(mute);
    if (mediaStream.current) {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      mediaStream.current.getAudioTracks().forEach((audioTrack) => (audioTrack.enabled = !mute));
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      setStatus(StatusMessages.paused);
      mediaRecorder.current.pause();
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      setStatus(StatusMessages.recording);
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== "inactive") {
        setStatus(StatusMessages.stopping);
        mediaRecorder.current.stop();

        mediaChunks.current = [];
      }
    } else {
      // media recorder not running
      setStatus(StatusMessages.shareScreenDeactivatedBeforeRecord);
    }
    if (stopStreamsOnStop && mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
    }
  };

  return {
    error: RecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startShareScreen,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    isAudioMuted,
    previewStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getVideoTracks())
      : null,
    previewAudioStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getAudioTracks())
      : null,
    clearBlobUrl: () => {
      setTotalTimeInSec(0);
      if (mediaBlobUrl) {
        URL.revokeObjectURL(mediaBlobUrl);
      }
      setMediaBlobUrl(null);
      setStatus(StatusMessages.idle);
      setError("NONE");
    },
    recordTimer,
    counterDownTimer
  };
};

export const ReactMediaRecorder = (props: ReactMediaRecorderProps) =>
  props.render(useReactMediaRecorder(props));
