import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppSelector } from "app/hooks";
import { Video, VideoStatusEnum } from "app/types";
import { Progress, Tooltip } from "antd";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { getVideoByDataStoreContentId } from "app/store/selectorsV2/videos.selectors";

const CircleBorder = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray1};
  border-radius: 12px;
  border: 1.5px solid ${({ theme }) => theme.gray5};
  flex-shrink: 0;
`;

const StyledProgress = styled(Progress)`
  width: 6.25rem;
  .ant-progress-inner {
    border: 1px solid ${({ theme }) => theme.blue2};
  }
`;

interface WorkflowTableStatusInterface {
  dataStoreContentId: string;
}

const WorkflowTableStatus = ({ dataStoreContentId }: WorkflowTableStatusInterface) => {
  const theme = useTheme();
  const importDataErrorsResult = useAppSelector((state) => state.dataStore.importDataErrorsResult);
  const currentVideo: Video | undefined = useAppSelector((state) =>
    getVideoByDataStoreContentId(state, dataStoreContentId)
  );

  const isStatusFailed = importDataErrorsResult[dataStoreContentId] === true;
  const progress = currentVideo?.progress;
  const isProgressDisplayed = !!(progress && progress > 0 && progress < 100);

  const statusData = useMemo(() => {
    if (isStatusFailed) {
      return { text: "Failed", color: "red" };
    }
    if (!currentVideo) {
      return { text: "New", color: theme.gray11 };
    }
    switch (currentVideo.status) {
      case VideoStatusEnum.NotStarted:
        return { text: "Not started", color: theme.gray11 };
      case VideoStatusEnum.Published:
      case VideoStatusEnum.Ready:
        return { text: "Ready", color: theme.green4 };
      case VideoStatusEnum.Failed:
        return { text: "Failed", color: "red" };
      case VideoStatusEnum.Processing:
        return { text: "Processing", color: theme.gray11 };
      default:
        return { text: "New", color: theme.gray11 };
    }
  }, [currentVideo?.status, isStatusFailed]);

  return (
    <H1_FlexColumn>
      <CircleBorder width="97px" height="24px" padding="0 4p 0 8px" justify="center" align="center">
        <H1_TextSmall fontFamily="inter" color={statusData.color}>
          {statusData.text}
        </H1_TextSmall>
      </CircleBorder>
      <ConditionalRender condition={isProgressDisplayed}>
        <Tooltip title={`${progress}%`}>
          <StyledProgress
            strokeColor={theme.blue2}
            strokeWidth={8}
            trailColor={theme.gray1}
            strokeLinecap="square"
            percent={progress}
            showInfo={false}
            width={75}
          />
        </Tooltip>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default WorkflowTableStatus;
