/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const StyledFlexColumn = styled(H1_FlexColumn)<{ x: number; y: number; color: string }>`
  z-index: 987654;
  position: absolute;
  left: 0;
  top: 0;
  transform: ${({ x, y }) => `translateX(${x}px) translateY(${y}px)`};
  span {
    padding: 0 5px;
    background-color: ${({ color }) => color};
    color: black;
  }
`;

interface CursorProps {
  color: string;
  x: number;
  y: number;
  title: string;
}

const Cursor = ({ color, x, y, title }: CursorProps) => {
  return (
    <StyledFlexColumn position="absolute" x={x} y={y} color={color}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
          fill={color}
        />
      </svg>
      <H1_TextXs color={color}>{title}</H1_TextXs>
    </StyledFlexColumn>
  );
};

export default Cursor;
