import ConditionalRender from "app/components/common/ConditionalRender";
import { FeatureFlag } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fetchingStatus } from "app/utils/helpers";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { paymentsActions } from "app/store/slices/payments.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import usePaymentFlow from "app/pages/pricing/usePaymentFlow";
import useModal, { ModalName } from "app/hooks/useModal";
import { paymentModalMessages } from "app/pages/pricing/messages";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import styled, { css, useTheme } from "styled-components";
import { Spin } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { getSpecificSubscriptionPlan } from "app/store/selectorsV2/payments.selectors";
import { Button } from "@nextui-org/react";

const GREEN_COLOR = "#3fcb80";
const COUPON_FAILED_TIME = 3000;
const COUPON_SUCCESS_TIME = 3000;

const RightText = styled(H1_TextXs)`
  align-self: flex-end;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const BorderedFlexRow = styled(H1_FlexRow)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.gray5};
  border-radius: 2px;
  box-sizing: border-box;
  ${({ error }: { error?: boolean }) =>
    error &&
    css`
      border-color: ${(props) => props.theme.pink4};
    `};
`;

const CouponTextFlexRow = styled(H1_FlexRow)`
  transition: height 0.3s linear;
  ${({ $open }: { $open: boolean }) => css`
    height: ${$open ? "22px" : 0};
    margin-top: ${$open ? "10px" : 0};
  `}
`;

const NoBorderInput = styled.input`
  &::placeholder,
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.gray5};
  }
  outline: none;
  width: 100%;
  background-color: ${(props) => props.theme.gray1};
  border: 1px solid transparent;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
`;

const CouponLinkButton = styled(Button)`
  &&& {
    background-color: transparent;
    color: ${GREEN_COLOR};
    &:hover {
      text-decoration: underline;
    }
    span {
      color: ${GREEN_COLOR};
    }
  }
`;

const GreenSpin = styled(Spin)`
  color: ${GREEN_COLOR};
`;

const GreenCheckOutlined = styled(CheckOutlined)`
  color: ${GREEN_COLOR};
`;

const RedCloseOutlined = styled(CloseOutlined)`
  color: ${(props) => props.theme.pink4};
`;

const PaymentModalV2CouponCode = () => {
  const [showCouponInput, setShowCouponInput] = useState<boolean>(false);
  const flags = useFlags();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { editorModalOpen } = useModal();
  const { planToUpgrade, onCouponChange } = usePaymentFlow({
    paymentPeriod: editorModalOpen?.context?.paymentPeriod,
    targetPlan: editorModalOpen?.context?.targetPlan,
    active: editorModalOpen?.open === ModalName.paymentModalV2,
    source: editorModalOpen?.context?.source,
    upgradeText: editorModalOpen?.context?.upgradeText
  });
  const { coupon } = useAppSelector((state) => state.payments.pricingModalProperties);
  const verifyCouponStatus = useAppSelector((state) => state.payments.verifyCouponStatus);
  const verifyCouponResults = useAppSelector((state) => state.payments.verifyCouponResults);
  const verifyCouponStatusLoading = verifyCouponStatus === fetchingStatus.loading;
  const verifyCouponStatusIdle = verifyCouponStatus === fetchingStatus.idle;
  const verifyCouponStatusSuccess = verifyCouponStatus === fetchingStatus.succeeded;
  const verifyCouponStatusFailed = verifyCouponStatus === fetchingStatus.failed;
  const isCouponApplied = verifyCouponResults?.isCouponValid && verifyCouponStatusSuccess;
  const isInvalidCoupon = !verifyCouponResults?.isCouponValid && verifyCouponStatusSuccess;
  const isCouponValid = verifyCouponResults?.isCouponValid;
  const { selectedPlan } = useAppSelector((state) => state.payments.pricingModalProperties);
  const targetSelectedPlan = useAppSelector((state) =>
    getSpecificSubscriptionPlan(state, selectedPlan)
  );

  useEffect(() => {
    if (flags[FeatureFlag.responsiveBannerCode]) {
      // applyCoupon(flags[FeatureFlag.responsiveBannerCode]);
      setShowCouponInput(true);
      // onCouponChange(flags[FeatureFlag.responsiveBannerCode]);
    }
  }, []);

  useEffect(() => {
    const couponError = () => {
      setTimeout(() => {
        dispatch(paymentsActions.setVerifyCouponStatusToIdle());
        dispatch(paymentsActions.cleanCouponCode());
      }, COUPON_FAILED_TIME);
    };

    if (verifyCouponStatusSuccess) {
      if (!isCouponValid) {
        couponError();
      } else {
        setTimeout(() => {
          dispatch(paymentsActions.setVerifyCouponStatusToIdle());
        }, COUPON_SUCCESS_TIME);
      }
    }
    if (verifyCouponStatusFailed) {
      couponError();
    }
  }, [verifyCouponStatus, isCouponValid, verifyCouponStatusSuccess, verifyCouponStatusFailed]);

  const applyCoupon = (fixedCoupon?: string) => {
    dispatch(
      paymentsActions.verifyCouponRequest({
        coupon: fixedCoupon ?? (coupon as string),
        planId: targetSelectedPlan?.id as string
      })
    );
    dispatch(
      analyticsEvents.userAppliedCoupon({
        coupon: fixedCoupon ?? (coupon as string),
        plan: planToUpgrade
      })
    );
  };

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    onCouponChange(inputValue);
  };

  const getCouponOnClick = () => {
    setShowCouponInput(true);
  };

  return (
    <>
      <ConditionalRender
        condition={!showCouponInput && !isCouponApplied && flags[FeatureFlag.showCoupon]}
      >
        <RightText onClick={getCouponOnClick} color={GREEN_COLOR}>
          {formatMessage(paymentModalMessages.gotCouponCode)}
        </RightText>
      </ConditionalRender>
      <ConditionalRender condition={showCouponInput}>
        <H1_FlexColumn width="100%" flex="1 0 auto" align="flex-start">
          <BorderedFlexRow
            align="center"
            gap="20px"
            width="100%"
            position="relative"
            height="31px"
            padding="0 5px 0 0"
          >
            <NoBorderInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder={formatMessage(paymentModalMessages.couponCodePlaceholder)}
              onChange={handleCouponChange}
              disabled={isCouponApplied || verifyCouponStatusLoading || verifyCouponStatusSuccess}
              value={coupon}
            />
            <ConditionalRender condition={verifyCouponStatusLoading}>
              <GreenSpin indicator={<LoadingOutlined spin />} />
            </ConditionalRender>
            <ConditionalRender condition={isCouponValid}>
              <GreenCheckOutlined />
            </ConditionalRender>
            <ConditionalRender condition={isInvalidCoupon}>
              <RedCloseOutlined />
            </ConditionalRender>
            <ConditionalRender condition={verifyCouponStatusIdle && !!coupon && !isCouponValid}>
              <CouponLinkButton size="sm" variant="light" onClick={() => applyCoupon()}>
                {formatMessage(paymentModalMessages.applyButton)}
              </CouponLinkButton>
            </ConditionalRender>
          </BorderedFlexRow>
          <CouponTextFlexRow overflow="hidden" $open={!!isCouponApplied}>
            <H1_TextXs color={GREEN_COLOR}>
              {formatMessage(paymentModalMessages.couponApplied)}
            </H1_TextXs>
          </CouponTextFlexRow>
          <CouponTextFlexRow overflow="hidden" $open={isInvalidCoupon}>
            <H1_TextXs color={theme.pink4}>
              {formatMessage(paymentModalMessages.couponInvalid)}
            </H1_TextXs>
          </CouponTextFlexRow>
        </H1_FlexColumn>
      </ConditionalRender>
    </>
  );
};

export default PaymentModalV2CouponCode;
