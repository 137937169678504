import { Recipe } from "app/types";
import { useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useCallback, useState } from "react";
import { H1_TextMiddle, H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import { videoPreviewModalMessages } from "app/pages/EditDraftPage/messages";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import VideoPlayerWithControls from "app/pages/Templates/VideoPlayerWithControls";
import useVideo from "app/hooks/useVideo";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 46px;
  }
`;

interface VideoPreviewModalProps {
  onClose: () => void;
  onFinish: () => void;
  visible: boolean;
}
const VideoPreviewModal = ({ onClose, visible, onFinish }: VideoPreviewModalProps) => {
  const [videoNode, setVideoNode] = useState<HTMLVideoElement | undefined>(undefined);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const setRef = useCallback((node: HTMLVideoElement) => {
    setVideoNode(node);
  }, []);
  const {
    reset,
    videoLoaded,
    currentTime,
    duration,
    isPlaying,
    aspectRatio,
    isMuted,
    handleMuteUnmute,
    handlePlayPause
  } = useVideo({
    videoNode,
    visible,
    isDefaultMuted: false,
    isAutoplay: false
  });
  const createdDraft = useAppSelector(draftsSelectors.currentDraft);
  const recipe: Recipe = useAppSelector((state) =>
    recipesSelectors.getRecipeByRecipeId(state, createdDraft?.recipe_id as string)
  ) as Recipe;

  if (!recipe) {
    return null;
  }
  return (
    <Modal
      width="589px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "580px" }}
      onCancel={onClose}
      closeIcon={<CloseModalIcon />}
      destroyOnClose
    >
      <H1_FlexColumn gap="18px">
        <H1_FlexColumn align="center">
          <H1_TextMidHeadline lineHeight="51px" textAlign="center">
            {formatMessage(videoPreviewModalMessages.mainText)}
          </H1_TextMidHeadline>
          <H1_TextMiddle whiteSpace="normal" color={theme.gray7} textAlign="center">
            {formatMessage(videoPreviewModalMessages.secondaryText)}
          </H1_TextMiddle>
        </H1_FlexColumn>
        <H1_FlexColumn flex="0 0 334px">
          <VideoPlayerWithControls
            width="100%"
            height="273px"
            maxHeight="273px"
            ref={setRef}
            src={recipe?.video_full as string}
            reset={reset}
            currentTime={currentTime}
            duration={duration}
            isPlaying={isPlaying}
            isMuted={isMuted}
            handleMuteUnmute={handleMuteUnmute}
            handlePlayPause={handlePlayPause}
            videoLoaded={videoLoaded}
            aspectRatio={aspectRatio}
            image={recipe?.image}
            videoNode={videoNode as HTMLVideoElement}
          />
        </H1_FlexColumn>
        <H1_FlexRow width="100%" justify="flex-end" gap="18px">
          <Button color="primary" onClick={onFinish}>
            {formatMessage(videoPreviewModalMessages.okButton)}
          </Button>
        </H1_FlexRow>
      </H1_FlexColumn>
    </Modal>
  );
};

export default VideoPreviewModal;
