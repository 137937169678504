import { createContext, Dispatch, SetStateAction } from "react";

interface SettingContext {
  selectedCamera?: string;
  setSelectedCamera: Dispatch<SetStateAction<string | undefined>>;
  selectedMicrophone?: string;
  setSelectedMicrophone: Dispatch<SetStateAction<string | undefined>>;
  faceOutline: boolean;
  setfaceOutline: Dispatch<SetStateAction<boolean>>;
}

const RecordStepSettingsContext = createContext<SettingContext | undefined>(undefined);

export default RecordStepSettingsContext;
