import { Tab, Tabs } from "@nextui-org/tabs";
import { useIntl } from "react-intl";
import { briefsPageMessages } from "app/pages/Briefs/messages";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { briefsActions } from "app/store/slices/briefs.slice";
import { fetchingStatus } from "app/utils/helpers";
import { BriefUserRelation } from "app/types/brief";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import BriefCardBody from "app/pages/Briefs/BriedCardBody";
import ConditionalRender from "app/components/common/ConditionalRender";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import useErrors from "app/hooks/useErrors";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import BriefsModal from "app/pages/Briefs/BriefsModal";
import { businessMessages } from "app/pages/Header/messages";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray1};
`;
const StyledTabs = styled(Tabs)`
  padding-bottom: 33px;
`;

const SendBriefButton = styled(Button)`
  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray8 : theme.gray3)};
  i {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray8 : theme.gray3)};
  }
  position: absolute;
  top: 0;
  right: 80px;
`;

const BriefsPage = () => {
  const [isOpenSendBriefModal, setIsOpenSendBriefModal] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { notifyError } = useErrors();
  const [selectedTab, setSelectedTab] = useState("incomingBriefsTab");
  const getBriefsStatus = useAppSelector((state) => state.briefs.getBriefsStatus);
  const currentBriefs = useAppSelector((state) => state.briefs.currentBriefs);
  const archivedBriefs = currentBriefs.filter((brief) => brief.archived);
  const incomingBriefs = currentBriefs.filter(
    (brief) => brief.user_relation == BriefUserRelation.recipient
  );
  const sentBriefs = currentBriefs.filter(
    (brief) => brief.user_relation === BriefUserRelation.creator
  );
  useEffect(() => {
    if (getBriefsStatus == fetchingStatus.failed) {
      notifyError({ general: buildGeneralError("Briefs not found", intl) });
    }
  }, [getBriefsStatus]);
  useEffect(() => {
    dispatch(briefsActions.getBriefsRequest());
  }, []);

  useEffect(() => {
    const openSentTab = new URLSearchParams(search).get("tab");
    if (openSentTab === "sentBriefs") {
      setSelectedTab("sentBriefsTab");
    }
  }, []);

  const onOpenSendBriefModal = () => {
    setIsOpenSendBriefModal(true);
  };
  const onCloseBriefModal = () => {
    setIsOpenSendBriefModal(false);
  };

  return (
    <BackgroundFlexColumn position="relative" padding="0 50px" flex="1">
      <BriefsModal onFinish={onCloseBriefModal} visible={isOpenSendBriefModal} />
      <SendBriefButton
        color="default"
        endContent={<i className="fa-regular fa-paper-plane-top" />}
        onClick={onOpenSendBriefModal}
      >
        {intl.formatMessage(businessMessages.sendBriefButton)}
      </SendBriefButton>
      <StyledTabs
        classNames={{ panel: "p-0" }}
        aria-label="Options"
        selectedKey={selectedTab}
        onSelectionChange={(key) => setSelectedTab(key as string)}
      >
        <Tab
          key="incomingBriefsTab"
          title={intl.formatMessage(briefsPageMessages.incomingBriefsTab)}
        >
          <ConditionalRender condition={incomingBriefs.length > 0}>
            <BriefCardBody briefs={incomingBriefs} />
          </ConditionalRender>
        </Tab>
        <Tab key="sentBriefsTab" title={intl.formatMessage(briefsPageMessages.sentBriefsTab)}>
          <ConditionalRender condition={sentBriefs.length > 0}>
            <BriefCardBody briefs={sentBriefs} />
          </ConditionalRender>
        </Tab>
        <Tab
          key="archivedBriefsTab"
          title={intl.formatMessage(briefsPageMessages.archivedBriefsTab)}
        >
          <ConditionalRender condition={archivedBriefs.length > 0}>
            <BriefCardBody briefs={archivedBriefs} />
          </ConditionalRender>
        </Tab>
      </StyledTabs>
    </BackgroundFlexColumn>
  );
};

export default BriefsPage;
