import { useAppDispatch, useAppSelector } from "app/hooks";
import { DraftVariable, SynthesisMarkupLanguage } from "app/types";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { useEffect, useMemo, useState } from "react";
import { draftsActions } from "app/store/slices/drafts.slice";
import { videosActions } from "app/store/slices/videos.slice";

const useWorkflow = () => {
  const [variableValues, setVariableValues] = useState<Record<string, string>>({});
  const dispatch = useAppDispatch();
  const draftId = useAppSelector((state) => state.drafts.currentDraft.id);
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);
  const draftVariables = useAppSelector((state) => state.drafts.currentDraft.variables);
  const workflowId = useAppSelector((state) => state.drafts.currentDraft.workflow_id);
  const totalSml: SynthesisMarkupLanguage[] = useAppSelector(
    scenesSelectors.getScenesTotalSmlWorkflow
  );

  const variables = useMemo(() => {
    const sceneVariables = totalSml
      .filter((sml) => sml.type === "variable")
      .reduce<Record<string, boolean>>((acc, cur) => {
        acc[cur.variable_id as string] = true;
        return acc;
      }, {});
    return draftVariables?.filter((variable) => sceneVariables[variable.id]);
  }, [draftVariables, totalSml]);

  useEffect(() => {
    if (variables) {
      const res = variables.reduce((acc: Record<string, string>, cur: DraftVariable) => {
        if (cur.default_value) {
          acc[cur.id] = cur.default_value;
        }

        return acc;
      }, {});
      setVariableValues(res as Record<string, string>);
    }
  }, [variables]);

  const onChangeVariableValue = (variableId: string, value: string) => {
    setVariableValues({ ...variableValues, [variableId]: value });
  };

  const createWorkflowVideo = (dataStoreId?: string, isCsv = false) => {
    if (workflowLiveMode) {
      const variables = isCsv
        ? undefined
        : Object.entries(variableValues).map(([variableId, value]) => {
            return { variable_id: variableId, value };
          });
      dispatch(
        draftsActions.createWorkflowSubmissionRequest({
          workflowId: workflowId as string,
          variables,
          action: "create_video",
          datastore_content_id: dataStoreId
        })
      );
    } else {
      dispatch(
        videosActions.createVideoRequest({
          datastore_content_id: dataStoreId,
          draftId: draftId as string,
          skip402ErrorHandling: true,
          variables: isCsv
            ? undefined
            : Object.entries(variableValues).map(([variableId, value]) => ({
                variable_id: variableId,
                value
              }))
        })
      );
    }
  };

  return {
    onChangeVariableValue,
    createWorkflowVideo,
    variableValues,
    variables
  };
};

export default useWorkflow;
