import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLayouts } from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";

const prefix = "[Recipes]";
const getLayoutsRequest = createAsyncThunk<any, any>(
  `${prefix} getLayoutsRequest`,
  async (templateId: string) => {
    return getLayouts(templateId);
  },
  thunkOptions
);

export default {
  getLayoutsRequest
};
