import { useAppDispatch, useAppSelector } from "app/hooks";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { Switch } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import { useEffect, useState } from "react";
import usePermissions from "app/hooks/usePermissions";
import { ThemeMode } from "app/utils/theme";

const StyledSwitch = styled(Switch)`
  width: 56px;
  height: 32px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.blue4 : theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5};
  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 28px - 2px);
  }
  .ant-switch-handle::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f121";
    font-size: 14px;
    line-height: 28px;
    border-radius: 50%;
    font-weight: 900;
    ${({ checked }) => (checked ? "background-color:white;" : "background-color: white;")}
  }
  .ant-switch-handle {
    margin-bottom: 5px;
    width: 28px;
    max-width: 28px;
    height: 28px;
    color: ${({ checked, theme }) =>
      checked ? theme.blue4 : theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5};
  }
  ${({ checked }) => (checked ? "background-color: #5A5AFF;" : "")}
`;
const DevModeSwitch = () => {
  const [value, setValue] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const developerMode = useAppSelector((state) => state.uiV2.developerMode);
  const { isAllowCreateApiCurl } = usePermissions();

  useEffect(() => {
    setValue(developerMode);
  }, [developerMode]);

  const onChange = () => {
    setValue(!developerMode);
    dispatch(uiActionsV2.setDeveloperMode(!developerMode));
  };

  if (!isAllowCreateApiCurl) {
    return null;
  }
  return (
    <H1_FlexRow align={"center"} gap={"5px"}>
      <StyledSwitch defaultChecked={value} checked={value} onChange={onChange} />
    </H1_FlexRow>
  );
};

export default DevModeSwitch;
