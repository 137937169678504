import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { profileMessages } from "app/pages/profile/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React from "react";
import { useIntl } from "react-intl";
import { User } from "app/types";
import { useAuth } from "app/auth/useAuth";
import styled, { useTheme } from "styled-components";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { Divider } from "antd";
import AvatarInitials from "app/components/common/AvatarInitials";
import { ThemeMode } from "app/utils/theme";

const StyledFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
  }
`;

const WhiteFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
  border-radius: 4px;
`;

const GeneralInfoCard = () => {
  const intl = useIntl();
  const theme = useTheme();
  const auth = useAuth();
  const { user } = auth;
  const { email, nickname, name, picture } = user as User;

  return (
    <WhiteFlexColumn padding="20px 30px 30px" gap="5px" width="100%" flex="0 0 auto">
      <H1_TextMiddle color={theme.gray8}>{intl.formatMessage(profileMessages.title)}</H1_TextMiddle>
      <Divider />
      <StyledFlexRow gap="28px" align="center" padding="10px 0">
        <AvatarInitials
          text={nickname || ""}
          src={picture}
          size="lg"
          className="w-25 h-25 text-large max-w-[90px]"
        />
        <H1_TextMiddle color={theme.gray8}>{name || nickname}</H1_TextMiddle>
      </StyledFlexRow>
      <Divider />
      <StyledFlexRow padding="3px 0 17px 0">
        <H1_FlexColumn gap="5px">
          <H1_TextSmall color={theme.gray9} fontWeight={600}>
            {intl.formatMessage(profileMessages.nameTitle)}
          </H1_TextSmall>
          <H1_TextSmall color={theme.gray9}>{name}</H1_TextSmall>
        </H1_FlexColumn>
      </StyledFlexRow>
      <StyledFlexRow padding="0 0 17px 0">
        <H1_FlexColumn gap="5px">
          <H1_TextSmall color={theme.gray9} fontWeight={600}>
            {intl.formatMessage(profileMessages.email)}
          </H1_TextSmall>
          <H1_TextSmall color={theme.gray9}>{email}</H1_TextSmall>
        </H1_FlexColumn>
      </StyledFlexRow>
      <StyledFlexRow>
        <H1_FlexColumn gap="5px">
          <H1_TextSmall color={theme.gray9} fontWeight={600}>
            {intl.formatMessage(profileMessages.nicknameTitle)}
          </H1_TextSmall>
          <H1_TextSmall color={theme.gray9}>{nickname}</H1_TextSmall>
        </H1_FlexColumn>
      </StyledFlexRow>
    </WhiteFlexColumn>
  );
};

export default GeneralInfoCard;
