import { GridChildComponentProps, VariableSizeGrid } from "react-window";
import React, { useCallback, useMemo } from "react";
import { CharacterType } from "app/types/character";
import { Recipe } from "app/types";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import SingleTemplate from "app/components/editor/sideDrawers/TemplatesDrawer/SingleTemplate";

interface TeamplteDrawerElementProps {
  height: number;
  onSelectedRecipeId?: (recipeId: string) => void;
  selectedSectionId?: string;
  currentDraftRecipeId?: string;
}
const TemplateDrawerElement = ({
  height,
  onSelectedRecipeId,
  selectedSectionId,
  currentDraftRecipeId
}: TeamplteDrawerElementProps) => {
  const currentTemplate = useAppSelector((state) => state.templates.currentTemplate);
  const selectedRecipeListItems = useAppSelector((state) =>
    recipesSelectors.getAllRecipesItemsByRecipeListId(state, selectedSectionId)
  );

  const rowCount = useMemo(
    () => Math.ceil(selectedRecipeListItems.length / 2),
    [selectedRecipeListItems.length]
  );

  const renderRow = useCallback(
    ({ rowIndex, columnIndex, style }: Partial<GridChildComponentProps>) => {
      if (rowIndex === undefined || columnIndex === undefined) {
        return null;
      }
      const templateIndex = rowIndex * 2 + columnIndex;

      const recipe: Recipe = selectedRecipeListItems[templateIndex];
      if (!recipe) {
        return null;
      }
      const isTemplateSupported =
        currentTemplate?.character_types && recipe.character_types
          ? currentTemplate?.character_types?.some((element: CharacterType) =>
              recipe.character_types?.includes(element)
            )
          : true;

      return (
        <AnimatePresence key={recipe.id}>
          <motion.div
            style={{
              ...style,
              display: "flex",
              alignItems: "center",
              marginLeft: templateIndex % 2 === 1 ? "14px" : ""
            }}
            layout
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
              default: { duration: 0.5 }
            }}
          >
            <SingleTemplate
              recipeId={recipe.id}
              onSelectedRecipeId={onSelectedRecipeId}
              isSelected={currentDraftRecipeId === recipe.id}
              isTemplateSupported={isTemplateSupported}
            />
          </motion.div>
        </AnimatePresence>
      );
    },
    [selectedRecipeListItems.length, selectedSectionId]
  );

  return (
    <VariableSizeGrid
      height={height}
      width={334}
      rowCount={rowCount}
      columnCount={2}
      rowHeight={() => 122}
      columnWidth={() => 155}
    >
      {renderRow}
    </VariableSizeGrid>
  );
};

export default React.memo(TemplateDrawerElement);
