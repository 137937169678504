import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Input } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import { isValidEmail } from "app/utils/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { Member } from "app/types";
import { inviteTeamMessages } from "app/components/teams/messages";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button } from "@nextui-org/react";

const MaxFlexColumn = styled(H1_FlexColumn)`
  max-width: 227px;
`;
const BottomFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray1};
`;

const EmailsFlexColumn = styled(H1_FlexColumn)`
  transition: all 1s ease-in-out;
`;

const EmailInput = styled(Input)<{ $error: boolean }>`
  margin-top: 8px;
  border: 1px solid ${({ theme }) => theme.gray5};
  border-radius: 2px;

  &&&,
  &&&:focus {
    border: 1px solid ${({ $error, theme }) => ($error ? "red" : theme.gray5)};
    box-shadow: none;
  }
`;

interface EmailAddressRowProps {
  email: string;
  onChange: (memberEmail: string, emailError: string) => void;
  errorMap: Record<number, string>;
  index: number;
}
const EmailAddressRow = ({ email, onChange }: EmailAddressRowProps) => {
  const [error, setError] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState(email);
  const { formatMessage } = useIntl();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));

  useEffect(() => {
    if (emailValue) {
      onChange(emailValue, error);
    }
  }, [emailValue]);
  const checkForEmailDuplication = (value: string) => {
    let duplicateError = "";
    currentWorkspace?.members.forEach((member: Member) => {
      if (member.email === value) {
        duplicateError = formatMessage(inviteTeamMessages.emailAddressDuplicated);
      }
    });
    return duplicateError;
  };
  const onBlurEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    onChangeEmail(e, true);
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>, isOnBlur = false) => {
    const { value } = e.target;
    setEmailValue(value);
    const emailValid = !value || isValidEmail(value);
    if (emailValid) {
      setIsDirty(true);
    }
    if (value && (isDirty || isOnBlur)) {
      let currentError = "";
      if (!value) {
        currentError = formatMessage(inviteTeamMessages.emptyEmailAddress);
      } else if (!emailValid) {
        currentError = formatMessage(inviteTeamMessages.emailAddressInvalid, { email: value });
      }

      setError(currentError);
      if (!currentError) {
        currentError = checkForEmailDuplication(value);
        setError(currentError);
      }
    }
  };
  return (
    <MaxFlexColumn width="227px" gap="5px" flex="0 0 auto">
      <EmailInput
        allowClear
        size="large"
        value={emailValue}
        placeholder={formatMessage(inviteTeamMessages.emailAddressPlaceholder)}
        onBlur={onBlurEmail}
        onChange={onChangeEmail}
        type="email"
        $error={!!error}
      />
      <ConditionalRender condition={!!error}>
        <H1_TextXs whiteSpace="normal" fontSize="11px" lineHeight="16px" color="red">
          {error}
        </H1_TextXs>
      </ConditionalRender>
    </MaxFlexColumn>
  );
};

interface InviteTeamMembersModalProps {
  onClose: (teamMembers: string[]) => void;
  numberOfInitialTeamMembers: number;
}
const EmailAddressesSection = ({
  onClose,
  numberOfInitialTeamMembers
}: InviteTeamMembersModalProps) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMap, setErrorMap] = useState<Record<number, string>>({});
  const [teamMembers, setTeamMembers] = useState<string[]>(
    Array(numberOfInitialTeamMembers).fill("") as string[]
  );
  const { formatMessage } = useIntl();

  const onChangeEmailAddress = (email: string, emailError: string, index: number) => {
    const newTeamMember = [...teamMembers];
    newTeamMember[index] = email;
    setTeamMembers(newTeamMember);
    const updatedErrorMaps = { ...errorMap, [index]: emailError };
    setErrorMap(updatedErrorMaps);
    const errorMapValues = Object.values(updatedErrorMaps);
    setIsError(errorMapValues.length > 0 ? errorMapValues.some((error: string) => !!error) : true);
  };

  const onSendEmails = () => {
    const validEmails = teamMembers.filter(
      (email, index) => isValidEmail(email) && !errorMap[index]
    );
    onClose(validEmails);
  };

  return (
    <BottomFlexColumn justify="space-between" gap="30px">
      <H1_FlexColumn>
        <H1_TextSmall fontWeight={500} color="#F31260" fontFamily="Inter">
          {formatMessage(inviteTeamMessages.emailAddresses)}
        </H1_TextSmall>
        <EmailsFlexColumn overflow="auto" position="relative">
          <AnimatePresence>
            {teamMembers.map((memberEmail: string, index) => (
              <motion.div
                key={index}
                layout="position"
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                  default: {
                    duration: 0.5
                  }
                }}
              >
                <EmailAddressRow
                  key={index}
                  email={memberEmail}
                  onChange={(email, emailError) => onChangeEmailAddress(email, emailError, index)}
                  errorMap={errorMap}
                  index={index}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </EmailsFlexColumn>
      </H1_FlexColumn>
      <H1_FlexRow
        align="center"
        justify="center"
        height="40px"
        padding="10px 0 0 0"
        flex="0 0 50px"
        gap="20px"
      >
        <Button color="primary" onClick={onSendEmails} disabled={isError}>
          {formatMessage(inviteTeamMessages.InviteAndContinueButton)}
        </Button>
        <Button color="primary" variant="light" onClick={() => onClose([])}>
          {formatMessage(inviteTeamMessages.skipButton)}
        </Button>
      </H1_FlexRow>
    </BottomFlexColumn>
  );
};

export default EmailAddressesSection;
