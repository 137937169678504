/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/recipes.thunk";
import { fetchingStatus } from "app/utils/helpers";
import { FlatRecipe, Recipe, RecipeList } from "app/types/recipe";
import { FetchStatus, TemplateSelection } from "app/types";

const INITIAL_STATE: {
  recipes: RecipeList[];
  customRecipes: RecipeList[];
  personalRecipes: RecipeList[];
  recommendedRecipes: Recipe[];
  status: string;
  deleteStatus: FetchStatus;
  selectedSectionsNames: string[];
  workflowRecipes: FlatRecipe[];
  useCache: boolean;
  sourceTemplateSelection?: TemplateSelection;
} = {
  recipes: [],
  customRecipes: [],
  personalRecipes: [],
  recommendedRecipes: [],
  status: fetchingStatus.idle,
  deleteStatus: fetchingStatus.idle,
  selectedSectionsNames: [],
  workflowRecipes: [],
  useCache: false
};

export const recipesSlice = createSlice({
  name: "Recipes",
  initialState: INITIAL_STATE,
  reducers: {
    setTemplateSelection(state, action) {
      state.sourceTemplateSelection = action.payload;
      return state;
    },
    clearTemplateSelection(state) {
      state.sourceTemplateSelection = undefined;
      return state;
    },
    updateDeleteStatusToIdle: (state) => {
      state.deleteStatus = fetchingStatus.idle;
    },
    clearSelectedSectionNames: (state) => {
      state.selectedSectionsNames = [];
    },
    setSelectedSectionNames: (state, action) => {
      state.selectedSectionsNames = action.payload;
    },
    setUseCache: (state, action) => {
      state.useCache = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getRecipesRequest.fulfilled, (state, action) => {
      state.status = fetchingStatus.succeeded;
      const { useCache } = action.payload;
      if (useCache) {
        return;
      }
      const { recipes, customRecipes, personalRecipes } = action.payload;
      state.recipes = recipes;
      state.customRecipes = customRecipes;
      state.personalRecipes = personalRecipes;
      state.useCache = true;
    });
    builder.addCase(asyncThunks.getRecipesRequest.pending, (state) => {
      state.status = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getRecipesRequest.rejected, (state) => {
      state.status = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.getRecommendedRecipesRequest.fulfilled, (state, action) => {
      state.recommendedRecipes = action.payload;
      state.status = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.getRecommendedRecipesRequest.pending, (state) => {
      state.status = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getRecommendedRecipesRequest.rejected, (state) => {
      state.status = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.deleteRecipeRequest.fulfilled, (state) => {
      state.deleteStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.deleteRecipeRequest.pending, (state, action) => {
      state.deleteStatus = fetchingStatus.loading;
      const { recipeId, categoryName } = action.meta.arg;
      const recipes: RecipeList | undefined = state.personalRecipes.find(
        (personalRecipe: RecipeList) => personalRecipe.name === categoryName
      );
      const recipeIndex: number = state.personalRecipes.findIndex(
        (personalRecipe: RecipeList) => personalRecipe.name === categoryName
      );
      if (recipes) {
        const recipesItems = recipes.items.filter((recipe: Recipe) => recipe.id !== recipeId);
        state.personalRecipes[recipeIndex] = { ...recipes, items: recipesItems };
      }
    });
    builder.addCase(asyncThunks.deleteRecipeRequest.rejected, (state) => {
      state.deleteStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.getRecipesWorkflowsRequest.pending, (state) => {
      state.status = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.getRecipesWorkflowsRequest.fulfilled, (state, action) => {
      state.workflowRecipes = action.payload;
      state.status = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.getRecipesWorkflowsRequest.rejected, (state) => {
      state.status = fetchingStatus.failed;
    });
  }
});

export default recipesSlice.reducer;

export const recipesActions = {
  getRecipesRequest: asyncThunks.getRecipesRequest,
  getRecommendedRecipesRequest: asyncThunks.getRecommendedRecipesRequest,
  deleteRecipeRequest: asyncThunks.deleteRecipeRequest,
  updateSelectedSectionNames: asyncThunks.updateSelectedSectionNames,
  getRecipesWorkflowsRequest: asyncThunks.getRecipesWorkflowsRequest,
  ...recipesSlice.actions
};
