import { IntlShape } from "react-intl";
import messages from "app/components/editor/validations-messages";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const buildGeneralError = (message = "", intl: IntlShape, title?: string) => {
  const modId = message ? message.match(/%(.*?)%/i) : undefined;
  return {
    type: "alert",
    message: title || intl.formatMessage(messages.generic),
    description: modId ? (
      <H1_TextXs whiteSpace="normal" color="#8c8c8c">
        {message.replace(modId[0], "")}
      </H1_TextXs>
    ) : (
      <H1_TextXs whiteSpace="normal" color="#8c8c8c">
        {message}
      </H1_TextXs>
    ),
    modId: modId ? modId[1] : undefined
  };
};

export default buildGeneralError;
