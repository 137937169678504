import { PreloadedVideo } from "app/types/playground";

export const playgroundVideosCollection: PreloadedVideo[] = [
  {
    url: "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/8f5a2391-725d-41ed-8d0a-2f1de746ece7/example_720p.mp4",
    preview:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/8f5a2391-725d-41ed-8d0a-2f1de746ece7/preview_540p.webm",
    thumbnail:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/8f5a2391-725d-41ed-8d0a-2f1de746ece7/preview_540p.webp"
  },
  {
    url: "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/c786db81-acc1-482e-b2da-48b25dfbe005/example_720p.mp4",
    preview:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/c786db81-acc1-482e-b2da-48b25dfbe005/preview_540p.webm",
    thumbnail:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/c786db81-acc1-482e-b2da-48b25dfbe005/preview_540p.webp"
  },
  {
    url: "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/b7549bfb-1055-49fb-a2f4-e80b39ef8a84/example_720p.mp4",
    preview:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/b7549bfb-1055-49fb-a2f4-e80b39ef8a84/preview_540p.webm",
    thumbnail:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/b7549bfb-1055-49fb-a2f4-e80b39ef8a84/preview_540p.webp"
  },
  {
    url: "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/15762f2c-6f3e-46a6-8d90-3a539b25d6a0/example_720p.mp4",
    preview:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/15762f2c-6f3e-46a6-8d90-3a539b25d6a0/preview_540p.webm",
    thumbnail:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/15762f2c-6f3e-46a6-8d90-3a539b25d6a0/preview_540p.webp"
  },
  {
    url: "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/f8fe8df3-84e5-409a-9b93-6f8b0c42a1c0/example_720p.mp4",
    preview:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/f8fe8df3-84e5-409a-9b93-6f8b0c42a1c0/preview_540p.webm",
    thumbnail:
      "https://d2ftgw93nvmcre.cloudfront.net/playground/video_examples/f8fe8df3-84e5-409a-9b93-6f8b0c42a1c0/preview_540p.webp"
  },
  {
    url: "https://cdn.filestackcontent.com/p1GbEVcwROCcMrMxQJyN",
    preview: "https://cdn.filestackcontent.com/p1GbEVcwROCcMrMxQJyN",
    thumbnail: "https://cdn.filestackcontent.com/p1GbEVcwROCcMrMxQJyN"
  }
];
