import {
  useInboxNotifications,
  useUnreadInboxNotificationsCount
} from "app/services/liveBlocksClient";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { InboxNotification, InboxNotificationList } from "@liveblocks/react-comments";
import { Location } from "app/types";
import React from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import styled, { useTheme } from "styled-components";
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";

const RoundButton = styled(Button)`
  border-radius: 50%;
  margin-left: 5px;
  min-width: 10px;
  background-color: transparent;
`;
const DotCount = styled(H1_FlexRow)`
  border-radius: 50%;
  background-color: red;
  color: white;
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 8px;
`;

const InboxFlexRow = styled(H1_FlexRow)`
  max-height: calc(100vh - 150px);
  overflow: hidden auto;
`;

const HeaderCommentsNotifications = () => {
  const { inboxNotifications } = useInboxNotifications();
  const { count } = useUnreadInboxNotificationsCount();
  const theme = useTheme();

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <RoundButton
          isIconOnly
          startContent={<H1_Icon color={theme.gray8} isCursorPointer icon="far fa-comments" />}
        >
          <ConditionalRender condition={!!(count && count > 0)}>
            <DotCount width="13px" height="13px" padding="3px" align="center" justify="center">
              {count}
            </DotCount>
          </ConditionalRender>
        </RoundButton>
      </PopoverTrigger>
      <PopoverContent>
        <InboxFlexRow width="350px">
          <InboxNotificationList>
            {inboxNotifications?.map((inboxNotification) => (
              <InboxNotification
                key={inboxNotification.id}
                inboxNotification={inboxNotification}
                href={`${Location.Editor}/${inboxNotification.roomId?.split("-")[1]}`}
              />
            ))}
          </InboxNotificationList>
        </InboxFlexRow>
      </PopoverContent>
    </Popover>
  );
};

export default HeaderCommentsNotifications;
