/* eslint-disable camelcase */
import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch } from "app/hooks";
import { Channel } from "pusher-js";
import { userActions } from "app/store/slices/user.slice";
import { extractUserIdForPusher } from "app/utils/helpers";

const PusherMobileCharacterHandler = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleProgressUpdate = (message: { progress: number }) => {
      const { progress } = message;
      if (progress === 100) {
        dispatch(userActions.getUserDataRequest());
      }
    };
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("record_progress_update", handleProgressUpdate);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherMobileCharacterHandler;
