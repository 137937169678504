import { createSelector } from "reselect";
import { playgroundGlobalSelectors } from "app/store/adapters/adapters";
import { dateSorter } from "app/utils/helpers";

export const getAllVideosSortedByDate = createSelector(
  [playgroundGlobalSelectors.selectAll],
  (videos) => {
    return videos.sort((a, b) => dateSorter(b.created_at, a.created_at));
  }
);
