/* eslint-disable no-nested-ternary */
import { PaymentPeriod, PlanEnum, PlanToUpgrade } from "app/types";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import React, { useMemo } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import {
  H1_TextHeadline,
  H1_TextLargeHeadline,
  H1_TextMidHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import usePricingSinglePlan from "app/pages/pricing/PaymentModalV2/usePricingSinglePlan";
import ConditionalRender from "app/components/common/ConditionalRender";
import { paymentModalV2InitialMessages } from "app/pages/pricing/PaymentModalV2/messages";
import { useIntl } from "react-intl";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Slider, Tooltip } from "antd";
import { ButtonType } from "app/components/_Infrastructure/design-system/button/types";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import type { SliderMarks } from "antd/es/slider";
import { paymentsActions } from "app/store/slices/payments.slice";
import {
  getAllBusinessMonthlyPlansMinutes,
  getCurrentBusinessMinutes
} from "app/store/selectorsV2/payments.selectors";
import { Button } from "@nextui-org/react";

const Icon = styled.i`
  color: ${({ theme }) => theme.gray1};
  background-color: ${({ theme }) => theme.blue1};
  border-radius: 50%;
  font-size: 17px;
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  .ant-slider-rail {
    background-color: ${({ theme }) => theme.gray4};
  }
  .ant-slider-track {
    background-color: ${({ theme }) => theme.blue4};
  }
  :not(.ant-slider-disabled) {
    .ant-slider-handle {
      background-color: ${({ theme }) => theme.blue4};
      border-color: ${({ theme }) => theme.blue4};
    }
  }
  .ant-slider-step {
    .ant-slider-dot {
      display: none;
    }
  }
  .ant-slider-mark {
    .ant-slider-mark-text {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      white-space: pre;
    }
  }
  &:hover {
    .ant-slider-rail {
      background-color: ${({ theme }) => theme.gray4};
    }
    .ant-slider-track {
      background-color: ${({ theme }) => theme.blue4};
    }
    :not(.ant-slider-disabled) {
      .ant-slider-handle:not(.ant-tooltip-open) {
        background-color: ${({ theme }) => theme.blue4};
        border-color: ${({ theme }) => theme.blue4};
      }
    }
  }
`;

const CustomButton = styled(Button)`
  flex: 0 0 auto;
  margin: auto 0 0 0;
`;

const ActionButton = styled(Button)`
  margin: auto 0 0 0;
  flex: 0 0 auto;
`;

const FullFlexColumn = styled(H1_FlexColumn)<{ $withBorder: boolean }>`
  box-shadow: 0px 1.3px 50px 0px rgba(20, 20, 43, 0.08);
  border-radius: 15px;
  border: ${({ $withBorder, theme }) => ($withBorder ? `1px solid ${theme.blue4}` : "none")};
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: calc(100% - 20px);
    height: 100%;
    flex: 1 0 100%;
    padding-right: 10px;
  }
`;

const StyledRecommendedContainer = styled(H1_FlexColumn)`
  background: ${({ theme }) => theme.blue4};
  border-radius: 15px 15px 0 0;
  color: ${({ theme }) => theme.gray1};
  top: -12px;
  left: 47px;
  border-radius: 55px;
`;

interface PricingModalSinglePlanProps {
  cardPlan: PlanToUpgrade.personal | PlanToUpgrade.enterprise | PlanToUpgrade.business;
  onSelectedPlan: (plan: PlanToUpgrade, numberOfMinutesPosition?: number) => void;
  targetPlan: PlanEnum;
  isRecommended?: boolean;
}

const PaymentModalV2SinglePlan = ({
  cardPlan,
  onSelectedPlan,
  targetPlan,
  isRecommended = false
}: PricingModalSinglePlanProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { paymentPeriod, numberOfMinutesPosition } = useAppSelector(
    (state) => state.payments.pricingModalProperties
  );
  const { singlePlans } = usePricingSinglePlan({ paymentPeriod, numberOfMinutesPosition });

  const { currentPlan } = useCurrentPlan();
  const allBusinessMonthlyPlansMinutes: SliderMarks = useAppSelector(
    getAllBusinessMonthlyPlansMinutes
  );
  const isCurrentPlanBusiness = currentPlan === PlanEnum.business;
  const isCurrentPlan = currentPlan.toString() === cardPlan.toString();
  const isPaymentMonthly = paymentPeriod === PaymentPeriod.Monthly;
  const isCardPlanBusiness = cardPlan === PlanToUpgrade.business;
  const isCardPlanPersonal = cardPlan === PlanToUpgrade.personal;
  const isDowngradePlan = isCurrentPlanBusiness && isCardPlanPersonal;
  const planNotSupportedByFeature =
    targetPlan === PlanEnum.business && isCardPlanPersonal && currentPlan === PlanEnum.onBoarding;
  const isDisabled = isCurrentPlan || isDowngradePlan || planNotSupportedByFeature;
  const currentMinutes = useAppSelector(getCurrentBusinessMinutes) as number;

  const marks: SliderMarks = useMemo(() => {
    if (!allBusinessMonthlyPlansMinutes) {
      return {};
    }
    const plansAsArray = Object.keys(allBusinessMonthlyPlansMinutes);
    if (plansAsArray.length > 0) {
      const returnObject: Record<number, string> = {};
      plansAsArray.forEach((obj: string, index: number) => {
        returnObject[index + 1] = formatMessage(paymentModalV2InitialMessages.sliderMinutes, {
          number: Math.round(allBusinessMonthlyPlansMinutes[obj] as number)
        });
      });
      return returnObject;
    }
    return {};
  }, [allBusinessMonthlyPlansMinutes]);

  const planStyles = useMemo(() => {
    return {
      circleIcon: isCardPlanBusiness
        ? "linear-gradient(151.28deg, #5A5AFF 17.7%, rgba(90, 90, 255, 0) 132.04%);"
        : isCardPlanPersonal
        ? theme.orange1
        : theme.green1,
      hourOneLogo: isCardPlanBusiness
        ? theme.gray1
        : isCardPlanPersonal
        ? theme.orange4
        : "#6CD091",
      ctaButtonType: isCardPlanBusiness ? "primary" : "default"
    };
  }, [isCardPlanBusiness]);

  const onChangeSlider = (value: number) => {
    dispatch(
      paymentsActions.updatePricingModalProperties({
        numberOfMinutesPosition: value
      })
    );
  };

  return (
    <FullFlexColumn
      gap="12px"
      width="285px"
      height="519px"
      position="relative"
      padding="26px 25px 27px 24px"
      $withBorder={isRecommended}
    >
      <ConditionalRender condition={isRecommended && !isCurrentPlanBusiness}>
        <StyledRecommendedContainer
          position="absolute"
          justify="center"
          align="center"
          height="26px"
          width="190px"
          flex="0 0 190px"
        >
          <H1_TextXs color="white">
            {formatMessage(paymentModalV2InitialMessages.recommended)}
          </H1_TextXs>
        </StyledRecommendedContainer>
      </ConditionalRender>
      {/* Plan name */}
      <H1_TextMidHeadline fontWeight={700}>{singlePlans[cardPlan].name}</H1_TextMidHeadline>
      {/* Description */}
      <H1_FlexRow height="44px">
        <H1_TextSmall whiteSpace="break-spaces" color={theme.gray7}>
          {singlePlans[cardPlan].description}
        </H1_TextSmall>
      </H1_FlexRow>

      <H1_FlexColumn flex="0 0 126px">
        {/* Price */}
        <ConditionalRender condition={cardPlan !== PlanToUpgrade.enterprise}>
          <H1_FlexRow align="flex-end" gap="7px">
            <H1_TextLargeHeadline fontWeight={700}>
              $
              {isPaymentMonthly
                ? singlePlans[cardPlan].priceMonthlyPerMonth
                : singlePlans[cardPlan].priceYearlyPerMonth}
            </H1_TextLargeHeadline>
            <H1_TextSmall color={theme.gray7}>
              {isPaymentMonthly
                ? formatMessage(paymentModalV2InitialMessages.perMonth)
                : formatMessage(paymentModalV2InitialMessages.perMonthAnnually)}
            </H1_TextSmall>
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={cardPlan === PlanToUpgrade.enterprise}>
          <H1_FlexRow padding="0 0 8px 0" align="flex-end" gap="7px">
            <H1_TextHeadline fontWeight={700}>
              {formatMessage(paymentModalV2InitialMessages.letsTalk)}
            </H1_TextHeadline>
          </H1_FlexRow>
        </ConditionalRender>

        {/* Billed Monthly */}
        <H1_FlexRow padding="6px 0 0 0" gap="10px">
          <ConditionalRender condition={isCardPlanBusiness}>🎉</ConditionalRender>
          <H1_TextXs
            color={isCardPlanBusiness && !isPaymentMonthly ? theme.blue4 : theme.gray7}
            fontWeight={600}
          >
            {singlePlans[cardPlan].priceDescription(currentMinutes)}
          </H1_TextXs>
        </H1_FlexRow>

        {/* Slider */}
        <ConditionalRender condition={cardPlan === PlanToUpgrade.business}>
          <H1_FlexRow width="100%" padding="6px 10px 0">
            <StyledSlider
              min={1}
              max={3}
              dots
              marks={marks}
              defaultValue={numberOfMinutesPosition}
              onChange={onChangeSlider}
              step={null}
              tooltip={{ formatter: null }}
              disabled={isDisabled}
            />
          </H1_FlexRow>
        </ConditionalRender>
        <ConditionalRender condition={cardPlan !== PlanToUpgrade.business}>
          <H1_FlexRow padding="52px 0 0 0" />
        </ConditionalRender>
      </H1_FlexColumn>

      {/* Features */}
      <H1_FlexColumn gap="5px" padding="18px 0 0 0">
        <H1_FlexRow padding="0 0 4px 0">
          <H1_TextXs fontWeight={700}>{singlePlans[cardPlan].whatsIncluded}</H1_TextXs>
        </H1_FlexRow>
        {singlePlans[cardPlan].headerFeatures.map((feature: { text: string }, index: number) => (
          <H1_FlexRow key={index} height="17px" align="center" gap="9px">
            <Icon className="fa-regular fa-check-circle" />
            <H1_TextXs lineHeight="17px">{feature.text}</H1_TextXs>
          </H1_FlexRow>
        ))}
      </H1_FlexColumn>

      {/* Button */}
      <ConditionalRender condition={cardPlan !== PlanToUpgrade.enterprise}>
        <Tooltip
          title={
            planNotSupportedByFeature
              ? formatMessage(paymentModalV2InitialMessages.planNotSupported)
              : ""
          }
        >
          <H1_FlexRow margin="auto 0 0 0">
            <CustomButton
              isDisabled={isDisabled}
              onClick={() => onSelectedPlan(cardPlan, numberOfMinutesPosition)}
              color={planStyles.ctaButtonType as ButtonType}
              fullWidth
            >
              {isCurrentPlan
                ? formatMessage(paymentModalV2InitialMessages.yourCurrentPlan)
                : formatMessage(paymentModalV2InitialMessages.selectPlan)}
            </CustomButton>
          </H1_FlexRow>
        </Tooltip>
      </ConditionalRender>
      <ConditionalRender condition={cardPlan === PlanToUpgrade.enterprise}>
        <ActionButton
          onClick={() => onSelectedPlan(cardPlan)}
          color={isCurrentPlanBusiness ? "primary" : "default"}
        >
          {formatMessage(paymentModalV2InitialMessages.contactSales)}
        </ActionButton>
      </ConditionalRender>
    </FullFlexColumn>
  );
};

export default PaymentModalV2SinglePlan;
