import { defineMessages, useIntl } from "react-intl";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { brandKitActions } from "app/store/slices/brandKit.slice";
import MediaChooseBox from "app/components/MediaChooseBox";
import useModal, { ModalName } from "app/hooks/useModal";
import { AssetKeyType, MediaType } from "app/types/media";
import styled, { useTheme } from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { fetchingStatus } from "app/utils/helpers";
import useDisplayUrls, { MediaUrl } from "app/hooks/useDisplayUrls";
import CustomColorPickerModal from "app/components/editor/sideDrawers/StyleDrawer/CustomColorPickerModal";
import { PaletteColor, PaletteColorKey, PlanEnum } from "app/types";
import PaletteBackground from "app/components/editor/sideDrawers/StyleDrawer/PaletteBackground";
import TitledInput from "app/components/common/TitledInput";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import OutsideClickHandler from "react-outside-click-handler";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { Button, Input } from "@nextui-org/react";

export const messages = defineMessages({
  brandhubHeadTitle: {
    id: "brandhub.head.title",
    defaultMessage: "Brand Hub"
  },

  nameInputTitle: {
    id: "brandhub.input.title",
    defaultMessage: "Name"
  },
  logoTitle: {
    id: "brandhub.input.logo-title",
    defaultMessage: "Logo"
  },
  removeLogoTooltip: {
    id: "brandhub.input.remove-logo-tooltip",
    defaultMessage: "Remove Logo"
  },
  InsertLogoBtn: {
    id: "brandhub.input.insert-logo-btn",
    defaultMessage: "Add Logo"
  },
  removeLogoBtn: {
    id: "brandhub.input.remove-logo-btn",
    defaultMessage: "Remove Logo"
  },
  replaceLogoTooltip: {
    id: "brandhub.input.replace-logo-tooltip",
    defaultMessage: "Replace Logo"
  },
  introTitle: {
    id: "brandhub.input.intro-title",
    defaultMessage: "Intro"
  },
  outroTitle: {
    id: "brandhub.input.outro-title",
    defaultMessage: "Outro"
  },
  insertBrandStyleBtn: {
    id: "brandhub.input.insert-brand-style",
    defaultMessage: "Add Brand Style"
  },
  insertIntroBtn: {
    id: "brandhub.input.insert-intro-btn",
    defaultMessage: "Add Intro"
  },
  removeIntroBtn: {
    id: "brandhub.input.remove-intro-btn",
    defaultMessage: "Remove Intro"
  },
  replaceIntroTooltip: {
    id: "brandhub.input.replace-intro-tooltip",
    defaultMessage: "Replace Intro"
  },
  insertOutroBtn: {
    id: "brandhub.input.insert-outro-btn",
    defaultMessage: "Add Outro"
  },
  removeOutroBtn: {
    id: "brandhub.input.remove-outro-btn",
    defaultMessage: "Remove Outro"
  },
  replaceOutroTooltip: {
    id: "brandhub.input.replace-outro-tooltip",
    defaultMessage: "Replace Outro"
  },
  edit: {
    id: "brandhub.input.edit-btn",
    defaultMessage: "Edit"
  },
  delete: {
    id: "brandhub.input.delete-btn",
    defaultMessage: "Delete"
  },
  colorsTitle: {
    id: "brandhub.input.colors-title",
    defaultMessage: "Colors"
  },
  addBrandUpgradeText: {
    id: "brandhub.add-brand-upgrade-text",
    defaultMessage: "Upgrade to add brand style"
  },
  addLogoUpgradeText: {
    id: "brandhub.add-logo-upgrade-text",
    defaultMessage: "Upgrade to add logo"
  },
  addIntroOutroUpgradeText: {
    id: "brandhub.add-intro-outro-upgrade-text",
    defaultMessage: "Upgrade to add intro and outro"
  }
});

const EditButton = styled(Button)`
  padding: unset;
  height: unset;
`;

const SubDrawer = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray2};

  overflow: scroll;
  height: 100%;
`;

const Wrapper = styled(H1_FlexRow)`
  position: absolute;
  left: 224px;
  background-color: transparent;
  height: 102vh;
  border-left: 1px solid ${(props) => props.theme.gray5};
  overflow: scroll;
  width: calc(100vw + 312px - 224px);
  padding-bottom: 10px;
`;

const CloseContainerFlexRow = styled(H1_FlexRow)`
  top: 10px;
  right: 10px;
  i {
    color: ${({ theme }) => theme.gray7};
    font-size: 20px;
  }
`;

const BlackShadow = styled(H1_FlexRow)`
  flex: 1;
  background-color: black;
  opacity: 0.6;
`;

const CreateBrandButton = styled(Button)<{ $orange?: boolean }>`
  height: 38px;
  &,
  &:active,
  &:focus {
    background: ${(props) => (props.$orange ? props.theme.orange1 : props.theme.blue4)};
    color: ${(props) => (props.$orange ? props.theme.orange4 : "white")};
    border: unset;
  }
  &:hover {
    color: white;
    background: ${(props) => (props.$orange ? props.theme.orange2 : props.theme.blue2)};
    opacity: 0.8;
  }
  i {
    margin-right: 8px;
  }
`;

const StyledCircleLoader = styled(CircleLoader)`
  justify-self: center;
`;

const initialPalette = [
  { color: "#000000", key: PaletteColorKey.color_B },
  { color: "#5A5AF6", key: PaletteColorKey.color_A1 },
  { color: "#F09636", key: PaletteColorKey.color_A2 },
  { color: "#DCDDFC", key: PaletteColorKey.color_A3 },
  { color: "#ffffff", key: PaletteColorKey.color_W }
];
interface BrandkitHubProps {
  onClose: () => void;
}
const BrandkitHub = ({ onClose }: BrandkitHubProps) => {
  const [showPaletteModal, setShowPaletteModal] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { openModal, editorModalOpen } = useModal();
  const theme = useTheme();
  const showCrown = useShowCrown({
    requestedFeature: PlanFeature.useBrand
  });
  const showPaymentModal = useUpgradeModal();

  const brandKit = useAppSelector((state) => state.brandKit.currentBrandkit);
  const brandkitStatus = useAppSelector((state) => state.brandKit.brandkitStatus);

  const urls = [brandKit.logo?.url, brandKit.intro?.url, brandKit.outro?.url].filter(
    (url) => !!url
  );
  const { displayUrls } = useDisplayUrls(urls);

  const isLoading = brandkitStatus === fetchingStatus.loading;

  useEffect(() => {
    dispatch(brandKitActions.getBrandKitRequest());
  }, []);

  const onChangeName = (value: string) => {
    dispatch(brandKitActions.upsertBrandkitRequest({ name: value }));
  };
  const checkUpgrade = ({ upgradeText }: { upgradeText: string }) => {
    return showPaymentModal({
      source: "brandhub",
      targetPlan: PlanEnum.business,
      requestedFeature: PlanFeature.useBrand,
      upgradeText
    });
  };
  const onLogoSelect = (key: string) => {
    const upgradeRequired = checkUpgrade({
      upgradeText: formatMessage(messages.addLogoUpgradeText)
    });
    if (!upgradeRequired) {
      openModal(ModalName.mediaLibraryModal, {
        assetKey: key,
        assetKeyType: AssetKeyType.brandkit,
        mediaType: MediaType.image,
        hideStockVideos: true
      });
    }
  };

  const onIntroOutroSelect = (key: string) => {
    const upgradeRequired = checkUpgrade({
      upgradeText: formatMessage(messages.addIntroOutroUpgradeText)
    });
    if (!upgradeRequired) {
      openModal(ModalName.mediaLibraryModal, {
        assetKey: key,
        assetKeyType: AssetKeyType.brandkit
      });
    }
  };
  const onRemove = (key: string) => {
    dispatch(brandKitActions.upsertBrandkitRequest({ [key]: null }));
  };

  const onCloseColorModal = () => {
    setShowPaletteModal(false);
  };
  const onSelectPalette = (colors: PaletteColor[]) => {
    dispatch(brandKitActions.upsertBrandkitRequest({ palette: colors }));
    setShowPaletteModal(false);
  };

  const onCreateBrand = () => {
    const upgradeRequired = checkUpgrade({
      upgradeText: formatMessage(messages.addBrandUpgradeText)
    });
    if (!upgradeRequired) {
      setShowPaletteModal(true);
    }
  };

  const onCloseBrandKit = () => {
    setTimeout(() => {
      onClose();
    }, 0);
  };

  const onClickOutside = () => {
    if (showPaletteModal || editorModalOpen?.open) {
      return;
    }
    onCloseBrandKit();
  };

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={onClickOutside}>
        <SubDrawer padding={"23px"} width={"312px"} position="relative">
          <CloseContainerFlexRow position="absolute" onClick={onCloseBrandKit}>
            <i className="far fa-xmark" />
          </CloseContainerFlexRow>
          <ConditionalRender condition={showPaletteModal}>
            <CustomColorPickerModal
              onClose={onCloseColorModal}
              onFinish={onSelectPalette}
              colors={brandKit.palette || initialPalette}
            />
          </ConditionalRender>
          <H1_FlexColumn gap={"13px"} margin={"0px 0px 10px 0px"} flex={"1"}>
            <H1_TextMiddle fontWeight={590} color={theme.gray8}>
              {formatMessage(messages.brandhubHeadTitle)}
            </H1_TextMiddle>
            <ConditionalRender condition={isLoading}>
              <H1_FlexColumn flex={"1"} justify={"center"}>
                <StyledCircleLoader />
              </H1_FlexColumn>
            </ConditionalRender>

            <ConditionalRender condition={!isLoading}>
              <TitledInput title={formatMessage(messages.nameInputTitle)}>
                <Input
                  size="sm"
                  labelPlacement="outside"
                  placeholder={formatMessage(messages.nameInputTitle)}
                  defaultValue={brandKit?.name}
                  onBlur={(evt: any) => onChangeName(evt.target.value)}
                />
              </TitledInput>
              <TitledInput title={formatMessage(messages.logoTitle)}>
                <MediaChooseBox
                  onClick={() => onLogoSelect("logo")}
                  showCrown={showCrown}
                  upgradeButtonText={formatMessage(messages.InsertLogoBtn)}
                  replaceToolTip={formatMessage(messages.replaceLogoTooltip)}
                  removeToolTip={formatMessage(messages.removeLogoTooltip)}
                  previewUrl={(brandKit.logo?.url && displayUrls[brandKit.logo?.url]) as MediaUrl}
                  removeMedia={() => onRemove("logo")}
                  insertText={formatMessage(messages.InsertLogoBtn)}
                  icon={<i className="fa-regular fa-cloud-arrow-up"></i>}
                  source={"brandhub_logo"}
                />
              </TitledInput>
              <ConditionalRender condition={!brandKit.palette}>
                <TitledInput title={formatMessage(messages.colorsTitle)}>
                  <CreateBrandButton $orange={showCrown} onClick={onCreateBrand}>
                    {formatMessage(messages.insertBrandStyleBtn)}
                  </CreateBrandButton>
                </TitledInput>
              </ConditionalRender>
              <ConditionalRender condition={!!brandKit.palette}>
                <TitledInput
                  title={formatMessage(messages.colorsTitle)}
                  rightElement={
                    <H1_FlexRow gap={"5px"}>
                      <EditButton onClick={() => onRemove("palette")} variant="light">
                        <H1_TextSmall>{formatMessage(messages.delete)}</H1_TextSmall>
                      </EditButton>
                      <EditButton onClick={onCreateBrand} variant="light">
                        <H1_TextSmall>{formatMessage(messages.edit)}</H1_TextSmall>
                      </EditButton>
                    </H1_FlexRow>
                  }
                >
                  <PaletteBackground colors={brandKit.palette as PaletteColor[]} selected />
                </TitledInput>
              </ConditionalRender>

              <TitledInput title={formatMessage(messages.introTitle)}>
                <MediaChooseBox
                  onClick={() => onIntroOutroSelect("intro")}
                  showCrown={showCrown}
                  upgradeButtonText={formatMessage(messages.insertIntroBtn)}
                  replaceToolTip={formatMessage(messages.replaceIntroTooltip)}
                  removeToolTip={formatMessage(messages.removeIntroBtn)}
                  previewUrl={(brandKit.intro?.url && displayUrls[brandKit.intro?.url]) as MediaUrl}
                  removeMedia={() => onRemove("intro")}
                  insertText={formatMessage(messages.insertIntroBtn)}
                  icon={<i className="fa-regular fa-cloud-arrow-up"></i>}
                  source={"brandhub_intro"}
                />
              </TitledInput>
              <TitledInput title={formatMessage(messages.outroTitle)}>
                <MediaChooseBox
                  onClick={() => onIntroOutroSelect("outro")}
                  showCrown={showCrown}
                  upgradeButtonText={"Add Outro"}
                  replaceToolTip={formatMessage(messages.replaceOutroTooltip)}
                  removeToolTip={formatMessage(messages.removeOutroBtn)}
                  previewUrl={(brandKit.outro?.url && displayUrls[brandKit.outro?.url]) as MediaUrl}
                  removeMedia={() => onRemove("outro")}
                  insertText={formatMessage(messages.insertOutroBtn)}
                  icon={<i className="fa-regular fa-cloud-arrow-up"></i>}
                  source={"brandhub_outro"}
                />
              </TitledInput>
            </ConditionalRender>
          </H1_FlexColumn>
        </SubDrawer>
      </OutsideClickHandler>
      <BlackShadow />
    </Wrapper>
  );
};

export default BrandkitHub;
