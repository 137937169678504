import { PropsWithChildren, useState } from "react";
import RecordStepSettingsContext from "app/pages/createDigitalTwinPage/RecordStepSettingsContext";

const RecordStepSettingsContextWrapper = ({ children }: PropsWithChildren) => {
  const [selectedCamera, setSelectedCamera] = useState<string>();
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>();
  const [faceOutline, setfaceOutline] = useState<boolean>(true);

  return (
    <RecordStepSettingsContext.Provider
      value={{
        selectedCamera,
        setSelectedCamera,
        selectedMicrophone,
        setSelectedMicrophone,
        faceOutline,
        setfaceOutline
      }}
    >
      {children}
    </RecordStepSettingsContext.Provider>
  );
};

export default RecordStepSettingsContextWrapper;
