import { Draft } from "app/types/draft";

export enum TrashType {
  draft = "draft"
}

export interface Trash {
  id: string;
  item_id: string;
  item_type: TrashType;
  item_data: Draft;
}

export interface TrashRequest {
  item_id: string;
  item_type: TrashType;
}
