import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import React, { useState } from "react";
import { Modal } from "antd";
import { Button } from "@nextui-org/react";

const WorkflowRevertButton = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const workflowLiveStatus = useAppSelector((state) => state.drafts.workflowLiveStatus);
  const workflowId = useAppSelector((state) => state.drafts.currentDraft?.workflow_id);
  const workflow = useAppSelector((state) => state.drafts.currentDraft?.workflow);
  const revertDraftStatus = useAppSelector((state) => state.drafts.revertDraftStatus);
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);

  const loading = revertDraftStatus === fetchingStatus.loading;
  const disabled = workflowLiveStatus === fetchingStatus.loading;
  const onClick = () => {
    setShowModal(true);
  };

  if (!workflow?.published || workflowLiveMode) {
    return null;
  }

  const closeModal = () => {
    setShowModal(false);
  };

  const onRevert = () => {
    setShowModal(false);
    dispatch(draftsActions.revertWorkflowRequest({ workflowId: workflowId as string }));
  };
  return (
    <>
      <Modal
        open={showModal}
        destroyOnClose
        onCancel={closeModal}
        centered
        cancelText={"Cancel"}
        okText={"Revert"}
        width={400}
        onOk={onRevert}
        title={"Revert Workflow"}
        bodyStyle={{ padding: "20px" }}
      >
        <p>
          Are you sure you want to revert this workflow to live version?
          <br />
          draft changes will be lost.
        </p>
      </Modal>
      <Button
        color="primary"
        data-auto-id="workflow-revert-button"
        isLoading={loading}
        onClick={onClick}
        isDisabled={disabled}
      >
        Revert
      </Button>
    </>
  );
};

export default WorkflowRevertButton;
