import { useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";
import { draftPageMessages } from "app/pages/EditDraftPage/messages";
import styled, { useTheme } from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ModalName } from "app/hooks/useModal";
import { SocialMedia, Video } from "app/types";
import * as videosSelector from "app/store/selectorsV2/videos.selectors";
import usePermissions from "app/hooks/usePermissions";
import useCopyLink from "app/hooks/useCopyLink";
import useEmbed from "app/hooks/useEmbed";
import useEmailVideoEmbedSender from "app/hooks/useEmailVideoEmbedSender";
import useDownload from "app/hooks/useDownload";
import React, { useState } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { sharePopoverMessages } from "app/pages/EditDraftPage/TopVideoActions/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import useShareSocialMedia from "app/hooks/useShareSocialMedia";
import { SwiperSlide } from "swiper/react";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/circle-upgrade-icon.svg";
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const EDITOR_HEADER = "editor_header";

const RoundButton = styled(Button)`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.gray4};
  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  span {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  }
  i {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
  }
  &:hover,
  &:active {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    span {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    i {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? "#2c285c" : theme.gray11)};
    }
    background-color: ${({ theme }) => theme.gray5};
  }
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: calc(100% - 24px);
  .swiper-slide {
    display: flex;
    width: auto;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    box-shadow: none;
    background-color: transparent;
    border: none;
    top: calc(50% - 10px);
    bottom: 20px;
    background-size: 24px;
    transition: 0.3s all ease-in-out;
    z-index: 100;
    &:hover {
      background-size: 30px;
    }
    &:active {
      background-size: 20px;
    }
  }
  &&& .swiper-button-prev {
    left: -20px;
    background-color: transparent;
  }
  &&& .swiper-button-next {
    right: -20px;
    background-color: transparent;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
  &:hover .swiper-button-prev:not(.swiper-button-disabled),
  &:hover .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const ShareIcon = styled.i`
  font-size {
    width: 20px;
    height: 20px;
  }
`;

const CircleIconContainer = styled(H1_FlexRow)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: inline-flex;
  color: white;
  position: relative;
`;
const EmbedCircle = styled(CircleIconContainer)`
  background-color: ${({ theme }) => theme.blue1};
`;

const EmailCircle = styled(CircleIconContainer)`
  background-color: ${({ theme }) => theme.blue1};
`;
const TwitterCircle = styled(CircleIconContainer)`
  background-color: rgb(29, 155, 240);
`;

const FacebookCircle = styled(CircleIconContainer)`
  background-color: #365896;
`;
const WhatsappCircle = styled(CircleIconContainer)`
  background-color: #25d366;
`;
const LinkedInCircle = styled(CircleIconContainer)`
  background-color: #0a66c2;
`;

const BrandIcon = styled.i<{ color?: string }>`
  color: ${({ color }) => color || "white"};
  font-size: 14px;
`;

const StyledUpgradeIcon = styled(UpgradeIcon)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
`;

const DownloadUpgradeIcon = styled(StyledUpgradeIcon)`
  top: -5px;
  right: -5px;
`;

interface TopVideoActionsSharePopoverProps {
  draftId: string;
}
const TopVideoActionsSharePopover = ({ draftId }: TopVideoActionsSharePopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { openDownloadModal } = useDownload();
  const { copyLink } = useCopyLink();
  const { shareLink } = useShareSocialMedia();
  const embedCode = useEmbed();
  const theme = useTheme();
  const { isPublishAllow, isDownloadAllow } = usePermissions();
  const { prepareEmailContent } = useEmailVideoEmbedSender();

  const readyToPlayVideos: Video[] = useAppSelector(videosSelector.getReadyToPlayVideos);
  const lastReadyVideo = readyToPlayVideos[0];

  const openEmbed = isPublishAllow || lastReadyVideo?.paid;
  const openDownload = isDownloadAllow || lastReadyVideo?.paid;

  const onDownload = () => {
    setIsOpen(false);
    openDownloadModal({ source: EDITOR_HEADER, video: lastReadyVideo });
  };

  const onClickCopyLink = () => {
    setIsOpen(false);
    copyLink(EDITOR_HEADER, lastReadyVideo as Video, draftId);
  };

  const onClickEmbeddedVideo = () => {
    setIsOpen(false);
    embedCode(EDITOR_HEADER, lastReadyVideo as Video);
  };

  const onEmbedInEmail = () => {
    setIsOpen(false);
    prepareEmailContent(lastReadyVideo, EDITOR_HEADER).then();
  };

  const onClickFacebook = () => {
    setIsOpen(false);
    shareLink(
      EDITOR_HEADER,
      {
        req_id: lastReadyVideo.req_id,
        id: lastReadyVideo.id,
        status: lastReadyVideo.status as string
      },
      SocialMedia.facebook,
      ModalName.videoReady
    );
  };

  const onClickLinkedIn = () => {
    setIsOpen(false);
    shareLink(
      EDITOR_HEADER,
      {
        req_id: lastReadyVideo.req_id,
        id: lastReadyVideo.id,
        status: lastReadyVideo.status as string
      },
      SocialMedia.linkedin,
      ModalName.videoReady
    );
  };

  const onClickWhatsapp = () => {
    setIsOpen(false);
    shareLink(
      EDITOR_HEADER,
      {
        req_id: lastReadyVideo.req_id,
        id: lastReadyVideo.id,
        status: lastReadyVideo.status as string
      },
      SocialMedia.whatsapp,
      ModalName.videoReady
    );
  };

  const onClickTwitter = () => {
    setIsOpen(false);
    shareLink(
      EDITOR_HEADER,
      {
        req_id: lastReadyVideo.req_id,
        id: lastReadyVideo.id,
        status: lastReadyVideo.status as string
      },
      SocialMedia.twitter,
      ModalName.videoReady
    );
  };

  const shareContent = (
    <H1_FlexColumn width="282px" padding="8px 4px">
      <H1_TextMiddle>{formatMessage(sharePopoverMessages.title)}</H1_TextMiddle>
      <H1_TextSmall padding="10px 0 0 0">
        {formatMessage(sharePopoverMessages.anyoneWithThisLink)}
      </H1_TextSmall>
      <H1_FlexRow padding="28px 0px" width="100%" flex="0 0 50px" gap="10px" position="relative">
        <StyledSwiper
          navigation
          speed={700}
          slidesPerView="auto"
          spaceBetween={18}
          slidesPerGroup={4}
        >
          <SwiperSlide key="email">
            <H1_FlexColumn gap="6px" align="center">
              <EmailCircle onClick={onEmbedInEmail}>
                <BrandIcon color={theme.blue4} className="fas fa-envelope" />
                <ConditionalRender condition={!openEmbed}>
                  <StyledUpgradeIcon />
                </ConditionalRender>
              </EmailCircle>
              <H1_TextXs>{formatMessage(sharePopoverMessages.email)}</H1_TextXs>
            </H1_FlexColumn>
          </SwiperSlide>
          <SwiperSlide key="embed">
            <H1_FlexColumn gap="6px" align="center">
              <EmbedCircle onClick={onClickEmbeddedVideo}>
                <BrandIcon color={theme.blue4} className="fas fa-code-simple" />
                <ConditionalRender condition={!openEmbed}>
                  <StyledUpgradeIcon />
                </ConditionalRender>
              </EmbedCircle>
              <H1_TextXs>{formatMessage(sharePopoverMessages.embed)}</H1_TextXs>
            </H1_FlexColumn>
          </SwiperSlide>
          <SwiperSlide key="facebook">
            <H1_FlexColumn gap="6px" align="center">
              <FacebookCircle onClick={onClickFacebook}>
                <BrandIcon className="fa-brands fa-facebook-f" />
              </FacebookCircle>
              <H1_TextXs>{formatMessage(sharePopoverMessages.facebook)}</H1_TextXs>
            </H1_FlexColumn>
          </SwiperSlide>
          <SwiperSlide key="linkedin">
            <H1_FlexColumn gap="6px" align="center">
              <LinkedInCircle onClick={onClickLinkedIn}>
                <BrandIcon className="fa-brands fa-linkedin-in" />
              </LinkedInCircle>
              <H1_TextXs>{formatMessage(sharePopoverMessages.linkedin)}</H1_TextXs>
            </H1_FlexColumn>
          </SwiperSlide>
          <SwiperSlide key="whatsapp">
            <H1_FlexColumn gap="6px" align="center">
              <WhatsappCircle onClick={onClickWhatsapp}>
                <BrandIcon className="fa-brands fa-whatsapp" />
              </WhatsappCircle>
              <H1_TextXs>{formatMessage(sharePopoverMessages.whatsapp)}</H1_TextXs>
            </H1_FlexColumn>
          </SwiperSlide>
          <SwiperSlide key="twitter">
            <H1_FlexColumn gap="6px" align="center">
              <TwitterCircle onClick={onClickTwitter}>
                <BrandIcon className="fa-brands fa-twitter" />
              </TwitterCircle>
              <H1_TextXs>{formatMessage(sharePopoverMessages.twitter)}</H1_TextXs>
            </H1_FlexColumn>
          </SwiperSlide>
        </StyledSwiper>
      </H1_FlexRow>
      <H1_FlexRow position="relative" width="100%">
        <Button
          color="primary"
          fullWidth
          onClick={onDownload}
          startContent={<i className="far fa-arrow-down-to-line" />}
        >
          {formatMessage(draftPageMessages.download)}
          <ConditionalRender condition={!openDownload}>
            <DownloadUpgradeIcon />
          </ConditionalRender>
        </Button>
      </H1_FlexRow>
      <H1_FlexRow padding="12px 0 0 0" />
      <Button
        color="primary"
        variant="bordered"
        onClick={onClickCopyLink}
        startContent={<i className="far fa-link" />}
      >
        {formatMessage(draftPageMessages.copyLink)}
      </Button>
    </H1_FlexColumn>
  );

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <Popover placement="bottom-end" isOpen={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger>
        <RoundButton
          color="primary"
          startContent={<ShareIcon className="fa-solid fa-share-nodes" />}
          onClick={() => setIsOpen(true)}
          isIconOnly
        />
      </PopoverTrigger>
      <PopoverContent>{shareContent}</PopoverContent>
    </Popover>
  );
};
export default TopVideoActionsSharePopover;
