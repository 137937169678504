import { useEffect, useMemo, useState } from "react";
import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useAuth } from "app/auth/useAuth";
import { userActions } from "app/store/slices/user.slice";
import { fetchingStatus } from "app/utils/helpers";
import { userIdentify } from "app/store/thunks/analyticsEvents.thunk";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { useCookies } from "react-cookie";
import { Cookies, outSideCookies, socialLoginCookies } from "app/services/cookie-manager";
import { security } from "app/store/security";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { isMobile } from "react-device-detect";
import useMarketplace from "app/hooks/useMarketplace";
import { MARKET_PLACE_KEYS } from "app/types/LocalStorageKeys";

const relevantCookies = [...socialLoginCookies, ...outSideCookies];
export const useAuthReduxReduce = () => {
  const [tokenPlaced, setTokenPlaced] = useState(false);
  const [isWorkspacesLoadedFirstTime, setIsWorkspacesLoadedFirstTime] = useState(false);
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<boolean>(false);

  useMarketplace();

  const userDataStatus = useAppSelector((state) => state.user.userDataStatus);
  const refreshToken = useAppSelector((state) => state.user.refreshToken);
  const registered = useAppSelector((state) => state.user.registered);
  const workspacesStatus = useAppSelector((state) => state.workspaces.workspacesStatus);
  const allUserPermissions = useAppSelector((state) => state.user.permissions);
  const initialQueryParams = useAppSelector((state) => state.user.initialQueryParams);
  const msMarketPlaceToken = useAppSelector((state) => state.user.msMarketPlaceToken);

  const [cookies, , removeCookie] = useCookies(relevantCookies);
  const authenticatedApiCallsAvailable = auth.isAuthenticated && tokenPlaced;

  const marketPlaceRefLocalStorage = useMemo(() => {
    try {
      return localStorage.getItem(MARKET_PLACE_KEYS.marketplace_ref);
    } catch (err) {
      Sentry.captureException(err, {
        extra: { description: "failed read marketplace_ref from local storage" }
      });
    }
  }, []);

  const msMarketPlaceRefLocalStorage = useMemo(() => {
    try {
      return localStorage.getItem(MARKET_PLACE_KEYS.msMarketplace_ref);
    } catch (err) {
      Sentry.captureException(err, {
        extra: { description: "failed read ms_marketplace_ref from local storage" }
      });
    }
  }, []);

  const onTokenChange = async () => {
    try {
      security.setAccessTokenSilently(auth.accessToken as string);
      setTokenPlaced(true);
      const decoded = jwtDecode<{ permissions: string[]; logout_redirect_url?: string }>(
        auth.accessToken as string
      );
      dispatch(userActions.setUserPermissions(decoded.permissions));
      if (decoded.logout_redirect_url) {
        dispatch(userActions.setLogoutRedirect(decoded.logout_redirect_url));
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error("error", err);
      setError(true);
    }
  };

  useEffect(() => {
    if (authenticatedApiCallsAvailable) {
      dispatch(
        userActions.getUserDataRequest({ journey: cookies.journey, hubspotutk: cookies.hubspotutk })
      );
    }
  }, [authenticatedApiCallsAvailable]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (authenticatedApiCallsAvailable) {
      dispatch(mediaActions.getMediaPolicyRequest());

      interval = setInterval(() => {
        dispatch(mediaActions.getMediaPolicyRequest());
      }, 1000 * 60 * 60 * 12);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [authenticatedApiCallsAvailable]);

  useEffect(() => {
    if (workspacesStatus === fetchingStatus.succeeded) {
      setIsWorkspacesLoadedFirstTime(true);
    }
    if (workspacesStatus === fetchingStatus.failed) {
      setIsWorkspacesLoadedFirstTime(true);
    }
  }, [workspacesStatus]);

  useEffect(() => {
    if (registered && auth.user?.sub) {
      dispatch(workspacesActions.getWorkspacesRequest());
    }
  }, [registered, auth.user?.sub]);

  useEffect(() => {
    const { user } = auth;
    if (user) {
      const userForAnalytics = {
        id: user?.sub || "",
        permissions: allUserPermissions,
        email: user?.email || "",
        isMobile
      };
      dispatch(userIdentify(userForAnalytics));
      Sentry.setUser({ id: user?.sub });
    }
  }, [auth.user]);

  useEffect(() => {
    const { renewToken } = auth;
    if (!refreshToken) {
      return;
    }
    (async () => {
      try {
        await renewToken();
        if (cookies.journey) {
          removeCookie(Cookies.journey);
        }
      } catch (err) {
        console.error("error", err);
        Sentry.captureException(err);

        setError(true);
      }
    })();
    //   refreshToken only on register
  }, [refreshToken]);

  useEffect(() => {
    if (registered) {
      // if user is registered we do nothing here - will not fail the flow.
      return;
    }
    if (userDataStatus === fetchingStatus.succeeded) {
      dispatch(userActions.setUserDataStatusToIdle());
    } else if (userDataStatus === fetchingStatus.failed) {
      setError(true);
      dispatch(userActions.setUserDataStatusToIdle());
    }
  }, [userDataStatus]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.accessToken && auth.isInitialized) {
      // auth.isInitialized is a condition here because until auth.isInitialized = true, the token is replaced.
      onTokenChange();
    } else {
      setTokenPlaced(false);
      setIsWorkspacesLoadedFirstTime(false);
      setError(false);
    }
  }, [auth.accessToken, auth.isInitialized]);
  const appReady =
    (authenticatedApiCallsAvailable && registered && isWorkspacesLoadedFirstTime) ||
    (!auth.isAuthenticated && auth.isInitialized);

  useEffect(() => {
    const gcpToken = initialQueryParams.marketplace_ref || marketPlaceRefLocalStorage || undefined;
    const msToken = msMarketPlaceToken || msMarketPlaceRefLocalStorage || undefined;
    if (registered && authenticatedApiCallsAvailable && (msToken || gcpToken)) {
      dispatch(
        userActions.userConnectRequest({
          gcpToken: gcpToken,
          msToken: msToken,
          skip401ErrorHandling: true
        })
      );
    }
  }, [
    authenticatedApiCallsAvailable,
    registered,
    initialQueryParams.marketplace_ref,
    msMarketPlaceToken
  ]);

  useEffect(() => {
    if (initialQueryParams.marketplace_ref) {
      try {
        localStorage.setItem(MARKET_PLACE_KEYS.marketplace_ref, initialQueryParams.marketplace_ref);
      } catch (err) {
        Sentry.captureException(err, { extra: { description: "failed to save marketplace_ref" } });
      }
    }
  }, [initialQueryParams.marketplace_ref]);

  return { error, appReady };
};
