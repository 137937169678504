import { useAppSelector } from "app/hooks";

const useIsFirstVideoCreated = () => {
  const preferences = useAppSelector((state) => state.user.preferences);
  const isFirstVideoCreated =
    preferences.is_first_video_created === undefined || preferences.is_first_video_created;

  return isFirstVideoCreated;
};

export default useIsFirstVideoCreated;
