import { useAppDispatch, useAppSelector } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import { PlanEnum } from "app/types";
import { NotSupportedPlansPerFeatures, PlanFeature } from "app/config/planFeature";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { getFeaturesList } from "app/store/selectorsV2/user.selectors";
import { bookDemoUrl } from "app/utils/urls";
import { maxAvatarsForPlan } from "app/config/createAvatarBlockConfig";
import { Avatar } from "app/types/virtualTwin";

interface showPaymentModalProps {
  source: string;
  reqId?: string;
  upgradeText?: string;
  upgradeSubText?: string;
  backModal?: ModalName;
  requestedFeature?: PlanFeature;
  requestedPlans?: PlanEnum[];
  targetPlan?: PlanEnum;
  context?: any;
}
const useUpgradeModal = () => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const { currentPlan: plan } = useCurrentPlan();
  const features = useAppSelector(getFeaturesList);

  const showPaymentModal = ({
    source,
    reqId,
    upgradeText,
    backModal,
    requestedFeature,
    requestedPlans,
    targetPlan,
    context
  }: showPaymentModalProps): boolean => {
    if (!requestedFeature && !requestedPlans) {
      console.error("should be set one option requestedFeature or requestedPlans");
      return false;
    }

    if (features.includes(requestedFeature as PlanFeature)) {
      return false;
    }

    if (requestedFeature) {
      if (NotSupportedPlansPerFeatures[requestedFeature].includes(plan)) {
        dispatch(googleEvents.productUpgradeCta({ cta: source }));
        if (targetPlan === PlanEnum.enterprise) {
          window.open(bookDemoUrl(source), "_blank");
        } else {
          openModal(
            ModalName.paymentModalV2,
            { source, reqId, upgradeText, targetPlan },
            backModal
          );
        }
        return true;
      }
    }
    if (requestedPlans && requestedPlans.length && !requestedPlans.includes(plan)) {
      dispatch(googleEvents.productUpgradeCta({ cta: source }));
      if (requestedPlans[0] === PlanEnum.enterprise) {
        window.open(bookDemoUrl(source), "_blank");
      } else {
        openModal(
          ModalName.paymentModalV2,
          { source, upgradeText, targetPlan: requestedPlans[0], ...context },
          backModal
        );
      }
      return true;
    }

    return false;
  };
  return showPaymentModal;
};

export default useUpgradeModal;

export const useShowCrown = ({ requestedFeature }: { requestedFeature?: PlanFeature }) => {
  if (!requestedFeature) return false;
  const { currentPlan } = useCurrentPlan();

  if (requestedFeature && NotSupportedPlansPerFeatures[requestedFeature].includes(currentPlan)) {
    return true;
  }
  return false;
};

export const createAvatarMaxedAmount = (avatarSessions: Avatar[]) => {
  const { currentPlan } = useCurrentPlan();
  if (!Object.keys(maxAvatarsForPlan).includes(currentPlan)) return true;
  // @ts-ignore Undefined plans will return True on previous line
  return avatarSessions.length > maxAvatarsForPlan[currentPlan];
};

export const useUpgradeByPlan = () => {
  const { openModal } = useModal();
  const { currentPlan: plan } = useCurrentPlan();
  const dispatch = useAppDispatch();

  return ({ source }: { source: string }) => {
    const targetPlan = plan === PlanEnum.onBoarding ? PlanEnum.personal : PlanEnum.business;
    dispatch(googleEvents.productUpgradeCta({ cta: source }));
    openModal(ModalName.paymentModalV2, { source, targetPlan });
  };
};

export const useUpgradeToEnterprise = () => {
  const dispatch = useAppDispatch();
  return ({ source }: { source: string }) => {
    dispatch(googleEvents.productUpgradeCta({ cta: source }));
    dispatch(googleEvents.pricingMeetingRequest({ source }));
    window.open(bookDemoUrl(source), "_blank");
  };
};

export const getTargetPlan = (plan: PlanEnum): PlanEnum => {
  if (plan == PlanEnum.onBoarding) {
    return PlanEnum.personal;
  }
  if (plan == PlanEnum.personal) {
    return PlanEnum.business;
  } else {
    return PlanEnum.enterprise;
  }
};
