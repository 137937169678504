import { defineMessages } from "react-intl";

export const createNewTeamMessages = defineMessages({
  title: {
    id: "teams.create-new-team.title",
    defaultMessage: "Create New Workspace"
  },
  description: {
    id: "teams.create-new-team.description",
    defaultMessage:
      "Ready, set, collaborate! Establish your workspace and choose a name to kick off your AI-powered video creation journey."
  },
  teamNameTitle: {
    id: "teams.create-new-team.team-name-title",
    defaultMessage: "Workspace Name"
  },
  teamNamePlaceholder: {
    id: "teams.create-new-team.team-name-placeholder",
    defaultMessage: "Workspace Name"
  },
  cancelButton: {
    id: "teams.create-new-team.cancel-button",
    defaultMessage: "Cancel"
  },
  createButton: {
    id: "teams.create-new-team.create-button",
    defaultMessage: "Create"
  }
});

export const inviteTeamMessages = defineMessages({
  title: {
    id: "teams.invite-team.title",
    defaultMessage: `Invite<span>Members</span>`
  },
  currentMembers: {
    id: "teams.invite-team.current-members",
    defaultMessage: `Current members`
  },
  inviteButton: {
    id: "teams.invite-team.invite-button",
    defaultMessage: "Invite"
  },
  upgradeButton: {
    id: "teams.invite-team.upgrade-button",
    defaultMessage: "Upgrade"
  },
  sendInvitationsButton: {
    id: "teams.invite-team.send-invitations-button",
    defaultMessage: "Send Invitations"
  },
  emailAddresses: {
    id: "teams.invite-team.email-addresses",
    defaultMessage: "Email Addresses"
  },
  InviteAndContinueButton: {
    id: "teams.invite-team.send-invite-and-continue-button",
    defaultMessage: "Invite and continue"
  },
  InviteToWorkspaceHeader: {
    id: "teams.invite-team.invite-to-workspace-header",
    defaultMessage: "Invite Members"
  },
  emailAddressLabel: {
    id: "teams.invite-team.email-address-label",
    defaultMessage: "Email Address"
  },
  InviteToWorkspaceSubHeader: {
    id: "teams.invite-team.invite-to-workspace-subheader",
    defaultMessage: "Invite a new member to your workspace"
  },
  closeButton: {
    id: "teams.invite-team.close-button",
    defaultMessage: "Close"
  },
  skipButton: {
    id: "teams.invite-team.skip-button",
    defaultMessage: "Skip"
  },
  cancelButton: {
    id: "teams.invite-team.cancel-button",
    defaultMessage: "Cancel"
  },
  description: {
    id: "teams.invite-team.description",
    defaultMessage:
      "Add team members to your HourOne workspace and experience seamless collaboration for creating stunning AI videos."
  },
  lowerTitle: {
    id: "teams.invite-team.lower-title",
    defaultMessage: "Invite your team members"
  },
  subtitle: {
    id: "teams.invite-team.subtitle",
    defaultMessage:
      "Fast-track your workspace’s creativity! Invite colleagues to Reals for rapid and effortless AI-powered video creation with lifelike avatars."
  },
  getInviteLink: {
    id: "teams.invite-team.get-invite-link",
    defaultMessage: "Get Invite Link"
  },
  roleEditor: {
    id: "teams.invite-team.role-editor",
    defaultMessage: "Editor"
  },
  roleViewer: {
    id: "teams.invite-team.role-viewer",
    defaultMessage: "Viewer"
  },
  inviteByEmail: {
    id: "teams.invite-team.invite-by-email",
    defaultMessage: "Invite by email"
  },
  emailAddressPlaceholder: {
    id: "teams.invite-team.email-address-placeholder",
    defaultMessage: "Enter email address"
  },
  emailAddressInvalid: {
    id: "teams.invite-team.email-address-invalid",
    defaultMessage: "{email} doesn't look like an email address. Try again?"
  },
  emptyEmailAddress: {
    id: "teams.invite-team.empty-email-address",
    defaultMessage: "Empty email address"
  },
  emptyHowDidYouHear: {
    id: "teams.invite-team.empty-how-did-you-hear",
    defaultMessage: "Please fill in"
  },
  emailAddressDuplicated: {
    id: "teams.invite-team.email-address-duplicated",
    defaultMessage: "Email address is already in the workspace"
  },
  emailInvalid: {
    id: "teams.invite-team.email-invalid",
    defaultMessage: "Please enter a valid email"
  },
  addAnother: {
    id: "teams.invite-team.add-another",
    defaultMessage: "Add Another"
  },
  maxSeats: {
    id: "teams.invite-team.max-seats",
    defaultMessage: "To add more than {maxSeats} seats"
  },
  inviteTeamMemberNotification: {
    id: "teams.invite-team.invite-team-member-notification",
    defaultMessage: "Invitation sent to {email}"
  },
  inviteTeamMembersNotification: {
    id: "teams.invite-team.invite-team-members-notification",
    defaultMessage: "{number} invitations sent"
  },
  newTeamCreatedNotification: {
    id: "teams.invite-team.new-team-created",
    defaultMessage: "New workspace created!"
  },
  upgradeTitle: {
    id: "teams.invite-team.upgrade-title",
    defaultMessage: "Invite more workspace members"
  },
  upgradeSubTitle: {
    id: "teams.invite-team.upgrade-subtitle",
    defaultMessage:
      "Invite more than {number} workspace members. Book a meeting with us and we can walk you through it!"
  },
  inviteMembers: {
    id: "teams.invite-team.invite-members",
    defaultMessage: "Invite Members"
  }
});
