import {
  $createRangeSelection,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_EDITOR
} from "lexical";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

import { $createPauseNode, PauseNode } from "app/components/common/LexicalEditor/nodes/PauseNode";
import { PauseCommandPayload } from "app/components/common/LexicalEditor/plugins/types";
import constants from "app/components/common/LexicalEditor/plugins/constants";

export default function PausePlugin({ sceneId }: { sceneId: string }): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([PauseNode])) {
      throw new Error("PausePlugins: PauseNode not registered on editor");
    }

    return editor.registerCommand<PauseCommandPayload>(
      constants.INSERT_PAUSE_COMMAND,
      (payload) => {
        const { time } = payload;
        let selection = $getSelection();
        if (!selection) {
          const rangeSelection = $createRangeSelection();
          $setSelection(rangeSelection);
          selection = $getSelection();
        }

        if ($isRangeSelection(selection)) {
          const pauseNode = $createPauseNode(time, sceneId);
          selection.insertNodes([pauseNode]);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
