import { Button, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";

const InfoButton = styled(Button)`
  & svg path {
    fill: ${(props) => props.theme.gray5};
  }
`;
const RowStyled = styled(H1_FlexRow)`
  padding-left: 12px;
  min-width: 220px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ContentWrapper = styled.div`
  width: 200px;
`;

interface FeatureContentProps {
  featureName: string;
  info: string;
}

const FeatureContent = ({ featureName, info }: FeatureContentProps) => {
  const theme = useTheme();
  return (
    <RowStyled gap="10px" align="center">
      <Popover
        placement="right"
        content={info ? <ContentWrapper>{info}</ContentWrapper> : null}
        showArrow={!!info}
      >
        <StyledDiv>
          <H1_TextMiddle whiteSpace="break-spaces" color={theme.gray11}>
            {featureName}
          </H1_TextMiddle>
          <ConditionalRender condition={!!info}>
            <InfoButton type="text" icon={<InfoCircleOutlined />} />
          </ConditionalRender>
        </StyledDiv>
      </Popover>
    </RowStyled>
  );
};

export default FeatureContent;
