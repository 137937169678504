import { createClient } from "@liveblocks/client";
import { createLiveblocksContext, createRoomContext } from "@liveblocks/react";
import Config from "app/config/Config";
import { Member, SynthesisMarkupLanguage, Location } from "app/types";
import { fetchSecured } from "app/store/security";
import {
  getCurrentWorkspace,
  workspaceSupportsLiveBlocks
} from "app/store/selectorsV2/workspaces.selectors";
import redux from "app/store/store";
import * as Sentry from "@sentry/react";

export enum LiveBlocksEventType {
  sceneTextChanged = "sceneTextChanged"
}

const createMemberObject = (curr: Member) => {
  return {
    name: curr.nickname || curr.email?.split("@")[0] || "",
    avatar: curr.picture || "",
    id: curr.user_id || ""
  };
};

const client = createClient({
  // publicApiKey: Config.liveBlocks,
  resolveRoomsInfo: async ({ roomIds }) => {
    const draftsIds: string[] = roomIds.map((roomId) => {
      const split = roomId.split("-");
      if (split.length > 0) {
        return split[1];
      }
      return roomId;
    });
    const roomsInfoResponse = await fetchSecured(
      `${Config.serverUrl}/v2/collaboration/rooms/info`,
      {
        method: "POST",
        body: JSON.stringify({ room_ids: draftsIds }),
        rejectOnFailure: true
      }
    );

    return (roomsInfoResponse.data.rooms_info || []).map(
      (roomData: { room_name: string; room_id: string }) => ({
        name: roomData.room_name,
        url: `${Location.Editor}/${roomData.room_id}`
      })
    );
  },
  authEndpoint: async () => {
    const state = redux.store.getState();
    const isLiveBlocksSupported = workspaceSupportsLiveBlocks(state);
    const { user } = state;
    const email = user.email || user.currentEmail || "";
    const name = email.split("@")[0];

    if (isLiveBlocksSupported) {
      try {
        const response = await fetchSecured(`${Config.serverUrl}/v2/collaboration/token`, {
          method: "POST",
          body: JSON.stringify({ user_info: { name } }),
          rejectOnFailure: true
        });
        const responseData = await response.data;
        if (responseData?.token) {
          return await responseData;
        }
      } catch (e) {
        console.error("Error authenticating LiveBlocks client", e);
        Sentry.captureMessage(`Error authenticating LiveBlocks client`);
      }
    }
  },
  resolveUsers: async (obj: { userIds: string[] }) => {
    const currentWorkspace = getCurrentWorkspace(redux.store.getState());

    const members = currentWorkspace?.members.reduce((acc: any, curr: Member) => {
      acc[curr.user_id as string] = createMemberObject(curr);
      return acc;
    }, {});
    const users = obj.userIds.map((userId: string) => members[userId]);
    return users;
  },
  resolveMentionSuggestions: ({ text }) => {
    const currentWorkspace = getCurrentWorkspace(redux.store.getState());
    const members = currentWorkspace?.members.map((member: Member) => createMemberObject(member));

    const filteredMembers =
      members
        ?.filter((member) => member.name.toLowerCase().includes(text.toLowerCase()))
        .map((member) => member.id) || [];

    return filteredMembers;
  }
});

// Presence represents the properties that will exist on every User in the Room
// and that will automatically be kept in sync. Accessible through the
// `user.presence` property. Must be JSON-serializable.
type Presence = {
  email?: string;
  sceneId?: string;
  cursor: { x: number; y: number } | undefined;
  dimensions: { width: number; height: number } | undefined;
  scrollTop: number;
};

// Optionally, Storage represents the shared document that persists in the
// Room, even after all Users leave. Fields under Storage typically are
// LiveList, LiveMap, LiveObject instances, for which updates are
// automatically persisted and synced to all connected clients.
type Storage = {
  // animals: LiveList<string>,
  // ...
};

type UserMeta = {
  id: string;
  info: {
    name: string;
  };
};

type RoomEvent = {
  type: LiveBlocksEventType;
  sceneId: string;
  sml: SynthesisMarkupLanguage[];
};

// Optionally, UserMeta represents static/readonly metadata on each User, as
// provided by your own custom auth backend (if used). Useful for data that
// will not change during a session, like a User's name or avatar.
// type UserMeta = {
//   id?: string,  // Accessible through `user.id`
//   info?: Json,  // Accessible through `user.info`
// };

// Optionally, the type of custom events broadcasted and listened for in this
// room. Must be JSON-serializable.
// type RoomEvent = {};

export const {
  RoomProvider,
  useThreads,
  useOthers,
  useUpdateMyPresence,
  useOthersMapped,
  useMyPresence,
  useBroadcastEvent,
  useEventListener
} = createRoomContext<
  Presence,
  Storage,
  UserMeta,
  // @ts-ignore - Json object
  RoomEvent
>(client);

export const { LiveblocksProvider, useInboxNotifications, useUnreadInboxNotificationsCount } =
  createLiveblocksContext(client);
