import { defineMessages } from "react-intl";

export const templatesFowChoosingMessages = defineMessages({
  header: {
    id: `onboarding.create-video-flow-modal.header`,
    defaultMessage: "Choose how you want to create your video"
  },
  templateOptionTitle: {
    id: `onboarding.create-video-flow-modal.templates.title`,
    defaultMessage: "Create video using {br} our templates"
  },
  templateOptionDescription: {
    id: `onboarding.create-video-flow-modal.templates.description`,
    defaultMessage: "Choose template from our vast collection of templates"
  },
  templateOptionButton: {
    id: `onboarding.create-video-flow-modal.templates.button`,
    defaultMessage: "Browse Templates"
  },
  presentationOptionTitle: {
    id: `onboarding.create-video-flow-modal.presentation.title`,
    defaultMessage: "Turn your presentation {br} into a video"
  },
  presentationOptionDescription: {
    id: `onboarding.create-video-flow-modal.presentation.description`,
    defaultMessage: "Choose a presentation or pdf for converting to a presentation video"
  },
  presentationOptionButton: {
    id: `onboarding.create-video-flow-modal.presentation.button`,
    defaultMessage: "Import Presentation"
  },
  or: {
    id: `onboarding.create-video-flow-modal.presentation.or`,
    defaultMessage: "Or"
  }
});

export const recommendedTemplateModalMessages = defineMessages({
  header: {
    id: `onboarding.templates-recommendation-modal.title`,
    defaultMessage: "Choose a template"
  },
  subTitle: {
    id: `onboarding.templates-recommendation-modal.sub-title`,
    defaultMessage: "These templates are recommended for you"
  },
  useTemplateBtn: {
    id: `onboarding.templates-recommendation-modal.use-template-btn`,
    defaultMessage: "Use This Template"
  },
  moreTemplatesBtn: {
    id: `onboarding.templates-recommendation-modal.more-templates-btn`,
    defaultMessage: "More templates"
  }
});
