import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import { Skeleton } from "antd";

const MinWidthFlexColumn = styled(H1_FlexColumn)`
  min-width: ${({ width }: { width?: string }) => width};
`;

const StyledSkeletonButton = styled(Skeleton.Button)`
  &&&.ant-skeleton-element .ant-skeleton-button {
    height: ${({ $height }: { $height?: string; $borderRadius?: string }) => $height};
    border-radius: ${({ $borderRadius }: { $borderRadius?: string }) => $borderRadius};
  }
`;

const TemplateCollectionSkelaton = () => {
  return (
    <H1_FlexRow align="center" justify="space-between" padding="0 28px 21px 0" margin="0 0 0 50px">
      <H1_FlexColumn padding="34px 0 0 0">
        <H1_FlexRow height="24px" align="flex-start">
          <Skeleton loading active paragraph={{ rows: 0 }} title={{ width: "190px" }} />
        </H1_FlexRow>
        <H1_FlexRow gap="28px" width="100%" padding="21px 0 0 0">
          <MinWidthFlexColumn width="284px" height="212px">
            <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
          </MinWidthFlexColumn>
          <MinWidthFlexColumn width="284px" height="212px">
            <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
          </MinWidthFlexColumn>
          <MinWidthFlexColumn width="284px" height="212px">
            <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
          </MinWidthFlexColumn>
          <MinWidthFlexColumn width="284px" height="212px">
            <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
          </MinWidthFlexColumn>
        </H1_FlexRow>
      </H1_FlexColumn>
    </H1_FlexRow>
  );
};

export default TemplateCollectionSkelaton;
