/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/brandkit.thunks";
import { fetchingStatus } from "app/utils/helpers";
import { BrandKit, FetchStatus } from "app/types";
import { brandKitsAdapter } from "app/store/adapters/adapters";

interface BrandKitState {
  brandkitStatus: FetchStatus;
  upsertStatus: FetchStatus;
  selectedBrandKitId?: string;
  currentBrandkit: Partial<BrandKit>;
}

export const brandKitSlice = createSlice({
  name: "BrandKit",
  initialState: brandKitsAdapter.getInitialState<BrandKitState>({
    currentBrandkit: {},
    brandkitStatus: fetchingStatus.idle as FetchStatus,
    upsertStatus: fetchingStatus.idle as FetchStatus,
    selectedBrandKitId: undefined
  }),
  reducers: {
    setBrandkitStatusToIdle(state) {
      state.brandkitStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    setCurrentBrandKit(state, { payload }) {
      state.selectedBrandKitId = payload as string;
      return state;
    },
    updateBrandKit: brandKitsAdapter.updateOne
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getBrandKitRequest.fulfilled, (state, action) => {
      if (action.payload.length) {
        brandKitsAdapter.setAll(state, action.payload);
        state.currentBrandkit = action.payload[0];
      } else {
        state.currentBrandkit = {};
      }
      state.brandkitStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getBrandKitRequest.pending, (state) => {
      state.brandkitStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getBrandKitRequest.rejected, (state) => {
      state.brandkitStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.upsertBrandkitRequest.fulfilled, (state, action) => {
      if (!state.currentBrandkit.id) {
        brandKitsAdapter.upsertOne(state, action.payload);
        state.currentBrandkit = action.payload;
      }
      state.upsertStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.upsertBrandkitRequest.pending, (state, action) => {
      state.currentBrandkit = { ...state.currentBrandkit, ...action.meta.arg };
      state.upsertStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.upsertBrandkitRequest.rejected, (state) => {
      state.upsertStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});

export default brandKitSlice.reducer;

export const brandKitActions = {
  getBrandKitRequest: asyncThunks.getBrandKitRequest,
  upsertBrandkitRequest: asyncThunks.upsertBrandkitRequest,
  ...brandKitSlice.actions
};
