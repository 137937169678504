import {
  StatusMessages,
  useReactMediaRecorder
} from "app/components/screenRecording/MediaRecorder";
import React, { useEffect, useState } from "react";
import useNotifications from "app/hooks/useNotifications";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import useAudioPlayer from "app/hooks/useAudioPlayer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { defineMessages, useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import {
  H1_TextHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";

export interface AudioRecorderProps {
  disabled?: boolean;
  hidePlayer: boolean;
  onFinish: (file: File) => void;
  maxTime: number;
  file?: File;
  cleanFile?: () => void;
  onStartRecordEvent?: () => void;
  onStopRecordEvent?: () => void;
}

const RecordButton = styled(Button)`
  width: 155px;
  height: 155px;
  i {
    font-size: 21px;
    color: white;
  }
  span {
    font-size: 14px;
  }
`;

const PlayButton = styled(Button)`
  background: unset;
  font-size: 32px;
  &&,
  &&:hover {
    color: ${({ theme }) => theme.blue4};
    span {
      color: ${({ theme }) => theme.blue4};
    }
    background: unset;
  }
  i {
    color: ${({ theme }) => theme.blue4}!important;
    font-size: 32px;
  }
  i:hover {
    opacity: 0.8;
  }
`;

const Messages = defineMessages({
  recordingButtonLabel: {
    id: "audio-recorder.recording-button.record.label.recording",
    defaultMessage: "Recording"
  },
  stopButtonLabel: {
    id: "audio-recorder.recording-button.record.label.stop",
    defaultMessage: "Stop"
  },
  recordAgainButtonLabel: {
    id: "audio-recorder.recording-button.record.label.record-again",
    defaultMessage: "Record Again"
  },
  startRecordingButtonLabel: {
    id: "audio-recorder.recording-button.record.label.start-record",
    defaultMessage: "Record"
  }
});

const AudioRecorder = ({
  onFinish,
  maxTime,
  file,
  cleanFile,
  onStartRecordEvent,
  onStopRecordEvent,
  disabled,
  hidePlayer
}: AudioRecorderProps) => {
  const [recordExecuted, setRecordExecuted] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const { notifyMessages } = useNotifications();
  const theme = useTheme();
  const intl = useIntl();
  const {
    error,
    counterDownTimer,
    recordTimer,
    status,
    startShareScreen,
    startRecording: startRecord,
    stopRecording: stopRecord,
    mediaBlobUrl,
    clearBlobUrl
  } = useReactMediaRecorder({
    screen: false,
    audio: true,
    video: false,
    countDownInSec: maxTime
  });

  const { formattedTime, playAudio, isPlaying, stopAudio } = useAudioPlayer(file);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (mediaBlobUrl) {
        const blob = await (await fetch(mediaBlobUrl as string)).blob();
        const asFile = new File([blob.slice(0, blob.size, MIME_TYPE)], fileName, {
          type: MIME_TYPE
        });
        onFinish(asFile);
      }
    })();
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (error) {
      console.error("audio recording is not supported", error);
      notifyMessages([{ message: `Error: ${error}` }]);
      onCloseSession();
    }
  }, [error]);

  useEffect(() => {
    if (status === StatusMessages.shareScreenActivated) {
      if (onStartRecordEvent) {
        onStartRecordEvent();
      }
      startRecord();
      setRecordExecuted(true);
    } else if (status === StatusMessages.shareScreenDeactivatedBeforeRecord) {
      onCloseSession();
    } else if (status === StatusMessages.stopped) {
      setRecordExecuted(false);
    }
  }, [status]);

  const onStartRecord = () => {
    dispatch(analyticsEvents.salesMeetStartRecordedSession());
    setFileName(`Recording ${new Date().toISOString()}`);
    setRecordExecuted(false);
    startShareScreen();
  };

  const MIME_TYPE = "audio/wav";

  const onCloseSession = () => {
    clearBlobUrl();
  };

  const onRecord = () => {
    if (recordExecuted) {
      if (onStopRecordEvent) {
        onStopRecordEvent();
      }

      stopRecord();
      setRecordExecuted(false);
    } else {
      clearBlobUrl();
      onStartRecord();
      if (cleanFile) {
        cleanFile();
      }
    }
  };
  const getButtonLabel = () => {
    if (recordExecuted && mediaBlobUrl) {
      return intl.formatMessage(Messages.recordingButtonLabel);
    }
    if (recordExecuted) {
      return intl.formatMessage(Messages.stopButtonLabel);
    }
    if (fileName) {
      return intl.formatMessage(Messages.recordAgainButtonLabel);
    }
    return intl.formatMessage(Messages.startRecordingButtonLabel);
  };

  return (
    <H1_FlexColumn gap="17px" align="center">
      <ConditionalRender condition={!!file && !hidePlayer}>
        <H1_FlexRow gap="12px" align="center">
          <PlayButton
            isDisabled={disabled}
            isIconOnly
            startContent={<i className={`fa-solid fa-circle-${isPlaying ? "stop" : "play"}`} />}
            onClick={() => (isPlaying ? stopAudio() : playAudio())}
          />
          <i className="fa-solid fa-waveform-lines" color={theme.gray9} />
          <H1_TextSmall color={theme.gray10}>{formattedTime}</H1_TextSmall>
          <i className="fa-solid fa-volume" color={theme.gray10} />
        </H1_FlexRow>
      </ConditionalRender>

      <ConditionalRender condition={!file && !hidePlayer}>
        <H1_TextHeadline color={isPlaying ? theme.blue4 : theme.gray7}>
          {recordExecuted ? counterDownTimer : recordTimer}
        </H1_TextHeadline>
      </ConditionalRender>

      <H1_FlexColumn gap="10px">
        <RecordButton
          color={disabled ? "success" : "danger"}
          radius="lg"
          disabled={disabled}
          onClick={onRecord}
        >
          <H1_FlexColumn gap="1px">
            <i className="fa-solid fa-microphone" />
            <H1_TextXs color="white">{getButtonLabel()}</H1_TextXs>
          </H1_FlexColumn>
        </RecordButton>
        <ConditionalRender condition={recordExecuted}>
          <Button variant="light" onClick={onRecord}>
            Cancel
          </Button>
        </ConditionalRender>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default AudioRecorder;
