import {
  StatusMessages,
  useReactMediaRecorder
} from "app/components/screenRecording/MediaRecorder";
import React, { useEffect, useState } from "react";
import useNotifications from "app/hooks/useNotifications";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import useAudioPlayer from "app/hooks/useAudioPlayer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { defineMessages, useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import {
  H1_TextHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

export interface AudioRecorderProps {
  onFinish: (file: File) => void;
  maxTime: number;
  file?: File;
  cleanFile?: () => void;
  onStartRecordEvent?: () => void;
  onStopRecordEvent?: () => void;
  description: string;
}

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background: ${(props) => props.theme.gray3};
  border-radius: 10px;
  min-height: 369px;
  min-width: 438px;
  justify-self: stretch;
`;

const RecordButton = styled(Button)`
  border-radius: 50%;
  width: 162px;
  height: 162px;
  i {
    font-size: 34px;
  }
  margin: unset;
  border: unset;
  padding: unset;
`;

const PlayButton = styled(Button)`
  background: unset;
  font-size: 32px;
  &&,
  &&:hover {
    color: ${({ theme }) => theme.blue4};
    span {
      color: ${({ theme }) => theme.blue4};
    }
    background: unset;
  }
  i {
    color: ${({ theme }) => theme.blue4}!important;
    font-size: 32px;
  }
  i:hover {
    opacity: 0.8;
  }
`;

const Messages = defineMessages({
  recordingButtonLabel: {
    id: "audio-recorder.recording-button.record.label.recording",
    defaultMessage: "Recording"
  },
  stopButtonLabel: {
    id: "audio-recorder.recording-button.record.label.stop",
    defaultMessage: "Stop"
  },
  recordAgainButtonLabel: {
    id: "audio-recorder.recording-button.record.label.record-again-label",
    defaultMessage: "Record again"
  },
  startRecordingButtonLabel: {
    id: "audio-recorder.recording-button.record.label.start",
    defaultMessage: "Start recording"
  }
});

const AudioRecorder = ({
  onFinish,
  maxTime,
  description,
  file,
  cleanFile,
  onStartRecordEvent,
  onStopRecordEvent
}: AudioRecorderProps) => {
  const [recordExecuted, setRecordExecuted] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const { notifyMessages } = useNotifications();
  const theme = useTheme();
  const intl = useIntl();
  const {
    error,
    counterDownTimer,
    recordTimer,
    status,
    startShareScreen,
    startRecording: startRecord,
    stopRecording: stopRecord,
    mediaBlobUrl,
    clearBlobUrl
  } = useReactMediaRecorder({
    screen: false,
    audio: true,
    video: false,
    countDownInSec: maxTime
  });

  const { formattedTime, playAudio, isPlaying, stopAudio } = useAudioPlayer(file);

  useEffect(() => {
    (async () => {
      if (mediaBlobUrl) {
        const blob = await (await fetch(mediaBlobUrl as string)).blob();
        const asFile = new File([blob.slice(0, blob.size, MIME_TYPE)], fileName, {
          type: MIME_TYPE
        });
        onFinish(asFile);
      }
    })();
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (error) {
      console.error("audio recording is not supported", error);
      notifyMessages([{ message: `Error: ${error}` }]);
      onCloseSession();
    }
  }, [error]);

  useEffect(() => {
    if (status === StatusMessages.shareScreenActivated) {
      if (onStartRecordEvent) {
        onStartRecordEvent();
      }
      startRecord();
      setRecordExecuted(true);
    } else if (status === StatusMessages.shareScreenDeactivatedBeforeRecord) {
      onCloseSession();
    } else if (status === StatusMessages.stopped) {
      setRecordExecuted(false);
    }
  }, [status]);

  const onStartRecord = () => {
    setFileName(`Recording ${new Date().toISOString()}`);
    setRecordExecuted(false);
    startShareScreen();
  };

  const MIME_TYPE = "audio/wav";

  const onCloseSession = () => {
    clearBlobUrl();
  };

  const onRecord = () => {
    if (recordExecuted) {
      if (onStopRecordEvent) {
        onStopRecordEvent();
      }

      stopRecord();
      setRecordExecuted(false);
    } else {
      clearBlobUrl();
      onStartRecord();
      if (cleanFile) {
        cleanFile();
      }
    }
  };
  const getButtonLabel = () => {
    if (recordExecuted && mediaBlobUrl) {
      return intl.formatMessage(Messages.recordingButtonLabel);
    }
    if (recordExecuted) {
      return intl.formatMessage(Messages.stopButtonLabel);
    }
    if (fileName) {
      return intl.formatMessage(Messages.recordAgainButtonLabel);
    }
    return intl.formatMessage(Messages.startRecordingButtonLabel);
  };

  return (
    <BackgroundFlexColumn gap="17px" align="center" justify="space-between" padding="52px">
      <ConditionalRender condition={!!file}>
        <H1_FlexRow gap="12px" align="center">
          <PlayButton
            isIconOnly
            startContent={<i className={`fa-solid fa-circle-${isPlaying ? "stop" : "play"}`} />}
            onClick={() => (isPlaying ? stopAudio() : playAudio())}
          />
          <H1_Icon icon="fa-solid fa-waveform-lines" color={theme.gray9} />
          <H1_TextSmall color={theme.gray10}>{formattedTime}</H1_TextSmall>
          <H1_Icon icon="fa-solid fa-volume" color={theme.gray10} />
        </H1_FlexRow>
      </ConditionalRender>

      <ConditionalRender condition={!file}>
        <H1_TextHeadline color={isPlaying ? theme.blue4 : theme.gray7}>
          {recordExecuted ? counterDownTimer : recordTimer}
        </H1_TextHeadline>
      </ConditionalRender>

      <H1_TextXs color={theme.gray7}>{description}</H1_TextXs>
      <RecordButton color="primary" onClick={onRecord}>
        <H1_FlexColumn gap="1px">
          <i className="fa-solid fa-microphone" />
          <H1_TextXs color="white">{getButtonLabel()}</H1_TextXs>
        </H1_FlexColumn>
      </RecordButton>
    </BackgroundFlexColumn>
  );
};

export default AudioRecorder;
