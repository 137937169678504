import styled from "styled-components";
import { Input } from "antd";
import { ReactComponent as LinkIcon } from "app/assets/editor/linkIcon.svg";
import useCopy from "app/hooks/useCopy";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { exportVideoDrawerMessages } from "app/components/editor/sideDrawers/messages";
import { useIntl } from "react-intl";

// region [[ Styles ]]

const SharePanelView = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShareInput = styled(Input)`
  color: #8c8c8c;
`;

const Title = styled.div``;

const TitlePart = styled.div`
  margin-bottom: 5px;
`;

// endregion [[ Styles ]]

// region [[ Props ]]

export interface SharePanelProps {
  url: string;
  draftId?: string;
  reqId?: string;
}

const SharePanel = ({ url, draftId, reqId }: SharePanelProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { triggerCopy, CopyToolTip } = useCopy();

  const onShare = () => {
    triggerCopy({ copyContent: url });
    dispatch(
      googleEvents.shareVideoCopy({ source: "drawer", draftId, req_id: reqId, publish: false })
    );
  };
  return (
    <SharePanelView>
      <TitlePart>
        <Title>{formatMessage(exportVideoDrawerMessages.exportShareTitle)}</Title>
      </TitlePart>
      <CopyToolTip>
        <ShareInput prefix={<LinkIcon />} value={url} onClick={onShare} />
      </CopyToolTip>
    </SharePanelView>
  );
};
export default SharePanel;
