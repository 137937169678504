import { useWindowSize } from "@react-hook/window-size";
import { MOBILE_SCREEN, SMALL_SCREEN } from "app/config/Constants";

const useSmallScreen = () => {
  const [width] = useWindowSize();
  const isSmallScreen = width < SMALL_SCREEN;
  const isMobile = width < MOBILE_SCREEN;

  return { isSmallScreen, isMobile };
};

export default useSmallScreen;
