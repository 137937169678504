import { FetchStatus } from "app/types";
import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/briefs.thunk";
import { briefsAdapter } from "app/store/adapters/adapters";
import { fetchingStatus } from "app/utils/helpers";
import { Brief } from "app/types/brief";

interface BriefsSliceState {
  currentBrief: Brief;
  currentBriefs: Brief[];
  briefsStatus: FetchStatus;
  createBriefsStatus: FetchStatus;
  getBriefsStatus: FetchStatus;
  deleteBriefStatus: FetchStatus;
}

export const briefsSlice = createSlice({
  name: "Briefs",
  initialState: briefsAdapter.getInitialState<BriefsSliceState>({
    currentBrief: {},
    currentBriefs: [],
    briefsStatus: fetchingStatus.idle as FetchStatus,
    createBriefsStatus: fetchingStatus.idle as FetchStatus,
    getBriefsStatus: fetchingStatus.idle as FetchStatus,
    deleteBriefStatus: fetchingStatus.idle as FetchStatus
  }),
  reducers: {
    setCreateBriefsToIdle: (state) => {
      state.createBriefsStatus = fetchingStatus.idle as FetchStatus;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.createBriefsRequest.fulfilled, (state, action) => {
      state.currentBrief = action.payload;
      state.createBriefsStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.createBriefsRequest.pending, (state) => {
      state.createBriefsStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createBriefsRequest.rejected, (state) => {
      state.createBriefsStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getBriefsRequest.fulfilled, (state, action) => {
      state.currentBriefs = action.payload;
      state.getBriefsStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getBriefsRequest.pending, (state) => {
      state.getBriefsStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getBriefsRequest.rejected, (state) => {
      state.getBriefsStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteBriefRequest.fulfilled, (state) => {
      state.deleteBriefStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteBriefRequest.pending, (state) => {
      state.deleteBriefStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteBriefRequest.rejected, (state) => {
      state.deleteBriefStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});
export default briefsSlice.reducer;

export const briefsActions = {
  createBriefsRequest: asyncThunks.createBriefsRequest,
  getBriefsRequest: asyncThunks.getBriefsRequest,
  deleteBriefsRequest: asyncThunks.deleteBriefRequest,
  ...briefsSlice.actions
};
