import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import "app/components/common/AudioPlayer.scss";
import { Button, Popover } from "antd";
import { useAppSelector } from "app/hooks";
import { isMediaPlaying } from "app/utils/helpers";

// eslint-disable-next-line no-shadow
enum PLAYER_STATES {
  loading = "loading",
  playing = "playing",
  canPlay = "canPlay",
  paused = "paused",
  stoped = "stoped",
  ended = "ended"
}

export interface AudioPlayerProps {
  audioFile: string;
  isFetchingAudio: boolean;
  autoPlay?: boolean;
  fetchAudio?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onLoading?: () => void;
  canPlay?: () => void;
}

// eslint-disable-next-line react/display-name
const AudioPlayer = React.forwardRef(
  (
    {
      audioFile,
      isFetchingAudio,
      fetchAudio,
      onPlay,
      onPause,
      autoPlay = true,
      onLoading,
      canPlay
    }: AudioPlayerProps,
    ref
  ) => {
    const durationContainer = useRef<HTMLSpanElement>(null);
    const seekSliderRef = useRef<HTMLInputElement>(null);
    const currentTimeContainerRef = useRef<HTMLSpanElement>(null);
    const playerContainerRef = useRef<HTMLDivElement>(null);
    const volumeRef = useRef<HTMLInputElement>(null);
    const volumeContainerRef = useRef<HTMLDivElement>(null);
    const componentPlayerRef =
      (ref as MutableRefObject<HTMLAudioElement>) || useRef<HTMLAudioElement>(null);
    const animRaf = useRef<number>();
    const [playerState, setPlayerState] = useState<null | PLAYER_STATES>(null);
    const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);
    const { sceneId } = footerAudioPlayer;

    useEffect(() => {
      if (componentPlayerRef.current && seekSliderRef.current) {
        setListinerAfterMountDepndence();
      }
      return unsetListinersOnAudio;
    }, [componentPlayerRef.current, seekSliderRef.current, sceneId, footerAudioPlayer]);

    const audioAndSeekerCurrent = componentPlayerRef.current && seekSliderRef.current;

    const displayBufferedAmount = () => {
      if (!playerContainerRef.current) return;
      try {
        if (!audioAndSeekerCurrent) return;
        const bufferedAmount = Math.floor(
          componentPlayerRef?.current?.buffered?.end(
            componentPlayerRef?.current?.buffered?.length - 1
          ) || 0
        );
        playerContainerRef.current.style.setProperty(
          "--buffered-width",
          `${(bufferedAmount / Number(seekSliderRef.current.max)) * 100}%`
        );
      } catch (err) {
        playerContainerRef.current.style.setProperty("--buffered-width", "0%");
      }
    };

    const playEventListiner = () => {
      if (!audioAndSeekerCurrent) return;
      if (!componentPlayerRef.current) return;
      if (seekSliderRef && seekSliderRef.current) {
        seekSliderRef.current.value = Math.floor(componentPlayerRef.current.currentTime).toString();
        seekSliderRef.current.classList.add("active");
      }
      setPlayerState(PLAYER_STATES.playing);
      requestAnimationFrame(whilePlaying);
      if (onPlay) {
        onPlay();
      }
    };

    const pauseEventListiner = () => {
      if (!audioAndSeekerCurrent) return;
      if (!componentPlayerRef.current) return;
      if (seekSliderRef && seekSliderRef.current) {
        seekSliderRef.current.classList.remove("active");
        seekSliderRef.current.value = Math.floor(componentPlayerRef.current.currentTime).toString();
      }
      setPlayerState(PLAYER_STATES.paused);
      if (onPause) {
        onPause();
      }
    };

    const endEventListiner = () => {
      if (!audioAndSeekerCurrent) return;
      if (!componentPlayerRef.current) return;
      seekSliderRef.current.classList.remove("active");
      setPlayerState(PLAYER_STATES.ended);
      componentPlayerRef.current.currentTime = 0;
    };

    const loadStartEventListiner = () => {
      setPlayerState(PLAYER_STATES.loading);
      if (onLoading) {
        onLoading();
      }
    };

    const loadedMetadataEventListiner = () => {
      if (!audioAndSeekerCurrent) return;
      if (!componentPlayerRef.current) return;
      setPlayerState(PLAYER_STATES.canPlay);
      displayDuration(componentPlayerRef.current.duration);
      setSliderMax();
      displayBufferedAmount();
    };

    const durationChangeEventListiner = () => {
      if (!audioAndSeekerCurrent) return;
      if (!componentPlayerRef.current) return;
      displayDuration(componentPlayerRef.current.duration);
    };
    const onCanPlay = () => {
      if (canPlay) {
        canPlay();
      }
    };
    const unsetListinersOnAudio = () => {
      const audio = componentPlayerRef.current;
      if (!audio) return;
      audio.removeEventListener("play", playEventListiner);
      audio.removeEventListener("canplay", onCanPlay);
      audio.removeEventListener("pause", pauseEventListiner);
      audio.removeEventListener("ended", endEventListiner);
      audio.removeEventListener("loadedmetadata", loadedMetadataEventListiner);
      audio.removeEventListener("loadstart", loadStartEventListiner);
      audio.removeEventListener("durationchange", durationChangeEventListiner);
      audio.removeEventListener("progress", displayBufferedAmount);
    };

    const setListinerAfterMountDepndence = () => {
      const audio = componentPlayerRef.current;
      if (!audio) return;

      audio.addEventListener("play", playEventListiner);
      audio.addEventListener("canplay", onCanPlay);
      audio.addEventListener("pause", pauseEventListiner);
      audio.addEventListener("ended", endEventListiner);
      audio.addEventListener("loadedmetadata", loadedMetadataEventListiner);
      audio.addEventListener("loadstart", loadStartEventListiner);
      audio.addEventListener("durationchange", durationChangeEventListiner);
      audio.addEventListener("progress", displayBufferedAmount);
    };

    const showRangeProgress = (rangeInput: HTMLInputElement) => {
      if (rangeInput === seekSliderRef.current) {
        if (playerState === null || playerContainerRef.current == null) return;
        playerContainerRef.current.style.setProperty(
          "--seek-before-width",
          `${(Number(rangeInput.value) / Number(rangeInput.max)) * 100}%`
        );
      } else {
        if (volumeContainerRef.current === null) return;
        volumeContainerRef.current.style.setProperty(
          "--volume-before-width",
          `${(Number(rangeInput.value) / Number(rangeInput.max)) * 100}%`
        );
      }
    };

    const calculateTime = (secs: number) => {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    };

    const setSliderMax = () => {
      const audio = componentPlayerRef.current;
      const seekSlider = seekSliderRef.current;
      if (!audio || !seekSlider) return;
      seekSlider.max = Math.floor(audio.duration).toString();
    };

    const displayDuration = (duration: number) => {
      if (!durationContainer.current) return;
      durationContainer.current.textContent = calculateTime(duration);
    };

    const handlePlay = () => {
      const player = componentPlayerRef.current;
      if (!player) return;

      switch (playerState) {
        case PLAYER_STATES.playing:
          if (isMediaPlaying(player)) {
            player.pause();
            cancelAnimationFrame(animRaf.current as number);
          }
          break;
        case PLAYER_STATES.paused:
        case PLAYER_STATES.ended:
        case PLAYER_STATES.canPlay:
          player.play();
          requestAnimationFrame(whilePlaying);
          break;
        default:
          break;
      }
    };

    const renderActionButton = () => {
      if (!audioFile) {
        return (
          <Button
            id="generate-icon"
            icon={
              isFetchingAudio ? (
                <i className="fas fa-spinner fa-spin" />
              ) : (
                <i className="fas fa-play" />
              )
            }
            disabled={isFetchingAudio}
            onClick={fetchAudio}
          />
        );
      }

      return (
        <Button
          id="play-icon"
          icon={
            // eslint-disable-next-line no-nested-ternary
            componentPlayerRef.current?.readyState === 0 ? (
              <i className="fas fa-spinner fa-spin" />
            ) : playerState !== PLAYER_STATES.playing ? (
              <i className="fas fa-play" />
            ) : (
              <i className="fas fa-pause" />
            )
          }
          onClick={handlePlay}
        />
      );
    };

    const whilePlaying = () => {
      const audio = componentPlayerRef.current;
      const seekSlider = seekSliderRef.current;
      const currentTimeContainer = currentTimeContainerRef.current;
      const audioPlayerContainer = playerContainerRef.current;
      if (!audio || !seekSlider || !currentTimeContainer || !audioPlayerContainer) return;

      seekSlider.value = Math.floor(audio.currentTime).toString();
      currentTimeContainer.textContent = calculateTime(Number(seekSlider.value));
      audioPlayerContainer.style.setProperty(
        "--seek-before-width",
        `${(Number(seekSlider.value) / Number(seekSlider.max)) * 100}%`
      );
      animRaf.current = requestAnimationFrame(whilePlaying);
    };

    const renderSoundBar = () => (
      <div ref={volumeContainerRef} className="audio-player-volume-container">
        <input
          type="range"
          max="100"
          ref={volumeRef}
          className="audio-player volume-slider vertical"
          onInput={(e) => {
            const audio = componentPlayerRef.current;
            if (!audio) return;

            audio.volume = Number((e.target as HTMLInputElement).value) / 100;
            showRangeProgress(e.target as HTMLInputElement);
          }}
        />
      </div>
    );

    const mute = () => {
      if (!componentPlayerRef.current) return;
      componentPlayerRef.current.muted = !componentPlayerRef.current.muted;
    };

    return (
      <div className="audio-player-container" ref={playerContainerRef}>
        <audio
          preload="metadata"
          ref={ref as MutableRefObject<HTMLAudioElement>}
          style={{ display: "none" }}
          autoPlay={autoPlay}
          src={audioFile as string}
        >
          <track kind="captions" />
        </audio>
        <div className="player-actions">{renderActionButton()}</div>
        <div className="player-slider">
          <span className="current-time time" ref={currentTimeContainerRef}>
            0:00
          </span>
          <input
            type="range"
            id="seek-slider"
            max="100"
            value="0"
            className="audio-player"
            ref={seekSliderRef}
            onInput={(e) => {
              if (!playerState || !currentTimeContainerRef.current) return;
              showRangeProgress(e.target as HTMLInputElement);
              currentTimeContainerRef.current.textContent = calculateTime(
                Number((e.target as HTMLInputElement).value)
              );
              if (playerState !== PLAYER_STATES.paused) {
                cancelAnimationFrame(animRaf.current as number);
              }
            }}
            onChange={(e) => {
              if (!componentPlayerRef.current) return;
              componentPlayerRef.current.currentTime = Number(e.target.value);
              if (playerState !== PLAYER_STATES.paused) {
                requestAnimationFrame(whilePlaying);
              }
            }}
          />
          <span className="time duration" ref={durationContainer}>
            0:00
          </span>
        </div>
        <div className="player-sound">
          {componentPlayerRef.current ? (
            <Popover
              content={renderSoundBar}
              overlayClassName="vertical-popover-volume"
              placement="top"
            >
              <Button
                disabled={!componentPlayerRef.current}
                icon={
                  componentPlayerRef.current.muted ? (
                    <i className="fal fa-volume-mute" />
                  ) : (
                    <i className="far fa-volume" />
                  )
                }
                onClick={mute}
              />
            </Popover>
          ) : (
            <Button disabled={!componentPlayerRef.current} icon={<i className="far fa-volume" />} />
          )}
        </div>
      </div>
    );
  }
);

export default AudioPlayer;
