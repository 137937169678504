import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store/store";
import { defaultVoiceFilter, VoiceAll, VoiceFilters, VoiceFiltersProperties } from "app/types";
import { shallowEqual } from "react-redux";
import { voicesActions } from "app/store/slices/voices.slice";
import OutsideClickHandler from "react-outside-click-handler";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useIntl } from "react-intl";
import { voiceDrawerFilterMessages } from "app/components/editor/sideDrawers/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button, Checkbox, Selection } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import NextSelect from "app/components/common/NextUI/Select/NextSelect";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import React from "react";
import { getUniqueDropdownValuesByPropertiesNextUI } from "app/store/selectorsV2/voices.selectors";

const UnderlineFlexRow = styled(H1_FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.gray3};
`;

const UnderlineButton = styled(Button)`
  && {
    background-color: transparent;
  }
  &:hover {
    text-decoration: underline;
  }
  font-size: 12px;
  color: ${({ theme }) => theme.gray11};
`;
const TopFlexRow = styled(H1_FlexRow)`
  top: 40px;
  right: 0;
`;
const FiltersFlexColumn = styled(H1_FlexColumn)<{ $open: boolean }>`
  top: 10px;
  left: 0;
  z-index: 603;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  border: ${({ $open, theme }) => ($open ? `1px solid ${theme.gray3}` : "none")};
  border: ${({ $open, theme }) => ($open ? `1px solid ${theme.gray3}` : "none")};
  border-radius: 6px;
  max-height: ${({ $open }) => ($open ? "500px" : "0")};
  padding: ${({ $open }) => ($open ? "20px" : "0")};
  transition-duration: 0.3s, 0.3s, 0s;
  transition-delay: 0s, 0s, ${({ $open }) => ($open ? "0" : "0.1s")};
  transition-property: height, max-height, padding;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275),
    cubic-bezier(0.175, 0.885, 0.32, 1.275), cubic-bezier(0.175, 0.885, 0.32, 1.275);

  overflow: hidden;
`;

const StyledSelect = styled(NextSelect)`
  &&& button {
    background-color: ${({ theme }) => theme.gray3};
    &:hover {
      background-color: ${({ theme }) => theme.gray4};
    }
  }
`;

const propertiesMemoArray = [
  VoiceFiltersProperties.age,
  VoiceFiltersProperties.tone,
  VoiceFiltersProperties.useCase
];

interface VoiceDrawerFiltersProps {
  isFiltersVisible: boolean;
  onClose: () => void;
}
const VoiceDrawerFilters = ({ onClose, isFiltersVisible }: VoiceDrawerFiltersProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const filters: Partial<VoiceFilters> = useAppSelector((state: RootState) => state.voices.filters);
  const allUniqueOptions = useAppSelector(
    (state) => getUniqueDropdownValuesByPropertiesNextUI(state, propertiesMemoArray, "All"),
    shallowEqual
  );
  const ageAllOptions = allUniqueOptions[VoiceFiltersProperties.age];
  const toneAllOptions = allUniqueOptions[VoiceFiltersProperties.tone];
  const useCaseAllOptions = allUniqueOptions[VoiceFiltersProperties.useCase];
  const useCaseFilter = filters.useCase || VoiceAll;
  const toneFilter = filters.tone || VoiceAll;
  const ageFilter = filters.age || VoiceAll;
  const pauseFilter = filters.pause;

  const onAgeChange = (value: Selection) => {
    const selection = Array.from(value)[0] as string;
    dispatch(analyticsEvents.changeVoicesFilters({ value: selection, filter: "age" }));
    dispatch(
      voicesActions.setFilters({
        age: selection === ageFilter || !selection ? VoiceAll : selection
      })
    );
  };

  const onPauseChange = (selection: boolean) => {
    dispatch(analyticsEvents.changeVoicesFilters({ value: selection, filter: "pause" }));
    dispatch(
      voicesActions.setFilters({
        pause: selection
      })
    );
  };

  const onToneChange = (value: Selection) => {
    const selection = Array.from(value)[0] as string;
    dispatch(analyticsEvents.changeVoicesFilters({ value: selection, filter: "tone" }));
    dispatch(
      voicesActions.setFilters({
        tone: selection === toneFilter || !selection ? VoiceAll : selection
      })
    );
  };

  const onUseCaseChange = (value: Selection) => {
    const selection = Array.from(value)[0] as string;
    dispatch(analyticsEvents.changeVoicesFilters({ value: selection, filter: "useCase" }));
    dispatch(
      voicesActions.setFilters({
        useCase: selection === useCaseFilter || !selection ? VoiceAll : selection
      })
    );
  };

  const onResetAll = () => {
    dispatch(analyticsEvents.changeVoicesFilters({ value: undefined, filter: "reset" }));
    dispatch(
      voicesActions.setFilters({
        age: defaultVoiceFilter.age,
        tone: defaultVoiceFilter.tone,
        useCase: defaultVoiceFilter.useCase
      })
    );
  };

  const onCloseSliders = () => {
    if (isFiltersVisible) {
      onClose();
    }
  };

  return (
    <TopFlexRow position="absolute" width="100%">
      <OutsideClickHandler onOutsideClick={onCloseSliders}>
        <FiltersFlexColumn
          $open={isFiltersVisible}
          gap="14px"
          position="absolute"
          width="100%"
          padding="0 0 20px 0"
        >
          <UnderlineFlexRow justify="space-between" align="center" padding="7px 0 6px 0">
            <H1_TextXs color={theme.gray7}>
              {formatMessage(voiceDrawerFilterMessages.filter)}
            </H1_TextXs>
            <UnderlineButton size="sm" variant="light" onClick={onResetAll}>
              {formatMessage(voiceDrawerFilterMessages.resetAll)}
            </UnderlineButton>
          </UnderlineFlexRow>
          <H1_FlexRow width="100%" align="center">
            <H1_FlexRow width="calc(100% - 160px)">
              <H1_TextXs>{formatMessage(voiceDrawerFilterMessages.age)}</H1_TextXs>
            </H1_FlexRow>
            <H1_FlexRow width="155px">
              <StyledSelect
                labelPlacement="outside"
                size="sm"
                selectedKeys={[ageFilter]}
                onSelectionChange={onAgeChange}
                selectorIcon={<H1_Icon color={theme.gray11} icon="far fa-chevron-down" />}
                items={ageAllOptions}
              />
            </H1_FlexRow>
          </H1_FlexRow>
          <H1_FlexRow width="100%" align="center">
            <H1_FlexRow width="calc(100% - 160px)">
              <H1_TextXs>{formatMessage(voiceDrawerFilterMessages.tone)}</H1_TextXs>
            </H1_FlexRow>
            <H1_FlexRow width="155px">
              <StyledSelect
                labelPlacement="outside"
                size="sm"
                selectedKeys={[toneFilter]}
                onSelectionChange={onToneChange}
                selectorIcon={<H1_Icon color={theme.gray11} icon="far fa-chevron-down" />}
                items={toneAllOptions}
              />
            </H1_FlexRow>
          </H1_FlexRow>
          <ConditionalRender condition={false}>
            <H1_FlexRow width="100%" align="center">
              <H1_FlexRow width="calc(100% - 160px)">
                <H1_TextXs>{formatMessage(voiceDrawerFilterMessages.useCase)}</H1_TextXs>
              </H1_FlexRow>
              <H1_FlexRow width="155px">
                <StyledSelect
                  labelPlacement="outside"
                  size="sm"
                  selectedKeys={[useCaseFilter]}
                  onSelectionChange={onUseCaseChange}
                  selectorIcon={<H1_Icon color={theme.gray11} icon="far fa-chevron-down" />}
                  items={useCaseAllOptions}
                />
              </H1_FlexRow>
            </H1_FlexRow>
          </ConditionalRender>
          <H1_FlexRow width="100%" align="center">
            <H1_FlexRow width="calc(100% - 160px)">
              <H1_TextXs>{formatMessage(voiceDrawerFilterMessages.pause)}</H1_TextXs>
            </H1_FlexRow>
            <H1_FlexRow width="155px">
              <Checkbox isSelected={pauseFilter} onValueChange={onPauseChange} />
            </H1_FlexRow>
          </H1_FlexRow>
        </FiltersFlexColumn>
      </OutsideClickHandler>
    </TopFlexRow>
  );
};

export default VoiceDrawerFilters;
