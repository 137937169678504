import { defineMessages } from "react-intl";

// Crete New Video Prompt
export const createNewVideoPromptMessages = defineMessages({
  noVideosTitle: {
    id: `videos.create-new-video-prompt.no-data-title`,
    defaultMessage: "Create your first video now!"
  },
  newVideoTitle: {
    id: `videos.create-new-video-prompt.empty-data-title`,
    defaultMessage: "Create another video now!"
  },

  createVideoLabel: {
    id: `videos.create-new-video-prompt.create-video-label`,
    defaultMessage: "Start Now"
  }
});

// Draft Page Action
export const draftPageActionMessages = defineMessages({
  createVideoTooltip: {
    id: `videos.draft-page-action.create-video-tooltip`,
    defaultMessage: `Create videos for selected rows`
  },
  createVideoLabel: {
    id: `videos.draft-page-action.create-video-label`,
    defaultMessage: `Create Video`
  },
  publishSelectedTooltip: {
    id: `videos.draft-page-action.publish-selection-tooltip`,
    defaultMessage: `Approve selected videos`
  },
  downloadSelectedTooltip: {
    id: `videos.draft-page-action.download-selected-tooltip`,
    defaultMessage: "Download"
  },
  shareSelectedTooltip: {
    id: `videos.draft-page-action.share-selected-tooltip`,
    defaultMessage: "Share"
  },
  deleteVideoTooltip: {
    id: `videos.draft-page-action.delete.tooltip`,
    defaultMessage: `Delete selected videos`
  },
  openVideoDetailsDrawerTooltip: {
    id: `videos.draft-page-action.details.tooltip`,
    defaultMessage: "View details"
  },
  deleteVideoWarning: {
    id: `videos.draft-page-action.delete.message`,
    defaultMessage: `Are you sure you want to delete selected videos?`
  },
  deleteVideoWarningTitle: {
    id: `videos.draft-page-action.delete.title`,
    defaultMessage: "Delete video"
  },
  downloadVideosBtn: {
    id: `videos.draft-page-action.download-videos-button`,
    defaultMessage: "Download"
  },
  downloadVideoBtn: {
    id: `videos.draft-page-action.download-video-button`,
    defaultMessage: "Download"
  },
  deleteVideoBtn: {
    id: `videos.draft-page-action.delete-video-button`,
    defaultMessage: "Delete"
  },
  deleteVideosBtn: {
    id: `videos.draft-page-action.delete-videos-button`,
    defaultMessage: "Delete"
  },
  deleteVideoConfirmTitle: {
    id: `videos.draft-page-action.delete-video-confirm-title`,
    defaultMessage: "Delete video"
  },
  moveVideoBtn: {
    id: `videos.draft-page-action.move-videos-button`,
    defaultMessage: "Move"
  },
  deleteVideosConfirmTitle: {
    id: `videos.draft-page-action.delete-videos-confirm-title`,
    defaultMessage: "Delete videos"
  },
  deleteVideoConfirmContent: {
    id: `videos.draft-page-action.delete-video-confirm-content`,
    defaultMessage: "You are about to delete a video. Are you sure you want to proceed?"
  },
  deleteVideosConfirmContent: {
    id: `videos.draft-page-action.delete-videos-confirm-content`,
    defaultMessage: "You are about to delete {number} videos. Are you sure you want to proceed?"
  },
  downloadVideoWarningTitle: {
    id: `videos.draft-page-action.download-video-warning-title`,
    defaultMessage: "Download video"
  },
  downloadVideosWarningTitle: {
    id: `videos.draft-page-action.download-videos-warning-title`,
    defaultMessage: "Download videos"
  },
  downloadVideosWarningContent: {
    id: `videos.draft-page-action.download-videos-warning-content`,
    defaultMessage: "You cannot download more than 6 videos"
  },
  cancel: {
    id: `videos.draft-page-action.cancel`,
    defaultMessage: "Cancel"
  },
  ok: {
    id: `videos.draft-page-action.ok`,
    defaultMessage: "Ok"
  }
});

// Draft Page
export const draftPageMessages = defineMessages({
  folderHeadline: {
    id: `videos.draft-page.headline`,
    defaultMessage: `Projects`
  },
  breadcrumbFolderParent: {
    id: `videos.draft-page.breadcrumb-folder-parent`,
    defaultMessage: `Projects > `
  }
});

// Expanded Table
export const expandedTableMessages = defineMessages({
  templateFieldsTitle: {
    id: `videos.expanded-table.column.template-fields`,
    defaultMessage: "Presenter & Voice"
  },
  versionTitle: {
    id: `videos.expanded-table.column.version`,
    defaultMessage: "Version"
  },
  renderTitleLabel: {
    id: `videos.expanded-table.render`,
    defaultMessage: "Render"
  }
});

// Folder Content Table
export const folderContentTableMessages = defineMessages({
  title: {
    id: `videos.folder-content-table.title`,
    defaultMessage: `Video Name`
  },
  table: {
    id: `videos.folder-content-table.table`,
    defaultMessage: `Table`
  },
  grid: {
    id: `videos.folder-content-table.grid`,
    defaultMessage: `Grid`
  },
  createPlaylist: {
    id: `videos.folder-content-table.create-playlist`,
    defaultMessage: `Create playlist`
  },
  lastUpdated: {
    id: `videos.folder-content-table.last-updated`,
    defaultMessage: `Last Updated`
  },
  dateCreated: {
    id: `videos.folder-content-table.date-created`,
    defaultMessage: `Date Created`
  },
  version: {
    id: `videos.folder-content-table.version`,
    defaultMessage: `Version`
  },
  createDraftTooltip: {
    id: `videos.folder-content-table.create-video-tooltip`,
    defaultMessage: `Create Video`
  },
  listViewTooltip: {
    id: `videos.folder-content-table.list-view-tooltip`,
    defaultMessage: "List View"
  },
  gridViewTooltip: {
    id: `videos.folder-content-table.grid-view-tooltip`,
    defaultMessage: "Grid View"
  },
  moveToFolder: {
    id: `videos.folder-content-table.move-to-folder`,
    defaultMessage: `Move To Project`
  }
});

// New Video Button
export const newVideoButtonMessages = defineMessages({
  actionLabelNewVideo: {
    id: `videos.new-video-button.new`,
    defaultMessage: "New Video"
  },
  actionLabelToEditor: {
    id: `videos.new-video-button.go-edit`,
    defaultMessage: "Go to editor"
  }
});

// useColumns
export const useColumnsMessages = defineMessages({
  actionLabelNewVideo: {
    id: `videos.use-columns.new`,
    defaultMessage: "New Video"
  },
  templateFieldsTitle: {
    id: `videos.use-columns.template-fields`,
    defaultMessage: "Presenter & Voice"
  },
  nameTitle: {
    id: `videos.use-columns.display-name`,
    defaultMessage: "Video name"
  },
  toEditorButton: {
    id: `videos.use-columns.editor-button`,
    defaultMessage: "Go to editor"
  },
  editTitle: {
    id: `videos.use-columns.actions`,
    defaultMessage: "Edit"
  },
  statusTitle: {
    id: `videos.use-columns.status`,
    defaultMessage: "Status"
  },
  reviewTitle: {
    id: `videos.use-columns.review`,
    defaultMessage: "Review"
  },
  rowIdxTitle: {
    id: `videos.use-columns.row-index`,
    defaultMessage: "#"
  },
  versionTitle: {
    id: `videos.use-columns.version`,
    defaultMessage: "Version"
  }
});

// Authenticator Router
export const projectsMessages = defineMessages({
  headline: {
    id: `projects.headline`,
    defaultMessage: `My Projects`
  },
  specificProject: {
    id: `projects.project.headline`,
    defaultMessage: `My Project`
  }
});
