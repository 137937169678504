import { FeatureFlag } from "app/types";
import styled from "styled-components";
import ApiKeysCard from "app/pages/profile/ApiKeysCard";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import SubscriptionCard from "app/pages/profile/SubscriptionCard";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import GeneralInfoCard from "app/pages/profile/GeneralInfoCard";
import CreditsCard from "app/pages/profile/CreditsCard";
import useCredits from "app/hooks/useCredits";
import { useAppDispatch } from "app/hooks";
import { paymentsActions } from "app/store/slices/payments.slice";
import SecurityCard from "app/pages/profile/SecurityCard";
import { useAuth } from "app/auth/useAuth";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (ThemeMode.Light === theme.mode ? theme.gray1 : theme.gray12)};
`;

const MaxWidthFlexColumn = styled(H1_FlexColumn)`
  max-width: 746px;
`;

const DimmedText = styled(H1_TextSubtitle)`
  width: 60%;
  margin-top: 10px;
`;

const Profile = () => {
  const flags = useFlags();
  const { llmEnable } = useCredits();
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const hasAuth0Identifier = user?.sub?.includes("|") ?? false;
  const isUserWorkOs = !hasAuth0Identifier;

  useEffect(() => {
    dispatch(paymentsActions.getCreditsRequest());
  }, []);

  return (
    <BackgroundFlexColumn width="100%" height="100%" padding="0 0 20px 51px">
      <H1_FlexColumn overflow="auto" flex="1 1 auto" width="100%">
        <MaxWidthFlexColumn width="100%" gap="53px">
          <GeneralInfoCard />
          <ConditionalRender condition={flags[FeatureFlag.subscriptionCard]}>
            <SubscriptionCard />
          </ConditionalRender>
          <ConditionalRender condition={llmEnable}>
            <CreditsCard />
          </ConditionalRender>
          <ApiKeysCard />
          <ConditionalRender condition={flags[FeatureFlag.securityCard] && isUserWorkOs}>
            <SecurityCard />
          </ConditionalRender>
          {import.meta.env.REACT_APP_VERSION && (
            <DimmedText textAlign="left" color="grey" fontSize="inherit">
              App version: {import.meta.env.REACT_APP_VERSION}
            </DimmedText>
          )}
        </MaxWidthFlexColumn>
      </H1_FlexColumn>
    </BackgroundFlexColumn>
  );
};

export default Profile;
