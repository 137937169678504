import { leftNavMessages } from "app/components/LeftNav/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import React, { MouseEventHandler, ReactNode } from "react";
import usePermissions from "app/hooks/usePermissions";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import styled, { useTheme } from "styled-components";
import IconButton from "app/components/common/Icons/IconButton";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/upgrade-crown.svg";
import { useIntl } from "react-intl";
import { SMALL_SCREEN_PX } from "app/config/Constants";

const BorderedIconButton = styled(IconButton)`
  align-items: center;
  justify-content: center;
  border: 1px solid var(--orange-05, #ffa940);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  flex: 0 0 auto;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      display: none;
    }
  }
`;

const StyledUpgradeIcon = styled(UpgradeIcon)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  flex: 0 0 16px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    margin-right: 0;
  }
`;

export interface UpgradeButtonProps<T> {
  source: string;
  onClick?: MouseEventHandler<T>;
  text?: string | ReactNode;
  disabled?: boolean;
}

const UpgradeButton = ({ source, onClick, text, disabled }: UpgradeButtonProps<Element>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isAllowToUpgrade } = usePermissions();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const onUpgrade = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e);
    } else {
      dispatch(analyticsEvents.productUpgradeCta({ cta: source }));
      navigate(`/pricing?${location.search}`);
    }
  };

  return (
    <ConditionalRender condition={isAllowToUpgrade}>
      <BorderedIconButton
        shrink
        onClick={onUpgrade}
        height="40px"
        color="var(--orange-05, #ffa940)"
        backgroundColor={theme.gray1}
        icon={<StyledUpgradeIcon />}
        disabled={disabled}
      >
        <span>{text || formatMessage(leftNavMessages.upgradeButton)}</span>
      </BorderedIconButton>
    </ConditionalRender>
  );
};

export default UpgradeButton;
