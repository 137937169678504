import { useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { fetchingStatus } from "app/utils/helpers";
import useNotifications from "app/hooks/useNotifications";
import useErrors from "app/hooks/useErrors";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import * as Sentry from "@sentry/react";
import { MARKET_PLACE_KEYS } from "app/types/LocalStorageKeys";

const useMarketplace = () => {
  const { notifyMessages } = useNotifications();
  const { notifyError } = useErrors();
  const userConnectStatus = useAppSelector((state) => state.user.userConnectStatus);
  const marketPlaceFlow = useAppSelector((state) => state.user.marketPlaceFlow);

  useEffect(() => {
    if (userConnectStatus === fetchingStatus.succeeded) {
      notifyMessages([
        {
          message: `User connected to ${marketPlaceFlow} marketplace successfully`
        }
      ]);
    } else if (userConnectStatus === fetchingStatus.failed) {
      notifyError({
        general: buildGeneralMessage(
          `Something went wrong`,
          `Failed to connect to ${marketPlaceFlow} marketplace, please try again or reach out support.`
        )
      });
    }
    if (
      userConnectStatus === fetchingStatus.failed ||
      userConnectStatus === fetchingStatus.succeeded
    ) {
      try {
        localStorage.removeItem(MARKET_PLACE_KEYS.marketplace_ref);
        localStorage.removeItem(MARKET_PLACE_KEYS.msMarketplace_ref);
      } catch (err) {
        Sentry.captureException(err, {
          extra: { description: "failed to remove marketplace_ref" }
        });
      }
    }
  }, [userConnectStatus]);
};

export default useMarketplace;
