import { paymentModalMessages } from "app/pages/pricing/messages";
import { ReactComponent as PoweredByStripeLogo } from "app/assets/pricing/powered-by-stripe-logo.wine.svg";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { useTheme } from "styled-components";

const PaymentModalLowerRow = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <H1_FlexRow align="center" justify="space-between" padding="10px 0 47px 49px">
      <H1_TextXs whiteSpace="break-spaces" fontSize="9px" color={theme.gray11} lineHeight="13px">
        {formatMessage(paymentModalMessages.iHaveRead, { br: <br /> }) as ReactNode}
      </H1_TextXs>
      <PoweredByStripeLogo />
    </H1_FlexRow>
  );
};

export default PaymentModalLowerRow;
