import styled, { css } from "styled-components";

const VideoWrapper = styled.div<{ $fullScreenMode: boolean; $visible: boolean }>`
  position: ${({ $fullScreenMode }) => ($fullScreenMode ? "fixed" : "relative")};
  top: 0;
  left: 0;
  width: ${({ $fullScreenMode, $visible }) => (!$visible ? 0 : $fullScreenMode ? "100vw" : "100%")};
  max-width: ${({ $fullScreenMode }) => ($fullScreenMode ? "auto" : "70%")};
  min-width: 70%;
  height: ${({ $fullScreenMode, $visible }) =>
    !$visible ? 0 : $fullScreenMode ? "100vh" : "auto"};
  aspect-ratio: 16 / 9;
  align-items: center;
  justify-content: center;
  background-color: ${({ $fullScreenMode }) => ($fullScreenMode ? "black" : "unset")};
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  z-index: 601; // Above floating zendesk button
  flex: ${({ $visible }) => ($visible ? 1 : 0)};
`;

const RoundedVideo = styled.video<{ $isRecording?: boolean }>`
  border-radius: 10px;
  aspect-ratio: 16 / 9;
  height: 100%;
  background: ${({ theme }) => theme.gray10};
  transition: border 0.3s ease;
  object-fit: cover;
  ${({ $isRecording }) =>
    !!$isRecording &&
    css`
      align-items: center;
      justify-content: center;
      border-radius: 0;
      position: absolute;
      z-index: 602; // Above video
    `};
`;

const styledComps = {
  VideoWrapper,
  RoundedVideo
};

export default styledComps;
