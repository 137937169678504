import { defineMessages } from "react-intl";

export const profileMessages = defineMessages({
  profileHeadline: {
    id: `profile.headline`,
    defaultMessage: "Profile"
  },
  title: {
    id: "profile.title",
    defaultMessage: "General"
  },
  disable: {
    id: "profile.security.disable",
    defaultMessage: "Disable"
  },
  enable: {
    id: "profile.security.enable",
    defaultMessage: "Enable"
  },
  nameTitle: {
    id: "profile.name-title",
    defaultMessage: "Name:"
  },
  nicknameTitle: {
    id: "profile.nickname-title",
    defaultMessage: "Nickname:"
  },
  email: {
    id: "profile.email",
    defaultMessage: "Email:"
  },
  minuteUsage: {
    id: "profile.minutes-usage",
    defaultMessage: "Video minutes usage"
  },
  aiCreditsTitle: {
    id: "profile.ai-credits-title",
    defaultMessage: "AI Wizard credits usage"
  },
  securityTitle: {
    id: "profile.security.title",
    defaultMessage: "Security"
  },
  security2FAUpgrade: {
    id: "profile.security.mfa-upgrade",
    defaultMessage: "Upgrade to enable multi-factor authentication"
  },
  security2FA: {
    id: "profile.security.mfa-title",
    defaultMessage: "2 Factor Authentication"
  },
  security2FAContent: {
    id: "profile.security.mfa-content",
    defaultMessage: "After entering your password, you'll be asked for a second verification step"
  },
  currentPlan: { id: "profile.current-plan", defaultMessage: "{plan} Plan ({period})" },
  unlimitedMinutes: {
    id: "profile.unlimited-minutes",
    defaultMessage: "Unlimited"
  },
  currentPlanMinutes: {
    id: "profile.current-plan-minutes",
    defaultMessage: "{number} minutes plan"
  },
  upgradeTextBtn: { id: "profile.upgrade-button", defaultMessage: "Upgrade" },
  minutesText: {
    id: "profile.minutes-text",
    defaultMessage: "{available} of {totalMinutes} minutes left ({percent}%)"
  },
  aiCreditsText: {
    id: "profile.ai-credits-text",
    defaultMessage: "{available} of {totalMinutes} AI Wizard credits left ({percent}%)"
  },
  profileDeleteModalTitle: {
    id: `profile.modal.delete.title`,
    defaultMessage: "Are you sure you want to delete your account?"
  },
  profileDeleteModalContent: {
    id: `profile.modal.delete.content`,
    defaultMessage:
      "Please be aware that this action will result in the removal of all data and videos linked to your account."
  },
  profileDeleteModalOkButton: {
    id: `profile.modal.delete.ok-button`,
    defaultMessage: "Delete Account"
  },
  profileDeleteModalContent2: {
    id: `profile.modal.delete.content2`,
    defaultMessage: "Please contact <a>Hour One Support</a> to cancel."
  },
  profileChangeImageButtonLabel: {
    id: `profile.form.image.change.button`,
    defaultMessage: "Click to change"
  },
  profileDeleteWarningText: {
    id: `profile.actions.delete.text`,
    defaultMessage: "Delete account will remove all your related projects and videos from Hour One"
  },
  profileDeleteWarningButton: {
    id: `profile.actions.delete.button.label`,
    defaultMessage: "Delete your account"
  },
  profileDeleteButton: {
    id: `profile.actions.delete.button`,
    defaultMessage: "Delete Account"
  },
  accountTitle: {
    id: `profile.account.title`,
    defaultMessage: "Account"
  },
  dateJoined: {
    id: `profile.account.date-joined`,
    defaultMessage: "Date Joined:"
  },
  userId: {
    id: `profile.account.user-id`,
    defaultMessage: "User id:"
  }
});

export const subscriptionMessages = defineMessages({
  title: { id: "profile.subscription.title", defaultMessage: "Subscriptions" },
  pricingPlans: { id: "profile.subscription.pricing-plan", defaultMessage: "Pricing Plan" },
  changeCreditCardButton: {
    id: "profile.subscription.change-credit-card-button",
    defaultMessage: "Update credit card"
  },
  cancelButton: { id: "profile.subscription.cancel-button", defaultMessage: "Cancel Subscription" },
  cancelAt: {
    id: "profile.subscription.cancel-at",
    defaultMessage: "Your subscription was canceled on the {time}"
  },
  subscriptionEnd: {
    id: "profile.subscription.subscription-end",
    defaultMessage: "Your subscription will end on the {time}"
  },
  subscriptionResume: {
    id: "profile.subscription.subscription-resume",
    defaultMessage: "In {time} days your {plan} plan subscription will resume"
  },
  defaultMinutes: {
    id: "profile.subscription.default-minutes",
    defaultMessage: "3 minutes a month"
  },
  cancelNotification: {
    id: "profile.subscription.cancel-notification",
    defaultMessage: "Subscription is canceled"
  },
  pausedNotification: {
    id: "profile.subscription.paused-notification",
    defaultMessage: "Subscription is paused"
  },
  refundedNotification: {
    id: "profile.subscription.refunded-notification",
    defaultMessage: "Subscription is refunded"
  },
  cancelScheduledNotification: {
    id: "profile.subscription.cancel-scheduled-notification",
    defaultMessage: "Subscription cancellation scheduled"
  },
  discountNotification: {
    id: "profile.subscription.discount-notification",
    defaultMessage: "Discount request sent"
  }
});
export const CreditsMessages = defineMessages({
  title: { id: "profile.credits.title", defaultMessage: "AI Wizard credits" }
});

export const apiKeyMessages = defineMessages({
  title: { id: "profile.api-keys.title", defaultMessage: "API keys" },
  regenerateButton: { id: "profile.api-keys.regenerate-btn", defaultMessage: "Regenerate" },
  createAlert: {
    id: "profile.api-keys.create-alert",
    defaultMessage:
      "Please note that the API key will be visible only once, please save it in a secure place."
  },
  generateButton: { id: "profile.api-keys.generate-btn", defaultMessage: "Generate" },
  generateModalTitle: {
    id: "profile.api-keys.generate-modal-title",
    defaultMessage: "Generate new API key"
  },
  generateModalDesc: {
    id: "profile.api-keys.generate-modal-description",
    defaultMessage: "Generating a new API key is revoking old one if exist. Are you sure?"
  },
  deleteModalTitle: {
    id: "profile.api-keys.delete-modal-title",
    defaultMessage: "Revoke API key?"
  },
  deleteModalDesc: {
    id: "profile.api-keys.delete-modal-description",
    defaultMessage: "Revoking an API key is irreversible. Are you sure?"
  },
  deleteModalWithSubscriptionTitle: {
    id: "profile.api-keys.delete-modal-with-subscription-title",
    defaultMessage: "Please Cancel Subscription Before Deleting Account"
  },
  deleteModalWithSubscriptionDesc: {
    id: "profile.api-keys.delete-modal-with-subscription-description",
    defaultMessage:
      "To ensure a smooth process as deleting your account does not end your paid subscription."
  },
  deleteModalWithSubscriptionButton: {
    id: "profile.api-keys.delete-modal-with-subscription-button",
    defaultMessage: "Cancel Subscription"
  },
  copyApiKey: {
    id: "profile.api-keys.copy-key-tooltip",
    defaultMessage: "Copy API key"
  },
  copyUserId: {
    id: "profile.api-keys.copy-user-id-tooltip",
    defaultMessage: "Copy user id"
  },
  revokeApiKey: {
    id: "profile.api-keys.revoke-key-tooltip",
    defaultMessage: "Revoke API key"
  },
  apiUpgradeTitle: {
    id: "profile.api-keys.api-upgrade-title",
    defaultMessage: "API Key Available for new pricing terms Enterprise members"
  },
  apiUpgradeSubtitle: {
    id: "profile.api-keys.api-upgrade-subtitle",
    defaultMessage: "Use HourOne API to programmatically create videos See <span>API Docs</span>"
  }
});

export const minutesModalMessages = defineMessages({
  title: {
    id: `minutes-modal.title`,
    defaultMessage: `Minutes usage`
  },
  today: {
    id: `minutes-modal.today`,
    defaultMessage: `Today`
  },
  videoName: {
    id: `minutes-modal.video-name`,
    defaultMessage: `Video name`
  },
  type: {
    id: `minutes-modal.type`,
    defaultMessage: `Type`
  },
  studio: {
    id: `minutes-modal.studio`,
    defaultMessage: `Studio`
  },
  api: {
    id: `minutes-modal.api`,
    defaultMessage: `API`
  },
  playground: {
    id: `minutes-modal.playground`,
    defaultMessage: `Playground`
  },
  duration: {
    id: `minutes-modal.duration`,
    defaultMessage: `Seconds used`
  },
  member: {
    id: `minutes-modal.member`,
    defaultMessage: `Member`
  },
  version: {
    id: `minutes-modal.version`,
    defaultMessage: `Version`
  },
  createdAt: {
    id: `minutes-modal.created-at`,
    defaultMessage: `Created at`
  },
  minutesUsageAnalytics: {
    id: `minutes-modal.minutes-usage-analytics`,
    defaultMessage: "Minutes usage statistics"
  },
  jan: {
    id: `minutes-modal.jan`,
    defaultMessage: "January"
  },
  feb: {
    id: `minutes-modal.feb`,
    defaultMessage: "February"
  },
  march: {
    id: `minutes-modal.march`,
    defaultMessage: "March"
  },
  april: {
    id: `minutes-modal.april`,
    defaultMessage: "April"
  },
  may: {
    id: `minutes-modal.may`,
    defaultMessage: "May"
  },
  june: {
    id: `minutes-modal.june`,
    defaultMessage: "June"
  },
  july: {
    id: `minutes-modal.july`,
    defaultMessage: "July"
  },
  aug: {
    id: `minutes-modal.aug`,
    defaultMessage: "August"
  },
  sep: {
    id: `minutes-modal.sep`,
    defaultMessage: "September"
  },
  oct: {
    id: `minutes-modal.oct`,
    defaultMessage: "October"
  },
  nov: {
    id: `minutes-modal.nov`,
    defaultMessage: "November"
  },
  dec: {
    id: `minutes-modal.dec`,
    defaultMessage: "December"
  }
});

export const securityCardMessages = defineMessages({
  securityModalTitle: {
    id: `security-card.title`,
    defaultMessage: "Two factors authentication"
  },
  googleAuthTitle: {
    id: `security-card.google-auth-title`,
    defaultMessage: "Set up via Google Authenticator"
  },
  googleAuthSubtitle: {
    id: `security-card.google-auth-subtitle`,
    defaultMessage: `Please use your Google Authenticator app to scan this QR code.`
  },
  orEnterCode: {
    id: `security-card.enter-code`,
    defaultMessage: `Or enter this code into your authentication app:`
  }
});
