/* eslint react/jsx-pascal-case: 0 */
/* eslint @typescript-eslint/naming-convention: 0 */
import styled from "styled-components";
import { HTMLAttributes } from "react";

const StyledIcon = styled.i<{
  $color?: string;
  $size?: string;
  $padding?: string;
  $margin?: string;
  $hoverColor?: string;
  $activeColor?: string;
  $cursor: string;
  $backgroundColor?: string;
}>`
  &&&&& {
    color: ${({ $color }) => $color};
  }
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: ${({ $size }) => $size};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  cursor: ${({ $cursor }) => $cursor};
  &:hover {
    color: ${({ $hoverColor }) => $hoverColor};
  }
  &:active {
    color: ${({ $activeColor }) => $activeColor};
  }
`;

interface IconProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  margin?: string;
  padding?: string;
  size?: string;
  icon: string;
  color?: string;
  backgroundColor?: string;
  hoverColor?: string;
  activeColor?: string;
  isCursorPointer?: boolean;
}

export const H1_Icon = ({
  icon,
  className = "",
  margin,
  padding,
  size,
  color,
  backgroundColor,
  hoverColor,
  activeColor,
  isCursorPointer = false,
  ...rest
}: IconProps) => {
  return (
    <StyledIcon
      className={`${icon}${className ? ` ${className}` : ""}`}
      $margin={margin}
      $padding={padding}
      $color={color}
      $backgroundColor={backgroundColor}
      $hoverColor={hoverColor}
      $activeColor={activeColor}
      $size={size}
      $cursor={isCursorPointer ? "pointer" : "default"}
      {...rest}
    />
  );
};
H1_Icon.defaultProps = {
  className: "",
  margin: "",
  padding: "",
  size: "",
  color: "",
  backgroundColor: "",
  activeColor: "",
  hoverColor: "",
  isCursorPointer: false
};
