import { useIntl } from "react-intl";
import styled from "styled-components";
import { homePageMessages } from "app/pages/HomePage/messages";
import { H1_TextLargeHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import React from "react";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { useNavigate } from "react-router-dom";
import { Location } from "app/types";
import { Button } from "@nextui-org/react";

const SizeTextLargeHeadline = styled(H1_TextLargeHeadline)`
  line-height: 40px;
  @media (max-width: 1400px) {
    font-size: 28px;
  }

  @media (max-width: 1300px) {
    font-size: 24px;
  }
`;

const ImageContainer = styled(H1_FlexRow)`
  right: -10px;
  bottom: 0px;
  @media (max-width: 1400px) {
    display: none;
  }
`;

const GradientSectionFlexRow = styled(H1_FlexRow)`
  background: linear-gradient(92deg, rgba(73, 82, 104, 1) 0.22%, rgba(255, 255, 255, 1) 160.21%);
  border-radius: 10px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      font-size: 16px;
    }
  }
`;

const PositionButton = styled(Button)`
  background-color: white;
  margin-top: 13px;
  padding: 2px 30px;
  width: min-content;
  border-radius: 60px;
  & {
    height: 47px;
  }
`;

const HomePageTopSectionDevelopers = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onCallToActionClick = () => {
    const SOURCE = "banner";
    dispatch(
      analyticsEvents.homepageCTA({
        source: SOURCE,
        actionDescription: "playground"
      })
    );

    navigate(Location.Playground);
  };

  return (
    <H1_FlexColumn
      position="relative"
      flex="1 1 auto"
      height="250px"
      data-auto-id="homepage-sales-banner"
    >
      <GradientSectionFlexRow
        position="relative"
        height="100%"
        overflow="hidden"
        padding="30px 30px 40px"
      >
        <H1_FlexColumn justify="space-between">
          <H1_FlexColumn gap="5px">
            <SizeTextLargeHeadline whiteSpace="break-spaces" fontWeight={500} color="white">
              {formatMessage(homePageMessages.bannerDevelopersTitle)}
            </SizeTextLargeHeadline>
            <H1_TextMiddle fontWeight={500} whiteSpace="break-spaces" color="white">
              {formatMessage(homePageMessages.bannerDevelopersSubTitle)}
            </H1_TextMiddle>
          </H1_FlexColumn>
          <PositionButton type="secondary" onClick={onCallToActionClick}>
            {formatMessage(homePageMessages.bannerDevelopersCTA)}
          </PositionButton>
        </H1_FlexColumn>
      </GradientSectionFlexRow>
      <ImageContainer position="absolute">
        <img
          width="224px"
          height="222px"
          src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/createVideoFromCodeIcon.webp"
          alt="workflow"
        />
      </ImageContainer>
    </H1_FlexColumn>
  );
};

export default HomePageTopSectionDevelopers;
