import { useEffect } from "react";
import { mobileDeepLink } from "app/utils/urls";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Location } from "app/types";

const AuthenticationRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isMobile) {
      window.location.href = mobileDeepLink;
    } else {
      navigate(Location.Home);
    }
  }, []);
  return null;
};

export default AuthenticationRedirect;
