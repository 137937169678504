import { Button, Form, message, Row, Space } from "antd";
import React from "react";
import { Folder } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";

import { foldersActions } from "app/store/slices/folders.slice";
import { foldersGlobalSelectors } from "app/store/adapters/adapters";
import { useIntl } from "react-intl";
import { renameFolderModalMessages } from "app/pages/FoldersPage/messages";
import useModal, { ModalName } from "app/hooks/useModal";
import { StyledModal } from "app/components/common/StyledModal";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Input } from "@nextui-org/react";

const RenameFolderModal = () => {
  const { editorModalOpen, closeModal } = useModal();
  const dispatch = useAppDispatch();
  const allFolders = useAppSelector(foldersGlobalSelectors.selectAll);
  const { formatMessage } = useIntl();
  const visible = editorModalOpen?.open === ModalName.renameFolder;
  const { folderId } = editorModalOpen?.context || {};
  const folder = useAppSelector((state) => foldersGlobalSelectors.selectById(state, folderId));

  const currentName = folder?.name;

  const onCloseModal = () => {
    closeModal();
  };

  const onRenameFolder = (folderData: Folder) => {
    dispatch(
      foldersActions.editFolderNameRequest({
        folderId: folderId as string,
        name: folderData.name as string
      })
    );
    message.success(
      formatMessage(renameFolderModalMessages.updateNameNotification, {
        previousName: currentName,
        name: folderData.name
      })
    );
    onCloseModal();
  };

  const handleFocus = (event: any) => event && event.target && event.target.select();

  return (
    <StyledModal
      open={visible}
      destroyOnClose
      onCancel={onCloseModal}
      centered
      width={400}
      footer={false}
      bodyStyle={{ padding: "20px" }}
    >
      <H1_TextMiddle>{formatMessage(renameFolderModalMessages.title)}</H1_TextMiddle>
      <Form layout="vertical" onFinish={(values) => onRenameFolder(values)} hideRequiredMark>
        <Form.Item
          label={<H1_TextSmall>{formatMessage(renameFolderModalMessages.label)}</H1_TextSmall>}
          name="name"
          rules={[
            {
              required: true,
              message: (
                <H1_TextSmall>
                  {formatMessage(renameFolderModalMessages.validationError)}
                </H1_TextSmall>
              )
            },
            {
              validator: (_, value) => {
                const exist =
                  value !== currentName && allFolders.find((curFolder) => curFolder.name === value);
                if (exist) {
                  return Promise.reject(
                    new Error(formatMessage(renameFolderModalMessages.validationUniqueError))
                  );
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            size="sm"
            labelPlacement="outside"
            placeholder={formatMessage(renameFolderModalMessages.namePlaceholder)}
            defaultValue={currentName}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onFocus={handleFocus}
          />
        </Form.Item>
        <Row justify="end">
          <Space>
            <Button onClick={onCloseModal} ghost type="primary">
              {formatMessage(renameFolderModalMessages.cancelButton)}
            </Button>
            <Button type="primary" htmlType="submit">
              {formatMessage(renameFolderModalMessages.saveButton)}
            </Button>
          </Space>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default RenameFolderModal;
