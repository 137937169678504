import MediaItemWrapper from "app/pages/mediaLibrary/MediaItemWrapper";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Card, CardBody } from "@nextui-org/react";
import CustomRipple from "app/components/common/CustomRipple";

const StyledCard = styled(Card)<{ $selected: boolean; $folderLayout: boolean }>`
  overflow: hidden;
  border: 1px solid ${({ theme, $folderLayout }) => ($folderLayout ? theme.blue1 : theme.gray3)};
  border-radius: 12px;
  &:has(.multi-selectable) {
    border: ${({ theme, $selected }) => $selected && `1px solid ${theme.blue4}`};
  }
  &&&:hover {
    --tw-shadow: var(--nextui-box-shadow-small);
    --tw-shadow-colored: var(--nextui-box-shadow-small);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
  &&&:active {
    --tw-shadow: var(--nextui-box-shadow-none);
    --tw-shadow-colored: var(--nextui-box-shadow-none);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
`;

interface ItemLayoutProps {
  footer?: ReactNode;
  selected?: boolean;
  folderLayout?: boolean;
  children: ReactNode;
  fileId?: string;
}

const ItemLayout = ({
  fileId,
  footer,
  folderLayout = false,
  selected,
  children
}: ItemLayoutProps) => {
  const handlePress = (e: React.MouseEvent<HTMLElement>) => {
    // Ensure the event bubbles up
    e.stopPropagation();
  };

  return (
    <H1_FlexColumn>
      <CustomRipple borderRadius="12px">
        <StyledCard
          $selected={selected}
          shadow="none"
          $folderLayout={folderLayout}
          onPress={handlePress}
          className="item-card"
          data-id={fileId}
        >
          <CardBody className="overflow-visible p-0 static">
            <MediaItemWrapper
              className={`multi-selectable ${folderLayout ? "droppable" : ""}`}
              width="164px"
              height="164px"
              selected={selected}
              $folderLayout={folderLayout}
              align="center"
              justify="center"
              overflow="hidden"
            >
              <H1_FlexColumn width={"100%"} height={"154px"} justify={"center"} align={"center"}>
                {children}
              </H1_FlexColumn>
            </MediaItemWrapper>
          </CardBody>
        </StyledCard>
      </CustomRipple>

      <ConditionalRender condition={!!footer}>
        <H1_FlexRow width={"100%"} justify={"center"} align={"center"} padding={"12px"}>
          {footer}
        </H1_FlexRow>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};
export default ItemLayout;
