import * as React from "react";
import { ReactNode } from "react";

import { ReactComponent as WarningIcon } from "app/assets/editor/warning-icon.svg";
import styled, { useTheme } from "styled-components";
import { limitSlidesWarningMessages } from "app/components/editor/presentations/messages";
import { useIntl } from "react-intl";
import { PlanEnum } from "app/types";
import UpgradeButton from "app/components/common/UpgradeButton/UpgradeButton";
import ConditionalRender from "app/components/common/ConditionalRender";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";
import { Button } from "@nextui-org/react";

const StyledContinueButton = styled(Button)`
  background-color: ${({ theme }) => theme.gray10};
  border: unset;
  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.gray9};
    border: unset;
  }
  height: 40px;
`;
interface LimitSlidesWarningProps {
  onContinue: () => void;
  onBack: () => void;
  loading: boolean;
  maxLimit: number;
  targetPlan?: PlanEnum;
  onUpgrade: () => void;
  showUpgradeButton: boolean;
}
const LimitSlidesWarningModal = ({
  onContinue,
  onBack,
  loading,
  maxLimit,
  onUpgrade,
  showUpgradeButton
}: LimitSlidesWarningProps) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <StyledModal
      width="525px"
      visible
      footer={null}
      centered
      bodyStyle={{ height: "240px" }}
      onCancel={onBack}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexColumn flex="1" justify="space-between" gap="33px" padding="28px">
        <H1_FlexRow gap="25px">
          <H1_FlexColumn>
            <WarningIcon />
          </H1_FlexColumn>
          <H1_FlexColumn gap="14px">
            <H1_TextMiddle whiteSpace="normal" fontWeight={600}>
              {intl.formatMessage(limitSlidesWarningMessages.mainDescription, {
                num: maxLimit
              })}
            </H1_TextMiddle>
            <H1_TextSmall whiteSpace="break-spaces" color={theme.gray7}>
              {
                intl.formatMessage(limitSlidesWarningMessages.subDescription, {
                  br: <br />,
                  maxLimit
                }) as ReactNode
              }
            </H1_TextSmall>
          </H1_FlexColumn>
        </H1_FlexRow>
        <H1_FlexRow gap="5px" align="center" justify="flex-end">
          <Button isDisabled={loading} variant="light" onClick={onBack}>
            {intl.formatMessage(limitSlidesWarningMessages.backButton)}
          </Button>
          <ConditionalRender condition={showUpgradeButton}>
            <UpgradeButton
              onClick={onUpgrade}
              source="presentations"
              text={intl.formatMessage(limitSlidesWarningMessages.modalButtonUpgrade)}
            />
          </ConditionalRender>

          <StyledContinueButton variant="" isLoading={loading} onClick={onContinue}>
            {intl.formatMessage(limitSlidesWarningMessages.continueButton)}
          </StyledContinueButton>
        </H1_FlexRow>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default LimitSlidesWarningModal;
