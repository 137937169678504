import { defineMessages } from "react-intl";

export const cloneVoiceMessages = defineMessages({
  modalHeader: {
    id: `clone-voice.modal.modal-header`,
    defaultMessage: "Clone Your Voice"
  },
  nameHeader: {
    id: `clone-voice.modal.name-header`,
    defaultMessage: "Name"
  },
  descriptionHeader: {
    id: `clone-voice.modal.description-header`,
    defaultMessage: "Description"
  },
  terms: {
    id: `clone-voice.modal.terms`,
    defaultMessage:
      "I herby confirm that I have necessary rights or consents to upload and clone these voice sample and that I will not use the platform-generated content for any illegal frauidient or harmful purpose I reaffirm my obligation to abide HourOne's Terms of Service and Privacy Policy."
  },
  modalButtonCancel: {
    id: `clone-voice.modal.cancel-button`,
    defaultMessage: "Cancel"
  },
  modalButtonAdd: {
    id: `clone-voice.modal.add-button`,
    defaultMessage: "Add Voice"
  },
  finishRecord: {
    id: `clone-voice.modal.finish-record-button`,
    defaultMessage: "Done"
  },
  info: {
    id: `clone-voice.modal.info`,
    defaultMessage:
      "Sample quality is more important than quantity. Noisy{br}samples give bad results. Please provide at least 2.5{br}minutes of audio."
  },
  uploadFileButtonTitle: {
    id: `clone-voice.modal.upload-file.title`,
    defaultMessage: "Click to upload a file{br}or drag and drop"
  },
  uploadFileButtonDescription: {
    id: `clone-voice.modal.upload-file.description`,
    defaultMessage: "Audio files, up to 10MB each"
  },
  recordButtonTitle: {
    id: `clone-voice.modal.record.title`,
    defaultMessage: "Record Voice"
  },
  recordButtonDescription: {
    id: `clone-voice.modal.record.description`,
    defaultMessage: "Record at least {maxTime} minutes of you talking."
  },
  voiceRecordingTitle: {
    id: `clone-voice.modal.voice-recording.title`,
    defaultMessage: "Audio Recording"
  },
  finishedTitle: {
    id: `clone-voice.modal.voice-recording.confirm-modal.title`,
    defaultMessage: "Clone Your Voice"
  },
  finishedDesc: {
    id: `clone-voice.modal.voice-recording.confirm-modal.description`,
    defaultMessage:
      "Your voice is being created this may take a few minutes, you'll be notified once voice is ready."
  },
  finishOkButton: {
    id: `clone-voice.modal.voice-recording.confirm-modal.confirm-btn`,
    defaultMessage: "Ok"
  },
  finishMessageNotification: {
    id: `clone-voice.modal.voice-recording.notification.message`,
    defaultMessage: "Your voice is being created"
  },
  validationTimeError: {
    id: `clone-voice.modal.voice-recording.notification.time-error`,
    defaultMessage: "record exceeded max allowed time"
  }
});
