import { defineMessages, IntlShape } from "react-intl";

const languageMessages = defineMessages({
  languageEnglish: {
    id: "presenters-page.language-english",
    defaultMessage: "English"
  },
  languageFrench: {
    id: "presenters-page.language-french",
    defaultMessage: "French"
  },
  languageGerman: {
    id: "presenters-page.language-german",
    defaultMessage: "German"
  },
  languageChinese: {
    id: "presenters-page.language-chinese",
    defaultMessage: "Chinese"
  },
  languageItalian: {
    id: "presenters-page.language-italian",
    defaultMessage: "Italian"
  },
  languagePortuguese: {
    id: "presenters-page.language-portuguese",
    defaultMessage: "Portuguese"
  },
  languageJapanese: {
    id: "presenters-page.language-japanese",
    defaultMessage: "Japanese"
  },
  languageArabic: {
    id: "presenters-page.language-arabic",
    defaultMessage: "Arabic"
  },
  languageRussian: {
    id: "presenters-page.language-russian",
    defaultMessage: "Russian"
  }
});
export const languageText = (local: string, intl: IntlShape): string => {
  switch (local) {
    case "us":
    case "en":
    case "gb":
      return intl.formatMessage(languageMessages.languageEnglish);
    case "fr":
      return intl.formatMessage(languageMessages.languageFrench);
    case "de":
      return intl.formatMessage(languageMessages.languageGerman);
    case "pt":
    case "br":
      return intl.formatMessage(languageMessages.languagePortuguese);
    case "it":
      return intl.formatMessage(languageMessages.languageItalian);
    case "sa":
    case "ae":
      return intl.formatMessage(languageMessages.languageArabic);
    case "ru":
      return intl.formatMessage(languageMessages.languageRussian);
    case "jp":
      return intl.formatMessage(languageMessages.languageJapanese);
    case "cn":
      return intl.formatMessage(languageMessages.languageChinese);
    default:
      return intl.formatMessage(languageMessages.languageEnglish);
  }
};
