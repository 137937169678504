import { useEffect, useState } from "react";
import styled from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import VirtualTwinTopBar from "app/pages/createDigitalTwinPage/VirtualTwinTopBar";
import IntroStep from "app/pages/createDigitalTwinPage/IntroStep";
import InstructionsStep from "app/pages/createDigitalTwinPage/InstructionsStep";
import RecordStepSettingsContextWrapper from "app/pages/createDigitalTwinPage/RecordStepSettingsContextWrapper";
import { VirtualTwinSteps } from "app/types/virtualTwin";
import { FeatureFlag, Gender, Location } from "app/types";
import { useNavigate } from "react-router-dom";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import RecorderContextWrapper from "app/hooks/recorder/RecorderContextWrapper";
import RecordStep from "app/pages/createDigitalTwinPage/RecordStep";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { avatarsActions } from "app/store/slices/avatars.slice";
import { fetchingStatus } from "app/utils/helpers";
import CharacterSettingsContextWrapper from "app/pages/createDigitalTwinPage/CharacterSettingsContextWrapper";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray4};
  height: 100vh;
`;

const StepContentCard = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray1};
  border-radius: 10px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  max-width: ${SMALL_SCREEN_PX};
  flex-shrink: 0;
`;

const steps = [VirtualTwinSteps.intro, VirtualTwinSteps.instructions, VirtualTwinSteps.record];

const CreateDigitalTwinPage = () => {
  const [currentStep, setCurrentStep] = useState<VirtualTwinSteps>(VirtualTwinSteps.intro);

  const avatarsSessionsFetchStatus = useAppSelector((state) => state.avatars.avatarStatus);
  const createAvatarStatus = useAppSelector((state) => state.avatars.createAvatarStatus);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const flags = useFlags();

  useEffect(() => {
    dispatch(avatarsActions.getAvatarsRequest([]));
  }, []);

  useEffect(() => {
    if (avatarsSessionsFetchStatus === fetchingStatus.succeeded) {
      dispatch(avatarsActions.setFetchToIdle());
    } else if (avatarsSessionsFetchStatus === fetchingStatus.failed) {
      dispatch(avatarsActions.setFetchToIdle());
    }
  }, [avatarsSessionsFetchStatus]);

  useEffect(() => {
    if (createAvatarStatus === fetchingStatus.succeeded) {
      dispatch(avatarsActions.setCreateToIdle());
      onFinish();
    } else if (createAvatarStatus === fetchingStatus.failed) {
      dispatch(avatarsActions.setCreateToIdle());
      onFinish();
    }
  }, [createAvatarStatus]);

  useEffect(() => {
    if (!flags[FeatureFlag.addAvatar] || !flags[FeatureFlag.avatarFromWebcam]) {
      navigate(Location.Home);
    }
  }, [flags]);

  const currentStepIsIntro = currentStep === VirtualTwinSteps.intro;
  const currentStepIsInstructions = currentStep === VirtualTwinSteps.instructions;
  const currentStepIsRecord = currentStep === VirtualTwinSteps.record;

  const onFinish = () => {
    navigate(Location.Avatars);
  };
  const onRestart = () => {
    setCurrentStep(VirtualTwinSteps.intro);
  };

  const changeCurrentStep = (step: VirtualTwinSteps) => {
    setCurrentStep(step);
  };

  const handleFinishRecordAvatar = ({
    approved,
    url,
    name,
    gender,
    addPong,
    addTransparent
  }: {
    approved: boolean;
    url: string;
    name: string;
    gender: Gender;
    addPong: boolean;
    addTransparent: boolean;
  }) => {
    let normalizedGender = gender;
    if (!normalizedGender || normalizedGender === Gender.all) {
      normalizedGender = Gender.notGiven;
    }

    dispatch(
      avatarsActions.createAvatarRequest({
        session_flow: "webcam",
        avatar_video_url: url,
        consent_granted: approved,
        character_data: {
          title: name,
          gender: normalizedGender
        },
        add_pong: addPong,
        create_transparent: addTransparent
      })
    );
  };

  return (
    <RecorderContextWrapper>
      <RecordStepSettingsContextWrapper>
        <BackgroundFlexColumn flex="0 0 auto" overflow="auto" gap="5px">
          <VirtualTwinTopBar steps={steps} currentStep={currentStep} onRestart={onRestart} />
          <ConditionalRender condition={currentStepIsIntro}>
            <StepContentCard justify="center" padding="40px 50px 30px" width="100%" margin="auto">
              <IntroStep onFinish={() => changeCurrentStep(VirtualTwinSteps.instructions)} />
            </StepContentCard>
          </ConditionalRender>
          <ConditionalRender condition={currentStepIsInstructions}>
            <StepContentCard justify="center" padding="50px 10px 20px" width="100%" margin="auto">
              <InstructionsStep onFinish={() => changeCurrentStep(VirtualTwinSteps.record)} />
            </StepContentCard>
          </ConditionalRender>
          <ConditionalRender condition={currentStepIsRecord}>
            <StepContentCard justify="center" padding="50px 10px 20px" width="100%" margin="auto">
              <CharacterSettingsContextWrapper>
                <RecordStep onUploadSucceed={handleFinishRecordAvatar} />
              </CharacterSettingsContextWrapper>
            </StepContentCard>
          </ConditionalRender>
        </BackgroundFlexColumn>
      </RecordStepSettingsContextWrapper>
    </RecorderContextWrapper>
  );
};

export default CreateDigitalTwinPage;
