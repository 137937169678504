import React, { FC, memo, ReactNode, useEffect, useReducer } from "react";
import * as Sentry from "@sentry/react";
import UiActions from "app/store/actions/ui.actions";

import { AuthContext, authInitialState, clearLocalAuthSession } from "app/auth/authContext";

import { authReducer } from "app/auth/reducers/AuthReducers";
import { resetAnalytics, trackEvent } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import * as workosApiCalls from "./workosApiCalls";
import { CreateTokenArgs } from "./workosApiCalls";
import { InitializeAction } from "app/auth/actions/AuthActions";
import { useCookies } from "react-cookie";
import { inhouseStoredCookies } from "app/services/cookie-manager";
import { AxiosError } from "axios";
import { userActions } from "app/store/slices/user.slice";
import { useNavigate } from "react-router-dom";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { security } from "app/store/security";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";

interface AuthProviderProps {
  children: ReactNode;
}
export enum Params {
  init = "init",
  email = "email",
  ref = "ref",
  journey = "journey",
  err = "err"
}
const AuthProvider: FC<React.PropsWithChildren<AuthProviderProps>> = ({ children }) => {
  const [cookies] = useCookies(inhouseStoredCookies);

  const [state, dispatch] = useReducer(authReducer, {
    ...authInitialState,
    isAuthenticated: false
  });
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const ssoCode = params.get("sso_code");

  useEffect(() => {
    (async () => {
      const codeVerifier = workosApiCalls.getSsoCodeVerifierStorage();
      if (ssoCode) {
        if (!codeVerifier) {
          console.warn(`no code verifier in storage for code ${ssoCode}, trying without`);
        }
        try {
          const result = await workosApiCalls.createToken({
            type: "pkce",
            codeVerifier: codeVerifier ? codeVerifier : "",
            code: ssoCode
          });
          workosApiCalls.saveSsoRefreshTokenStorage(result.refresh_token);
          workosApiCalls.saveSsoExpiresInStorage(result.access_token_expires);
          workosApiCalls.saveSsoRefreshExpiresInStorage(result.refresh_token_expires);
          workosApiCalls.removeCodeVerifierStorage();
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              expiresIn: result.access_token_expires,
              refreshExpiresIn: result.refresh_token_expires,
              refreshToken: result.refresh_token,
              user: result.user_info,
              accessToken: result.access_token
            }
          } as unknown as InitializeAction);
          const provider = result.user_info.sub?.includes("|")
            ? result.user_info.sub.split("|")[0]
            : result.user_info.sub;

          appDispatch(
            trackEvent({
              eventName: "app:user_login",
              properties: {
                provider: provider,
                action: "social_login",
                hubspotToken: cookies.hubspotutk
              }
            })
          );
        } catch (err) {
          workosApiCalls.removeCodeVerifierStorage();
          if (
            err instanceof AxiosError &&
            err?.response?.data.detail?.detail === "email_verification_required"
          ) {
            const pending_authentication_token =
              err?.response?.data.detail?.pending_authentication_token;
            appDispatch(userActions.setPendingAuthenticationToken(pending_authentication_token));

            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                expiresIn: null,
                refreshExpiresIn: null,
                user: null,
                accessToken: null,
                refreshToken: null
              }
            } as unknown as InitializeAction);
            navigate(`/signupSuccess`);

            return;
          }

          Sentry.captureException(err, {
            extra: {
              description: `failed to create token for code ${ssoCode}`,
              code: ssoCode,
              response: err instanceof AxiosError ? err?.response?.data : undefined
            }
          });

          dispatchNotAuthenticated();
        }

        return;
      } else {
        console.debug("debug rehydrate");
        rehydrate();
      }
    })();
  }, []);

  const renewToken = async () => {
    const refreshToken = workosApiCalls.getSsoRefreshTokenStorage() || state.refreshToken;
    const refreshExpired = workosApiCalls.getSsoRefreshExpiresInStorage() || state.refreshExpiresIn;
    if (!refreshToken || !refreshExpired || isTokenExpired(refreshExpired)) {
      workosApiCalls.cleanSessionStorage();
      throw new Error("login_required");
    }
    try {
      const expand = state.user ? undefined : true;
      const result = await workosApiCalls.refreshToken(refreshToken as string, expand);
      workosApiCalls.saveSsoExpiresInStorage(result.access_token_expires);
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: true,
          expiresIn: result.access_token_expires,
          user: state.user || result.user_info,
          accessToken: result.access_token
        }
      } as unknown as InitializeAction);
      return result.access_token;
    } catch (err) {
      console.warn(err);
      throw new Error("refresh token failed");
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    let interval: ReturnType<typeof setInterval> | null = null;

    (async () => {
      if (state?.expiresIn) {
        const { expiresIn } = state;
        timer = setTimeout(async () => {
          try {
            await renewToken();
          } catch (err) {
            console.warn(err);
            // @ts-ignore handels no code in unkown
            if (err.message === "login_required") {
              // opening modal only when login_required
              appDispatch(UiActions.setTokenRefreshError());
            } else {
              interval = setInterval(async () => {
                await renewToken()
                  .then(() => {
                    clearInterval(interval as ReturnType<typeof setInterval>);
                  })
                  .catch();
              }, 10000);
            }
          }
        }, (expiresIn - new Date().getTime() / 1000) * 0.75 * 1000); // 75 % of time
      }
    })();

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [state.accessToken]);

  const rehydrate = async () => {
    try {
      await renewToken();
    } catch (err) {
      dispatchNotAuthenticated();
    }
  };

  const show = (): void => {
    return;
  };

  const hide = (): void => {
    return;
  };

  const dispatchNotAuthenticated = () => {
    workosApiCalls.cleanSessionStorage();

    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        expiresIn: null,
        refreshExpiresIn: null,
        user: null,
        accessToken: null,
        refreshToken: null
      }
    } as unknown as InitializeAction);
  };

  const logout = (redirect?: string): void => {
    const provider = state.user?.sub?.includes("|")
      ? state.user?.sub?.split("|")[0]
      : state.user?.sub;

    appDispatch(trackEvent({ eventName: "app:user_logout", properties: { provider: provider } }));
    appDispatch(resetAnalytics());
    clearLocalAuthSession();
    appDispatch(UiActions.cleeanTokeRefreshError());
    workosApiCalls.cleanSessionStorage();
    Sentry.setUser(null);
    appDispatch(userActions.cleanUser());
    appDispatch(workspacesActions.cleanWorkspaces());
    appDispatch(mediaActions.invalidMediaRootCache());
    security.setAccessTokenSilently(null);
    if (redirect) {
      window.location.href = redirect;
    } else {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          expiresIn: null,
          refreshExpiresIn: null,
          user: null,
          accessToken: null,
          refreshToken: null
        }
      } as unknown as InitializeAction);
    }
  };

  const createToken = async (args: CreateTokenArgs) => {
    const result = await workosApiCalls.createToken(args);
    workosApiCalls.saveSsoRefreshTokenStorage(result.refresh_token);
    workosApiCalls.saveSsoExpiresInStorage(result.access_token_expires);
    workosApiCalls.saveSsoRefreshExpiresInStorage(result.refresh_token_expires);
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: true,
        expiresIn: result.access_token_expires,
        refreshExpiresIn: result.refresh_token_expires,
        user: result.user_info,
        accessToken: result.access_token,
        refresh_token: result.refresh_token
      }
    } as unknown as InitializeAction);
  };

  security.setAuthLockClient({ renewToken: renewToken });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "Auth0-lock",
        logout,
        show,
        hide,
        renewToken,
        createToken,
        signIn: true,
        signUp: false
      }}
    >
      <MemoChildren>{children}</MemoChildren>
    </AuthContext.Provider>
  );
};
// eslint-disable-next-line react/display-name
const MemoChildren = memo(({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
});

export default AuthProvider;
const isTokenExpired = (expiredAtTimestampInSeconds: number) => {
  // Convert expiration timestamp to milliseconds
  const expiredAtTimestampInMilliseconds = expiredAtTimestampInSeconds * 1000;

  // Get the current time in milliseconds
  const currentTime = new Date().getTime();

  // Compare the expiration timestamp with the current time
  return expiredAtTimestampInMilliseconds < currentTime;
};
