import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import React, { useEffect, useMemo, useState } from "react";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import VariableItem from "app/components/editor/workflows/VariableItem";
import UploadLogoButton from "app/pages/EditDraftPage/workflow/UploadLogoButton";
import { SynthesisMarkupLanguage } from "app/types";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import { Button } from "@nextui-org/react";

const Container = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: ${({ theme }) => theme.gray1};

  /* Modal shadow 10% */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  max-width: 710px;
  min-height: 500px;
  flex-shrink: 0;
  min-width: 710px;
`;
const LogoWrapper = styled(H1_FlexRow)`
  // position: absolute;
  top: -60px;
  left: 0px;
`;

const SubmissionForm = ({
  variableValues,
  onChangeVariableValue,
  totalSml
}: {
  variableValues: Record<string, string>;
  onChangeVariableValue: (id: string, value: string) => void;
  totalSml: SynthesisMarkupLanguage[];
}) => {
  const dispatch = useAppDispatch();

  const [done] = useState<boolean>(false);
  const workflowResult = useAppSelector((state) => state.drafts.workflowResult);
  const { displayUrls } = useDisplayUrls([workflowResult?.form.image]);
  const workflowSubmissionStatus = useAppSelector(
    (state) => state.drafts.createWorkflowSubmissionStatus
  );
  const submitLoading = workflowSubmissionStatus === fetchingStatus.loading;

  const logoPreviewUrl =
    workflowResult?.form.image && displayUrls[workflowResult?.form.image]
      ? displayUrls[workflowResult?.form.image]
      : undefined;

  const variables = useMemo(() => {
    const sceneVariables = totalSml
      .filter((sml) => sml.type === "variable")
      .reduce<Record<string, boolean>>((acc, cur) => {
        acc[cur.variable_id as string] = true;
        return acc;
      }, {});
    return workflowResult?.variables.filter((variable) => sceneVariables[variable.id]) || [];
  }, [totalSml, workflowResult?.variables]);

  useEffect(() => {
    if (workflowSubmissionStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setCreateWorkflowSubmissionStatusToIdle());
    } else if (workflowSubmissionStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setCreateWorkflowSubmissionStatusToIdle());
    }
  }, [workflowSubmissionStatus]);

  const submitVideo = () => {
    const variables = Object.entries(variableValues).map(([variableId, value]) => {
      return { variable_id: variableId, value };
    });
    dispatch(
      draftsActions.createWorkflowSubmissionRequest({
        workflowId: workflowResult?.id as string,
        variables,
        action: "create_video"
      })
    );
  };

  return (
    <Container gap={"25px"} align={"center"} position={"relative"} padding={"85px 127px"}>
      <LogoWrapper justify={"center"} position={"absolute"} width={"100%"}>
        <UploadLogoButton background={logoPreviewUrl?.url} disabled={true} />
      </LogoWrapper>
      <H1_FlexColumn gap={"12px"} align={"center"}>
        <H1_TextHeadline>{workflowResult?.form?.title || ""}</H1_TextHeadline>
        <H1_TextMiddle>{workflowResult?.form?.description}</H1_TextMiddle>
      </H1_FlexColumn>

      <ConditionalRender condition={!done}>
        {variables.map((variable) => {
          return (
            <VariableItem
              onChangeVariableValue={(value) => onChangeVariableValue(variable.id, value)}
              variable={variable}
              key={variable.id}
              viewOnly={true}
              disabled={submitLoading}
              hideVariableName={true}
            />
          );
        })}
        <Button
          color="primary"
          isLoading={submitLoading}
          onClick={submitVideo}
          startContent={<i className="fa-light fa-video" />}
        >
          Submit
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={done}>Thank you</ConditionalRender>
    </Container>
  );
};

export default SubmissionForm;
