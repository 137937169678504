import styled from "styled-components";
import SkeletonButton from "antd/es/skeleton/Button";

export const StyledSkeletonButton = styled(SkeletonButton)<{
  $height: string;
  $width?: string;
  $borderRadius?: string;
  size?: string;
}>`
  &&&.ant-skeleton.ant-skeleton-block {
    height: ${({ $height }: { $height: string }) => $height};
    width: ${({ $width }: { $width?: string }) => $width};
  }
  &&&.ant-skeleton-element .ant-skeleton-button {
    height: ${({ $height }: { $height: string }) => $height};
    width: ${({ $width }: { $width?: string }) => $width};
    border-radius: ${({ $borderRadius }: { $borderRadius?: string }) =>
      $borderRadius ? $borderRadius : "5px"};
    min-width: ${({ $width }: { $width?: string }) => `calc(${$width} - 8px)`};
  }
`;
