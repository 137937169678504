import styled from "styled-components";
import { gradientGenerate } from "app/utils/gradientGenrator";
import React from "react";

const GradientDiv = styled.div<{ $gradient: string }>`
  width: 100%;
  height: 100%;
  ${(p) => p.$gradient && `background:${p.$gradient};`}
`;

const GradientPlaceholder = React.memo(({ gradientStr }: { gradientStr?: string }) => {
  const gradient = gradientGenerate();
  return <GradientDiv $gradient={gradientStr || gradient} />;
});

GradientPlaceholder.displayName = "GradientPlaceholder";

export default React.memo(GradientPlaceholder);
