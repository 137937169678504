import React, { FC, useContext } from "react";
import { Video } from "app/types";
import { useAppSelector } from "app/hooks";
import { videosGlobalSelectors } from "app/store/adapters/adapters";

export interface SingleVideoContextProps {
  video: Video;
}

interface SingleVideoContextWrapperProps {
  videoId: string;
}

export const SingleVideoContext = React.createContext<SingleVideoContextProps | null>(null);

export const SingleVideoContextWrapper: FC<
  React.PropsWithChildren<SingleVideoContextWrapperProps>
> = (props) => {
  const currentVideo: Video = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, props.videoId as string)
  ) as Video;

  return (
    <SingleVideoContext.Provider value={{ video: currentVideo }}>
      {props.children}
    </SingleVideoContext.Provider>
  );
};

export const useSingleVideoContext = () => {
  const currentVideo = useContext(SingleVideoContext);
  if (!currentVideo) {
    throw new Error("No single video context");
  }

  return currentVideo;
};
