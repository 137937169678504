import { leftNavMessages } from "app/components/LeftNav/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import React, { MouseEventHandler } from "react";
import usePermissions from "app/hooks/usePermissions";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Button } from "@nextui-org/react";

const BorderedIconButton = styled(Button)`
  background-color: ${({ theme }) => theme.orange1};
  color: ${({ theme }) => theme.orange4};
  span {
    color: ${({ theme }) => theme.orange4};
  }
  i {
    color: ${({ theme }) => theme.orange4};
  }
  &:hover {
    background-color: ${({ theme }) => theme.orange1};
    color: ${({ theme }) => theme.orange4};
    span {
      color: ${({ theme }) => theme.orange4};
    }
    i {
      color: ${({ theme }) => theme.orange4};
    }
    opacity: 0.8;
  }
  &:active {
    background-color: ${({ theme }) => theme.orange1};
    color: ${({ theme }) => theme.orange4};
    span {
      color: ${({ theme }) => theme.orange4};
    }
    i {
      color: ${({ theme }) => theme.orange4};
    }
  }
`;

export interface UpgradeButtonProps<T> {
  source: string;
  onClick?: MouseEventHandler<T>;
  text?: string;
  disabled?: boolean;
  className?: string;
}

const OppositeUpgradeButton = ({
  source,
  onClick,
  text,
  disabled,
  className
}: UpgradeButtonProps<Element>) => {
  const navigate = useNavigate();
  const { isAllowToUpgrade } = usePermissions();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const onUpgrade = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e);
    } else {
      dispatch(analyticsEvents.productUpgradeCta({ cta: source }));
      navigate(`/pricing`);
    }
  };

  return (
    <ConditionalRender condition={isAllowToUpgrade}>
      <BorderedIconButton
        className={className}
        onClick={onUpgrade}
        startContent={<i className="fa fa-crown" />}
        isDisabled={disabled}
      >
        {text || formatMessage(leftNavMessages.upgradeButton)}
      </BorderedIconButton>
    </ConditionalRender>
  );
};

export default OppositeUpgradeButton;
