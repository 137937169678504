import { defineMessages } from "react-intl";

export const sharePopoverMessages = defineMessages({
  title: {
    id: `share-popover.title`,
    defaultMessage: `Share this video`
  },
  inviteText: {
    id: `share-popover.invite-text`,
    defaultMessage: `Invite member`
  },
  shareButton: {
    id: `share-popover.share-button`,
    defaultMessage: `Share`
  },
  emailPlaceholder: {
    id: `share-popover.email-placeholder`,
    defaultMessage: "Email Address here..."
  },
  inviteButton: {
    id: `share-popover.invite-button`,
    defaultMessage: `Invite`
  },
  anyoneWithThisLink: {
    id: `share-popover.anyone-with-link`,
    defaultMessage: `Anyone with this link can view`
  },
  email: {
    id: `share-popover.email`,
    defaultMessage: "Email"
  },
  embed: {
    id: `share-popover.embed`,
    defaultMessage: "Embed"
  },
  facebook: {
    id: `share-popover.facebook`,
    defaultMessage: "Facebook"
  },
  linkedin: {
    id: `share-popover.linkedin`,
    defaultMessage: "LinkedIn"
  },
  whatsapp: {
    id: `share-popover.whatsapp`,
    defaultMessage: "Whatsapp"
  },
  twitter: {
    id: `share-popover.twitter`,
    defaultMessage: "Twitter"
  }
});
