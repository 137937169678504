import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { PaletteColor, PaletteColorKey } from "app/types";
import styled, { css } from "styled-components";
import { Tooltip } from "antd";
import { useIntl } from "react-intl";
import { customColorPickerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";

interface CustomCirclePickerProps {
  selectedColor: number;
  onSelectColorIndex: (index: number) => void;
  colors: PaletteColor[];
}

const OuterDiv = styled.div<{ $selected?: boolean }>`
  cursor: pointer;
  background-color: ${(props) => props.theme.gray1};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px ${(props) => props.theme.gray3} solid;
  position: relative;
  ${({ $selected }) =>
    $selected &&
    css`
      filter: drop-shadow(0px 0px 4px #aaf);
    `};
`;

const InnerDiv = styled.div<{ $backgroundColor?: string }>`
  position: absolute;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "initial"};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 0;
  left: 0;
`;

const CustomCirclePicker = ({
  onSelectColorIndex,
  selectedColor,
  colors
}: CustomCirclePickerProps) => {
  const { formatMessage } = useIntl();
  const colorTooltip = (color: PaletteColor) => {
    switch (color.key) {
      case PaletteColorKey.color_A1:
        return formatMessage(customColorPickerMessages.a1Color);
      case PaletteColorKey.color_A2:
        return formatMessage(customColorPickerMessages.a2Color);
      case PaletteColorKey.color_A3:
        return formatMessage(customColorPickerMessages.a3Color);
      case PaletteColorKey.color_W:
        return formatMessage(customColorPickerMessages.wColor);
      case PaletteColorKey.color_B:
        return formatMessage(customColorPickerMessages.bColor);
      default:
        return "";
    }
  };

  return (
    <H1_FlexRow width="90%" gap="13px">
      {colors.map((paletteColor, index) => {
        return (
          <Tooltip key={index} title={colorTooltip(paletteColor)}>
            <OuterDiv
              key={index}
              onClick={() => onSelectColorIndex(index)}
              $selected={selectedColor === index}
            >
              <InnerDiv key={paletteColor.color} $backgroundColor={paletteColor.color} />
            </OuterDiv>
          </Tooltip>
        );
      })}
    </H1_FlexRow>
  );
};

export default CustomCirclePicker;
