import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";

import { INTRO_OUTRO_KEYS, SupportedGroups, TemplateAsset } from "app/types";
import { DrawerProps } from "app/components/editor/sideDrawers/CommonDrawerTypes";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import TitleFlexRow from "app/components/editor/sideDrawers/CommonComponents/TitleFlexRow";
import LogoElement from "app/components/editor/sideDrawers/LogoDrawer/LogoElement";
import VideoDrawerSingleMedia from "app/components/editor/sideDrawers/VideoDrawerSingleMedia";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useAppSelector } from "app/hooks";
import TitledInput from "app/components/common/TitledInput";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button } from "@nextui-org/react";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";

interface BrandDrawerProps extends DrawerProps {
  assetsArr: TemplateAsset[];
}

const TextButton = styled(Button)`
  color: ${({ theme }) => theme.gray11};
  height: unset;
`;
const messages = defineMessages({
  brandkitTitle: {
    id: "brandkit-drawer.title",
    defaultMessage: "Brand"
  },
  logoTitle: {
    id: "brandkit-drawer.logo-title",
    defaultMessage: "Logo"
  },
  upgradeTextOutroIntro: {
    id: "brandkit-drawer.outro-intro-upgrade-text",
    defaultMessage: "Upgrade to add intro/outro"
  },
  useBrand: {
    id: "brandkit-drawer.use-brand-title",
    defaultMessage: "Use Brand Kit"
  }
});
const BrandDrawer = ({
  right,
  assetsArr,
  onDraftAttributeChange,
  attributes,
  width
}: BrandDrawerProps) => {
  const intl = useIntl();
  const { editorDrawerOpen } = useDrawer();

  const currentBrandkit = useAppSelector((state) => state.brandKit.currentBrandkit);

  const isDrawerVisible = editorDrawerOpen === Drawer.Brand;

  const logos = useMemo(() => {
    return assetsArr.filter((asset) => asset.group === SupportedGroups.Logo);
  }, [assetsArr]);

  const videos = useMemo(() => {
    return assetsArr
      .filter(
        (asset) => asset.group === SupportedGroups.Video && INTRO_OUTRO_KEYS.includes(asset.key)
      )
      .sort((a) => {
        if (a.key === "url_intro") {
          return -1;
        } else {
          return 1;
        }
      });
  }, [assetsArr]);

  const onUseBrandkitLogo = (key: string) => {
    onDraftAttributeChange(`attributes.media.${key}`, currentBrandkit.logo);
  };
  return (
    <FlexDrawer
      right={right}
      active={isDrawerVisible}
      className="brand-drawer-container"
      width={width}
    >
      <TitleFlexRow height="42px">
        <H1_TextMiddle>{intl.formatMessage(messages.brandkitTitle)}</H1_TextMiddle>
      </TitleFlexRow>
      <H1_FlexColumn flex="0 0 auto" gap="32px" padding="0 22px 30px 0">
        <ConditionalRender condition={!!logos.length}>
          {logos.map((asset) => {
            return (
              <TitledInput
                key={asset.key}
                title={intl.formatMessage(messages.logoTitle)}
                rightElement={
                  <ConditionalRender
                    condition={
                      !!currentBrandkit?.logo &&
                      attributes.media[asset.key]?.url !== currentBrandkit?.logo?.url
                    }
                  >
                    <TextButton variant="light" onClick={() => onUseBrandkitLogo(asset.key)}>
                      {intl.formatMessage(messages.useBrand)}
                    </TextButton>
                  </ConditionalRender>
                }
              >
                <LogoElement
                  asset={asset}
                  onDraftAttributeChange={onDraftAttributeChange}
                  attributes={attributes}
                />
              </TitledInput>
            );
          })}
        </ConditionalRender>
        {videos.map((asset) => {
          return (
            <VideoDrawerSingleMedia
              key={asset.key as string}
              title={asset.title as string}
              assetKey={asset.key as string}
              url={attributes?.media?.[asset.key as string]?.url}
              restrictions={asset.restrictions}
              onDraftAttributeChange={onDraftAttributeChange}
              upgradeText={intl.formatMessage(messages.upgradeTextOutroIntro)}
            />
          );
        })}
      </H1_FlexColumn>
    </FlexDrawer>
  );
};

export default BrandDrawer;
