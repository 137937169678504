import React, { useEffect } from "react";
import styled from "styled-components";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import { useAuth } from "app/auth/useAuth";
import VideoTags from "app/components/common/VideoTags";
import { Avatar } from "antd";
import VideoReactions from "app/components/common/Reactions/VideoReactions";
import { useSingleVideoContext } from "app/components/common/Reactions/ChannelsPageSingleVideoContext";
import ChannelsPageSingleVideoSideButtons from "app/pages/Channels/ChannelsPageSingleVideoSideButtons";
import { getTimeAgo, isMediaPlaying } from "app/utils/helpers";
import { Location, Video } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSubtitle, H1_TextXs } from "app/components/_Infrastructure/Typography";

const MainVideoContainerFlexRow = styled(H1_FlexRow)`
  position: relative;
  .views-popover {
    .ant-popover-title {
      border-bottom: none;
      padding: 11px 0 5px;
    }
    .ant-popover-content {
      margin: auto;
      padding-bottom: 5px;
      border-radius: 2px;
      overflow: hidden;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }
  .video-reactors-popover {
    padding: 9px 6px 12px;
    flex: none;
    .ant-popover-inner-content {
      height: 0;
      padding: 0;
    }
    .ant-popover-content {
      margin: auto;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  @media screen and (max-height: 810px) {
    width: min(60vw, 785px);
  }
  @media screen and (max-height: 630px) {
    width: min(50vw, 600px);
  }
`;

const MainTaglineContainerFlexRow = styled(H1_FlexRow)`
  position: relative;
  width: calc(100% - 60px);
  .emojis-popover {
    .ant-popover-inner-content {
      height: 0;
      padding: 0;
    }
    .ant-popover-content {
      margin: auto;
      border-radius: 2px;
      overflow: hidden;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }
  @media screen and (max-height: 810px) {
    width: min(60vw, calc(785px - 60px));
  }
  @media screen and (max-height: 630px) {
    width: min(50vw, calc(600px - 60px));
  }
`;

const ReactionAvatar = styled(Avatar)`
  min-width: 45px;
  min-height: 45px;
`;

function getVideoTitle(video: Video): string {
  let videoTitle = "";
  if (video.title) {
    videoTitle = video.title;
  } else if (video.draft_info && video.draft_info.title) {
    videoTitle = video.draft_info.title;
  }
  return videoTitle;
}

interface ChannelsPageSingleVideoProps {
  isCurrentVideo: boolean;
}
const ChannelsPageSingleVideo = ({ isCurrentVideo }: ChannelsPageSingleVideoProps) => {
  const auth = useAuth();
  const { video } = useSingleVideoContext();
  if (!video) {
    throw new Error("RealsMeDestinationsVideo is out of Video context");
  }
  useEffect(() => {
    // Todo - Add a better way to play and pause a video from outside the component
    const player = document.querySelector(
      `[data-reqid='${video?.pipeline_response?.req_id}'] mux-player`
    );
    if (player) {
      if (isCurrentVideo) {
        setTimeout(() => {
          (player as HTMLMediaElement).play();
        }, 0.3);
      } else if (isMediaPlaying(player as HTMLMediaElement)) {
        (player as HTMLMediaElement).pause();
      }
    }
  }, [isCurrentVideo]);

  return (
    <H1_FlexColumn
      gap="10px"
      align="center"
      justify="center"
      className={`video-outer-container-${video.id}`}
    >
      <MainVideoContainerFlexRow
        flex="0 0 auto"
        width="min(65vw, 1100px)"
        justify="center"
        align="center"
        gap="22px"
        className={`video-container-${video.id}`}
      >
        <H1_FlexRow gap="20px" width="100%">
          <MuxPlayer
            width="100%"
            debug={false}
            controls
            centerPlayButton
            playbackId={video.playback_id}
            reqId={video.pipeline_response.req_id}
            overflow="hidden"
            borderRadius="6px"
          />
          <ChannelsPageSingleVideoSideButtons
            showForkButton={location.pathname !== Location.Community}
          />
        </H1_FlexRow>
      </MainVideoContainerFlexRow>
      <MainTaglineContainerFlexRow
        justify="space-between"
        className={`video-tagline-${video.id}`}
        alignSelf="flex-start"
      >
        <H1_FlexRow gap="15px" align="center" justify="flex-start" alignSelf="flex-start">
          {video.created_by_picture ? (
            <ReactionAvatar size={45} src={video.created_by_picture} />
          ) : (
            <ReactionAvatar size={45}>
              {video.created_by_name ? video.created_by_name[0] : "U"}
            </ReactionAvatar>
          )}
          <H1_FlexColumn gap="10px" justify="flex-end">
            <H1_TextSubtitle lineHeight="20px" whiteSpace="pre-wrap">
              {getVideoTitle(video)}
            </H1_TextSubtitle>
            <VideoTags videoId={video.id} isOwner={video.created_by === auth.user?.sub} />
          </H1_FlexColumn>
        </H1_FlexRow>
        <H1_FlexRow
          gap="10px"
          height="24px"
          align="center"
          alignSelf={
            !video?.tags?.length && video.created_by !== auth.user?.sub ? "center" : "flex-start"
          }
        >
          <H1_TextXs>{getTimeAgo(video.created_at)}</H1_TextXs>
          <VideoReactions />
        </H1_FlexRow>
      </MainTaglineContainerFlexRow>
    </H1_FlexColumn>
  );
};

export default ChannelsPageSingleVideo;
