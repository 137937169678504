import { defineMessages, useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import styled, { useTheme } from "styled-components";
import { Progress } from "antd";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import srcImage from "app/assets/images/empty-balance.png";
import useCredits from "app/hooks/useCredits";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import ConditionalRender from "app/components/common/ConditionalRender";
import useSmallScreen from "app/hooks/useSmallScreen";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextLargeHeadline,
  H1_TextSmall,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const messages = defineMessages({
  title: {
    id: "credits.modals.empty-credit.title",
    defaultMessage: "No More Minutes :("
  },
  subTitle: {
    id: "credits.modals.empty-credit.subtitle",
    defaultMessage: "You’ve reached your published minutes limit"
  },
  subTitlePreview: {
    id: "credits.modals.empty-credit.subtitle-preview",
    defaultMessage: "You’ve reached your preview minutes limit"
  },
  description: {
    id: "credits.modals.empty-credit.description",
    defaultMessage:
      "you will not be able to download or{br}embed video. Upgrade to get more minutes"
  },
  descriptionPreview: {
    id: "credits.modals.empty-credit.description-preview",
    defaultMessage: "you will not be able to create videos. Upgrade to get more minutes"
  },
  getCreditBtn: {
    id: "credits.modals.empty-credit.btn.get-credits",
    defaultMessage: "Upgrade"
  },
  minutes: {
    id: "credits.modals.empty-credit.minutes",
    defaultMessage: "Minutes"
  },
  minutesLeft: {
    id: `credits.modals.empty-credit.minutes-left`,
    defaultMessage: "{available} of {totalMinutes} minutes"
  },
  upgradeText: {
    id: "credits.modals.empty-credit.upgrade-text",
    defaultMessage: "Upgrade to get more minutes"
  }
});

const StyledFlexContainer = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    width: 100%;
    gap: 0;
    align-items: flex-start;
  }
`;

const ProgressBarFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex: 0 0 45px;
    width: 100%;
  }
`;

const ProgressContainerFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
    padding: 0;
  }
`;

const Rectangle = styled.svg`
  background-color: ${({ theme }) => theme.orange4};
  transform: rotate(14deg);
  width: 311px;
  height: 153px;
  left: 30px;
  top: 88px;
  position: absolute;
  z-index: 1;
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 2;
  width: 243px;
  height: 258px;
  left: 87px;
  top: 0;
  clip-path: polygon(0% 0%, 60% 0%, 100% 0%, 100% 100%, 67% 100%, 0% 85%);
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const NoEnoughCreditsModal = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isSmallScreen } = useSmallScreen();
  const { availableInMinutes, videoPercent, videoTotalNumberInMinutes } = useCredits();
  const { editorModalOpen, returnToModal, openModal } = useModal();
  const theme = useTheme();
  const visible = editorModalOpen?.open === ModalName.noEnoughCredits;

  const onContinue = () => {
    const upgradeText = intl.formatMessage(messages.upgradeText);
    const source = "no_enough_credits_modal";
    dispatch(googleEvents.productUpgradeCta({ cta: source }));
    openModal(ModalName.paymentModalV2, { source, upgradeText });
  };
  const onCancel = () => {
    returnToModal();
  };
  return (
    <StyledModal
      width="833px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "470px" }}
      maskClosable
      closable
      onCancel={onCancel}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="40px 62px 80px 51px"
      $alignItems="center"
    >
      <StyledFlexContainer width="100%" height="100%" align="center" gap="46px">
        <ProgressBarFlexColumn
          position="relative"
          flex="0 0 366px"
          padding="0 10px 0 0"
          height="100%"
        >
          <ConditionalRender condition={!isSmallScreen}>
            <Rectangle />
            <StyledImage src={srcImage} />
          </ConditionalRender>
          <ProgressContainerFlexColumn padding="45px 0 0 0">
            <Progress
              strokeColor={theme.gray10}
              trailColor={theme.gray7}
              showInfo={false}
              percent={parseInt(videoPercent, 10)}
              status="active"
            />
            <H1_FlexRow justify="space-between">
              <H1_TextXs>{intl.formatMessage(messages.minutes)}</H1_TextXs>
              <H1_TextXs>
                {intl.formatMessage(messages.minutesLeft, {
                  available: availableInMinutes.toFixed(),
                  totalMinutes: videoTotalNumberInMinutes.toFixed()
                })}
              </H1_TextXs>
            </H1_FlexRow>
          </ProgressContainerFlexColumn>
        </ProgressBarFlexColumn>
        <H1_FlexColumn width="308px" justify="space-between" height="100%" padding="15px 0 0 0">
          <H1_FlexColumn gap="12px">
            <H1_TextLargeHeadline whiteSpace="break-spaces" fontWeight={700} color={theme.gray10}>
              {intl.formatMessage(messages.title)}
            </H1_TextLargeHeadline>
            <H1_TextHeadline whiteSpace="break-spaces" fontWeight={600} color={theme.gray10}>
              {intl.formatMessage(messages.subTitle)}
            </H1_TextHeadline>
            <H1_TextSmall whiteSpace="break-spaces" color={theme.gray10}>
              {
                intl.formatMessage(messages.description, {
                  br: <br />
                }) as React.ReactNode
              }
            </H1_TextSmall>
          </H1_FlexColumn>
          <Button onClick={onContinue} color="primary">
            {intl.formatMessage(messages.getCreditBtn)}
          </Button>
        </H1_FlexColumn>
      </StyledFlexContainer>
    </StyledModal>
  );
};

export default NoEnoughCreditsModal;
