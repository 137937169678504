import React, { ReactNode } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Tag } from "antd";
import { SMALL_SCREEN_PX } from "app/config/Constants";

const BackgroundFlexRow = styled(H1_FlexRow)<{ $shadowColor?: string; $borderColor?: string }>`
  background-color: ${(props) => props.theme.gray1};
  border-radius: 64px;
  width: fit-content;
  border: ${({ $borderColor }) => ($borderColor ? `1px solid ${$borderColor}` : "none")};
  &:hover {
    box-shadow: 0 0 12px
      ${({ $shadowColor }) => ($shadowColor ? $shadowColor : "rgba(0, 0, 0, 0.1)")};
  }
  &:active {
    box-shadow: none;
  }
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    gap: 0;
    span {
      font-size: 10px;
    }
  }
`;

const AbsoluteTag = styled(Tag)`
  position: absolute;
  top: 9px;
  right: 20px;
  z-index: 6;
  height: 12px;
  font-size: 8px;
  line-height: 8px;
  padding-top: 1px;
  border-radius: 2px;
  margin: 0;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;
interface HomePageActionButtonProps {
  dataAutoId: string;
  title: string;
  icon: ReactNode;
  onClick: () => void;
  height?: string;
  shadowColor?: string;
  borderColor?: string;
  tag?: string;
  tagBackgroundColor?: string;
}

const HomePageActionButton = ({
  dataAutoId,
  title,
  icon,
  onClick,
  height,
  shadowColor,
  borderColor,
  tag,
  tagBackgroundColor
}: HomePageActionButtonProps) => {
  const theme = useTheme();
  return (
    <BackgroundFlexRow
      padding="14px 24px 14px 20px"
      gap="11px"
      onClick={onClick}
      align="center"
      position="relative"
      data-auto-id={dataAutoId}
      height={height || "auto"}
      $shadowColor={shadowColor}
      $borderColor={borderColor}
    >
      {icon}
      <H1_TextSmall color={theme.gray11}>{title}</H1_TextSmall>
      <ConditionalRender condition={!!tag}>
        <AbsoluteTag color={tagBackgroundColor}>{tag}</AbsoluteTag>
      </ConditionalRender>
    </BackgroundFlexRow>
  );
};

export default HomePageActionButton;
