import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch } from "app/hooks";
import { Channel } from "pusher-js";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import { voicesActions } from "app/store/slices/voices.slice";
import { extractUserIdForPusher, fetchingStatus } from "app/utils/helpers";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { playgroundActions } from "app/store/slices/playground.slice";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";
import * as Sentry from "@sentry/react";

interface FullAudioPreviewEvent {
  pair_info: { url: string; order_id: string };
  progress: number;
  status: PusherMessagesStatusEnum;
  order_id: string;
  url: string;
  scenes_timing: [][];
}
const PusherAudioPreviewV2 = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const { notifyError } = useErrors();

  useEffect(() => {
    let channel: Channel;
    const handleProgressUpdate = (message: FullAudioPreviewEvent) => {
      const {
        progress,
        status,
        scenes_timing: scenesTiming,
        pair_info: { url, order_id }
      } = message;
      if (status === PusherMessagesStatusEnum.ready) {
        dispatch(
          voicesActions.setVoicePreviewV2({
            orderId: order_id,
            url,
            scenesTiming,
            status: fetchingStatus.succeeded
          })
        );
        dispatch(
          playgroundActions.setVoicePreviewV2({
            orderId: order_id,
            url,
            status: fetchingStatus.succeeded
          })
        );
      } else if (progress === -1) {
        voicesActions.setVoicePreviewV2({ orderId: order_id, status: fetchingStatus.failed });
        Sentry.captureMessage(`Voice preview failed for order ${order_id}, url: ${url}`);

        dispatch(
          analyticsEvents.somethingWentWrongEvent({
            message: "Failed voice preview"
          })
        );
        dispatch(voicesActions.cleanFooterAudio());
        dispatch(playgroundActions.cleanPlaygroundAudio());
        notifyError({
          general: buildGeneralMessage(
            `Something went wrong`,
            "There is an issue with current voice, please select a different voice."
          )
        });
      }
    };
    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("full_audio_preview_v2", handleProgressUpdate);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherAudioPreviewV2;
