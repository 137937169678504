import { DraftVariable } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import React, { useMemo } from "react";
import EditableText from "app/components/editor/workflows/EditableText";
import styled from "styled-components";
import { getVariableColor } from "app/utils/helpers";
const VariableContainer = styled.div<{ $borderColor: string }>`
  display: flex;
  width: 115px;
  height: 29.096px;
  padding: 6.928px;
  justify-content: center;
  align-items: center;
  gap: 6.928px;
  flex-shrink: 0;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  border-radius: 5px;
  background: ${({ theme }) => theme.gray2};
  filter: drop-shadow(0px 0px 8.313px rgba(0, 0, 0, 0.1));
  font-size: 9px;
  i {
    color: #5a5aff;
  }
`;

const BorderInput = styled(H1_Input)<{ $borderColor: string }>`
  &&& input {
    border: 1px solid ${({ $borderColor }) => $borderColor};
    background-color: ${({ theme }) => theme.gray1};
    color: ${({ theme }) => theme.gray11};
  }
`;
const VariableItem = ({
  variable,
  viewOnly,
  onChangeVariableValue,
  hideVariableName,
  disabled
}: {
  variable: DraftVariable;
  viewOnly?: boolean;
  disabled?: boolean;
  hideVariableName?: boolean;
  onChangeVariableValue: (value: string) => void;
}) => {
  const draftId = useAppSelector((state) => state.drafts.currentDraft.id);
  const color = useMemo(() => {
    return getVariableColor(variable.id);
  }, [variable.id]);

  const dispatch = useAppDispatch();
  const onEditVariable = (value: string) => {
    dispatch(
      draftsActions.updateVariableRequest({
        draftId: draftId as string,
        variableId: variable.id,
        variable: { display_title: value }
      })
    );
  };
  const onEditVariableDescription = (value: string) => {
    dispatch(
      draftsActions.updateVariableRequest({
        draftId: draftId as string,
        variableId: variable.id,
        variable: { description: value }
      })
    );
  };

  const onChange = (event: any) => {
    onChangeVariableValue(event.target.value);
  };

  return (
    <H1_FlexColumn padding={"10px 20px"} width={"100%"} gap="8px" flex="0 0 auto">
      <H1_FlexRow justify={"space-between"} align={"center"}>
        <EditableText
          viewOnly={viewOnly}
          value={variable.display_title || variable.name}
          text={<H1_TextSmall>{variable.display_title || variable.name}</H1_TextSmall>}
          onSave={onEditVariable}
          maxWidth={"100px"}
        />
        <ConditionalRender condition={!hideVariableName}>
          <VariableContainer $borderColor={color}>
            <i className="fa-solid fa-cube"></i>
            <H1_TextXs>{variable.name}</H1_TextXs>
          </VariableContainer>
        </ConditionalRender>
      </H1_FlexRow>
      <BorderInput
        $borderColor={color}
        disabled={disabled}
        initialValue={variable.default_value}
        onChange={onChange}
        minWidth={"100%"}
      />

      <EditableText
        viewOnly={viewOnly}
        value={variable.description || ""}
        text={
          <H1_TextSmall color={"#BFBFBF"}>
            {variable.description || "Add variable description"}
          </H1_TextSmall>
        }
        onSave={onEditVariableDescription}
        maxWidth={"100px"}
      />
    </H1_FlexColumn>
  );
};

export default VariableItem;
