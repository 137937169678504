import styled, { useTheme } from "styled-components";
import { Select, Selection, SelectItem, SelectProps } from "@nextui-org/react";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import React, { Key, ReactNode } from "react";
import { ThemeMode } from "app/utils/theme";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { SelectSlots, SlotsToClasses } from "@nextui-org/theme";

const StyledSelect = styled(Select)<{ $maxWidth?: string }>`
  flex: 1 0 auto;
  max-width: ${({ $maxWidth }) => $maxWidth};
  &&& button {
    transition: background-color 0.3s ease-in-out;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray3 : theme.gray2};
    &:hover {
      background-color: ${({ theme }) =>
        theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray3};
    }
    label {
      color: ${({ theme }) => theme.gray7};
    }
    span[data-slot="value"] {
      color: ${({ theme }) => (ThemeMode.Light ? theme.gray6 : theme.gray5)};
    }
    svg path {
      fill: ${({ theme }) => theme.gray11};
    }
  }
`;

export interface SelectItemsProps {
  key: string;
  label: string;
  isRequired?: boolean;
  selectorIcon?: ReactNode;
  startContent?: React.ReactNode;
  endContent?: ReactNode;
  placeholder?: string;
  showScrollIndicators?: boolean;
  onClose?: () => void;
  classNames?: SlotsToClasses<SelectSlots>;
}

interface NextSelectProps extends Omit<SelectProps, "children"> {
  size?: "sm" | "md" | "lg" | undefined;
  maxWidth?: string;
  items: SelectItemsProps[];
  label?: string | ReactNode;
  placeholder?: string;
  className?: string;
  classNames?: SlotsToClasses<SelectSlots>;
  selectedKeys?: Key[];
  onSelectionChange: (value: Selection) => void;
}
const NextSelect = ({
  size,
  items,
  maxWidth,
  label,
  placeholder,
  selectedKeys,
  className,
  classNames,
  onSelectionChange,
  ...props
}: NextSelectProps) => {
  const theme = useTheme();
  return (
    <StyledSelect
      size={size}
      $maxWidth={maxWidth}
      label={label}
      placeholder={placeholder}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      className={className}
      classNames={classNames}
      {...props}
    >
      {items.map((useCase) => (
        <SelectItem
          startContent={useCase.startContent}
          textValue={useCase.label}
          key={useCase.key}
          selectedIcon={
            selectedKeys &&
            selectedKeys[0] === useCase.key && <H1_Icon color={theme.gray11} icon="far fa-check" />
          }
        >
          <H1_TextSmall color={theme.gray8}>{useCase.label}</H1_TextSmall>
        </SelectItem>
      ))}
    </StyledSelect>
  );
};

export default NextSelect;
