import Pusher from "pusher-js";
import Config from "app/config/Config";

const pusher = new Pusher(Config.pusherApiKey, {
  cluster: Config.pusherApiCluster
});

if (["development", "test"].includes(Config.env)) {
  Pusher.logToConsole = true;
}

export default pusher;
