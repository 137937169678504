import React, { ReactNode, useMemo } from "react";
import styled from "@emotion/styled";
import { ReactComponent as CheckIcon } from "app/assets/pricing/checkIcon.svg";
import { ReactComponent as InfiniteIcon } from "app/assets/pricing/infiniteIcon.svg";
import { PlanValues } from "app/pages/pricing/PricingPlans";

export const FeatureWrapper = styled.div`
  text-align: center;
  display: block;
`;

interface FeatureCellProps {
  plans: PlanValues;
  plan: string;
}
const FeatureCell = ({ plans, plan }: FeatureCellProps) => {
  const feature = useMemo(() => {
    let res: string | number | ReactNode | null;
    switch (plans[plan]) {
      case "v":
        res = <CheckIcon />;
        break;
      case "infinite":
        res = <InfiniteIcon />;
        break;
      default:
        res = plans[plan] || null;
    }

    return res;
  }, [plans, plan]);

  return <FeatureWrapper>{feature}</FeatureWrapper>;
};

export default FeatureCell;
