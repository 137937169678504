import { useAppDispatch } from "app/hooks";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { videosActions } from "app/store/slices/videos.slice";
import { MediaFileType, Video, VideoResolution } from "app/types";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import { paymentModalMessages } from "app/pages/pricing/messages";
import { PlanFeature } from "app/config/planFeature";

interface DownloadProps {
  source: string;
  video: Video;
  draftId: string;
  fileType: MediaFileType;
  backModal?: ModalName;
}

interface UseDownloadProps {
  resolution?: VideoResolution;
}
const useDownload = (options?: Partial<UseDownloadProps>) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const showPaymentModal = useUpgradeModal();
  const upgradeText = formatMessage(paymentModalMessages.upgradeDownloadTitle);

  const openDownloadModal = ({ source, video, backModal }: Partial<DownloadProps>) => {
    const videoId = video?.id;
    const reqId = video?.req_id;
    if (
      !video?.paid &&
      showPaymentModal({
        source: source as string,
        reqId,
        upgradeText,
        backModal,
        requestedFeature:
          options?.resolution === VideoResolution["1080p"]
            ? PlanFeature.DownloadVideoFullHd
            : PlanFeature.DownloadVideo
      })
    ) {
      return;
    }
    openModal(ModalName.downloadVideo, { videoId, reqId: video?.req_id, source }, backModal);
  };

  const downloadVideo = ({ source, video, draftId, backModal, fileType }: DownloadProps) => {
    dispatch(googleEvents.downloadIntent({ source }));
    const videoId = video.id;
    const reqId = video.req_id;
    if (!video.paid && showPaymentModal({ source, reqId, upgradeText, backModal })) {
      return;
    }
    dispatch(googleEvents.downloadVideo({ draftId, req_id: video.req_id }));
    dispatch(
      videosActions.downloadTranscodeVideoRequest({
        fileType,
        resolution: options?.resolution as VideoResolution,
        draftId,
        videoId,
        backModal: source
      })
    );
  };

  return { downloadVideo, openDownloadModal };
};

export default useDownload;
