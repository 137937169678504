import React from "react";
import { Tooltip, TooltipProps } from "@nextui-org/react";

const NextUITooltip = ({ classNames, ...props }: TooltipProps) => {
  return (
    <Tooltip
      classNames={{
        content: "text-gray-11 bg-gray-2 z-[5000]",
        ...classNames
      }}
      {...props}
    />
  );
};

export default NextUITooltip;
