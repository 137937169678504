import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import styled from "styled-components";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import { useIntl } from "react-intl";
import { digitalTwinCreationMessages } from "app/pages/createDigitalTwinPage/messages";
import { useEffect } from "react";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { VirtualTwinSteps } from "app/types/virtualTwin";
import { Button } from "@nextui-org/react";

const VideoPreview = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.gray10};
  aspect-ratio: 16/9;
  display: flex;
  border-radius: 10px;
  div {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
  }
  & > * {
    flex: 1;
    color: ${({ theme }) => theme.gray1};
    justify-content: center;
    span {
      width: 100%;
      text-align: center;
      display: block;
    }
  }
`;

const PaddingButton = styled(Button)`
  padding: 20px 30px;
  margin: 50px auto 20px;
`;

interface IntroStepProps {
  onFinish: () => void;
}
const IntroStep = ({ onFinish }: IntroStepProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(googleEvents.avatarWebcamSessionProgress({ stage: VirtualTwinSteps.intro }));
  }, []);

  return (
    <H1_FlexColumn align="center" justify="center" gap="15px">
      <H1_TextMidHeadline whiteSpace="normal" textAlign="center">
        {intl.formatMessage(digitalTwinCreationMessages.avatarCreationStepIntroHeadline)}
      </H1_TextMidHeadline>

      <VideoPreview>
        <MuxPlayer playbackId="8RnA6XffdDWIWpYCEhIfs1Z1DV9QC01fAmhy8iZDSelU" autoPlay={true} />
      </VideoPreview>

      <PaddingButton color="primary" onClick={onFinish}>
        {intl.formatMessage(digitalTwinCreationMessages.nextButtonLabel)}
      </PaddingButton>
    </H1_FlexColumn>
  );
};
export default IntroStep;
