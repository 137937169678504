import React, { useState } from "react";
import { Image, Tooltip } from "antd";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getScenePreviewLoading,
  getTextsAssetFromLayoutWithoutTranscript
} from "app/store/selectorsV2/scenes.selectors";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "app/types/featureFlags";
import ConditionalRender from "app/components/common/ConditionalRender";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import { useIntl } from "react-intl";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import { SceneTabs } from "app/types";
import { scenesActions } from "app/store/slices/scenes.slice";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { CorneredIconButton } from "app/components/editor/scene/Scene";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import { fetchingStatus, imagePlaceholder } from "app/utils/helpers";

export interface ScenePreviewProps {
  sceneId: string;
  disabledPreview?: boolean;
  url?: string;
  height?: string;
  width?: string;
}
const SpinnerWrapper = styled.div`
  ${({ width }: { width: string }) => css`
    width: ${width};
  `};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  background: ${(props) => props.theme.gray3};
`;

const ContentButtonContainer = styled(H1_FlexColumn)`
  z-index: 2;
  position: absolute;
  top: 47px;
  right: 13px;
`;
const ContentButton = styled(CorneredIconButton)<{ $visible: boolean }>`
  opacity: ${({ $visible }) => $visible && "1"};
  color: ${({ theme }) => theme.gray11};
`;
const LayoutButtonContainer = styled(H1_FlexColumn)`
  z-index: 2;
  position: absolute;
  top: 13px;
  right: 13px;
`;
const LayoutButton = styled(CorneredIconButton)<{ $visible: boolean }>`
  opacity: ${({ $visible }) => $visible && "1"};
  height: 28px;
  width: 28px;
  color: ${({ theme }) => theme.gray11};
`;
const PreviewButtonContainer = styled(H1_FlexColumn)`
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
`;
const PreviewButton = styled(CorneredIconButton)<{ $visible: boolean }>`
  opacity: ${({ $visible }) => $visible && "1"};
  height: 28px;
  width: 28px;
  color: ${({ theme }) => theme.gray11};
`;

const StyledImage = styled(Image)<{ $height: string; $width: string; $aspectRatio: number }>`
  display: flex;
  justify-content: center;
  aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
  // (# TODO: make it work with other false / css value)
  opacity: ${(props: { $aspectRatio: number }) => (props.$aspectRatio ? 1 : 0)};
  transition: all 0.3s ease-in;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
`;

const ScenePreview = ({ sceneId, url, height, width, disabledPreview }: ScenePreviewProps) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [error, setError] = useState<boolean>();
  const { openDrawer } = useDrawer();
  const flags = useFlags();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => getScenePreviewLoading(state, sceneId));
  const aspectRatio = useAppSelector(templatesSelectors.getAspectRatio);
  const scene = useAppSelector((state) => scenesGlobalSelectors.selectById(state, sceneId));
  const patchSceneLayoutStatus = useAppSelector((state) => state.scenes.patchSceneLayoutStatus);
  const patchSceneIdLayout = useAppSelector((state) => state.scenes.patchSceneIdLayout);
  const textAssets = useAppSelector((state) =>
    getTextsAssetFromLayoutWithoutTranscript(state, sceneId)
  );
  const selectedSceneId = useAppSelector((state) => state.scenes.selectedSceneId);
  const isButtonVisible = selectedSceneId === sceneId;
  const media = scene?.layout?.assets?.visual || scene?.layout?.assets?.media;
  const isMediaExists = media && media.length > 0;
  const isShowContentButton = textAssets?.length > 0 || isMediaExists;
  const isPatchSceneLayoutStatusLoading = patchSceneLayoutStatus === fetchingStatus.loading;
  if (!flags[FeatureFlag.framePreviewFeature]) {
    return null;
  }

  const isSceneLoading =
    loading || (isPatchSceneLayoutStatusLoading && patchSceneIdLayout === sceneId);
  if (isSceneLoading) {
    return (
      <SpinnerWrapper width={width || "246px"}>
        <StyledSkeletonButton
          active
          block
          size="large"
          $width="100%"
          $height="calc(294px * 9 / 16)"
        />
      </SpinnerWrapper>
    );
  }

  const onClickSceneContent = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(analyticsEvents.changeSceneTab({ source: "scenePreview", tab: SceneTabs.Content }));
    dispatch(scenesActions.setSelectedSceneId(sceneId));
    dispatch(scenesActions.updateSceneTab(SceneTabs.Content));
    openDrawer(Drawer.Scene);
  };

  const onClickSceneLayout = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(analyticsEvents.changeSceneTab({ source: "scenePreview", tab: SceneTabs.Layout }));
    dispatch(scenesActions.setSelectedSceneId(sceneId));
    dispatch(scenesActions.updateSceneTab(SceneTabs.Layout));
    openDrawer(Drawer.Scene);
  };

  return (
    <H1_FlexColumn flex="1" position="relative" align="center">
      <StyledImage
        preview={{
          visible: isPreviewVisible,
          mask: null,
          onVisibleChange: (visible, prevVisible) => prevVisible && setPreviewVisible(visible)
        }}
        $aspectRatio={aspectRatio}
        $height={height || "112px"}
        $width={width || "auto"}
        src={url || imagePlaceholder}
        fallback={imagePlaceholder}
        onError={() => {
          setError(true);
        }}
        onLoad={() => {
          setError(false);
        }}
      />
      <ConditionalRender condition={!error && !disabledPreview}>
        <Tooltip title={formatMessage(sceneMessages.previewTooltip)} placement="left">
          <PreviewButtonContainer>
            <PreviewButton
              $iconSize="28px"
              startContent={<i className="fal fa-magnifying-glass" />}
              isIconOnly
              onClick={() => setPreviewVisible(!isPreviewVisible)}
              $visible={isButtonVisible}
            />
          </PreviewButtonContainer>
        </Tooltip>
        <Tooltip title={formatMessage(sceneMessages.layoutTooltip)} placement="right">
          <LayoutButtonContainer>
            <LayoutButton
              $iconSize="28px"
              isIconOnly
              onClick={onClickSceneLayout}
              $visible={isButtonVisible}
              startContent={<i className="fal fa-table-layout" />}
            />
          </LayoutButtonContainer>
        </Tooltip>
        <ConditionalRender condition={isShowContentButton}>
          <Tooltip title={formatMessage(sceneMessages.contentTooltip)} placement="right">
            <ContentButtonContainer>
              <ContentButton
                $iconSize="28px"
                isIconOnly
                onClick={onClickSceneContent}
                $visible={isButtonVisible}
                startContent={<i className="fal fa-image" />}
              />
            </ContentButtonContainer>
          </Tooltip>
        </ConditionalRender>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default ScenePreview;
