import React, { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { Button, Space } from "antd";
import { ReactComponent as SadFaceIcon } from "app/assets/editor/sad-face.svg";
import { useIntl } from "react-intl";
import { videoFailedModalMessages } from "app/pages/editor/messages";
import { SUPPORT_EMAIL } from "app/utils/helpers";
import LobsterText from "app/components/common/LobsterText";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { StyledModal } from "app/components/common/StyledModal";

const HeaderPoppinsRedBold = styled("span")`
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  margin-top: 20px;
  text-align: center;
  color: ${({ theme }) => theme.gray10};
`;

const ParagraphPoppins = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 22px;
`;

const StyledSpace = styled(Space)`
  justify-content: center;
  align-items: center;
`;

const GrowSpace = styled(StyledSpace)`
  flex-grow: 1;
`;

const FixedSpace = styled(StyledSpace)`
  flex: 0 0 auto;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
`;

const AnimatedSadFaceIcon = styled(SadFaceIcon)`
  animation: ${pulse} 3s ease-in-out infinite;
  margin-bottom: 27px;
  circle,
  rect,
  path {
    fill: ${({ theme }) => theme.gray2};
  }
  g path {
    fill: ${({ theme }) => theme.gray9};
  }
`;

const VideoFailedModal = () => {
  const { formatMessage } = useIntl();
  const { closeModal, editorModalOpen } = useModal();
  const visible = editorModalOpen?.open === ModalName.videoFailed;

  return (
    <StyledModal
      width="867px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "671px" }}
      onCancel={closeModal}
      closeIcon={<CloseModalIcon />}
      $justifyContent="space-between"
      $padding="44px 42px 45px 55px"
      $alignItems="center"
    >
      <GrowSpace direction="vertical">
        <AnimatedSadFaceIcon />
        <HeaderPoppinsRedBold>
          {
            // @ts-ignore handels intl formatting with elements issue
            formatMessage(videoFailedModalMessages.title, {
              span: (...text) => <LobsterText>{text}</LobsterText>
            }) as ReactNode
          }
        </HeaderPoppinsRedBold>
        <ParagraphPoppins>
          {formatMessage(videoFailedModalMessages.failedRendering, { br: <br /> }) as ReactNode}
        </ParagraphPoppins>
      </GrowSpace>
      <FixedSpace direction="vertical">
        <Button type="primary" onClick={closeModal}>
          {formatMessage(videoFailedModalMessages.continueButton)}
        </Button>
        <Button type="link" href={SUPPORT_EMAIL}>
          {formatMessage(videoFailedModalMessages.contactSupportLink)}
        </Button>
      </FixedSpace>
    </StyledModal>
  );
};

export default VideoFailedModal;
