import React, { useMemo, useState } from "react";
import {
  importDocumentsModalMessages,
  urlModalUploaderMessages
} from "app/components/editor/documents/messages";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled, { useTheme } from "styled-components";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import { DraftGeneratorType, VideoLength } from "app/types";
import { MenuProps, Radio, RadioChangeEvent } from "antd";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button } from "@nextui-org/react";

const StyledDropdown = styled(H1_Dropdown)`
  border: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  color: ${(props) => props.theme.gray11};
  background-color: ${({ theme }) => theme.gray2};
  transition: background-color 0.3s ease-in-out;
  width: 274px;
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
    color: ${(props) => props.theme.gray11};
  }
  padding: 10px;
  &.url-settings {
    .ant-dropdown {
      width: auto;
      .ant-dropdown-menu {
        padding: 10px;
        li.clickable:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    max-height: 180px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.gray4};
    .clickable {
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.gray4};
    }
    .selected {
      border: 1px solid ${({ theme }) => theme.blue4};
    }
    font-size: 14px;
    .ant-dropdown-menu-item {
      padding: 5px 10px;
      i {
        color: ${({ theme }) => theme.gray1};
      }
      &:hover {
        background-color: ${({ theme }) => theme.gray3};
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 5px 10px;
      color: ${({ theme }) => theme.gray7};
    }
  }
`;

const ChangeButton = styled(Button)`
  && {
    background-color: transparent;
  }
  &:hover {
    text-decoration: underline;
  }
`;
export interface UrlSettingsContentProps {
  reset: () => void;
  onFinish: (videoLength: VideoLength, textType: DraftGeneratorType) => void;
}

const UrlSettingsContent = ({ reset, onFinish }: UrlSettingsContentProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [textType, setTextType] = useState<DraftGeneratorType>(DraftGeneratorType.Summarize);
  const [videoLength, setVideoLength] = useState<VideoLength>(VideoLength.sec30);
  const videoLengthList = [
    {
      label: intl.formatMessage(urlModalUploaderMessages.seconds15),
      value: VideoLength.sec15
    },
    {
      label: intl.formatMessage(urlModalUploaderMessages.seconds30),
      value: VideoLength.sec30
    },
    {
      label: intl.formatMessage(urlModalUploaderMessages.seconds45),
      value: VideoLength.sec45
    },
    {
      label: intl.formatMessage(urlModalUploaderMessages.seconds60),
      value: VideoLength.sec60
    },
    {
      label: intl.formatMessage(urlModalUploaderMessages.seconds75),
      value: VideoLength.sec75
    }
  ];

  const onChangeVideoLength = (e: RadioChangeEvent) => {
    setVideoLength(e.target.value as VideoLength);
  };
  const onCreateVideo = () => {
    onFinish(videoLength, textType);
  };
  const onSelectSummarize = () => {
    setTextType(DraftGeneratorType.Summarize);
  };

  const textTypeTitle: string = useMemo(() => {
    switch (textType) {
      case DraftGeneratorType.Summarize:
        return intl.formatMessage(importDocumentsModalMessages.Summarize);
      default:
        return intl.formatMessage(importDocumentsModalMessages.Summarize);
    }
  }, [textType]);

  const textTypeItems: MenuProps["items"] = useMemo(() => {
    return [
      {
        className: `playback-menu clickable ${
          textType === DraftGeneratorType.Summarize ? "selected" : ""
        }`,
        key: "Summarize",
        onClick: onSelectSummarize,
        label: <H1_TextXs>{intl.formatMessage(importDocumentsModalMessages.Summarize)}</H1_TextXs>
      }
    ];
  }, [textType]);
  return (
    <>
      <H1_FlexRow padding="20px 0 0 0" width="100%" gap="20px" align="center" height="100%">
        <H1_FlexColumn height="100%" justify="space-between" width="100%">
          <H1_FlexColumn gap="37px" width="100%" flex-shrink="0">
            <H1_FlexColumn>
              <H1_TextSmall
                color={theme.gray6}
                font-weight="500"
                line-height="32px"
                font-style="normal"
              >
                {intl.formatMessage(importDocumentsModalMessages.wishToDoTitle)}
              </H1_TextSmall>
              <StyledDropdown
                withChevron
                title={textTypeTitle}
                destroyPopupOnHide
                trigger={["click"]}
                menu={{ items: textTypeItems }}
                className="url-settings"
                getPopupContainer={() => document.querySelector(`.url-settings`) as HTMLElement}
              >
                <H1_Icon icon="fa-regular fa-chevron-down" size="22px" />
              </StyledDropdown>
            </H1_FlexColumn>
            <H1_FlexColumn>
              <H1_TextSmall
                color={theme.gray6}
                fontSize="14px"
                font-weight="500"
                line-height="32px"
                font-style="normal"
              >
                {intl.formatMessage(importDocumentsModalMessages.videoLengthTitle)}
              </H1_TextSmall>
              <Radio.Group
                font-size="18px"
                onChange={onChangeVideoLength}
                value={videoLength}
                options={videoLengthList}
              ></Radio.Group>
            </H1_FlexColumn>
          </H1_FlexColumn>
          <H1_FlexRow gap="14px" align="center" justify="space-between" width="100%">
            <ChangeButton variant="light" color="primary" onClick={reset}>
              {intl.formatMessage(importDocumentsModalMessages.ChangeButton)}
            </ChangeButton>
            <Button color="primary" onClick={onCreateVideo}>
              {intl.formatMessage(importDocumentsModalMessages.CreateVideoButton)}
            </Button>
          </H1_FlexRow>
        </H1_FlexColumn>
      </H1_FlexRow>
    </>
  );
};

export default UrlSettingsContent;
