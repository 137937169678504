import styled, { css, useTheme } from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

const StyledFlexRow = styled(H1_FlexRow)<{ $noBorder?: boolean }>`
  /* opacity: 0.4; */
  ${({ $noBorder, theme }) =>
    !$noBorder &&
    css`
      border: 1px solid ${theme.gray6};
      border-radius: 2px;
    `};
`;

const ButtonWrapper = styled(H1_FlexRow)`
  cursor: pointer;
`;

interface BackButtonProps {
  onClick: () => void;
  displayText?: boolean;
  noBorder?: boolean;
}
const messages = defineMessages({
  backButtonLabel: {
    id: `style-drawer.back-button-label`,
    defaultMessage: "BACK"
  }
});
const BackButton = ({ onClick, noBorder = false, displayText = true }: BackButtonProps) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <ButtonWrapper onClick={onClick} gap="16px" align="center">
      <StyledFlexRow
        width="24px"
        height="32px"
        justify="center"
        align="center"
        $noBorder={noBorder}
      >
        <H1_Icon icon="fa-solid fa-chevron-left" />
      </StyledFlexRow>
      <ConditionalRender condition={displayText}>
        <H1_TextSmall fontWeight={500} color={theme.gray8}>
          {intl.formatMessage(messages.backButtonLabel)}
        </H1_TextSmall>
      </ConditionalRender>
    </ButtonWrapper>
  );
};

export default BackButton;
