import styled from "styled-components";
import React, { MouseEventHandler } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ThemeMode } from "app/utils/theme";

const Container = styled(H1_FlexRow)`
  font-size: 18px;
  padding-bottom: 10px;
  border: none;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  line-height: 22px;
  white-space: pre;
`;

export interface TitleFlexRowProps<T> {
  height: string;
  justify?: string;
  className?: string;
  onClick?: MouseEventHandler<T>;
  children?: React.ReactNode;
}

const TitleFlexRow = ({
  className,
  height,
  justify,
  onClick,
  children
}: TitleFlexRowProps<HTMLDivElement>) => {
  return (
    <Container
      flex="0 0 auto"
      height={height}
      align="center"
      justify={justify || "normal"}
      className={className}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};
export default TitleFlexRow;
