import { useOthersMapped, useUpdateMyPresence } from "app/services/liveBlocksClient";
import { AnimatePresence, motion } from "framer-motion";
import { Avatar, Tooltip } from "antd";
import styled, { useTheme } from "styled-components";
import React, { useEffect, useMemo } from "react";
import { avatarColorsList } from "app/types";
import { useAuth } from "app/auth/useAuth";
import { useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import ConditionalRender from "app/components/common/ConditionalRender";
import { capitalize } from "lodash-es";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const MAX_OTHERS = 3;

const animationProps = {
  initial: { width: 0, transformOrigin: "left" },
  animate: { width: "40px", height: "40px" },
  exit: { width: 0 },
  transition: {
    type: "spring",
    damping: 15,
    mass: 1,
    stiffness: 200,
    restSpeed: 0.01
  }
};

const MotionFlexRow = styled(motion.div)`
  display: flex;
  margin-right: -12px;
`;

const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  outline-width: 3px;
  outline-color: white;
  background-color: ${(props: { $color: string }) => props.$color};
  > span {
    margin-right: -0.75rem;
    color: ${(props) => props.theme.gray11};
  }
  span {
    font-size: 20px;
    padding-top: 3px;
    min-width: 11px;
    transform: scale(1) translateX(-50%) !important; /* Weird Avatar bug sometimes */
  }
`;

const StyledFlexRow = styled(H1_FlexRow)`
  div {
    min-height: 40px;
  }
`;

const LiveAvatars = () => {
  const others = useOthersMapped((other) => other.presence.email);
  const { user } = useAuth();
  const theme = useTheme();
  const updateMyPresence = useUpdateMyPresence();
  const { sceneId } = useSelectedScene();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const showExtraUsersShortcut = others.length > 3;

  const userFirstLetter = useMemo(() => {
    return capitalize(user?.email?.charAt(0));
  }, [user?.email]);

  const otherAvatars = useMemo(() => {
    return others.slice(0, MAX_OTHERS).reverse();
  }, [others]);

  useEffect(() => {
    if ((currentWorkspace?.members.length || 0) > 1 && sceneId) {
      if (updateMyPresence) {
        updateMyPresence({ sceneId, email: user?.email as string });
      }
    }
  }, [others, sceneId, currentWorkspace]);

  if (!currentWorkspace || currentWorkspace.members.length === 1 || others.length === 0) {
    return null;
  }

  return (
    <AnimatePresence>
      <StyledFlexRow height="40px" align="center" padding="0 11px">
        <ConditionalRender condition={showExtraUsersShortcut}>
          <MotionFlexRow key="count" {...animationProps}>
            <StyledAvatar $color={avatarColorsList(theme)[MAX_OTHERS]}>
              <span>{others.length - MAX_OTHERS}</span>
            </StyledAvatar>
          </MotionFlexRow>
        </ConditionalRender>

        {otherAvatars.map(([key, email], index) => (
          <MotionFlexRow key={key} {...animationProps}>
            <Tooltip title={email}>
              <StyledAvatar
                $color={avatarColorsList(theme)[(index + 1) % avatarColorsList(theme).length]}
              >
                <span>{capitalize(email?.charAt(0))}</span>
              </StyledAvatar>
            </Tooltip>
          </MotionFlexRow>
        ))}

        <motion.div key="you" {...animationProps}>
          <Tooltip title={user?.email}>
            <StyledAvatar $color={avatarColorsList(theme)[0]}>
              <span>{userFirstLetter}</span>
            </StyledAvatar>
          </Tooltip>
        </motion.div>
      </StyledFlexRow>
    </AnimatePresence>
  );
};

export default LiveAvatars;
