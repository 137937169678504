import { Breadcrumb } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { fetchingStatus } from "app/utils/helpers";
import styled from "styled-components";
import Droppable from "app/pages/mediaLibrary/Droppable";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { mediaLibraryMessages } from "app/pages/mediaLibrary/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";

const RootBreadcrumb = styled(Breadcrumb.Item)`
  width: 100%;
  height: 32px;
`;
const StyledTextSmall = styled(H1_TextSmall)<{ $cursor: string }>`
  cursor: ${({ $cursor }) => $cursor};
`;
const StyledBreadcrumb = styled(Breadcrumb)`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel, sans-serif !important;
  font-weight: 400;
  padding-left: 5px;
  .droppable {
    border-radius: 30px;
  }
  &.ant-breadcrumb ol li {
    display: flex;
  }
  &.ant-breadcrumb ol {
    span,
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme.gray7};
    }
    .ant-breadcrumb-link span {
      color: ${(props) => props.theme.gray7};
    }
    li:not(:last-child):hover {
      span {
        color: ${(props) => props.theme.gray8};
      }

      &:active {
        span {
          color: ${(props) => props.theme.gray9};
        }
      }
    }
    > :last-child > .ant-breadcrumb-link {
      padding-left: 7px;
      margin: auto;
      > span {
        color: ${(props) => props.theme.gray11};
      }
    }
  }
`;

const MediaLibraryBreadCrumbs = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const rootFolderId = useAppSelector((state) => state.media.rootFolderId);
  const foldersStack = useAppSelector((state) => state.media.foldersStack);
  const mediaStatus = useAppSelector((state) => state.media.mediaStatus);
  const folderTargetLoadingId = useAppSelector((state) => state.media.folderTargetLoadingId);
  const disabled = mediaStatus === fetchingStatus.loading;
  const lastFolder = foldersStack?.at(-1);
  const isRootEnabled = !!foldersStack.length && !disabled;

  const onSelect = (index: number) => {
    dispatch(mediaActions.setFolderStackByIndex(index));
  };

  return (
    <StyledBreadcrumb>
      <RootBreadcrumb onClick={() => isRootEnabled && onSelect(0)}>
        <Droppable id={rootFolderId || ""}>
          <ConditionalRender
            condition={!!(folderTargetLoadingId === rootFolderId && folderTargetLoadingId)}
          >
            <CircleLoader size="32px" />
          </ConditionalRender>
          <ConditionalRender condition={folderTargetLoadingId !== rootFolderId}>
            <StyledTextSmall className="droppable" $cursor={isRootEnabled ? "pointer" : "unset"}>
              {formatMessage(mediaLibraryMessages.root)}
            </StyledTextSmall>
          </ConditionalRender>
        </Droppable>
      </RootBreadcrumb>

      {foldersStack.slice(0, foldersStack.length - 1).map((folder, index) => {
        return (
          <Breadcrumb.Item key={folder.id} onClick={() => onSelect(index + 1)}>
            <Droppable id={folder.id as string}>
              <ConditionalRender
                condition={!!(folderTargetLoadingId === folder.id && folderTargetLoadingId)}
              >
                <CircleLoader size="32px" />
              </ConditionalRender>
              <ConditionalRender condition={folderTargetLoadingId !== folder.id}>
                <StyledTextSmall className="droppable" $cursor="pointer">
                  {folder.name}
                </StyledTextSmall>
              </ConditionalRender>
            </Droppable>
          </Breadcrumb.Item>
        );
      })}
      <ConditionalRender condition={!!foldersStack.length}>
        <Breadcrumb.Item>
          <H1_TextSmall>{lastFolder?.name}</H1_TextSmall>
        </Breadcrumb.Item>
      </ConditionalRender>
    </StyledBreadcrumb>
  );
};

export default MediaLibraryBreadCrumbs;
