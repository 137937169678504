import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { Channel } from "pusher-js";
import { useAppDispatch } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";

const PusherSummary = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let channel: Channel;
    const handleSummaryUpdate = (message: {
      status: "ready" | "failed";
      draft_id: string;
      order_id: string;
    }) => {
      const { status, draft_id, order_id } = message;

      dispatch(
        draftsActions.setSummary({
          orderId: order_id,
          draftId: draft_id,
          status: status
        })
      );
    };
    if (user?.sub) {
      const userId = user.sub.split("|")[1];
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("summary_generated", handleSummaryUpdate);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherSummary;
