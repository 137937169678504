/* eslint-disable no-nested-ternary,react/jsx-props-no-spreading */
import styled, { css, useTheme } from "styled-components";
import { Direction, IconButtonProps } from "app/components/common/Icons/types";

const WithHoverAfter = styled.div<{ backgroundColor?: string; shrink?: boolean }>`
  border-radius: 24px;
  background-color: ${(props) => props.backgroundColor || props.theme.pink4};
  position: relative;
  overflow: hidden;
  & > *:not(:empty) {
    z-index: 1;
  }
  &::before {
    content: "";
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: auto;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: white;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    &::before {
      opacity: 0.3;
    }
  }
  &:active {
    background-color: ${(props) => props.backgroundColor};
    opacity: ${(props) => (props.backgroundColor ? 0.6 : 1)};
    transform: scale(${(props) => (props.shrink ? 0.95 : 1)});
  }
`;

const IconButtonContainer = styled(WithHoverAfter)`
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 8px;
  svg {
    fill: var(--gray-09, #262626);
  }

  &:focus {
    border: none;
  }
  ${({
    $active,
    disabled,
    direction,
    activeColor,
    color,
    disabledColor,
    backgroundColor,
    width,
    height
  }: {
    $active: boolean;
    disabled: boolean;
    direction: Direction;
    activeColor: string;
    color?: string;
    disabledColor: string;
    backgroundColor?: string;
    width: string;
    height: string;
  }) => css`
    flex-direction: ${direction === Direction.Horizontal ? "row" : "column"};
    color: ${disabled ? disabledColor : $active ? activeColor : color};
    background-color: ${backgroundColor || "transparent"};
    width: ${width};
    height: ${height};
    svg {
      fill: ${disabled ? disabledColor : $active ? activeColor : color};
      .background {
        fill: transparent;
      }
    }
    pointer-events: ${disabled ? "none" : "auto"};
  `};
`;

const IconButton = ({
  direction,
  className,
  active,
  disabled,
  activeColor,
  color,
  disabledColor,
  backgroundColor,
  height,
  width,
  shrink,
  icon,
  onClick,
  children,
  ...rest
}: IconButtonProps<HTMLDivElement>) => {
  const theme = useTheme();
  return (
    <IconButtonContainer
      disabled={disabled || false}
      height={height || "auto"}
      width={width || "auto"}
      $active={active || false}
      activeColor={activeColor || theme.pink4}
      color={color || "#0f0c1d"}
      disabledColor={disabledColor || theme.gray5}
      backgroundColor={backgroundColor}
      shrink={shrink}
      direction={direction || Direction.Horizontal}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {!!icon && icon}
      {children}
    </IconButtonContainer>
  );
};
export default IconButton;
