import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createKeys,
  deleteKey,
  deleteMfa,
  getKeys,
  getSlackChannels,
  getUserData,
  mFAEnroll,
  onBoarding,
  registerUser,
  setFavorite,
  shareSlackChannel,
  slackBind,
  slackInstallUrl,
  updateUserPreferences,
  userConnect
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { Preferences } from "app/types/preferences";
import {
  ApiKey,
  AppMetadata,
  FavoriteType,
  Feature,
  MFAMetadata,
  MFATypes,
  ThunkApi,
  UserMetadata
} from "app/types";

import { SlackChannel, SlackInstall } from "app/types/slack";
import { charactersActions } from "app/store/slices/characters.slice";
import { userActions } from "app/store/slices/user.slice";
import { paymentsActions } from "app/store/slices/payments.slice";
import { recipesActions } from "app/store/slices/recipes.slice";

const prefix = "[UserData]";

const getUserDataRequest = createAsyncThunk<
  {
    user_metadata: UserMetadata;
    app_metadata: AppMetadata;
    preferences: Preferences;
    features: Feature[];
    email: string;
    registered: boolean;
    refreshToken: boolean;
    auth_factors: MFAMetadata[];
  },
  { hubspotutk?: string; journey?: string } | undefined,
  ThunkApi
>(
  `${prefix} getUserDataRequest`,
  async (args) => {
    let res = await getUserData();
    let refreshToken = false;
    if (!res.registered) {
      await registerUser({
        journey: args?.journey,
        extra: args?.hubspotutk ? { hubspotutk: args?.hubspotutk } : undefined
      });
      res = await getUserData();
      refreshToken = true;
    }
    return { ...res, refreshToken };
  },
  thunkOptions
);

const registerUserRequest = createAsyncThunk<void, { journey?: string; hubspotutk?: string }>(
  `${prefix} registerUserRequest`,
  async ({ journey, hubspotutk }) => {
    await registerUser({ journey, extra: hubspotutk ? { hubspotutk } : undefined });
  },
  thunkOptions
);

const updateUserPreferencesRequest = createAsyncThunk(
  `${prefix} updateUserPreferencesRequest`,
  async (preferences: Preferences, thunkApi) => {
    if (preferences.current_workspace_id) {
      thunkApi.dispatch(recipesActions.setUseCache(false));
    }

    await updateUserPreferences(preferences);
  },
  thunkOptions
);

const createKeysRequest = createAsyncThunk<{ key: string }>(
  `${prefix} createKeysRequest`,
  async () => {
    const result = await createKeys();
    return result;
  },
  thunkOptions
);
const getKeysRequest = createAsyncThunk<ApiKey[]>(
  `${prefix} getKeysRequest`,
  async () => {
    const result = await getKeys();
    return result;
  },
  thunkOptions
);

const deleteKeyRequest = createAsyncThunk(
  `${prefix} deleteKeyRequest`,
  async (id: string) => {
    await deleteKey(id);
  },
  thunkOptions
);

const slackBindRequest = createAsyncThunk(
  `${prefix} slackBindRequest`,
  async (bindingId: string) => {
    await slackBind(bindingId);
  },
  thunkOptions
);

const slackInstallUrlRequest = createAsyncThunk<SlackInstall, void>(
  `${prefix} slackInstallUrlRequest`,
  async () => {
    const result = await slackInstallUrl();
    return result;
  },
  thunkOptions
);
const getSlackChannelsRequest = createAsyncThunk<{ data: SlackChannel[] }, void>(
  `${prefix} getSlackChannelsRequest`,
  async () => {
    const result = await getSlackChannels();
    return result;
  },
  thunkOptions
);

const setFavoriteRequest = createAsyncThunk<
  void,
  { id: string; type: FavoriteType; favorite: boolean },
  ThunkApi
>(
  `${prefix} setFavoriteRequest`,
  async ({ id, type, favorite }, thunkApi) => {
    if (type === FavoriteType.character) {
      thunkApi.dispatch(charactersActions.setFavorite({ id, favorite }));
    }
    await setFavorite(id, type, favorite);
  },
  thunkOptions
);

const slackShareRequest = createAsyncThunk<void, { channelId: string; videoId: string }>(
  `${prefix} slackShareRequest`,
  async ({ channelId, videoId }) => {
    await shareSlackChannel(channelId, videoId);
  },
  thunkOptions
);
const userConnectRequest = createAsyncThunk<
  void,
  { gcpToken?: string; msToken?: string; skip401ErrorHandling: boolean }
>(
  `${prefix} userConnectRequest`,
  async ({ gcpToken, msToken }) => {
    await userConnect({ gcpToken, msToken });
  },
  thunkOptions
);

const onBoardingRequest = createAsyncThunk<any, Record<string, any>>(
  `${prefix} onBoardingRequest`,
  async (userFinalAnswers: Record<string, any>, thunkAPI) => {
    await thunkAPI.dispatch(
      userActions.updateUserPreferencesRequest({
        ...userFinalAnswers,
        show_on_boarding_survey_v2: false
      })
    );
    await onBoarding();
    thunkAPI.dispatch(paymentsActions.getCreditsRequest());
  },
  thunkOptions
);

const mFAEnrollRequest = createAsyncThunk<any, { type: MFATypes }>(
  `${prefix} mFAEnrollRequest`,
  async ({ type }) => {
    const res = await mFAEnroll(type);
    return res;
  },
  thunkOptions
);

const deleteMFARequest = createAsyncThunk<string | undefined, { type: MFATypes }, ThunkApi>(
  `${prefix} deleteMFARequest`,
  async ({ type }, thunkApi) => {
    const { authFactors } = thunkApi.getState().user;
    const totpFactor = authFactors.find((authFactor: MFAMetadata) => authFactor.type === type);
    if (totpFactor) {
      await deleteMfa(totpFactor.id);
    }
    return totpFactor?.id;
  },
  thunkOptions
);

export default {
  getUserDataRequest,
  registerUserRequest,
  updateUserPreferencesRequest,
  createKeysRequest,
  getKeysRequest,
  deleteKeyRequest,
  slackBindRequest,
  slackInstallUrlRequest,
  getSlackChannelsRequest,
  slackShareRequest,
  setFavoriteRequest,
  userConnectRequest,
  onBoardingRequest,
  mFAEnrollRequest,
  deleteMFARequest
};
