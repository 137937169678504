import React, { memo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { Typography } from "antd";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import MediaGenericItem from "app/pages/mediaLibrary/MediaGenericItem";

const StyledAnimatePresence = styled(AnimatePresence)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 35px;
  flex: 2;
`;

const BoxFlexColumn = styled(H1_FlexColumn)`
  max-width: auto;
  padding: 15px;
`;
const NameText = styled(Typography.Text)`
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.gray8};
  white-space: break-spaces;
  text-align: center;
`;

interface GeneratedImageDisplayType {
  url: string;
  selected: boolean;
  handle: string;
  onClickImage: (url: string) => void;
  text?: string;
}

const GeneratedImageDisplay = ({
  url,
  selected,
  handle,
  onClickImage,
  text = undefined
}: GeneratedImageDisplayType) => {
  return (
    <StyledAnimatePresence>
      <motion.div
        layout
        key={handle}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1.3 }}
        exit={{ scale: 0 }}
        transition={{
          default: {
            duration: 0.5
          },
          scale: {
            damping: 2
          }
        }}
      >
        <BoxFlexColumn
          position="relative"
          overflow="hidden"
          gap="7px"
          flex="0 0 auto"
          justify="center"
          align="center"
          onClick={() => onClickImage(url)}
        >
          <MediaGenericItem url={url} selected={selected} id={handle} />
        </BoxFlexColumn>
      </motion.div>
      <NameText ellipsis={{ tooltip: text }}>{text}</NameText>
    </StyledAnimatePresence>
  );
};

const areEqual = (prevProps: GeneratedImageDisplayType, nextProps: GeneratedImageDisplayType) =>
  prevProps.handle === nextProps.handle;

export default memo(GeneratedImageDisplay, areEqual);
