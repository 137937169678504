import { useEffect } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import { useAuth } from "app/auth/useAuth";

const useGlobalKeyboardShortcut = () => {
  const { openModal, editorModalOpen } = useModal();
  const { user } = useAuth();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        if (!editorModalOpen?.open && user) {
          openModal(ModalName.globalSearch);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [user]);
};

export default useGlobalKeyboardShortcut;
