import { createSelector } from "reselect";
import { RootState } from "app/store/store";

const allTrayElms = (state: RootState) => state.uiV2.tray;

export const getTrayElmByKey = createSelector(
  [allTrayElms, (state, trayKey?: string) => trayKey],
  (trayElments, trayKey) => {
    if (!trayKey) return;
    return trayElments[trayKey];
  }
);

export default {
  getTrayElmByKey
};
