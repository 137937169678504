import React, { FC, useCallback } from "react";
import { Checkbox, Divider, Popover, Tooltip } from "antd";
import { allMediaTypeOptions, MediaType } from "app/types/media";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { mediaLibraryFiltersMessages } from "app/pages/mediaLibrary/messages";
import { useIntl } from "react-intl";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { getMediaTypeSelectedFilters } from "app/store/selectorsV2/media.selectors";
import { Button } from "@nextui-org/react";

const FilteredButton = styled(Button)<{ $background: string }>`
  && {
    background-color: ${({ $background }) => $background};
  }
`;
const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  padding-left: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.blue4};
    color: ${({ theme }) => theme.gray1};
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ theme }) => theme.blue4};
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.gray1};
    border-color: ${({ theme }) => theme.blue4};
    color: ${({ theme }) => theme.blue4};
  }
  .ant-checkbox-checked::after,
  &&.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-radius: 4px;
    border-color: ${({ theme }) => theme.blue4};
  }
  .ant-checkbox:hover::after,
  &&.ant-checkbox:hover .ant-checkbox-inner,
  &&.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    border-radius: 4px;
    border-color: ${({ theme }) => theme.blue3};
  }
`;

const FilterPopover: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const mediaFilter = useAppSelector(getMediaTypeSelectedFilters);
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();

  const isFiltered = mediaFilter.length !== allMediaTypeOptions.length;
  const handleFilterChange = (option: MediaType) => {
    dispatch(
      analyticsEvents.filterMedia({
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        },
        value: option
      })
    );

    dispatch(mediaActions.updateMediaFilter(option));
  };

  const getIntlText = useCallback((value: string) => {
    switch (value) {
      case MediaType.image:
        return formatMessage(mediaLibraryFiltersMessages.images);
      case MediaType.video:
        return formatMessage(mediaLibraryFiltersMessages.videos);
      case MediaType.folder:
        return formatMessage(mediaLibraryFiltersMessages.folders);
      default:
        return formatMessage(mediaLibraryFiltersMessages.all);
    }
  }, []);

  const content = (
    <H1_FlexColumn gap="5px" width="177px">
      <H1_TextXs padding="10px 10px 0" color={theme.gray7}>
        {formatMessage(mediaLibraryFiltersMessages.filterTitle)}
      </H1_TextXs>
      <Divider />
      <H1_FlexColumn gap="5px" padding="0 10px 10px">
        {allMediaTypeOptions.map((option) => (
          <H1_FlexRow key={option}>
            <StyledCheckbox
              checked={mediaFilter.includes(option)}
              onChange={() => handleFilterChange(option)}
            >
              <H1_TextXs>{getIntlText(option)}</H1_TextXs>
            </StyledCheckbox>
          </H1_FlexRow>
        ))}
      </H1_FlexColumn>
    </H1_FlexColumn>
  );

  return (
    <Tooltip title={formatMessage(mediaLibraryFiltersMessages.filterTitle)}>
      <>
        <Popover
          placement="left"
          content={content}
          overlayClassName="media-item-action-popover"
          trigger="click"
        >
          <FilteredButton
            $background={isFiltered ? theme.blue1 : "transparent"}
            variant="light"
            isIconOnly
            startContent={<i className="fa-light fa-filter"></i>}
          ></FilteredButton>
        </Popover>
      </>
    </Tooltip>
  );
};

export default FilterPopover;
