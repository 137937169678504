import styled, { useTheme } from "styled-components";
import { H1_TextLargeHeadline } from "app/components/_Infrastructure/Typography";

const HeadlinePoppinsText = styled(H1_TextLargeHeadline)`
  margin-bottom: 20px;
`;

interface PaymentModalHeadlinePoppinsTextProps {
  title: string | React.ReactNode;
}
const PaymentModalHeadlinePoppinsText = ({ title }: PaymentModalHeadlinePoppinsTextProps) => {
  const theme = useTheme();
  return (
    <HeadlinePoppinsText
      whiteSpace="break-spaces"
      lineHeight="40px"
      color={theme.gray10}
      fontSize="36px"
    >
      {title}
    </HeadlinePoppinsText>
  );
};

export default PaymentModalHeadlinePoppinsText;
