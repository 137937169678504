import React, { FC } from "react";
import { Divider, Tooltip } from "antd";
import { MediaTypeSort, SortOrder } from "app/types/media";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import { mediaLibraryFiltersMessages } from "app/pages/mediaLibrary/messages";
import { useIntl } from "react-intl";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Button, PopoverContent, PopoverTrigger, Popover } from "@nextui-org/react";

const SelectedButton = styled(Button)<{ $selected?: boolean }>`
  justify-content: flex-start;
  ${({ $selected, theme }) => ($selected ? `color:${theme.blue4}` : "")};
  padding-left: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.blue4};
    color: white;
    span {
      color: white;
    }
    i {
      color: white;
    }
  }
`;
const SortPopover: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();
  const mediaSort = useAppSelector((state) => state.media.mediaSort);
  const onSortMedia = (value: MediaTypeSort, order: SortOrder) => {
    dispatch(
      analyticsEvents.sortMedia({
        selectedScene: {
          name: scene?.name as string,
          id: sceneId,
          index: selectedSceneIndex
        },
        value
      })
    );
    dispatch(mediaActions.setMediaSort({ prop: value, order }));
  };

  const content = (
    <H1_FlexColumn width="177px">
      <H1_TextXs padding="10px 10px 0" color={theme.gray7}>
        {formatMessage(mediaLibraryFiltersMessages.lastModified)}
      </H1_TextXs>
      <Divider />
      <H1_FlexColumn padding="10px">
        <SelectedButton
          $selected={mediaSort.order === SortOrder.down && mediaSort.prop === MediaTypeSort.date}
          onClick={() => onSortMedia(MediaTypeSort.date, SortOrder.down)}
          variant="light"
          size="sm"
          startContent={<i className="fa-solid fa-arrow-down-wide-short" />}
        >
          {formatMessage(mediaLibraryFiltersMessages.descending)}
        </SelectedButton>
        <SelectedButton
          $selected={mediaSort.order === SortOrder.up && mediaSort.prop === MediaTypeSort.date}
          onClick={() => onSortMedia(MediaTypeSort.date, SortOrder.up)}
          variant="light"
          size="sm"
          startContent={<i className="fa-solid fa-arrow-up-short-wide"></i>}
        >
          {formatMessage(mediaLibraryFiltersMessages.ascending)}
        </SelectedButton>
      </H1_FlexColumn>
      <H1_TextXs padding="10px 10px 0" color={theme.gray7}>
        {formatMessage(mediaLibraryFiltersMessages.name)}
      </H1_TextXs>
      <Divider />
      <H1_FlexColumn padding="10px">
        <SelectedButton
          $selected={mediaSort.order === SortOrder.down && mediaSort.prop === MediaTypeSort.name}
          onClick={() => onSortMedia(MediaTypeSort.name, SortOrder.down)}
          variant="light"
          size="sm"
          startContent={<i className="fa-solid fa-arrow-down-z-a"></i>}
        >
          {formatMessage(mediaLibraryFiltersMessages.zA)}
        </SelectedButton>
        <SelectedButton
          $selected={mediaSort.order === SortOrder.up && mediaSort.prop === MediaTypeSort.name}
          onClick={() => onSortMedia(MediaTypeSort.name, SortOrder.up)}
          variant="light"
          size="sm"
          startContent={<i className="fa-solid fa-arrow-up-a-z"></i>}
        >
          {formatMessage(mediaLibraryFiltersMessages.aZ)}
        </SelectedButton>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );

  return (
    <Tooltip title={formatMessage(mediaLibraryFiltersMessages.sortTitle)}>
      <>
        <Popover placement="left">
          <PopoverTrigger>
            <Button
              variant="light"
              isIconOnly
              startContent={<i className="fa-solid fa-arrow-down-wide-short"></i>}
            />
          </PopoverTrigger>
          <PopoverContent>{content}</PopoverContent>
        </Popover>
      </>
    </Tooltip>
  );
};

export default SortPopover;
