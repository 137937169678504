import { Input, Switch } from "antd";
import { useIntl } from "react-intl";
import { digitalTwinCreationMessages } from "app/pages/createDigitalTwinPage/messages";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import useCharacterSettingsContext from "app/pages/createDigitalTwinPage/useCharacterSettingsContext";
import { Collapse } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const { Panel } = Collapse;

const StyledH1Input = styled(H1_Input)<{ $active: boolean }>`
  width: 100%;
  && input {
    width: 100%;
    padding-right: 50px;
    background-color: ${(props) => props.theme.gray3};
    color: ${(props) => (props.$active ? props.theme.blue4 : props.theme.gray6)};
    border-radius: 3px;
    border: none;
    &:disabled &:focus,
    &:hover,
    &:active {
      color: ${(props) => props.theme.blue4};
      border: none;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  width: 70%;
  .ant-collapse-content {
    max-width: 100%;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .anticon.anticon-right.ant-collapse-arrow {
    vertical-align: 2px;
  }
`;

const StyledSwitch = styled(Switch)<{ $active: boolean }>`
  position: absolute;
  right: 3px;
  top: 5px;
  width: 44px;
  left: auto;
  z-index: 1;
  .fa-table-tennis-paddle-ball,
  .fa-eye-slash {
    color: ${(props) => (props.$active ? props.theme.gray1 : "var(--grey-blue-02, #615a80)")};
  }
`;

const FlexWrraper = styled(H1_FlexRow)`
  & > div {
    flex: 1 1 40%;
    /* Only way controlling the switch border-radius inside a compact input group */
    .ant-input-group.ant-input-group-compact > *:last-child {
      border-radius: 20px;
    }
  }
`;

const AdvancedCharacterSettings = () => {
  const intl = useIntl();
  const { addPong, setAddPong, addTransparent, setAddTransparent } = useCharacterSettingsContext();

  return (
    <StyledCollapse defaultActiveKey={[]} ghost>
      <Panel header={intl.formatMessage(digitalTwinCreationMessages.advancedMenuTitle)} key="1">
        <FlexWrraper gap="10px" width="100%" wrap="wrap">
          <H1_FlexColumn position="relative">
            <Input.Group compact>
              <StyledH1Input
                placeholder={intl.formatMessage(digitalTwinCreationMessages.avatarNamePlaceholder)}
                minWidth="100%"
                disabled
                $active={addTransparent}
                initialValue={
                  addTransparent
                    ? intl.formatMessage(digitalTwinCreationMessages.addTransparentOn)
                    : intl.formatMessage(digitalTwinCreationMessages.addTransparentOff)
                }
              />
              <StyledSwitch
                checkedChildren={<i className="fa-regular fa-eye-slash" />}
                $active={addTransparent}
                unCheckedChildren={<i className="fa-solid fa-eye-slash" />}
                size="default"
                onChange={setAddTransparent}
                checked={addTransparent}
              />
            </Input.Group>
          </H1_FlexColumn>
          <H1_FlexColumn position="relative">
            <Input.Group compact>
              <StyledH1Input
                placeholder={intl.formatMessage(digitalTwinCreationMessages.avatarNamePlaceholder)}
                minWidth="100%"
                disabled
                $active={addPong}
                initialValue={
                  addPong
                    ? intl.formatMessage(digitalTwinCreationMessages.addPongOn)
                    : intl.formatMessage(digitalTwinCreationMessages.addPongOff)
                }
              />
              <StyledSwitch
                $active={addPong}
                checkedChildren={<i className="fa-regular fa-table-tennis-paddle-ball" />}
                unCheckedChildren={<i className="fa-solid fa-table-tennis-paddle-ball" />}
                size="default"
                onChange={setAddPong}
                checked={addPong}
              />
            </Input.Group>
          </H1_FlexColumn>
        </FlexWrraper>
      </Panel>
    </StyledCollapse>
  );
};

export default AdvancedCharacterSettings;
