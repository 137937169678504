import OutsideClickHandler from "react-outside-click-handler";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { charactersActions } from "app/store/slices/characters.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import {
  CharacterAll,
  CharacterFilters,
  CharacterFiltersProperties,
  defaultCharacterFilter
} from "app/types/character";
import { presentersPageMessages } from "app/pages/presenters/messages";
import { shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import { Gender } from "app/types";
import { characterDrawerMessages } from "app/components/editor/sideDrawers/CharacterDrawer/messages";
import { Checkbox } from "antd";
import { RootState } from "app/store/store";
import { Button, Selection } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import NextSelect from "app/components/common/NextUI/Select/NextSelect";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

const CrownIcon = styled.i`
  color: ${({ theme }) => theme.orange3};
`;
const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  padding-left: 5px;
  &&.ant-checkbox-wrapper {
    margin-left: 0;
    padding-left: 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ theme }) => theme.gray6};
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    border-color: ${({ theme }) => theme.gray6};
    color: ${({ theme }) => theme.gray6};
  }
  .ant-checkbox-checked::after,
  &&.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-radius: 4px;
    border-color: ${({ theme }) => theme.gray6};
  }
  .ant-checkbox:hover::after,
  &&.ant-checkbox-input:focus,
  &&.ant-checkbox:hover .ant-checkbox-inner,
  &&.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    border-radius: 4px;
    border-color: ${({ theme }) => theme.gray5};
  }
  &:hover {
    color: ${({ theme }) => theme.gray7};
    .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.gray5};
    }
  }
`;

const UnderlineFlexRow = styled(H1_FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.gray3};
`;

const UnderlineButton = styled(Button)`
  && {
    background: transparent;
  }
  &:hover {
    text-decoration: underline;
  }
  font-size: 12px;
`;
const TopFlexRow = styled(H1_FlexRow)`
  top: 40px;
  right: 0;
`;
const FiltersFlexColumn = styled(H1_FlexColumn)<{ $open: boolean }>`
  top: 10px;
  left: 0;
  z-index: 603;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  border: ${({ $open, theme }) => ($open ? `1px solid ${theme.gray3}` : "none")};
  border: ${({ $open, theme }) => ($open ? `1px solid ${theme.gray3}` : "none")};
  border-radius: 6px;
  max-height: ${({ $open }) => ($open ? "500px" : "0")};
  padding: ${({ $open }) => ($open ? "20px" : "0")};
  transition-duration: 0.3s, 0.3s, 0s;
  transition-delay: 0s, 0s, ${({ $open }) => ($open ? "0" : "0.1s")};
  transition-property: height, max-height, padding;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275),
    cubic-bezier(0.175, 0.885, 0.32, 1.275), cubic-bezier(0.175, 0.885, 0.32, 1.275);

  overflow: hidden;
`;

const StyledSelect = styled(NextSelect)`
  &&& button {
    background-color: ${({ theme }) => theme.gray3};
    &:hover {
      background-color: ${({ theme }) => theme.gray4};
    }
  }
`;

const propertiesMemoArray = [CharacterFiltersProperties.age, CharacterFiltersProperties.clothing];

interface CharacterDrawerFiltersProps {
  isFiltersVisible: boolean;
  onClose: () => void;
}

const CharacterDrawerFilters = ({ isFiltersVisible, onClose }: CharacterDrawerFiltersProps) => {
  const [clothingFilter, setClothingFilter] = useState<string>(CharacterAll);
  const [ageFilter, setAgeFilter] = useState<string>(CharacterAll);
  const [genderFilter, setGenderFilter] = useState<Gender>(Gender.all);
  const [freeAvatars, setFreeAvatars] = useState<boolean>(true);
  const [myAvatars, setMyAvatars] = useState<boolean>(true);
  const [publicAvatars, setPublicAvatars] = useState<boolean>(true);
  const [proAvatars, setProAvatars] = useState<boolean>(true);
  const [preventClosing, setPreventClosing] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const filters: Partial<CharacterFilters> = useAppSelector(
    (state: RootState) => state.characters.filters
  );

  const allUniqueOptions = useAppSelector(
    (state) =>
      CharacterSelectors.getUniqueDropdownValuesByPropertiesNextUI(
        state,
        propertiesMemoArray,
        "All"
      ),
    shallowEqual
  );

  const ageAllOptions = allUniqueOptions[CharacterFiltersProperties.age];
  const clothingStylesAllOptions = allUniqueOptions[CharacterFiltersProperties.clothing];

  useEffect(() => {
    setProAvatars(!!filters?.pro);
  }, [filters?.pro]);
  useEffect(() => {
    setFreeAvatars(!!filters?.free);
  }, [filters?.free]);

  useEffect(() => {
    if (isFiltersVisible) {
      setProAvatars(!!filters?.pro);
      setFreeAvatars(!!filters?.free);
      setMyAvatars(!!filters?.myAvatars);
      setPublicAvatars(!!filters?.publicAvatars);
      setClothingFilter(filters?.clothing || CharacterAll);
      setAgeFilter(filters?.age || CharacterAll);
      setGenderFilter(filters?.gender || Gender.all);
    }
  }, [isFiltersVisible]);

  const onGenderChange = (value: Selection) => {
    const selection = Array.from(value)[0] as Gender;
    dispatch(
      analyticsEvents.changePresenterPageFilters({
        value: selection,
        filter: CharacterFiltersProperties.gender
      })
    );
    if (selection === genderFilter) {
      setGenderFilter(Gender.all);
    } else {
      setGenderFilter(selection);
    }
  };

  const onAgeChange = (value: Selection) => {
    const selection = Array.from(value)[0] as string;
    dispatch(analyticsEvents.changePresenterPageFilters({ value: selection, filter: "age" }));
    if (selection === ageFilter || !selection) {
      setAgeFilter(CharacterAll);
    } else {
      setAgeFilter(selection);
    }
  };

  const onClothingChange = (value: Selection) => {
    const selection = Array.from(value)[0] as string;
    dispatch(analyticsEvents.changePresenterPageFilters({ value: selection, filter: "clothing" }));
    if (selection === clothingFilter || !selection) {
      setClothingFilter(CharacterAll);
    } else {
      setClothingFilter(selection);
    }
  };

  const onResetAll = () => {
    dispatch(analyticsEvents.changePresenterPageFilters({ value: undefined, filter: "reset" }));
    setFreeAvatars(defaultCharacterFilter.free);
    setAgeFilter(defaultCharacterFilter.age);
    setClothingFilter(defaultCharacterFilter.clothing);
    setGenderFilter(defaultCharacterFilter.gender);
    setProAvatars(defaultCharacterFilter.pro);
    setMyAvatars(defaultCharacterFilter.myAvatars);
    setPublicAvatars(defaultCharacterFilter.publicAvatars);
  };

  const onCloseSliders = () => {
    if (isFiltersVisible && !preventClosing) {
      onClose();
    }
    setPreventClosing(false);
  };

  const onChangeMyAvatars = () => {
    setMyAvatars(!myAvatars);
  };
  const onChangePublicAvatars = () => {
    setPublicAvatars(!publicAvatars);
  };
  const onChangeFreeAvatars = () => {
    setFreeAvatars(!freeAvatars);
  };

  const onChangeProAvatars = () => {
    setProAvatars(!proAvatars);
  };

  const onClickApplyFilters = () => {
    dispatch(
      analyticsEvents.changePresenterPageFilters({
        value: {
          clothing: clothingFilter,
          age: ageFilter,
          gender: genderFilter,
          myAvatars,
          publicAvatars,
          free: freeAvatars,
          pro: proAvatars
        },
        filter: "Apply Filters"
      })
    );
    dispatch(
      charactersActions.setFilters({
        clothing: clothingFilter,
        age: ageFilter,
        gender: genderFilter,
        myAvatars,
        publicAvatars,
        free: freeAvatars,
        pro: proAvatars
      })
    );
    onClose();
  };

  return (
    <TopFlexRow position="absolute" width="100%">
      <OutsideClickHandler onOutsideClick={onCloseSliders}>
        <FiltersFlexColumn
          $open={isFiltersVisible}
          gap="14px"
          position="absolute"
          width="100%"
          padding="0 0 20px 0"
        >
          <UnderlineFlexRow justify="space-between" align="center" padding="7px 0 6px 0">
            <H1_TextXs color={theme.gray7}>
              {formatMessage(characterDrawerMessages.filter)}
            </H1_TextXs>
            <UnderlineButton size="sm" variant="light" color="primary" onClick={onResetAll}>
              {formatMessage(characterDrawerMessages.resetAll)}
            </UnderlineButton>
          </UnderlineFlexRow>
          <H1_FlexRow width="100%" align="center">
            <H1_FlexRow width="calc(100% - 160px)">
              <H1_TextXs>{formatMessage(characterDrawerMessages.gender)}</H1_TextXs>
            </H1_FlexRow>
            <H1_FlexRow width="155px">
              <StyledSelect
                items={[
                  {
                    key: Gender.all,
                    label: formatMessage(presentersPageMessages.all)
                  },
                  {
                    key: Gender.male,
                    label: formatMessage(presentersPageMessages.male)
                  },
                  {
                    key: Gender.female,
                    label: formatMessage(presentersPageMessages.female)
                  }
                ]}
                labelPlacement="outside"
                size="sm"
                selectedKeys={[genderFilter]}
                onSelectionChange={onGenderChange}
                selectorIcon={<H1_Icon color={theme.gray11} icon="far fa-chevron-down" />}
                onClick={() => setPreventClosing(true)}
              />
            </H1_FlexRow>
          </H1_FlexRow>
          <H1_FlexRow width="100%" align="center">
            <H1_FlexRow width="calc(100% - 160px)">
              <H1_TextXs>{formatMessage(characterDrawerMessages.age)}</H1_TextXs>
            </H1_FlexRow>
            <H1_FlexRow width="155px">
              <StyledSelect
                items={ageAllOptions}
                labelPlacement="outside"
                size="sm"
                selectedKeys={[ageFilter]}
                onSelectionChange={onAgeChange}
                selectorIcon={<H1_Icon color={theme.gray11} icon="far fa-chevron-down" />}
                onClick={() => setPreventClosing(true)}
              />
            </H1_FlexRow>
          </H1_FlexRow>
          <H1_FlexRow width="100%" align="center">
            <H1_FlexRow width="calc(100% - 160px)">
              <H1_TextXs>{formatMessage(characterDrawerMessages.clothing)}</H1_TextXs>
            </H1_FlexRow>
            <H1_FlexRow width="155px">
              <StyledSelect
                items={clothingStylesAllOptions}
                labelPlacement="outside"
                size="sm"
                selectedKeys={[clothingFilter]}
                onSelectionChange={onClothingChange}
                selectorIcon={<H1_Icon color={theme.gray11} icon="far fa-chevron-down" />}
                onClick={() => setPreventClosing(true)}
              />
            </H1_FlexRow>
          </H1_FlexRow>
          <H1_FlexRow>
            <H1_TextXs color={theme.gray7}>
              {formatMessage(characterDrawerMessages.displayAvatars)}
            </H1_TextXs>
          </H1_FlexRow>
          <StyledCheckbox checked={myAvatars} onClick={onChangeMyAvatars}>
            <H1_TextXs>{formatMessage(characterDrawerMessages.myAvatars)}</H1_TextXs>
          </StyledCheckbox>
          <StyledCheckbox checked={publicAvatars} onClick={onChangePublicAvatars}>
            <H1_TextXs>{formatMessage(characterDrawerMessages.publicAvatars)}</H1_TextXs>
          </StyledCheckbox>
          <H1_FlexRow padding="5px 0 0 0">
            <H1_TextXs color={theme.gray7}>
              {formatMessage(characterDrawerMessages.price)}
            </H1_TextXs>
          </H1_FlexRow>
          <StyledCheckbox checked={freeAvatars} onClick={onChangeFreeAvatars}>
            <H1_TextXs>{formatMessage(characterDrawerMessages.free)}</H1_TextXs>
          </StyledCheckbox>
          <StyledCheckbox checked={proAvatars} onClick={onChangeProAvatars}>
            <H1_FlexRow align="center" gap="7px">
              <H1_TextXs>{formatMessage(characterDrawerMessages.pro)}</H1_TextXs>
              <CrownIcon className="fas fa-crown" />
            </H1_FlexRow>
          </StyledCheckbox>
          <Button color="primary" onClick={onClickApplyFilters}>
            {formatMessage(characterDrawerMessages.applyFilters)}
          </Button>
        </FiltersFlexColumn>
      </OutsideClickHandler>
    </TopFlexRow>
  );
};

export default CharacterDrawerFilters;
