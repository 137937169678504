import React, { ReactNode, useCallback } from "react";
import FileUploader from "app/components/common/FileUploader";
import ConditionalRender from "app/components/common/ConditionalRender";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import { useIntl } from "react-intl";
import { fileUploaderMessages } from "app/components/common/fileUploaderMessages";

interface FileDropzoneProps {
  onSelectedFiles: (files: File[]) => void;
  disabled?: boolean;
  fileTypes: string;
  children: ReactNode;
  openFileDialogOnClick?: boolean;
  maxSizeInMB?: number;
  height?: string;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onSelectedFiles,
  disabled,
  fileTypes,
  children,
  openFileDialogOnClick = true,
  maxSizeInMB,
  height
}) => {
  const { notifyError } = useErrors();
  const { formatMessage } = useIntl();
  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (disabled) {
        return;
      }

      const { files } = e.dataTransfer;
      if (files.length > 0) {
        const result = Array.from(files).filter((file) => fileTypes.includes(file.type));
        let isSizeOk = true;
        if (maxSizeInMB) {
          isSizeOk = Array.from(files).every((file) => file.size < maxSizeInMB * 1000 * 1000);
          notifyError({
            general: buildGeneralMessage(
              formatMessage(fileUploaderMessages.tooLargeTitle),
              formatMessage(fileUploaderMessages.tooLargeDescription)
            )
          });
        }
        if (result.length && isSizeOk) {
          onSelectedFiles(result);
        }
      }
    },
    [onSelectedFiles, disabled, fileTypes]
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  return (
    <div style={{ flex: "1" }} onDrop={handleDrop} onDragOver={handleDragOver}>
      <ConditionalRender condition={openFileDialogOnClick}>
        <FileUploader
          fileTypes={fileTypes}
          multiple
          onFilesSelected={onSelectedFiles}
          disabled={disabled}
          maxSizeInMB={maxSizeInMB}
          height={height}
        >
          {children}
        </FileUploader>
      </ConditionalRender>
      <ConditionalRender condition={!openFileDialogOnClick}>{children}</ConditionalRender>
    </div>
  );
};

export default FileDropzone;
