import React from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { userActions } from "app/store/slices/user.slice";
import { fetchingStatus } from "app/utils/helpers";
import { Tab, Tabs } from "@nextui-org/tabs";
import { defineMessages } from "@formatjs/intl";
import { useIntl } from "react-intl";
import { DraftViewMode } from "app/types";
import styled from "styled-components";

const StyledTabs = styled(Tabs)`
  && > div {
    background-color: ${({ theme }) => theme.gray2};
    padding: 4px;
    border-radius: 8px;

    div[data-slot="tabContent"] {
      color: ${({ theme }) => theme.gray6};
    }

    button[aria-selected="true"] > [data-slot="cursor"] {
      color: ${({ theme }) => theme.gray10};
      background-color: ${({ theme }) => theme.gray4}; /* Darker background color */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Darker shadow */
      opacity: 1;
      ~ div[data-slot="tabContent"] {
        color: ${({ theme }) => theme.gray10};
      }
    }
  }
`;
const messages = defineMessages({
  me: {
    id: `drafts-view-mode-me`,
    defaultMessage: "Me"
  },
  everyone: {
    id: `drafts-view-mode-everyone`,
    defaultMessage: "Everyone"
  }
});
const DraftsViewMode = () => {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector((state) => state.user.preferences);
  const draftsStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const intl = useIntl();

  const handleChange = (value: boolean) => {
    if (draftsStatus !== fetchingStatus.loading) {
      dispatch(userActions.updateUserPreferencesRequest({ view_mode_me: value }));
    }
  };

  return (
    <StyledTabs
      size={"sm"}
      aria-label="Options"
      selectedKey={preferences.view_mode_me ? DraftViewMode.me : DraftViewMode.everyone}
      onSelectionChange={(key) => handleChange(key === DraftViewMode.me)}
    >
      <Tab key="everyone" title={intl.formatMessage(messages.everyone)} />
      <Tab key="me" title={intl.formatMessage(messages.me)} />
    </StyledTabs>
  );
};

export default DraftsViewMode;
