import styled from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useEffect, useState } from "react";
import {
  Location,
  VideoWizardDuration,
  VideoWizardRatio,
  VideoWizardStage,
  VideoWizardStyle,
  VideoWizardV2Properties,
  VideoWizardWorkflowVariables
} from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import VideoWizardV2Stage1 from "app/components/common/VideoWizardModalV2/VideoWizardV2Stage1";
import VideoWizardV2Stage2 from "app/components/common/VideoWizardModalV2/VideoWizardV2Stage2";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { v4 as uuidv4 } from "uuid";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import { VIDEO_WIZARD_TIMEOUT_SEC } from "app/config/Constants";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import IncrementalLoader from "app/components/common/Loaders/IncrementalLoader";
import { useIntl } from "react-intl";
import useErrors from "app/hooks/useErrors";
import { videoWizardV2Messages } from "app/components/common/VideoWizardModalV2/messages";
import { useNavigate } from "react-router-dom";
import useTimeoutOnLoading from "app/hooks/useTimeoutOnLoading";
import { getCategoriesOptions, getVariablesOptions } from "app/store/selectorsV2/drafts.selectors";
import { StyledModal } from "app/components/common/StyledModal";

const StyledIncrementalLoader = styled(IncrementalLoader)`
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 15px;
`;

interface VideoWizardModalV2 {
  visible: boolean;
  onClose: () => void;
  source: string;
}
const VideoWizardModalV2 = ({ visible, onClose, source }: VideoWizardModalV2) => {
  const [stage, setStage] = useState<VideoWizardStage>(VideoWizardStage.stage1);
  const [properties, setProperties] = useState<Partial<VideoWizardV2Properties>>({});
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { notifyError } = useErrors();
  const navigate = useNavigate();

  const variables: VideoWizardWorkflowVariables[] = useAppSelector((state) =>
    getVariablesOptions(state, properties.category, properties.subCategory)
  );
  const categoryOptions = useAppSelector(getCategoriesOptions);
  const workflowCategoriesStatus = useAppSelector((state) => state.drafts.workflowCategoriesStatus);
  const topicToDraftStatus = useAppSelector((state) => state.drafts.topicToDraftStatus);
  const topicToDraftResultId = useAppSelector((state) => state.drafts.topicToDraftResultId);
  const createWorkflowCategoriesStatus = useAppSelector(
    (state) => state.drafts.createWorkflowCategoriesStatus
  );

  const isLoading = workflowCategoriesStatus === fetchingStatus.loading;
  const isTopicToDraftLoading = topicToDraftStatus === fetchingStatus.loading;
  const isTopicToDraftSucceeded = topicToDraftStatus === fetchingStatus.succeeded;
  const shouldShowIncrementalLoader = isTopicToDraftLoading || isTopicToDraftSucceeded;

  const onTimeout = () => {
    notifyError({
      general: buildGeneralMessage(
        formatMessage(videoWizardV2Messages.videoWizardTimeoutErrorHead),
        formatMessage(videoWizardV2Messages.videoWizardTimeoutError)
      )
    });
    dispatch(draftsActions.updateTopicToDraftStatusToIdle());
  };

  useTimeoutOnLoading({
    loading: isTopicToDraftLoading,
    timeoutSec: VIDEO_WIZARD_TIMEOUT_SEC * 1000,
    onTimeout
  });

  useEffect(() => {
    let timeoutTopicSucceededId: ReturnType<typeof setTimeout>;
    if (topicToDraftStatus === fetchingStatus.idle || !visible) {
      return;
    }
    if (topicToDraftStatus === fetchingStatus.succeeded && topicToDraftResultId) {
      // Wait a second to show the loader in 100%
      timeoutTopicSucceededId = setTimeout(() => {
        onClose();
        dispatch(analyticsEvents.navigateToEditor({ source: "video_wizard_modal_v2" }));
        navigate(`${Location.Editor}/${topicToDraftResultId}`);
        dispatch(draftsActions.updateTopicToDraftStatusToIdle());
        dispatch(
          analyticsEvents.videoWizardV2({
            action: "navigateToDraft",
            source,
            data: properties.variablesValues,
            videoUseCase: properties.subCategoryTitle,
            duration: properties.duration,
            format: properties.ratio,
            style: properties.style,
            draftId: topicToDraftResultId
          })
        );
        clearTimeout(timeoutTopicSucceededId);
      }, 1000);
    } else if (topicToDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateTopicToDraftStatusToIdle());
    }
    return () => {
      if (timeoutTopicSucceededId) {
        clearTimeout(timeoutTopicSucceededId);
      }
    };
  }, [topicToDraftStatus, visible, topicToDraftResultId]);

  useEffect(() => {
    if (!visible || createWorkflowCategoriesStatus === fetchingStatus.idle) {
      return;
    }
    if (createWorkflowCategoriesStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateCreateWorkflowCategoriesToIdle());
    }
    if (createWorkflowCategoriesStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateCreateWorkflowCategoriesToIdle());
    }
  }, [visible, createWorkflowCategoriesStatus]);
  useEffect(() => {
    if (!visible || workflowCategoriesStatus === fetchingStatus.idle) {
      return;
    }
    if (workflowCategoriesStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateWorkflowCategoriesToIdle());
      const initialCategory = categoryOptions?.find((category) => category.label === "Sales");
      setProperties((prevProperties) => ({
        ...prevProperties,
        category: initialCategory?.value || ""
      }));
    }
    if (workflowCategoriesStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateWorkflowCategoriesToIdle());
    }
  }, [visible, workflowCategoriesStatus]);

  useEffect(() => {
    if (!visible) {
      const initialCategory = categoryOptions?.find((category) => category.label === "Sales");
      setProperties({
        subCategory: "",
        subCategoryTitle: "",
        ratio: VideoWizardRatio.landscape,
        duration: VideoWizardDuration.seconds30,
        variablesValues: {},
        category: initialCategory?.value || "",
        recipeId: "",
        style: VideoWizardStyle.corporate
      });
      setStage(VideoWizardStage.stage1);
    }
  }, [visible]);

  const onChangeProperties = (key: keyof VideoWizardV2Properties, value: any) => {
    setProperties((prevProperties) => ({
      ...prevProperties,
      [key]: value
    }));
  };

  const onFinishStage1 = () => {
    setStage(VideoWizardStage.stage2);
    dispatch(
      analyticsEvents.videoWizardV2({
        action: "finishVideoSettings",
        source,
        data: properties.variablesValues,
        videoUseCase: properties.subCategoryTitle,
        duration: properties.duration,
        format: properties.ratio,
        style: properties.style,
        stepIndex: 1
      })
    );
  };
  const onFinishStage2 = () => {
    const orderId = uuidv4();
    const currentPropertiesVariablesValues = { ...properties };
    variables.forEach(({ name, placeholder }) => {
      if (!currentPropertiesVariablesValues.variablesValues) return;
      if (!currentPropertiesVariablesValues.variablesValues[name]) {
        currentPropertiesVariablesValues.variablesValues[name] = placeholder;
      }
    });
    dispatch(
      draftsActions.createVideoFromWorkflowCategoriesRequest({
        properties: {
          ...properties,
          variablesValues: currentPropertiesVariablesValues.variablesValues
        },
        orderId
      })
    );
    dispatch(
      analyticsEvents.videoWizardV2({
        action: "createVideo",
        source,
        data: properties.variablesValues,
        videoUseCase: properties.subCategoryTitle,
        duration: properties.duration,
        format: properties.ratio,
        style: properties.style,
        stepIndex: 2
      })
    );
  };

  const onBackStage2 = () => {
    setStage(VideoWizardStage.stage1);
    dispatch(
      analyticsEvents.videoWizardV2({
        action: "backToVideoSettings",
        source,
        data: properties.variablesValues,
        videoUseCase: properties.subCategoryTitle,
        duration: properties.duration,
        format: properties.ratio,
        style: properties.style,
        stepIndex: 2
      })
    );
  };

  return (
    <StyledModal
      width="879px"
      open={visible}
      footer={null}
      centered
      $minHeight="631px"
      bodyStyle={{ height: "631px" }}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="0"
      $overflow="hidden"
      $glassEffect
    >
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={stage === VideoWizardStage.stage1 && !isLoading}>
        <VideoWizardV2Stage1
          onClickNext={onFinishStage1}
          style={properties.style}
          duration={properties.duration}
          ratio={properties.ratio}
          category={properties.category}
          subCategory={properties.subCategory}
          subCategoryTitle={properties.subCategoryTitle}
          onChangeProperties={onChangeProperties}
        />
      </ConditionalRender>
      <ConditionalRender condition={stage === VideoWizardStage.stage2 && !isLoading}>
        <VideoWizardV2Stage2
          onClickNext={onFinishStage2}
          onClickBack={onBackStage2}
          onChangeProperties={onChangeProperties}
          variablesValues={properties.variablesValues || {}}
          category={properties.category as string}
          subCategory={properties.subCategory as string}
          subCategoryTitle={properties.subCategoryTitle as string}
        />
      </ConditionalRender>
      <ConditionalRender condition={shouldShowIncrementalLoader}>
        <StyledIncrementalLoader isFinished={isTopicToDraftSucceeded} />
      </ConditionalRender>
    </StyledModal>
  );
};

export default VideoWizardModalV2;
