/* eslint @typescript-eslint/naming-convention: 0 */
import React from "react";
import { Select } from "antd";
import styled from "styled-components";
import type { SelectProps, BaseOptionType, DefaultOptionType } from "antd/es/select";

const StyledSelect = styled(Select)<{ $isTransparent?: boolean; $width?: string }>`
  &&&& .ant-select-selector,
  &&&&:hover .ant-select-selector,
  &&&&:focus .ant-select-selector {
    border: none;
    background-color: inherit;
    outline: none;
    box-shadow: none;
    transition: none;
  }
  transition: none;
  cursor: pointer;
  outline: none;
  padding: ${(props) => (props.$isTransparent ? "0" : "0 1rem")};
  border-width: 1px;
  border-style: solid;
  background-color: ${(props) => (props.$isTransparent ? "transparent" : props.theme.gray1)};
  border-color: ${(props) => (props.$isTransparent ? "transparent" : props.theme.gray5)};
  color: ${(props) => props.theme.gray9};
  fill: ${(props) => props.theme.gray9};
  width: ${(props) => props.$width};
  &:hover:not(.ant-select-disabled),
  &:hover:not(.ant-select-disabled) .ant-select-arrow,
  &:hover:not(.ant-select-disabled).ant-select-open .ant-select-selection-item {
    color: ${(props) => (props.$isTransparent ? props.theme.blue4 : props.theme.pink3)};
    fill: ${(props) => (props.$isTransparent ? props.theme.blue4 : props.theme.pink3)};
    border-color: ${(props) => (props.$isTransparent ? props.theme.blue4 : props.theme.pink3)};
    transition: none;
    .ant-select-selection-item {
      transition: none;
    }
  }
  &:active {
    color: ${(props) => (props.$isTransparent ? props.theme.blue2 : props.theme.pink2)};
    fill: ${(props) => (props.$isTransparent ? props.theme.blue2 : props.theme.pink2)};
    border-color: ${(props) => (props.$isTransparent ? props.theme.blue2 : props.theme.pink2)};
  }
  &&&&.ant-select-disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.gray6} !important;
    fill: ${(props) => props.theme.gray6} !important;
    border-color: ${(props) => (props.$isTransparent ? "transparent" : props.theme.gray5)};
    background-color: ${(props) => (props.$isTransparent ? "transparent" : props.theme.gray3)};

    &&&& .ant-select-selector,
    &&&&:hover .ant-select-selector,
    &&&&:focus &&&&.ant-select-selector {
      border: ${(props) => (props.$isTransparent ? "transparent" : props.theme.gray5)} !important;
      background-color: ${(props) =>
        props.$isTransparent ? "transparent" : props.theme.gray3} !important;
      outline: none;
      box-shadow: none;
      transition: none;
      color: ${(props) => props.theme.gray6} !important;
      fill: ${(props) => props.theme.gray6} !important;
      .ant-select-arrow {
        color: ${(props) => props.theme.gray6} !important;
      }
    }
  }
`;

export interface H1_SelectProps<ValueType = any>
  extends SelectProps<ValueType, DefaultOptionType | BaseOptionType> {
  className?: string;
  options: (DefaultOptionType | BaseOptionType)[];
  width?: string;
  isTransparent?: boolean;
  disabled?: boolean;
}

export const H1_Select = ({
  className,
  options = [],
  width,
  isTransparent,
  disabled,
  ...props
}: H1_SelectProps) => {
  return (
    <StyledSelect
      disabled={disabled}
      $width={width}
      $isTransparent={isTransparent}
      className={className}
      options={options}
      {...props}
    />
  );
};

H1_Select.defaultProps = {
  isTransparent: false,
  className: ""
};
