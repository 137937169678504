import { createContext } from "react";
import { User } from "app/types";
import { CreateTokenArgs } from "app/auth/workosApiCalls";

export const AUTH_STORAGE_KEY = "auth0-key";

export const storeLocalAuthSession = (session: AuthSession): void => {
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(session));
};

export const clearLocalAuthSession = (): void => {
  localStorage.removeItem(AUTH_STORAGE_KEY);
};

export const getLocalAuthSession = (): AuthSession | null => {
  const session = localStorage.getItem(AUTH_STORAGE_KEY);
  return session ? (JSON.parse(session) as AuthSession) : null;
};

export const isSessionExpired = (expiresAt: number): boolean => new Date().getTime() > expiresAt;

export interface AuthContextState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  error: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  expiresIn: number | null;
  refreshExpiresIn: number | null;
}

interface AuthSession {
  isAuthenticated: boolean;
  accessToken: string | undefined;
  expiresAt: number;
}

export interface AuthContextValue extends AuthContextState {
  method: "Auth0-lock";
  show: () => void;
  hide: () => void;
  signIn: boolean;
  signUp: boolean;
  renewToken: () => Promise<string>;
  logout: (redirect?: string) => void;
  createToken?: (args: CreateTokenArgs) => void;
}

export const authInitialState: AuthContextState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
  accessToken: null,
  refreshToken: null,
  expiresIn: null,
  refreshExpiresIn: null
};

export const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);
