import { Player as RemotionPlayer } from "@remotion/player";
import Composition from "app/pages/editor/ScenesPlayerModal/Composition";
import { getAudioDurationInSeconds } from "@remotion/media-utils";
import { useEffect, useState } from "react";
import { delayRender, continueRender } from "remotion";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ScenesSelectors from "app/store/selectorsV2/scenes.selectors";
import { checkUrlForImg } from "app/utils/helpers";
import { SequenceImage } from "app/types";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";

interface PlayerProps {
  url: string;
  width: number;
  height: number;
  aspectRatio: number;
}

const FPS = 30;

const ScenesPlayer = ({ url, width, height, aspectRatio }: PlayerProps) => {
  const [handle] = useState(() => delayRender());
  const [duration, setDuration] = useState(0);
  const [scenesImagesWithPlaceholder, setScenesImagesWithPlaceholder] = useState<SequenceImage[]>(
    []
  );
  const dispatch = useAppDispatch();
  const scenesImages = useAppSelector((state) =>
    ScenesSelectors.getScenesFramePreviewTiming(state, FPS)
  );

  useEffect(() => {
    (async () => {
      const urlResults = await Promise.all(
        scenesImages.map((sceneImage) => sceneImage.imageUrl && checkUrlForImg(sceneImage.imageUrl))
      );

      const result = scenesImages.map((sceneImage, index) => {
        if (urlResults[index]) {
          return sceneImage;
        } else {
          return { ...sceneImage, imageUrl: fallbackPlaceholder };
        }
      });
      setScenesImagesWithPlaceholder(result);
    })();
  }, [scenesImages]);

  useEffect(() => {
    (async () => {
      dispatch(analyticsEvents.onQuickVideoPreviewPlayerLoaded());
      try {
        const durationInSeconds = await getAudioDurationInSeconds(url);
        setDuration(Math.round(durationInSeconds * FPS));
        continueRender(handle);
      } catch (err) {
        console.error(`Error fetching metadata: ${err}`, err);
      }
    })();
  }, [url]);

  if (!duration) {
    return null;
  }
  return (
    <RemotionPlayer
      style={{ borderRadius: "4px" }}
      component={Composition}
      durationInFrames={duration}
      compositionWidth={width}
      compositionHeight={height}
      fps={FPS}
      inputProps={{ audioUrl: url, data: scenesImagesWithPlaceholder, aspectRatio, height }}
      controls
      autoPlay={true}
      allowFullscreen={true}
    />
  );
};

export default ScenesPlayer;
