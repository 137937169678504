import { Steps } from "antd";
import { VirtualTwinSteps } from "app/types/virtualTwin";
import { upperFirst } from "lodash-es";
import styled from "@emotion/styled";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useTheme } from "styled-components";

interface StepsBarProps {
  currentStep: VirtualTwinSteps;
  steps: VirtualTwinSteps[];
}

const StyledSteps = styled(Steps)`
  padding: 0 20px;
  width: 100%;
  .ant-steps-item-container {
    display: flex;
    align-items: center;
  }
`;

const StyledDotIcon = styled(H1_FlexRow)<{ $color: string }>`
  height: 10px;
  width: 10px;
  margin: auto;
  border-radius: 50px;
  background-color: ${({ $color }) => $color};
`;

const StepsBar = ({ currentStep, steps }: StepsBarProps) => {
  const theme = useTheme();
  const stepLocation = steps.findIndex((step) => step === currentStep);
  const iconedSteps = steps.map((step, idx) => ({
    title: upperFirst(step),
    icon: <StyledDotIcon $color={stepLocation >= idx ? theme.blue4 : theme.gray6} />
  }));

  return <StyledSteps size="small" current={stepLocation} items={iconedSteps} />;
};

export default StepsBar;
