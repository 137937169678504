/* eslint-disable @typescript-eslint/naming-convention */
import { messages } from "app/components/editor/sideDrawers/messages";
import * as Sentry from "@sentry/react";
import { IntlShape } from "react-intl";

// https://flagcdn.com/en/codes.json - Languages to locales
export const mapLanguageToLocale: Record<string, string[]> = {
  Afrikaans: ["za", "za-af"],
  Albania: ["al"],
  Arabic: ["sa", "ae", "iq", "ma", "eg", "qa", "un"],
  Argentina: ["ar", "co", "es"],
  Armenia: ["am"],
  Austria: ["at"],
  Australia: ["au", "au-en"],
  Azerbaijan: ["az"],
  "Bosnia and Herzegovina": ["ba"],
  Bangladesh: ["bd"],
  Belarus: ["by"],
  Bengali: ["bd", "in-bn"],
  Bolivia: ["bo"],
  Belgium: ["be", "fr-be"],
  Brazil: ["br", "un"],
  Bulgaria: ["bg", "un"],
  Cameroon: ["cm"],
  Cambodia: ["kh"],
  Canada: ["ca", "ca-en", "ca-fr"],
  Cantonese: ["cn-cn", "hk-cn"],
  Chile: ["cl"],
  Chinese: ["cn", "cn-mn", "cn-cn", "un"],
  Colombia: ["ar", "co", "es"],
  "Costa Rica": ["cr"],
  Croatia: ["hr", "un"],
  Cuba: ["cu"],
  Czech: ["cz", "un"],
  Danish: ["dk", "un"],
  Denmark: ["dk"],
  "Dominican Republic": ["do"],
  Dutch: ["nl", "un"],
  Holland: ["nl", "un"],
  Netherlands: ["nl", "un"],
  Ecuador: ["ec"],
  Egypt: ["eg"],
  "El Salvador": ["sv"],
  "Equatorial Guinea": ["gq"],
  English: [
    "us",
    "us-en",
    "gb",
    "gb-en",
    "ie",
    "au",
    "au-en",
    "ke",
    "hk-en",
    "ca-en",
    "in-en",
    "za-en",
    "gb_sct",
    "gb_wls",
    "un"
  ],
  "English (GB)": ["gb", "gb-en", "gb_sct", "gb_wls", "un"],
  Estonia: ["ee"],
  Finnish: ["fi", "se", "un"],
  Finland: ["fi", "se", "un"],
  Flemish: ["be"],
  France: ["fr", "ca"],
  French: ["fr", "ca", "ca-fr", "cm", "be-fr", "un"],
  German: ["de", "at", "ch", "un"],
  Global: ["un"],
  Greek: ["gr", "un"],
  Guatemala: ["gt"],
  Gujarati: ["in-gu"],
  Hebrew: ["il"],
  "Hong Kong": ["hk", "hk-en"],
  Iceland: ["is"],
  Israel: ["il"],
  India: [
    "un",
    "in",
    "in-en",
    "in-bn",
    "in-gu",
    "in-hi",
    "in-kn",
    "in-ml",
    "in-mr",
    "in-ta",
    "in-te",
    "in-ur"
  ],
  Indonesian: ["id", "un"],
  Iran: ["ir"],
  Ireland: ["ie"],
  Hindi: ["in-hi"],
  Hungarian: ["hu"],
  Iraq: ["iq"],
  Italian: ["it", "un"],
  Japanese: ["jp", "un"],
  Kannada: ["in-kn"],
  Kazakh: ["kz"],
  Kazakhstan: ["kz"],
  Kenya: ["ke", "ke-sw"],
  Khmer: ["kh"],
  Korean: ["kr", "un"],
  Kyrgyz: ["kg"],
  Kyrgyzstan: ["kg"],
  Laos: ["la"],
  Latvia: ["lv"],
  Lithuania: ["lt"],
  Macedonian: ["mk"],
  Malay: ["my", "un"],
  Malayalam: ["in-ml"],
  Malaysia: ["my", "un"],
  Malta: ["mt"],
  Mandarin: ["cn"],
  Marathi: ["in-mr"],
  Mexico: ["mx"],
  Mongolia: ["mn"],
  Montenegro: ["me"],
  Morocco: ["ma"],
  Nepal: ["np"],
  "North Macedonia": ["mk"],
  Norway: ["no"],
  Norwegian: ["no"],
  Pakistan: ["pk"],
  Persian: ["ir"],
  Peru: ["pe"],
  Philippines: ["ph", "un"],
  Poland: ["pl", "un"],
  Polish: ["pl", "un"],
  Portugal: ["pt"],
  Portuguese: ["br", "pt", "un"],
  "Puerto Rico": ["pr"],
  Punjabi: ["pk", "in-pa"],
  Qatar: ["qa"],
  Qazaq: ["kz"],
  Romania: ["ro", "un"],
  Russian: ["ru"],
  Scotland: ["gb_sct"],
  Scottish: ["gb_sct"],
  Serbia: ["rs"],
  Slovakia: ["sk", "un"],
  Slovenia: ["si"],
  Somalia: ["so"],
  "South Africa": ["za", "za-en", "za-af", "za-za"],
  Spain: ["ar", "co", "es", "uy"],
  Spanish: [
    "un",
    "ar",
    "co",
    "es",
    "uy",
    "do",
    "cl",
    "cu",
    "gq",
    "mx",
    "bo",
    "ec",
    "sv",
    "gt",
    "pe",
    "us-sp",
    "cr",
    "pr"
  ],
  Swahili: ["tz-sw", "ke-sw"],
  Swedish: ["fi", "se", "un"],
  Sweden: ["fi", "se", "un"],
  Switzerland: ["ch"],
  Tagalog: ["ph", "un"],
  Tajikistan: ["tj"],
  Tamil: ["in-ta", "un"],
  Tanzania: ["tz-sw"],
  Telugu: ["in-te"],
  Thai: ["th"],
  Thailand: ["th"],
  Turkey: ["tr", "un"],
  UAE: ["ae"],
  Ukraine: ["ua", "un"],
  UN: ["un"],
  Uzbek: ["uz"],
  Uzbekistan: ["uz"],
  Urdu: ["in-ur"],
  Usa: ["us", "us-en", "us-sp"],
  Vietnam: ["vn"],
  Wales: ["gb_wls"],
  Welsh: ["gb_wls"],
  Zulu: ["za", "za-za"]
};

export const languageTranslationHelper = (local: string, intl: IntlShape) => {
  let result;
  switch (local) {
    case "us":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_us);
      break;
    case "us-en":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_us);
      break;
    case "us-sp":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_us_sp);
      break;
    case "hr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_hr);
      break;
    case "al":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_al);
      break;
    case "pr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_pr);
      break;
    case "am":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_am);
      break;
    case "az":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_az);
      break;
    case "cm":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cm);
      break;
    case "np":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_np);
      break;
    case "ba":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ba);
      break;
    case "cr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cr);
      break;
    case "be":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_be);
      break;
    case "un":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_un);
      break;
    case "ee":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ee);
      break;
    case "be-fr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_be_fr);
      break;
    case "hk":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_hk);
      break;
    case "hk-en":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_hk_en);
      break;
    case "hk-cn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_hk_cn);
      break;
    case "by":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_by);
      break;
    case "is":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_is);
      break;
    case "kh":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_kh);
      break;
    case "la":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_la);
      break;
    case "uz":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_uz);
      break;
    case "kg":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_kg);
      break;
    case "tj":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_tj);
      break;
    case "lt":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_lt);
      break;
    case "me":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_me);
      break;
    case "ir":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ir);
      break;
    case "mk":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_mk);
      break;
    case "mn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_mn);
      break;
    case "mt":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_mt);
      break;
    case "gb_sct":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_gb_sct);
      break;
    case "gb_wls":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_gb_wls);
      break;
    case "my":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_my);
      break;
    case "sa":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_sa);
      break;
    case "ar":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ar);
      break;
    case "bd":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_bd);
      break;
    case "kz":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_kz);
      break;
    case "no":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_no);
      break;
    case "co":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_co);
      break;
    case "vn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_vn);
      break;
    case "es":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_es);
      break;
    case "nl":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_nl);
      break;
    case "qa":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_qa);
      break;
    case "gb":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_gb);
      break;
    case "gb-en":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_gb);
      break;
    case "in":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in);
      break;
    case "in-en":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_en);
      break;
    case "in-bn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_bn);
      break;
    case "in-gu":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_gu);
      break;
    case "in-hi":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_hi);
      break;
    case "in-kn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_kn);
      break;
    case "in-ml":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_ml);
      break;
    case "in-mr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_mr);
      break;
    case "in-pa":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_pa);
      break;
    case "in-ta":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_ta);
      break;
    case "in-te":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_te);
      break;
    case "in-ur":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_in_ur);
      break;
    case "ph":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ph);
      break;
    case "fr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_fr);
      break;
    case "de":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_de);
      break;
    case "gr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_gr);
      break;
    case "il":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_il);
      break;
    case "it":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_it);
      break;
    case "jp":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_jp);
      break;
    case "kr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_kr);
      break;
    case "pl":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_pl);
      break;
    case "br":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_br);
      break;
    case "ru":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ru);
      break;
    case "ie":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ie);
      break;
    case "so":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_so);
      break;
    case "se":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_se);
      break;
    case "bg":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_bg);
      break;
    case "fi":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_fi);
      break;
    case "cz":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cz);
      break;
    case "cn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cn);
      break;
    case "cn-cn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cn_cn);
      break;
    case "cn-mn":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cn_mn);
      break;
    case "hu":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_hu);
      break;
    case "za":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_za);
      break;
    case "za-za":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_za_za);
      break;
    case "za-af":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_za_af);
      break;
    case "za-en":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_za_en);
      break;
    case "ae":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ae);
      break;
    case "iq":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_iq);
      break;
    case "ma":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ma);
      break;
    case "dk":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_dk);
      break;
    case "uy":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_uy);
      break;
    case "sk":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_sk);
      break;
    case "si":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_si);
      break;
    case "rs":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_rs);
      break;
    case "th":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_th);
      break;
    case "tr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_tr);
      break;
    case "ua":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ua);
      break;
    case "ca":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ca);
      break;
    case "ca-en":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ca_en);
      break;
    case "ca-fr":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ca_fr);
      break;
    case "au":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_au);
      break;
    case "au-en":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_au);
      break;
    case "ke":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ke);
      break;
    case "ke-sw":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ke_sw);
      break;
    case "tz-sw":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_tz_sw);
      break;
    case "do":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_do);
      break;
    case "cl":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cl);
      break;
    case "cu":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_cu);
      break;
    case "gq":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_gq);
      break;
    case "mx":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_mx);
      break;
    case "bo":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_bo);
      break;
    case "ec":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ec);
      break;
    case "sv":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_sv);
      break;
    case "gt":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_gt);
      break;
    case "pe":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_pe);
      break;
    case "pt":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_pt);
      break;
    case "ch":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ch);
      break;
    case "eg":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_eg);
      break;
    case "at":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_at);
      break;
    case "id":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_id);
      break;
    case "ro":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_ro);
      break;
    case "lv":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_lv);
      break;
    case "pk":
      result = intl.formatMessage(messages.voiceDrawerLanguageName_pk);
      break;

    default:
      Sentry.captureMessage(`voiceDrawerLanguageName_${local} has no translation`);
      result = local;
  }
  return result;
};
