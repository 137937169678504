import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import { scenesActions } from "app/store/slices/scenes.slice";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";
import { dataStoreActions } from "app/store/slices/dataStore.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { Button } from "@nextui-org/react";

const WorkflowLiveButton = () => {
  const dispatch = useAppDispatch();

  const workflowLiveStatus = useAppSelector((state) => state.drafts.workflowLiveStatus);
  const workflowId = useAppSelector((state) => state.drafts.currentDraft?.workflow_id);
  const workflow = useAppSelector((state) => state.drafts.currentDraft?.workflow);
  const loading = workflowLiveStatus === fetchingStatus.loading;
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);
  const revertDraftStatus = useAppSelector((state) => state.drafts.revertDraftStatus);
  const disabled = revertDraftStatus === fetchingStatus.loading;
  const onClick = () => {
    dispatch(
      analyticsEvents.onClickDraftPublishMode({
        isPublishMode: workflowLiveMode,
        isDraftMode: !workflowLiveMode,
        workflowId
      })
    );
    if (workflowLiveMode) {
      dispatch(draftsActions.restoreTempDraft());
      dispatch(scenesActions.restoreTempScenes());
      dispatch(dataStoreActions.restoreCurrentCsvTable());
      dispatch(uiActionsV2.setShowEditorDrawer(true));
    } else {
      dispatch(draftsActions.getLiveWorkflowRequest({ workflowId: workflowId as string }));
      dispatch(dataStoreActions.cleanDataStore());
    }
  };

  if (!workflow?.published) {
    return null;
  }
  return (
    <Button
      data-auto-id="workflow-live-button"
      isDisabled={disabled}
      variant="bordered"
      isLoading={loading}
      onClick={onClick}
      color="primary"
    >
      {workflowLiveMode ? "View Draft Version" : "View Published Version"}
    </Button>
  );
};

export default WorkflowLiveButton;
