/* eslint-disable camelcase */

import { Layout } from "app/types/layout";
import { Attributes } from "app/types/attributes";
import { Align } from "filestack-js";
import { FilterType } from "app/types/media";
import { SerializedLexicalNode } from "lexical/LexicalNode";

export interface Scene {
  id: string;
  name: string;
  layout_id: string;
  layout: Layout;
  updated_at: string;
  created_at: string;
  version: string;
  archived: boolean;
  attributes: Attributes;
  last_preview_url?: string;
  skip?: boolean;
}

export interface SceneHistory {
  id: string;
  name: string;
  layout_id: string;
  updated_at: string;
  created_at: string;
  version: string;
  archived: boolean;
  attributes: Attributes;
}

export interface InternalScene extends Scene {
  last_preview_url?: string;
}

export interface PartialScene {
  id?: string;
  name?: string;
  layout_id?: string;
  attributes?: Attributes;
}

export interface SelectedScene {
  name: string;
  index: number;
  id: string;
}

export enum MoveDirection {
  UP,
  DOWN
}

export enum FitOptions {
  scale = "scale",
  crop = "crop",
  smart_crop = "smart_crop"
}

export interface ImageFitModel {
  fitType?: FitOptions;
  width?: number;
  height?: number;
  align?: Align;
  filterType?: FilterType;
  x?: number;
  y?: number;
  initialUpdate?: boolean;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface SequenceImage {
  imageUrl: string;
  durationInFrames: number;
  from: number;
}

export enum SynthesisMarkupLanguageType {
  text = "text",
  pronunciation = "pronunciation",
  sceneEst = "sceneEst",
  pause = "pause",
  variable = "variable",
  linebreak = "linebreak"
}

export interface SynthesisMarkupLanguage extends SerializedLexicalNode {
  pronounced?: string;
  text?: string;
  type: SynthesisMarkupLanguageType | string;
  version: number;
  variable_id?: string;
  time?: string;
  key?: string | number;
}

export enum SceneTabs {
  Layout = "Layout",
  Content = "Content"
}

export enum DefaultAddScenePosition {
  First = "first",
  Last = "last"
}
