import { useContext } from "react";
import RecordStepSettingsContext from "app/pages/createDigitalTwinPage/RecordStepSettingsContext";

const useRecordStepContext = () => {
  const recordStrpSettingsContext = useContext(RecordStepSettingsContext);
  if (!recordStrpSettingsContext) {
    throw new Error("Out off context");
  }

  return recordStrpSettingsContext;
};

export default useRecordStepContext;
