import { createSelector } from "reselect";
import { playlistsGlobalSelectors } from "app/store/adapters/adapters";

const getPlaylistById = createSelector(
  [playlistsGlobalSelectors.selectAll, (state, playlistId) => playlistId],
  (playlists, playlistId) => {
    if (playlists.length > 0) {
      return playlists.find((playlist) => playlist.id === playlistId);
    }
    return undefined;
  }
);

export default {
  getPlaylistById
};
