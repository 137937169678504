import drafts from "app/store/slices/drafts.slice";
import recipes from "app/store/slices/recipes.slice";
import scenes from "app/store/slices/scenes.slice";
import templates from "app/store/slices/templates.slice";
import voices from "app/store/slices/voices.slice";
import videos from "app/store/slices/videos.slice";
import payments from "app/store/slices/payments.slice";
import folders from "app/store/slices/folders.slice";
import layouts from "app/store/slices/layouts.slice";
import playlists from "app/store/slices/playlists.slice";
import characters from "app/store/slices/characters.slice";
import media from "app/store/slices/mediaLibrary.slice";
import user from "app/store/slices/user.slice";
import music from "app/store/slices/music.slice";
import workspaces from "app/store/slices/workspaces.slice";
import brandKit from "app/store/slices/brandKit.slice";
import uiV2 from "app/store/slices/uiV2.slice";
import playground from "app/store/slices/playground.slice";
import dataStore from "app/store/slices/dataStore.slice";
import avatars from "app/store/slices/avatars.slice";
import fonts from "app/store/slices/fonts.slice";
import briefs from "app/store/slices/briefs.slice";
import trash from "app/store/slices/trash.slice";
import webhooks from "app/store/slices/webhooks.slice";
import cgn from "app/store/slices/cgn.slice";

export default {
  drafts,
  music,
  user,
  recipes,
  scenes,
  templates,
  playlists,
  voices,
  videos,
  payments,
  folders,
  layouts,
  characters,
  media,
  workspaces,
  brandKit,
  uiV2,
  playground,
  dataStore,
  avatars,
  fonts,
  briefs,
  webhooks,
  trash,
  cgn
};
