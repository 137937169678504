import { Workspace } from "./workspace";

export enum SubscriptionStatusValue {
  canceled = "canceled",
  active = "active",
  paused = "paused",
  cancel_at_period_end = "cancel_at_period_end",
  past_due = "past_due"
}
export interface SubscriptionStatus {
  current_period_end?: string;
  resumes_at?: string;
  subscription_plan_on_resume?: string;
  canceled_at?: string;
  cancel_at_period_end?: string;
  status: SubscriptionStatusValue;
  subscription_id: string;
  action_occurred?: boolean;
  refundable?: boolean;
  credit_charge_per_period?: number;
}

export interface CancelResponse extends SubscriptionStatus {
  workspace: Workspace;
  result?: SubscriptionCancellationResult;
}

export enum SubscriptionCancellationResult {
  subscription_canceled = "subscription_canceled",
  subscription_paused = "subscription_paused",
  subscription_refunded = "subscription_refunded",
  subscription_cancellation_scheduled = "subscription_cancellation_scheduled",
  discount_requested = "discount_requested"
}

export enum CreditTypeUsed {
  VIDEO_PUBLISH = "VIDEO_PUBLISH"
}

export enum MinutesUsageType {
  apiVideo = "api_video",
  platformVideo = "platform_video",
  playgroundVideo = "playground_video"
}
export interface MinuteAnalyticsData {
  type: MinutesUsageType;
  id: string;
  req_id: string;
  draft_version: string;
  thumbnail: string;
  draft_id: string;
  title: string;
  video_available?: boolean;
  draft_archived?: boolean;
}

export interface MinuteAnalytic {
  id: string;
  created_at: string;
  created_by?: string;
  published_by: string;
  creator_avatar?: string;
  credits_type: CreditTypeUsed;
  amount: number;
  data: MinuteAnalyticsData;
  type: string;
}

export interface VideoUsageAnalytic {
  year: number;
  month: number;
  count: number;
}

export interface UsageMetadataResponse {
  activity_months: VideoUsageAnalytic[];
}

export enum MarketPlaceFlow {
  google = "google",
  microsoft = "microsoft"
}
