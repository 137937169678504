import { LiveBlocksEventType, useEventListener } from "app/services/liveBlocksClient";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { Scene, SynthesisMarkupLanguage } from "app/types";

const LiveBlockBroadcastEvents = () => {
  const dispatch = useAppDispatch();
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);

  useEventListener(
    ({ event }: { event: { type: string; sceneId: string; sml: SynthesisMarkupLanguage[] } }) => {
      const scene = scenes.find(({ id }: Scene) => id === event.sceneId);
      if (!scene) {
        return;
      }

      const sml = scene.attributes.text?.transcript?.synthesis_markup_language;
      if (
        sml &&
        event.type === LiveBlocksEventType.sceneTextChanged &&
        JSON.stringify(sml) !== JSON.stringify(event.sml)
      ) {
        dispatch(
          scenesActions.updateOutsideChangeSynthesisMarkupLanguage({
            [event.sceneId]: event.sml
          })
        );
      }
    }
  );

  return null;
};

export default LiveBlockBroadcastEvents;
