import { useAppSelector } from "app/hooks";
import { PlanEnum, WorkspacePermission } from "app/types";
import { NotSupportedPlansPerFeatures, PlanFeature } from "app/config/planFeature";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { getFeaturesList } from "app/store/selectorsV2/user.selectors";
import { getCurrentWorkspacePermissions } from "app/store/selectorsV2/workspaces.selectors";

interface UsedPermissions {
  isDownloadAllow: boolean;
  isPublishAllow: boolean;
  isCreateProjectAllow: boolean;
  isAllowedToDeleteDraft: boolean;
  isAllowedToDuplicateDraft: boolean;
  isAllowToUpgrade: boolean;
  isAllowedToAddPalette: boolean;
  shouldShowUpgradeButton: boolean;
  isOnboardingUser: boolean;
  isPersonalUser: boolean;
  isEnterprise: boolean;
  isAllowToUseVoiceRecording: boolean;
  isAllowUseApiKeys: boolean;
  buyVideo: boolean;
  isAllowCreateApiCurl: boolean;
  isWriteWorkspaceAllow: boolean;
  isAllowChangeRoleWorkspace: boolean;
}
const usePermissions = (): UsedPermissions => {
  const { currentPlan: plan } = useCurrentPlan();
  const permissions = useAppSelector((state) => state.user.permissions);
  const workspace_permissions = useAppSelector(getCurrentWorkspacePermissions);
  const features = useAppSelector(getFeaturesList);
  const isOnboardingUser = plan === "on-boarding";
  const isPersonalUser = plan === "personal";
  const isEnterprise = plan?.toLowerCase() === PlanEnum.enterprise;

  return {
    isAllowedToDeleteDraft: true,
    isAllowedToDuplicateDraft: true,
    isDownloadAllow: features.includes(PlanFeature.DownloadVideo),
    isPublishAllow: features.includes(PlanFeature.PublishVideo),
    buyVideo: features.includes(PlanFeature.buyVideo),
    isCreateProjectAllow: permissions.includes("create:project"),
    isAllowToUpgrade: !isEnterprise,
    isAllowedToAddPalette:
      !NotSupportedPlansPerFeatures[PlanFeature.CustomColorPalette].includes(plan),
    shouldShowUpgradeButton: isOnboardingUser || isPersonalUser,
    isOnboardingUser,
    isPersonalUser,
    isEnterprise,
    isAllowToUseVoiceRecording: permissions.includes("editor:useVoiceRecording"),
    isAllowUseApiKeys: permissions.includes("api:key:generate"),
    isAllowCreateApiCurl: permissions.includes("api:movies:write"),
    isWriteWorkspaceAllow: workspace_permissions.length
      ? workspace_permissions.includes("write")
      : true,
    isAllowChangeRoleWorkspace: workspace_permissions.includes(WorkspacePermission.change_role)
  };
};

export default usePermissions;
