import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch } from "app/hooks";
import { Channel } from "pusher-js";
import { draftsActions } from "app/store/slices/drafts.slice";
import { extractUserIdForPusher, fetchingStatus } from "app/utils/helpers";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import * as Sentry from "@sentry/react";
import { aiGenerateMessages } from "app/components/editor/scene/AugmentedSelection/messages";
import { useIntl } from "react-intl";
import { Location, PlanEnum } from "app/types";
import { useNavigate } from "react-router-dom";
import useSmallScreen from "app/hooks/useSmallScreen";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";

const PusherVideoWizardHandler = () => {
  const navigate = useNavigate();
  const { isSmallScreen } = useSmallScreen();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { notifyError } = useErrors();
  const { formatMessage } = useIntl();
  const { currentPlan } = useCurrentPlan();

  useEffect(() => {
    let channel: Channel;
    const handleVideoWizardResult = (message: {
      status: PusherMessagesStatusEnum;
      draft_id?: string;
      order_id: string;
    }) => {
      const { draft_id, status, order_id } = message;

      // eslint-disable-next-line no-constant-condition
      if (status === "ready") {
        dispatch(
          draftsActions.handleVideoWizardResult({
            status: fetchingStatus.succeeded,
            draft_id,
            order_id
          })
        );
      } else {
        dispatch(
          draftsActions.handleVideoWizardResult({
            status: fetchingStatus.failed,
            draft_id,
            order_id
          })
        );

        if (isSmallScreen && currentPlan === PlanEnum.onBoarding) {
          dispatch(
            analyticsEvents.somethingWentWrongEvent({
              message: "Mobile client got failed video wizard message"
            })
          );
          Sentry.captureMessage("Mobile client got failed video wizard message", {
            extra: {
              draft_id: draft_id,
              status
            }
          });
          navigate(`${Location.MobileFinish}`);
          return;
        }

        Sentry.captureMessage("Client got failed video wizard message", {
          extra: {
            draft_id: draft_id,
            status
          }
        });

        dispatch(
          analyticsEvents.somethingWentWrongEvent({
            message: "Client got failed video wizard message"
          })
        );

        notifyError({
          general: buildGeneralMessage(
            formatMessage(aiGenerateMessages.videoWizardTimeoutErrorHead),
            formatMessage(aiGenerateMessages.videoWizardTimeoutError)
          )
        });
      }
    };

    if (user?.sub) {
      const userId = extractUserIdForPusher(user.sub);
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("video_assets_generated", handleVideoWizardResult);
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherVideoWizardHandler;
