import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { blobToFile } from "app/utils/helpers";
import RecorderContext, {
  RecorderContextMediaMenu,
  RecorderContextWrapperOptions
} from "app/hooks/recorder/RecorderContext";
import { useIntl } from "react-intl";
import { recorderMessages } from "app/hooks/recorder/messages";
import fixWebmDuration from "webm-duration-fix";

const COUNTDOWN = 5;
const RecorderContextWrapper = ({
  children,
  options
}: PropsWithChildren<{
  options?: RecorderContextWrapperOptions;
}>) => {
  const [timeInSec, setTimeInSec] = useState<number>(0);
  const [maxRecordingDurationSec, setMaxRecordingDurationSec] = useState<number | undefined>();
  const [minRecordingDurationSec, setMinRecordingDurationSec] = useState<number | undefined>();
  const [useStartCounter, setUseStartCounter] = useState<boolean>(true);
  const [decreasingTimer, setDecreasingTimer] = useState<boolean>(false);

  const [error, setError] = useState<string | null | unknown>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [triggerVideoCreation, setTriggerVideoCreation] = useState(false);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const recordedChunksRef = useRef<Blob[]>([]);
  const [cameraDevices, setCameraDevices] = useState<MediaDeviceInfo[]>([]);
  const [microphoneDevices, setMicrophoneDevices] = useState<MediaDeviceInfo[]>([]);
  const [counter, setCount] = useState<string>();
  const [recordingInProcess, setRecordingInProcess] = useState(false);
  const timeInSecRef = useRef(timeInSec); // Create a ref to keep track of the current timeInSec
  const { formatMessage } = useIntl();

  const blockableMsgs = [
    formatMessage(recorderMessages.recorderErrEnumeratingDevices),
    formatMessage(recorderMessages.recorderErrAccessingCam)
  ];

  const recordingOptions = options?.recordOptions || {
    audioBitsPerSecond: 128000,
    videoBitsPerSecond: 2500000,
    mimeType: "video/webm;codecs=vp8"
  };

  useEffect(() => {
    if (options) {
      resetOptions(options);
    }
  }, [options]);

  const initTimer = useMemo(() => {
    return decreasingTimer ? maxRecordingDurationSec || 0 : 0;
  }, [maxRecordingDurationSec, decreasingTimer]);

  useEffect(() => {
    // Mapping state into ref is an anti-pattern most of the time, here we need it so the onStopRecording event will not be re-rendered
    if (timeInSec > 0) {
      timeInSecRef.current = timeInSec;
    }
  }, [timeInSec]);

  const resetTimer = () => {
    setTimeInSec(initTimer);
  };

  const resetOptions = (updatedOptions?: RecorderContextWrapperOptions) => {
    setMaxRecordingDurationSec(updatedOptions?.maxRecordingDurationSec);
    setMinRecordingDurationSec(updatedOptions?.minRecordingDurationSec);
    setDecreasingTimer(updatedOptions?.timerDown || false);
    setUseStartCounter(updatedOptions?.counter || false);
    setRecordingInProcess(false);
    setTimeInSec(updatedOptions?.timerDown ? updatedOptions?.maxRecordingDurationSec || 0 : 0);
  };

  const getMediaDevices = async () => {
    try {
      // Adjusted constraints for 16:9 aspect ratio
      const adjustedConstraints = {
        video: {
          aspectRatio: 16 / 9,
          width: { ideal: 1920, max: 1920 },
          height: { ideal: 1080 }, // Adjust height according to 16:9 ratio
          frameRate: { max: 25 },
          advanced: [
            { width: 1920, height: 1080 },
            { aspectRatio: 16 / 9, facingMode: "user" }
          ]
        },
        audio: true
      };
      await navigator.mediaDevices.getUserMedia(adjustedConstraints);
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      setCameraDevices(cameras);
      const microphones = devices.filter((device) => device.kind === "audioinput");
      setMicrophoneDevices(microphones);
    } catch (error) {
      setError(formatMessage(recorderMessages.recorderErrEnumeratingDevices));
      console.error(formatMessage(recorderMessages.recorderErrEnumeratingDevices), error);
    }
  };

  useEffect(() => {
    let timeoutId: any = null;
    if (mediaRecorder && recordingInProcess) {
      if (maxRecordingDurationSec) {
        timeoutId = setTimeout(() => {
          if (mediaRecorder.state === "recording") {
            mediaRecorder.stop();
            setIsRecording(false);
          }
        }, (maxRecordingDurationSec + 1) * 1000); //TODO: figure out why it's not updated on time when + 1 removed
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [mediaRecorder, maxRecordingDurationSec, recordingInProcess]);

  useEffect(() => {
    let intervalId: any;
    if (recordingInProcess) {
      intervalId = setInterval(() => {
        // Use functional update to ensure the latest state is always used
        setTimeInSec((currentTime) => {
          if (decreasingTimer) {
            return currentTime - 1;
          } else {
            return currentTime + 1;
          }
        });
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [recordingInProcess, decreasingTimer]);

  useEffect(() => {
    if (!recordingInProcess && triggerVideoCreation) {
      const validDuration = validateDuration();
      if (validDuration) {
        const fileName = "recorded-video.webm"; // Set the desired file name
        const fileType = "video/webm"; // Set the desired file type (optional)

        (async () => {
          const recordedBlob = await fixWebmDuration(
            new Blob(recordedChunksRef.current, {
              type: fileType
            })
          );
          const recordedFile = blobToFile(recordedBlob, fileName, fileType);
          if (videoRef.current) {
            videoRef.current.srcObject = null;
            videoRef.current.src = URL.createObjectURL(recordedBlob);
          }
          setVideoFile(recordedFile);
          setRecordingInProcess(false);
          setError(undefined);
        })();
      } else {
        setError(
          formatMessage(recorderMessages.recorderErrMinDuration, {
            duration: minRecordingDurationSec
          })
        );
        resetTimer();
      }
      setTriggerVideoCreation(false);
    }
  }, [recordingInProcess, triggerVideoCreation]);

  const validateDuration = () => {
    if (decreasingTimer) {
      return timeInSecRef.current <= 1; //TODO: figure out why it's not updated on time
    } else {
      const normalizedNow = timeInSecRef.current - 1;
      return (
        (maxRecordingDurationSec || 0) >= normalizedNow &&
        (minRecordingDurationSec || normalizedNow) <= normalizedNow
      );
    }
  };

  const onMediaStopRecording = () => {
    setRecordingInProcess(false);
    setTriggerVideoCreation(true);
  };

  const startCapture = async (constraints: MediaStreamConstraints = { video: true }) => {
    try {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
      // Adjusted constraints for 16:9 aspect ratio
      const adjustedConstraints = constraints;
      if (typeof constraints.video == "object") {
        adjustedConstraints.video = {
          ...(constraints.video as object),
          aspectRatio: 16 / 9,
          width: { ideal: 1920, max: 1920 },
          height: { ideal: 1080 }, // Adjust height according to 16:9 ratio
          frameRate: { max: 25 },
          advanced: [
            { width: 1920, height: 1080 },
            { aspectRatio: 16 / 9, facingMode: "user" }
          ]
        };
      }

      const stream = await navigator.mediaDevices.getUserMedia(adjustedConstraints);
      if (adjustedConstraints.video) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.applyConstraints(adjustedConstraints.video as object));
      }

      const recorder = new MediaRecorder(stream, recordingOptions);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.style["transform"] = "rotateY(180deg)";
      }

      setMediaStream(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (error) {
          setError(undefined);
        }
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      recorder.onstop = () => onMediaStopRecording();
    } catch (error) {
      setError(formatMessage(recorderMessages.recorderErrAccessingCam));
      console.error(formatMessage(recorderMessages.recorderErrAccessingCam), error);
    }
  };

  const stopCapture = () => {
    try {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }

      if (videoRef.current) {
        videoRef.current.srcObject = null;
        videoRef.current.src = "";
      }

      setMediaStream(null);
      setMediaRecorder(null);
    } catch (error) {
      setError("Error accessing camera");
      console.error("Error accessing camera:", error);
    }
  };

  const counterTrigger = async () => {
    let current = COUNTDOWN;
    setCount(current.toString());
    while (current !== 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      current--;
      if (current !== 0) {
        setCount(current.toString());
      } else {
        setCount(undefined);
      }
    }
  };

  const handleRecordingToggle = async () => {
    if (mediaRecorder) {
      if (isRecording) {
        mediaRecorder.stop();
        setRecordingInProcess(false);
        setIsRecording(false);
      } else {
        recordedChunksRef.current = [];
        setIsRecording(true);
        if (useStartCounter) {
          await counterTrigger();
        }
        setRecordingInProcess(true);
        mediaRecorder.start();
        if (videoRef.current) {
          videoRef.current.srcObject = mediaRecorder.stream;
        }
      }
    }
  };

  const reset = (dontClearError?: boolean) => {
    stopCapture();
    if (!dontClearError) {
      setError(undefined);
    }
    setVideoFile(null);
    setCount(undefined);
    setRecordingInProcess(false);
    resetTimer();
    resetOptions();
    setTriggerVideoCreation(false);
  };

  const microphoneMenu = useMemo(
    () =>
      microphoneDevices.map(({ deviceId, label }) => ({
        key: deviceId,
        value: deviceId,
        label
      })) as RecorderContextMediaMenu[],
    [microphoneDevices]
  );

  const cameraMenu = useMemo(
    () =>
      cameraDevices.map(({ deviceId, label }) => ({
        key: deviceId,
        value: deviceId,
        label
      })) as RecorderContextMediaMenu[],
    [cameraDevices]
  );

  return (
    <RecorderContext.RecorderContext.Provider
      value={{
        isRecording,
        startCapture,
        handleRecordingToggle,
        videoRef,
        videoFile,
        error,
        reset,
        ready: !!mediaRecorder && (error ? !blockableMsgs.includes(error as string) : true),
        microphoneDevices,
        cameraDevices,
        counter,
        timeInSec: timeInSecRef.current,
        stopCapture,
        getMediaDevices,
        resetOptions,
        microphoneMenu,
        cameraMenu
      }}
    >
      {children}
    </RecorderContext.RecorderContext.Provider>
  );
};

export default RecorderContextWrapper;
