import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

export const SpanElement = styled.span`
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
  ${({ fontSize }: { fontSize: string }) => css`
    font-size: ${fontSize};
  `}
`;

export const InputElement = styled.input`
  min-width: 1px;
  padding: 0;
  border: none;
  ${({ fontSize }: { fontSize: string }) => css`
    font-size: ${fontSize};
  `}
  &:focus {
    outline-width: 0;
  }
`;

export interface GrowingInputProps {
  fontSize?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  id?: string;
  maxLength?: number;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const GrowingInput = ({
  fontSize,
  onBlur,
  onChange,
  onInput,
  value,
  id,
  maxLength,
  className,
  autoFocus = false,
  disabled
}: GrowingInputProps) => {
  const [content, setContent] = useState("");
  const [width, setWidth] = useState(0);
  const span = useRef(null);

  useEffect(() => {
    if (value) {
      setContent(value);
    }
  }, []);

  // Once the content is changed, the span will have a new width, and we want to reflect that width to the input
  useEffect(() => {
    setWidth((span?.current as unknown as HTMLSpanElement).offsetWidth || 1);
  }, [content]);

  useEffect(() => {
    setContent(value as string);
  }, [value]);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setContent(evt.target.value);
    if (onChange) {
      onChange(evt);
    }
    if (onInput) {
      onInput(evt);
    }
  };
  const blurHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(evt);
    }
  };

  return (
    <div className={className}>
      <SpanElement fontSize={fontSize || "14px"} ref={span}>
        {content}
      </SpanElement>
      <InputElement
        fontSize={fontSize || "14px"}
        maxLength={maxLength || 100}
        value={content}
        id={id}
        type="text"
        style={{ width }}
        onChange={changeHandler}
        onBlur={blurHandler}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </div>
  );
};

export default GrowingInput;
