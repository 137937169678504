import { useDroppable } from "@dnd-kit/core";
import { ReactNode } from "react";
import styled from "styled-components";

const StyledDiv = styled.div<{ $isOver: boolean }>`
  .droppable {
    border-color: ${({ $isOver, theme }) => $isOver && theme.blue2};
    background-color: ${({ $isOver, theme }) => $isOver && `${theme.blue2}AA`};
    padding: 5px 7px;
  }
`;
interface DroppableProps {
  id: string;
  children: ReactNode;
}
const Droppable = ({ children, id }: DroppableProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id
  });

  return (
    <StyledDiv $isOver={isOver} ref={setNodeRef}>
      {children}
    </StyledDiv>
  );
};
export default Droppable;
