import React, { useRef } from "react";
import styled from "styled-components";
import Search from "antd/es/input/Search";
import { useIntl } from "react-intl";
import { templatesMessages } from "app/components/editor/templateSelect/messages";
import { InputRef } from "antd";

// region [[ Styles ]]

const TemplateSearchBoxView = styled.div`
  padding: 40px;
  flex: 1 0 auto;
`;

const FullWidthSearch = styled(Search)`
  width: 100%;
`;

// endregion [[ Styles ]]

// region [[ Props ]]

export interface TemplateSearchBoxProps {
  onSearch: (query: string) => void;
  onReset?: () => void;
  value: string;
}

// endregion [[ Props ]]

// region [[ Functions ]]
// endregion [[ Functions ]]

const TemplateSearchBox = ({ onSearch, onReset, value }: TemplateSearchBoxProps) => {
  const { formatMessage } = useIntl();
  const searchRef = useRef<InputRef>(null);
  const resetAndfocus = () => {
    searchRef.current?.focus();
    if (onReset) {
      onReset();
    }
  };

  return (
    <TemplateSearchBoxView>
      <FullWidthSearch
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        ref={searchRef}
        onReset={resetAndfocus}
        placeholder={formatMessage(templatesMessages.searchTemplatePlaceholder)}
        onSearch={onSearch}
        onChange={(e) => onSearch(e.currentTarget.value)}
        value={value}
      />
    </TemplateSearchBoxView>
  );
};

export default React.memo(TemplateSearchBox);
