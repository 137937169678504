import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import ConditionalRender from "app/components/common/ConditionalRender";
import {
  Location,
  PaymentModalAction,
  PaymentModalPhase,
  PlanEnum,
  PlanToUpgrade,
  PRICING_PAGE_SOURCE
} from "app/types";
import { paymentsActions } from "app/store/slices/payments.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useIntl } from "react-intl";
import { paymentModalMessages } from "app/pages/pricing/messages";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import PaymentModalV2PhaseInitial from "app/pages/pricing/PaymentModalV2/PaymentModalV2PhaseInitial";
import usePaymentFlow from "app/pages/pricing/usePaymentFlow";
import PricingModalPhaseCreditCardDetails from "app/pages/pricing/PaymentModalV2/PaymentModalV2PhaseCreditCardDetails";
import PaymentModalV2PhasePaymentSuccess from "app/pages/pricing/PaymentModalV2/PaymentModalV2PhasePaymentSuccess";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextLargeHeadline, H1_TextSubBanner } from "app/components/_Infrastructure/Typography";
import { bookDemoUrl } from "app/utils/urls";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledModal } from "app/components/common/StyledModal";

const PaymentModalV2 = () => {
  const dispatch = useAppDispatch();
  const { editorModalOpen } = useModal();
  const { formatMessage } = useIntl();
  const { currentPlan: plan } = useCurrentPlan();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const source = editorModalOpen?.context?.source;
  const shouldStartWithCreditCardPage = source === PRICING_PAGE_SOURCE;
  const { phase } = useAppSelector((state) => state.payments.pricingModalProperties);
  const visible = editorModalOpen?.open === ModalName.paymentModalV2;

  const { pricingFetchError, onClose, targetPlan } = usePaymentFlow({
    paymentPeriod: editorModalOpen?.context?.paymentPeriod,
    targetPlan: editorModalOpen?.context?.targetPlan,
    active: visible,
    source: editorModalOpen?.context?.source,
    upgradeText: editorModalOpen?.context?.upgradeText
  });

  const paymentPeriod = editorModalOpen?.context?.paymentPeriod;
  const isPhaseInitial = phase === PaymentModalPhase.INITIAL;
  const isPhaseCreditCardDetails = phase === PaymentModalPhase.CREDIT_CARD_DETAILS;
  const isPhasePaymentSuccess = phase === PaymentModalPhase.PAYMENT_SUCCESS;

  useEffect(() => {
    dispatch(paymentsActions.getPricingAndPlansRequest());
  }, []);

  useEffect(() => {
    if (visible) {
      dispatch(analyticsEvents.openPaymentModal({ source }));
    }
    if (!visible) {
      dispatch(
        paymentsActions.updatePricingModalProperties({
          selectedPlan: PlanToUpgrade.personal,
          phase: PaymentModalPhase.INITIAL,
          originalPlan: undefined,
          coupon: undefined,
          numberOfMinutesPosition: 1
        })
      );
    }
  }, [visible]);

  useEffect(() => {
    if (visible && shouldStartWithCreditCardPage && targetPlan && paymentPeriod) {
      dispatch(
        paymentsActions.updatePricingModalProperties({
          phase: PaymentModalPhase.CREDIT_CARD_DETAILS,
          selectedPlan: targetPlan,
          paymentPeriod
        })
      );
    }
  }, [visible, targetPlan, shouldStartWithCreditCardPage, paymentPeriod]);

  const onFinishInitial = (selectedPlan: PlanToUpgrade) => {
    dispatch(analyticsEvents.pricingUpgrade({ plan: selectedPlan }));
    if (selectedPlan.includes("enterprise")) {
      dispatch(analyticsEvents.pricingMeetingRequest({ source: `upgrade_modal_${source}` }));
      window.open(bookDemoUrl(`upgrade_modal_${source}`), "_blank");

      return;
    }

    dispatch(
      paymentsActions.updatePricingModalProperties({
        phase: PaymentModalPhase.CREDIT_CARD_DETAILS,
        selectedPlan
      })
    );
    dispatch(
      analyticsEvents.userUpgrade({
        source,
        phase: PaymentModalPhase.CHOOSE_PLAN,
        action: PaymentModalAction.next,
        plan: selectedPlan as unknown as PlanEnum
      })
    );
  };

  const onFinishCreditCardDetails = () => {
    dispatch(paymentsActions.cleanPayment());
    dispatch(
      paymentsActions.updatePricingModalProperties({
        phase: PaymentModalPhase.PAYMENT_SUCCESS
      })
    );
    dispatch(analyticsEvents.onPaymentSuccess({ plan, paymentPeriod }));
    dispatch(
      analyticsEvents.userUpgrade({
        source,
        phase: PaymentModalPhase.CREDIT_CARD_DETAILS,
        action: PaymentModalAction.next,
        plan
      })
    );
    if (location.pathname === Location.Pricing) {
      navigate(Location.Home);
    }
  };

  const onBackCreditCardDetails = () => {
    dispatch(paymentsActions.cleanPayment());
    dispatch(
      paymentsActions.updatePricingModalProperties({
        phase: PaymentModalPhase.INITIAL
      })
    );
    dispatch(
      analyticsEvents.userUpgrade({
        source,
        phase: PaymentModalPhase.CREDIT_CARD_DETAILS,
        action: PaymentModalAction.back,
        plan
      })
    );
  };

  return (
    <StyledModal
      width="1013px"
      bodyStyle={{ height: "771px" }}
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      maskClosable={phase !== PaymentModalPhase.CREDIT_CARD_DETAILS}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $flex="1"
    >
      <ConditionalRender condition={pricingFetchError}>
        <H1_FlexColumn flex="1" gap="10px" justify="center" align="center">
          <H1_TextSubBanner color={theme.pink4}>
            {formatMessage(paymentModalMessages.paymentErrorHeader)}
          </H1_TextSubBanner>
          <H1_TextLargeHeadline color={theme.pink4}>
            {formatMessage(paymentModalMessages.paymentErrorDescription)}
          </H1_TextLargeHeadline>
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={!pricingFetchError}>
        <ConditionalRender condition={isPhaseInitial}>
          <PaymentModalV2PhaseInitial onFinish={onFinishInitial} />
        </ConditionalRender>
        <ConditionalRender condition={isPhaseCreditCardDetails}>
          <PricingModalPhaseCreditCardDetails
            visible={visible}
            onFinish={onFinishCreditCardDetails}
            onBack={shouldStartWithCreditCardPage ? undefined : onBackCreditCardDetails}
          />
        </ConditionalRender>
        <ConditionalRender condition={isPhasePaymentSuccess}>
          <PaymentModalV2PhasePaymentSuccess />
        </ConditionalRender>
      </ConditionalRender>
    </StyledModal>
  );
};

export default PaymentModalV2;
