import { createAsyncThunk } from "@reduxjs/toolkit";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { getImportData, importData, updateImportData } from "app/services/serviceV2Apis";
import {
  ImportDataStoreRequest,
  DataStoreResult,
  UpdateDataStoreRequest,
  ThunkApi
} from "app/types";
import { draftsActions } from "app/store/slices/drafts.slice";

const prefix = "[data-stores]";
const MAX_CSV_ROWS = 100;

const importDataRequest = createAsyncThunk<DataStoreResult, ImportDataStoreRequest>(
  `${prefix} importDataRequest`,
  async (fileRequest) => {
    const result: DataStoreResult = await importData(fileRequest);
    return result;
  },
  thunkOptions
);
const importDataAndConnectRequest = createAsyncThunk<
  DataStoreResult,
  ImportDataStoreRequest & { draftId?: string },
  ThunkApi
>(
  `${prefix} importDataAndConnectRequest`,
  async (fileRequest, thunkAPI) => {
    const { draftId, ...rest } = fileRequest;
    let result: DataStoreResult = await importData(rest);
    if (result.id && draftId) {
      thunkAPI.dispatch(
        draftsActions.connectDraftToDatastoreRequest({
          dataStoreId: result.id,
          draftId: draftId as string
        })
      );
    }
    result = updateResultContent(result);
    return result;
  },
  thunkOptions
);

const updateResultContent = (result: DataStoreResult) => {
  if (result.content) {
    const slicedResult = result.content.slice(0, MAX_CSV_ROWS);
    const transformedContent = slicedResult.map((contentItem) => ({
      ...contentItem,
      data: (contentItem.data as unknown as { key: string; value: string }[]).reduce(
        (acc: Record<string, string>, curr: { key: string; value: string }) => {
          acc[curr.key] = curr.value;
          return acc;
        },
        {}
      )
    }));
    result = { ...result, content: transformedContent };
  }
  return result;
};

const getImportDataRequest = createAsyncThunk<
  DataStoreResult,
  { dataStoreId: string; includeContent?: boolean }
>(
  `${prefix} getImportDataRequest`,
  async ({ dataStoreId, includeContent }) => {
    let result: DataStoreResult = await getImportData(dataStoreId, includeContent);
    result = updateResultContent(result);
    return result;
  },
  thunkOptions
);

const updateImportDataRequest = createAsyncThunk<void, UpdateDataStoreRequest>(
  `${prefix} updateImportDataRequest`,
  async (updateDataStoreRequest) => {
    await updateImportData(updateDataStoreRequest);
  },
  thunkOptions
);
export default {
  importDataRequest,
  importDataAndConnectRequest,
  updateImportDataRequest,
  getImportDataRequest
};
