import styled, { useTheme } from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import useModal, { ModalName } from "app/hooks/useModal";
import WorkspaceSearch from "app/components/common/Layout/WorkspaceSearch";
import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-content {
    overflow: visible;
  }
  .ant-modal-body {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 33px 37px 52px 48px;
    gap: 20px;
    background-color: ${({ theme }) => theme.gray2};
    overflow: visible;
  }
`;

const SearchModal = () => {
  const { returnToModal, editorModalOpen } = useModal();
  const theme = useTheme();
  const visible = editorModalOpen?.open === ModalName.globalSearch;

  return (
    <Modal
      width="500px"
      open={visible}
      footer={null}
      bodyStyle={{ height: "200px" }}
      onCancel={returnToModal}
      maskClosable
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexRow gap="10px" align="center">
        <H1_Icon color={theme.gray11} icon="far fa-magnifying-glass" size="20px" />
        <H1_TextMidHeadline>Search for videos and folders</H1_TextMidHeadline>
      </H1_FlexRow>
      <WorkspaceSearch
        isDarkBackground={false}
        containerClassName="search-modal"
        onElementClicked={returnToModal}
      />
    </Modal>
  );
};

export default SearchModal;
