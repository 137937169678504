import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { LayoutAsset, Voice } from "app/types";
import { useAppSelector } from "app/hooks";
import * as characterSelectors from "app/store/selectorsV2/character.selectors";
import * as voiceSelectors from "app/store/selectorsV2/voices.selectors";
import styled, { css, useTheme } from "styled-components";
import { ThemeMode } from "app/utils/theme";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import { replaceFlagSeparator } from "app/utils/helpers";
import React, { useState } from "react";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

const StyledPauseCircleFilled = styled.i`
  font-size: 12px;
  color: ${(props) => props.theme.gray8};
  &:hover {
    color: ${(props) => props.theme.gray7};
  }
`;

const StyledIconBackground = styled(H1_FlexRow)<{ $isVisible?: boolean }>`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray5};
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &&& {
    ${({ $isVisible }) =>
      !!$isVisible &&
      css`
        opacity: 1;
      `};
  }
`;

const StyledPlayCircleFilled = styled.i`
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-size: 12px;
  color: ${(props) => props.theme.gray8};
  &:hover {
    color: ${(props) => props.theme.gray7};
  }
`;

const EntityBorderFlexRow = styled(H1_FlexRow)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.gray3};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray3)};

  ${StyledIconBackground} {
    opacity: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
    ${StyledIconBackground} {
      opacity: 1;
    }
  }
`;

const FlagImg = styled.img`
  border-radius: 10px;
  width: 42px;
  height: 32px;
  object-fit: cover;
`;

const CharacterContainerFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
`;

const CharacterImg = styled.img`
  border-radius: 10px;
  width: 155px;
  height: 141px;
  object-fit: cover;
`;

export interface AvatarsAndVoicesDrawerSingleElementProps {
  characterAsset: LayoutAsset;
  onClickVoicePlay: (e: React.MouseEvent, voice: Voice) => void;
  previewItemId?: string;
  playingVoiceId?: string;
  loadingVoiceId?: string;
}

const AvatarsAndVoicesDrawerSingleElement = ({
  characterAsset,
  onClickVoicePlay,
  previewItemId,
  playingVoiceId,
  loadingVoiceId
}: AvatarsAndVoicesDrawerSingleElementProps) => {
  const [hoverVoiceId, setHoverVoiceId] = useState<boolean>(false);
  const { openDrawer } = useDrawer();
  const selectedCharacter = useAppSelector((state) =>
    characterSelectors.getDrawerCharacterBySelectedScene(state, characterAsset.key)
  );
  const theme = useTheme();

  const voiceAssetKey = characterAsset?.restrictions?.voice_key || "voice";
  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    voiceSelectors.getSelectedVoiceForDrawer(state, voiceAssetKey)
  );

  const flagSvg = replaceFlagSeparator(selectedVoice?.local);
  const isPreviewItem = previewItemId === selectedVoice?.id;
  const isPlaying = playingVoiceId === selectedVoice?.id;
  const isLoadingVoice = loadingVoiceId === selectedVoice?.id;

  const onMouseEnter = () => {
    setHoverVoiceId(true);
  };

  const onMouseLeave = () => {
    setHoverVoiceId(false);
  };
  const onClickAvatar = () => {
    openDrawer(Drawer.Presenter, {
      assetKey: characterAsset.key,
      voiceAssetKey,
      isSwitchingAvatar: true
    });
  };

  const onClickVoice = () => {
    openDrawer(Drawer.Voices, {
      characterAssetKey: characterAsset.key,
      assetKey: voiceAssetKey,
      isSwitchingAvatar: true
    });
  };

  return (
    <H1_FlexColumn width="155px" gap="14px" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <EntityBorderFlexRow
        data-auto-id="avatar-drawer-selected-avatar"
        flex="0 0 102px"
        height="141px"
        padding="0"
        gap="10px"
        align="center"
        onClick={onClickAvatar}
      >
        <CharacterContainerFlexRow overflow="hidden" width="155px" height="141px" flex="0 0 155px">
          <CharacterImg
            src={(selectedCharacter?.thumbnails?.panel.image as string) || selectedCharacter?.image}
          />
        </CharacterContainerFlexRow>
        <NameText $maxWidth="155px" ellipsis={{ tooltip: selectedCharacter?.title }}>
          {selectedCharacter?.title}
        </NameText>
      </EntityBorderFlexRow>
      <EntityBorderFlexRow
        flex="0 0 48px"
        height="48px"
        padding="7px 10px"
        gap="5px"
        align="center"
        onClick={onClickVoice}
        justify="space-between"
      >
        <H1_FlexRow gap="5px" align="center">
          <FlagImg src={`https://flagcdn.com/${flagSvg}.svg`} />
          <NameText $maxWidth="155px" ellipsis={{ tooltip: selectedVoice?.display_name }}>
            {selectedVoice?.display_name}
          </NameText>
        </H1_FlexRow>
        <ConditionalRender condition={!!selectedVoice}>
          <ConditionalRender
            condition={
              (hoverVoiceId &&
                ((isPreviewItem && !isPlaying && !isLoadingVoice) || !isPreviewItem)) ||
              (!isPlaying && !isLoadingVoice)
            }
          >
            <StyledIconBackground onClick={(e) => onClickVoicePlay(e, selectedVoice as Voice)}>
              <StyledPlayCircleFilled className="fas fa-play" />
            </StyledIconBackground>
          </ConditionalRender>
          <ConditionalRender condition={isPreviewItem && isLoadingVoice}>
            <StyledIconBackground $isVisible={isLoadingVoice}>
              <H1_Icon size="10px" color={theme.gray8} icon="fa-solid fa-spinner-third fa-spin" />
            </StyledIconBackground>
          </ConditionalRender>
          <ConditionalRender condition={isPreviewItem && isPlaying && !isLoadingVoice}>
            <StyledIconBackground
              $isVisible={isPlaying}
              onClick={(e) => onClickVoicePlay(e, selectedVoice as Voice)}
            >
              <StyledPauseCircleFilled className="fas fa-pause" />
            </StyledIconBackground>
          </ConditionalRender>
        </ConditionalRender>
      </EntityBorderFlexRow>
    </H1_FlexColumn>
  );
};

export default AvatarsAndVoicesDrawerSingleElement;
