import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { $getNodeByKey, TextNode } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { $isPronunciationNode } from "app/components/common/LexicalEditor/nodes/PronunciationNode";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ListenStatus, voicesActions } from "app/store/slices/voices.slice";
import { v4 as uuidv4 } from "uuid";
import { TranscriptAudioPlayType } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import useTranscriptPlay from "app/hooks/useTranscriptPlay";
import ConditionalRender from "app/components/common/ConditionalRender";
import { scenesActions } from "app/store/slices/scenes.slice";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";

const NextButton = styled(Button)`
  min-width: 34px;
  width: 34px;
`;

const InlineFlexRow = styled(H1_FlexRow)<{ $open: boolean }>`
  display: inline-flex;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
  background-color: ${({ theme, $open }) => ($open ? theme.gray2 : theme.gray3)};
  color: ${({ theme }) => theme.gray8};
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
`;

const ItalicTextXs = styled(H1_TextXs)`
  font-style: italic;
`;

const Icon = styled.i<{ $padding: string; $minWidth?: string }>`
  font-size: 16px;
  cursor: pointer;
  padding: ${({ $padding }) => $padding};
  min-width: ${({ $minWidth }) => $minWidth};
  color: ${({ theme }) => theme.gray11};
  &:hover {
    color: ${({ theme }) => theme.blue4};
  }
`;

const InputContainer = styled(H1_Input)`
  position: relative;
  z-index: 4;
  input {
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
    background-color: ${({ theme }) => theme.blue1};
    color: ${({ theme }) => theme.blue4};
  }
`;

interface PronunciationComponentProps {
  pronounced: string;
  text: string;
  nodeKey: string;
  isFirstCreation: boolean;
}

const PronunciationComponent = ({
  pronounced,
  text,
  isFirstCreation,
  nodeKey
}: PronunciationComponentProps) => {
  const [open, setOpen] = useState(isFirstCreation);
  const [currentText, setCurrentText] = useState<string>(pronounced);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [editor] = useLexicalComposerContext();
  const dispatch = useAppDispatch();
  const { sceneId, scene } = useSelectedScene();
  const { onTogglePlayPause, isCurrentDraftLoaded } = useTranscriptPlay({
    autoPlay: true,
    type: TranscriptAudioPlayType.draft
  });
  const draft = useAppSelector(({ drafts }) => drafts.currentDraft);
  const footerLoading = useAppSelector((state) => state.voices.footerLoading);
  const createVoiceStatus = useAppSelector((state) => state.voices.createVoiceStatus);
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);
  const audioLoading =
    footerLoading ||
    createVoiceStatus === fetchingStatus.loading ||
    footerAudioPlayer.status === ListenStatus.loading;
  const isPlayingAudio = footerAudioPlayer.status === ListenStatus.playing && isCurrentDraftLoaded;
  const sceneVoice = useAppSelector((state) =>
    scenesSelectors.getSceneVoiceBySceneId(state, scene?.id as string)
  );
  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current?.focus();
    }
    dispatch(scenesActions.setScenesChanged(open));
    if (!open) {
      onBlur();
    }
  }, [open]);

  const removePronunciation = () => {
    dispatch(analyticsEvents.editorPronunciationRemoved({ text, pronounced, nodeKey }));
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isPronunciationNode(node)) {
        const index = node.getIndexWithinParent();
        const parent = node.getParent();
        parent?.getChildAtIndex(index);
        const textNode = new TextNode(text);
        parent?.splice(index, 1, [textNode]);
        // Force calling the onBlurText in order to update the server
        editor.focus();
        editor.blur();
      }
    });
  };

  const onBlur = () => {
    setOpen(false);
    dispatch(analyticsEvents.editorPronunciationClosed({ text, pronounced, nodeKey }));
    editor.update(
      () => {
        const node = $getNodeByKey(nodeKey);
        if ($isPronunciationNode(node)) {
          node.setText(currentText || text);
        }
      },
      {
        onUpdate: () => {
          // Force calling the onBlurText in order to update the server
          editor.focus();
          editor.blur();
        }
      }
    );
  };

  const onPreviewPhraseClicked = () => {
    if (isPlayingAudio) {
      onTogglePlayPause();
      return;
    }
    dispatch(analyticsEvents.previewPhrase({ text, pronounced, nodeKey }));
    dispatch(
      voicesActions.createAudioPreviewRequest({
        text: currentText || text,
        orderId: uuidv4(),
        draftId: draft.id as string,
        sceneId,
        type: TranscriptAudioPlayType.text,
        voiceId: sceneVoice?.id as string,
        autoPlay: true
      })
    );
  };

  const onClickText = () => {
    setOpen(true);
  };

  const onUpdateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentText(e.target.value);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = (
    <H1_FlexRow gap="8px" align="center">
      <InputContainer
        initialValue={currentText}
        onChange={onUpdateText}
        minWidth="165px"
        ref={inputRef}
        // @ts-ignore - Should be added to design system in the future
        maxLength={20}
      />
      <ConditionalRender condition={!audioLoading}>
        <NextButton
          isIconOnly
          onClick={onPreviewPhraseClicked}
          startContent={<i className={isPlayingAudio ? "far fa-pause" : "far fa-volume"} />}
          variant="light"
          size="sm"
        />
      </ConditionalRender>
      <ConditionalRender condition={audioLoading}>
        <NextButton isIconOnly size="sm" isLoading variant="light" />
      </ConditionalRender>
      <Icon onClick={onBlur} className="far fa-circle-check" $padding="1px 0" />
      <NextButton
        size="sm"
        variant="light"
        isIconOnly
        startContent={<i className="far fa-trash" />}
        onClick={removePronunciation}
      />
    </H1_FlexRow>
  );

  return (
    <Popover isOpen={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger>
        <InlineFlexRow
          ref={ref}
          data-auto-id="data-lexical-button"
          padding="0 5px"
          justify="center"
          align="center"
          height="22px"
          position="relative"
          $open={open}
          onClick={onClickText}
        >
          <ItalicTextXs
            fontSize="19px"
            lineHeight="1.875rem"
            fontFamily="ArbelHagilda"
            fontWeight={300}
          >
            {text}
          </ItalicTextXs>
        </InlineFlexRow>
      </PopoverTrigger>
      <PopoverContent>{content}</PopoverContent>
    </Popover>
  );
};

export default PronunciationComponent;
