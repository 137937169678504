import type { MouseEventHandler, ReactNode } from "react";
export enum Direction {
  Vertical = "Vertical",
  Horizontal = "Horizontal"
}
export interface IconButtonProps<T> {
  direction?: Direction;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  activeColor?: string;
  color?: string;
  disabledColor?: string;
  backgroundColor?: string;
  height?: string;
  width?: string;
  shrink?: boolean;
  icon?: ReactNode;
  onClick?: MouseEventHandler<T>;
  children?: React.ReactNode;
}
