import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, RangeSelection } from "lexical";
import { $moveCaretSelection } from "@lexical/selection";
import { useEffect } from "react";
import {
  $createPronunciationNode,
  PronunciationNode
} from "app/components/common/LexicalEditor/nodes/PronunciationNode";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import constants from "app/components/common/LexicalEditor/plugins/constants";
import { PronunciationCommandPayload } from "app/components/common/LexicalEditor/plugins/types";

export default function PronunciationPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const dispatch = useAppDispatch();

  const selectCurrentWord = (): string => {
    const CHARACTER = "character";
    const WORD = "word";
    const isHoldingShift = true;
    const isBackward = true;
    const selection = $getSelection() as RangeSelection;
    $moveCaretSelection(selection, isHoldingShift, isBackward, CHARACTER);
    const characterSelection = $getSelection()?.getTextContent() || "";
    $moveCaretSelection(selection, !isHoldingShift, !isBackward, CHARACTER);
    if (characterSelection === " ") {
      $moveCaretSelection(selection, !isHoldingShift, !isBackward, WORD);
      $moveCaretSelection(selection, isHoldingShift, isBackward, WORD);
    } else {
      $moveCaretSelection(selection, !isHoldingShift, isBackward, WORD);
      $moveCaretSelection(selection, isHoldingShift, !isBackward, WORD);
    }
    return $getSelection()?.getTextContent().trim() || "";
  };

  useEffect(() => {
    if (!editor.hasNodes([PronunciationNode])) {
      throw new Error("PronunciationPlugins: PronunciationNode not registered on editor");
    }

    return editor.registerCommand<PronunciationCommandPayload>(
      constants.INSERT_PRONUNCIATION_COMMAND,
      (payload) => {
        const { pronounced, text } = payload;
        const selection = $getSelection();
        if (selection) {
          const wordSelection = selectCurrentWord();
          if ($isRangeSelection(selection) && wordSelection.trim().length > 1) {
            const pronunciationNode = $createPronunciationNode(
              pronounced || wordSelection,
              text || wordSelection,
              true
            );
            dispatch(analyticsEvents.editorPronunciationCreated({ text: wordSelection }));
            selection.insertNodes([pronunciationNode]);
          }
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
