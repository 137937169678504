/* eslint-disable camelcase */
export interface PusherMessagesBase {
  order_id?: string;
  status?: string;
  progress?: number;
}

export enum PusherMessagesStatusEnum {
  ready = "ready",
  failed = "failed",
  processing = "processing",
  idle = "idle"
}

export interface SplitMsg {
  status: PusherMessagesStatusEnum;
  scene_id: string;
  order_id: string;
  workspace_id: string;
}

export interface DraftTranslationMsg {
  status: PusherMessagesStatusEnum;
  draft_id: string;
  order_id: string;
  workspace_id: string;
}
export enum CreditsType {
  AUDIO_PREVIEW = "AUDIO_PREVIEW",
  VIDEO_PREVIEW = "VIDEO_PREVIEW",
  VIDEO_PUBLISH = "VIDEO_PUBLISH",
  VIDEO_DOWNLOAD = "VIDEO_DOWNLOAD",
  LLM = "LLM"
}

export interface CreditsReportMsg {
  credits_type: CreditsType;
  spent_percentage: number;
}

export interface DraftGeneratorMsg {
  status: string;
  draft_id: string;
  order_id: string;
  type?: string;
  req_id?: string;
}
