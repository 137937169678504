import { UseCaseSegmentType } from "app/types/segment";
import React from "react";

export enum OnBoardingSteps {
  userType = "userType",
  contentType = "contentType",
  marketingContentType = "marketingContentType",
  productManagementContentType = "productManagementContentType",
  salesContentType = "salesContentType",
  customerSupportContentType = "customerSupportContentType",
  humanResourcesContentType = "humanResourcesContentType",
  learningDevelopmentContentType = "learningDevelopmentContentType",
  userResearchContentType = "userResearchContentType",
  teamType = "teamType",
  setWorkspace = "setWorkspace",
  inviteTeamMembers = "inviteTeamMembers",
  howDidYouHearAboutUs = "howDidYouHearAboutUs",
  pricingList = "pricingList"
}

export const UseCases: Record<string, UseCaseSegmentType> = {
  learning_development: UseCaseSegmentType.Training,
  human_resources: UseCaseSegmentType.Training,
  other: UseCaseSegmentType.SocialMediaContent,
  personal: UseCaseSegmentType.SocialMediaContent,
  student: UseCaseSegmentType.SocialMediaContent,
  marketing: UseCaseSegmentType.Marketing,
  product_management: UseCaseSegmentType.Marketing,
  customer_support: UseCaseSegmentType.Learning,
  sales: UseCaseSegmentType.Sales,
  user_research: UseCaseSegmentType.News
};
export interface InputInterface {
  inputTitle: string;
  defaultInput: string;
  value: string;
  onClick: (e: React.BaseSyntheticEvent<HTMLInputElement> | any) => void;
}

export interface ButtonInterface {
  headlineTitle: string;
  subTitle?: string;
  icon: string;
  onClick: () => void;
}
export type RouteInterface = Record<
  OnBoardingSteps,
  {
    headlineTitle: string;
    subTitle?: string;
    currentStep?: number;
    continueButton?: () => void;
    continueButtonTitle?: string;
    buttonList?: ButtonInterface[];
    inputList?: InputInterface[];
  }
>;

export enum UserType {
  work = "work",
  personal = "personal",
  student = "student"
}
