import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import styled, { useTheme } from "styled-components";
import LayoutSection from "app/components/editor/sideDrawers/SceneDrawer/LayoutSection";
import ContentSection from "app/components/editor/sideDrawers/SceneDrawer/ContentSection";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import { SceneTabs } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getScenePreviewLoading,
  getTextsAssetFromLayoutWithoutTranscript
} from "app/store/selectorsV2/scenes.selectors";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import { scenesActions } from "app/store/slices/scenes.slice";
import { Image, Tabs, TabsProps, Tooltip } from "antd";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { fetchingStatus, imagePlaceholder } from "app/utils/helpers";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import { CorneredIconButton } from "app/components/editor/scene/Scene";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import { ThemeMode } from "app/utils/theme";

const H1_FlexRowTitle = styled(H1_FlexRow)`
  min-height: 55px;
  flex-wrap: wrap;
`;

const StyledImage = styled(Image)`
  display: flex;
  justify-content: center;
  aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
  // (# TODO: make it work with other false / css value)
  opacity: ${(props: { $aspectRatio: number }) => (props.$aspectRatio ? 1 : 0)};
  transition: all 0.3s ease-in;
`;

const PreviewButton = styled(CorneredIconButton)`
  opacity: 0;
  height: 28px;
  width: 28px;
  color: ${({ theme }) => theme.gray11};
  transition: 0.3s opacity ease-in-out;
`;

const PreviewButtonContainer = styled(H1_FlexColumn)`
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
`;

const PreviewContainerFlexRow = styled(H1_FlexRow)`
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 6px 0;
  border-radius: 10px;
  &:hover {
    ${PreviewButton} {
      opacity: 1;
    }
  }
`;

const TitleInput = styled(H1_Input)`
  font-size: 14px;
  padding: 0;
  border: none;
  background: transparent;
  color: black;
  font-size: 14px;
  justify-content: center;

  input {
    padding: 0;
    border: none;
    word-wrap: break-word;
    word-break: break-all;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
    line-height: 22px;
    max-width: 100%;
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6)};
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    &:focus,
    &:hover,
    &:active {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-skip: none;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
      border: none;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  padding-right: 30px;
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.gray7};
    margin: auto;
    span {
      color: ${({ theme }) => theme.gray7};
      -webkit-font-smoothing: antialiased;
    }
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.gray9};
    span {
      color: ${({ theme }) => theme.gray9};
    }
  }
  .ant-tabs-ink-bar {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2};
  }
  &&.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid
      ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5)};
  }
  && .ant-tabs-tab-btn span {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5)};
  }
  && .ant-tabs-tab-active .ant-tabs-tab-btn span {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2)};
  }
`;

export interface SceneDrawerProps {
  right: string;
  width: string;
  isContentTabOpen?: boolean;
  isLayoutTabOpen?: boolean;
}

const SceneDrawer = ({ right, width }: SceneDrawerProps) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [isScenesLoaded, setIsScenesLoaded] = useState<boolean>(false);
  const intl = useIntl();
  const { editorDrawerOpen } = useDrawer();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { loading: scenePreviewLoading } = useAppSelector((state) =>
    getScenePreviewLoading(state, sceneId)
  );
  const aspectRatio = useAppSelector(templatesSelectors.getAspectRatio);
  const selectedSceneTab = useAppSelector((state) => state.scenes.selectedSceneTab);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const textAssets = useAppSelector((state) =>
    getTextsAssetFromLayoutWithoutTranscript(state, sceneId)
  );
  const media = scene?.layout?.assets?.visual || scene?.layout?.assets?.media;
  const isMediaExists = media && media.length > 0;
  const isTextAssetsExists = textAssets?.length > 0;
  const isDrawerActive = editorDrawerOpen === Drawer.Scene;
  const isCurrentTabLayout = selectedSceneTab === SceneTabs.Layout;
  const isCurrentTabContent = selectedSceneTab === SceneTabs.Content;
  const sceneName = scene?.name;
  const draftId = currentDraft?.id as string;

  useEffect(() => {
    if (
      !isTextAssetsExists &&
      !isMediaExists &&
      !isScenesLoaded &&
      scenesStatus === fetchingStatus.succeeded
    ) {
      dispatch(scenesActions.updateSceneTab(SceneTabs.Layout));
    }

    if (scenesStatus === fetchingStatus.succeeded) {
      setIsScenesLoaded(true);
    }
  }, [isMediaExists, isTextAssetsExists, scenesStatus, isScenesLoaded]);

  const onChangeTab = (tab: SceneTabs) => {
    dispatch(scenesActions.updateSceneTab(tab));
    dispatch(analyticsEvents.changeSceneTab({ source: "tab", tab }));
  };

  const onChangeName = (value: string) => {
    dispatch(
      analyticsEvents.changeSceneName({
        selectedScene: {
          name: sceneName || "",
          id: sceneId,
          index: selectedSceneIndex
        },
        value
      })
    );
    dispatch(
      scenesActions.patchSceneRequest({
        draftId,
        sceneId,
        operations: [{ op: "replace", path: "name", value }]
      })
    );
  };

  const items: TabsProps["items"] = useMemo(() => {
    return [
      {
        key: SceneTabs.Layout,
        label: (
          <H1_FlexRow align="center">
            <H1_TextSmall color={theme.gray9}>
              {intl.formatMessage(sceneDrawerMessages.layoutTitle)}
            </H1_TextSmall>
          </H1_FlexRow>
        )
      },
      {
        key: SceneTabs.Content,
        label: (
          <H1_FlexRow align="center">
            <H1_TextSmall color={theme.gray9}>
              {intl.formatMessage(sceneDrawerMessages.contentTitle)}
            </H1_TextSmall>
          </H1_FlexRow>
        )
      }
    ];
  }, []);

  return (
    <FlexDrawer padding="10px 0 0 30px" right={right} active={isDrawerActive} width={width}>
      <H1_FlexRowTitle align="center" gap="5px">
        <H1_TextSmall
          color={theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray6}
          lineHeight="55px"
        >
          {intl.formatMessage(sceneDrawerMessages.sceneNameTitle, {
            number: selectedSceneIndex + 1
          })}
        </H1_TextSmall>
        <TitleInput
          isFixedWidth
          initialValue={sceneName}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangeName(e.target.value)}
        />
      </H1_FlexRowTitle>
      <H1_FlexRow justify="center" width="320px" height="180px" position="relative">
        <ConditionalRender condition={scenePreviewLoading}>
          <StyledSkeletonButton active block size="large" $width="100%" $height="180px" />
        </ConditionalRender>
        <ConditionalRender condition={!scenePreviewLoading}>
          <PreviewContainerFlexRow justify="center" position="relative" flex="1" overflow="hidden">
            <StyledImage
              preview={{
                visible: isPreviewVisible,
                mask: null,
                onVisibleChange: (visible, prevVisible) => prevVisible && setPreviewVisible(visible)
              }}
              $aspectRatio={aspectRatio}
              height={180}
              width="auto"
              src={scene?.last_preview_url || imagePlaceholder}
              fallback={imagePlaceholder}
            />
            <Tooltip title={intl.formatMessage(sceneMessages.previewTooltip)} placement="left">
              <PreviewButtonContainer>
                <PreviewButton
                  $iconSize="28px"
                  startContent={<i className="fal fa-magnifying-glass" />}
                  isIconOnly
                  onClick={() => setPreviewVisible(!isPreviewVisible)}
                />
              </PreviewButtonContainer>
            </Tooltip>
          </PreviewContainerFlexRow>
        </ConditionalRender>
      </H1_FlexRow>

      <StyledTabs
        activeKey={selectedSceneTab}
        items={items}
        onChange={(key) => onChangeTab(key as SceneTabs)}
      />
      <ConditionalRender condition={isCurrentTabLayout}>
        <LayoutSection />
      </ConditionalRender>
      <ConditionalRender condition={isCurrentTabContent}>
        <ContentSection />
      </ConditionalRender>
    </FlexDrawer>
  );
};

export default SceneDrawer;
