import styled from "styled-components";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { mediaActions } from "app/store/slices/mediaLibrary.slice";
import { Typography } from "antd";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import React, { useEffect, useRef } from "react";
import { KeyboardCode } from "@dnd-kit/core";
import { FolderType } from "app/types";
import { Input } from "@nextui-org/react";

const NameText = styled(Typography.Text)`
  color: ${(props) => props.theme.gray8};
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  height: 32px;
  && {
    max-width: calc(100% - 1px); // Due to ellipsis we should not use 100% width as antd requires
  }
`;

// todo need to support file and folder

const MediaLibraryEditName = React.memo(
  ({
    editable,
    name,
    fileId,
    onEdit,
    onCloseEdit
  }: {
    name: string;
    fileId: string;
    editable: boolean;
    onEdit: () => void;
    onCloseEdit: () => void;
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const file = useAppSelector((state) => mediaGlobalSelectors.selectById(state, fileId));
    const fileName = name || file?.name;

    const filePostfix: string =
      fileName && file?.type === FolderType.file ? "." + fileName?.split(".").pop() : "";

    useEffect(() => {
      if (editable) {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    }, [editable]);

    const updateMedia = (fileUpdateName: string) => {
      const mediaId = file?.data?.id as string;
      analyticsEvents.editMedia({
        name: fileUpdateName as string,
        mediaId
      });

      if (file?.type === FolderType.folder) {
        dispatch(
          mediaActions.updateMediaFolderRequest({
            folderId: file?.id as string,
            name: fileUpdateName
          })
        );
      } else {
        dispatch(
          mediaActions.updateMediaRequest({
            fileId: file?.id as string,
            mediaId,
            mediaUpdate: {
              name: `${fileUpdateName}${filePostfix}`
            }
          })
        );
      }

      onCloseEdit();
    };

    const closeOnEsc = () => {
      onCloseEdit();
    };

    const onBlur = (event: any) => {
      updateMedia(event.target?.value as string);
    };

    const onKeyDown = (event: React.KeyboardEvent & React.ChangeEvent<HTMLInputElement>) => {
      if (event.key === KeyboardCode.Enter) {
        updateMedia(event.target.value as string);
      }
      if (event.key === KeyboardCode.Esc) {
        event.stopPropagation();
        closeOnEsc();
      }
    };

    if (!editable) {
      return (
        <NameText
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation(); // Avoid select item when clicking on name
            onEdit();
          }}
          ellipsis={{ tooltip: fileName }}
        >
          {fileName}
        </NameText>
      );
    } else {
      return (
        <H1_FlexRow
          width="100%"
          justify="center"
          align="stretch"
          onClick={(event) => {
            event.stopPropagation(); // Avoid select item when editing name
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onDoubleClick={(event: any) => {
            event.stopPropagation(); // Avoid select item when editing name
          }}
        >
          <Input
            ref={inputRef}
            size="sm"
            labelPlacement="outside"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            // @ts-ignore - ..
            onKeyDown={onKeyDown}
            defaultValue={fileName?.replace(filePostfix, "")}
            onBlur={onBlur}
          />
        </H1_FlexRow>
      );
    }
  },
  (prevProps, nextProps) =>
    prevProps.editable === nextProps.editable &&
    prevProps.name === nextProps.name &&
    prevProps.fileId === nextProps.fileId
);

MediaLibraryEditName.displayName = "MediaLibraryEditName";

export default MediaLibraryEditName;
