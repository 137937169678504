import { createContext, useState, ReactNode, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { PasswordProtectionPayload, ProtectionType, Video } from "app/types";
import { videosActions } from "app/store/slices/videos.slice";
import { videoProtectionMessages } from "app/components/VideoShareProtection/messages";
import { fetchingStatus } from "app/utils/helpers";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";

interface ProtectionOption {
  key: ProtectionType;
  icon: JSX.Element;
  headline: string;
  subtitle: string;
}

enum DisplayStateEnum {
  SHOW_PASSWORD_DIALOG = "showPasswordDialog",
  PUBLIC = "public",
  PROTECTED_BY_PASSWORD = "protectedByPassword"
}

export interface VideoProtectionContextProps {
  displayState: DisplayStateEnum;
  setVideoProtectionDisplay: (display: DisplayStateEnum) => void;
  currentProtectionOption: (protection?: ProtectionType) => ProtectionOption;
  protectionOptions: ProtectionOption[];
  fetchVideoProtection: () => void;
  setVideoProtection: (protection: ProtectionType, payload?: PasswordProtectionPayload) => void;
}

const VideoProtectionContext = createContext<VideoProtectionContextProps | undefined>(undefined);

interface VideoProtectionProviderProps {
  children: ReactNode;
  videoId: string;
}

const VideoProtectionProvider = ({ children, videoId }: VideoProtectionProviderProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [displayState, setDisplayState] = useState(DisplayStateEnum.PUBLIC);
  const selectedVideo: Video | undefined = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, videoId as string)
  );

  const setVideoProtectionStatus = useAppSelector((state) => state.videos.setVideoProtectionStatus);
  const fetchVideoProtectionStatus = useAppSelector(
    (state) => state.videos.fetchVideoProtectionStatus
  );

  const setVideoProtection = (
    protectionType: ProtectionType,
    payload?: undefined | PasswordProtectionPayload
  ) => {
    switch (protectionType) {
      case ProtectionType.Password:
        if (!payload || !payload.password) return;
        showPasswordDialog();
        dispatch(
          googleEvents.onVideoProtectionChange({
            videoId,
            protection: ProtectionType.Password
          })
        );
        dispatch(
          videosActions.setVideoPasswordProtectionRequest({ videoId, password: payload.password })
        );
        break;
      case ProtectionType.None:
        showPublic();
        dispatch(
          googleEvents.onVideoProtectionChange({
            videoId,
            protection: ProtectionType.None
          })
        );
        dispatch(videosActions.removeVideoProtectionRequest({ videoId }));
        break;
      default:
        console.error("Unknown protection type");
        break;
    }
  };

  const fetchVideoProtection = () => {
    dispatch(videosActions.fetchVideoProtectionRequest({ videoId }));
  };

  const showPasswordDialog = () => setDisplayState(DisplayStateEnum.SHOW_PASSWORD_DIALOG);
  const showPublic = () => setDisplayState(DisplayStateEnum.PUBLIC);
  const showProtectedByPassword = () => setDisplayState(DisplayStateEnum.PROTECTED_BY_PASSWORD);

  const setVideoProtectionDisplay = (display: DisplayStateEnum) => {
    if (!selectedVideo) return;
    setDisplayState(display);
    if (display === DisplayStateEnum.PUBLIC && selectedVideo?.protected !== ProtectionType.None) {
      setVideoProtection(ProtectionType.None);
    }
  };

  const showCurrentPretectionAfterFetching = () => {
    switch (selectedVideo?.protected) {
      case ProtectionType.Password:
        showProtectedByPassword();
        break;
      default:
        showPublic();
        break;
    }
  };

  useEffect(() => {
    if (fetchVideoProtectionStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateFetchVideoProtectionRequest());
    }
    if (fetchVideoProtectionStatus === fetchingStatus.succeeded) {
      dispatch(videosActions.updateFetchVideoProtectionRequest());
    }
  }, [fetchVideoProtectionStatus]);

  useEffect(() => {
    if (selectedVideo && fetchVideoProtectionStatus === fetchingStatus.idle) {
      showCurrentPretectionAfterFetching();
    }
  }, [fetchVideoProtectionStatus, selectedVideo]);

  useEffect(() => {
    if (setVideoProtectionStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateSetVideoProtectionStatus());
    }
    if (setVideoProtectionStatus === fetchingStatus.succeeded) {
      dispatch(videosActions.updateSetVideoProtectionStatus());
    }
  }, [setVideoProtectionStatus]);

  useEffect(() => {
    if (selectedVideo && setVideoProtectionStatus === fetchingStatus.idle) {
      showCurrentPretectionAfterFetching();
    }
  }, [setVideoProtectionStatus, selectedVideo]);

  const protectionOptions: ProtectionOption[] = [
    {
      key: ProtectionType.None,
      icon: <i className="fa-sharp fa-light fa-globe" />,
      headline: formatMessage(videoProtectionMessages.anyoneWithTheLink),
      subtitle: formatMessage(videoProtectionMessages.publiclyAvailable)
    },
    {
      key: ProtectionType.Password,
      icon: <i className="fa-regular fa-lock" />,
      headline: formatMessage(videoProtectionMessages.passwordProtected),
      subtitle: formatMessage(videoProtectionMessages.passwordProtectedExplained)
    }
  ];

  const currentProtectionOption = useCallback(
    (protection?: ProtectionType): ProtectionOption => {
      return protectionOptions.find(
        (protectionOption: ProtectionOption) =>
          protectionOption.key ===
          (displayState !== DisplayStateEnum.PUBLIC ? ProtectionType.Password : protection)
      ) as ProtectionOption;
    },
    [displayState]
  );

  return (
    <VideoProtectionContext.Provider
      value={{
        displayState,
        setVideoProtectionDisplay,
        currentProtectionOption,
        protectionOptions,
        fetchVideoProtection,
        setVideoProtection
      }}
    >
      {children}
    </VideoProtectionContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export { VideoProtectionProvider, VideoProtectionContext, DisplayStateEnum };
