import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useEffect, useState, useMemo } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled from "styled-components";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import messages from "app/components/common/player/messages";
import { IntlShape, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { videosActions } from "app/store/slices/videos.slice";
import { Video } from "app/types";
import videosSelectors from "app/store/selectorsV2/videos.selectors";
import { fetchingStatus } from "app/utils/helpers";
import { Modal, Rate } from "antd";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import FeedbackModal from "./FeedbackModal";

const StyledBlueBar = styled(H1_FlexRow)`
  border: 1px solid ${(props) => props.theme.blue4};
  color: ${(props) => props.theme.blue4};
`;

const StyledRate = styled(Rate)`
  font-size: 15px;
  color: ${(props) => props.theme.blue4};
  path {
    stroke: ${(props) => props.theme.blue4};
    stroke-width: 30px;
  }
`;

const feedbackOptionsCreate = (intl: IntlShape) => [
  {
    value: intl.formatMessage(messages.renderQualityOption)
  },
  {
    value: intl.formatMessage(messages.avatarLipsyncOption)
  },
  {
    value: intl.formatMessage(messages.avatarMovementOption)
  },
  {
    value: intl.formatMessage(messages.avatareEpressivenessOption)
  },
  {
    value: intl.formatMessage(messages.voiceQualityOption)
  },
  {
    value: intl.formatMessage(messages.templateQualityOption)
  },
  {
    value: intl.formatMessage(messages.cameraMovmentOption)
  },
  {
    value: intl.formatMessage(messages.mediaElementOption)
  }
];
const FeedbackBar = ({
  reqId,
  onOpenTextualFeedback
}: {
  reqId: string;
  onOpenTextualFeedback?: () => void;
}) => {
  const [readyToDisplay, setReadyToDisplay] = useState<boolean>(false);
  const [readyToTextualFeedback, setReadyToTextualFeedback] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const selectedVideo: Video | undefined = useAppSelector((state) =>
    videosSelectors.getVideoByReqId(state, reqId)
  );
  const fetchFeedbackStatus = useAppSelector((state) => state.videos.videoFetchFeedbackStatus);
  const videoUpsertVideoFeedbackStatus = useAppSelector(
    (state) => state.videos.videoUpsertVideoFeedbackStatus
  );
  const feedbackOptions = useMemo(() => feedbackOptionsCreate(intl), [intl]);

  const modalTitle = intl.formatMessage(messages.feedbackModalTitle);
  useEffect(() => {
    if (selectedVideo?.id && fetchFeedbackStatus === fetchingStatus.idle) {
      dispatch(videosActions.getVideoFeedbackRequest({ videoId: selectedVideo?.id }));
    }
  }, []);

  useEffect(() => {
    if (fetchFeedbackStatus === fetchingStatus.succeeded) {
      setReadyToDisplay(!selectedVideo?.feedbacked);
      dispatch(videosActions.updateVideoFetchFeedbackStatusStateToIdle());
    }
    if (fetchFeedbackStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateVideoFetchFeedbackStatusStateToIdle());
      setReadyToDisplay(false);
    }
  }, [fetchFeedbackStatus]);

  useEffect(() => {
    if (videoUpsertVideoFeedbackStatus === fetchingStatus.succeeded) {
      dispatch(videosActions.updateVideoUpsertFeedbackStatusStateToIdle());
      if (readyToTextualFeedback) {
        let feedback: string[] = [];

        Modal.success({
          title: modalTitle,
          centered: true,
          closable: true,
          content: (
            <FeedbackModal
              options={feedbackOptions}
              onSelect={(val: string[]) => {
                feedback = val;
              }}
            />
          ),
          onCancel: () => {
            dispatch(videosActions.updateVideoFetchFeedbackStatusStateToIdle());
            setReadyToDisplay(false);
            setReadyToTextualFeedback(false);
          },
          onOk: () => {
            dispatch(
              videosActions.upsertVideoFeedbackRequest({
                videoId: selectedVideo?.id as string,
                reaction: {
                  feedback,
                  rate: selectedVideo?.feedbacked?.rate || 0
                }
              })
            );
            setReadyToDisplay(false);
            setReadyToTextualFeedback(false);
            dispatch(
              analyticsEvents.feedbackAVideo({
                reaction: {
                  feedback,
                  rate: selectedVideo?.feedbacked?.rate as number
                },
                videoId: selectedVideo?.id as string
              })
            );
          }
        });
      }
    }

    if (videoUpsertVideoFeedbackStatus === fetchingStatus.failed) {
      dispatch(videosActions.updateVideoUpsertFeedbackStatusStateToIdle());
    }
  }, [videoUpsertVideoFeedbackStatus]);

  useEffect(() => {
    return () => {
      setReadyToDisplay(false);
      setReadyToTextualFeedback(false);
    };
  }, []);

  const onStarClicked = (starIndex: number) => {
    if (selectedVideo?.id && videoUpsertVideoFeedbackStatus == fetchingStatus.idle) {
      dispatch(
        videosActions.upsertVideoFeedbackRequest({
          videoId: selectedVideo?.id as string,
          reaction: {
            feedback: [],
            rate: starIndex
          }
        })
      );
      setReadyToTextualFeedback(starIndex < 4);
      if (starIndex < 4) {
        if (onOpenTextualFeedback) {
          onOpenTextualFeedback();
        }
      } else {
        setReadyToDisplay(false);
      }
      dispatch(
        analyticsEvents.feedbackAVideo({
          reaction: {
            feedback: [],
            rate: starIndex
          },
          videoId: selectedVideo?.id
        })
      );
    }
  };

  return (
    <ConditionalRender condition={readyToDisplay}>
      <StyledBlueBar
        justify="center"
        align="center"
        gap="20px"
        margin="20px 0 0"
        padding="10px"
        width="100%"
      >
        <H1_TextSmall>{intl.formatMessage(messages.feedbackBarExplained)}</H1_TextSmall>
        <H1_FlexRow gap="2.5px" justify="center" align="center">
          <StyledRate onChange={onStarClicked} value={selectedVideo?.feedbacked?.rate || 0} />
        </H1_FlexRow>
      </StyledBlueBar>
    </ConditionalRender>
  );
};

export default FeedbackBar;
