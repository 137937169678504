import styled, { useTheme } from "styled-components";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Location, Webhook, WebhookStatus } from "app/types";
import React, { useMemo } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useIntl } from "react-intl";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import EmptyState from "app/components/common/EmptyState";
import { WebhooksTableMessages } from "app/pages/webhooks/messages";
import { dateSorter, getTimeAgo } from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import { webhooksGlobalSelectors } from "app/store/adapters/adapters";
import { useNavigate } from "react-router-dom";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { capitalize } from "lodash";

const StyledNameText = styled(NameText)`
  font-weight: 400;
`;
const StyledTable = styled(Table)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  margin-top: 0.3125rem;
  .ant-table {
    background-color: transparent;
  }
  && .ant-table-thead > tr > th {
    border-bottom: 1px solid ${(props) => props.theme.gray5};
    background-color: transparent;
    padding-left: 0;
    position: sticky;
    top: 0;
    background-color: ${(props) => props.theme.gray2};
    z-index: 11;
  }
  .ant-table table {
    border-spacing: 0 0.625rem;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0.5625rem 0.5rem;
  }
  & .ant-table-column-sorters {
    flex-direction: row-reverse;
    gap: 0.3125rem;
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
        transition: all 0.5s ease-in-out;
        background-color: transparent;
        height: 5.3125rem;
      }
      &:hover {
        td {
          background-color: ${(props) =>
            props.theme.gray3} !important; /* &&& doesn't work with &:hover */
          ${NameText} {
            text-decoration: underline;
          }
        }
      }
      padding: 0.625rem 1rem 0.625rem;
      &:not(:last-child) td {
        border-bottom: 1px solid ${(props) => props.theme.gray5};
      }
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

const WebhooksTable = ({
  onCreateWebhook,
  hideEmptyState = false
}: {
  hideEmptyState?: boolean;
  onCreateWebhook: () => void;
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const navigate = useNavigate();
  const webhooks = useAppSelector(webhooksGlobalSelectors.selectAll);

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: formatMessage(WebhooksTableMessages.tableNameColumn),
        dataIndex: "name",
        key: "name",
        width: "3rem",
        render: (name: string) => {
          return (
            <H1_FlexRow gap="1rem" align="center">
              <NameText $maxWidth="22.5rem" ellipsis={{ tooltip: name }}>
                {name}
              </NameText>
            </H1_FlexRow>
          );
        },
        sorter: (a: Webhook, b: Webhook) => (a.name as string).localeCompare(b.name as string)
      },
      {
        title: formatMessage(WebhooksTableMessages.tableDescriptionColumn),
        dataIndex: "description",
        key: "description",
        width: "3rem",
        render: (description: string) => {
          return (
            <H1_FlexRow gap="1rem" align="center">
              <StyledNameText $maxWidth="22.5rem" ellipsis={{ tooltip: description }}>
                {description}
              </StyledNameText>
            </H1_FlexRow>
          );
        }
      },
      {
        title: formatMessage(WebhooksTableMessages.tableUpadtedColumn),
        dataIndex: "updated_at",
        key: "updated_at",
        width: "3rem",
        render: (editedAt: string) => {
          return (
            <H1_FlexColumn>
              <H1_TextSmall>{editedAt && getTimeAgo(editedAt)}</H1_TextSmall>
            </H1_FlexColumn>
          );
        },
        sorter: (a: Webhook, b: Webhook) =>
          dateSorter(a.updated_at as string, b.updated_at as string)
      },
      {
        title: formatMessage(WebhooksTableMessages.tableStatusColumn),
        dataIndex: "status",
        width: "3rem",
        key: "status",
        render: (status: string) => {
          let statusColor = "black";
          if (status) {
            switch (status) {
              case WebhookStatus.active:
                statusColor = theme.green4;
                break;
              case WebhookStatus.inactive:
                statusColor = theme.gray7;
                break;
              case WebhookStatus.paused:
                statusColor = theme.pink4;
                break;
              default:
                statusColor = theme.gray11;
            }
          }
          return <H1_TextSmall color={statusColor}>{capitalize(status)}</H1_TextSmall>;
        }
      },
      {
        title: formatMessage(WebhooksTableMessages.tableUrlColumn),
        dataIndex: "url",
        key: "url",
        width: "10rem",
        render: (url: string) => {
          return <H1_TextSmall>{url}</H1_TextSmall>;
        }
      }
    ],
    [webhooks]
  );

  return (
    <>
      <ConditionalRender condition={webhooks.length === 0 && !hideEmptyState}>
        {/*todo*/}
        <EmptyState
          text={formatMessage(WebhooksTableMessages.emptyWebhookText)}
          description={formatMessage(WebhooksTableMessages.emptyWebhookDescription)}
          withButton
          buttonText={formatMessage(WebhooksTableMessages.emptyWebhookButtonPage)}
          icon="fal fa-webhook"
          onClickButton={onCreateWebhook}
        />
      </ConditionalRender>
      <ConditionalRender condition={webhooks.length > 0}>
        <StyledTable
          size="middle"
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={webhooks}
          onRow={(record: any) => ({
            onClick: () => {
              navigate(`${Location.Webhooks}/${record.id}`);
            }
          })}
        />
      </ConditionalRender>
    </>
  );
};

export default WebhooksTable;
