import { defineMessages } from "react-intl";

const messages = defineMessages({
  knowledgeBase: {
    id: "zendesk.knowledge-base",
    defaultMessage: "Knowledge Base"
  },
  feedback: {
    id: "zendesk.feedback",
    defaultMessage: "Feedback"
  },
  contactSupport: {
    id: "zendesk.contact-support",
    defaultMessage: "Contact Support"
  }
});

export default messages;
