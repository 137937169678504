import React, { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "app/types";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import TranscriptAudioModalContent, {
  VIEW_MODE
} from "app/components/editor/scene/transcriptAudioUploader/TranscriptAudioModalContent";
import { StyledModal } from "app/components/common/StyledModal";

interface TranscriptAudioUploadPlaygroundModalProps {
  open: boolean;
  closeModal: () => void;
  file?: File;
  reset: () => void;
  onSelectFile: (file: File) => void;
  importLoading: boolean;
  executeImport: () => void;
  fileStoreResult?: { url: string; handle: string };
}
const TranscriptAudioUploadPlaygroundModal = ({
  open,
  closeModal,
  file,
  reset,
  onSelectFile,
  importLoading,
  executeImport
}: TranscriptAudioUploadPlaygroundModalProps) => {
  const [viewMode, setViewMode] = useState<VIEW_MODE>(VIEW_MODE.main);
  const flags = useFlags();
  const MAX_TIME_IN_SEC = flags[FeatureFlag.transcriptAudioUploadNoLimit] ? 600 : 60;

  useEffect(() => {
    if (!open) {
      // reset();
      setViewMode(VIEW_MODE.main);
    }
  }, [open]);

  const onModalCancel = () => {
    closeModal();
  };

  const onChooseFile = (selectedFile: File) => {
    onSelectFile(selectedFile);
    setViewMode(VIEW_MODE.finish);
  };

  const cleanFile = () => {
    reset();
  };

  const onUserChooseFile = (file: File) => {
    onSelectFile(file);
    // setViewMode(VIEW_MODE.finish);
  };

  return (
    <StyledModal
      width="530px"
      open={open}
      footer={null}
      centered
      bodyStyle={{ height: "600px" }}
      onCancel={onModalCancel}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      closable={!importLoading}
      $padding="50px"
      $alignItems="flex-start"
      $justifyContent="space-between"
    >
      <TranscriptAudioModalContent
        file={file}
        maxTimeInSec={MAX_TIME_IN_SEC}
        viewMode={viewMode}
        importLoading={importLoading}
        onChooseFile={onChooseFile}
        cleanFile={cleanFile}
        onSelectFile={onUserChooseFile}
        executeImport={executeImport}
        onRecordSelect={() => setViewMode(VIEW_MODE.voiceRecording)}
        onCancel={onModalCancel}
      />
    </StyledModal>
  );
};

export default TranscriptAudioUploadPlaygroundModal;
