import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TextInput from "app/components/editor/scene/TextInput";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { aiGenerateMessages } from "app/components/editor/scene/AugmentedSelection/messages";
import { useIntl } from "react-intl";
import ConditionalRender from "app/components/common/ConditionalRender";

const GradientSmallText = styled(H1_TextSmall)`
  background: linear-gradient(100.91deg, #885ef1 0%, #ec007f 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  && {
    font-weight: 600;
    line-height: 27px;
    font-size: 14px;
  }
`;

const StyledTextArea = styled(TextInput)<{ $indent: string }>`
  &&& {
    border: none;
    resize: none;
    font-size: 14px;
    color: ${(props) => props.theme.gray10};
    line-height: 25px;
    width: 100%;
    outline: none;
    background: transparent;
    padding: 0;
    overflow: hidden;
    height: auto;
    text-indent: ${({ $indent }) => $indent};
    margin-right: -146px;
    margin-top: -25px;
  }
`;

const HiddenMirroredSizedSpan = styled.span<{ $indent: string }>`
  height: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: ${({ $indent }) => $indent};
  background-color: red;
  text-wrap: nowrap; // Critical for making the mirrored span off contianer to detect netural break lines.
`;

const Container = styled.div<{ $overlap: boolean }>`
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ $overlap }) =>
    $overlap
      ? "0"
      : "-43px"}; // If value or placeholder is not overlapping container, make the button div higher
  transition: margin-bottom 0.2s cubic-bezier(0.19, 1, 0.22, 1);
`;

interface AIPromptAreaProps {
  placeholder: string;
  value: string;
  onChange?: (text: string) => void;
  disabled?: boolean;
  addSceneSelect?: boolean;
  checkOverlapping?: boolean;
}

const AIPromptArea = ({
  placeholder,
  value,
  onChange,
  disabled = false,
  addSceneSelect = false,
  checkOverlapping = false
}: AIPromptAreaProps) => {
  const { formatMessage } = useIntl();
  const [rightLimit, setRightLimit] = useState<number>();
  const [overlap, setOverlap] = useState<boolean>(false);
  const ref = useRef<HTMLSpanElement>(null);
  const refRoot = useRef<HTMLDivElement>();
  const buttonsSize = useCallback(() => (addSceneSelect ? 300 : 110), [addSceneSelect]);
  const textIndentation = `142px`;

  const onRefChange = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        refRoot.current = node;
        calcRightLimit();
      }
    },
    [refRoot]
  );

  const calcRightLimit = useCallback(() => {
    if (refRoot?.current) {
      const rect = refRoot.current.getBoundingClientRect();
      setRightLimit(rect.x + rect.width);
    }
  }, []);

  const calcIntersection = () => {
    if (ref.current && rightLimit) {
      const rect = ref.current.getBoundingClientRect();
      const right = rect.x + rect.width;
      const isMaxingRight = right > rightLimit - buttonsSize();
      setOverlap(isMaxingRight);
    }
  };

  useEffect(() => {
    if (checkOverlapping && ref.current && rightLimit) {
      calcIntersection();
    } else {
      setOverlap(true);
    }
  }, [value, ref.current, rightLimit]);

  return (
    <Container ref={onRefChange} $overlap={overlap}>
      <GradientSmallText>{formatMessage(aiGenerateMessages.promptPrefix)}</GradientSmallText>
      <ConditionalRender condition={checkOverlapping}>
        <HiddenMirroredSizedSpan aria-hidden="true" ref={ref} $indent={textIndentation}>
          {placeholder}
        </HiddenMirroredSizedSpan>
      </ConditionalRender>
      <StyledTextArea
        $indent={textIndentation}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        autoSize={{
          minRows: 1,
          maxRows: 99
        }}
      />
    </Container>
  );
};

AIPromptArea.displayName = "AIPromptArea";

export default AIPromptArea;
