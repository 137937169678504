import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import SkeletonButton from "antd/es/skeleton/Button";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

import styled from "styled-components";

const StyledSkeletonButton = styled(SkeletonButton)`
  &&&.ant-skeleton.ant-skeleton-block {
    width: 12.3125rem;
  }
  &&&.ant-skeleton-element .ant-skeleton-button {
    height: calc(12.3125rem * 9 / 16 + 3.6875rem);
    width: 12.3125rem;
    border-radius: 5px;
  }
`;

const GridSkeletonRow = () => {
  return (
    <H1_FlexRow gap="1.75rem" padding="0 0 1.25rem 0">
      <StyledSkeletonButton active block size="large" />
      <StyledSkeletonButton active block size="large" />
      <StyledSkeletonButton active block size="large" />
      <StyledSkeletonButton active block size="large" />
      <StyledSkeletonButton active block size="large" />
    </H1_FlexRow>
  );
};

const PageGridSkeleton = () => {
  return (
    <H1_FlexColumn padding="0 1.875rem 0 0">
      <GridSkeletonRow />
      <GridSkeletonRow />
    </H1_FlexColumn>
  );
};

export default PageGridSkeleton;
