/* eslint-disable react/jsx-pascal-case,react/jsx-props-no-spreading */
import React, { useMemo, useRef } from "react";

import { useIntl } from "react-intl";

import "app/pages/HomePage/HomePage.scss";
import { homePageMessages } from "app/pages/HomePage/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import SocialMediaCard, { SocialMediaCardProps } from "app/pages/HomePage/SocialMediaCard";
import { TargetAudience } from "app/types";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { useTheme } from "styled-components";

const SocialMediaCollection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const socialMediaCards: SocialMediaCardProps[] = useMemo(() => {
    return [
      {
        type: TargetAudience.creators,
        text: formatMessage(homePageMessages.youtube),
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/socialStrip1.webp"
            alt="creators"
          />
        )
      },
      {
        type: TargetAudience.training,
        text: formatMessage(homePageMessages.instagram),
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/socialStrip2.webp"
            alt="L&D"
          />
        )
      },
      {
        type: TargetAudience.sales,
        text: formatMessage(homePageMessages.tiktok),
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/socialStrip3.webp"
            alt="sales"
          />
        )
      },
      {
        type: TargetAudience.developers,
        text: formatMessage(homePageMessages.facebook),
        image: (
          <img
            width="100%"
            height="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/socialStrip4.webp"
            alt="developers"
          />
        )
      }
      // {
      //   type: SocialMedia.linkedin,
      //   text: formatMessage(homePageMessages.linkedin),
      //   icon: <i className="fa-brands fa-linkedin-in" />,
      //   backgroundGradient:
      //     "radial-gradient(111.39% 356.84% at 54.26% 50%, #06679B 0%, rgba(51, 98, 131, 0) 100%)"
      // },
      // {
      //   type: SocialMedia.twitter,
      //   text: formatMessage(homePageMessages.twitter),
      //   icon: <i className="fa-brands fa-twitter" />,
      //   backgroundGradient:
      //     "radial-gradient(171.87% 400.82% at 50% 50%, #67C2FF 0%, rgba(29, 155, 240, 0.1) 100%)"
      // }
    ];
  }, []);

  return (
    <H1_FlexColumn padding="2rem 0.875rem 0 0" width="100%" flex="1 0 auto" ref={ref}>
      <ConditionalRender condition={socialMediaCards.length > 0}>
        <H1_FlexRow
          align="center"
          justify="space-between"
          padding="0 0 1.3125rem 0"
          margin="0 0 0 3.125rem"
        >
          <H1_TextMiddle color={theme.gray8} fontWeight={500}>
            {formatMessage(homePageMessages.socialMediaCollection)}
          </H1_TextMiddle>
        </H1_FlexRow>
        <H1_FlexRow
          height="100%"
          width="100%"
          position="relative"
          padding="0 2.25rem 1.875rem 3.125rem"
          overflow="hidden"
          gap="30px"
        >
          {socialMediaCards.map((socialMediaCard: SocialMediaCardProps, index: number) => (
            <SocialMediaCard key={socialMediaCard.text + index} {...socialMediaCard} />
          ))}
        </H1_FlexRow>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default SocialMediaCollection;
