import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { userActions } from "app/store/slices/user.slice";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Divider, Tooltip } from "antd";
import useCopy from "app/hooks/useCopy";
import { useIntl } from "react-intl";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { apiKeyMessages, profileMessages } from "app/pages/profile/messages";
import { PlanEnum, User } from "app/types";
import { useAuth } from "app/auth/useAuth";
import styled, { useTheme } from "styled-components";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import usePermissions from "app/hooks/usePermissions";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { deleteProfile } from "app/services/serviceV2Apis";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import OppositeUpgradeButton from "app/components/common/UpgradeButton/OppositeUpgradeButton";
import { useUpgradeToEnterprise } from "app/hooks/useUpgradeModal";
import { apiDocsUrl } from "app/utils/urls";
import { SubscriptionStatus, SubscriptionStatusValue } from "app/types/payments";
import CancelModal from "app/pages/profile/CancelModal";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";
import useConfirmModal from "app/hooks/useConfirmModal";

const WhiteFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
  border-radius: 4px;
`;

const StyledFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    width: 100%;
  }
`;

const StyledFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
  }
`;

const CircleIcon = styled.i`
  color: ${({ theme }) => theme.gray1};
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
`;
const CircleIconContainer = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray7};
  border-radius: 50%;
`;

const MaxButton = styled(Button)`
  min-width: 10px;
  color: ${({ theme }) => theme.gray9};
`;
const StyledButton = styled(Button)`
  height: 20px;
  font-size: 12px;
  line-height: 20px;
`;

const ApiKeysCard = () => {
  const [showQuestions, setShowQuestions] = useState(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { triggerCopy } = useCopy();
  const theme = useTheme();
  const { isAllowUseApiKeys, isWriteWorkspaceAllow } = usePermissions();
  const { currentPlan } = useCurrentPlan();
  const upgradeToEnterprise = useUpgradeToEnterprise();
  const auth = useAuth();
  const navigate = useNavigate();
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const { user } = auth;
  const { sub, email } = user as User;

  const subscriptions = useAppSelector((state) => state.payments.subscriptions);
  const apiKeys = useAppSelector((state) => state.user.apiKeys);
  const createStatus = useAppSelector((state) => state.user.createKeyStatus);
  const keysStatus = useAppSelector((state) => state.user.keysStatus);
  const loading = keysStatus === fetchingStatus.loading;
  const createLoading = createStatus === fetchingStatus.loading;
  const currentKey = apiKeys[0]?.key;
  const currentId = apiKeys[0]?.id;
  const isOnboarding = currentPlan === PlanEnum.onBoarding;
  const currentSubscription: SubscriptionStatus | undefined =
    subscriptions[0]?.status === SubscriptionStatusValue.canceled && isOnboarding
      ? undefined
      : subscriptions[0];

  useEffect(() => {
    if (isAllowUseApiKeys && isWriteWorkspaceAllow) {
      dispatch(userActions.getKeysRequest());
    }
  }, [isAllowUseApiKeys, isWriteWorkspaceAllow]);

  const onClickApiDocs = () => {
    window.open(apiDocsUrl, "_blank");
  };

  const onGenerate = () => {
    openConfirmModal({
      title: intl.formatMessage(apiKeyMessages.generateModalTitle),
      content: intl.formatMessage(apiKeyMessages.generateModalDesc),
      onConfirm: () => {
        dispatch(userActions.createKeysRequest());
      },
      onCancel: closeConfirmModal
    });
  };

  const onCloseCancelModal = () => {
    setShowQuestions(false);
  };

  const onDelete = () => {
    openConfirmModal({
      title: intl.formatMessage(apiKeyMessages.deleteModalTitle),
      content: intl.formatMessage(apiKeyMessages.deleteModalDesc),
      onConfirm: () => {
        dispatch(userActions.deleteKeyRequest(currentId as string));
      },
      onCancel: closeConfirmModal
    });
  };

  const onCopyApiKey = () => {
    if (!currentKey) {
      return;
    }
    triggerCopy({ copyContent: currentKey, shouldNotify: true });
  };

  const onCopyUserId = () => {
    triggerCopy({ copyContent: sub, shouldNotify: true });
  };

  const deleteMyAccount = async () => {
    dispatch(
      analyticsEvents.deleteProfile({
        email: email as string,
        authId: sub as string,
        plan: currentPlan
      })
    );

    const { logout } = auth;
    try {
      await deleteProfile();
      logout();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteAccountPopup = () => {
    currentSubscription
      ? openConfirmModal({
          type: "danger",
          title: intl.formatMessage(apiKeyMessages.deleteModalWithSubscriptionTitle),
          content: intl.formatMessage(apiKeyMessages.deleteModalWithSubscriptionDesc),
          confirmText: intl.formatMessage(apiKeyMessages.deleteModalWithSubscriptionButton),
          onCancel: closeConfirmModal,
          onConfirm: () => {
            setShowQuestions(true);
          }
        })
      : openConfirmModal({
          type: "danger",
          title: intl.formatMessage(profileMessages.profileDeleteModalTitle),
          content: intl.formatMessage(profileMessages.profileDeleteModalContent),
          onConfirm: deleteMyAccount,
          onCancel: closeConfirmModal,
          confirmText: intl.formatMessage(profileMessages.profileDeleteModalOkButton)
        });
  };

  const onClickUpgrade = () => {
    upgradeToEnterprise({ source: `upgrade_api_key` });
  };

  return (
    <WhiteFlexColumn padding="20px 30px 30px" width="100%" flex="0 0 auto">
      <ConditionalRender condition={!!currentSubscription}>
        <CancelModal open={showQuestions} onClose={onCloseCancelModal} />
      </ConditionalRender>
      <H1_TextMiddle margin="0 0 3px 0" color={theme.gray8}>
        {intl.formatMessage(profileMessages.accountTitle)}
      </H1_TextMiddle>
      <Divider />
      <H1_FlexRow padding="3px 0">
        <StyledFlexColumn gap="8px">
          <H1_TextSmall color={theme.gray9} fontWeight={600}>
            {intl.formatMessage(profileMessages.userId)}
          </H1_TextSmall>
          <H1_FlexRow onClick={onCopyUserId} gap="10px" align="center">
            <H1_TextSmall color={theme.gray9}>{sub}</H1_TextSmall>
            <Tooltip title={intl.formatMessage(apiKeyMessages.copyUserId)}>
              <>
                <MaxButton
                  onClick={onCopyUserId}
                  isIconOnly
                  variant="light"
                  startContent={<i className="far fa-copy" />}
                />
              </>
            </Tooltip>
          </H1_FlexRow>
        </StyledFlexColumn>
      </H1_FlexRow>
      <ConditionalRender condition={!isAllowUseApiKeys}>
        <Divider />
        <H1_FlexRow justify="space-between" align="center" padding="10px 20px 0 0">
          <H1_FlexRow align="center" gap="20px">
            <CircleIconContainer width="32px" height="32px" position="relative">
              <CircleIcon className="fas fa-key" />
            </CircleIconContainer>
            <H1_FlexColumn gap="4px">
              <H1_TextSmall>{intl.formatMessage(apiKeyMessages.apiUpgradeTitle)}</H1_TextSmall>
              <H1_TextXs color={theme.gray8}>
                {
                  // @ts-ignore handels intl formatting with elements issue
                  intl.formatMessage(apiKeyMessages.apiUpgradeSubtitle, {
                    span: (str) => (
                      <StyledButton size="sm" variant="light" onClick={onClickApiDocs}>
                        {str}
                      </StyledButton>
                    )
                  })
                }
              </H1_TextXs>
            </H1_FlexColumn>
          </H1_FlexRow>
          <OppositeUpgradeButton
            onClick={onClickUpgrade}
            source="upgrade_api_key"
            text={intl.formatMessage(profileMessages.upgradeTextBtn)}
          />
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={isAllowUseApiKeys && isWriteWorkspaceAllow}>
        <Divider />
        <H1_TextSmall color={theme.gray9} fontWeight={600}>
          {intl.formatMessage(apiKeyMessages.title)}
        </H1_TextSmall>
        <ConditionalRender condition={loading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={!loading && apiKeys.length > 0}>
          <StyledFlexRow gap="10px" align="center" margin="0 0 10px 0">
            <H1_TextSmall color={theme.gray9} onClick={onCopyApiKey}>
              {currentKey ?? "******************************************"}
            </H1_TextSmall>
            <ConditionalRender condition={apiKeys.length > 0 && !!currentKey}>
              <Tooltip title={intl.formatMessage(apiKeyMessages.copyApiKey)}>
                <>
                  <MaxButton
                    isIconOnly
                    variant="light"
                    startContent={<i className="far fa-copy" />}
                    onClick={onCopyApiKey}
                  />
                </>
              </Tooltip>
            </ConditionalRender>
            <Tooltip title={intl.formatMessage(apiKeyMessages.revokeApiKey)}>
              <>
                <MaxButton
                  isIconOnly
                  variant="light"
                  startContent={<i className="far fa-trash" />}
                  onClick={onDelete}
                />
              </>
            </Tooltip>
            <Button onClick={onGenerate} isLoading={createLoading}>
              {intl.formatMessage(apiKeyMessages.regenerateButton)}
            </Button>
          </StyledFlexRow>
          <ConditionalRender condition={!!currentKey}>
            <H1_TextXs>{intl.formatMessage(apiKeyMessages.createAlert)}</H1_TextXs>
          </ConditionalRender>
        </ConditionalRender>
        <ConditionalRender condition={!loading && !apiKeys.length}>
          <H1_FlexRow margin="10px 0 0 0">
            <Button color="primary" onClick={onGenerate} isLoading={createLoading}>
              {intl.formatMessage(apiKeyMessages.generateButton)}
            </Button>
          </H1_FlexRow>
        </ConditionalRender>
      </ConditionalRender>
      <H1_FlexRow justify="flex-end" gap="15px" padding="44px 0 0 0">
        <Button
          variant={theme.mode === ThemeMode.Light ? "bordered" : "solid"}
          size="sm"
          onClick={() => navigate("/webhooks")}
        >
          webhooks
        </Button>
        <Button color="danger" size="sm" onClick={deleteAccountPopup}>
          {intl.formatMessage(profileMessages.profileDeleteButton)}
        </Button>
      </H1_FlexRow>
    </WhiteFlexColumn>
  );
};

export default ApiKeysCard;
