import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Skeleton } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

import styled from "styled-components";
import { FeatureFlag } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useFlags } from "launchdarkly-react-client-sdk";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";

const MinWidthFlexColumn = styled(H1_FlexColumn)`
  min-width: ${({ width }: { width?: string }) => width};
`;

export const DraftsTableSkeleton = () => {
  return (
    <H1_FlexColumn padding="30px 50px 0">
      <Skeleton loading active paragraph={{ rows: 0 }} title={{ width: "120px" }} />
      <br />
      <Skeleton loading active paragraph={{ rows: 0, width: 100 }} title={{ width: "100%" }} />
      <H1_FlexRow height="85px" flex="0 0 85px" gap="20px">
        <Skeleton.Button size="large" />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
      </H1_FlexRow>
      <H1_FlexRow height="85px" flex="0 0 85px" gap="20px">
        <Skeleton.Button size="large" />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
      </H1_FlexRow>
      <H1_FlexRow height="85px" flex="0 0 85px" gap="20px">
        <Skeleton.Button size="large" />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
        <Skeleton loading active paragraph={{ rows: 0 }} />
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

const HomePageSkeletonLoading = () => {
  const flags = useFlags();
  return (
    <>
      <H1_FlexColumn width="100%" flex="1 0 auto" padding="0 50px">
        <H1_FlexColumn padding="30px 0 0 0">
          <H1_FlexRow height="24px" align="flex-start">
            <Skeleton loading active paragraph={{ rows: 0 }} title={{ width: "190px" }} />
          </H1_FlexRow>
          <ConditionalRender condition={flags[FeatureFlag.socialMediaCollection]}>
            {/* Social media collections */}
            <H1_FlexRow gap="28px" width="100%" padding="21px 0 0 0">
              <MinWidthFlexColumn width="284px" height="207px">
                <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
              </MinWidthFlexColumn>
              <MinWidthFlexColumn width="284px" height="207px">
                <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
              </MinWidthFlexColumn>
              <MinWidthFlexColumn width="284px" height="207px">
                <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
              </MinWidthFlexColumn>
              <MinWidthFlexColumn width="284px" height="207px">
                <StyledSkeletonButton $height="207px" active block $borderRadius="5px" />
              </MinWidthFlexColumn>
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexColumn>
      </H1_FlexColumn>
      <DraftsTableSkeleton />
    </>
  );
};

export default HomePageSkeletonLoading;
