import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Video } from "app/types";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

// region [[ Styles ]]

const PreviewPanelView = styled.div``;

const VideoPreview = styled.div`
  width: 100%;
  background-color: transparent;
  aspect-ratio: 16/9;
  display: flex;
  & > * {
    flex: 1;
  }
`;

const VersionButton = styled(Button)`
  margin-top: 10px;
  color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.pink4 : props.theme.pink2};
  border: unset;
`;

// endregion [[ Styles ]]

// region [[ Props ]]

export interface PreviewPanelProps {
  videos?: Video[];
  selectedVideo?: Video;
  onChange?: (video: Video) => void;
}

// endregion [[ Props ]]

// region [[ Functions ]]
// endregion [[ Functions ]]

const PreviewPanel = ({ videos, selectedVideo, onChange }: PreviewPanelProps) => {
  const [menu, setMenu] = useState<MenuProps>();

  useEffect(() => {
    if (videos) {
      setMenu({
        items: videos.map((video) => {
          return {
            key: video.id,
            onClick: onChange && onChange(video),
            label: <H1_TextSmall>version {video?.draft_version}</H1_TextSmall>
          } as ItemType;
        })
      });
    }
  }, [videos]);

  return (
    <PreviewPanelView>
      <VideoPreview>
        <MuxPlayer reqId={selectedVideo?.pipeline_response?.req_id as string} />
      </VideoPreview>
      {selectedVideo && !!videos?.length && (
        <NextDropdown withWhiteBackground items={menu?.items || []}>
          <VersionButton variant="light">
            Version {selectedVideo?.draft_version}
            {!!videos?.length && <DownOutlined />}
          </VersionButton>
        </NextDropdown>
        // <Dropdown menu={menu}>
        //   <VersionButton>
        //     Version {selectedVideo?.draft_version}
        //     {!!videos?.length && <DownOutlined />}
        //   </VersionButton>
        // </Dropdown>
      )}
    </PreviewPanelView>
  );
};

export default PreviewPanel;
