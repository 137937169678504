import { H1_TextLargeHeadline } from "app/components/_Infrastructure/Typography";
import { homePageMessages } from "app/pages/HomePage/messages";
import React from "react";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { Location } from "app/types";
import { useIntl } from "react-intl";
import { useAppDispatch } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";

const SizeTextLargeHeadline = styled(H1_TextLargeHeadline)`
  line-height: 40px;
  @media (max-width: 1400px) {
    font-size: 28px;
  }

  @media (max-width: 1300px) {
    font-size: 24px;
  }
`;
const BorderedFlexColumn = styled(H1_FlexColumn)`
  background: linear-gradient(92deg, rgba(128, 110, 255, 1) 0.22%, rgba(255, 255, 255, 1) 160.21%);
  border-radius: 10px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    span {
      font-size: 16px;
    }
  }
`;
const ImageContainer = styled(H1_FlexRow)`
  right: 10px;
  bottom: 10px;
  @media (max-width: 1400px) {
    display: none;
  }
`;

const WorkflowButton = styled(Button)`
  margin-top: 13px;
  padding: 2px 15px;
  display: block;
  width: min-content;
  min-width: min-content;
  border-radius: 60px;
  & {
    height: 47px;
  }
`;

const HomePageWorkflowSection = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onWorkflowClick = () => {
    const SOURCE = "banner";
    dispatch(
      analyticsEvents.homepageCTA({
        source: SOURCE,
        actionDescription: "ExploreWorkflow"
      })
    );
    navigate(Location.Workflows);
  };

  return (
    <BorderedFlexColumn
      width="100%"
      height="100%"
      position="relative"
      justify="space-between"
      padding="30px 30px 40px"
      flex="1 1 auto"
    >
      <SizeTextLargeHeadline whiteSpace="break-spaces" fontWeight={500} color="white">
        {formatMessage(homePageMessages.largeHeadingWorkflow)}
      </SizeTextLargeHeadline>
      <WorkflowButton onClick={onWorkflowClick}>
        {formatMessage(homePageMessages.workflowButton)}
      </WorkflowButton>
      <ImageContainer position="absolute">
        <img
          width="158px"
          height="153px"
          src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/createWorkflowIcon.webp"
          alt="workflow"
        />
      </ImageContainer>
    </BorderedFlexColumn>
  );
};

export default HomePageWorkflowSection;
