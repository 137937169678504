import ConditionalRender from "app/components/common/ConditionalRender";
import { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import { useIntl } from "react-intl";
import { WebhooksPageMessages } from "app/pages/webhooks/messages";
import { useTheme } from "styled-components";

interface SecretButtonProps {
  value: string;
}

const SecretButton = ({ value }: SecretButtonProps) => {
  const [showSecret, setShowSecret] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const changeSecretStatus = useAppSelector((state) => state.webhooks.changeSecretStatus);
  const changeStatusLoading = changeSecretStatus === fetchingStatus.loading;

  useEffect(() => {
    if (changeSecretStatus === fetchingStatus.loading) {
      setShowSecret(false);
    }
    if (changeSecretStatus === fetchingStatus.succeeded) {
      setShowSecret(true);
    }
  }, [changeSecretStatus]);

  const onClick = () => {
    setShowSecret(true);
  };
  return (
    <>
      <ConditionalRender condition={showSecret}>
        <H1_TextSmall color={theme.gray8}>{value}</H1_TextSmall>
      </ConditionalRender>
      <ConditionalRender condition={!showSecret}>
        <Button isLoading={changeStatusLoading} onClick={onClick}>
          {formatMessage(WebhooksPageMessages.revealSecret)}
        </Button>
      </ConditionalRender>
    </>
  );
};

export default SecretButton;
