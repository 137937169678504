import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createMergePlaylist,
  createPlaylist,
  deletePlaylist,
  getPlaylistById,
  getPlaylists,
  updatePlaylist
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { Draft, Playlist, PlaylistVideo, ThunkApi } from "app/types";
import { videosActions } from "app/store/slices/videos.slice";
import { draftsActions } from "app/store/slices/drafts.slice";
import { scenesActions } from "app/store/slices/scenes.slice";

const prefix = "[Playlist]";
const getPlaylistsRequest = createAsyncThunk<Playlist[], void>(
  `${prefix} getPlaylistsRequest`,
  async () => {
    return getPlaylists();
  },
  thunkOptions
);

const getPlaylistByIdRequest = createAsyncThunk<Playlist, string, ThunkApi>(
  `${prefix} getPlaylistByIdRequest`,
  async (playlistId, thunkAPI) => {
    thunkAPI.dispatch(scenesActions.cleanScenes());
    const playlistData = await getPlaylistById(playlistId);
    if (playlistData.drafts.length > 0) {
      const draftsLastVideo = playlistData.drafts.map((draft: Draft) => draft.last_video);
      const playlistVideos = playlistData.playlist_videos.map(
        (playlistVideo: PlaylistVideo) => playlistVideo.video
      );
      thunkAPI.dispatch(videosActions.setVideos([...playlistVideos, ...draftsLastVideo]));
      thunkAPI.dispatch(draftsActions.setAllDrafts(playlistData.drafts));
    }
    return playlistData || [];
  },
  thunkOptions
);

const createPlaylistRequest = createAsyncThunk<
  Playlist,
  { draftsIds?: string[]; folderId?: string; title: string }
>(
  `${prefix} createPlaylistRequest`,
  async ({ draftsIds, folderId, title }) => {
    if (!folderId && !draftsIds) {
      return undefined;
    }
    return createPlaylist(title, folderId, draftsIds);
  },
  thunkOptions
);

const createPlaylistMergeRequest = createAsyncThunk<
  PlaylistVideo | undefined,
  { id: string },
  ThunkApi
>(
  `${prefix} createPlaylistMergeRequest`,
  async ({ id }, thunkAPI) => {
    if (!id) {
      return undefined;
    }
    const res: PlaylistVideo = await createMergePlaylist(id);
    if (res && res.video) {
      res.video.progress = 10;
      thunkAPI.dispatch(videosActions.addVideoToFirst(res.video));
    }
    return res;
  },
  thunkOptions
);

const updatePlaylistRequest = createAsyncThunk<
  any,
  { draftsIds: string[]; id: string; title: string }
>(
  `${prefix} updatePlaylistRequest`,
  async ({ draftsIds, id, title }) => {
    if (!id) {
      return undefined;
    }
    const res = await updatePlaylist(id, title, draftsIds);
    return res;
  },
  thunkOptions
);

const deletePlaylistRequest = createAsyncThunk<void, { id: string }>(
  `${prefix} deletePlaylistRequest`,
  async ({ id }) => {
    if (!id) {
      return undefined;
    }
    await deletePlaylist(id);
  },
  thunkOptions
);

export default {
  getPlaylistsRequest,
  getPlaylistByIdRequest,
  createPlaylistRequest,
  createPlaylistMergeRequest,
  updatePlaylistRequest,
  deletePlaylistRequest
};
