import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { draftsActions } from "app/store/slices/drafts.slice";
import SubmissionsTable from "app/pages/EditDraftPage/workflow/SubmissionsTable";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";

const Container = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: ${({ theme }) => theme.gray1};

  /* Modal shadow 10% */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  max-width: 710px;
  min-height: 640px;
  flex-shrink: 0;
  min-width: 1103px;
`;

const Submissions = () => {
  const dispatch = useAppDispatch();
  const workflowId = useAppSelector((state) => state.drafts.currentDraft.workflow_id);

  const workflowSubmissionStatus = useAppSelector((state) => state.drafts.workflowSubmissionStatus);

  const isLoading = workflowSubmissionStatus === fetchingStatus.loading;

  useEffect(() => {
    if (workflowId) {
      dispatch(draftsActions.getWorkflowSubmissionsRequest({ workflowId: workflowId as string }));
    }
  }, [workflowId]);
  return (
    <H1_FlexColumn gap={"10px"} margin={"0px 0px 10px 0px"}>
      <Container align={"center"} position={"relative"} padding={"48px 52px"}>
        <ConditionalRender condition={isLoading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={!isLoading}>
          <SubmissionsTable />
        </ConditionalRender>
      </Container>
    </H1_FlexColumn>
  );
};

export default Submissions;
