import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import {
  H1_TextMiddle,
  H1_TextMidHeadline,
  H1_TextSmall,
  H1_TextSubtitle,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { draftsActions } from "app/store/slices/drafts.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import ConditionalRender from "app/components/common/ConditionalRender";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { v4 as uuidv4 } from "uuid";
import AudioRecorder from "./AudioRecorder";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "app/types";
import SummaryConfig from "app/components/Summary/SummaryConfig";
import { Button, Divider, Input, Tab, Tabs } from "@nextui-org/react";
import { AnimatePresence, motion } from "framer-motion";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { StyledModal } from "app/components/common/StyledModal";

// todo need to move drafts state to be state of summary and pass it to table avoid race condition of home page

const RecordButton = styled(Button)`
  min-width: 155px;
  height: 155px;
  i {
    font-size: 21px;
    color: white;
  }
  span {
    font-size: 14px;
  }
`;

const MAX_TIME_IN_SEC = 60 * 60 * 1;
enum SUMMARY_STATUS {
  chooseOption = "Idle",
  sessionEnded = "Session ended, awaiting results",
  recording = "Recording",
  failed = "Failed",
  processingMeeting = "Started",
  meetingIsReady = "Done"
}
// todo one problem is we have missing draft video on the drafts from meeting so play cant be shown

enum VIEW {
  main = "main",
  settings = "settings"
}

const GOOGLE_MEET_URL = "https://meet.google.com/";
const GOOGLE_MEET_SHORT_URL = "meet.google.com/";

const SummaryModal = () => {
  const [view, setView] = useState<string>(VIEW.main);
  const [url, setUrl] = useState<string>("");
  const [status, setStatus] = useState<string>(SUMMARY_STATUS.chooseOption);
  const { editorModalOpen, closeModal } = useModal();
  const flags = useFlags();

  const meetingStatus = useAppSelector((state) => state.drafts.meetingStatus);
  const summaryId = useAppSelector((state) => state.drafts.summaryId);
  const summaryLeaveStatus = useAppSelector((state) => state.drafts.summaryLeaveStatus);
  const loadingMeetingTable = meetingStatus === fetchingStatus.loading;
  const endSessionLoading = summaryLeaveStatus === fetchingStatus.loading;
  const { reset, onSelectFile, file, fileStoreResult } = useFileUploadFilestack({
    autoUpload: true
  });

  const summaryStatus = useAppSelector((state) => state.drafts.summaryStatus);
  const draftIdResult = useAppSelector((state) => state.drafts.summaryDraftId);

  const visible = editorModalOpen?.open === ModalName.summaryModal;
  const summaryV2 = flags[FeatureFlag.summaryV2];
  const disabled = !summaryV2;
  const dispatch = useAppDispatch();
  const isViewSettings = view === VIEW.settings;
  const isProcessingMeeting = status === SUMMARY_STATUS.processingMeeting;
  const isUrlValid = url.startsWith(GOOGLE_MEET_URL) || url.startsWith(GOOGLE_MEET_SHORT_URL);

  useEffect(() => {
    if (visible && !disabled) {
      dispatch(draftsActions.getMeetingsDraftsRequest({}));
    }
    if (!visible) {
      dispatch(draftsActions.setSummaryToIdle());
      setStatus(SUMMARY_STATUS.chooseOption);
      setUrl("");
      reset();
      dispatch(draftsActions.setSummaryToIdle());
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (summaryLeaveStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setSummaryToIdle());
      setStatus(SUMMARY_STATUS.sessionEnded);
    } else if (summaryLeaveStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setSummaryToIdle());
    }
  }, [summaryLeaveStatus]);

  useEffect(() => {
    if (fileStoreResult && !disabled) {
      dispatch(
        draftsActions.summaryRequest({
          type: "audio_recorded",
          url: fileStoreResult.url,
          orderId: uuidv4()
        })
      );
    }
  }, [fileStoreResult?.url]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (summaryStatus === fetchingStatus.succeeded) {
      dispatch(
        draftsActions.getMeetingsDraftsRequest({
          draftId: draftIdResult
        })
      );
      setStatus(SUMMARY_STATUS.meetingIsReady);
      dispatch(draftsActions.setSummaryToIdle());
    } else if (summaryStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setSummaryToIdle());
      setStatus(SUMMARY_STATUS.failed);
    }
  }, [summaryStatus]);

  const onBeforeCloseModal = () => {
    dispatch(draftsActions.getDraftsRequest({ limit: 10, page: 1 }));
    closeModal();
  };

  const onRemoveBot = () => {
    dispatch(analyticsEvents.salesMeetRemoveBot());
    dispatch(draftsActions.summaryLeaveRequest({ id: summaryId as string }));
  };
  const onStopSession = () => {
    setStatus(SUMMARY_STATUS.sessionEnded);
  };

  const onStartRecordEvent = () => {
    // startRecord();
    setStatus(SUMMARY_STATUS.recording);
  };

  const onRecordFinish = (recordFile: File) => {
    onSelectFile(recordFile);
    setStatus(SUMMARY_STATUS.processingMeeting);
  };
  const cleanFile = () => {
    reset();
  };
  const onStartSession = () => {
    dispatch(draftsActions.summaryRequest({ type: "zoom_meeting", url, orderId: uuidv4() }));
    setStatus(SUMMARY_STATUS.processingMeeting);
    dispatch(analyticsEvents.salesMeetStartGoogleMeetSession());
  };

  const onConfiguration = () => {
    setView(isViewSettings ? VIEW.main : VIEW.settings);
  };
  const onFinishConfig = () => {
    setView(VIEW.main);
  };

  return (
    <StyledModal
      width={"858px"}
      closeIcon={<CloseModalIcon />}
      open={visible}
      onCancel={onBeforeCloseModal}
      footer={null}
      centered
      destroyOnClose
      $padding="40px"
      $flex="1"
    >
      <H1_FlexColumn>
        <H1_TextSubtitle fontWeight={500} margin="0 0 22px 0">
          SalesMeet
        </H1_TextSubtitle>
        <Tabs aria-label="Options">
          <Tab key="zoom" title="Summarize meeting">
            <Divider />
            <ConditionalRender condition={!isProcessingMeeting}>
              <H1_FlexRow justify="space-between" padding="40px 70px 0 40px">
                <H1_FlexColumn width="100%" padding="0 100px 0 0">
                  <H1_TextMiddle lineHeight="20px">Place meeting link</H1_TextMiddle>
                  <H1_TextSmall margin="0 0 13px 0" color="#D4D4D8">
                    Paste any Google Meet link
                  </H1_TextSmall>
                  <Input
                    placeholder={"https://meet.google.com/xxx-xxxx-xxx"}
                    onChange={(e) => setUrl(e.target.value)}
                    width="300px"
                    value={url}
                    endContent={
                      isUrlValid ? <H1_Icon icon="fas fa-circle-check" color="#17c964" /> : ""
                    }
                  />
                </H1_FlexColumn>
                <ConditionalRender condition={loadingMeetingTable}>
                  <H1_FlexRow flex="0 0 155px" width="155px" height="155px">
                    <CircleLoader size="155px" />
                  </H1_FlexRow>
                </ConditionalRender>
                <ConditionalRender condition={!loadingMeetingTable}>
                  <H1_FlexColumn gap="10px">
                    <RecordButton
                      color={disabled ? "success" : "primary"}
                      isDisabled={
                        disabled ||
                        status === SUMMARY_STATUS.processingMeeting ||
                        !url ||
                        !isUrlValid
                      }
                      onClick={onStartSession}
                      radius="lg"
                    >
                      <H1_FlexColumn gap="1px">
                        <i className="fa-solid fa-video" />
                        <H1_TextXs color="white">Join meeting</H1_TextXs>
                      </H1_FlexColumn>
                    </RecordButton>
                  </H1_FlexColumn>
                </ConditionalRender>
              </H1_FlexRow>
            </ConditionalRender>
            <ConditionalRender condition={isProcessingMeeting}>
              <H1_FlexColumn align="center" padding="46px 0 0 0">
                <H1_TextMiddle color="#17C964">Thank you!</H1_TextMiddle>
                <H1_TextMidHeadline fontWeight={500}>
                  SalesMeet will join your meeting soon
                </H1_TextMidHeadline>
                <H1_TextSubtitle margin="0 0 22px 0" color="#A1A1AA">
                  After meeting ends a summary video will be created for you in your library
                </H1_TextSubtitle>
                <H1_FlexRow gap="20px">
                  <Button variant="light" onClick={onRemoveBot} isDisabled={endSessionLoading}>
                    End recording
                  </Button>
                  <Button color="primary" onClick={onStopSession} isLoading={endSessionLoading}>
                    Create new bot
                  </Button>
                </H1_FlexRow>
              </H1_FlexColumn>
            </ConditionalRender>
          </Tab>
          <Tab key="audio" title="Summarize meeting audio">
            <ConditionalRender condition={!isProcessingMeeting}>
              <H1_FlexRow justify="space-between" padding="40px 70px 0 40px">
                <H1_FlexColumn>
                  <H1_TextMiddle lineHeight="20px">Record meeting</H1_TextMiddle>
                  <H1_TextSmall margin="0 0 13px 0" color="#D4D4D8" whiteSpace="normal">
                    Record your meeting and we will create a video summary for you
                  </H1_TextSmall>
                </H1_FlexColumn>
                <ConditionalRender condition={loadingMeetingTable}>
                  <H1_FlexRow flex="0 0 155px" width="155px" height="155px">
                    <CircleLoader size="155px" />
                  </H1_FlexRow>
                </ConditionalRender>
                <ConditionalRender condition={!loadingMeetingTable}>
                  <AudioRecorder
                    disabled={disabled || status === SUMMARY_STATUS.processingMeeting}
                    onFinish={onRecordFinish}
                    maxTime={MAX_TIME_IN_SEC}
                    file={file}
                    cleanFile={cleanFile}
                    onStartRecordEvent={onStartRecordEvent}
                    hidePlayer
                  />
                </ConditionalRender>
              </H1_FlexRow>
            </ConditionalRender>
            <ConditionalRender condition={isProcessingMeeting}>
              <H1_FlexColumn align="center" padding="46px 0 0 0">
                <H1_TextMiddle color="#17C964">Thank you!</H1_TextMiddle>
                <H1_TextMidHeadline>Your video is now being processed</H1_TextMidHeadline>
                <H1_TextSubtitle margin="0 0 22px 0" color="#A1A1AA">
                  In a few minutes, a summary video will be created for you in your library
                </H1_TextSubtitle>
                <Button color="primary" onClick={onStopSession} isLoading={endSessionLoading}>
                  Create new bot
                </Button>
              </H1_FlexColumn>
            </ConditionalRender>
          </Tab>
        </Tabs>
      </H1_FlexColumn>
      <H1_FlexRow height="46px" />
      <H1_FlexRow width="150px" padding={isViewSettings ? "0 0 10px" : "0"}>
        <Button
          fullWidth={false}
          variant="light"
          onClick={onConfiguration}
          endContent={
            <i className={isViewSettings ? "far fa-chevron-up" : "far fa-chevron-down"} />
          }
        >
          Settings
        </Button>
      </H1_FlexRow>
      <ConditionalRender condition={isViewSettings}>
        <Divider />
      </ConditionalRender>
      <AnimatePresence>
        {isViewSettings && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
          >
            <SummaryConfig onFinish={onFinishConfig} />
          </motion.div>
        )}
      </AnimatePresence>
    </StyledModal>
  );
};

export default SummaryModal;
