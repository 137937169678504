import { defineMessages } from "react-intl";

export const digitalTwinCreationMessages = defineMessages({
  avatarCreationStepIntroHeadline: {
    id: "create-avatar-form.steps.intro.headline",
    defaultMessage: "Create your digital twin in a few steps"
  },
  nextButtonLabel: {
    id: "create-avatar-form.steps.button.label",
    defaultMessage: "Next"
  },
  avatarCreationStepInstructionsHeadline: {
    id: "create-avatar-form.steps.instraction.headline",
    defaultMessage: "Instructions"
  },
  instructionsStepRecommendedHeadline: {
    id: "create-avatar-form.steps.instraction.recommended.headline",
    defaultMessage: "Recommended"
  },
  instructionsStepRecommendedFeature1: {
    id: "create-avatar-form.steps.instraction.recommended.feature1",
    defaultMessage: "Record in a well-lit environment"
  },
  instructionsStepRecommendedFeature2: {
    id: "create-avatar-form.steps.instraction.recommended.feature2",
    defaultMessage: "Look directly into the camera"
  },
  instructionsStepRecommendedFeature3: {
    id: "create-avatar-form.steps.instraction.recommended.feature3",
    defaultMessage: "Use high resolution camera"
  },
  instructionsStepRecommendedFeature4: {
    id: "create-avatar-form.steps.instraction.recommended.feature4",
    defaultMessage: "Keep hands below chest"
  },
  instructionsStepAvoidHeadline: {
    id: "create-avatar-form.steps.instraction.avoid.headline",
    defaultMessage: "Things to avoid"
  },
  instructionsStepAvoidFeature1: {
    id: "create-avatar-form.steps.instraction.avoid.feature1",
    defaultMessage: "Loud background noise"
  },
  instructionsStepAvoidFeature2: {
    id: "create-avatar-form.steps.instraction.avoid.feature2",
    defaultMessage: "Shadows or overexposure of face"
  },
  instructionsStepAvoidFeature3: {
    id: "create-avatar-form.steps.instraction.avoid.feature3",
    defaultMessage: "Hand gestures below chest"
  },
  instructionsStepAvoidFeature4: {
    id: "create-avatar-form.steps.instraction.avoid.feature4",
    defaultMessage: "Changing positions while recording"
  },
  startButtonLabel: {
    id: "create-avatar-form.steps.instraction.button.label",
    defaultMessage: "Let's start"
  },
  recordStepUseCameraButton: {
    id: "create-avatar-form.steps.record.use-camera.button.label",
    defaultMessage: "Use Camera"
  },
  recordStepSettingsHeadline: {
    id: "create-avatar-form.steps.record.use-camera.settings.headline",
    defaultMessage: "Recording Settings"
  },
  recordStepCTAButton: {
    id: "create-avatar-form.steps.record.cta-button.label",
    defaultMessage: "Start Recording"
  },
  recordStepPreviewButton: {
    id: "create-avatar-form.steps.record.preview.label",
    defaultMessage: "Preview"
  },
  recordStepReviewTitle: {
    id: "create-avatar-form.steps.record.review.title",
    defaultMessage: "Review your recording"
  },
  approveRecordButton: {
    id: "create-avatar-form.steps.record.review.approve.button.title",
    defaultMessage: "Approve recording"
  },
  declineRecordButton: {
    id: "create-avatar-form.steps.record.review.decline.button.title",
    defaultMessage: "Record again"
  },
  selectMicPlaceholder: {
    id: "create-avatar-form.steps.record.preview.select.mic.placeholder",
    defaultMessage: "Select microphone"
  },
  selectCamPlaceholder: {
    id: "create-avatar-form.steps.record.preview.select.camera.placeholder",
    defaultMessage: "Select camera"
  },
  askAllowingDevices: {
    id: "create-avatar-form.steps.record.preview.no-devices.text",
    defaultMessage: "Please allow browser to use microphone and camera"
  },
  faceOutlineOn: {
    id: "create-avatar-form.steps.record.preview.faceOutline.on",
    defaultMessage: "Face outline will help you to position yourself best for recording your avatar"
  },
  faceOutlineOff: {
    id: "create-avatar-form.steps.record.preview.faceOutline.off",
    defaultMessage:
      "Face outline will not appear, we trust you to position yourself at the middle of the screen"
  },
  recordStepInstractionOnRecord: {
    id: "create-avatar-form.steps.record.tag.on-screen-instractions",
    defaultMessage: "Look directly into the camera, {br} no need to speak"
  },
  avatarNamePlaceholder: {
    id: "create-avatar-form.character-settings.name.placeholder",
    defaultMessage: "Charlie chaplin"
  },
  avatarNameLabel: {
    id: "create-avatar-form.character-settings.name.label",
    defaultMessage: "Avatar name"
  },
  avatarGenderLabel: {
    id: "create-avatar-form.character-settings.gender.label",
    defaultMessage: "Voice gender"
  },
  advancedMenuTitle: {
    id: "create-avatar-form.character-settings.advanced.title",
    defaultMessage: "Advanced options"
  },
  addTransparentOn: {
    id: "create-avatar-form.character-settings.addTransparent.on",
    defaultMessage: "Transparent background on"
  },
  addTransparentOff: {
    id: "create-avatar-form.steps.upload.preview.addTransparent.off",
    defaultMessage: "Transparent background off"
  },
  addPongOn: {
    id: "create-avatar-form.character-settings.addPong.on",
    defaultMessage: "Use ping pong loop"
  },
  addPongOff: {
    id: "create-avatar-form.character-settings.addPong.off",
    defaultMessage: "No ping pong loop"
  }
});
