import React, { useEffect, useMemo } from "react";
import "app/components/projects-managment/projectsManagment.scss";
import { foldersActions } from "app/store/slices/folders.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import DeleteFolderModal from "app/pages/FoldersPage/DeleteFolderModal";
import useDrawer from "app/hooks/useDrawer";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Divider, TablePaginationConfig, Tooltip } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useIntl } from "react-intl";
import { folderTableMessages } from "app/pages/FoldersPage/messages";
import styled from "styled-components";
import useModal, { ModalName } from "app/hooks/useModal";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import HourOneBreadcrumb, { BreadcrumbElement } from "app/components/common/HourOneBreadcrumb";
import PageTableSkeleton from "app/components/common/DraftsAndFolders/PageTableSkeleton";
import PageGridSkeleton from "app/components/common/DraftsAndFolders/PageGridSkeleton";
import FoldersGrid from "app/components/common/DraftsAndFolders/FoldersGrid";
import FoldersTable from "app/components/common/DraftsAndFolders/FoldersTable";
import { userActions } from "app/store/slices/user.slice";
import usePermissions from "app/hooks/usePermissions";
import { playlistsActions } from "app/store/slices/playlists.slice";
import { Location } from "app/types";
import { playlistsGlobalSelectors } from "app/store/adapters/adapters";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
`;

const StyledHourOneBreadcrumb = styled(HourOneBreadcrumb)`
  && {
    padding-left: 0;
    font-size: 1rem;
  }
  &&&.ant-breadcrumb {
    a,
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme.gray7};
    }
    > :last-child a {
      color: ${(props) => props.theme.gray7};
    }
    .ant-breadcrumb-link {
      color: ${(props) => props.theme.gray9};
    }
    &&& .ant-breadcrumb-link:hover {
      a {
        color: ${(props) => props.theme.gray8};
      }
    }
  }
`;

const StyledButton = styled(Button)`
  i {
    color: ${({ theme }) => theme.gray11};
    font-size: 1rem;
  }
`;

const FolderButton = styled(Button)`
  font-size: 1rem;
  gap: 0.625rem;
  align-items: center;
  color: ${({ theme }) => theme.gray11};
  i {
    color: ${({ theme }) => theme.gray11};
    font-size: 1rem;
  }
`;

const FoldersPage = () => {
  const { closeDrawer } = useDrawer();
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const createPlaylistStatus = useAppSelector((state) => state.playlists.createPlaylistStatus);
  const playlists = useAppSelector(playlistsGlobalSelectors.selectAll);
  const preferences = useAppSelector((state) => state.user.preferences);
  const foldersStatus = useAppSelector((state) => state.folders.foldersStatus);
  const isLoading = foldersStatus === fetchingStatus.loading;
  const isGrid = preferences.folders_page_grid;
  const { isWriteWorkspaceAllow } = usePermissions();

  useEffect(() => {
    closeDrawer();
    dispatch(foldersActions.getRootFoldersRequest());
  }, []);

  useEffect(() => {
    if (createPlaylistStatus === fetchingStatus.succeeded) {
      dispatch(playlistsActions.updateCreatePlaylistStatusToIdle());
      navigate(`${Location.Playlists}/${playlists[0].id}`);
    }
    if (createPlaylistStatus === fetchingStatus.failed) {
      dispatch(playlistsActions.updateCreatePlaylistStatusToIdle());
    }
  }, [createPlaylistStatus]);

  const breadcrumbs: BreadcrumbElement[] = useMemo(() => {
    const res: BreadcrumbElement[] = [
      {
        id: "folders",
        name: formatMessage(folderTableMessages.projects)
      }
    ];
    return res;
  }, []);

  const onCreateFolder = () => {
    openModal(ModalName.createFolder);
  };

  const onClickTable = () => {
    // eslint-disable-next-line camelcase
    dispatch(userActions.updateUserPreferencesRequest({ folders_page_grid: false }));
  };

  const onClickGrid = () => {
    // eslint-disable-next-line camelcase
    dispatch(userActions.updateUserPreferencesRequest({ folders_page_grid: true }));
  };

  return (
    <BackgroundFlexColumn padding="1rem 0 1.875rem 1.875rem" flex="1">
      <H1_FlexColumn gap="2.125rem" flex="1">
        <H1_FlexRow
          height="1.375rem"
          flex="0 0 2.375rem"
          align="center"
          justify="space-between"
          gap="0.3125rem"
          padding="0 1.875rem 0 0"
        >
          <StyledHourOneBreadcrumb breadcrumbs={breadcrumbs} separator="/" />
          <H1_FlexRow align="center">
            <Tooltip title={formatMessage(folderTableMessages.table)}>
              <>
                <StyledButton
                  variant="light"
                  onClick={onClickTable}
                  startContent={<i className="fa-solid fa-list" />}
                  isIconOnly
                />
              </>
            </Tooltip>
            <Tooltip title={formatMessage(folderTableMessages.grid)}>
              <>
                <StyledButton
                  variant="light"
                  onClick={onClickGrid}
                  startContent={<i className="fa-solid fa-grid" />}
                  isIconOnly
                />
              </>
            </Tooltip>
            <Divider type="vertical" />
            <FolderButton
              data-auto-id="create-new-folder-button"
              isDisabled={!isWriteWorkspaceAllow}
              onClick={onCreateFolder}
              variant="light"
              startContent={<i className="fa-regular fa-folder-plus" />}
            >
              {formatMessage(folderTableMessages.createFolderLabel)}
            </FolderButton>
          </H1_FlexRow>
        </H1_FlexRow>
        <H1_FlexColumn overflow="auto" flex="1 1 auto" padding="0 1.875rem 0 0">
          <ConditionalRender condition={isLoading}>
            <ConditionalRender condition={isGrid}>
              <PageGridSkeleton />
            </ConditionalRender>
            <ConditionalRender condition={!isGrid}>
              <PageTableSkeleton />
            </ConditionalRender>
          </ConditionalRender>
          <ConditionalRender condition={!isLoading}>
            <ConditionalRender condition={!isGrid}>
              <FoldersTable
                onCreateFolder={onCreateFolder}
                pagination={
                  { position: ["bottomCenter"], size: "default" } as TablePaginationConfig
                }
              />
            </ConditionalRender>
            <ConditionalRender condition={isGrid}>
              <FoldersGrid onCreateFolder={onCreateFolder} />
            </ConditionalRender>
          </ConditionalRender>
        </H1_FlexColumn>
      </H1_FlexColumn>
      <DeleteFolderModal />
    </BackgroundFlexColumn>
  );
};
export default FoldersPage;
