/* eslint-disable  camelcase */

import { PlanEnum } from "app/types/userData";
import { UseCaseSegmentType } from "app/types/segment";
import { CharacterType } from "app/types/character";

export interface Recipe {
  aspect_ratio?: { width: string; height: string };
  available: boolean;
  description: string;
  id: string;
  image: string;
  title: string;
  video: string;
  video_full?: string;
  template_id: string;
  pro?: boolean;
  tags: string[];
  hidden_tags?: string[];
  plans: PlanEnum[];
  character_types?: CharacterType[];
  usage_segments?: UseCaseSegmentType[];
  default?: boolean;
}
export interface RecipeList {
  items: Recipe[];
  name: string;
  tags: string[];
  id?: string;
  recipeType?: RecipeType;
  usage_segments?: UseCaseSegmentType[];
}

export enum RecipeType {
  custom = "custom",
  personal = "personal"
}

export interface FlatRecipe extends Recipe {
  categoryName: string;
  recipeListId?: string;
  recipeType?: RecipeType;
  usage_segments?: UseCaseSegmentType[];
}

export enum Orientation {
  AnySize = "AnySize",
  Landscape = "Landscape",
  Portrait = "Portrait",
  Square = "Square"
}

export const MyTemplates = "My Templates";
