import { useDraggable } from "@dnd-kit/core";
import { ReactNode } from "react";
import { FolderType } from "app/types";
import styled from "styled-components";
interface DraggableProps {
  children: ReactNode;
  id: string;
  type: FolderType;
  title: string;
}

const Wrapper = styled.div<{ opacity: number; $height: string; $maxHeight: string }>`
  opacity: ${({ opacity }) => opacity};
  outline: none !important;
  height: ${({ $height }) => $height};
  max-height: ${({ $maxHeight }) => $maxHeight};
  width: auto;
`;

const Draggable = ({ children, id, type, title }: DraggableProps) => {
  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id,
    data: {
      type,
      title
    }
  });
  return (
    <Wrapper
      ref={setNodeRef}
      $maxHeight={isDragging ? "164px" : "none"}
      $height={isDragging ? "100%" : "auto"}
      opacity={isDragging ? 0.5 : 1}
      {...attributes}
      {...listeners}
    >
      {children}
    </Wrapper>
  );
};

export default Draggable;
