import { onOpenKnowledgeBase, onOpenZendesk } from "app/utils/zendesk/zendesk";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import IconButton from "app/components/common/Icons/IconButton";
import ConditionalRender from "app/components/common/ConditionalRender";
import messages from "app/utils/zendesk/messages";
import { ThemeMode } from "app/utils/theme";

const ZendeskFeedbackModal = React.lazy(() => import("app/utils/zendesk/ZendeskFeedbackModal"));

const ZendeskFlexColumn = styled(H1_FlexColumn)`
  right: 23px;
  bottom: 23px;
  z-index: -1; // Above drawers
  pointer-events: none;
  opacity: 0;
`;

const ButtonsFlexColumn = styled(H1_FlexColumn)`
  background-color: white;
  border: 1px solid ${(props) => props.theme.gray11};
  border-radius: 10px;
`;

const StyledCloseIcon = styled("div")`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
`;

const GapIconButton = styled(IconButton)`
  justify-content: flex-start;
  gap: 7px;
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === ThemeMode.Light ? props.theme.gray3 : props.theme.gray9};
  }
`;

const HelpIcon = styled(H1_FlexRow)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray11 : props.theme.gray3};
  outline: 1px solid
    ${(props) => (props.theme.mode === ThemeMode.Light ? props.theme.gray11 : props.theme.gray3)};
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    transform: scale(0.95);
  }
`;

const Icon = styled.i`
  font-size: 16px;
  color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray11};
`;

const ZendeskChoiceButtons = () => {
  const [feedbackVisible, setFeedbackVisible] = useState<boolean>(false);
  const [helpSelected, setHelpSelected] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (window.zE && typeof window.zE === "function") {
      window.zE("webWidget", "hide");
      window.zE("webWidget:on", "close", () => setHelpSelected(false));
    }
  }, []);

  const onClickSupport = () => {
    onOpenZendesk();
  };

  const onClickKnowledgeBase = () => {
    setHelpSelected(false);
    onOpenKnowledgeBase();
  };

  const onClickFeedback = () => {
    setHelpSelected(false);
    setFeedbackVisible(true);
  };

  return (
    <ZendeskFlexColumn position="fixed">
      <ConditionalRender condition={!helpSelected}>
        <HelpIcon justify="center" align="center" onClick={() => setHelpSelected(true)}>
          <Icon className="fa-regular fa-question" />
        </HelpIcon>
      </ConditionalRender>
      <ConditionalRender condition={helpSelected}>
        <ButtonsFlexColumn padding="20px" gap="10px" position="relative">
          <StyledCloseIcon onClick={() => setHelpSelected(false)}>
            <i className="far fa-xmark" />
          </StyledCloseIcon>

          <div style={{ display: "none" }}>
            <GapIconButton
              height="30px"
              icon={<i className="fas fa-comment" />}
              onClick={onClickFeedback}
            >
              {formatMessage(messages.feedback)}
            </GapIconButton>
          </div>
          <GapIconButton
            height="30px"
            icon={<i className="fas fa-book-open" />}
            onClick={onClickKnowledgeBase}
          >
            {formatMessage(messages.knowledgeBase)}
          </GapIconButton>
          <GapIconButton
            height="30px"
            icon={<i className="fal fa-circle-question" />}
            onClick={onClickSupport}
          >
            {formatMessage(messages.contactSupport)}
          </GapIconButton>
        </ButtonsFlexColumn>
      </ConditionalRender>
      <ZendeskFeedbackModal visible={feedbackVisible} onClose={() => setFeedbackVisible(false)} />
    </ZendeskFlexColumn>
  );
};

export default ZendeskChoiceButtons;
