import React, { useEffect, useState } from "react";
import Icon from "@ant-design/icons";
import styled from "styled-components";
import { Button, Divider } from "antd";
import SharePanel from "app/components/editor/sideDrawers/RenderPanel/SharePanel";
import EmbedPanel from "app/components/editor/sideDrawers/RenderPanel/EmbedPanel";
import { ReactComponent as DownloadIcon } from "app/assets/editor/downloadIcon.svg";
import { fetchingStatus } from "app/utils/helpers";
import usePermissions from "app/hooks/usePermissions";
import { Video, VideoStatusEnum } from "app/types";
import { videosActions } from "app/store/slices/videos.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Config from "app/config/Config";
import { ReactComponent as LinkIcon } from "app/assets/editor/linkIcon.svg";
import { useIntl } from "react-intl";
import { exportVideoDrawerMessages } from "app/components/editor/sideDrawers/messages";
import useCopyLink from "app/hooks/useCopyLink";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/upgrade-crown.svg";
import useEmbed from "app/hooks/useEmbed";
import useDownload from "app/hooks/useDownload";

const PanelDivider = styled(Divider)`
  border-color: #ececec;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const DesignedButton = styled(Button)`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  background-color: #4285f4;
  border-radius: 3px;
  color: white;
  width: 122px;
  align-self: flex-end;
  i {
    margin-right: 5px;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: #4285f4;
    color: white;
  }
`;

interface ExportContentProps {
  video?: Video;
}

const ExportContent = ({ video }: ExportContentProps) => {
  const [action, setAction] = useState<"embed" | "copyLink" | undefined>();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { isPublishAllow, isDownloadAllow, isOnboardingUser } = usePermissions();
  const { copyLink } = useCopyLink();
  const embedCode = useEmbed();
  const { openDownloadModal } = useDownload();
  const isPublishAllowStatus = [VideoStatusEnum.Ready, VideoStatusEnum.Published].includes(
    video?.status || VideoStatusEnum.NotStarted
  );

  const downloadVideoStatus = useAppSelector((state) => state.videos.downloadVideoStatus);
  const publishVideoStatus = useAppSelector((state) => state.videos.publishVideoStatus);

  const draftId: string | undefined = video?.draft_id;
  const reqId: string | undefined = video?.req_id;

  const isVideoPublished = video?.status === VideoStatusEnum.Published;
  const isPublishLoading = publishVideoStatus === fetchingStatus.loading;

  const openDownload = isDownloadAllow || video?.paid;

  useEffect(() => {
    if (publishVideoStatus === fetchingStatus.succeeded) {
      dispatch(videosActions.updatePublishStatusToIdle());
    } else if (downloadVideoStatus === fetchingStatus.failed) {
      dispatch(videosActions.updatePublishStatusToIdle());
    }
  }, [publishVideoStatus]);

  const onDownload = () => {
    openDownloadModal({ source: "export_drawer_download", video });
  };
  const onEmbed = () => {
    setAction("embed");
    embedCode("export_drawer_embed", video as Video);
  };

  const onCopyLink = () => {
    setAction("copyLink");
    copyLink("export_drawer_copy_link", video as Video, draftId as string);
  };

  return (
    <>
      {isPublishAllow && isVideoPublished && isPublishAllowStatus && (
        <SharePanel
          draftId={draftId}
          reqId={reqId}
          url={`${Config.ogPlayerUrl}/${video?.pipeline_response?.req_id}`}
        />
      )}
      {isPublishAllow && !isVideoPublished && (
        <DesignedButton
          className="secondary-btn"
          loading={action === "copyLink" && isPublishLoading}
          icon={
            <Icon>
              <LinkIcon />
            </Icon>
          }
          onClick={onCopyLink}
        >
          Copy Link
        </DesignedButton>
      )}
      <PanelDivider />
      {isPublishAllow && !isVideoPublished && (
        <DesignedButton
          icon={
            isOnboardingUser ? (
              <Icon>
                <UpgradeIcon />
              </Icon>
            ) : (
              <i className="fas fa-code-simple" />
            )
          }
          onClick={onEmbed}
          loading={action === "embed" && isPublishLoading}
        >
          {formatMessage(exportVideoDrawerMessages.exportEmbedButton)}
        </DesignedButton>
      )}

      {isPublishAllow && isVideoPublished && (
        <EmbedPanel
          url={`${Config.frontoHost}/e?id=${video?.pipeline_response?.req_id}`}
          title={formatMessage(exportVideoDrawerMessages.exportEmbedButton)}
          videoId={video?.id as string}
        />
      )}
      <PanelDivider />

      <Actions>
        <div>{formatMessage(exportVideoDrawerMessages.exportDownloadTitle)}</div>
        <DesignedButton
          icon={<Icon>{openDownload ? <DownloadIcon /> : <UpgradeIcon />}</Icon>}
          onClick={onDownload}
          loading={downloadVideoStatus === fetchingStatus.loading}
        >
          {formatMessage(exportVideoDrawerMessages.exportDownloadButton)}
        </DesignedButton>
      </Actions>
    </>
  );
};
ExportContent.defaultProps = {
  video: undefined
};
export default ExportContent;
