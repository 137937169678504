import { Draft, VideoStatusEnum } from "app/types";
import styled from "styled-components";
import React, { useMemo } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useNavigate } from "react-router-dom";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import DropdownProjectMetaEdit from "app/components/common/DropdownProjectMetaEdit";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { homePageMessages } from "app/pages/HomePage/messages";
import { useIntl } from "react-intl";
import ConditionalRender from "app/components/common/ConditionalRender";
import GridItem from "app/components/common/GridItem";
import { getTimeAgo, onImageError } from "app/utils/helpers";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;
const ButtonsFlexRow = styled(H1_FlexRow)`
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;
const PositionButton = styled(Button)`
  min-width: 20px;
  background-color: rgba(202, 202, 202, 0.6);
  color: white;
  span {
    color: white;
  }
  border: none;
  &:hover,
  &:active {
    border: none;
    color: white;
    span {
      color: white;
    }
    background-color: rgba(202, 202, 202, 1);
    &&& i {
      color: white;
      fill: white;
    }
  }
`;

const StyledIcon = styled.i<{ $color?: string }>`
  font-size: 1.125rem;
  height: 2rem;
  line-height: 2rem;
  margin: auto;
  padding: 0 3px;
  color: ${({ theme, $color }) => ($color ? $color : theme.gray11)};
  &:hover {
    color: ${({ theme, $color }) =>
      $color ? $color : theme.mode === ThemeMode.Light ? theme.blue4 : theme.gray6};
  }
`;

const StyledGridItem = styled(GridItem)`
  border-radius: 5px;
  &:hover {
    ${ButtonsFlexRow} {
      z-index: 2;
      opacity: 1;
    }
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.4);
    img {
      filter: brightness(70%);
    }
  }
  &:active {
    box-shadow: none;
  }
`;

interface DraftGridItemProps {
  draftId: string;
  source: string;
  hideMoveFolder?: boolean;
}

const DraftGridItem = ({ draftId, source, hideMoveFolder }: DraftGridItemProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const draft = useAppSelector((state) => draftsGlobalSelectors.selectById(state, draftId));
  const updatedAt = useMemo(() => getTimeAgo(draft?.updated_at as string), [draft]);

  const isVideoReady =
    draft?.last_video?.status === VideoStatusEnum.Published ||
    draft?.last_video?.status === VideoStatusEnum.Ready;

  const onSelectDraft = () => {
    dispatch(analyticsEvents.editDraft({ source, draftId }));
    dispatch(analyticsEvents.navigateToEditor({ source: "draft_grid_item" }));
    navigate(`/editor/${draftId}`);
  };

  const onPlayVideo = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(analyticsEvents.videoPlay({ source, videoId: draft?.last_video?.id }));
    openModal(ModalName.videoReady, { reqId: draft?.last_video?.req_id });
  };

  return (
    <StyledGridItem
      title={draft?.title as string}
      subTitle={updatedAt}
      dropdown={
        <DropdownProjectMetaEdit
          draft={draft as Draft}
          onRenameDraft={() => {
            openModal(ModalName.renameDraft, { draftId });
          }}
          showCopyLink={isVideoReady}
          showDownload={isVideoReady}
          showEmbed={isVideoReady}
          onOpenDraft={onSelectDraft}
          icon={<StyledIcon className="fa-solid fa-ellipsis-vertical draft-actions-menu" />}
          withMoveToFolder={!hideMoveFolder}
        />
      }
      onClick={onSelectDraft}
    >
      <>
        <StyledImage
          height="calc(12.3125rem * 9 / 16)"
          width="12.3125rem"
          src={draft?.image || fallbackPlaceholder}
          onError={onImageError}
        />
        <ButtonsFlexRow
          position="relative"
          height="100%"
          align="center"
          justify="center"
          gap="0.625rem"
        >
          <PositionButton
            onClick={onSelectDraft}
            startContent={<StyledIcon $color="white" className="fa-solid fa-scissors" />}
          >
            {formatMessage(homePageMessages.edit)}
          </PositionButton>
          <ConditionalRender condition={isVideoReady}>
            <PositionButton
              isIconOnly
              onClick={onPlayVideo}
              startContent={<StyledIcon $color="white" className="fa-solid fa-play" />}
            />
          </ConditionalRender>
        </ButtonsFlexRow>
      </>
    </StyledGridItem>
  );
};

export default DraftGridItem;
