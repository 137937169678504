import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { DevModeSize, PlanEnum, TemplateAsset } from "app/types";
import { messages } from "app/components/editor/sideDrawers/messages";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import { paymentModalMessages } from "app/pages/pricing/messages";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useDispatch } from "react-redux";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import useModal, { ModalName } from "app/hooks/useModal";
import { AssetKeyType, MediaType } from "app/types/media";
import { PlanFeature } from "app/config/planFeature";
import MediaChooseBox from "app/components/MediaChooseBox";
import DevModePopover from "app/components/DevModePopover";

interface LogoElementProps {
  asset: TemplateAsset;
  onDraftAttributeChange: (key: string, value?: any) => void;
  attributes: any;
}

const LogoElement = ({ onDraftAttributeChange, attributes, asset }: LogoElementProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const showPaymentModal = useUpgradeModal();
  const { scene, sceneId, selectedSceneIndex } = useSelectedScene();
  const sceneName = scene?.name as string;

  const originalDisplayUrls = useMemo(() => {
    const mediaUrl = asset && attributes[asset.type]?.[asset.key]?.url;
    return mediaUrl ? [mediaUrl] : [];
  }, [asset, asset && attributes[asset.type]?.[asset.key]?.url]);
  const { displayUrls } = useDisplayUrls(originalDisplayUrls);
  const url = asset && attributes[asset.type]?.[asset.key]?.url;
  const previewUrl = url && displayUrls[url] ? displayUrls[url] : undefined;

  const showCrown = useShowCrown({
    requestedFeature: PlanFeature.LogoDraft
  });

  const isRequired = asset.restrictions.required;
  const isUpgradeRequired = () => {
    const upgradeText = intl.formatMessage(paymentModalMessages.upgradeLogoTitle);
    const result = showPaymentModal({
      source: "upgrade_logo_drawer",
      upgradeText,
      targetPlan: PlanEnum.business,
      requestedFeature: PlanFeature.LogoDraft
    });
    return result;
  };
  const onImageClick = () => {
    dispatch(
      analyticsEvents.changeLogoIntent({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        }
      })
    );
    if (isUpgradeRequired()) {
      return;
    }
    dispatch(
      analyticsEvents.changeLogoOpenModal({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        }
      })
    );
    openModal(ModalName.mediaLibraryModal, {
      assetKey: asset.key,
      assetKeyType: AssetKeyType.draft,
      mediaType: MediaType.image,
      hideStockVideos: true
    });
  };

  const removeMedia = () => {
    dispatch(
      analyticsEvents.removeLogo({
        selectedScene: {
          name: sceneName,
          id: sceneId,
          index: selectedSceneIndex
        }
      })
    );
    onDraftAttributeChange(`attributes.media.${asset.key}`);
  };

  return (
    <>
      <DevModePopover
        size={DevModeSize.large}
        right={"unset"}
        left={"24px"}
        value={{ id: asset.key, url: url || "{{URL}}" }}
        path={"global_media_elements[#]"}
      />
      <MediaChooseBox
        onClick={onImageClick}
        showCrown={showCrown}
        upgradeButtonText={intl.formatMessage(messages.addYourLogo)}
        replaceToolTip={intl.formatMessage(messages.replaceLogoTooltip)}
        removeToolTip={intl.formatMessage(messages.removeLogoTooltip)}
        previewUrl={previewUrl}
        removeMedia={isRequired ? undefined : removeMedia}
        insertText={intl.formatMessage(messages.logoUploadText)}
        source={"logo"}
        icon={<i className="fa-regular fa-cloud-arrow-up"></i>}
      />
    </>
  );
};

export default LogoElement;
