import { ReactionMapping } from "app/types";
import { emojiMessages } from "app/components/common/Reactions/messages";

export const emojisMappedData: ReactionMapping[] = [
  {
    emojiText: ":thumbs_up:",
    emojiSign: "👍",
    emojiLabel: emojiMessages.LikeEmojiLabel,
    emojiTooltip: emojiMessages.LikeEmojiTooltip
  },
  {
    emojiText: ":face_with_tears_of_joy:",
    emojiSign: "😂",
    emojiLabel: emojiMessages.FunnyEmojiLabel,
    emojiTooltip: emojiMessages.FunnyEmojiTooltip
  },
  {
    emojiText: ":clapping_hands:",
    emojiSign: "👏",
    emojiLabel: emojiMessages.CelebrateEmojiLabel,
    emojiTooltip: emojiMessages.CelebrateEmojiTooltip
  },
  {
    emojiText: ":yellow_heart:",
    emojiSign: "💛",
    emojiLabel: emojiMessages.LoveEmojiLabel,
    emojiTooltip: emojiMessages.LoveEmojiTooltip
  },
  {
    emojiText: ":light_bulb:",
    emojiSign: "💡",
    emojiLabel: emojiMessages.InsightfulEmojiLabel,
    emojiTooltip: emojiMessages.InsightfulEmojiTooltip
  }
];
