import { createContext } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";

export type SegmentTrackProps = {
  name: string;
  properties?: any;
};

export type SegmentIdentifyProps = {
  userId: string;
  permissions: string;
  userEmail: string;
  data: any;
};

export type PrependSend = (
  eventName: string,
  eventProps?: SegmentTrackProps | SegmentIdentifyProps
) => void;

export const SegmentContextContext = createContext<{
  analytics: AnalyticsBrowser;
  prependSend?: PrependSend;
}>(undefined!);
