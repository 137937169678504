import Cursor from "app/components/editor/scene/Cursor";
import { avatarColorsList } from "app/types";
import React, { useMemo } from "react";
import { useOthersMapped } from "app/services/liveBlocksClient";
import { drawerWidth, drawerWidthOffset } from "app/pages/editor/RightNavAttributesList";
import { useTheme } from "styled-components";

const MAX_SCENE_WIDTH = 850;
const HEADER_HEIGHT = 57;
const EditingPageDraftContentLiveBlocksOthers = ({
  fullScreenWidth,
  scrollTop
}: {
  fullScreenWidth: number;
  scrollTop: number;
}) => {
  const theme = useTheme();
  const others = useOthersMapped((other) => ({
    cursor: other.presence.cursor,
    scrollTop: other.presence.scrollTop,
    dimensions: other.presence.dimensions,
    email: other.presence.email
  }));
  const drawerFullWidth = useMemo(
    () => parseInt(drawerWidthOffset, 10) + parseInt(drawerWidth, 10),
    []
  );

  return (
    <>
      {others.map(([connectionId, presence], index: number) => {
        if (presence.cursor === undefined) {
          return null;
        }
        // Other user cursor x position
        const presenceCursorX = presence.cursor?.x;
        // Other user screen x dimension
        const presenceDimensionX = presence.dimensions?.width || 1;
        // Weather or not the other user is on the drawer
        const onPresenceDrawer = presenceDimensionX - drawerFullWidth;
        // Max screen width
        const sceneWidth = MAX_SCENE_WIDTH;
        // Current user space between scene to edge - left and right are the same
        const spaceBetweenSceneToEdge = (fullScreenWidth - drawerFullWidth - sceneWidth) / 2;
        // Other user space between scene to edge - left and right are the same
        const presenceSpaceBetweenSceneToEdge = (onPresenceDrawer - sceneWidth) / 2;
        // Other user cursor is on the left side of the scene - not on the drawer
        const isOnSceneSpace = presenceCursorX < onPresenceDrawer;
        // Other user cursor is on the left edge - left to the scene
        const isOnLeftSceneSpace =
          isOnSceneSpace && presenceCursorX < presenceSpaceBetweenSceneToEdge;
        // Other user cursor is on the right edge - right to the scene and before the drawer
        const isOnRightSceneSpace =
          isOnSceneSpace && presenceCursorX > presenceSpaceBetweenSceneToEdge + sceneWidth;

        let currentXCursor: number;
        // Check if the other user is on the drawer
        if (presenceCursorX > onPresenceDrawer) {
          // Add to the cursor x position the user's left side of the scene minus the other user's left side of the scene, also the right side so its twice
          currentXCursor =
            spaceBetweenSceneToEdge -
            presenceSpaceBetweenSceneToEdge +
            spaceBetweenSceneToEdge -
            presenceSpaceBetweenSceneToEdge +
            presenceCursorX;
          // Check if the other user is on the left side of the scene
        } else if (isOnLeftSceneSpace) {
          // New cursor x position is the relative between the other user's left side of the scene and the current user's left side of the scene'
          currentXCursor =
            (spaceBetweenSceneToEdge / presenceSpaceBetweenSceneToEdge) * presenceCursorX;
          // Check if the other user is on the right side of the scene
        } else if (isOnRightSceneSpace) {
          // New cursor x position is the relative between the other user's right side of the scene and the current user's right side of the scene',
          // and the left side of the scene and the scene width
          currentXCursor =
            spaceBetweenSceneToEdge +
            sceneWidth +
            (spaceBetweenSceneToEdge / presenceSpaceBetweenSceneToEdge) *
              (presenceCursorX - sceneWidth - presenceSpaceBetweenSceneToEdge);
          // The other user is on the scene
        } else {
          // New cursor x position is the user's left side of the scene minus the other user's left side of the scene
          currentXCursor =
            presenceCursorX + spaceBetweenSceneToEdge - presenceSpaceBetweenSceneToEdge;
        }

        const normalizedX = currentXCursor;
        // Cursor y position is the other user's scrollTop minus the current user's scrollTop, minus the header height
        const normalizedY =
          presence.cursor?.y + presence.scrollTop - HEADER_HEIGHT - (scrollTop || 0);

        return (
          <Cursor
            title={presence.email as string}
            key={`cursor-${connectionId}`}
            color={avatarColorsList(theme)[(index + 1) % avatarColorsList(theme).length]}
            x={normalizedX}
            y={normalizedY}
          />
        );
      })}
    </>
  );
};
export default EditingPageDraftContentLiveBlocksOthers;
