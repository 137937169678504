import { transcriptAudioUploaderMessages } from "app/components/editor/scene/transcriptAudioUploader/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import UploadFileContent from "app/components/common/UploadFileContent";
import ButtonBox from "app/components/common/ButtonBox";
import AudioRecorder from "app/components/editor/scene/transcriptAudioUploader/AudioRecorder";
import FilePlayer from "app/components/common/FilePlayer";
import { useIntl } from "react-intl";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import styled, { useTheme } from "styled-components";
import { ReactNode } from "react";
import useAudioPlayer from "app/hooks/useAudioPlayer";
import { MimeType } from "app/types";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMidHeadline, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";

export enum VIEW_MODE {
  main,
  voiceRecording,
  finish
}
const FILE_TYPES = [MimeType.wav, MimeType.mp4, MimeType.mp3];

const OrderFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const ButtonsWrapper = styled(H1_FlexRow)`
  align-self: end;
`;

const CancelButton = styled(Button)`
  & {
    width: 125px;
    height: 40px;
  }
`;

interface TranscriptAudioModalContentProps {
  //     todo
  file?: File;
  maxTimeInSec: number;
  viewMode: VIEW_MODE;
  importLoading: boolean;
  onChooseFile: (file: File) => void;
  cleanFile: () => void;
  onSelectFile: (file: File) => void;
  onStopRecordEvent?: () => void;
  onStartRecordEvent?: () => void;
  executeImport: () => void;
  onRecordSelect: () => void;
  onCancel: () => void;
}
const TranscriptAudioModalContent = ({
  file,
  maxTimeInSec,
  viewMode,
  importLoading,
  onChooseFile,
  cleanFile,
  onSelectFile,
  onStopRecordEvent,
  onStartRecordEvent,
  executeImport,
  onRecordSelect,
  onCancel
}: TranscriptAudioModalContentProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const { totalTime, playAudio, stopAudio, isPlaying } = useAudioPlayer(file);
  const isRecordValid = totalTime ? totalTime <= maxTimeInSec : true;

  return (
    <>
      <H1_FlexColumn justify="space-between">
        <H1_TextMidHeadline textAlign="center" margin="0 0 20px 0">
          {intl.formatMessage(transcriptAudioUploaderMessages.modalHeader)}
        </H1_TextMidHeadline>
      </H1_FlexColumn>
      <ConditionalRender condition={viewMode === VIEW_MODE.main}>
        <OrderFlexRow gap="5px">
          <UploadFileContent
            disabled={importLoading}
            fileTypes={FILE_TYPES}
            onSelectFile={(files) => onChooseFile(files[0])}
          >
            <ButtonBox
              icon={<i className="fa-solid fa-file-music" />}
              title={
                intl.formatMessage(transcriptAudioUploaderMessages.uploadFileButtonTitle, {
                  br: <br />
                }) as ReactNode
              }
              description={intl.formatMessage(
                transcriptAudioUploaderMessages.uploadFileButtonDescription
              )}
            />
          </UploadFileContent>
          <ButtonBox
            icon={<i className="fa-solid fa-microphone" />}
            title={intl.formatMessage(transcriptAudioUploaderMessages.recordButtonTitle)}
            description={intl.formatMessage(
              transcriptAudioUploaderMessages.recordButtonDescription,
              { maxTime: maxTimeInSec }
            )}
            onClick={() => onRecordSelect()}
          />
        </OrderFlexRow>
      </ConditionalRender>
      <ConditionalRender condition={viewMode === VIEW_MODE.voiceRecording}>
        <AudioRecorder
          file={file}
          cleanFile={cleanFile}
          onFinish={onSelectFile}
          onStartRecordEvent={onStartRecordEvent}
          onStopRecordEvent={onStopRecordEvent}
          maxTime={maxTimeInSec}
          description={intl.formatMessage(transcriptAudioUploaderMessages.recordButtonDescription, {
            maxTime: maxTimeInSec
          })}
        />
      </ConditionalRender>

      <ConditionalRender condition={!isRecordValid}>
        <H1_TextXs whiteSpace="break-spaces" color={theme.pink4}>
          {intl.formatMessage(transcriptAudioUploaderMessages.lengthValidationError, {
            maxTimeInSec
          })}
        </H1_TextXs>
      </ConditionalRender>

      <ConditionalRender condition={viewMode === VIEW_MODE.finish}>
        <FilePlayer
          fileName={file?.name}
          fileSize={file?.size}
          playing={isPlaying}
          onPlay={playAudio}
          onStop={stopAudio}
        />
      </ConditionalRender>
      <ButtonsWrapper margin="20px 0 0 0" gap="10px" justify="space-between" align="center">
        <CancelButton key="cancel" variant="light" isDisabled={importLoading} onClick={onCancel}>
          {intl.formatMessage(transcriptAudioUploaderMessages.modalButtonCancel)}
        </CancelButton>
        <Button
          color="primary"
          onClick={executeImport}
          isDisabled={!isRecordValid || !file}
          isLoading={importLoading}
        >
          {intl.formatMessage(transcriptAudioUploaderMessages.modalButtonImport)}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default TranscriptAudioModalContent;
