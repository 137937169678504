import { useAppSelector } from "app/hooks";
import { PlanEnum, SubscriptionPlanEnum } from "app/types";
import { useIntl } from "react-intl";
import { pricingPageMessages } from "app/pages/pricing/messages";
import {
  currentWorkspacePlan,
  currentWorkspaceSubscriptionPlan
} from "app/store/selectorsV2/workspaces.selectors";

const useCurrentPlan = () => {
  const { formatMessage } = useIntl();
  const currentPlan = useAppSelector((state) => currentWorkspacePlan(state));
  const currentSubscriptionPlan: SubscriptionPlanEnum = useAppSelector((state) =>
    currentWorkspaceSubscriptionPlan(state)
  );

  const getCurrentPlanText = (plan: PlanEnum): string => {
    let translationKey: { id: string; defaultMessage: string };
    switch (plan?.toLowerCase()) {
      case PlanEnum.onBoarding:
        translationKey = pricingPageMessages.onBoardingTitle;
        break;
      case PlanEnum.business:
        translationKey = pricingPageMessages.businessTitle;
        break;
      case PlanEnum.personal:
        translationKey = pricingPageMessages.personalTitle;
        break;
      case PlanEnum.basic:
        translationKey = pricingPageMessages.personalTitle;
        break;
      case PlanEnum.pro:
        translationKey = pricingPageMessages.proTitle;
        break;
      case PlanEnum.enterprise:
        translationKey = pricingPageMessages.enterpriseTitle;
        break;
      default:
        translationKey = pricingPageMessages.onBoardingTitle;
    }
    return formatMessage(translationKey);
  };

  const getCurrentPlanPeriodText = (plan: SubscriptionPlanEnum): string => {
    const translationKey = plan?.toLowerCase().includes("month")
      ? pricingPageMessages.monthlyTitle
      : pricingPageMessages.yearlyTitle;
    return formatMessage(translationKey);
  };
  const getCurrentSubscriptionPlanText = (plan: SubscriptionPlanEnum): string => {
    let translationKey: { id: string; defaultMessage: string };
    switch (plan?.toLowerCase()) {
      case SubscriptionPlanEnum.on_boarding:
        translationKey = pricingPageMessages.onBoardingTitle;
        break;
      case SubscriptionPlanEnum.personal_monthly:
        translationKey = pricingPageMessages.personalMonthlyTitle;
        break;
      case SubscriptionPlanEnum.personal_yearly:
        translationKey = pricingPageMessages.personalYearlyTitle;
        break;
      case SubscriptionPlanEnum.business_monthly:
        translationKey = pricingPageMessages.businessMonthlyTitle;
        break;
      case SubscriptionPlanEnum.business_yearly:
        translationKey = pricingPageMessages.businessYearlyTitle;
        break;
      case SubscriptionPlanEnum.enterprise_monthly:
      case SubscriptionPlanEnum.enterprise_yearly:
        translationKey = pricingPageMessages.enterpriseTitle;
        break;
      default:
        translationKey = pricingPageMessages.onBoardingTitle;
    }
    return formatMessage(translationKey);
  };

  const currentPlanPeriodTimeText = getCurrentPlanPeriodText(currentSubscriptionPlan);
  const currentPlanText = getCurrentPlanText(currentPlan);
  const currentSubscriptionPlanText = getCurrentSubscriptionPlanText(currentSubscriptionPlan);

  return {
    currentPlan,
    currentSubscriptionPlan,
    currentPlanText,
    getCurrentPlanText,
    currentSubscriptionPlanText,
    currentPlanPeriodTimeText
  };
};

export default useCurrentPlan;
