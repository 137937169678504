import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled from "styled-components";
import { useAppSelector } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const Container = styled(H1_FlexRow)<{ background?: string }>`
  border-radius: 50px;
  background: ${({ background }) => background || ""};
`;

const StyledContainer = styled(H1_FlexColumn)`
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
`;

const RoundIcon = styled.div<{ background?: string }>`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: ${({ background }) => background || ""};
`;
const WorkFlowModeTag = () => {
  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);
  return (
    <StyledContainer align={"center"}>
      <Container
        padding={"0 20px"}
        gap={"15px"}
        background={workflowLiveMode ? "#E3FCE6" : "#D9D9D9"}
        height={"42px"}
        align={"center"}
        justify={"center"}
      >
        <RoundIcon background={workflowLiveMode ? "#37FC81" : "#595959"} />
        <H1_TextSmall color={workflowLiveMode ? "#2C285C" : "#595959"}>
          {workflowLiveMode ? "Published" : "Draft"}
        </H1_TextSmall>
      </Container>
    </StyledContainer>
  );
};

export default WorkFlowModeTag;
