import { Upload } from "antd";
import styled from "@emotion/styled";
import {
  presentationFileTypeErrors,
  presentationModalUploaderMessages
} from "app/components/editor/presentations/messages";
import { useIntl } from "react-intl";
import ConditionalRender from "app/components/common/ConditionalRender";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";
import { useRef } from "react";

const { Dragger } = Upload;

const UploadDragWrapper = styled.div<{ $background: string }>`
  display: flex;
  width: 100%;
  .ant-upload.ant-upload-drag {
    background: ${(props) => props.$background};
    border-radius: 9px;
  }
  & > span {
    height: 276px;
    width: 100%;
  }
  border-radius: 10px;
`;

export interface UploadPresentationContentProps {
  onSelectFile: (file: File) => void;
  fileTypes: string;
  // eslint-disable-next-line react/require-default-props
  file?: File;
}

const UploadPresentationContent = ({
  file,
  onSelectFile,
  fileTypes
}: UploadPresentationContentProps) => {
  const draggerRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const theme = useTheme();
  const { notifyError } = useErrors();
  const { isWriteWorkspaceAllow } = usePermissions();

  const validateFileExtentions = (uploadedFile: File): boolean => {
    const { name } = uploadedFile;
    const allowedExtensions = /(\.ppt|\.pptx)$/i;
    return name.match(allowedExtensions) !== null;
  };

  const handleBeforeUpload = (rcfile: File) => {
    const fileValidType = validateFileExtentions(rcfile);
    if (fileValidType) {
      onSelectFile(rcfile);
    } else {
      notifyError({
        general: buildGeneralMessage(
          intl.formatMessage(presentationFileTypeErrors.presentationTypeErrorHeadline),
          intl.formatMessage(presentationFileTypeErrors.presentationTypeErrorDescription, {
            acceptableTypes: "ppt,pptx"
          })
        )
      });
    }
    return false;
  };

  const handleButtonClick = (e: any) => {
    e.stopPropagation();
    const fileInput = document.querySelector('.ant-upload input[type="file"]') as HTMLElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <UploadDragWrapper $background={theme.gray2}>
      <Dragger
        ref={draggerRef}
        disabled={!isWriteWorkspaceAllow}
        beforeUpload={handleBeforeUpload}
        showUploadList={false}
        accept={fileTypes}
      >
        <H1_FlexColumn height="160px" justify="space-between" align="center">
          <H1_Icon icon="far fa-presentation-screen" color={theme.blue4} size="55px" />
          <ConditionalRender condition={!file}>
            <H1_FlexColumn>
              <H1_TextSmall color={theme.gray8} fontWeight={700}>
                {intl.formatMessage(presentationModalUploaderMessages.mainDescription)}
              </H1_TextSmall>
              <H1_TextXs color={theme.gray7} lineHeight="17px">
                {intl.formatMessage(presentationModalUploaderMessages.description)}
              </H1_TextXs>
            </H1_FlexColumn>
          </ConditionalRender>
          <ConditionalRender condition={!!file}>
            <H1_TextSmall fontWeight={600} color={theme.gray7}>
              {file?.name}
            </H1_TextSmall>
          </ConditionalRender>
          <Upload
            ref={draggerRef}
            disabled={!isWriteWorkspaceAllow}
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
            accept={fileTypes}
          >
            <>
              <Button
                color="primary"
                isDisabled={!isWriteWorkspaceAllow}
                startContent={<i className="fas fa-cloud-arrow-up" />}
                onClick={handleButtonClick}
              >
                {file
                  ? intl.formatMessage(presentationModalUploaderMessages.changeFileButton)
                  : intl.formatMessage(presentationModalUploaderMessages.browseButton)}
              </Button>
            </>
          </Upload>
        </H1_FlexColumn>
      </Dragger>
    </UploadDragWrapper>
  );
};

export default UploadPresentationContent;
