import React, { useEffect, useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch } from "app/hooks";
import Config from "app/config/Config";

import { userActions } from "app/store/slices/user.slice";

interface ConnectLunchDraklyReduxProps {
  children: React.ReactNode;
}

const ConnectLunchDraklyRedux = ({ children }: ConnectLunchDraklyReduxProps) => {
  const dispatch = useAppDispatch();
  const flags = useFlags();

  useEffect(() => {
    if (Config.env.includes("local")) {
      const allFlagsKeys = Object.keys(flags);
      const trueFlags = allFlagsKeys.reduce((o, key) => Object.assign(o, { [key]: true }), {});
      dispatch(userActions.setFeatureFlags(trueFlags));
    } else {
      dispatch(userActions.setFeatureFlags(flags));
    }
  }, [flags]);

  const content = useMemo(() => {
    return <>{children}</>;
  }, []);

  return content;
};

export default ConnectLunchDraklyRedux;
