import { defineMessages } from "react-intl";

export const templatesPageMessages = defineMessages({
  headerTitle: {
    id: "templates-page.header-title",
    defaultMessage: "Create professional"
  },
  headerTitleLower: {
    id: "templates-page.header-title-lower",
    defaultMessage: "videos in less than 15 minutes"
  },
  headerSubTitle: {
    id: "templates-page.header-subtitle",
    defaultMessage: "What do you want to create today?"
  },
  headerSearchPlaceholder: {
    id: "templates-page.header-search-placeholder",
    defaultMessage: "Product tutorial video"
  },
  shortSearchPlaceholder: {
    id: "templates-page.short-search-placeholder",
    defaultMessage: "Search..."
  },
  filterBy: {
    id: "templates-page.filter-by",
    defaultMessage: "Filter By"
  },
  anysize: {
    id: "templates-page.any-size",
    defaultMessage: "Any size"
  },
  portrait: {
    id: "templates-page.portrait",
    defaultMessage: "Portrait"
  },
  landscape: {
    id: "templates-page.landscape",
    defaultMessage: "Landscape"
  },
  square: {
    id: "templates-page.square",
    defaultMessage: "Square"
  },
  noTemplates: {
    id: "templates-page.no-templates-v2",
    defaultMessage: "Need a custom template?"
  },
  noTemplateUpgradeSubText: {
    id: "templates-page.no-templates-upgrade-subtitle",
    defaultMessage: "Let us know which templates you want"
  },
  noTemplateUpgradeText: {
    id: "templates-page.no-templates-upgrade-text",
    defaultMessage: "Request a template"
  },
  requestTemplate: {
    id: "templates-page.request-template-v2",
    defaultMessage: "Book a call"
  },
  seeAll: {
    id: "templates-page.see-all",
    defaultMessage: "See All"
  },
  createCustomTemplate: {
    id: "templates-page.create-custom-template-v2",
    defaultMessage: "Talk to us to{br}create your template"
  },
  createCustomTemplateSubtitle: {
    id: "templates-page.create-custom-template-subtitle",
    defaultMessage: "Let us know which templates you want"
  }
});

export const templatesPreviewModalMessages = defineMessages({
  landscape: {
    id: "templates-preview-modal.landscape",
    defaultMessage: "Landscape"
  },
  portrait: {
    id: "templates-preview-modal.portrait",
    defaultMessage: "Portrait"
  },
  square: {
    id: "templates-preview-modal.square",
    defaultMessage: "Square"
  },
  upgradeText: {
    id: `templates-preview-modal.upgrade-text`,
    defaultMessage: `Create amazing templates`
  },
  upgradeSubtitleText: {
    id: `templates-preview-modal.upgrade-subtitle-text`,
    defaultMessage: `Upgrade to Enterprise in order to use transparent templates`
  },
  chooseDraftNameFormHeader: {
    id: `templates-preview-modal.choose-draft-name-form-header`,
    defaultMessage: `Video name`
  },
  untitledPlaceholder: {
    id: `templates-preview-modal.untitled-placeholder`,
    defaultMessage: `Untitled`
  },
  chooseFolderFormHeader: {
    id: `templates-preview-modal.choose-folder-form-header`,
    defaultMessage: `Folder`
  },
  selectFolderPlaceholder: {
    id: `templates-preview-modal.select-folder-placeholder`,
    defaultMessage: `Select a Folder`
  },
  useThisTemplateButton: {
    id: `templates-preview-modal.use-template`,
    defaultMessage: `Use template`
  },
  similarTemplates: {
    id: `templates-preview-modal.similar-templates`,
    defaultMessage: "Similar templates"
  }
});
