import styled from "styled-components";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

export const VideoActionButton = styled(Button)<{ $selected?: boolean; $blue?: boolean }>`
  position: relative;
  height: 48px;
  min-width: 102px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.gray4};
  background-color: ${({ theme, $selected }) =>
    $selected ? (theme.mode === ThemeMode.Light ? theme.blue1 : theme.blue2) : theme.gray1};
  color: ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray11)};
  span {
    color: ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray11)};
  }
  i {
    color: ${({ theme, $selected, $blue }) => ($blue || $selected ? theme.blue4 : theme.gray11)};
  }
  &:hover,
  &:active {
    border: 1px solid ${({ theme }) => theme.gray3};
    background-color: ${({ theme, $selected }) => ($selected ? theme.blue1 : theme.gray2)};
    color: ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray11)};
    span {
      color: ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray11)};
    }
    i {
      color: ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray11)};
    }
  }
`;
