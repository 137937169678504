import { AbsoluteFill, Audio, Img, interpolate, Sequence, useCurrentFrame } from "remotion";
import { SequenceImage } from "app/types";
import { onImageError } from "app/utils/helpers";
import styled from "styled-components";

interface CompositionProps {
  audioUrl: string;
  data: SequenceImage[];
  aspectRatio: number;
  height: number;
}

interface ProgressBarProps {
  sceneStart: number;
  sceneEnd: number;
}

const StyledProgressBar = styled.div`
  background-color: #4867ff;
  color: white;
  height: 5px;
`;
const StyledProgressBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(114, 114, 114, 0.5);
`;

const ProgressBar = ({ sceneStart, sceneEnd }: ProgressBarProps) => {
  const frame = useCurrentFrame();
  // Calculate progress

  const progress = interpolate(frame, [sceneStart, sceneEnd], [0, 100], {
    extrapolateLeft: "clamp",
    extrapolateRight: "clamp"
  });

  return (
    <StyledProgressBarWrapper>
      <StyledProgressBar style={{ width: `${progress}%` }}></StyledProgressBar>
      {/*<StyledProgressBaText>Scene {sceneIndex}</StyledProgressBaText>*/}
    </StyledProgressBarWrapper>
  );
};
const Composition = ({ audioUrl, data, aspectRatio, height }: CompositionProps) => {
  return (
    <AbsoluteFill>
      {data.map((cur, index) => {
        return (
          <Sequence key={index} from={cur.from} durationInFrames={cur.durationInFrames}>
            <ProgressBar sceneStart={0} sceneEnd={cur.durationInFrames} />
            <Img
              width={height * aspectRatio}
              height={height}
              onError={onImageError}
              src={cur.imageUrl}
            />
          </Sequence>
        );
      })}
      <Audio src={audioUrl} />
    </AbsoluteFill>
  );
};

export default Composition;
