import { useEffect } from "react";
import { MARKET_PLACE_KEYS } from "app/types/LocalStorageKeys";
import * as Sentry from "@sentry/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userActions } from "app/store/slices/user.slice";
import { useAppDispatch } from "app/hooks";

const MsLandingPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      try {
        dispatch(userActions.setMsMarketplaceToken(token));
        localStorage.setItem(MARKET_PLACE_KEYS.msMarketplace_ref, token);
      } catch (err) {
        Sentry.captureException(err, {
          extra: { description: "failed to save ms marketplace_ref" }
        });
      }
    }
    navigate("/");
  }, [token]);

  return null;
};
export default MsLandingPage;
