import { FetchStatus, CGNType } from "app/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/cgn.thunk";
import { fetchingStatus } from "app/utils/helpers";

interface CGNSliceState {
  channels: { name: "news" | "entertainment"; playback_id: string; type: CGNType }[];
  getCGNStatus: FetchStatus;
}

const INITIAL_STATE: CGNSliceState = {
  channels: [],
  getCGNStatus: fetchingStatus.idle
};

export const cgnSlice = createSlice({
  name: "CGN",
  initialState: INITIAL_STATE,
  reducers: {
    setCGNProperties(
      state,
      action: PayloadAction<{ name: "entertainment" | "news"; playback_id: string; type: CGNType }>
    ) {
      state.channels = state.channels.map((channel) =>
        channel.name === action.payload.name
          ? { ...channel, playback_id: action.payload.playback_id, type: action.payload.type }
          : channel
      );
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getCGNRequest.fulfilled, (state, action) => {
      state.channels = action.payload;
      state.getCGNStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNRequest.pending, (state) => {
      state.getCGNStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getCGNRequest.rejected, (state) => {
      state.getCGNStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});
export default cgnSlice.reducer;

export const cgnActions = {
  getCGNRequest: asyncThunks.getCGNRequest,
  ...cgnSlice.actions
};
