import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import { PlanAndPriceModel, PlanToUpgrade, SubscriptionPlanEnum } from "app/types";
import { SliderMarks } from "antd/es/slider";

export const template = (state: RootState) => state.templates.currentTemplate;
export const createDraftStatus = (state: RootState) => state.drafts.createDraftStatus;
const featuresPricing = (state: RootState) => state.payments.featuresPricing;
const pricingAndPlans = (state: RootState) => state.payments.pricingAndPlans;
const pricingModalProperties = (state: RootState) => state.payments.pricingModalProperties;

const publishSortFunc = (a: Partial<PlanAndPriceModel>, b: Partial<PlanAndPriceModel>) =>
  (a.publish_credits as number) > (b.publish_credits as number) ? 1 : -1;

export const getPricingFeatureIdByAction = createSelector(
  [featuresPricing, (state, name) => name],
  (features, name) => {
    return features.find((feature) => feature.name === name);
  }
);

export const getPersonalMonthlyPlan = createSelector([pricingAndPlans], (pricing) => {
  if (pricing) {
    const allPlans = pricing.find(
      (p) => p.subscription_plan === SubscriptionPlanEnum.personal_monthly
    );
    return allPlans;
  }
  return undefined;
});
export const getPersonalYearlyPlan = createSelector([pricingAndPlans], (pricing) => {
  if (pricing) {
    const allPlans = pricing.find(
      (p) => p.subscription_plan === SubscriptionPlanEnum.personal_yearly
    );
    return allPlans;
  }
  return undefined;
});

export const getAllBusinessMonthlyPlansMinutes = createSelector(
  [pricingAndPlans],
  (pricing): SliderMarks => {
    if (pricing) {
      const allBusinessMonthlyPlans = pricing
        .filter((p) => p.subscription_plan === SubscriptionPlanEnum.business_monthly)
        .sort(publishSortFunc);

      const marks: SliderMarks = {};
      allBusinessMonthlyPlans.forEach((businessPlan, index) => {
        marks[index + 1] = Math.round((businessPlan.publish_credits || 0) / 60);
      });

      return marks;
    }
    return {};
  }
);

export const getCurrentBusinessMinutes = createSelector(
  [getAllBusinessMonthlyPlansMinutes, pricingModalProperties],
  (allBusinessMonthlyPlansMinutes, { numberOfMinutesPosition }) => {
    if (allBusinessMonthlyPlansMinutes && numberOfMinutesPosition) {
      return allBusinessMonthlyPlansMinutes[numberOfMinutesPosition as number];
    }
    return 0;
  }
);
export const getBusinessMonthlyPlan = createSelector(
  [pricingAndPlans, pricingModalProperties],
  (pricing, { numberOfMinutesPosition }) => {
    if (pricing && numberOfMinutesPosition) {
      const allPlans = pricing
        .filter((p) => p.subscription_plan === SubscriptionPlanEnum.business_monthly)
        .sort(publishSortFunc);
      return allPlans[numberOfMinutesPosition - 1];
    }
    return undefined;
  }
);

export const getBusinessYearlyPlan = createSelector(
  [pricingAndPlans, pricingModalProperties],
  (pricing, { numberOfMinutesPosition }) => {
    if (pricing && numberOfMinutesPosition) {
      const allPlans = pricing
        .filter((p) => p.subscription_plan === SubscriptionPlanEnum.business_yearly)
        .sort(publishSortFunc);
      return allPlans[numberOfMinutesPosition - 1];
    }
    return undefined;
  }
);

export const getSpecificSubscriptionPlan = createSelector(
  [pricingAndPlans, pricingModalProperties, (state, targetPlan?: PlanToUpgrade) => targetPlan],
  (pricing, { selectedPlan: plan, paymentPeriod, numberOfMinutesPosition }, targetPlan) => {
    const selectedPlan = targetPlan || plan;
    if (pricing && selectedPlan) {
      const specificPlan =
        `${selectedPlan.toLowerCase()}_${paymentPeriod.toLowerCase()}` as SubscriptionPlanEnum;
      const allPlans = pricing
        .filter((p) => p.subscription_plan === specificPlan)
        .sort(publishSortFunc);

      // Currently only Business plan has multiple subscriptions
      return allPlans[
        specificPlan.includes("business") ? (numberOfMinutesPosition as number) - 1 : 0
      ];
    }
    return undefined;
  }
);
