import { defineMessages } from "react-intl";

export const reactionsMessages = defineMessages({
  detailedReactionsPopoverHeader: {
    id: `reactions.popover.detailed.headline`,
    defaultMessage: "Reactions"
  },
  allReactionsLabel: {
    id: `reactions.buttons.addOne.label`,
    defaultMessage: "All {count}"
  }
});

export const emojiMessages = defineMessages({
  LikeEmojiLabel: {
    id: `reactions.emoji.like.label`,
    defaultMessage: "thumbs up"
  },
  LikeEmojiTooltip: {
    id: `reactions.emoji.like.tooltip`,
    defaultMessage: "Like"
  },
  FunnyEmojiLabel: {
    id: `reactions.emoji.funny.label`,
    defaultMessage: "face with tears of joy"
  },
  FunnyEmojiTooltip: {
    id: `reactions.emoji.funny.tooltip`,
    defaultMessage: "Funny"
  },
  CelebrateEmojiLabel: {
    id: `reactions.emoji.celebrate.label`,
    defaultMessage: "clapping hands"
  },
  CelebrateEmojiTooltip: {
    id: `reactions.emoji.celebrate.tooltip`,
    defaultMessage: "Celebrate"
  },
  LoveEmojiLabel: {
    id: `reactions.emoji.love.label`,
    defaultMessage: "yellow heart"
  },
  LoveEmojiTooltip: {
    id: `reactions.emoji.love.tooltip`,
    defaultMessage: "Love"
  },
  InsightfulEmojiLabel: {
    id: `reactions.emoji.insightful.label`,
    defaultMessage: "light bulb"
  },
  InsightfulEmojiTooltip: {
    id: `reactions.emoji.insightful.tooltip`,
    defaultMessage: "Insightful"
  }
});
