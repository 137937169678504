import React, { useEffect, useMemo, useState } from "react";

import "app/components/editor/Editor.scss";

import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HourOneBreadcrumb, { BreadcrumbElement } from "app/components/common/HourOneBreadcrumb";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { Input, InputRef } from "antd";
import VideoProgress from "app/pages/EditDraftPage/VideoProgress";
import TopVideoActions from "app/pages/EditDraftPage/TopVideoActions/TopVideoActions";
import styled from "styled-components";
import { foldersActions } from "app/store/slices/folders.slice";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import OutsideClickHandler from "react-outside-click-handler";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag, Location, VideoStatusEnum } from "app/types";
import LiveAvatars from "app/components/editor/LiveAvatars";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ReactComponent as HourOneNewLogo } from "app/assets/sider/hourone-new-logo.svg";
import { ReactComponent as HourOneLogo } from "app/assets/images/h1loginlogo.svg";
import useSmallScreen from "app/hooks/useSmallScreen";
import usePermissions from "app/hooks/usePermissions";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { defineMessages, useIntl } from "react-intl";
import useDropdownHandler from "app/hooks/useDropdownHandler";
import DevModeSwitch from "app/components/DevModeSwitch";
import TranslateDraftDropdown from "app/components/editor/editorHeader/TranslateDraftDropdown";
import DeveloperRequestButton from "app/components/DeveloperRequestButton";
import useModal, { ModalName } from "app/hooks/useModal";
import * as videosSelector from "app/store/selectorsV2/videos.selectors";
import OppositeUpgradeButton from "app/components/common/UpgradeButton/OppositeUpgradeButton";
import { useUpgradeByPlan } from "app/hooks/useUpgradeModal";
import DraftDropdownActions from "app/components/common/DraftDropdownActions";
import WorkFlowModeTag from "app/pages/EditDraftPage/workflow/WorkFlowModeTag";
import ReviewStatusButton from "app/components/common/ReviewStatus/ReviewStatusButton";
import { ThemeMode } from "app/utils/theme";

const EDITOR_HEADER = "editor_header";

const StyledOppositeUpgradeButton = styled(OppositeUpgradeButton)`
  border-radius: 7px;
  padding: 1px 13px;
  height: 40px;
`;

const BlueHourOneNewLogo = styled(HourOneNewLogo)`
  fill: ${({ theme }) => theme.blue4};
  @media (min-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  height: 30px;
  display: block;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    height: 28px;
  }
`;

const StyledHourOneLogo = styled(HourOneLogo)`
  g path {
    fill: ${({ theme }) => theme.gray11};
    &:last-child {
      fill: ${({ theme }) => theme.blue4};
    }
  }
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const ContainerFlexRow = styled(H1_FlexRow)`
  padding-left: 22px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
`;

const StyledFlexRow = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const StyledHourOneBreadcrumb = styled(HourOneBreadcrumb)`
  &.ant-breadcrumb > * > :last-child {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.gray11};
  }
`;

const RenameDraftInput = styled(Input)`
  padding: 0;
  margin: 0;
  font-size: 14px;
  max-height: 25px;
  width: max-content;
  border: none;
  outline: 0;
  box-shadow: none;
  border-radius: 2px;
  &:focus {
    color: ${({ theme }) => theme.gray11};
    background-color: ${({ theme }) => theme.gray3};
    border-radius: 2px;
    box-shadow: none;
    outline: 0;
  }
  &.ant-input {
    border-radius: 10px;
    color: ${({ theme }) => theme.gray11};
    background-color: ${({ theme }) => theme.gray3};
  }
`;
const ViewOnlyTagWrapper = styled(H1_FlexRow)`
  background-color: #b0b0b0;
  border-radius: 4px;
  color: white;
  height: 38px;
`;

const messages = defineMessages({
  viewOnlyTitle: {
    id: "editor-header.view-only.title",
    defaultMessage: "View Only"
  },

  editProjectMetaDropdownDuplicateNotification: {
    id: `editor-header.dropdown.notifications.duplicate`,
    defaultMessage:
      "Duplicate Draft in progress. You will be brought to the new draft once completed"
  }
});

const ViewOnlyTag = () => {
  const { formatMessage } = useIntl();
  return (
    <ViewOnlyTagWrapper gap={"8px"} padding={"0px 16px"} align={"center"} justify={"center"}>
      <i className="fa-solid fa-eye" />
      <H1_TextSmall>{formatMessage(messages.viewOnlyTitle)}</H1_TextSmall>
    </ViewOnlyTagWrapper>
  );
};

const EditorHeader = () => {
  const [originalInputName, setOriginalInputName] = useState<string>("");
  const [renameActive, setRenameActive] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const { editorModalOpen } = useModal();
  const { isSmallScreen } = useSmallScreen();
  const { shouldShowUpgradeButton, isWriteWorkspaceAllow } = usePermissions();
  const intl = useIntl();
  const upgradeModal = useUpgradeByPlan();

  const workflowLiveMode = useAppSelector((state) => state.drafts.workflowLiveMode);
  useDropdownHandler({
    goFoldersOnDelete: true,
    goDraftOnDuplicate: true,
    activeApiExampleHandler: !workflowLiveMode
  });
  const inputRef = React.useRef<InputRef>(null);
  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const getFolderStatus = useAppSelector((state) => state.folders.getFolderStatus);
  const currentFolder = useAppSelector((state) => state.folders.currentFolder);
  const draftsStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const draftType = useAppSelector((state) => state.drafts.currentDraft.type);
  const lastVideo = useAppSelector(videosSelector.getLastVersionVideo);

  const createVideoModalVisible = editorModalOpen?.open === ModalName.createVideoModalV2;
  const isCanceledVideo = lastVideo?.status === VideoStatusEnum.Canceled;
  const isFailedVideo = lastVideo?.status === VideoStatusEnum.Failed;
  const isLoading = draftsStatus === fetchingStatus.loading;
  const isFolderLoading = getFolderStatus === fetchingStatus.loading;
  const draftTitle = draft?.title;
  const draftFolderParentId = draft?.folder_parent_id as string;
  const folderName = currentFolder?.name || "";

  const isWorkflow = draftType === "workflow";
  useEffect(() => {
    if (renameActive) {
      inputRef.current?.focus();
    }
  }, [renameActive]);

  useEffect(() => {
    if (draftFolderParentId && currentFolder.id !== draftFolderParentId && !isWorkflow) {
      dispatch(foldersActions.getFolderByIdRequest({ folderId: draftFolderParentId }));
    }
    //   current folder is not part of dep, because we want to get first value
  }, [draftFolderParentId]);

  const breadcrumbs: BreadcrumbElement[] = useMemo(() => {
    const res: BreadcrumbElement[] = [
      {
        id: "folderName",
        name: folderName,
        isLoading: !folderName || isFolderLoading,
        url: `/folders/${draftFolderParentId}`
      }
    ];

    if (draftTitle) {
      res.push({ name: draftTitle, id: "draftTitle" });
    }

    return res;
  }, [renameActive, draftTitle, folderName, isFolderLoading]);

  const onRenameDraft = () => {
    if (draftTitle) {
      setOriginalInputName(draftTitle);
    }
    setRenameActive(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 101);
  };

  const isNameNewAndValid = (newName: string) => {
    return newName && newName !== draftTitle;
  };

  const onBlurChange = () => {
    const newName = inputRef.current?.input?.value;
    if (newName && isNameNewAndValid(newName)) {
      dispatch(
        draftsActionsV2.patchDraftRequest({
          draftId: draft?.id as string,
          operations: [{ op: "replace", path: "title", value: newName }]
        })
      );
      dispatch(googleEvents.editedVideoName());
    } else if (!newName && inputRef.current?.input) {
      inputRef.current.input.value = originalInputName;
    }
    setRenameActive(false);
  };

  const onUpgrade = () => {
    upgradeModal({ source: `upgrade_${EDITOR_HEADER}` });
  };

  return (
    <ContainerFlexRow
      justify="space-between"
      flex="1 1 auto"
      position={"relative"}
      align={"center"}
    >
      <H1_FlexRow align="center">
        <StyledLink to={Location.Home}>
          <StyledHourOneLogo height={30} width="100%" />
          <BlueHourOneNewLogo height={32} width={32} />
        </StyledLink>

        <StyledFlexRow align="center" gap="10px">
          {breadcrumbs && !isWorkflow && (
            <StyledHourOneBreadcrumb
              breadcrumbs={breadcrumbs}
              editableItems={{
                draftTitle: renameActive
              }}
              onRenameBreadcrumb={onRenameDraft}
              editableActive={
                <OutsideClickHandler onOutsideClick={onBlurChange}>
                  <RenameDraftInput
                    ref={inputRef}
                    defaultValue={draftTitle}
                    onPressEnter={onBlurChange}
                  />
                </OutsideClickHandler>
              }
            />
          )}
          <ConditionalRender condition={!workflowLiveMode}>
            <ConditionalRender condition={isWorkflow}>
              <H1_FlexRow padding="0 5px 0 0" />
            </ConditionalRender>
            <DraftDropdownActions
              draft={draft}
              key="dropdown-actions"
              onRenameDraft={onRenameDraft}
              duplicateNotification={intl.formatMessage(
                messages.editProjectMetaDropdownDuplicateNotification
              )}
            />
            <ConditionalRender condition={!isWorkflow}>
              <TranslateDraftDropdown />
            </ConditionalRender>
            <ConditionalRender condition={!isWorkflow && isWriteWorkspaceAllow}>
              <ReviewStatusButton draftId={draft.id as string} />
            </ConditionalRender>
            <DeveloperRequestButton />
          </ConditionalRender>
          <ConditionalRender condition={!isWriteWorkspaceAllow}>
            <ViewOnlyTag />
          </ConditionalRender>
        </StyledFlexRow>
      </H1_FlexRow>
      <ConditionalRender condition={isWorkflow}>
        <WorkFlowModeTag />
      </ConditionalRender>

      <H1_FlexRow align="center" flex="0 0 auto">
        <H1_FlexRow align="center" padding="0 11px">
          <DevModeSwitch />
        </H1_FlexRow>
        <ConditionalRender condition={!isLoading}>
          <ConditionalRender
            condition={
              !createVideoModalVisible && !isCanceledVideo && !isFailedVideo && !isWorkflow
            }
          >
            <VideoProgress />
          </ConditionalRender>
          <ConditionalRender condition={flags[FeatureFlag.liveBlocks] && !isSmallScreen}>
            <LiveAvatars />
          </ConditionalRender>
          <H1_FlexRow gap="10px" align="center" padding="0 10px 0 0">
            <ConditionalRender condition={shouldShowUpgradeButton}>
              <StyledOppositeUpgradeButton
                disabled={isLoading}
                source="editor"
                onClick={onUpgrade}
              />
            </ConditionalRender>
          </H1_FlexRow>
          <TopVideoActions draftId={draft.id as string} />
        </ConditionalRender>
      </H1_FlexRow>
    </ContainerFlexRow>
  );
};

export default EditorHeader;
