import { Tooltip } from "antd";
import React, { MouseEvent, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import useNotifications from "app/hooks/useNotifications";

const messages = defineMessages({
  copiedNotification: {
    id: `use-copy.copied-to-clipboard-notification`,
    defaultMessage: `Link has been copied!`
  }
});

interface TriggerCopyProps {
  event: MouseEvent | MouseEvent<HTMLTextAreaElement> | undefined;
  copyContent: string | undefined;
  shouldNotify: boolean;
}

const useCopy = () => {
  const [copyStatus, setCopyStatus] = useState<undefined | string>(undefined);
  const { formatMessage } = useIntl();
  const { notifyMessages } = useNotifications();

  const CopyToolTip: React.FC<React.PropsWithChildren<Record<string, any>>> = ({ children }) => {
    return (
      <Tooltip
        title={copyStatus ? "Copied" : "Failed"}
        color={copyStatus ? "#ff015c" : "#d9d9d9"}
        key={copyStatus?.toString() || "hidden"}
        open={!!copyStatus}
        getTooltipContainer={(node: HTMLElement) => node}
      >
        {children}
      </Tooltip>
    );
  };

  const triggerCopy = async ({ event, copyContent, shouldNotify }: Partial<TriggerCopyProps>) => {
    try {
      if (copyContent) {
        await navigator.clipboard.writeText(copyContent);
      } else if (event) {
        const elm = event.target as HTMLInputElement;
        const content = elm?.value;
        await navigator.clipboard.writeText(content);
      } else {
        throw new Error("No content or elm to copy from");
      }
      setTimeout(() => {
        return setCopyStatus(undefined);
      }, 3000);
      if (shouldNotify) {
        notifyMessages([
          {
            message: formatMessage(messages.copiedNotification)
          }
        ]);
      }
      return setCopyStatus("Copied");
    } catch (err) {
      console.error(err);
      return setCopyStatus("Failed");
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  return { triggerCopy, CopyToolTip };
};

export default useCopy;
