import ConditionalRender from "app/components/common/ConditionalRender";
import PlaygroundVoiceSelection from "app/pages/PlayGround/PlaygroundVoiceSelection";
import { PlaygroundVideoTypeTabs } from "app/types/playground";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { BlackButton } from "app/pages/PlayGround/BlackButton";
import { Button } from "@nextui-org/react";
import React from "react";
import { Voice } from "app/types";
import { useAppSelector } from "app/hooks";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { fetchingStatus } from "app/utils/helpers";
import styled from "styled-components";

const AbsoluteTextContainer = styled(H1_FlexRow)`
  position: absolute;
  bottom: -30px;
  right: 20px;
`;
interface PlaygroundFooterAreaProps {
  onGenerate: () => void;
  activeTab: PlaygroundVideoTypeTabs;
  transcript?: string;
  charactersLength?: number;
  voicesLength?: number;
  onVoicePreviewClicked: (voice: Partial<Voice>) => void;
  onShowVoiceModal: (show: boolean) => void;
  importLoadingVoice?: boolean;
  voiceIdLoading: string;
  voiceIdPlaying: string;
  generateButtonLoading: boolean;
  isFullTranscriptPreviewPlaying: boolean;
  isGenerateButtonDisabled: boolean;
  onPreviewText: () => void;
}

const PlaygroundFooterArea = ({
  onShowVoiceModal,
  voiceIdLoading,
  voiceIdPlaying,
  generateButtonLoading,
  isGenerateButtonDisabled,
  isFullTranscriptPreviewPlaying,
  onPreviewText,
  onGenerate,
  transcript,
  activeTab,
  charactersLength = 0,
  voicesLength = 0,
  importLoadingVoice,
  onVoicePreviewClicked
}: PlaygroundFooterAreaProps) => {
  const limits = useAppSelector(getLimits);
  const previewTranscriptStatus = useAppSelector(
    (state) => state.playground.previewTranscriptStatus
  );
  const previewTranscriptLoading = previewTranscriptStatus === fetchingStatus.loading;
  const isTabTranslate = activeTab === PlaygroundVideoTypeTabs.Translate;
  const isTabLivePortrait = activeTab === PlaygroundVideoTypeTabs.Live_Portrait;

  return (
    <H1_FlexRow
      alignSelf="flex-end"
      align="center"
      height="38px"
      justify={isTabTranslate ? "flex-end" : "space-between"}
      width="100%"
    >
      <ConditionalRender condition={!isTabTranslate}>
        <ConditionalRender condition={charactersLength > 0 && voicesLength > 0 && !isTabTranslate}>
          <PlaygroundVoiceSelection
            importLoadingVoice={importLoadingVoice}
            onShowVoiceModal={onShowVoiceModal}
            onVoicePreviewClicked={onVoicePreviewClicked}
            voiceIdLoading={voiceIdLoading}
            voiceIdPlaying={voiceIdPlaying}
            isExternalVideo={
              activeTab === PlaygroundVideoTypeTabs.Speaking_Video || isTabLivePortrait
            }
          />
        </ConditionalRender>
        <ConditionalRender condition={!isTabLivePortrait}>
          <H1_TextSmall color="#8C8C8C" margin="0 10px">
            {transcript?.length || 0}/{limits.chars_per_scene}
          </H1_TextSmall>
        </ConditionalRender>
        <ConditionalRender condition={isTabLivePortrait}>
          <AbsoluteTextContainer>
            <H1_TextSmall color="#8C8C8C" margin="0 10px">
              {transcript?.length || 0}/{limits.chars_per_scene}
            </H1_TextSmall>
          </AbsoluteTextContainer>
        </ConditionalRender>
      </ConditionalRender>
      <H1_FlexRow gap="18px">
        <ConditionalRender condition={!isTabTranslate}>
          <BlackButton
            isDisabled={!transcript}
            variant="light"
            startContent={
              <i
                className={
                  previewTranscriptLoading
                    ? "fas fa-spinner-third fa-spin"
                    : isFullTranscriptPreviewPlaying
                    ? "fas fa-pause"
                    : "fas fa-volume"
                }
              />
            }
            onClick={onPreviewText}
          >
            Listen
          </BlackButton>
        </ConditionalRender>
        <Button
          color="primary"
          isLoading={generateButtonLoading}
          onClick={onGenerate}
          isDisabled={isGenerateButtonDisabled}
        >
          {isTabTranslate ? "Translate" : "Generate"}
        </Button>
      </H1_FlexRow>
    </H1_FlexRow>
  );
};

export default PlaygroundFooterArea;
