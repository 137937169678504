import NoEnoughCreditsModal from "app/components/credits/NoEnoughCreditsModal";
import ErrorNotifier from "app/hoc/ErrorNotifier/ErrorNotifier";
import TemplateSelectModal from "app/components/editor/templateSelect/TemplateSelectModal";
import RenameDraftModal from "app/pages/FoldersPage/RenameDraftModal";
import CreateFolderModal from "app/pages/FoldersPage/CreateFolderModal";
import DeleteVideoModal from "app/pages/FoldersPage/DeleteVideoModal";
import MoveToFolderModal from "app/pages/FoldersPage/MoveToFolderModal";
import NotificationsHandler from "app/hoc/NotificationsHandler";
import VideoReadyModal from "app/pages/editor/VideoReadyModal/VideoReadyModal";
import VideoFailedModal from "app/pages/editor/VideoFailedModal/VideoFailedModal";
import CreateVideoModalV2 from "app/pages/editor/CreateVideoModalV2/CreateVideoModalV2";
import ScreenRecording from "app/components/screenRecording/ScreenRecording";
import TemplateFlowChoosingModal from "app/components/onBoarding/TemplateFlowChoosingModal";
import RecommendedTemplateModal from "app/components/onBoarding/RecommendedTemplateModal";
import PusherHandler from "app/hoc/Pusher/PusherHandler";
import EnterpriseModal from "app/pages/pricing/EnterpriseModal/EnterpriseModal";
import PublishVideoModal from "app/pages/editor/PublishVideoModal/PublishVideoModal";
import EmbedVideoModal from "app/pages/editor/EmbedVideoModal/EmbedVideoModal";
import MediaLibraryModal from "app/pages/mediaLibrary/MediaLibraryModal";
import DownloadVideoModal from "app/pages/editor/DownloadVideoModal/DownloadVideoModal";
import SlackBindModal from "app/components/slack/SlackBindModal";
import ShareChannelModal from "app/components/slack/ShareChannelModal";
import MobileCharacterQrModal from "app/components/editor/sideDrawers/EditingCharacterDiscover/MobileCharacterQrModal";
import VideoWizardModal from "app/components/common/VideoWizardModal";
import RenameFolderModal from "app/pages/FoldersPage/RenameFolderModal";
import AddOnPaymentModal from "app/pages/pricing/PaymentModal/AddOnPaymentModal";
import CloneVoiceModal from "app/components/editor/cloneVoice/CloneVoiceModal";
import PaymentModalV2 from "app/pages/pricing/PaymentModalV2/PaymentModalV2";
import TemplatePreviewModal from "app/pages/Templates/TemplatePreviewModal";
import StartupModals from "app/hoc/StartupModals";
import RenewTokenError from "app/components/authentication/RenewTokenError";
import NoWorkspaceAccessModal from "app/components/NoWorkspaceAccessModal";
import MaximumWorkspaceSeatsModal from "app/components/credits/MaximumWorkspaceSeatsModal";
import PlaygroundPublishVideoModal from "app/pages/PlayGround/PlaygroundPublishVideoModal";
import CreateDigitalTwinModal from "app/components/digitalAvatar/CreateDigitalTwinModal";
import SearchModal from "app/components/common/SearchModal/SearchModal";
import CreditsWarningNotifier from "app/hoc/ErrorNotifier/CreditsWarningNotifier";
import StartFromDocumentModal from "app/components/editor/documents/StartFromDocumentModal";
import StartFromScriptModal from "app/components/editor/documents/StartFromScriptModal";
import StartFromPresentationModal from "app/components/editor/presentations/StartFromPresentationModal";
import SummaryModal from "app/components/Summary/SummaryModal";
import ScormLoadingCreationModal from "app/components/common/player/ScormLoadingCreationModal";
import StartFromUrlModal from "app/components/editor/documents/StartFromURLModal";
import NextUIConfirmModal from "app/components/common/NextUI/ConfirmModal/NextUIConfirmModal";
import NoEnoughVideoPreviewCreditsModal from "app/components/credits/NoEnoughVideoPreviewCreditsModal";

const RootModalsContainer = () => {
  return (
    <>
      <StartupModals />
      <NoWorkspaceAccessModal />
      <NoEnoughCreditsModal />
      <NoEnoughVideoPreviewCreditsModal />
      <MaximumWorkspaceSeatsModal />
      <ErrorNotifier />
      <CreditsWarningNotifier />
      <NotificationsHandler />
      <RenameDraftModal />
      <CreateFolderModal />
      <VideoReadyModal />
      <ScormLoadingCreationModal />
      <VideoWizardModal />
      <PaymentModalV2 />
      <AddOnPaymentModal />
      <DownloadVideoModal />
      <EnterpriseModal />
      <CreateVideoModalV2 />
      <MobileCharacterQrModal />
      <VideoFailedModal />
      <DeleteVideoModal />
      <MoveToFolderModal />
      <RenameFolderModal />
      <TemplateSelectModal />
      <SearchModal />
      <PublishVideoModal />
      <PlaygroundPublishVideoModal />
      <EmbedVideoModal />
      <StartFromPresentationModal />
      <StartFromDocumentModal />
      <StartFromUrlModal />
      <StartFromScriptModal />
      <PusherHandler />
      <MediaLibraryModal />
      <ScreenRecording />
      <ShareChannelModal />
      <NextUIConfirmModal />
      {/* onBoarding modals: */}
      <TemplateFlowChoosingModal />
      <RecommendedTemplateModal />
      <SlackBindModal />
      <CloneVoiceModal />
      <TemplatePreviewModal />
      <RenewTokenError />
      <CreateDigitalTwinModal />
      <SummaryModal />
    </>
  );
};
export default RootModalsContainer;
