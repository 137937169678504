import { Brief, BriefUserRelation } from "app/types/brief";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Location } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { getTimeAgo } from "app/utils/helpers";
import { briefsPageMessages } from "app/pages/Briefs/messages";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import UserDetails from "app/pages/Briefs/UserDetails";
import { Button } from "@nextui-org/react";

const StyledCreateVideoButton = styled(Button)`
  width: fit-content;
  border-radius: 12px;
  padding: 10px 50px 10px 50px;

  background-color: ${({ theme }) => theme.gray1};
  display: flex;
  height: 40px;
  min-width: 80px;
  align-items: center;
  align-self: flex-end;
  color: ${({ theme }) => theme.gray7};
  span {
    color: ${({ theme }) => theme.gray7};
  }
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  font- &:hover {
    background-color: ${({ theme }) => theme.gray3};
  }
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
  }
`;
const StyledFlexColumn = styled(H1_FlexColumn)`
  border-radius: 14px;
  gap: 13px;
  padding: 12px 25px 20px 27px;
  border: 1px solid rgba(236, 237, 238, 0.1);
  background: linear-gradient(0deg, #e3e3e4 0%, #e3e3e4 100%), #18181b;
`;
interface BriefCardBodyProps {
  briefs: Brief[];
}
const BriefCardBody = ({ briefs }: BriefCardBodyProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <H1_FlexColumn gap="28px">
      {briefs.map((brief) => {
        return (
          <StyledFlexColumn key={brief.id}>
            <H1_FlexRow justify="space-between" align="center" width="100%">
              <UserDetails userEmail={brief.email as string} />
              <H1_TextXs>{getTimeAgo(brief.created_at)}</H1_TextXs>
            </H1_FlexRow>
            <H1_TextSmall whiteSpace="normal" margin="0 0 0 15px" color={theme.gray8}>
              {brief.content}
            </H1_TextSmall>
            <ConditionalRender condition={brief.user_relation === BriefUserRelation.recipient}>
              <StyledCreateVideoButton onClick={() => navigate(Location.Templates)}>
                {intl.formatMessage(briefsPageMessages.createVideoButton)}
              </StyledCreateVideoButton>
            </ConditionalRender>
            <H1_FlexColumn />
          </StyledFlexColumn>
        );
      })}
    </H1_FlexColumn>
  );
};

export default BriefCardBody;
