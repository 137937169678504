import { SupportedTranslationLanguages } from "app/types";
import React, { Key, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { editorHeaderMessages } from "app/components/editor/editorHeader/messages";
import { useIntl } from "react-intl";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { capitalize } from "lodash-es";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectItem
} from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const StyledPopoverContent = styled(PopoverContent)`
  max-width: 300px;
  width: 100%;
`;

const StyledTranslationLoaderContiner = styled(Popover)`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${(props) => props.theme.blue4};
  .ant-popover-inner-content {
    border: none;
    font-weight: 400;
  }
`;

const StyledIcon = styled.i`
  font-size: 19px;
  line-height: 25px;
  color: ${({ theme }) => theme.gray8};
`;

const StyledCrownIcon = styled(CrownIcon)`
  margin-top: 2px;
  margin-right: 5px;
`;

const content = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const intl = useIntl();
  const showPaymentModal = useUpgradeModal();
  const showCrownModal = useShowCrown({
    requestedFeature: PlanFeature.DraftTranslation
  });

  const detectedLanguageLoweredSplit = useMemo(
    () => draft.language_detected?.trim().toLowerCase().split(" ")[0],
    [draft.language_detected]
  );

  const languageCapitalized = (lang?: string) => capitalize(lang?.trim().split(" ")[0]);

  const langOptions = useMemo(
    () =>
      Object.entries(SupportedTranslationLanguages)
        .filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([langKey, langName]) => langName.toLocaleLowerCase() !== detectedLanguageLoweredSplit
        )
        .map(([langKey, langName]) => ({
          value: langName,
          key: langKey,
          label: languageCapitalized(langName)
        })),
    [draft.language_detected]
  );

  const [languageDestination, setLanguageDestination] = useState<SupportedTranslationLanguages>();
  const languageDetectionStatus = useAppSelector((state) => state.drafts.languageDetectionStatus);
  const draftTranslationStatus = useAppSelector((state) => state.drafts.draftTranslationStatus);

  const languageDetectionIsLoading = languageDetectionStatus === fetchingStatus.loading;
  const draftTranslationIsLoading = draftTranslationStatus === fetchingStatus.loading;

  useEffect(() => {
    if (languageCapitalized(draft?.language_detected) === SupportedTranslationLanguages.english) {
      setLanguageDestination(SupportedTranslationLanguages.spanish);
    } else {
      setLanguageDestination(SupportedTranslationLanguages.english);
    }
  }, [draft?.language_detected]);

  const onChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguageDestination(e.target.value as SupportedTranslationLanguages);
  };

  const onTranslate = () => {
    if (
      !showPaymentModal({
        source: "upgrade_editor_translation",
        upgradeText: intl.formatMessage(editorHeaderMessages.translationPaymentHeadline),
        requestedFeature: PlanFeature.DraftTranslation
      }) &&
      languageDestination
    ) {
      dispatch(
        analyticsEvents.draftTranslationIntent({
          language: languageDestination,
          draftId: draft.id as string
        })
      );
      dispatch(
        draftsActions.translateDraftRequest({
          draftId: draft.id as string,
          translateTo: languageDestination as SupportedTranslationLanguages
        })
      );
    }
  };

  useEffect(() => {
    if (!draftTranslationIsLoading && !languageDetectionIsLoading) {
      dispatch(draftsActions.detectDraftLanguageRequest({ draftId: draft.id as string }));
    }

    return () => {
      dispatch(draftsActions.updateDraftLanguageDetectionStatusToIdle());
    };
  }, []);

  useEffect(() => {
    if (
      languageDetectionStatus === fetchingStatus.failed ||
      languageDetectionStatus === fetchingStatus.succeeded
    ) {
      dispatch(draftsActions.updateDraftLanguageDetectionStatusToIdle());
    }
  }, [languageDetectionStatus]);

  return (
    <H1_FlexColumn gap="20px">
      {/* Headline */}
      <H1_TextMiddle>{formatMessage(editorHeaderMessages.translationHeadline)}</H1_TextMiddle>

      <H1_FlexColumn gap="15px">
        {/* Current lang */}
        <H1_FlexRow justify="space-between" align="center">
          <H1_TextSmall>{formatMessage(editorHeaderMessages.translationCurrentLang)}</H1_TextSmall>
          <H1_FlexRow>
            <ConditionalRender condition={languageDetectionIsLoading}>
              <CircleLoader size="20px" />
            </ConditionalRender>
            <ConditionalRender condition={!!draft.language_detected}>
              <H1_TextSmall fontWeight={400} color={theme.gray7}>
                {languageCapitalized(draft.language_detected)}
              </H1_TextSmall>
            </ConditionalRender>
          </H1_FlexRow>
        </H1_FlexRow>

        {/* Translate destination */}
        <H1_FlexRow justify="space-between" align="center" gap="20px">
          <H1_TextSmall>{formatMessage(editorHeaderMessages.translationDestination)}</H1_TextSmall>
          <Select
            classNames={{
              popoverContent: "max-w-[125px]",
              listbox: theme.mode === ThemeMode.Light ? "text-gray-11" : "text-white"
            }}
            className="translation-lang-select"
            onChange={onChangeLanguage}
            selectedKeys={[languageDestination as Key]}
            items={langOptions}
            size="sm"
            labelPlacement="outside"
          >
            {(option: any) => (
              <SelectItem color="primary" key={option.value} textValue={option.label}>
                <span>{option.label}</span>
              </SelectItem>
            )}
          </Select>
        </H1_FlexRow>
      </H1_FlexColumn>

      {/* Explanation */}
      <H1_TextXs whiteSpace="initial" color={theme.gray7}>
        {formatMessage(editorHeaderMessages.translationExplain)}
      </H1_TextXs>

      {/* Call to action */}
      <Button
        color="primary"
        isDisabled={
          !languageDestination ||
          languageDestination === draft.language_detected ||
          !!draftTranslationIsLoading
        }
        isLoading={!!draftTranslationIsLoading}
        onClick={onTranslate}
      >
        <ConditionalRender condition={showCrownModal}>
          <StyledCrownIcon />
        </ConditionalRender>
        {formatMessage(editorHeaderMessages.callToAction)}
      </Button>
    </H1_FlexColumn>
  );
};

const TranslateDraftDropdown = () => {
  const [hovered, setHovered] = useState(false);
  const draftTranslationStatus = useAppSelector((state) => state.drafts.draftTranslationStatus);
  const { formatMessage } = useIntl();
  const handleHoverChange = (open: boolean) => {
    setHovered(open);
  };

  useEffect(() => {
    if (draftTranslationStatus === fetchingStatus.loading) {
      handleHoverChange(false);
    }
  }, [draftTranslationStatus]);

  if (draftTranslationStatus === fetchingStatus.loading) {
    return (
      <StyledTranslationLoaderContiner
        placement="right"
        className="translate-draft-loader-pop"
        getPopupContainer={() =>
          document.querySelector(`.translate-draft-loader-pop`) as HTMLElement
        }
        content={formatMessage(editorHeaderMessages.translationProcess)}
        overlayInnerStyle={{
          padding: 0
        }}
        showArrow={false}
        trigger="hover"
      >
        <StyledIcon className="fa-light fa-language" />
        <CircleLoader size="50px" />
      </StyledTranslationLoaderContiner>
    );
  }
  return (
    <Popover
      placement="bottom-start"
      className="draft-translation"
      isOpen={hovered}
      onOpenChange={handleHoverChange}
      containerPadding={20}
      classNames={{ content: "p-5" }}
    >
      <PopoverTrigger>
        <Button
          variant="light"
          startContent={<StyledIcon className="fa-light fa-language" />}
          isIconOnly
          data-auto-id="translate-draft-popover"
        />
      </PopoverTrigger>
      <StyledPopoverContent>{content}</StyledPopoverContent>
    </Popover>
  );
};

export default TranslateDraftDropdown;
