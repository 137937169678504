import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

import { ReactComponent as HouroneLogo } from "./hourone-logo.svg";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextHeadline, H1_TextXs } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import React, { useEffect } from "react";
import { fetchingStatus } from "app/utils/helpers";
import JSONPretty from "react-json-pretty";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { message, Tooltip } from "antd";
import useCopy from "app/hooks/useCopy";
import { draftsActions } from "app/store/slices/drafts.slice";
import { Button } from "@nextui-org/react";

const Container = styled(H1_FlexColumn)`
  border-radius: 10px;
  background: ${({ theme }) => theme.gray1};

  /* Modal shadow 10% */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  max-width: 710px;
  min-height: 500px;
  flex-shrink: 0;
  min-width: 710px;
`;
const LogoWrapper = styled(H1_FlexRow)`
  // position: absolute;
  top: -60px;
  left: 0px;
`;

const StyledJSONPretty = styled(JSONPretty)`
  max-height: 300px;
  background-color: ${({ theme }) => theme.gray3};
  margin-bottom: 0;
  display: flex;
  border-radius: 4px;
  overflow: hidden;

  && .__json-pretty-workflow__ {
    overflow: scroll;
  }
  && {
    width: 100%;
    border-radius: 2px;
  }
`;
const CopyButtonWrapper = styled("div")`
  //position: absolute;
  //right: 35px;
  top: 0;
  i {
    color: ${({ theme }) => theme.gray11};
  }
`;

const CodeContainer = styled.div`
  border-radius: 2px;
  background: #020d44;
  color: white;
  padding: 5px;
  //display: flex;
  flex: 1;
`;
const IntegrateWithApi = () => {
  const dispatch = useAppDispatch();
  const { triggerCopy } = useCopy();

  const apiExampleResult = useAppSelector((state) => state.drafts.apiExampleResult);
  const apiExampleStatus = useAppSelector((state) => state.drafts.apiExampleStatus);
  const isLoading = apiExampleStatus === fetchingStatus.loading;
  const jsonViewerCommonStyle = "background:#020d44" + ";color:white; flex:1";

  const workflowId = useAppSelector((state) => state.drafts.currentDraft?.workflow_id);

  useEffect(() => {
    dispatch(
      draftsActions.createApiVideoExampleRequest({
        type: "create_video_from_workflow",
        workflowId: workflowId as string
      })
    );

    return () => {
      dispatch(draftsActions.setApiExampleStatusToIdle());
    };
  }, []);

  const onCopy = () => {
    if (apiExampleResult?.curl) {
      triggerCopy({ copyContent: apiExampleResult?.curl, shouldNotify: false });
    }
    message.success("Copied to clipboard");
  };

  const onCopyJson = () => {
    if (apiExampleResult?.json_request) {
      triggerCopy({
        copyContent: JSON.stringify(apiExampleResult?.json_request, null, 4),
        shouldNotify: false
      });
      message.success("Copied to clipboard");
    }
  };

  return (
    <Container
      gap={"25px"}
      align={"center"}
      position={"relative"}
      padding={"85px 120px"}
      margin={"0px 0px 10px 0px"}
    >
      <LogoWrapper justify={"center"} position={"absolute"} width={"100%"}>
        <HouroneLogo />
      </LogoWrapper>
      <H1_FlexColumn gap={"12px"} align={"center"}>
        <H1_TextHeadline>Integrate with API</H1_TextHeadline>
      </H1_FlexColumn>
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!!apiExampleResult && !isLoading}>
        <H1_FlexColumn width={"100%"}>
          <H1_TextXs>Curl</H1_TextXs>
          <H1_FlexRow width={"100%"}>
            <CodeContainer>{apiExampleResult?.curl}</CodeContainer>
            <CopyButtonWrapper>
              <Tooltip title="Copy CURL">
                <>
                  <Button
                    onClick={onCopy}
                    variant="light"
                    startContent={<i className="fa-regular fa-copy"></i>}
                    isIconOnly
                  />
                </>
              </Tooltip>
            </CopyButtonWrapper>
          </H1_FlexRow>
        </H1_FlexColumn>
        <H1_FlexColumn width={"100%"}>
          <H1_TextXs>Body</H1_TextXs>
          <H1_FlexRow width={"100%"} position={"relative"}>
            <StyledJSONPretty
              id="json-pretty-workflow"
              data={apiExampleResult?.json_request || {}}
              mainStyle={jsonViewerCommonStyle + ";padding:5px;margin-bottom:0"}
              keyStyle={jsonViewerCommonStyle}
              valueStyle={jsonViewerCommonStyle}
              booleanStyle={jsonViewerCommonStyle}
              stringStyle={jsonViewerCommonStyle}
            />
            <CopyButtonWrapper>
              <Tooltip title="Copy JSON">
                <>
                  <Button
                    onClick={onCopyJson}
                    variant="light"
                    startContent={<i className="fa-regular fa-copy"></i>}
                    isIconOnly
                  />
                </>
              </Tooltip>
            </CopyButtonWrapper>
          </H1_FlexRow>
        </H1_FlexColumn>
      </ConditionalRender>
    </Container>
  );
};

export default IntegrateWithApi;
