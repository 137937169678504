import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .ant-dropdown-menu-submenu {
    overflow: auto;
    max-height: 300px;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  .ant-dropdown-menu {
    padding: 8px 8px 8px 8px;
    border-radius: 10px;
  }
  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu {
      background-color: ${({ theme }) => theme.gray2};
    }
    background-color: transparent;
    color: ${({ theme }) => theme.gray11};
    transition: none;
    padding: 11px 12px;
    border-radius: 10px;
    .ant-dropdown-menu-item {
      background-color: ${({ theme }) => theme.gray2};
      color: ${({ theme }) => theme.gray11};
      border-radius: 10px;
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
      background-color: ${({ theme }) => theme.blue4};
      color: white;
      border-radius: 10px;
      span {
        color: white;
      }
    }
  }
`;
