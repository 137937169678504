import { Button, Row } from "antd";

import styled, { useTheme } from "styled-components";
import { StatusMessages } from "app/components/screenRecording/MediaRecorder";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useIntl } from "react-intl";
import { screenRecordingSessionMessages } from "app/components/screenRecording/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextBanner } from "app/components/_Infrastructure/Typography";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";

const CounterDiv = styled.div`
  height: 168px;
  width: 168px;
  background: #ffffff;
  opacity: 0.65;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 600; // Above video
  position: relative;
  box-shadow: 0px 22px 64px 0px #00000040;
`;

const ActionsButtonsFlexRow = styled(H1_FlexRow)`
  border-radius: 5px;
  background-color: #ffff;
  z-index: 600; // Above video
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 22px 64px 0px #00000040;
  margin: 20px;
`;

const CounterWrapper = styled(H1_FlexColumn)`
  left: 0;
  top: 0;
  position: fixed;
  z-index: 600; // Above video
`;

export interface ScreenRecordingSessionProps {
  status: StatusMessages;
  counter: number;
  mediaBlobUrl: string | null;
  recordTimer: string;
  recordExecuted: boolean;
  paused: boolean;
  togglePause: () => void;
  onStop: () => void;
  onCancel: () => void;
  loading: boolean;
}

const StyledCancelButton = styled(Button)`
  background: ${(props) => props.theme.gray5};
  font-size: 16px;
  font-family: Poppins;
  color: white;
  border: unset;
  border-radius: 2px;
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.gray5};
    opacity: 0.7;
    border: unset;
    color: white;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 133px;
  height: 40px;
`;

const ScreenRecordingSession = ({
  status,
  counter,
  loading,
  mediaBlobUrl,
  recordTimer,
  recordExecuted,
  paused,
  togglePause,
  onCancel,
  onStop
}: ScreenRecordingSessionProps) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <>
      <ConditionalRender
        condition={!loading && status === StatusMessages.shareScreenActivated && !!counter}
      >
        <CounterWrapper width="100%" height="100%" gap="33px" justify="center" align="center">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            src={`https://df6g5g0b3bt51.cloudfront.net/countdown/${counter - 1}.wav`}
            autoPlay
          />
          <CounterDiv>
            <H1_TextBanner fontSize="60px" color={theme.pink4} fontWeight={700}>
              {counter}
            </H1_TextBanner>
          </CounterDiv>
          <StyledCancelButton onClick={onCancel}>
            {intl.formatMessage(screenRecordingSessionMessages.cancelButton)}
          </StyledCancelButton>
        </CounterWrapper>
      </ConditionalRender>
      <ConditionalRender condition={!mediaBlobUrl}>
        <ActionsButtonsFlexRow padding="5px">
          <Row align="middle">
            <div style={{ padding: "2.4px" }}>{recordTimer}</div>
            {recordExecuted && (
              <Button
                onClick={togglePause}
                type="text"
                icon={<i className={`fas ${paused ? "fa-record-vinyl" : "fa-pause"}`} />}
              />
            )}

            <Button
              onClick={onStop}
              type="text"
              icon={<H1_Icon color="red" icon="fas fa-stop" />}
            />
          </Row>
        </ActionsButtonsFlexRow>
      </ConditionalRender>
    </>
  );
};

export default ScreenRecordingSession;
