import { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { videosActions } from "app/store/slices/videos.slice";
import { VideoStatusEnum } from "app/types";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { extractUserIdForPusher } from "app/utils/helpers";

interface UpdateMessage {
  user_id: string;
  req_id: string;
  draft_id: string;
  url: string;
}
const THUMBNAIL_CHANNEL = "ThumbnailUpdate";

const PusherVideoProgressHandler = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuth();

  useEffect(() => {
    const handleProgress = (parsed: {
      status: VideoStatusEnum;
      progress: number;
      // eslint-disable-next-line camelcase
      req_id: string;
    }) => {
      dispatch(videosActions.updateVideoProgress(parsed));
    };
    const userId = extractUserIdForPusher(user?.sub);
    const channelName = `${userId}`;
    const channel = pusher.subscribe(channelName);
    const handleUpdateThumbnail = (message: UpdateMessage) => {
      dispatch(videosActions.updateVideoThumbnail(message));
    };
    channel.bind("pipeline_progress", handleProgress);
    channel.bind(THUMBNAIL_CHANNEL, handleUpdateThumbnail);
    return () => {
      if (channel) {
        channel.unbind("pipeline_progress", handleProgress);
        channel.unbind(THUMBNAIL_CHANNEL, handleUpdateThumbnail);
      }
    };
    //  its important to not have dependencies here ,
    //  because on each change use effect bind and unbind which can cause to miss messages
  }, []);

  return null;
};

export default PusherVideoProgressHandler;
