import { defineMessages } from "react-intl";

export const recorderMessages = defineMessages({
  recorderErrEnumeratingDevices: {
    id: "recorder.error.list-media",
    defaultMessage: "Error enumerating media devices"
  },
  recorderErrMinDuration: {
    id: "recorder.error.min-duration",
    defaultMessage: `Record should last at least {duration} seconds`
  },
  recorderErrAccessingCam: {
    id: "recorder.error.aceess-cam",
    defaultMessage: `Error accessing camera`
  }
});
