import React, { useState } from "react";
import Select, { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { LabelInValueType, RawValueType } from "rc-select/lib/Select";
import { mediaLibraryMessages } from "app/pages/mediaLibrary/StableDiffusion/messages";
import { useIntl } from "react-intl";
import { Tooltip } from "antd";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from "app/pages/mediaLibrary/StableDiffusion/DraggableTags";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const { Option, OptGroup } = Select;

export interface Modifier {
  title: string;
  values: string[];
  priority: number;
  icon: string;
  color: string;
}

const StyledSelect = styled(Select)`
  &&& {
    border: none;
    outline: none;
    margin: 2px;
    .ant-select-selector {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border: none;
      outline: none;
    }
  }
`;

const MediaLibraryTabSDGeneratePromptTagSelect = ({
  someModifiers,
  addTag,
  selected,
  onFocus
}: {
  someModifiers: Modifier[];
  addTag: (tag: string, color: string, icon: string) => void;
  selected: string[];
  // eslint-disable-next-line react/require-default-props
  onFocus?: () => void;
}) => {
  const [val, setVal] = useState<BaseOptionType | null>();
  const intl = useIntl();
  const msg = intl.formatMessage(mediaLibraryMessages.sdAddPromptTooltip);
  return (
    <Tooltip title={msg}>
      <StyledSelect
        size="small"
        placeholder={msg}
        defaultActiveFirstOption={false}
        value={val}
        suffixIcon={<PlusOutlined />}
        onFocus={onFocus}
        // @ts-ignore handels on select type missmatch
        onSelect={(
          value: RawValueType | LabelInValueType,
          option: BaseOptionType | DefaultOptionType
        ) => {
          addTag(option.value, option.color, option.icon);
          setVal(null);
        }}
      >
        {someModifiers.map((mod) => (
          <OptGroup
            key={mod.title.toString()}
            label={
              <H1_TextSmall color={mod.color} fontSize="14px" underline>
                <Icon className={mod.icon} /> {mod.title}
              </H1_TextSmall>
            }
          >
            {mod.values.map((value) => (
              <Option
                key={value}
                value={value}
                color={mod.color}
                icon={mod.icon}
                disabled={selected.includes(value)}
              >
                {value}
              </Option>
            ))}
          </OptGroup>
        ))}
      </StyledSelect>
    </Tooltip>
  );
};

export default MediaLibraryTabSDGeneratePromptTagSelect;
