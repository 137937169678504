import { useEffect } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { videosActions } from "app/store/slices/videos.slice";
import { FeatureFlag, PatchOperation, Video, VideoStatusEnum } from "app/types";
import { useIntl } from "react-intl";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import useCopy from "app/hooks/useCopy";
import { fetchingStatus } from "app/utils/helpers";
import useModal, { ModalName } from "app/hooks/useModal";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { publishVideoModalMessages } from "app/pages/editor/PublishVideoModal/messages";
import Config from "app/config/Config";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import ShareOptions from "app/components/VideoShareProtection/ShareOptions";
import ConditionalRender from "app/components/common/ConditionalRender";
import PublishVideoProtectionView from "./PublishVideoProtectionView";
import { VideoProtectionProvider } from "app/components/VideoShareProtection/ProtectionContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const PublishVideoModal = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { returnToModal, editorModalOpen } = useModal();
  const { formatMessage } = useIntl();
  const { triggerCopy } = useCopy();
  const flags = useFlags();
  const { videoId } = editorModalOpen?.context ?? {};
  const selectedVideoById: Video | undefined = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, videoId as string)
  );
  const publishVideoStatus = useAppSelector((state) => state.videos.publishVideoStatus);

  const visible = editorModalOpen?.open === ModalName.publishVideo;
  const currentReqId: string | undefined = selectedVideoById?.req_id;
  const draftId: string | undefined = selectedVideoById?.draft_id;
  const isPublishLoading = publishVideoStatus === fetchingStatus.loading;
  const isPublishSucceeded = publishVideoStatus === fetchingStatus.succeeded;
  const isPublishFailed = publishVideoStatus === fetchingStatus.failed;
  const showVideoUrl =
    selectedVideoById?.status === VideoStatusEnum.Published || flags[FeatureFlag.freeCopyLink];
  const url = `${Config.ogPlayerUrl}/${currentReqId}`;

  useEffect(() => {
    if (visible && publishVideoStatus) {
      if (isPublishSucceeded) {
        onCopy();
        dispatch(videosActions.updatePublishStatusToIdle());
      } else if (isPublishFailed) {
        dispatch(videosActions.updatePublishStatusToIdle());
      }
    }
  }, [publishVideoStatus]);

  useEffect(() => {
    // this use effect doing publish and depend only the selected video
    // visible not part of dependencies for not execute publish twice!
    if (!selectedVideoById || !visible) {
      return;
    }
    if (selectedVideoById?.status === VideoStatusEnum.Ready && !flags[FeatureFlag.freeCopyLink]) {
      dispatch(
        videosActions.publishVideoRequest({
          videoId: selectedVideoById?.id as string,
          reqId: selectedVideoById?.req_id as string,
          backModal: editorModalOpen?.backModal
        })
      );
      dispatch(
        googleEvents.shareVideoCopy({
          source: "modal",
          draftId,
          // eslint-disable-next-line camelcase
          req_id: selectedVideoById?.req_id,
          publish: false
        })
      );
    }
    //   here we listen only for the id because when we change the status all the instance is new and
    //   we dont want to trigger publish again
  }, [selectedVideoById?.id]);

  const onCopy = () => {
    triggerCopy({ copyContent: url, shouldNotify: true });
    dispatch(
      googleEvents.shareVideoCopy({
        source: "modal",
        draftId,
        req_id: currentReqId,
        publish: true
      })
    );
  };

  const onInput = () => {
    dispatch(
      googleEvents.shareVideoCopy({ source: "modal", draftId, req_id: currentReqId, publish: true })
    );
  };

  const onChangeAvailability = (isSelected: boolean) => {
    const operations = [{ op: "replace", path: "link_availability", value: isSelected }];
    dispatch(
      videosActions.updateVideoRequest({ videoId, operations: operations as PatchOperation[] })
    );
    dispatch(
      googleEvents.updateVideoAvailability({
        videoId,
        checked: isSelected
      })
    );
  };

  return (
    <StyledModal
      width="673px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ minHeight: "267px" }}
      onCancel={returnToModal}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="31px 41px 52px 47px"
      $alignItems="center"
    >
      <H1_FlexColumn gap="24px" width="100%">
        <H1_FlexRow justify="space-between" align="center">
          <H1_TextSubtitle color={theme.gray9}>
            {formatMessage(publishVideoModalMessages.copyLinkTitle)}
          </H1_TextSubtitle>
        </H1_FlexRow>
        <ConditionalRender condition={!!selectedVideoById && !!selectedVideoById.id}>
          <VideoProtectionProvider videoId={(selectedVideoById as Video)?.id}>
            <ShareOptions videoId={(selectedVideoById as Video)?.id} />
            <PublishVideoProtectionView
              onCopy={onCopy}
              onChangeAvailability={onChangeAvailability}
              onInput={onInput}
              url={url}
              isPublishLoading={isPublishLoading}
              showVideoUrl={showVideoUrl}
              linkAvailability={!!(selectedVideoById as Video)?.link_availability}
            />
          </VideoProtectionProvider>
        </ConditionalRender>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default PublishVideoModal;
