import { PlanEnum } from "app/types";

export enum PlanFeature {
  EmbedVideo = "embed_video",
  DownloadVideo = "download_video",
  PublishVideo = "publish_video",
  Comments = "comments",
  DownloadVideoFullHd = "DownloadVideoFullHd",
  LogoDraft = "LogoDraft",
  CustomColorPalette = "CustomColorPalette",
  IntroOutro = "IntroOutro",
  VoiceClone = "VoiceClone",
  buyVideo = "buy_video",
  useBrand = "use_brand",
  DownloadVideoSubtitles = "download_video_subtitles",
  ZoomBotMeeting = "zoom_bot_meeting",
  DraftTranslation = "draft_translation",
  VideoPasswordProtection = "video_password_protection",
  createAvatar = "create_avatar",
  uploadMusic = "upload_music",
  workflows = "workflows",
  mfa = "mfa", // Multi-Factor Authentication
  StartFromUrl = "start_from_url",
  ScormExport = "scorm_export",
  summaryMeeting = "summaryMeeting"
}

export const NotSupportedPlansPerFeatures: Record<PlanFeature, PlanEnum[]> = {
  [PlanFeature.EmbedVideo]: [PlanEnum.onBoarding],
  [PlanFeature.DownloadVideo]: [PlanEnum.onBoarding],
  [PlanFeature.StartFromUrl]: [PlanEnum.onBoarding],
  [PlanFeature.DraftTranslation]: [PlanEnum.onBoarding, PlanEnum.personal, PlanEnum.basic],
  [PlanFeature.VideoPasswordProtection]: [PlanEnum.onBoarding, PlanEnum.personal, PlanEnum.basic],
  [PlanFeature.DownloadVideoSubtitles]: [PlanEnum.onBoarding, PlanEnum.personal, PlanEnum.basic],
  [PlanFeature.DownloadVideoFullHd]: [PlanEnum.onBoarding],
  [PlanFeature.CustomColorPalette]: [PlanEnum.personal, PlanEnum.basic],
  [PlanFeature.IntroOutro]: [PlanEnum.onBoarding, PlanEnum.personal],
  [PlanFeature.LogoDraft]: [PlanEnum.onBoarding, PlanEnum.personal],
  [PlanFeature.useBrand]: [PlanEnum.onBoarding, PlanEnum.personal],
  [PlanFeature.VoiceClone]: [PlanEnum.onBoarding, PlanEnum.personal, PlanEnum.enterprise],
  [PlanFeature.ZoomBotMeeting]: [
    PlanEnum.onBoarding,
    PlanEnum.personal,
    PlanEnum.basic,
    PlanEnum.advanced,
    PlanEnum.business
  ],
  [PlanFeature.Comments]: [
    PlanEnum.onBoarding,
    PlanEnum.personal,
    PlanEnum.basic,
    PlanEnum.advanced
  ],
  [PlanFeature.PublishVideo]: [],
  [PlanFeature.buyVideo]: [],
  [PlanFeature.createAvatar]: [
    PlanEnum.onBoarding,
    PlanEnum.personal,
    PlanEnum.basic,
    PlanEnum.advanced
  ],
  [PlanFeature.uploadMusic]: [
    PlanEnum.onBoarding,
    PlanEnum.personal,
    PlanEnum.basic,
    PlanEnum.advanced,
    PlanEnum.business
  ],
  [PlanFeature.workflows]: [PlanEnum.basic, PlanEnum.pro, PlanEnum.advanced],
  [PlanFeature.ScormExport]: [
    PlanEnum.onBoarding,
    PlanEnum.personal,
    PlanEnum.basic,
    PlanEnum.advanced,
    PlanEnum.business
  ],
  [PlanFeature.mfa]: [
    PlanEnum.onBoarding,
    PlanEnum.personal,
    PlanEnum.basic,
    PlanEnum.advanced,
    PlanEnum.business
  ],
  [PlanFeature.summaryMeeting]: [PlanEnum.onBoarding]
};

export const DEFAULT_MAX_SCENES = 15;
export const DEFAULT_CHARS_PER_SCENE = 500;
