import styled from "styled-components";
import { Typography } from "antd";

export const NameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.gray11};
  font-size: 0.875rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  font-weight: 500;
  && {
    max-width: ${({ $maxWidth }: { $maxWidth: string }) => $maxWidth};
  }
`;
