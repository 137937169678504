import ConditionalRender from "app/components/common/ConditionalRender";
import WandLoader from "app/components/common/Layout/WandLoader";
import { useEffect } from "react";
import styled from "styled-components";
import { FetchStatus } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { scenesActions } from "app/store/slices/scenes.slice";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { ThemeMode } from "app/utils/theme";

const AugmentedTextOverlay = styled(H1_FlexRow)`
  z-index: 8;
  pointer-events: none;
  background: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)}d9;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
`;

const WandLoaderContainer = styled(H1_FlexRow)`
  --loaderSize: 70px;
  position: absolute;
  top: calc(50% - (var(--loaderSize) / 2));
  right: calc(50% - (var(--loaderSize) / 2));
`;

interface AugmentedSelectionContainerProps {
  sceneId: string;
}

const AugmentedSelectionContainer = ({ sceneId }: AugmentedSelectionContainerProps) => {
  const dispatch = useAppDispatch();

  const wandOverlaySceneId: string | undefined = useAppSelector(
    (state) => state.scenes.wandOverlaySceneId
  );
  const augmentedSceneStatus: FetchStatus = useAppSelector(
    (state) => state.scenes.augmentedSceneStatus
  );
  const generateTranscriptStatus: FetchStatus = useAppSelector(
    (state) => state.scenes.generateTranscriptStatus
  );
  const segmentToSceneStatus = useAppSelector((state) => state.drafts.segmentToSceneStatus);

  const isAugmentedSceneLoading = augmentedSceneStatus === fetchingStatus.loading;
  const isGenerateTranscriptStatusLoading = generateTranscriptStatus === fetchingStatus.loading;
  const isSegmentToSceneStatusLoading = segmentToSceneStatus === fetchingStatus.loading;

  const wandOverlayLoading =
    (isAugmentedSceneLoading ||
      isGenerateTranscriptStatusLoading ||
      isSegmentToSceneStatusLoading) &&
    wandOverlaySceneId === sceneId;

  useEffect(() => {
    if (wandOverlaySceneId === sceneId) {
      if (augmentedSceneStatus === fetchingStatus.succeeded) {
        dispatch(scenesActions.updateAugmentedSceneStatusToIdle());
      }
      if (augmentedSceneStatus === fetchingStatus.failed) {
        dispatch(scenesActions.updateAugmentedSceneStatusToIdle());
      }
    }
  }, [augmentedSceneStatus, wandOverlaySceneId, sceneId]);

  useEffect(() => {
    if (wandOverlaySceneId === sceneId) {
      if (generateTranscriptStatus === fetchingStatus.succeeded) {
        dispatch(scenesActions.updateGeneratedTranscriptStatusToIdle());
      }
      if (generateTranscriptStatus === fetchingStatus.failed) {
        dispatch(scenesActions.updateGeneratedTranscriptStatusToIdle());
      }
    }
  }, [generateTranscriptStatus, wandOverlaySceneId, sceneId]);

  return (
    <ConditionalRender condition={wandOverlayLoading}>
      <AugmentedTextOverlay width="100%" height="100%">
        <WandLoaderContainer
          width="var(--loaderSize)"
          height="var(--loaderSize)"
          justify="flex-end"
        >
          <WandLoader size="var(--loaderSize)" />
        </WandLoaderContainer>
      </AugmentedTextOverlay>
    </ConditionalRender>
  );
};

export default AugmentedSelectionContainer;
