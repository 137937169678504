import { FeatureFlag, Gender } from "app/types";
import styled, { useTheme } from "styled-components";
import { MenuProps } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { presentersPageMessages } from "app/pages/presenters/messages";
import { useEffect, useMemo } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useIntl } from "react-intl";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { charactersActions } from "app/store/slices/characters.slice";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { shallowEqual } from "react-redux";
import {
  CharacterAll,
  CharacterFiltersProperties,
  CharacterType,
  defaultCharacterFilter
} from "app/types/character";
import { useFlags } from "launchdarkly-react-client-sdk";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Button } from "@nextui-org/react";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { ThemeMode } from "app/utils/theme";

const LeftNavFlexColumn = styled(H1_FlexColumn)`
  top: 100px;
  left: 0;
  z-index: 3;
  position: sticky;
  align-self: flex-start;
`;

const SelectionText = styled(H1_TextSmall)<{ $selected?: boolean }>`
  font-weight: ${({ $selected }) => ($selected ? 700 : 400)};
  color: ${({ $selected, theme }) => ($selected ? theme.blue4 : theme.gray11)};
  cursor: pointer;
  &:hover {
    font-weight: 700;
    color: ${({ theme }) => theme.gray10};
  }
  &:active {
    color: ${({ theme }) => theme.gray9};
  }
`;

const CategoriesFlexRow = styled(H1_FlexColumn)`
  top: 50px;
  z-index: 3;
  opacity: 1;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${({ $scroll }: { $scroll: number }) =>
    $scroll < 200 ? "none" : "1px 3px 4px 0px rgba(0, 0, 0, 0.1)"};
`;

const Icon = styled.i<{ $color: string }>`
  color: ${({ $color }) => $color};
  padding-right: 5px;
`;

const BoxFlexRow = styled(H1_FlexRow)<{ $isColored?: boolean }>`
  border-radius: 50px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  padding: 3px 8px;
  border: 2px solid ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray1)};
  background-color: ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray1)};
  transition: transform 0.3s ease-in-out;
  &&& span,
  i {
    color: ${(props) =>
      props.$isColored
        ? props.theme.mode === ThemeMode.Light
          ? props.theme.gray1
          : props.theme.gray11
        : props.theme.gray9};
  }
`;

interface PresentersFiltersProps {
  scrollTop: number;
}
const PresentersFilters = ({ scrollTop }: PresentersFiltersProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const flags = useFlags();

  const { gender, age, hairStyle, clothing, type } = useAppSelector(
    (state) => state.characters.filters
  );
  const allUniqueOptions = useAppSelector(
    (state) =>
      CharacterSelectors.getUniqueDropdownValuesByProperties(
        state,
        [
          CharacterFiltersProperties.age,
          CharacterFiltersProperties.hairStyle,
          CharacterFiltersProperties.clothing
        ],
        _.capitalize(CharacterAll)
      ),
    shallowEqual
  );

  const ageAllOptions = allUniqueOptions[CharacterFiltersProperties.age];
  const hairStyleAllOptions = allUniqueOptions[CharacterFiltersProperties.hairStyle];
  const clothingStylesAllOptions = allUniqueOptions[CharacterFiltersProperties.clothing];

  useEffect(() => {
    onResetAll();
    return () => {
      onResetAll();
    };
  }, []);

  const genderText = useMemo(() => {
    let resultText: string;
    switch (gender) {
      case Gender.all:
        resultText = formatMessage(presentersPageMessages.gender);
        break;
      case Gender.male:
        resultText = formatMessage(presentersPageMessages.male);
        break;
      case Gender.female:
        resultText = formatMessage(presentersPageMessages.female);
        break;
      case undefined:
      default:
        resultText = formatMessage(presentersPageMessages.gender);
        break;
    }

    return resultText;
  }, [gender]);

  const onGenderChange = (selection: Gender | undefined) => {
    dispatch(
      analyticsEvents.changePresenterPageFilters({
        value: selection,
        filter: CharacterFiltersProperties.gender
      })
    );
    if (selection === gender || selection === Gender.all || !selection) {
      dispatch(charactersActions.setFilters({ gender: defaultCharacterFilter.gender }));
    } else {
      dispatch(charactersActions.setFilters({ gender: selection }));
    }
  };

  const onHairChange = (selection: string) => {
    dispatch(analyticsEvents.changePresenterPageFilters({ value: selection, filter: "hairStyle" }));
    if (selection === hairStyle || selection === CharacterAll) {
      dispatch(charactersActions.setFilters({ hairStyle: defaultCharacterFilter.hairStyle }));
    } else {
      dispatch(charactersActions.setFilters({ hairStyle: selection }));
    }
  };

  const onFilterByType = (selection?: CharacterType[]) => {
    dispatch(analyticsEvents.changePresenterPageFilters({ value: selection, filter: "type" }));
    if (selection?.every((element) => type?.includes(element)) || !selection) {
      dispatch(charactersActions.removeFilter("type"));
    } else {
      dispatch(charactersActions.setFilters({ type: selection }));
    }
  };

  const onResetAll = () => {
    dispatch(analyticsEvents.changePresenterPageFilters({ value: undefined, filter: "reset" }));
    dispatch(charactersActions.resetFilters());
  };

  const onClothingChange = (selection: string) => {
    dispatch(analyticsEvents.changePresenterPageFilters({ value: selection, filter: "clothing" }));
    if (selection === clothing || selection === CharacterAll) {
      dispatch(charactersActions.setFilters({ clothing: defaultCharacterFilter.clothing }));
    } else {
      dispatch(charactersActions.setFilters({ clothing: selection }));
    }
  };

  const onAgeChange = (selection: string) => {
    dispatch(analyticsEvents.changePresenterPageFilters({ value: selection, filter: "age" }));
    if (selection === age || selection === CharacterAll) {
      dispatch(charactersActions.setFilters({ age: defaultCharacterFilter.age }));
    } else {
      dispatch(charactersActions.setFilters({ age: selection }));
    }
  };

  const genderItems: MenuProps["items"] = useMemo(() => {
    const initialItems: MenuProps["items"] = [
      {
        className: "gender-menu clickable",
        key: "any-gender",
        onClick: () => onGenderChange(Gender.all),
        label: <H1_TextSmall>{formatMessage(presentersPageMessages.all)}</H1_TextSmall>
      },
      {
        className: "gender-menu clickable",
        key: "male",
        onClick: () => onGenderChange(Gender.male),
        label: <H1_TextSmall>{formatMessage(presentersPageMessages.male)}</H1_TextSmall>
      },
      {
        className: "gender-menu clickable",
        key: "landscape",
        onClick: () => onGenderChange(Gender.female),
        label: <H1_TextSmall>{formatMessage(presentersPageMessages.female)}</H1_TextSmall>
      }
    ];
    return initialItems;
  }, [genderText]);

  const hairItems: MenuProps["items"] = useMemo(() => {
    const initialItems: MenuProps["items"] = hairStyleAllOptions.map(
      (hairItem: { value: string; label: string }) => ({
        className: "hair-menu clickable",
        key: hairItem.value,
        onClick: () => onHairChange(hairItem.value),
        label: (
          <H1_TextSmall fontWeight={hairStyle === hairItem.value ? 700 : 400}>
            {hairItem.label}
          </H1_TextSmall>
        )
      })
    );
    return initialItems || [];
  }, [hairStyle, hairStyleAllOptions]);

  const clothingItems: MenuProps["items"] = useMemo(() => {
    const initialItems: MenuProps["items"] = clothingStylesAllOptions.map(
      (clothingItem: { value: string; label: string }) => ({
        className: "clothing-menu clickable",
        key: clothingItem.value,
        onClick: () => onClothingChange(clothingItem.value),
        label: (
          <H1_TextSmall fontWeight={clothing === clothingItem.value ? 700 : 400}>
            {clothingItem.label}
          </H1_TextSmall>
        )
      })
    );
    return initialItems || [];
  }, [clothing, clothingStylesAllOptions]);

  const ageItems: MenuProps["items"] = useMemo(() => {
    const initialItems: MenuProps["items"] = ageAllOptions.map(
      (ageItem: { value: string; label: string }) => ({
        className: "age-menu clickable",
        key: ageItem.value,
        onClick: () => onAgeChange(ageItem.value),
        label: (
          <H1_TextSmall fontWeight={age === ageItem.value ? 700 : 400}>
            {ageItem.label}
          </H1_TextSmall>
        )
      })
    );
    return initialItems || [];
  }, [age, ageAllOptions]);

  return (
    <CategoriesFlexRow
      $scroll={scrollTop}
      flex="0 0 75px"
      width="100%"
      position="sticky"
      gap="50px"
      align="center"
      padding="20px 45px"
    >
      <H1_FlexRow height="36px" flex="0 0 auto" justify="space-between" width="100%">
        <H1_FlexRow height="36px" flex="0 0 auto" align="center" gap="10px">
          <H1_TextSmall margin="0 9px 0 0" lineHeight="36px">
            {formatMessage(presentersPageMessages.filterBy)}
          </H1_TextSmall>
          <NextDropdown items={genderItems} withWhiteBackground>
            <BoxFlexRow
              width="auto"
              align="center"
              height="29px"
              $isColored={gender !== defaultCharacterFilter.gender}
              gap="10px"
            >
              <H1_TextSmall color={theme.gray9}>{genderText}</H1_TextSmall>
              <Icon
                $color={gender !== defaultCharacterFilter.gender ? theme.gray1 : theme.gray11}
                className="far fa-chevron-down"
              />
            </BoxFlexRow>
          </NextDropdown>
          <ConditionalRender condition={flags[FeatureFlag.avatarHair]}>
            <NextDropdown items={hairItems} withWhiteBackground>
              <BoxFlexRow
                gap="10px"
                width="auto"
                align="center"
                height="29px"
                $isColored={!!hairStyle}
              >
                <H1_TextSmall color={theme.gray9}>
                  {hairStyle !== defaultCharacterFilter.hairStyle
                    ? _.capitalize(hairStyle)
                    : formatMessage(presentersPageMessages.hair)}
                </H1_TextSmall>
                <Icon
                  $color={
                    hairStyle !== defaultCharacterFilter.hairStyle ? theme.gray1 : theme.gray11
                  }
                  className="far fa-chevron-down"
                />
              </BoxFlexRow>
            </NextDropdown>
          </ConditionalRender>
          <NextDropdown items={ageItems} withWhiteBackground>
            <BoxFlexRow
              gap="10px"
              width="auto"
              align="center"
              height="29px"
              $isColored={age !== defaultCharacterFilter.age}
            >
              <H1_TextSmall color={theme.gray9}>
                {age !== defaultCharacterFilter.age
                  ? _.capitalize(age)
                  : formatMessage(presentersPageMessages.age)}
              </H1_TextSmall>
              <Icon
                $color={age !== defaultCharacterFilter.age ? theme.gray1 : theme.gray11}
                className="far fa-chevron-down"
              />
            </BoxFlexRow>
          </NextDropdown>
          <NextDropdown items={clothingItems} withWhiteBackground>
            <BoxFlexRow
              gap="10px"
              width="auto"
              align="center"
              height="29px"
              $isColored={clothing !== defaultCharacterFilter.clothing}
            >
              <H1_TextSmall color={theme.gray9}>
                {clothing !== defaultCharacterFilter.clothing
                  ? _.capitalize(clothing)
                  : formatMessage(presentersPageMessages.clothing)}
              </H1_TextSmall>
              <Icon
                $color={clothing !== defaultCharacterFilter.clothing ? theme.gray1 : theme.gray11}
                className="far fa-chevron-down"
              />
            </BoxFlexRow>
          </NextDropdown>
        </H1_FlexRow>
        <Button color="primary" variant="light" onClick={onResetAll}>
          {formatMessage(presentersPageMessages.resetAll)}
        </Button>
      </H1_FlexRow>
      <LeftNavFlexColumn gap="12px" flex="0 0 130px" align="flex-start">
        <SelectionText onClick={() => onFilterByType()} $selected={!type}>
          {formatMessage(presentersPageMessages.all)}
        </SelectionText>
        <SelectionText
          onClick={() => onFilterByType([CharacterType.reals])}
          $selected={type?.includes(CharacterType.reals)}
        >
          {formatMessage(presentersPageMessages.studio)}
        </SelectionText>
        <SelectionText
          onClick={() => onFilterByType([CharacterType.mobile])}
          $selected={type?.includes(CharacterType.mobile)}
        >
          {formatMessage(presentersPageMessages.mobile)}
        </SelectionText>
        <SelectionText
          onClick={() => onFilterByType([CharacterType.desk, CharacterType.webcam])}
          $selected={type?.includes(CharacterType.desk) || type?.includes(CharacterType.webcam)}
        >
          {formatMessage(presentersPageMessages.desk)}
        </SelectionText>
      </LeftNavFlexColumn>
    </CategoriesFlexRow>
  );
};

export default PresentersFilters;
