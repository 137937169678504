import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import successImage from "app/assets/pricing/successChar.webp";
import SquaresImage from "app/assets/pricing/squares.png";
import { H1_TextSmall, H1_TextSubBanner } from "app/components/_Infrastructure/Typography";
import { paymentModalV2SuccessMessages } from "app/pages/pricing/PaymentModalV2/messages";
import useModal from "app/hooks/useModal";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppSelector } from "app/hooks";
import { PlanToUpgrade } from "app/types";
import Confetti from "react-confetti";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { Button } from "@nextui-org/react";

const FixedConfetti = styled(Confetti)`
  position: fixed !important; /* Must have important cause the position is on the style */
`;
const LeftFlexColumn = styled(H1_FlexColumn)`
  background-color: transparent;
  z-index: 1;
`;

const RightFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.blue1};
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const AbsoluteImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const CharacterImage = styled.img`
  position: absolute;
  z-index: 2;
  left: -150px; // The middle of the character should be on the separation of the modal
`;

const PaymentModalV2PhasePaymentSuccess = () => {
  const { closeModal } = useModal();
  const { formatMessage } = useIntl();
  const { selectedPlan } = useAppSelector((state) => state.payments.pricingModalProperties);
  const welcomeText =
    selectedPlan === PlanToUpgrade.personal
      ? formatMessage(paymentModalV2SuccessMessages.welcomeLite)
      : formatMessage(paymentModalV2SuccessMessages.welcomeBusiness);

  return (
    <H1_FlexRow height="100%" flex="1 1 auto" position="relative">
      <FixedConfetti />
      <AbsoluteImage width={798} height={668} alt="" src={SquaresImage} />
      <LeftFlexColumn
        padding="44px 39px 0"
        width="338px"
        flex="0 0 571px"
        gap="17px"
        position="relative"
      >
        <H1_TextSubBanner whiteSpace="break-spaces">{welcomeText}</H1_TextSubBanner>
        <H1_TextSmall whiteSpace="break-spaces">
          {formatMessage(paymentModalV2SuccessMessages.content)}
        </H1_TextSmall>
        <Button onClick={closeModal} color="primary">
          {formatMessage(paymentModalV2SuccessMessages.letsStart)}
        </Button>
      </LeftFlexColumn>
      <RightFlexColumn
        height="100%"
        flex="1 1 100%"
        justify="flex-end"
        align="center"
        position="relative"
      >
        <CharacterImage width={336} height={551} alt="" src={successImage} />
      </RightFlexColumn>
    </H1_FlexRow>
  );
};

export default PaymentModalV2PhasePaymentSuccess;
