/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/characters.thunk";
import { fetchingStatus } from "app/utils/helpers";
import { Favorite, FetchStatus } from "app/types";
import { charactersAdapter } from "app/store/adapters/adapters";
import { CharacterFilters, defaultCharacterFilter } from "app/types/character";

interface CharactersState {
  charactersStatus: FetchStatus;
  favoriteStatus: FetchStatus;
  changeCinematicCharactersStatus: FetchStatus;
  becomeCharacterStatus: FetchStatus;
  filters: Partial<CharacterFilters>;
  gestureSourceId?: string;
}
export const charactersSlice = createSlice({
  name: "Characters",
  initialState: charactersAdapter.getInitialState<CharactersState>({
    charactersStatus: fetchingStatus.idle as FetchStatus,
    changeCinematicCharactersStatus: fetchingStatus.idle as FetchStatus,
    favoriteStatus: fetchingStatus.idle as FetchStatus,
    becomeCharacterStatus: fetchingStatus.idle as FetchStatus,
    filters: defaultCharacterFilter
  }),
  reducers: {
    setBecomeCharacterStatusToIdle(state) {
      state.becomeCharacterStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    upsertCharacter(state, action) {
      charactersAdapter.upsertOne(state, action.payload);
      return state;
    },
    removeCharacter(state, action) {
      charactersAdapter.removeOne(state, action.payload);
      return state;
    },

    setFilters(state, action) {
      const {
        age,
        clothing,
        hairStyle,
        gender,
        type,
        myAvatars,
        publicAvatars,
        free,
        pro,
        gestures
      } = action.payload;
      if (age) {
        state.filters.age = age;
      }
      if (clothing) {
        state.filters.clothing = clothing;
      }
      if (hairStyle) {
        state.filters.hairStyle = hairStyle;
      }
      if (gender) {
        state.filters.gender = gender;
      }
      if (type) {
        const typeVal = Array.isArray(type) ? type : [type];
        state.filters.type = typeVal;
        // if (type === CharacterType.all || state.filters.type?.includes(CharacterType.all)) {
        // state.filters.type = typeVal;
        // } else if (state.filters.type?.length) {
        // state.filters.type.concat(...typeVal);
        // } else {
        // state.filters.type = typeVal;
        // }
      }
      if (pro !== undefined) {
        state.filters.pro = pro;
      }
      if (free !== undefined) {
        state.filters.free = free;
      }
      if (myAvatars !== undefined) {
        state.filters.myAvatars = myAvatars;
      }
      if (publicAvatars !== undefined) {
        state.filters.publicAvatars = publicAvatars;
      }
      if (gestures) {
        state.filters.gestures = gestures;
      }

      return state;
    },
    removeFilter(state, action) {
      const currentFilter = action.payload as keyof CharacterFilters;
      if (state.filters[currentFilter]) {
        state.filters[currentFilter] = undefined;
      }
      return state;
    },
    resetFilters(state) {
      const copyDefaultCharacterFilter = { ...defaultCharacterFilter };
      state.filters = copyDefaultCharacterFilter;
      return state;
    },
    setFavorite(state, action) {
      const { favorite, id } = action.payload;

      const character = state.entities[id];
      const entityIndex = state.ids.indexOf(id);

      if (character && entityIndex > -1) {
        character.favorite = favorite;
        if (favorite) {
          const newIds = [id, ...state.ids.filter((entityId) => entityId !== id)];
          state.ids = newIds;
        } else {
          const newIds = [...state.ids.filter((entityId) => entityId !== id), id];
          state.ids = newIds;
        }
      }
      return state;
    },
    setGestureCharacter(state, action) {
      const sourceId: string = action.payload;
      state.gestureSourceId = sourceId ? sourceId : undefined;
      return state;
    },
    setFavoritesBulk(state, action) {
      const favorites = action.payload;
      favorites.forEach((favorite: Favorite) => {
        const { asset_id, active } = favorite;
        const character = state.entities[asset_id];
        const entityIndex = state.ids.indexOf(asset_id);

        if (character && entityIndex > -1) {
          character.favorite = active;
          if (active) {
            const newIds = [asset_id, ...state.ids.filter((entityId) => entityId !== asset_id)];
            state.ids = newIds;
          } else {
            const newIds = [...state.ids.filter((entityId) => entityId !== asset_id), asset_id];
            state.ids = newIds;
          }
        }
      });
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getCharactersListRequest.fulfilled, (state, action) => {
      charactersAdapter.setAll(state, action.payload);
      state.charactersStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.getCharactersListRequest.pending, (state) => {
      state.charactersStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getCharactersListRequest.rejected, (state) => {
      state.charactersStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.becomeCharacterRequest.fulfilled, (state) => {
      state.becomeCharacterStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.becomeCharacterRequest.pending, (state) => {
      state.becomeCharacterStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.becomeCharacterRequest.rejected, (state) => {
      state.becomeCharacterStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getMobileCharactersListRequest.fulfilled, (state, action) => {
      charactersAdapter.setAll(state, action.payload);
      state.charactersStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getMobileCharactersListRequest.pending, (state) => {
      state.charactersStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getMobileCharactersListRequest.rejected, (state) => {
      state.charactersStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.setFavoritesBulkRequest.pending, (state) => {
      state.favoriteStatus = fetchingStatus.loading;
    });
    builder.addCase(asyncThunks.setFavoritesBulkRequest.rejected, (state) => {
      state.favoriteStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.setFavoritesBulkRequest.fulfilled, (state) => {
      state.favoriteStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.updateCharacterTitleRequest.pending, (state, action) => {
      const { id, title } = action.meta.arg;
      charactersAdapter.updateOne(state, { id, changes: { title } });
      return state;
    });
    builder.addCase(asyncThunks.deleteCharacterRequest.pending, (state, action) => {
      const { id } = action.meta.arg;
      charactersAdapter.removeOne(state, id);
      return state;
    });
    builder.addCase(asyncThunks.updateAttributesOnAllScenesRequest.fulfilled, (state) => {
      state.changeCinematicCharactersStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.updateAttributesOnAllScenesRequest.pending, (state) => {
      state.changeCinematicCharactersStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.updateAttributesOnAllScenesRequest.rejected, (state) => {
      state.changeCinematicCharactersStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});

export default charactersSlice.reducer;

export const charactersActions = {
  getCharactersListRequest: asyncThunks.getCharactersListRequest,
  getMobileCharactersListRequest: asyncThunks.getMobileCharactersListRequest,
  becomeCharacterRequest: asyncThunks.becomeCharacterRequest,
  setFavoritesBulkRequest: asyncThunks.setFavoritesBulkRequest,
  updateCharacterTitleRequest: asyncThunks.updateCharacterTitleRequest,
  deleteCharacterRequest: asyncThunks.deleteCharacterRequest,
  handleTalkingCharacterChanging: asyncThunks.handleTalkingCharacterChanging,
  updateAttributesOnAllScenesRequest: asyncThunks.updateAttributesOnAllScenesRequest,
  ...charactersSlice.actions
};
