import { createRoot } from "react-dom/client";
// eslint-disable-next-line absolute-imports/only-absolute-imports
import reportWebVitals from "./reportWebVitals";
// eslint-disable-next-line absolute-imports/only-absolute-imports
import App from "./App";
import { initializeSentry } from "app/services/sentry";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import Config from "app/config/Config";

initializeSentry();
const domNode = document.getElementById("root");
const root = createRoot(domNode as HTMLElement);
(async () => {
  const LDProvider = withLDProvider({
    clientSideID:
      Config.launchDarkly[Config.env as "production" | "test" | "development"] ||
      Config.launchDarkly.development
  })(App);

  root.render(<LDProvider />);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
