import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useIntl } from "react-intl";
import layoutSelectors from "app/store/selectorsV2/layout.selectors";
import { getSceneLayoutBySelectedSceneId } from "app/store/selectorsV2/scenes.selectors";
import { TemplateLayout } from "app/types/layout";
import { useAppSelector } from "app/hooks";
import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import styled, { useTheme } from "styled-components";
import ImageWithLoading from "app/components/common/ImageWithLoading";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useDispatch } from "react-redux";
import { sceneDrawerMessages } from "app/components/editor/sideDrawers/SceneDrawer/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { PlanEnum, Template } from "app/types";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import ConditionalRender from "app/components/common/ConditionalRender";
import PreviewTag from "app/components/common/PreviewTag";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import VideoWithHover from "app/components/common/VideoWithHover";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import DevModePopover from "app/components/DevModePopover";

const PreviewTagContainer = styled.div`
  position: absolute;
  right: 6px;
  text-transform: uppercase;
`;

const LayoutContentFlexColumn = styled(H1_FlexColumn)`
  flex: 1 1 100%;
  padding-bottom: 32px;
  opacity: 1;
  transition: flex 0.3s ease-in, opacity 0.3s ease-in;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    padding-bottom: 0;
  }
`;

const WrapFlexRow = styled(H1_FlexRow)`
  flex-wrap: wrap;
`;

const ImageFlexColumn = styled(H1_FlexColumn)`
  flex-wrap: wrap;
  margin: 0 auto;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    margin: 0 0 20px 20px;
    width: auto;
    max-width: 160px;
  }
`;
const ImageContainer = styled(H1_FlexColumn)<{ $selected?: boolean }>`
  text-align: center;
  border-radius: 8px;
  background: transparent;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
  border: 2px solid ${({ theme, $selected }) => ($selected ? theme.blue4 : "transparent")};

  img {
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
`;

const StyledVideoWithPoster = styled(VideoWithHover)`
  transition: 0.5s;
  background: ${({ theme }) => theme.gray5};
  border-radius: 6px;
  video {
    height: 83px;
  }
`;

const LayoutSection = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentPlan } = useCurrentPlan();
  const { isWriteWorkspaceAllow } = usePermissions();

  const allLayouts: TemplateLayout[] = useAppSelector(layoutSelectors.getCurrentTemplateLayouts);
  const sceneId = useAppSelector((state) => state.scenes.selectedSceneId) as string;
  const currentSceneLayout = useAppSelector(getSceneLayoutBySelectedSceneId);
  const [selectedLayoutId, setSelectedLayoutId] = useState<string>("");
  const currentTemplate: Template | undefined = useAppSelector(
    (state) => state.templates.currentTemplate
  );
  const developerMode = useAppSelector((state) => state.uiV2.developerMode);

  const renderConfig = currentTemplate?.render_config;
  const aspectRatio = renderConfig ? renderConfig.width / renderConfig.height : 16 / 9;

  useEffect(() => {
    if (currentSceneLayout?.id) {
      setSelectedLayoutId(currentSceneLayout?.id);
    }
  }, [currentSceneLayout?.id]);

  const onLayoutSelection = (layoutId: string, name: string) => {
    if (!isWriteWorkspaceAllow) {
      return;
    }
    setSelectedLayoutId(layoutId);
    dispatch(
      analyticsEvents.changeLayout({
        layout: {
          id: layoutId,
          name
        }
      })
    );
    dispatch(scenesActions.patchSceneLayout({ layoutId, sceneId: sceneId as string }));
  };

  return (
    <>
      {/* Layout selection */}
      <LayoutContentFlexColumn overflow="auto">
        <H1_TextSmall color={theme.gray9}>
          {intl.formatMessage(sceneDrawerMessages.layoutBottomTitle)}
        </H1_TextSmall>
        <WrapFlexRow padding="13px 22px 0 0" gap="20px">
          {allLayouts.map((item: TemplateLayout) => {
            const isPro =
              item.plans && item.plans.length
                ? !item?.plans.includes(currentPlan as PlanEnum)
                : false;
            return (
              <ImageFlexColumn
                width={aspectRatio >= 1 ? "calc(50% - 10px)" : "calc(33.33% - 10px)"}
                key={item.id}
              >
                <H1_FlexColumn onClick={() => !isPro && onLayoutSelection(item.id, item.name)}>
                  <Tooltip
                    placement="top"
                    title={
                      isPro ? intl.formatMessage(sceneDrawerMessages.premiumLayoutTooltip) : ""
                    }
                  >
                    <ImageContainer overflow="hidden" $selected={item.id === selectedLayoutId}>
                      <DevModePopover value={{ layout_id: item.id }} path={"scenes[#]"} />

                      <ConditionalRender condition={!developerMode && isPro}>
                        <PreviewTagContainer>
                          <PreviewTag icon={<CrownIcon />} background={theme.orange3}>
                            {String(item?.plans?.[0]).toUpperCase()}
                          </PreviewTag>
                        </PreviewTagContainer>
                      </ConditionalRender>
                      <ConditionalRender condition={!!item.video}>
                        <StyledVideoWithPoster
                          image={item.image}
                          disableIcon
                          video={item.video}
                          autoPlay={false}
                          shouldRestartOnLeave
                        />
                      </ConditionalRender>
                      <ConditionalRender condition={!item.video && !!item.image}>
                        <ImageWithLoading height="100%" image={item.image as string} />
                      </ConditionalRender>
                    </ImageContainer>
                  </Tooltip>
                </H1_FlexColumn>
              </ImageFlexColumn>
            );
          })}
        </WrapFlexRow>
      </LayoutContentFlexColumn>
    </>
  );
};

export default LayoutSection;
