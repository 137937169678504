import animationData from "app/assets/lottie/wand.json";
import Lottie from "lottie-react";

import styled from "styled-components";

const StyledLottie = styled(Lottie)`
  height: ${(props: { sizeprop: string }) => props.sizeprop};
  width: ${(props: { sizeprop: string }) => props.sizeprop};
`;

interface WandLoaderProps {
  size?: string;
}

const WandLoader = ({ size }: WandLoaderProps) => {
  return <StyledLottie sizeprop={size || "100%"} loop autoplay animationData={animationData} />;
};

export default WandLoader;
