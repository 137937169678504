import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Video, VideoStatusEnum } from "app/types";
import { defineMessages, useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import Config from "app/config/Config";
import EmbedPanel from "app/components/editor/sideDrawers/RenderPanel/EmbedPanel";
import ConditionalRender from "app/components/common/ConditionalRender";
import GoBackButton from "app/components/common/GoBackButton";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { videosActions } from "app/store/slices/videos.slice";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const messages = defineMessages({
  embeddedVideo: {
    id: `embed-video-modal.embedded-video`,
    defaultMessage: `Embedded Video`
  }
});

const VideoPreview = styled(H1_FlexRow)`
  background-color: transparent;
  aspect-ratio: 16/9;
  min-width: 610px;
`;

const EmbedVideoModal = () => {
  const [embeddedUrl, setEmbeddedUrl] = useState<string>(``);
  const [allowedIframeFeatures, setAllowedIframeFeatures] = useState<string>(``);
  const dispatch = useAppDispatch();
  const { closeModal, editorModalOpen, returnToModal } = useModal();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { videoId } = editorModalOpen?.context || {};
  const { backModal } = editorModalOpen || {};
  const selectedVideoById: Video | undefined = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, videoId as string)
  );
  const visible = editorModalOpen?.open === ModalName.embeddedVideo;
  const isVideoPublished = selectedVideoById?.status === VideoStatusEnum.Published;

  useEffect(() => {
    if (selectedVideoById) {
      setEmbeddedUrl(`${Config.frontoHost}/e?id=${selectedVideoById?.req_id}`);
    }
  }, [selectedVideoById?.id]);

  useEffect(() => {
    // this use effect doing publish and depend only the selected video
    // visible not part of dependices for not execute publish twice!
    if (!selectedVideoById || !visible) {
      return;
    }
    if (selectedVideoById?.status === VideoStatusEnum.Ready) {
      dispatch(
        videosActions.publishVideoRequest({
          videoId: selectedVideoById?.id,
          reqId: selectedVideoById?.req_id as string,
          backModal
        })
      );
    }
    //   here we listen only for the id because when we change the status all the instance is new and
    //   we dont want to trigger publish again
  }, [selectedVideoById?.id]);

  const onClickBack = () => {
    if (backModal) {
      returnToModal();
    }
  };

  return (
    <StyledModal
      width="1055px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "483px" }}
      onCancel={closeModal}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="31px 41px 52px 47px"
      $alignItems="center"
      $minHeight="483px"
    >
      <H1_FlexColumn gap="23px" width="100%" flex="1 1 auto">
        <H1_FlexRow align="center">
          <ConditionalRender condition={backModal}>
            <GoBackButton onClick={onClickBack} fontSize="12px" />
          </ConditionalRender>
          <H1_TextSubtitle color={theme.gray10}>
            {formatMessage(messages.embeddedVideo)}
          </H1_TextSubtitle>
        </H1_FlexRow>

        <ConditionalRender condition={isVideoPublished}>
          <H1_FlexRow gap="46px" width="100%" height="100%">
            <VideoPreview flex="1 1 610px" align="center" justify="center">
              <iframe
                width="100%"
                height="100%"
                title="embedded-url"
                name="player"
                src={embeddedUrl}
                allow={allowedIframeFeatures}
              />
            </VideoPreview>
            <H1_FlexColumn flex="1 1 311px" width="311px">
              <EmbedPanel
                showCopyButton
                url={`${Config.frontoHost}/e?id=${selectedVideoById?.req_id}`}
                onEmbeddedUrlChange={setEmbeddedUrl}
                onAllowedIframeFeaturesChanged={setAllowedIframeFeatures}
                videoId={selectedVideoById?.id as string}
              />
            </H1_FlexColumn>
          </H1_FlexRow>
        </ConditionalRender>

        <ConditionalRender condition={!isVideoPublished}>
          <H1_FlexRow height="100%" align="center" justify="center">
            <CircleLoader />
          </H1_FlexRow>
        </ConditionalRender>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default EmbedVideoModal;
