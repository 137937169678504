import React, { ReactNode, useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { paymentModalMessages } from "app/pages/pricing/messages";
import { useIntl } from "react-intl";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { useAuth } from "app/auth/useAuth";
import * as stripeJs from "@stripe/stripe-js";
import { SetupIntentResult } from "@stripe/stripe-js";
import { Button, Spin } from "antd";
import { useForm } from "react-hook-form";
import { paymentsActions } from "app/store/slices/payments.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { PaymentFormFields } from "app/types";
import PaymentModalLeftSide from "app/pages/pricing/PaymentModal/PaymentModalLeftSide";
import PaymentModalHeadlinePoppinsText from "app/pages/pricing/PaymentModal/PaymentModalHeadlinePoppinsText";
import PaymentModalLowerRow from "app/pages/pricing/PaymentModal/PaymentModalLowerRow";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as Sentry from "@sentry/react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  flex: 1 1 auto;
`;

const StyledArrowLeftOutlined = styled(ArrowLeftOutlined)`
  cursor: pointer;
  align-self: flex-start;
`;

const PageFlexRow = styled(H1_FlexRow)`
  .ant-spin-container {
    height: 100%;
  }
`;

const BorderBottomFlexRow = styled(H1_FlexRow)`
  border-bottom: 1px solid ${(props) => props.theme.gray3};
  padding-bottom: 14px;
  margin-bottom: 4px;
`;

const ErrorFlexRow = styled(H1_FlexRow)`
  background-color: #f8d3d4; /* Or said its a new color for now */
  padding-left: 12px;
  min-height: 32px;
`;

const ErrorText = styled(H1_TextXs)`
  height: 100%;
  align-items: center;
`;

const BorderedFlexRow = styled(H1_FlexRow)`
  background-color: ${(props) => props.theme.gray3};
  border: 1px solid ${(props) => props.theme.gray5};
  border-radius: 4px;
  padding: 10px 8px;
  box-sizing: border-box;
  height: 40px;
  position: relative;
  ${({ error }: { error?: boolean }) =>
    error &&
    css`
      border-color: ${(props) => props.theme.gray11};
    `};
`;

const PaymentButton = styled(Button)`
  height: 36px;
  border-radius: 8px;
  margin-left: 47px;
  background-color: #40a9ff;
  color: ${(props) => props.theme.gray3};

  &:hover,
  &:focus {
    background-color: #40a9ff;
    color: ${(props) => props.theme.gray3};
    opacity: 0.8;
  }

  &.ant-btn[disabled] {
    background-color: #40a9ff;
    color: ${(props) => props.theme.gray3};
    border: 1px solid transparent;
  }
`;

const StyledCardNumberElement = styled(CardNumberElement)`
  width: 100%;
`;

const ExpiryIcon = styled.i`
  padding-left: 5px;
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  width: calc(50% - 8px);
  position: absolute;
  left: calc(2em + 8px);
`;

const StyledCardCvcElement = styled(CardCvcElement)`
  width: calc(50% - 8px);
`;

const NoBorderInput = styled.input`
  width: 100%;
  outline-color: transparent;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding-left: 0;
  box-sizing: border-box;
  margin-left: 2em;
`;

interface CreditCardDetailsContentProps {
  price: number | ReactNode;
  loading?: boolean;
  title?: string;
  secret?: string;
  error?: string;
  confirmText: string | ReactNode;
  onBack?: () => void;
  onFinish: () => void;
  onSetupFinish: (setupResult: SetupIntentResult) => void;
}

const CreditCardDetailsContent = ({
  price,
  loading,
  onBack,
  title,
  secret,
  onFinish,
  confirmText,
  error,
  onSetupFinish
}: CreditCardDetailsContentProps) => {
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();
  const [cardHolderName, setCardHolderName] = useState<string>("");
  const [postal, setPostal] = useState<string>("");

  const [isStripeLoading, setIsStripeLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: "onBlur"
  });
  const theme = useTheme();
  const elements = useElements();
  const { user } = useAuth();

  const setupResult = useAppSelector((state) => state.payments.setupResult);
  const setupStatus = useAppSelector((state) => state.payments.setupStatus);
  const setupStatusLoading = setupStatus === fetchingStatus.loading;
  const allLoading = loading || isStripeLoading;

  useEffect(() => {
    dispatch(paymentsActions.setupRequest());
  }, []);

  useEffect(() => {
    if (paymentErrorMessage) {
      dispatch(paymentsActions.setupRequest());
    }
  }, [paymentErrorMessage]);

  useEffect(() => {
    if (error) {
      setPaymentErrorMessage(error);
      setIsStripeLoading(false);
    }
  }, [error]);

  useEffect(() => {
    (async () => {
      if (secret) {
        try {
          const result = await stripe?.confirmCardPayment(secret as string);
          if (result?.error) {
            Sentry.captureMessage("failed to confirm payment", {
              extra: { result: result?.error }
            });
            setPaymentErrorMessage(result.error.message);
          } else if (result?.paymentIntent?.status === "succeeded") {
            onFinish();
            setIsStripeLoading(false);
          } else {
            setPaymentErrorMessage("Unable to authenticate credit card");
            Sentry.captureMessage("unable to confirm payment", { extra: { result } });
          }
        } catch (err) {
          setPaymentErrorMessage("Unable to authenticate credit card");
          Sentry.captureException(err, {
            extra: {
              description: "exception on confirm payment"
            }
          });
        } finally {
          setIsStripeLoading(false);
        }
      }
    })();
  }, [secret]);

  const handlePostalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setPostal(inputValue);
    if (inputValue && errors.postal) {
      clearErrors(PaymentFormFields.postal);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setCardHolderName(inputValue);
    if (inputValue && errors.cardHolderName) {
      clearErrors(PaymentFormFields.cardHolderName);
    }
  };

  const handleSetupResult = (setupIntent: SetupIntentResult) => {
    if (setupIntent.setupIntent?.status === "succeeded") {
      setPaymentErrorMessage(undefined);
      onSetupFinish(setupIntent);
    } else if (setupIntent.error) {
      Sentry.captureException(setupIntent.error);
      console.error(setupIntent.error.message);
      setPaymentErrorMessage(setupIntent.error.message);
      setIsStripeLoading(false);
    }
  };

  const confirmCardPayment = async () => {
    const cardNumberElement = elements?.getElement(
      CardNumberElement
    ) as stripeJs.StripeCardNumberElement;
    const cardExpiryElement = elements?.getElement(
      CardExpiryElement
    ) as stripeJs.StripeCardExpiryElement;
    const cardCvcElement = elements?.getElement(CardCvcElement) as stripeJs.StripeCardCvcElement;
    try {
      cardNumberElement.update({ disabled: true });
      cardExpiryElement.update({ disabled: true });
      cardCvcElement.update({ disabled: true });
      setIsStripeLoading(true);
      const result = await stripe?.confirmCardSetup(setupResult?.secret as string, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: cardHolderName,
            email: user?.email as string,
            address: {
              postal_code: postal
            }
          }
        }
      });
      handleSetupResult(result as SetupIntentResult);
    } catch (e: any) {
      const message = e?.message?.split("\n")[0];
      Sentry.captureException(e);
      console.error(e);
      setPaymentErrorMessage(message);
      setIsStripeLoading(false);
    } finally {
      cardNumberElement.update({ disabled: false });
      cardExpiryElement.update({ disabled: false });
      cardCvcElement.update({ disabled: false });
    }
  };

  const onSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    await confirmCardPayment();
  };

  return (
    <PageFlexRow height="100%" flex="1 1 auto">
      <PaymentModalLeftSide />
      <Spin spinning={allLoading}>
        <H1_FlexColumn height="100%" width="423px" flex="1 0 auto" justify="space-between">
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <H1_FlexColumn padding="46px 0 0 49px" width="100%" gap="16px" flex="1 1 auto">
              <ConditionalRender condition={!!onBack}>
                <StyledArrowLeftOutlined onClick={onBack} />
              </ConditionalRender>
              <PaymentModalHeadlinePoppinsText title={title} />
              {/* Payment Details Header */}
              <BorderBottomFlexRow justify="space-between" height="24px" align="center" gap="10px">
                <H1_TextSmall
                  lineHeight="18px"
                  color="var(--ho-secondary, #0f0c1d)"
                  fontWeight={700}
                >
                  {formatMessage(paymentModalMessages.paymentDetails)}
                </H1_TextSmall>
                <H1_TextMiddle fontSize="18px" lineHeight="18px" color={theme.gray11}>
                  {price}
                </H1_TextMiddle>
              </BorderBottomFlexRow>
              {/* Error Message */}
              <ConditionalRender condition={!!paymentErrorMessage}>
                <ErrorFlexRow height="100%" align="center">
                  <ErrorText
                    fontSize="12px"
                    lineHeight="24px"
                    color={theme.gray11}
                    whiteSpace="break-spaces"
                  >
                    {paymentErrorMessage}
                  </ErrorText>
                </ErrorFlexRow>
              </ConditionalRender>
              {/* Cardholder Name */}
              <BorderedFlexRow align="center" error={!!errors.cardHolderName}>
                <NoBorderInput
                  {...register(PaymentFormFields.cardHolderName, { required: true })}
                  placeholder={formatMessage(paymentModalMessages.cardholderNamePlaceholder)}
                  onChange={handleNameChange}
                />
              </BorderedFlexRow>
              {/* Card Number */}
              <BorderedFlexRow align="center" gap="10px">
                <StyledCardNumberElement options={{ showIcon: true }} />
              </BorderedFlexRow>
              {/* Card Expiry && Card CVC */}
              <H1_FlexRow gap="16px">
                <BorderedFlexRow align="center" flex="1" gap="10px">
                  <ExpiryIcon className="fal fa-calendar" />
                  <StyledCardExpiryElement />
                </BorderedFlexRow>
                <BorderedFlexRow align="center" flex="1" gap="10px">
                  <i className="fal fa-calendar" />
                  <StyledCardCvcElement />
                </BorderedFlexRow>
              </H1_FlexRow>
              {/* Postal Code */}
              <BorderedFlexRow align="center" error={!!errors.postal}>
                <NoBorderInput
                  {...register(PaymentFormFields.postal, { required: false })}
                  placeholder={formatMessage(paymentModalMessages.postalCodePlaceholder)}
                  onChange={handlePostalChange}
                />
              </BorderedFlexRow>
            </H1_FlexColumn>
            <H1_FlexColumn gap="16px" flex="0 0 auto">
              <PaymentButton
                htmlType="submit"
                loading={allLoading}
                disabled={!stripe || allLoading || setupStatusLoading}
              >
                {confirmText}
              </PaymentButton>
              <PaymentModalLowerRow />
            </H1_FlexColumn>
          </StyledForm>
        </H1_FlexColumn>
      </Spin>
    </PageFlexRow>
  );
};

export default CreditCardDetailsContent;
