import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import styled from "styled-components";

import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React from "react";

const UpgradeIcon = styled(H1_Icon)`
  border-radius: 2px;
`;

const buildLlmWarningMessage = (
  message = "",
  description: string | React.ReactNode,
  type = "alert",
  btn: React.ReactNode
) => {
  return {
    type,
    message: (
      <H1_FlexRow gap="10px">
        <UpgradeIcon
          icon="fa-sharp fa-solid fa-wand-magic-sparkles"
          backgroundColor="#FFAF4D"
          color="white"
          padding="8px"
          size="15px"
        />
        {message}
      </H1_FlexRow>
    ),
    description,
    btn
  };
};

export default buildLlmWarningMessage;
