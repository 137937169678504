import { PaymentPeriod, PlanToUpgrade } from "app/types";
import { useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";
import { useMemo } from "react";
import { RootState } from "app/store/store";
import { paymentModalV2InitialMessages } from "app/pages/pricing/PaymentModalV2/messages";
import {
  getBusinessMonthlyPlan,
  getBusinessYearlyPlan,
  getPersonalMonthlyPlan,
  getPersonalYearlyPlan
} from "app/store/selectorsV2/payments.selectors";

const usePricingSinglePlan = ({
  paymentPeriod,
  numberOfMinutesPosition
}: {
  paymentPeriod: PaymentPeriod;
  numberOfMinutesPosition?: number;
}) => {
  const { formatMessage } = useIntl();
  const pricingAndPlans = useAppSelector((state: RootState) => state.payments.pricingAndPlans);
  const planBusinessMonthly = useAppSelector(getBusinessMonthlyPlan);
  const planBusinessYearly = useAppSelector(getBusinessYearlyPlan);
  const planPersonalMonthly = useAppSelector(getPersonalMonthlyPlan);
  const planPersonalYearly = useAppSelector(getPersonalYearlyPlan);

  const singlePlans = useMemo(() => {
    return {
      [PlanToUpgrade.personal]: {
        name: formatMessage(paymentModalV2InitialMessages.personal),
        priceMonthlyTotal: planPersonalMonthly?.price,
        priceMonthlyPerMonth: planPersonalMonthly?.price_per_month,
        priceYearlyPerMonth: planPersonalYearly?.price_per_month,
        priceYearlyTotal: planPersonalYearly?.price,
        whatsIncluded: formatMessage(paymentModalV2InitialMessages.whatsIncluded),
        description: formatMessage(paymentModalV2InitialMessages.personalDescription),
        priceDescription: () =>
          paymentPeriod === PaymentPeriod.Monthly
            ? formatMessage(paymentModalV2InitialMessages.personalPriceDescriptionMonthly)
            : formatMessage(paymentModalV2InitialMessages.personalPriceDescriptionYearly),
        headerFeatures: [
          { text: formatMessage(paymentModalV2InitialMessages.videoDownloads) },
          { text: formatMessage(paymentModalV2InitialMessages.watermark) },
          { text: formatMessage(paymentModalV2InitialMessages.all2dTemplates) },
          { text: formatMessage(paymentModalV2InitialMessages.scriptVideoWizard) },
          { text: formatMessage(paymentModalV2InitialMessages.liteSeats) }
        ]
      },
      [PlanToUpgrade.business]: {
        name: formatMessage(paymentModalV2InitialMessages.business),
        priceMonthlyTotal: planBusinessMonthly?.price,
        priceMonthlyPerMonth: planBusinessMonthly?.price_per_month,
        priceYearlyPerMonth: planBusinessYearly?.price_per_month,
        priceYearlyTotal: planBusinessYearly?.price,
        whatsIncluded: formatMessage(paymentModalV2InitialMessages.whatsIncludedBusiness),
        description: formatMessage(paymentModalV2InitialMessages.businessDescription),
        priceDescription: (numberOfMinutesPosition: number) =>
          paymentPeriod === PaymentPeriod.Monthly
            ? formatMessage(paymentModalV2InitialMessages.businessPriceMonthly, {
                number: numberOfMinutesPosition
              })
            : formatMessage(paymentModalV2InitialMessages.businessPriceYearly, {
                number: numberOfMinutesPosition * 12
              }),
        headerFeatures: [
          { text: formatMessage(paymentModalV2InitialMessages.template3dPremium) },
          { text: formatMessage(paymentModalV2InitialMessages.brandKit) },
          { text: formatMessage(paymentModalV2InitialMessages.teamsSeats5) },
          { text: formatMessage(paymentModalV2InitialMessages.support) },
          { text: formatMessage(paymentModalV2InitialMessages.improvedRendering) }
        ]
      },
      [PlanToUpgrade.enterprise]: {
        name: formatMessage(paymentModalV2InitialMessages.enterprise),
        priceMonthlyTotal: 0,
        priceMonthlyPerMonth: "",
        priceYearlyPerMonth: "",
        priceYearlyTotal: 0,
        description: formatMessage(paymentModalV2InitialMessages.enterpriseDescription),
        priceDescription: () =>
          formatMessage(paymentModalV2InitialMessages.enterprisePriceDescription),
        whatsIncluded: formatMessage(paymentModalV2InitialMessages.whatsIncludedEnterprise),
        headerFeatures: [
          { text: formatMessage(paymentModalV2InitialMessages.totalCustomization) },
          { text: formatMessage(paymentModalV2InitialMessages.userAccessManagement) },
          { text: formatMessage(paymentModalV2InitialMessages.dedicatedCS) },
          { text: formatMessage(paymentModalV2InitialMessages.priorityRendering) },
          { text: formatMessage(paymentModalV2InitialMessages.customNumberOfUsers) }
        ]
      }
    };
  }, [formatMessage, pricingAndPlans, paymentPeriod, numberOfMinutesPosition]);

  return { singlePlans };
};

export default usePricingSinglePlan;
