import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "app/components/projects-managment/projectsManagment.scss";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { FlatRecipe, Location } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { draftsActions } from "app/store/slices/drafts.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import { TablePaginationConfig } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import DeleteFolderModal from "app/pages/FoldersPage/DeleteFolderModal";
import PageTableSkeleton from "app/components/common/DraftsAndFolders/PageTableSkeleton";
import PageGridSkeleton from "app/components/common/DraftsAndFolders/PageGridSkeleton";
import DraftsTable from "app/components/common/DraftsAndFolders/DraftsTable";
import useDropdownHandler from "app/hooks/useDropdownHandler";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { Swiper, SwiperSlide } from "swiper/react";
import SingleTemplateInCollectionSwiper from "./SingleTemplateInCollectionSwiper";
import { recipesActions } from "app/store/slices/recipes.slice";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray1};
`;

const StyledSwiper = styled(Swiper)`
  position: static;
  overflow: hidden;
  padding-bottom: 5px;
  .swiper-slide {
    width: calc(160px * 16 / 9);
    height: 400px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 94px;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.gray5};
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(64 87 109 / 7%);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${(props) => props.theme.gray8};
    background-color: ${(props) => props.theme.gray2};
    transition-property: border-color, color;
    transition-duration: 0.2s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    svg {
      fill: ${(props) => props.theme.gray8};
      width: 16px;
      height: 16px;
    }
    &.swiper-button-disabled {
      pointer-events: auto;
    }
    &:hover {
      border-color: ${(props) => props.theme.gray5};
    }
    &:active {
      transform: scale(0.95);
    }
    &::after {
      content: "";
    }
  }
  .swiper-button-prev {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-left.svg");
    left: 30px;
  }

  .swiper-button-next {
    background-image: url("https://df6g5g0b3bt51.cloudfront.net/reals-static-files/Arrows/angle-right.svg");
    right: 15px;
  }
`;

const MAX_NUM_OF_SLIDES = 6;
const TEMPLATE_WIDTH = (160 * 16) / 9;
const SPACE_BETWEEN_TEMPLATES = 28;

const WorkflowsPage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  useDropdownHandler({
    goFoldersOnDelete: false,
    goDraftOnDuplicate: false,
    activeApiExampleHandler: true
  });
  const flattenRecipes = useAppSelector((state) => state.recipes.workflowRecipes) as FlatRecipe[];

  const numOfSlides = useMemo(() => {
    if (ref?.current && flattenRecipes.length > 0) {
      // @ts-ignore handels intl formatting with elements issue
      const width = ref?.current?.offsetWidth;
      return Math.floor((width - 100) / (TEMPLATE_WIDTH + SPACE_BETWEEN_TEMPLATES)) || 1;
    }
    return MAX_NUM_OF_SLIDES;
  }, [ref?.current, flattenRecipes.length]);

  const preferences = useAppSelector((state) => state.user.preferences);

  const folderStatus = useAppSelector((state) => state.folders.folderContentStatus);
  const draftsStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const isLoading =
    draftsStatus === fetchingStatus.loading || folderStatus === fetchingStatus.loading;
  const isGrid = preferences.draft_page_grid;

  useEffect(() => {
    dispatch(draftsActions.getDraftsRequest({ type: "workflow", limit: 100, page: 1 }));
    dispatch(recipesActions.getRecipesWorkflowsRequest());
    return () => {
      dispatch(draftsActions.resetDraftStore());
    };
  }, []);

  const onReachEnd = () => {
    setCurrentPage(currentPage + 1);
  };

  const onCreateNewVideo = () => {
    dispatch(
      analyticsEvents.newVideo({
        source: "empty-folder",
        type: NewVideoType.video
      })
    );
    navigate(Location.Templates);
  };

  return (
    <BackgroundFlexColumn padding="1rem 0 1.875rem 1.875rem" flex="1" overflow="auto" ref={ref}>
      <H1_FlexColumn flex="1">
        <ConditionalRender condition={isLoading && isGrid}>
          <PageGridSkeleton />
        </ConditionalRender>
        <ConditionalRender condition={isLoading && !isGrid}>
          <PageTableSkeleton />
        </ConditionalRender>

        <ConditionalRender condition={!isLoading}>
          <H1_FlexRow
            width="100%"
            position="relative"
            padding="10px 30px"
            overflow="hidden"
            flex="0 0 230px"
            data-auto-id="workflow-swiper"
          >
            <StyledSwiper
              navigation
              speed={700}
              spaceBetween={28}
              slidesPerView="auto"
              slidesPerGroup={numOfSlides}
              onReachEnd={onReachEnd}
              lazy
            >
              {flattenRecipes.map((recipe: FlatRecipe) => (
                <SwiperSlide key={`${recipe.categoryName}_${recipe.id}`}>
                  <SingleTemplateInCollectionSwiper recipe={recipe} />
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </H1_FlexRow>
          <H1_FlexColumn overflow="auto" flex="1 1 auto" padding="0 1.875rem 0 0">
            <DraftsTable
              type="workflow"
              hideOptions={true}
              hideEmptyState
              hidePlayColumn
              onCreateNewVideo={onCreateNewVideo}
              pagination={{ position: ["bottomCenter"], size: "default" } as TablePaginationConfig}
            />
            {/*<ConditionalRender condition={isGrid}>*/}
            {/*  <DraftsGrid onCreateNewVideo={onCreateNewVideo} source="draftsPage" />*/}
            {/*</ConditionalRender>*/}
          </H1_FlexColumn>
        </ConditionalRender>
      </H1_FlexColumn>
      <DeleteFolderModal />
    </BackgroundFlexColumn>
  );
};
export default WorkflowsPage;
