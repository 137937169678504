import styled, { css } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

const MediaItemWrapper = styled(H1_FlexColumn)<{ selected?: boolean; $folderLayout: boolean }>`
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${({ theme }) => `${theme.blue1}33`};
    transition: 0.5s ease;
    opacity: 0;
  }
  &:hover:after {
    opacity: 1;
  }

  transition: transform 0.3s ease-in-out;
  background-color: ${({ theme, $folderLayout }) =>
    $folderLayout ? `${theme.blue1}33` : `${theme.gray2}`};
  padding: 12px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => `${theme.blue1}33`};
    `};
`;

export default MediaItemWrapper;
