import { defineMessages } from "react-intl";

export const fileUploaderMessages = defineMessages({
  tooLargeTitle: {
    id: `file-uploader.file-to-large`,
    defaultMessage: `The file is too large`
  },
  tooLargeDescription: {
    id: `file-uploader.file-to-large-desc`,
    defaultMessage: `Please select a smaller file to upload`
  }
});
