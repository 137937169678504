import { Slider } from "antd";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import styled, { useTheme } from "styled-components";

const StyledSlider = styled(Slider)`
  width: 100%;
`;

const VolumeSlider = ({
  onSelect,
  defaultValue = 80
}: {
  onSelect: (volume: number, finalSelect: boolean) => void;
  defaultValue?: number;
}) => {
  const theme = useTheme();
  return (
    <H1_FlexRow padding="10px 20px 10px 10px" align="center" gap="10px">
      <H1_Icon color={theme.gray11} icon="fa-solid fa-volume" />
      <StyledSlider
        defaultValue={defaultValue}
        onChange={(v) => onSelect(v, false)}
        onAfterChange={(v) => onSelect(v, true)}
        tooltip={{ open: false }}
        min={1}
        trackStyle={{
          backgroundColor: theme.blue4
        }}
        handleStyle={{
          border: "0px",
          boxShadow: "0 0 0 3px rgba(0, 0, 0, 0.12)"
        }}
      />
    </H1_FlexRow>
  );
};

export default VolumeSlider;
