import animationData from "app/assets/lottie/circle-loader.json";
import Lottie from "lottie-react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledLottie = styled(Lottie)`
  height: ${(props: { sizeprop: string }) => props.sizeprop};
  width: ${(props: { sizeprop: string }) => props.sizeprop};
`;

interface CircleLoaderProps {
  size?: string;
  className?: string;
}

const CircleLoader = ({ size = "120px", className }: CircleLoaderProps) => {
  return (
    <Container className={className}>
      <StyledLottie sizeprop={size} loop autoplay animationData={animationData} />
    </Container>
  );
};

export default CircleLoader;
