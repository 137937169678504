import styled from "styled-components";

const SelectionContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;

const SelectionBorder = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-width: 5px;
  border-style: solid;
  border-radius: 11px;
  opacity: 0.2;
  border-color: ${(props: { $color: string }) => props.$color};
`;

const SelectionName = styled.div`
  position: absolute;
  bottom: -22px;
  left: 0;
  height: 20px;
  padding: 0 6px;
  border-radius: 3px;
  color: black;
  font-size: 12px;
  line-height: 20px;
  background-color: ${(props: { $color: string }) => props.$color};
`;

interface SelectionProps {
  email?: string;
  color?: string;
}
const Selection = ({ email, color }: SelectionProps) => {
  return (
    <SelectionContainer>
      <SelectionBorder $color={color as string} />
      <SelectionName $color={color as string}>{email}</SelectionName>
    </SelectionContainer>
  );
};

export default Selection;
