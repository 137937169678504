import React, { ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { noop } from "lodash-es";

import { message, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { Draft, FeatureFlag, PlanEnum, Video } from "app/types";
import { draftsActions } from "app/store/slices/drafts.slice";
import { fetchingStatus } from "app/utils/helpers";
import usePermissions from "app/hooks/usePermissions";
import styled, { useTheme } from "styled-components";
import useCopyLink from "app/hooks/useCopyLink";
import useEmbed from "app/hooks/useEmbed";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ReactComponent as UpgradeIcon } from "app/assets/sider/upgrade-crown.svg";
import { useFlags } from "launchdarkly-react-client-sdk";
import useDownload from "app/hooks/useDownload";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAuth } from "app/auth/useAuth";
import useModal, { ModalName } from "app/hooks/useModal";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { videosActions } from "app/store/slices/videos.slice";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { Button } from "@nextui-org/react";
import useConfirmModal from "app/hooks/useConfirmModal";
import { ThemeMode } from "app/utils/theme";

const DROPDOWN_SOURCE = "video_dropdown";

const StyledIcon = styled.i`
  font-size: 20px;
  height: 32px;
  min-width: 32px;
  line-height: 32px;
  margin: auto;
  color: ${({ theme }) => theme.gray8};
  &:hover {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.gray7)};
  }
`;

const StyledUpgradeIcon = styled(UpgradeIcon)`
  width: 12px;
  height: 12px;
`;

const LoadingSpin = styled(Spin)`
  color: ${(props) => props.theme.pink4};
  float: left;
`;

const messages = defineMessages({
  approveDeleteProjectTitle: {
    id: `warning.project-delete-approval.title`,
    defaultMessage: "Delete video"
  },
  deleteVideoButton: {
    id: `warning.delete-video-button`,
    defaultMessage: "Delete"
  },
  deleteVideoButtonCancel: {
    id: `warning.delete-video-button-cancel`,
    defaultMessage: "Cancel"
  },
  approveDeleteProjectContent: {
    id: `warning.project-delete-approval.messege`,
    defaultMessage: "Are you sure you want to delete this video?"
  },
  editProjectMetaTitle: {
    id: `project.meta-edit.dropdown.popup.title`,
    defaultMessage: "Edit video meta"
  },
  editProjectMetaFormNameLabel: {
    id: `project.meta-edit.dropdown.popup.form.title.label`,
    defaultMessage: "Folder title"
  },
  editProjectMetaFormDescLabel: {
    id: `project.meta-edit.dropdown.popup.form.description.label`,
    defaultMessage: "Description"
  },
  editProjectMetaDropdownDeleteButton: {
    id: `project.meta-edit.dropdown.delete.button`,
    defaultMessage: "Delete"
  },
  editProjectMetaDropdownDuplicteButton: {
    id: `project.meta-edit.dropdown.duplicate.button`,
    defaultMessage: "Duplicate"
  },
  editProjectMetaDropdownMoveToFolderButton: {
    id: `project.meta-edit.dropdown.move-to-folder.button`,
    defaultMessage: "Move to folder"
  },
  editProjectMetaDropdownSaveAsTemplateButton: {
    id: `project.meta-edit.dropdown.save-draft-as-template.button`,
    defaultMessage: "Save as template"
  },
  editProjectMetaDropdownRenameButton: {
    id: `project.meta-edit.dropdown.rename.button`,
    defaultMessage: "Rename"
  },
  copyCurlExample: {
    id: `project.meta-edit.dropdown.copy-curl.button`,
    defaultMessage: "Copy api curl"
  },
  editProjectMetaDropdownCopyLinkButton: {
    id: `project.meta-edit.dropdown.copy-link.button`,
    defaultMessage: "Copy link"
  },
  editProjectMetaDropdownOpenDraftButton: {
    id: `project.meta-edit.dropdown.open-draft.button`,
    defaultMessage: "Edit"
  },
  editProjectMetaDropdownDownloadButton: {
    id: `project.meta-edit.dropdown.download.button`,
    defaultMessage: "Download"
  },
  editProjectMetaDropdownEmbedButton: {
    id: `project.meta-edit.dropdown.embed.button`,
    defaultMessage: "Embed"
  },
  editProjectNewTemplateCreated: {
    id: `project.meta-edit.dropdown.new-template.created`,
    defaultMessage: "New template created!"
  },
  editProjectMetaDropdownDuplicateNotification: {
    id: `project.meta-edit.dropdown.notifications.duplicate-v2`,
    defaultMessage: "Video duplicate in progress"
  },
  editProjectMetaDropdownEditButton: {
    id: `project.meta-edit.dropdown.meta-edit.button`,
    defaultMessage: "Rename"
  },
  editProjectMetaDropdownEditCsv: {
    id: "project.meta-edit.dropdown.edit-db.button",
    defaultMessage: "Edit database"
  },
  editProjectMetaDropdownDangerZoneLabel: {
    id: "project.meta-edit.dropdown.danger.label",
    defaultMessage: "Danger Zone"
  },
  editProjectMetaDropdownDownloadSubtitlesLabel: {
    id: "project.meta-edit.dropdown.download-subtitles.label",
    defaultMessage: "Export Subtitles"
  }
});

interface DropdownProjectMetaEditProps {
  className?: string;
  draft: Draft;
  dropdownExtensions?: ItemType[];
  onRenameDraft?: () => void;
  showCopyLink?: boolean;
  showDownload?: boolean;
  showEmbed?: boolean;
  onOpenDraft?: () => void;
  showDownloadSubtitles?: boolean;
  downloadSubtitles?: () => void;
  icon?: ReactNode;
  withMoveToFolder?: boolean;
  duplicateNotification?: string | ReactNode;
}

const DropdownProjectMetaEdit = ({
  className = "dropdown-project-",
  draft,
  dropdownExtensions,
  onRenameDraft,
  showCopyLink,
  showDownload,
  showEmbed,
  onOpenDraft,
  showDownloadSubtitles,
  icon,
  withMoveToFolder = false,
  duplicateNotification
}: DropdownProjectMetaEditProps) => {
  const intl = useIntl();
  const flags = useFlags();
  const { openDownloadModal } = useDownload();
  const { user } = useAuth();
  const { currentPlan } = useCurrentPlan();
  const dispatch = useAppDispatch();
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();
  const { copyLink } = useCopyLink();
  const embedCode = useEmbed();
  const { openModal } = useModal();
  const theme = useTheme();
  const {
    isAllowedToDeleteDraft,
    isAllowedToDuplicateDraft,
    isPublishAllow,
    isAllowCreateApiCurl,
    isWriteWorkspaceAllow
  } = usePermissions();
  const deleteStatus = useAppSelector((state) => state.drafts.deleteDraftStatus);
  const deletedDraftId = useAppSelector((state) => state.drafts.deletedDraftId);
  const duplicationStatus = useAppSelector((state) => state.drafts.duplicationStatus);
  const duplicatedDraftId = useAppSelector((state) => state.drafts.duplicatedDraftId);

  const isOnBoarding = currentPlan === PlanEnum.onBoarding;
  const draftId: string = draft?.id as string;
  const loading =
    (duplicationStatus === fetchingStatus.loading && duplicatedDraftId === draftId) ||
    (deleteStatus === fetchingStatus.loading && deletedDraftId === draftId);

  const onConfirmDelete = () => {
    dispatch(draftsActions.deleteDraftRequest(draftId));
  };
  const openDeleteModal = () => {
    openConfirmModal({
      onConfirm: onConfirmDelete,
      onCancel: closeConfirmModal,
      title: intl.formatMessage(messages.approveDeleteProjectTitle),
      content: intl.formatMessage(messages.approveDeleteProjectContent),
      confirmText: intl.formatMessage(messages.deleteVideoButton),
      cancelText: intl.formatMessage(messages.deleteVideoButtonCancel)
    });
  };

  const onMoveToFolder = () => {
    openModal(ModalName.moveToFolder, { draftIds: [draftId] });
  };
  const onDownloadSubtitle = () => {
    dispatch(googleEvents.exportSubtitles({ source: `${DROPDOWN_SOURCE}_download_subtitles` }));
    const videoId = draft.last_video?.id as string;
    dispatch(videosActions.getVideoSubtitleApiRequest(videoId));
  };
  const onDuplicate = () => {
    message.info(
      duplicateNotification ||
        intl.formatMessage(messages.editProjectMetaDropdownDuplicateNotification)
    );
    dispatch(draftsActions.duplicateDraftsRequest({ draftId }));
  };

  const onDownload = () => {
    openDownloadModal({ video: draft.last_video, source: `${DROPDOWN_SOURCE}_download` });
  };

  const onCopyLink = () => {
    copyLink(`${DROPDOWN_SOURCE}_copy_link`, draft.last_video as Video, draftId);
  };

  const onSaveAsRecipe = () => {
    dispatch(
      analyticsEvents.saveAsRecipe({
        draftId,
        title: draft.title as string,
        description: draft.description
      })
    );
    dispatch(
      draftsActions.saveAsRecipeRequest({
        owner: user?.sub as string,
        draftId,
        title: draft.title as string,
        description: draft.description
      })
    );
  };

  const onEmbeddedVideo = () => {
    embedCode(`${DROPDOWN_SOURCE}_embed`, draft.last_video as Video);
  };
  const onCopyApiExample = () => {
    if (isAllowCreateApiCurl) {
      dispatch(draftsActions.createApiVideoExampleRequest({ draftId, type: "create" }));
    } else {
      dispatch(draftsActions.createApiVideoExampleRequest({ draftId, type: "override" }));
    }
  };
  const menu = {
    items: [
      onOpenDraft && {
        key: "open-draft",
        startContent: <i className="far fa-video" />,
        onClick: onOpenDraft,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownOpenDraftButton)}
          </H1_TextSmall>
        )
      },
      {
        key: "rename-project",
        startContent: <i className="far fa-pen" />,
        onClick: onRenameDraft,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownRenameButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow,
        showDivider: showCopyLink || showDownload || showEmbed
      },
      showCopyLink && {
        key: "copy-link",
        startContent: <i className="far fa-link" />,
        onClick: onCopyLink,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownCopyLinkButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow
      },
      showDownload && {
        key: "download",
        startContent: <i className="far fa-arrow-down-to-line" />,
        onClick: onDownload,
        label: (
          <H1_FlexRow justify="space-between" height="100%" align="center">
            <H1_TextSmall>
              {intl.formatMessage(messages.editProjectMetaDropdownDownloadButton)}
            </H1_TextSmall>
            <ConditionalRender condition={!draft.last_video?.paid && isOnBoarding}>
              <StyledUpgradeIcon />
            </ConditionalRender>
          </H1_FlexRow>
        ),
        disabled: !isWriteWorkspaceAllow
      },
      showEmbed && {
        key: "embed",
        startContent: <i className="far fa-code" />,
        onClick: onEmbeddedVideo,
        label: (
          <H1_FlexRow justify="space-between" height="100%" align="center">
            <H1_TextSmall>
              {intl.formatMessage(messages.editProjectMetaDropdownEmbedButton)}
            </H1_TextSmall>
            <ConditionalRender
              condition={!isPublishAllow && !draft.last_video?.paid && isOnBoarding}
            >
              <StyledUpgradeIcon />
            </ConditionalRender>
          </H1_FlexRow>
        ),
        disabled: !isWriteWorkspaceAllow,
        showDivider: true
      },
      isAllowCreateApiCurl && {
        key: "copy-api-example",
        startContent: <i className="far fa-server" />,
        onClick: onCopyApiExample,
        label: <H1_TextSmall>{intl.formatMessage(messages.copyCurlExample)}</H1_TextSmall>
      },
      showDownloadSubtitles && {
        key: "download-subtitles",
        startContent: <i className="far fa-subtitles" />,
        onClick: onDownloadSubtitle,
        label: (
          <H1_FlexRow justify="space-between" height="100%" align="center">
            <H1_TextSmall>
              {intl.formatMessage(messages.editProjectMetaDropdownDownloadSubtitlesLabel)}
            </H1_TextSmall>
            <ConditionalRender
              condition={!isPublishAllow && !draft.last_video?.paid && isOnBoarding}
            >
              <StyledUpgradeIcon />
            </ConditionalRender>
          </H1_FlexRow>
        ),
        disabled: !isWriteWorkspaceAllow
      },
      flags[FeatureFlag.personalTemplates] && {
        key: "save-as-recipe",
        startContent: <i className="fa-regular fa-table-layout" />,
        onClick: onSaveAsRecipe,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownSaveAsTemplateButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow,
        showDivider: dropdownExtensions && dropdownExtensions
      },
      isAllowedToDuplicateDraft && {
        key: "duplicate-project",
        startContent: <i className="far fa-copy" />,
        onClick: onDuplicate,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownDuplicteButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow
      },
      withMoveToFolder && {
        key: "move-to-folder",
        startContent: <i className="far fa-folder-arrow-up" />,
        onClick: onMoveToFolder,
        label: (
          <H1_TextSmall>
            {intl.formatMessage(messages.editProjectMetaDropdownMoveToFolderButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow
      },
      isAllowedToDeleteDraft && {
        className: "danger-zone",
        danger: true,
        key: "delete-project",
        startContent: <i className="far fa-trash text-pink-4" />,
        onClick: openDeleteModal,
        label: (
          <H1_TextSmall color={theme.pink4}>
            {intl.formatMessage(messages.editProjectMetaDropdownDeleteButton)}
          </H1_TextSmall>
        ),
        disabled: !isWriteWorkspaceAllow
      }
    ]
  };

  if (loading) {
    return (
      <Row className="align-i-left draft-actions-menu" key="drafts-actions">
        <LoadingSpin indicator={<LoadingOutlined spin />} />
      </Row>
    );
  }

  return (
    <>
      <NextDropdown
        withWhiteBackground
        items={menu.items}
        key="actions-menu1"
        className={className + " overlay-dropdown-menu"}
      >
        {icon || (
          <Button
            variant="light"
            isIconOnly
            startContent={<StyledIcon className="far fa-ellipsis-h" />}
          />
        )}
      </NextDropdown>
    </>
  );
};

export default DropdownProjectMetaEdit;

DropdownProjectMetaEdit.defaultProps = {
  onRenameDraft: noop,
  dropdownExtensions: null,
  showCopyLink: false,
  showDownload: false,
  showDownloadSubtitles: false,
  showEmbed: false,
  onOpenDraft: undefined
};
