import styled from "styled-components";
import { onImageError, onVideoError } from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";
import { CaretRightOutlined } from "@ant-design/icons";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { ReactNode, useState } from "react";
import MediaItemActions from "app/pages/mediaLibrary/MediaItemActions";
import ItemLayout from "./ItemLayout";
import MediaLibraryEditName from "app/pages/mediaLibrary/MediaLibraryEditName";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import { MediaType, ServerFolderMedia } from "app/types/media";
import LazyImage from "app/pages/mediaLibrary/LazyImage";
import LazyVideo from "app/pages/mediaLibrary/LazyVideo";
import useDisplayUrls, { MediaUrl } from "app/hooks/useDisplayUrls";

const PlayIconContainerFlexRow = styled(H1_FlexRow)`
  position: absolute;
  top: 60px;
  left: 60px;
  background-color: ${(props) => props.theme.gray1};
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
`;
const PlayIcon = styled(CaretRightOutlined)`
  svg {
    width: 21px;
    height: 21px;
  }
  color: ${(props) => props.theme.gray11};
`;

export interface MediaLibraryItemProps {
  id: string;
  isSelected: boolean;
  footer?: ReactNode;
  onZoomItem?: (event: React.MouseEvent) => void;
  onDeleteItem?: (event: React.MouseEvent) => void;
  onMoveItem?: (event: React.MouseEvent) => void;
  onDownload?: (event: React.MouseEvent, file: ServerFolderMedia, name: string) => void;
  onEditIntent: (file: ServerFolderMedia) => void;
}

const MediaLibraryItem = React.memo(
  ({
    id,
    isSelected,
    onZoomItem,
    onDeleteItem,
    onMoveItem,
    onDownload,
    onEditIntent
  }: MediaLibraryItemProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const [editable, setEditable] = useState<boolean>(false);

    const file = useAppSelector((state) =>
      mediaGlobalSelectors.selectById(state, id)
    ) as ServerFolderMedia;
    const { displayUrls, loading } = useDisplayUrls([file?.data?.url], isVisible);
    const displayUrl: MediaUrl | undefined = displayUrls[file?.data?.url as string];
    const isVideo = file.data?.media_type === MediaType.video;
    const isThumbnailVideo = displayUrl?.thumbnailType === MediaType.video;
    const fileName = loading
      ? ""
      : ((file.data?.name || displayUrl?.fileName || file.name) as string);

    const onEdit = () => {
      onEditIntent(file as ServerFolderMedia);
      setEditable(true);
    };

    const onCloseEdit = () => {
      setEditable(false);
    };

    const onChangeVisible = (visible: boolean) => {
      setIsVisible(visible);
    };
    return (
      <ItemLayout
        selected={isSelected}
        fileId={file.id}
        footer={
          <MediaLibraryEditName
            name={fileName}
            onCloseEdit={onCloseEdit}
            onEdit={onEdit}
            editable={editable}
            fileId={id}
          />
        }
      >
        <ConditionalRender condition={!isThumbnailVideo}>
          <LazyImage
            isVisible={isVisible}
            dataImageHandle={id}
            src={displayUrl?.thumbnail as string}
            onChangeVisible={onChangeVisible}
            onErrorImage={onImageError}
          />
        </ConditionalRender>

        <ConditionalRender condition={!!isThumbnailVideo}>
          <LazyVideo
            isVisible={isVideo}
            onChangeVisible={onChangeVisible}
            dataImageHandle={id}
            src={displayUrl?.thumbnail as string}
            onErrorVideo={onVideoError}
          />
        </ConditionalRender>
        <ConditionalRender condition={!!isVideo}>
          <PlayIconContainerFlexRow
            width="40px"
            height="40px"
            padding="0 0 0 4px"
            justify="center"
            align="center"
          >
            <PlayIcon />
          </PlayIconContainerFlexRow>
        </ConditionalRender>
        <MediaItemActions
          onDeleteItem={onDeleteItem}
          onZoomItem={onZoomItem}
          onMoveItem={onMoveItem}
          onEditItem={onEdit}
          onDownloadItem={(e) => onDownload && onDownload(e, file as ServerFolderMedia, fileName)}
        />
      </ItemLayout>
    );
  },
  (prevProps, nextProps) => prevProps.isSelected === nextProps.isSelected
);

MediaLibraryItem.displayName = "MediaLibraryItem";
export default MediaLibraryItem;
