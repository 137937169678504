import { defineMessages } from "react-intl";

export const sceneDrawerMessages = defineMessages({
  sceneTitle: {
    id: `editor.drawer.scene.scene-title`,
    defaultMessage: "Scene Edit"
  },
  layoutTitle: {
    id: `editor.drawer.scene.layout-title`,
    defaultMessage: "Layout"
  },
  layoutTextTitle: {
    id: `editor.drawer.scene.layout-text-title`,
    defaultMessage: "Current Layout"
  },
  layoutBottomTitle: {
    id: `editor.drawer.scene.layout-bottom-title`,
    defaultMessage: "Choose a new layout"
  },
  contentTitle: {
    id: `editor.drawer.scene.content-title`,
    defaultMessage: "Content"
  },
  sceneNameTitle: {
    id: `editor.drawer.scene.name-title`,
    defaultMessage: "Scene {number} -"
  },
  layoutSubTitle: {
    id: `editor.drawer.scene.subtitle`,
    defaultMessage: "Your layout"
  },
  layoutChange: {
    id: `editor.drawer.scene.layout-change`,
    defaultMessage: "Change Layout"
  },
  replace: {
    id: `editor.drawer.scene.replace`,
    defaultMessage: "Replace"
  },
  replaceMediaTooltip: {
    id: `editor.drawer.scene.replace-media-tooltip`,
    defaultMessage: "Replace Media"
  },
  replaceColor: {
    id: `editor.drawer.scene.replace-color`,
    defaultMessage: "Change Color"
  },
  replaceTooltip: {
    id: `editor.drawer.scene.replace-tooltip`,
    defaultMessage: "Replace {title}"
  },
  removeTooltip: {
    id: `editor.drawer.scene.remove-tooltip`,
    defaultMessage: "Remove {title}"
  },
  imageFitTooltip: {
    id: `editor.drawer.scene.image-fit-tooltip`,
    defaultMessage: "Media Settings"
  },
  changeLayoutTooltip: {
    id: `editor.drawer.scene.change-layout-tooltip`,
    defaultMessage: "Change Layout"
  },
  titlesTitle: {
    id: `editor.drawer.scene.titles.title`,
    defaultMessage: "Titles"
  },
  avatarAndVoiceTitle: {
    id: `editor.drawer.scene.titles.avatar-and-voice-title`,
    defaultMessage: "Avatar & Voice"
  },
  avatarEyeShowTooltip: {
    id: `editor.drawer.scene.titles.avatar-eye-show-tooltip`,
    defaultMessage: "Show avatar"
  },
  avatarEyeHideTooltip: {
    id: `editor.drawer.scene.titles.avatar-eye-hide-tooltip`,
    defaultMessage: "Hide avatar"
  },
  avatarEyeNotSupportedTooltip: {
    id: `editor.drawer.scene.titles.avatar-eye-not-supported-tooltip`,
    defaultMessage: "No avatar in this layout"
  },
  mediaTitle: {
    id: `editor.drawer.scene.media.title`,
    defaultMessage: "Media"
  },
  mediaUpdateText: {
    id: `editor.drawer.scene.media.update-text`,
    defaultMessage: "Upgrade to use media"
  },
  mediaUploadText: {
    id: `editor.drawer.scene.media.upload-text`,
    defaultMessage: "Add {title}"
  },
  mediaUploadMedia: {
    id: `editor.drawer.scene.media.upload-media`,
    defaultMessage: "Insert Media"
  },
  premiumLayoutTooltip: {
    id: `editor.drawer.change-layout-premium-tooltip`,
    defaultMessage: "In order to use this layout, an upgrade is required"
  },
  mediaGenerationToolTip: {
    id: `editor.drawer.scene.media.generate.tooltip.title`,
    defaultMessage: "Generate media"
  },
  screenRecordingToolTip: {
    id: `editor.drawer.scene.media.screen-recording.tooltip`,
    defaultMessage: "Screen recording"
  },
  background: {
    id: `editor.drawer.scene.media.background.title`,
    defaultMessage: "Background"
  },
  titleGenerationToolTip: {
    id: `editor.drawer.scene.title.generate.tooltip.title`,
    defaultMessage: "Generate title"
  },
  titleArabicUnsupported: {
    id: `editor.drawer.scene.title.error.arabic`,
    defaultMessage: "Scene titles in arabic is not supported"
  },
  textColorTooltip: {
    id: `editor.drawer.scene.title.text-color-tooltip`,
    defaultMessage: "Text color"
  },
  noColorTooltip: {
    id: `editor.drawer.scene.title.no-color-tooltip`,
    defaultMessage: "None"
  },
  gradientColorTooltip: {
    id: `editor.drawer.scene.title.gradient-color-tooltip`,
    defaultMessage: "Gradient"
  },
  solidColorTooltip: {
    id: `editor.drawer.scene.title.solid-color-tooltip`,
    defaultMessage: "Solid"
  },
  textBackgroundColorTooltip: {
    id: `editor.drawer.scene.title.text-background-color-tooltip`,
    defaultMessage: "Text background color"
  },
  textAlignmentTooltip: {
    id: `editor.drawer.scene.title.text-alignment-tooltip`,
    defaultMessage: "Text alignment"
  },
  textAlignLeftTooltip: {
    id: `editor.drawer.scene.title.text-align-left-tooltip`,
    defaultMessage: "Left align"
  },
  textAlignCenterTooltip: {
    id: `editor.drawer.scene.title.text-align-center-tooltip`,
    defaultMessage: "Center align"
  },
  textAlignRightTooltip: {
    id: `editor.drawer.scene.title.text-align-right-tooltip`,
    defaultMessage: "Right align"
  },
  textAlignTopTooltip: {
    id: `editor.drawer.scene.title.text-align-top-tooltip`,
    defaultMessage: "Top align"
  },
  textAlignMiddleTooltip: {
    id: `editor.drawer.scene.title.text-align-middle-tooltip`,
    defaultMessage: "Middle align"
  },
  textAlignBottomTooltip: {
    id: `editor.drawer.scene.title.text-align-bottom-tooltip`,
    defaultMessage: "Bottom align"
  },
  textFamilyTooltip: {
    id: `editor.drawer.scene.title.text-family-tooltip`,
    defaultMessage: "Text font"
  },
  textOverflowScaleToFitTooltip: {
    id: `editor.drawer.scene.title.text-overflow-scale-to-fit-tooltip`,
    defaultMessage: "Scale text to fit"
  },
  textOverflowTruncateTooltip: {
    id: `editor.drawer.scene.title.text-overflow-truncate-tooltip`,
    defaultMessage: "Truncate text"
  },
  textHiddenTooltip: {
    id: `editor.drawer.scene.title.text-hidden-tooltip`,
    defaultMessage: "Hide text"
  },
  textDisplayedTooltip: {
    id: `editor.drawer.scene.title.text-displayed-tooltip`,
    defaultMessage: "Show text"
  },
  solidColor: {
    id: `editor.drawer.scene.content.solid-color`,
    defaultMessage: "Solid"
  },
  mediaColor: {
    id: `editor.drawer.scene.content.media-color`,
    defaultMessage: "Media"
  },
  gradientColor: {
    id: `editor.drawer.scene.content.gradient-color`,
    defaultMessage: "Gradient"
  }
});

export const imageFitDrawerMessages = defineMessages({
  backToScene: {
    id: `editor.drawer.image-fit.back-to-layout`,
    defaultMessage: "Back to scene"
  },
  scaleIcon: {
    id: `editor.drawer.image-fit.scale-icon`,
    defaultMessage: "Scale"
  },
  cropIcon: {
    id: `editor.drawer.image-fit.crop-icon`,
    defaultMessage: "Crop"
  },
  smartCropIcon: {
    id: `editor.drawer.image-fit.smart-crop-icon`,
    defaultMessage: "Smart"
  },
  imageFit: {
    id: `editor.drawer.image-fit.image-fit`,
    defaultMessage: "IMAGE FIT"
  },
  filters: {
    id: `editor.drawer.image-fit.filters`,
    defaultMessage: "FILTERS"
  },
  position: {
    id: `editor.drawer.image-fit.position`,
    defaultMessage: "POSITION"
  },
  filtersBW: {
    id: `editor.drawer.image-fit.filters-b-w`,
    defaultMessage: "B&W"
  },
  filtersSepia: {
    id: `editor.drawer.image-fit.filters-sepia`,
    defaultMessage: "Sepia"
  },
  filtersOilPaint: {
    id: `editor.drawer.image-fit.filters-oil-paint`,
    defaultMessage: "Oil Paint"
  },
  none: {
    id: `editor.drawer.image-fit.none`,
    defaultMessage: "None"
  },
  center: {
    id: `editor.drawer.image-fit.center`,
    defaultMessage: "Center"
  },
  top: {
    id: `editor.drawer.image-fit.top`,
    defaultMessage: "Top"
  },
  bottom: {
    id: `editor.drawer.image-fit.bottom`,
    defaultMessage: "Bottom"
  },
  left: {
    id: `editor.drawer.image-fit.left`,
    defaultMessage: "Left"
  },
  right: {
    id: `editor.drawer.image-fit.right`,
    defaultMessage: "Right"
  },
  faces: {
    id: `editor.drawer.image-fit.faces`,
    defaultMessage: "Faces"
  }
});

export const videoActionsMessages = defineMessages({
  resetAll: {
    id: `video-actions.reset-all`,
    defaultMessage: `Reset all`
  },
  recordAgain: {
    id: `video-actions.record-again`,
    defaultMessage: "Record again"
  },
  play: {
    id: `video-actions.play`,
    defaultMessage: "Play"
  },
  fit: {
    id: `video-actions.fit`,
    defaultMessage: `Video fit`
  },
  trim: {
    id: `video-actions.trim`,
    defaultMessage: `Trim`
  },
  brollOn: {
    id: `video-actions.broll-on`,
    defaultMessage: `Sound on`
  },
  brollOff: {
    id: `video-actions.broll-off`,
    defaultMessage: `Sound off`
  },
  scale: {
    id: `video-actions.scale`,
    defaultMessage: `Scale`
  },
  pad: {
    id: `video-actions.pad`,
    defaultMessage: `Fit`
  },
  playback: {
    id: `video-actions.playback`,
    defaultMessage: `Playback`
  },
  loop: {
    id: `video-actions.loop`,
    defaultMessage: `Loop`
  },
  loopInfo: {
    id: `video-actions.loop-info`,
    defaultMessage: `When video ends it starts again from beginning`
  },
  freeze: {
    id: `video-actions.freeze`,
    defaultMessage: `Freeze`
  },
  freezeInfo: {
    id: `video-actions.freeze-info`,
    defaultMessage: `When video ends it freezes on last frame`
  },
  interpolate: {
    id: `video-actions.interpolate`,
    defaultMessage: `Interpolate`
  },
  interpolateInfo: {
    id: `video-actions.interpolate-info`,
    defaultMessage: `Video playback is stretched to scene duration`
  },
  paddingColor: {
    id: `video-actions.padding-color`,
    defaultMessage: `Background color`
  },
  clip: {
    id: `video-actions.clip`,
    defaultMessage: `Clip`
  },
  crop: {
    id: `video-actions.crop`,
    defaultMessage: `Crop`
  },
  cancelBtn: {
    id: `video-actions.cancel-button`,
    defaultMessage: `Cancel`
  },
  doneBtn: {
    id: `video-actions.done-button`,
    defaultMessage: `Done`
  },
  useRecording: {
    id: `video-actions.use-recording`,
    defaultMessage: `Use recording`
  },
  applyBtn: {
    id: `video-actions.apply-button`,
    defaultMessage: `Apply`
  },
  trimVideo: {
    id: `video-actions.trim-video`,
    defaultMessage: `Trim Video`
  },
  fitVideo: {
    id: `video-actions.fit-video`,
    defaultMessage: `Fit Video`
  },
  cropVideo: {
    id: `video-actions.crop-video`,
    defaultMessage: `Crop Video`
  },
  videoSettings: {
    id: `video-actions.video-settings`,
    defaultMessage: `Video Settings`
  },
  screenRecordingSettings: {
    id: `video-actions.screen-recording-settings`,
    defaultMessage: `Screen Recording`
  },
  zoom: {
    id: `video-actions.zoom`,
    defaultMessage: `Zoom`
  }
});
