import React, { ReactNode, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Avatar, List, Image, Switch, Button } from "antd";
import { defineMessages, useIntl } from "react-intl";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import TitleFlexRow from "app/components/editor/sideDrawers/CommonComponents/TitleFlexRow";
import Loader from "app/components/common/Loader";
import styled from "styled-components";
import { DrawerProps } from "app/components/editor/sideDrawers/CommonDrawerTypes";
import { BackgroundMusic } from "app/types/music";
import { useAppDispatch, useAppSelector } from "app/hooks";
import AudioPlayer from "app/components/common/AudioPlayer";
import * as musicSelectors from "app/store/selectorsV2/music.selectors";
import PlayCircle from "app/assets/icons-reals/PlayCircleIcon.svg";
import PauseCircle from "app/assets/icons-reals/PauseCircleIcon.svg";
import MusicPlaceholder from "app/assets/images/musicPlaceholder.jpg";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { Draft } from "app/types/draft";
import { fetchingStatus } from "app/utils/helpers";
import GradientPlaceholder from "app/components/common/GradientPlaceholder";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";

export const messages = defineMessages({
  title: {
    id: `editor.drawer.music.title`,
    defaultMessage: "<b>Select</b> Music"
  },
  subTitle: {
    id: `editor.drawer.music.sub-title`,
    defaultMessage: "Tracks"
  },
  switch: {
    id: `editor.drawer.music.switch.label`,
    defaultMessage: "Music"
  },
  cta: {
    id: `editor.drawer.music.button.call-to-action`,
    defaultMessage: "Upgrade"
  }
});

const MusicDrawer = styled(FlexDrawer)`
  padding: 0;
  justify-content: space-between;
`;

const PaddingFlexColumn = styled(H1_FlexColumn)`
  padding: 20px 22px 20px 24px;
`;

const MusicItem = styled(H1_FlexRow)<{ active: boolean }>`
  padding: 17px;
  margin: 5px auto;
  border: "1px solid" + ${(p) => (p.active ? p.theme.blue4 : p.theme.gray3)};
  border-radius: 5px;
  word-break: break-all;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.gray3};
  }
`;

const StyledListItemMeta = styled(List.Item.Meta)`
  width: 56px;
  margin-right: 20px;
  flex: none;
`;

const BottomBar = styled(H1_FlexColumn)`
  max-width: 100%;
  flex-wrap: nowrap;
  box-shadow: 0px -4px 4.8px 0.8px rgba(0, 0, 0, 0.05);
  min-height: 90px;
  padding: 15px;
`;

const SwitchText = styled("span")`
  margin-left: 10px;
  width: auto;
`;

interface MusicImageProps {
  $playing: boolean;
}

const MusicImage = styled(Avatar)<MusicImageProps>`
  width: 56px;
  height: 56px;
  cursor: pointer;
  &::after,
  &:hover::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-size: 50%;
    background-repeat: no-repeat;
    ${(p) => p.$playing && `background-image: url(${PauseCircle});`}
    background-position: center;
    transform: translateY("50%");
    transition: transform 0.05s ease-in-out;
  }
  &:hover {
    &::after {
      transform: translateY(0%);
      transition: transform 0s ease-in-out;
      background-image: url(${(p) => (p.$playing ? PauseCircle : PlayCircle)});
    }
  }
`;

const MusicTitle = styled(H1_TextSmall)`
  white-space: pre-wrap;
`;

const MusicArtist = styled(MusicTitle)`
  font-weight: 400;
`;

const MusicDuration = styled(MusicArtist)`
  color: ${(props) => props.theme.gray7};
`;

const HiddenPlayer = styled(H1_FlexRow)`
  display: none;
  overflow: hidden;
`;

const EditorBackgroundMusicDrawer = ({ right, width, onDraftAttributeChange }: DrawerProps) => {
  const playerRef = useRef<HTMLAudioElement | undefined>();
  const [playTrack, setPlayTrack] = useState<string | undefined>(undefined);

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { editorDrawerOpen } = useDrawer();
  const { isWriteWorkspaceAllow } = usePermissions();

  const currentDraft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const allBackgroundMusic = useAppSelector((state) =>
    musicSelectors.formattedMusicTracks(state, { query: "" })
  );
  const selectedBackgroundMusic = useAppSelector(musicSelectors.getSelectedBackgroundMusic);
  const backgroundMusicLoad: boolean =
    useAppSelector((state) => state.music.status) === fetchingStatus.loading;

  const isDrawerActive: boolean = editorDrawerOpen === Drawer.Music;

  const onMusicSelected = ({ track }: { track: BackgroundMusic }) => {
    if (!isWriteWorkspaceAllow) {
      return;
    }
    dispatch(
      analyticsEvents.selectBackgroundMusicTrack({ track, draft: currentDraft.id as string })
    );
    onDraftAttributeChange("attributes.music.background_music.url", track.url, true);
  };

  const togglePlayTrack = (url: string, e: SyntheticEvent) => {
    e.stopPropagation();
    setPlayTrack(playTrack === url && playerRef ? undefined : url);
  };

  const onChange = (checked: boolean) => {
    dispatch(analyticsEvents.toggleMusic({ value: checked, draft: currentDraft.id as string }));
    if (checked && !!playTrack) {
      const trackObj = allBackgroundMusic.find((track) => track.sample === playTrack);
      onDraftAttributeChange(
        "attributes.music.background_music.url",
        (trackObj && trackObj?.url) || playTrack,
        true
      );
    } else if (checked && !selectedBackgroundMusic) {
      onDraftAttributeChange(
        "attributes.music.background_music.url",
        allBackgroundMusic[0].url,
        true
      );
    } else if (!checked) {
      onDraftAttributeChange("attributes.music.background_music", null, true);
    }
  };

  const showUpgradeButton = false;

  useEffect(() => {
    if (!isDrawerActive) {
      setPlayTrack(undefined);
    }
  }, [isDrawerActive]);

  return (
    <MusicDrawer right={right} active={isDrawerActive} width={width}>
      <PaddingFlexColumn overflow="auto">
        <TitleFlexRow height="52px">
          {
            // @ts-ignore handels formmating issue with html tags params
            intl.formatMessage(messages.title, {
              b: (str) => (
                <H1_TextMiddle fontSize="18px" fontWeight={300}>
                  {str}
                </H1_TextMiddle>
              )
            }) as ReactNode
          }
        </TitleFlexRow>
        <H1_FlexColumn flex="1 1 auto">
          <ConditionalRender condition={!!backgroundMusicLoad}>
            <Loader />
          </ConditionalRender>
          {allBackgroundMusic.map((track) => (
            <MusicItem
              width="100%"
              justify="start"
              key={track.id}
              active={(selectedBackgroundMusic?.id === track.id) as boolean}
              onClick={() => onMusicSelected({ track })}
            >
              <StyledListItemMeta
                avatar={
                  <MusicImage
                    shape="square"
                    $playing={
                      !playerRef.current?.paused && playTrack === (track.sample ?? track.url)
                    }
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
                      togglePlayTrack(track.sample ?? track.url, e as SyntheticEvent);
                    }}
                    src={
                      track.image ? (
                        <Image src={track.image ?? MusicPlaceholder} fallback={MusicPlaceholder} />
                      ) : (
                        <GradientPlaceholder key={track.id} />
                      )
                    }
                  />
                }
              />
              <div>
                <MusicTitle fontWeight={500}>{track.title}</MusicTitle>
                <MusicArtist fontSize="12px">{track.artist}</MusicArtist>
                <MusicDuration fontSize="12px">{track.duration}</MusicDuration>
              </div>
            </MusicItem>
          ))}
        </H1_FlexColumn>
      </PaddingFlexColumn>
      <BottomBar align="center" justify="center" width="100%">
        <H1_FlexRow width="100%">
          <H1_FlexRow flex="1" justify="center" align="center">
            <Switch
              defaultChecked={!!selectedBackgroundMusic}
              checked={!!selectedBackgroundMusic}
              onChange={onChange}
            />
            <SwitchText>{intl.formatMessage(messages.switch)}</SwitchText>
          </H1_FlexRow>
          <ConditionalRender condition={showUpgradeButton}>
            <H1_FlexColumn flex="1">
              <Button>{intl.formatMessage(messages.cta)}</Button>
            </H1_FlexColumn>
          </ConditionalRender>
        </H1_FlexRow>
        <ConditionalRender condition={playTrack !== undefined}>
          <HiddenPlayer width="100%">
            <AudioPlayer audioFile={playTrack as string} isFetchingAudio={false} ref={playerRef} />
          </HiddenPlayer>
        </ConditionalRender>
      </BottomBar>
    </MusicDrawer>
  );
};

export default EditorBackgroundMusicDrawer;
