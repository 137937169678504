import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { Button, Divider, Input, Row, Space } from "antd";
import { templatesRequestMessages } from "app/components/editor/templateSelect/messages";
import { templatesActions } from "app/store/slices/templates.slice";
import { useAppDispatch } from "app/hooks";

interface VisibleProps {
  $open: boolean;
}
// region [[ Styles ]]

const WrapperSpace = styled(Space)`
  flex: 0 0 auto;
  padding-top: 20px;
  margin-right: 40px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 22px;
  color: #4285f4;
  cursor: pointer;
`;

const BottomBox = styled.div.attrs(({ $open }: VisibleProps) => $open)`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s;
  ${({ $open }: VisibleProps) =>
    $open &&
    `
    max-height: 110px;
  `}
`;
const StyledButton = styled(Button)`
  height: 18px;
  font-size: 10px;
  line-height: 13px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
`;

const StyledInput = styled(Input)`
  border: none !important;
  padding: 0;
  box-shadow: none !important;
  font-size: 10px;
  line-height: 22px;
  font-family: "Lexend";
`;

// endregion [[ Styles ]]

const THANK_YOU_TIMEOUT = 6000;

const TemplateRequest = () => {
  const [text, setText] = useState<string>();
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showThankYouNote, setShowThankYouNote] = useState(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const toggleMoreButton = () => {
    setShowMoreButton(!showMoreButton);
  };
  const onInputChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
    setText(currentTarget.value);
  };
  const onSendButtonClicked = () => {
    dispatch(templatesActions.postNeedTemplatesRequest(text as string));
    setText("");
    setShowMoreButton(false);
    setShowThankYouNote(true);
    setTimeout(() => {
      setShowThankYouNote(false);
    }, THANK_YOU_TIMEOUT);
  };

  const onRemoveThankYou = () => {
    setShowThankYouNote(false);
  };

  return (
    <WrapperSpace direction="vertical">
      {showThankYouNote ? (
        <Title onClick={onRemoveThankYou}>
          {formatMessage(templatesRequestMessages.templatesRequestThankYou)}
        </Title>
      ) : (
        <>
          <Title onClick={toggleMoreButton} color={theme.pink4}>
            {formatMessage(templatesRequestMessages.templatesRequestTitle)}
          </Title>
          <BottomBox $open={showMoreButton}>
            <Divider />
            <StyledInput
              onChange={onInputChange}
              placeholder={formatMessage(templatesRequestMessages.templatesRequestInputPlaceholder)}
            />
            <Row justify="end">
              <StyledButton
                className="secondary-btn"
                onClick={onSendButtonClicked}
                disabled={!text}
              >
                {formatMessage(templatesRequestMessages.templateRequestSendButton)}
              </StyledButton>
            </Row>
          </BottomBox>
        </>
      )}
    </WrapperSpace>
  );
};

export default TemplateRequest;
