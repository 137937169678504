import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { defineMessages } from "@formatjs/intl";
import styled from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { uiActionsV2 } from "app/store/slices/uiV2.slice";

const messages = defineMessages({
  title: {
    id: `no-connection-modal.title`,
    defaultMessage: "Connection issue detected, you are offline"
  },
  description: {
    id: `no-connection-modal.description`,
    defaultMessage:
      "We have identified a connectivity problem. Kindly check your internet connection for any issues."
  },
  close: {
    id: `no-connection-modal.close`,
    defaultMessage: "CLOSE"
  }
});

const Wrapper = styled(H1_FlexColumn)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11111;
`;
const Content = styled(H1_FlexColumn)`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  width: 350px;
`;
const NoConnectionModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const isOnline = useAppSelector((state) => state.uiV2.isOnline);

  useEffect(() => {
    const handleStatusChange = () => {
      dispatch(uiActionsV2.setIsOnline(navigator.onLine));
    };

    window.addEventListener("online", handleStatusChange);

    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  return (
    <ConditionalRender condition={!isOnline}>
      <Wrapper justify={"center"} align={"center"}>
        <Content margin={"10px"} padding={"10px"}>
          <H1_FlexColumn>
            <H1_FlexRow gap={"5px"}>
              <H1_TextSmall color={"red"} fontWeight={500}>
                {formatMessage(messages.title)}
              </H1_TextSmall>
            </H1_FlexRow>
            <H1_FlexRow padding={"0px 0px 0px 10px"}>
              <H1_TextXs color="black" whiteSpace={"normal"}>
                {formatMessage(messages.description)}
              </H1_TextXs>
            </H1_FlexRow>
          </H1_FlexColumn>
        </Content>
      </Wrapper>
    </ConditionalRender>
  );
};

export default NoConnectionModal;
