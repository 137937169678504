import { defineMessages, useIntl } from "react-intl";
import useModal, { ModalName } from "app/hooks/useModal";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import styled, { useTheme } from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import srcImage from "app/assets/images/empty-balance.png";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import ConditionalRender from "app/components/common/ConditionalRender";
import useSmallScreen from "app/hooks/useSmallScreen";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextHeadline,
  H1_TextLargeHeadline,
  H1_TextSmall
} from "app/components/_Infrastructure/Typography";
import { onOpenZendesk } from "app/utils/zendesk/zendesk";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const messages = defineMessages({
  title: {
    id: "max-seats-modals.empty-credit.title",
    defaultMessage: "No More Seats"
  },
  subTitle: {
    id: "max-seats-modals.empty-credit.subtitle",
    defaultMessage: "You’ve reached your maximum number of seats"
  },
  description: {
    id: "max-seats-modals.empty-credit.description",
    defaultMessage: "you will not be able to add users{br}or update user's role"
  },
  contactSupport: {
    id: "max-seats-modals.empty-credit.btn.contact-support",
    defaultMessage: "Contact support for assistance"
  }
});

const StyledFlexContainer = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    width: 100%;
    gap: 0;
    align-items: flex-start;
  }
`;

const ProgressBarFlexColumn = styled(H1_FlexColumn)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex: 0 0 45px;
    width: 100%;
  }
`;

const Rectangle = styled.svg`
  background-color: ${({ theme }) => theme.orange4};
  transform: rotate(14deg);
  width: 311px;
  height: 153px;
  left: 30px;
  top: 88px;
  position: absolute;
  z-index: 1;
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 2;
  width: 243px;
  height: 258px;
  left: 87px;
  top: 0;
  clip-path: polygon(0% 0%, 60% 0%, 100% 0%, 100% 100%, 67% 100%, 0% 85%);
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const MaximumWorkspaceSeatsModal = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isSmallScreen } = useSmallScreen();
  const { editorModalOpen, returnToModal } = useModal();
  const theme = useTheme();

  const visible = editorModalOpen?.open === ModalName.maximumWorkspaceSeats;

  const onContactSupport = () => {
    onOpenZendesk();
    dispatch(googleEvents.maximumSeatsSupportClicked());
  };
  const onCancel = () => {
    returnToModal();
  };
  return (
    <StyledModal
      width="833px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "470px" }}
      maskClosable
      closable
      onCancel={onCancel}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="40px 62px 80px 51px"
      $alignItems="center"
    >
      <StyledFlexContainer width="100%" height="100%" align="center" gap="46px">
        <ProgressBarFlexColumn
          position="relative"
          flex="0 0 366px"
          padding="0 10px 0 0"
          height="100%"
        >
          <ConditionalRender condition={!isSmallScreen}>
            <Rectangle />
            <StyledImage src={srcImage} />
          </ConditionalRender>
        </ProgressBarFlexColumn>
        <H1_FlexColumn width="308px" justify="space-between" height="100%" padding="15px 0 0 0">
          <H1_FlexColumn gap="12px">
            <H1_TextLargeHeadline whiteSpace="break-spaces" fontWeight={700} color={theme.gray10}>
              {intl.formatMessage(messages.title)}
            </H1_TextLargeHeadline>
            <H1_TextHeadline whiteSpace="break-spaces" fontWeight={600} color={theme.gray10}>
              {intl.formatMessage(messages.subTitle)}
            </H1_TextHeadline>
            <H1_TextSmall whiteSpace="break-spaces" color={theme.gray10}>
              {
                intl.formatMessage(messages.description, {
                  br: <br />
                }) as React.ReactNode
              }
            </H1_TextSmall>
          </H1_FlexColumn>
          <Button color="primary" size="sm" onClick={onContactSupport}>
            {intl.formatMessage(messages.contactSupport)}
          </Button>
        </H1_FlexColumn>
      </StyledFlexContainer>
    </StyledModal>
  );
};

export default MaximumWorkspaceSeatsModal;
