/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import "app/components/editor/bottomDrawers/GroupDrawers.scss";
import { messages } from "app/components/editor/sideDrawers/messages";
import { DrawerProps } from "app/components/editor/sideDrawers/CommonDrawerTypes";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import TitleFlexRow from "app/components/editor/sideDrawers/CommonComponents/TitleFlexRow";
import VideoDrawerSingleMedia from "app/components/editor/sideDrawers/VideoDrawerSingleMedia";
import { INTRO_OUTRO_KEYS } from "app/types";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";

const VideoDrawer = ({ right, fieldsData, attributes, width }: DrawerProps) => {
  const intl = useIntl();
  const { editorDrawerOpen } = useDrawer();
  const isDrawerVisible = editorDrawerOpen === Drawer.Video;

  const memoFieldData = useMemo(() => {
    return fieldsData
      ? Object.values(fieldsData).filter((field) => !INTRO_OUTRO_KEYS.includes(field.key))
      : [];
  }, [fieldsData]);

  if (!memoFieldData.length) {
    return null;
  }

  return (
    <FlexDrawer
      right={right}
      active={isDrawerVisible}
      className="video-drawer-container"
      width={width}
    >
      <TitleFlexRow height="52px">
        <H1_TextMiddle>{intl.formatMessage(messages.videoTitle)}</H1_TextMiddle>
      </TitleFlexRow>
      <H1_FlexColumn flex="1 1 auto" padding="0 22px 0 0">
        <H1_FlexColumn flex="1 1 auto" width="100%" overflow="auto" gap="15px">
          {memoFieldData.map((asset) => {
            return (
              <VideoDrawerSingleMedia
                key={asset.key as string}
                title={asset.title as string}
                assetKey={asset.key as string}
                restrictions={asset.restrictions}
                url={attributes?.media?.[asset.key as string]?.url}
              />
            );
          })}
        </H1_FlexColumn>
      </H1_FlexColumn>
    </FlexDrawer>
  );
};

export default VideoDrawer;
