import { useRef, useState } from "react";

const useVideoRecorder = (options?: { maxRecordingDurationSec?: number }) => {
  const [error, setError] = useState<Error | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const recordedChunksRef = useRef<Blob[]>([]);

  const startCapture = async () => {
    try {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
      setError(null);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const recorder = new MediaRecorder(stream);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

      setMediaStream(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      recorder.onstop = () => {
        const recordedBlob = new Blob(recordedChunksRef.current, {
          type: "video/webm"
        });
        const fileName = "recorded-video.webm"; // Set the desired file name
        const fileType = "video/webm"; // Set the desired file type (optional)

        const recordedFile = blobToFile(recordedBlob, fileName, fileType);

        setVideoFile(recordedFile);

        if (videoRef.current) {
          videoRef.current.srcObject = null;
          videoRef.current.src = URL.createObjectURL(recordedBlob);
        }
      };
    } catch (error) {
      setError(error as Error);
      console.error("Error accessing camera:", error);
    }
  };

  const handleStartStopRecording = () => {
    if (mediaRecorder) {
      if (isRecording) {
        mediaRecorder.stop();
        setIsRecording(false);
      } else {
        recordedChunksRef.current = [];
        mediaRecorder.start();
        setIsRecording(true);
        if (videoRef.current) {
          videoRef.current.srcObject = mediaRecorder.stream;
        }

        if (options?.maxRecordingDurationSec) {
          setTimeout(() => {
            if (mediaRecorder.state === "recording") {
              mediaRecorder.stop();
              setIsRecording(false);
            }
          }, options.maxRecordingDurationSec * 1000);
        }
      }
    }
  };

  const reset = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setError(null);
  };

  return {
    isRecording,
    startCapture,
    handleStartStopRecording,
    videoRef,
    videoFile,
    error,
    reset,
    ready: !!(mediaRecorder && !error)
  };
};

export default useVideoRecorder;

function blobToFile(blob: Blob, fileName: string, fileType?: string): File {
  const options: FilePropertyBag = { type: fileType || blob.type };
  const file = new File([blob], fileName, options);
  return file;
}
