import React, { useEffect, useMemo } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { ReactComponent as HourOneNewLogo } from "app/assets/sider/hourone-new-logo.svg";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as videosSelector from "app/store/selectorsV2/videos.selectors";
import { fetchingStatus } from "app/utils/helpers";
import { MenuProps, Progress } from "antd";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { mobileHomepage } from "app/pages/HomePage/messages";
import { Location, SocialMedia, VideoStatusEnum } from "app/types";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import useModal, { ModalName } from "app/hooks/useModal";
import { videoReadyModalMessages } from "app/pages/editor/messages";
import useShareSocialMedia from "app/hooks/useShareSocialMedia";
import { useNavigate, useParams } from "react-router-dom";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Dropdown } from "app/components/_Infrastructure/design-system/dropdown";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { Button } from "@nextui-org/react";

const GreenHourOneNewLogo = styled(HourOneNewLogo)`
  fill: ${({ theme }) => theme.green4};
`;
const OrangeHourOneNewLogo = styled(HourOneNewLogo)`
  fill: ${({ theme }) => theme.orange4};
`;
const BlueHourOneNewLogo = styled(HourOneNewLogo)`
  fill: ${({ theme }) => theme.blue4};
`;

const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25);
  }
`;

const MinHeightFlexRow = styled(H1_FlexRow)`
  min-height: 0; /* Fix bug in Safari */
`;

const VideoPreview = styled(H1_FlexRow)`
  min-height: 0; /* Fix bug in Safari */
  transition: 0.3s ease-in-out all;
  background-color: transparent;
  aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
  width: ${(props: { $aspectRatio: number }) => (props.$aspectRatio > 1 ? "100%" : "auto")};
  height: ${(props: { $aspectRatio: number }) => (props.$aspectRatio > 1 ? "auto" : "100%")};
  &&& mux-player {
    aspect-ratio: ${(props: { $aspectRatio: number }) => props.$aspectRatio};
    max-height: 452px;
  }
  & > * {
    flex: 1;
  }
`;

const CircleIconContainer = styled(H1_FlexRow)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-flex;
`;

const TwitterCircle = styled(CircleIconContainer)`
  background-color: rgb(29, 155, 240);
`;

const FacebookCircle = styled(CircleIconContainer)`
  background-color: #365896;
`;
const WhatsappCircle = styled(CircleIconContainer)`
  background-color: #25d366;
`;
const LinkedInCircle = styled(CircleIconContainer)`
  background-color: #0a66c2;
`;

const BrandIcon = styled.i`
  color: white;
  font-size: 14px;
`;

const StyledDropdown = styled(H1_Dropdown)`
  height: 50px;
  width: 100%;
  border-radius: 2px;
  color: ${(props) => props.theme.gray1};
  background-color: ${({ theme }) => theme.blue4};
  justify-content: center;
  i {
    color: ${(props) => props.theme.gray1};
  }
  &:hover {
    background-color: ${(props) => props.theme.blue2};
    color: ${(props) => props.theme.gray1};
    i {
      color: ${(props) => props.theme.gray1};
    }
  }
  padding: 0 1rem;
  &.overlay-dropdown {
    box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
    .ant-dropdown {
      width: 250px;
    }
    max-height: 370px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray4};
    font-size: 12px;
    i {
      font-size: 16px;
    }
    .ant-dropdown-menu-item {
      padding: 11px 12px;
      i {
        color: ${({ theme }) => theme.gray1};
      }
      &:hover {
        background-color: ${({ theme }) => theme.blue4};
        color: ${({ theme }) => theme.gray1};
        i {
          color: ${({ theme }) => theme.gray1};
        }
      }
    }
    .ant-dropdown-menu-item-group-title {
      padding: 11px 12px;
    }
  }
`;

const MobileCreatePage = () => {
  const { closeModal } = useModal();
  const { formatMessage } = useIntl();
  const { shareLink } = useShareSocialMedia();
  const { draftId } = useParams<{ draftId: string }>();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const draftStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const lastVideo = useAppSelector(videosSelector.getLastVersionVideo);
  const isProgressDisplayed = !!(
    lastVideo &&
    lastVideo.progress &&
    lastVideo.progress > 0 &&
    lastVideo.progress < 100
  );
  const videoCreateStatus = useAppSelector(({ videos }) => videos.videoCreateStatus);
  const isDraftLoading = draftStatus === fetchingStatus.loading;
  const isVideoLoading =
    isDraftLoading ||
    videoCreateStatus === fetchingStatus.loading ||
    isProgressDisplayed ||
    lastVideo?.status === VideoStatusEnum.Processing ||
    lastVideo?.status === VideoStatusEnum.NotStarted;
  const isVideoReady =
    lastVideo?.status === VideoStatusEnum.Ready || lastVideo?.status === VideoStatusEnum.Published;
  const aspectRatio =
    (lastVideo?.aspect_ratio?.width || 0) / (lastVideo?.aspect_ratio?.height || 1) || 16 / 9;

  useEffect(() => {
    closeModal();
    dispatch(draftsActionsV2.startWorkingOnDraft(draftId as string));
  }, []);

  const onClickTwitter = () => {
    shareLink(
      "mobile",
      { req_id: lastVideo.req_id, id: lastVideo.id, status: lastVideo.status as string },
      SocialMedia.twitter,
      ModalName.videoReady
    );
  };

  const onClickFacebook = () => {
    shareLink(
      "mobile",
      { req_id: lastVideo.req_id, id: lastVideo.id, status: lastVideo.status as string },
      SocialMedia.facebook,
      ModalName.videoReady
    );
  };

  const onClickLinkedIn = () => {
    shareLink(
      "mobile",
      { req_id: lastVideo.req_id, id: lastVideo.id, status: lastVideo.status as string },
      SocialMedia.linkedin,
      ModalName.videoReady
    );
  };

  const onClickWhatsapp = () => {
    shareLink(
      "mobile",
      { req_id: lastVideo.req_id, id: lastVideo.id, status: lastVideo.status as string },
      SocialMedia.whatsapp,
      ModalName.videoReady
    );
  };

  const onClickPersonalize = () => {
    dispatch(analyticsEvents.navigateToEditor({ source: "mobile_create_page" }));
    navigate(`${Location.Editor}/${draftId}`);
  };

  const items: MenuProps["items"] = useMemo(() => {
    const initialItems: MenuProps["items"] = [
      {
        className: "share-menu clickable",
        key: "shareOnLinkedIn",
        icon: (
          <LinkedInCircle>
            <BrandIcon className="fa-brands fa-linkedin-in" />
          </LinkedInCircle>
        ),
        onClick: onClickLinkedIn,
        label: formatMessage(videoReadyModalMessages.shareToLinkedIn)
      },
      {
        className: "share-menu clickable",
        key: "shareOnTwitter",
        icon: (
          <TwitterCircle>
            <BrandIcon className="fa-brands fa-twitter" />
          </TwitterCircle>
        ),
        onClick: onClickTwitter,
        label: formatMessage(videoReadyModalMessages.shareToTwitter)
      },
      {
        className: "share-menu clickable",
        key: "shareOnFacebook",
        icon: (
          <FacebookCircle>
            <BrandIcon className="fa-brands fa-facebook-f" />
          </FacebookCircle>
        ),
        onClick: onClickFacebook,
        label: formatMessage(videoReadyModalMessages.shareToFacebook)
      },
      {
        className: "share-menu clickable",
        key: "shareOnWhatsapp",
        icon: (
          <WhatsappCircle>
            <BrandIcon className="fa-brands fa-whatsapp" />
          </WhatsappCircle>
        ),
        onClick: onClickWhatsapp,
        label: formatMessage(videoReadyModalMessages.shareToWhatsapp)
      }
    ];

    return initialItems;
  }, [lastVideo]);

  if (isVideoLoading) {
    return (
      <H1_FlexColumn align="center" width="100%" padding="100px 20px 0" gap="20px">
        <H1_FlexColumn align="center" width="100%">
          <GreenHourOneNewLogo height={50} width="100%" />
          <H1_FlexRow align="center" gap="20px" padding="20px 0 25px 0">
            <OrangeHourOneNewLogo height={50} width="100%" />
            <BlueHourOneNewLogo height={50} width="100%" />
          </H1_FlexRow>
        </H1_FlexColumn>
        <ConditionalRender condition={isProgressDisplayed}>
          <StyledProgress
            strokeColor={theme.orange4}
            trailColor={theme.gray5}
            percent={lastVideo?.progress || 0}
            strokeWidth={16}
          />
        </ConditionalRender>
        <ConditionalRender condition={isProgressDisplayed}>
          <H1_TextMiddle>{formatMessage(mobileHomepage.creatingVideo)}</H1_TextMiddle>
        </ConditionalRender>
        <ConditionalRender condition={!isProgressDisplayed}>
          <H1_TextMiddle>{formatMessage(mobileHomepage.loading)}</H1_TextMiddle>
        </ConditionalRender>
        <H1_TextSmall>{formatMessage(mobileHomepage.mayTake)}</H1_TextSmall>
      </H1_FlexColumn>
    );
  }

  if (isVideoReady) {
    return (
      <H1_FlexColumn align="center" width="100%" padding="100px 20px 0" gap="20px">
        <H1_TextMiddle>{formatMessage(mobileHomepage.checkVideo)}</H1_TextMiddle>
        <MinHeightFlexRow
          flex="1 1 auto"
          justify="center"
          width={aspectRatio > 1 ? "100%" : "auto"}
          height={aspectRatio > 1 ? "auto" : "calc(100% - 104px)"}
        >
          <VideoPreview
            $aspectRatio={aspectRatio}
            align="center"
            justify="center"
            className="video-preview-container"
          >
            <MuxPlayer
              shape={aspectRatio > 1 ? "landscape" : "portrait"}
              templateFlavor={lastVideo?.pipeline_request?.flavor_id}
              reqId={lastVideo?.pipeline_response?.req_id as string}
            />
          </VideoPreview>
        </MinHeightFlexRow>
        <StyledDropdown
          withChevron={false}
          icon={<i className="fa-solid fa-share" />}
          title={formatMessage(videoReadyModalMessages.shareButton)}
          destroyPopupOnHide
          menu={{ items }}
          trigger={["click", "hover"]}
          className="overlay-dropdown"
          getPopupContainer={() => document.querySelector(`.overlay-dropdown`) as HTMLElement}
        />
        <Button onClick={onClickPersonalize}>
          {formatMessage(mobileHomepage.personalizedButton)}
        </Button>
        <H1_TextSmall color={theme.gray7} whiteSpace="normal">
          {formatMessage(mobileHomepage.tryDesktop)}
        </H1_TextSmall>
      </H1_FlexColumn>
    );
  }

  return (
    <H1_FlexColumn gap="20px" justify="center" align="center">
      <H1_TextMiddle>Video failed</H1_TextMiddle>
      <H1_TextSmall>
        Please contact <a href="mailto:support@hourone.ai">support@hourone.ai</a>
      </H1_TextSmall>
      <H1_TextXs whiteSpace="normal">video draft id - {draftId}</H1_TextXs>
    </H1_FlexColumn>
  );
};

export default MobileCreatePage;
