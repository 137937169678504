import React, { useEffect, useState } from "react";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { useTheme } from "styled-components";
import WebhookForm from "app/pages/webhooks/WebhookForm";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { webhooksActions } from "app/store/slices/webhooks.slice";
import { Webhook, WebHookRequest } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { validateWebHook } from "app/components/editor/validations";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextHeadline, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import { CreateWebhookMessages } from "app/pages/webhooks/messages";
import { useIntl } from "react-intl";
import { StyledModal } from "app/components/common/StyledModal";

interface CreateWebhookModalProps {
  visible: boolean;
  onClose: () => void;
}

const CreateWebhookModal = ({ visible, onClose }: CreateWebhookModalProps) => {
  const [errors, setErrors] = useState<Record<keyof Webhook, string>>(
    {} as Record<keyof Webhook, string>
  );
  const [webhook, setWebhook] = useState<Partial<Webhook>>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const createWebhookStatus = useAppSelector((state) => state.webhooks.createWebhookStatus);
  const isLoading = createWebhookStatus === fetchingStatus.loading;

  useEffect(() => {
    if (createWebhookStatus === fetchingStatus.succeeded) {
      dispatch(webhooksActions.setCreateStatusToIdle());
      onClose();
    } else if (createWebhookStatus === fetchingStatus.failed) {
      dispatch(webhooksActions.setCreateStatusToIdle());
    }
  }, [createWebhookStatus]);

  const onSave = () => {
    const errors = validateWebHook(webhook as WebHookRequest);
    if (Object.keys(errors).length === 0) {
      dispatch(webhooksActions.createWebhookRequest(webhook as WebHookRequest));
    }
    setErrors(errors);
  };

  const onChange = (prop: keyof Webhook, data: any) => {
    setWebhook({ ...webhook, [prop]: data });
  };

  return (
    <StyledModal
      width="461px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      bodyStyle={{ height: "560px" }}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="54px 46px 40px"
      $flex="1"
      $gap="23px"
      $alignItems="center"
    >
      <H1_FlexColumn justify="space-between" flex="1">
        <H1_FlexColumn gap="19px" padding={"0px 0px 10px 0px"}>
          <H1_TextHeadline color={theme.gray10}>
            {formatMessage(CreateWebhookMessages.title)}
          </H1_TextHeadline>
          <H1_TextSmall whiteSpace="break-spaces">
            {formatMessage(CreateWebhookMessages.subtitle)}
          </H1_TextSmall>
        </H1_FlexColumn>
        <WebhookForm onChange={onChange} errors={errors} />
        <Button color="primary" isLoading={isLoading} onClick={onSave}>
          {formatMessage(CreateWebhookMessages.saveBtn)}
        </Button>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default CreateWebhookModal;
