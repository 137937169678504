import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import StatusTag from "app/pages/EditDraftPage/workflow/Statustag";
import React from "react";
import { getWorkflowSubmissionById } from "app/store/selectorsV2/drafts.selectors";
import { useAppSelector } from "app/hooks";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import { getTimeAgo } from "app/utils/helpers";
import { Divider } from "antd";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import styled from "styled-components";
import GoBackButton from "app/components/common/GoBackButton";
import WorkflowPlayer from "app/components/editor/workflows/WorkflowPlayer";

const BackButtonWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 10px;
`;

const DisabledInput = styled(H1_Input)`
  && input:disabled {
    background-color: ${({ theme }) => theme.gray1};
    color: ${({ theme }) => theme.gray7};
  }
`;

export interface SubmissionViewProps {
  submissionId: string;
  onBack: () => void;
}
const SubmissionView = ({ submissionId, onBack }: SubmissionViewProps) => {
  const submission = useAppSelector((state) =>
    getWorkflowSubmissionById(state, submissionId as string)
  );

  const video = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, submission?.video?.id as string)
  );

  const variables = useAppSelector((state) => state.drafts.currentDraft.variables);

  return (
    <H1_FlexRow gap={"87px"} width={"100%"}>
      <BackButtonWrapper>
        <GoBackButton onClick={onBack} fontSize={"16px"} />
      </BackButtonWrapper>
      <H1_FlexColumn gap={"35px"}>
        <H1_FlexRow justify={"space-between"}>
          <H1_TextXs>Status</H1_TextXs>
          <StatusTag>
            <H1_TextXs color={"#595959"} fontWeight={400}>
              {video ? video.status : "not created"}
            </H1_TextXs>
          </StatusTag>
        </H1_FlexRow>
        <H1_FlexColumn>
          <H1_TextSmall>{getTimeAgo(submission?.created_at)}</H1_TextSmall>
          <H1_TextSmall>{submission?.email || "anonymous"}</H1_TextSmall>
          <Divider />
        </H1_FlexColumn>
        {variables?.map((variable) => {
          const value =
            submission?.variables.find((v) => v.variable_id === variable.id)?.value || "";
          return (
            <H1_FlexColumn key={variable.id} gap={"5px"}>
              <H1_TextSmall>{variable.display_title || variable.name}</H1_TextSmall>
              <DisabledInput disabled initialValue={value}></DisabledInput>
            </H1_FlexColumn>
          );
          // variable.dis
        })}
      </H1_FlexColumn>
      <WorkflowPlayer videoId={submission?.video?.id} />
    </H1_FlexRow>
  );
};

export default SubmissionView;
