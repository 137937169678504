export interface ValidationErrors {
  [key: string]: {
    type: string;
    message: string | React.ReactNode;
    description: string | React.ReactNode;
    btn?: React.ReactNode;
    icon?: JSX.Element;
    modId?: string;
    context?: unknown;
    duration?: number;
  };
}

export type SceneValidationErrors = Record<string, ValidationErrors>;

export interface Notification {
  message: string | React.ReactNode;
  placement?: string;
  style?: React.CSSProperties;
  closeIcon?: React.ReactNode;
  duration?: number;
  key?: string;
  type?: "success" | "loading" | "error" | null;
  onClose?: () => void;
  className?: string;
}

export enum MimeType {
  mp4 = "video/mp4",
  mp3 = "audio/mpeg",
  wav = "audio/wav",
  mov = "video/quicktime",
  allImages = "image/*",
  allowedImages = "image/jpg,image/jpeg,image/png",
  presentations = "application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint",
  document = "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  csv = "text/csv"
}
