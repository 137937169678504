import React from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import IntegrationsCard from "app/pages/IntegrationsPage/IntegrationsCard";
import { integrationsMessages } from "app/pages/IntegrationsPage/messages";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { onOpenKnowledgeBase } from "app/utils/zendesk/zendesk";
import { apiDocsUrl } from "app/utils/urls";

const IntegrationsPage = () => {
  const { formatMessage } = useIntl();
  const onClickApi = () => {
    window.open(apiDocsUrl, "_blank");
  };
  const onClickZappier = () => {
    onOpenKnowledgeBase();
  };
  const onClickSlack = () => {
    onOpenKnowledgeBase();
  };
  const onClickGmail = () => {
    onOpenKnowledgeBase();
  };
  const onClickChatGPT = () => {
    // Does nothing for now
  };
  return (
    <H1_FlexColumn padding="36px" flex="1" overflow="auto">
      <H1_FlexRow wrap="wrap" gap="28px 34px">
        <IntegrationsCard
          image="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/api_integrations.png"
          imageSize="14px"
          headline={formatMessage(integrationsMessages.apiTitle)}
          content={formatMessage(integrationsMessages.apiContent)}
          onClick={onClickApi}
        />
        <IntegrationsCard
          image="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/zappier_integrations.png"
          headline={formatMessage(integrationsMessages.zappierTitle)}
          content={formatMessage(integrationsMessages.zappierContent)}
          onClick={onClickZappier}
        />
        <IntegrationsCard
          image="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/slack_integrations.png"
          headline={formatMessage(integrationsMessages.slackTitle)}
          content={formatMessage(integrationsMessages.slackContent)}
          onClick={onClickSlack}
        />
        <IntegrationsCard
          image="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/gmail_integrations.png"
          headline={formatMessage(integrationsMessages.gmailTitle)}
          content={formatMessage(integrationsMessages.gmailContent)}
          onClick={onClickGmail}
        />
        <IntegrationsCard
          image="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/chatgpt_integrations.png"
          headline={formatMessage(integrationsMessages.chatGPTTitle)}
          content={formatMessage(integrationsMessages.chatGPTContent)}
          onClick={onClickChatGPT}
          buttonText={formatMessage(integrationsMessages.comingSoon)}
          disabled
        />
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default IntegrationsPage;
