import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import TemplatePreviewThumbnail from "app/components/editor/templateSelect/TemplatePreviewThumbnail";
import { FlatRecipe } from "app/types";
import useModal, { ModalName } from "app/hooks/useModal";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useAppDispatch } from "app/hooks";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useInView } from "react-intersection-observer";
import ConditionalRender from "app/components/common/ConditionalRender";

const TemplateContainerFlexColumn = styled(H1_FlexColumn)`
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  background-color: ${(props) => props.theme.gray1};
  .template-video {
    border: none;
    border-radius: 4px 4px 0 0;
  }
  &:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    .template-video {
      filter: brightness(80%);
    }
  }
  &:active {
    box-shadow: none;
  }
`;
const SingleTemplateInCollectionSwiper = ({ recipe }: { recipe: FlatRecipe }) => {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true
  });

  const selectRecipe = (recipe: FlatRecipe) => {
    const { title, categoryName, id, recipeType } = recipe;
    dispatch(
      analyticsEvents.templateSelectedModal({
        source: "templates-collection",
        templateName: title,
        category: categoryName
      })
    );
    openModal(ModalName.templatePreview, {
      recipeId: id,
      recipeType: recipeType,
      recipeCategoryName: categoryName,
      workflowMode: true
    });
  };

  return (
    <TemplateContainerFlexColumn
      gap="13px"
      onClick={() => selectRecipe(recipe)}
      position="relative"
      ref={ref}
    >
      <ConditionalRender condition={inView}>
        <TemplatePreviewThumbnail
          width="calc(160px * 16 / 9)"
          height="160px"
          badgeText={recipe.pro ? String(recipe.plans[0] ?? "").toUpperCase() : undefined}
          badgeIcon={<CrownIcon />}
          alt=""
          video={recipe.video}
          image={recipe.image}
          tags={recipe.tags}
          borderRadius="9px"
        />
        <H1_FlexRow padding="0 0 12px 20px">
          <H1_TextSmall color={theme.gray11}>{recipe.title}</H1_TextSmall>
        </H1_FlexRow>
      </ConditionalRender>
    </TemplateContainerFlexColumn>
  );
};

export default SingleTemplateInCollectionSwiper;
