import { TemplateLayout } from "app/types";
import styled from "styled-components";

const PreviewContainer = styled.div`
  border-radius: 9px;
  --aspect-ratio: 9/16;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

export interface LayoutDrawerPreviewProps {
  selectedLayout: TemplateLayout;
}

const LayoutDrawerPreview = ({ selectedLayout }: LayoutDrawerPreviewProps) => {
  return (
    <PreviewContainer className="preview">
      <StyledImage
        src={selectedLayout.thumbnail_image}
        alt="selected"
        onLoad={(a) => {
          const targetElm = a.target as HTMLElement;
          const targetParent = targetElm.parentElement as HTMLElement;
          const contH = targetParent.getBoundingClientRect().height as number | null;
          const selfH = targetElm.offsetHeight as number | null;
          if (contH && selfH && contH < selfH) {
            targetElm.style.top = `${(contH - selfH) / 2}px`;
          }
        }}
      />
    </PreviewContainer>
  );
};

export default LayoutDrawerPreview;
