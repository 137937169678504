import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";
import { H1_TextLargeHeadline, H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { useIntl } from "react-intl";
import { homePageWorkspaceMessages } from "app/pages/HomePage/messages";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { HeaderContext } from "app/hoc/HeaderContext";
import useDisplayUrls from "app/hooks/useDisplayUrls";
import ConditionalRender from "app/components/common/ConditionalRender";
import { brandKitActions } from "app/store/slices/brandKit.slice";
import { fetchingStatus } from "app/utils/helpers";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import PublishedVideosStrip from "app/pages/HomePage/PublishedVideosStrip";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
`;
const BannerFlexRow = styled(H1_FlexRow)`
  border-radius: 10px;
  background: linear-gradient(236deg, #1c1c1c 0%, #5b5b91 100%);
`;
const LogoBox = styled(H1_FlexRow)`
  border-radius: 10px;
  background-color: transparent;
`;

const StyledImage = styled.img`
  border-radius: 10px;
  max-width: 168px;
  max-height: 168px;
  height: auto;
  &:hover img {
    opacity: 0.5;
  }
`;
const BorderedButton = styled(Button)`
  border-radius: 40px;
  color: white;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0.14) 1.15%,
    rgba(255, 255, 255, 0.14) 98.91%
  );
`;

const HomePageWorkspace = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setIsOpenBrandHub } = useContext(HeaderContext);
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const brandKit = useAppSelector((state) => state.brandKit.currentBrandkit);
  const brandkitStatus = useAppSelector((state) => state.brandKit.brandkitStatus);
  const { displayUrls } = useDisplayUrls([brandKit.logo?.url]);

  const isLoadingBrandkit = brandkitStatus === fetchingStatus.loading;
  useEffect(() => {
    dispatch(brandKitActions.getBrandKitRequest());
  }, []);

  const onClickManageWorkspace = () => {
    navigate(`/workspaces/${currentWorkspace?.id}`);
  };

  const onClickAddBrandKit = () => {
    setIsOpenBrandHub(true);
  };

  return (
    <BackgroundFlexColumn padding="0 50px" flex="1">
      <BannerFlexRow padding="40px" justify="space-between" height="250px">
        <H1_FlexRow gap="38px" align="center">
          <LogoBox justify="center" align="center">
            <ConditionalRender condition={isLoadingBrandkit}>
              <CircleLoader />
            </ConditionalRender>
            <ConditionalRender
              condition={
                !!(brandKit.logo?.url && displayUrls[brandKit.logo?.url]?.url) && !isLoadingBrandkit
              }
            >
              <StyledImage src={displayUrls[brandKit.logo?.url as string]?.url} />
            </ConditionalRender>
          </LogoBox>
          <H1_FlexColumn>
            <H1_TextLargeHeadline color="white">{currentWorkspace?.name}</H1_TextLargeHeadline>
            <H1_TextMiddle color="white">
              {formatMessage(homePageWorkspaceMessages.numOfMembers, {
                number: currentWorkspace?.members?.length || 0
              })}
            </H1_TextMiddle>
          </H1_FlexColumn>
        </H1_FlexRow>
        <H1_FlexRow align="flex-end" gap="15px">
          <BorderedButton onClick={onClickManageWorkspace}>
            {formatMessage(homePageWorkspaceMessages.manageWorkspace)}
          </BorderedButton>
          <BorderedButton onClick={onClickAddBrandKit}>
            {formatMessage(homePageWorkspaceMessages.addBrandKit)}
          </BorderedButton>
        </H1_FlexRow>
      </BannerFlexRow>
      <PublishedVideosStrip />
    </BackgroundFlexColumn>
  );
};

export default HomePageWorkspace;
