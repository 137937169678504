import { Space } from "antd";

import styled from "styled-components";

const Title = styled.div`
  color: #8c8c8c;
  font-size: 11l;
`;
interface LabelProps {
  label: string;
  children: React.ReactNode;
}

const Label = ({ label, children }: LabelProps) => {
  return (
    <Space size={0} direction="vertical" style={{ width: "100%" }}>
      <Title>{label}</Title>
      {children}
    </Space>
  );
};
export default Label;
