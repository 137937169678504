import { defineMessages } from "react-intl";

export const templatesMessages = defineMessages({
  listTitle: {
    id: `template-select.side-menu.list-label`,
    defaultMessage: "Use Cases"
  },
  customTitle: {
    id: `template-select.side-menu.custom-label`,
    defaultMessage: "Custom"
  },
  personalTitle: {
    id: `template-select.side-menu.personal-label`,
    defaultMessage: "Personal"
  },
  deleteTemplate: {
    id: `template-select.side-menu.remove-template`,
    defaultMessage: "Delete Template"
  },
  deleteTemplateSucceeded: {
    id: `template-select.remove-template-succeeded`,
    defaultMessage: "Template deleted successfully!"
  },
  allTemplatesButton: {
    id: `template-select.side-menu.buttons.all-templates-button`,
    defaultMessage: "All"
  },
  presentationButton: {
    id: `template-select.side-menu.buttons.presentation-button`,
    defaultMessage: "Import Presentation"
  },
  backToTemplatesLink: {
    id: `template-select.use-case.back-to-templates-link`,
    defaultMessage: "Back To Templates"
  },
  premiumFeature: {
    id: `template-select.notifications.premium-feature`,
    defaultMessage: "You discovered a pro feature!"
  },
  chooseDraftNameFormHeader: {
    id: `template-select.use-case.choose-draft-name-form-header`,
    defaultMessage: `Choose Video Name`
  },
  untitledPlaceholder: {
    id: `template-select.use-case.untitled-placeholder`,
    defaultMessage: `Untitled`
  },
  chooseFolderFormHeader: {
    id: `template-select.use-case.choose-folder-form-header`,
    defaultMessage: `Choose Project`
  },
  selectFolderPlaceholder: {
    id: `template-select.use-case.select-folder-placeholder`,
    defaultMessage: `Select a Project`
  },
  useBlankTemplateButton: {
    id: `template-select.use-case.use-blank-template`,
    defaultMessage: `Use blank template`
  },
  useThisTemplateButton: {
    id: `template-select.use-case.buttons.use-this-template`,
    defaultMessage: `Use this template`
  },
  realsExplanation: {
    id: `template-select.use-case.reals-explanation`,
    defaultMessage: `Here you can see a video example of your use case with pre-filled content, colors and presenter.`
  },
  upgradeText: {
    id: `template-select.use-case.upgrade-text`,
    defaultMessage: `Create amazing templates`
  },
  upgradeSubtitleText: {
    id: `template-select.use-case.upgrade-subtitle-text`,
    defaultMessage: `Upgrade to Enterprise in order to use transparent templates`
  },
  searchTemplatePlaceholder: {
    id: `template-select.search-template-placeholder`,
    defaultMessage: `Search template by name or category`
  },
  noTemplatesFoundTitle: {
    id: `template-select.search-template.response.not-found.title`,
    defaultMessage: `Got no templates with this title`
  },
  noTemplatesFoundAction: {
    id: `template-select.search-template.response.not-found.action`,
    defaultMessage: "Try a different search query"
  },
  supportMyVirtualHuman: {
    id: `template-select.checkbox.support-my-virtual-human`,
    defaultMessage: "My presenter"
  }
});

export const templatesRequestMessages = defineMessages({
  templatesRequestTitle: {
    id: `template-select.request-form.title`,
    defaultMessage: "What use case do you need?"
  },
  templatesRequestInputPlaceholder: {
    id: `template-select.request-form.input-placeholder`,
    defaultMessage: "Type here..."
  },
  templateRequestSendButton: {
    id: `template-select.request-form.send-button`,
    defaultMessage: "Send"
  },
  templatesRequestThankYou: {
    id: `template-select.request-form.thank-you`,
    defaultMessage: "Thank you"
  }
});
