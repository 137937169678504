/* eslint-disable react/require-default-props */

import { Carousel } from "antd";
import { MessageDescriptor, useIntl } from "react-intl";
import VideoWithPoster from "app/components/common/VideoWithPoster";
import messages from "app/components/editor/sideDrawers/EditingProjectMediaLibrary/messages";
import { MediaUrl } from "app/hooks/useDisplayUrls";
import styled from "styled-components";
import { ReactNode, useState } from "react";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";

interface PreviewProps {
  previewUrls: MediaUrl[];
  previewMessage?: MessageDescriptor;
  autoplay?: boolean;
  defaultPreview?: boolean;
  isFill?: boolean;
  className?: string;
}

export const RatioVideo = styled(H1_FlexColumn)<{ $aspectRatio: number }>`
  border-radius: 9px;
  background-color: ${(props) => props.theme.gray3};
  position: relative;
  overflow: hidden;
  flex: 1;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    background-color: transparent;
  }
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
    padding-top: calc((9 / 16) * 100%);
    @media (max-width: ${SMALL_SCREEN_PX}) {
      background-color: ${(props) => props.theme.gray3};
      padding-top: calc((9 / 16) * 160px);
      width: 160px;
      margin: 0 auto;
    }
  }
  video {
    transition: opacity 0.3s ease-in-out;
    object-fit: ${({ $aspectRatio }) => ($aspectRatio > 4 / 3 ? "fill" : "contain")};
    opacity: ${({ $aspectRatio }) => ($aspectRatio > 0 ? 1 : 0)};
  }
`;

const RatioImage = styled(H1_FlexColumn)<{ $aspectRatio: number }>`
  border-radius: 9px;
  background-color: ${(props) => props.theme.gray3};
  position: relative;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
    width: 100%;
  }
  img {
    transition: opacity 0.3s ease-in-out;
    object-fit: ${({ $aspectRatio }) => ($aspectRatio > 4 / 3 ? "fill" : "contain")};
    opacity: ${({ $aspectRatio }) => ($aspectRatio > 0 ? 1 : 0)};
  }
`;

const renderPreviewCarusel = (urls: MediaUrl[], intl: any) => {
  return (
    <Carousel autoplay style={{ height: 200 }}>
      {urls.map(({ url }, idx) => (
        <div
          key={`preview-carusel-item-${idx}`}
          className="preview-carusel-item"
          data-image-src={url}
          style={{
            backgroundImage: `url("${url}")`
          }}
        >
          <span>{intl.formatMessage(messages.carouselTag, { row: idx + 1 })}</span>
        </div>
      ))}
    </Carousel>
  );
};

export const renderPreviewVideo = (
  url: string,
  isFill: boolean,
  autoplay: boolean,
  className?: string
) => {
  const [aspectRatio, setAspectRatio] = useState(0);
  const handleVideoLoaded = (ref: HTMLVideoElement) => {
    if (ref) {
      const width = ref.videoWidth;
      const height = ref.videoHeight;

      const ratio = width / height;

      setAspectRatio(ratio);
    }
  };
  return (
    <RatioVideo
      $aspectRatio={aspectRatio}
      className={`${className ? className + " " : ""}preview-video-container`}
    >
      <VideoWithPoster
        disableIcon
        autoplay={autoplay}
        video={url}
        onLoaded={handleVideoLoaded}
        containerStyle={{
          display: "flex",
          justifyContent: "center"
        }}
        style={{
          position: "absolute",
          bottom: 0,
          height: "100%"
        }}
      />
    </RatioVideo>
  );
};
export const renderPreviewImage = (url: string, isFill: boolean, className?: string) => {
  const [aspectRatio, setAspectRatio] = useState(0);

  const handleImageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const target = e.currentTarget as HTMLImageElement;
    const width = target.naturalWidth;
    const height = target.naturalHeight;

    const ratio = width / height;

    setAspectRatio(ratio);
  };

  return (
    <RatioImage className={className} $aspectRatio={aspectRatio}>
      <img onLoad={handleImageLoaded} src={url} alt="selected" />
    </RatioImage>
  );
};
/*DEPRECATED COMPONENT*/

const Preview = ({
  previewUrls,
  previewMessage,
  autoplay = false,
  defaultPreview = true,
  isFill = false,
  className
}: PreviewProps) => {
  const intl = useIntl();

  const renderDefaultPreview = () => {
    return (
      <div className="preview-default-media preview">
        {previewMessage && // @ts-ignore handels formmating issue with html tags params
          (intl.formatMessage(previewMessage, {
            b: (str) => <span style={{ fontWeight: 200, marginRight: "8px" }}>{str}</span>
          }) as ReactNode)}
      </div>
    );
  };

  if (!previewUrls || !previewUrls.length) {
    if (defaultPreview) {
      return renderDefaultPreview();
    }
    return null;
  }

  if (previewUrls.length > 1) {
    return renderPreviewCarusel(previewUrls, intl);
  }

  const isVideo = previewUrls[0].assetType === "video";
  if (isVideo) {
    return renderPreviewVideo(previewUrls[0].url, isFill, autoplay);
  }

  return renderPreviewImage(previewUrls[0].url, isFill, className);
};

export default Preview;
