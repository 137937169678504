import { HeaderContext, HeaderContextType } from "app/hoc/HeaderContext";
import { useDebounce } from "@react-hook/debounce";
import { useState } from "react";
import { HeaderTab } from "app/types";

const HeaderContextWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [searchValue, setSearchValue] = useDebounce<string>("", 500);
  const [activeHeaderTab, setActiveHeaderTab] = useState<HeaderTab>(HeaderTab.home);
  const [isOpenBrandHub, setIsOpenBrandHub] = useState<boolean>(false);

  return (
    <HeaderContext.Provider
      value={
        {
          searchValue,
          setSearchValue,
          activeHeaderTab,
          setActiveHeaderTab,
          isOpenBrandHub,
          setIsOpenBrandHub
        } as HeaderContextType
      }
    >
      {children}
    </HeaderContext.Provider>
  );
};
export default HeaderContextWrapper;
