import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { profileMessages } from "app/pages/profile/messages";
import useCredits from "app/hooks/useCredits";
import { Progress } from "antd";
import { useUpgradeByPlan } from "app/hooks/useUpgradeModal";
import OppositeUpgradeButton from "app/components/common/UpgradeButton/OppositeUpgradeButton";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ThemeMode } from "app/utils/theme";

const PlanCardFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
`;

const MinutesCard = () => {
  const [mouseHoverMinutes, setMouseHoverMinutes] = useState(false);
  const intl = useIntl();
  const upgradeModal = useUpgradeByPlan();
  const theme = useTheme();
  const {
    availableInMinutes,
    videoPercent,
    videoTotalNumberInMinutes,
    videoPercentRemained,
    isUnlimited
  } = useCredits();

  const onMouseEnter = () => {
    setMouseHoverMinutes(true);
  };

  const onMouseLeave = () => {
    setMouseHoverMinutes(false);
  };

  const onClickUpgrade = () => {
    upgradeModal({ source: `upgrade_profile_page` });
  };

  return (
    <H1_FlexColumn
      flex="0 0 auto"
      gap="6px"
      padding="3px 20px 0 0"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <H1_TextSmall color={theme.gray9} fontWeight={500}>
        {intl.formatMessage(profileMessages.minuteUsage)}
      </H1_TextSmall>
      <PlanCardFlexRow align="center" justify="space-between" gap="30px">
        <H1_FlexColumn width="100%">
          <ConditionalRender condition={isUnlimited}>
            <H1_TextXs whiteSpace="break-spaces" color={theme.gray8}>
              {intl.formatMessage(profileMessages.unlimitedMinutes)}
            </H1_TextXs>
          </ConditionalRender>
          <ConditionalRender condition={!isUnlimited}>
            <Progress
              strokeColor={theme.blue4}
              trailColor={theme.gray6}
              showInfo={false}
              percent={videoPercentRemained}
              status={mouseHoverMinutes ? "active" : undefined}
            />
            <H1_TextXs color={theme.gray8}>
              {intl.formatMessage(profileMessages.minutesText, {
                available: availableInMinutes.toFixed(0),
                totalMinutes: videoTotalNumberInMinutes.toFixed(0),
                percent: videoPercent
              })}
            </H1_TextXs>
          </ConditionalRender>
        </H1_FlexColumn>
        <OppositeUpgradeButton
          onClick={onClickUpgrade}
          source="upgrade-via-profile-page"
          text={intl.formatMessage(profileMessages.upgradeTextBtn)}
        />
      </PlanCardFlexRow>
    </H1_FlexColumn>
  );
};

export default MinutesCard;
