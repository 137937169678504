import { ColorResult, CustomPicker } from "react-color";

import React, { PropsWithChildren, useEffect, useState } from "react";
import { EditableInput, Saturation } from "react-color/lib/components/common";
import styled from "styled-components";
import { mappedAlphaHex } from "app/utils/helpers";
import CustomCirclePicker from "app/components/editor/sideDrawers/StyleDrawer/CustomCirclePicker";
import { PaletteColor } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useIntl } from "react-intl";
import { customColorPickerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { EditPaletteSource } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { Modal } from "antd";
import PurpleButton from "app/components/editor/sideDrawers/StyleDrawer/PurpleButton";
import CustomHue from "app/components/editor/sideDrawers/StyleDrawer/CustomHue";
import { ColorWrap } from "app/components/editor/sideDrawers/StyleDrawer/ColorWrap";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const SaturationWrapper = styled.div`
  width: 100%;
  height: 207px;
  position: relative;
  .saturation-black,
  .saturation-white {
    border-radius: 4px;
  }
  & > div:nth-of-type(1) {
    border-radius: 6px;
  }
`;

const HuePointer = styled.div`
  cursor: pointer;
  width: 8px;
  height: 8px;
  margin-top: 1px;
  border: 2px solid ${(props) => props.theme.gray11};
  border-radius: 50%;
`;

const EditableInputWrapper = styled.div`
  width: 100%;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1.10828px 2.21656px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  padding: 4px 7px 4px 7px;
  input {
    border: none;
  }
  div:before {
    color: #9ca3af;
    content: "#";
  }
`;

interface CustomColorPickerProps {
  colors: PaletteColor[];
  onFinish: (colors: PaletteColor[]) => void;
  onClose: () => void;
}

const CustomSaturationPicker = ColorWrap((props: PropsWithChildren<any>) => {
  return (
    <SaturationWrapper>
      <Saturation {...props} />
    </SaturationWrapper>
  );
});

const CustomColorInput = CustomPicker((props) => {
  return (
    <EditableInputWrapper>
      <EditableInput
        // @ts-ignore handle property color does not exist InjectedColorProps type
        value={props.color.split("#")[1]}
        {...props}
      />
    </EditableInputWrapper>
  );
});

const CustomColorPickerModal = ({ onClose, colors, onFinish }: CustomColorPickerProps) => {
  const [colorsList, setColorsList] = useState<PaletteColor[]>([]);
  const [selectedColorIndex, setSelectedColorIndex] = useState<number>(0);
  const intl = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setColorsList(colors);
  }, [colors]);

  const onColorChange = ({ hex, rgb }: ColorResult, source: EditPaletteSource): void => {
    dispatch(analyticsEvents.editPalette({ source }));

    const newColor =
      hex === "transparent" ? "#00000000" : hex + mappedAlphaHex((rgb?.a || 1) * 100);
    const newColorsList = [...colorsList];
    newColorsList[selectedColorIndex] = { ...newColorsList[selectedColorIndex], color: newColor };
    setColorsList(newColorsList);
  };

  const onDone = () => {
    onFinish(colorsList);
  };

  const onSelectColorIndex = (index: number) => {
    dispatch(
      analyticsEvents.editPaletteSelectColor({
        colorIndex: index,
        colorKey: colors[index].key as string
      })
    );

    setSelectedColorIndex(index);
  };
  return (
    <Modal
      width="535px"
      open
      onCancel={onClose}
      destroyOnClose
      footer={null}
      centered
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexColumn padding="18px" gap="17px" width="100%" overflow="auto" justify="space-between">
        <H1_TextSmall fontSize="13px">
          {intl.formatMessage(customColorPickerMessages.title)}
        </H1_TextSmall>
        <ConditionalRender condition={!!colorsList.length}>
          <CustomCirclePicker
            selectedColor={selectedColorIndex}
            colors={colorsList}
            onSelectColorIndex={onSelectColorIndex}
          />
          <CustomSaturationPicker
            color={colorsList[selectedColorIndex]?.color}
            onChangeComplete={(colorResult: ColorResult) => {
              onColorChange(colorResult, EditPaletteSource.colorMap);
            }}
          />
          <CustomHue
            onChangeComplete={(result) => {
              onColorChange(result, EditPaletteSource.colorRow);
            }}
            color={colorsList[selectedColorIndex]?.color}
            // @ts-ignore handle Property 'pointer' does not exist on type 'IntrinsicAttributes'
            pointer={() => <HuePointer />}
          />
          <CustomColorInput
            color={colorsList[selectedColorIndex]?.color}
            onChangeComplete={(colorResult: ColorResult) =>
              onColorChange(colorResult, EditPaletteSource.manual)
            }
          />
        </ConditionalRender>
        <PurpleButton onClick={onDone} size="large">
          {intl.formatMessage(customColorPickerMessages.doneButton)}
        </PurpleButton>
      </H1_FlexColumn>
    </Modal>
  );
};

export default CustomColorPickerModal;
