import { Button, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { RuleObject } from "antd/lib/form";
import { Folder } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";

import { foldersActions } from "app/store/slices/folders.slice";
import { foldersGlobalSelectors } from "app/store/adapters/adapters";
import { fetchingStatus } from "app/utils/helpers";
import useModal, { ModalName } from "app/hooks/useModal";
import useNotifications from "app/hooks/useNotifications";
import { useIntl } from "react-intl";
import { createFolderModalMessages } from "app/pages/FoldersPage/messages";
import { StyledModal } from "app/components/common/StyledModal";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Input } from "@nextui-org/react";

const CreateFolderModal = () => {
  const dispatch = useAppDispatch();
  const { editorModalOpen, closeModal, openModal } = useModal();
  const { notifyMessages } = useNotifications();
  const [newFolderName, setNewFolderName] = useState<string | null>(null);
  const { formatMessage } = useIntl();

  const visible = editorModalOpen?.open === ModalName.createFolder;
  const createStatus = useAppSelector((state) => state.folders.createFolderStatus);
  const allFolders = useAppSelector(foldersGlobalSelectors.selectAll);

  const { showMoveToFolder, ...restContext } = editorModalOpen?.context || {};

  useEffect(() => {
    if (createStatus === fetchingStatus.succeeded) {
      dispatch(foldersActions.setCreateFolderStatusToIdle());
      closeModal();

      if (showMoveToFolder) {
        openModal(ModalName.moveToFolder, restContext);
      } else {
        notifyMessages([
          {
            message: formatMessage(createFolderModalMessages.nameUpdateNotification, {
              name: newFolderName
            })
          }
        ]);
      }
    } else if (createStatus === fetchingStatus.failed) {
      dispatch(foldersActions.setCreateFolderStatusToIdle());
    }
  }, [createStatus]);

  const isLoading = createStatus === fetchingStatus.loading;
  const onCreateFolder = (folder: Folder) => {
    setNewFolderName(folder.name as string);
    dispatch(foldersActions.createFolderRequest({ name: folder.name as string }));
  };

  return (
    <StyledModal
      open={visible}
      destroyOnClose
      onCancel={closeModal}
      centered
      width={400}
      footer={false}
      bodyStyle={{ padding: "20px" }}
    >
      <H1_TextMiddle>{formatMessage(createFolderModalMessages.title)}</H1_TextMiddle>
      <Form layout="vertical" onFinish={(values) => onCreateFolder(values)} hideRequiredMark>
        <Form.Item
          label={
            <H1_TextSmall>{formatMessage(createFolderModalMessages.nameFormHeader)}</H1_TextSmall>
          }
          name="name"
          validateFirst
          rules={[
            {
              required: true,
              message: formatMessage(createFolderModalMessages.formValidationErrorUnique)
            },
            {
              validator: (ruleObject: RuleObject, value: string) => {
                const exist = allFolders.find((folder) => folder.name === value);
                if (exist) {
                  return Promise.reject(
                    new Error(formatMessage(createFolderModalMessages.formValidationErrorUnique))
                  );
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            labelPlacement="outside"
            size="sm"
            placeholder={formatMessage(createFolderModalMessages.nameFormPlaceholder)}
          />
        </Form.Item>
        <Row justify="end">
          <Space>
            <Button onClick={closeModal} ghost type="primary">
              {formatMessage(createFolderModalMessages.cancelButton)}
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {formatMessage(createFolderModalMessages.createButton)}
            </Button>
          </Space>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default CreateFolderModal;
