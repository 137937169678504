import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from "@nextui-org/react";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { ConfirmModalState } from "app/store/slices/uiV2.slice";
import { useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";

const NextUIConfirmModal = () => {
  const confirmModalState: ConfirmModalState = useAppSelector((state) => state.uiV2.confirmModal);
  const onCancelConfirm = () => {
    if (confirmModalState.onCancel) {
      confirmModalState.onCancel();
    }
  };

  return (
    <Modal
      backdrop="opaque"
      isOpen={confirmModalState.visible}
      onClose={onCancelConfirm}
      classNames={{ wrapper: "z-[5000]", backdrop: "z-[5000]" }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              <H1_TextMiddle whiteSpace="normal">{confirmModalState.title}</H1_TextMiddle>
            </ModalHeader>
            <ModalBody>
              <H1_TextSmall whiteSpace="normal">{confirmModalState.content}</H1_TextSmall>
            </ModalBody>
            <ModalFooter>
              <ConditionalRender
                condition={
                  confirmModalState.withCancelButton !== undefined
                    ? confirmModalState.withCancelButton
                    : true
                }
              >
                <Button variant="flat" onPress={onClose}>
                  {confirmModalState.cancelText || "Cancel"}
                </Button>
              </ConditionalRender>
              <Button
                color={confirmModalState.type || "primary"}
                onPress={() => {
                  onClose();
                  if (confirmModalState.onConfirm) {
                    confirmModalState.onConfirm();
                  }
                }}
              >
                {confirmModalState.confirmText || "Ok"}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default NextUIConfirmModal;
