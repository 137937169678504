import { durationToMMSS, isMediaPlaying } from "app/utils/helpers";
import { useEffect, useMemo, useState } from "react";

// todo add more capabilities like play
const useAudioPlayer = (file?: File, audioUrl?: string) => {
  const [totalTime, setTotalTime] = useState<number | undefined>();
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement>();
  const [isPlaying, setIsplaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formattedTime = useMemo(() => {
    return durationToMMSS(totalTime);
  }, [totalTime]);

  useEffect(() => {
    let audio: HTMLAudioElement;
    const fixVideoDuration = (retry = 0) => {
      // hack for loading duration infinity
      if (retry === 5) {
        console.warn("failed load duration");
        return;
      }
      if (audio.duration === Infinity) {
        console.log("audio time infinity");
        setTimeout(() => {
          fixVideoDuration(retry + 1);
        }, 1000);
      } else {
        audio.currentTime = 0;
        setTotalTime(audio.duration);
      }
    };
    const loadedmetadata = () => {
      if (audio.duration === Infinity) {
        audio.currentTime = 1000;
        fixVideoDuration();
      } else {
        setTotalTime(audio.duration);
      }
    };

    const onPlaying = () => {
      setIsplaying(true);
      setIsLoading(false);
    };
    const onPause = () => {
      setIsplaying(false);
    };
    if (!audioUrl && !file) {
      audio = new Audio();
      setTotalTime(undefined);
      setIsLoading(false);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (file) {
        const result = URL.createObjectURL(file);
        audio = new Audio(result);
      } else {
        audio = new Audio(audioUrl);
      }
    }
    audio.addEventListener("loadedmetadata", loadedmetadata);
    audio.addEventListener("playing", onPlaying);
    audio.addEventListener("pause", onPause);
    audio.load();
    setCurrentAudio(audio);

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", loadedmetadata);
        audio.removeEventListener("playing", onPlaying);
        audio.removeEventListener("pause", onPause);
      }
    };
  }, [file, audioUrl]);

  const playAudio = () => {
    setIsLoading(true);
    currentAudio?.play();
  };
  const pauseAudio = () => {
    if (isMediaPlaying(currentAudio)) {
      currentAudio?.pause();
    }
  };
  const stopAudio = () => {
    if (currentAudio) {
      currentAudio.currentTime = 0;
    }
    currentAudio?.pause();
  };
  const setAudioUrl = (url: string) => {
    if (currentAudio) {
      currentAudio.src = url;
      currentAudio.load();
    }
  };

  return {
    totalTime,
    formattedTime,
    playAudio,
    pauseAudio,
    isPlaying,
    stopAudio,
    setAudioUrl,
    isLoading
  };
};

export default useAudioPlayer;
