import { useEffect, useRef, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import ConditionalRender from "app/components/common/ConditionalRender";
import OutsideClickHandler from "react-outside-click-handler";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import styled from "styled-components";
import { Button } from "@nextui-org/react";

const EditButton = styled(Button)`
  min-width: 10px;
  i {
    font-size: 12px;
  }
`;

const EditableText = ({ text, onSave, value, maxWidth, viewOnly }: any) => {
  const [editable, setEditable] = useState(false);
  const [editValue, setEditValue] = useState("");

  const ref = useRef<HTMLInputElement | null>(null);
  // todo max width does not work
  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const onEdit = () => {
    setEditable(true);
    ref.current?.focus();
  };
  const onFinish = () => {
    setEditable(false);
    onSave(editValue);
  };
  return (
    <H1_FlexRow gap={"3px"} align={"center"} width={maxWidth}>
      <ConditionalRender condition={editable}>
        <OutsideClickHandler onOutsideClick={onFinish}>
          <H1_Input
            ref={ref}
            maxWidth={maxWidth}
            initialValue={editValue}
            onChange={(evt: any) => setEditValue(evt.target.value)}
          ></H1_Input>
        </OutsideClickHandler>
      </ConditionalRender>
      <ConditionalRender condition={!editable}>
        {text}
        <ConditionalRender condition={!viewOnly}>
          <EditButton
            size="sm"
            color="primary"
            onClick={onEdit}
            isIconOnly
            variant="light"
            startContent={<i className="fa-regular fa-pen-to-square"></i>}
          />
        </ConditionalRender>
      </ConditionalRender>
    </H1_FlexRow>
  );
};

export default EditableText;
