import React, { ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import useModal, { ModalName } from "app/hooks/useModal";
import { draftsGlobalSelectors } from "app/store/adapters/adapters";
import { Draft } from "app/types";
import useNotifications from "app/hooks/useNotifications";
import { useIntl } from "react-intl";
import { deleteVideoModalMessages } from "app/pages/FoldersPage/messages";
import styled from "styled-components";
import { StyledModal } from "app/components/common/StyledModal";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";

const BoldMessage = styled("span")`
  font-weight: 300;
`;
const DeleteVideoModal = () => {
  const dispatch = useAppDispatch();
  const { closeModal, editorModalOpen } = useModal();
  const { notifyMessages } = useNotifications();
  const { formatMessage } = useIntl();
  const { draftId } = editorModalOpen?.context || {};
  const draft = useAppSelector((state) =>
    draftsGlobalSelectors.selectById(state, draftId)
  ) as Draft;

  // @ts-ignore handels intl formatting with elements issue
  const deleteVideoModalTranslation = formatMessage(deleteVideoModalMessages.content, {
    draftTitle: draft?.title,
    b: (str) => <BoldMessage> {str}</BoldMessage>
  }) as ReactNode;
  const visible = editorModalOpen?.open === ModalName.deleteDraft;

  const onOk = () => {
    dispatch(draftsActions.deleteDraftRequest(draftId as string));
    notifyMessages([
      {
        message: formatMessage(deleteVideoModalMessages.message, {
          draftTitle: draft.title
        })
      }
    ]);
    closeModal();
  };

  return (
    <StyledModal
      open={visible}
      onOk={onOk}
      destroyOnClose
      onCancel={closeModal}
      centered
      okText={<H1_TextSmall>{formatMessage(deleteVideoModalMessages.deleteButton)}</H1_TextSmall>}
      cancelText={
        <H1_TextSmall>{formatMessage(deleteVideoModalMessages.cancelButton)}</H1_TextSmall>
      }
      width={400}
      bodyStyle={{ padding: "20px" }}
    >
      <H1_TextMiddle>{formatMessage(deleteVideoModalMessages.title)}</H1_TextMiddle>
      <H1_TextSmall>{deleteVideoModalTranslation}</H1_TextSmall>
    </StyledModal>
  );
};

export default DeleteVideoModal;
