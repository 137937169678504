import React, { useEffect, useState } from "react";
import { message, Tooltip } from "antd";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import CloseModalIcon from "./common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import DeveloperButton from "app/components/DeveloperButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { draftsActions } from "app/store/slices/drafts.slice";
import useCopy from "app/hooks/useCopy";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { fetchingStatus } from "app/utils/helpers";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import usePermissions from "app/hooks/usePermissions";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const StyledJSONPretty = styled(JSONPretty)`
  max-height: 300px;
  background-color: ${({ theme }) => theme.gray3};
  margin-bottom: 0;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
`;

const CopyButtonWrapper = styled("div")`
  position: absolute;
  right: 35px;
  top: 0;
  i {
    color: ${({ theme }) => theme.gray11};
  }
`;
const CopyCurlButtonWrapper = styled("div")`
  position: absolute;
  right: 65px;
  top: 0;
  i {
    color: ${({ theme }) => theme.gray11};
  }
`;

const DeveloperRequestButton = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const draft = useAppSelector((state) => state.drafts.currentDraft);
  const apiExampleResult = useAppSelector((state) => state.drafts.apiExampleResult);
  const { triggerCopy } = useCopy();
  const theme = useTheme();
  const apiExampleStatus = useAppSelector((state) => state.drafts.apiExampleStatus);
  const developerMode = useAppSelector((state) => state.uiV2.developerMode);

  const isLoading = apiExampleStatus === fetchingStatus.loading;
  const jsonViewerCommonStyle = "background:" + theme.gray3 + ";color:" + theme.gray11;
  const { isAllowCreateApiCurl } = usePermissions();

  useEffect(() => {
    if (showModal) {
      dispatch(
        draftsActions.createApiVideoExampleRequest({
          draftId: draft?.id as string,
          type: "create"
        })
      );
    }
  }, [showModal]);

  const onClick = () => {
    setShowModal(true);
  };

  const onCopy = () => {
    if (apiExampleResult?.curl) {
      triggerCopy({ copyContent: apiExampleResult?.curl, shouldNotify: false });
    }
    message.success("Copied to clipboard");
  };

  const onCopyJson = () => {
    if (apiExampleResult?.json_request) {
      triggerCopy({
        copyContent: JSON.stringify(apiExampleResult?.json_request, null, 4),
        shouldNotify: false
      });
      message.success("Copied to clipboard");
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  if (!developerMode || !isAllowCreateApiCurl) {
    return null;
  }

  return (
    <>
      <DeveloperButton size="24px" onClick={onClick}></DeveloperButton>
      <StyledModal
        width="700px"
        open={showModal}
        footer={null}
        centered
        onCancel={onClose}
        destroyOnClose
        closeIcon={<CloseModalIcon />}
        $padding="10px 0 0 0"
        $flex="1"
        $gap="5px"
      >
        <H1_FlexColumn align="center" position="relative">
          <H1_TextSmall margin="0 0 10px 0">HTTP Request</H1_TextSmall>
          <CopyButtonWrapper>
            <Tooltip title="Copy JSON">
              <>
                <Button
                  onClick={onCopyJson}
                  variant="light"
                  startContent={<i className="fa-regular fa-copy"></i>}
                  isIconOnly
                />
              </>
            </Tooltip>
          </CopyButtonWrapper>
          <CopyCurlButtonWrapper>
            <Tooltip title="Copy CURL">
              <>
                <Button
                  onClick={onCopy}
                  variant="light"
                  startContent={<i className="fa-regular fa-rectangle-terminal"></i>}
                  isIconOnly
                />
              </>
            </Tooltip>
          </CopyCurlButtonWrapper>
        </H1_FlexColumn>
        <ConditionalRender condition={isLoading}>
          <CircleLoader />
        </ConditionalRender>
        <ConditionalRender condition={!isLoading}>
          <H1_FlexRow>
            <H1_FlexColumn overflow="auto" flex="1 1 auto">
              <StyledJSONPretty
                id="json-pretty"
                data={apiExampleResult?.json_request || {}}
                mainStyle={jsonViewerCommonStyle + ";padding:5px;margin-bottom:0"}
                keyStyle={jsonViewerCommonStyle}
                valueStyle={jsonViewerCommonStyle}
                booleanStyle={jsonViewerCommonStyle}
                stringStyle={jsonViewerCommonStyle}
              />
            </H1_FlexColumn>
          </H1_FlexRow>
        </ConditionalRender>
      </StyledModal>
    </>
  );
};

export default DeveloperRequestButton;
