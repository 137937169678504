import MediaLibraryTabSDGenerateDraggableTags from "app/pages/mediaLibrary/StableDiffusion/DraggableTags";
import MediaLibraryTabSDGeneratePromptTagSelect, {
  Modifier
} from "app/pages/mediaLibrary/StableDiffusion/SelectTags";
import styled from "styled-components";
import { TagAreaType } from "app/types/media";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

interface MediaLibraryTabSDTagWrapType extends TagAreaType {
  modifiers: Modifier[];
  addTag: (tag: string, color: string, icon: string) => void;
  selected: string[];
  editable: boolean;
  // eslint-disable-next-line react/require-default-props
  onFocus?: () => void;
}

const TagWrapper = styled(H1_FlexRow)`
  margin: 10px;
`;

const MediaLibraryTabSDTagWrap = ({
  modifiers,
  tags,
  setTags,
  addTag,
  selected,
  editable,
  onFocus
}: MediaLibraryTabSDTagWrapType) => {
  return (
    <TagWrapper wrap="wrap" justify="center" align="center" gap="5px">
      <MediaLibraryTabSDGenerateDraggableTags tags={tags} setTags={setTags} />
      <ConditionalRender condition={editable}>
        <MediaLibraryTabSDGeneratePromptTagSelect
          selected={selected}
          someModifiers={modifiers}
          addTag={addTag}
          onFocus={onFocus}
        />
      </ConditionalRender>
    </TagWrapper>
  );
};

export default MediaLibraryTabSDTagWrap;
