import { AppDispatch, RootState } from "app/store/store";

export const meetingsDetails = `https://hourone.ai/demo/`;
export const illegalCharacterFormat = /[`)(<>[\]|*^#±~/\\=+_]/;
export const illegalCharacterFormatGlobal = /[`)(<>[\]|*^#±~/\\=+_]/g;

export interface PatchOperation {
  op: "add" | "delete" | "replace" | "generate";
  path: string;
  value?: any;
}

export interface ThunkApi {
  dispatch: AppDispatch;
  state: RootState;
}

export type FetchStatus = "idle" | "loading" | "failed" | "succeeded";

export interface FilestackPolicy {
  policy?: string;
  signature?: string;
}

export type View = "gallery" | "list";

export enum UseCase {
  PRODUCT_TUTORIALS = "Product Tutorials",
  E_LEARNING = "ELearning",
  HR = "HR",
  ECommerce = "ECommerce",
  NEWS = "News",
  CORPORATE_COMMUNICATION = "Corporate Communication"
}

export enum LayoutAction {
  Open = "Open",
  Select = "Select",
  Close = "Close"
}

export enum CurrentPage {
  cgn = "cgn",
  integrations = "integrations",
  home = "home",
  projects = "projects",
  templates = "templates",
  avatars = "avatars",
  workflows = "workflows",
  brandHub = "brandHub",
  briefs = "briefs",
  trash = "trash",
  playlists = "playlists"
}
export enum Location {
  Home = "/home",
  Pricing = "/pricing",
  Projects = "/folders",
  Templates = "/templates",
  Avatars = "/avatars",
  Profile = "/profile",
  Channels = "/channels",
  Editor = "/editor",
  Bio = "/bio",
  Videos = "/videos",
  RealsEditorHomepage = "/editor",
  MobileCreate = "/mobile/drafts",
  MobileFinish = "/mobile/finish",
  Player = "/player",
  Survey = "/survey",
  IneerEditor = "/editor/", // Will effect only - editor/{id}
  Community = "/community",
  Integrations = "/integrations",
  Playground = "/playground",
  CreateTwin = "/avatars/create",
  WorkflowForm = "/workflows/",
  Workflows = "/workflows",
  OnBoardingV2 = "/onboarding",
  Briefs = "/briefs",
  Live = "/live",
  Playlists = "/playlists",
  Webhooks = "/webhooks",
  Trash = "/trash"
}

export enum FramePreviewQuality {
  low = "low",
  medium = "medium",
  high = "high"
}

export const enum PaymentFormFields {
  postal = "postal",
  cardHolderName = "cardHolderName"
}
export interface ServerError {
  message?: string;
  status?: number;
  body?: any;
}

export interface PricingPlanLine {
  text: string;
  tooltip?: string;
  currentMinutes?: number;
}

export enum ReplacedBannerType {
  blackFriday = "blackFriday",
  cyberMonday = "cyberMonday",
  christmas = "christmas",
  newYear = "newYear",
  valentinesDay = "valentinesDay"
}

export enum DataLayout {
  table = "table",
  grid = "grid"
}

export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const DEFAULT_GALLERY_PAGE_SIZE = DEFAULT_TABLE_PAGE_SIZE * 2;

export enum TargetAudience {
  marketing = "marketing",
  training = "training",
  sales = "sales",
  creators = "creators",
  developers = "developers"
}

export enum SocialMedia {
  facebook = "facebook",
  twitter = "twitter",
  instagram = "instagram",
  linkedin = "linkedin",
  youtube = "youtube",
  tiktok = "tiktok",
  reals = "reals",
  whatsapp = "whatsapp",
  telegram = "telegram"
}

export enum SearchType {
  draft = "draft",
  folder = "folder"
}

export enum DevModeSize {
  default = "default",
  large = "large"
}

export enum BoostProvider {
  gcp = "gcp",
  azure = "azure",
  aws = "aws"
}

export enum HeaderTab {
  home = "home",
  workspace = "workspace"
}
