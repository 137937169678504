import { createSelector } from "reselect";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import {
  allMediaTypeOptions,
  MediaSort,
  MediaSources,
  MediaType,
  MediaTypeSort,
  ServerFolderMedia,
  SortOrder
} from "app/types/media";
import { RootState } from "app/store/store";
import { Modifier } from "app/pages/mediaLibrary/StableDiffusion/SelectTags";
import modifiers from "app/pages/mediaLibrary/StableDiffusion/sd-modifiers.json";
import { FolderType, MimeType } from "app/types";

export const mediaSort = (state: RootState) => state.media.mediaSort;
export const mediaFilter = (state: RootState) => state.media.mediaFilter;
export const mediaStock = (state: RootState) => state.media.mediaStock;

export const getFilteredMediaByType = createSelector(
  [mediaGlobalSelectors.selectAll, mediaFilter],
  (mediaList, filter) => {
    return mediaList.filter((currentFile) =>
      filter.includes(currentFile.data?.media_type as MediaType)
    );
  }
);

export const getFilteredMediaBySpecificType = createSelector(
  [mediaGlobalSelectors.selectAll, (state, mediaType) => mediaType],
  (mediaList, mediaType) => {
    return mediaList.filter((currentFile) => currentFile.data?.media_type === mediaType);
  }
);

// todo this is the main thinkg
export const getFilteredSortedMedia = createSelector(
  [getFilteredMediaByType, mediaSort],
  (mediaList, mediaSorter) => {
    const result = [...mediaList];
    let sortingFunc;
    switch (mediaSorter.prop) {
      case MediaTypeSort.name:
        sortingFunc = (a: ServerFolderMedia, b: ServerFolderMedia) =>
          (a.data?.name || a.name || "").localeCompare(b.data?.name || b.name || "");
        break;
      default:
        sortingFunc = (a: ServerFolderMedia, b: ServerFolderMedia) =>
          (new Date(a.created_at).getTime() as number) <
          (new Date(b.created_at).getTime() as number)
            ? 1
            : -1;
    }
    let TheResult;
    if (mediaSorter.order === SortOrder.down) {
      TheResult = result.sort(sortingFunc);
    } else {
      TheResult = result.sort(sortingFunc).reverse();
    }
    return TheResult;
  }
);

export const findMediaByUrl = createSelector(
  [mediaGlobalSelectors.selectAll, (state, selectedUrl: string) => ({ selectedUrl })],
  (mediaList, { selectedUrl }) => {
    const res = mediaList.find((currentFile) => currentFile.data?.url === selectedUrl);
    return res;
  }
);

export const getPrevModifiers = createSelector(
  [],
  () => modifiers.filter((elm) => elm.priority < 0) as Modifier[]
);

export const getAfterModifiers = createSelector(
  [],
  () => modifiers.filter((elm) => elm.priority > 0) as Modifier[]
);

const getFilterMedia = createSelector(
  [
    getFilteredMediaByType,
    (_state, { search, mimetypes }: { search?: string; mimetypes?: string[] }) => ({
      search,
      mimetypes
    })
  ],
  (sortedMedia, { search, mimetypes }) => {
    let filteredAndSorted = sortedMedia;
    if (mimetypes?.length) {
      filteredAndSorted = filteredAndSorted.filter((currentFile) => {
        return (
          mimetypes.includes(currentFile.data?.media_type as string) ||
          (mimetypes.includes(MediaType.folder) && currentFile.type === FolderType.folder)
        );
      });
    }

    if (search) {
      filteredAndSorted = filteredAndSorted.filter(
        (currentFile) =>
          (currentFile?.data?.name || currentFile.name || "")
            .toLowerCase()
            .indexOf(search.toLowerCase()) > -1
      );
    }
    return filteredAndSorted;
  }
);

export const getGeneratedSortedMediaByType = createSelector(
  [
    (state, { search, mimetypes }: { search?: string; mimetypes?: string[] }) => ({
      state,
      search,
      mimetypes
    })
  ],
  ({ state, search, mimetypes }) => {
    // For updating when sortedMedia updates  https://redux.js.org/usage/deriving-data-selectors#passing-input-parameters
    const sortedMedia = getFilterMedia(state, { search, mimetypes });
    const sortedMediaFiltered = sortedMedia.filter(
      (currentFile) => currentFile?.data?.source === MediaSources.stableDiffusion
    );
    return sortedMediaFiltered;
  }
  //   todo check who use it
);

export const getMediaListSelect = createSelector(
  [
    mediaGlobalSelectors.selectAll,
    mediaFilter,
    mediaSort,
    (_state, search: string, mimetypesRestrictions: MimeType[]) => ({
      search,
      mimetypesRestrictions
    })
  ],
  (mediaList, filterProps, sortProps, { search, mimetypesRestrictions }) => {
    const result = mediaList
      .filter(
        (currentFile: ServerFolderMedia) =>
          (currentFile?.data?.source ? currentFile?.data?.source === MediaSources.upload : true) &&
          (filterProps.includes(currentFile.data?.media_type as MediaType) ||
            (filterProps.includes(MediaType.folder) && currentFile.type === FolderType.folder)) &&
          (search
            ? (currentFile.data?.name || currentFile.name || "")
                .toLowerCase()
                .indexOf(search.toLowerCase()) > -1
            : true) &&
          (mimetypesRestrictions && mimetypesRestrictions.length && currentFile.data?.media_type
            ? mimetypesRestrictions.some((elem) =>
                elem.includes(currentFile.data?.media_type as MimeType)
              )
            : true)
      )
      .sort((a, b) => {
        const first = a.type === FolderType.folder ? 1 : -1;
        const second = b.type === FolderType.folder ? 1 : -1;
        return first - second;
      });

    return sortMedia(result, sortProps);
  }
);

export const getMediaListFolders = createSelector(
  [mediaGlobalSelectors.selectAll, (state, selectedFiles) => selectedFiles],
  (mediaList, selectedFiles) => {
    const arrSelectedFiles = Array.from(selectedFiles);
    const result = mediaList
      .filter(
        (currentFile: ServerFolderMedia) =>
          currentFile.type === FolderType.folder && !arrSelectedFiles.includes(currentFile.id)
      )
      .sort((a: ServerFolderMedia, b: ServerFolderMedia) =>
        (a.data?.name || a.name || "").localeCompare(b.data?.name || b.name || "")
      );

    return result;
  }
);

const sortMedia = (mediaList: ServerFolderMedia[], mediaSorter: MediaSort) => {
  let sortingFunc;
  switch (mediaSorter.prop) {
    case MediaTypeSort.name:
      sortingFunc = (a: ServerFolderMedia, b: ServerFolderMedia) =>
        (a.data?.name || a.name || "").localeCompare(b.data?.name || b.name || "");
      break;
    default:
      sortingFunc = (a: ServerFolderMedia, b: ServerFolderMedia) =>
        (new Date(a.created_at).getTime() as number) < (new Date(b.created_at).getTime() as number)
          ? -1
          : 1;
  }

  const sorted =
    mediaSorter.order === SortOrder.down
      ? mediaList.sort(sortingFunc).reverse()
      : mediaList.sort(sortingFunc);

  return sorted.sort((a, b) => {
    const first = a.type === FolderType.folder ? -1 : 1;
    const second = b.type === FolderType.folder ? -1 : 1;
    return first - second;
  });
};

export const getMediaTypeSelectedFilters = createSelector(
  [mediaFilter],
  (selectedTypes: MediaType[]) => {
    if (selectedTypes.includes(MediaType.all)) {
      return allMediaTypeOptions;
    }
    if (selectedTypes.length === allMediaTypeOptions.length - 1) {
      return allMediaTypeOptions;
    }

    return selectedTypes;
  }
);
export const getMediaStockTags = createSelector([mediaStock], (mediaList) => {
  return [...new Set(mediaList.flatMap((item) => item.tags))];
});

export const getMediaStockContentByTags = createSelector(
  [mediaStock, (_state, tags: string[]) => ({ tags })],
  (mediaList, { tags }) => {
    if (tags.length === 0) {
      return mediaList;
    }
    return mediaList.filter((item) => item.tags.some((tag) => tags.includes(tag)));
  }
);
