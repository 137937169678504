import { ErrorBoundary, setContext } from "@sentry/react";
import { Provider } from "react-redux";
import { IntlProvider, MessageFormatElement } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import redux from "app/store/store";
// eslint-disable-next-line absolute-imports/only-absolute-imports
import locales from "./locales";
import Main from "app/Main";
import ErrorPage from "app/components/authentication/ErrorPage";
import { initAnalytics } from "app/store/thunks/analyticsEvents.thunk";
import ConnectLunchDraklyRedux from "app/services/ConnectLunchDraklyRedux";
import ZendeskChoiceButtons from "app/utils/zendesk/ZendeskChoiceButtons";
import NoConnectionModal from "app/components/NoConnectionModal";
import ThemeProvider from "app/ThemeProvider";
import "app/scss/Index.scss";
import StripWraper from "app/StripWraper";
import AuthSwitcher from "app/auth/AuthSwitcher";
import { LiveblocksProvider } from "app/services/liveBlocksClient";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { GlobalStyle } from "app/GlobalStyles";

initAnalytics();
const App = () => {
  setContext("clientVersion", {
    version: import.meta.env.REACT_APP_VERSION
  });

  return (
    <ThemeProvider>
      <NextUIProvider>
        <NextThemesProvider attribute="class">
          {/* Apply the global styles */}
          <GlobalStyle />
          <IntlProvider
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onError={(error) => {
              //  todo do nothing
              // this is templ for omitting errors from format js on that refactor process.
            }}
            locale="en-US"
            key="en-US"
            messages={locales["en-US"] as unknown as Record<string, MessageFormatElement[]>}
          >
            <Provider store={redux.store}>
              <ErrorBoundary fallback={<ErrorPage />}>
                <ConnectLunchDraklyRedux>
                  <BrowserRouter>
                    <AuthSwitcher>
                      <StripWraper>
                        <LiveblocksProvider>
                          <NoConnectionModal />
                          <Main />
                        </LiveblocksProvider>
                      </StripWraper>
                    </AuthSwitcher>
                  </BrowserRouter>
                </ConnectLunchDraklyRedux>
              </ErrorBoundary>
            </Provider>
            <ZendeskChoiceButtons />
          </IntlProvider>
        </NextThemesProvider>
      </NextUIProvider>
    </ThemeProvider>
  );
};

export default App;
