/* eslint react/prop-types: 0 */
/* eslint no-nested-ternary: 0 */
import React, { ForwardedRef, MouseEventHandler } from "react";
import styled from "styled-components";

interface ContainerProps {
  $fontFamily: string;
  $fontWeight: number;
  $fontSize: string;
  $lineHeight?: string;
  $inline?: boolean;
  $ellipsis?: boolean;
  $color?: string;
  $textAlign?: string;
  $whiteSpace?: string;
  $underline?: boolean;
  $margin?: string;
  $padding?: string;
}

const Container = styled.span<ContainerProps>`
  font-family: ${(props) => props.$fontFamily};
  font-size: ${(props) => props.$fontSize};
  font-weight: ${(props) => props.$fontWeight};
  line-height: ${(props) => (props.$lineHeight ? props.$lineHeight : "normal")};
  color: ${(props) => (props.$color ? props.$color : props.theme.gray11)};
  text-align: ${(props) => (props.$textAlign ? props.$textAlign : "inherit")};
  text-overflow: ${(props) => (props.$ellipsis ? "ellipsis" : "inherit")};
  overflow: ${(props) => (props.$ellipsis ? "hidden" : "visible")};
  display: ${(props) => (props.$ellipsis ? "block" : props.$inline ? "inline-flex" : "flex")};
  white-space: ${(props) =>
    props.$ellipsis ? "nowrap" : (props) => (props.$whiteSpace ? props.$whiteSpace : "pre")};
  ${(props) => props.$underline && `text-decoration: underline`};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
  ${(props) => props.$padding && `padding: ${props.$padding}`};
`;

export interface TextProps<T> {
  inline?: boolean;
  fontFamily?: string;
  fontWeight?: number;
  lineHeight?: string;
  fontSize: string;
  color?: string;
  ellipsis?: boolean;
  className?: string;
  textAlign?: string;
  whiteSpace?: string;
  onClick?: MouseEventHandler<T>;
  underline?: boolean;
  margin?: string;
  padding?: string;
}

export const Text = React.forwardRef<
  HTMLSpanElement,
  React.PropsWithChildren<TextProps<HTMLSpanElement>>
>(
  (
    {
      inline,
      fontFamily,
      fontWeight,
      lineHeight,
      fontSize,
      color,
      ellipsis,
      className,
      onClick,
      children,
      textAlign,
      whiteSpace,
      underline,
      margin,
      padding
    },
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    return (
      <Container
        ref={ref}
        $fontFamily={fontFamily || "Ariel"}
        $fontWeight={fontWeight || 400}
        $lineHeight={lineHeight}
        $fontSize={fontSize}
        $color={color}
        $textAlign={textAlign}
        $ellipsis={ellipsis}
        $inline={inline}
        $underline={underline}
        className={className}
        onClick={onClick}
        $whiteSpace={whiteSpace}
        $margin={margin}
        $padding={padding}
      >
        {children}
      </Container>
    );
  }
);

Text.displayName = "Text";

Text.defaultProps = {
  inline: false,
  fontFamily: "Inter,-apple-system,BlinkMacSystemFont,Poppins,Ariel",
  fontWeight: 400
};
