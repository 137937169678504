import React, { useEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import recipesSelectors, { ALL_CATEGORIES_ID } from "app/store/selectorsV2/recipes.selectors";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { RecipeList, segmentTitle } from "app/types";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { recipesActions } from "app/store/slices/recipes.slice";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";

const HiddenBoxes = styled(H1_FlexRow)`
  visibility: hidden;
`;
const BorderLeftFlexRow = styled(H1_FlexRow)`
  border-left: 1px solid ${({ theme }) => theme.gray6};
`;

const Box = styled(H1_FlexRow)<{ $isColored?: boolean }>`
  border-radius: 50px;
  border: 2px solid ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray4)};
  background-color: ${(props) => (props.$isColored ? props.theme.blue4 : props.theme.gray4)};
  transition: transform 0.3s ease-in-out;
  i {
    color: ${(props) => props.theme.gray9};
  }
  &&& span {
    color: ${(props) => (props.$isColored ? "white" : props.theme.gray9)};
  }
`;

const ChipsContainer = () => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const selectedSectionsNames = useAppSelector((state) => state.recipes.selectedSectionsNames);
  const visibleChipsCount = useMemo(() => {
    // TODO: use ref with on mount and never use ref,current as a memo dependency
    const chipElements = containerRef?.current?.getElementsByClassName("category-box");
    let totalWidth = 0;
    let calculatedChips = 0;

    if (!chipElements) {
      return 0;
    }

    for (let i = 0; i < chipElements.length; i++) {
      const chip = chipElements[i] as HTMLDivElement;
      totalWidth += Math.floor(chip.offsetWidth) + 5;
      // 50 for padding right, 17 for padding left
      if (totalWidth <= containerWidth - 67) {
        calculatedChips++;
      } else {
        break;
      }
    }

    return calculatedChips;
  }, [containerWidth, containerRef?.current, selectedSectionsNames.length]);

  const categoriesNamesWithAll = useAppSelector((state) =>
    recipesSelectors.getAllRecipesCategoriesNames(state, {
      visibleChipsCount,
      selectedSectionsNames
    })
  );
  const hiddenChipsCount = categoriesNamesWithAll.length - visibleChipsCount;

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef?.current?.offsetWidth || 0;
      setContainerWidth(width);
    };

    // Call the handleResize function initially and add event listener for window resize
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSelectSection = (section: Partial<RecipeList>) => {
    dispatch(analyticsEvents.templateCategorySelect({ categoryName: section.name as string }));
    if (section.id === ALL_CATEGORIES_ID) {
      dispatch(recipesActions.clearSelectedSectionNames());
      return;
    }
    dispatch(
      recipesActions.updateSelectedSectionNames({
        name: section?.name as string
      })
    );
  };

  const items = useMemo(() => {
    const hiddenChips = categoriesNamesWithAll.slice(visibleChipsCount);
    const menuChildren = hiddenChips.map((child) => ({
      key: child.name as string,
      onClick: () => onSelectSection(child),
      label: <H1_TextSmall>{child.name}</H1_TextSmall>
    }));

    return menuChildren;
  }, [categoriesNamesWithAll, visibleChipsCount, selectedSectionsNames]);

  return (
    <BorderLeftFlexRow
      padding="0 0 0 17px"
      position="relative"
      ref={containerRef}
      gap="5px"
      align="center"
      width="100%"
      flex="0 1 auto"
    >
      <HiddenBoxes position="absolute">
        {categoriesNamesWithAll.map((chip) => (
          <Box
            key={chip.name}
            $isColored
            width="fit-content"
            padding="4px 16px"
            height="30px"
            justify="center"
            align="center"
            flex="0 0 auto"
            className="category-box"
          >
            <H1_TextSmall color={theme.gray9}>{chip.name}</H1_TextSmall>
          </Box>
        ))}
      </HiddenBoxes>
      {categoriesNamesWithAll.slice(0, visibleChipsCount).map((chip) => (
        <Box
          key={chip.name}
          onClick={() => onSelectSection(chip)}
          $isColored={
            selectedSectionsNames.includes(chip.name as string) ||
            (selectedSectionsNames.length === 0 && chip.id === ALL_CATEGORIES_ID)
          }
          width="fit-content"
          padding="4px 16px"
          height="30px"
          justify="center"
          align="center"
          flex="0 0 auto"
        >
          <H1_TextSmall color={theme.gray9}>{segmentTitle(chip.name as string)}</H1_TextSmall>
        </Box>
      ))}
      {hiddenChipsCount > 0 && (
        <NextDropdown withWhiteBackground placement="bottom-start" items={items}>
          <Box
            align="center"
            height="29px"
            width="fit-content"
            padding="4px 16px"
            justify="center"
            flex="0 0 auto"
          >
            <i className="far fa-ellipsis" />
          </Box>
        </NextDropdown>
      )}
    </BorderLeftFlexRow>
  );
};

export default ChipsContainer;
