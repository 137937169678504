import React, { useEffect } from "react";

// Redux
import { useParams } from "react-router-dom";
import { draftsActions, draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { scenesActions } from "app/store/slices/scenes.slice";
import { videosActions } from "app/store/slices/videos.slice";
import * as Sentry from "@sentry/react";

// Local components
import EditorHeader from "app/components/editor/EditorHeader";
// Design
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Draft, FetchStatus, SceneTabs } from "app/types";

import "app/components/editor/Editor.scss";

import { fetchingStatus } from "app/utils/helpers";
import styled from "styled-components";
import useDrawer from "app/hooks/useDrawer";
import TranscriptAudioUploadModal from "app/components/editor/scene/transcriptAudioUploader/TranscriptAudioUploadModal";
import { RoomProvider } from "app/services/liveBlocksClient";
import {
  getCurrentWorkspace,
  workspaceSupportsLiveBlocks
} from "app/store/selectorsV2/workspaces.selectors";
import EditingDraftPageContent from "app/pages/EditDraftPage/EditingDraftPageContent";
import LiveBlockBroadcastEvents from "app/pages/EditDraftPage/LiveBlockBroadcastEvents";
import ScenesPlayerModal from "app/pages/editor/ScenesPlayerModal/ScenesPlayerModal";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { ThemeMode } from "app/utils/theme";

const BorderFlexRow = styled(H1_FlexRow)`
  border-bottom: 1px solid
    ${(props) => (props.theme.mode === ThemeMode.Light ? props.theme.gray5 : props.theme.gray12)};
  z-index: 8; // It need to be higher than the drawer, and the scene buttons
  padding-right: 10px;
  position: relative;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
`;

const MiddleSpin = styled(CircleLoader)`
  position: relative;
  margin: auto;
`;

const EditingDraftPage = () => {
  const dispatch = useAppDispatch();
  const { closeDrawer } = useDrawer();
  // todo extract selectors function so we can have a type
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const draftStatus = useAppSelector((state) => state.drafts.draftsStatus);
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const clearScenesStatus: FetchStatus = useAppSelector((state) => state.drafts.clearScenesStatus);
  const isLiveBlocksSupported = useAppSelector(workspaceSupportsLiveBlocks);

  const loading = draftStatus === fetchingStatus.loading;
  const { draftId } = useParams<{ draftId: string }>();

  useEffect(() => {
    if (!draftId) {
      Sentry.captureException(new Error("got draftId undefined"));
    }
  }, [draftId]);

  useEffect(() => {
    dispatch(scenesActions.updateSceneTab(SceneTabs.Content));
    return () => {
      closeDrawer();
    };
  }, []);

  useEffect(() => {
    if (draftStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateDraftsStatusToIdle());
    }
    if (draftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateDraftsStatusToIdle());
    }
  }, [draftStatus]);

  useEffect(() => {
    if (clearScenesStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateClearScenesStatusToIdle());
    }
    if (clearScenesStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateClearScenesStatusToIdle());
    }
  }, [clearScenesStatus]);

  useEffect(() => {
    dispatch(draftsActionsV2.startWorkingOnDraft(draftId as string));
    dispatch(draftsActionsV2.loadDraftResources());

    return () => {
      dispatch(draftsActionsV2.cleanDraft());
      dispatch(videosActions.cleanVideos());
      dispatch(scenesActions.cleanScenes());
      dispatch(scenesActions.setSelectedSceneId(undefined));
      dispatch(draftsActionsV2.updateDraftsStatusToIdle());
    };
  }, [draftId]);

  return (
    <H1_FlexColumn flex="1 1 auto">
      {loading && !draft?.id && <MiddleSpin />}
      {loading && draft?.id && <MiddleSpin />}
      {!loading && draft?.id && (
        <RoomProvider
          id={`${currentWorkspace?.id as string}-${draft?.id as string}`}
          initialPresence={{
            sceneId: undefined,
            cursor: undefined,
            dimensions: undefined,
            scrollTop: 0
          }}
          autoConnect={isLiveBlocksSupported}
        >
          <>
            <LiveBlockBroadcastEvents />
            <TranscriptAudioUploadModal />
            <BorderFlexRow height="57px" flex="0 0 auto">
              <EditorHeader />
            </BorderFlexRow>
            <EditingDraftPageContent />
          </>
        </RoomProvider>
      )}
      <ScenesPlayerModal />
    </H1_FlexColumn>
  );
};

export default EditingDraftPage;
