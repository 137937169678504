import {
  DndContext,
  DragOverlay,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import React, { ReactNode, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { FolderType } from "app/types";
import styled, { css } from "styled-components";
import { useAppSelector } from "app/hooks";
import { mediaGlobalSelectors } from "app/store/adapters/adapters";
import { onImageError, onVideoError } from "app/utils/helpers";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import useDisplayUrls, { MediaUrl } from "app/hooks/useDisplayUrls";
import { MediaType } from "app/types/media";

const FolderIcon = styled.i`
  color: ${({ theme }) => theme.blue1};
  font-size: 32px;
`;

const StyledDragOverlay = styled(DragOverlay)`
  ${({ isDragging }: { isDragging: boolean }) =>
    isDragging &&
    css`
      && {
        opacity: 0.8;
        border-radius: 8px;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        display: flex;
        width: fit-content;
        height: fit-content;
      }
    `};
`;

const TransformedDiv = styled.div<{ $transform: string }>`
  transform: ${({ $transform }) => $transform};
`;

const StyledImage = styled.img`
  object-fit: contain;
  height: auto;
  max-height: 120px;
  max-width: 120px;
  border-radius: 10px;
  width: auto;
`;

const StyledVideo = styled.video`
  object-fit: contain;
  height: auto;
  max-height: 120px;
  max-width: 120px;
  width: auto;
  border-radius: 10px;
`;

interface DragAndDropContainerProps {
  children: ReactNode;
  onFinishDrop: (id: string, over: string) => void;
}

const DragAndDropFileShadow = ({
  fileId,
  cssTransform
}: {
  fileId: string;
  cssTransform: string;
}) => {
  const file = useAppSelector((state) => mediaGlobalSelectors.selectById(state, fileId));
  const { displayUrls } = useDisplayUrls([file?.data?.url]);
  const displayUrl: MediaUrl | undefined = displayUrls[file?.data?.url as string];

  const isThumbnailVideo = displayUrl?.thumbnailType === MediaType.video;
  const formattedUrl = displayUrl?.thumbnail || displayUrl?.url;

  return (
    <TransformedDiv $transform={cssTransform}>
      <H1_FlexRow justify="center" align="center" height="120px" width="120px">
        <ConditionalRender condition={!isThumbnailVideo}>
          <StyledImage data-image-handle={file?.id} src={formattedUrl} onError={onImageError} />
        </ConditionalRender>

        <ConditionalRender condition={!!isThumbnailVideo}>
          <StyledVideo data-image-handle={file?.id} src={formattedUrl} onError={onVideoError} />
        </ConditionalRender>
      </H1_FlexRow>
    </TransformedDiv>
  );
};

const DragAndDropContainer = ({ children, onFinishDrop }: DragAndDropContainerProps) => {
  const [cssTransform, setCssTransform] = useState<string>("none");
  const [draggingFileId, setDraggingFileId] = useState<string>("");
  const [dragType, setDragType] = useState(FolderType.file);
  const [title, setTitle] = useState<string>("");
  const [dragState, setDragState] = useState({
    isDragging: false,
    origin: { x: 0, y: 0 },
    position: { x: 0, y: 0 },
    transform: cssTransform
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10 // Enable sort function when dragging 10px   💡 here!!!
    }
  });
  const sensors = useSensors(mouseSensor);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    setDraggingFileId("");
    if (active && over && active.id && over.id && active.id != over.id) {
      onFinishDrop(active.id, over.id);
    }
    setDragState({
      ...dragState,
      isDragging: true,
      origin: {
        x: event.delta.x,
        y: event.delta.y
      }
    });
  };
  const handleDragStart = ({ active }: any) => {
    setDraggingFileId(active.id);
    setDragType(active.data.current.type);
    setTitle(active.data.current.title);
    setCssTransform("none");
    setDragState({
      ...dragState,
      isDragging: false,
      position: { x: 0, y: 0 },
      transform: "none"
    });
  };
  const handleDragMove = (event: any) => {
    const { origin } = dragState;
    const newX = event.delta.x - origin.x;
    const newY = event.delta.y - origin.y;
    const maxRotation = 25;

    // Calculate rotation and scaling based on distance from origin
    let rotationX = newY * 0.1; // Adjust these values based on desired effect
    let rotationY = -newX * 0.1;

    // Clamp the rotation values to the range [-maxRotation, maxRotation]
    rotationX = Math.max(-maxRotation, Math.min(maxRotation, rotationX));
    rotationY = Math.max(-maxRotation, Math.min(maxRotation, rotationY));

    const scale = Math.max(0.75, 1 - Math.sqrt(newX ** 2 + newY ** 2) / 1000);
    const transformCalc = `rotateX(${rotationX}deg) rotateY(${rotationY}deg) scale(${scale})`;
    setDragState({
      ...dragState,
      position: { x: newX, y: newY },
      transform: transformCalc
    });
    setCssTransform(transformCalc);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      collisionDetection={pointerWithin}
      modifiers={[snapCenterToCursor]}
    >
      {children}
      <StyledDragOverlay isDragging={!!draggingFileId}>
        <ConditionalRender condition={dragType === FolderType.file}>
          <DragAndDropFileShadow fileId={draggingFileId} cssTransform={cssTransform} />
        </ConditionalRender>
        <ConditionalRender condition={dragType === FolderType.folder}>
          <H1_FlexRow width="164px" height="164px" align="center" justify="center" gap="5px">
            <FolderIcon className="far fa-folder" />
            <H1_TextSmall>{title}</H1_TextSmall>
          </H1_FlexRow>
        </ConditionalRender>
      </StyledDragOverlay>
    </DndContext>
  );
};

export default DragAndDropContainer;
