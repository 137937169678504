import { validateIllegalCharsTranscript } from "app/components/editor/validations";

export const validatePlayGround = (
  transcript: string,
  maxTranscriptLength: number,
  isCustomVoiceSet: boolean,
  selectedVoice?: string,
  selectedPresenter?: string
) => {
  const result: { transcript?: string; selectedVoice?: string; selectedPresenter?: string } = {};
  if (!isCustomVoiceSet && !transcript) {
    result.transcript = "Transcript required";
  }
  if (!isCustomVoiceSet && transcript && transcript.length > maxTranscriptLength) {
    result.transcript = `Transcript passed the max length`;
  }
  if (!isCustomVoiceSet && !validateIllegalCharsTranscript(transcript as string)) {
    result.transcript = `Invalid characters`;
  }
  if (!selectedVoice) {
    result.selectedVoice = "Please select voice";
  }
  if (!selectedPresenter) {
    result.selectedPresenter = "Please select presenter";
  }

  return result;
};
