import { ListenStatus, voicesActions } from "app/store/slices/voices.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";
import { TranscriptAudioPlayType } from "app/types";
import { v4 as uuidv4 } from "uuid";
import scenesSelectors from "app/store/selectorsV2/scenes.selectors";

const useTranscriptPlay = ({
  sceneId,
  autoPlay,
  type
}: {
  sceneId?: string;
  autoPlay: boolean;
  type: TranscriptAudioPlayType;
}) => {
  const dispatch = useAppDispatch();
  const draft = useAppSelector(({ drafts }) => drafts.currentDraft);
  const footerAudioPlayer = useAppSelector(({ voices }) => voices.footerAudioPlayer);
  const footerLoading = useAppSelector(({ voices }) => voices.footerLoading);
  const scene = useAppSelector((state) =>
    scenesGlobalSelectors.selectById(state, sceneId as string)
  );

  const voice = useAppSelector((state) =>
    scenesSelectors.getSceneVoiceBySceneId(state, sceneId as string)
  );

  const transcriptAudio = scene?.attributes?.media?.transcript?.url;
  const isCurrentSceneLoaded =
    sceneId === footerAudioPlayer.sceneId && type === TranscriptAudioPlayType.scene;
  const isCurrentDraftLoaded =
    draft?.id === footerAudioPlayer.draftId &&
    !footerAudioPlayer.sceneId &&
    type === TranscriptAudioPlayType.draft;

  const isAnyAudioLoading = footerLoading || footerAudioPlayer.status === ListenStatus.loading;
  const isCurrentAudioLoading = isCurrentSceneLoaded && isAnyAudioLoading;
  const playAudio = () => {
    dispatch(
      voicesActions.updateFooterAudioPlayer({
        status: ListenStatus.play,
        draftId: draft?.id as string,
        sceneId
      })
    );
  };
  const pauseAudio = () => {
    dispatch(
      voicesActions.updateFooterAudioPlayer({
        url: footerAudioPlayer.url,
        status: ListenStatus.pause,
        draftId: draft.id as string,
        sceneId
      })
    );
  };
  const toggleListenStatusChange = () => {
    const nextStatus =
      footerAudioPlayer.status === ListenStatus.pause ||
      footerAudioPlayer.status === ListenStatus.idle
        ? ListenStatus.play
        : ListenStatus.pause;
    dispatch(
      voicesActions.updateFooterAudioPlayer({
        url: footerAudioPlayer.url,
        status: nextStatus,
        draftId: draft.id as string,
        sceneId
      })
    );

    dispatch(analyticsEvents.audioPreview({ context: "scene", action: nextStatus }));
  };
  const rewind = () => {
    dispatch(
      voicesActions.updateFooterAudioPlayer({
        status: ListenStatus.rewind
      })
    );

    dispatch(analyticsEvents.audioPreview({ context: "scene", action: ListenStatus.rewind }));
  };
  const onTogglePlayPause = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    if (isCurrentSceneLoaded || isCurrentDraftLoaded) {
      toggleListenStatusChange();
    } else if (transcriptAudio && type === TranscriptAudioPlayType.scene) {
      // send to sizzle instead of playing the file
      dispatch(
        voicesActions.createAudioPreviewRequest({
          orderId: uuidv4(),
          draftId: draft.id as string,
          sceneId,
          type: TranscriptAudioPlayType.scene,
          voiceId: voice?.id as string,
          autoPlay
        })
      );
      dispatch(analyticsEvents.audioPreview({ context: "scene", action: ListenStatus.play }));
    } else if (type === TranscriptAudioPlayType.scene) {
      dispatch(
        voicesActions.createAudioPreviewRequest({
          orderId: uuidv4(),
          draftId: draft.id as string,
          sceneId,
          type: TranscriptAudioPlayType.scene,
          voiceId: voice?.id as string,
          autoPlay
        })
      );
      dispatch(analyticsEvents.audioPreview({ context: "scene", action: ListenStatus.play }));
    } else {
      dispatch(analyticsEvents.audioPreview({ context: "video", action: ListenStatus.play }));

      dispatch(
        voicesActions.createAudioPreviewRequest({
          orderId: uuidv4(),
          draftId: draft.id as string,
          type: TranscriptAudioPlayType.draft,
          autoPlay
        })
      );
    }
  };

  return {
    isCurrentAudioLoading,
    isCurrentDraftLoaded,
    onTogglePlayPause,
    isCurrentSceneLoaded,
    isAnyAudioLoading,
    pauseAudio,
    playAudio,
    rewind
  };
};

export default useTranscriptPlay;
