/* eslint react/prop-types: 0 */
import styled, { css } from "styled-components";
import React, { MouseEventHandler, PointerEventHandler, UIEventHandler } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  ${({
    $flex,
    $gap,
    $padding,
    $width,
    $height,
    $justify,
    $overflow,
    $align,
    $alignSelf,
    $position,
    $cursor,
    $wrap,
    $margin
  }: {
    $flex?: string;
    $gap?: string;
    $padding?: string;
    $width?: string;
    $height?: string;
    $justify?: string;
    $overflow?: string;
    $align?: string;
    $alignSelf?: string;
    $position?: string;
    $cursor?: string;
    $wrap?: string;
    $margin?: string;
  }) => css`
    flex: ${$flex};
    height: ${$height};
    width: ${$width};
    overflow: ${$overflow};
    justify-content: ${$justify};
    align-items: ${$align};
    gap: ${$gap};
    padding: ${$padding};
    cursor: ${$cursor};
    flex-wrap: ${$wrap};
    align-self: ${$alignSelf};
    margin: ${$margin};
    position: ${$position};
  `};
`;

export interface FlexColumnProps<T> {
  flex?: string;
  height?: string;
  width?: string;
  overflow?: string;
  justify?: string;
  align?: string;
  gap?: string;
  padding?: string;
  className?: string;
  onClick?: MouseEventHandler<T>;
  onScroll?: UIEventHandler<T>;
  onPointerMove?: PointerEventHandler<T>;
  onPointerLeave?: PointerEventHandler<T>;
  onMouseEnter?: MouseEventHandler<T>;
  onMouseLeave?: MouseEventHandler<T>;
  children?: React.ReactNode;
  wrap?: string;
  alignSelf?: string;
  margin?: string;
  position?: string;
}
export const FlexColumn = React.forwardRef<HTMLDivElement, FlexColumnProps<HTMLDivElement>>(
  (props, ref) => {
    const {
      flex,
      width,
      height,
      justify,
      gap,
      padding,
      align,
      alignSelf,
      position,
      overflow,
      className,
      onClick,
      onScroll,
      onPointerMove,
      onPointerLeave,
      onMouseEnter,
      onMouseLeave,
      children,
      margin,
      wrap,
      ...rest
    } = props;
    return (
      <Container
        $align={align}
        $alignSelf={alignSelf}
        $position={position}
        $gap={gap}
        $padding={padding}
        $flex={flex}
        $width={width}
        $height={height}
        $justify={justify}
        className={className}
        $overflow={overflow}
        $margin={margin}
        onClick={onClick}
        onScroll={onScroll}
        onPointerMove={onPointerMove}
        onPointerLeave={onPointerLeave}
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        $cursor={onClick ? "pointer" : undefined}
        $wrap={wrap}
        {...rest}
      >
        {children}
      </Container>
    );
  }
);

FlexColumn.displayName = "FlexColumn";
