import animationData from "app/assets/lottie/edupia-loading.json";
import Lottie from "lottie-react";

import styled from "styled-components";

const StyledLottie = styled(Lottie)`
  height: 100%;
  width: 100%;
`;

const LottieSpinLoader = () => {
  return <StyledLottie loop autoplay animationData={animationData} />;
};

export default LottieSpinLoader;
