import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBrandKit, upsertBrandKit } from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { BrandKit, PaletteColorKey } from "app/types";

const prefix = "[BrandKit]";

const getBrandKitRequest = createAsyncThunk(
  `${prefix} getBrandKitRequest`,
  async () => {
    const res = await getBrandKit();
    res.map((response) => {
      const { palette } = response;
      if (palette?.length === 5) {
        palette[0] = { ...palette[0], key: palette[0].key || PaletteColorKey.color_B };
        palette[1] = { ...palette[1], key: palette[1].key || PaletteColorKey.color_A1 };
        palette[2] = { ...palette[2], key: palette[2].key || PaletteColorKey.color_A2 };
        palette[3] = { ...palette[3], key: palette[3].key || PaletteColorKey.color_A3 };
        palette[4] = { ...palette[4], key: palette[4].key || PaletteColorKey.color_W };
      }
      return response;
    });
    return res;
  },
  thunkOptions
);
const upsertBrandkitRequest = createAsyncThunk<BrandKit, Partial<BrandKit>>(
  `${prefix} upsertBrandkitRequest`,
  async (brandkit) => {
    const res = await upsertBrandKit(brandkit);
    return res;
  },
  thunkOptions
);

export default {
  getBrandKitRequest,
  upsertBrandkitRequest
};
