import styled from "styled-components";

const CloseIconContainer = styled.div<{
  $size?: string;
  $background?: string;
  $isOutsideTopRight?: boolean;
}>`
  margin-top: ${({ $isOutsideTopRight }) => ($isOutsideTopRight ? "-50px" : "5px")};
  margin-right: ${({ $isOutsideTopRight }) => ($isOutsideTopRight ? "-55px" : "5px")};
  i {
    width: ${(props) => (props.$size ? props.$size : "25px")};
    height: ${(props) => (props.$size ? props.$size : "25px")};
    color: ${(props) => props.theme.gray7};
    &:hover {
      color: ${(props) => props.theme.blue4};
    }
  }
`;

const CloseModalIcon = ({
  onClick,
  size,
  isOutsideTopRight
}: {
  onClick?: () => void;
  size?: string;
  background?: string;
  isOutsideTopRight?: boolean;
}) => {
  return (
    <CloseIconContainer $isOutsideTopRight={isOutsideTopRight} $size={size} onClick={onClick}>
      <i className="far fa-xmark" />
    </CloseIconContainer>
  );
};

export default CloseModalIcon;
