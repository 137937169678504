import { ReactComponent as HourOneNewLogo } from "app/assets/sider/hourone-new-logo.svg";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import StepsBar from "app/pages/createDigitalTwinPage/StepsBar";
import styled, { useTheme } from "styled-components";
import { VirtualTwinSteps } from "app/types/virtualTwin";
import ConditionalRender from "app/components/common/ConditionalRender";
import BackButton from "app/components/editor/sideDrawers/StyleDrawer/BackButton";
import { useNavigate } from "react-router-dom";
import { Location } from "app/types";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import RecorderContext from "app/hooks/recorder/RecorderContext";
import { Button } from "@nextui-org/react";

const antdBreakPoint = "576px";
const Wrapper = styled(H1_FlexRow)`
  background-color: #ffffff;
  min-height: 80px;
  @media (max-width: ${antdBreakPoint}) {
    display: block;
    margin-bottom: auto;
  }
`;

const LogoBackWrapper = styled(H1_FlexRow)<{ $color: string }>`
  max-width: 150px;
  i:before {
    color: ${({ $color }) => $color};
  }
  @media (max-width: ${antdBreakPoint}) {
    order: 2;
  }
`;

const StepsWrapper = styled(H1_FlexColumn)`
  max-width: ${antdBreakPoint};
  width: calc(100% - 50px);
  @media (max-width: ${antdBreakPoint}) {
    max-width: 100%;
    width: 100%;
    order: 3;
  }
`;

const ReStartButtonWrapper = styled(H1_FlexColumn)`
  flex: 1;
  button {
    min-width: 10px;
  }
  @media (max-width: ${antdBreakPoint}) {
    order: 2;
  }
`;

interface VirtualTwinTopBarProps {
  currentStep: VirtualTwinSteps;
  steps: VirtualTwinSteps[];
  onRestart: () => void;
}
const VirtualTwinTopBar = ({ currentStep, steps, onRestart }: VirtualTwinTopBarProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { reset: resetRecording } = RecorderContext.useRecorderContext();

  const onBack = () => {
    (async () => {
      await resetRecording();
      navigate(Location.Home);
    })();
  };

  const stopDevicesAndRestart = () => {
    (async () => {
      await resetRecording();
      onRestart();
    })();
  };

  return (
    <Wrapper padding="10px" justify="flex-start" align="center" width="100%">
      <LogoBackWrapper justify="flex-start" gap="15px" $color={theme.blue4}>
        <BackButton onClick={onBack} displayText={false} noBorder={true} />
        <HourOneNewLogo height={45} width="auto" fill={theme.blue4} />
      </LogoBackWrapper>
      <H1_FlexColumn justify="center" align="center" width="100%">
        <StepsWrapper>
          <StepsBar steps={steps} currentStep={currentStep} />
        </StepsWrapper>
      </H1_FlexColumn>
      <ConditionalRender condition={steps[0] !== currentStep}>
        <ReStartButtonWrapper>
          <Button
            variant="light"
            isIconOnly
            onClick={stopDevicesAndRestart}
            startContent={<i className="fa-light fa-arrow-rotate-left" />}
          />
        </ReStartButtonWrapper>
      </ConditionalRender>
    </Wrapper>
  );
};
export default VirtualTwinTopBar;
