import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import { UseCase } from "app/types";

const currentPreferences = (state: RootState) => state.user.preferences;
const currentUserData = (state: RootState) => state.user.userData;
const currentFeatures = (state: RootState) => state.user.features;

export const isOnBoardingElearning = createSelector(
  [currentPreferences, currentUserData],
  (preferences, userData) => {
    return (
      preferences.show_recommended &&
      userData?.hubspot?.initial &&
      userData?.use_case_selection === UseCase.E_LEARNING
    );
  }
);

export const isOnBoardingNotElearning = createSelector(
  [currentPreferences, currentUserData],
  (preferences, userData) => {
    return (
      preferences.show_recommended &&
      userData?.hubspot?.initial &&
      userData?.use_case_selection !== UseCase.E_LEARNING
    );
  }
);

export const getFeaturesList = createSelector([currentFeatures], (features) => {
  return features.map((feature) => feature.name);
});
