import { MetadataLimits, PlanEnum, SubscriptionPlanEnum } from "app/types/userData";
import { BaseThemeSchema } from "app/utils/theme";

export const avatarColorsList = (theme: BaseThemeSchema) => [
  theme.blue2,
  theme.green2,
  theme.orange2,
  theme.pink2,
  theme.blue2,
  theme.green2,
  theme.orange2,
  theme.pink2
];

export enum MemberStatus {
  in_review = "in_review",
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected"
}

export interface Invite {
  email: string;
  id: string;
  sender_user_id: string;
  status: MemberStatus;
  workspace_id: string;
  workspace_name: string;
}
export interface Member {
  id?: string;
  email?: string;
  name?: string;
  picture?: string;
  nickname?: string;
  user_id?: string;
  status?: MemberStatus;
  is_owner?: boolean;
  role?: WorkspaceRole;
}

export interface InviteMember {
  email: string;
  role: WorkspaceRole;
}

export interface GetInvitesResponse {
  invites: Member[];
  workspace_id: string;
  workspace_info: Workspace;
}

export interface Team {
  id: string;
  name: string;
  owner_id?: string;
  members: Member[];
  default: boolean;
  updated_at?: string;
  created_at?: string;
}

export interface Workspace {
  name: string;
  owner_id?: string;
  members: Member[];
  plan: PlanEnum;
  id: string;
  default: boolean;
  created_at?: string;
  updated_at?: string;
  current_team: Team;
  max_seats?: number;
  is_owner?: boolean;
  permissions?: string[];
  metadata?: {
    type?: WorkspaceType;
    subscription_plan?: SubscriptionPlanEnum;
    limits?: MetadataLimits;
    unlimited?: boolean;
    enterprise?: boolean;
    llm_payment_enable?: boolean;
  };
  role?: WorkspaceRole;
}

export interface WorkspaceRequest {
  name: string;
  members: Member[];
}

export interface Channel {
  name: string;
}

export interface ChannelResponse {
  channels: Channel[];
}

export const maxWorkspaceMembers = {
  [PlanEnum.onBoarding]: 4,
  [PlanEnum.personal]: 4,
  [PlanEnum.basic]: 4,
  [PlanEnum.pro]: 4,
  [PlanEnum.legacy]: 4,
  [PlanEnum.advanced]: 6,
  [PlanEnum.business]: 6,
  [PlanEnum.enterprise]: 10
};

export enum WorkspacePermission {
  change_role = "change_role"
}

export enum WorkspaceRole {
  viewer = "viewer",
  member = "member",
  owner = "owner"
}

export enum WorkspaceType {
  Iconic = "iconic"
}

export interface ChangeRoleRequest {
  workspaceId: string;
  userId: string;
  role: WorkspaceRole;
}
export enum WebhookStatus {
  active = "active",
  inactive = "inactive",
  paused = "paused"
}
export interface Webhook {
  id: string;
  name: string;
  description?: string;
  url: string;
  status: WebhookStatus;
  events: string[];
  updated_at: string;
  signing_secret?: string;
}

export type WebHookRequest = Omit<Webhook, "id" | "updated_at" | "status">;
