import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { playlistsActions } from "app/store/slices/playlists.slice";
import { Location, Playlist } from "app/types";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { playListMessages } from "app/pages/PlaylistsPage/messages";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { fetchingStatus } from "app/utils/helpers";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { playlistsGlobalSelectors } from "app/store/adapters/adapters";
import EmptyState from "app/components/common/EmptyState";
import { Button } from "@nextui-org/react";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import RenamePlaylistModal from "app/pages/PlaylistsPage/RenamePlaylistModal";

const OpacVideoSign = styled(H1_FlexRow)`
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.3);
  top: 110px;
  right: 11px;
`;
const MoreOptionsButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;
const BoxFlexColumn = styled(H1_FlexColumn)`
  &:hover {
    ${MoreOptionsButton} {
      opacity: 1;
    }
  }
`;
const ThumbnailImage = styled.img`
  width: 253px;
  height: calc(253px * 9 / 16);
  border-radius: 10px;
`;
const PlaylistsPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [renamePlaylistId, setRenamePlaylistId] = useState<string | undefined>(undefined);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const playlists = useAppSelector(playlistsGlobalSelectors.selectAll);
  const getPlaylistsStatus = useAppSelector((state) => state.playlists.status);
  const isLoading = getPlaylistsStatus === fetchingStatus.loading;

  useEffect(() => {
    dispatch(playlistsActions.getPlaylistsRequest());
    setIsLoaded(true);
  }, []);

  const onRenamePlaylist = (playlistId: string) => {
    setRenamePlaylistId(playlistId);
  };

  const onRemovePlaylist = (playlistId: string) => {
    dispatch(
      playlistsActions.deletePlaylistRequest({
        id: playlistId as string
      })
    );
  };
  const items = (playlistId: string) => {
    const result = [
      {
        key: "rename",
        onClick: () => onRenamePlaylist(playlistId),
        label: <H1_TextSmall>{formatMessage(playListMessages.rename)}</H1_TextSmall>,
        startContent: <i className="fa fa-edit" />
      },
      {
        key: "remove",
        onClick: () => onRemovePlaylist(playlistId),
        label: <H1_TextSmall>{formatMessage(playListMessages.remove)}</H1_TextSmall>,
        startContent: <i className="fa fa-trash" />
      }
    ];
    return result;
  };

  const onClickPlaylist = (playlistId: string) => {
    navigate(`${Location.Playlists}/${playlistId}`);
  };

  if (isLoading || !isLoaded) {
    return <CircleLoader />;
  }

  if (playlists.length === 0) {
    return (
      <EmptyState
        text={formatMessage(playListMessages.noPlaylistsText)}
        description={formatMessage(playListMessages.noPlaylistsDesc)}
        icon="far fa-list-music"
      />
    );
  }

  return (
    <H1_FlexColumn gap="25px" padding="0 35px 20px">
      <RenamePlaylistModal
        id={renamePlaylistId}
        onCloseModal={() => setRenamePlaylistId(undefined)}
        visible={!!renamePlaylistId}
      />
      <H1_TextSmall fontSize="16px" fontWeight={500}>
        {formatMessage(playListMessages.headline)}
      </H1_TextSmall>
      <H1_FlexRow wrap="wrap" gap="20px">
        {playlists.map((playlist: Playlist) => (
          <BoxFlexColumn
            onClick={() => onClickPlaylist(playlist.id)}
            key={playlist.id}
            position="relative"
            width="253px"
            height="210px"
            gap="7px"
          >
            <NextDropdown withWhiteBackground items={items(playlist.id)}>
              <MoreOptionsButton
                size="sm"
                isIconOnly
                startContent={<i className="fa fa-ellipsis" />}
              />
            </NextDropdown>
            <ThumbnailImage src={playlist.thumbnail || fallbackPlaceholder} alt="playlist" />
            <H1_FlexRow justify="space-between">
              <H1_FlexColumn>
                <H1_TextSmall>{playlist.title}</H1_TextSmall>
              </H1_FlexColumn>
            </H1_FlexRow>
            <OpacVideoSign
              height="24px"
              position="absolute"
              align="center"
              gap="4px"
              padding="0 8px"
            >
              <H1_Icon isCursorPointer color="white" icon="far fa-list-music" />
              {playlist.draft_ids && (
                <H1_TextXs color="white">
                  {formatMessage(playListMessages.numOfVideos, {
                    number: playlist.draft_ids.length
                  })}
                </H1_TextXs>
              )}
            </OpacVideoSign>
          </BoxFlexColumn>
        ))}
      </H1_FlexRow>
    </H1_FlexColumn>
  );
};

export default PlaylistsPage;
