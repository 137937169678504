import styled, { useTheme } from "styled-components";
import { message } from "antd";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { useAppSelector } from "app/hooks";
import {
  H1_TextMiddle,
  H1_TextSmall,
  H1_TextSubtitle,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { securityCardMessages } from "app/pages/profile/messages";
import { Button, Divider } from "@nextui-org/react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import { fetchingStatus } from "app/utils/helpers";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import useCopy from "app/hooks/useCopy";
import { useState } from "react";
import { StyledModal } from "app/components/common/StyledModal";

const StyledDivider = styled(Divider)`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const BorderedBox = styled(H1_FlexColumn)`
  border: 1px solid ${({ theme }) => theme.gray5};
`;

const VisibleFlexRow = styled(H1_FlexRow)<{ $visible: boolean }>`
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  pointer-events: ${({ $visible }) => ($visible ? "auto" : "none")};
  transition: 0.3s opacity ease-in-out;
`;

const QrImage = styled.img`
  width: 120px;
  height: 120px;
`;

interface MfaModalProps {
  visible: boolean;
  onClose: () => void;
}

const MfaModal = ({ visible, onClose }: MfaModalProps) => {
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { triggerCopy } = useCopy();
  const mfaStatus = useAppSelector((state) => state.user.mfaStatus);
  const mfaInfo = useAppSelector((state) => state.user.mfaInfo);
  const isMfaLoading = mfaStatus === fetchingStatus.loading;

  const onCopySecretCode = () => {
    if (mfaInfo?.secret) {
      triggerCopy({
        copyContent: JSON.stringify(mfaInfo.secret, null, 4),
        shouldNotify: false
      });
      message.success("Copied to clipboard");
    }
  };

  const onShowSecretCode = () => {
    setIsCodeVisible(!isCodeVisible);
  };

  if (!mfaInfo) {
    return null;
  }
  return (
    <StyledModal
      width="433px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "405px" }}
      onCancel={onClose}
      closeIcon={<CloseModalIcon />}
      $padding="24px 30px 35px 33px"
      $alignItems="center"
    >
      <H1_TextSubtitle>{formatMessage(securityCardMessages.securityModalTitle)}</H1_TextSubtitle>
      <StyledDivider />
      <H1_FlexRow gap="10px">
        <H1_Icon icon="fal fa-qrcode" size="26px" />
        <H1_FlexColumn gap="10px">
          <H1_TextMiddle fontWeight={500}>
            {formatMessage(securityCardMessages.googleAuthTitle)}
          </H1_TextMiddle>
          <H1_TextMiddle color={theme.gray8} whiteSpace="normal" lineHeight="18px">
            {formatMessage(securityCardMessages.googleAuthSubtitle)}
          </H1_TextMiddle>
        </H1_FlexColumn>
      </H1_FlexRow>
      <StyledDivider />
      <ConditionalRender condition={isMfaLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!isMfaLoading}>
        <H1_FlexRow width="100%" gap="20px" padding="10px" justify="space-between">
          <QrImage src={mfaInfo.qrCode as string} />
          <BorderedBox width="calc(100% - 140px)" height="120px" padding="20px">
            <H1_TextSmall whiteSpace="normal">
              {formatMessage(securityCardMessages.orEnterCode)}
            </H1_TextSmall>
            <H1_FlexRow justify="center" gap="10px">
              <Button
                variant="light"
                isIconOnly
                startContent={<H1_Icon icon="far fa-copy" isCursorPointer />}
                onClick={onCopySecretCode}
              />
              <Button
                variant="light"
                isIconOnly
                startContent={<H1_Icon icon="far fa-eye" isCursorPointer />}
                onClick={onShowSecretCode}
              />
            </H1_FlexRow>
          </BorderedBox>
        </H1_FlexRow>
        <VisibleFlexRow $visible={isCodeVisible} onClick={onCopySecretCode}>
          <H1_TextXs fontWeight={500} fontFamily="DrukWide">
            {mfaInfo.secret}
          </H1_TextXs>
        </VisibleFlexRow>
      </ConditionalRender>
    </StyledModal>
  );
};

export default MfaModal;
