import { Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Draft } from "app/types";
import styled, { css } from "styled-components";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import usePermissions from "app/hooks/usePermissions";
import { BackgroundMusic } from "app/types/music";
import { getBackgroundMusicById } from "app/store/selectorsV2/music.selectors";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import PauseCircle from "app/assets/icons-reals/PauseCircleIcon.svg";
import PlayCircle from "app/assets/icons-reals/PlayCircleIcon.svg";
import MusicPlaceholder from "app/assets/images/musicPlaceholder.jpg";
import GradientPlaceholder from "app/components/common/GradientPlaceholder";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import DevModePopover from "app/components/DevModePopover";
import { musicActions } from "app/store/slices/music.slice";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { useIntl } from "react-intl";
import { Button, Tooltip } from "@nextui-org/react";
import { musicDrawerMessages } from "app/components/editor/sideDrawers/messages";

const MusicIcon = styled.img<{ $isPlaying: boolean }>`
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 3;
  opacity: ${({ $isPlaying }) => ($isPlaying ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const IconButton = styled(Button)`
  opacity: 0;
  z-index: -1;
  visibility: collapse;
`;

const StyledSelectionElementFlexRow = styled(H1_FlexRow)<{
  $isSelected: boolean;
  $sticky?: boolean;
}>`
  border-radius: 9px;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid transparent;
  &&:hover {
    background-color: ${(props) => props.theme.gray2};
    padding: 15px;
    ${MusicIcon}, ${IconButton} {
      opacity: 1;
      z-index: 1;
      visibility: visible;
    }
  }
  ${({ $isSelected, $sticky, theme }) =>
    css`
      border: ${$isSelected && !$sticky ? `1px solid ${theme.blue4}` : ""};
      border-radius: 4px;
      background-color: ${$sticky && theme.gray1};
    `}
`;

const ElementImage = styled(H1_FlexRow)`
  border-radius: 2px;
`;

const NameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.gray11};
  font-size: 14px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  line-height: 22px;
  && {
    max-width: 100%;
  }
`;

export interface SelectedMusicElementProp {
  itemId: string;
  isSelected: boolean;
  isPlaying: boolean;
  isSticky?: boolean;
  onClickMusicPlay?: (e: React.MouseEvent, itemId: string) => void;
  onChooseMusic?: (itemId: string) => void;
  query: string;
}

const SelectedMusicElement = ({
  itemId,
  isSelected,
  isPlaying,
  isSticky,
  onClickMusicPlay,
  onChooseMusic,
  query
}: SelectedMusicElementProp) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isEditName, setIsEditName] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>("");
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { isWriteWorkspaceAllow } = usePermissions();

  const developerMode = useAppSelector((state) => state.uiV2.developerMode);
  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const item: BackgroundMusic = useAppSelector((state) =>
    getBackgroundMusicById(state, { query, id: itemId })
  ) as BackgroundMusic;

  const draftId = draft?.id as string;

  useEffect(() => {
    if (!currentName) {
      setCurrentName(item.title);
    }
  }, [item]);

  const onClickEditName = () => {
    if (isEditName && currentName !== item.title) {
      dispatch(musicActions.updateBackgroundMusicListRequest({ id: itemId, title: currentName }));
    }
    setIsEditName(!isEditName);
  };

  const onClickDelete = () => {
    dispatch(musicActions.deleteBackgroundMusicListRequest({ id: itemId }));
  };

  const onMouseEnter = () => {
    setIsHover(true);
  };

  const onMouseLeave = () => {
    setIsHover(false);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      dispatch(musicActions.updateBackgroundMusicListRequest({ id: itemId, title: currentName }));
      setIsEditName(false);
    }
  };

  const chooseMusic = () => {
    if (!isWriteWorkspaceAllow || isSticky) {
      return;
    }
    if (onChooseMusic) {
      const { id } = item;
      dispatch(googleEvents.selectBackgroundMusicTrack({ track: item, draft: draftId }));
      onChooseMusic(id);
    }
  };

  const onClickPlay = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    if (onClickMusicPlay && e) {
      onClickMusicPlay(e, itemId);
    }
  };

  return (
    <StyledSelectionElementFlexRow
      padding="20px"
      width="334px"
      height="120px"
      align="center"
      gap="19px"
      flex="0 0 auto"
      $isSelected={isSelected}
      key={itemId}
      onClick={chooseMusic}
      $sticky={isSticky}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ElementImage
        flex="0 0 80px"
        width="80px"
        height="80px"
        position="relative"
        onClick={onClickPlay}
      >
        {item.image ? (
          <Image preview={false} src={item.image ?? MusicPlaceholder} fallback={MusicPlaceholder} />
        ) : (
          <GradientPlaceholder gradientStr={item.backgroundGradient} key={itemId} />
        )}
        <MusicIcon
          $isPlaying={isPlaying}
          src={isPlaying ? PauseCircle : PlayCircle}
          alt={isPlaying ? "Pause" : "Play"}
        />
      </ElementImage>
      <H1_FlexColumn width={item.is_public ? "100%" : "calc(100% - 90px)"}>
        <H1_FlexRow justify="space-between" align="center" gap="10px">
          <ConditionalRender condition={!isEditName}>
            <NameText ellipsis={{ tooltip: item.title }}>{item.title}</NameText>
          </ConditionalRender>
          <ConditionalRender condition={isEditName}>
            <H1_Input
              initialValue={currentName}
              onChange={(e) => setCurrentName(e.target.value)}
              isFixedWidth
              minWidth="112px"
              // @ts-ignore - should be added
              onKeyDown={onKeyDown}
            />
          </ConditionalRender>
          <ConditionalRender condition={!item.is_public}>
            <H1_FlexRow flex={isHover ? "0 0 90px" : "0 0 0"}>
              <Tooltip content={formatMessage(musicDrawerMessages.editTitle)}>
                <IconButton
                  variant="light"
                  isIconOnly
                  startContent={<i className={isEditName ? "far fa-check" : "far fa-pencil"} />}
                  onClick={onClickEditName}
                />
              </Tooltip>
              <Tooltip content={formatMessage(musicDrawerMessages.deleteMusic)}>
                <IconButton
                  variant="light"
                  isIconOnly
                  startContent={<i className="far fa-trash" />}
                  onClick={onClickDelete}
                />
              </Tooltip>
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexRow>
        <H1_TextXs>{item.artist}</H1_TextXs>
        <H1_TextXs whiteSpace="pre-wrap">{item.duration}</H1_TextXs>
      </H1_FlexColumn>
      <ConditionalRender condition={developerMode}>
        <H1_FlexRow flex={"1"} justify={"flex-end"}>
          <DevModePopover
            value={{ id: "music", media_id: itemId, url: "{{OPTIONAL}}" }}
            padding={"2px"}
            path={"global_media_elements[#]"}
            position={"relative"}
            placement="left"
          />
        </H1_FlexRow>
      </ConditionalRender>
    </StyledSelectionElementFlexRow>
  );
};

export default SelectedMusicElement;
