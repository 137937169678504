import { defineMessages } from "react-intl";

// Login
export const loginMessages = defineMessages({
  welcomeToReals: {
    id: `login.login-page.welcome-to-reals`,
    defaultMessage: `Welcome to <span>Hour One</span>`
  },
  welcomeToRealsDesktop: {
    id: `login.login-page.welcome-to-reals-desktop`,
    defaultMessage: `Welcome to Hour One`
  },
  createYourAccount: {
    id: `login.login-page.create-your-account`,
    defaultMessage: `Create your account`
  },
  verificationLinkSent: {
    id: `login.login-page.verification-link-sent`,
    defaultMessage: `Verify your email`
  },
  verificationLinkSubtitle: {
    id: `login.login-page.verification-link-sent-subtitle`,
    defaultMessage: `We sent you an email to
                <span>{email}</span>`
  },
  verificationClickHere: {
    id: `login.login-page.verification-link-click-here`,
    defaultMessage: `Click the link inside to get started.`
  },
  verificationCode: {
    id: `login.login-page.verification-code`,
    defaultMessage: `Enter the verification code`
  },
  yourEmail: {
    id: `login.login-page.your-email`,
    defaultMessage: "your email."
  },
  noEmail: {
    id: `login.login-page.no-email`,
    defaultMessage: "Didn't get a verification email?"
  },
  checkSpam: {
    id: `login.login-page.check-spam`,
    defaultMessage: "Check your spam folder or <a>contact support</a>"
  },
  sendAgainButton: {
    id: `login.login-page.send-again-button`,
    defaultMessage: "Resend email"
  },
  emailSent: {
    id: `login.login-page.email-sent`,
    defaultMessage: "Email sent."
  },
  loginTab: {
    id: `login.login-page.login-tab`,
    defaultMessage: "Log In"
  },
  signUpTab: {
    id: `login.login-page.sign-up-tab`,
    defaultMessage: "Sign Up"
  },
  login: {
    id: `login.login-page.login`,
    defaultMessage: "Login"
  },
  signUp: {
    id: `login.login-page.sign-up`,
    defaultMessage: "Sign Up"
  },
  emailPlaceholder: {
    id: `login.login-page.email-placeholder`,
    defaultMessage: "Business Email"
  },
  or: {
    id: `login.login-page.or`,
    defaultMessage: "OR"
  },
  passwordPlaceholder: {
    id: `login.login-page.password-placeholder`,
    defaultMessage: "Password"
  },
  forgotYourPassword: {
    id: `login.login-page.forgot-password`,
    defaultMessage: "Forgot your Password? Click here"
  },
  forgotPasswordTitle: {
    id: `login.login-page.forgot-password-title`,
    defaultMessage:
      "PLEASE ENTER YOUR EMAIL ADDRESS. WE WILL SEND YOU AN EMAIL TO RESET YOUR PASSWORD."
  },
  forgotPasswordEmailSent: {
    id: `login.login-page.forgot-password-email-sent`,
    defaultMessage: "WE HAVE JUST SENT YOU AN EMAIL TO RESET YOUR PASSWORD."
  },
  totpExplain: {
    id: `login.login-page.totp-expl`,
    defaultMessage:
      "Open your two-factor authenticator (TOTP) app or browser extension to view your authentication code."
  },
  verifyButton: {
    id: `login.login-page.verify-button`,
    defaultMessage: "Verify"
  },
  authenticationCode: {
    id: `login.login-page.authentication-code`,
    defaultMessage: "Authentication code"
  },
  backToLogin: {
    id: `login.login-page.back-to-login`,
    defaultMessage: "Back to login"
  },
  changePassword: {
    id: `login.login-page.change-password`,
    defaultMessage: "Change password"
  },
  newPassword: {
    id: `login.login-page.new-password`,
    defaultMessage: "New password"
  },
  confirmNewPassword: {
    id: `login.login-page.confirm-new-password`,
    defaultMessage: "Confirm new password"
  },
  sendEmail: {
    id: `login.login-page.send-email`,
    defaultMessage: "Send email"
  },
  passwordReq: {
    id: `login.login-page.password-req`,
    defaultMessage: "Password requirements"
  },
  passwordRule1: {
    id: `login.login-page.password-rule1`,
    defaultMessage: "At least 8 characters"
  },
  passwordRule2: {
    id: `login.login-page.password-rule2`,
    defaultMessage: "At least one uppercase letter"
  },
  passwordRule3: {
    id: `login.login-page.password-rule3`,
    defaultMessage: "At least one lowercase letter"
  },
  passwordRule4: {
    id: `login.login-page.password-rule4`,
    defaultMessage: "At least one number"
  },
  signUpApproval: {
    id: `login.login-page.sign-up-text`,
    defaultMessage: "By clicking Sign Up, you agree to the {servicesAgreement} and {privacyPolicy}."
  }
});
