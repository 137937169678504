import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import Config from "app/config/Config";
import React from "react";
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from "react-router-dom";

export const envEnum = {
  local: ["local"],
  dev: ["dev", "development", "stage", "staging", "develop"],
  prod: ["prod", "production"]
};

const errorSampleRate = () => {
  if (envEnum.dev.includes(Config.env)) {
    return 0.1;
  }
  if (envEnum.prod.includes(Config.env)) {
    return 1.0;
  }
  return 0;
};

export const transactionId = Math.random().toString(36).substr(2, 9);

export const initializeSentry = () => {
  // Integrate router
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api
  Sentry.init({
    environment: Config.env,
    dsn: Config.sentryDsn,
    release: Config.release,
    debug: envEnum.local.includes(Config.env),
    enabled: !envEnum.local.includes(Config.env),
    replaysOnErrorSampleRate: errorSampleRate(),
    replaysSessionSampleRate: 0, // To reduce the volume of performance data captured, change tracesSampleRate to a value between 0 and 1.
    initialScope: (scope) => {
      // eslint-disable-next-line camelcase
      scope.setTags({ transaction_id: transactionId });
      return scope;
    },
    integrations: [
      // Add browser profiling integration to the list of integrations
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Replay({
        blockAllMedia: true
      })
    ],
    normalizeDepth: 10, // Or however deep you want your state context to be.
    tracesSampleRate: 1.0
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
