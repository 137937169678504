import React from "react";
import { useIntl } from "react-intl";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { CreditsMessages } from "app/pages/profile/messages";
import { Divider } from "antd";
import AiCreditsCard from "app/pages/profile/AiCreditsCard";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { ThemeMode } from "app/utils/theme";

const WhiteFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
  border-radius: 4px;
`;
const UpgradeIcon = styled(H1_Icon)`
  border-radius: 100px;
`;
const CreditsCard = () => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <WhiteFlexColumn flex="0 0 auto" width="100%" padding="20px 30px 30px">
      <H1_FlexRow gap="8px" align="center" height="32px">
        <UpgradeIcon
          icon="fa-sharp fa-solid fa-wand-magic-sparkles"
          backgroundColor="#FFAF4D"
          color="white"
          padding="8px"
          size="15px"
        />
        <H1_TextMiddle margin="0 0 3px 0" color={theme.gray8}>
          {intl.formatMessage(CreditsMessages.title)}
        </H1_TextMiddle>
      </H1_FlexRow>
      <Divider />
      <AiCreditsCard />
    </WhiteFlexColumn>
  );
};

export default CreditsCard;
