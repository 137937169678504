import { Button } from "@nextui-org/react";
import styled from "styled-components";

const StyledButton = styled(Button)<{ $size: string }>`
  border-radius: 50%;
  min-width: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  i {
    font-size: ${({ $size }) => `calc(${$size} / 2)`};
  }
`;

interface DeveloperButtonProps {
  onClick: (evt: any) => void;
  size?: string;
}
const DeveloperButton = ({ onClick, size = "18px" }: DeveloperButtonProps) => {
  return (
    <StyledButton
      color="primary"
      $size={size}
      isIconOnly
      size="sm"
      onClick={onClick}
      startContent={<i className="fa-sharp fa-regular fa-code"></i>}
    />
  );
};

export default DeveloperButton;
