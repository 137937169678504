import useModal, { ModalName } from "app/hooks/useModal";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import { Button, Card, CardBody, CardFooter, cn } from "@nextui-org/react";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { StyledModal } from "app/components/common/StyledModal";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import styled from "styled-components";

const StyledUpgradeButton = styled(Button)`
  color: white;
  i {
    color: white;
  }
`;

const StyledCard = styled(Card)`
  background: radial-gradient(circle at center, #1e3c72, #2a5298, #243b55);
`;
const StyledH2 = styled.h2`
  padding: 10px;
  color: burlywood;
`;

const NoEnoughVideoPreviewCreditsModal = () => {
  const dispatch = useAppDispatch();
  const { editorModalOpen, returnToModal, openModal } = useModal();
  const visible = editorModalOpen?.open === ModalName.noEnoughVideoPreviewCredits;

  const onContinue = () => {
    const upgradeText = "Upgrade to get more credits";
    const source = "no_enough_video_preview_credits_modal";
    dispatch(googleEvents.productUpgradeCta({ cta: source }));
    openModal(ModalName.paymentModalV2, { source, upgradeText });
  };
  const onCancel = () => {
    returnToModal();
  };

  const content = (
    <ul>
      <li className="flex items-center gap-1">
        <p className="text-small text-default-500">
          Upgrade now to get additional previews and enhance your video experience.
        </p>
      </li>
      <li className="flex justify-end items-center gap-1 mt-4">
        <StyledUpgradeButton
          color="warning"
          source="video_preview"
          onClick={onContinue}
          startContent={<i className="fas fa-crown" />}
        >
          Upgrade
        </StyledUpgradeButton>
      </li>
    </ul>
  );

  return (
    <StyledModal
      width="400px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "420px" }}
      maskClosable
      closable
      onCancel={onCancel}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="0"
      $alignItems="center"
      $overflow="hidden"
    >
      <StyledCard className="relative w-full max-w-[400px] pb-[120px] overflow-hidden">
        <CardBody className="overflow-hidden relative min-h-[300px] from-content1 to-default-100/50 p-8 before:inset-0 before:h-full before:w-full before:content-['']">
          <H1_FlexRow width="100%" justify="space-between" align="center">
            <h1 className="mb-4 text-red-400">Preview limit</h1>
          </H1_FlexRow>
          <StyledH2 className="inline from-foreground-800 to-foreground-500 bg-clip-text text-6xl font-semibold tracking-tight text-transparent dark:to-foreground-200">
            Preview
            <br />
            Credits
            <br />
            Used Up
          </StyledH2>
        </CardBody>
        <CardFooter
          className={cn(
            "absolute bottom-0 h-[120px] overflow-visible bg-content1 px-6 duration-300 ease-in-out transition-height",
            {
              "border-t-1 border-default-100": true
            }
          )}
        >
          {content}
        </CardFooter>
      </StyledCard>
    </StyledModal>
  );
};

export default NoEnoughVideoPreviewCreditsModal;
