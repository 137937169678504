import React, { useEffect, useState } from "react";

import styled from "styled-components";
import useModal, { ModalName } from "app/hooks/useModal";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import AudioRecorder from "app/components/editor/scene/transcriptAudioUploader/AudioRecorder";
import { voicesActions } from "app/store/slices/voices.slice";
import ConditionalRender from "app/components/common/ConditionalRender";
import { cloneVoiceMessages } from "app/components/editor/cloneVoice/messages";
import useAudioPlayer from "app/hooks/useAudioPlayer";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { v4 as uuidv4 } from "uuid";
import { fetchingStatus } from "app/utils/helpers";
import useNotifications from "app/hooks/useNotifications";
import CloneVoiceMainView from "app/components/editor/cloneVoice/CloneVoiceMainView";
import ApproveView from "app/components/editor/cloneVoice/ApproveView";
import { MimeType } from "app/types";
import useErrors from "app/hooks/useErrors";
import buildGeneralError from "app/hoc/ErrorNotifier/buildGeneralError";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import { CloneVoiceType } from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextHeadline } from "app/components/_Infrastructure/Typography";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const StyledButton = styled(Button)`
  min-width: 136px;
`;

const ButtonsWrapper = styled(H1_FlexRow)`
  align-self: end;
`;

enum VIEW_MODE {
  main,
  voiceRecording,
  voiceUpload,
  approve,
  finish
}

const CancelButton = styled(Button)`
  & {
    width: 125px;
    height: 40px;
  }
`;
const MAX_TIME_IN_SEC = 150;

const CloneVoiceModal = () => {
  const [currentView, setCurrentView] = useState<VIEW_MODE>(VIEW_MODE.main);
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [approveTerms, setApproveTerms] = useState<boolean>(false);
  const [flowType, setFlowType] = useState<CloneVoiceType>(CloneVoiceType.record);
  const intl = useIntl();
  const { notifyMessages } = useNotifications();
  const { notifyError } = useErrors();

  const dispatch = useAppDispatch();
  const { closeModal, editorModalOpen } = useModal();
  const cloneVoiceStatus = useAppSelector((state) => state.voices.cloneVoiceStatus);
  const open = editorModalOpen?.open === ModalName.cloneVoice;

  const { file, reset, onSelectFile, importLoading, executeImport, fileStoreResult } =
    useFileUploadFilestack();

  const { totalTime, playAudio, stopAudio, isPlaying } = useAudioPlayer(file);

  const isRecordValid = totalTime ? totalTime <= MAX_TIME_IN_SEC : true;

  useEffect(() => {
    if (!open) {
      reset();
      setCurrentView(VIEW_MODE.main);
      setName(undefined);
      setName(undefined);
      setDescription(undefined);
      setApproveTerms(false);
      setFlowType(CloneVoiceType.record);
    }
  }, [open]);

  useEffect(() => {
    if (cloneVoiceStatus === fetchingStatus.succeeded) {
      dispatch(voicesActions.setCloneVoiceToIdle());
      notifyMessages([
        {
          message: intl.formatMessage(cloneVoiceMessages.finishMessageNotification)
        }
      ]);
    } else if (cloneVoiceStatus === fetchingStatus.failed) {
      dispatch(voicesActions.setCloneVoiceToIdle());
    }
  }, [cloneVoiceStatus]);

  useEffect(() => {
    if (fileStoreResult) {
      dispatch(voicesActions.cleanFooterAudio());
      dispatch(
        voicesActions.cloneVoiceRequest({
          displayName: name ?? "My voice",
          description,
          url: fileStoreResult.url,
          orderId: uuidv4()
        })
      );
      closeModal();
    }
  }, [fileStoreResult]);

  const onModalCancel = () => {
    closeModal();
  };

  const onChangeName = (evt: any) => {
    setName(evt.target.value);
  };
  const onChangeDescription = (evt: any) => {
    setDescription(evt.target.value);
  };

  const onCancelRecord = () => {
    reset();
    setCurrentView(VIEW_MODE.main);
  };

  const onChangeTerms = (evt: any) => {
    setApproveTerms(evt.target.checked);
  };

  const onRecordFinish = (recordFile: File) => {
    onSelectFile(recordFile);
  };

  const onUploadFile = (selectedFile: File) => {
    if (selectedFile.type !== MimeType.wav) {
      notifyError({ general: buildGeneralError("Only wav files supported", intl) });
    } else {
      dispatch(googleEvents.chooseVoiceFile({ file: selectedFile.name }));
      onSelectFile(selectedFile);
      setFlowType(CloneVoiceType.file);
      setCurrentView(VIEW_MODE.approve);
    }
  };

  const cleanFile = () => {
    reset();
  };

  const onFinishRecord = () => {
    setCurrentView(VIEW_MODE.approve);
  };

  const onDeleteFile = () => {
    reset();
    setCurrentView(VIEW_MODE.main);
  };
  const onStartRecordEvent = () => {
    dispatch(googleEvents.recordVoice({ action: "start" }));
  };

  const onStopRecordEvent = () => {
    dispatch(googleEvents.recordVoice({ action: "stop" }));
  };

  const onFinishApprove = () => {
    dispatch(googleEvents.createCloneVoice({ time: totalTime as number, type: flowType }));
    executeImport();
  };

  return (
    <StyledModal
      open={open}
      footer={null}
      centered
      width="530px"
      bodyStyle={{ height: "626px" }}
      onCancel={onModalCancel}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      closable={!importLoading}
      $padding="54px 46px"
      $justifyContent="space-between"
    >
      <H1_FlexColumn justify="space-between" flex="1" gap="15px">
        <ConditionalRender condition={currentView === VIEW_MODE.main}>
          <CloneVoiceMainView
            name={name}
            loading={importLoading}
            executeImport={executeImport}
            onChangeName={onChangeName}
            maxTime={MAX_TIME_IN_SEC}
            onUploadFile={onUploadFile}
            onCancel={onModalCancel}
            onRecording={() => setCurrentView(VIEW_MODE.voiceRecording)}
          />
        </ConditionalRender>
        <ConditionalRender condition={currentView === VIEW_MODE.voiceRecording}>
          <H1_TextHeadline textAlign="center">
            {intl.formatMessage(cloneVoiceMessages.voiceRecordingTitle)}
          </H1_TextHeadline>
          <AudioRecorder
            onFinish={onRecordFinish}
            maxTime={MAX_TIME_IN_SEC}
            file={file}
            cleanFile={cleanFile}
            description={intl.formatMessage(cloneVoiceMessages.recordButtonDescription, {
              maxTime: MAX_TIME_IN_SEC / 60
            })}
            onStartRecordEvent={onStartRecordEvent}
            onStopRecordEvent={onStopRecordEvent}
          />
          <ButtonsWrapper align="center" gap="15px">
            <CancelButton
              size="large"
              key="cancel"
              disabled={importLoading}
              onClick={onCancelRecord}
            >
              {intl.formatMessage(cloneVoiceMessages.modalButtonCancel)}
            </CancelButton>
            <StyledButton
              type="primary"
              onClick={onFinishRecord}
              disabled={!isRecordValid || !file}
              loading={importLoading}
            >
              {intl.formatMessage(cloneVoiceMessages.finishRecord)}
            </StyledButton>
          </ButtonsWrapper>
        </ConditionalRender>
        <ConditionalRender condition={currentView === VIEW_MODE.voiceUpload}>
          <ButtonsWrapper align="center" gap="15px">
            <CancelButton key="cancel" isDisabled={importLoading} onClick={onCancelRecord}>
              {intl.formatMessage(cloneVoiceMessages.modalButtonCancel)}
            </CancelButton>
            <StyledButton
              color="primary"
              onClick={() => setCurrentView(VIEW_MODE.main)}
              isDisabled={!isRecordValid}
              isLoading={importLoading}
            >
              {intl.formatMessage(cloneVoiceMessages.finishRecord)}
            </StyledButton>
          </ButtonsWrapper>
        </ConditionalRender>
        <ConditionalRender condition={currentView === VIEW_MODE.approve}>
          <ApproveView
            name={name}
            onChangeName={onChangeName}
            file={file}
            playing={isPlaying}
            onPlay={playAudio}
            onStop={stopAudio}
            description={description}
            onChangeDescription={onChangeDescription}
            approveTerms={approveTerms}
            onChangeTerms={onChangeTerms}
            disabled={importLoading}
            onCancel={onModalCancel}
            onFinish={onFinishApprove}
            disabledFinish={!isRecordValid || !approveTerms}
            onDeleteFile={onDeleteFile}
            showValidationError={!isRecordValid}
          />
        </ConditionalRender>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default CloneVoiceModal;
