import { Button, Progress, Row } from "antd";

import styled, { useTheme } from "styled-components";
import { startCase } from "lodash-es";
import { Video, VideoStatusEnum } from "app/types";
import Label from "app/components/common/Label";
import { useIntl } from "react-intl";
import { videoProgressMessages } from "app/components/editor/sideDrawers/RenderPanel/messages";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const ExportButton = styled(Button)`
  color: ${(props) => props.theme.gray11};
  padding: 0;
  font-size: 11px;
  height: auto;
  border: 0;
  margin-left: 10px;
  &:hover {
    background: none;
    color: #2930ff;
  }
  span {
    text-decoration: underline;
  }
`;

const StatusTitle = styled.div`
  color: #95de64;
  font-size: 11px;
  margin-right: 10px;
`;

const StyledProgress = styled(Progress)`
  width: 225px;
  margin-bottom: 1px;
  flex: 1;
`;

interface VideoProgressProps {
  video: Video;
  onExport: (video: Video) => void;
  onRevert: (video: Video) => void;
}

const VideoProgress = ({ video, onExport, onRevert }: VideoProgressProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isReady =
    video.status === VideoStatusEnum.Ready || video.status === VideoStatusEnum.Published;
  const isCanceled = video.status === VideoStatusEnum.Canceled;

  const progressStatus = (() => {
    switch (video.status) {
      case "failed":
        return "exception";
      case "published":
      case "ready":
        return undefined;
      default:
        return "active";
    }
  })();

  return (
    <>
      <Label label={`Version ${video.draft_version}`}>
        <Row justify="space-between" align="middle">
          {isReady && <StatusTitle>{startCase(video.status)}</StatusTitle>}
          <StyledProgress
            showInfo={!isCanceled}
            percent={video.progress ?? 0}
            status={progressStatus}
            strokeColor={isReady ? "#95DE64" : theme.pink4}
          />
          <ConditionalRender condition={isReady}>
            <ExportButton type="text" onClick={() => onExport(video)}>
              {formatMessage(videoProgressMessages.export)}
            </ExportButton>
          </ConditionalRender>
          <ExportButton type="text" onClick={() => onRevert(video)}>
            {formatMessage(videoProgressMessages.restore)}
          </ExportButton>
          <ConditionalRender condition={isCanceled}>
            <H1_TextXs margin="0 0 0 10px">
              {formatMessage(videoProgressMessages.canceled)}
            </H1_TextXs>
          </ConditionalRender>
        </Row>
      </Label>
    </>
  );
};

export default VideoProgress;
