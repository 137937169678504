import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { profileMessages } from "app/pages/profile/messages";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button } from "@nextui-org/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { userActions } from "app/store/slices/user.slice";
import { MFATypes, PlanEnum } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import MfaModal from "app/pages/profile/MfaModal";
import ConditionalRender from "app/components/common/ConditionalRender";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useUpgradeModal, { useShowCrown } from "app/hooks/useUpgradeModal";
import { PlanFeature } from "app/config/planFeature";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { ThemeMode } from "app/utils/theme";

const WhiteFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
  border-radius: 4px;
`;

const SecurityCard = () => {
  const [is2FaModalVisible, setIs2FaModalVisible] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const mfaCrown = useShowCrown({ requestedFeature: PlanFeature.mfa });
  const showPaymentModal = useUpgradeModal();
  const mfaInfo = useAppSelector((state) => state.user.mfaInfo);
  const mfaStatus = useAppSelector((state) => state.user.mfaStatus);
  const authFactors = useAppSelector((state) => state.user.authFactors);
  const mfaStatusLoading = mfaStatus === fetchingStatus.loading;
  const is2FaEnabled = authFactors.length > 0;

  useEffect(() => {
    if (mfaStatus === fetchingStatus.succeeded) {
      dispatch(userActions.setMFAStatusToIdle());
    }
    if (mfaStatus === fetchingStatus.failed) {
      dispatch(userActions.setMFAStatusToIdle());
    }
  }, [mfaStatus]);

  const onClickQrCode = () => {
    setIs2FaModalVisible(true);
  };
  const onChange2FA = () => {
    dispatch(
      analyticsEvents.onClickMfa({
        action: is2FaEnabled ? "disable" : "enable",
        type: MFATypes.totp
      })
    );
    if (!is2FaEnabled) {
      if (
        showPaymentModal({
          source: "profile_multi_factor_auth",
          requestedFeature: PlanFeature.mfa,
          targetPlan: PlanEnum.enterprise,
          upgradeText: formatMessage(profileMessages.security2FAUpgrade)
        })
      ) {
        return;
      }
      dispatch(userActions.mFAEnrollRequest({ type: MFATypes.totp }));
      setIs2FaModalVisible(true);
    } else {
      const totpFactor = authFactors.find((authFactor) => authFactor.type === MFATypes.totp);
      if (totpFactor) {
        dispatch(userActions.deleteMFARequest({ type: MFATypes.totp }));
      }
    }
  };

  const onCloseMfaModal = () => {
    setIs2FaModalVisible(false);
  };

  return (
    <WhiteFlexColumn padding="20px 30px 30px" width="100%" flex="0 0 auto">
      <MfaModal visible={is2FaModalVisible} onClose={onCloseMfaModal} />
      <H1_TextMiddle margin="0 0 3px 0" color={theme.gray8}>
        {formatMessage(profileMessages.securityTitle)}
      </H1_TextMiddle>
      <Divider />
      <H1_FlexRow justify="space-between" padding="3px 0" align="center">
        <H1_FlexColumn gap="11px">
          <H1_TextSmall>{formatMessage(profileMessages.security2FA)}</H1_TextSmall>
          <H1_TextXs>{formatMessage(profileMessages.security2FAContent)}</H1_TextXs>
        </H1_FlexColumn>
        <H1_FlexRow gap="11px" align="center">
          <ConditionalRender condition={!!mfaInfo && !mfaStatusLoading}>
            <Button
              variant="light"
              onClick={onClickQrCode}
              isIconOnly
              startContent={<i className="far fa-qrcode" />}
            />
          </ConditionalRender>
          <Button
            variant="light"
            onClick={onChange2FA}
            isLoading={mfaStatusLoading}
            startContent={
              mfaCrown && !is2FaEnabled ? (
                <H1_Icon icon="fas fa-crown" color={theme.orange4} isCursorPointer />
              ) : (
                ""
              )
            }
          >
            <H1_TextSmall>
              {formatMessage(is2FaEnabled ? profileMessages.disable : profileMessages.enable)}
            </H1_TextSmall>
          </Button>
        </H1_FlexRow>
      </H1_FlexRow>
    </WhiteFlexColumn>
  );
};

export default SecurityCard;
