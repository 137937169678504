import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { importDocumentsModalMessages } from "app/components/editor/documents/messages";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Typography } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_Textarea } from "app/components/_Infrastructure/design-system/textarea";
import { Button } from "@nextui-org/react";

const NameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.gray11};
  font-size: 14px;
  line-height: 22px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  && {
    max-width: ${({ $maxWidth }: { $maxWidth: string }) => $maxWidth};
  }
`;

const BorderFlexRow = styled(H1_FlexRow)`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.gray6};
  max-width: 200px;
`;
const FlexImage = styled.img`
  flex-shrink: 0;
`;
const AbsoluteButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const ChangeButton = styled(Button)`
  text-decoration: underline;
`;
const StyledTextArea = styled(H1_Textarea)`
  && {
    display: block;
    height: 100%;
    line-height: 22px;
    margin: 0;
    padding: 20px;
    width: 100%;
    border: none;
    overflow: auto;
    border-radius: 5px;
    background: ${({ theme }) => theme.gray2};
    color: ${({ theme }) => theme.gray8};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) inset;
  }
  &&:focus {
    box-shadow: none;
  }
`;

const MAX_LENGTH = 3250;
export interface PreviewPresentationContentProps {
  reset: () => void;
  onFinish: (data: string) => void;
  textData: string;
  fileName?: string;
  editable?: boolean;
}

const PreviewDocumentContent = ({
  reset,
  onFinish,
  textData,
  fileName,
  editable = false
}: PreviewPresentationContentProps) => {
  const [data, setData] = useState(textData);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const intl = useIntl();
  const theme = useTheme();

  const textLengthValidationError = data?.length > MAX_LENGTH;

  useEffect(() => {
    setData(textData);
  }, [textData]);

  const iconImage = () => {
    if (fileName?.match("docx") !== null) {
      return "https://df6g5g0b3bt51.cloudfront.net/reals-static-files/image+22.webp";
    }
    if (fileName?.match("pdf") !== null) {
      return "https://df6g5g0b3bt51.cloudfront.net/reals-static-files/pdf_icon.webp";
    }
  };

  const onChangeText = (value: string) => {
    if (!editable) {
      return;
    }
    setData(value);
  };

  const onPasteFromClipboard = async () => {
    try {
      const textToPaste = await navigator.clipboard.readText();
      const currentTextArea = textareaRef.current;
      if (currentTextArea) {
        const startPos = currentTextArea.selectionStart;
        const endPos = currentTextArea.selectionEnd;
        const textBefore = data ? data.substring(0, startPos) : "";
        const textAfter = data ? data.substring(endPos, data.length) : "";
        setData(textBefore + textToPaste + textAfter);
        // Move cursor after the pasted text
        const newPos = startPos + textToPaste.length;
        currentTextArea.selectionStart = newPos;
        currentTextArea.selectionEnd = newPos;
      }
    } catch (err) {
      console.error("Failed to paste text: ", err);
    }
  };

  return (
    <>
      <H1_FlexRow position="relative" height="100%" width="100%">
        <StyledTextArea ref={textareaRef} value={data} onChange={onChangeText} />
        <ConditionalRender condition={!data?.length && editable}>
          <AbsoluteButton
            onClick={onPasteFromClipboard}
            startContent={<i className="far fa-copy" />}
          >
            {intl.formatMessage(importDocumentsModalMessages.Paste)}
          </AbsoluteButton>
        </ConditionalRender>
      </H1_FlexRow>
      <H1_FlexRow justify={"flex-end"} width={"100%"}>
        <H1_TextSmall color={theme.gray6}>
          {data?.length || 0} / {MAX_LENGTH}
        </H1_TextSmall>
      </H1_FlexRow>
      <ConditionalRender condition={textLengthValidationError}>
        <H1_FlexRow width={"100%"}>
          <H1_TextSmall color="red">
            {intl.formatMessage(importDocumentsModalMessages.validationTextLengthError, {
              MAX_LENGTH: MAX_LENGTH
            })}
          </H1_TextSmall>
        </H1_FlexRow>
      </ConditionalRender>
      <H1_FlexRow
        padding="5px 0 0 0"
        width="100%"
        gap="20px"
        justify={fileName ? "space-between" : "flex-end"}
        align="center"
      >
        <ConditionalRender condition={!!fileName}>
          <H1_FlexRow gap="14px" align="center">
            <BorderFlexRow height="44px" gap="8px" padding="0 24px" align="center">
              <FlexImage src={iconImage()} height="18px" width="18px" />
              <NameText $maxWidth="124px" ellipsis={{ tooltip: fileName }}>
                {fileName}
              </NameText>
            </BorderFlexRow>
            <ChangeButton variant="light" color="primary" onClick={reset}>
              {intl.formatMessage(importDocumentsModalMessages.ChangeButton)}
            </ChangeButton>
          </H1_FlexRow>
        </ConditionalRender>
        <Button
          isDisabled={!data || textLengthValidationError}
          onClick={() => onFinish(data)}
          color="primary"
        >
          {intl.formatMessage(importDocumentsModalMessages.CreateVideoButton)}
        </Button>
      </H1_FlexRow>
    </>
  );
};

export default PreviewDocumentContent;
