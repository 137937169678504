import { defineMessages } from "react-intl";

export const publishVideoModalMessages = defineMessages({
  copyLinkTitle: {
    id: `publish-video-modal.copy-link-title`,
    defaultMessage: `Copy Link`
  },
  copyLinkButton: {
    id: `publish-video-modal.copy-link-button`,
    defaultMessage: `Copy Link`
  },
  protectButton: {
    id: `publish-video-modal.protect-link-button`,
    defaultMessage: `Protect`
  },
  overrideProtectionButton: {
    id: `publish-video-modal.overide-protection-button`,
    defaultMessage: `Override password`
  },
  passwordGeneralRestriction: {
    id: `publish-video-modal.password-protection.validation.explained`,
    defaultMessage: `Your password must be between 3 and 8 length`
  },
  emptyPasswordGeneral: {
    id: `publish-video-modal.password-protection.validation.empty`,
    defaultMessage: `Password must have a value`
  },
  videoAvailability: {
    id: `publish-video-modal.video-availability`,
    defaultMessage: `Make video available`
  }
});
