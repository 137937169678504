import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { ReactNode } from "react";

const StatusTagContainer = styled(H1_FlexColumn)`
  //display: flex;
  //width: 117px;
  justify-content: center;
  align-items: center;
  border-radius: 42px;
  background: var(--Green-Green-1, #e3fce6);
`;
export interface StatusTagProps {
  children: ReactNode;
}
const StatusTag = ({ children }: StatusTagProps) => {
  return (
    <StatusTagContainer padding={"1px 8px 2px 8px"} width={"117px"}>
      {children}
    </StatusTagContainer>
  );
};

export default StatusTag;
