import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMidHeadline, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { paymentModalV2CreditCardMessages } from "app/pages/pricing/PaymentModalV2/messages";
import React from "react";
import { useIntl } from "react-intl";
import styled, { useTheme } from "styled-components";
import { ReactComponent as HPELogo } from "app/assets/pricing/hpe.svg";
import { ReactComponent as TMobileLogo } from "app/assets/pricing/tMobile.svg";
import { ReactComponent as AstraZenecaLogo } from "app/assets/pricing/astrazeneca.svg";
import RecommendedImage from "app/assets/pricing/guy.png";

const RightSideFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray3};
`;

const QuotesTextMiddle = styled.blockquote`
  color: ${({ theme }) => theme.gray8};
  font-size: 16px;
  line-height: 24px;
  quotes: "“" "”" "‘" "’";
  &::before {
    color: ${({ theme }) => theme.blue4};
    content: open-quote;
    font-size: 20px;
    margin-top: -5px;
  }
  &::after {
    color: ${({ theme }) => theme.blue4};
    content: close-quote;
    font-size: 20px;
  }
`;

const Image = styled.img`
  border-radius: 50%;
`;

const PaymentModalV2G2Recommendation = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <RightSideFlexColumn width="444px" padding="102px 53px 210px" justify="space-between">
      <H1_FlexColumn gap="5px">
        <H1_FlexColumn padding="0 0 8px 0" width="62px" height="62px" flex="0 0 auto">
          <Image src={RecommendedImage} alt="" width={62} height={62} />
        </H1_FlexColumn>
        <H1_TextMidHeadline fontWeight={600} color={theme.gray10} whiteSpace="break-spaces">
          Adrian M.
        </H1_TextMidHeadline>
        <H1_FlexRow align="center" padding="0 0 5px 0">
          <H1_TextXs color={theme.gray10} whiteSpace="break-spaces">
            {formatMessage(paymentModalV2CreditCardMessages.g2Subtitle)}
          </H1_TextXs>
        </H1_FlexRow>
        <H1_FlexRow align="center">
          <QuotesTextMiddle>
            {formatMessage(paymentModalV2CreditCardMessages.g2Content)}
          </QuotesTextMiddle>
        </H1_FlexRow>
      </H1_FlexColumn>
      <H1_FlexColumn gap="20px" width="100%">
        <H1_TextXs color={theme.gray8} whiteSpace="break-spaces">
          {formatMessage(paymentModalV2CreditCardMessages.trustedBy)}
        </H1_TextXs>
        <H1_FlexRow justify="space-between" align="center" padding="10px 0 0 0">
          <HPELogo />
          <TMobileLogo />
          <AstraZenecaLogo />
        </H1_FlexRow>
      </H1_FlexColumn>
    </RightSideFlexColumn>
  );
};

export default PaymentModalV2G2Recommendation;
