import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppSelector } from "app/hooks";
import styled, { css } from "styled-components";
import { ThemeMode } from "app/utils/theme";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import React from "react";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import ConditionalRender from "app/components/common/ConditionalRender";
import { Chip } from "@nextui-org/react";

const StyledIconBackground = styled(H1_FlexRow)<{ $isVisible?: boolean }>`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray5};
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &&& {
    ${({ $isVisible }) =>
      !!$isVisible &&
      css`
        opacity: 1;
      `};
  }
`;

const StyledNameText = styled(NameText)`
  padding: 0 0 0 20px;
  color: ${({ theme }) => theme.gray8};
`;

const EntityBorderFlexColumn = styled(H1_FlexColumn)<{ $selected: boolean }>`
  border-radius: 10px;
  border: 1px solid
    ${({ theme, $selected }) =>
      $selected ? (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue2) : theme.gray3};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray3)};

  ${StyledIconBackground} {
    opacity: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.gray2};
    ${StyledIconBackground} {
      opacity: 1;
    }
  }
`;

const CharacterContainerFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
`;

const StyledChip = styled(Chip)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.blue3)};
`;

const CharacterImg = styled.img`
  border-radius: 10px;
  width: 155px;
  height: 141px;
  object-fit: cover;
`;

export interface PodcastCharacterElementProps {
  id: string;
  isParticipantHost: boolean;
  isParticipant: boolean;
  onSelectAvatar: () => void;
}

const PodcastCharacterElement = ({
  id,
  isParticipantHost,
  isParticipant,
  onSelectAvatar
}: PodcastCharacterElementProps) => {
  const selectedCharacter = useAppSelector((state) =>
    charactersGlobalSelectors.selectById(state, id)
  );

  return (
    <H1_FlexColumn width="155px" gap="14px">
      <EntityBorderFlexColumn
        data-auto-id="avatar-drawer-selected-avatar"
        flex="0 0 auto"
        padding="0"
        gap="10px"
        align="center"
        onClick={onSelectAvatar}
        $selected={isParticipant || isParticipantHost}
        position="relative"
      >
        <CharacterContainerFlexRow overflow="hidden" width="155px" height="141px" flex="0 0 155px">
          <CharacterImg
            src={(selectedCharacter?.thumbnails?.panel.image as string) || selectedCharacter?.image}
          />
        </CharacterContainerFlexRow>
        <ConditionalRender condition={isParticipantHost}>
          <StyledChip size="sm">Host</StyledChip>
        </ConditionalRender>
      </EntityBorderFlexColumn>
      <StyledNameText $maxWidth="155px" ellipsis={{ tooltip: selectedCharacter?.title }}>
        {selectedCharacter?.title}
      </StyledNameText>
    </H1_FlexColumn>
  );
};

export default PodcastCharacterElement;
