import React, { ReactNode } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Steps } from "antd";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { StyledModal } from "app/components/common/StyledModal";

const StyledSteps = styled(Steps)<{ $width: string }>`
  width: ${({ $width }) => $width};
  && .ant-steps-item-icon {
    width: 18px;
    height: 18px;
    font-size: 12px;
    padding-top: 2px;
    border-color: ${({ theme }) => theme.gray6};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.gray6};
    .ant-steps-icon {
      color: ${({ theme }) => theme.gray1};
      line-height: 12px;
    }
  }
  && .ant-steps-item-active .ant-steps-item-icon {
    border-color: ${({ theme }) => theme.blue4};
    background-color: ${({ theme }) => theme.blue4};
  }
  &&& .ant-steps-item-title {
    color: ${({ theme }) => theme.gray11};
    font-weight: 400;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
    font-size: 12px;
    line-height: 18px;
  }
  &&& .ant-steps-item-title::after {
    top: 9px;
    width: 35px;
    background-color: ${({ theme }) => theme.gray6};
  }
`;
const Modal = styled(StyledModal)`
  .ant-modal-body {
    justify-content: space-between;
    & > div {
      flex: 1;
      width: 50%;
    }
    @media (max-width: ${SMALL_SCREEN_PX}) {
      padding: 20px;
    }
  }
`;
interface StartFromModalProps {
  open: boolean;
  titles: ReactNode;
  footer?: ReactNode;
  buttonSelected?: string;
  startFromSteps: Record<string, any>[];
  current: number;
  onClose: () => void;
  displaySteps: boolean;
}

export const StartFromTemplate = ({
  open,
  titles,
  children,
  startFromSteps,
  current,
  onClose,
  displaySteps
}: React.PropsWithChildren<StartFromModalProps>) => {
  const theme = useTheme();
  const items = startFromSteps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  const widthOfSteps = startFromSteps.length === 2 ? `260px` : "100%";

  return (
    <Modal
      width="879px"
      open={open}
      footer={null}
      centered
      bodyStyle={{ height: "631px" }}
      maskClosable={false}
      onCancel={onClose}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $gap="25px"
      $padding="0"
      $overflow="hidden"
      mask
    >
      <H1_FlexRow flex="0 0 auto" height="631px">
        <H1_FlexColumn
          justify="space-between"
          align="center"
          flex="0 0 518px"
          padding="43px 41px 63px 42px"
          width="518px"
        >
          <ConditionalRender condition={displaySteps}>
            <H1_FlexColumn flex="0 0 auto" gap="20px" width="100%" padding="0 0 25px 0">
              <H1_FlexRow height="35px" width="100%" gap="10px" align="center">
                <H1_Icon icon="fas fa-stars" color={theme.blue4} size="19px" />
                {titles}
              </H1_FlexRow>
              <StyledSteps $width={widthOfSteps} current={current} items={items} />
            </H1_FlexColumn>
          </ConditionalRender>
          {children}
        </H1_FlexColumn>
        <H1_FlexRow flex="0 0 361px">
          <img
            height="100%"
            width="100%"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/document.webp"
            alt="workflow1"
          />
        </H1_FlexRow>
      </H1_FlexRow>
    </Modal>
  );
};
export default StartFromTemplate;
