import { defineMessages } from "react-intl";

export const mediaLibraryMessages = defineMessages({
  sdTab: {
    id: `media-library-modal-sd.sdTab.title`,
    defaultMessage: "Generate Image"
  },
  sdSearchPlaceholder: {
    id: `media-library-modal-sd.sdTab.input.placeholder`,
    defaultMessage: "Generate original imagery by entering text"
  },
  sdLastCreatedHeader: {
    id: `media-library-modal-sd.sdTab.created.header`,
    defaultMessage: "Last created"
  },
  helperGenerateSD: {
    id: `media-library-modal-sd.sdTab.input.helper`,
    defaultMessage:
      "Describe what you'd like to see. E.g.: Man in sunglasses drinking juice by the beach"
  },
  sdAddPromptTooltip: {
    id: `media-library-modal-sd.sdTab.prompt.add.tooltip`,
    defaultMessage: "Add prompt section"
  },
  sdActionButton: {
    id: `media-library-modal-sd.sdTab.button.action`,
    defaultMessage: "Generate"
  },
  sdResponseTOMessage: {
    id: `media-library-modal-sd.sdTab.response.to.message`,
    defaultMessage: "Please try again"
  },
  sdResponseFailedMessage: {
    id: `media-library-modal-sd.sdTab.response.failure`,
    defaultMessage: "Failed creating media"
  }
});
