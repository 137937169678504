import React, { useEffect, useMemo, useState } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import { useIntl } from "react-intl";
import { H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import { importDocumentsModalMessages } from "app/components/editor/documents/messages";
import StartFromTemplate from "app/components/editor/documents/StartFromTemplate";

import { useAppDispatch, useAppSelector } from "app/hooks";
import ConditionalRender from "app/components/common/ConditionalRender";
import PreviewDocumentContent from "app/components/editor/documents/PreviewDocumentContent";

import { fetchingStatus } from "app/utils/helpers";
import { dataStoreActions } from "app/store/slices/dataStore.slice";
import DocumentLoadingCreationModal from "app/components/editor/documents/DocumentLoadingCreationModal";
import dataStoreThunk from "app/store/thunks/dataStore.thunk";
import { v4 as uuidv4 } from "uuid";
import { draftsActions } from "app/store/slices/drafts.slice";
import { DataType, ImportDataStoreRequest, draftGeneratorRequest, Location } from "app/types";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";

const StartFromScriptModal = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [openDocumentCreationLoadingModal, setOpenDocumentCreationLoadingModal] = useState(false);

  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { editorModalOpen, closeModal } = useModal();

  const visible = editorModalOpen?.open === ModalName.startFromScriptModal;
  const recipeId = editorModalOpen?.context?.recipeId;
  const createDraftStatus = useAppSelector((state) => state.drafts.createDraftStatus);
  const createDraftFromImportLoading = createDraftStatus === fetchingStatus.loading;
  const importDataStatus = useAppSelector((state) => state.dataStore.importDataStatus);
  const importDataResult = useAppSelector((state) => state.dataStore.importDataResult);
  const dataStoreId = importDataResult?.id;
  const textData = importDataResult?.data;
  const draftGeneratorOrderId = useAppSelector((state) => state.drafts.createDraftGeneratorOrderId);
  const createDraftGeneratorStatus = useAppSelector(
    (state) => state.drafts.createDraftGeneratorStatus
  );
  const draftGeneratorId = useAppSelector((state) => state.drafts.draftGeneratorId);

  useEffect(() => {
    if (visible && createDraftGeneratorStatus === fetchingStatus.succeeded) {
      const editorDraftLink = `${Location.Editor}/${draftGeneratorId}`;
      dispatch(analyticsEvents.StartFromScript({ status: "success" }));
      dispatch(analyticsEvents.navigateToEditor({ source: "import_data" }));
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
      navigate(editorDraftLink);
      setOpenDocumentCreationLoadingModal(false);
      closeModal();
    }
    if (visible && createDraftGeneratorStatus === fetchingStatus.failed) {
      dispatch(analyticsEvents.StartFromScript({ status: "fail" }));
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
      setOpenDocumentCreationLoadingModal(false);
      closeModal();
    }
  }, [createDraftGeneratorStatus]);
  useEffect(() => {
    if (!visible) {
      return;
    }
    if (importDataStatus === fetchingStatus.succeeded && dataStoreId) {
      dispatch(dataStoreActions.updateImportDataToIdle());
      const draftGeneratorRequest: draftGeneratorRequest = {
        title: "Video from my script",
        order_id: uuidv4().toString(),
        data_store_id: dataStoreId,
        recipe_id: recipeId
      };
      dispatch(draftsActions.createDraftGenerator(draftGeneratorRequest));
    }
    if (importDataStatus === fetchingStatus.failed) {
      dispatch(dataStoreActions.updateImportDataToIdle());
    }
  }, [importDataStatus, dataStoreId]);

  useEffect(() => {
    if (!visible) {
      resetModal();
    }
  }, [visible]);

  const resetModal = () => {
    setCurrentStep(0);
    dispatch(dataStoreActions.cleanDataStore());
    dispatch(dataStoreActions.updateImportDataToIdle());
  };

  const stepOneSucceeded = (data: string) => {
    const importRequest: ImportDataStoreRequest = {
      type: DataType.text,
      text: data
    };
    dispatch(dataStoreThunk.importDataRequest(importRequest));
    setOpenDocumentCreationLoadingModal(true);
  };

  const startFromScriptStepsTitles = useMemo(
    () => [
      {
        title: intl.formatMessage(importDocumentsModalMessages.FirstStepScript)
      }
    ],
    []
  );
  if (visible && openDocumentCreationLoadingModal) {
    return <DocumentLoadingCreationModal trayKey={draftGeneratorOrderId} startFromType="script" />;
  }

  return (
    <StartFromTemplate
      open={visible}
      current={currentStep}
      onClose={closeModal}
      displaySteps={!createDraftFromImportLoading}
      titles={
        <H1_TextMidHeadline color={theme.gray11} whiteSpace="normal">
          {intl.formatMessage(importDocumentsModalMessages.ScriptHeadlineTitle)}
        </H1_TextMidHeadline>
      }
      startFromSteps={startFromScriptStepsTitles}
    >
      <ConditionalRender condition={!createDraftFromImportLoading}>
        <ConditionalRender condition={currentStep === 0}>
          <PreviewDocumentContent
            reset={resetModal}
            onFinish={stepOneSucceeded}
            textData={textData as string}
            editable
          />
        </ConditionalRender>
      </ConditionalRender>
    </StartFromTemplate>
  );
};
export default StartFromScriptModal;
