import { PropsWithChildren, useState } from "react";
import CharacterSettingsContext from "app/pages/createDigitalTwinPage/CharacterSettingsContext";
import { Gender } from "app/types";

const CharacterSettingsContextWrapper = ({ children }: PropsWithChildren) => {
  const [gender, setGender] = useState<Gender>(Gender.all);
  const [addPong, setAddPong] = useState<boolean>(true);
  const [addTransparent, setAddTransparent] = useState<boolean>(true);
  const [name, setName] = useState<string>("My avatar");

  return (
    <CharacterSettingsContext.Provider
      value={{
        gender,
        setGender,
        addPong,
        setAddPong,
        addTransparent,
        setAddTransparent,
        name,
        setName
      }}
    >
      {children}
    </CharacterSettingsContext.Provider>
  );
};

export default CharacterSettingsContextWrapper;
