import React, { ReactNode } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface SortableContainerProps {
  children: ReactNode;
  elementId: string;
}

const SortableContainer = ({ children, elementId }: SortableContainerProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: elementId
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export default SortableContainer;
