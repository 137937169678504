import { defineMessages } from "react-intl";

export const augmentedSelectionMessages = defineMessages({
  magicButton: {
    id: "augmented-selection.magic-button",
    defaultMessage: "Script Wizard"
  },
  makeItShorter: {
    id: "augmented-selection.make-it-shorter",
    defaultMessage: "Make shorter"
  },
  scriptWizard: {
    id: "augmented-selection.script-wizard",
    defaultMessage: "Script Wizard"
  },
  makeItLonger: {
    id: "augmented-selection.make-it-longer",
    defaultMessage: "Make longer"
  },
  makeItOneSentence: {
    id: "augmented-selection.make-it-one-sentence",
    defaultMessage: "Turn into a single sentence"
  },
  makeItHappier: {
    id: "augmented-selection.make-it-happier",
    defaultMessage: "Happier"
  },
  makeItFormal: {
    id: "augmented-selection.make-it-formal",
    defaultMessage: "Formal"
  },
  makeItProfessional: {
    id: "augmented-selection.make-it-professional",
    defaultMessage: "Professional"
  },
  makeItYouthful: {
    id: "augmented-selection.make-it-youthful",
    defaultMessage: "Youthful"
  },
  makeItMoreFun: {
    id: "augmented-selection.make-it-more-fun",
    defaultMessage: "Fun"
  },
  rephrase: {
    id: "augmented-selection.rephrase",
    defaultMessage: "Rephrase"
  },
  changeTone: {
    id: "augmented-selection.change-tone",
    defaultMessage: "Change tone"
  },
  templates: {
    id: "augmented-selection.templates",
    defaultMessage: "AI templates"
  },
  sales: {
    id: "augmented-selection.sales",
    defaultMessage: "Sales pitch"
  },
  product: {
    id: "augmented-selection.product",
    defaultMessage: "Product tutorial"
  },
  runAsPrompt: {
    id: "augmented-selection.run-as-prompt",
    defaultMessage: "AI Magic"
  },
  rephraseEntirely: {
    id: "augmented-selection.rephrase-entirely",
    defaultMessage: "Rephrase entirely"
  },
  rephraseAs1stPerson: {
    id: "augmented-selection.rephrase-as-1st-person",
    defaultMessage: "As 1st person"
  },
  productTutorial: {
    id: "augmented-selection.product-tutorial",
    defaultMessage: "Product Tutorial"
  },
  salesPitch: {
    id: "augmented-selection.sales-pitch",
    defaultMessage: "Sales Pitch"
  },
  createNewText: {
    id: "augmented-selection.create-new-text",
    defaultMessage: "Create entirely new text"
  },
  updateText: {
    id: "augmented-selection.update-text",
    defaultMessage: "Update current text"
  }
});

export const aiGenerateMessages = defineMessages({
  creatingScript: {
    id: "ai-generate.creating-script",
    defaultMessage: "Creating script..."
  },
  autoScenes: {
    id: "ai-generate.auto-scenes",
    defaultMessage: "Auto"
  },
  singleScene: {
    id: "ai-generate.single-scene",
    defaultMessage: "1 scene"
  },
  numberOfScenes: {
    id: "ai-generate.number-of-scenes",
    defaultMessage: "{number} scenes"
  },
  regenerate: {
    id: "ai-generate.regenerate",
    defaultMessage: "Regenerate"
  },
  undo: {
    id: "ai-generate.undo",
    defaultMessage: "Revert"
  },
  magic: {
    id: "ai-generate.magic",
    defaultMessage: "Magic"
  },
  promptPrefix: {
    id: "ai-generate.prompt-prefix",
    defaultMessage: "Write a script about"
  },
  send: {
    id: "ai-generate.send",
    defaultMessage: "Send"
  },
  keep: {
    id: "ai-generate.keep",
    defaultMessage: "Keep"
  },
  videoWizard: {
    id: "ai-generate.video-wizard",
    defaultMessage: "Video Wizard"
  },
  videoWizardTimeoutErrorHead: {
    id: "ai-generate.video-wizard.timeout-error-head",
    defaultMessage: "Oops, Script Generation Taking Longer Than Expected!"
  },
  videoWizardTimeoutError: {
    id: "ai-generate.video-wizard.timeout-error",
    defaultMessage: "Don't worry, our video wizard is working hard. Please give it another shot!"
  }
});
