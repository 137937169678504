import { defineMessages } from "react-intl";

export const presentationModalMessages = defineMessages({
  modalHeader: {
    id: `presentations.modal.modal-header`,
    defaultMessage: "Upload Presentation"
  },
  modalDescription: {
    id: `presentations.modal.modal-description`,
    defaultMessage: "Each slide will become a scene in the video ({num} slides max)."
  },
  modalButtonImport: {
    id: `presentations.modal.import-button`,
    defaultMessage: "Create video"
  },
  modalButtonCancel: {
    id: `presentations.modal.cancel-button`,
    defaultMessage: "Cancel"
  },

  modalLoaderTitle: {
    id: `presentations.modal.modal-loader-title`,
    defaultMessage: "Importing presentation"
  },
  modalLoaderDescription: {
    id: `presentations.modal.modal-loader-description`,
    defaultMessage: "Sit tight! Large presentations may take longer to upload"
  }
});

export const presentationModalUploaderMessages = defineMessages({
  mainDescription: {
    id: `presentations.modal-uploader.main-description`,
    defaultMessage: "Upload presentation or Drag and drop"
  },
  description: {
    id: `presentations.modal-uploader.description`,
    defaultMessage: ".ppt / .pptx formats are supported"
  },
  browseButton: {
    id: `presentations.modal-uploader.browse-button`,
    defaultMessage: "Choose file"
  },
  changeFileButton: {
    id: `presentations.modal-uploader.change-file-button`,
    defaultMessage: "Change file"
  }
});

export const limitSlidesWarningMessages = defineMessages({
  mainDescription: {
    id: `presentations.warning-limit-slides.main-description`,
    defaultMessage: "This presentation exceeds the maximum of { num } slides"
  },
  subDescription: {
    id: `presentations.warning-limit-slides.sub-description`,
    defaultMessage:
      "This presentation exceeds a maximum of {maxLimit} slides. The first {maxLimit} slides of the presentation will be used. To use more than {maxLimit} slides, upgrade your account."
  },
  continueButton: {
    id: `presentations.warning-limit-slides.continue-button`,
    defaultMessage: "Continue"
  },
  modalButtonUpgrade: {
    id: `presentations.warning-limit-slides.upgrade-button`,
    defaultMessage: "Upgrade"
  },
  backButton: {
    id: `presentations.warning-limit-slides.back-button`,
    defaultMessage: "Back"
  }
});

export const presentationFileTypeErrors = defineMessages({
  presentationTypeErrorHeadline: {
    id: `presentations.modal-uploader.error.headline`,
    defaultMessage: "Whoops... this is the wrong file format"
  },
  presentationTypeErrorDescription: {
    id: `presentations.modal-uploader.error.description`,
    defaultMessage: "We're sorry but we currently only support { acceptableTypes }"
  }
});
