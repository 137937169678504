import React, { useEffect, useState } from "react";
import { ReactComponent as HourOneSvg } from "app/assets/realsme/hourone-large.svg";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { Location } from "app/types";
import { useNavigate } from "react-router-dom";
import RoundButton from "app/components/common/RoundButton";
import { SourceName } from "app/types/realsMe";
import { channelsMessages } from "app/pages/Channels/messages";
import { Tooltip } from "antd";
import { useIntl } from "react-intl";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import styled from "styled-components";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";

const StyledCircleLoader = styled(CircleLoader)`
  width: 32px;
`;
interface ChannelsPageForkButtonProps {
  draftId: string;
}

const ChannelsPageForkButton = ({ draftId }: ChannelsPageForkButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const duplicationStatus = useAppSelector((state) => state.drafts.duplicationStatus);
  const duplicatedDraftResult = useAppSelector((state) => state.drafts.duplicatedDraftResult);

  const setToInitialState = () => {
    setIsLoading(false);
    dispatch(draftsActions.updateDuplicateDraftStatusToIdle());
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    if (duplicationStatus === fetchingStatus.succeeded && duplicatedDraftResult) {
      setToInitialState();
      dispatch(analyticsEvents.navigateToEditor({ source: "channels_page_fork" }));
      navigate(`${Location.Editor}/${duplicatedDraftResult?.id}`);
    } else if (duplicationStatus === fetchingStatus.failed) {
      setToInitialState();
    }
  }, [duplicationStatus, isLoading, duplicatedDraftResult]);

  const duplicateDraft = () => {
    setIsLoading(true);
    dispatch(draftsActions.duplicateDraftsRequest({ draftId, source: SourceName.video }));
  };

  return (
    <Tooltip title={formatMessage(channelsMessages.forkButtonTooltip)}>
      <RoundButton
        onClick={() => duplicateDraft()}
        icon={isLoading ? <StyledCircleLoader size="32px" /> : <HourOneSvg />}
      />
    </Tooltip>
  );
};

export default ChannelsPageForkButton;
