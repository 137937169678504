import React, { useState } from "react";

import styled, { css } from "styled-components";
import { Button } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Draft, EditorView, PartialScene } from "app/types";
import { PlusCircleFilled } from "@ant-design/icons";
import { scenesActions } from "app/store/slices/scenes.slice";
import { fetchingStatus } from "app/utils/helpers";
import { useIntl } from "react-intl";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SceneActionOrigin } from "app/store/thunks/analyticsEvents.thunk";
import useSelectedScene from "app/components/editor/scene/useSelectedScene";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import SceneItem from "app/pages/editor/SceneItem";
import ConditionalRender from "app/components/common/ConditionalRender";
import SortableContainer from "app/pages/editor/SortableContainer";
import { paymentModalMessages } from "app/pages/pricing/messages";
import useModal, { ModalName } from "app/hooks/useModal";
import { getLimits } from "app/store/selectorsV2/workspaces.selectors";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { scenesGlobalSelectors } from "app/store/adapters/adapters";

const ContainerFlexColumn = styled(H1_FlexColumn)`
  padding: 12px;
`;

const AddSceneFlexColumn = styled(H1_FlexColumn)`
  padding: 5px;
`;

const StyledDragOverlay = styled(DragOverlay)`
  ${({ isDragging }: { isDragging: boolean }) =>
    isDragging &&
    css`
      && {
        box-shadow: rgba(0, 0, 0, 0.2) 0 7px 14px 0;
        opacity: 0.8;
        border-radius: 8px;
        background-color: white;
      }
    `};
`;

const StyledPlusCircleFilled = styled(PlusCircleFilled)`
  svg {
    fill: #4285f4;
    height: 20px;
    width: 20px;
  }
`;

const AddSceneButton = styled(Button)`
  width: 111px;
  height: 64px;
  min-height: 64px;
  background-color: ${(props) => props.theme.gray3};
  &:hover {
    background-color: ${(props) => props.theme.gray4};
  }
  &:active {
    background-color: ${(props) => props.theme.gray5};
  }
`;

const LeftNavScenesList = () => {
  const [draggingSceneId, setDraggingSceneId] = useState(undefined);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const intl = useIntl();
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const { selectedSceneIndex, sceneId, scene } = useSelectedScene();
  const dispatch = useAppDispatch();

  const draft: Draft = useAppSelector((state) => state.drafts.currentDraft);
  const limits = useAppSelector(getLimits);
  const scenes = useAppSelector(scenesGlobalSelectors.selectAll);
  const scenesStatus = useAppSelector((state) => state.scenes.scenesStatus);
  const createStatus = useAppSelector((state) => state.scenes.createSceneStatus);

  const createLoading = createStatus === fetchingStatus.loading;
  const isLoading = scenesStatus === fetchingStatus.loading;
  const maxScenesLimit: number = limits.scenes as number;
  const disableAddScene = maxScenesLimit <= scenes.length;
  const sceneName = scene?.name as string;

  const onAddScene = () => {
    if (disableAddScene) {
      const upgradeText = intl.formatMessage(paymentModalMessages.upgradeSceneTitle);
      const source = "addScene";
      dispatch(analyticsEvents.productUpgradeCta({ cta: source }));
      openModal(ModalName.paymentModalV2, { source, upgradeText });
    } else {
      dispatch(
        analyticsEvents.addScene({
          selectedScene: {
            name: sceneName,
            id: sceneId,
            index: selectedSceneIndex
          },
          action: SceneActionOrigin.NavigationBar
        })
      );
      const newScene: PartialScene = {
        name: "new scene",
        attributes: {
          text: {
            transcript: {
              text: ""
            }
          }
        }
      };
      dispatch(
        scenesActions.addDraftSceneRequest({ draftId: draft.id as string, scene: newScene })
      );
      openDrawer(Drawer.Scene);
    }
  };

  const onClickImage = (selectedSceneId: string) => {
    dispatch(
      analyticsEvents.selectScene({
        selectedScene: {
          name: sceneName,
          index: selectedSceneIndex + 1,
          id: selectedSceneId
        },
        view: EditorView.Single
      })
    );
    dispatch(scenesActions.setSelectedSceneId(selectedSceneId));
    openDrawer(Drawer.Scene);
  };

  const handleDragStart = (event: any) => {
    setDraggingSceneId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    setDraggingSceneId(undefined);
    if (active.id === over.id) {
      onClickImage(active.id);
    } else {
      const idx = scenes.findIndex((currentScene) => currentScene.id === active.id);
      const targetIndex = scenes.findIndex((currentScene) => currentScene.id === over.id);
      const isMoveDirectionUp = idx > targetIndex;
      let originalParentIndex = targetIndex;
      let parentId: string | undefined = scenes[targetIndex].id;
      if (isMoveDirectionUp) {
        originalParentIndex = targetIndex > 0 ? targetIndex - 1 : 0;
        parentId = targetIndex > 0 ? scenes[targetIndex - 1].id : undefined;
      }
      dispatch(
        scenesActions.moveScene({
          originalSceneIndex: idx,
          originalParentIndex,
          draftId: draft.id as string,
          sceneId: active.id,
          parentId
        })
      );
    }
  };

  return (
    <ContainerFlexColumn flex="1 1 auto" overflow="auto" gap="15px">
      <DndContext
        autoScroll={false}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={scenes} strategy={verticalListSortingStrategy}>
          {scenes.map((currentScene) => (
            <SortableContainer key={currentScene.id} elementId={currentScene.id}>
              <SceneItem currentSceneId={currentScene.id} />
            </SortableContainer>
          ))}
        </SortableContext>

        <StyledDragOverlay isDragging={!!draggingSceneId}>
          <ConditionalRender condition={draggingSceneId}>
            <SortableContainer key={draggingSceneId} elementId={draggingSceneId ?? ""}>
              <SceneItem currentSceneId={draggingSceneId ?? ""} />
            </SortableContainer>
          </ConditionalRender>
        </StyledDragOverlay>
      </DndContext>
      <ConditionalRender condition={!isLoading}>
        <AddSceneFlexColumn flex="1 0 auto">
          <AddSceneButton
            key="addScene"
            icon={<StyledPlusCircleFilled />}
            onClick={onAddScene}
            loading={createLoading}
          />
        </AddSceneFlexColumn>
      </ConditionalRender>
    </ContainerFlexColumn>
  );
};

export default React.memo(LeftNavScenesList);
