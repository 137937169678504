import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextSmall } from "app/components/_Infrastructure/Typography";
import React, { useEffect, useState } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import TemplatePreviewThumbnail from "app/components/editor/templateSelect/TemplatePreviewThumbnail";
import { useAppDispatch, useAppSelector } from "app/hooks";
import recipesSelectors, { getRecipeByRecipeId } from "app/store/selectorsV2/recipes.selectors";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-white-icon.svg";
import PresenterSelect from "app/components/Summary/PresenterSelect";
import VoiceSelect from "app/components/Summary/VoiceSelect";
import { charactersActions } from "app/store/slices/characters.slice";
import { voicesActions } from "app/store/slices/voices.slice";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import styled from "styled-components";
import { userActions } from "app/store/slices/user.slice";
import ConditionalRender from "app/components/common/ConditionalRender";
import { SwiperSlide } from "swiper/react";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { StyledSkeletonButton } from "app/components/common/Loaders/SkeletonButtonLoader";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import GoBackButton from "app/components/common/GoBackButton";
import { useCloneVoiceModal } from "app/hooks/useCloneVoiceModal";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

interface SummaryConfigProps {
  onFinish: () => void;
}

const PreviewWrapper = styled(H1_FlexColumn)<{ selected: boolean }>`
  border: 2px solid ${({ selected, theme }) => (selected ? theme.blue4 : "transparent")};
  border-radius: 5px;
  box-shadow: ${({ selected }) => (selected ? "0px 0px 10px 0px rgba(0, 0, 255, 0.5)" : "none")};
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: calc(100% - 24px);
  flex: 0 0 auto;
  margin-left: 0;
  .swiper-slide {
    display: flex;
    width: fit-content;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    top: calc(50% + 15px);
    justify-content: center;
    border: 1px solid ${(props) => props.theme.gray5};
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(64 87 109 / 7%);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${(props) => props.theme.gray8};
    background-color: ${(props) => props.theme.gray2};
    transition-property: border-color, color, opacity;
    transition-duration: 0.3s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    svg {
      fill: ${(props) => props.theme.gray8};
      width: 16px;
      height: 16px;
    }
    &.swiper-button-disabled {
      pointer-events: auto;
    }
    &:hover {
      border-color: ${(props) => props.theme.gray5};
    }
    &:active {
      transform: scale(0.95);
    }
    &::after {
      content: "";
    }
  }
  && .swiper-button-prev {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    left: 15px;
  }

  && .swiper-button-next {
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
    right: 15px;
  }
  &:hover .swiper-button-prev:not(.swiper-button-disabled),
  &:hover .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const RecipeTitleWrapper = styled("div")`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 230px;
  color: ${({ theme }) => theme.gray11};
`;

enum Stage {
  Initial = "Initial",
  Templates = "Templates"
}
const SummaryConfig = ({ onFinish }: SummaryConfigProps) => {
  const [currentStage, setCurrentStage] = useState<Stage>(Stage.Initial);
  const [isSwiperReady, setIsSwiperReady] = useState<boolean>(false);
  const [originalRecipeId, setOriginalRecipeId] = useState<string>();
  const [characterId, setCharacterId] = useState<string>();
  const [voiceId, setVoiceId] = useState<string>();

  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const preferences = useAppSelector((state) => state.user.preferences);

  const characters = useAppSelector((state) => charactersGlobalSelectors.selectAll(state));
  const templates = useAppSelector((state) =>
    recipesSelectors.getSummaryRecipes(state, originalRecipeId)
  );
  const selectedRecipeId = preferences.summary_recipe_id;
  const selectedRecipe = useAppSelector((state) => getRecipeByRecipeId(state, selectedRecipeId));

  const isNoStageSelected = currentStage === Stage.Initial;
  const isStageTemplates = currentStage === Stage.Templates;

  useEffect(() => {
    if (!characters.length) {
      dispatch(charactersActions.getCharactersListRequest());
      dispatch(voicesActions.getVoicesListRequest());
    }
    setCharacterId(preferences.summary_character_id || "");
    setVoiceId(preferences.summary_voice_id || "");
    setOriginalRecipeId(selectedRecipeId || "");
    setCurrentStage(selectedRecipeId ? Stage.Initial : Stage.Templates);
    setTimeout(() => {
      setIsSwiperReady(true);
    }, 500);
  }, []);
  useEffect(() => {
    if (characters.length) {
      setCharacterId(characters[0].id);
    }
  }, [characters]);

  const onCreateAvatar = () => {
    openModal(ModalName.createVirtualTwin);
  };
  const openVoiceModal = useCloneVoiceModal();
  const onCloneVoice = () => {
    openVoiceModal("summary_config", ModalName.summaryModal);
  };
  const onSelectCharacter = (value: string) => {
    setCharacterId(value);
  };
  const onSelectVoice = (value: string) => {
    setVoiceId(value);
  };
  const onSelectTemplate = (recipeId: string, title: string) => {
    dispatch(analyticsEvents.salesMeetSelectTemplate({ recipeId, title }));
    dispatch(
      userActions.updateUserPreferencesRequest({
        summary_voice_id: voiceId,
        summary_character_id: characterId,
        summary_recipe_id: recipeId
      })
    );
    onFinish();
  };

  return (
    <H1_FlexColumn
      gap={"7px"}
      padding={"20px 0 0"}
      align={isNoStageSelected ? "flex-start" : "center"}
      width={"100%"}
    >
      <ConditionalRender condition={isStageTemplates && (!isSwiperReady || !templates.length)}>
        <H1_FlexColumn height="156px" width="100%">
          <StyledSkeletonButton $height="156px" active block $borderRadius="5px" />
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={isNoStageSelected}>
        <H1_FlexRow>
          <PreviewWrapper
            align="center"
            position={"relative"}
            selected={false}
            onClick={() => setCurrentStage(Stage.Templates)}
            padding="5px"
          >
            <TemplatePreviewThumbnail
              width="calc(130px * 16 / 9)"
              height="130px"
              badgeText={
                selectedRecipe?.pro
                  ? String(selectedRecipe?.plans[0] ?? "").toUpperCase()
                  : undefined
              }
              badgeIcon={<CrownIcon />}
              alt=""
              video={selectedRecipe?.video as string}
              image={selectedRecipe?.image as string}
              borderRadius="9px"
            />
            <H1_TextSmall>Change template</H1_TextSmall>
          </PreviewWrapper>
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={!!(isSwiperReady && templates.length && isStageTemplates)}>
        <H1_FlexColumn justify={"center"} gap={"5px"} position={"relative"} width={"100%"}>
          <GoBackButton onClick={() => setCurrentStage(Stage.Initial)} fontSize="14px" />
          <StyledSwiper
            navigation
            speed={700}
            spaceBetween={5}
            slidesPerView="auto"
            slidesPerGroup={3}
            allowTouchMove={false}
            lazy
          >
            {templates.map((recipe) => {
              const aspectRatio = recipe.aspect_ratio
                ? Number(recipe.aspect_ratio.width) / Number(recipe.aspect_ratio.height)
                : 16 / 9;
              return (
                <SwiperSlide key={`${recipe.categoryName}_${recipe.id}`}>
                  <PreviewWrapper
                    position={"relative"}
                    selected={selectedRecipeId === recipe.id}
                    onClick={() => onSelectTemplate(recipe.id, recipe.title)}
                    key={recipe.id}
                    padding="5px"
                  >
                    <TemplatePreviewThumbnail
                      width={`calc(130px * ${aspectRatio})`}
                      height="130px"
                      badgeText={
                        recipe.pro ? String(recipe.plans[0] ?? "").toUpperCase() : undefined
                      }
                      badgeIcon={<CrownIcon />}
                      alt=""
                      video={recipe.video}
                      image={recipe.image}
                      borderRadius="9px"
                    />
                    <RecipeTitleWrapper>{recipe.title}</RecipeTitleWrapper>
                  </PreviewWrapper>
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={false}>
        <H1_FlexColumn width={"100%"} align={"baseline"}>
          <Button color="primary" variant="light" onClick={onCreateAvatar}>
            {"Create your avatar here if you haven't already"}
          </Button>
          <Button variant="light" color="primary" onClick={onCloneVoice}>
            {"Create your clone voice here if you haven't already"}
          </Button>
        </H1_FlexColumn>
      </ConditionalRender>
      <ConditionalRender condition={false}>
        <H1_TextMiddle>Presenter</H1_TextMiddle>
        <H1_FlexColumn gap={"5px"}>
          <PresenterSelect value={characterId} onChange={onSelectCharacter} />
          <div style={{ background: "black" }}>
            <VoiceSelect voiceId={voiceId} onChange={onSelectVoice} />
          </div>
        </H1_FlexColumn>
      </ConditionalRender>
    </H1_FlexColumn>
  );
};
export default SummaryConfig;
