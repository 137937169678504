import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import styled, { useTheme } from "styled-components";
import { ThemeMode } from "app/utils/theme";
import { cgnMessages } from "app/pages/CGN/messages";
import { useIntl } from "react-intl";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button, Input } from "@nextui-org/react";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import MuxPlayer from "app/components/common/player/MuxPlayer";
import CGNComment from "app/pages/CGN/CGNComment";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { cgnActions } from "app/store/slices/cgn.slice";
import { CGNType, MuxStream } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { Tab, Tabs } from "@nextui-org/tabs";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray12};
`;
const PromptFlexRow = styled(H1_FlexRow)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.gray2};

  &&&& div[data-slot="input-wrapper"] {
    background-color: ${({ theme }) => theme.gray3};

    input {
      color: ${({ theme }) => theme.gray11};
    }
  }
`;
const PromptButton = styled(Button)`
  position: absolute;
  right: 25px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.blue2};

  i {
    color: ${({ theme }) => theme.gray1};
  }
`;
const SendCommentWrapper = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray3};
  transition: background-color 0s ease-in-out;
  border-radius: 12px;
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.1) inset;
`;
const SubmitButton = styled(Button)`
  && {
    background-color: transparent;
  }

  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5)};

  &:hover {
    color: ${({ theme }) => theme.gray6};
  }
`;
const SendCommentInput = styled(Input)`
  max-height: 20px;

  &&&& div[data-slot="base"] {
    box-shadow: none;
  }

  &&&& div[data-slot="input-wrapper"] {
    max-height: 20px;
    min-height: 20px;
    background-color: ${({ theme }) => theme.gray3};
    transition: background-color 0s ease-in-out;
    box-shadow: none;

    div[data-slot="inner-wrapper"] {
      height: 20px;

      input {
        color: ${({ theme }) => theme.gray11};
      }
    }
  }
`;
const MuxWrapper = styled(H1_FlexColumn)`
  align-self: center;

  > div > div:first-of-type {
    overflow: hidden;
    flex: 1;

    > div {
      height: 100%;
      width: auto;
      overflow: hidden;

      > div {
        height: 100%;
        width: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
      }

      mux-player {
        border-radius: 14px;
        height: 100%;
        width: auto;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  && > div {
    background-color: ${({ theme }) => theme.gray2};
    padding: 4px;
    border-radius: 8px;

    div[data-slot="tabContent"] {
      color: ${({ theme }) => theme.gray6};
    }

    button[aria-selected="true"] > [data-slot="cursor"] {
      color: ${({ theme }) => theme.gray10};
      background-color: ${({ theme }) => theme.gray4}; /* Darker background color */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Darker shadow */
      opacity: 1;

      ~ div[data-slot="tabContent"] {
        color: ${({ theme }) => theme.gray10};
      }
    }
  }
`;
const Circle = styled(H1_FlexRow)`
  border-radius: 50%;
  background-color: #01f5bf;
`;

enum ChannelsTabs {
  news = "news",
  entertainment = "entertainment",
  technology = "technology",
  podcast = "podcast"
}

const CGNPage = () => {
  const [comment, setComment] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  const [selectedChannel, setSelectedChannel] = useState<ChannelsTabs>(ChannelsTabs.news);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const currentChannel = useAppSelector((state) =>
    state.cgn.channels.find((channel) => channel.name === selectedChannel)
  );
  const cgnType = currentChannel?.type;
  const getCGNStatus = useAppSelector((state) => state.cgn.getCGNStatus);
  const playbackId = currentChannel?.playback_id;
  const isCGNAsset = cgnType === CGNType.asset;
  const isCgnLoading = getCGNStatus === fetchingStatus.loading;

  useEffect(() => {
    dispatch(cgnActions.getCGNRequest());
  }, []);

  const onChangePromptValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPrompt(value);
  };

  const onChangeCommentValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setComment(value);
  };

  const onSendPrompt = () => {
    // Send prompt to server
    setPrompt("");
  };

  const onClickSubmitComment = () => {
    setComment("");
  };

  const handleWrapperClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const commentsItems = useMemo(() => {
    return [
      {
        id: 1,
        img: "https://i.pravatar.cc/150?u=a042581f4e29026024d",
        content: "What a great video!",
        name: "Rodney Smith",
        time: "9/9/2024"
      }
    ];
  }, []);

  const onChangeTab = (tab: ChannelsTabs) => {
    setSelectedChannel(tab);
  };

  return (
    <BackgroundFlexColumn gap="28px" width="100%" height="100%" padding="15px 28px 41px">
      <H1_FlexRow flex="1 1 auto" justify="space-between" gap="28px" overflow="hidden">
        <MuxWrapper gap="28px" flex="1 1 auto" height="100%" overflow="hidden">
          <H1_FlexRow alignSelf={"center"}>
            <StyledTabs
              // activeKey={selectedChannel}
              selectedKey={selectedChannel}
              onSelectionChange={(value) => onChangeTab(value as ChannelsTabs)}
            >
              <Tab key={ChannelsTabs.news} title={"News"} />
              <Tab key={ChannelsTabs.entertainment} title={"Entertainment"} />
              <Tab key={ChannelsTabs.technology} title={"Technology"} />
              <Tab key={ChannelsTabs.podcast} title={"Podcast"} />
            </StyledTabs>
          </H1_FlexRow>
          <ConditionalRender condition={isCgnLoading}>
            <CircleLoader />
          </ConditionalRender>
          <ConditionalRender condition={!isCgnLoading}>
            <H1_FlexColumn flex="1" width="auto" gap="40px" overflow="hidden">
              <MuxPlayer
                shape="landscape"
                playbackId={playbackId || "f3uwVtnFPDHdGRrtR01RsfSUG4FO8ndEWTzvIGKdo80100"}
                streamType={isCGNAsset ? MuxStream.onDemand : MuxStream.live}
                overflow="hidden"
                loop
                autoPlay
              />
              <H1_FlexRow flex="0 0 40px" justify="space-between">
                <H1_FlexRow gap="8px">
                  <Circle
                    align="center"
                    justify="center"
                    height="40px"
                    width="40px"
                    flex="1 0 40px"
                  >
                    M
                  </Circle>
                  <H1_FlexColumn>
                    <H1_TextSmall color={theme.gray5}>Getting started with Reals</H1_TextSmall>
                    <H1_TextSmall color={theme.gray6}>20 August, 11:09AM</H1_TextSmall>
                  </H1_FlexColumn>
                </H1_FlexRow>
                <H1_FlexRow gap="8px">
                  <Button isIconOnly startContent={<i className="fas fa-link" />} />
                  <Button isIconOnly startContent={<i className="fas fa-share" />} />
                  <Button isIconOnly startContent={<i className="fas fa-ellipsis" />} />
                </H1_FlexRow>
              </H1_FlexRow>
            </H1_FlexColumn>
          </ConditionalRender>
        </MuxWrapper>
        <H1_FlexColumn flex="0 0 340px" width="340px" height="100%" gap="8px">
          <H1_TextSubtitle>{formatMessage(cgnMessages.commentsTitle)}</H1_TextSubtitle>
          <H1_FlexColumn flex="1" padding="0 0 20px 0">
            {commentsItems.map((comment) => (
              <CGNComment
                key={comment.id}
                img={comment.img}
                name={comment.name}
                time={comment.time}
                content={comment.content}
              />
            ))}
          </H1_FlexColumn>
          <SendCommentWrapper
            onClick={handleWrapperClick}
            height="63px"
            flex="0 0 63px"
            padding="8px 15px"
          >
            <SendCommentInput
              ref={inputRef}
              defaultValue={comment}
              value={comment}
              onChange={onChangeCommentValue}
              placeholder={formatMessage(cgnMessages.writeCommentPlaceholder)}
              size="sm"
              labelPlacement="outside"
            />
            <H1_FlexRow justify="flex-end" padding="0 0 5px 0">
              <SubmitButton
                size="sm"
                isIconOnly
                startContent={<i className="far fa-paper-plane-top" />}
                variant="light"
                onClick={onClickSubmitComment}
              />
            </H1_FlexRow>
          </SendCommentWrapper>
        </H1_FlexColumn>
      </H1_FlexRow>
      <PromptFlexRow
        flex="0 0 62px"
        padding="8px 13px"
        align="center"
        gap="70px"
        position="relative"
        width="calc(100% - 50px)"
      >
        <H1_TextSmall color={theme.gray7}>{formatMessage(cgnMessages.selectSubject)}</H1_TextSmall>
        <H1_FlexRow flex="1" align="center" gap="10px">
          <Input
            size="lg"
            placeholder={formatMessage(cgnMessages.promptPlaceholder)}
            defaultValue={prompt}
            value={prompt}
            onChange={onChangePromptValue}
            fullWidth
          />
          <PromptButton
            onClick={onSendPrompt}
            isIconOnly
            startContent={<i className="fas fa-sparkles" />}
          />
        </H1_FlexRow>
      </PromptFlexRow>
    </BackgroundFlexColumn>
  );
};

export default CGNPage;
