/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/videos.thunk";
import { videosAdapter } from "app/store/adapters/adapters";
import { executeDownload, fetchingStatus } from "app/utils/helpers";
import { FetchStatus, ProtectionType, ServerError, Video, VideoStatusEnum } from "app/types";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";
import { v4 as uuidv4 } from "uuid";
import { set } from "lodash-es";

interface VideoState {
  videosStatus: FetchStatus;
  deleteVideosStatus: FetchStatus;
  videoByReqIdStatus: FetchStatus;
  videoCreateStatus: FetchStatus;
  cancelVideoCreateStatus: FetchStatus;
  downloadVideoStatus: FetchStatus;
  videoFetchFeedbackStatus: FetchStatus;
  videoUpsertVideoFeedbackStatus: FetchStatus;
  publishVideoStatus: FetchStatus;
  translateVideoSubtitlesStatus: FetchStatus;
  fetchVideoProtectionStatus: FetchStatus;
  setVideoProtectionStatus: FetchStatus;
  getVideoFeedStatus: FetchStatus;
  isLastVideoReached: boolean;
  downloadVideoFormat: Record<
    string,
    { link?: string; orderId?: string; status: PusherMessagesStatusEnum }
  >;
  scormDownloadVideoFormat: Record<
    string,
    {
      videoId?: string;
      link?: string;
      orderId: string;
      status?: PusherMessagesStatusEnum;
    }
  >;
  downloadLink?: string;
  videoCreateError?: ServerError;
  realsMeProcessingVideos: Record<string, boolean>;
  videosSubtitleStatus: FetchStatus;
}

export const videosSlice = createSlice({
  name: "Videos",
  initialState: videosAdapter.getInitialState<VideoState>({
    videosSubtitleStatus: fetchingStatus.idle as FetchStatus,
    videosStatus: fetchingStatus.idle as FetchStatus,
    cancelVideoCreateStatus: fetchingStatus.idle as FetchStatus,
    getVideoFeedStatus: fetchingStatus.idle as FetchStatus,
    deleteVideosStatus: fetchingStatus.idle as FetchStatus,
    videoByReqIdStatus: fetchingStatus.idle as FetchStatus,
    videoCreateStatus: fetchingStatus.idle as FetchStatus,
    downloadVideoStatus: fetchingStatus.idle as FetchStatus,
    publishVideoStatus: fetchingStatus.idle as FetchStatus,
    videoFetchFeedbackStatus: fetchingStatus.idle as FetchStatus,
    videoUpsertVideoFeedbackStatus: fetchingStatus.idle as FetchStatus,
    translateVideoSubtitlesStatus: fetchingStatus.idle as FetchStatus,
    fetchVideoProtectionStatus: fetchingStatus.idle as FetchStatus,
    setVideoProtectionStatus: fetchingStatus.idle as FetchStatus,
    downloadLink: undefined,
    downloadVideoFormat: {},
    scormDownloadVideoFormat: {},
    videoCreateError: undefined,
    realsMeProcessingVideos: {},
    isLastVideoReached: false
  }),
  reducers: {
    cleanVideos(state) {
      videosAdapter.removeAll(state);
      state.isLastVideoReached = false;
      return state;
    },
    updateStateToIdle(state) {
      state.videosStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateFetchVideoProtectionRequest(state) {
      state.fetchVideoProtectionStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateSetVideoProtectionStatus(state) {
      state.setVideoProtectionStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateVideoFetchFeedbackStatusStateToIdle(state) {
      state.videoFetchFeedbackStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateVideoUpsertFeedbackStatusStateToIdle(state) {
      state.videoUpsertVideoFeedbackStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateCancelVideoStateToIdle(state) {
      state.cancelVideoCreateStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateVideoDeleteStateToIdle(state) {
      state.deleteVideosStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateVideoCreateStatusToIdle(state) {
      state.videoCreateStatus = fetchingStatus.idle as FetchStatus;
      state.videoCreateError = undefined;
      return state;
    },
    cleanDownload(state) {
      state.downloadLink = undefined;
      state.downloadVideoStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updatePublishStatusToIdle(state) {
      state.publishVideoStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateVideoStatusAndProgress(
      state,
      action: PayloadAction<{
        videoId: string;
        progress?: number;
        status: VideoStatusEnum;
        duration?: number;
      }>
    ) {
      const video = state.entities[action.payload.videoId];
      if (video) {
        video.status = action.payload.status;
        if (action.payload.progress) {
          video.progress = action.payload.progress;
        }
        if (action.payload.duration) {
          video.duration = action.payload.duration;
        }
        state.entities[action.payload.videoId] = video;
        return state;
      }
      return state;
    },
    updateVideoThumbnailUrl(state, action: PayloadAction<{ videoId: string; url: string }>) {
      const { videoId, url } = action.payload;
      const video = state.entities[videoId];
      if (video) {
        video.thumbnail_url = url;
        videosAdapter.updateOne(state, { id: videoId, changes: video });
        return state;
      }
      return state;
    },
    setVideos(state, action: PayloadAction<Video[]>) {
      videosAdapter.setAll(state, action.payload);
      return state;
    },
    addVideo(state, action: PayloadAction<Video>) {
      videosAdapter.upsertOne(state, action.payload);
      return state;
    },
    addMultipleVideos(state, action: PayloadAction<Partial<Video>[]>) {
      videosAdapter.upsertMany(state, action.payload as Video[]);
      return state;
    },
    addVideoToFirst(state, action: PayloadAction<Video>) {
      state.entities[action.payload.id] = action.payload;
      state.ids.unshift(action.payload.id);
      return state;
    },
    setDownloadVideoFormat: (
      state,
      action: PayloadAction<{
        videoId: string;
        link?: string;
        orderId?: string;
        status: PusherMessagesStatusEnum;
      }>
    ) => {
      state.downloadVideoFormat[action.payload.videoId] = {
        link: action.payload.link,
        status: action.payload.status,
        orderId: action.payload.orderId
      };
      return state;
    },
    setScormDownloadVideoFormat: (
      state,
      action: PayloadAction<{
        videoId: string;
        link?: string;
        orderId: string;
        status: PusherMessagesStatusEnum;
      }>
    ) => {
      state.scormDownloadVideoFormat[action.payload.orderId] = {
        videoId: action.payload.videoId,
        link: action.payload.link,
        status: action.payload.status,
        orderId: action.payload.orderId
      };
      return state;
    },
    setRealsMeProcessingOverlay: (
      state,
      action: PayloadAction<{ draftId: string; value: boolean }>
    ) => {
      state.realsMeProcessingVideos[action.payload.draftId] = action.payload.value;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getVideosRequest.fulfilled, (state, action) => {
      state.videosStatus = fetchingStatus.succeeded as FetchStatus;
      videosAdapter.setAll(state, action.payload);
    });
    builder.addCase(asyncThunks.getVideosRequest.pending, (state) => {
      state.videosStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getVideosRequest.rejected, (state) => {
      state.videosStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getVideoSubtitleApiRequest.fulfilled, (state, action) => {
      state.videosSubtitleStatus = fetchingStatus.succeeded as FetchStatus;
      const downloadLink = action.payload.url;
      executeDownload(downloadLink as string);
    });
    builder.addCase(asyncThunks.getVideoSubtitleApiRequest.pending, (state) => {
      state.videosSubtitleStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getVideoSubtitleApiRequest.rejected, (state) => {
      state.videosSubtitleStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.translateVideoSubtitlesApiRequest.fulfilled, (state, action) => {
      state.translateVideoSubtitlesStatus = fetchingStatus.succeeded as FetchStatus;
      const downloadLink = action.payload.url;
      executeDownload(downloadLink as string);
    });
    builder.addCase(asyncThunks.translateVideoSubtitlesApiRequest.pending, (state) => {
      state.translateVideoSubtitlesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.translateVideoSubtitlesApiRequest.rejected, (state) => {
      state.translateVideoSubtitlesStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.createVideoRequest.fulfilled, (state, action) => {
      state.videoCreateStatus = fetchingStatus.succeeded as FetchStatus;
      state.entities[action.payload.id] = action.payload;
      state.ids.unshift(action.payload.id);
    });
    builder.addCase(asyncThunks.createVideoRequest.pending, (state) => {
      state.videoCreateStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.createVideoRequest.rejected, (state, action) => {
      state.videoCreateStatus = fetchingStatus.failed as FetchStatus;
      state.videoCreateError = action.error as ServerError;
    });
    builder.addCase(asyncThunks.createVideoRealsMeRequest.fulfilled, (state, action) => {
      state.videoCreateStatus = fetchingStatus.succeeded as FetchStatus;
      state.entities[action.payload.id] = action.payload;
      state.ids.unshift(action.payload.id);
    });
    builder.addCase(asyncThunks.createVideoRealsMeRequest.pending, (state, action) => {
      state.videoCreateStatus = fetchingStatus.loading as FetchStatus;
      state.realsMeProcessingVideos[action.meta.arg.draftId] = true;
    });
    builder.addCase(asyncThunks.createVideoRealsMeRequest.rejected, (state, action) => {
      state.videoCreateStatus = fetchingStatus.failed as FetchStatus;
      state.realsMeProcessingVideos[action.meta.arg.draftId] = false;
      state.videoCreateError = action.error as ServerError;
    });

    builder.addCase(asyncThunks.downloadVideoRequest.fulfilled, (state, action) => {
      state.downloadVideoStatus = fetchingStatus.succeeded as FetchStatus;
      const downloadLink = action.payload.url;
      executeDownload(downloadLink as string);
    });
    builder.addCase(asyncThunks.downloadVideoRequest.pending, (state) => {
      state.downloadVideoStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.downloadVideoRequest.rejected, (state) => {
      state.downloadVideoStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.publishVideoRequest.fulfilled, (state, action) => {
      state.publishVideoStatus = fetchingStatus.succeeded as FetchStatus;
      const { videoId } = action.meta.arg;
      const video = state.entities[videoId];
      if (video) {
        video.status = VideoStatusEnum.Published;
        videosAdapter.updateOne(state, { id: videoId, changes: video });
      }
    });
    builder.addCase(asyncThunks.publishVideoRequest.pending, (state) => {
      state.publishVideoStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.publishVideoRequest.rejected, (state) => {
      state.publishVideoStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getVideosByReqIdRequest.fulfilled, (state, action) => {
      state.videoByReqIdStatus = fetchingStatus.succeeded as FetchStatus;
      videosAdapter.upsertOne(state, action.payload[0]);
    });
    builder.addCase(asyncThunks.getVideosByReqIdRequest.pending, (state) => {
      state.videoByReqIdStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getVideosByReqIdRequest.rejected, (state) => {
      state.videoByReqIdStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.downloadTranscodeVideoRequest.fulfilled, (state, action) => {
      const { videoId } = action.meta.arg;
      const { link, status } = action.payload;
      if (link && status === PusherMessagesStatusEnum.ready) {
        state.downloadVideoFormat[videoId] = {
          orderId: state.downloadVideoFormat[videoId].orderId,
          status: PusherMessagesStatusEnum.ready,
          link
        };

        executeDownload(link as string);
      }
    });
    builder.addCase(asyncThunks.downloadTranscodeVideoRequest.pending, (state, action) => {
      const { videoId } = action.meta.arg;
      const orderId = uuidv4();
      state.downloadVideoFormat[videoId] = {
        status: PusherMessagesStatusEnum.processing,
        orderId
      };
    });
    builder.addCase(asyncThunks.downloadTranscodeVideoRequest.rejected, (state, action) => {
      const { videoId } = action.meta.arg;
      state.downloadVideoFormat[videoId] = {
        status: PusherMessagesStatusEnum.failed
      };
    });

    builder.addCase(asyncThunks.downloadScormVideoRequest.fulfilled, (state, action) => {
      const { videoId, orderId } = action.meta.arg;
      const { link, status } = action.payload;
      if (link && status === PusherMessagesStatusEnum.ready) {
        state.scormDownloadVideoFormat[orderId] = {
          videoId,
          orderId,
          status: PusherMessagesStatusEnum.ready,
          link
        };
        executeDownload(link as string);
      }
    });

    builder.addCase(asyncThunks.downloadScormVideoRequest.pending, (state, action) => {
      const { videoId, orderId } = action.meta.arg;
      state.scormDownloadVideoFormat[orderId] = {
        videoId,
        status: PusherMessagesStatusEnum.processing,
        orderId
      };
    });

    builder.addCase(asyncThunks.downloadScormVideoRequest.rejected, (state, action) => {
      const { orderId } = action.meta.arg;
      state.scormDownloadVideoFormat[orderId].status = PusherMessagesStatusEnum.failed;
    });

    builder.addCase(asyncThunks.upsertVideoRatingRequest.pending, (state, action) => {
      const { videoId, reaction, user, activate } = action.meta.arg;
      const reactionIdx = state.entities[videoId]?.reactions?.findIndex(
        (singleReaction) => singleReaction.reaction === reaction
      );
      if (activate) {
        if (reactionIdx === undefined || reactionIdx === -1) {
          // First reaction react
          state.entities[videoId]?.reactions?.push({
            reaction,
            count: 1,
            active: true,
            // eslint-disable-next-line camelcase
            reacted_users: [user],
            reaction_type: "video_rating"
          });
        } else if (reactionIdx > -1) {
          // Update exisiting reaction
          const reactors = (state.entities[videoId] as Video).reactions[reactionIdx].reacted_users;
          set(state.entities[videoId] as Video, `reactions[${reactionIdx}]`, {
            reaction,
            count: ((state.entities[videoId] as Video).reactions[reactionIdx].count as number) + 1,
            active: true,
            // eslint-disable-next-line camelcase
            reacted_users: reactors?.concat(user)
          });
        }
      } else if (reactionIdx !== undefined && reactionIdx > -1) {
        // Remove reaction from exsiting
        const reactors = (state.entities[videoId] as Video).reactions[reactionIdx].reacted_users;
        set(state.entities[videoId] as Video, `reactions[${reactionIdx}]`, {
          reaction,
          count: ((state.entities[videoId] as Video).reactions[reactionIdx].count as number) - 1,
          active: false,
          // eslint-disable-next-line camelcase
          reacted_users: [...(reactors || [])]?.filter((reactor) => !reactor.is_current_user)
        });
      }
    });

    builder.addCase(asyncThunks.upsertVideoRatingRequest.rejected, (state, action) => {
      const { videoId, reaction } = action.meta.arg;
      const reactionIdx = state.entities[videoId]?.reactions?.findIndex(
        (singleReaction) => singleReaction.reaction === reaction
      );
      if (reactionIdx !== undefined && reactionIdx > -1) {
        set(state.entities[videoId] as Video, `reactions[${reactionIdx}]`, {
          reaction,
          count: ((state.entities[videoId] as Video).reactions[reactionIdx].count as number) - 1,
          active: false
        });
      }
    });

    builder.addCase(asyncThunks.upsertVideoFeedbackRequest.pending, (state) => {
      state.videoUpsertVideoFeedbackStatus = fetchingStatus.loading as FetchStatus;
    });

    builder.addCase(asyncThunks.upsertVideoFeedbackRequest.fulfilled, (state, action) => {
      state.videoUpsertVideoFeedbackStatus = fetchingStatus.succeeded as FetchStatus;
      const res = action.payload;
      const { videoId } = action.meta.arg;
      const video = state.entities[videoId];
      if (video && res) {
        video.feedbacked = res;
      }
    });

    builder.addCase(asyncThunks.upsertVideoFeedbackRequest.rejected, (state) => {
      state.videoUpsertVideoFeedbackStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getVideoFeedbackRequest.pending, (state) => {
      state.videoFetchFeedbackStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getVideoFeedbackRequest.rejected, (state) => {
      state.videoFetchFeedbackStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getVideoFeedbackRequest.fulfilled, (state, action) => {
      state.videoFetchFeedbackStatus = fetchingStatus.succeeded as FetchStatus;
      const res = action.payload;
      const { videoId } = action.meta.arg;
      const video = state.entities[videoId];
      if (video && res) {
        video.feedbacked = res;
      }
    });

    builder.addCase(asyncThunks.searchVideosApiRequest.pending, (state) => {
      state.videosStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.searchVideosApiRequest.rejected, (state) => {
      state.videosStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.searchVideosApiRequest.fulfilled, (state, action) => {
      state.videosStatus = fetchingStatus.succeeded as FetchStatus;
      videosAdapter.setAll(state, action.payload.data);
    });
    builder.addCase(asyncThunks.getFeaturedVideosApiRequest.pending, (state) => {
      state.videosStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getFeaturedVideosApiRequest.rejected, (state) => {
      state.videosStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.getFeaturedVideosApiRequest.fulfilled, (state, action) => {
      state.videosStatus = fetchingStatus.succeeded as FetchStatus;
      videosAdapter.setAll(state, action.payload.data);
    });

    builder.addCase(asyncThunks.deleteVideoRequest.pending, (state, action) => {
      state.deleteVideosStatus = fetchingStatus.loading as FetchStatus;
      const { videoId } = action.meta.arg;
      videosAdapter.removeOne(state, videoId);
    });
    builder.addCase(asyncThunks.deleteVideoRequest.rejected, (state) => {
      state.deleteVideosStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteVideoRequest.fulfilled, (state) => {
      state.deleteVideosStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.cancelVideoRequest.pending, (state, action) => {
      state.cancelVideoCreateStatus = fetchingStatus.loading as FetchStatus;
      const { videoId } = action.meta.arg;
      const video = state.entities[videoId];
      if (video) {
        video.status = VideoStatusEnum.Canceled;
      }
    });
    builder.addCase(asyncThunks.cancelVideoRequest.rejected, (state) => {
      state.cancelVideoCreateStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.cancelVideoRequest.fulfilled, (state) => {
      state.cancelVideoCreateStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.updateVideoRequest.pending, (state, action) => {
      const { operations, videoId } = action.meta.arg;
      const video = state.entities[videoId];
      const updateOps: Record<keyof Video, any> = {} as Record<keyof Video, any>;

      // eslint-disable-next-line no-restricted-syntax
      for (const operation of operations) {
        if (video) {
          switch (operation.op) {
            case "add":
              // eslint-disable-next-line no-case-declarations
              const currentList = (video[operation.path as keyof Video] || []) as string[];
              set(updateOps, operation.path as keyof Video, [...currentList, operation.value]);
              break;
            case "delete":
              set(
                updateOps,
                operation.path as keyof Video,
                ((video[operation.path as keyof Video] || []) as string[]).filter(
                  (item: any) => item !== operation.value
                )
              );
              break;
            default:
              // It's a replace
              set(updateOps, operation.path as keyof Video, operation.value);
              break;
          }
        }
      }
      videosAdapter.updateOne(state, { id: videoId, changes: updateOps });
      return state;
    });

    builder.addCase(asyncThunks.fetchVideoProtectionRequest.pending, (state) => {
      state.fetchVideoProtectionStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.fetchVideoProtectionRequest.rejected, (state) => {
      state.fetchVideoProtectionStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.fetchVideoProtectionRequest.fulfilled, (state, action) => {
      state.fetchVideoProtectionStatus = fetchingStatus.succeeded as FetchStatus;
      const { videoId } = action.meta.arg;
      const video = state.entities[videoId];
      if (video && action.payload.protection) {
        video.protected = action.payload.protection;
      }
    });

    builder.addCase(asyncThunks.setVideoPasswordProtectionRequest.pending, (state) => {
      state.setVideoProtectionStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.setVideoPasswordProtectionRequest.rejected, (state) => {
      state.setVideoProtectionStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.setVideoPasswordProtectionRequest.fulfilled, (state, action) => {
      state.setVideoProtectionStatus = fetchingStatus.succeeded as FetchStatus;
      const { videoId } = action.meta.arg;
      const video = state.entities[videoId];
      if (video) {
        video.protected = ProtectionType.Password;
      }
    });

    builder.addCase(asyncThunks.removeVideoProtectionRequest.pending, (state) => {
      state.setVideoProtectionStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.removeVideoProtectionRequest.rejected, (state) => {
      state.setVideoProtectionStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.removeVideoProtectionRequest.fulfilled, (state, action) => {
      state.setVideoProtectionStatus = fetchingStatus.succeeded as FetchStatus;
      const { videoId } = action.meta.arg;
      const video = state.entities[videoId];
      if (video) {
        video.protected = ProtectionType.None;
      }
    });
    builder.addCase(asyncThunks.getVideosFeedRequest.pending, (state) => {
      state.getVideoFeedStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getVideosFeedRequest.rejected, (state) => {
      state.getVideoFeedStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.getVideosFeedRequest.fulfilled, (state, action) => {
      state.getVideoFeedStatus = fetchingStatus.succeeded as FetchStatus;
      if (action.payload.data.length < action.meta.arg.limit) {
        state.isLastVideoReached = true;
      }
      videosAdapter.upsertMany(state, action.payload.data as Video[]);
    });
  }
});

export default videosSlice.reducer;

export const videosActions = {
  createVideoRequest: asyncThunks.createVideoRequest,
  createVideoRealsMeRequest: asyncThunks.createVideoRealsMeRequest,
  getVideosRequest: asyncThunks.getVideosRequest,
  getVideosByReqIdRequest: asyncThunks.getVideosByReqIdRequest,
  downloadVideoRequest: asyncThunks.downloadVideoRequest,
  downloadTranscodeVideoRequest: asyncThunks.downloadTranscodeVideoRequest,
  downloadScormVideoRequest: asyncThunks.downloadScormVideoRequest,
  updateLocalDownloadFormat: asyncThunks.updateLocalDownloadFormat,
  updateLocalScormDownloadFormat: asyncThunks.updateLocalScormDownloadFormat,
  publishVideoRequest: asyncThunks.publishVideoRequest,
  updateVideoProgress: asyncThunks.updateVideoProgress,
  updateVideoThumbnail: asyncThunks.updateVideoThumbnail,
  searchVideosApiRequest: asyncThunks.searchVideosApiRequest,
  deleteVideoRequest: asyncThunks.deleteVideoRequest,
  updateVideoRequest: asyncThunks.updateVideoRequest,
  upsertVideoRatingRequest: asyncThunks.upsertVideoRatingRequest,
  upsertVideoFeedbackRequest: asyncThunks.upsertVideoFeedbackRequest,
  getFeaturedVideosApiRequest: asyncThunks.getFeaturedVideosApiRequest,
  getVideoFeedbackRequest: asyncThunks.getVideoFeedbackRequest,
  getVideoSubtitleApiRequest: asyncThunks.getVideoSubtitleApiRequest,
  translateVideoSubtitles: asyncThunks.translateVideoSubtitlesApiRequest,
  cancelVideoRequest: asyncThunks.cancelVideoRequest,
  fetchVideoProtectionRequest: asyncThunks.fetchVideoProtectionRequest,
  setVideoPasswordProtectionRequest: asyncThunks.setVideoPasswordProtectionRequest,
  removeVideoProtectionRequest: asyncThunks.removeVideoProtectionRequest,
  getVideosFeedRequest: asyncThunks.getVideosFeedRequest,
  ...videosSlice.actions
};
