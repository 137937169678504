import { defineMessages } from "react-intl";

export default defineMessages({
  pexelsImageTag: {
    id: "editor.drawer.media.pexels.image.tagline",
    defaultMessage: "Photo by <imageLink>{name}</imageLink> on <pexelsLink>Pexels</pexelsLink>"
  },
  pexelsVideoTag: {
    id: "editor.drawer.media.pexels.video.tagline",
    defaultMessage: "Video by <imageLink>{name}</imageLink> on <pexelsLink>Pexels</pexelsLink>"
  },
  loadMore: {
    id: "editor.drawer.media.pexels.button.load-more",
    defaultMessage: "Load more"
  },
  error: {
    id: "editor.drawer.media.error.pexels",
    defaultMessage: "Got Pexels Error, Try again later"
  },
  searchPlaceholder: {
    id: "editor.drawer.media.error.pexels-search",
    defaultMessage: "Search pexels"
  },
  filterImages: {
    id: "editor.drawer.media.filter-images.pexels",
    defaultMessage: "Images"
  },
  filterVideos: {
    id: "editor.drawer.media.filter-videos.pexels",
    defaultMessage: "Videos"
  }
});
