import {
  Accordion,
  AccordionItem,
  Button,
  Listbox,
  ListboxItem,
  type ListboxProps,
  ListboxSection,
  type Selection
} from "@nextui-org/react";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { cn } from "app/components/LeftNav/cn";
import { leftNavChannelsMessages, leftNavMessages } from "app/components/LeftNav/messages";
import { useIntl } from "react-intl";
import { Channel, CurrentPage, FeatureFlag, Location } from "app/types";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { NewVideoType } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { Chip } from "@nextui-org/chip";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { useFlags } from "launchdarkly-react-client-sdk";
import styled, { useTheme } from "styled-components";
import AddChannelModal from "app/components/LeftNav/Channels/AddChannelModal";
import { useAuth } from "app/auth/useAuth";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import usePermissions from "app/hooks/usePermissions";
import useModal, { ModalName } from "app/hooks/useModal";
import { ThemeMode } from "app/utils/theme";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";

const ChannelFlexRow = styled(H1_FlexRow)`
  ~ span {
    color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6)};
  }
`;
const HoverButton = styled(Button)<{ $visible: boolean }>`
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  pointer-events: ${({ $visible }) => ($visible ? "auto" : "none")};
  height: 20px;
  width: 20px;
  min-width: 20px;
`;
type SidebarProps = Omit<ListboxProps<SidebarItem>, "children"> & {
  showBrandKit?: boolean;
  onShowBrandKit?: (bool: boolean) => void;
  selectedPage: string;
  onSelectedPage: (page: CurrentPage) => void;
};

export enum SidebarItemType {
  Nest = "nest"
}

export type SidebarItem = {
  onClick?: () => void;
  key: string;
  title: string;
  icon?: string;
  href?: string;
  type?: SidebarItemType.Nest;
  isRemovable?: boolean;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  items?: SidebarItem[];
  className?: string;
  dataAutoId?: string;
};

const SideBar = forwardRef<HTMLElement, SidebarProps>(
  ({ selectedPage, onSelectedPage, showBrandKit, onShowBrandKit, className, ...props }, ref) => {
    const [hoverItemKey, setHoverItemKey] = useState<string | undefined>(undefined);
    const [createChannelsModalVisible, setCreateChannelsModalVisible] = useState(false);
    const { formatMessage } = useIntl();
    const { isAuthenticated } = useAuth();
    const { openModal, editorModalOpen } = useModal();
    const { isEnterprise } = usePermissions();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const flags = useFlags();
    const theme = useTheme();
    const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
    const channels = useAppSelector((state) => state.workspaces.channels);
    const preferences = useAppSelector((state) => state.user.preferences);
    const isFirstVideoCreated =
      preferences.is_first_video_created === undefined || preferences.is_first_video_created;
    const isMediaOpened = editorModalOpen?.open === ModalName.mediaLibraryModal;
    const isPlaylistAvailable = flags[FeatureFlag.playlists] && isEnterprise;
    const isCGNAvailable = flags[FeatureFlag.cgn];

    useEffect(() => {
      if (currentWorkspace) {
        dispatch(workspacesActions.getChannelsRequest());
      }
    }, [currentWorkspace, flags]);

    const onClickChannel = (channelName: string) => {
      navigate(`/channels/${channelName}`);
    };

    const onFinishCreateChannel = (channelName: string) => {
      setCreateChannelsModalVisible(false);
      dispatch(analyticsEvents.createChannel({ name: channelName }));
      dispatch(workspacesActions.createChannelRequest({ name: channelName }));
    };

    const onCloseCreateChannel = () => {
      setCreateChannelsModalVisible(false);
    };

    const onRemoveChannel = (e: React.MouseEvent, channelName: string) => {
      e.stopPropagation();
      dispatch(analyticsEvents.removeChannel({ name: channelName }));
      dispatch(workspacesActions.removeChannelRequest({ name: channelName }));
    };

    const onMouseEnter = (key: string) => {
      setHoverItemKey(key);
    };

    const onMouseLeave = () => {
      setHoverItemKey(undefined);
    };

    const onClickRecent = () => {
      navigate(`/channels/`);
    };

    const onClickAvatars = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "presenters" }));
      navigate(Location.Avatars);
    };
    const onClickTemplates = () => {
      dispatch(
        analyticsEvents.newVideo({
          source: "leftNav",
          type: NewVideoType.video
        })
      );
      navigate(Location.Templates);
    };
    const onClickBriefs = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "briefs" }));
      navigate(Location.Briefs);
    };
    const onClickTrash = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "trash" }));
      navigate(Location.Trash);
    };

    const onClickPlaylists = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "playlists" }));
      navigate(Location.Playlists);
    };
    const onClickCGN = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "cgn" }));
      navigate(Location.Live);
    };
    const onClickWorkflows = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "workflows" }));
      navigate(Location.Workflows);
    };
    const onClickProjects = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "projects" }));
      navigate(Location.Projects);
    };

    const onClickHome = () => {
      dispatch(analyticsEvents.navigate({ source: "leftNav", value: "home" }));
      navigate(Location.Home);
    };

    const onClickShowBrandKit = () => {
      if (onShowBrandKit) {
        onShowBrandKit(!showBrandKit);
      }
    };
    const onClickShowMediaLibrary = () => {
      openModal(ModalName.mediaLibraryModal, { mainMode: true });
    };

    const onClickAddChannelsModal = () => {
      if (!isAuthenticated) {
        navigate(Location.Home);
        return;
      }
      setCreateChannelsModalVisible(true);
    };

    const items: SidebarItem[] = useMemo(() => {
      const initialItems = [
        {
          key: "home",
          onClick: onClickHome,
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.home
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-home"
              />
            </H1_FlexRow>
          ),
          title: formatMessage(leftNavMessages.home),
          dataAutoId: "left-nav-home"
        },
        {
          key: "projects",
          onClick: onClickProjects,
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.projects
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-video"
              />
            </H1_FlexRow>
          ),
          title: formatMessage(leftNavMessages.projects),
          dataAutoId: "left-nav-projects"
        },
        {
          key: "templates",
          onClick: onClickTemplates,
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.templates
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-table-layout"
              />
            </H1_FlexRow>
          ),
          title: formatMessage(leftNavMessages.templates),
          dataAutoId: "left-nav-templates"
        },
        {
          onClick: onClickAvatars,
          key: "avatars",
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.avatars
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-user"
              />
            </H1_FlexRow>
          ),
          title: formatMessage(leftNavMessages.avatars),
          dataAutoId: "left-nav-presenters"
        },
        {
          key: "workflows",
          onClick: onClickWorkflows,
          title: formatMessage(leftNavMessages.workflows),
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.workflows
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-cube"
              />
            </H1_FlexRow>
          ),
          endContent: (
            <Chip size="sm" variant="flat">
              {formatMessage(leftNavMessages.beta)}
            </Chip>
          ),
          dataAutoId: "left-nav-workflows"
        },
        {
          onClick: onClickShowBrandKit,
          key: "brandHub",
          title: formatMessage(leftNavMessages.brandHub),
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.brandHub
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-swatchbook"
              />
            </H1_FlexRow>
          ),
          dataAutoId: "left-nav-brandkit-hub"
        },
        flags[FeatureFlag.mediaSideBar] && {
          onClick: onClickShowMediaLibrary,
          key: "media",
          title: formatMessage(leftNavMessages.mediaLibrary),
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  isMediaOpened
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-image"
              />
            </H1_FlexRow>
          ),
          dataAutoId: "left-nav-media"
        },
        isPlaylistAvailable && {
          key: "playlists",
          onClick: onClickPlaylists,
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.playlists
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "3"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-light fa-list-music"
              />
            </H1_FlexRow>
          ),
          title: formatMessage(leftNavMessages.playlists)
        },
        isCGNAvailable && {
          key: "cgn",
          onClick: onClickCGN,
          startContent: (
            <H1_FlexRow flex="0 0 16px">
              <H1_Icon
                className={
                  selectedPage === CurrentPage.cgn
                    ? `font-normal text-small text-gray-${
                        theme.mode === ThemeMode.Light ? "7" : "6"
                      }`
                    : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
                }
                isCursorPointer
                icon="fa-sharp fa-light fa-newspaper"
              />
            </H1_FlexRow>
          ),
          title: formatMessage(leftNavMessages.cgn)
        }
      ];

      const trashItem = {
        key: "trash",
        onClick: onClickTrash,
        startContent: (
          <H1_FlexRow flex="0 0 16px">
            <H1_Icon
              className={
                selectedPage === CurrentPage.trash
                  ? `font-normal text-small text-gray-${theme.mode === ThemeMode.Light ? "7" : "3"}`
                  : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
              }
              isCursorPointer
              icon="fa-light fa-trash"
            />
          </H1_FlexRow>
        ),
        title: formatMessage(leftNavMessages.trash)
      };
      const briefItem = {
        key: "briefs",
        onClick: onClickBriefs,
        startContent: (
          <H1_FlexRow flex="0 0 16px">
            <H1_Icon
              className={
                selectedPage === CurrentPage.briefs
                  ? `font-normal text-small text-gray-${theme.mode === ThemeMode.Light ? "7" : "3"}`
                  : `text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}`
              }
              isCursorPointer
              icon="fa-light fa-paper-plane-top"
            />
          </H1_FlexRow>
        ),
        title: formatMessage(leftNavMessages.briefs)
      };

      const myVideosItem = {
        key: "recent-videos",
        startContent: (
          <ChannelFlexRow flex="0 0 16px">
            <H1_Icon
              className={`text-gray-${theme.mode === ThemeMode.Light ? "7" : "3"}`}
              isCursorPointer
              icon="fa-light fa-video"
            />
          </ChannelFlexRow>
        ),
        title: formatMessage(leftNavChannelsMessages.recent),
        onClick: onClickRecent
      };
      const channelsItems = {
        key: "channels",
        title: formatMessage(leftNavChannelsMessages.channels),
        type: SidebarItemType.Nest,
        items:
          channels && isAuthenticated
            ? ([
                {
                  key: "add-channel-button",
                  startContent: (
                    <ChannelFlexRow flex="0 0 16px">
                      <H1_Icon
                        className={`text-gray-${theme.mode === ThemeMode.Light ? "7" : "3"}`}
                        isCursorPointer
                        icon="fa-light fa-add"
                      />
                    </ChannelFlexRow>
                  ),
                  title: (
                    <H1_TextSmall
                      color={theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray6}
                    >
                      {formatMessage(leftNavChannelsMessages.addChannels)}
                    </H1_TextSmall>
                  ),
                  onClick: onClickAddChannelsModal
                },
                ...channels.map((channel: Channel, index) => ({
                  key: channel.name + index,
                  startContent: (
                    <ChannelFlexRow flex="0 0 16px">
                      <H1_Icon
                        className={`text-gray-${theme.mode === ThemeMode.Light ? "7" : "3"}`}
                        isCursorPointer
                        icon="fa-light fa-hashtag"
                      />
                    </ChannelFlexRow>
                  ),
                  title: channel.name,
                  isRemovable: true,
                  onClick: () => onClickChannel(channel.name)
                }))
              ] as SidebarItem[])
            : []
      };

      const finalItems = [...initialItems];
      if (flags[FeatureFlag.trash]) {
        finalItems.push(trashItem);
      }

      if (channelsItems.items.length > 0 && flags[FeatureFlag.recentVideoFeed]) {
        const [firstItem, ...restOfList] = channelsItems.items;
        channelsItems.items = [firstItem, myVideosItem, ...restOfList];
      }
      if (isFirstVideoCreated) {
        if (flags[FeatureFlag.briefsPage] && isEnterprise) {
          finalItems.push(briefItem, channelsItems);
        } else {
          finalItems.push(channelsItems);
        }
      }
      return finalItems;
    }, [
      theme,
      hoverItemKey,
      showBrandKit,
      createChannelsModalVisible,
      selectedPage,
      isAuthenticated,
      isFirstVideoCreated,
      isEnterprise
    ]);

    const renderNestItem = React.useCallback(
      (item: SidebarItem) => {
        const isNestType =
          item.items && item.items?.length > 0 && item?.type === SidebarItemType.Nest;

        if (isNestType) {
          // Is a nest type item , so we need to remove the href
          delete item.href;
        }

        return (
          <ListboxItem
            {...item}
            key={item.key}
            classNames={{
              base: cn({
                "h-auto p-0": isNestType,
                [`data-[hover=true]:bg-gray-3`]: theme.mode === ThemeMode.Light,
                [`data-[selected=true]:text-gray-2 data-[hover=true]:bg-gray-10`]:
                  theme.mode !== ThemeMode.Light
              })
            }}
            endContent={isNestType ? null : item.endContent ?? null}
            startContent={item.startContent}
            title={isNestType ? null : item.title}
          >
            {isNestType ? (
              <Accordion
                className={"p-0"}
                itemClasses={{
                  title: "font-normal text-small text-default-500",
                  content: "py-0"
                }}
              >
                <AccordionItem
                  key={item.key}
                  aria-label={item.title}
                  classNames={{
                    heading: "px-3 py-2.5",
                    trigger: "p-0"
                  }}
                  title={item.title ?? null}
                >
                  {item.items && item.items?.length > 0 ? (
                    <Listbox
                      items={item.items}
                      variant="flat"
                      classNames={{ base: "pt-0" }}
                      itemClasses={{
                        base: cn(
                          `px-3 rounded-large h-[40px] data-[hover=true]:bg-gray-${
                            theme.mode === ThemeMode.Light ? "3" : "10"
                          } data-[selected=true]:bg-gray-${
                            theme.mode === ThemeMode.Light ? "4" : "9"
                          } data-[selectable=true]:text-gray-${
                            theme.mode === ThemeMode.Light ? "9" : "2"
                          } data-[selectable=true]:focus:bg-gray-${
                            theme.mode === ThemeMode.Light ? "5" : "9"
                          } data-[hover=true]:text-gray-${
                            theme.mode === ThemeMode.Light ? "7" : "5"
                          }`
                        ),
                        title: cn(`text-small`)
                      }}
                    >
                      {item.items.map(renderItem)}
                    </Listbox>
                  ) : (
                    renderItem(item)
                  )}
                </AccordionItem>
              </Accordion>
            ) : null}
          </ListboxItem>
        );
      },
      [items]
    );

    const renderItem = React.useCallback(
      (item: SidebarItem) => {
        const isNestType =
          item && item.items && item.items?.length > 0 && item?.type === SidebarItemType.Nest;

        if (isNestType) {
          return renderNestItem(item);
        }

        return (
          <ListboxItem
            {...item}
            key={item.key}
            onMouseEnter={() => onMouseEnter(item.key)}
            onMouseLeave={onMouseLeave}
            endContent={
              item.isRemovable ? (
                <HoverButton
                  variant="light"
                  $visible={item.key === hoverItemKey}
                  onClick={(e) => onRemoveChannel(e, item.title)}
                  isIconOnly
                >
                  <i className="fa-solid fa-minus text-gray-6 hover:text-blue-4" />
                </HoverButton>
              ) : item.endContent ? (
                item.endContent
              ) : null
            }
            startContent={item.startContent}
            textValue={item.title}
            title={item.title}
            data-auto-id={item.dataAutoId}
          ></ListboxItem>
        );
      },
      [hoverItemKey]
    );

    return (
      <>
        <AddChannelModal
          visible={createChannelsModalVisible}
          onFinish={onFinishCreateChannel}
          onClose={onCloseCreateChannel}
        />
        <Listbox
          key="default"
          ref={ref}
          hideSelectedIcon
          as="nav"
          className={cn("list-none", className)}
          classNames={{
            list: cn("items-center")
          }}
          color="default"
          itemClasses={{
            base: cn(
              `px-3 rounded-large h-[40px] data-[hover=true]:bg-gray-${
                theme.mode === ThemeMode.Light ? "3" : "9"
              } data-[selected=true]:bg-gray-${
                theme.mode === ThemeMode.Light ? "4" : "8"
              } data-[selectable=true]:focus:text-gray-${theme.mode === ThemeMode.Light ? "7" : "6"}
               data-[selectable=true]:text-gray-${
                 theme.mode === ThemeMode.Light ? "7" : "6"
               } data-[selected=true]:text-gray-${theme.mode === ThemeMode.Light ? "7" : "3"}
               data-[selectable=true]:focus:bg-gray-${theme.mode === ThemeMode.Light ? "9" : "5"}`
            ),
            title: cn(`text-small`)
          }}
          items={items}
          selectedKeys={[selectedPage] as unknown as Selection}
          selectionMode="single"
          variant="flat"
          onSelectionChange={(keys: Selection) => {
            const key = Array.from(keys)[0];
            onSelectedPage(key as CurrentPage);
          }}
          {...props}
        >
          {(item: any) => {
            return item.items && item.items?.length > 0 && item?.type === SidebarItemType.Nest ? (
              renderNestItem(item)
            ) : item.items && item.items?.length > 0 ? (
              <ListboxSection
                key={item.key}
                title={item.title}
                onClick={item.onClick}
                data-auto-id={item.dataAutoId}
                classNames={{
                  base: `py-0 px-3 rounded-large h-[40px] data-[hover=true]:bg-gray-${
                    theme.mode === ThemeMode.Light ? "3" : "10"
                  } data-[selected=true]:bg-gray-${
                    theme.mode === ThemeMode.Light ? "4" : "9"
                  } data-[selectable=true]:focus:bg-gray-${
                    theme.mode === ThemeMode.Light ? "5" : "9"
                  }`
                }}
              >
                {item.items.map(renderItem)}
              </ListboxSection>
            ) : (
              renderItem(item)
            );
          }}
        </Listbox>
      </>
    );
  }
);

SideBar.displayName = "SideBar";

export default SideBar;
