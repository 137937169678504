import { VideoFeedbackReaction, VideoReactions } from "app/types/reactions";
import { SupportedSubtitlesFileExtension } from "app/types/services";

export enum VideoStatusEnum {
  Ready = "ready",
  Published = "published",
  NotStarted = "not_started",
  Processing = "processing",
  Failed = "failed",
  Canceled = "cancelled"
}

export enum ProtectionType {
  Password = "password",
  None = "none"
}

export interface PasswordProtectionPayload {
  password: string;
}

export interface Video {
  animated_thumbnail?: string;
  datastore_content_id?: string;
  id: string;
  draft_version: string;
  versioned_draft_id: string;
  draft_id: string;
  created_at: string;
  created_by: string;
  created_by_name?: string;
  created_by_picture?: string;
  pipeline_response: {
    req_id: string;
    uri: string;
  };
  // todo pipeline_request does not exist on the response right now. and its heavy - what todo with flavor_id
  pipeline_request?: {
    // todo verify that we have it
    flavor_id: string;
  };
  draft_info?: {
    title: string;
  };
  status?: VideoStatusEnum;
  req_id: string;
  link_availability?: boolean;
  progress?: number;
  thumbnail_url?: string;
  duration?: number;
  tags?: string[];
  aspect_ratio: { width: number; height: number };
  reactions: VideoReactions[];
  views?: number;
  viewers?: number;
  analytics_updated_at?: string;
  watch_time?: number;
  remixed?: number;
  paid?: boolean;
  playback_id?: string;
  title?: string;
  feedbacked?: VideoFeedbackReaction;
  protected?: ProtectionType;
  type?: VideoType;
}

export enum VideoResolution {
  "720p" = "720p",
  "1080p" = "1080p"
}

export enum MediaFileType {
  mp4 = "mp4",
  mov = "mov",
  webm = "webm"
}

export enum Audience {
  private = "private",
  public = "public"
}

export interface VideoSubtitleResult {
  url: string;
}

export interface TranslateSubtitlesResponse {
  url: string;
  language_code: string;
  display_name: string;
}

export interface TranslateSubtitlesRequest {
  language_code: string;
  file_extension: SupportedSubtitlesFileExtension;
}

export interface VideoVariable {
  value: string;
  variable_id: string;
}

export enum VideoType {
  editor = "editor",
  playlist = "playlist"
}
