/* eslint @typescript-eslint/naming-convention: 0 */
import React, { ForwardedRef, ReactNode } from "react";
import styled from "styled-components";
import type { H1_InputSize } from "app/components/_Infrastructure/design-system/input";
import { H1_TextNames, H1_TextSizes } from "app/components/_Infrastructure/Typography";

const sizeStyles: Record<H1_InputSize, any> = {
  small: {
    padding: "0 0.5rem",
    fontSize: H1_TextSizes[H1_TextNames.small].fontSize,
    lineHeight: H1_TextSizes[H1_TextNames.small].lineHeight
  },
  default: {
    padding: "0.3125rem 0.75rem",
    fontSize: H1_TextSizes[H1_TextNames.small].fontSize,
    lineHeight: H1_TextSizes[H1_TextNames.small].lineHeight
  },
  large: {
    padding: "0.5rem 0.75rem",
    fontSize: H1_TextSizes[H1_TextNames.middle].fontSize,
    lineHeight: H1_TextSizes[H1_TextNames.middle].lineHeight
  }
};

const StyledTextarea = styled.textarea<{
  resize: string;
  size: H1_InputSize;
  $width?: string;
  $height?: string;
  withBoxShadow: boolean;
}>`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  outline: none !important;
  border-radius: 2px;
  resize: ${(props) => props.resize};
  color: ${(props) => props.theme.gray9};
  background-color: ${(props) => props.theme.gray1};
  border: 1px solid ${(props) => props.theme.gray5};
  padding: ${(props) => sizeStyles[props.size].padding};
  font-size: ${(props) => sizeStyles[props.size].fontSize};
  line-height: ${(props) => sizeStyles[props.size].lineHeight};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  &:hover {
    border: 1px solid ${(props) => props.theme.blue2};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.blue4};
    box-shadow: ${(props) => (props.withBoxShadow ? "0 0 4px rgba(24, 144, 255, 0.5)" : "none")};
  }
  &:disabled {
    color: ${(props) => props.theme.gray6};
    background-color: ${(props) => props.theme.gray3};
    border: 1px solid ${(props) => props.theme.gray5};
  }
`;

export type H1_TextareaProps = {
  size?: H1_InputSize;
  disabled?: boolean;
  withBoxShadow?: boolean;
  resize?: string;
  width?: string;
  height?: string;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  children?: ReactNode;
};

export const H1_Textarea = React.forwardRef(
  (
    {
      className = undefined,
      width,
      height,
      size = "default",
      disabled = false,
      withBoxShadow = true,
      resize = "none",
      defaultValue,
      value,
      placeholder,
      onChange,
      onBlur,
      children,
      ...props
    }: H1_TextareaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const onChangeFunc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e.target.value);
      }
    };
    const onBlurFunc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        onBlur(e.target.value);
      }
    };
    return (
      <StyledTextarea
        className={className}
        resize={resize}
        ref={ref}
        size={size}
        disabled={disabled}
        withBoxShadow={withBoxShadow}
        $width={width}
        $height={height}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={onChangeFunc}
        onBlur={onBlurFunc}
        {...props}
      >
        {children}
      </StyledTextarea>
    );
  }
);

H1_Textarea.displayName = "H1_Textarea";
