import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Slider } from "antd";
import type { SliderMarks } from "antd/es/slider";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { BoostProvider } from "app/types";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const ProviderIcon = styled(H1_FlexRow)<{ $selected?: boolean }>`
  border: 2px solid ${({ $selected, theme }) => ($selected ? theme.blue4 : "transparent")};
  border-radius: 7px;
  background-color: ${({ theme }) => theme.blue1};
  i {
    font-size: 30px;
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;
  .ant-slider-rail {
    background-color: ${({ theme }) => theme.gray4};
  }
  .ant-slider-track {
    background-color: ${({ theme }) => theme.blue4};
  }
  :not(.ant-slider-disabled) {
    .ant-slider-handle {
      background-color: ${({ theme }) => theme.blue4};
      border-color: ${({ theme }) => theme.blue4};
    }
  }
  .ant-slider-step {
    .ant-slider-dot {
      display: none;
    }
  }
  .ant-slider-mark {
    .ant-slider-mark-text {
      font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
      white-space: pre;
    }
  }
  &:hover {
    .ant-slider-rail {
      background-color: ${({ theme }) => theme.gray4};
    }
    .ant-slider-track {
      background-color: ${({ theme }) => theme.blue4};
    }
    :not(.ant-slider-disabled) {
      .ant-slider-handle:not(.ant-tooltip-open) {
        background-color: ${({ theme }) => theme.blue4};
        border-color: ${({ theme }) => theme.blue4};
      }
    }
  }
`;

interface LightningModalProps {
  onFinish: (obj?: any) => void;
  visible: boolean;
  boostProperties?: { scale: number; time: number; provider: string };
}
const LightningModal = ({ visible, onFinish, boostProperties }: LightningModalProps) => {
  const [currentScale, setCurrentScale] = useState<number>(2);
  const [currentTime, setCurrentTime] = useState<number>(1);
  const [currentProvider, setCurrentProvider] = useState<BoostProvider | undefined>(undefined);

  const isAwsSelected = currentProvider === BoostProvider.aws;
  const isGcpSelected = currentProvider === BoostProvider.gcp;
  const isAzureSelected = currentProvider === BoostProvider.azure;

  useEffect(() => {
    if (visible && boostProperties) {
      setCurrentScale(boostProperties.scale);
      const adjustedValue = boostProperties.time === 4.5 ? 4 : boostProperties.time;
      setCurrentTime(adjustedValue);
      setCurrentProvider(boostProperties.provider as BoostProvider);
    }
  }, [visible]);
  const scaleMarks: SliderMarks = {
    2: "2x",
    3: "3x",
    4: "4x"
  };

  const timeMarks: SliderMarks = {
    1: "1hr",
    4.5: "4hrs",
    8: "8hrs"
  };

  const onChangeScaleSlider = (value: number) => {
    setCurrentScale(value);
  };

  const onChangeTimeSlider = (value: number) => {
    const adjustedValue = value === 4.5 ? 4 : value;
    setCurrentTime(adjustedValue);
  };

  const onClickBoost = () => {
    onFinish({ scale: currentScale, time: currentTime, provider: currentProvider });
  };

  const onCancel = () => {
    if (boostProperties) {
      setCurrentScale(boostProperties.scale);
      const adjustedValue = boostProperties.time === 4.5 ? 4 : boostProperties.time;
      setCurrentTime(adjustedValue);
      setCurrentProvider(boostProperties.provider as BoostProvider);
    }
    onFinish();
  };

  return (
    <StyledModal
      width="400px"
      open={visible}
      footer={null}
      centered
      onCancel={onCancel}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="30px"
      $overflow="hidden"
      $gap="30px"
    >
      <H1_TextSubtitle>Boost rendering performance</H1_TextSubtitle>
      <H1_FlexColumn width="100%" padding="6px 10px 0">
        <H1_TextSmall>Scale Performance</H1_TextSmall>
        <StyledSlider
          min={2}
          max={4}
          dots
          marks={scaleMarks}
          defaultValue={currentScale}
          onChange={onChangeScaleSlider}
          step={null}
          tooltip={{ formatter: null }}
        />
      </H1_FlexColumn>
      <H1_FlexColumn width="100%" padding="6px 10px 0">
        <H1_TextSmall>Boost Time</H1_TextSmall>
        <StyledSlider
          min={1}
          max={8}
          dots
          marks={timeMarks}
          defaultValue={currentTime}
          onChange={onChangeTimeSlider}
          step={null}
          tooltip={{ formatter: null }}
        />
      </H1_FlexColumn>
      <H1_FlexRow gap="10px" justify="center">
        <ProviderIcon
          $selected={isAwsSelected}
          width="100px"
          height="60px"
          justify="center"
          align="center"
          onClick={() => setCurrentProvider(BoostProvider.aws)}
        >
          <i className="fa-brands fa-aws" />
        </ProviderIcon>
        <ProviderIcon
          $selected={isAzureSelected}
          width="100px"
          height="60px"
          justify="center"
          align="center"
          onClick={() => setCurrentProvider(BoostProvider.azure)}
        >
          <img
            width="40px"
            height="40px"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/icons-azure.svg"
            alt=""
          />
        </ProviderIcon>
        <ProviderIcon
          $selected={isGcpSelected}
          width="100px"
          height="60px"
          justify="center"
          align="center"
          onClick={() => setCurrentProvider(BoostProvider.gcp)}
        >
          <img
            width="40px"
            height="40px"
            src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/cloud-google-icon.svg"
            alt=""
          />
        </ProviderIcon>
      </H1_FlexRow>
      <H1_FlexRow justify="center">
        <Button fullWidth color="primary" onClick={onClickBoost}>
          Boost
        </Button>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default LightningModal;
