import styled, { useTheme } from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { charactersGlobalSelectors } from "app/store/adapters/adapters";
import { Character, CharacterDictionary } from "app/types/character";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMidHeadline, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { presentersPageMessages } from "app/pages/presenters/messages";
import { useIntl } from "react-intl";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { draftsActions } from "app/store/slices/drafts.slice";
import { Draft, Location } from "app/types";
import { fetchingStatus } from "app/utils/helpers";
import { useNavigate } from "react-router-dom";
import { languageText } from "app/utils/languageText";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const StyledVideo = styled.video`
  max-height: 289px;
  height: 100%;
`;
const GrayFlexRow = styled(H1_FlexRow)<{ $selected: boolean }>`
  background-color: ${({ theme, $selected }) => ($selected ? theme.blue4 : theme.gray4)};
  color: ${({ theme, $selected }) => ($selected ? theme.gray1 : theme.gray11)};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: brightness(0.8);
  }
`;
const VideoBackgroundFlexRow = styled(H1_FlexRow)`
  background-color: ${({ theme }) => theme.gray3};
`;
interface PresenterModalProps {
  characterId: string | undefined;
  visible: boolean;
  onCloseModal: () => void;
}
const PresenterModal = ({ characterId, visible, onCloseModal }: PresenterModalProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<CharacterDictionary | undefined>(
    undefined
  );
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const character = useAppSelector((state) =>
    charactersGlobalSelectors.selectById(state, characterId || "")
  ) as Character;
  const createDraftStatus = useAppSelector((state) => state.drafts.createDraftStatus);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const isLoading = createDraftStatus === fetchingStatus.loading;

  useEffect(() => {
    if (createDraftStatus === fetchingStatus.succeeded && currentDraft) {
      dispatch(draftsActions.setCreateDraftStatusToIdle());
      dispatch(analyticsEvents.navigateToEditor({ source: "avatar_modal" }));
      navigate(`${Location.Editor}/${currentDraft.id}`);
    }
  }, [createDraftStatus, currentDraft]);

  const onCreateDraft = () => {
    dispatch(
      analyticsEvents.createPresenterDraft({
        characterId: character.character_id as string,
        characterTitle: character.title
      })
    );
    const newDraft: Draft = {
      character_id: character.character_id,
      type: "virtual_avatar"
    };

    dispatch(draftsActions.createDraftRequest({ draft: newDraft }));
  };

  const onSelectLanguage = (language: CharacterDictionary) => {
    const languageValue = language.local === selectedLanguage?.local ? undefined : language;
    dispatch(
      analyticsEvents.selectPresenterLanguage({
        characterId: characterId as string,
        characterTitle: character.title,
        language: languageValue?.local
      })
    );
    setSelectedLanguage(languageValue);
  };

  if (!characterId || !character) {
    return null;
  }

  return (
    <StyledModal
      width="652px"
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: "569px" }}
      onCancel={onCloseModal}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="60px 60px 55px"
      $gap="18px"
    >
      <VideoBackgroundFlexRow justify="center" align="flex-end" height="289px">
        <StyledVideo
          src={
            selectedLanguage
              ? selectedLanguage.url
              : character?.thumbnails?.modal?.video || character?.video
          }
          autoPlay
          loop
        />
      </VideoBackgroundFlexRow>
      <H1_FlexColumn>
        <H1_TextMidHeadline>{character?.title}</H1_TextMidHeadline>
        <H1_TextXs color={theme.gray7}>{character?.description}</H1_TextXs>
      </H1_FlexColumn>
      <H1_FlexRow height="40px" align="center" gap="12px">
        {character?.dictionary?.map((dictionary: CharacterDictionary) => (
          <GrayFlexRow
            $selected={dictionary.local === selectedLanguage?.local}
            padding="10px"
            gap="9px"
            key={dictionary.local}
            onClick={() => onSelectLanguage(dictionary)}
          >
            <img height={20} alt="" src={`https://flagcdn.com/${dictionary.local}.svg`} />
            <H1_TextXs>{languageText(dictionary.local, intl)}</H1_TextXs>
          </GrayFlexRow>
        ))}
      </H1_FlexRow>
      <H1_FlexRow justify="flex-end">
        <Button color="primary" isLoading={isLoading} onClick={onCreateDraft}>
          {intl.formatMessage(presentersPageMessages.createVideoWithCharacter)}
        </Button>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default PresenterModal;
