/* eslint-disable  camelcase */
import { PlanEnum } from "app/types/userData";

export const VoiceAll = "all";

export enum CloneVoiceStatus {
  done = "done",
  processing = "processing",
  failed = "failed"
}
export enum VoiceType {
  premade = "premade",
  cloned = "cloned"
}
export interface Voice {
  favorite: boolean;
  tone: string[];
  use_case: string[];
  age: string;
  id: string;
  gender?: Gender;
  display_name: string;
  local: string;
  sample_url: string;
  provider: string;
  plans: PlanEnum[];
  description?: string;
  wpm?: number;
  clone_status: CloneVoiceStatus;
  req_id: string;
  order_id: string;
  type: VoiceType;
  pause_supported: boolean;
  tags: string[];
}

export interface CustomVoice extends Voice {
  image: string;
  url: string;
}

export interface CloneVoiceEvent {
  new_id: string;
  clone_status: CloneVoiceStatus;
  order_id: string;
  req_id: string;
  sample_url: string;
}

export enum Gender {
  all = "all",
  male = "male",
  female = "female",
  notGiven = "not_given"
}

export enum VoiceFiltersProperties {
  age = "age",
  useCase = "use_case",
  tone = "tone",
  pause = "pause"
}

export interface VoiceFilters {
  age: string;
  useCase: string;
  tone: string;
  pause: boolean;
}

export const defaultVoiceFilter = {
  age: VoiceAll,
  tone: VoiceAll,
  useCase: VoiceAll,
  pause: false
};

export enum VoiceSpeechAudioAction {
  tempo = "tempo",
  pitch_shift = "pitch_shift",
  add_silence = "add_silence"
}

export enum VoiceProvider {
  ElevenLabs = "elevenLabs",
  respeecher = "respeecher"
}
