import { useEffect, useState } from "react";

interface useVideoProps {
  videoNode: HTMLVideoElement | undefined;
  visible: boolean;
  isAutoplay?: boolean;
  isDefaultMuted?: boolean;
}

const useVideo = ({
  videoNode,
  visible,
  isAutoplay = true,
  isDefaultMuted = true
}: useVideoProps) => {
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(isAutoplay);
  const [isMuted, setIsMuted] = useState(isDefaultMuted);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (visible && videoNode) {
      videoNode.addEventListener("timeupdate", handleCurrentTimeUpdate);
      videoNode.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoNode.addEventListener("canplay", handleOnLoadedVideo);
      videoNode.addEventListener("ended", handleOnEndedVideo);
    }
    return () => {
      if (videoNode) {
        videoNode.removeEventListener("timeupdate", handleCurrentTimeUpdate);
        videoNode.removeEventListener("loadedmetadata", handleLoadedMetadata);
        videoNode.removeEventListener("canplay", handleLoadedMetadata);
        videoNode.removeEventListener("ended", handleOnEndedVideo);
      }
    };
  }, [visible, videoNode]);

  const handleCurrentTimeUpdate = () => {
    setCurrentTime(videoNode?.currentTime || 0);
  };

  const handleLoadedMetadata = () => {
    setDuration(videoNode?.duration || 0);
  };

  const handleOnLoadedVideo = () => {
    onVideoLoaded();
  };

  const handleOnEndedVideo = () => {
    setIsPlaying(false);
  };

  const onVideoLoaded = () => {
    const aspectRatio = (videoNode?.videoWidth || 1) / (videoNode?.videoHeight || 1);
    setAspectRatio(aspectRatio);
    setVideoLoaded(true);
  };

  const handlePlayPause = () => {
    if (videoNode?.paused) {
      videoNode?.play();
      setIsPlaying(true);
    } else {
      videoNode?.pause();
      setIsPlaying(false);
    }
  };

  const handleMuteUnmute = () => {
    if (videoNode) {
      if (videoNode.muted) {
        videoNode.muted = false;
        setIsMuted(false);
      } else {
        videoNode.muted = true;
        setIsMuted(true);
      }
    }
  };

  const handleTimeUpdate = () => {
    if (videoNode) {
      const { currentTime, duration } = videoNode;
      const progress = (currentTime / duration) * 100 || 0;
      setProgress(progress);
    }
  };

  const reset = () => {
    setIsPlaying(isAutoplay);
    setIsMuted(isDefaultMuted);
    setVideoLoaded(false);
    setAspectRatio(0);
    setCurrentTime(0);
    setDuration(0);
    setProgress(0);
    if (videoNode) {
      videoNode.muted = isDefaultMuted;
    }
  };

  return {
    reset,
    videoLoaded,
    handleTimeUpdate,
    handleMuteUnmute,
    handlePlayPause,
    isPlaying,
    isMuted,
    currentTime,
    duration,
    aspectRatio,
    progress
  };
};

export default useVideo;
