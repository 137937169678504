import styled from "styled-components";
import { Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DEFAULT_TABLE_PAGE_SIZE, Folder } from "app/types";
import React, { useMemo } from "react";
import messages from "app/components/projects-managment/DraftsView/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { dateSorter, getFormattedDate, getTimeAgo, hasSomeParentTheClass } from "app/utils/helpers";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import FolderItemDropdown from "app/components/common/DraftsAndFolders/FolderItemDropdown";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import { useAppSelector } from "app/hooks";
import * as foldersSelectors from "app/store/selectorsV2/folders.selectors";
import EmptyState from "app/components/common/EmptyState";
import ConditionalRender from "app/components/common/ConditionalRender";

const StyledTable = styled(Table)`
  position: relative;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  margin-top: 0.3125rem;
  .ant-table {
    background-color: transparent;
  }
  && .ant-table-thead > tr > th {
    border-bottom: 1px solid ${(props) => props.theme.gray5};
    background-color: transparent;
    padding-left: 0;
    position: sticky;
    top: 0;
    background-color: ${(props) => props.theme.gray1};
    z-index: 11;
  }
  .ant-table table {
    border-spacing: 0 0.625rem;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0.5625rem 0.5rem;
    &.ant-table-cell-row-hover {
      background-color: ${(props) => props.theme.gray2};
    }
  }
  & .ant-table-column-sorters {
    flex-direction: row-reverse;
    gap: 0.3125rem;
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
        transition: all 0.5s ease-in-out;
        background-color: transparent;
        height: 5.3125rem;
      }
      &:hover {
        td {
          background-color: ${(props) =>
            props.theme.gray3} !important; /* &&& doesn't work with &:hover */
          ${NameText} {
            text-decoration: underline;
          }
        }
      }
      padding: 0.625rem 1rem 0.625rem;
      &:not(:last-child) td {
        border-bottom: 1px solid ${(props) => props.theme.gray4};
      }
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

const StyledIcon = styled.i`
  font-size: 1.125rem;
  height: 2rem;
  line-height: 2rem;
  margin: auto;
  width: 100%;
  &&& {
    transform: translate(50%);
  }
  color: ${({ theme }) => theme.gray8};
  &:hover {
    color: ${({ theme }) => theme.blue4};
  }
`;

const FolderIcon = styled.i`
  font-size: 1.625rem;
  color: white;
`;

const FolderIconContainerFlexRow = styled(H1_FlexRow)`
  background-color: ${(props) => props.theme.blue1};
  border-radius: 3px;
`;

interface FoldersTableProps {
  pagination?: false | TablePaginationConfig;
  onCreateFolder: () => void;
}

const FoldersTable = ({ pagination = false, onCreateFolder }: FoldersTableProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { filterFolders: folders } = useAppSelector((state) =>
    foldersSelectors.getFoldersByPageAndQuery(state)
  );
  // Todo - Remove once server implementation is made
  const totalFolders = folders.length;
  const withPagination = totalFolders > DEFAULT_TABLE_PAGE_SIZE;

  const onSelectFolder = (id: string) => {
    navigate(`/folders/${id}`);
  };

  const columns: ColumnsType<Folder> = useMemo(
    () => [
      {
        title: formatMessage(messages.tableColTitleTitle),
        dataIndex: "title",
        key: "title",
        render: (_: unknown, row: Folder) => {
          return (
            <H1_FlexRow gap="1.625rem" align="center">
              <FolderIconContainerFlexRow
                width="7rem"
                height="3.9375rem"
                align="center"
                justify="center"
              >
                <FolderIcon className="fa-solid fa-folder" />
              </FolderIconContainerFlexRow>
              <NameText $maxWidth="22.5rem" ellipsis={{ tooltip: row.name }}>
                {row.name}
              </NameText>
            </H1_FlexRow>
          );
        },
        sorter: (a: Folder, b: Folder) => (a.name as string).localeCompare(b.name as string)
      },
      {
        title: formatMessage(messages.tableColUpdatedTitle),
        dataIndex: "updated_at",
        key: "updated_at",
        width: "9.375rem",
        render: (editedAt: string) => {
          return <H1_TextSmall>{editedAt && getTimeAgo(editedAt)}</H1_TextSmall>;
        },
        sorter: (a: Folder, b: Folder) => dateSorter(a.updated_at as string, b.updated_at as string)
      },
      {
        title: formatMessage(messages.tableColCreatedTitle),
        dataIndex: "created_at",
        key: "created_at",
        width: "6.25rem",
        render: (createdDate: string) => {
          return <H1_TextSmall>{getFormattedDate(createdDate)}</H1_TextSmall>;
        }
      },
      {
        dataIndex: "",
        key: "x",
        className: "actions",
        width: "4.375rem",
        render: (_: unknown, row: Folder) => {
          return (
            <FolderItemDropdown
              folderItem={row}
              icon={<StyledIcon className="fa-solid fa-ellipsis" />}
            />
          );
        }
      }
    ],
    [folders]
  );

  return (
    <>
      <ConditionalRender condition={folders.length === 0}>
        <EmptyState
          text={formatMessage(messages.emptyFoldersText)}
          description={formatMessage(messages.emptyFoldersDescription)}
          withButton
          buttonText={formatMessage(messages.emptyFoldersButtonText)}
          icon="fal fa-folder"
          onClickButton={onCreateFolder}
        />
      </ConditionalRender>
      <ConditionalRender condition={folders.length > 0}>
        <StyledTable
          size="middle"
          columns={columns}
          rowKey="id"
          pagination={withPagination ? pagination : false}
          dataSource={folders}
          rowClassName={(record, index) => `videos-folder-row-${index}`}
          onRow={(record: Folder): React.HTMLAttributes<HTMLElement> => ({
            onClick: (event: React.MouseEvent<HTMLElement>) => {
              if (
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "draft-actions-menu") &&
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "ant-dropdown-trigger") &&
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "ant-dropdown")
              ) {
                onSelectFolder(record.id as string);
              }
            }
          })}
        />
      </ConditionalRender>
    </>
  );
};

export default FoldersTable;
