import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Table } from "antd";
import styled, { useTheme } from "styled-components";
import { useIntl } from "react-intl";
import { manageTeamMessages } from "app/pages/Teams/messages";
import { avatarColorsList, Location, Member, MemberStatus, WorkspaceRole } from "app/types";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import ConditionalRender from "app/components/common/ConditionalRender";
import { fetchingStatus } from "app/utils/helpers";
import { useAuth } from "app/auth/useAuth";
import MembersPageTeamName from "app/pages/Teams/MembersPageTeamName";
import InviteMembersButton from "app/components/teams/InviteMembersButton";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import usePermissions from "app/hooks/usePermissions";
import useSmallScreen from "app/hooks/useSmallScreen";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button, Input } from "@nextui-org/react";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import useConfirmModal from "app/hooks/useConfirmModal";
import { ThemeMode } from "app/utils/theme";

const BackgroundFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray12)};
`;
const StyledSearchInput = styled(Input)`
  width: 500px;
  align-self: center;
  && div[data-slot="input-wrapper"] {
    background-color: ${({ theme }) => theme.gray2};
    &:hover,
    &:focus-visible {
      background-color: ${({ theme }) => theme.gray3};
    }
    input {
      color: ${({ theme }) => theme.gray11};
    }
    span {
      color: ${({ theme }) => theme.gray11};
    }
  }
`;

const InviteIcon = styled.i`
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
    filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.4));
  }
  &:active {
    transform: scale(0.8);
  }
`;

const Icon = styled.i`
  margin-left: 10px;
  height: 22px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  font-size: 16px;
  color: ${({ theme }) => theme.gray11};
`;

const StyledTable = styled(Table)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  margin-top: 0.3125rem;
  .ant-table {
    background-color: ${(props) =>
      props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray2};
  }
  && .ant-table-thead > tr > th {
    border-bottom: 1px solid ${(props) => props.theme.gray5};
    padding-left: 5px;
    position: sticky;
    top: 0;
    background-color: ${(props) =>
      props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray2};
    z-index: 11;
  }
  .ant-table table {
    border-spacing: 0 0.625rem;
  }
  .ant-checkbox {
    border-spacing: 0;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0.5625rem 0.5rem;
    &.ant-table-cell-row-hover {
      background-color: ${(props) =>
        props.theme.mode === ThemeMode.Light ? props.theme.gray2 : props.theme.gray3};
    }
  }
  & .ant-table-column-sorters {
    flex-direction: row-reverse;
    gap: 0.3125rem;
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
        transition: all 0.5s ease-in-out;
        background-color: ${(props) =>
          props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray2};
        height: 5.3125rem;
      }
      &:hover {
        td {
          background-color: ${(props) =>
            props.theme.gray3} !important; /* &&& doesn't work with &:hover */
          ${NameText} {
            text-decoration: underline;
            font-weight: 400;
          }
        }
      }
      padding: 0.625rem 1rem 0.625rem;
      &:not(:last-child) td {
        border-bottom: 1px solid ${(props) => props.theme.gray4};
      }
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
  &&& tr > td,
  &&& tr > th {
    padding-left: 46px;
  }
  &&& tr > td:last-child,
  &&& tr > th:last-child {
    padding-right: 46px;
  }
  tr:nth-child(2n) td {
    background-color: ${({ theme }) => theme.gray3};
  }
`;

const EnlargedCircleLoader = styled(CircleLoader)`
  transform: scale(2);
`;

const StyledDropdown = styled(NextDropdown)<{ $noLeftPadding: boolean }>`
  padding-left: ${({ $noLeftPadding }) => ($noLeftPadding ? 0 : "1rem")};
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${(props: { $color: string }) => props.$color};
  flex: 0 0 auto;
  span {
    color: ${(props) => props.theme.gray11};
  }
`;

const BWCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px dashed ${({ theme }) => theme.gray7};
  box-sizing: border-box;
  background-color: ${(props) =>
    props.theme.mode === ThemeMode.Light ? props.theme.gray1 : props.theme.gray2};
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.gray7};
  }
`;

const MembersPage = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredMembers, setFilterMembers] = useState<Member[]>([]);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { openConfirmModal, closeConfirmModal } = useConfirmModal();
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isSmallScreen } = useSmallScreen();

  const { isAllowChangeRoleWorkspace } = usePermissions();
  const removeMembersStatus = useAppSelector((state) => state.workspaces.removeMembersStatus);
  const changeWorkspaceRoleStatus = useAppSelector(
    (state) => state.workspaces.changeWorkspaceRoleStatus
  );
  const changeWorkspaceRoleUserId = useAppSelector(
    (state) => state.workspaces.changeWorkspaceRoleUserId
  );
  const preferences = useAppSelector((state) => state.user.preferences);
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const updateWorkspaceNameStatus = useAppSelector(
    (state) => state.workspaces.updateWorkspaceNameStatus
  );

  const getWorkspaceByIdStatus = useAppSelector((state) => state.workspaces.getWorkspaceByIdStatus);
  const isLoading = getWorkspaceByIdStatus === fetchingStatus.loading;
  const isOwner = currentWorkspace?.is_owner;
  const isDeletingMember = removeMembersStatus === fetchingStatus.loading;

  useEffect(() => {
    if (isSmallScreen) {
      navigate(Location.Home);
    }
    if (currentWorkspace) {
      dispatch(workspacesActions.getWorkspaceByIdRequest({ workspaceId: currentWorkspace.id }));
    }
  }, []);

  useEffect(() => {
    if (updateWorkspaceNameStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.updateWorkspaceNameStatusToIdle());
    }
    if (updateWorkspaceNameStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.updateWorkspaceNameStatusToIdle());
    }
  }, [updateWorkspaceNameStatus]);

  useEffect(() => {
    if (changeWorkspaceRoleStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.cleanChangeWorkspaceRole());
    }
    if (changeWorkspaceRoleStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.cleanChangeWorkspaceRole());
    }
  }, [changeWorkspaceRoleStatus]);

  useEffect(() => {
    if (removeMembersStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.updateRemoveMembersStatusToIdle());
    }
    if (removeMembersStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.updateRemoveMembersStatusToIdle());
    }
  }, [removeMembersStatus]);

  useEffect(() => {
    if (getWorkspaceByIdStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.updateGetWorkspaceByIdStatusToIdle());
    }
    if (getWorkspaceByIdStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.updateGetWorkspaceByIdStatusToIdle());
    }
  }, [getWorkspaceByIdStatus]);

  useEffect(() => {
    if (currentWorkspace) {
      setFilterMembers(currentWorkspace.members);
    }
  }, [currentWorkspace]);

  const onSearch = (value: string) => {
    const filtered = currentWorkspace?.members.filter(({ email }: Member) =>
      email?.toLowerCase().includes(value.toLowerCase())
    );
    setFilterMembers(filtered as Member[]);
  };

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    onSearch(value);
  };

  const reviewInvitation = (inviteId: string, isApproved: boolean) => {
    dispatch(workspacesActions.reviewInviteRequest({ inviteId, isApproved }));
  };

  const onRemoveMember = (member: Member) => {
    dispatch(
      analyticsEvents.removeMember({
        workspaceId: currentWorkspace?.id as string,
        workspaceName: currentWorkspace?.name as string,
        member: member.email as string
      })
    );
    dispatch(
      workspacesActions.removeMembersFromWorkspaceRequest({
        workspaceId: currentWorkspace?.id as string,
        members: [member]
      })
    );
  };

  const onChangeMemberRole = (member: Member, role: WorkspaceRole) => {
    dispatch(
      workspacesActions.changeWorkspaceRoleRequest({
        workspaceId: currentWorkspace?.id as string,
        role: role,
        userId: member.user_id as string
      })
    );
  };

  const onClickLeaveTeam = () => {
    openConfirmModal({
      title: formatMessage(manageTeamMessages.approveLeaveTeamTitle),
      content: formatMessage(manageTeamMessages.approveLeaveTeamContent),
      onConfirm: () => {
        dispatch(
          analyticsEvents.leaveTeam({
            workspaceId: currentWorkspace?.id as string,
            workspaceName: currentWorkspace?.name as string,
            userEmail: user?.email as string
          })
        );
        dispatch(
          workspacesActions.removeMembersFromWorkspaceRequest({
            workspaceId: currentWorkspace?.id as string,
            members: [{ email: user?.email as string }]
          })
        );

        const isRealMe = preferences?.show_reals_me;
        const route = isRealMe ? `/editor` : `/`;
        navigate(route);
      },
      onCancel: closeConfirmModal
    });
  };

  const columns = useMemo(() => {
    const menuItems = (record: Member) => {
      const items = [];
      if (isAllowChangeRoleWorkspace) {
        items.push({
          key: "Viewer",
          onClick: () => onChangeMemberRole(record, WorkspaceRole.viewer),
          label: <H1_TextSmall>{formatMessage(manageTeamMessages.viewerRole)}</H1_TextSmall>
        });
        items.push({
          key: "Member",
          onClick: () => onChangeMemberRole(record, WorkspaceRole.member),
          label: <H1_TextSmall>{formatMessage(manageTeamMessages.editorRole)}</H1_TextSmall>
        });
      }

      items.push({
        key: "remove-member",
        onClick: () => onRemoveMember(record),
        label: <H1_TextSmall>{formatMessage(manageTeamMessages.removeFromTeam)}</H1_TextSmall>
      });

      return items;
    };

    return [
      {
        title: "",
        dataIndex: "icon",
        key: "icon",
        width: 50,
        render: (_: unknown, record: Member, index: number) => {
          if (record.status === MemberStatus.pending || record.status === MemberStatus.in_review) {
            return (
              <BWCircle>
                <span>{record.email?.toUpperCase().charAt(0)}</span>
              </BWCircle>
            );
          }
          return (
            <StyledAvatar $color={avatarColorsList(theme)[index % avatarColorsList(theme).length]}>
              <span>{record.email?.toUpperCase().charAt(0)}</span>
            </StyledAvatar>
          );
        }
      },
      {
        title: "",
        dataIndex: "name",
        key: "name",
        width: 150,
        render: (_: unknown, record: Member) => {
          let message: string;
          switch (record.status) {
            case MemberStatus.rejected:
              message = formatMessage(manageTeamMessages.statusRejected);
              break;
            case MemberStatus.accepted:
              message = formatMessage(manageTeamMessages.statusAccepted);
              break;
            case MemberStatus.pending:
              message = formatMessage(manageTeamMessages.statusPending);
              break;
            case MemberStatus.in_review:
              message = formatMessage(manageTeamMessages.statusInvited);
              break;
            default:
              message = "";
              break;
          }
          return <H1_TextSmall>{message}</H1_TextSmall>;
        }
      },
      {
        title: "",
        dataIndex: "email",
        key: "email",
        render: (_: unknown, record: Member) => {
          return <H1_TextSmall>{record.email}</H1_TextSmall>;
        }
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        width: 100,
        render: (_: unknown, record: Member) => {
          if (record.is_owner) {
            return <H1_TextSmall>{formatMessage(manageTeamMessages.owner)}</H1_TextSmall>;
          }
          if (!isOwner) {
            return <></>;
          }
          if (record.email === user?.email) {
            return <></>;
          }
          if (record.status === MemberStatus.pending) {
            return <></>;
          }
          if (record.status === MemberStatus.in_review) {
            return (
              <H1_FlexRow padding="0 10px 0 0" gap="20px" align="center">
                <Button
                  color="primary"
                  startContent={
                    <InviteIcon
                      className="fa-solid fa-check"
                      onClick={() => reviewInvitation(record.id as string, true)}
                    />
                  }
                >
                  {formatMessage(manageTeamMessages.approveInvite)}
                </Button>
                <Button
                  color="danger"
                  startContent={
                    <InviteIcon
                      className="fa-solid fa-xmark"
                      onClick={() => reviewInvitation(record.id as string, false)}
                    />
                  }
                >
                  {formatMessage(manageTeamMessages.rejectInvite)}
                </Button>
              </H1_FlexRow>
            );
          }

          const getCurrentRoleText = (record: Member) => {
            switch (record.role) {
              case WorkspaceRole.member:
                return formatMessage(manageTeamMessages.editorRole);
              case WorkspaceRole.viewer:
                return formatMessage(manageTeamMessages.viewerRole);
            }
            return formatMessage(manageTeamMessages.editorRole);
          };

          const isCurrentRoleChanged =
            changeWorkspaceRoleStatus === fetchingStatus.loading &&
            record.user_id === changeWorkspaceRoleUserId;

          return (
            <StyledDropdown
              withWhiteBackground
              placement="bottom-start"
              items={menuItems(record)}
              key={`actions-menu-${record.email}`}
              $noLeftPadding={isCurrentRoleChanged}
            >
              <H1_FlexRow justify="space-between" align="center" flex="0 0 60px">
                <H1_FlexRow align="center">
                  <ConditionalRender condition={isCurrentRoleChanged}>
                    <EnlargedCircleLoader size="16px" />
                  </ConditionalRender>
                  <H1_TextSmall>{getCurrentRoleText(record)}</H1_TextSmall>
                </H1_FlexRow>
                <Icon className="fa-sharp fa-solid fa-caret-down" />
              </H1_FlexRow>
            </StyledDropdown>
          );
        }
      }
    ] as ColumnsType<object>;
  }, [currentWorkspace, reviewInvitation, onRemoveMember]);

  return (
    <BackgroundFlexColumn padding="20px 0 0 0" flex="1">
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!isLoading}>
        <StyledSearchInput
          isClearable
          variant="flat"
          onChange={onChangeSearchInput}
          defaultValue={searchValue}
          placeholder={formatMessage(manageTeamMessages.searchPlaceholder)}
        />
        <H1_FlexRow padding="38px 0 17px 46px" justify="space-between" align="center">
          <H1_TextSubtitle fontSize="22px" lineHeight="33px">
            {formatMessage(manageTeamMessages.manageTeam)}
          </H1_TextSubtitle>
          <ConditionalRender condition={!isOwner}>
            <H1_FlexRow padding="0 46px 0 0">
              <Button isLoading={isDeletingMember} onClick={onClickLeaveTeam}>
                {formatMessage(manageTeamMessages.leaveTeam)}
              </Button>
            </H1_FlexRow>
          </ConditionalRender>
        </H1_FlexRow>
        <H1_FlexRow padding="0 0 0 46px">
          <H1_TextSmall fontSize="13px" lineHeight="24px" color={theme.gray7}>
            {formatMessage(manageTeamMessages.teamName)}
          </H1_TextSmall>
        </H1_FlexRow>
        <MembersPageTeamName />
        <H1_FlexRow padding="10px 0 0 46px" align="center">
          <H1_TextSmall fontSize="13px" lineHeight="22px" color={theme.gray7}>
            {formatMessage(manageTeamMessages.teamMembers)}
          </H1_TextSmall>
        </H1_FlexRow>
        <StyledTable
          size="middle"
          columns={columns}
          rowKey="email"
          pagination={false}
          dataSource={filteredMembers}
        />
        <H1_FlexRow padding="20px 0 0 46px" align="center">
          <InviteMembersButton source="manage_team_invite_button" />
        </H1_FlexRow>
      </ConditionalRender>
    </BackgroundFlexColumn>
  );
};

export default MembersPage;
