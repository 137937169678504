import { createEntityAdapter } from "@reduxjs/toolkit";
import {
  BrandKit,
  DataStoreResult,
  Draft,
  Folder,
  Playlist,
  Scene,
  Video,
  Voice,
  Workspace,
  Webhook
} from "app/types";
import { RootState } from "app/store/store";
import { Character } from "app/types/character";
import { ServerFolderMedia } from "app/types/media";
import { BackgroundMusic } from "app/types/music";
import { PlayGroundResponse } from "app/types/playground";
import { Avatar } from "app/types/virtualTwin";
import { Brief } from "app/types/brief";
import { Trash } from "app/types/trash";

export const scenesAdapter = createEntityAdapter<Scene>({
  selectId: (scene) => scene.id
});

export const scenesGlobalSelectors = scenesAdapter.getSelectors<RootState>((state) => state.scenes);

export const draftsAdapter = createEntityAdapter<Draft>({
  selectId: (draft) => draft.id as string
});

export const briefsAdapter = createEntityAdapter<Brief>({
  selectId: (brief) => brief.id as string
});

export const draftsGlobalSelectors = draftsAdapter.getSelectors<RootState>((state) => state.drafts);

export const voicesAdapter = createEntityAdapter<Voice>({
  selectId: (voice) => voice.id as string
});
export const charactersAdapter = createEntityAdapter<Character>({
  selectId: (character) => character.id as string
});
export const charactersGlobalSelectors = charactersAdapter.getSelectors<RootState>(
  (state) => state.characters
);

export const voicesGlobalSelectors = voicesAdapter.getSelectors<RootState>((state) => state.voices);

export const videosAdapter = createEntityAdapter<Video>({
  selectId: (video) => video.id
});

export const videosGlobalSelectors = videosAdapter.getSelectors<RootState>((state) => state.videos);

export const foldersAdapter = createEntityAdapter<Folder>({
  selectId: (folder) => folder.id as string
});

export const foldersGlobalSelectors = foldersAdapter.getSelectors<RootState>(
  (state) => state.folders
);

export const trashAdapter = createEntityAdapter<Trash>({
  selectId: (trash) => trash.id as string
});

export const trashGlobalSelectors = trashAdapter.getSelectors<RootState>((state) => state.trash);

export const mediaAdapter = createEntityAdapter<ServerFolderMedia>({
  selectId: (media) => media.id
});

export const mediaGlobalSelectors = mediaAdapter.getSelectors<RootState>((state) => state.media);

export const workspaceAdapter = createEntityAdapter<Workspace>({
  selectId: (workspace) => workspace.id as string
});

export const workspacesGlobalSelectors = workspaceAdapter.getSelectors<RootState>(
  (state) => state.workspaces
);

export const brandKitsAdapter = createEntityAdapter<BrandKit>({
  selectId: (brandKit) => brandKit.id as string
});

export const brandkitGlobalSelectors = brandKitsAdapter.getSelectors<RootState>(
  (state) => state.brandKit
);

export const playgroundAdapter = createEntityAdapter<PlayGroundResponse>({
  selectId: (playground) => playground.id as string
});

export const dataStoreAdapter = createEntityAdapter<DataStoreResult>({
  selectId: (dataStore) => dataStore.id as string
});

export const playgroundGlobalSelectors = playgroundAdapter.getSelectors<RootState>(
  (state) => state.playground
);

export const backgroundMusicAdapter = createEntityAdapter<BackgroundMusic>({
  selectId: (music) => music.id
});

export const backgroundMusicGlobalSelectors = backgroundMusicAdapter.getSelectors<RootState>(
  (state) => state.music
);

export const avatarsAdapter = createEntityAdapter<Avatar>({
  selectId: (avatar) => avatar.id
});

export const avatarsGlobalSelectors = avatarsAdapter.getSelectors<RootState>(
  (state) => state.avatars
);

export const playlistsAdapter = createEntityAdapter<Playlist>({
  selectId: (playlist) => playlist.id
});

export const playlistsGlobalSelectors = playlistsAdapter.getSelectors<RootState>(
  (state) => state.playlists
);

export const webhooksAdapter = createEntityAdapter<Webhook>({
  selectId: (webhook) => webhook.id
});

export const webhooksGlobalSelectors = webhooksAdapter.getSelectors<RootState>(
  (state) => state.webhooks
);
