import { DEFAULT_GALLERY_PAGE_SIZE, Folder } from "app/types";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import FolderGridItem from "app/components/common/DraftsAndFolders/FolderGridItem";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Pagination } from "antd";
import { useAppSelector } from "app/hooks";
import { getFoldersByPageAndQuery } from "app/store/selectorsV2/folders.selectors";
import { RootState } from "app/store/store";
import { calculateTotalNumberOfGridElements, convertRemToPixels } from "app/utils/helpers";
import { HeaderContext } from "app/hoc/HeaderContext";
import EmptyState from "app/components/common/EmptyState";
import messages from "app/components/projects-managment/DraftsView/messages";

const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

interface FoldersGridProps {
  onCreateFolder: () => void;
}

const FoldersGrid = ({ onCreateFolder }: FoldersGridProps) => {
  const [totalNumberOfFoldersPerPage, setTotalNumberOfFoldersPerPage] =
    useState(DEFAULT_GALLERY_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const ref = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const { searchValue } = useContext(HeaderContext);

  const { slicedFolders: currentDisplayedFolders, totalFolders } = useAppSelector(
    (state: RootState) =>
      getFoldersByPageAndQuery(state, currentPage, searchValue, totalNumberOfFoldersPerPage)
  );

  const withPagination = totalFolders > totalNumberOfFoldersPerPage;

  useLayoutEffect(() => {
    if (ref.current) {
      const paginationHeight = convertRemToPixels(2);
      const paginationGapHeight = convertRemToPixels(1.5);
      const width = ref.current.clientWidth;
      const height = ref.current.clientHeight - (paginationHeight + paginationGapHeight);
      const widthGap = 2;
      const heightGap = 2.125;
      setTotalNumberOfFoldersPerPage(
        calculateTotalNumberOfGridElements(width, height, widthGap, heightGap)
      );
    }
  }, []);

  const onChangePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <H1_FlexColumn flex="1 1 auto" width="100%" gap="1.5rem" ref={ref} justify="space-between">
      <ConditionalRender condition={totalFolders > 0}>
        <H1_FlexRow
          wrap="wrap"
          width="100%"
          align="center"
          gap="2rem 2.125rem"
          padding="0 0.5rem 0 0"
        >
          {currentDisplayedFolders.map((folder: Folder) => (
            <FolderGridItem key={folder.id} folder={folder} />
          ))}
        </H1_FlexRow>
        <ConditionalRender condition={withPagination}>
          <H1_FlexRow justify="center" width="100%" align="center">
            <StyledPagination
              defaultCurrent={1}
              defaultPageSize={totalNumberOfFoldersPerPage}
              current={currentPage}
              total={totalFolders}
              onChange={onChangePagination}
              showSizeChanger={false}
            />
          </H1_FlexRow>
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender condition={totalFolders === 0}>
        <EmptyState
          text={formatMessage(messages.emptyFoldersText)}
          description={formatMessage(messages.emptyFoldersDescription)}
          withButton
          buttonText={formatMessage(messages.emptyFoldersButtonText)}
          icon="fal fa-folder"
          onClickButton={onCreateFolder}
        />
      </ConditionalRender>
    </H1_FlexColumn>
  );
};

export default FoldersGrid;
