import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Draft, PatchOperation, Pronunciation } from "app/types";
import React, { useState } from "react";
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import styled from "styled-components";
import GlobalPronunciationSingleElement from "app/components/editor/scene/GlobalPronunciationSingleElement";
import { draftsActions } from "app/store/slices/drafts.slice";
import { Tooltip } from "antd";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ThemeMode } from "app/utils/theme";

const MaxFlexColumn = styled(H1_FlexColumn)`
  max-height: 400px;
`;

const PronunciationButton = styled(Button)`
  span,
  i {
    color: ${({ theme }) => theme.blue2};
  }
  &:hover {
    span,
    i {
      color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.blue4 : theme.gray9)};
    }
  }
`;

const GlobalPronunciation = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const currentDraft: Draft = useAppSelector((state) => state.drafts.currentDraft);

  const { pronunciations } = currentDraft;
  const isLastPronunciationEmpty = !(
    pronunciations && pronunciations[pronunciations.length - 1].word
  );

  const onOpenChange = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen);
    if (isOpen && (!pronunciations || pronunciations?.length === 0)) {
      const newPronunciation: Pronunciation = {
        word: "",
        pronounced: ""
      };
      dispatch(draftsActions.updateCurrentDraftPronunciations([newPronunciation]));
    }
  };
  const onClose = () => {
    if (isUpdated) {
      const operations: PatchOperation[] = [];
      operations.push({
        op: "replace",
        path: "pronunciations",
        value: pronunciations
      } as PatchOperation);
      dispatch(
        draftsActions.patchDraftRequest({ draftId: currentDraft?.id as string, operations })
      );
    }
    setIsUpdated(false);
  };

  const addGlobalPronunciation = () => {
    const newPronunciation: Pronunciation = {
      word: "",
      pronounced: ""
    };
    dispatch(
      draftsActions.updateCurrentDraftPronunciations([...(pronunciations || []), newPronunciation])
    );
  };

  const onUpdateWord = (value: string, index: number) => {
    if (!pronunciations) {
      return;
    }
    setIsUpdated(true);
    const currentPronunciations: Pronunciation = { ...pronunciations[index], word: value };
    const newOperationsValue = [
      ...pronunciations.slice(0, index),
      currentPronunciations,
      ...pronunciations.slice(index + 1, pronunciations.length)
    ];

    dispatch(draftsActions.updateCurrentDraftPronunciations(newOperationsValue));
  };

  const onUpdatePronounced = (value: string, index: number) => {
    if (!pronunciations) {
      return;
    }
    setIsUpdated(true);
    const currentPronunciations: Pronunciation = { ...pronunciations[index], pronounced: value };
    const newOperationsValue = [
      ...pronunciations.slice(0, index),
      currentPronunciations,
      ...pronunciations.slice(index + 1, pronunciations.length)
    ];

    dispatch(draftsActions.updateCurrentDraftPronunciations(newOperationsValue));
  };

  const onDeletePronunciation = (index: number) => {
    if (!pronunciations) {
      return;
    }

    setIsUpdated(true);
    const newOperationsValue =
      pronunciations.length === 1
        ? [
            {
              word: "",
              pronounced: ""
            }
          ]
        : [
            ...pronunciations.slice(0, index),
            ...pronunciations.slice(index + 1, pronunciations.length)
          ];

    dispatch(draftsActions.updateCurrentDraftPronunciations(newOperationsValue));
  };

  return (
    <Popover placement="bottom-end" showArrow={false} onClose={onClose} onOpenChange={onOpenChange}>
      <Tooltip title={formatMessage(sceneMessages.globalPronunciationTooltip)}>
        <PopoverTrigger>
          <PronunciationButton
            size="sm"
            startContent={<i className="far fa-font" />}
            isIconOnly
            variant="light"
          />
        </PopoverTrigger>
      </Tooltip>
      <PopoverContent>
        <MaxFlexColumn overflow="auto" padding="40px 32px" gap="13px">
          <H1_FlexRow gap="43px" align="center">
            <H1_FlexRow width="191px">
              <H1_TextSmall color="rgba(113, 113, 122, 1)">
                {formatMessage(sceneMessages.globalPronunciationWritten)}
              </H1_TextSmall>
            </H1_FlexRow>
            <H1_FlexRow width="191px">
              <H1_TextSmall color="rgba(113, 113, 122, 1)">
                {formatMessage(sceneMessages.globalPronunciationPronounced)}
              </H1_TextSmall>
            </H1_FlexRow>
          </H1_FlexRow>
          <ConditionalRender condition={isPopoverOpen}>
            <H1_FlexColumn gap="21px">
              {(pronunciations || []).map((pronunciation: Pronunciation, index: number) => (
                <GlobalPronunciationSingleElement
                  key={index}
                  word={pronunciation.word}
                  pronounce={pronunciation.pronounced}
                  onUpdateWord={(value: string) => onUpdateWord(value, index)}
                  onUpdatePronounced={(value: string) => onUpdatePronounced(value, index)}
                  onDelete={() => onDeletePronunciation(index)}
                />
              ))}
              <H1_FlexRow width="190px">
                <Button
                  isDisabled={isLastPronunciationEmpty}
                  onClick={addGlobalPronunciation}
                  color="primary"
                  startContent={<i className="far fa-plus" />}
                >
                  {formatMessage(sceneMessages.addGlobalPronunciation)}
                </Button>
              </H1_FlexRow>
            </H1_FlexColumn>
          </ConditionalRender>
        </MaxFlexColumn>
      </PopoverContent>
    </Popover>
  );
};

export default GlobalPronunciation;
