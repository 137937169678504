import React, { ReactNode } from "react";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle, H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled, { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";

const EmptyStateIcon = styled.i`
  font-size: 4rem;
  color: ${({ theme }) => theme.gray7};
`;

interface EmptyStateProps {
  text: string;
  textSize?: string;
  description: string;
  icon: string | ReactNode;
  withButton?: boolean;
  buttonText?: string;
  onClickButton?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  width?: string;
  button?: ReactNode;
}

const EmptyState = ({
  text,
  textSize = "24px",
  description,
  icon,
  buttonText,
  onClickButton,
  withButton,
  width = "264px",
  button
}: EmptyStateProps) => {
  const theme = useTheme();

  const IconComponent = typeof icon === "string" ? <EmptyStateIcon className={icon} /> : icon;
  return (
    <H1_FlexColumn
      margin="auto"
      flex="1 1 100%"
      gap="10px"
      width={width}
      align="center"
      justify="center"
    >
      {IconComponent}
      <H1_TextMidHeadline
        textAlign="center"
        whiteSpace="normal"
        color={theme.gray7}
        fontWeight={500}
        fontSize={textSize}
      >
        {text}
      </H1_TextMidHeadline>
      <H1_TextMiddle textAlign="center" whiteSpace="normal" color={theme.gray7} fontWeight={500}>
        {description}
      </H1_TextMiddle>
      <H1_FlexColumn padding="10px 0 0 0" />
      <ConditionalRender condition={!!withButton && !button}>
        <Button color="primary" onClick={onClickButton}>
          {buttonText}
        </Button>
      </ConditionalRender>
      <ConditionalRender condition={!!withButton && !!button}>{button}</ConditionalRender>
    </H1_FlexColumn>
  );
};

export default EmptyState;
