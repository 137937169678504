import { fetchingStatus } from "app/utils/helpers";
import { draftsActions } from "app/store/slices/drafts.slice";
import uiActions from "app/store/actions/ui.actions";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import useCopy from "app/hooks/useCopy";
import { Location } from "app/types/general";
import { message } from "antd";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";

interface useDropdownHandlerOptions {
  goFoldersOnDelete?: boolean;
  activeApiExampleHandler: boolean;
  goDraftOnDuplicate?: boolean;
}
const messages = defineMessages({
  editProjectNewTemplateCreated: {
    id: `dropdown-handler.dropdown.new-template.created`,
    defaultMessage: "New template created!"
  }
});
const useDropdownHandler = (options: useDropdownHandlerOptions) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { triggerCopy } = useCopy();

  const deleteStatus = useAppSelector((state) => state.drafts.deleteDraftStatus);
  const saveDraftAsRecipeStatus = useAppSelector((state) => state.drafts.saveDraftAsRecipeStatus);
  const apiExampleStatus = useAppSelector((state) => state.drafts.apiExampleStatus);
  const duplicatedDraftResult = useAppSelector((state) => state.drafts.duplicatedDraftResult);
  const duplicationStatus = useAppSelector((state) => state.drafts.duplicationStatus);
  const apiExampleResult = useAppSelector((state) => state.drafts.apiExampleResult);

  useEffect(() => {
    if (deleteStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateDeleteDraftStatusToIdle());
      if (options.goFoldersOnDelete) {
        navigate(Location.Projects);
      }
    } else if (deleteStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateDeleteDraftStatusToIdle());
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (saveDraftAsRecipeStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setSaveDraftAsRecipeStatusToIdle());
      dispatch(
        uiActions.setNotifications([
          { message: intl.formatMessage(messages.editProjectNewTemplateCreated) }
        ])
      );
    } else if (saveDraftAsRecipeStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setSaveDraftAsRecipeStatusToIdle());
    }
  }, [saveDraftAsRecipeStatus]);

  useEffect(() => {
    if (duplicationStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.updateDuplicateDraftStatusToIdle());
      if (options.goDraftOnDuplicate) {
        dispatch(analyticsEvents.navigateToEditor({ source: "dropdown_duplicate" }));
        navigate(`/editor/${duplicatedDraftResult?.id}`);
      }
    } else if (duplicationStatus === fetchingStatus.failed) {
      dispatch(draftsActions.updateDuplicateDraftStatusToIdle());
    }
  }, [duplicationStatus]);
  useEffect(() => {
    if (!options.activeApiExampleHandler) {
      return;
    }
    if (apiExampleStatus === fetchingStatus.succeeded) {
      triggerCopy({ copyContent: apiExampleResult?.curl, shouldNotify: false });
      message.success("Copied to clipboard");

      dispatch(draftsActions.setApiExampleStatusToIdle());
    } else if (apiExampleStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setApiExampleStatusToIdle());
    }
  }, [apiExampleStatus]);
};

export default useDropdownHandler;
