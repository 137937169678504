import { ColorResult, CustomPicker } from "react-color";

import React, { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { EditableInput, Saturation } from "react-color/lib/components/common";
import styled, { useTheme } from "styled-components";
import { mappedAlphaHex } from "app/utils/helpers";
import CustomCirclePicker from "app/components/editor/sideDrawers/StyleDrawer/CustomCirclePicker";
import { PaletteColor } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { useIntl } from "react-intl";
import { customColorPickerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { EditPaletteSource, SelectColorType } from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch } from "app/hooks";
import CustomHue from "app/components/editor/sideDrawers/StyleDrawer/CustomHue";
import { ColorWrap } from "app/components/editor/sideDrawers/StyleDrawer/ColorWrap";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { templatesActions } from "app/store/slices/templates.slice";
import { Popover, PopoverTrigger, PopoverContent, Button } from "@nextui-org/react";

const CloseFlexRow = styled(H1_FlexRow)`
  top: 2px;
  right: -20px; // We are inside the popover context, so we need to move out of it
`;
const LinkButton = styled(Button)`
  && {
    background-color: transparent;
  }
  &:hover {
    text-decoration: underline;
  }
`;
const SaturationWrapper = styled.div`
  width: 100%;
  height: 86px;
  position: relative;
  .saturation-black,
  .saturation-white {
    border-radius: 4px;
  }
  & > div:nth-of-type(1) {
    border-radius: 6px;
  }
`;

const HuePointer = styled.div`
  cursor: pointer;
  width: 8px;
  height: 8px;
  margin-top: 1px;
  border: 2px solid ${(props) => props.theme.gray11};
  border-radius: 50%;
`;

const EditableInputWrapper = styled.div`
  width: 100%;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1.10828px 2.21656px rgba(31, 41, 55, 0.08);
  border-radius: 5px;
  padding: 4px 7px 4px 7px;
  input {
    border: none;
    color: ${({ theme }) => theme.gray11};
  }
  div:before {
    color: #9ca3af;
    content: "#";
  }
`;

interface CustomColorPickerPopoverProps {
  draftId: string;
  header: string;
  initialColors: PaletteColor[];
  onFinish: (colors?: PaletteColor[]) => void;
  onVisibleChange: (visible: boolean) => void;
  visible: boolean;
  children: ReactNode;
}

const CustomSaturationPicker = ColorWrap((props: PropsWithChildren<any>) => {
  return (
    <SaturationWrapper>
      <Saturation {...props} />
    </SaturationWrapper>
  );
});

const CustomColorInput = CustomPicker((props) => {
  return (
    <EditableInputWrapper>
      <EditableInput
        // @ts-ignore handle property color does not exist InjectedColorProps type
        value={props.color.split("#")[1].slice(0, 6)}
        {...props}
      />
    </EditableInputWrapper>
  );
});

const CustomColorPickerPopover = ({
  draftId,
  header,
  initialColors,
  onFinish,
  visible,
  onVisibleChange,
  children
}: CustomColorPickerPopoverProps) => {
  const [isColorChanged, setIsColorChanged] = useState<boolean>(false);
  const [colorsList, setColorsList] = useState<PaletteColor[]>([]);
  const [selectedColorIndex, setSelectedColorIndex] = useState<number>(0);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    setColorsList(initialColors);
  }, [initialColors]);

  const onColorChange = ({ hex, rgb }: ColorResult, source: EditPaletteSource): void => {
    setIsColorChanged(true);
    dispatch(analyticsEvents.editPalette({ source }));

    const newColor =
      hex === "transparent" ? "#00000000" : hex + mappedAlphaHex((rgb?.a || 1) * 100);
    const newColorsList = [...colorsList];
    newColorsList[selectedColorIndex] = { ...newColorsList[selectedColorIndex], color: newColor };
    setColorsList(newColorsList);
  };

  const onApply = () => {
    dispatch(
      templatesActions.upsertPaletteRequest({
        draftId,
        name: "custom",
        colors: colorsList,
        skipFramePreview: false
      })
    );
    dispatch(
      analyticsEvents.selectColor({ palette: "draft", type: SelectColorType.edit_selected_color })
    );
    onClosePopover(false);
  };

  const onCancel = () => {
    setIsColorChanged(false);
    setColorsList(initialColors);
    onVisibleChange(false);
  };

  const onClosePopover = (currentVisibility: boolean) => {
    setIsColorChanged(false);
    onVisibleChange(currentVisibility);
    if (!currentVisibility) {
      if (isColorChanged) {
        onFinish(colorsList);
      } else {
        onFinish();
      }
    }
  };

  const onSelectColorIndex = (index: number) => {
    dispatch(
      analyticsEvents.editPaletteSelectColor({
        colorIndex: index,
        colorKey: initialColors[index].key as string
      })
    );

    setSelectedColorIndex(index);
  };

  const colorsPopoverContent = () => {
    return (
      <H1_FlexColumn padding="8px 4px" gap="18px" position="relative" width="204px">
        <CloseFlexRow position="absolute" onClick={onCancel}>
          <CloseModalIcon />
        </CloseFlexRow>
        <H1_TextXs color={theme.gray7} fontWeight={600}>
          {header}
        </H1_TextXs>
        <H1_FlexColumn gap="18px" width="100%" justify="space-between">
          <ConditionalRender condition={!!colorsList.length}>
            <CustomCirclePicker
              selectedColor={selectedColorIndex}
              colors={colorsList}
              onSelectColorIndex={onSelectColorIndex}
            />
            <CustomSaturationPicker
              color={colorsList[selectedColorIndex]?.color}
              onChangeComplete={(colorResult: ColorResult) => {
                onColorChange(colorResult, EditPaletteSource.colorMap);
              }}
            />
            <CustomHue
              onChangeComplete={(result) => {
                onColorChange(result, EditPaletteSource.colorRow);
              }}
              color={colorsList[selectedColorIndex]?.color}
              // @ts-ignore handle Property 'pointer' does not exist on type 'IntrinsicAttributes'
              pointer={() => <HuePointer />}
            />
            <CustomColorInput
              color={colorsList[selectedColorIndex]?.color}
              onChangeComplete={(colorResult: ColorResult) =>
                onColorChange(colorResult, EditPaletteSource.manual)
              }
            />
          </ConditionalRender>
          <H1_FlexRow gap="20px" justify="space-between">
            <LinkButton onClick={onCancel} variant="light" size="sm">
              <H1_TextSmall>
                {intl.formatMessage(customColorPickerMessages.cancelButton)}
              </H1_TextSmall>
            </LinkButton>
            <Button onClick={onApply} size="sm" color="primary">
              {intl.formatMessage(customColorPickerMessages.doneButton)}
            </Button>
          </H1_FlexRow>
        </H1_FlexColumn>
      </H1_FlexColumn>
    );
  };

  return (
    <Popover isOpen={visible} placement="left-start" onOpenChange={onClosePopover}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>{colorsPopoverContent}</PopoverContent>
    </Popover>
  );
};

export default CustomColorPickerPopover;
