/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/voices.thunk";
import { fetchingStatus } from "app/utils/helpers";
import {
  CloneVoiceEvent,
  defaultVoiceFilter,
  Favorite,
  FetchStatus,
  VoiceFilters
} from "app/types";
import { voicesAdapter } from "app/store/adapters/adapters";

export enum ListenStatus {
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
  canPlay = "canPlay",
  play = "play",
  rewind = "rewind",
  playing = "playing",
  pause = "pause",
  failed = "failed"
}

export interface FooterAudioPlayerProps {
  status: ListenStatus;
  draftId?: string;
  orderId?: string;
  sceneId?: string;
  url?: string;
  autoPlay?: boolean;
}

type Timing = [number, number];
interface VoiceState {
  createVoiceStatus: FetchStatus;
  cloneVoiceStatus: FetchStatus;
  favoriteStatus: FetchStatus;
  footerLoading: boolean;
  footerAudioPlayer: FooterAudioPlayerProps;
  fullAudioPreviewPlayed: Record<string, boolean>;
  voicePreviewUrl?: string;
  scenesTiming: Timing[];
  voicePreviewOrderId?: string;
  voicePreviewStatus: FetchStatus;
  filters: Partial<VoiceFilters>;
}
export const voicesSlice = createSlice({
  name: "Voices",
  initialState: voicesAdapter.getInitialState<VoiceState>({
    createVoiceStatus: fetchingStatus.idle as FetchStatus,
    cloneVoiceStatus: fetchingStatus.idle as FetchStatus,
    favoriteStatus: fetchingStatus.idle as FetchStatus,
    filters: defaultVoiceFilter,
    footerLoading: false,
    footerAudioPlayer: {
      status: ListenStatus.idle,
      draftId: "",
      sceneId: ""
    },
    fullAudioPreviewPlayed: {},
    scenesTiming: [],
    voicePreviewStatus: fetchingStatus.idle as FetchStatus
  }),
  reducers: {
    setFavoritesBulk(state, action) {
      const favorites = action.payload;
      favorites.forEach((favorite: Favorite) => {
        const { asset_id, active } = favorite;
        const voice = state.entities[asset_id];
        const entityIndex = state.ids.indexOf(asset_id);

        if (voice && entityIndex > -1) {
          voice.favorite = active;
          if (active) {
            const newIds = [asset_id, ...state.ids.filter((entityId) => entityId !== asset_id)];
            state.ids = newIds;
          } else {
            const newIds = [...state.ids.filter((entityId) => entityId !== asset_id), asset_id];
            state.ids = newIds;
          }
        }
      });
      return state;
    },
    setFilters(state, action) {
      const { age, tone, useCase, pause } = action.payload;
      if (age) {
        state.filters.age = age;
      }
      if (tone) {
        state.filters.tone = tone;
      }
      if (useCase) {
        state.filters.useCase = useCase;
      }
      if (pause !== undefined) {
        state.filters.pause = pause;
      }

      return state;
    },
    resetFilters(state) {
      const copyDefaultVoiceFilter = { ...defaultVoiceFilter };
      state.filters = copyDefaultVoiceFilter;
      return state;
    },
    turnOffFullAudioPlayed(state, action) {
      state.fullAudioPreviewPlayed[action.payload] = false;
      return state;
    },
    turnOffFooterLoading(state) {
      state.footerLoading = false;
      return state;
    },
    updateFooterAudioPlayer(state, { payload }) {
      const { status, sceneId, draftId, url, autoPlay } = payload;
      state.footerAudioPlayer = { ...state.footerAudioPlayer };
      if (status) {
        state.footerAudioPlayer.status = status;
      }
      if (url) {
        state.footerAudioPlayer.url = url;
      }
      if (draftId) {
        state.footerAudioPlayer.draftId = draftId;
      }
      if (sceneId) {
        state.footerAudioPlayer.sceneId = sceneId;
      }
      if (autoPlay !== undefined) {
        state.footerAudioPlayer.autoPlay = autoPlay;
      }
      return state;
    },
    cleanFooterAudio(state) {
      state.voicePreviewUrl = undefined;
      state.scenesTiming = [];
      state.voicePreviewStatus = fetchingStatus.idle;
      state.voicePreviewOrderId = undefined;
      state.footerAudioPlayer = {
        status: ListenStatus.idle,
        autoPlay: false
      };
      return state;
    },

    replaceCloneVoice(state, action: PayloadAction<CloneVoiceEvent>) {
      // search by req_id

      const { order_id } = action.payload;
      const entity = Object.values(state.entities).find((voice) => voice?.order_id === order_id);
      if (entity) {
        voicesAdapter.removeOne(state, entity.id);
        voicesAdapter.addOne(state, {
          ...entity,
          ...action.payload,
          id: action.payload.new_id
        });
      }
      return state;
    },
    removeVoiceByOrderId(state, action) {
      const entity = Object.values(state.entities).find(
        (voice) => voice?.order_id === action.payload
      );
      if (entity) {
        voicesAdapter.removeOne(state, entity.id);
      }
      return state;
    },
    setCloneVoiceToIdle(state) {
      state.cloneVoiceStatus = fetchingStatus.idle;
      return state;
    },
    setVoicePreviewV2(state, action) {
      const { url, scenesTiming, status, orderId } = action.payload;
      if (orderId === state.voicePreviewOrderId) {
        state.voicePreviewUrl = url;
        state.scenesTiming = scenesTiming;
        state.voicePreviewStatus = status;
      }

      return state;
    },
    cleanVoicePreviewV2(state) {
      state.voicePreviewUrl = undefined;
      state.scenesTiming = [];
      state.voicePreviewStatus = fetchingStatus.idle;
      state.voicePreviewOrderId = undefined;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getVoicesListRequest.fulfilled, (state, action) => {
      voicesAdapter.setAll(state, action.payload);
    });

    builder.addCase(asyncThunks.createAudioPreviewRequest.fulfilled, (state, action) => {
      state.createVoiceStatus = fetchingStatus.succeeded as FetchStatus;
      if (action.meta.arg.type === "draft") {
        state.fullAudioPreviewPlayed[action.meta.arg.draftId] = true;
      }
    });
    builder.addCase(asyncThunks.createAudioPreviewRequest.pending, (state, action) => {
      state.createVoiceStatus = fetchingStatus.loading as FetchStatus;
      state.footerLoading = true;
      state.footerAudioPlayer = {
        status: ListenStatus.loading,
        draftId: action.meta.arg.draftId,
        sceneId: action.meta.arg.sceneId,
        autoPlay: action.meta.arg.autoPlay,
        orderId: action.meta.arg.orderId
      };
    });
    builder.addCase(asyncThunks.createAudioPreviewRequest.rejected, (state, action) => {
      state.createVoiceStatus = fetchingStatus.failed as FetchStatus;
      state.footerLoading = false;
      state.footerAudioPlayer = {
        status: ListenStatus.failed,
        orderId: action.meta.arg.orderId
      };
    });
    builder.addCase(asyncThunks.createFullPreviewV2Request.fulfilled, (state) => {
      state.voicePreviewStatus = fetchingStatus.idle;
    });
    builder.addCase(asyncThunks.createFullPreviewV2Request.pending, (state, action) => {
      state.voicePreviewStatus = fetchingStatus.loading;
      state.voicePreviewOrderId = action.meta.arg.orderId;
    });
    builder.addCase(asyncThunks.createFullPreviewV2Request.rejected, (state) => {
      state.voicePreviewStatus = fetchingStatus.failed;
    });
    builder.addCase(asyncThunks.cloneVoiceRequest.pending, (state) => {
      state.cloneVoiceStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.cloneVoiceRequest.rejected, (state) => {
      state.cloneVoiceStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.cloneVoiceRequest.fulfilled, (state, action) => {
      state.cloneVoiceStatus = fetchingStatus.succeeded as FetchStatus;
      voicesAdapter.addOne(state, action.payload);
    });
    builder.addCase(asyncThunks.setFavoritesBulkRequest.pending, (state) => {
      state.favoriteStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.setFavoritesBulkRequest.rejected, (state) => {
      state.favoriteStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.setFavoritesBulkRequest.fulfilled, (state) => {
      state.favoriteStatus = fetchingStatus.succeeded as FetchStatus;
    });
  }
});

export default voicesSlice.reducer;

export const voicesActions = {
  getVoicesListRequest: asyncThunks.getVoicesListRequest,
  createAudioPreviewRequest: asyncThunks.createAudioPreviewRequest,
  createFullPreviewV2Request: asyncThunks.createFullPreviewV2Request,
  cloneVoiceRequest: asyncThunks.cloneVoiceRequest,
  setFavoritesBulkRequest: asyncThunks.setFavoritesBulkRequest,
  ...voicesSlice.actions
};
