import type { LexicalCommand } from "lexical";
import { createCommand } from "lexical";
import {
  SceneEstCommandPayload,
  PronunciationCommandPayload,
  PauseCommandPayload,
  VariableCommandPayload
} from "app/components/common/LexicalEditor/plugins/types";

const INSERT_SCENE_EST_COMMAND: LexicalCommand<SceneEstCommandPayload> = createCommand();
const INSERT_PAUSE_COMMAND: LexicalCommand<PauseCommandPayload> = createCommand();
const INSERT_PRONUNCIATION_COMMAND: LexicalCommand<PronunciationCommandPayload> = createCommand();
const INSERT_VARIABLE_COMMAND: LexicalCommand<VariableCommandPayload> = createCommand();

export default {
  INSERT_SCENE_EST_COMMAND,
  INSERT_PAUSE_COMMAND,
  INSERT_PRONUNCIATION_COMMAND,
  INSERT_VARIABLE_COMMAND
};
