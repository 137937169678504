/* eslint-disable no-template-curly-in-string */
import { defineMessages } from "react-intl";

export const paymentModalV2InitialMessages = defineMessages({
  title: {
    id: `pricing-modal.title`,
    defaultMessage: `Choose your plan`
  },
  comparePlans: {
    id: `pricing-modal.compare-plans`,
    defaultMessage: `Compare plans`
  },
  recommended: {
    id: `pricing-modal.recommended`,
    defaultMessage: `RECOMMENDED`
  },
  monthly: {
    id: `pricing-modal.monthly`,
    defaultMessage: `Monthly`
  },
  yearly: {
    id: `pricing-modal.yearly`,
    defaultMessage: `Yearly`
  },
  yearlyDiscount: {
    id: `pricing-modal.yearly-discount`,
    defaultMessage: `({yearlyDiscount})`
  },
  personal: {
    id: `pricing-modal.personal`,
    defaultMessage: `Lite`
  },
  business: {
    id: `pricing-modal.business`,
    defaultMessage: `Business`
  },
  enterprise: {
    id: `pricing-modal.enterprise`,
    defaultMessage: `Enterprise`
  },
  perMonth: {
    id: `pricing-modal.per-month`,
    defaultMessage: `/ mo`
  },
  perMonthAnnually: {
    id: `pricing-modal.per-month-annually`,
    defaultMessage: `/ mo (billed annually)`
  },
  perYear: {
    id: `pricing-modal.per-year`,
    defaultMessage: `/ yearly`
  },
  sliderMinutes: {
    id: `pricing-modal.slider-minutes`,
    defaultMessage: `{number} Min`
  },
  letsTalk: {
    id: `pricing-modal.lets-talk`,
    defaultMessage: `Custom`
  },
  letsStart: {
    id: `pricing-modal.lets-start`,
    defaultMessage: `Lets start`
  },
  whatsIncluded: {
    id: `pricing-modal.whats-included`,
    defaultMessage: `What's included`
  },
  whatsIncludedBusiness: {
    id: `pricing-modal.whats-included-business`,
    defaultMessage: `Get everything in Lite and:`
  },
  whatsIncludedEnterprise: {
    id: `pricing-modal.whats-included-enterprise`,
    defaultMessage: `Get everything in Business and:`
  },
  billedMonthly: {
    id: `pricing-modal.billed-per-month`,
    defaultMessage: `Billed Monthly`
  },
  billedAnnually: {
    id: `pricing-modal.billed-per-year`,
    defaultMessage: `Billed Annually`
  },
  personalDescription: {
    id: `pricing-modal.personal-description`,
    defaultMessage: `AI videos for professionals or small teams`
  },
  personalPriceDescriptionMonthly: {
    id: `pricing-modal.personal-price-description-monthly`,
    defaultMessage: `You'll get 20 minutes each month`
  },
  personalPriceDescriptionYearly: {
    id: `pricing-modal.personal-price-description-yearly`,
    defaultMessage: `Get 240 minutes upfront`
  },
  businessDescription: {
    id: `pricing-modal.business-description`,
    defaultMessage: `For companies scaling video creation`
  },
  businessPriceMonthly: {
    id: `pricing-modal.business-price-monthly`,
    defaultMessage: `You'll get {number} minutes each month`
  },
  businessPriceYearly: {
    id: `pricing-modal.business-price-yearly`,
    defaultMessage: `Get {number} minutes upfront`
  },
  enterpriseDescription: {
    id: `pricing-modal.enterprise-description`,
    defaultMessage: `Complete AI video pack tailored to your company`
  },
  enterprisePriceDescription: {
    id: `pricing-modal.enterprise-price-description`,
    defaultMessage: `Get unlimited minutes`
  },
  selectPlan: {
    id: `pricing-modal.select-plan`,
    defaultMessage: `Select Plan`
  },
  contactSales: {
    id: `pricing-modal.contact-sales`,
    defaultMessage: `Contact Sales`
  },
  yourCurrentPlan: {
    id: `pricing-modal.your-current-plan`,
    defaultMessage: `Your Current Plan`
  },
  liteMinutesPerMonth: {
    id: `pricing-modal.lite-minutes-per-month`,
    defaultMessage: `10 Minutes / Month`
  },
  liteMinutesPerYear: {
    id: `pricing-modal.lite-minutes-per-year`,
    defaultMessage: `120 Minutes / Year`
  },
  videoDownloads: {
    id: `pricing-modal.video-downloads`,
    defaultMessage: `Share & Download`
  },
  teamsSeats3: {
    id: `pricing-modal.teams-seats-3`,
    defaultMessage: `3 Users`
  },
  watermark: {
    id: `pricing-modal.watermark`,
    defaultMessage: `No Watermark`
  },
  all2dTemplates: {
    id: `pricing-modal.all-2d-templates`,
    defaultMessage: `All 2D Templates`
  },
  scriptVideoWizard: {
    id: `pricing-modal.script-video-wizard`,
    defaultMessage: `Script & Video Wizard`
  },
  liteSeats: {
    id: `pricing-modal.lite-seats`,
    defaultMessage: `1 Editor & 3 Viewer Seats`
  },
  minutesPerMonth: {
    id: `pricing-modal.business-minutes-per-month`,
    defaultMessage: `{number} Minutes / Month`
  },
  minutesPerYear: {
    id: `pricing-modal.business-minutes-per-year`,
    defaultMessage: `{number} Minutes / Year`
  },
  brandKit: {
    id: `pricing-modal.brand-kit`,
    defaultMessage: `Custom Brand Kit & Logo`
  },
  support: {
    id: `pricing-modal.support`,
    defaultMessage: `Customer Support`
  },
  improvedRendering: {
    id: `pricing-modal.improved-rendering`,
    defaultMessage: `Improved Rendering`
  },
  template3dPremium: {
    id: `pricing-modal.templates-3d-premium`,
    defaultMessage: `3D & Premium Templates`
  },
  teamsSeats5: {
    id: `pricing-modal.teams-seats-5`,
    defaultMessage: `5 Users`
  },
  unlimitedMinutes: {
    id: `pricing-modal.unlimited-minutes`,
    defaultMessage: `Unlimited Minutes`
  },
  userAccessManagement: {
    id: `pricing-modal.user-access-management`,
    defaultMessage: `User Access Management`
  },
  totalCustomization: {
    id: `pricing-modal.total-customization`,
    defaultMessage: `Full Brand Customization`
  },
  videoDownloadsAndMore: {
    id: `pricing-modal.video-downloads-and-more`,
    defaultMessage: `Video Downloads, Embed & More`
  },
  customTemplate: {
    id: `pricing-modal.custom-template`,
    defaultMessage: `Custom Template & Avatars`
  },
  customNumberOfUsers: {
    id: `pricing-modal.custom-number-of-users`,
    defaultMessage: `Workspace Collaboration`
  },
  priorityRendering: {
    id: `pricing-modal.priority-rendering`,
    defaultMessage: `Priority Rendering`
  },
  dedicatedCS: {
    id: `pricing-modal.dedicated-cs`,
    defaultMessage: `Customer Success Manager`
  },
  planNotSupported: {
    id: `pricing-modal.plan-not-supported`,
    defaultMessage: `The feature is not supported by this plan`
  }
});

export const paymentModalV2CreditCardMessages = defineMessages({
  subscribeToLite: {
    id: `pricing-modal.subscribe-to-lite`,
    defaultMessage: `Subscribe to Lite`
  },
  subscribeToBusiness: {
    id: `pricing-modal.subscribe-to-business`,
    defaultMessage: `Subscribe to Business`
  },
  pricePerMonth: {
    id: `pricing.payment-modal.price-per-month`,
    defaultMessage: "${price}/month"
  },
  pricePerYear: {
    id: `pricing.payment-modal.price-per-year`,
    defaultMessage: "${price}/year"
  },
  cancellationNote: {
    id: `pricing-modal.cancellation-note`,
    defaultMessage: "Pro rated cancellation in the first 14 days"
  },
  upgradeNow: {
    id: `pricing-modal.upgrade-now`,
    defaultMessage: "Upgrade Now"
  },
  postalCodePlaceholder: {
    id: `pricing-modal.postal-code-placeholder`,
    defaultMessage: "Postal Code"
  },
  cardholderNamePlaceholder: {
    id: `pricing-modal.cardholder-name-placeholder`,
    defaultMessage: "Cardholder Name"
  },
  yearlyPopover: {
    id: `pricing-modal.yearly-popover`,
    defaultMessage:
      "Once canceled in the first 14 days you will be charged for the number of minutes that have been used"
  },
  monthlyPopover: {
    id: `pricing-modal.monthly-popover`,
    defaultMessage:
      "Once canceled in the first 14 days you will be charged for one month of use. We recommend that you will use the entire creations minutes for this month before you cancel"
  },
  monthly: {
    id: `pricing-modal.credit-card.monthly`,
    defaultMessage: `Monthly`
  },
  yearly: {
    id: `pricing-modal.credit-card.yearly`,
    defaultMessage: `Yearly`
  },
  currentTime: {
    id: `pricing-modal.credit-card.current-time`,
    defaultMessage: "Start from {time}"
  },
  billedMonthly: {
    id: `pricing-modal.credit-card.billed-monthly`,
    defaultMessage: "Billed monthly"
  },
  billedAnnually: {
    id: `pricing-modal.credit-card.billed-annually`,
    defaultMessage: "Billed annually"
  },
  agreeToTerms: {
    id: `pricing-modal.credit-card.agree-to-terms`,
    defaultMessage: "By continuing on subscribe, you agree to our <span>Terms</span> and "
  },
  privacyPolicy: {
    id: `pricing-modal.credit-card.privacy-policy`,
    defaultMessage: "Privacy Policy"
  },
  trustedBy: {
    id: `pricing-modal.credit-card.trust-by`,
    defaultMessage: "JOIN LEADING BRANDS AND COMPANIES WORLDWIDE"
  },
  g2Content: {
    id: `pricing-modal.credit-card.g2-content`,
    // eslint-disable-next-line max-len
    defaultMessage: `I like that Hour One has options that are inclusive of diverse cultures and ethnicity. I also like that it has ready-made templates that are easy to use so you can focus more on your content than on the technical aspects that Hour One already made for the users.`
  },
  g2Title: {
    id: `pricing-modal.credit-card.g2-title`,
    defaultMessage: "Martin Goutry"
  },
  g2Subtitle: {
    id: `pricing-modal.credit-card.g2-subtitle`,
    defaultMessage: "Saves Time, Nurtures Creativity!"
  },
  welcomeLite: {
    id: `pricing-modal.welcome-lite`,
    defaultMessage: "Welcome to Lite plan"
  },
  welcomeBusiness: {
    id: `pricing-modal.welcome-business`,
    defaultMessage: "Welcome to Business plan"
  }
});

export const paymentModalV2SuccessMessages = defineMessages({
  welcomeLite: {
    id: `pricing-modal.success.welcome-lite`,
    defaultMessage: "Welcome to Lite plan"
  },
  welcomeBusiness: {
    id: `pricing-modal.success.welcome-business`,
    defaultMessage: "Welcome to Business plan"
  },
  content: {
    id: `pricing-modal.success.content`,
    defaultMessage: "Congratulations! you are can now create amazing professional videos. Enjoy."
  },
  letsStart: {
    id: `pricing-modal.success.lets-start`,
    defaultMessage: "Let's Start"
  }
});
