/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  SET_SIDEBAR_COLLAPSE,
  SET_EDITOR_ROW_VIEW,
  SET_EDITOR_DRAWER_OPEN,
  SET_OPEN_DRAWER_ORIGIN,
  SHOW_PAYMENT_FLOW,
  SET_ERRORS,
  SET_VALIDATION_SCROLL,
  SET_SCENE_ERRORS,
  CLEAN_ERRORS,
  SET_INTL,
  SET_FTU_VIDEO_PREVIEW,
  SET_OPEN_MODAL,
  SET_SIDEBAR_VISIBILITY,
  SET_NOTIFICATIONS,
  SET_VIEW,
  TOKEN_REFRESH_ERROR,
  CLEAN_TOKEN_REFRESH_ERROR,
  SET_NOTIFICATION_BADGE_COUNTER,
  SET_REALSME_VIDEO_READY_NOTIFICATION
} from "app/store/actions/ui.actions";
import { EditorView } from "app/types";

const INITIAL_STATE = {
  siderCollapsed: false,
  siderVisibility: true,
  editorDrawerOpen: null,
  editorModalOpen: null,
  drawerOrigin: null,
  editorRow: -1,
  paymentFlow: null,
  ftuVideoPreview: true,
  errors: {},
  validationScroll: false,
  sceneIdsError: {},
  lastErrors: {},
  showMenu: true,
  notifications: [],
  view: EditorView.Multiple,
  notificationsBadgeCounter: 0,
  realsmeVideoReadyNotification: false,
  apiRefreshTokenError: false
};

const userUiReducer = produce((state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SIDEBAR_COLLAPSE:
      state.siderCollapsed = payload;
      break;
    case CLEAN_TOKEN_REFRESH_ERROR:
      state.apiRefreshTokenError = undefined;
      break;
    case TOKEN_REFRESH_ERROR:
      state.lastErrors = payload;
      state.apiRefreshTokenError = true;
      break;
    case SET_SIDEBAR_VISIBILITY:
      state.siderVisibility = payload;
      break;
    case SET_EDITOR_DRAWER_OPEN:
      state.editorDrawerOpen = payload;
      break;
    case SET_OPEN_MODAL:
      state.editorModalOpen = payload;
      break;
    case SET_EDITOR_ROW_VIEW:
      state.editorRow = payload;
      break;
    case SET_OPEN_DRAWER_ORIGIN:
      state.drawerOrigin = payload;
      break;
    case SHOW_PAYMENT_FLOW:
      state.paymentFlow = payload.action;
      break;
    case SET_ERRORS:
      state.lastErrors = payload;
      state.errors = payload;
      break;
    case SET_VALIDATION_SCROLL:
      state.validationScroll = payload;
      break;
    case SET_SCENE_ERRORS:
      state.sceneIdsError = payload;
      break;
    case CLEAN_ERRORS:
      state.errors = {};
      break;
    case SET_INTL:
      state.intl = payload;
      break;
    case SET_FTU_VIDEO_PREVIEW:
      state.ftuVideoPreview = payload;
      break;
    case SET_NOTIFICATIONS:
      state.notifications = payload;
      break;
    case SET_VIEW:
      state.view = payload;
      break;
    case SET_NOTIFICATION_BADGE_COUNTER:
      state.notificationsBadgeCounter = payload;
      break;
    case SET_REALSME_VIDEO_READY_NOTIFICATION:
      state.realsmeVideoReadyNotification = payload;
      break;

    default:
      break;
  }
}, INITIAL_STATE);

export default userUiReducer;
