import styled, { useTheme } from "styled-components";
import { Divider, Tooltip } from "antd";
import { useIntl } from "react-intl";
import { channelsMessages } from "app/pages/Channels/messages";
import { useSingleVideoContext } from "app/components/common/Reactions/ChannelsPageSingleVideoContext";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextXs } from "app/components/_Infrastructure/Typography";

const Separator = styled(Divider)`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.gray3};
`;

const VideoAnalyticsPopover = () => {
  const intl = useIntl();
  const theme = useTheme();
  const { video } = useSingleVideoContext();
  return (
    <H1_FlexColumn>
      <H1_FlexColumn>
        <H1_FlexRow align="center" gap="15px">
          <H1_TextXs color={theme.gray8}>
            {intl.formatMessage(channelsMessages.videoAnalyticsPopoverViewsTitle)}
          </H1_TextXs>
          <Tooltip
            placement="right"
            title={intl.formatMessage(channelsMessages.videoAnalyticsPopoverViewsTooltip)}
          >
            <i className="fa-light fa-circle-exclamation" />
          </Tooltip>
        </H1_FlexRow>
        <H1_TextMiddle>{video.views || 0}</H1_TextMiddle>
      </H1_FlexColumn>
      <Separator />
      <H1_FlexColumn>
        <H1_FlexRow align="center" gap="15px">
          <H1_TextXs color={theme.gray8}>
            {intl.formatMessage(channelsMessages.videoAnalyticsPopoverViewersTitle)}
          </H1_TextXs>
          <Tooltip
            placement="right"
            title={intl.formatMessage(channelsMessages.videoAnalyticsPopoverViewersTooltip)}
          >
            <i className="fa-light fa-circle-exclamation" />
          </Tooltip>
        </H1_FlexRow>
        <H1_TextMiddle>{video.viewers || 0}</H1_TextMiddle>
      </H1_FlexColumn>
      <Separator />
      <H1_FlexColumn>
        <H1_FlexRow align="center" gap="15px">
          <H1_TextXs color={theme.gray8}>
            {intl.formatMessage(channelsMessages.videoAnalyticsPopoverRemixedTitle)}
          </H1_TextXs>
          <Tooltip
            placement="right"
            title={intl.formatMessage(channelsMessages.videoAnalyticsPopoverRemixedTooltip)}
          >
            <i className="fa-light fa-circle-exclamation" />
          </Tooltip>
        </H1_FlexRow>
        <H1_TextMiddle>{video.remixed || 0}</H1_TextMiddle>
      </H1_FlexColumn>
    </H1_FlexColumn>
  );
};

export default VideoAnalyticsPopover;
