import { defineMessages } from "react-intl";

export const integrationsMessages = defineMessages({
  headerTitle: {
    id: `integrations.headline`,
    defaultMessage: "Integrations"
  },
  apiTitle: {
    id: `integrations.api.title`,
    defaultMessage: "API"
  },
  apiContent: {
    id: `integrations.api.content`,
    defaultMessage:
      "Expand and customize your content creation capabilities with our API, enabling seamless integration and data management."
  },
  zappierTitle: {
    id: `integrations.zappier.title`,
    defaultMessage: "Zapier"
  },
  zappierContent: {
    id: `integrations.zappier.content`,
    defaultMessage:
      "Connect Hour One to other applications via Zapier to automate content workflows and boost productivity across your tools."
  },
  gmailTitle: {
    id: `integrations.gmail.title`,
    defaultMessage: "Gmail"
  },
  gmailContent: {
    id: `integrations.gmail.content`,
    defaultMessage:
      "Streamline your team’s email communications with Hour One, enhancing collaboration and content delivery."
  },
  chatGPTTitle: {
    id: `integrations.chatGPT.title`,
    defaultMessage: "ChatGPT Plugin"
  },
  chatGPTContent: {
    id: `integrations.chatGPT.content`,
    defaultMessage: "Integrate AI-driven conversational capabilities to content creation process."
  },
  slackTitle: {
    id: `integrations.slack.title`,
    defaultMessage: "Slack"
  },
  slackContent: {
    id: `integrations.slack.content`,
    defaultMessage:
      "Facilitate smoother communication and project updates among your content team using Hour One videos."
  },
  comingSoon: {
    id: `integrations.coming-soon`,
    defaultMessage: "Coming soon"
  },
  cardButtonText: {
    id: `integrations.button-text`,
    defaultMessage: "Try now"
  }
});
