import {
  AvatarGroup,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Input,
  Spacer,
  Tooltip
} from "@nextui-org/react";

import React, { useMemo, useState } from "react";

import UserCell from "app/components/teams/InviteMemberNextUIModal/UserCell";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { Member, PlanEnum, WorkspaceRole } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { isValidEmail } from "app/utils/helpers";
import { inviteTeamMessages } from "app/components/teams/messages";
import { useIntl } from "react-intl";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { useAuth } from "app/auth/useAuth";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import useNotifications from "app/hooks/useNotifications";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import AvatarInitials from "app/components/common/AvatarInitials";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;
    border-radius: 12px;
  }
  .ant-modal-close {
    z-index: 11; /* default of NextUI is also 10 so must override or it unclickable */
  }
`;

const MaxHeightFlexColumn = styled(H1_FlexColumn)`
  max-height: 300px;
`;

interface InviteMemberNextUIModalProps {
  visible: boolean;
  onClose: () => void;
}
const InviteMemberNextUIModal = ({ visible, onClose }: InviteMemberNextUIModalProps) => {
  const [role, setRole] = useState(WorkspaceRole.member);
  const [emailValue, setEmailValue] = useState("");
  const [isCurrentEmailValid, setIsCurrentEmailValid] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { currentPlan } = useCurrentPlan();
  const showPaymentModal = useUpgradeModal();
  const { user } = useAuth();
  const theme = useTheme();
  const { notifyMessages } = useNotifications();
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const numberOfMembers = currentWorkspace?.members?.length || 0;
  const totalAllowedMembers: number = currentWorkspace?.max_seats as number;
  const isReachedMaxNumber = numberOfMembers >= totalAllowedMembers;

  const currentRoleText = useMemo(() => {
    switch (role) {
      case WorkspaceRole.viewer:
        return formatMessage(inviteTeamMessages.roleViewer);
      case WorkspaceRole.member:
      default:
        return formatMessage(inviteTeamMessages.roleEditor);
    }
  }, [role]);
  const roleItems = useMemo(() => {
    const result = [
      {
        key: "editor",
        onClick: () => setRole(WorkspaceRole.member),
        label: <H1_TextSmall>{formatMessage(inviteTeamMessages.roleEditor)}</H1_TextSmall>
      },
      {
        key: "viewer",
        onClick: () => setRole(WorkspaceRole.viewer),
        label: <H1_TextSmall>{formatMessage(inviteTeamMessages.roleViewer)}</H1_TextSmall>
      }
    ];
    return result;
  }, []);
  // Memoize the user list to avoid re-rendering when changing the selected keys
  const userList = useMemo(() => {
    const length = currentWorkspace?.members.length || 0;
    return currentWorkspace?.members.map((member: Member, index: number) => (
      <H1_FlexColumn flex="0 0 auto" margin="8px 0 0 0" gap="8px" key={member.email}>
        <UserCell avatar={member.picture || ""} name={member.nickname || member.email || ""} />
        <ConditionalRender condition={index < length}>
          <Divider />
        </ConditionalRender>
      </H1_FlexColumn>
    ));
  }, [currentWorkspace?.members]);

  const avatarsList = useMemo(() => {
    return currentWorkspace?.members.slice(0, 10).map((member: Member) => (
      <Tooltip content={member.nickname} key={member.email} placement="bottom">
        <AvatarInitials src={member.picture} text={member.nickname || member.email || ""} />
      </Tooltip>
    ));
  }, [currentWorkspace?.members]);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmailValue(value);
    const emailValid = isValidEmail(value) && value !== user?.email;
    setIsCurrentEmailValid(emailValid);
  };

  const onUpgrade = () => {
    const upgradeText = formatMessage(inviteTeamMessages.upgradeTitle);
    const source = "inviteTeam";
    const targetPlan =
      currentPlan === PlanEnum.business ||
      currentPlan === PlanEnum.enterprise ||
      currentPlan === PlanEnum.advanced
        ? PlanEnum.enterprise
        : PlanEnum.business;
    showPaymentModal({
      source,
      requestedPlans: [targetPlan],
      targetPlan: PlanEnum.business,
      upgradeText
    });
  };

  const onInviteMember = () => {
    if (isReachedMaxNumber) {
      onUpgrade();
      return;
    }
    if (checkForEmailDuplication(emailValue)) {
      notifyMessages([
        {
          message: formatMessage(inviteTeamMessages.emailAddressDuplicated),
          type: "error"
        }
      ]);
    } else {
      dispatch(
        workspacesActions.inviteMembersToWorkspaceRequest({
          workspaceId: currentWorkspace?.id as string,
          members: [{ email: emailValue, role }]
        })
      );

      notifyMessages([
        {
          message: formatMessage(inviteTeamMessages.inviteTeamMemberNotification, {
            email: emailValue
          })
        }
      ]);
    }
    setEmailValue("");
  };

  const checkForEmailDuplication = (value: string): boolean => {
    return currentWorkspace?.members.some((member: Member) => member.email === value) || false;
  };

  return (
    <Modal
      width="400px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon size="40px" />}
    >
      <Card className="max-w-[400px]">
        <CardHeader className="justify-center px-6 pb-0 pt-6">
          <H1_FlexColumn align="center">
            <AvatarGroup isBordered size="sm">
              {avatarsList}
            </AvatarGroup>
            <Spacer y={2} />
            <H1_TextSubtitle>
              {formatMessage(inviteTeamMessages.InviteToWorkspaceHeader)}
            </H1_TextSubtitle>
            <H1_TextSmall textAlign="center" color={theme.gray7}>
              {formatMessage(inviteTeamMessages.InviteToWorkspaceSubHeader)}
            </H1_TextSmall>
          </H1_FlexColumn>
        </CardHeader>
        <CardBody className="p-6">
          <H1_FlexRow align="flex-end" gap="8px">
            <Input
              endContent={
                <NextDropdown items={roleItems}>
                  <Button
                    size="sm"
                    variant="light"
                    endContent={<i className="fal fa-chevron-down" />}
                  >
                    {currentRoleText}
                  </Button>
                </NextDropdown>
              }
              label={formatMessage(inviteTeamMessages.emailAddressLabel)}
              labelPlacement="outside"
              placeholder={formatMessage(inviteTeamMessages.emailAddressPlaceholder)}
              onChange={onChangeEmail}
              value={emailValue}
            />
            <ConditionalRender condition={isCurrentEmailValid}>
              <Button
                color={isReachedMaxNumber ? "warning" : "primary"}
                className={isReachedMaxNumber ? "text-gray-1" : ""}
                size="md"
                onClick={onInviteMember}
                startContent={isReachedMaxNumber ? <i className="fas fa-crown" /> : null}
              >
                {formatMessage(inviteTeamMessages.inviteButton)}
              </Button>
            </ConditionalRender>
          </H1_FlexRow>
          <Spacer y={4} />
          <H1_TextSmall margin="0 0 5px 0" color={theme.gray8}>
            {formatMessage(inviteTeamMessages.currentMembers)}
          </H1_TextSmall>
          <MaxHeightFlexColumn overflow="auto">{userList}</MaxHeightFlexColumn>
        </CardBody>
        <CardFooter className="justify-end gap-2 pb-6 pr-6">
          <Button size="sm" variant="flat" onClick={onClose}>
            {formatMessage(inviteTeamMessages.closeButton)}
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};

export default InviteMemberNextUIModal;
