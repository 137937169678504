import { createSelector } from "reselect";
import { RootState } from "app/store/store";

const layoutList = (state: RootState) => state.layouts.layouts;
const currentTemplate = (state: RootState) => state.templates.currentTemplate;

const getCurrentTemplateLayouts = createSelector(
  [currentTemplate, layoutList],
  (template, layouts) => {
    const availableLayouts = template?.layouts;
    if (availableLayouts) {
      return layouts
        .filter((lay) => availableLayouts.includes(lay.id))
        .map((layout) => {
          return {
            ...layout,
            image: layout.thumbnail_image,
            video: layout.thumbnail_video,
            available: true
          };
        });
    }
    return [];
  }
);

const getLayoutCardsById = createSelector(
  [layoutList, (state, layoutId) => layoutId],
  (layouts, layoutId) => {
    if (layouts.length > 0 && layoutId) {
      const currentLayout = layouts.find((layout) => layout.id === layoutId);
      return currentLayout?.cards || [];
    }
    return [];
  }
);

export default {
  getCurrentTemplateLayouts,
  getLayoutCardsById
};
