import React, { useMemo } from "react";
import styled from "styled-components";
import { fetchingStatus } from "app/utils/helpers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FeatureFlag, FetchStatus } from "app/types";
import { IntlShape, useIntl } from "react-intl";
import { AppDispatch } from "app/store/store";
import { augmentedSelectionMessages } from "app/components/editor/scene/AugmentedSelection/messages";
import { AugmentedSelection } from "app/types/services";
import { scenesActions } from "app/store/slices/scenes.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import ProfessionalImage from "app/assets/emojis/professional.png";
import SeriousImage from "app/assets/emojis/serious.png";
import HappyImage from "app/assets/emojis/happy.png";
import YouthfulImage from "app/assets/emojis/youthful.png";
import FunImage from "app/assets/emojis/fun.png";
import "app/components/editor/scene/AugmentedSelection/AugmentedDropdown.scss";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button } from "@nextui-org/react";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import NextDropdown from "app/components/common/NextUI/Dropdown/NextDropdown";
import { sceneMessages } from "app/components/editor/scene/sceneMessages";
import NextUITooltip from "app/components/common/NextUI/Tooltip/NextUITooltip";

const EmojiImg = styled.img`
  width: 18px;
  height: 18px;
`;

const augmentedSelections = (
  intl: IntlShape,
  dispatch: AppDispatch,
  sceneId: string,
  source: string,
  onUseScriptWizard?: () => void
) => [
  {
    key: "script-wizard",
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.scriptWizard)}</H1_TextSmall>
    ),
    isDisabled: true,
    showDivider: true
  },
  {
    key: AugmentedSelection.shorten,
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.makeItShorter)}</H1_TextSmall>
    ),
    startContent: <i className="fa-sharp fa-solid fa-wand-magic-sparkles" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.shorten,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.longer,
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.makeItLonger)}</H1_TextSmall>
    ),
    startContent: <i className="fa-sharp fa-solid fa-wand-magic-sparkles" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.longer,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.oneSentence,
    label: (
      <H1_TextSmall>
        {intl.formatMessage(augmentedSelectionMessages.makeItOneSentence)}
      </H1_TextSmall>
    ),
    startContent: <i className="fa-sharp fa-solid fa-wand-magic-sparkles" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.oneSentence,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.rephraseFree,
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.rephraseEntirely)}</H1_TextSmall>
    ),
    startContent: <i className="fa-sharp fa-solid fa-wand-magic-sparkles" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.rephraseFree,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.rephrasePerson,
    label: (
      <H1_TextSmall>
        {intl.formatMessage(augmentedSelectionMessages.rephraseAs1stPerson)}
      </H1_TextSmall>
    ),
    startContent: <i className="fa-sharp fa-solid fa-wand-magic-sparkles" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.rephrasePerson,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: "change-tone",
    label: <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.changeTone)}</H1_TextSmall>,
    isDisabled: true,
    showDivider: true
  },
  {
    key: AugmentedSelection.happy,
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.makeItHappier)}</H1_TextSmall>
    ),
    startContent: <EmojiImg src={HappyImage} alt="" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.happy,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.serious,
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.makeItFormal)}</H1_TextSmall>
    ),
    startContent: <EmojiImg src={SeriousImage} alt="" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.serious,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.professional,
    label: (
      <H1_TextSmall>
        {intl.formatMessage(augmentedSelectionMessages.makeItProfessional)}
      </H1_TextSmall>
    ),
    startContent: <EmojiImg src={ProfessionalImage} alt="" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.professional,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.young,
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.makeItYouthful)}</H1_TextSmall>
    ),
    startContent: <EmojiImg src={YouthfulImage} alt="" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.young,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  },
  {
    key: AugmentedSelection.funny,
    label: (
      <H1_TextSmall>{intl.formatMessage(augmentedSelectionMessages.makeItMoreFun)}</H1_TextSmall>
    ),
    startContent: <EmojiImg src={FunImage} alt="" />,
    onClick: () => {
      dispatch(
        scenesActions.updateSceneWithAugmentedTextRequest({
          sceneId,
          augmentSelection: AugmentedSelection.funny,
          source
        })
      );
      if (onUseScriptWizard) {
        onUseScriptWizard();
      }
    }
  }
];

const StyledNextDropdown = styled(NextDropdown)`
  && i {
    color: ${({ theme }) => theme.blue2};
  }
`;

const HoverButton = styled(Button)<{ disabled?: boolean }>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  &:hover {
    opacity: 0.6;
    background-color: transparent;
  }
`;

interface AugmentedDropdownProps {
  sceneId: string;
  source: string;
  transcript: string;
  idx?: number;
  onUseScriptWizard?: () => void;
}

const AugmentedDropdown = ({
  idx = 0,
  transcript,
  sceneId,
  source,
  onUseScriptWizard
}: AugmentedDropdownProps) => {
  const dispatch = useAppDispatch();
  const { isWriteWorkspaceAllow } = usePermissions();
  const flags = useFlags();
  const intl = useIntl();
  const augmentedSceneStatus: FetchStatus = useAppSelector(
    (state) => state.scenes.augmentedSceneStatus
  );

  const isAugmentedSceneLoading = augmentedSceneStatus === fetchingStatus.loading;

  const menuItems = useMemo(() => {
    const originItems = augmentedSelections(intl, dispatch, sceneId, source, onUseScriptWizard);
    return originItems;
  }, [sceneId, source, transcript, flags[FeatureFlag.generateTranscript]]);

  return (
    <H1_FlexColumn data-auto-id={`script-wizard-dropdown-${idx}`}>
      <NextUITooltip content={intl.formatMessage(sceneMessages.scriptWizard)}>
        <div>
          <StyledNextDropdown
            withWhiteBackground
            isDisabled={isAugmentedSceneLoading || !transcript || !isWriteWorkspaceAllow}
            items={menuItems}
          >
            <HoverButton
              variant="light"
              isIconOnly
              startContent={<i className="far fa-wand-magic-sparkles" />}
            />
          </StyledNextDropdown>
        </div>
      </NextUITooltip>
    </H1_FlexColumn>
  );
};

export default AugmentedDropdown;
