export enum RealMeMainDisplay {
  sources = "sources",
  destinations = "destinations",
  bio = "bio"
}

export enum SourceName {
  realsme = "realsme",
  slack = "slack",
  linkedin = "linkedin",
  twitter = "twitter",
  openai = "openai",
  bio = "bio",
  video = "video"
}

export enum AiType {
  friendly = "friendly",
  unfriendly = "unfriendly"
}

export type Source = {
  name: string;
};

export type BioInformation = {
  slug?: string;
  linkedin_url: string;
  req_id?: string;
  playback_id?: string;
  draft_id?: string;
};

export type RealsMeDraftInfo = {
  sources: Source[];
  videoCreated: boolean;
};
