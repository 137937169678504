import ConditionalRender from "app/components/common/ConditionalRender";
import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "antd";
import { videosActions } from "app/store/slices/videos.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { defineMessages, useIntl } from "react-intl";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { videosGlobalSelectors } from "app/store/adapters/adapters";
import { PatchOperation, Video } from "app/types";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button, Chip } from "@nextui-org/react";

const messages = defineMessages({
  newTag: {
    id: "videos.tags.newTag",
    defaultMessage: "+ New tag"
  }
});

const StyledTag = styled(Chip)`
  font-size: 12px;
  height: 20px;
  margin-top: 2px;
  padding: 3px 5px;
  color: ${({ theme }) => theme.gray1};
  background-color: ${({ theme }) => theme.gray7};
  > span:first-child {
    margin-bottom: 1px;
  }
  i {
    font-size: 14px;
    color: ${({ theme }) => theme.gray1};
    margin-right: 5px;
    margin-bottom: 8px;
    &::before {
      margin-bottom: 2px;
    }
  }
`;

const NewTagButton = styled(Button)`
  font-size: 12px;
  height: 20px;
  && {
    margin-top: 2px;
    border-radius: 8px;
    border: 1px dashed ${({ theme }) => theme.gray5};
    background-color: ${({ theme }) => theme.gray3};
    padding: 3px 5px;
  }
`;

const StyledInput = styled(Input)`
  &&,
  &:focus,
  &:hover {
    border: 1px dashed var(--gray-05, #d9d9d9);
    background-color: var(--gray-02, "#fafafa");
    color: var(--gray-09, #262626);
    height: 24px;
    min-width: 90px;
    width: min-content;
    max-width: fit-content;
    font-size: 12px;
    border-radius: 2px;
    outline: none;
    box-shadow: none;
  }
`;

const MAX_TAGS = 1;

interface VideoTagsProps {
  isOwner?: boolean;
  videoId: string;
}

const VideoTags = ({ isOwner = false, videoId }: VideoTagsProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newTagValue, setNewTagValue] = useState<string | undefined>(undefined);
  const video = useAppSelector((state) =>
    videosGlobalSelectors.selectById(state, videoId)
  ) as Video;

  if (!video) {
    return null;
  }

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isMaxTags = !!(video?.tags && video?.tags.length < MAX_TAGS);

  const onCreateNewTag = (value: string) => {
    const { id } = video;
    const operations = [{ op: "replace", path: "tags", value: [...(video?.tags || []), value] }];
    dispatch(analyticsEvents.createNewTag({ name: value }));
    dispatch(
      videosActions.updateVideoRequest({ videoId: id, operations: operations as PatchOperation[] })
    );
    dispatch(workspacesActions.createChannelRequest({ name: value }));
    setEditMode(false);
    setNewTagValue(undefined);
  };

  const isDuplicatedTag = (value: string): boolean => {
    return (video.tags || []).some((tag: string) => tag === value);
  };

  const handleTagChange = (value: string) => {
    if (!value || isDuplicatedTag(value)) {
      setEditMode(false);
      return;
    }

    onCreateNewTag(value);
  };

  const onNewTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleTagChange(value.trim());
  };

  const onEnter = (e: any) => {
    const { value } = e.target;
    handleTagChange(value.trim());
  };

  const onRemoveTag = (tag: string) => {
    const operations = [{ op: "delete", path: "tags", value: tag }];
    dispatch(
      videosActions.updateVideoRequest({
        videoId: video?.id as string,
        operations: operations as PatchOperation[]
      })
    );
  };

  if (!isOwner && !video.tags?.length) {
    return null;
  }

  return (
    <H1_FlexRow wrap="wrap" gap="10px" align="center" justify="flex-start">
      <ConditionalRender condition={Array.isArray(video.tags) && video.tags.length > 0}>
        {video.tags?.map((tag?: string) => {
          if (!tag) return <></>;
          return (
            <StyledTag
              endContent={<i className="fal fa-circle-xmark" />}
              onClose={() => onRemoveTag(tag)}
              closable={isOwner}
              key={tag}
            >
              {tag}
            </StyledTag>
          );
        })}
      </ConditionalRender>
      <ConditionalRender condition={editMode && isOwner}>
        <StyledInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          size="small"
          value={newTagValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTagValue(e.target.value)}
          onBlur={onNewTagChange}
          onPressEnter={onEnter}
        />
      </ConditionalRender>
      <ConditionalRender condition={!editMode && isOwner && isMaxTags}>
        <NewTagButton size="sm" onClick={() => setEditMode(true)}>
          {intl.formatMessage(messages.newTag)}
        </NewTagButton>
      </ConditionalRender>
    </H1_FlexRow>
  );
};

export default VideoTags;
