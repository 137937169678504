import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { paymentsActions } from "app/store/slices/payments.slice";
import styled, { useTheme } from "styled-components";
import { Avatar, Image, Table, Tag, Tooltip } from "antd";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { dateSorter, fetchingStatus, getTimeAgo } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import useModal, { ModalName } from "app/hooks/useModal";
import { ColumnsType } from "antd/lib/table";
import { fallbackPlaceholder } from "app/assets/images/placeholder";
import { AlignType } from "rc-table/lib/interface";
import { useNavigate } from "react-router-dom";
import { MinuteAnalytic, MinutesUsageType, VideoUsageAnalytic } from "app/types/payments";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useIntl } from "react-intl";
import { minutesModalMessages } from "app/pages/profile/messages";
import { H1_Select } from "app/components/_Infrastructure/design-system/select";
import { BaseOptionType } from "antd/lib/select";
import { debounce } from "lodash";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const PlayButton = styled(Button)`
  background-color: ${({ theme }) => theme.blue1};
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const VideosTable = styled(Table)`
  flex: 1;
  width: 100%;
  overflow-y: auto;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  margin-top: 0.3125rem;
  .ant-table-pagination {
    width: 100%;
    text-align: center;
    &.ant-pagination {
      justify-content: center;
    }
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .ant-table {
    background-color: transparent;
  }
  && .ant-table-thead > tr > th {
    border-bottom: 1px solid ${(props) => props.theme.gray5};
    background-color: ${(props) => props.theme.gray1};
    padding-left: 0;
    position: sticky;
    top: 0;
    z-index: 11;
  }
  .ant-table table {
    border-spacing: 0 0.625rem;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0.5625rem 0.5rem;
  }
  & .ant-table-column-sorters {
    flex-direction: row-reverse;
    gap: 0.3125rem;
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
        transition: all 0.5s ease-in-out;
        background-color: transparent;
        height: 5.3125rem;
      }
      &:hover {
        td {
          background-color: ${(props) =>
            props.theme.gray1} !important; /* &&& doesn't work with &:hover */
          ${NameText} {
            text-decoration: underline;
          }
        }
      }
      padding: 0.625rem 1rem 0.625rem;
      &:not(:last-child) td {
        border-bottom: 1px solid ${(props) => props.theme.gray4};
      }
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

const StyledTag = styled(Tag)`
  border-radius: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.gray1};
  padding: 0 7px;
`;

const ImageContainer = styled.div`
  height: 4.0625rem;
  width: 7.25rem;
  img {
    border: 1px solid ${(props) => props.theme.gray3};
    object-fit: contain;
    border-radius: 8px;
    overflow: hidden;
  }
`;

interface MinutesModalProps {
  onClose: () => void;
  visible: boolean;
}
const MinutesModal = ({ onClose, visible }: MinutesModalProps) => {
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth() + 1);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const minutesAnalyticsStatus = useAppSelector((state) => state.payments.minutesAnalyticsStatus);
  const analyticsMetadataStatus = useAppSelector((state) => state.payments.analyticsMetadataStatus);
  const analyticsMetadata: VideoUsageAnalytic[] = useAppSelector(
    (state) => state.payments.analyticsMetadata
  );

  const minutesAnalytics: MinuteAnalytic[] = useAppSelector(
    (state) => state.payments.minutesAnalytics
  );

  const isLoading = minutesAnalyticsStatus === fetchingStatus.loading;
  const isUsageLoading = analyticsMetadataStatus === fetchingStatus.loading;
  const isTodayAvailable = useMemo(
    () =>
      analyticsMetadata.find(
        (item) => item.year === new Date().getFullYear() && item.month === new Date().getMonth() + 1
      ),
    [analyticsMetadata]
  );

  const videoYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const uniqueYears = new Set(analyticsMetadata.map((item) => item.year));

    uniqueYears.add(currentYear);

    return Array.from(uniqueYears)
      .sort((a, b) => a - b)
      .map((year) => ({
        value: year,
        label: year
      }));
  }, [analyticsMetadata]);

  const videoMonthsTexts = useMemo(() => {
    return [
      formatMessage(minutesModalMessages.jan),
      formatMessage(minutesModalMessages.feb),
      formatMessage(minutesModalMessages.march),
      formatMessage(minutesModalMessages.april),
      formatMessage(minutesModalMessages.may),
      formatMessage(minutesModalMessages.june),
      formatMessage(minutesModalMessages.july),
      formatMessage(minutesModalMessages.aug),
      formatMessage(minutesModalMessages.sep),
      formatMessage(minutesModalMessages.oct),
      formatMessage(minutesModalMessages.nov),
      formatMessage(minutesModalMessages.dec)
    ];
  }, []);

  const videoMonths: BaseOptionType[] = useMemo(() => {
    const thisYear = new Date().getFullYear();

    let data =
      analyticsMetadata
        .filter((item) => item.year === currentYear)
        .map((item) => ({
          value: item.month,
          label: videoMonthsTexts[item.month - 1]
        })) || [];

    if (thisYear === currentYear && !isTodayAvailable) {
      const thisMonth = new Date().getMonth();
      data = [...data, { value: thisMonth + 1, label: videoMonthsTexts[thisMonth] }];
      data.sort((a, b) => a.value - b.value);
    }

    return data;
  }, [analyticsMetadata, currentYear, isTodayAvailable]);

  useEffect(() => {
    if (videoMonths.length > 0 && visible) {
      setCurrentMonth(videoMonths[0].value);
    }
  }, [videoMonths]);

  useEffect(() => {
    if (visible) {
      const month = currentMonth;
      const year = currentYear;
      dispatch(paymentsActions.getMinutesAnalyticsRequest({ month, year }));
    } else {
      onChooseTodayDate();
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    const debouncedFetch = debounce(() => {
      dispatch(
        paymentsActions.getMinutesAnalyticsRequest({ month: currentMonth, year: currentYear })
      );
    }, 500); // When changing year, month is also being changes and we want to prevent 2 calls

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentYear, currentMonth]);

  useEffect(() => {
    dispatch(paymentsActions.getAnalyticsMetadataRequest());
  }, [currentWorkspace?.id]);

  const playVideo = (e: React.MouseEvent, video: MinuteAnalytic) => {
    e.stopPropagation();
    if (video.data.video_available) {
      dispatch(analyticsEvents.videoPlay({ source: "minutesUsed", videoId: video.data?.id }));
      openModal(ModalName.videoReady, { reqId: video.data?.req_id });
    }
  };

  const onSelectDraft = (draftId: string) => {
    dispatch(analyticsEvents.editDraft({ source: "recentVideos", draftId }));
    dispatch(analyticsEvents.navigateToEditor({ source: "draft_table_item" }));
    navigate(`/editor/${draftId}`);
  };

  const onChooseTodayDate = () => {
    setCurrentYear(new Date().getFullYear());
    setCurrentMonth(new Date().getMonth() + 1);
  };

  const createdByFilters = useMemo(
    () =>
      Array.from(new Set(minutesAnalytics.map((item) => item.published_by))).map((publishedBy) => ({
        text: publishedBy,
        value: publishedBy
      })),
    [minutesAnalytics]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: formatMessage(minutesModalMessages.videoName),
        dataIndex: ["data", "title"],
        key: "title",
        render: (_: unknown, row: MinuteAnalytic) => {
          return (
            <H1_FlexRow gap="1rem" align="center">
              <ImageContainer>
                <Image
                  width="7rem"
                  height="3.9375rem"
                  src={row.data.thumbnail || fallbackPlaceholder}
                  preview={false}
                  fallback={fallbackPlaceholder}
                />
              </ImageContainer>
              <NameText $maxWidth="22.5rem" ellipsis={{ tooltip: row.data.title }}>
                {row.data.title}
              </NameText>
            </H1_FlexRow>
          );
        },
        sorter: (a: MinuteAnalytic, b: MinuteAnalytic) =>
          (a.data.title as string).localeCompare(b.data.title as string)
      },
      {
        title: formatMessage(minutesModalMessages.type),
        dataIndex: ["data", "type"],
        key: "type",
        width: "7.5rem",
        render: (_: unknown, row: MinuteAnalytic) => {
          let typeText = "";
          switch (row.data.type) {
            case MinutesUsageType.platformVideo:
              typeText = formatMessage(minutesModalMessages.studio);
              break;
            case MinutesUsageType.apiVideo:
              typeText = formatMessage(minutesModalMessages.api);
              break;
            case MinutesUsageType.playgroundVideo:
              typeText = formatMessage(minutesModalMessages.playground);
              break;
            default:
          }
          return <StyledTag color={theme.blue2}>{typeText}</StyledTag>;
        },
        sorter: (a: MinuteAnalytic, b: MinuteAnalytic) => a.data.type.localeCompare(b.data.type)
      },
      {
        title: formatMessage(minutesModalMessages.duration),
        dataIndex: "amount",
        key: "amount",
        width: "7.25rem",
        render: (amount: number) => {
          return <H1_TextSmall>{amount}s</H1_TextSmall>;
        },
        sorter: (a: MinuteAnalytic, b: MinuteAnalytic) => a.amount - b.amount
      },
      {
        title: formatMessage(minutesModalMessages.version),
        dataIndex: ["data", "draft_version"],
        key: "draft_version",
        width: "9.375rem",
        render: (draftVersion: string) => {
          return <H1_TextSmall>{draftVersion}</H1_TextSmall>;
        },
        sorter: (a: MinuteAnalytic, b: MinuteAnalytic) =>
          a.data.draft_version.localeCompare(b.data.draft_version)
      },
      {
        title: formatMessage(minutesModalMessages.member),
        dataIndex: ["published_by"],
        key: "published_by",
        filters: createdByFilters,
        onFilter: (value, record) => record.published_by === value,
        width: "9.375rem",
        render: (createdBy: string, row: MinuteAnalytic) => {
          return (
            <Tooltip title={createdBy}>
              <Avatar src={row.creator_avatar}>
                <span>{createdBy?.toUpperCase().charAt(0)}</span>
              </Avatar>
            </Tooltip>
          );
        },
        sorter: (a: MinuteAnalytic, b: MinuteAnalytic) =>
          a.published_by.localeCompare(b.published_by)
      },
      {
        title: formatMessage(minutesModalMessages.createdAt),
        dataIndex: "created_at",
        key: "created_at",
        width: "6.25rem",
        render: (createdDate: string) => {
          return <H1_TextSmall>{getTimeAgo(createdDate)}</H1_TextSmall>;
        },
        sorter: (a: MinuteAnalytic, b: MinuteAnalytic) =>
          dateSorter(a.created_at as string, b.created_at as string)
      },
      {
        dataIndex: "",
        align: "center" as AlignType,
        key: "playVideo",
        width: "4.6875rem",
        render: (_: unknown, row: MinuteAnalytic) => {
          return (
            <H1_FlexRow justify="center" align="center">
              <PlayButton
                isIconOnly
                onClick={(e) => playVideo(e, row)}
                startContent={
                  <H1_Icon isCursorPointer size="17px" color={theme.gray1} icon="fas fa-play" />
                }
              />
            </H1_FlexRow>
          );
        }
      }
    ],
    [minutesAnalytics]
  );

  return (
    <StyledModal
      width="1223px"
      open={visible}
      footer={null}
      bodyStyle={{ height: "640px" }}
      centered
      onCancel={onClose}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="50px"
      $alignItems="flex-start"
      $justifyContent="space-between"
    >
      <H1_FlexRow justify="space-between" align="center" width="100%">
        <H1_TextSubtitle color={theme.gray8}>
          {formatMessage(minutesModalMessages.title)}
        </H1_TextSubtitle>
        <ConditionalRender condition={isUsageLoading}>
          <CircleLoader size="34px" />
        </ConditionalRender>
        <ConditionalRender condition={!isUsageLoading}>
          <H1_FlexRow align="center" gap="15px">
            <ConditionalRender condition={!!isTodayAvailable}>
              <Button variant="light" size="sm" onClick={onChooseTodayDate}>
                {formatMessage(minutesModalMessages.today)}
              </Button>
            </ConditionalRender>
            <H1_Select options={videoMonths} value={currentMonth} onChange={setCurrentMonth} />
            <H1_Select options={videoYears} value={currentYear} onChange={setCurrentYear} />
          </H1_FlexRow>
        </ConditionalRender>
      </H1_FlexRow>
      <ConditionalRender condition={isLoading}>
        <CircleLoader />
      </ConditionalRender>
      <ConditionalRender condition={!isLoading}>
        <VideosTable
          size="middle"
          columns={columns}
          rowKey={(record) => (record as MinuteAnalytic).data.id as string}
          dataSource={minutesAnalytics}
          pagination={{ hideOnSinglePage: true }}
          onRow={(record) => ({
            onClick: () => {
              (record as MinuteAnalytic).data.draft_id &&
                !(record as MinuteAnalytic).data.draft_archived &&
                onSelectDraft((record as MinuteAnalytic).data.draft_id as string);
            }
          })}
        />
      </ConditionalRender>
    </StyledModal>
  );
};

export default MinutesModal;
