import styled from "styled-components";
import PaymentModalGuy from "app/assets/pricing/paymentModalGuy.png";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const LeftFlexColumn = styled(H1_FlexColumn)`
  background-color: #2c2645;
  min-height: 610px;
  @media (max-width: ${SMALL_SCREEN_PX}) {
    display: none;
  }
`;

const ImageFlexRow = styled(H1_FlexRow)`
  background-image: url(${PaymentModalGuy});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  background-position-x: center;
  width: 347px;
  height: 320px;
  margin-bottom: 65px;
`;

const PaymentModalLeftSide = () => {
  return (
    <LeftFlexColumn justify="center" align="center" width="463px" flex="0 0 auto">
      <ImageFlexRow />
    </LeftFlexColumn>
  );
};

export default PaymentModalLeftSide;
