import React from "react";

import "app/pages/HomePage/HomePage.scss";
import HomePage from "app/pages/HomePage/HomePage";
import useSmallScreen from "app/hooks/useSmallScreen";
import MobileGeneralPage from "app/components/common/MobileGeneralPage";

const ProxyPage = React.memo(() => {
  const { isSmallScreen } = useSmallScreen();

  if (isSmallScreen) {
    return <MobileGeneralPage />;
  }
  return <HomePage />;
});

ProxyPage.displayName = "ProxyPage";
export default ProxyPage;
