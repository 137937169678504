import { defineMessages } from "react-intl";

export const mediaLibraryMessages = defineMessages({
  headerTitle: {
    id: `media-library-modal.headline`,
    defaultMessage: "Media Library"
  },
  uploadButton: {
    id: `media-library-modal.upload-button`,
    defaultMessage: "Upload"
  },
  selectButton: {
    id: `media-library-modal.select-button`,
    defaultMessage: "Select"
  },
  root: {
    id: `media-library-modal.root`,
    defaultMessage: "Media Files"
  },
  myMediaTab: {
    id: `media-library-modal.my-media-tab`,
    defaultMessage: "My Media"
  },
  stockTab: {
    id: `media-library-modal.stock-tab`,
    defaultMessage: "Stock Images"
  },
  houroneTab: {
    id: `media-library-modal.hourone-tab`,
    defaultMessage: "Hour One Stock"
  },
  pexelsTab: {
    id: `media-library-modal.pexels-tab`,
    defaultMessage: "Stock Videos"
  },
  cancelButton: {
    id: `media-library-modal.cancel-button`,
    defaultMessage: "Cancel"
  },
  allMedia: {
    id: `media-library-modal.all-media`,
    defaultMessage: "All Media"
  },
  images: {
    id: `media-library-modal.images`,
    defaultMessage: "Images"
  },
  videos: {
    id: `media-library-modal.videos`,
    defaultMessage: "Videos"
  },
  sortDate: {
    id: `media-library-modal.sort-date`,
    defaultMessage: "Date Uploaded"
  },
  sortName: {
    id: `media-library-modal.sort-name`,
    defaultMessage: "Name"
  },
  searchMedia: {
    id: `media-library-modal.search-media`,
    defaultMessage: "Search Media"
  },
  moveMedia: {
    id: `media-library-modal.move-media`,
    defaultMessage: "Move to folder"
  },
  deleteMedia: {
    id: `media-library-modal.delete-media`,
    defaultMessage: "Delete"
  },
  createFolder: {
    id: `media-library-modal.create-folder`,
    defaultMessage: "Create Folder"
  },
  convertPresentationNotification: {
    id: `media-library-modal.convert-presentation-notification`,
    defaultMessage: "Converting a presentation file into individual images"
  },
  onePresentationAtTimeNotification: {
    id: `media-library-modal.one-presentation-at-time-notification`,
    defaultMessage: "You can only upload one presentation at a time"
  },
  emptyStateText: {
    id: `media-library-modal.empty-state-text`,
    defaultMessage: "No Media"
  },
  emptyStateDescription: {
    id: `media-library-modal.empty-state-description`,
    defaultMessage: "Upload any image or video"
  },
  emptyStateButtonText: {
    id: `media-library-modal.empty-state-button-text`,
    defaultMessage: "Upload"
  },
  fileSizeReducedHeader: {
    id: `media-library-modal.file-size-reduced-header`,
    defaultMessage: `File resolution can be up to 4K.`
  },
  fileSizeReduced: {
    id: `media-library-modal.file-size-reduced`,
    defaultMessage: `Image will be resized and uploaded.`
  }
});

export const mediaItemActionsLibraryMessages = defineMessages({
  editName: {
    id: `media-library-modal.item.actions.edit-name`,
    defaultMessage: "Rename"
  },
  download: {
    id: `media-library-modal.item.actions.download`,
    defaultMessage: "Download"
  },
  delete: {
    id: `media-library-modal.item.actions.delete`,
    defaultMessage: "Delete"
  },
  move: {
    id: `media-library-modal.item.actions.move`,
    defaultMessage: "Move"
  },
  zoom: {
    id: `media-library-modal.item.actions.zoom`,
    defaultMessage: "Preview"
  }
});

export const mediaLibraryFiltersMessages = defineMessages({
  filterTitle: {
    id: `media-library-modal.filters.title`,
    defaultMessage: "Type"
  },
  lastModified: {
    id: `media-library-modal.sorters.last`,
    defaultMessage: "Last Modified"
  },
  descending: {
    id: `media-library-modal.sorters.descending`,
    defaultMessage: "Descending"
  },
  ascending: {
    id: `media-library-modal.sorters.ascending`,
    defaultMessage: "Ascending"
  },
  name: {
    id: `media-library-modal.sorters.name`,
    defaultMessage: "Name"
  },
  sortTitle: {
    id: `media-library-modal.sorters.sort`,
    defaultMessage: "Sort"
  },
  aZ: {
    id: `media-library-modal.sorters.a-z`,
    defaultMessage: "A-Z"
  },
  zA: {
    id: `media-library-modal.sorters.z-a`,
    defaultMessage: "Z-A"
  },
  images: {
    id: `media-library-modal.sorters.images`,
    defaultMessage: "Images"
  },
  videos: {
    id: `media-library-modal.sorters.videos`,
    defaultMessage: "Videos"
  },
  folders: {
    id: `media-library-modal.sorters.folders`,
    defaultMessage: "Folders"
  },
  all: {
    id: `media-library-modal.sorters.all`,
    defaultMessage: "All"
  }
});

export const mediaLibraryFoldersModalMessages = defineMessages({
  headerTitle: {
    id: `media-library-folders-modal.title`,
    defaultMessage: "Move to folder"
  },
  folders: {
    id: `media-library-folders-modal.folders`,
    defaultMessage: `Folders`
  },
  cancelBtn: {
    id: `media-library-folders-modal.cancel-button`,
    defaultMessage: `Cancel`
  },
  moveBtn: {
    id: `media-library-folders-modal.move-button`,
    defaultMessage: `Move`
  },
  numOfItems: {
    id: `media-library-folders-modal.num-of-items`,
    defaultMessage: `{number} file`
  },
  numOfItemsPlurals: {
    id: `media-library-folders-modal.num-of-items-plurals`,
    defaultMessage: `{number} files`
  },
  root: {
    id: `media-library-folders-modal.root`,
    defaultMessage: `Root`
  }
});
