import { useContext } from "react";
import CharacterSettingsContext from "app/pages/createDigitalTwinPage/CharacterSettingsContext";

const useCharacterSettingsContext = () => {
  const characterSettingsContext = useContext(CharacterSettingsContext);
  if (!characterSettingsContext) {
    throw new Error("Out off context");
  }

  return characterSettingsContext;
};

export default useCharacterSettingsContext;
