import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTrashItemsByType } from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { TrashType } from "app/types/trash";
import { Draft, ThunkApi } from "app/types";
import { draftsActions } from "app/store/slices/drafts.slice";

const prefix = "[Trash]";

const getTrashItemsByTypeRequest = createAsyncThunk<Draft[], TrashType, ThunkApi>(
  `${prefix} getTrashItemsByTypeRequest`,
  async (TrashItemType, thunkApi) => {
    const result = await getTrashItemsByType(TrashItemType);
    const drafts = result.map((trashItem) => trashItem.item_data);
    thunkApi.dispatch(draftsActions.setAllDrafts(drafts));
    return drafts;
  },
  thunkOptions
);

export default {
  getTrashItemByTypeRequest: getTrashItemsByTypeRequest
};
