export enum ThemeMode {
  Light = "light",
  Dark = "dark"
}

export interface BaseThemeSchema {
  // application colors:
  mode: ThemeMode;

  content: string;

  // gray
  gray1: string; // #FFFFFF;
  gray2: string; // #FAFAFA
  gray3: string; // #F5F5F5
  gray4: string; // #F0F0F0
  gray5: string; // #D9D9D9
  gray6: string; // #BFBFBF
  gray7: string; // #8C8C8C
  gray8: string; // #595959
  gray9: string; // #262626
  gray10: string; // #1D1D1B
  gray11: string; // #000000
  gray12: string; // #ABCDEF

  // green
  green1: string; // #E3FCE6
  green2: string; // #BCFAC5
  green3: string; // #A2F8AE
  green4: string; // #37FC81

  // blue
  blue1: string; // #DCDDFF
  blue2: string; // #AAAAFF
  blue3: string; // #8989FF
  blue4: string; // #5A5AFF

  // orange
  orange1: string; // #FFE7CA
  orange2: string; // #FFC580
  orange3: string; // #FFAF4D
  orange4: string; // #FF9000

  // pink
  pink1: string; // #FAD4DE
  pink2: string; // #F397AE
  pink3: string; // #EE7090
  pink4: string; // #FF0061
}

export const lightTheme = {
  content: "#FFFFFF",

  // gray
  gray1: "#FFFFFF",
  gray2: "#FAFAFA",
  gray3: "#F4F4F5",
  gray4: "#E4E4E7",
  gray5: "#D4D4D8",
  gray6: "#A1A1AA",
  gray7: "#71717A",
  gray8: "#52525B",
  gray9: "#3F3F46",
  gray10: "#27272A",
  gray11: "#000000",
  gray12: "#ABCDEF",

  // green
  green1: "#E3FCE6",
  green2: "#BCFAC5",
  green3: "#A2F8AE",
  green4: "#37FC81",

  // orange
  orange1: "#FFE7CA",
  orange2: "#FFC580",
  orange3: "#FFAF4D",
  orange4: "#FF9000",

  // blue
  blue1: "#DCDDFF",
  blue2: "#AAAAFF",
  blue3: "#8989FF",
  blue4: "#5A5AFF",

  // pink
  pink1: "#FAD4DE",
  pink2: "#F397AE",
  pink3: "#EE7090",
  pink4: "#FF0061"
};

export const darkTheme = {
  content: "#18181B",
  // gray
  gray11: "#FFFFFF",
  gray10: "#FAFAFA",
  gray9: "#F4F4F5",
  gray8: "#E4E4E7",
  gray7: "#D4D4D8",
  gray6: "#A1A1AA",
  gray5: "#71717A",
  gray4: "#52525B",
  gray3: "#3F3F46",
  gray2: "#27272A",
  gray1: "#000000",
  gray12: "#18181B",

  // green
  green1: "#E3FCE6",
  green2: "#BCFAC5",
  green3: "#A2F8AE",
  green4: "#37FC81",

  // orange
  orange1: "#FFE7CA",
  orange2: "#FFC580",
  orange3: "#FFAF4D",
  orange4: "#FF9000",

  // blue
  blue1: "#DCDDFF",
  blue2: "#AAAAFF",
  blue3: "#8989FF",
  blue4: "#5A5AFF",

  // pink
  pink1: "#FAD4DE",
  pink2: "#F397AE",
  pink3: "#EE7090",
  pink4: "#FF0061"
};
