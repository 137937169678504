import { useMemo } from "react";
import { Avatar } from "@nextui-org/react";

interface AvatarInitialsProps {
  text: string;
  size?: "sm" | "md" | "lg";
  src?: string;
  className?: string;
}

const AvatarInitials = ({ text, src, size = "md", className }: AvatarInitialsProps) => {
  const initials = useMemo(() => {
    let initials = "";
    const textNoEmail = text.split("@")[0];
    if (textNoEmail.includes(" ")) {
      const words = textNoEmail.trim().split(" ");
      initials = words.length > 1 ? words[0][0] + words[words.length - 1][0] : words[0][0];
    } else if (textNoEmail.includes(".")) {
      initials = textNoEmail
        .split(".")
        .map((word) => word[0])
        .join("");
    } else if (textNoEmail.includes("+")) {
      initials = textNoEmail
        .split("+")
        .map((word) => word[0])
        .join("");
    } else {
      initials = textNoEmail[0] || "";
    }

    return initials.toUpperCase();
  }, [text]);
  const sanitizeText = useMemo(() => {
    return text.replace(/[^a-zA-Z0-9_]/g, "");
  }, [text]);

  return (
    <Avatar
      className={`${className ? className + " " : ""}flex-shrink-0`}
      size={size}
      src={src || `https://avatar.vercel.sh/${sanitizeText}.svg?text=${initials}`}
      name={initials}
    />
  );
};

export default AvatarInitials;
