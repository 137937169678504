import CharacterModal from "app/components/CharacterModal/CharacterModal";
import { useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";

enum StartupModalsOptions {
  mobile = "mobile"
}
const StartupModals = () => {
  const [currentModal, setCurrentModal] = useState<StartupModalsOptions | null>(null);
  const preferences = useAppSelector((state) => state.user.preferences);
  const mobileCondition =
    !preferences.show_on_boarding_survey_v2 &&
    preferences.show_mobile_first_time_character &&
    preferences.initial_survey;
  useEffect(() => {
    if (mobileCondition) {
      setCurrentModal(StartupModalsOptions.mobile);
    }
  }, [mobileCondition]);
  const onFinish = () => {
    setCurrentModal(null);
  };

  return (
    <>
      <CharacterModal open={currentModal === StartupModalsOptions.mobile} onFinish={onFinish} />
    </>
  );
};

export default StartupModals;
