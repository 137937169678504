import { PlanEnum } from "app/types";
import { PlanFeature } from "app/config/planFeature";
import { messages } from "app/components/editor/sideDrawers/messages";
import useModal, { ModalName } from "app/hooks/useModal";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import useUpgradeModal, { useUpgradeToEnterprise } from "app/hooks/useUpgradeModal";
import { useIntl } from "react-intl";

export const useCloneVoiceModal = () => {
  const { currentPlan } = useCurrentPlan();
  const showPaymentModal = useUpgradeModal();
  const intl = useIntl();
  const upgradeToEnterprise = useUpgradeToEnterprise();
  const { openModal } = useModal();

  const openCloneVoiceModal = (source: string, returnModal?: ModalName) => {
    if (currentPlan === PlanEnum.enterprise) {
      upgradeToEnterprise({ source });
      return;
    }

    const upgradeRequired = showPaymentModal({
      source,
      requestedFeature: PlanFeature.VoiceClone,
      targetPlan: PlanEnum.business,
      upgradeText: intl.formatMessage(messages.cloneButtonUpgradeText)
    });

    if (!upgradeRequired) {
      openModal(ModalName.cloneVoice, undefined, returnModal);
    }
  };
  return openCloneVoiceModal;
};
