import React, { useState, useEffect, SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FeatureFlag, FetchStatus } from "app/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button, Input } from "antd";
import styled from "@emotion/styled";
import ConditionalRender from "app/components/common/ConditionalRender";
import { ReactComponent as WandIcon } from "app/assets/icons-reals/Wand.svg";
import MediaLibraryTabSDTagWrap from "app/pages/mediaLibrary/StableDiffusion/TagsWrap";
import { sdPromptValidate } from "app/utils/helpers";
import { getAfterModifiers, getPrevModifiers } from "app/store/selectorsV2/media.selectors";
import uiActions from "app/store/actions/ui.actions";
import { Modifier } from "app/pages/mediaLibrary/StableDiffusion/SelectTags";
import { mediaLibraryMessages } from "app/pages/mediaLibrary/StableDiffusion/messages";
import { TypeTag } from "app/types/media";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

const Container = styled.div`
  width: 100%;
  display: block;
  text-align: center;
`;

const PromptContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

const MerginedWandIcon = styled(WandIcon)`
  margin-right: 10px;
`;

const StyledInput = styled(Input)`
  min-width: 200px;
  width: 100%;
  margin-bottom: 5px;
`;

const GenerateButton = styled(Button)`
  margin: 20px auto;
  display: block;
`;

interface MediaLibraryTabMyMediaPromptProps {
  sendPrompt: (t: string) => void;
  validators: Record<string, boolean>;
  setValidators: React.Dispatch<Record<string, boolean>>;
  helperText: string | boolean;
  shrinked: boolean;
  // eslint-disable-next-line react/require-default-props
  onFocus?: () => void;
}

const joinPromptParts = (bTags: TypeTag[], prompt: string, aTags: TypeTag[]) => {
  return [
    bTags.map((tag) => tag.label).join(bTags.length ? ", " : ""),
    bTags.length ? ". " : "",
    prompt,
    aTags.length ? ". " : "",
    aTags.map((tag) => tag.label).join(aTags.length ? ", " : "")
  ].join("");
};

const MediaLibraryTabSDGeneratePrompt = ({
  sendPrompt,
  validators,
  shrinked,
  setValidators,
  helperText,
  onFocus
}: MediaLibraryTabMyMediaPromptProps) => {
  const [prevTags, setPrevTags] = useState<TypeTag[]>([]);
  const [afterTags, setAfterTags] = useState<TypeTag[]>([]);
  const [promptText, setPromptText] = useState<string>();
  const [touched, setTouched] = useState<boolean>(false);
  // @ts-ignore handels untyed getPrevModifiers
  const prevModifiers: Modifier[] = useAppSelector(getPrevModifiers);
  // @ts-ignore handels untyed getPrevModifiers
  const afterModifiers: Modifier[] = useAppSelector(getAfterModifiers);
  const mediaGenerateStatus: FetchStatus = useAppSelector(
    (state) => state.media.generatedRequestStatus
  );
  const intl = useIntl();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    if (promptText) {
      const values = joinPromptParts(prevTags, promptText, afterTags);
      validatePrompt(values);
    }
  }, [promptText]);

  useEffect(() => {
    return () => {
      setPromptText(undefined);
      setValidators({
        length: true,
        negation: true,
        noun: true,
        adjective: true,
        dependentObj: true
      });
    };
  }, []);

  const sdValidation = flags[FeatureFlag.sdPromptValidation];

  const validatePrompt = async (value: string) => {
    if (sdValidation) {
      try {
        const validates = await sdPromptValidate(value);
        setValidators(validates as Record<string, any>);
      } catch (e: any) {
        dispatch(uiActions.setErrors({ generatePrompValidation: e }));
      }
    } else {
      setValidators({
        length: value.split(" ").length > 2,
        negation: true,
        noun: true,
        adjective: true,
        dependentObj: true
      });
    }
  };

  const validPrompt = (event: SyntheticEvent<HTMLInputElement>) => {
    const txt = event.currentTarget?.value;
    setPromptText(txt);
  };

  const onGenerate = async () => {
    try {
      if (!Object.values(validators).includes(false) && promptText) {
        const values = joinPromptParts(prevTags, promptText, afterTags);
        sendPrompt(values);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <PromptContainer>
        <DndContext autoScroll={false} sensors={sensors} collisionDetection={closestCenter}>
          <MediaLibraryTabSDTagWrap
            tags={prevTags}
            modifiers={prevModifiers}
            onFocus={onFocus}
            editable={!shrinked}
            setTags={setPrevTags}
            selected={prevTags.map((t) => t.label)}
            addTag={(tag: string, color: string, icon: string) =>
              setPrevTags([...prevTags, { label: tag, color, icon, id: tag }])
            }
          />
        </DndContext>
        <H1_FlexColumn>
          <StyledInput
            value={promptText}
            placeholder={intl.formatMessage(mediaLibraryMessages.sdSearchPlaceholder)}
            allowClear
            onFocus={onFocus}
            onBlur={() => setTouched(true)}
            onPressEnter={onGenerate}
            onChange={validPrompt}
            status={Object.values(validators).includes(false) ? "error" : ""}
            disabled={mediaGenerateStatus === "loading"}
          />
          <ConditionalRender condition={!!helperText}>
            <H1_TextXs
              fontSize="10px"
              color={touched && Object.values(validators).includes(false) ? "red" : "grey"}
            >
              {helperText}
            </H1_TextXs>
          </ConditionalRender>
        </H1_FlexColumn>
        <DndContext autoScroll={false} sensors={sensors} collisionDetection={closestCenter}>
          <MediaLibraryTabSDTagWrap
            tags={afterTags}
            editable={!shrinked}
            modifiers={afterModifiers}
            setTags={setAfterTags}
            selected={afterTags.map((t) => t.label)}
            addTag={(tag: string, color: string, icon: string) =>
              setAfterTags([...afterTags, { label: tag, color, icon, id: tag }])
            }
          />
        </DndContext>
      </PromptContainer>
      <GenerateButton
        type="primary"
        loading={mediaGenerateStatus === "loading"}
        icon={<MerginedWandIcon />}
        onClick={onGenerate}
      >
        {intl.formatMessage(mediaLibraryMessages.sdActionButton)}
      </GenerateButton>
    </Container>
  );
};

export default MediaLibraryTabSDGeneratePrompt;
