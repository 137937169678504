import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPalettes,
  getTemplateById,
  getTemplates,
  postTemplatesRequest,
  upsertPalette
} from "app/services/serviceV2Apis";
import { thunkOptions } from "app/store/thunks/thunkCommon";
import { FeatureFlag, Palette, PaletteColor, Template, ThunkApi } from "app/types";
import { scenesActions } from "app/store/slices/scenes.slice";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";

const prefix = "[Templates]";

const getTemplatesRequest = createAsyncThunk(
  `${prefix} getTemplatesRequest`,
  async () => {
    const result = await getTemplates();
    return result;
  },
  thunkOptions
);

const getTemplateByIdRequest = createAsyncThunk<Template, string, ThunkApi>(
  `${prefix} getTemplateByIdRequest`,
  async (templateId) => {
    const result = await getTemplateById(templateId);

    return result;
  },
  thunkOptions
);

const postNeedTemplatesRequest = createAsyncThunk<void, string>(
  `${prefix} postNeedTemplatesRequest`,
  async (text: string) => {
    await postTemplatesRequest(text);
  },
  thunkOptions
);

const upsertPaletteRequest = createAsyncThunk<
  Palette,
  { id?: string; name: string; draftId: string; colors: PaletteColor[]; skipFramePreview: boolean },
  ThunkApi
>(
  `${prefix} upsertPaletteRequest`,
  async ({ name, draftId, colors, skipFramePreview }, thunkApi) => {
    const state = thunkApi.getState();
    const {
      user: { featureFlags }
    } = thunkApi.getState();
    const result: any = await upsertPalette(name as string, draftId, colors);
    if (featureFlags[FeatureFlag.framePreviewFeature] && !skipFramePreview) {
      //  checking if edit is by editor
      thunkApi.dispatch(
        scenesActions.scenePreviewRequest({
          draftId: state.drafts.currentDraft?.id as string,
          sceneIds: state.scenes.ids as string[]
        })
      );
    }
    if (result) {
      await thunkApi.dispatch(
        draftsActionsV2.patchDraftRequest({
          draftId,
          operations: [
            { op: "replace", path: "palette_id", value: result.id },
            { op: "replace", path: "palette" }
          ]
        })
      );
    }
    return result;
  },
  thunkOptions
);

const getPalettesRequest = createAsyncThunk<
  Palette[],
  { draftId: string; templateId: string },
  ThunkApi
>(
  `${prefix} getPalettesRequest`,
  async ({ templateId, draftId }) => {
    const result = await getPalettes(templateId, draftId);

    return result;
  },
  thunkOptions
);

export default {
  getTemplatesRequest,
  postNeedTemplatesRequest,
  getTemplateByIdRequest,
  upsertPaletteRequest,
  getPalettesRequest
};
