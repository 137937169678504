import { useEffect } from "react";
import pusher from "app/services/pusher";
import { useAuth } from "app/auth/useAuth";
import { useAppDispatch } from "app/hooks";
import { Channel } from "pusher-js";
import { avatarsActions } from "app/store/slices/avatars.slice";
import { PusherMessagesStatusEnum } from "app/types/pusherMessages";
import { useIntl } from "react-intl";

const PusherAvatarSession = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    let channel: Channel;
    const handleProgressUpdate = (message: {
      status: PusherMessagesStatusEnum;
      character_id?: string;
      progress: { percentage: number };
      session_id?: string;
      pair_info: { order_id: string };
      failure_reason?: string;
    }) => {
      const {
        progress,
        status,
        character_id: characterId,
        pair_info: pairInfo,
        session_id: sessionId,
        failure_reason: failureReason
      } = message;
      dispatch(
        avatarsActions.updateAvatarProgress({
          status,
          progress: progress.percentage,
          sessionId: sessionId || pairInfo.order_id,
          characterId,
          failureReason,
          intl
        })
      );
    };
    if (user?.sub) {
      const userId = user.sub.split("|")[1];
      const channelName = `${userId}`;
      channel = pusher.subscribe(channelName);
      channel.bind("avatar_session_progress", handleProgressUpdate);
    }

    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, []);

  return null;
};

export default PusherAvatarSession;
