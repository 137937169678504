/* eslint-disable  camelcase */
import { Video } from "app/types/video";
import { Scene, SceneHistory, SynthesisMarkupLanguage } from "app/types/scene";
import { Attributes } from "app/types/attributes";
import { PaletteColor, Template } from "app/types/template";
import { SourceName } from "app/types/realsMe";
import { SearchType, SocialMedia } from "app/types/general";
import { SupportedTranslationLanguages } from "app/types/services";
import { UseCaseSegmentType } from "app/types/segment";
import { PresentationTextType } from "app/types/datastore";

export enum VariableType {
  text = "text"
}

export interface DraftVariable {
  id: string;
  name: string;
  type: VariableType;
  display_title: string;
  default_value?: any;
  description?: string;
  required?: boolean;
  // type: string;
}

export interface VariableMenuItem {
  id?: string;
  name?: string;
  display_title?: string;
  // type: string;
}

export interface WorkflowForm {
  title?: string;
  image?: string;
  description?: string;
}

export interface Workflow {
  id: string;
  form: WorkflowForm;
  published: boolean;
}

export type DraftType =
  | "presentation"
  | "realsme"
  | "personal_recipe"
  | "mobile"
  | "video_wizard"
  | "virtual_avatar"
  | "plain_text"
  | "usage_segment"
  | "workflow";

export interface Draft {
  id?: string;
  review_status?: ReviewStatus;
  pronunciations?: Pronunciation[];
  variables?: DraftVariable[];
  character_id?: string;
  type?: DraftType;
  title?: string;
  description?: string;
  recipe_id?: string;
  template_id?: string;
  palette_id?: string;
  palette?: PaletteColor[];
  data_store_id?: string;
  available?: boolean;
  created_at?: string;
  owner?: string;
  owner_email?: string;
  updated_at?: string;
  font_set_id?: string;
  version?: string;
  versions?: string[];
  last_video?: Video;
  attributes?: Attributes;
  folder_parent_id?: string;
  path_id?: string;
  presentation_assets?: string[];
  image?: string;
  owner_picture?: string;
  source?: string;
  tags?: string[];
  topic?: string;
  scenes_amount?: number;
  target_platform?: SocialMedia;
  element_graph?: {
    head: string;
  };
  language_detected?: string;
  usage_segment?: UseCaseSegmentType;
  initial_scenes_transcript?: string[];
  immediately_create_video?: boolean;
  global_character?: boolean;
  presentation_type?: PresentationTextType;
  presentation_url?: string;
  workflow_id?: string;
  workflow?: Workflow;
}

export interface DraftHistory extends Draft {
  draft_id: string;
  video: Video;
  scenes: SceneHistory[];
}

export interface DraftExtended extends Draft {
  scenes: Scene[];
  template: Template;
}

export interface SearchSingleResult {
  id: string;
  owner: string;
  image: string;
  owner_nickname: string;
  resource_type: SearchType;
  text: string;
  updated_at: string;
}

export interface SearchResults {
  metadata: {
    total_items: number;
  };
  results: SearchSingleResult[];
}

export interface DraftDuplicationRequest {
  destination_source?: SourceName;
  destination_user_id?: string;
  destination_draft_name?: string;
  destination_folder?: string;
  destination_language?: SupportedTranslationLanguages;
}

export enum DraftApplySettings {
  ALL_SCENES = "ALL_SCENES",
  ONE_SCENE = "ONE_SCENE"
}

export interface WorkflowVariableValue {
  name?: string;
  variable_id: string;
  value: string;
}

export interface WorkflowFormVariableResult {
  id: string;
  name: string;
  default_value?: string;
  type: VariableType;
  description?: string;
  required: boolean;
  display_title: string;
}

export interface WorkflowResult {
  id: string;
  form: WorkflowForm;
  variables: WorkflowFormVariableResult[];
  published: true;
  synthesis_markup_language: SynthesisMarkupLanguage[];
}

export interface WorkflowSubmission {
  id: string;
  created_at: string;
  updated_at: string;
  auto_created: boolean;
  created_by: string;
  req_id: string;
  draft_id: string;
  email?: string;
  draft_history_id: string;
  variables: WorkflowVariableValue[];
  video?: Video;
}

export enum ReviewStatus {
  Draft = "draft",
  InReview = "in_review",
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected"
}

export enum DraftViewMode {
  me = "me",
  everyone = "everyone"
}

export interface Pronunciation {
  word: string;
  pronounced: string;
}

export interface RecoverBulkDraftRequest {
  id: string;
}

export enum DraftRevertType {
  relative_to_head = "relative_to_head",
  draft_history = "draft_history"
}

export interface DraftRevertRequest {
  type: DraftRevertType;
  relative_to_head?: number;
  draft_history_id?: string;
}
