import { StyledModal } from "app/components/common/StyledModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import {
  H1_TextMiddle,
  H1_TextSmall,
  H1_TextSubtitle
} from "app/components/_Infrastructure/Typography";
import { podcastMessages } from "app/pages/HomePage/PodcastModal/messages";
import { useIntl } from "react-intl";
import styled, { css, useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
  draftGeneratorRequest,
  DraftGeneratorType,
  FeatureFlag,
  MimeType,
  RecipeSetup,
  TalkPace
} from "app/types";
import { Button, Input } from "@nextui-org/react";
import ConditionalRender from "app/components/common/ConditionalRender";
import ButtonBox from "app/components/common/ButtonBox";
import { transcriptAudioUploaderMessages } from "app/components/editor/scene/transcriptAudioUploader/messages";
import UploadFileContent from "app/components/common/UploadFileContent";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import FilePlayer from "app/components/common/FilePlayer";
import useAudioPlayer from "app/hooks/useAudioPlayer";
import { v4 as uuidv4 } from "uuid";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import DocumentLoadingCreationModal from "app/components/editor/documents/DocumentLoadingCreationModal";
import { fetchingStatus } from "app/utils/helpers";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { ThemeMode } from "app/utils/theme";
import LineAnimationLoader from "app/components/common/Loaders/LineAnimationLoader";
import PodcastModalSetup from "app/pages/HomePage/PodcastModal/PodcastModalSetup";
import { useFlags } from "launchdarkly-react-client-sdk";

enum VIEW_MODE {
  main,
  upload,
  voiceRecording,
  finish,
  promptToVideo,
  setup
}

const PromptButton = styled(Button)`
  position: absolute;
  right: 45px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.blue2};

  i {
    color: ${({ theme }) => theme.gray1};
  }
`;

const ActionBox = styled(H1_FlexColumn)<{ $disabled?: boolean }>`
  background: ${({ theme }) => theme.gray2};
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px
    ${({ theme }) =>
      theme.mode === ThemeMode.Light ? "rgba(0, 0, 0, 0.05)" : "rgba(255, 255, 255, 0.05)"};
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;

      i {
        color: ${({ theme }) => theme.gray4};
      }
    `};
`;

const Video = styled.video`
  max-height: 311px;
  height: 311px;
  width: 100%;
  border-radius: 12px;
  object-fit: contain;
`;

interface PodcastModalProps {
  visible: boolean;
  onClose: () => void;
}

const PodcastModal = ({ visible, onClose }: PodcastModalProps) => {
  const [createVideoLoadingModal, setCreateVideoLoadingModal] = useState(false);
  const [prompt, setPrompt] = useState<string>("");
  const [currentView, setCurrentView] = useState<VIEW_MODE>(VIEW_MODE.promptToVideo);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { file, reset, importLoading, executeImport, onSelectFile, fileStoreResult } =
    useFileUploadFilestack();
  const { playAudio, stopAudio, isPlaying } = useAudioPlayer(file);
  const flags = useFlags();

  const draftGeneratorOrderId = useAppSelector((state) => state.drafts.createDraftGeneratorOrderId);
  const createDraftGeneratorStatus = useAppSelector(
    (state) => state.drafts.createDraftGeneratorStatus
  );
  const generateDraftLoading = createDraftGeneratorStatus === fetchingStatus.loading;

  const modalSize = useMemo(() => {
    switch (currentView) {
      case VIEW_MODE.main:
        return { width: "1046px", height: "702px" };
      case VIEW_MODE.upload:
        return { width: "500px", height: "408px" };
      case VIEW_MODE.setup:
        return { width: "957px", height: "580px" };
      case VIEW_MODE.finish:
        return { width: "500px", height: "408px" };
      case VIEW_MODE.promptToVideo:
        return { width: "700px", height: "208px" };
      default:
        return { width: "500px", height: "408px" };
    }
  }, [currentView]);

  useEffect(() => {
    if (!visible) {
      cleanFile();
    }
  }, [visible]);

  useEffect(() => {
    if (visible && createDraftGeneratorStatus === fetchingStatus.succeeded) {
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
      setCreateVideoLoadingModal(false);
      onClose();
      cleanFile();
    }
    if (visible && createDraftGeneratorStatus === fetchingStatus.failed) {
      onClose();
      dispatch(draftsActions.setCreateDraftGeneratorStatusToIdle());
      setCreateVideoLoadingModal(false);
      cleanFile();
    }
  }, [createDraftGeneratorStatus]);

  useEffect(() => {
    if (visible && fileStoreResult) {
      setCreateVideoLoadingModal(true);
      const draftGeneratorRequest: draftGeneratorRequest = {
        title: fileStoreResult.name,
        order_id: uuidv4().toString(),
        type: DraftGeneratorType.Podcast,
        podcast_url: fileStoreResult.url
      };
      dispatch(draftsActions.createDraftGenerator(draftGeneratorRequest));
    }
  }, [fileStoreResult]);

  const onClickUploadAudioBox = () => {
    if (!flags[FeatureFlag.podcastFile]) {
      return;
    }
    setCurrentView(VIEW_MODE.upload);
  };
  const onChooseFile = (selectedFile: File) => {
    onSelectFile(selectedFile);
    setCurrentView(VIEW_MODE.finish);
  };

  const cleanFile = () => {
    reset();
    setCurrentView(VIEW_MODE.main);
  };

  const onClickPromptVideo = () => {
    setCurrentView(VIEW_MODE.promptToVideo);
  };

  const headerContent = (
    <H1_FlexColumn flex="0 0 auto">
      <H1_TextSubtitle color={theme.gray10}>{formatMessage(podcastMessages.title)}</H1_TextSubtitle>
      <H1_TextSmall color={theme.gray6}>{formatMessage(podcastMessages.subtitle)}</H1_TextSmall>
    </H1_FlexColumn>
  );
  const onChangePromptValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPrompt(value);
  };

  const onSendPrompt = () => {
    setCurrentView(VIEW_MODE.setup);
  };

  const onFinish = (properties?: {
    participantHostId: string;
    participantId: string;
    setup: RecipeSetup;
    pace: TalkPace;
  }) => {
    if (properties) {
      setCreateVideoLoadingModal(true);
      let recipeId: string;
      switch (properties.setup) {
        case RecipeSetup.Interview:
          recipeId = flags[FeatureFlag.interviewRecipeId];
          break;
        case RecipeSetup.Podcast:
          recipeId = flags[FeatureFlag.podcastRecipeId];
          break;
        default:
          recipeId = flags[FeatureFlag.podcastRecipeId];
      }
      const draftGeneratorRequest: draftGeneratorRequest = {
        title: "prompt to video",
        order_id: uuidv4().toString(),
        type: DraftGeneratorType.Podcast,
        prompt,
        pace: properties.pace,
        host: properties.participantHostId,
        recipe_id: recipeId
        // participant: properties.participantId // don't need at the moment, but I've already implemented this...
      };
      dispatch(draftsActions.createDraftGenerator(draftGeneratorRequest));
    }
  };

  if (visible && createVideoLoadingModal) {
    return <DocumentLoadingCreationModal trayKey={draftGeneratorOrderId} startFromType="podcast" />;
  }

  return (
    <StyledModal
      width={modalSize.width}
      open={visible}
      footer={null}
      centered
      bodyStyle={{ height: modalSize.height }}
      onCancel={onClose}
      destroyOnClose
      maskClosable
      closeIcon={<CloseModalIcon />}
      $padding="24px 24px 12px"
      $gap="21px"
    >
      <ConditionalRender condition={currentView === VIEW_MODE.promptToVideo}>
        <H1_FlexColumn padding="10px 0 0 0" flex="0 0 auto" width={"100%"} align={"center"}>
          <H1_TextSubtitle color={theme.gray10}>
            {formatMessage(podcastMessages.promptToVideoTile)}
          </H1_TextSubtitle>
        </H1_FlexColumn>
        <H1_FlexRow padding="0 10px 10px" flex="1" align="center" gap="10px">
          <Input
            size="lg"
            placeholder={formatMessage(podcastMessages.podcastMessages)}
            // defaultValue={prompt}
            value={prompt}
            onChange={onChangePromptValue}
            fullWidth
          />
          <PromptButton
            isLoading={generateDraftLoading}
            onClick={onSendPrompt}
            isIconOnly
            startContent={<i className="fas fa-sparkles" />}
            isDisabled={!prompt}
          />
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={currentView === VIEW_MODE.setup}>
        <PodcastModalSetup onClose={onClose} onFinish={onFinish} />
      </ConditionalRender>
      <ConditionalRender condition={currentView === VIEW_MODE.main}>
        <H1_FlexColumn align="center">
          <H1_FlexColumn width="fit-content" gap="30px">
            <Video
              src="https://df6g5g0b3bt51.cloudfront.net/reals-static-files/66eff07f50f5c9db716ebb1a_full.webm"
              autoPlay
              loop
              muted
            />
            <LineAnimationLoader />
          </H1_FlexColumn>
          <H1_TextSubtitle margin="30px 0 0 0" color={theme.gray10}>
            {formatMessage(podcastMessages.title)}
          </H1_TextSubtitle>
          <H1_TextSmall color={theme.gray6}>{formatMessage(podcastMessages.subtitle)}</H1_TextSmall>
        </H1_FlexColumn>
        <H1_FlexRow padding="37px 0 0 0" gap="35px" justify="center" width="100%">
          <ActionBox
            onClick={onClickUploadAudioBox}
            justify="center"
            align="center"
            width="298px"
            height="134px"
            $disabled={!flags[FeatureFlag.podcastFile]}
          >
            <H1_Icon color={theme.blue2} icon="fa fa-cloud-arrow-up" size="22px" />
            <H1_TextMiddle fontWeight={500} color={theme.gray11}>
              {formatMessage(podcastMessages.uploadAudio)}
            </H1_TextMiddle>
          </ActionBox>
          <ActionBox
            onClick={onClickPromptVideo}
            justify="center"
            align="center"
            width="298px"
            height="134px"
          >
            <H1_Icon color={theme.blue2} icon="fa fa-video" size="22px" />
            <H1_TextMiddle fontWeight={500} color={theme.gray11}>
              {formatMessage(podcastMessages.promptToVideo)}
            </H1_TextMiddle>
          </ActionBox>
          <ActionBox $disabled justify="center" align="center" width="298px" height="134px">
            <H1_Icon color={theme.blue2} icon="fa fa-scroll" size="22px" />
            <H1_TextMiddle fontWeight={500} color={theme.gray11}>
              {formatMessage(podcastMessages.usePodcastScript)}
            </H1_TextMiddle>
          </ActionBox>
        </H1_FlexRow>
      </ConditionalRender>
      <ConditionalRender condition={currentView === VIEW_MODE.upload}>
        {headerContent}
        <UploadFileContent
          disabled={importLoading}
          fileTypes={[MimeType.mp4, MimeType.wav]}
          onSelectFile={(files) => onChooseFile(files[0])}
          height="100%"
        >
          <ButtonBox
            iconColor={theme.blue2}
            height="100%"
            icon={<i className="fa-solid fa-file-music" />}
            title={
              formatMessage(transcriptAudioUploaderMessages.uploadFileButtonTitle, {
                br: <br />
              }) as ReactNode
            }
            description={formatMessage(transcriptAudioUploaderMessages.uploadFileButtonDescription)}
          />
        </UploadFileContent>
      </ConditionalRender>
      <ConditionalRender condition={currentView === VIEW_MODE.finish}>
        {headerContent}
        <H1_FlexColumn height="100%" justify="center">
          <H1_FlexColumn padding="0 0 20px 0" align="center" height="226px" justify="center">
            <FilePlayer
              fileName={file?.name}
              fileSize={file?.size}
              playing={isPlaying}
              onPlay={playAudio}
              onStop={stopAudio}
            />
          </H1_FlexColumn>
        </H1_FlexColumn>
      </ConditionalRender>
      <H1_FlexRow
        gap="10px"
        align="center"
        justify={
          currentView === VIEW_MODE.finish || currentView === VIEW_MODE.upload
            ? "space-between"
            : "flex-end"
        }
      >
        <ConditionalRender condition={currentView === VIEW_MODE.upload}>
          <Button variant="light" onClick={cleanFile}>
            {formatMessage(podcastMessages.backBtn)}
          </Button>
        </ConditionalRender>
        <ConditionalRender condition={currentView === VIEW_MODE.finish}>
          <Button variant="light" onClick={() => setCurrentView(VIEW_MODE.upload)}>
            {formatMessage(podcastMessages.backBtn)}
          </Button>
          <H1_FlexRow gap="10px" align="center" justify="flex-end">
            <Button variant="bordered" onClick={onClose}>
              {formatMessage(podcastMessages.cancelBtn)}
            </Button>
            <Button
              color="primary"
              onClick={executeImport}
              isDisabled={!file}
              isLoading={importLoading}
            >
              {formatMessage(podcastMessages.generateBtn)}
            </Button>
          </H1_FlexRow>
        </ConditionalRender>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default PodcastModal;
