import React, { ReactNode } from "react";
import styled from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";

interface ButtonBoxProps {
  icon: ReactNode;
  title: string | ReactNode;
  description?: string | ReactNode;
  onClick?: () => void;
  width?: string;
  selected?: boolean;
}
//
const Wrapper = styled(H1_FlexColumn)<{ $selected?: boolean }>`
  border: ${({ $selected }) => ($selected ? "2px solid #5A5AFF;" : "1px solid #b9b9b9;")} 
  border-radius: 10px;
  width:115px;
  height:30px;
  background:white;
`;

const ButtonBox = ({ title, onClick, selected }: ButtonBoxProps) => {
  return (
    <Wrapper
      $selected={selected}
      align="center"
      onClick={onClick}
      justify={"center"}
      gap="5px"
      // padding="22px"
    >
      <H1_TextXs lineHeight={"0px"} color="#000000" fontSize="12px" textAlign="center">
        {title}
      </H1_TextXs>
    </Wrapper>
  );
};

export default ButtonBox;
