import React, { CSSProperties, useEffect, useRef, useState } from "react";
import "app/components/common/VideoWithPoster.scss";
import { isMediaPlaying } from "app/utils/helpers";

interface VideoWithPosterProps {
  image?: null | string;
  video: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  className?: null | string;
  disableIcon?: boolean;
  onLoaded?: (ref: HTMLVideoElement) => void;
  autoplay?: boolean;
}

const VideoWithPoster = ({
  image = null,
  video,
  style = {},
  containerStyle = {},
  className = null,
  disableIcon = false,
  onLoaded = () => null,
  autoplay
}: VideoWithPosterProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const imgIsDisplable =
    image &&
    image.split(".")[image.split(".").length - 1] !== video.split(".")[video.split(".").length - 1];

  useEffect(() => {
    if (videoLoaded) {
      if (image && imgIsDisplable) {
        videoRef?.current?.play();
      }
    }
  }, [videoLoaded]);

  return (
    <div style={containerStyle} className={`videoWithPoster-container ${className || ""}`}>
      {!disableIcon && <i className="fas fa-video video-poster-icon" />}

      <video
        ref={videoRef}
        poster={imgIsDisplable ? image : undefined}
        preload={image && imgIsDisplable ? "none" : "auto"}
        muted
        loop
        src={`${video}#t=0.1`}
        autoPlay={autoplay}
        style={{
          ...style
        }}
        onCanPlay={(e) => {
          setVideoLoaded(true);
          onLoaded(e.currentTarget);
        }}
        onMouseEnter={() => image && videoLoaded && videoRef?.current?.play()}
        onMouseLeave={() => {
          if (
            image &&
            imgIsDisplable &&
            videoLoaded &&
            videoRef?.current &&
            isMediaPlaying(videoRef.current)
          ) {
            if (isMediaPlaying(videoRef.current as HTMLVideoElement)) {
              videoRef?.current?.pause();
            }
            return videoRef?.current?.load();
          }
          return false;
        }}
      />
    </div>
  );
};

VideoWithPoster.defaultProps = {
  disableIcon: false,
  image: "",
  style: {},
  containerStyle: null,
  className: null,
  onLoaded: () => null,
  autoplay: false
};

export default VideoWithPoster;
