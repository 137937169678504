import { Avatar, Dropdown, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import useCurrentPlan from "app/hooks/useCurrentPlan";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { avatarColorsList, FeatureFlag, FetchStatus, Workspace } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { userActions } from "app/store/slices/user.slice";
import { MenuItemType } from "antd/lib/menu/hooks/useItems";
import { useNavigate } from "react-router-dom";
import { workspacesGlobalSelectors } from "app/store/adapters/adapters";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextXs } from "app/components/_Infrastructure/Typography";
import { fetchingStatus } from "app/utils/helpers";
import { workspacesActions } from "app/store/slices/workspaces.slice";
import { inviteTeamMessages } from "app/components/teams/messages";
import { useFlags } from "launchdarkly-react-client-sdk";
import useNotifications from "app/hooks/useNotifications";
import { useIntl } from "react-intl";
import { leftNavMessages } from "app/components/LeftNav/messages";
import CreateWorkspaceModal from "app/components/teams/createWorkspaceModal";
import InviteMembersButton from "app/components/teams/InviteMembersButton";
import { Button } from "@nextui-org/react";
import { ThemeMode } from "app/utils/theme";

const HiddenInviteButton = styled(InviteMembersButton)`
  &&&& {
    display: none;
  }
`;

const NameText = styled(Typography.Text)`
  font-size: 0.875rem;
  font-weight: 400;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  line-height: 1rem;
  && {
    max-width: 105px;
  }
  color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray11 : theme.gray7)};
`;

const WhiteFlexRow = styled(H1_FlexRow)`
  box-sizing: content-box;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  i {
    font-size: 12px;
  }
`;
const StyledDropdown = styled(Dropdown)`
  height: 47px;
  padding: 7px 8px;
  margin: 0 4px 4px;
  width: 181px;
  border-radius: 14px;
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2)};
  border: 1px solid ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray4 : theme.gray2)};
  .ant-dropdown-menu-item,
  .ant-dropdown-menu {
    background-color: ${(props) => props.theme.gray4};
    padding: 0;
    max-height: 50vh;
    overflow: auto;
  }
  .ant-dropdown-menu-item:hover {
    background-color: ${(props) => props.theme.gray5};
  }
`;

const GearButton = styled(Button)`
  min-width: 22px;
  flex-shrink: 0;
  opacity: 0;
  i {
    font-size: 14px;
  }
`;

const BackgroundFlexRow = styled(H1_FlexRow)<{ $highlighted: boolean }>`
  background-color: ${({ $highlighted, theme }) => ($highlighted ? theme.gray3 : theme.gray1)};
  &:hover {
    ${GearButton} {
      opacity: 1;
    }
    background-color: ${(props) => props.theme.gray4};
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${(props: { $color: string }) => props.$color};
    flex: 0 0 auto;
    span {
      color: ${(props) => props.theme.gray11};
    }
  }
`;

const Icon = styled.i`
  margin-left: 10px;
  width: 14px;
  height: 22px;
  color: ${(props) => props.theme.gray7};
  font-size: 10px;
  line-height: 22px;
`;

const WorkspacesDropdownList = () => {
  const [open, setOpen] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [createWorkspaceModalVisible, setCreateWorkspaceModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const flags = useFlags();
  const { notifyMessages } = useNotifications();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { currentPlanText, getCurrentPlanText } = useCurrentPlan();
  const workspaces = useAppSelector(workspacesGlobalSelectors.selectAll);
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));

  const createWorkspacesStatus: FetchStatus = useAppSelector(
    (state) => state.workspaces.createWorkspacesStatus
  );
  const numberOfOwnerWorkspaces = useMemo(() => {
    return workspaces.reduce((acc, curr) => {
      return curr.is_owner ? acc + 1 : acc;
    }, 0);
  }, [workspaces]);
  const isCreateTeamAvailable = numberOfOwnerWorkspaces <= 1 && flags[FeatureFlag.createWorkspace];

  const currentWorkspaceIndex = useMemo(() => {
    return (
      workspaces.findIndex((workspace) => workspace.id === currentWorkspace?.id) %
      avatarColorsList(theme).length
    );
  }, [workspaces, currentWorkspace]);

  const avatarText = useMemo(() => {
    return currentWorkspace?.name.toUpperCase().charAt(0);
  }, [currentWorkspace]);

  useEffect(() => {
    if (createWorkspacesStatus === fetchingStatus.succeeded) {
      dispatch(workspacesActions.updateCreateWorkspaceStatusToIdle());
    }
    if (createWorkspacesStatus === fetchingStatus.failed) {
      dispatch(workspacesActions.updateCreateWorkspaceStatusToIdle());
    }
  }, [createWorkspacesStatus]);

  const onCreateWorkspace = () => {
    setCreateWorkspaceModalVisible(true);
  };

  const onCloseCreateWorkspace = () => {
    setCreateWorkspaceModalVisible(false);
  };

  const onFinishCreateWorkspace = (name: string) => {
    setCreateWorkspaceModalVisible(false);
    createWorkspace(name);
    notifyMessages([{ message: formatMessage(inviteTeamMessages.newTeamCreatedNotification) }]);
    setOpenInviteModal(true);
  };

  const createWorkspace = (name: string) => {
    dispatch(
      analyticsEvents.createWorkspace({
        workspaceName: name
      })
    );
    dispatch(
      workspacesActions.createWorkspaceRequest({
        workspace: {
          members: [],
          name
        }
      })
    );
  };

  const onCloseInviteMembers = () => {
    setOpenInviteModal(false);
  };

  const onWorkspaceClick = (
    event: React.MouseEvent,
    workspaceId: string,
    isClickManageWorkspace = false
  ) => {
    setOpen(false);
    if (workspaceId === currentWorkspace?.id && !isClickManageWorkspace) {
      return;
    }
    const clickedWorkspace = workspaces.find((workspace) => workspace.id === workspaceId);
    dispatch(
      analyticsEvents.changeWorkspace({
        workspaceId,
        workspaceName: clickedWorkspace?.name as string,
        teamId: clickedWorkspace?.current_team.id as string,
        teamName: clickedWorkspace?.current_team.name as string
      })
    );
    dispatch(userActions.updateUserPreferencesRequest({ current_workspace_id: workspaceId }));
    const route = isClickManageWorkspace ? `/workspaces/${workspaceId}` : `/`;
    navigate(route);
    if (isClickManageWorkspace) {
      event.stopPropagation();
    }
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const items: MenuItemType[] = useMemo(() => {
    if (!workspaces) {
      return [];
    }

    const topMenuItem = {
      key: "top",
      label: (
        <WhiteFlexRow height="26px" align="center" justify="flex-end" padding="0 0 10px">
          <Button
            variant="light"
            onClick={onCreateWorkspace}
            startContent={<i className="fa-regular fa-plus" />}
          >
            <H1_TextXs>{formatMessage(leftNavMessages.addNew)}</H1_TextXs>
          </Button>
        </WhiteFlexRow>
      )
    };

    const workspacesList = workspaces?.map((workspace: Workspace, index) => ({
      key: workspace.id,
      label: (
        <BackgroundFlexRow
          onClick={(e) => onWorkspaceClick(e, workspace.id)}
          padding="5px 12px"
          gap="9px"
          align="center"
          $highlighted={currentWorkspace?.id === workspace.id}
          height="47px"
          justify="space-between"
        >
          <H1_FlexRow gap="9px" align="center">
            <StyledAvatar $color={avatarColorsList(theme)[index % avatarColorsList(theme).length]}>
              <span>{workspace.name.toUpperCase().charAt(0)}</span>
            </StyledAvatar>
            <H1_FlexColumn gap="2px">
              <H1_TextXs color={theme.gray11} lineHeight="1rem">
                {workspace.name}
              </H1_TextXs>
              <H1_TextXs color={theme.gray7} lineHeight="1rem">
                {getCurrentPlanText(workspace.plan)}
              </H1_TextXs>
            </H1_FlexColumn>
          </H1_FlexRow>
          <GearButton
            variant="light"
            size="sm"
            onClick={(e) => onWorkspaceClick(e, workspace.id, true)}
            isIconOnly
            startContent={<i className="fa-regular fa-gear" />}
          />
        </BackgroundFlexRow>
      )
    })) as MenuItemType[];

    return isCreateTeamAvailable ? [topMenuItem, ...workspacesList] : workspacesList;
  }, [workspaces, currentWorkspace?.id, theme.mode]);
  return (
    <ConditionalRender condition={workspaces && workspaces.length > 0}>
      <StyledDropdown
        trigger={["click"]}
        className="left-nav-dropdown"
        menu={{ items }}
        onOpenChange={handleOpenChange}
        open={open}
        getPopupContainer={() => document.querySelector(`.left-nav-dropdown`) as HTMLElement}
      >
        <H1_FlexRow
          align="center"
          justify="space-between"
          data-auto-id="workspaces-dropdown-select"
        >
          <H1_FlexRow gap="9px" align="center">
            <StyledAvatar $color={avatarColorsList(theme)[currentWorkspaceIndex]}>
              <span>{avatarText}</span>
            </StyledAvatar>
            <H1_FlexColumn gap="2px">
              <NameText ellipsis={{ tooltip: currentWorkspace?.name }}>
                {currentWorkspace?.name}
              </NameText>
              <H1_TextXs
                color={theme.mode === ThemeMode.Light ? theme.gray7 : theme.gray5}
                lineHeight="1rem"
              >
                {currentPlanText}
              </H1_TextXs>
            </H1_FlexColumn>
          </H1_FlexRow>
          <Icon className="fa-sharp fa-solid fa-chevron-down" />
        </H1_FlexRow>
      </StyledDropdown>
      <HiddenInviteButton
        source="workspace_dropdown_invite_button"
        onClose={onCloseInviteMembers}
        openModal={openInviteModal}
      />
      <CreateWorkspaceModal
        visible={createWorkspaceModalVisible}
        onFinish={onFinishCreateWorkspace}
        onClose={onCloseCreateWorkspace}
      />
    </ConditionalRender>
  );
};

export default WorkspacesDropdownList;
