import { useEffect, useState } from "react";
import styled from "styled-components";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { digitalTwinCreationMessages } from "app/pages/createDigitalTwinPage/messages";
import { useIntl } from "react-intl";
import useRecordStepContext from "app/pages/createDigitalTwinPage/useRecordStepSettingsContext";
import RecordStepRecordingReview from "app/pages/createDigitalTwinPage/RecordStepRecordingReview";
import RecordStepRecordingSettings from "app/pages/createDigitalTwinPage/RecordStepRecordingSettings";
import RecorderContext from "app/hooks/recorder/RecorderContext";
import { Alert } from "antd";
import * as googleEvents from "app/store/thunks/analyticsEvents.thunk";
import VideoRecorderWrap from "./VideoRecorderWrap";
import { Gender } from "app/types";
import useCharacterSettingsContext from "app/pages/createDigitalTwinPage/useCharacterSettingsContext";

const VideoContainerWihtoutaFile = styled(H1_FlexColumn)`
  background-color: ${(props) => props.theme.gray4};
  border-radius: 10px;
  border: 1px solid #000;
  aspect-ratio: 16/9;
  width: 70%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
`;

const NoShrinkFlex = styled(H1_FlexColumn)`
  flex-shrink: 0;
`;
const EmptyStateVideo = () => {
  const intl = useIntl();
  return (
    <VideoContainerWihtoutaFile align="center" justify="center">
      {intl.formatMessage(digitalTwinCreationMessages.askAllowingDevices)}
    </VideoContainerWihtoutaFile>
  );
};

interface RecordStepStepProps {
  onUploadSucceed: ({
    approved,
    url,
    name,
    gender,
    addPong,
    addTransparent
  }: {
    approved: boolean;
    url: string;
    name: string;
    gender: Gender;
    addPong: boolean;
    addTransparent: boolean;
  }) => void;
}
const MAX_TIME_IN_SEC = 15;

const stepRecordSettings = {
  minRecordingDurationSec: MAX_TIME_IN_SEC,
  maxRecordingDurationSec: MAX_TIME_IN_SEC,
  counter: true,
  timerDown: true
};

const RecordStep = ({ onUploadSucceed }: RecordStepStepProps) => {
  const dispatch = useAppDispatch();
  const [approved, setApproved] = useState<boolean>(false);
  const { selectedCamera } = useRecordStepContext();
  const { gender, addPong, addTransparent, name } = useCharacterSettingsContext();
  const {
    videoRef,
    videoFile,
    handleRecordingToggle,
    startCapture,
    reset: resetRecording,
    resetOptions,
    ready,
    isRecording,
    counter,
    timeInSec,
    getMediaDevices,
    cameraMenu,
    error
  } = RecorderContext.useRecorderContext();
  const intl = useIntl();
  const {
    onSelectFile,
    importLoading,
    fileStoreResult,
    reset: resetFs
  } = useFileUploadFilestack({
    autoUpload: true
  });
  const createAvatarStatus = useAppSelector((state) => state.avatars.createAvatarStatus);
  const approveLoading = importLoading || createAvatarStatus === fetchingStatus.loading;

  useEffect(() => {
    resetFs();
    resetRecording();
    resetOptions(stepRecordSettings);
    dispatch(
      googleEvents.avatarWebcamSessionProgress({
        stage: "camera_settings"
      })
    );
    return () => {
      resetRecording();
      resetFs();
    };
  }, []);

  useEffect(() => {
    if (fileStoreResult?.url) {
      onUploadSucceed({
        url: fileStoreResult.url,
        approved,
        name,
        gender,
        addPong,
        addTransparent
      });
      resetRecording();
    }
  }, [fileStoreResult?.url]);

  useEffect(() => {
    if (!!selectedCamera && !error) {
      onShowCamera();
    }
  }, [selectedCamera]);

  useEffect(() => {
    if (!cameraMenu.length) {
      getMediaDevices();
    }
  }, [cameraMenu]);

  const onShowCamera = () => {
    startCapture({
      audio: false,
      video: { deviceId: selectedCamera }
    });
  };

  const onReset = (keepCameraOn = false, dontClearError = false) => {
    resetRecording(dontClearError);
    resetOptions(stepRecordSettings);
    resetFs();
    if (keepCameraOn) {
      onShowCamera();
    }
    dispatch(
      googleEvents.avatarWebcamSessionProgress({
        stage: "redo_capture"
      })
    );
  };

  const onApprove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, approved: boolean) => {
    onSelectFile(videoFile as File);
    setApproved(approved);
    dispatch(
      googleEvents.avatarWebcamSessionProgress({
        stage: "approve_capture"
      })
    );
  };

  const startRecording = () => {
    dispatch(
      googleEvents.avatarWebcamSessionProgress({
        stage: "start_capture"
      })
    );
    handleRecordingToggle();
  };

  const stopRecording = () => {
    dispatch(
      googleEvents.avatarWebcamSessionProgress({
        stage: "finish_capture"
      })
    );
    handleRecordingToggle();
  };

  return (
    <NoShrinkFlex height="100%" align="center" gap="30px" justify="space-between">
      <ConditionalRender condition={!!error}>
        <Alert message={error as string} type="error" />
      </ConditionalRender>
      <ConditionalRender condition={!!videoFile}>
        <RecordStepRecordingReview
          videoFile={videoFile as File}
          approveLoading={approveLoading}
          onApprove={onApprove}
          onReset={onReset}
        />
      </ConditionalRender>
      <ConditionalRender condition={!videoFile}>
        <ConditionalRender condition={!ready}>
          <EmptyStateVideo />
        </ConditionalRender>
        {/* Should always be mounted so the video can load the camera src */}
        <VideoRecorderWrap
          isReady={ready}
          isRecording={isRecording}
          videoRef={videoRef}
          counter={counter}
          timeInSec={timeInSec}
          onStopRecord={stopRecording}
          textOnScreen={intl.formatMessage(
            digitalTwinCreationMessages.recordStepInstractionOnRecord,
            {
              br: <br />
            }
          )}
        />
        <ConditionalRender condition={!isRecording}>
          <RecordStepRecordingSettings
            isReady={ready}
            handleRecordingToggle={startRecording}
            cameraMenu={cameraMenu}
            recordCTAText={intl.formatMessage(digitalTwinCreationMessages.recordStepCTAButton)}
          />
        </ConditionalRender>
      </ConditionalRender>
    </NoShrinkFlex>
  );
};

export default RecordStep;
