import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { useAppSelector } from "app/hooks";
import recipesSelectors from "app/store/selectorsV2/recipes.selectors";
import {
  H1_TextHeadline,
  H1_TextSmall,
  H1_TextSubtitle,
  H1_TextXs
} from "app/components/_Infrastructure/Typography";
import { useIntl } from "react-intl";
import { Draft, FeatureFlag, Folder, Location, Orientation, Recipe, RecipeType } from "app/types";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_Input } from "app/components/_Infrastructure/design-system/input";
import { H1_Select } from "app/components/_Infrastructure/design-system/select";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { fetchingStatus } from "app/utils/helpers";
import { getFoldersSortAlphabetically } from "app/store/selectorsV2/folders.selectors";
import { useAuth } from "app/auth/useAuth";
import { useNavigate } from "react-router-dom";
import useModal, { ModalName } from "app/hooks/useModal";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useFlags } from "launchdarkly-react-client-sdk";
import useUpgradeModal from "app/hooks/useUpgradeModal";
import { useDispatch } from "react-redux";
import { ReactComponent as CrownIcon } from "app/assets/editor/crown-icon.svg";
import VideoPlayerWithControls from "app/pages/Templates/VideoPlayerWithControls";
import { SwiperSlide } from "swiper/react";
import SingleTemplateItem from "app/pages/Templates/SingleTemplateItem";
import { NavigationSwiper } from "app/components/common/NavigationSwiper";
import { foldersActions } from "app/store/slices/folders.slice";
import { templatesPreviewModalMessages } from "app/pages/Templates/messages";
import useVideo from "app/hooks/useVideo";
import { SMALL_SCREEN_PX } from "app/config/Constants";
import usePermissions from "app/hooks/usePermissions";
import uiActions from "app/store/actions/ui.actions";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";
import { ThemeMode } from "app/utils/theme";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 58px 38px 31px 42px;
    flex: 1;
    border-radius: 12px;
    min-height: 700px;
    height: 667px;
    gap: 23px;
    align-items: center;
    position: relative;
    @media (max-width: ${SMALL_SCREEN_PX}) {
      overflow-y: auto;
    }
  }
`;

const StyledSwiper = styled(NavigationSwiper)`
  position: static;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  margin-left: 0;
  .swiper-slide {
    display: flex;
    width: fit-content;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
    top: 50%;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.gray5};
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgb(64 87 109 / 7%);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${(props) => props.theme.gray8};
    background-color: ${(props) => props.theme.gray2};
    transition-property: border-color, color, opacity;
    transition-duration: 0.3s;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 16px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    svg {
      fill: ${(props) => props.theme.gray8};
      width: 16px;
      height: 16px;
    }
    &.swiper-button-disabled {
      pointer-events: auto;
    }
    &:hover {
      border-color: ${(props) => props.theme.gray5};
    }
    &:active {
      transform: scale(0.95);
    }
    &::after {
      content: "";
    }
  }
  && .swiper-button-prev {
    left: -15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }

  && .swiper-button-next {
    right: -15px;
    background-color: ${({ theme }) =>
      theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2};
  }
  &:hover .swiper-button-prev:not(.swiper-button-disabled),
  &:hover .swiper-button-next:not(.swiper-button-disabled) {
    opacity: 1;
  }
`;

const InnerFlexColumn = styled(H1_FlexColumn)``;
const InfoFlexColumn = styled(H1_FlexColumn)``;

const MobileFlex = styled(H1_FlexRow)`
  @media (max-width: ${SMALL_SCREEN_PX}) {
    flex-direction: column;
    gap: 20px;
    min-height: 0;
    flex: 1 0 auto;
    ${InnerFlexColumn} {
      flex: 1 1 auto;
      height: fit-content;
      min-height: 100px;
      width: 300px;
      video {
        max-width: 300px;
      }
    }
    ${InfoFlexColumn} {
      flex: 1 0 auto;
      width: auto;
    }
  }
`;

const OrientationFlexColumn = styled(H1_FlexColumn)<{ $isDisabled: boolean }>`
  border: 1px solid ${({ theme }) => theme.gray6};
  border-radius: 4px;
  background-color: ${({ $isDisabled, theme }) => ($isDisabled ? theme.gray2 : theme.gray1)};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 1 : 0.2)};
`;

const BoxSizingFlexRow = styled(H1_FlexRow)`
  box-sizing: content-box;
`;

const RoundCornersTag = styled(H1_FlexRow)`
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.gray6};
`;

const TemplatePreviewModal = () => {
  const [videoNode, setVideoNode] = useState<HTMLVideoElement | undefined>(undefined);
  const [showSwiper, setShowSwiper] = useState(false);
  const [recipeId, setRecipeId] = useState("");
  const [draftName, setDraftName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>();
  const [orientation, setOrientation] = useState<Orientation | undefined>(undefined);

  const { isWriteWorkspaceAllow } = usePermissions();
  const { editorModalOpen, returnToModal, closeModal } = useModal();
  const flags = useFlags();
  const dispatch = useDispatch();
  const showPaymentModal = useUpgradeModal();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { formatMessage } = useIntl();
  const setRef = useCallback((node: HTMLVideoElement) => {
    setVideoNode(node);
  }, []);
  const visible = editorModalOpen?.open === ModalName.templatePreview;
  const {
    reset,
    videoLoaded,
    currentTime,
    duration,
    isPlaying,
    aspectRatio,
    isMuted,
    handleMuteUnmute,
    handlePlayPause
  } = useVideo({ videoNode, visible });

  const recipe = useAppSelector((state) => recipesSelectors.getRecipeByRecipeId(state, recipeId));
  const createdDraft = useAppSelector(draftsSelectors.currentDraft);
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const isDraftLoading = createDraftStatus === fetchingStatus.loading;
  const folders = useAppSelector(getFoldersSortAlphabetically);

  const recipeIdContext = editorModalOpen?.context?.recipeId;
  const recipeType = editorModalOpen?.context?.recipeType;
  const recipeCategoryName = editorModalOpen?.context?.recipeCategoryName;
  const workflowMode = editorModalOpen?.context?.workflowMode;
  const isOrientationLandscape = orientation === Orientation.Landscape;
  const isOrientationPortrait = orientation === Orientation.Portrait;
  const isOrientationSquare = orientation === Orientation.Square;

  const recipeIdArr = useMemo(() => [recipeId], [recipeId]);
  const filteredTemplates = useAppSelector((state) =>
    recipesSelectors.getFilteredFlattenRecipesByOrientationAndId(
      state,
      Orientation.AnySize,
      recipeIdArr
    )
  );
  const templates = useAppSelector((state) =>
    recipesSelectors.getFilteredFlattenRecipesBySectionName(
      state,
      filteredTemplates,
      recipeCategoryName
    )
  );
  useEffect(() => {
    let clearSetTimeout: any;
    if (templates.length > 0 && visible) {
      clearSetTimeout = setTimeout(() => {
        setShowSwiper(true);
      }, 300);
    }
    return () => {
      if (clearSetTimeout) {
        clearTimeout(clearSetTimeout);
      }
    };
  }, [templates.length, visible]);

  useEffect(() => {
    if (recipeIdContext) {
      setRecipeId(recipeIdContext);
    }
  }, [recipeIdContext]);

  useEffect(() => {
    if (aspectRatio === 0) {
      return;
    }
    if (aspectRatio > 1) {
      setOrientation(Orientation.Landscape);
    } else if (aspectRatio === 1) {
      setOrientation(Orientation.Square);
    } else {
      setOrientation(Orientation.Portrait);
    }
  }, [aspectRatio]);

  useEffect(() => {
    if (visible && folders.length === 0 && isAuthenticated && !workflowMode) {
      dispatch(foldersActions.getRootFoldersRequest());
    }
    if (!visible) {
      setShowSwiper(false);
      setOrientation(undefined);
    }
  }, [visible, folders]);

  useEffect(() => {
    if (createDraftStatus === fetchingStatus.idle || !visible) {
      return;
    }
    if (createDraftStatus === fetchingStatus.succeeded) {
      closeModal();
      dispatch(analyticsEvents.navigateToEditor({ source: "template_preview_modal" }));
      navigate(`/editor/${createdDraft.id}`);
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    } else if (createDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    }
  }, [createDraftStatus, visible]);

  const userNotAuthActionHandler = () => {
    closeModal();
    navigate(Location.Home);
  };

  const handleProjectCreation = (draft: Draft) => {
    const upgradeText = formatMessage(templatesPreviewModalMessages.upgradeText);
    const upgradeSubText = formatMessage(templatesPreviewModalMessages.upgradeSubtitleText);
    const newDraft = {
      ...draft,
      type: recipeType === RecipeType.personal ? "personal_recipe" : undefined
    } as Draft;
    if (recipe?.pro && flags[FeatureFlag.blockProTemplateOnCreateDraft]) {
      const upgradeRequired = showPaymentModal({
        upgradeText,
        upgradeSubText,
        source: "upgrade_template_select",
        backModal: ModalName.templatePreview,
        requestedPlans: recipe.plans,
        context: {
          recipeId: recipe.id,
          recipeType,
          recipeCategoryName: recipeCategoryName
        }
      });

      if (!upgradeRequired) {
        dispatch(uiActions.setFtuVideoPreview(false));
        dispatch(
          draftsActions.createDraftRequest({
            draft: newDraft
          })
        );
      }
    } else {
      dispatch(uiActions.setFtuVideoPreview(false));
      dispatch(
        draftsActions.createDraftRequest({
          draft: newDraft
        })
      );
    }
  };

  const onClickLowerTemplate = (recipeIdSelected: string) => {
    setRecipeId(recipeIdSelected);
  };

  const onUseTemplate = () => {
    if (!isAuthenticated) {
      userNotAuthActionHandler();
      return;
    }

    handleProjectCreation({
      recipe_id: recipe?.id,
      title: getDraftName(),
      description: "",
      folder_parent_id: selectedFolder?.id
    });
  };

  const getDraftName = () => {
    return draftName === "" ? `Untitled Video - ${recipe?.title}` : draftName;
  };

  const onChangeName = (event: any) => {
    setDraftName(event.target.value);
  };

  const onSelectFolder = (selectedFolderId: string) => {
    setSelectedFolder(folders.find((folder) => folder.id === selectedFolderId));
  };

  return (
    <Modal
      width="964px"
      open={visible}
      footer={null}
      centered
      onCancel={returnToModal}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <MobileFlex gap="85px" justify="center" flex="1">
        <InnerFlexColumn gap="20px" flex="0 0 497px">
          <VideoPlayerWithControls
            width="497px"
            height="395px"
            maxHeight="395px"
            ref={setRef}
            videoNode={videoNode as HTMLVideoElement}
            src={recipe?.video_full as string}
            reset={reset}
            currentTime={currentTime}
            duration={duration}
            isPlaying={isPlaying}
            isMuted={isMuted}
            handleMuteUnmute={handleMuteUnmute}
            handlePlayPause={handlePlayPause}
            videoLoaded={videoLoaded}
            aspectRatio={aspectRatio}
          />
        </InnerFlexColumn>
        <InfoFlexColumn flex="0 0 302px" width="302px">
          <H1_TextHeadline whiteSpace="normal">{recipe?.title}</H1_TextHeadline>
          <BoxSizingFlexRow flex="0 0 20px" padding="8px 0 30px 0" gap="11px" overflow="auto">
            {recipe?.tags.map((tag: string, index) => (
              <RoundCornersTag key={index} align="center" justify="center" padding="0 7px">
                <H1_TextXs color={theme.gray7}>{tag}</H1_TextXs>
              </RoundCornersTag>
            ))}
          </BoxSizingFlexRow>
          <BoxSizingFlexRow flex="0 0 92px" padding="0 0 18px 0" gap="13px">
            <ConditionalRender condition={isOrientationLandscape}>
              <OrientationFlexColumn
                $isDisabled={isOrientationLandscape}
                flex="0 0 92px"
                height="92px"
                align="center"
                gap="20px"
                justify="flex-end"
                padding="20px 0 8px 0"
              >
                <img src={recipe?.image} width={50} height={28} alt={recipe?.title} />
                <H1_TextXs color={theme.gray8}>
                  {formatMessage(templatesPreviewModalMessages.landscape)}
                </H1_TextXs>
              </OrientationFlexColumn>
            </ConditionalRender>
            <ConditionalRender condition={isOrientationPortrait}>
              <OrientationFlexColumn
                $isDisabled={isOrientationPortrait}
                flex="0 0 92px"
                height="92px"
                align="center"
                gap="20px"
                justify="flex-end"
                padding="20px 0 8px 0"
              >
                <img src={recipe?.image} width={20} height={34} alt={recipe?.title} />
                <H1_TextXs color={theme.gray8}>
                  {formatMessage(templatesPreviewModalMessages.portrait)}
                </H1_TextXs>
              </OrientationFlexColumn>
            </ConditionalRender>
            <ConditionalRender condition={isOrientationSquare}>
              <OrientationFlexColumn
                $isDisabled={isOrientationSquare}
                flex="0 0 92px"
                height="92px"
                align="center"
                gap="20px"
                justify="flex-end"
                padding="20px 0 8px 0"
              >
                <img src={recipe?.image} width={34} height={34} alt={recipe?.title} />
                <H1_TextXs color={theme.gray8}>
                  {formatMessage(templatesPreviewModalMessages.square)}
                </H1_TextXs>
              </OrientationFlexColumn>
            </ConditionalRender>
          </BoxSizingFlexRow>
          <H1_FlexColumn gap="8px" padding="0 0 18px 0">
            <H1_TextSmall>
              {formatMessage(templatesPreviewModalMessages.chooseDraftNameFormHeader)}
            </H1_TextSmall>
            <H1_Input
              minWidth="100%"
              isFixedWidth
              disabled={isDraftLoading}
              placeholder={formatMessage(templatesPreviewModalMessages.untitledPlaceholder)}
              initialValue={draftName}
              onChange={onChangeName}
            />
          </H1_FlexColumn>
          <ConditionalRender condition={!workflowMode}>
            <H1_FlexColumn gap="8px" padding="0 0 28px 0">
              <H1_TextSmall>
                {formatMessage(templatesPreviewModalMessages.chooseFolderFormHeader)}
              </H1_TextSmall>
              <H1_Select
                options={folders.map((folder) => ({ value: folder.id, label: folder.name }))}
                placeholder={formatMessage(templatesPreviewModalMessages.selectFolderPlaceholder)}
                onChange={onSelectFolder}
                defaultValue={selectedFolder?.name || "Default"}
                value={selectedFolder?.name}
                loading={isDraftLoading}
              />
            </H1_FlexColumn>
          </ConditionalRender>
          <H1_FlexRow>
            <Button
              fullWidth
              color="primary"
              startContent={recipe?.pro ? <CrownIcon /> : undefined}
              onClick={onUseTemplate}
              isLoading={isDraftLoading}
              isDisabled={!isWriteWorkspaceAllow}
            >
              {formatMessage(templatesPreviewModalMessages.useThisTemplateButton)}
            </Button>
          </H1_FlexRow>
        </InfoFlexColumn>
      </MobileFlex>
      <H1_FlexColumn gap="17px" width="100%" flex="0 0 auto">
        <H1_TextSubtitle>
          {formatMessage(templatesPreviewModalMessages.similarTemplates)}
        </H1_TextSubtitle>
        <H1_FlexRow position="relative" width="100%" flex="0 0 91px">
          <ConditionalRender condition={showSwiper && templates.length > 0}>
            <StyledSwiper
              navigation
              speed={700}
              slidesPerView="auto"
              spaceBetween={15}
              slidesPerGroup={1}
            >
              {templates.map((recipe: Recipe) => (
                <SwiperSlide key={recipe.id}>
                  <SingleTemplateItem
                    height={(160 * 9) / 16}
                    recipe={recipe}
                    onClick={onClickLowerTemplate}
                    withTitle={false}
                  />
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </ConditionalRender>
        </H1_FlexRow>
      </H1_FlexColumn>
    </Modal>
  );
};

export default TemplatePreviewModal;
