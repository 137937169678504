/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";
import asyncThunks from "app/store/thunks/music.thunks";
import { fetchingStatus } from "app/utils/helpers";
import { FetchStatus } from "app/types";
import { backgroundMusicAdapter } from "app/store/adapters/adapters";

interface BackgroundMusicState {
  status: FetchStatus;
  uploadStatus: FetchStatus;
  deleteStatus: FetchStatus;
  updateStatus: FetchStatus;
}

export const musicSlice = createSlice({
  name: "Background Music",
  initialState: backgroundMusicAdapter.getInitialState<BackgroundMusicState>({
    status: fetchingStatus.idle as FetchStatus,
    deleteStatus: fetchingStatus.idle as FetchStatus,
    updateStatus: fetchingStatus.idle as FetchStatus,
    uploadStatus: fetchingStatus.idle as FetchStatus
  }),
  reducers: {
    updateUploadStatusToIdle: (state) => {
      state.uploadStatus = fetchingStatus.idle;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getBackgroundMusicListRequest.fulfilled, (state, action) => {
      backgroundMusicAdapter.setAll(state, action.payload);
      state.status = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getBackgroundMusicListRequest.pending, (state) => {
      state.status = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getBackgroundMusicListRequest.rejected, (state) => {
      state.status = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.uploadBackgroundMusicListRequest.fulfilled, (state, action) => {
      backgroundMusicAdapter.upsertOne(state, action.payload);
      state.uploadStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.uploadBackgroundMusicListRequest.pending, (state) => {
      state.uploadStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.uploadBackgroundMusicListRequest.rejected, (state) => {
      state.uploadStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteBackgroundMusicListRequest.fulfilled, (state) => {
      state.deleteStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteBackgroundMusicListRequest.pending, (state, action) => {
      const { id } = action.meta.arg;
      backgroundMusicAdapter.removeOne(state, id);
      state.deleteStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.deleteBackgroundMusicListRequest.rejected, (state) => {
      state.deleteStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.updateBackgroundMusicListRequest.fulfilled, (state) => {
      state.updateStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.updateBackgroundMusicListRequest.pending, (state, action) => {
      const { id, title } = action.meta.arg;
      if (id && title) {
        backgroundMusicAdapter.updateOne(state, {
          id,
          changes: { title }
        });
      }
      state.updateStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.updateBackgroundMusicListRequest.rejected, (state) => {
      state.updateStatus = fetchingStatus.failed as FetchStatus;
    });
  }
});

export default musicSlice.reducer;

export const musicActions = {
  getBackgroundMusicListRequest: asyncThunks.getBackgroundMusicListRequest,
  uploadBackgroundMusicListRequest: asyncThunks.uploadBackgroundMusicListRequest,
  updateBackgroundMusicListRequest: asyncThunks.updateBackgroundMusicListRequest,
  deleteBackgroundMusicListRequest: asyncThunks.deleteBackgroundMusicListRequest,
  ...musicSlice.actions
};
