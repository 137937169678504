import styled from "styled-components";

const Icon = styled.i`
  font-size: ${(props: { $size: number; $color?: string; $backgroundColor?: string }) =>
    `${props.$size}px`};
  color: ${(props: { $color?: string }) => props.$color || "var(--gray-10, #000)"};
  background-color: ${(props: { $backgroundColor?: string }) =>
    props.$backgroundColor || "transparent"};
`;

interface RealsTextIconProps {
  size: number;
  color?: string;
  backgroundColor?: string;
  className?: string;
}

const RealsTextIcon = ({ className, size, color, backgroundColor }: RealsTextIconProps) => {
  return (
    <Icon
      $size={size}
      $color={color}
      $backgroundColor={backgroundColor}
      className={"fa-kit fa-logo-plain " + className}
    />
  );
};

export default RealsTextIcon;
