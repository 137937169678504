import React, { useEffect, useRef, useState } from "react";

import "app/components/editor/sideDrawers/LayoutDrawer.scss";
import useDrawer, { Drawer } from "app/hooks/useDrawer";
import FlexDrawer from "app/components/common/Layout/FlexDrawer";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { useThreads } from "app/services/liveBlocksClient";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { Composer, Thread } from "@liveblocks/react-comments";
import ConditionalRender from "app/components/common/ConditionalRender";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { commentsDrawerMessages } from "app/components/editor/sideDrawers/CommentsDrawer/messages";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";

const StyledThread = styled(Thread)`
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 2px 6px rgb(0 0 0 / 4%), 0 8px 26px rgb(0 0 0 / 6%);
  flex: 1 0 auto;
`;
const StyledComposer = styled(Composer)`
  width: 100%;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 2px 6px rgb(0 0 0 / 4%), 0 8px 26px rgb(0 0 0 / 6%);
  flex: 1 0 auto;
`;
export interface CommentsDrawerProps {
  right: string;
  width: string;
}

const CommentsDrawer = ({ right, width }: CommentsDrawerProps) => {
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const flexColumnRef = useRef<HTMLDivElement>(null);
  const { editorDrawerOpen } = useDrawer();
  const { threads } = useThreads();
  const { formatMessage } = useIntl();
  const isDrawerActive = editorDrawerOpen === Drawer.Comments;
  useEffect(() => {
    const checkForScrollbar = () => {
      const element = flexColumnRef.current;
      if (element) {
        const hasOverflow = element.scrollHeight > element.clientHeight;
        setHasScrollbar(hasOverflow);
      }
    };

    checkForScrollbar();
    window.addEventListener("resize", checkForScrollbar);

    return () => {
      window.removeEventListener("resize", checkForScrollbar);
    };
  }, [threads]);

  return (
    <FlexDrawer right={right} active={isDrawerActive} width={width} className="comments">
      <H1_FlexRow flex="0 0 auto">
        <H1_TextSmall>{formatMessage(commentsDrawerMessages.title)}</H1_TextSmall>
      </H1_FlexRow>
      <H1_FlexColumn
        ref={flexColumnRef}
        flex="0 1 auto"
        overflow="auto"
        gap="20px"
        padding={hasScrollbar ? "15px 12px 5px 2px" : "15px 22px 5px 2px"}
      >
        <ConditionalRender condition={threads && threads.length > 0}>
          {threads?.map((thread) => (
            <StyledThread key={thread.id} thread={thread} className="thread" />
          ))}
        </ConditionalRender>
      </H1_FlexColumn>
      <H1_FlexRow flex="0 0 auto" padding="10px 22px 0 0">
        <StyledComposer className="composer" />
      </H1_FlexRow>
    </FlexDrawer>
  );
};

export default CommentsDrawer;
