import { defineMessages } from "react-intl";

export const cgnMessages = defineMessages({
  headline: {
    id: `cgn.headline`,
    defaultMessage: "Live"
  },
  selectSubject: {
    id: `cgn.select-subject`,
    defaultMessage: "Select any subject you want and generate content"
  },
  promptPlaceholder: {
    id: `cgn.prompt-placeholder`,
    defaultMessage: "Enter your prompt"
  },
  writeCommentPlaceholder: {
    id: `cgn.comment-placeholder`,
    defaultMessage: "Write a comment..."
  },
  commentsTitle: {
    id: `cgn.comment-title`,
    defaultMessage: "Comments"
  }
});
