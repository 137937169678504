import React, { SyntheticEvent, useState } from "react";
import styled from "styled-components";
import { addPolicyToUrlIfFileStackMedia, onImageError, onVideoError } from "app/utils/helpers";
import { useAppSelector } from "app/hooks";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import ConditionalRender from "app/components/common/ConditionalRender";
import LottieSpinLoader from "app/components/common/Layout/LottieSpinLoader";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const RelativeFlexRow = styled(H1_FlexRow)`
  margin: 44px 34px;
  position: relative;
  min-width: 200px;
  min-height: 200px;
`;

const Modal = styled(StyledModal)`
  max-width: 70%;
`;

const StyledVideo = styled.video`
  aspect-ratio: 16/9;
  width: 100%;
  height: 80%;
`;

const StyledImage = styled.img`
  object-fit: cover;
  max-width: 100vh;
  max-height: 80vh;
`;

interface MediaLibraryPreviewModalProps {
  visible: boolean;
  onClose: () => void;
  isVideo: boolean;
  url: string;
}
const MediaLibraryPreviewModal = ({
  visible,
  onClose,
  isVideo,
  url
}: MediaLibraryPreviewModalProps) => {
  const [loading, setLoading] = useState(true);
  const filestackPolicy = useAppSelector((state) => state.media.filestackReadPolicy);
  const policyUrl = addPolicyToUrlIfFileStackMedia(url, filestackPolicy);

  const onLoadedVideoError = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
    setLoading(false);
    onVideoError(e);
  };

  const onLoadedImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    setLoading(false);
    onImageError(e);
  };

  return (
    <Modal
      width="auto"
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      closeIcon={<CloseModalIcon />}
      destroyOnClose
    >
      <RelativeFlexRow align="center" justify="center" overflow="hidden">
        <ConditionalRender condition={isVideo}>
          <StyledVideo
            onError={onLoadedVideoError}
            onCanPlay={() => setLoading(false)}
            src={policyUrl}
            controls
          />
        </ConditionalRender>
        <ConditionalRender condition={!isVideo}>
          <StyledImage
            hidden={loading}
            src={policyUrl}
            onLoad={() => setLoading(false)}
            onError={onLoadedImageError}
          />
        </ConditionalRender>
        <ConditionalRender condition={loading && !isVideo}>
          <LottieSpinLoader />
        </ConditionalRender>
      </RelativeFlexRow>
    </Modal>
  );
};

export default MediaLibraryPreviewModal;
