import React, { useEffect, useMemo, useState } from "react";
import ConditionalRender from "app/components/common/ConditionalRender";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Input, Popover } from "antd";
import { changePassword, resetPassword } from "app/auth/workosApiCalls";
import { isValidEmail } from "app/utils/helpers";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import styled from "styled-components";
import { loginMessages } from "app/pages/login/messages";
import { useIntl } from "react-intl";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import useNotifications from "app/hooks/useNotifications";
import { Button } from "@nextui-org/react";

const StyledUl = styled.ul`
  padding: 0 0 0 14px;
  margin: 0;
`;

const StyledInput = styled(Input)`
  position: relative;
  &&& .ant-input::placeholder {
    color: #999;
    opacity: 1;
  }
  &&& {
    height: 42px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
    box-shadow: none !important;
    .ant-input-prefix {
      margin-right: 12px;
    }
    &:focus,
    &.ant-input-affix-wrapper-focused {
      border-color: #5a5aff;
    }
    & .ant-input-group {
      height: 100%;
      .ant-input-affix-wrapper {
        height: 100%;
        border: none;
        border-right: 1px solid #ced4da;
      }
      .ant-input-group-addon {
        border: none;
      }
    }
  }
  i {
    width: 20px;
  }
`;

interface ForgotPasswordProps {
  onFinish: () => void;
  changePasswordMode?: boolean;
}
const ForgotPassword = ({ onFinish, changePasswordMode }: ForgotPasswordProps) => {
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [sent, setSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [params] = useSearchParams();
  const { notifyMessages } = useNotifications();

  const popoverContent = useMemo(() => {
    return (
      <H1_FlexColumn width="240px">
        <H1_TextSubtitle color="black" whiteSpace="break-spaces">
          {formatMessage(loginMessages.passwordReq)}
        </H1_TextSubtitle>
        <StyledUl>
          <li>
            <H1_TextSmall color="black" whiteSpace="break-spaces">
              {formatMessage(loginMessages.passwordRule1)}
            </H1_TextSmall>
          </li>
          <li>
            <H1_TextSmall color="black" whiteSpace="break-spaces">
              {formatMessage(loginMessages.passwordRule2)}
            </H1_TextSmall>
          </li>
          <li>
            <H1_TextSmall color="black" whiteSpace="break-spaces">
              {formatMessage(loginMessages.passwordRule3)}
            </H1_TextSmall>
          </li>
          <li>
            <H1_TextSmall color="black" whiteSpace="break-spaces">
              {formatMessage(loginMessages.passwordRule4)}
            </H1_TextSmall>
          </li>
        </StyledUl>
      </H1_FlexColumn>
    );
  }, []);

  useEffect(() => {
    if (params.get("token")) {
      setToken(params.get("token") || "");
      return () => {
        setToken("");
      };
    }
  }, []);
  const onSend = async () => {
    try {
      if (isValidEmail(email)) {
        setSent(true);
        await resetPassword(email);
        setError("");
      } else {
        setError("Invalid email");
      }
    } catch (err) {
      Sentry.captureException(err, {
        extra: { description: "unhandled reset password error", email: email }
      });
      console.error(err);
    }
  };
  const onChangePassword = async () => {
    try {
      if (newPassword === confirmPassword) {
        setError("");
        setLoading(true);
        await changePassword(newPassword, token as string);
        notifyMessages([
          {
            message: "Password has changed successfully"
          }
        ]);
        onFinish();
        setSent(true);
      } else {
        setError("Passwords do not match");
      }
    } catch (err) {
      //   todo here
      console.error(err);
      // todo identify the error for password issues
      if (err instanceof AxiosError && err?.response?.status === 400) {
        setError(err?.response?.data.detail);
      } else {
        Sentry.captureException(err, {
          extra: { description: "unhandled change password error", email: email }
        });
        setError("Failed to change password, please try again");
      }
    }
    setLoading(false);
  };

  if (changePasswordMode) {
    return (
      <H1_FlexColumn gap="10px" width="360px" alignSelf="center">
        <StyledInput
          type="password"
          value={newPassword}
          onChange={(evt) => setNewPassword(evt.target.value)}
          prefix={<H1_Icon icon="far fa-envelope" color="#cccccc" size="20px" />}
          placeholder={formatMessage(loginMessages.newPassword)}
          addonAfter={
            <Popover placement="top" content={popoverContent}>
              <i className="fa-regular fa-info-circle" />
            </Popover>
          }
        />
        <StyledInput
          type="password"
          value={confirmPassword}
          onChange={(evt) => setConfirmPassword(evt.target.value)}
          prefix={<H1_Icon icon="far fa-envelope" color="#cccccc" size="20px" />}
          placeholder={formatMessage(loginMessages.confirmNewPassword)}
          addonAfter={
            <Popover placement="top" content={popoverContent}>
              <i className="fa-regular fa-info-circle" />
            </Popover>
          }
        />
        <ConditionalRender condition={!!error}>
          <H1_TextSmall color="red">{error}</H1_TextSmall>
        </ConditionalRender>
        <Button
          color="primary"
          isDisabled={sent && !error}
          isLoading={loading}
          onClick={onChangePassword}
        >
          {formatMessage(loginMessages.changePassword)}
        </Button>

        <ConditionalRender condition={sent}>
          <Button color="primary" variant="light" onClick={onFinish}>
            {formatMessage(loginMessages.backToLogin)}
          </Button>
        </ConditionalRender>
      </H1_FlexColumn>
    );
  }

  return (
    <H1_FlexColumn gap="20px" width="360px" alignSelf="center">
      <H1_TextSmall color="black" textAlign="center" fontSize="13px" whiteSpace="break-spaces">
        {formatMessage(loginMessages.forgotPasswordTitle)}
      </H1_TextSmall>
      <StyledInput
        value={email}
        onChange={(evt) => setEmail(evt.target.value)}
        prefix={<H1_Icon icon="far fa-envelope" color="#cccccc" size="20px" />}
        placeholder={formatMessage(loginMessages.emailPlaceholder)}
        addonAfter={
          <Popover placement="top" content={popoverContent}>
            <i className="fa-regular fa-info-circle" />
          </Popover>
        }
      />
      <ConditionalRender condition={!!error}>
        <H1_TextSmall color="red">{error}</H1_TextSmall>
      </ConditionalRender>
      <ConditionalRender condition={sent}>
        <H1_TextSmall color="green">
          {formatMessage(loginMessages.forgotPasswordEmailSent)}
        </H1_TextSmall>
      </ConditionalRender>
      <ConditionalRender condition={!sent}>
        <Button color="primary" onClick={onSend}>
          {formatMessage(loginMessages.sendEmail)}
        </Button>
      </ConditionalRender>
      <Button variant="light" color="primary" onClick={onFinish}>
        {formatMessage(loginMessages.backToLogin)}
      </Button>
    </H1_FlexColumn>
  );
};

export default ForgotPassword;
