import { connect } from "react-redux";
import { Layout } from "antd";

import * as PropTypes from "prop-types";

import "app/hoc/WithPaddingContainer.scss";

const mapStateToProps = (state) => ({
  editorDrawerOpen: state.userUi.editorDrawerOpen
});

const mapDispatchToProps = {};

const WithPaddingContainer = ({ allowedDemo, editorDrawerOpen, children, hiddenSider }) => {
  return (
    <Layout.Content
      className={{
        "top-content-container": !hiddenSider,
        "editor-drawer-open": !hiddenSider && editorDrawerOpen && allowedDemo,
        "not-allowed": !allowedDemo
      }}
    >
      {children}
    </Layout.Content>
  );
};

WithPaddingContainer.propTypes = {
  allowedDemo: PropTypes.bool,
  editorDrawerOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.any,
  hiddenSider: PropTypes.bool
};

WithPaddingContainer.defaultProps = {
  allowedDemo: true,
  editorDrawerOpen: false,
  children: null,
  hiddenSider: false
};

export default connect(mapStateToProps, mapDispatchToProps)(WithPaddingContainer);
