import styled from "styled-components";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import React from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_TextMiddle, H1_TextMidHeadline } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { Button } from "@nextui-org/react";
import { StyledModal } from "app/components/common/StyledModal";

const Modal = styled(StyledModal)`
  .ant-modal-body {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    justify-content: space-between;
    background-color: #383544;
  }
`;

const IconContainer = styled(H1_FlexRow)`
  width: 29px;
  height: 29px;
  background-color: rgba(170, 170, 255, 0.12);
  i {
    color: white;
  }
`;
interface PlaygroundFtuModalProps {
  visible: boolean;
  onClose: () => void;
}
const PlaygroundFtuModal = ({ visible, onClose }: PlaygroundFtuModalProps) => {
  return (
    <Modal
      width="450px"
      open={visible}
      footer={null}
      centered
      onCancel={onClose}
      bodyStyle={{ height: "400px" }}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
    >
      <H1_FlexColumn gap="20px">
        <IconContainer justify="center" align="center">
          <i className="fa-solid fa-flask" />
        </IconContainer>
        <H1_TextMidHeadline color="white">Welcome to Hour One Playground</H1_TextMidHeadline>
        <H1_TextMiddle color="#BFBFBF" whiteSpace="normal">
          Check out our new playground environment. Try out ideas as a content creators aiming to
          enhance video creation within our playground.
        </H1_TextMiddle>
        <H1_TextMiddle color="#49ABFC" whiteSpace="normal">
          Every video creation will reduce 40 seconds from your total minutes
        </H1_TextMiddle>
      </H1_FlexColumn>
      <H1_FlexRow alignSelf="flex-end" justify="flex-end">
        <Button color="primary" onClick={onClose}>
          Let&lsquo;s Start
        </Button>
      </H1_FlexRow>
    </Modal>
  );
};

export default PlaygroundFtuModal;
