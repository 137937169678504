import { defineMessages } from "react-intl";

export const manageTeamMessages = defineMessages({
  headline: {
    id: "team.headline",
    defaultMessage: "Workspaces"
  },
  inviteMembers: {
    id: "team.inviteMembers",
    defaultMessage: "Invite members"
  },
  manageTeam: {
    id: "team.manage-team",
    defaultMessage: "Manage Workspace"
  },
  leaveTeam: {
    id: "team.leave-team",
    defaultMessage: "Leave workspace"
  },
  teamName: {
    id: "team.name",
    defaultMessage: "Workspace Name"
  },
  edit: {
    id: "team.edit",
    defaultMessage: "Edit"
  },
  teamMembers: {
    id: "team.team-members",
    defaultMessage: "Workspace Members"
  },
  member: {
    id: "team.member",
    defaultMessage: "Member"
  },
  columnEmail: {
    id: "team.column-email",
    defaultMessage: "Email"
  },
  columnStatus: {
    id: "team.column-status",
    defaultMessage: "Status"
  },
  approveInvite: {
    id: "team.approve-invite",
    defaultMessage: "Approve"
  },
  rejectInvite: {
    id: "team.reject-invite",
    defaultMessage: "Reject"
  },
  owner: {
    id: "team.owner",
    defaultMessage: "Owner"
  },
  statusPending: {
    id: "team.column-status-pending",
    defaultMessage: "Invited"
  },
  statusInvited: {
    id: "team.column-status-invited",
    defaultMessage: "Pending"
  },
  statusRejected: {
    id: "team.column-status-rejected",
    defaultMessage: "Rejected"
  },
  statusAccepted: {
    id: "team.column-status-accepted",
    defaultMessage: "Accepted"
  },
  removeFromTeam: {
    id: "team.remove-from-team",
    defaultMessage: "Remove from workspace"
  },
  viewerRole: {
    id: "team.roles.viewer",
    defaultMessage: "Viewer"
  },
  editorRole: {
    id: "team.roles.editor",
    defaultMessage: "Editor"
  },
  searchPlaceholder: {
    id: "team.search-placeholder",
    defaultMessage: "Search members..."
  },
  approveLeaveTeamTitle: {
    id: "team.approve-leave-team-title",
    defaultMessage: "Confirm leaving workspace"
  },
  approveLeaveTeamContent: {
    id: "team.approve-leave-team-content",
    defaultMessage: "Are you sure you want to leave this workspace?"
  }
});

export const joinTeamMessages = defineMessages({
  title: {
    id: "teams.join-team.title",
    defaultMessage: `Join <span>Workspaces</span>`
  },
  welcome: {
    id: "teams.join-team.welcome",
    defaultMessage: "You have been invited to join a new workspace:"
  },
  welcomePlurals: {
    id: "teams.join-team.welcome-plurals",
    defaultMessage: "You have been invited to join new workspaces:"
  },
  description: {
    id: "teams.join-team.description",
    defaultMessage: "Do you want to accept this invitation and join this workspace?"
  },
  descriptionPlurals: {
    id: "teams.join-team.description-plurals",
    defaultMessage: "Do you want to accept this invitation and join these workspaces?"
  },
  letsGo: {
    id: "teams.join-team.lets-collaborate",
    defaultMessage: "Done"
  },
  join: {
    id: "teams.join-team.join",
    defaultMessage: "Join"
  },
  reject: {
    id: "teams.join-team.reject",
    defaultMessage: "Reject"
  }
});

export const welcomeTeamMessages = defineMessages({
  title: {
    id: "teams.welcome-team.title",
    defaultMessage: `Welcome`
  },
  welcome: {
    id: "teams.welcome-team.welcome",
    defaultMessage: "Welcome to {name}'s workspace!"
  },
  description: {
    id: "teams.welcome-team.description",
    defaultMessage: "Let's work together to produce inspiring videos that captivate our audience"
  },
  letsGoButton: {
    id: "teams.welcome-team.lets-go-button",
    defaultMessage: "Let's go!"
  }
});
