import { Upload } from "antd";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import ConditionalRender from "app/components/common/ConditionalRender";
import buildGeneralMessage from "app/hoc/ErrorNotifier/buildGeneralMessage";
import useErrors from "app/hooks/useErrors";
import usePermissions from "app/hooks/usePermissions";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import {
  documentFileSizeErrors,
  documentFileTypeErrors,
  documentModalUploaderMessages
} from "app/components/editor/documents/messages";
import { DataType, ImportDataStoreRequest, MimeType } from "app/types";
import { useEffect, useRef } from "react";
import useFileUploadFilestack from "app/components/editor/scene/transcriptAudioUploader/useFileUploadFilestack";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { useTheme } from "styled-components";
import { Button } from "@nextui-org/react";

const { Dragger } = Upload;

const UploadDragWrapper = styled.div<{ $background: string }>`
  display: flex;
  width: 100%;
  .ant-upload.ant-upload-drag {
    background: ${(props) => props.$background};
    border-radius: 9px;
  }
  & > span {
    height: 276px;
    width: 100%;
  }
  border-radius: 10px;
`;

export interface UploadContentProps {
  uploadSucceeded: (importRequest: ImportDataStoreRequest) => void;
}

const UploadDocumentContent = ({ uploadSucceeded }: UploadContentProps) => {
  const intl = useIntl();
  const draggerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { notifyError } = useErrors();
  const { file, onSelectFile, fileStoreResult, importLoading, reset } = useFileUploadFilestack({
    autoUpload: true
  });
  const { isWriteWorkspaceAllow } = usePermissions();
  const validateFileExtensions = (uploadedFile: File): boolean => {
    if (!MimeType.document.includes(uploadedFile.type)) {
      notifyError({
        general: buildGeneralMessage(
          intl.formatMessage(documentFileTypeErrors.documentTypeErrorHeadline),
          intl.formatMessage(documentFileTypeErrors.documentsTypeErrorDescription, {
            acceptableTypes: ".docx, .pdf"
          })
        )
      });
    }
    return MimeType.document.includes(uploadedFile.type);
  };
  const validateFileSize = (uploadedFile: File): boolean => {
    const limitMegabytes = 16;
    const fileSizeBytes = uploadedFile.size;
    const fileSizeMegabytes = fileSizeBytes / Math.pow(1024, 2);
    if (fileSizeMegabytes > limitMegabytes) {
      notifyError({
        general: buildGeneralMessage(
          intl.formatMessage(documentFileSizeErrors.documentSizeErrorHeadline),
          intl.formatMessage(documentFileSizeErrors.documentsSizeErrorDescription, {
            limitSize: limitMegabytes.toString()
          })
        )
      });
    }
    return fileSizeMegabytes < limitMegabytes;
  };

  useEffect(() => {
    if (fileStoreResult) {
      const importRequest: ImportDataStoreRequest = {
        url: fileStoreResult.url,
        handle: fileStoreResult.handle,
        file_name: fileStoreResult.name,
        type: DataType.file
      };
      uploadSucceeded(importRequest);
      reset();
    }
  }, [fileStoreResult]);

  const handleBeforeUpload = (rcfile: File) => {
    const fileValidType = validateFileExtensions(rcfile);
    const fileValidSize = validateFileSize(rcfile);
    if (fileValidType && fileValidSize) {
      onSelectFile(rcfile);
    }
    return false; // Prevent automatic upload
  };

  const handleButtonClick = (e: any) => {
    e.stopPropagation();
    const fileInput = document.querySelector('.ant-upload input[type="file"]') as HTMLElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <UploadDragWrapper $background={theme.gray2}>
      <Dragger
        ref={draggerRef}
        disabled={!isWriteWorkspaceAllow}
        beforeUpload={handleBeforeUpload}
        showUploadList={false}
        accept={MimeType.document}
      >
        <ConditionalRender condition={importLoading}>
          <H1_FlexColumn justify="center" align="center">
            <CircleLoader />
            <H1_TextSmall fontWeight={700}>
              {intl.formatMessage(documentModalUploaderMessages.uploadingDocument)}
            </H1_TextSmall>
          </H1_FlexColumn>
        </ConditionalRender>
        <ConditionalRender condition={!importLoading}>
          <H1_FlexColumn height="160px" justify="space-between" align="center">
            <H1_Icon icon="fas fa-file-arrow-up" size="30px" color={theme.gray7} />
            <ConditionalRender condition={!file}>
              <H1_FlexColumn align="center">
                <H1_TextSmall color={theme.gray7}>
                  {intl.formatMessage(documentModalUploaderMessages.mainDescription)}
                </H1_TextSmall>
                <H1_TextXs color={theme.gray6}>
                  {intl.formatMessage(documentModalUploaderMessages.description)}
                </H1_TextXs>
              </H1_FlexColumn>
            </ConditionalRender>
            <ConditionalRender condition={!!file}>
              <H1_TextSmall fontWeight={600} color={theme.gray7}>
                {file?.name}
              </H1_TextSmall>
            </ConditionalRender>
            <Upload
              disabled={!isWriteWorkspaceAllow}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
              accept={MimeType.document}
            >
              <>
                <Button
                  color="primary"
                  isDisabled={!isWriteWorkspaceAllow}
                  startContent={<i className="fas fa-cloud-arrow-up" />}
                  onClick={handleButtonClick}
                >
                  {file
                    ? intl.formatMessage(documentModalUploaderMessages.changeFileButton)
                    : intl.formatMessage(documentModalUploaderMessages.browseButton)}
                </Button>
              </>
            </Upload>
          </H1_FlexColumn>
        </ConditionalRender>
      </Dragger>
    </UploadDragWrapper>
  );
};

export default UploadDocumentContent;
