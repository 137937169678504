import React, { ReactNode, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { Palette, PaletteColor } from "app/types";
import "app/components/editor/bottomDrawers/GroupDrawers.scss";
import styled from "styled-components";
import PaletteButton from "app/components/editor/sideDrawers/StyleDrawer/PaletteButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { templatesActions } from "app/store/slices/templates.slice";
import { Divider } from "antd";
import templatesSelectors from "app/store/selectorsV2/templates.selectors";
import ConditionalRender from "app/components/common/ConditionalRender";
import { styleDrawerMessages } from "app/components/editor/sideDrawers/StyleDrawer/messages";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { SelectColorType } from "app/store/thunks/analyticsEvents.thunk";
import OwnPallete from "app/components/editor/sideDrawers/StyleDrawer/OwnPallete";
import { extractColorsBySize, fetchingStatus } from "app/utils/helpers";
import { isEqual } from "lodash";
import { draftsActions as draftsActionsV2 } from "app/store/slices/drafts.slice";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall } from "app/components/_Infrastructure/Typography";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { Button } from "@nextui-org/react";

const TextButton = styled(Button)`
  height: unset;
  text-decoration: underline;
  &&:hover {
    text-decoration: underline;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0px;
  border-top: 1px solid ${(props) => props.theme.gray5};
`;

const TitledColumnContainer = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <H1_FlexColumn gap="12px">
      <H1_TextSmall fontSize="13px">{title}</H1_TextSmall>
      {children}
    </H1_FlexColumn>
  );
};

const StyleDrawerInner = ({
  drawerName,
  isDrawerActive = false
}: {
  drawerName: string;
  isDrawerActive: boolean;
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const currentPalettes = useAppSelector(templatesSelectors.getSystemPalettes);
  const currentDraft = useAppSelector((state) => state.drafts.currentDraft);
  const appliedPalette = useAppSelector(templatesSelectors.getAppliedPalette);

  const currentBrandkit = useAppSelector((state) => state.brandKit.currentBrandkit);
  const createPaletteStatus = useAppSelector((state) => state.templates.createPaletteStatus);
  const createPaletteResult = useAppSelector((state) => state.templates.createPaletteResult);

  const draftId = currentDraft.id as string;
  const createLoading = createPaletteStatus === fetchingStatus.loading;

  const showUseBrandButton = useMemo(() => {
    return (
      !!currentBrandkit.palette &&
      !isEqual(
        extractColorsBySize(currentBrandkit.palette, appliedPalette)?.map(
          (palette: PaletteColor) => palette.color
        ),
        appliedPalette?.map((palette: PaletteColor) => palette.color)
      )
    );
  }, [currentBrandkit.palette, appliedPalette]);

  useEffect(() => {
    if (isDrawerActive) {
      dispatch(
        templatesActions.getPalettesRequest({
          draftId,
          templateId: currentDraft.template_id as string
        })
      );
    }
  }, [isDrawerActive, draftId]);

  useEffect(() => {
    if (isDrawerActive && createPaletteStatus === fetchingStatus.succeeded) {
      dispatch(templatesActions.setCreatePaletteToIdle());
      onSelectPaletteByPaletteId(createPaletteResult as Palette);
    } else if (isDrawerActive && createPaletteStatus === fetchingStatus.failed) {
      dispatch(templatesActions.setCreatePaletteToIdle());
    }
  }, [createPaletteStatus, isDrawerActive]);

  const onSelectPaletteByPaletteId = (palette: Palette) => {
    dispatch(
      analyticsEvents.selectColor({
        palette: palette.name,
        paletteId: palette.id as string,
        type: SelectColorType.change_by_palette_id
      })
    );
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId,
        operations: [
          { op: "replace", path: "palette_id", value: palette.id },
          { op: "replace", path: "palette" }
        ]
      })
    );
  };
  const onSelectPaletteByPalette = (colors: PaletteColor[], type: SelectColorType) => {
    dispatch(analyticsEvents.selectColor({ palette: "brand", type: type }));
    dispatch(
      draftsActionsV2.patchDraftRequest({
        draftId,
        operations: [
          { op: "replace", path: "palette", value: colors },
          { op: "replace", path: "palette_id" }
        ]
      })
    );
  };

  const upsertCustom = (colors: PaletteColor[]) => {
    dispatch(
      templatesActions.upsertPaletteRequest({
        draftId,
        name: "custom",
        colors: colors,
        skipFramePreview: true
      })
    );
  };

  const onUseBrandColor = () => {
    const colors = extractColorsBySize(
      currentBrandkit.palette as PaletteColor[],
      currentPalettes[0].colors
    );
    if (colors) {
      onSelectPaletteByPalette(colors, SelectColorType.use_brand);
    } else {
      console.error("failed to apply brand colors", currentBrandkit.palette);
    }
  };

  return (
    <>
      <H1_FlexColumn gap="12px">
        <H1_TextSmall fontWeight={500}>
          {intl.formatMessage(styleDrawerMessages.styleDrawerTitle)}
        </H1_TextSmall>
        <ConditionalRender condition={!!appliedPalette}>
          <H1_FlexColumn gap="8px" padding="0px 22px 0px 0px">
            <H1_FlexRow justify={"space-between"}>
              <H1_TextSmall fontSize="13px">
                {intl.formatMessage(styleDrawerMessages.appliedTitle)}
              </H1_TextSmall>
              <ConditionalRender condition={showUseBrandButton}>
                <H1_FlexRow gap={"5px"} align={"center"} justify={"center"}>
                  <TextButton
                    color="primary"
                    isDisabled={createLoading}
                    variant="light"
                    onClick={onUseBrandColor}
                  >
                    {intl.formatMessage(styleDrawerMessages.useBrandkit)}
                  </TextButton>
                </H1_FlexRow>
              </ConditionalRender>
            </H1_FlexRow>
            <PaletteButton
              paletteColors={appliedPalette as PaletteColor[]}
              selected={false}
              disabled
              paletteId={currentDraft.palette_id}
            />

            <StyledDivider />
          </H1_FlexColumn>
        </ConditionalRender>
      </H1_FlexColumn>
      <H1_FlexColumn flex="1" justify="space-between">
        <H1_FlexColumn padding="15px 22px 0px 0px" gap="17px" overflow="auto">
          <TitledColumnContainer title={intl.formatMessage(styleDrawerMessages.colorsTitle)}>
            <H1_FlexRow>
              <OwnPallete
                drawerName={drawerName}
                upsertCustom={upsertCustom}
                onSelectPalette={onSelectPaletteByPaletteId}
              />
            </H1_FlexRow>
            {currentPalettes.map((palette) => {
              return (
                <PaletteButton
                  onSelectPalette={() => onSelectPaletteByPaletteId(palette)}
                  key={palette.id}
                  paletteColors={palette.colors}
                  selected={palette.id === currentDraft.palette_id}
                  paletteId={palette.id}
                />
              );
            })}
          </TitledColumnContainer>
        </H1_FlexColumn>
      </H1_FlexColumn>
    </>
  );
};

export default StyleDrawerInner;
