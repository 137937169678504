import { Layout, Space } from "antd";
import VideoProgress from "app/components/editor/sideDrawers/RenderPanel/VideoProgress";
import { Video } from "app/types";
import { ThemeMode } from "app/utils/theme";
import styled, { useTheme } from "styled-components";

const Card = styled("div")<{ $selected?: boolean }>`
  width: 100%;
  height: auto;
  color: ${(props) => (props.$selected === true ? props.theme.pink4 : "#7e7e7e")};
  box-shadow: ${(props) => (props.$selected === true ? "0px 0px 4px rgba(0, 0, 0, 0.25)" : "none")};
  border: none;
  &:focus,
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border: none;
    filter: brightness(120%);
    cursor: pointer;
  }
`;
interface RenderingContentProps {
  onExport: (video: Video) => void;
  onRevert: (video: Video) => void;
  onVideoSelect: (videoId: string) => void;
  selectedVideo?: Video;
  videos: Video[];
}

const RenderingContent = ({
  selectedVideo,
  videos,
  onExport,
  onRevert,
  onVideoSelect
}: RenderingContentProps) => {
  const theme = useTheme();
  return (
    <Layout>
      <Layout.Content
        style={{
          overflow: "hidden auto",
          background: theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray2,
          paddingBottom: "20px"
        }}
      >
        <Space
          direction="vertical"
          style={{ width: "100%", marginLeft: "5px", paddingRight: "10px" }}
        >
          {" "}
          {videos.map((video) => {
            return (
              <Card
                $selected={selectedVideo?.id === video.id}
                key={video.id}
                onClick={() => onVideoSelect(video.id)}
              >
                <VideoProgress video={video} onExport={onExport} onRevert={onRevert} />
              </Card>
            );
          })}
        </Space>
      </Layout.Content>
    </Layout>
  );
};

RenderingContent.defaultProps = {
  selectedVideo: undefined
};
export default RenderingContent;
