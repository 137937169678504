import { defineMessages } from "react-intl";

// Create Folder Modal
export const createFolderModalMessages = defineMessages({
  nameUpdateNotification: {
    id: `folders.create-folder-modal.folder-name-updated-notification`,
    defaultMessage: `Folder "{name}" was created`
  },
  title: {
    id: `folders.create-folder-modal.title`,
    defaultMessage: `Create folder`
  },
  nameFormHeader: {
    id: `folders.create-folder-modal.name-form-header`,
    defaultMessage: `Name your new folder`
  },
  nameFormPlaceholder: {
    id: `folders.create-folder-modal.name-form-placeholder`,
    defaultMessage: `Folder name`
  },
  formValidationErrorEmpty: {
    id: `folders.create-folder-modal.form-validation-error-empty`,
    defaultMessage: `Please insert a folder name`
  },
  formValidationErrorUnique: {
    id: `folders.create-folder-modal.form-validation-error-unique`,
    defaultMessage: `Please insert a unique folder name`
  },
  cancelButton: {
    id: `folders.create-folder-modal.cancel-button`,
    defaultMessage: `Cancel`
  },
  createButton: {
    id: `folders.create-folder-modal.create-button`,
    defaultMessage: `Create`
  }
});

// Delete Video Modal
export const deleteVideoModalMessages = defineMessages({
  message: {
    id: `folders.delete-video-modal.message`,
    defaultMessage: `"{draftTitle}" video was deleted`
  },
  title: {
    id: `folders.delete-video-modal.title`,
    defaultMessage: `Delete video`
  },
  content: {
    id: `folders.delete-video-modal.content`,
    defaultMessage: `Are you sure you want to delete <b>{draftTitle}</b> Video?`
  },
  cancelButton: {
    id: `folders.delete-video-modal.cancel-button`,
    defaultMessage: `Cancel`
  },
  deleteButton: {
    id: `folders.delete-video-modal.delete-button`,
    defaultMessage: `Delete`
  }
});

// Delete Folder Modal
export const deleteFolderModalMessages = defineMessages({
  title: {
    id: `folders.delete-folder-modal.title`,
    defaultMessage: `Delete folder`
  },
  content: {
    id: `folders.delete-folder-modal.content`,
    defaultMessage: `Are you sure you want to delete <b> {folderName} </b> folder?`
  },
  cancelButton: {
    id: `folders.delete-folder-modal.cancel-button`,
    defaultMessage: `Cancel`
  },
  deleteButton: {
    id: `folders.delete-folder-modal.delete-button`,
    defaultMessage: `Delete`
  }
});

// Folder Item
export const folderItemMessages = defineMessages({
  updated: {
    id: `folders.folder-item.updated`,
    defaultMessage: `Updated {time}`
  },
  menuItemOpenFolder: {
    id: `folders.folder-item.menu-item-open-folder`,
    defaultMessage: `Open folder`
  },
  menuItemCreateList: {
    id: `folders.folder-item.menu-item-create-playlist`,
    defaultMessage: `Create playlist`
  },
  menuItemDeleteFolder: {
    id: `folders.folder-item.menu-item-delete-folder`,
    defaultMessage: `Delete folder`
  },
  menuItemRenameFolder: {
    id: `folders.folder-item.menu-item-rename-folder`,
    defaultMessage: `Rename folder`
  }
});

// Folder Page
export const folderPageMessages = defineMessages({
  title: {
    id: `folders.folder-page.title`,
    defaultMessage: `My folders`
  }
});

// Folder Table
export const folderTableMessages = defineMessages({
  noFoldersTitle: {
    id: `folders.folder-table.no-folders-title`,
    defaultMessage: `No folders exist yet`
  },
  noFoldersSubTitle: {
    id: `folders.folder-table.no-folders-sub-title`,
    defaultMessage: `To add folders here, click on Create New Folder.`
  },
  noFoldersFoundTitle: {
    id: `folders.folder-table.no-folders-found-title`,
    defaultMessage: `No folders found`
  },
  noFoldersFoundSubTitle: {
    id: `folders.folder-table.no-folders-found-sub-title`,
    defaultMessage: `No folders match your search, try another search term.`
  },
  createFolderLabel: {
    id: `folders.folder-table.create-folder`,
    defaultMessage: `New folder`
  },
  table: {
    id: `folders.folder-table.table`,
    defaultMessage: `Table`
  },
  grid: {
    id: `folders.folder-table.grid`,
    defaultMessage: `Grid`
  },
  home: {
    id: `folders.folder-breadcrumb.home`,
    defaultMessage: `Home`
  },
  projects: {
    id: `folders.folder-breadcrumb.projects`,
    defaultMessage: `My videos`
  },
  name: {
    id: `folders.folder-table.name-column`,
    defaultMessage: `Name`
  },
  updatedAt: {
    id: `folders.folder-table.updated-at-column`,
    defaultMessage: `Edited`
  }
});

// Move To Folder Modal
export const moveFolderModalMessages = defineMessages({
  title: {
    id: `folders.move-folder-modal.title`,
    defaultMessage: `Move to folder`
  },
  subtitle: {
    id: `folders.move-folder-modal.subtitle`,
    defaultMessage: `Folders`
  },
  numOfItems: {
    id: `folders.move-folder-modal.num-of-items`,
    defaultMessage: `{number} videos` // Todo - handle plurals
  },
  createNewProject: {
    id: `folders.move-folder-modal.create-new-project`,
    defaultMessage: `Create new folder`
  },
  cancelButton: {
    id: `folders.move-folder-modal.cancel-button`,
    defaultMessage: `Cancel`
  },
  moveButton: {
    id: `folders.move-folder-modal.move-button`,
    defaultMessage: `Move`
  }
});

// Rename Draft Modal
export const renameDraftModalMessages = defineMessages({
  title: {
    id: `folders.rename-draft-modal.title`,
    defaultMessage: `Rename video`
  },
  updateNameNotification: {
    id: `folders.rename-draft-modal.update-name-notification`,
    defaultMessage: `Video "{previousName}" has been renamed as "{name}"`
  },
  label: {
    id: `folders.rename-draft-modal.label`,
    defaultMessage: `Give this video a new name`
  },
  validationError: {
    id: `folders.rename-draft-modal.validation-error`,
    defaultMessage: `Please enter a new name`
  },
  validationUniqueError: {
    id: `folders.rename-draft-modal.validation-unique-error`,
    defaultMessage: `Please insert a unique video name`
  },
  namePlaceholder: {
    id: `folders.rename-draft-modal.name-placeholder`,
    defaultMessage: `Video name`
  },
  cancelButton: {
    id: `folders.rename-draft-modal.cancel-button`,
    defaultMessage: `Cancel`
  },
  saveButton: {
    id: `folders.rename-draft-modal.save-button`,
    defaultMessage: `Save`
  }
});

// Rename Folder Modal
export const renameFolderModalMessages = defineMessages({
  title: {
    id: `folders.rename-folder-modal.title`,
    defaultMessage: `Rename folder`
  },
  updateNameNotification: {
    id: `folders.rename-folder-modal.update-name-notification`,
    defaultMessage: `Folder "{previousName}" has been renamed as "{name}"`
  },
  label: {
    id: `folders.rename-folder-modal.content`,
    defaultMessage: `Give this folder a new name`
  },
  validationError: {
    id: `folders.rename-folder-modal.validation-error`,
    defaultMessage: `Please enter a new name`
  },
  validationUniqueError: {
    id: `folders.rename-folder-modal.validation-unique-error`,
    defaultMessage: `Please insert a unique folder name`
  },
  namePlaceholder: {
    id: `folders.rename-folder-modal.name-placeholder`,
    defaultMessage: `Folder name`
  },
  cancelButton: {
    id: `folders.rename-folder-modal.cancel-button`,
    defaultMessage: `Cancel`
  },
  saveButton: {
    id: `folders.rename-folder-modal.save-button`,
    defaultMessage: `Save`
  }
});

// Video Actions Menu
export const videoActionsMenu = defineMessages({
  menuItemEditVideo: {
    id: `folders.video-actions-menu.edit-video`,
    defaultMessage: `Edit video`
  },
  menuItemRenameVideo: {
    id: `folders.video-actions-menu.rename-video`,
    defaultMessage: `Rename video`
  },
  menuItemMoveToFolder: {
    id: `folders.video-actions-menu.move-to-folder`,
    defaultMessage: `Move to folder`
  },
  menuItemDuplicateVideo: {
    id: `folders.video-actions-menu.duplicate-video`,
    defaultMessage: `Duplicate video`
  },
  menuItemDeleteVideo: {
    id: `folders.video-actions-menu.delete-video`,
    defaultMessage: `Delete video`
  }
});

// Video Search Bar
export const videoSearchBarMessages = defineMessages({
  placeholder: {
    id: `folders.video-search-bar.placeholder`,
    defaultMessage: `Search a video`
  }
});
