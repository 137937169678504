import React, { useEffect } from "react";
import useModal, { ModalName } from "app/hooks/useModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchingStatus } from "app/utils/helpers";
import { userActions } from "app/store/slices/user.slice";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextMiddle } from "app/components/_Infrastructure/Typography";
import { StyledModal } from "app/components/common/StyledModal";

const SlackBindModal = () => {
  const dispatch = useAppDispatch();
  const { openModal, editorModalOpen, closeModal } = useModal();
  const initialQueryParams = useAppSelector((state) => state.user.initialQueryParams);
  const slackBindStatus = useAppSelector((state) => state.user.slackBindStatus);
  const open = editorModalOpen?.open === ModalName.slackBindModal;

  useEffect(() => {
    if (initialQueryParams.slack_install) {
      dispatch(userActions.slackBindRequest(initialQueryParams.slack_install));
    }
  }, [initialQueryParams]);

  useEffect(() => {
    if (slackBindStatus === fetchingStatus.succeeded) {
      openModal(ModalName.slackBindModal);
    }
  }, [slackBindStatus]);

  const onClose = () => {
    closeModal();
  };
  return (
    <StyledModal
      width="867px"
      open={open}
      footer={null}
      centered
      bodyStyle={{ height: "671px" }}
      closeIcon={<CloseModalIcon />}
      onCancel={onClose}
      destroyOnClose
      $padding="44px 47px 45px"
      $alignItems="center"
    >
      <H1_FlexColumn>
        <H1_TextMiddle>Slack installation succeeded</H1_TextMiddle>
      </H1_FlexColumn>
    </StyledModal>
  );
};

export default SlackBindModal;
