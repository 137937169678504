import styled, { useTheme } from "styled-components";
import { Progress, Table, TablePaginationConfig, Tooltip } from "antd";
import { AlignType } from "rc-table/lib/interface";
import { Draft, Video, VideoStatusEnum, WorkflowSubmission } from "app/types";
import React, { useEffect, useMemo, useState } from "react";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";
import { dateSorter, fetchingStatus, getTimeAgo, hasSomeParentTheClass } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import useModal, { ModalName } from "app/hooks/useModal";
import { defineMessages, useIntl } from "react-intl";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import { videosEntities } from "app/store/selectorsV2/videos.selectors";
import StatusTag from "app/pages/EditDraftPage/workflow/Statustag";
import SubmissionView from "app/pages/EditDraftPage/workflow/SubmissionView";
import { draftsActions } from "app/store/slices/drafts.slice";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";

const messages = defineMessages({
  tableUpdated: {
    id: `workflow.submissions.table.column.created_at`,
    defaultMessage: "Date"
  },
  tableStatus: {
    id: `workflow.submissions.table.column.status`,
    defaultMessage: "Status"
  },
  tableViewData: {
    id: `workflow.submissions.table.column.video-data`,
    defaultMessage: "Video Data"
  },
  tableEmail: {
    id: `workflow.submissions.table.column.email`,
    defaultMessage: "Email"
  },
  tableActions: {
    id: `workflow.submissions.table.column.actions`,
    defaultMessage: "Actions"
  }
});

const StyledTable = styled(Table)`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
  margin-top: 0.3125rem;
  .ant-table {
    background-color: transparent;
  }
  && .ant-table-thead > tr > th {
    border-bottom: 1px solid ${(props) => props.theme.gray5};
    background-color: transparent;
    padding-left: 0;
    position: sticky;
    top: 0;
    background-color: ${(props) => props.theme.gray4};
    z-index: 11;
  }
  .ant-table table {
    border-spacing: 0 0.625rem;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0.5625rem 0.5rem;
  }
  & .ant-table-column-sorters {
    flex-direction: row-reverse;
    gap: 0.3125rem;
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        font-family: Inter, -apple-system, BlinkMacSystemFont, Poppins, Ariel;
        transition: all 0.5s ease-in-out;
        background-color: transparent;
        height: 5.3125rem;
      }
      &:hover {
        td {
          background-color: ${(props) =>
            props.theme.gray2} !important; /* &&& doesn't work with &:hover */
          ${NameText} {
            text-decoration: underline;
          }
        }
      }
      padding: 0.625rem 1rem 0.625rem;
      &:not(:last-child) td {
        border-bottom: 1px solid ${(props) => props.theme.gray4};
      }
    }
  }
  .ant-pagination-item {
    &.ant-pagination-item-active {
      border-color: ${({ theme }) => theme.blue4};
      a {
        color: ${({ theme }) => theme.blue4};
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.blue2};
      a {
        color: ${({ theme }) => theme.blue2};
      }
    }
    &:active {
      border-color: ${({ theme }) => theme.blue1};
      a {
        color: ${({ theme }) => theme.blue1};
      }
    }
  }
`;

const StyledProgress = styled(Progress)`
  width: 6.25rem;
  .ant-progress-inner {
    border: 1px solid ${({ theme }) => theme.blue2};
  }
`;

const PlayButton = styled(Button)`
  &&,
  &&:focus {
    flex: 0 0 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 2.5rem;
    min-width: 2.5rem;
    border: 1px solid ${({ theme }) => theme.blue1};
    background-color: ${({ theme }) => theme.blue1};
    i {
      color: ${({ theme }) => theme.gray10};
    }
  }
  &&:hover {
    background-color: ${({ theme }) => theme.blue2};
    border: 1px solid ${({ theme }) => theme.blue2};
  }
  &&:active {
    background-color: ${({ theme }) => theme.blue3};
    border: 1px solid ${({ theme }) => theme.blue3};
  }
`;

const StyledIcon = styled.i`
  font-size: 0.625rem;
`;

const StyledViewDataButton = styled(Button)`
  height: 38px;
  width: 100px;
`;
export const DEFAULT_TABLE_PAGE_SIZE = 10;
const SOURCE = "submissions-table";

const SubmissionsTable = () => {
  const [submissionIdCreateDraft, setSubmissionIdCreateDraft] = useState<string>("");
  const [viewSubmissionId, setViewSubmissionId] = useState<string>("");
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const submissions = useAppSelector((state) => state.drafts.workflowSubmissions);
  const withPagination = submissions.length > DEFAULT_TABLE_PAGE_SIZE;
  const videosMap = useAppSelector((state) => videosEntities(state));
  const workflowId = useAppSelector((state) => state.drafts.currentDraft.workflow_id);
  const runSubmissionStatus = useAppSelector((state) => state.drafts.runSubmissionStatus);

  const runSubmissionResultDraftId = useAppSelector(
    (state) => state.drafts.runSubmissionResultDraftId
  );
  const playVideo = (e: React.MouseEvent, submission: WorkflowSubmission) => {
    e.stopPropagation();
    dispatch(analyticsEvents.videoPlay({ source: SOURCE, videoId: submission.video?.id }));
    openModal(ModalName.videoReady, { reqId: submission.video?.req_id });
  };

  const onView = (id: string) => {
    dispatch(analyticsEvents.viewSubmission({ source: SOURCE, submissionId: id, workflowId }));
    setViewSubmissionId(id);
  };
  const onRunSubmission = (submissionId: string, type: "create_draft" | "create_video") => {
    dispatch(analyticsEvents.runSubmission({ source: SOURCE, submissionId, type, workflowId }));
    setSubmissionIdCreateDraft(submissionId);
    dispatch(
      draftsActions.runWorkflowSubmissionRequest({
        workflowId: workflowId as string,
        submissionId,
        type
      })
    );
  };
  useEffect(() => {
    if (runSubmissionStatus === fetchingStatus.succeeded) {
      navigate(`/editor/${runSubmissionResultDraftId}`);
      dispatch(draftsActions.setRunSubmissionStatusToIdle());

      setSubmissionIdCreateDraft("");
    } else if (runSubmissionStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setRunSubmissionStatusToIdle());
      setSubmissionIdCreateDraft("");
    }
  }, [runSubmissionStatus]);

  const columns: any = useMemo(
    () => [
      {
        title: formatMessage(messages.tableUpdated),
        dataIndex: "created_at",
        key: "created_at",
        width: "9.375rem",
        render: (editedAt: string) => {
          return (
            <H1_FlexColumn>
              <H1_TextSmall>{editedAt && getTimeAgo(editedAt)}</H1_TextSmall>
            </H1_FlexColumn>
          );
        },
        sorter: (a: WorkflowSubmission, b: WorkflowSubmission) =>
          dateSorter(a.updated_at as string, b.updated_at as string)
      },
      {
        title: formatMessage(messages.tableStatus),
        dataIndex: "video",
        key: "video",
        width: "9.375rem",
        render: (v?: Video) => {
          const videoId = v?.id;
          const video = videosMap[videoId as string];
          const progress = video?.progress;
          const isProgressDisplayed = !!(progress && progress > 0 && progress < 100);
          // todo need to map status to text
          return (
            <H1_FlexColumn align={"center"}>
              <StatusTag>
                <H1_TextXs color={"#595959"} fontWeight={400}>
                  {video ? video.status : "not created"}
                </H1_TextXs>
              </StatusTag>
              <ConditionalRender condition={isProgressDisplayed}>
                <Tooltip title={`${progress}%`}>
                  <StyledProgress
                    strokeColor={theme.blue2}
                    strokeWidth={8}
                    trailColor={theme.gray1}
                    strokeLinecap="square"
                    percent={progress}
                    showInfo={false}
                    width={75}
                  />
                </Tooltip>
              </ConditionalRender>
            </H1_FlexColumn>
          );
        }
      },
      {
        title: formatMessage(messages.tableViewData),
        key: "status",
        width: "9.375rem",
        render: (_: any, row: WorkflowSubmission) => {
          return (
            <H1_FlexColumn>
              <StyledViewDataButton onClick={() => onView(row.id)}>View Data</StyledViewDataButton>
            </H1_FlexColumn>
          );
        }
      },
      {
        title: formatMessage(messages.tableEmail),
        dataIndex: "email",
        key: "email",
        width: "9.375rem",
        render: (email: string) => {
          return (
            <H1_FlexColumn>
              <H1_TextSmall>{email || "anonymous"}</H1_TextSmall>
            </H1_FlexColumn>
          );
        }
      },
      {
        title: formatMessage(messages.tableActions),
        dataIndex: "id",
        key: "id",
        width: "9.375rem",
        render: (id: string) => {
          return (
            <H1_FlexColumn>
              <Button
                isLoading={id === submissionIdCreateDraft}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  onRunSubmission(id, "create_draft");
                }}
                variant="light"
                color="primary"
              >
                Create Draft
              </Button>
            </H1_FlexColumn>
          );
        }
      },
      {
        dataIndex: "",
        align: "center" as AlignType,
        key: "playVideo",
        width: "4.6875rem",
        hidden: submissions.length === 0,
        onFilter: (_: unknown, row: WorkflowSubmission) => {
          return (
            row.video?.status === VideoStatusEnum.Published ||
            row.video?.status === VideoStatusEnum.Ready
          );
        },
        render: (_: unknown, row: WorkflowSubmission) => {
          const video = videosMap[row.video?.id as string];

          return (
            <ConditionalRender
              condition={[VideoStatusEnum.Published, VideoStatusEnum.Ready].includes(
                video?.status as VideoStatusEnum
              )}
            >
              <H1_FlexRow justify="center" align="center">
                <PlayButton
                  radius="full"
                  onClick={(e) => playVideo(e, row)}
                  startContent={<StyledIcon className="fas fa-play" />}
                />
              </H1_FlexRow>
            </ConditionalRender>
          );
        }
      }
    ],
    [submissions, videosMap, submissionIdCreateDraft]
  ).filter((item) => !item.hidden);

  if (!submissions.length) {
    return <>No submissions yet</>;
  }
  const onBack = () => {
    setViewSubmissionId("");
  };
  return (
    <>
      <ConditionalRender condition={!!viewSubmissionId}>
        <SubmissionView submissionId={viewSubmissionId} onBack={onBack} />
      </ConditionalRender>
      <ConditionalRender condition={submissions.length > 0 && !viewSubmissionId}>
        <StyledTable
          size="middle"
          columns={columns}
          rowKey="id"
          pagination={
            withPagination
              ? ({ position: ["bottomCenter"], size: "default" } as TablePaginationConfig)
              : false
          }
          dataSource={submissions}
          onRow={(record: Draft): React.HTMLAttributes<HTMLElement> => ({
            onClick: (event: React.MouseEvent<HTMLElement>) => {
              if (
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "draft-actions-menu") &&
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "ant-dropdown-trigger") &&
                // @ts-ignore handels Argument of type 'EventTarget' is not assignable to parameter of type 'Element'
                !hasSomeParentTheClass(event.target, "ant-dropdown")
              ) {
                onView(record.id as string);
              }
            }
          })}
        />
      </ConditionalRender>
    </>
  );
};

export default SubmissionsTable;
