import { createSelector } from "reselect";
import { RootState } from "app/store/store";
import { BackgroundMusic } from "app/types/music";
import { backgroundMusicGlobalSelectors } from "app/store/adapters/adapters";

export const currentDraft = (state: RootState) => state.drafts.currentDraft;

export const getSelectedBackgroundMusic = createSelector(
  [currentDraft, backgroundMusicGlobalSelectors.selectAll],
  (draft, tracks) => {
    const musicUrl = draft?.attributes?.music?.background_music?.url;
    if (musicUrl) {
      return tracks.find((track) => track.url === musicUrl);
    }
    return undefined;
  }
);

export const getSelectedBackgroundMusicVolume = createSelector([currentDraft], (draft) => {
  const musicVolume = draft?.attributes?.music?.background_music?.audio_properties?.volume;
  if (musicVolume) {
    return musicVolume * 100;
  }
});

export const formattedMusicTracks = createSelector(
  [backgroundMusicGlobalSelectors.selectAll, (state, { query }: { query?: string }) => query || ""],
  (tracks, query) => {
    if (!tracks || tracks.length === 0) {
      return [] as BackgroundMusic[];
    }
    const filtered = tracks.filter(
      (track) =>
        track.title.toLowerCase().includes(query.toLowerCase()) ||
        track.description?.toLowerCase().includes(query.toLowerCase()) ||
        track.artist?.toLowerCase().includes(query.toLowerCase()) ||
        track.mood.includes(query)
    );
    const formattedTracks = filtered.map((track) => {
      if (Number.isNaN(Number(track.duration))) {
        return track;
      }
      return {
        ...track,
        duration: new Date(Math.round(Number(track.duration) * 1000)).toISOString().slice(14, 19)
      };
    });

    return formattedTracks.sort((a, b) =>
      a.is_public && b.is_public ? 0 : a.is_public ? 1 : -1
    ) as BackgroundMusic[];
  }
);

export const getBackgroundMusicById = createSelector(
  [formattedMusicTracks, (state, { id }: { id?: string }) => ({ state, id })],
  (tracks, { id }) => {
    if (!id) {
      return undefined;
    }

    // Not using the adapter here as the formatted music duration is being formatted in formattedMusicTracks
    return tracks.find((track) => track.id === id);
  }
);

export const getAllMoods = createSelector([backgroundMusicGlobalSelectors.selectAll], (tracks) => [
  ...new Set(
    tracks
      .map((obj) => obj.mood)
      .reduce((accumulator, moodArray) => [...accumulator, ...moodArray], [])
      .sort()
  )
]);
