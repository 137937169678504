import React, { Component, PureComponent } from "react";
import debounce from "lodash/debounce";
import * as color from "react-color/lib/helpers/color";
/* origin code is taken react-color, debounce time set to 0 */
export const ColorWrap = (Picker) => {
  class ColorPicker extends (PureComponent || Component) {
    constructor(props) {
      super();

      this.state = {
        ...color.toState(props.color, 0)
      };

      this.debounce = debounce((fn, data, event) => {
        fn(data, event);
      }, 0);
    }

    static getDerivedStateFromProps(nextProps, state) {
      return {
        ...color.toState(nextProps.color, state.oldHue)
      };
    }

    handleChange = (data, event) => {
      const isValidColor = color.simpleCheckForValidColor(data);
      if (isValidColor) {
        const colors = color.toState(data, data.h || this.state.oldHue);
        this.setState(colors);
        // eslint-disable-next-line no-unused-expressions
        this.props.onChangeComplete && this.debounce(this.props.onChangeComplete, colors, event);
        // eslint-disable-next-line no-unused-expressions
        this.props.onChange && this.props.onChange(colors, event);
      }
    };

    handleSwatchHover = (data, event) => {
      const isValidColor = color.simpleCheckForValidColor(data);
      if (isValidColor) {
        const colors = color.toState(data, data.h || this.state.oldHue);
        // eslint-disable-next-line no-unused-expressions
        this.props.onSwatchHover && this.props.onSwatchHover(colors, event);
      }
    };

    render() {
      const optionalEvents = {};
      if (this.props.onSwatchHover) {
        optionalEvents.onSwatchHover = this.handleSwatchHover;
      }

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Picker {...this.props} {...this.state} onChange={this.handleChange} {...optionalEvents} />
      );
    }
  }

  ColorPicker.propTypes = {
    ...Picker.propTypes
  };

  ColorPicker.defaultProps = {
    ...Picker.defaultProps,
    color: {
      h: 250,
      s: 0.5,
      l: 0.2,
      a: 1
    }
  };

  return ColorPicker;
};

export default ColorWrap;
