import { Tag } from "antd";
import styled, { useTheme } from "styled-components";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import {
  H1_TextMidHeadline,
  H1_TextSmall,
  H1_TextSubtitle
} from "app/components/_Infrastructure/Typography";
import useVirtualCharacterOptions from "app/components/digitalAvatar/useVirtualCharacterOptions";
import { useIntl } from "react-intl";
import { addCharacterMessages } from "app/components/digitalAvatar/messages";
import { H1_Icon } from "app/components/_Infrastructure/design-system/icon";
import { StyledModal } from "app/components/common/StyledModal";

const BoxContainer = styled(H1_FlexColumn)<{ $instances: number }>`
  border: 1px solid ${({ theme }) => theme.gray5};
  border-radius: 10px;
  flex: 2 1 240px;
  transition: box-shadow 0.2s ease-in-out;
  gap: 10px;
  padding: 50px 10px;
  &:hover {
    box-shadow: 0px 5px 20px ${({ theme }) => theme.gray5};
  }
`;

const Modal = styled(StyledModal)`
  min-width: 70vw;
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 70px 50px;
  }
`;

const StyledTag = styled(Tag)`
  border-radius: 16px;
  font-weight: 300;
  color: #1d1d1b; // Must be constant as the color of the tags are bright
  padding: 5px 25px;
`;

const StyledList = styled.ul`
  padding-left: 15px;
  width: 85%;
  li * {
    white-space: break-spaces;
  }
`;

const CreateDigitalTwinModal: React.FC = () => {
  const { closeModal, editorModalOpen } = useModal();
  const intl = useIntl();
  const theme = useTheme();
  const visible = editorModalOpen?.open === ModalName.createVirtualTwin;
  const { virtualCharacterOptions } = useVirtualCharacterOptions();

  const onClose = () => {
    closeModal();
  };

  return (
    <Modal
      closeIcon={<CloseModalIcon />}
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
    >
      <H1_FlexRow justify="center">
        <H1_TextMidHeadline fontWeight={300}>
          {intl.formatMessage(addCharacterMessages.virtualTwinCreationModalTitle)}
        </H1_TextMidHeadline>
      </H1_FlexRow>
      <H1_FlexRow justify="space-between" gap="25px" wrap="wrap">
        {virtualCharacterOptions.map((option) => (
          <BoxContainer
            $instances={virtualCharacterOptions.length}
            align={"center"}
            justify={"center"}
            onClick={option.onClick}
            key={option.title}
          >
            <H1_Icon icon={option.iconName} size="2rem" color={theme.gray8} />
            <H1_TextSubtitle fontWeight={300} whiteSpace="break-spaces" textAlign="center">
              {option.title}
            </H1_TextSubtitle>
            <StyledTag color={option.tagColor}>{option.tag}</StyledTag>
            <StyledList>
              {option.features.map((txt: string) => (
                <li key={txt}>
                  <H1_TextSmall>{txt}</H1_TextSmall>
                </li>
              ))}
            </StyledList>
          </BoxContainer>
        ))}
      </H1_FlexRow>
    </Modal>
  );
};

export default CreateDigitalTwinModal;
