import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import useModal, { ModalName } from "app/hooks/useModal";
import CloseModalIcon from "app/components/common/CloseModalIcon/CloseModalIcon";
import { characterModalMessages } from "app/pages/pricing/messages";
import { useIntl } from "react-intl";
import { Draft } from "app/types";
import IconButton from "app/components/common/Icons/IconButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import * as analyticsEvents from "app/store/thunks/analyticsEvents.thunk";
import { charactersActions } from "app/store/slices/characters.slice";
import * as CharacterSelectors from "app/store/selectorsV2/character.selectors";
import { userActions } from "app/store/slices/user.slice";
import { fetchingStatus, isMediaPlaying } from "app/utils/helpers";
import ConditionalRender from "app/components/common/ConditionalRender";
import { draftsActions } from "app/store/slices/drafts.slice";
import * as draftsSelectors from "app/store/selectorsV2/drafts.selectors";
import { useNavigate } from "react-router-dom";
import CircleLoader from "app/components/common/Loaders/CircleLoader";
import { H1_TextSmall, H1_TextSubtitle } from "app/components/_Infrastructure/Typography";
import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { StyledModal } from "app/components/common/StyledModal";

const BottomIconButton = styled(IconButton)`
  align-self: center;
  border: 1px solid ${({ theme }) => theme.gray5};
  border-radius: 2px;
`;

const LeftFlexColumn = styled(H1_FlexColumn)`
  background-color: ${({ theme }) => theme.gray1};
  position: relative;
`;

const CharacterVideo = styled.video`
  width: 250px;
  height: 250px;
  position: absolute;
  border-radius: 50%;
  object-fit: cover;
`;

const Rectangle = styled.svg`
  background-color: orange;
  transform: rotate(14deg);
  width: 329px;
  height: 106px;
  right: 70px;
  bottom: 211px;
  position: absolute;
  visibility: hidden;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`;

const StyledCaretRightOutlined = styled.i`
  cursor: pointer;
  margin-top: 90px;
  font-size: 48px;
  color: ${({ theme }) => theme.gray5};
  animation: ${pulse} 1s linear infinite;
  stroke-width: 10px;
  stroke: ${(props) => props.theme.gray11};
`;

const StyledPauseOutlined = styled.i`
  cursor: pointer;
  margin-top: 90px;
  font-size: 48px;
  color: ${({ theme }) => theme.gray5};
  stroke-width: 10px;
  stroke: ${(props) => props.theme.gray11};
  position: relative;
`;

interface CharacterModalProps {
  open: boolean;
  onFinish: () => void;
}
const CharacterModal = ({ open, onFinish }: CharacterModalProps) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { formatMessage } = useIntl();
  const { returnToModal, editorModalOpen, openModal } = useModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const createDraftStatus = useAppSelector(draftsSelectors.createDraftStatus);
  const createdDraft = useAppSelector(draftsSelectors.currentDraft);
  const visible = editorModalOpen?.open === ModalName.characterModal;

  const mobileCharacters = useAppSelector(CharacterSelectors.getAllMobileCharacters);
  const videoSrc = mobileCharacters?.length > 0 && mobileCharacters[0].video;
  const isCreateDraftLoading = createDraftStatus === fetchingStatus.loading;

  useEffect(() => {
    if (open) {
      dispatch(charactersActions.getMobileCharactersListRequest());
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      openModal(ModalName.characterModal);
    }
  }, [mobileCharacters?.length, open]);

  useEffect(() => {
    if (createDraftStatus === fetchingStatus.idle || !visible) {
      return;
    }
    if (createDraftStatus === fetchingStatus.succeeded) {
      onClose();
      dispatch(analyticsEvents.navigateToEditor({ source: "mobile_character_modal" }));
      navigate(`/editor/${createdDraft.id}`);
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    } else if (createDraftStatus === fetchingStatus.failed) {
      dispatch(draftsActions.setCreateDraftStatusToIdle());
    }
  }, [createDraftStatus, visible]);

  const onClose = () => {
    dispatch(userActions.updateUserPreferencesRequest({ show_mobile_first_time_character: false }));
    returnToModal();
    onFinish();
  };

  const onClickPlay = () => {
    if (!videoLoaded) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isVideoPlaying = isMediaPlaying(videoRef.current!);
    if (isVideoPlaying) {
      videoRef.current?.pause();
      setVideoPlaying(false);
    } else {
      videoRef.current?.play();
      setVideoPlaying(true);
    }
  };

  const onClickCreateVideo = () => {
    dispatch(analyticsEvents.mobileCharacterModalClick({ action: "click" }));
    const draft: Draft = {
      title: "Virtual human draft",
      description: "",
      type: "mobile"
    };
    dispatch(draftsActions.createDraftRequest({ draft }));
  };

  const onVideoLoaded = async () => {
    setVideoLoaded(true);
  };

  return (
    <StyledModal
      width="811px"
      open={visible}
      footer={null}
      bodyStyle={{ height: "430px" }}
      centered
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<CloseModalIcon />}
      $padding="0"
      $flex="1"
    >
      <H1_FlexRow flex="1 1 auto">
        <LeftFlexColumn justify="center" align="center" width="389px" flex="0 0 auto">
          <Rectangle />
          {videoSrc && (
            <CharacterVideo
              ref={videoRef}
              src={videoSrc}
              onCanPlay={onVideoLoaded}
              onEnded={() => setVideoPlaying(false)}
            />
          )}
          <ConditionalRender condition={!videoLoaded}>
            <CircleLoader />
          </ConditionalRender>
          <ConditionalRender condition={!videoPlaying && videoLoaded}>
            <StyledCaretRightOutlined className="far fa-circle-play" onClick={onClickPlay} />
          </ConditionalRender>
          <ConditionalRender condition={videoPlaying && videoLoaded}>
            <StyledPauseOutlined className="far fa-circle-pause" onClick={onClickPlay} />
          </ConditionalRender>
        </LeftFlexColumn>
        <H1_FlexColumn width="423px" flex="1" justify="space-between" padding="0 0 50px 0">
          <H1_FlexColumn flex="1" width="100%" gap="16px" padding="46px 36px 0 44px">
            <H1_TextSmall fontWeight={500} color={theme.blue4}>
              {formatMessage(characterModalMessages.characterInfoSubTitle)}
            </H1_TextSmall>
            <H1_TextSubtitle fontWeight={500} whiteSpace="break-spaces" color={theme.gray11}>
              {formatMessage(characterModalMessages.characterInfoTitle)}
            </H1_TextSubtitle>
            <H1_TextSmall whiteSpace="break-spaces" color={theme.gray11}>
              {formatMessage(characterModalMessages.characterInfoText)}
            </H1_TextSmall>
          </H1_FlexColumn>
          <H1_FlexRow padding="0 36px 0 44px" gap="20px">
            <BottomIconButton
              disabled={isCreateDraftLoading}
              width="120px"
              shrink
              height="38px"
              color="var(--blue-04)"
              backgroundColor="var(--gray-01)"
              onClick={onClose}
            >
              {formatMessage(characterModalMessages.maybeLaterButton)}
            </BottomIconButton>
            <BottomIconButton
              disabled={isCreateDraftLoading}
              width="120px"
              shrink
              height="38px"
              color="var(--gray-01)"
              backgroundColor="var(--blue-04)"
              onClick={onClickCreateVideo}
            >
              {isCreateDraftLoading ? (
                <CircleLoader />
              ) : (
                formatMessage(characterModalMessages.createVideoButton)
              )}
            </BottomIconButton>
          </H1_FlexRow>
        </H1_FlexColumn>
      </H1_FlexRow>
    </StyledModal>
  );
};

export default CharacterModal;
