/* eslint-disable react-refresh/only-export-components */
const uiPrefix = "[UI]";

export const SET_SIDEBAR_COLLAPSE = `${uiPrefix} SET SIDEBAR COLLAPSE`;
export const SET_SIDEBAR_VISIBILITY = `${uiPrefix} SET SIDEBAR VISIBILITY`;
export const SET_EDITOR_ROW_VIEW = `${uiPrefix} SET EDITOR ROW VIEW`;
export const SET_EDITOR_DRAWER_OPEN = `${uiPrefix} SET OPEN DRAWER PADDING`;
export const SET_OPEN_DRAWER_ORIGIN = `${uiPrefix} SET OPEN DRAWER ORIGIN`;
export const SET_OPEN_MODAL = `${uiPrefix} SET OPEN MODAL`;
export const SHOW_PAYMENT_FLOW = `${uiPrefix} SHOW_PAYMENT_FLOW`;
export const SET_ERRORS = `${uiPrefix} SET_ERRORS`;
export const SET_VALIDATION_SCROLL = `${uiPrefix} SET_VALIDATION_SCROLL`;
export const SET_SCENE_ERRORS = `${uiPrefix} SET_SCENE_ERRORS`;
export const CLEAN_ERRORS = `${uiPrefix} CLEAN_ERRORS`;
export const SET_INTL = `${uiPrefix} SET_INTL`;
export const SET_FTU_VIDEO_PREVIEW = `${uiPrefix} SET_FTU_VIDEO_PREVIEW`;
export const SET_NOTIFICATIONS = `${uiPrefix} SET_NOTIFICATIONS`;
export const SET_VIEW = `${uiPrefix} SET_VIEW`;
export const TOKEN_REFRESH_ERROR = `${uiPrefix} TOKEN_REFRESH_ERROR`;
export const CLEAN_TOKEN_REFRESH_ERROR = `${uiPrefix} CLEAN_TOKEN_REFRESH_ERROR`;
export const SET_NOTIFICATION_BADGE_COUNTER = `${uiPrefix} SET_NOTIFICATION_BADGE_COUNTER`;
export const SET_REALSME_VIDEO_READY_NOTIFICATION = `${uiPrefix} SET_REALSME_VIDEO_READY_NOTIFICATION`;

const toggleSiderCollapse = (collapsed) => ({
  type: SET_SIDEBAR_COLLAPSE,
  payload: collapsed
});

const toggleSiderVisibility = (show) => ({
  type: SET_SIDEBAR_VISIBILITY,
  payload: show
});

const setEditorRowView = (rowIdxDisplay) => ({
  type: SET_EDITOR_ROW_VIEW,
  payload: rowIdxDisplay
});

const setEditorDrawerOpen = (drawerOpen) => ({
  type: SET_EDITOR_DRAWER_OPEN,
  payload: drawerOpen
});

const setEditorDrawerOpenOrigin = (drawerOrigin) => ({
  type: SET_OPEN_DRAWER_ORIGIN,
  payload: drawerOrigin
});

const setEditorModalOpen = (modalOpen, modalOpenOrigin, backModal) => ({
  type: SET_OPEN_MODAL,
  payload: {
    open: modalOpen,
    context: modalOpenOrigin,
    backModal
  }
});

const showPaymentFlow = (action) => ({
  type: SHOW_PAYMENT_FLOW,
  payload: {
    action
  }
});

const setErrors = (errors) => ({
  type: SET_ERRORS,
  payload: errors
});
const setValidationScroll = (shouldScroll) => ({
  type: SET_VALIDATION_SCROLL,
  payload: shouldScroll
});
const setSceneErrors = (errors) => ({
  type: SET_SCENE_ERRORS,
  payload: errors
});
const cleanErrors = () => ({
  type: CLEAN_ERRORS
});

const setIntl = (intl) => ({
  type: SET_INTL,
  payload: intl
});

const setFtuVideoPreview = (isVideoPreview) => ({
  type: SET_FTU_VIDEO_PREVIEW,
  payload: isVideoPreview
});

const setNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: notifications
});

const setView = (view) => ({
  type: SET_VIEW,
  payload: view
});

const setTokenRefreshError = () => ({
  type: TOKEN_REFRESH_ERROR
});

const cleeanTokeRefreshError = () => ({
  type: CLEAN_TOKEN_REFRESH_ERROR
});

const setNotificationsBadgeCounter = (notifications) => ({
  type: SET_NOTIFICATION_BADGE_COUNTER,
  payload: notifications
});

const setRealsMeVideoReadyNotification = (show) => ({
  type: SET_REALSME_VIDEO_READY_NOTIFICATION,
  payload: show
});

const UiActions = {
  toggleSiderCollapse,
  toggleSiderVisibility,
  setEditorRowView,
  setEditorDrawerOpen,
  setEditorDrawerOpenOrigin,
  setEditorModalOpen,
  showPaymentFlow,
  setErrors,
  setValidationScroll,
  setSceneErrors,
  cleanErrors,
  setIntl,
  setFtuVideoPreview,
  setNotifications,
  setView,
  setTokenRefreshError,
  cleeanTokeRefreshError,
  setNotificationsBadgeCounter,
  setRealsMeVideoReadyNotification
};

export default UiActions;
