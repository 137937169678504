import React from "react";
import { useAppSelector } from "app/hooks";
import { getCurrentWorkspace } from "app/store/selectorsV2/workspaces.selectors";
import { Member } from "app/types";
import AvatarInitials from "app/components/common/AvatarInitials";
import { H1_TextSmall, H1_TextXs } from "app/components/_Infrastructure/Typography";

interface UserDetailsProps {
  userEmail: string;
}

const UserDetails = ({ userEmail }: UserDetailsProps) => {
  const currentWorkspace = useAppSelector((state) => getCurrentWorkspace(state));
  const workspaceMember = currentWorkspace?.members.find(
    (member: Member) => member.email === userEmail
  );

  return (
    <div className="flex gap-2 items-center">
      <AvatarInitials
        src={workspaceMember?.picture}
        text={workspaceMember?.nickname || ""}
        size="sm"
      />
      <div className="flex flex-col">
        <H1_TextSmall>{workspaceMember?.nickname}</H1_TextSmall>
        <H1_TextXs>{workspaceMember?.email}</H1_TextXs>
      </div>
    </div>
  );
};

export default UserDetails;
